type ShouldRetrySign = (e,retriesDone:number)=>Promise<boolean>
type LinearRetriesFactorySign = (maxRetries:number) => ShouldRetrySign
export const linearRetriesFactory : LinearRetriesFactorySign = (maxRetries:number)=>{
  return (e,retriesDone:number)=> {
    const retriesLeft = maxRetries - retriesDone
    console.warn(`Action failed. ${retriesLeft} tries left`, e)
    return Promise.resolve().then(() => retriesLeft > 0)
  }
}
export const exponentialRetriesFactory : LinearRetriesFactorySign = (maxRetries:number)=>{
  return (e,retriesDone:number)=> {
    const retriesLeft = maxRetries - retriesDone
    const waiting = retriesDone*retriesDone*1000 + 1000
    console.warn(`Action failed. ${retriesLeft} tries left - Waiting ${waiting} milliseconds`, e)
    const shouldRetry = retriesLeft > 0
    if(!shouldRetry){return Promise.resolve(false)}
    return new Promise(resolve => setTimeout(()=>resolve(shouldRetry), waiting))
  }
}
export const extractMessageFromError = (error:any) => {
  if (error.response?.data) {
    return error.response.data.error
  }
}
export const extractStatusCodeFromError = (error:any) => {
  if (error.response?.status) {
    return error.response.status
  }
}
export default class ActionUtils{
  public static retryOnFailure<T>(
    asyncAction : () => Promise<T>,
    retriesDone : number = 0,
    shouldRetry : ShouldRetrySign = linearRetriesFactory(3)
  ) : Promise<T> {
    return Promise.resolve()
    .then(asyncAction)
    .catch((e)=>{
      return shouldRetry(e, retriesDone)
      .then(
        (shouldRetryResult:boolean) => {
          if(shouldRetryResult){
            return ActionUtils.retryOnFailure(asyncAction, retriesDone+1, shouldRetry)
          }
          throw e
        }
      )
    })
  }
}
