import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const addAccount = (state, action) => {
  return [...state, action.accountId]
}
const removeAccount = (state, action) => {
  return state.filter(x=>x!=action.accountId) //In preparation of this feature
}
const AccountsWithoutWaze = ReducerUtils.createReducer<number[]>([],{
  [Actions.RETRIEVE_WAZE_DATA_NO_ACCOUNT] : addAccount,
  [Actions.RETRIEVE_ACCOUNT_WAZE_COMPANY] : removeAccount
})
export default AccountsWithoutWaze
