import Actions      from "../../../model/constant/actions"
import ReducerUtils from "../../../utils/ReducerUtils"
import ObjectUtils  from "../../../utils/ObjectUtils"

const saveStats = (state, action) =>{
  return {...state, [action.accountId] : action.stats}
}

interface StatsByCampaign{
  [id:string] : any[]
}

export const ImpressionsByDevice = ReducerUtils.createReducer<StatsByCampaign>({},{
  [Actions.RETRIEVE_IMPRESSIONS_BY_DEVICE] : saveStats,
  [Actions.CHANGE_SELECTED_PERIOD] : (state,action):StatsByCampaign=>({})
})
