import * as React                  from "react"
import {HashRouter, Route}         from "react-router-dom"
import {Switch}                    from "react-router-dom"
import {Redirect}                  from "react-router-dom"
import CampaignsTypes              from "../page/campaign/"
import SearchCampaign              from "../page/campaign/component/search/Campaign"
import SearchCampaignsList         from "../page/campaign/component/search/CampaignsList"
import DisplayCampaign             from "../page/campaign/component/display/Campaign"
import DisplayCampaignsList        from "../page/campaign/component/display/CampaignsList"

export const routing = (props) => {
  let redirectPath="/Account/"+props.accountId+"/Campaigns"
  return (
    <HashRouter>
      <Switch>
        <Route
          exact
          path={"*/campaign(s)?"}
          render={(props)=><CampaignsTypes/>}
        />

        <Route
          exact
          path={"*/campaign(s)?/search"}
          render={(props)=><SearchCampaignsList/>}
        />
        <Route
          exact
          path={"*/campaign(s)?/display"}
          render={(props)=><DisplayCampaignsList/>}
        />

        <Route
          exact
          path={"*/campaign(s)?/search/:campaignid"}
          render={(props)=> <SearchCampaign campaignIds={props.match.params.campaignid}/>}
        />
        <Route
          exact
          path={"*/campaign(s)?/display/:campaignid"}
          render={(props)=> <DisplayCampaign campaignId={props.match.params.campaignid}/>}
        />

        <Route
          path="*/campaign(s)?/search/:campaignid/:campaignview"
          render={(props)=> <SearchCampaign campaignIds={props.match.params.campaignid} defaultView={props.match.params.campaignview}/>}
        />
        <Route
          path="*/campaign(s)?/display/:campaignid/:campaignview"
          render={(props)=> <DisplayCampaign campaignId={props.match.params.campaignid} defaultView={props.match.params.campaignview}/>}
        />

        <Redirect from="/" to={redirectPath}/>
      </Switch>
    </HashRouter>
  )
}
