import ReducerUtils from "../../utils/ReducerUtils"
import Actions      from "../../model/constant/actions"
import Account      from "../../model/Store/Account/Account"

const retrieveAccountForToken = (state,action) => {
	return action.accounts
}
const retrieveAccountTrackingInformation = (state,action) => {
  return state.map(account=>{
    if(account.id === action.information.AccountID){
      return {
        ...account,
        GAId : action.information.GoogleAnalyticsID?action.information.GoogleAnalyticsID:null,
        GTMContainerName : action.information.GTMContainerName?action.information.GTMContainerName:null,
        callRailId : action.information.CallRailID?action.information.CallRailID:null,
      }
    }
    return account
  })
}
const retrieveAccountWazeCompany = (state,action) => {
  return state.map(account=>{
    if(account.id === action.accountId){
      return {
        ...account,
        wazeCompanyId : action.companyId
      }
    }
    return account
  })
}
const setWazeCompanyNull = (state,action) => {
  return state.map(account=>{
    if(account.id === action.accountId){
      return {
        ...account,
        wazeCompanyId : null
      }
    }
    return account
  })
}
export const AccountList = ReducerUtils.createReducer<Account[]>([],{
	[Actions.RETRIEVE_ACCOUNTS_FOR_TOKEN] : retrieveAccountForToken,
  [Actions.RETRIEVE_ACCOUNT_TRACKING_INFORMATION] : retrieveAccountTrackingInformation,
  [Actions.RETRIEVE_ACCOUNT_WAZE_COMPANY] : retrieveAccountWazeCompany,
  [Actions.RETRIEVE_WAZE_DATA_NO_ACCOUNT] : setWazeCompanyNull,
})
