import './index.css'
import * as React                   from "react"
import keydown                      from 'react-keydown'
import {connect}                    from 'react-redux'
import {Translate}                  from "react-localize-redux"
import ArrayUtils                   from "../../../../../utils/ArrayUtils"
import Status                       from "../../../../../model/constant/status"
import assignDesiredStatus          from "../../../../../actions/desiredStatusException/assignDesiredStatusException"
import removeDesiredStatus          from "../../../../../actions/desiredStatusException/removeDesiredStatusException"
import StatusAccessor               from "../../../../../storeAccessor/StatusException"
import StatusModal                  from "./Modal"
import {Loading}                    from "carbon-components-react"
import { StatusExceptions }         from "../../../../../utils/Headstone"

interface StatusModalWrapperOwnProps{
  accountId         : number
  campaignName      : string
  campaigns         : any[]
  clickable        ?: boolean
  showStatusCircle ?: boolean
}
interface StatusModalWrapperProps extends StatusModalWrapperOwnProps{
  isOpen                    ?: boolean
  statusException            : any
  statusExceptionType        : any
  addDesiredStatusException  : (status:string)=>void
  deleteDesiredStatus        : ()=>void
}

const makeMapStateToProps = () => {
  const exceptionSelector = StatusAccessor.makeStatusExceptionForCampaignsSelector()
  return (state, ownProps:StatusModalWrapperOwnProps) => {
    const exception = exceptionSelector(state, ownProps)
    return {
      statusException     : exception.statusException,
      statusExceptionType : exception.statusExceptionType,
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    addDesiredStatusException : (accountId:number, campaignNames:string[], status:StatusExceptions['status']) => dispatch(assignDesiredStatus(accountId,campaignNames,status)),
    deleteDesiredStatus : (accountId:number, campaignNames:string[]) => dispatch(removeDesiredStatus(accountId,campaignNames))
  }
}
const mergeProps = (SP, DP, ownProps:StatusModalWrapperOwnProps) => {
  const accountId = ownProps.accountId
  const names = ownProps.campaigns.map(campaign=>campaign.name)
  return {
    ...SP,...DP,...ownProps,
    addDesiredStatusException : (status:string)=>DP.addDesiredStatusException(accountId, names, status),
    deleteDesiredStatus : ()=>DP.deleteDesiredStatus(accountId, names)
  }
}
class StatusModalWrapper extends React.Component<StatusModalWrapperProps,any> {
  static defaultProps = {
    clickable : true,
    showStatusCircle : true
  }
  constructor(props){
    super(props)
    this.state = {
      isOpen : props.isOpen,
    }
    this.toggleModal  = this.toggleModal.bind(this)
    this.closeModal   = this.closeModal.bind(this)
    this.renderCircle = this.renderCircle.bind(this)
  }
  toggleModal(){
    if(this.props.clickable){
      this.setState(prevState => ({
        isOpen : !prevState.isOpen,
      }))
    }
    else{
      alert("The status module is disabled for this section.")
    }
  }
  @keydown(['esc'])
  closeModal(){
    this.setState({isOpen:false})
  }

  render(){
    if(this.props.campaigns === undefined){return <Loading small withOverlay={false}/>}
    const campaigns = this.props.campaigns
    const friendStatus = ArrayUtils.unique(campaigns.map(c=>c.status.toUpperCase()))
    const hasException = this.props.statusException.length > 0
    let currentStatus
    if(hasException){
      currentStatus = (
        this.props.statusExceptionType == Status.ENABLED
        ? <Translate id="campaigns.exceptionModal.active"/>
        : <Translate id="campaigns.exceptionModal.paused"/>
      )
    }
    else{
      currentStatus = (
        friendStatus[0] == Status.ENABLED
        ? <Translate id="campaigns.exceptionModal.active"/>
        : <Translate id="campaigns.exceptionModal.paused"/>
      )
    }
    return (
      <div className="Status-modal-wrapper">
        <div className="Icon clickable" onClick={this.toggleModal}>
          {this.renderCircle(friendStatus, hasException)}
          <div className="Status">{currentStatus} {hasException?<span className = "Exception">*</span> : <></>}</div>
        </div>
        <StatusModal
          isOpen={this.state.isOpen}
          hasException={hasException}
          statusExceptionType={this.props.statusExceptionType}
          close={this.closeModal}
          addDesiredStatusException={this.props.addDesiredStatusException}
          deleteDesiredStatus={this.props.deleteDesiredStatus}
        />
      </div>
    )
  }
  renderCircle(friendStatus, hasException){
    if(!this.props.showStatusCircle){return null}
    let color
    if(friendStatus.length > 1){color = "orange"}
    else{
      if(hasException){color = this.props.statusExceptionType == Status.ENABLED ? "green":"#ff9800"}
      else{color = friendStatus[0] == Status.ENABLED ? "green":"#ff9800"}
    }
    const style = {backgroundColor:color}
    return <div className="bx--detail-page-header-status-icon" style={style}></div>
  }
}
export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(StatusModalWrapper)
