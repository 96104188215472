import './index.css'
import * as React                                     from "react"
import {connect}                                      from "react-redux"
import Account                                        from "../../model/Store/Account/Account"
import retrieveTrackingInfo                           from "../../actions/account/retrieveTrackingInformation"
import {assignGTMInformation, assignCallRailId}       from "../../actions/account/assignTrackingInformation"
import {assignGoogleAnalyticsAccountId}               from "../../actions/account/assignTrackingInformation"
import {getCompanyOfAccount}                          from "../../actions/account/WazeAccount"
import {assignCompany, unassignCompany}               from "../../actions/account/WazeAccount"
import {setAccountWhiteLabel,removeAccountWhiteLabel} from "../../actions/account/accountPreferences"
import {Button}                                       from "carbon-components-react"
import AccountView                                    from "./component/AccountView"
import AccountTrackingView                            from "./component/AccountTrackingView"
import AccountWazeView                                from "./component/AccountWazeView"
import GTMModal                                       from "./component/GTMModal"
import AccountPreferences                             from "./component/AccountPreferences"
import {Panel}                                        from "../../components/Panel/Panel"
import GoBack                                         from "../../components/Button/ButtonGoBack"
import UserPermissionBoundary                         from "../../components/permissions/UserPermissionBoundary"
import requiresWazeCompanies                          from "../../components/loaders/wazeCompaniesLoader"
import requiresAccountWhiteLabels                     from "../../components/loaders/possibleAccountWhiteLabelsLoader"
import {retrieveAccountPreferences}                   from "../../actions/account/accountPreferences"


interface AccountComponentOwnProps{
  accountId ?: number
  exit      ?: ()=>void
}
interface AccountComponentProps extends AccountComponentOwnProps{
  accountId                : number
  account                  : Account
  accountPreferences       : any
  wazeCompanies            : any[]
  accountWhiteLabels       : string[]
  isFetchingPreferences    : boolean
  fetchTrackingInformation : ()=>void
  fetchAccountWazeCompany  : ()=>void
  fetchAccountPreferences  : ()=>void
  assignGTMInfo            : (name:string)=>void
  assignCallRailId         : (id:number)=>void
  assignGAId               : (id:string)=>void
  assignWazeCompany        : (companyId:number)=>void
  unassignWazeCompany      : ()=>void
  setWhiteLabel            : (whiteLabel:string)=>void
  removeWhiteLabel         : ()=>void
}
const mapStateToProps = (state, ownProps:AccountComponentOwnProps) => {
  const accountId = ownProps.accountId || state.Accounts.selected
	let account
  if(accountId){
    account = state.Accounts.list.find(account=>account.id==accountId)
	}
	return {
    accountId             : accountId,
		account               : account,
    accountPreferences    : state.Accounts.preferences[accountId],
    isFetchingPreferences : state.Accounts.isFetchingPreferences[accountId],
    wazeCompanies         : state.Waze.Companies.list,
	}
}
const mapDispatchToProps = dispatch => {
  return {
    fetchTrackingInformation : (accountId:number)=>dispatch(retrieveTrackingInfo(accountId)),
    fetchAccountWazeCompany  : (accountId:number)=>dispatch(getCompanyOfAccount(accountId)),
    fetchAccountPreferences  : (accountId)=>dispatch(retrieveAccountPreferences(accountId)),
    assignGTMInfo            : (accountId:number, name:string)=>dispatch(assignGTMInformation(accountId, name)),
    assignCallRailId         : (accountId:number, id:number)=>dispatch(assignCallRailId(accountId, id)),
    assignGAId               : (accountId:number, id:string)=>dispatch(assignGoogleAnalyticsAccountId(accountId, id)),
    assignWazeCompany        : (accountId:number, companyId:number)=>dispatch(assignCompany(accountId, companyId)),
    unassignWazeCompany      : (accountId:number, companyId:number)=>dispatch(unassignCompany(accountId)),
    setWhiteLabel            : (accountId:number, whiteLabel:string)=>dispatch(setAccountWhiteLabel(accountId, whiteLabel, true)),
    removeWhiteLabel         : (accountId:number)=>dispatch(removeAccountWhiteLabel(accountId, true))
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    fetchTrackingInformation : ()=>DP.fetchTrackingInformation(SP.accountId),
    fetchAccountPreferences  : ()=>DP.fetchAccountPreferences(SP.accountId),
    fetchAccountWazeCompany  : ()=>DP.fetchAccountWazeCompany(SP.accountId),
    assignGTMInfo            : (name:string)=>DP.assignGTMInfo(SP.accountId, name),
    assignCallRailId         : (id:number)=>DP.assignCallRailId(SP.accountId, id),
    assignGAId               : (id:string)=>DP.assignGAId(SP.accountId, id),
    assignWazeCompany        : (id:number)=>DP.assignWazeCompany(SP.accountId, id),
    unassignWazeCompany      : ()=>DP.unassignWazeCompany(SP.accountId),
    setWhiteLabel            : (whiteLabel:string)=>DP.setWhiteLabel(SP.accountId, whiteLabel),
    removeWhiteLabel         : ()=>DP.removeWhiteLabel(SP.accountId),
  }
}
interface AccountComponentState{
  actionsToSave : {
    [informationName:string] : ()=>void
  },
  randomKey : number //Let us reset the children components' state
}
const PermissionWrapper = props => (
  <UserPermissionBoundary
    userLevel={3}
    permissions={[]}
    onFailure={()=><div>ACCESS DENIED</div>}
  >
    <AccountComponent {...props}/>
  </UserPermissionBoundary>
)
class AccountComponent extends React.Component<AccountComponentProps,AccountComponentState>{
  constructor(props){
    super(props)
    this.state = {
      actionsToSave : {},
      randomKey : Math.random()
    }
    this.addAction = this.addAction.bind(this)
    this.executeActions = this.executeActions.bind(this)
    this.cancelActions = this.cancelActions.bind(this)
  }
  componentDidUpdate(prevProps){
    if(prevProps.account.id != this.props.account.id){
      this.setState({actionsToSave:{}})
    }
  }
  addAction(name:string, action:()=>void){
    this.setState(prevState=>({
      actionsToSave : {
        ...prevState.actionsToSave,
        [name] : action
      }
    }))
  }
  executeActions(){
    for(let informationName of Object.keys(this.state.actionsToSave)){
      this.state.actionsToSave[informationName]()
    }
    this.setState({actionsToSave : {}})
  }
  cancelActions(){
    this.setState(prevState=>({
      actionsToSave : {},
      randomKey : prevState.randomKey+1
    }))
  }
  render(){
    if(this.props.account && this.props.account.GTMContainerName === undefined){this.props.fetchTrackingInformation()}
    if(this.props.account && this.props.account.wazeCompanyId === undefined){this.props.fetchAccountWazeCompany()}
    if(!this.props.accountPreferences && !this.props.isFetchingPreferences){this.props.fetchAccountPreferences()}
    const subtitle = this.props.account ? this.props.account.name : ""
    const changedInformationName = Object.keys(this.state.actionsToSave)
    return (
      <Panel title="Account" subtitle={subtitle}>
        {this.props.exit && <GoBack onClick={this.props.exit}/>}
        {this.props.account?
          <div className="AccountPage">
            <div className="AccountActions">
              <div className="Left">
                <Button disabled={changedInformationName.length === 0} onClick={this.executeActions}>Save</Button>
                <Button onClick={this.cancelActions}>Cancel</Button>
              </div>
              <div className="Right">
                <GTMModal
                  GAId={this.props.account.GAId?this.props.account.GAId:""}
                  accountName={this.props.account.name}
                />
              </div>
            </div>
            <div  className="AccountInformation">
              <AccountView
                account={this.props.account}
                title="General"
              />
              <AccountTrackingView
                key={"Tracking-"+this.state.randomKey}
                account={this.props.account}
                addSaveAction={this.addAction}
                assignGTMInfo={this.props.assignGTMInfo}
                assignCallRailId={this.props.assignCallRailId}
                assignGAId={this.props.assignGAId}
                editedInformation={changedInformationName}
              />
              <AccountWazeView
                key={"Waze-"+this.state.randomKey}
                currentCompanyId={this.props.account.wazeCompanyId}
                allCompanies={this.props.wazeCompanies}
                addSaveAction={this.addAction}
                assignCompany={this.props.assignWazeCompany}
                unassignCompany={this.props.unassignWazeCompany}
                editedInformation={changedInformationName}
              />
              <AccountPreferences
                key={"Preferences-"+this.state.randomKey}
                accountPreferences={this.props.accountPreferences}
                whiteLabels={this.props.accountWhiteLabels}
                saveWhiteLabel={this.props.setWhiteLabel}
                removeWhiteLabel={this.props.removeWhiteLabel}
                addSaveAction={this.addAction}
                editedInformation={changedInformationName}
              />
            </div>

          </div>
          :
          <div>Select an account to begin</div>
        }
      </Panel>
    )
  }
}
export default (
  requiresAccountWhiteLabels(
    requiresWazeCompanies(
      connect(mapStateToProps,mapDispatchToProps,mergeProps)(
        PermissionWrapper
      )
    )
  )
)
