import styles                        from "./MarketingEventIdentityStyles"
import * as React                    from "react"
import IdentitySetup                 from "./IdentitySetup"
import BoundariesSetup               from "./BoundariesSetup"
import Vehicle                       from "../../../model/Store/Vehicle/Vehicle"
import {
  MakesRow,
  MakeModelsRow,
  ReferenceRow
}                                    from "../../../model/Store/Cleaner"
import {
  ProtoMarketingEvent,
  MarketingEventBoundariesTypes
}                                    from "../../../model/adbuilder/"
import {
  Translate,
  LocalizeContextProps,
  withLocalize
}                                    from "react-localize-redux"
import {Button}                      from "@material-ui/core"
import {Tooltip}                     from "react-tippy"
import PriorityHighIcon              from "@material-ui/icons/PriorityHigh"
import {HeadstoneActiveStrategies}   from "../../../model/Store/Strategy/Strategy"

interface MarketingEventIdentityProps extends LocalizeContextProps{
  event              : ProtoMarketingEvent
  updateTitle        : (title:string) => void
  updateGroup        : (state:string|undefined, index:number) => void
  updateLang         : (lang:string) => void
  updateStartDate    : (date:Date) => void
  updateEndDate      : (date:Date) => void
  updateYear         : (year:number|undefined, index:number) => void
  updateMake         : (make:string|undefined, index:number) => void
  updateModel        : (model:string|undefined, index:number) => void
  updateCampaignName : (index:number, campaignName?:string, campaignGroup?:string) => void
  cleanerReference   : ReferenceRow[]
  cleanerMakes       : MakesRow[]
  cleanerMakeModels  : MakeModelsRow[]
  matchingInventory  : Vehicle[]
  goToEditor         : (isInventory:boolean) => void
  cancel             : () => void
  addBoundary        : (type?:MarketingEventBoundariesTypes) => void
  removeBoundary     : (index:number) => void
  activeStrategies   : HeadstoneActiveStrategies
}

const MarketingEventIdentity = (props:MarketingEventIdentityProps) => {
  const classes = styles()
  const [fieldsErrors, setFieldsErrors] = React.useState<string[]>([])
  const isIndentitySetup = ():boolean => {
    const errors = []
    if(props.event.title === undefined || props.event.title.length === 0){
      errors.push("adBuilder.createEvent.errors.titleRequired")
    }
    else if(props.event.title.length < 4){
      errors.push("adBuilder.createEvent.errors.title4Char")
    }
    else if(props.event.title.length > 128){
      errors.push("adBuilder.createEvent.errors.title128Char")
    }
    if(props.event.boundaries[0].type !== MarketingEventBoundariesTypes.NON_INVENTORY 
      && (props.event.boundaries[0].vehicleState === undefined && props.event.boundaries[0].campaignGroup === undefined)
    ){
      errors.push("adBuilder.createEvent.errors.state")
    }
    if(props.event.startDate === undefined){
      errors.push("adBuilder.createEvent.errors.startDate")
    }
    if(props.event.endDate === undefined){
      errors.push("adBuilder.createEvent.errors.endDate")
    }
    updateFieldsErrors(errors)
    return errors.length === 0
  }
  const updateFieldsErrors = (errors:string[]) => {
    if(fieldsErrors.length !== errors.length){
      setFieldsErrors(errors)
    }
    else{
      for(let i = 0; i < errors.length; i++){
        if(errors[i] != fieldsErrors[i]){
          setFieldsErrors(errors)
          break
        }
      }
    }
  }
  return (
    <div>
      <IdentitySetup
        event={props.event}
        setLang={props.updateLang}
        setTitle={props.updateTitle}
        setGroup={props.updateGroup}
        setStart={props.updateStartDate}
        setEnd={props.updateEndDate}
        activeStrategies={props.activeStrategies}
      />
      <BoundariesSetup
        event={props.event}
        setYear={props.updateYear}
        setMake={props.updateMake}
        setModel={props.updateModel}
        setCampaignName={props.updateCampaignName}
        cleanerReference={props.cleanerReference}
        makes={props.cleanerMakes}
        makeModels={props.cleanerMakeModels}
        matchingInventory={props.matchingInventory}
        addBoundary={props.addBoundary}
        removeBoundary={props.removeBoundary}
      />
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          onClick={()=>props.goToEditor(Boolean(props.event.boundaries[0]?.vehicleState))}
          style={{color:'white'}}
          variant="contained"
          size="large"
          disabled={!isIndentitySetup()}
        >
          <Translate id="adBuilder.createEvent.nextStep" />
        </Button>
        {fieldsErrors.length > 0 &&
          <Tooltip
            className={classes.tooltip}
            html={(
              <div className={classes.errors}>
                {fieldsErrors.map((x,i)=>
                  <div key={i}>
                    - {props.translate(x)}
                  </div>
                )}
              </div>
            )}
            delay={[450,0]}
            position="top"
            followCursor={false}
          >
            <PriorityHighIcon className={classes.icon}>priority_high</PriorityHighIcon>
          </Tooltip>
        }
        <Button
          onClick={props.cancel}
          variant="outlined"
          size="large"
          color="secondary"
        >
          <Translate id="common.cancel" />
        </Button>
      </div>
    </div>
  )
}

export default withLocalize(MarketingEventIdentity)
