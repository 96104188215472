import './CreateKeywords.css'
import * as React            from 'react'
import {
    Card,
    Typography,
    Icon,
    Divider
}                            from '@material-ui/core'
import { newAdGroupsByLang } from '../../../model/branding/newAdGroup'
import {
  withLocalize,
  TranslateFunction,
  Translate
}                            from 'react-localize-redux'
import KeywordsInput         from '../../../components/keywordsInput/KeywordsInput'
import KeywordsUtils         from '../../../utils/KeywordsUtils'
import { BRANDING_TAGS }     from '../../../model/constant/KeywordsTags'

interface CreateKeywordsOwnProps {
  selectedLanguages        : string[]
  updateNewAdGroupKeywords : (lang:string,keywords:string[],adGroupName:string) => void
  adGroupName              : string
  newAdGroup               : newAdGroupsByLang
  setKeywordsError         : (boolean) => void
}
interface CreateKeywordsProps extends CreateKeywordsOwnProps {
  translate : TranslateFunction
}

const CreateKeywords = (props:CreateKeywordsProps) => {
  const [keywordsState,setKeywordsState] = React.useState({EN:props.newAdGroup['EN'].keywordPatterns,FR:props.newAdGroup['FR'].keywordPatterns})
  function setKeywords(text:string,lang:string) {
    const temp = text.split('\n')
    setKeywordsState(prevState=>{
      return {...prevState,[lang]:temp}
    })
    const toReduxFormat = text.split('\n').map(keyword=>keyword.trim()).filter(keyword=>keyword)
    props.updateNewAdGroupKeywords(lang,toReduxFormat,props.adGroupName)
  }
  function resetKeywords(lang) {
    props.updateNewAdGroupKeywords(lang,[],props.adGroupName)
    setKeywordsState(prevState=>{
      return {...prevState,[lang]:[]}
    })
  }

  const errors = props.selectedLanguages.reduce((acc,lang)=>{
    return {...acc, [lang] : KeywordsUtils.positiveKeywordsError(keywordsState[lang].map(keyword=>keyword.trim()).filter(keyword=>keyword),BRANDING_TAGS)}
  },{})
  let error = false
  for (let lang of props.selectedLanguages) {
    if(errors[lang].length !== 0) {
      error = true
      break
    }
  }
  props.setKeywordsError(error)

  const availableLanguages = {
    EN : 'english',
    FR : 'french'
  }

  return (
    <Card className="CreateKeywords">
      <Typography style={{display:'flex',alignItems:'center',marginBottom:10}} variant="subtitle2">
        <Icon style={{marginRight:5}}>search</Icon>
        <Translate id="branding.creation.chooseKeywords" />
      </Typography>
      <Divider />
      <div className="Edit">
        {props.selectedLanguages.map((lang)=>{
          return (
            <div key={lang} className="KeywordsInputContainer" >
              <KeywordsInput
                type="positive"
                functionnality='creation'
                title={props.translate(`common.${availableLanguages[lang]}`) as string}
                description={false}
                errors={errors[lang]}
                originalKeywords={[]}
                keywords={keywordsState[lang]}
                handleInput={(keywords)=>setKeywords(keywords,lang)}
                resetFunction={()=>resetKeywords(lang)}
                translateErrors
              />
            </div>
          )
        })}
      </div>
    </Card>
  )
}

export default withLocalize(CreateKeywords as any) as React.ComponentType<CreateKeywordsOwnProps>
