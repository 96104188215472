import './BoostShapeGraph.css'
import * as React                from 'react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  Label
}                                from 'recharts'
import {
  withLocalize,
  LocalizeContextProps
}                                from 'react-localize-redux'


interface BoostShapeSelectorOwnProps extends LocalizeContextProps {
  mutiplier : number
  duration  : number
  selected  : "FLAT" | "LINEAR"
}
interface BoostShapeSelectorProps extends BoostShapeSelectorOwnProps {}

const BoostShapeSelector = (props:BoostShapeSelectorProps) => {
  const localeString = props.activeLanguage.code === "fr" ? "fr-CA" : "en-CA"

  const linear = (boostRatio, boostLength, dayOfMonth, baseline) => {
    if(boostLength === 0 || dayOfMonth > boostLength){return baseline}
    const boosted = (boostRatio - 1) * 2 * ((boostLength + 1 - dayOfMonth)/boostLength) * baseline + baseline
    return boosted
  }
  const flat = (boostRatio, boostLength, dayOfMonth, baseline) => {
    if(boostLength === 0 || dayOfMonth > boostLength){return baseline}
    return baseline*boostRatio
  }
  const accurateData = []
  if(props.selected === 'FLAT') {
    for (let day = 1;day<=props.duration+5;day++) {
      accurateData.push(
        {
          name:day,
          flat: [100, flat(props.mutiplier,props.duration,day,100)],
        }
      )
    }
  } else {
    for (let day = 1;day<=props.duration+5;day++) {
      accurateData.push(
        {
          name:day,
          linear: [100,linear(props.mutiplier,props.duration,day,100)],
        }
      )
    }
  }

  return (
    <div className="BoostShapeSelector">
      <AreaChart width={500} height={300} data={accurateData}>
        <XAxis dataKey="name"/>
        <YAxis  domain={[100, Math.round((props.mutiplier-1)*3*100+100)]}>
          <Label value="%" position="insideTopLeft" />
        </YAxis>
        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
        <Area
          type="stepAfter"
          dataKey="linear"
        />
        <Area
          type="stepAfter"
          dataKey="flat"
        />
        <Tooltip
          labelFormatter={(value) => props.translate('common.day').toString() + " " + value }
          // Column data takes the form [100, 120]
          formatter={(value) => [value[1].toFixed(0) + "%"]} // Must be an array or Recharts will insert some extras
        />
      </AreaChart>
    </div>
  )
}

export default withLocalize(BoostShapeSelector)
