import styles                       from "./AdPreviewStyles"
import * as React                   from "react"
import {MarketingEvent}             from "../../../model/adbuilder/index"
import {Translate}                  from "react-localize-redux"
import SimpleRSAAdCopy              from "./SimpleRSAAdCopy"
import {
  Popover,
  Button
}                                   from "@material-ui/core"

interface AdPreviewProps{
  event : MarketingEvent
}

interface StatusState {
  class: string
  translation: string
}

const AdPreview = (props:AdPreviewProps) => {
  const [anchor, setAnchor] = React.useState<HTMLAnchorElement | null>(null)
  const adCopiesToDisplay = props.event.rsaApproaches[0].patterns
  const classes = styles()
  return (
    <div>
      <a className={classes.a} onClick={(e)=>setAnchor(e.currentTarget)}>
        <Translate id="adBuilder.eventOverview.adPreview" />
      </a>
      <Popover
        open={Boolean(anchor)}
        onClose={()=>setAnchor(null)}
        anchorEl={anchor}
      >
        <div className={classes.popover}>
          <Button className={classes.button} onClick={()=>setAnchor(null)}>
            X
          </Button>
          <div className={classes.adcopies}>
            {adCopiesToDisplay.map(x=>{
              return (
                <SimpleRSAAdCopy key={x.id} headlines={x.headlines} descriptions={x.descriptions} url="www.google.com" />
              )
            })}
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default AdPreview
