import * as React         from "react"
import {withLocalize}     from "react-localize-redux"
import UserConnected      from "../../../model/Store/Login/UserConnected"
import UserLevel          from "../../../components/user/UserLevel"
import KeyValueTable      from "../../../components/table/KeyValueTable"

interface ProfileViewProps{
  user       : UserConnected
  translate ?: (translateId:string)=>string
}
const ProfileViewer = (props:ProfileViewProps) => {
  const struct = {
    [props.translate("profile.id")]          : props.user.id,
    [props.translate("profile.email")]       : <a href={"mailto:"+props.user.email}>{props.user.email}</a>,
    [props.translate("profile.userLevel")]   : <UserLevel level={props.user.userLevel} />,
    [props.translate("profile.officePhone")] : props.user.officePhone,
    [props.translate("profile.cellPhone")]   : props.user.cellPhone,
  }
  return(
    <div>
      <KeyValueTable
        title={props.user.firstName+" "+props.user.lastName}
        keyValue={struct}
      />
    </div>
  )
}

export default withLocalize(ProfileViewer as any) as any