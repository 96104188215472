import * as React         from 'react'
import {
  Chip,
  Icon,
  Tooltip,
}                         from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                                          from "react-localize-redux"

interface LangChipProps extends LocalizeContextProps {
  lang          : string
  inventoryLang : string
}
const LangChip = (props:LangChipProps) => {
  const label = (props.lang === props.inventoryLang?"":"* ") + props.translate("feeds.language-lookup."+props.lang)
  const tip   = (
    props.lang === props.inventoryLang
    ? <p>{props.translate("feeds.tooltip.language")}</p>
    : (
      <ul>
        <li><p>{props.translate("feeds.tooltip.language")}</p></li>
        <li><p>{
          props.translate("feeds.tooltip.language-inv-lang-diff", {
            inventoryLang : props.translate("feeds.tooltip.language-inv-lang-diff-lookup."+props.inventoryLang),
          })
        }</p></li>
      </ul>
    )
  )
  return <Tooltip placement="top" title={tip}><Chip color="primary" variant="outlined" label={label}/></Tooltip>
}
export default withLocalize(LangChip)
