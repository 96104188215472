import * as React                   from "react"
import {withLocalize, Translate}    from "react-localize-redux"
import ArrayUtils                   from "../../../../utils/ArrayUtils"

interface StrategyFiltersProps{
  strategies     : any[]
  selected       : any[]
  toggleStrategy : (strategy:any)=>void
  translate      : (translateId:string)=>string
}
const StrategyFilters = (props:StrategyFiltersProps) => {
  const selectedStrategyNames = props.selected.map(x=>x.name)
  return (
    <div className="StrategyFilters">
      <Translate id="dashboard.mapSpend.strategies"/>
      {props.strategies.map(strategy=>
        <div key={strategy.name}>
          <input
            type="checkbox"
            checked={ArrayUtils.contain(selectedStrategyNames, strategy.name)}
            onChange={()=>props.toggleStrategy(strategy)}
          />
          &nbsp;
          {props.translate("common."+strategy.translationId)}
        </div>
      )}
    </div>
  )
}

export default withLocalize(StrategyFilters as any) as any
