import ReducerUtils       from "../../../utils/ReducerUtils"
import Actions            from "../../../model/constant/actions"
import {StringMap}        from "../../../model/generics"
import {Alert}            from "../../../model/alerting/Alert"
import {PacyV0UsageAlert} from "../../../model/alerting/PacyV0UsageAlert"
import {AlertType}        from "../../../model/constant/AlertType"

const saveV0Usage = (state:State, action):State => {
  return {
    ...state,
    [AlertType.PACY_V0USAGE] : {
      name: AlertType.PACY_V0USAGE,
      count: action?.v0Usage[0]?.provider.length ?? 0,
      data: action?.v0Usage[0]?.provider ?? []
    } as PacyV0UsageAlert,
  }
}

type State = StringMap<Alert>
const Retrieved = ReducerUtils.createReducer<State>({},{
  [Actions.RETRIEVE_PACY_V0USAGE] : saveV0Usage,
})

export default Retrieved
