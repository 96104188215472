import {createSelector}  from "reselect"
import * as Generic      from "./Generic"
import moment            from "moment"
import {ProxyGroup}      from "../model/pacy/ProxyGroup"
import {StringMap}       from "../model/generics"
import BudgetStats       from "../model/Store/Statistics/BudgetStats"

interface GoogleStats {byDay: StringMap<BudgetStats[]>}

export default class PacyAccessors {
  public static makeStatsForProxyGroupByMonthSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getBudgetsStats,
      Generic.getBingBudgetsStats,
      Generic.getFacebookBudgetsStats,
      (_,props)=>props.proxyGroup,
      (accountId:number, googleStats:GoogleStats, bingStats:StringMap<BudgetStats[]>, facebookStats:StringMap<BudgetStats[]>, proxyGroup:ProxyGroup)=>{
        const budgetIdsMap = proxyGroup.proxies.reduce((map,proxy)=>{
          for(const agent of proxy.agents){
            map[agent.providerId] = 1
          }
          return map
        },{})
        return [...googleStats[accountId]?.byDay ?? [], ...bingStats[accountId] ?? [], ...facebookStats[accountId] ?? []]
        .filter((x:BudgetStats)=>budgetIdsMap[x.BudgetID] !== undefined)
        .reduce((byMonth, stat:BudgetStats)=>{
          const month = moment(stat.Day).format("YYYY-MM")
          if(!byMonth[month]){byMonth[month] = []}
          byMonth[month].push(stat)
          return byMonth
        },{})
      }
    )
  }
  public static makeStatsByMonthByProxyGroupSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getBudgetsStats,
      Generic.getBingBudgetsStats,
      Generic.getFacebookBudgetsStats,
      (_,props)=>props.proxyGroups,
      (accountId:number, googleStats:GoogleStats, bingStats:StringMap<BudgetStats[]>, facebookStats:StringMap<BudgetStats[]>, proxyGroups:ProxyGroup[])=>{
        return proxyGroups.reduce((byProxyGroup, proxyGroup)=>{
          const budgetIdsMap = {}
          for(const proxy of proxyGroup.proxies){
            for(const agent of proxy.agents){
              budgetIdsMap[agent.providerId] = 1
            }
          }
          byProxyGroup[proxyGroup.id] = (
            [...googleStats[accountId]?.byDay ?? [], ...bingStats[accountId] ?? [], ...facebookStats[accountId] ?? []]
            .filter((x:BudgetStats)=>budgetIdsMap[x.BudgetID] !== undefined)
            .reduce((byMonth, stat:BudgetStats)=>{
              const month = moment(stat.Day).format("YYYY-MM")
              if(!byMonth[month]){byMonth[month] = []}
              byMonth[month].push(stat)
              return byMonth
            },{})
          )
          return byProxyGroup
        },{})
      }
    )
  }

  public static makeIsGuidelineSavingForProxyGroup(){
    return createSelector(
      (state:any)=>state.Pacy.Save.Guideline,
      (_,props)=>props.proxyGroup.id,
      (guidelineSaveStatus, proxyGroupId:string)=>{
        return (guidelineSaveStatus.status && guidelineSaveStatus.proxyGroupId === proxyGroupId)
      }
    )
  }
}
