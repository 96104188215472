import Column                         from "./Column"
import ColumnDefinition               from "./ColumnDefinition"
import SimplifiedColumn               from "./SimplifiedColumn"
import AgglomeratedStats              from "./AgglomeratedStats"
import ArrayUtils                     from "../../../utils/ArrayUtils"
import ReportTableTemplateUtils       from "../../../utils/ReportTableTemplateUtils"
import {Provider}                     from "../../Provider"

export default abstract class ReportTableTemplate{
  protected abstract context : string
  protected abstract KEY_COLUMN : string
  protected abstract DEFAULT_COLUMNS : ColumnDefinition[]
  protected id           : string
  protected name         : string
  protected columns      : ColumnDefinition[]
  protected options      : any
  protected sizePerPage  : number = 10
  protected revisionOf   : number = undefined
  protected provider     : Provider
  private isOrdered      : boolean = false

  constructor(id:string, name:string, options:any){
    this.id         = id
    this.name       = name
    this.options    = options
  }
  abstract clone():ReportTableTemplate

  public setId(id:string)                    { this.id = id               }
  public setName(name:string)                { this.name = name           }
  public setProvider(provider:Provider)      { this.provider = provider   }
  public setOptions(options:any)             { this.options = options     }
  public setSizePerPage(size:number)         { this.sizePerPage = size    }
  public setRevisionOf(rev:number)           { this.revisionOf = rev      }
  public setColumns(columns:ColumnDefinition[]){
    if(columns.length !== this.columns.length){throw Error("Cannot change the number of columns")}
    if(!this.validateDataFields(columns.map(column=>column.getDataField()))){throw Error("New data fields do not correspond.")}
    this.columns = columns
    this.isOrdered = false
  }
  public setColumnsFromSimplified(simplifiedColumns:SimplifiedColumn[]){
    this.columns = this.DEFAULT_COLUMNS.map(column=>{
      let generatedColumn = column.clone()
      for(let simple of simplifiedColumns){
        if(simple.dataField === generatedColumn.getDataField()){
          if(generatedColumn.getFilterType()==="SELECT"){
            generatedColumn.setFilterOptions({
              ...simple.filterOptions,
              options : generatedColumn.getFilterOptions().options,
            })
          }
          else{
            generatedColumn.setFilterOptions(simple.filterOptions)
          }
          generatedColumn.setHidden(false)
          generatedColumn.setOrderIndex(simple.orderIndex)
          return generatedColumn
        }
      }
      generatedColumn.setHidden(true)
      return generatedColumn
    })
  }

  protected getDefaultColumns()                  { return this.DEFAULT_COLUMNS }
  public getId()            : string             { return this.id             }
  public getContext()       : string             { return this.context        }
  public getName()          : string             { return this.name           }
  public getProvider()      : string             { return this.provider       }
  public getKeyField()      : string             { return this.KEY_COLUMN     }
  public getOptions()       : any                { return this.options        }
  public getColumns()       : ColumnDefinition[] { return this.columns        }
  public getSizePerPage()   : number             { return this.sizePerPage    }
  public getRevisionOf()    : number             { return this.revisionOf     }
  public getDefaultSortedColumn() : string       { return this.options.defaultSorted[0].dataField }
  public getDefaultSortedOrder()  : string       { return this.options.defaultSorted[0].order }
  public getSpecificColumn(dataField:string) : ColumnDefinition {
    let column = this.columns.find(column=>column.getDataField()===dataField)
    if(!column){throw Error("Invalid datafield given")}
    return column
  }
  public getOrderedColumns() : ColumnDefinition[]{
    this.orderColumns()
    return this.columns
  }
  public getComputedColumns() : Column[]{
    this.orderColumns()
    return this.columns.map(column=>column.getComputed())
  }
  public toggleColumn(dataField:string){
    let column = this.getSpecificColumn(dataField)
    if(column){column.toggleHidden()}
  }
  //newOrder = all dataField in the wanted order
  public changeOrder(newOrder:string[]){
    if(newOrder.length !== this.columns.length){throw Error("New column order must be for every column")}
    if(!this.validateDataFields(newOrder)){throw Error("Invalid datafield(s) given")}
    newOrder.map((dataField, i)=>{this.getSpecificColumn(dataField).setOrderIndex(i)})
    this.isOrdered = false
  }
  public getDefaultFilters() : {[dataField:string]:any} {
    let defaultFilters = {}
    for(let column of this.columns){
      if(column.getFilterType() === "TEXT"){
        const filterOptions = column.getFilterOptions()
        defaultFilters[column.getDataField()] = filterOptions.defaultValue?filterOptions.defaultValue:""
      }
      else if(column.getFilterType() === "SELECT"){
        const filterOptions = column.getFilterOptions()
        defaultFilters[column.getDataField()] = {
          value : filterOptions.defaultValue?filterOptions.defaultValue:undefined,
          choices : filterOptions.options
        }
      }
    }
    return defaultFilters
  }
  public setColumnDefaultFilterValue(dataField:string, filterValue:string){
    let column = this.getSpecificColumn(dataField)
    column.setFilterOptions({
      ...column.getFilterOptions(),
      defaultValue : filterValue
    })
  }
  public setDefaultSortedColumn(dataField:string){
    if(!this.validateDataFields([dataField])){throw Error("Invalid datafield given")}
    this.options = {
      ...this.options,
      defaultSorted : [{
        ...this.options.defaultSorted[0],
        dataField : dataField,
      }]
    }
  }
  public setDefaultSortedOrder(order:string){
    this.options = {
      ...this.options,
      defaultSorted : [{
        ...this.options.defaultSorted[0],
        order : order,
      }]
    }
  }


  public compileStats(stats:any[]):any[]{
    if(!stats || stats.length === 0){return []}
    return this.final({},
      this.columns
      .map(col=>col.produceAgglomeratingFunction())
      .reduce((a,c)=>c(a),stats)
    )
  }
  protected final (indexes:object,stats:AgglomeratedStats|any[]):any[]{
    if(stats === undefined){return [{...indexes}]}
    if(stats instanceof Array){return [{...indexes,...this.compile(stats)}]}
    var compiled = []
    for(let valueName in stats.values){
      const newIndexes = {...indexes, [stats.key]:valueName}
      compiled = [...compiled,...this.final(newIndexes,stats.values[valueName])]
    }
    return compiled
  }
  protected abstract compile(stats : any[]):any


  private validateDataFields(dataFields:string[]):boolean{
    return ArrayUtils.containAll(this.columns.map(column=>column.getDataField()), dataFields)
  }
  private orderColumns(){
    if(!this.isOrdered){
      this.columns.sort((a,b)=>{
        if(a.getOrderIndex()<b.getOrderIndex()){return -1}
        if(a.getOrderIndex()>b.getOrderIndex()){return 1}
        return 0
      })
      this.isOrdered = true
    }
  }
  public toJSON():string{
    return JSON.stringify({
      context     : this.context,
      id          : this.id,
      name        : this.name,
      provider    : this.provider,
      columns     : this.columns.filter(c=>!c.getHidden()).map(c=>c.getSimplifiedObject()),
      options     : this.options,
      sizePerPage : this.sizePerPage,
    })
  }
  public static fromJSON(json:string):ReportTableTemplate{
    const obj = JSON.parse(json)
    const TemplateClass = ReportTableTemplateUtils.getTemplateClassFromContext(obj.provider.toUpperCase(),obj.context.toUpperCase())
    if(!TemplateClass){throw Error("Invalid context in JSON.")}
    const id = obj.id
    const name = obj.name
    const options = obj.options
    const size = obj.sizePerPage
    let template = new TemplateClass(id, name, options)
    template.setProvider(obj.provider)
    template.setColumnsFromSimplified(obj.columns)
    if(!isNaN(size)){template.setSizePerPage(parseInt(size))}
    return template
  }
}
