import './AdGroups.css'
import * as React                from 'react'
import {
  AdGroupConfigsByLang,
}                                from '../../../model/branding/AdGroupConfigs'
import {
  withLocalize,
  TranslateFunction,
}                                from 'react-localize-redux'
import requiresAWSearchAudiences from '../../../components/loaders/AWSearchAudiencesLoader'
import AWSearch                  from '../../../model/Store/Audiences/AWSearch'
import AdGroupCreator            from '../Creators/AdGroupCreator'
import {withRouter}              from "react-router-dom"
import AdGroupDisplay            from './AdGroupDisplay'


interface AdGroupsOwnProps {
  adGroupConfigsByLang : AdGroupConfigsByLang
  lang                 : string
  setAdGroup           : (adGroup:string) => void
  setAudienceAd        : (audienceAd :string) => void
  handleChangeLang
}
interface AdGroupsProps extends AdGroupsOwnProps {
  translate : TranslateFunction
  AWAudiences : AWSearch[]
  history
}

const AdGroups = (props:AdGroupsProps) => {
  const[creation,setCreation] = React.useState(false)
  function toggleCreationMode(state:boolean){
    setCreation(state)
  }
  return (
    <div>
      {!creation &&
        <AdGroupDisplay
          toggleCreationMode={toggleCreationMode}
          adGroupConfigsByLang={props.adGroupConfigsByLang}
          lang={props.lang}
          handleChangeLang={props.handleChangeLang}
          setAdGroup={props.setAdGroup}
          setAudienceAd={props.setAudienceAd}
        />
      }
      {creation &&
        <AdGroupCreator
          toggleCreationMode={toggleCreationMode}
          allAdGroupsByLang={props.adGroupConfigsByLang}
          awAudiences={props.AWAudiences}
        />
      }
    </div>
  )
}

export default withLocalize(
  requiresAWSearchAudiences(false)(
    withRouter(AdGroups as any) as any)) as React.ComponentType<AdGroupsOwnProps>
