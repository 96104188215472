export default class FieldsValuesDefinitionUtils{

  static decompress(fv:any){
    return fv.values.map((value:any[])=>{
      const obj = {}
      for(let i=0; i<fv.fields.length; i++){
        obj[fv.fields[i]] = value[i]
      }
      return obj
    })
  }
}
