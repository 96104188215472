import './Campaign.css'
import * as React                from "react"
import {connect}                 from 'react-redux'
import {withLocalize, Translate} from "react-localize-redux"
import {withRouter}              from "react-router"
import {createSelector}          from "reselect"
import RoutingUtils              from "../../../../utils/RoutingUtils"
import ObjectUtils               from "../../../../utils/ObjectUtils"
import ArrayUtils                from "../../../../utils/ArrayUtils"
import Friend                    from "../../../../model/Store/Campaign/Friend"
import {Provider}                from "../../../../model/Provider"
import retrieveDesiredStatus     from "../../../../actions/desiredStatusException/retrieveDesiredStatusExceptions"
import {loadCampaign}            from "../../action/Campaign"
import CampaignsAccessors        from "../../../../storeAccessor/Campaigns"
import Overview                  from "./view/Overview/Overview"
import Geo                       from "./view/Geo/Geo"
import AdCopy                    from "./view/AdCopy/AdCopy"
import Hint                      from "./view/Hint/Hint"
import Header                    from './view/Header/Header'
import {Viewer}                  from "../../../../components/Viewer/Viewer"
import ExplainedLoading          from "../../../../components/loading/"
import requiresFriends           from "../../../../components/loaders/friendsLoader"

interface CampaignProps {
  friend                     : Friend
  accountId                  : number
  loadCampaign               : (campaignId:number)=>void
  getDesiredStatusExceptions : ()=>void
  retrievingCampaigns        : boolean
  statusExceptions           : any
  translate                 ?: (any)=>any
  activeLanguage            ?: any
  defaultView               ?: CampaignView
  history
  location
  match
}
type CampaignView = "Overview" | "Geo" | "Adcopy" | "Hint"
const makeMapStateToProps = () => {
  const friendSelector = CampaignsAccessors.makeFindFriendWithIdsSelector()
  const idsSelector = createSelector(
    (_,idsString)=>idsString,
    (idsString)=>idsString.split(",").map(id=>parseInt(id, 10))
  )
  return (state, ownProps) => {
    const accountId =  state.Accounts.selected
    const campaignIds = idsSelector(state,ownProps.campaignIds)
    return {
      accountId   : accountId,
      friend      : friendSelector(state, campaignIds),
      retrievingCampaigns : !state.Campaigns.list[accountId] || state.Campaigns.list[accountId].isRetrieving,
      statusExceptions   : state.Campaigns.DesiredStatusExceptions[accountId],

    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    loadCampaign : (campaignId:number, friend:Friend)=>dispatch(loadCampaign(campaignId, friend)),
    getDesiredStatusExceptions : (accountId:number) => dispatch(retrieveDesiredStatus(accountId)),
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    loadCampaign : (campaignId:number)=>DP.loadCampaign(campaignId, SP.friend),
    getDesiredStatusExceptions : ()=>DP.getDesiredStatusExceptions(SP.accountId),
  }
}
const Campaign:React.SFC<CampaignProps> = (props:CampaignProps) => {
  if(props.retrievingCampaigns){return <ExplainedLoading translateId="loadings.campaigns"/>}
  var message = ""
  if(!props.friend){
    return(
      <div>
        <span>
          <Translate id="campaigns.campaignNotFound"/>
        </span>
        <br/>
        <div className="ImitateLink" onClick={()=>RoutingUtils.removeLastElementOfUrl(props.history)}>
          <Translate id="campaigns.goBackToList"/>
        </div>
      </div>
    )
  }
  var isLoading = false
  for(let campaign of props.friend.getCampaigns()){
    if(campaign.childrenLoading){
      isLoading = true
      continue
    }
    if(!campaign.childrenLoaded && campaign.provider===Provider.AW){
      props.loadCampaign(campaign.id)
      isLoading = true
      continue
    }
  }
  if(isLoading){return <ExplainedLoading translateId="loadings.campaign"/>}
  if(props.statusExceptions === undefined){props.getDesiredStatusExceptions()}
  const views = {
    Overview: {display:props.translate("campaigns.sections.overview"), name:"Overview", component:Overview },
    Geo:      {display:props.translate("campaigns.sections.geo"),      name:"Geo",      component:Geo      },
    Adcopy:   {display:props.translate("campaigns.sections.adCopy"),   name:"Adcopy",   component:AdCopy   },
    Details:  {display:props.translate("campaigns.sections.hint"),     name:"Details",  component:Hint     },
  }
  const formattedDefaultView = props.defaultView[0].toUpperCase() + props.defaultView.slice(1).toLowerCase()
  const updateUrlWithViewName = (viewName:string) => {
    const lastElement = RoutingUtils.getLastElementOfUrl(props.history)
    const viewNames = ObjectUtils.getObjectValues(
      ObjectUtils.mapOnObject(views, (key, value)=>value.name.toLowerCase())
    )
    if(ArrayUtils.contain(viewNames, lastElement.toLowerCase())){
      RoutingUtils.replaceLastElementOfUrl(props.history, viewName)
    }
    else{
      RoutingUtils.appendToUrl(props.history, viewName)
    }
  }
  return (
    <Viewer
      key={props.defaultView}
      object={props.friend}
      header={Header}
      views={views}
      defaultView={formattedDefaultView}
      headerClasses={"FriendViewViewerHeader"}
      onViewChange={updateUrlWithViewName}
    />
  )
}
Campaign.defaultProps = {
  defaultView : "Overview"
}
export default withLocalize(requiresFriends(true)(connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(withRouter(Campaign))))
