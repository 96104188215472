import * as React                    from "react"
import ArrayUtils                    from "../../../utils/ArrayUtils"
import {Select, SelectItem}          from "carbon-components-react"
import ExplainedLoading              from "../../../components/loading/"

interface AccountPreferencesProps{
  accountPreferences : any
  whiteLabels        : string[]
  editedInformation  : string[]
  addSaveAction      : (informationName:string, action:()=>void)=>void
  saveWhiteLabel     : (whiteLabel:string)=>void
  removeWhiteLabel   : ()=>void
}
interface AccountPreferencesState{
  selectedWhiteLabel : string
}
const INFORMATION_NAMES = {
  WHITELABEL : "WhiteLabel"
}
const NO_WHITE_LABEL = "NO_WHITE_LABEL"

class AccountPreferences extends React.Component<AccountPreferencesProps,AccountPreferencesState>{
  constructor(props:AccountPreferencesProps){
    super(props)
    this.state = {
      selectedWhiteLabel : props.accountPreferences && props.accountPreferences.whitelabel ? props.accountPreferences.whitelabel : NO_WHITE_LABEL
    }
    this.onSelectChange = this.onSelectChange.bind(this)
  }
  componentDidUpdate(prevProps:AccountPreferencesProps, prevState:AccountPreferencesState){
    if(this.props.accountPreferences && prevProps.accountPreferences !== this.props.accountPreferences){
      this.setState({
        selectedWhiteLabel : this.props.accountPreferences.whitelabel || NO_WHITE_LABEL
      })
    }
  }
  onSelectChange(event){
    const value = event.target.selectedOptions[0].value
    this.setState({selectedWhiteLabel:value})
    let action
    if(value === NO_WHITE_LABEL){action = ()=>this.props.removeWhiteLabel()}
    else{action = ()=>this.props.saveWhiteLabel(value)}
    this.props.addSaveAction(INFORMATION_NAMES.WHITELABEL, action)
  }
  render(){
    if(!this.props.accountPreferences){return <ExplainedLoading text="Loading preferences"/>}
    const whiteLabels = ([...this.props.whiteLabels].sort())
    return (
      <div className="AccountPreferencesView">
        <div className="TopPart">
          <h4>Preferences</h4>
        </div>
        <hr/>
        <div className="BotPart">
          <span className="Label">White label</span>
          <Select
            onChange={this.onSelectChange}
            className={"WhiteLabelSelect"+(ArrayUtils.contain(this.props.editedInformation, INFORMATION_NAMES.WHITELABEL)?" Edited":"")}
            hideLabel
            id="whiteLabelSelect"
            value={this.state.selectedWhiteLabel}
          >
            <SelectItem
              value={NO_WHITE_LABEL}
              text="None"
            />
              {whiteLabels.map(x=>
                <SelectItem
                  key={x}
                  value={x}
                  text={x}
                />
              )}
          </Select>
        </div>
      </div>
    )
  }
}

export default AccountPreferences
