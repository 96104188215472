import AxiosWrapper from "./AxiosWrapper"
import {stringify} from "querystring"

export interface Campaign {
  id                  : string
  AWId                : string
  lang                : string
  type                : string
  finalUrl            : string
  name                : string
  status              : string
  GeoTargetingType    : string
  TrackingUrlTemplate : string
  vehicleState        : string
  vehicleType         : string
  vehicles            : string
}
export type GetCampaignsReturn = {
  data : {
    campaigns : Campaign[],
    nextPage ?: number
  }
  error : any
}
export default class Keystone extends AxiosWrapper {
  protected static getApiUrl(){
    return process.env.REACT_APP_KEYSTONE_URL + '/keystone/index.php'
  }
}
