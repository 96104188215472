import './DualAxisGraph.css'
import * as React   from "react"
import * as d3      from "d3"
import d3Tip        from "d3-tip"
import Line         from "./Line"
import Axis         from "./Axis"
import NumFormatter from '../../utils/NumberFormatter'


export default class DualAxisGraph extends React.Component<any,any> {

  private node
  private tip
  private color
  private scaling

  constructor(props){
    super(props)
    this.state = {
      ready:false,
      size: {},
      margin:{top: 20, right: 80, bottom: 30, left: 50},
    }
    this.scaling = {}
    let formatTime = d3.timeFormat("%Y-%m-%d")
    this.tip = d3Tip().attr('class', 'graphTip')
      .offset([-10, 0])
      .html(d=> {
        const yValue = parseFloat(d.yValue)
        return `
          <div>
            <p><strong>${d.line}</strong></p>
            <p>${formatTime(d.xValue)}</p>
            <p>${NumFormatter.formatNumber(yValue)}</p>
          </div>
        `
    })
    this.resize = this.resize.bind(this)
    this.updateState = this.updateState.bind(this)
    this.getNodeSize = this.getNodeSize.bind(this)
  }
  getNodeSize() {
    return {
      width  : d3.select(this.node).node().getBoundingClientRect().width - this.state.margin.left - this.state.margin.right,
      height : d3.select(this.node).node().getBoundingClientRect().height - this.state.margin.top - this.state.margin.bottom
    }
  }
  getScaling(size) {
    return {
      x  : d3.scaleTime().range([0, size.width]),
      y1 : d3.scaleLinear().range([size.height, 0]),
      y2 : d3.scaleLinear().range([size.height, 0]),
    }
  }
  resize() {
    let size = this.getNodeSize()
    if(size !== this.state.size){
      this.scaling = this.getScaling(size)
      this.setState({size: size})
    }
  }
  componentDidMount(){window.addEventListener('resize',this.resize);}
  componentWillUnmount(){window.removeEventListener('resize',this.resize)}
  updateState(node){
    this.node = node || this.node
    let size = this.getNodeSize()
    this.setState({
      size  : size,
      ready : true
    })
    this.scaling = this.getScaling(size)
    return this.node
  }
  render(){
    if(!this.state.ready){
      return <svg width={"100%"} height={"100%"} ref={node => {this.updateState(node)}} />
    }
    let props = this.props
    d3.selectAll(".graphTip").remove()
    d3.select(this.node).call(this.tip)
    let flatData = [...props.primary.data,...props.secondary.data]
    let scalingX = this.scaling.x.domain(d3.extent(flatData,d=>d.xValue))
    let scalingY1 = this.scaling.y1.domain([0,d3.max(props.primary.data.map(d=>d.yValue))])
    let scalingY2 = this.scaling.y2.domain([0,d3.max(props.secondary.data.map(d=>d.yValue))])
    return(
      <svg width={"100%"} height={"100%"} >
        <g transform={`translate(${this.state.margin.left},${this.state.margin.top})`}>
          <Axis
            axisLeft={{scale:scalingY1}}
            axisRight={{scale:scalingY2}}
            axisBottom={{scale:scalingX}}
            size={this.state.size}
          />
          <Line
            key={"primary"}
            data={props.primary.data}
            scalingX={scalingX}
            scalingY={scalingY1}
            color={props.primary.color}
            tip={this.tip}
          />
          <Line
            key={"secondary"}
            data={props.secondary.data}
            scalingX={scalingX}
            scalingY={scalingY2}
            color={props.secondary.color}
            tip={this.tip}
          />
        </g>
      </svg>
    )
  }
}
