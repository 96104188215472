import Actions      from "../../model/constant/actions"
import ReducerUtils from "../../utils/ReducerUtils"

const createReducer = ReducerUtils.createReducer
const addAccountIdToList = (state, action) => {
  let accountId = action.accountId
  if(state.indexOf(accountId) === -1){return [...state, accountId]}
  return state
}
export const FetchedAccounts = createReducer<number[]>([],{
  [Actions.RETRIEVE_CAMPAIGNS_BY_PAGE_FOR_ACCOUNTID] : addAccountIdToList
})
