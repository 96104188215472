import Keystone,{
  Campaign as KeystoneCampaign
}                                      from "../../utils/Keystone"
import Brimstone, {
  Campaign as BrimstoneCampaign
}                                      from "../../utils/Brimstone"
import Actions                         from "../../model/constant/actions"
import {findVehicleState}              from "../../model/constant/VehicleState"
import Friend                          from "../../model/Store/Campaign/Friend"
import InventoryFriend                 from "../../model/Store/Campaign/InventoryFriend"
import SearchCampaign                  from "../../model/Store/Campaign/SearchCampaign"
import {getToken}                      from "../../storeAccessor/Generic"
import {Provider}                      from "../../model/Provider"

export default (accountId:number) => async (dispatch, getState) => {
  const token = getToken(getState())
  dispatch({
    type   : Actions.RETRIEVING_CAMPAIGNS,
    status : true
  })
  try{
    const google = await loadAWCampaigns(token,accountId)
    const bing   = await loadBingCampaigns(token,accountId)
    dispatch(loadCampaignsByPageForAccountSuccess(accountId,google,bing))
  }
  catch(e){
    console.log("ERROR",e)
    dispatch(loadCampaignsByPageForAccountError(accountId))
  }
  dispatch({
    type   : Actions.RETRIEVING_CAMPAIGNS,
    status : false
  })
}
const loadAWCampaigns = async (token:string, accountId:number) : Promise<KeystoneCampaign[]> => {
  let page = 0
  let loadedCampaigns = []
  while(true){
    let data = await Keystone.get({
      token     : token,
      accountid : accountId,
      action    : "IDB2GetCampaigns",
      page      : page,
    })
    .then(
      (response) => response.data
    )
    loadedCampaigns.push(...data.campaigns)
    if(data.nextPage===undefined || data.nextPage===page){break}
    page = parseInt(data.nextPage+"",10)
  }
  return loadedCampaigns
}
const loadBingCampaigns = async (token:string, accountId:number) : Promise<BrimstoneCampaign[]> => {
  let page = 0
  let loadedCampaigns = []
  while(true){
    let data = await loadBingCampaignsForPage(token,accountId,page)
    loadedCampaigns.push(...data.campaigns)
    if(data.nextPage === undefined || !Number.isInteger(data.nextPage)){
      break
    }
    page++
  }
  return loadedCampaigns
}
const loadBingCampaignsForPage = (token:string, accountId:number, pageNumber:number) => {
  return Brimstone.getCampaigns(token,accountId,pageNumber)
  .then(
    (response) => response.data
  )
}
const loadCampaignsByPageForAccountSuccess = (
  accountId:number,
  awCampaigns:any[],
  bingCampaigns:any[],
) => {
  const aw = awCampaigns.map(campaign=>{
    return {
      ...campaign,
      accountId,
      id                    : parseInt(campaign.id,10),
      externalCampaignId    : parseInt(campaign.AWId,10),
      provider              : Provider.AW,
      childrenLoaded        : false,
      childrenLoading       : false,
      childrenLoadingFailed : false,
      adGroups              : undefined,
      siteLinks             : undefined,
    }
  })
  const bing = bingCampaigns.map(campaign=>{
    return {
      ...campaign,
      accountId,
      externalCampaignId    : parseInt(campaign.bingId,10),
      childrenLoaded        : false,
      childrenLoading       : false,
      childrenLoadingFailed : false,
      adGroups              : undefined,
      siteLinks             : undefined,
      provider              : Provider.BING,
    }
  })
  const friends = groupCampaigns(aw,bing)
  return {
    type           : Actions.RETRIEVE_CAMPAIGNS_BY_PAGE_FOR_ACCOUNTID,
    accountId      : accountId,
    friends        : friends,
    allLangFriends : friends,
  }
}
const loadCampaignsByPageForAccountError = (accountId:number) => {
  return {
    type           : Actions.RETRIEVE_CAMPAIGNS_BY_PAGE_FOR_ACCOUNTID,
    accountId      : accountId,
    friends        : [],
    allLangFriends : [],
  }
}
const groupCampaigns = (awCampaigns:SearchCampaign[],bingCampaigns:SearchCampaign[]):Friend[] => {
  let friends = []
  for(let campaign of awCampaigns ){
    const reg = new RegExp(`(\\s\\||\\|)|(^|\\s)${campaign.lang}($|\\s)`,"g")
    let campaignName = campaign.name.replace(reg,"")
    let found = false
    for (let friend of friends){
      if(friend.getName() === campaignName){
        friend.addCampaign(campaign)
        found = true
        break
      }
    }
    if(!found){
      if(campaign.vehicleState){
        friends.push(new InventoryFriend(
          campaign.accountId,
          [campaign],
          findVehicleState(campaign.vehicleState.toString())
        ))
      }
      else{
        friends.push(new Friend(campaign.accountId,[campaign]))
      }
    }
  }
  for(let campaign of bingCampaigns){
    const reg = new RegExp(`(\\s\\||\\|)|(^|\\s)${campaign.lang}($|\\s)`,"g")
    let campaignName = campaign.name.replace(reg,"")
    for (let friend of friends){
      if(friend.getName() === campaignName){
        friend.addCampaign(campaign)
        break
      }
    }
  }
  return friends
}
