import "./ProxyGroupsList.css"
import * as React              from "react"
import {
  ProxyGroup
}                              from "../../../../model/pacy/ProxyGroup"
import Period                  from "../../../../model/Period"
import {StringMap}             from "../../../../model/generics"
import BudgetStats             from "../../../../model/Store/Statistics/BudgetStats"
import ProxyGroupDisplay       from "./ProxyGroup"

interface ProxyGroupsListProps{
  proxyGroups              : ProxyGroup[]
  idToColorMapping         : StringMap<string>
  requestReportingDataLoad : (period:Period)=>boolean
  statsByMonthByProxyGroup : StringMap<StringMap<BudgetStats[]>>
  readOnly                ?: boolean
}

const ProxyGroupsList = (props:ProxyGroupsListProps) => {
  return (
    <div className="ProxyGroupsList">
      {props.proxyGroups.map((x,i)=>
        <div key={x.id} className="ProxyGroupDisplay">
          <ProxyGroupDisplay
            proxyGroup={x}
            requestReportingDataLoad={props.requestReportingDataLoad}
            budgetStatsByMonth={props.statsByMonthByProxyGroup[x.id]}
            color={props.idToColorMapping[x.id]}
            readOnly={props.readOnly}
          />
          {i<props.proxyGroups.length-1 &&
            <div className="Separator"><hr/></div>
          }
        </div>
      )}
    </div>
  )
}

export default ProxyGroupsList
