import * as React                       from "react"
import {connect}                        from "react-redux"
import {retrievePossibleWhiteLabels}    from "../../actions/account/accountPreferences"
import ExplainedLoading                 from "../loading"
import ErrorBoundary                    from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  return {
    whiteLabels : state.WhiteLabels.list,
    retrieving  : state.WhiteLabels.retrieving
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadPossibleWhiteLabels : ()=>dispatch(retrievePossibleWhiteLabels())
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
const PossibleAccountWhiteLabelsLoader = props => {
  if(props.whiteLabels === null){
    if(!props.retrieving){props.loadPossibleWhiteLabels()}
    return <ExplainedLoading translateId="loadings.whiteLabels"/>
  }
  return <ErrorBoundary>{props.render(props.whiteLabels)}</ErrorBoundary>
}
const PossibleAccountWhiteLabelsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(PossibleAccountWhiteLabelsLoader)
const requiresPossibleAccountWhiteLabels = Component => props => (
  <PossibleAccountWhiteLabelsLoaderConnected
    render={ (whiteLabels) => <Component accountWhiteLabels={whiteLabels} {...props}/> }
  />
)
export default requiresPossibleAccountWhiteLabels

