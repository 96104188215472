import './MissedOpportunities.css'
import * as React                from "react"
import {Translate}               from "react-localize-redux"
import BootstrapTable            from 'react-bootstrap-table-next'
import filterFactory             from 'react-bootstrap-table2-filter'
import paginationFactory         from 'react-bootstrap-table2-paginator'
import InventoryFriend           from "../../../../model/Store/Campaign/InventoryFriend"
import ArrayUtils                from "../../../../utils/ArrayUtils"
import ObjectUtils               from "../../../../utils/ObjectUtils"
import StatisticsUtils           from "../../../../utils/StatisticsUtils"
import ExplainedLoading          from '../../../../components/loading'
import Icon                      from "../HighlightIcon"
import {Provider}                from "../../../../model/Provider"
import NumFormatter              from '../../../../utils/NumberFormatter'

interface MissedOpportunitiesProps{
  campaigns     : any[]
  campaignStats : any[]
  highlighted  ?: any[]
}
interface VehicleData{
  id       : number
  name     : string
  impShare : number
}

const compileData = (campaigns, campaignStats, highlighted:any[]):VehicleData[] => {
  let vehicles = []
  let marketAverage
  const friends = campaigns.filter(x=>x instanceof InventoryFriend)
  let statsByVehicle = campaignStats
  .reduce((byVehicle,stat)=>{
    for(let friend of friends){
      if((
        stat.Provider === Provider.AW &&
         ArrayUtils.contain(friend.getIds(),stat.GenericId)
      ) || (
         stat.Provider === Provider.BING &&
         ArrayUtils.contain(friend.getExternalIds(Provider.BING),stat.CampaignBingId)
      )){
        for(let vehicle of friend.getInventory()){
          const YMM = (
            vehicle.getYear() != 0
              ? [vehicle.getYear(), vehicle.getMake(), vehicle.getModel()]
              : [vehicle.getMake(), vehicle.getModel()]
          ).join(" ")
          if(!byVehicle[YMM]){byVehicle[YMM] = []}
          byVehicle[YMM].push(stat)
        }
        break
      }
    }
    return byVehicle
  },{})
  if(Object.keys(statsByVehicle).length > 0){
    vehicles = ObjectUtils.getObjectValues(
      ObjectUtils.mapOnObject(
        statsByVehicle,
        (id,value)=>{
          const impShareSet = (
            value
            .filter(x=>x.SearchImpressionShare!==null)
            .map(x=>[x.Impressions,x.SearchImpressionShare])
          )
          const impShare = StatisticsUtils.calculateImpressionShare(impShareSet)
          return {
            id,
            name : id,
            impShare,
            highlighted : ArrayUtils.contain(highlighted, id)
          }
        }
      )
    )
    .filter(x=>x.impShare && x.impShare<90)
    .sort((a,b)=>ArrayUtils.comp(a.impShare,b.impShare))
  }
  return vehicles
}
const headerTranslator = (col, _, components)=>(
  <div>
    <Translate id={col.text}/>
    {components.sortElement}
    {components.filterElement}
  </div>
)
const MissedOpportunities = React.forwardRef((props:MissedOpportunitiesProps, ref) => {
  if(!props.campaignStats){return <ExplainedLoading translateId="loadings.stats"/>}
  if(!props.campaigns){return <ExplainedLoading translateId="loadings.campaigns"/>}
  const vehicles = compileData(props.campaigns, props.campaignStats, props.highlighted||[])
  if(vehicles.length){
    return (
      <div className={"MissedOpportunities"}>
        <BootstrapTable
          ref={ref}
          key={vehicles.length} // remount if new data comes in
          keyField='id'
          data={vehicles}
          pagination={paginationFactory({
            sizePerPage : 5,//000,
            hideSizePerPage : true,
            hidePageListOnlyOnePage : true,
            showTotal : false,
          })}
          defaultSorted={[{dataField:'impShare',order:'asc'},]}
          columns={[
            {dataField:"id",hidden:true,sort:true},
            {
              dataField:"name",
              text:"dashboard.missedOpportunities.missedOpp",
              headerFormatter: headerTranslator,
              formatter: (name, row)=>{
                if(row.highlighted){
                  return (
                    <div className="highlightedRow">
                      <Icon tooltipTranslateId="dashboard.missedOpportunities.highlightTooltip"/>
                      {name}
                    </div>
                  )
                }
                return name
              }
            },{
              dataField:"impShare",
              text: "dashboard.missedOpportunities.share",
              headerFormatter: headerTranslator,
              sort:true,
              classes:"fixedSizedColumn",
              headerClasses:"fixedSizedColumn",
              formatter:x=>x?(NumFormatter.formatNumber(x, 2)+"%"):"-"
            },
          ]}
          filter={filterFactory()}
          classes="normalSizedDatatable MissedOppDataTable"
        />
      </div>
    )
  }
  return <div className={"EmptyMissedOpportunities"}>
    <h4><Translate id="dashboard.missedOpportunities.noMissed1"/></h4>
    <h4><Translate id="dashboard.missedOpportunities.noMissed2"/></h4>
  </div>
})

export default MissedOpportunities

