import * as React                        from "react"
import {connect}                         from 'react-redux'
import {withLocalize}                    from "react-localize-redux"
import StatisticsAccessors               from "../../../../storeAccessor/Statistics"
import InventoryFriend                   from "../../../../model/Store/Campaign/InventoryFriend"
import ArrayUtils                        from "../../../../utils/ArrayUtils"
import retrieveDesiredStatus             from "../../../../actions/desiredStatusException/retrieveDesiredStatusExceptions"
import GoBack                            from "../../../../components/Button/LinkGoBack"
import {Panel}                           from "../../../../components/Panel/Panel"
import ExplainedLoading                  from "../../../../components/loading/"
import CampaignsTable                    from "../shared/CampaignsTable"
import requiresFriends                   from "../../../../components/loaders/friendsLoader"
import GROUPS                            from "../../../../model/constant/Groups"
import Friend                            from "../../../../model/Store/Campaign/Friend"
import TranslatedGoBackLarge             from "../../../../components/notifications/TranslatedGoBackLarge"
import NumFormatter                      from '../../../../utils/NumberFormatter'



interface CampaignsListProps{
  accountId          : number
  statistics         : any[]
  allLangFriends     : (Friend | InventoryFriend)[]
  loadingFriends     : boolean
  allLangIsFetching  : boolean
  statusExceptions   : any
  getDesiredStatusExceptions : ()=>void
  translate : (any:any, any2?:any, any3?:any)=>any
}

const makeMapStateToProps = ()=>{
  const statisticsSelector = StatisticsAccessors.makeCampaignsStatsInCurrentPeriodSelector()
  return state => {
    const accountId = state.Accounts.selected
    return {
      accountId          : accountId,
      statistics         : statisticsSelector(state),
      statusExceptions   : state.Campaigns.DesiredStatusExceptions[accountId],
      inventory          : state.Inventory.list[accountId],
      allLangIsFetching  : state.Inventory.allLangIsFetching
    }
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    getDesiredStatusExceptions : (accountId:number) => dispatch(retrieveDesiredStatus(accountId)),
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    getDesiredStatusExceptions : ()=>DP.getDesiredStatusExceptions(SP.accountId),
  }
}

class CampaignsList extends React.Component<CampaignsListProps,any>{
  constructor(props){
    super(props)
    this.getFriendStatus = this.getFriendStatus.bind(this)
  }
  componentDidMount(){
    if(this.props.statusExceptions === undefined){
      this.props.getDesiredStatusExceptions()
    }
  }
  componentDidUpdate(prevProps){
    if(this.props.statusExceptions === undefined){
      this.props.getDesiredStatusExceptions()
    }
  }
  getFriendStatus(friend){
    if(this.props.statusExceptions != undefined && this.props.statusExceptions.Enabled){
      const names = friend.getCampaignNames()
      if(this.props.statusExceptions.Enabled.find(name=>ArrayUtils.contain(names, name))){return "Active"}
      if(this.props.statusExceptions.Paused.find(name=>ArrayUtils.contain(names, name))){return "Pause"}
    }
    return friend.getStatus()==="ENABLED"?"Active":"Paused"
  }

  getDaysInInventory(friend, isInvFriend) {
    if (isInvFriend && GROUPS.VDP.includes(friend.getType())) {
      const inventory = friend.getInventory()

      if (inventory.length === 0) {
        return this.props.translate('campaigns.list.noInventory')
      }

      if (inventory.length === 1) {
        return inventory[0].daysInInventory
      }
    }

    if (!isInvFriend && GROUPS.VDP.includes(friend.getType())) {
      return this.props.translate('campaigns.list.noInventory')
    }

    if (isInvFriend) {
      if (friend.inventory.length === 0) {
        return this.props.translate('campaigns.list.noInventory')
      }

      const average = (friend.inventory.reduce((sum, b) =>  sum + b.daysInInventory, 0)) / friend.inventory.length

      return this.props.translate('campaigns.list.avg') + NumFormatter.formatNumber(Math.round(average))
    }

    else {
      return this.props.translate('campaigns.list.notApplicable')
    }
  }
  render(){
    if(this.props.allLangFriends === undefined || this.props.loadingFriends){
      return <ExplainedLoading translateId="loadings.campaigns"/>
    }
    if(this.props.statistics === undefined){return <ExplainedLoading translateId="loadings.stats"/>}
    if (this.props.allLangIsFetching) {
      return <ExplainedLoading translateId="loadings.inventory"/>
    }
    if(this.props.allLangFriends.length === 0){
      return <TranslatedGoBackLarge type="link" translationId="campaigns.list.noCampaigns"/>
    }

    const data = this.props.allLangFriends.map(allLangFriend=>{
      const friendStat = this.props.statistics.filter(stat=> {
        for(let c of allLangFriend.getCampaigns()){
          if(c.name === stat.Name){return true}
        }
        return false
      })
      const clicks      = friendStat.reduce((total,currentValue) => total + currentValue.Clicks,0)
      const cost        = friendStat.reduce((total,currentValue) => total + currentValue.Cost,0)
      const isInvFriend = allLangFriend instanceof InventoryFriend

      return {
        accountId       : this.props.accountId,
        campaigns       : allLangFriend.getCampaigns(),
        id              : allLangFriend.getIds().join(","),
        status          : this.getFriendStatus(allLangFriend),
        stock           : isInvFriend && (allLangFriend as InventoryFriend).getInventory().length > 0
                          ? (allLangFriend as InventoryFriend).getInventory()[0].getMostRepresentativeId()
                          : "-",
        name            : allLangFriend.getName(),
        vehicleState    : isInvFriend ? (allLangFriend as InventoryFriend).getVehicleStateString() : "-",
        group           : allLangFriend.getType(),
        clicks          : clicks,
        CPC             : clicks ? cost/clicks : 0,
        cost            : cost,
        daysInInventory : this.getDaysInInventory(allLangFriend, isInvFriend)
      }
    })

    return(
      <Panel title={this.props.translate("campaigns.list.title")} subtitle={this.props.translate("campaigns.list.subtitle")}>
        <GoBack/>
        <CampaignsTable
          data={data}
          columns={["id","status","stock","name","vehicleState","group","clicks","CPC","cost", "daysInInventory"]}
        />
      </Panel>
    )
  }
}

export default withLocalize(requiresFriends(true)(connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(CampaignsList)))
