import './Menu.css'
import * as React                                    from "react"
import {connect}                                     from 'react-redux'
import keydown                                       from 'react-keydown'
import {withRouter}                                  from 'react-router'
import ArrayUtils                                    from "../../../utils/ArrayUtils"
import MenuHeader                                    from "./header/MenuHeader"
import {SideBar}                                     from "./sidebar/SideBar"
import ErrorBoundary                                 from '../../Error/ErrorBoundary'
import {ToggleMenu, changeButtonActive}              from "../../../actions/Menu"
import {ChangePeriod}                                from "../../../actions/Period"
import {MENU_BUTTONS, MenuButtonDescription}         from "./sidebar/menuButtonSignature"

const mapStateToProps = (state,history) => {
  let newActiveButton
  const urlSectionsToUpperCase: string[] = (history.location.pathname).toUpperCase().split("/").filter(x=>x)

  // Filters "Account" and its following number from the first two spaces of the array
  if (urlSectionsToUpperCase[1] && (urlSectionsToUpperCase[0] === "ACCOUNT" && Number.isInteger(parseInt(urlSectionsToUpperCase[1])))) {
    urlSectionsToUpperCase.splice(0, 2)
  }

  // Ensures we can only go two layers deep with the button selection.
  // Should eliminate interference from URLs longer than we would ever currently use.
  if (urlSectionsToUpperCase.length > 2){
    urlSectionsToUpperCase.splice(2)
  }

  // The array used should now only ever contain up to two highlightable buttons.
  // Forseeable breaks: menu buttons or their children being named "Account" or a number, a third depth of menu buttons.
  for (let button of MENU_BUTTONS) {
    if (urlSectionsToUpperCase[0] === button.path.slice(1).toUpperCase()) {
      newActiveButton = button.name
      if (button.subButtons) {
        for (let subButton of button.subButtons) {
          if(ArrayUtils.contain(urlSectionsToUpperCase, subButton.path.slice(1).toUpperCase())){
            newActiveButton = subButton.name
          }
        }
      }
    }
  }

  return {
    toggle            : state.Menu.isMenuToggle,
    accountId         : state.Accounts.selected,
    newActiveButton   : newActiveButton,
    period            : state.Period,
    activeButton      : state.Menu.activeButton
  }
}
const mapDispatchToProps = dispatch => {
  return {
    doToggle : () => dispatch(ToggleMenu()),
    activateButton : (index:string) => dispatch(changeButtonActive(index)),
    updateDate : (dateFrom,dateTo) => dispatch(ChangePeriod(dateFrom,dateTo)),
  }
}
interface MenuProps{
  toggle                      : boolean
  activeButton                : string
  newActiveButton             : string
  accountId                   : number
  activateButton              : (string)=>void
  doToggle                    : ()=>void
  period                      : {dateFrom:Date,dateTo:Date}
  updateDate                  : (dateFrom,dateTo)=>void
  children
}
class MenuComponent extends React.Component<MenuProps,any>{
  constructor(props){
    super(props)
    this.handleButtonActivation = this.handleButtonActivation.bind(this)
  }
  componentDidUpdate(){
    if(this.props.newActiveButton && this.props.newActiveButton != this.props.activeButton){
      this.props.activateButton(this.props.newActiveButton)
    }
  }
  handleButtonActivation(button:MenuButtonDescription){
    this.props.activateButton(button.name)
    if(window.innerWidth <= 991){this.props.doToggle()} //On mobile, close the menu on button click
  }
  @keydown(['alt+m'])
  toggle(event){this.props.doToggle()}
  render(){
    return (
      <div className={"MenuComponent "+(this.props.toggle? "menu_opened nav-open":"menu_closed")}>
        <section className="MenuComponentChildren">
          <MenuHeader period={this.props.period} onUpdate={this.props.updateDate} doToggle={this.props.doToggle}/>
          <div className='MainViewContent'>
            <ErrorBoundary>
              {this.props.children}
            </ErrorBoundary>
          </div>
        </section>
        <SideBar
          doToggle={this.props.doToggle}
          accountPath={this.props.accountId  ?"/Account/"+this.props.accountId  :""}
          toggle={this.props.toggle}
          activeButton={this.props.activeButton}
          activateButton={this.handleButtonActivation}
        />
      </div>
    )
  }
}
export const Menu = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuComponent))
