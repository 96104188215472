import SearchCampaign        from "./SearchCampaign"
import ArrayUtils            from "../../../utils/ArrayUtils"
import ObjectUtils           from "../../../utils/ObjectUtils"
import Place                 from "../GeoBid/Place"
import Radius                from "../GeoBid/Radius"
import {Provider}            from "../../Provider"

export default class Friend {

  protected accountId : number
  protected campaigns : SearchCampaign[]

  constructor(accountId:number,campaigns:SearchCampaign[]){
    this.accountId = accountId
    this.setCampaigns(campaigns)
  }
  clone(){
    return new Friend(
      this.accountId,
      this.campaigns,
    )
  }
  private setCampaigns(campaigns:SearchCampaign[]){
    if(campaigns.length<1){throw Error("Friend cannot be based on no campaigns")}
    if(campaigns.length>1){
      campaigns = ArrayUtils.uniqueFunc(campaigns, c=>{
        return [c.provider,c.externalCampaignId+""].join('_')
      })
      const type = campaigns[0].type
      var langsByProvider = {}
      for(let campaign of campaigns){
        if(campaign.type != type){
          throw Error("Friend cannot be based on campaigns of different types")
        }
        if(campaign.accountId != this.accountId){
          throw Error("Friend cannot be from different accounts")
        }
        if(!langsByProvider.hasOwnProperty(campaign.provider)){
          langsByProvider = {...langsByProvider, [campaign.provider] : []}
        }
        if(langsByProvider[campaign.provider].indexOf(campaign.lang)!==-1){
          console.error(campaigns.map(x=>x.name))
          throw Error("Friend cannot have 2 campaigns of same lang")
        }
        langsByProvider[campaign.provider].push(campaign.lang)
      }
    }
    if(this.additionnalCampaignsCheck(campaigns)){this.campaigns = campaigns}
  }
  public withCampaigns(campaigns:SearchCampaign[]){
    this.setCampaigns(campaigns)
    return this
  }
  protected additionnalCampaignsCheck(campaigns:SearchCampaign[]):boolean{return true}
  protected additionnalPlacesCheck(places:Place[]):boolean{return true}
  protected additionnalRadiusesCheck(radiuses:Radius[]):boolean{return true}

  public addCampaign(campaign:SearchCampaign){
    this.setCampaigns([...this.campaigns,campaign])
  }
  public getAccountId()        : number           { return this.accountId                }
  public getIds() : number[] {
    return this.campaigns.filter(c=>c.provider===Provider.AW).map(c=>c.id)
  }

  public getExternalIds(provider:Provider) : string[]         {
    return this.campaigns.filter(c=>c.provider===provider).map(c=>c.externalCampaignId)
  }
  public getStatus()           : string           { return this.campaigns[0].status      }
  public getType()             : string           { return this.campaigns[0].type        }
  public getCampaigns()        : SearchCampaign[] { return this.campaigns                }
  public getName()             : string           {
    let rawName = this.campaigns[0].name
    let lang    = this.campaigns[0].lang
    const reg = new RegExp(`(\\s\\||\\|)|(^|\\s)${lang}($|\\s)`,"g")
    return rawName.replace(reg,"")
  }
  public getAllVehicleIds() : number[]{
    var allVehicleIds = []
    for(let campaign of this.getCampaigns()){
      let ids = campaign.vehicles ? campaign.vehicles.split(",").map(id=>parseInt(id)) : []
      allVehicleIds = [...allVehicleIds,...ids]
    }
    return ArrayUtils.unique(allVehicleIds)
  }
  public getPlaces(){
    let places = {}
    // Make sure there's no duplicate
    let campaigns = this.campaigns.filter(c=>c.provider===Provider.AW)
    for(let campaign of campaigns){
      for(let place of campaign.geoBids.places){ places[JSON.stringify(place)] = place }
    }
    return ObjectUtils.getObjectValues(places)
  }
  private setPlaces(places:Place[]){
    if(this.additionnalPlacesCheck(places)){
      for(let campaign of this.campaigns){
        if(campaign.geoBids === undefined){campaign.geoBids = {places:[], radiuses:[]}}
        campaign.geoBids.places = places
      }
    }
  }
  public getRadiuses(){
    let radiuses = {}
    // Make sure there's no duplicate
    let campaigns = this.campaigns.filter(c=>c.provider===Provider.AW)
    for(let campaign of campaigns) {
      for(let radius of campaign.geoBids.radiuses){ radiuses[JSON.stringify(radius)] = radius }
    }
    return ObjectUtils.getObjectValues(radiuses)
  }
  private setRadiuses(radiuses:Radius[]){
    if(this.additionnalRadiusesCheck(radiuses)){
      for(let campaign of this.campaigns){
        if(campaign.geoBids === undefined){campaign.geoBids = {places:[], radiuses:[]}}
        campaign.geoBids.radiuses = radiuses
      }
    }
  }
  public withGeoBids(places:Place[],radiuses:Radius[]){
    this.setPlaces(places)
    this.setRadiuses(radiuses)
    return this
  }
  public getCampaignNames(){
    return this.getCampaigns().map(campaign=>campaign.name)
  }
}
