import './Geo.css'
import * as React                        from "react"
import {connect}                         from "react-redux"
import {withLocalize}                    from "react-localize-redux"
import Friend                            from "../../../../../../model/Store/Campaign/Friend"
import GeoMapInfo                        from "../../../../../../model/Store/GeoBid/GeoMapInfo"
import Permissions                       from "../../../../../../model/constant/Permissions"
import {
  createDesiredGeoBidException,
  updateGeoBidException
}                                        from "../../../../../../actions/desiredGeoBidException/assignDesiredGeoBidException"
import removeGeoBids                     from "../../../../../../actions/desiredGeoBidException/removeDesiredGeoBidException"
import {MapWithEditor}                   from "../../../../../../components/Map/MapWithEditor"
import Hint                              from "../../../../../../components/alert/Hint"
import AccountPermissionBoundary         from "../../../../../../components/permissions/AccountPermissionBoundary"
import { ModalWrapper}                   from "carbon-components-react"


interface GeoProps{
  geoExceptions   : GeoException
  saveHandler     : (desiredGeos:(DesiredGeoPlace | DesiredGeoCircle)[], friend:Friend)=>void
  putHandler      : (desiredGeos:(DesiredGeoPlace | DesiredGeoCircle)[], friend:Friend)=>void
  object          : Friend
  removeException : (friend:Friend)=>void
  translate       : (translateId:string)=>string
}
export interface GeoException{
  places   : any[]
  radiuses : any[]
}
export interface DesiredGeoCircle {
  lat         : number,
  long        : number,
  radius      : number,
  bidModifier : number
}

export interface DesiredGeoPlace {
  code        : number,
  include     : boolean,
  bidModifier : number
}

export interface GeoExceptionRequestBody {
  campaignNames : string[],
  geos          : (DesiredGeoPlace | DesiredGeoCircle)[]
}

const mapStateToProps = (state, ownProps) =>{
  const geoExceptions = (
    state.Campaigns.DesiredGeoExceptions[state.Accounts.selected]
    ? state.Campaigns.DesiredGeoExceptions[state.Accounts.selected][ownProps.object.getName()]
    : undefined
  )
  return {
    geoExceptions : geoExceptions
  }
}
const mapDispatchToProps = dispatch => {
  return {
    saveHandler     : (desiredGeos:(DesiredGeoPlace | DesiredGeoCircle)[], friend:Friend)=>{dispatch(createDesiredGeoBidException(friend, desiredGeos))},
    putHandler      : (desiredGeos:(DesiredGeoPlace | DesiredGeoCircle)[], friend:Friend)=>{dispatch(updateGeoBidException(friend, desiredGeos))},
    removeException : (friend:Friend)=>{dispatch(removeGeoBids(friend))}
  }
}


const Geo = (props:GeoProps) => {
  const identifier = props.object.getIds().join(",")
  let geoTargeting = {
    accountId : props.object.getAccountId(),
    matcher   : {group:identifier, lang:identifier},
    radiuses  : props.object.getRadiuses(),
    places    : props.object.getPlaces(),
  }
  let alert = <></>
  let buttonRemove = <></>
  if(props.geoExceptions !== undefined){
    geoTargeting = {
      ...geoTargeting,
      ...props.geoExceptions
    }
    alert = <Hint text={props.translate("campaigns.geo.exceptionMessage")}/>
    buttonRemove = <div className = "ModalWrapperOptionalButton">
      <ModalWrapper
        id = "transactional-modal"
        buttonTriggerText = {props.translate("campaigns.geo.removeButton")}
        modalLabel = ""
        modalHeading = ""
        primaryButtonText = {props.translate("common.yes")}
        secondaryButtonText = {props.translate("common.cancel")}
        handleSubmit = {()=>props.removeException(props.object)}
        shouldCloseAfterSubmit
      >
        <p className="bx--modal-content__text GeoExceptionConfirmationModalContent">{props.translate("campaigns.geo.confirmationMessage")}</p>
      </ModalWrapper>
    </div>
  }

  const determineHandler = (campaignGeoBid: GeoMapInfo, friend: Friend, geoExceptions: GeoException) => {
    const desiredGeos: (DesiredGeoPlace | DesiredGeoCircle)[] = [
      ...campaignGeoBid.circles.map(circle=>({
        lat         : circle.circleProps.center.lat,
        long        : circle.circleProps.center.lng,
        radius      : circle.circleProps.radius/1000,
        bidModifier : circle.bidModifier,
      })),
      ...campaignGeoBid.places.map(place=>({
        code        : place.code,
        include     : place.include,
        bidModifier : place.bidModifier,
      })),
    ]

    if (desiredGeos.length === 0) {
      props.removeException(friend)
    }
    else if (geoExceptions !== undefined) {
      props.putHandler(desiredGeos, friend)
    }
    else {
      props.saveHandler(desiredGeos, friend)
    }
  }

  return (
    <div className="Geo">
      {alert}
      <div className="Row1">
        <div className="Map">
          <AccountPermissionBoundary
            permissions={[Permissions.Keystone_ChangeGeo]}
            onFailure={
              <MapWithEditor
                geoTargeting          = {geoTargeting}
                circleOptions         = {{fillColor:"#01B9F5", strokeColor:"#012B74", strokeWeight:"1"}}
                editedCircleOptions   = {{fillColor:"#FFFF66", strokeColor:"#FF9900", strokeWeight:"1"}}
                selectedCircleOptions = {{fillColor:"#A4FBA6", strokeColor:"#4AE54A", strokeWeight:"1"}}
                saveHandler           = {(campaignGeoBid)=>determineHandler(campaignGeoBid, props.object, props.geoExceptions)}
                buttonSaveText        = {props.translate("campaigns.geo.saveButton")}
                readOnly
              />
            }
          >
            <MapWithEditor
              geoTargeting          = {geoTargeting}
              circleOptions         = {{fillColor:"#01B9F5", strokeColor:"#012B74", strokeWeight:"1"}}
              editedCircleOptions   = {{fillColor:"#FFFF66", strokeColor:"#FF9900", strokeWeight:"1"}}
              selectedCircleOptions = {{fillColor:"#A4FBA6", strokeColor:"#4AE54A", strokeWeight:"1"}}
              saveHandler           = {(campaignGeoBid)=>determineHandler(campaignGeoBid, props.object, props.geoExceptions)}
              buttonSaveText        = {props.translate("campaigns.geo.saveButton")}
              optionalButton        = {buttonRemove}
            />
          </AccountPermissionBoundary>
        </div>
      </div>
    </div>
  )
}
export default (
  withLocalize(
    connect(mapStateToProps,mapDispatchToProps)(
      Geo
    )
  )
)
