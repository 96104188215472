import * as React                           from "react"
import {withLocalize}                       from "react-localize-redux"
import {BarChart, Bar, XAxis,
        YAxis, CartesianGrid, Legend,
        Tooltip, ResponsiveContainer}       from "recharts"
import ObjectUtils                          from "../../utils/ObjectUtils"
import ArrayUtils                           from "../../utils/ArrayUtils"
import MathUtils                            from "../../utils/MathUtils"

interface InventoryScatterChartProps{
  data      : any[]
  vehicles  : any[]
  translate : (translateId:string)=>string
}
interface InventoryScatterChartState{
  selectedRadio : string
}
const calculateVisitors = navigations => ObjectUtils.getObjectValues(
  navigations.reduce((a,b)=>{
    a[b.browserId] = 1
    return a
  },{})
).length
const calculateVisitorsRank = obj=>{
  const fromDB = calculateVisitors(obj.navigationsFromDB)
  return Math.round(100*fromDB/(calculateVisitors(obj.navigationsNotFromDB)+fromDB))
}
const roundUp5 = (x) => ""+Math.ceil(x/5)*5
const build5PercentStruct = ()=>{
  var struct = {}
  for(let i=0; i<101; i+=5){struct[""+i] = []}
  return struct
}
const separateData = (data) => {
  const separated = data.reduce((separated, simplified)=>{
    separated[roundUp5(simplified.visitorsFromDB)].push(simplified)
    return separated
  },build5PercentStruct())
  return (
    ObjectUtils.getObjectValues(
      ObjectUtils.mapOnObject(separated, (key, value)=>{
        const groupedData = value.reduce((grouped, simplified)=>{
          grouped.addedDays = grouped.addedDays+simplified.daysInInventory
          return grouped
        },{addedDays:0, count:value.length})
        groupedData.averageDaysInInventory = (
          value.length === 0
          ? "0"
          : MathUtils.round(groupedData.addedDays/value.length,2)
        )
        groupedData.visitorsFromDB = key
        return groupedData
      })
    )
  )
}
const simplify = x => ({
  YMM : [
    x.getYear(),
    x.getMake(),
    x.getModel(),
    "("+x.getMostRepresentativeId()+")",
  ].join(" "),
  state : x.state.toLowerCase(),
  daysInInventory : x.daysInInventory,
  visitorsFromDB : 0,
})
class InventoryScatterChart extends React.Component<InventoryScatterChartProps,InventoryScatterChartState>{
  constructor(props){
    super(props)
    this.state = {
      selectedRadio : "all"
    }
    this.handleRadioChange = this.handleRadioChange.bind(this)
  }
  handleRadioChange(value){
    this.setState({
      selectedRadio : value
    })
  }

  render(){
    const fromDBIds = this.props.data.map(x=>x.vehicle.id)
    const allVehicles = [
      ...this.props.vehicles.filter(x=>!ArrayUtils.contain(fromDBIds, x.id)).map(x=>simplify(x)),
      ...this.props.data.map(x=>({
        ...simplify(x.vehicle),
        visitorsFromDB : calculateVisitorsRank(x),
        totalVisitors : x.navigationsFromDB.length + x.navigationsNotFromDB.length
      })),
    ]
    const data = separateData(allVehicles)
    return(
      <div className="InventoryScatterChart">
        {/*
          <div className="Options">
            <RadioButton
              name="all"
              value="all"
              labelText="All"
              checked={this.state.selectedRadio === "all"}
              onChange={this.handleRadioChange}
            />
            <RadioButton
              name="new"
              value="new"
              labelText="New"
              checked={this.state.selectedRadio === "new"}
              onChange={this.handleRadioChange}
            />
            <RadioButton
              name="used"
              value="used"
              labelText="Used"
              checked={this.state.selectedRadio === "used"}
              onChange={this.handleRadioChange}
            />
          </div>
        */}
        <div className="Chart">
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={data} margin={{top: 20, right: 20, bottom: 20, left: 20}}>
              <CartesianGrid  strokeDasharray="3 3"/>
              <XAxis
                dataKey="visitorsFromDB"
                unit="%"
                name="VisitorsFromDB"
              />
              <YAxis/>
              <Tooltip cursor={{ strokeDasharray: '3 3' }}/>
              <Legend />
              <Bar
                dataKey="count"
                fill="#8884d8"
                name={this.props.translate("inventoryTurn.graphs.numberVehicles")}
              />
              <Bar
                dataKey="averageDaysInInventory"
                fill="#298678"
                name={this.props.translate("inventoryTurn.graphs.avgDays")}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

export default withLocalize(InventoryScatterChart)
