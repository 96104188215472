import Actions      from "../../../model/constant/actions"
import ReducerUtils from "../../../utils/ReducerUtils"
import ObjectUtils  from "../../../utils/ObjectUtils"

const retrieveGeoBidsStatsForAccount = (state,action) =>{
  if (action.stats){
    if (state[action.accountId]){
      var newState={}
      for (let stat of state[action.accountId] ){ newState[stat.CampaignAWId+stat.Day+stat.SpecificLocationId] = stat }
      for (let stat of action.stats            ){ newState[stat.CampaignAWId+stat.Day+stat.SpecificLocationId] = stat }
      return {...state, [action.accountId] : ObjectUtils.getObjectValues(newState)}
    }
    return {...state,[action.accountId] : action.stats}
  }
  return {...state,[action.accountId] : []}
}
export const GeoBidsStats = ReducerUtils.createReducer<any>({},{
  [Actions.RETRIEVE_AW_GEOBIDS_STATS_OF_ACCOUNT] : retrieveGeoBidsStatsForAccount
})
