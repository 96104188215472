import LoadUsers            from "../user/users"
import Actions              from "../../model/constant/actions"
import Authentification     from '../../utils/Authentification'
import {getToken}           from "../../storeAccessor/Generic"

const onSuccess = data=>({
  type:Actions.SET_PERMISSION_MESSAGE,
  message : JSON.stringify(data,null,4)
})
const onError = error => {
  console.log(error)
  return ({
    type : Actions.SET_PERMISSION_MESSAGE,
    message : JSON.stringify(error,null,4)
  })
}

const assign = (userId:number, userLevel:number) => (dispatch, getState) => {
  dispatch({
    type    : Actions.SET_PERMISSION_MESSAGE,
    message : "Assigning user level..."
  })
  Authentification.post({
      action    : "AssignUserLevel",
      token     : getToken(getState()),
      userId    : userId,
      userLevel : userLevel,
    },
    data => {
      dispatch(onSuccess(data))
      dispatch(LoadUsers())
    },
    error => dispatch(onError(error)),
  )
}

export default assign
