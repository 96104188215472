import './managementRoutes.css'
import * as React                     from "react"
import {HashRouter, Route}            from "react-router-dom"
import {Switch}                       from "react-router-dom"
import {Redirect}                     from "react-router-dom"
import RoutingUtils                   from "../utils/RoutingUtils"
import CreateUser                     from "../page/createUser"
import UsersDatatable                 from "../page/users"
import UserView                       from "../page/user"
import UserAccountView                from "../page/userAccount"
import AccountPage                    from "../page/account"
import AccountsDatatable              from "../page/accounts"
import requiresAccounts               from "../components/loaders/accountsLoader"
import UserPermissionBoundary         from "../components/permissions/UserPermissionBoundary"
import Button                         from "../components/Button/MinimalistButton"
import {Panel}                        from "../components/Panel/Panel"

const routing = (props) => {
  return (
      <UserPermissionBoundary
        userLevel={2}
        permissions={[]}
        onFailure={()=><span>You do not have access to this section</span>}
      >
        <HashRouter>
          <Switch>
            <Route
              exact
              key={"*/Management"}
              path={"*/Management"}
              render={(props) =>
                <Panel title="Management" subtitle="Admin only operations">
                  <div className="MinimalistButtonsList">
                    <Button text={"Create User"} subtitle={"Create new user accounts"} onClick={()=>RoutingUtils.appendToUrl(props.history, "CreateUser")}/>
                    <Button text={"Users"} subtitle={"Manage users' permissions and settings"} onClick={()=>RoutingUtils.appendToUrl(props.history, "Users")}/>
                    <Button text={"Accounts"} subtitle={"Manage dealer accounts settings"} onClick={()=>RoutingUtils.appendToUrl(props.history, "Accounts")}/>
                  </div>
                </Panel>
              }
            />
            <Route
              exact
              key={"*/Management/CreateUser"}
              path={"*/Management/CreateUser"}
              component={CreateUser}
            />
            <Route
              exact
              key={"*/Management/Users"}
              path={"*/Management/Users"}
              component={UsersDatatable}
            />
            <Route
              exact
              key={"*/Management/Users/:userId"}
              path={"*/Management/Users/:userId"}
              render={ props=>(
                <UserView
                  userId={parseInt(props.match.params.userId)}
                  path={props.location.pathname}
                />
              )}
            />
            <Route
              exact
              key={"*/Management/Users/:userId/:accountId"}
              path={"*/Management/Users/:userId/:accountId"}
              render={ props=>(
                <UserAccountView
                  userId={parseInt(props.match.params.userId)}
                  accountId={parseInt(props.match.params.accountId)}
                  path={props.location.pathname}
                />
              )}
            />
            <Route
              exact
              path={"*Management/Accounts"}
              component={AccountsDatatable}
            />
            <Route
              exact
              path={"*Management/Accounts/:accountId"}
              render={props=>
                <AccountPage
                  accountId={parseInt(props.match.params.accountId)}
                  exit={()=>props.history.push("/Management/Accounts")}
                />
              }
            />
            <Redirect from="/" to={"/Management"}/>
          </Switch>
        </HashRouter>
    </UserPermissionBoundary>
  )
}
export default requiresAccounts(routing)
