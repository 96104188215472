import {
  makeStyles,
  Theme,
  createStyles
} from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    panel : {
      marginTop: 40,
      width: 400,
    },
    card : {
      padding: 0,
      margin: "0px 0px 20px 0px",
    },
    cardInactive : {
      backgroundColor : "#f7f7f7"
    },
    cardContent : {
      padding: "0px 0px 0px 20px !important",
      margin: "20px 20px 30px 20px",
    },
    category : {
      marginBottom: 40,
    },
    categoryTitle : {
      fontFamily : "Roboto",
      fontSize : 20,
      color : "#757575"
    },
    row : {
      margin: "25px 0px 25px 0px",
    },
    logo : {
      width: "25px !important",
      marginRight: 10,
      verticalAlign: "middle"
    },
    label : {
      fontFamily : "Roboto",
      fontSize : 16,
      color : "#757575",
      verticalAlign: "middle"
    },
    inputPacingRatio : {
      width: "7ch",
      paddingLeft: "1ch",
      float: "right",
      marginRight: "40px",
    },
    messageRow : {
      margin: "30px 0px 25px 0px",
      height: 40,
    },
    message : {
      fontFamily : "Roboto",
      fontSize : 12,
      color : "red",
      verticalAlign: "middle"
    },
    containerButtons : {
      marginTop: 40,
      display: "flex",
      gap: "20px",
      justifyContent: "center",
      alignItems: "center",
      position: "sticky",
      width: "100%",
    },
    buttonSave : {
      fontFamily : "Roboto",
      backgroundColor : "#707070",
      color : "#FFFFFF",
    },
    alert : {
      textAlign : "center",
      paddingTop : 12,
      verticalAlign : "center",
      backgroundColor : "#E4FAE8",
      width : 500,
      height: 50,
      borderRadius: 4,
      padding: "0px 20px 0px 20px"
    },
    buttonSnackbar : {
      float : "right"
    },

  })
)

export default useStyles
