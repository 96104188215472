import './DropdownManager.css'
import * as React                               from 'react'
import { NativeSelect }                         from '@material-ui/core'
import { Translate }                            from 'react-localize-redux'
import { Button }                               from "carbon-components-react"
import TargetUserPreferences                    from '../../../model/Store/Management/TargetUserPreferences'
import requiresPossibleAccountWhiteLabelsMini   from '../../../components/loaders/possibleAccountWhiteLabelsLoaderMini'
import requiresTargetUserPreferencesMini        from '../../../components/loaders/targetUserPreferencesLoaderMini'

interface WhiteLabelManagerProps {
  accountWhiteLabels    : string[]
  currentWhiteLabel     : string,
  userId                : string,
  targetUserPreferences : TargetUserPreferences
  setWhiteLabelPref     : (whiteLabel: string) => void
}

const generateOptions = (level, name) => (
  <option
    key={level}
    value={level}
  >
    {name}
  </option>
)

const NONE = "None"

const WhiteLabelManager = (props: WhiteLabelManagerProps) => {
  const prependedList = [NONE, ...props.accountWhiteLabels]
  const [selectedValue, setSelectedValue] = React.useState<number>(undefined)

  const handleSubmit = (whiteLabel: string) => {
    if (whiteLabel === NONE) {
      props.setWhiteLabelPref("REMOVE_PREFERENCE")
    }
    else {
      props.setWhiteLabelPref(whiteLabel)
    }
    setSelectedValue(undefined)
  }

  const handleChange = (value: string) => {
    const index = parseInt(value)
    setSelectedValue(index)
  }

  return (
    <div className="ManagerContainer">
      <div className="ManagerControls">
        <NativeSelect
          variant="filled"
          defaultValue={prependedList.indexOf(props.currentWhiteLabel)}
          onChange={(e)=>handleChange(e.target.value)}
        >
          {
            props.accountWhiteLabels &&
            prependedList.map((item, index) => generateOptions(index, item))
          }
        </NativeSelect>
        <Button
          onClick={(e) => handleSubmit(prependedList[selectedValue])}
          disabled={selectedValue === undefined}
        >
          <Translate id="common.confirm"></Translate>
        </Button>
      </div>
    </div>
  )
}

export default requiresPossibleAccountWhiteLabelsMini(
  requiresTargetUserPreferencesMini(WhiteLabelManager)
)
