import Auth2, {
  GetAccountsReturn
}                        from "../../utils/Auth2"
import ActionUtils       from "../../utils/ActionUtils"
import Actions           from "../../model/constant/actions"
import {getToken}        from "../../storeAccessor/Generic"
import {State}           from "../../reducers/generic/reducerAssembly"
import DemoEmails        from "../../model/DemoEmails"
import User              from "../../model/Store/Login/UserLogged"
import {ThunkAction}     from "redux-thunk"

const OVERWRITE_ACCOUNT_NAMES_FOR_DEMO_USERS = (accounts:GetAccountsReturn, user:User):GetAccountsReturn => {
  if(!DemoEmails.includes(user.profile.email)){return accounts}
  return accounts.map((x,i)=>({
    ...x,
    name : "Demo account "+(i+1)
  }))
}

const retrieveAccounts = ():ThunkAction<void, State, null, any> => async (dispatch, getState) => {
  dispatch({type : Actions.RETRIEVING_ACCOUNTS_FOR_TOKEN})
  const state = getState()
  const token = getToken(state)
  const accounts = await getAccounts(token)

  // Previously matched against Keystone accounts, which now contain nothing Auth2 doesn't.
  // But this key is still used elsewhere in the app.
  for (const account of accounts) {
    account["bingCustomerId"] = account.bingAccountId.toString()
  }

  dispatch({
    type     : Actions.RETRIEVE_ACCOUNTS_FOR_TOKEN,
    accounts : OVERWRITE_ACCOUNT_NAMES_FOR_DEMO_USERS(accounts, state.Login.userLogged)
  })
}

const getAccounts = (token:string):Promise<GetAccountsReturn> => {
  return ActionUtils.retryOnFailure(()=>
    Auth2.getAccounts(token)
  )
  .then((response)=>response.data)
  .catch((error)=>{
    console.log("Error retrieving accounts", error)
    return []
  })
}

export default retrieveAccounts
