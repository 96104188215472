import "./SimpleAdCopy.css"
import * as React  from "react"

interface SimpleAdCopyOwnProps{
  headlines    : string[]
  descriptions : string[]
  url          : string
}
interface SimpleAdCopyProps extends SimpleAdCopyOwnProps {}

const finishWithPonctuation = new RegExp("[.!?]{1}$")
const formatDescriptions = (descriptions:string[]):string => {
  return descriptions
  .map(x=>x.trim())
  .filter(x=>x)
  .map(description => finishWithPonctuation.test(description) ? description : description + '.')
  .join(" ");
}

const formatHeadlines = (headlines:string[]):string => {
  const filtered = headlines.map(x=>x.trim()).filter(x=>x) //remove empty
  return filtered.join(" | ")
}

const SimpleAdCopy = (props:SimpleAdCopyProps) => {
  return (
    <div className="SimpleAdCopy" >
      <div className="Title">
        {formatHeadlines(props.headlines)}
      </div>
      <div className="Url">{props.url}</div>
      <div className="Description">
        {formatDescriptions(props.descriptions)}
      </div>
    </div>
  )
}

export default SimpleAdCopy
