import './index.css'
import * as React         from 'react'
import {Translate}        from "react-localize-redux"
import {Loading}          from "carbon-components-react"

interface ExplainedLoadingProps{
  text        ?: string
  translateId ?: string
  small       ?: boolean
  horizontal  ?: boolean
}
const ExplainedLoading = (props:ExplainedLoadingProps) =>(
  <div className={ 'ExplainedLoading' + (props.horizontal ? ' Horizontal' : '')}>
    <span>
      {
        props.translateId
        ? <Translate id={props.translateId}/>
        : props.text
      }
    </span>
    <Loading withOverlay={false} small={props.small || false} />
  </div>
)

export default ExplainedLoading
