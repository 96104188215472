import * as React                     from "react"
import ObjectUtils                    from "../../utils/ObjectUtils"
import {Dropdown, DropdownItem}       from "carbon-components-react"
import {Views}                        from "./Viewer"

interface ViewChangerProps{
  views       : Views
  currentView : string
  changeView  : (view:string)=>void
}
const ViewChanger = (props:ViewChangerProps) =>(
  <Dropdown
    value={props.currentView}
    onChange={(item)=>props.changeView(item.value)}
    ariaLabel=""
  >
    {ObjectUtils.getObjectValues(props.views).map(view=>
      <DropdownItem key={view.name} itemText={view.display?view.display:view.name} value={view.name} />
    )}
  </Dropdown>
)


export default ViewChanger
