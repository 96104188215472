import * as React   from "react"
import {connect}    from "react-redux"
import Confirmation from "./Confirmation"
import Actions      from "../../model/constant/actions"

interface NavigationBlockerDialogOwnProps {
}

const mapStateToProps = (state,ownProps)=>{
  const open = state.Navigation.ConfirmationModal.status
  const title = state.Navigation.ShouldBlock.title
  const content = state.Navigation.ShouldBlock.content
  return {
    open,
    title,
    content
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    handleDialogClose : ()=>{
      dispatch({type: Actions.CANCEL_NAVIGATION})
      dispatch({type: Actions.SHOW_NAVIGATION_CONFIRMATION_MODAL, status: false})
    },
    handleDialogConfirm : ()=>{
      dispatch({type: Actions.CONFIRM_NAVIGATION})
      dispatch({type: Actions.SHOW_NAVIGATION_CONFIRMATION_MODAL, status: false})
    }
  }
}

const mergeProps = (SP:ReturnType<typeof mapStateToProps>,DP:ReturnType<typeof mapDispatchToProps>,ownProps:NavigationBlockerDialogOwnProps)=>{
  return {...SP,...DP,...ownProps}
}

type NavigationBlockerDialogProps = ReturnType<typeof mergeProps>

const NavigationBlockerDialog = (props:NavigationBlockerDialogProps) => {
  const onClose = React.useCallback(() => props.handleDialogClose(), [])
  const onConfirm = React.useCallback(() => props.handleDialogConfirm(), [])
  return (
    <Confirmation
      open={props.open}
      title={props.title !== "common.NOTHING" ? props.title : "navBlocker.title"}
      content={props.content !== "common.NOTHING" ? props.content :"navBlocker.message"}
      translate={true}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmButtonText="common.ok"
    />
  )
}
export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(NavigationBlockerDialog) as React.ComponentType<NavigationBlockerDialogOwnProps>
