import {createSelector}        from "reselect"
import ArrayUtils              from "../utils/ArrayUtils"
import * as Generic            from "./Generic"

export default class CampaignsAccessors{
  public static makeFindFriendWithIdsSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getCampaigns,
      (_,ids)=>ids,
      (accountId, friends, ids)=>{
        if(!friends[accountId]){return undefined}
        return friends[accountId].friends.find(friend=>ArrayUtils.containAll(friend.getIds(), ids))
      }
    )
  }
  public static makeFindDisplayCampaignWithIdSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getDisplayCampaigns,
      (_,id)=>id,
      (accountId, campaigns, id)=>{
        if(!campaigns[accountId]){return undefined}
        return campaigns[accountId].find(campaign=>campaign.externalCampaignId == id)
      }
    )
  }
  public static makeAllCampaignsSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getCampaigns,
      (accountId, campaigns)=>{
        if(!campaigns[accountId]){return undefined}
        return campaigns[accountId].friends.reduce((campaigns, friend)=>{
          campaigns.push(...friend.campaigns)
          return campaigns
        },[])
      }
    )
  }
}
