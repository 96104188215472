import ReducerUtils           from "../../utils/ReducerUtils"
import ArrayUtils             from "../../utils/ArrayUtils"
import Actions                from "../../model/constant/actions"
import {MarketingEvent}       from "../../model/adbuilder/"
import {
  reorderRSAText,
  assignIdentifiersToRSAText
}                             from "../../components/adCopy/utils"

const setEvents = (state, action) => {
  const events:MarketingEvent[] = action.events
  events.forEach(event=>{
    event.rsaApproaches.forEach(approach=>{
      approach.patterns.forEach(pattern=>{
        pattern.headlines = reorderRSAText(assignIdentifiersToRSAText(pattern.headlines))
        pattern.descriptions = reorderRSAText(assignIdentifiersToRSAText(pattern.descriptions))
      })
    })
  })

  if(!state[action.accountId]){
    return {
      ...state,
      [action.accountId] : action.events
    }
  }
  return {
    ...state,
    [action.accountId] : ArrayUtils.mergeBySerialization(state[action.accountId], action.events, (event)=>event.id)
  }
}
const deleteEvent = (state, action) => {
  if(!state[action.accountId]){return state}
  return {
    ...state,
    [action.accountId] : state[action.accountId].filter(x=>x.id != action.id)
  }
}
interface EventsByAccount{
  [accountId:number] : MarketingEvent[]
}

const MarketingEvents = ReducerUtils.createReducer<EventsByAccount>({},{
  [Actions.RETRIEVE_MARKETING_EVENTS] : setEvents,
  [Actions.DELETE_SPECIFIC_MARKETING_EVENT] : deleteEvent
})

export default MarketingEvents
