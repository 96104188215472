import Keystone          from "../../utils/Keystone"
import Actions           from "../../model/constant/actions"
import {getToken}        from "../../storeAccessor/Generic"

export const retrieveAWSearchAudiences = (accountId:number) => (dispatch, getState) => {
  dispatch({
    type : Actions.RETRIEVING_AWSEARCH_AUDIENCES,
    status : true
  })
  Keystone.get({
      token  : getToken(getState()),
      action : "GetAllAWAudiences",
      accountId
    },
    (response) => {
      if (response.data.audiences){
        const audiences = response.data.audiences.map(x=>({
          id           : x.id,
          name         : x.name,
          description  : x.description,
          size         : x.sizeForSearch,
          listType     : x.listType,
          userListType : x.userListType
        }))
        dispatch({
          type : Actions.RETRIEVE_AWSEARCH_AUDIENCES,
          accountId,
          audiences
        })    
      }
      if (response.data.status==="error"){
        dispatch({
          type : Actions.FAILED_RETRIEVING_AWSEARCH_AUDIENCES,
          status : true,
          error : response.error[0]
        })
      }
    },
    (error) => console.log(error)
  )
  .then(()=>{
    dispatch({
      type : Actions.RETRIEVING_AWSEARCH_AUDIENCES,
      status : false
    })
  })
}
