import './IdentityVariants.css'
import * as React                                         from 'react'
import IdentityVariantInput                               from './IdentityVariantInput'
import {
  Button,
  Typography,
  CardContent,
  CardActions,
  CardHeader,
}                                                         from '@material-ui/core'
import {
  Translate,
  withLocalize ,
  TranslateFunction
}                                                         from 'react-localize-redux'
import { SimplifiedIdentityVariants }                     from '../../../../model/branding/SimplifiedIdentityVariants'


interface IdentityVariantsOwnProps {
  setIdentityVariants              : (IdentityVariant:SimplifiedIdentityVariants) => void
  identityVariants                 : SimplifiedIdentityVariants
  initialIdentityVariants          : SimplifiedIdentityVariants
  title                           ?: JSX.Element
  description                     ?: JSX.Element
}
interface IdentityVariantsProps extends IdentityVariantsOwnProps {
  translate  : TranslateFunction
}
const IdentityVariants = (props:IdentityVariantsProps) => {
  const [cities,setCities]           = React.useState(props.identityVariants.cities)
  const [dealerNames,setDealerNames] = React.useState(props.identityVariants.dealerNames)
  const [makes,setMakes]             = React.useState(props.identityVariants.makes)
  React.useEffect(()=>{
    setCities(props.identityVariants.cities)
    setDealerNames(props.identityVariants.dealerNames)
    setMakes(props.identityVariants.makes)
  },[props.identityVariants])

  function handleReset() {
    setCities(props.initialIdentityVariants.cities)
    setDealerNames(props.initialIdentityVariants.dealerNames)
    setMakes(props.initialIdentityVariants.makes)
    props.setIdentityVariants({
      ...props.identityVariants,
      makes       : props.initialIdentityVariants.makes,
      cities      : props.initialIdentityVariants.cities,
      dealerNames : props.initialIdentityVariants.dealerNames
    })
  }

  const allFilled = props.identityVariants.cities.length > 0 && props.identityVariants.dealerNames.length > 0 && props.identityVariants.makes.length > 0
  return (
    <div className="IdentityVariants">
      <CardHeader
        className="Header"
        title={props.title ||
          <Typography variant="h6">
            <Translate id="branding.identityVariants" />
          </Typography>
        }
        subheader={props.description ||
          <div>
            <Typography className="Caption" variant="caption">
              <Translate id="branding.descriptions.identityVariants" />
            </Typography>
          </div>
        }
      />
      <CardContent className="Inputs">
        <IdentityVariantInput
          type="dealerNames"
          identityVariants={props.identityVariants}
          variants={dealerNames}
          title={props.translate('branding.dealerNames') as string}
          setValues={setDealerNames}
          setIdentityVariants={props.setIdentityVariants}
        />
        <IdentityVariantInput
          type="cities"
          identityVariants={props.identityVariants}
          variants={cities}
          title={props.translate('branding.cities') as string}
          setValues={setCities}
          setIdentityVariants={props.setIdentityVariants}
        />
        <IdentityVariantInput
          type="makes"
          identityVariants={props.identityVariants}
          variants={makes}
          title={props.translate('branding.makes') as string}
          setValues={setMakes}
          setIdentityVariants={props.setIdentityVariants}
        />
      </CardContent>
      {!allFilled &&
        <div className="WarningYellow UnfilledError"><Translate id={'branding.unfilledError'}></Translate></div>
      }
      <CardActions className="Buttons">
        <Button color="secondary"  onClick={handleReset}><Translate id="common.reset" /></Button>
      </CardActions>
    </div>
  )
}

export default withLocalize(IdentityVariants as any) as React.ComponentType<IdentityVariantsOwnProps>
