import ReducerUtils       from "../../../../utils/ReducerUtils"
import ObjectUtils        from "../../../../utils/ObjectUtils"
import Actions            from "../../../../model/constant/actions"
import CampaignBaseInfo   from "../../../../model/Store/Campaign/CampaignBaseInfo"

const setData = (state, action) => {
  const accountId = action.accountId
  if(!state[accountId]){
    return {
      ...state,
      [accountId] : action.infoById
    }
  }

  return {
    ...state,
    [accountId] : {
      ...state[accountId],
      ...action.infoById
    }
  }
}

interface InfoByIdByAccount{
  [accountId:number] : {[campaignAWId:string]:CampaignBaseInfo}
}
const BaseInfoById = ReducerUtils.createReducer<InfoByIdByAccount>({},{
  [Actions.RETRIEVE_AW_CAMPAIGNS_BASE_INFO] : setData
})

export default BaseInfoById
