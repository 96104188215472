import Actions                                from "../../model/constant/actions"
import IDBAPI                                 from "../../utils/IDBAPI"
import ReportTableTemplate                    from "../../model/Store/Report/ReportTableTemplate"
import ReportTableTemplateUtils               from "../../utils/ReportTableTemplateUtils"
import {getToken}                             from "../../storeAccessor/Generic"

export const fetchTemplates = (context?:string) => (dispatch, getState) => {
  const payload : any = {
    token  : getToken(getState()),
    action : "GetUserReports",
  }
  if(context){payload.context = context}
  IDBAPI.get(
    payload,
    (data)=>dispatch(fetchTemplatesSuccess(data)),
    (data)=>console.log(data)
  )
}
export const saveTemplate = (template:ReportTableTemplate) => (dispatch, getState) => {
  IDBAPI.post({
      token      : getToken(getState()),
      action     : "CreateReport",
      templateID : template.getId(),
      context    : template.getContext(),
      name       : template.getName(),
      provider   : template.getProvider(),
      structure  : template.toJSON()
    },
    (data)=>dispatch(saveTemplateSuccess(data)),
    (data)=>console.log(data)
  )
}
export const createTemplate = (template:ReportTableTemplate) => (dispatch, getState) => {
  IDBAPI.post({
      token     : getToken(getState()),
      action    : "CreateReport",
      context   : template.getContext(),
      name      : template.getName(),
      provider  : template.getProvider(),
      structure : template.toJSON(),
    },
    (data)=>dispatch(createTemplateSuccess(data)),
    (data)=>console.log(data)
  )
}
export const deleteTemplate = (templateId:string) => (dispatch, getState) => {
  const token = getToken(getState())
  IDBAPI.post({
      token : token,
      action : "DeleteReport",
      templateId : templateId
    },
    (data)=>{
      dispatch({
        type : Actions.REMOVE_REPORT_TEMPLATE,
        templateId : templateId
      })
      dispatch(fetchTemplates(token))
    },
    (data)=>console.log(data)
  )
}
const fetchTemplatesSuccess = (response) => {
  const templates = response.data.reports.map(templateDescription=>{
    const TemplateClass = ReportTableTemplateUtils.getTemplateClassFromContext(
      templateDescription.Provider,
      templateDescription.Context
    )
    let newTemplate = TemplateClass.fromJSON(templateDescription.Structure)
    newTemplate.setId(templateDescription.TemplateId)
    newTemplate.setRevisionOf(templateDescription.RevisionOf)
    return newTemplate
  })
  return {
    type : Actions.ADD_REPORT_TEMPLATES,
    templates : templates
  }
}
const saveTemplateSuccess = (response) => {
  const TemplateClass = ReportTableTemplateUtils.getTemplateClassFromContext(
    response.data.report.Provider,
    response.data.report.Context
  )
  const template = TemplateClass.fromJSON(response.data.report.Structure)
  template.setId(response.data.report.TemplateId)
  template.setRevisionOf(response.data.report.RevisionOf )
  return {
    type : Actions.ADD_REPORT_TEMPLATE,
    template : template
  }
}
const createTemplateSuccess = (response) => {
  const TemplateClass = ReportTableTemplateUtils.getTemplateClassFromContext(
    response.data.report.Provider,
    response.data.report.Context
  )
  let template = TemplateClass.fromJSON(response.data.report.Structure)
  template.setId(response.data.report.TemplateId)
  template.setRevisionOf(response.data.report.RevisionOf )
  return {
    type : Actions.ADD_REPORT_TEMPLATE,
    template : template
  }
}
