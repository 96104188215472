import * as React                  from 'react'
import {Marker, Circle, Polygon}   from "react-google-maps"
import {PermissionContext}         from "../context/permissionContext"
import PlaceDefinition             from "../../model/map/PlaceDefinition"
import PolygonWithTooltip          from "./PolygonWithTooltip"

interface UserLevelRestrictedPolygonProps{
  shapes                  : any[]
  placeDefinition         : PlaceDefinition
  userLevel               : number
  refHandler             ?: (ref, id)=>void
  hideIfNoAvailableShape ?: boolean
}

const UserLevelRestrictedPolygon:React.SFC<UserLevelRestrictedPolygonProps> = (props:UserLevelRestrictedPolygonProps) => {
  return <PermissionContext.Consumer>{context=>{
    const includeStatus = props.placeDefinition.include?"Included":"Excluded"
    const polygon = props.shapes[props.placeDefinition.code]
    return (context.validateStaffLevel(props.userLevel)
      ?
        <PolygonWithTooltip
          tooltipContent={props.placeDefinition.address+" ("+includeStatus+")"}
          key={props.placeDefinition.id+"-P"}
          ref={ref => ref && props.refHandler(ref, props.placeDefinition.id+"-P")}
          paths={polygon.polygons}
          visible={props.placeDefinition.placeProps.visible!==undefined?props.placeDefinition.placeProps.visible:true}
          options={{
            strokeColor:props.placeDefinition.include?"blue":"red",
            strokeWeight:1,
            fillColor:props.placeDefinition.include?"blue":"red",
          }}
        />
      :
        (props.hideIfNoAvailableShape
          ?
            <></>
          :
            <Marker
              key={props.placeDefinition.id+"-M"}
              ref={ref => ref && props.refHandler(ref, props.placeDefinition.id+"-M")}
              title={props.placeDefinition.address+" ("+includeStatus+")"}
              animation={google.maps.Animation.DROP}
              {...props.placeDefinition.placeProps}
            />
        )
    )
  }}</PermissionContext.Consumer>
}
UserLevelRestrictedPolygon.defaultProps = {
  refHandler : ()=>{},
  hideIfNoAvailableShape : false
}
export default UserLevelRestrictedPolygon
