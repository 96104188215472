import "./PlaceSearchBox.css"
import * as React                from "react"
import {connect}                 from "react-redux"
import {withLocalize, Translate} from "react-localize-redux"
import AccountSelectors          from "../../../storeAccessor/Accounts"
import ArrayUtils                from "../../../utils/ArrayUtils"
import PlaceDefinition           from "../../../model/map/PlaceDefinition"
import Account                   from "../../../model/Store/Account/Account"
import CurrencyToCountryCode     from "../../../model/constant/CurrencyToCountryCode"
import {PlaceSuggestion}         from "./PlaceSuggestion"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
  Typography
}                                from "@material-ui/core"

interface PlaceSearchBoxComponentProps{
  account         : Account
  allPlaces       : any[]
  currentPlaces   : PlaceDefinition[]
  addPendingPlace : (place)=>void
  addCodeToQueue  : (code)=>void
  translate      ?: (translateId:string)=>string
}
const mapStateToProps = () => {
  const selectedAccountSelector = AccountSelectors.makeSelectedAccountSelector()
  return (state, ownProps) => {
    return {
      account   : selectedAccountSelector(state),
      allPlaces : state.Params.GeoBids.AdWordsPlaces,
    }
  }
}
const mapDispatchToProps = dispatch => {
  return{}
}
interface PlaceSearchBoxComponentState{
  text        : string
  suggestions : any[]
  extended    : boolean
}
const targetTypeOrder = [
  "Country",
  "State",
  "Province",
  "Territory",
  "Department",
  "Region",
  "County",
  "City",
  "City Region",
  "Canton",
  "Municipality",
  "National Park",
  "Borough",
  "Neighborhood",
  "Postal Code"    ,
  "Congressional district",
  "Airport",
  "University",
  "Autonomous community",
]

class PlaceSearchBoxComponent extends React.Component<PlaceSearchBoxComponentProps,PlaceSearchBoxComponentState>{
  constructor(props){
    super(props)
    this.state={
      text:"",
      suggestions:[],
      extended : false
    }
    this.onChange = this.onChange.bind(this)
    this.getSuggestions = this.getSuggestions.bind(this)
    this.selectSuggestion = this.selectSuggestion.bind(this)
  }
  onChange(e){
    let searchTerm = e.target.value
    let suggestions = []
    if(searchTerm.length >= 3){suggestions = this.getSuggestions(searchTerm)}
    this.setState({
      text:searchTerm,
      suggestions:suggestions,
      extended:true
    })
    if(searchTerm.length<3) {
      this.setState({
        extended:false
      })
    }
  }
  getSuggestions(searchTerm:string):any[]{
    let suggestions = []
    let extractSuggestionName = a=>a[1]
    const tokens = searchTerm.toUpperCase().match(/\S+/g)
    const accountCountryCode = CurrencyToCountryCode[this.props.account.currency]

    suggestions = this.props.allPlaces.filter(place=>{
      for(const token of tokens){if(place[1].toUpperCase().search(token)===-1){return false}}
      return true
    })
    if(suggestions.length === 0){
      //If no suggestions are found, we use the canonical name instead of name
      suggestions = this.props.allPlaces.filter(place=>{
        for(const token of tokens){if(place[2].toUpperCase().search(token)===-1){return false}}
        return true
      })
      extractSuggestionName = a=>a[2]
    }

    suggestions.sort((aArray,bArray)=>{
      if(accountCountryCode){ //Account's country's places are always first
        if(aArray[3] === accountCountryCode && bArray[3] !== accountCountryCode){return -1}
        if(aArray[3] !== accountCountryCode && bArray[3] === accountCountryCode){return 1}
      }
      const a = extractSuggestionName(aArray)
      const b = extractSuggestionName(bArray)
      if(a.toUpperCase()===searchTerm.toUpperCase() && b.toUpperCase()===searchTerm.toUpperCase()){
        if(targetTypeOrder.indexOf(aArray[4]) < targetTypeOrder.indexOf(bArray[4])){return -1}
        if(targetTypeOrder.indexOf(aArray[4]) > targetTypeOrder.indexOf(bArray[4])){return 1}
        if(a>b){return 1}
        if(a<b){return -1}
        return 0
      }
      if(a.toUpperCase()===searchTerm.toUpperCase() && b.toUpperCase()!==searchTerm.toUpperCase()){return -1}
      if(a.toUpperCase()!==searchTerm.toUpperCase() && b.toUpperCase()===searchTerm.toUpperCase()){return 1}
      if(targetTypeOrder.indexOf(aArray[4]) < targetTypeOrder.indexOf(bArray[4])){return -1}
      if(targetTypeOrder.indexOf(aArray[4]) > targetTypeOrder.indexOf(bArray[4])){return 1}
      if(a>b){return 1}
      if(a<b){return -1}
      return 0
    })
    return suggestions
  }
  selectSuggestion(suggestion, include:boolean){
    const place = {
      code : suggestion[0],
      literal : "",
      level : suggestion[4],
      include : include,
      bidModifier : 1.0
    }
    this.props.addPendingPlace(place)
    this.props.addCodeToQueue(place.code)
  }
  render(){
    const currentCodes = this.props.currentPlaces.map(x=>x.code)
    return(
      <div className="PlaceSearchBox">
        <TextField
          InputProps={{
            endAdornment: <InputAdornment style={{width:100}} position="end">
              <Typography variant="caption">
                {this.state.suggestions.length !=0 && this.state.suggestions.length + " " + this.props.translate('geoTarget.placeSearch')}
              </Typography>
            </InputAdornment>,
          }}
          variant="outlined"
          fullWidth
          id="PlaceSearchBoxInput"
          value={this.state.text}
          onChange={this.onChange}
          placeholder={this.props.translate("geoTarget.placesPlaceholder")}
        />
        <div className="mainTableContainer" style={this.state.extended ? {maxHeight:200} : {maxHeight:0} }>
        <Table stickyHeader className="mainTable">
          <TableHead>
            <TableRow>
              <TableCell style={{zIndex:100,backgroundColor:'#fafafa'}}></TableCell>
              <TableCell><Translate id="geoTarget.name"/></TableCell>
              <TableCell><Translate id="geoTarget.details"/></TableCell>
              <TableCell><Translate id="geoTarget.type"/></TableCell>
              <TableCell style={{zIndex:100,backgroundColor:'#fafafa'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="SearchTable">
            {
              this.state.suggestions.map(suggestion=>
                <PlaceSuggestion
                  key={suggestion[0]}
                  suggestion={suggestion}
                  onClick={this.selectSuggestion}
                  alreadySelected={ArrayUtils.contain(currentCodes, suggestion[0])}
                />
              )
            }
          </TableBody>
        </Table>
        </div>
      </div>
    )
  }
}

export const PlaceSearchBox = (
  withLocalize(
    connect(mapStateToProps,mapDispatchToProps)(
      PlaceSearchBoxComponent
    )
  )
)
