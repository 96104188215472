import * as React             from "react"
import {connect}              from "react-redux"
import {getBudgets}           from "../../actions/Budgets"
import ExplainedLoading       from "../loading"
import ErrorBoundary          from "../Error/ErrorBoundary"

const mapStateToProps = (state,ownProps) => {
  const accountId = state.Accounts.selected
  return {
    accountId           : accountId,
    fetchingBudgets     : state.Params.BudgetsPlans.RetrievingBudgets,
    budgets             : state.Params.BudgetsPlans.Budgets[accountId],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadBudgets : (accountId:number)=>dispatch(getBudgets(accountId)),
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    loadBudgets : () => DP.loadBudgets(SP.accountId),
  }
}
const BudgetsLoader = props => {
  if(props.budgets === undefined){
    if(!props.fetchingBudgets){props.loadBudgets()}
    return <ExplainedLoading translateId="loadings.budgets"/>
  }
  return <ErrorBoundary>{props.render(props.budgets)}</ErrorBoundary>
}
const BudgetsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(BudgetsLoader)

const requiresBudgets = Component => props => (
  <BudgetsLoaderConnected
    render={ budgets => <Component budgets={budgets} {...props} /> }
  />
)

export default requiresBudgets
