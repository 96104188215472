import Pacy        from "../../utils/Pacy"
import ActionUtils from "../../utils/ActionUtils"
import Actions     from "../../model/constant/actions"
import {getToken}  from "../../storeAccessor/Generic"


export const retrieveV0Usage = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_PACY_V0USAGE, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Pacy.getV0Usage(token, accountId)
  )
  .then(response=>{
    dispatch({
      type : Actions.RETRIEVE_PACY_V0USAGE,
      accountId : accountId,
      v0Usage : response.data
    })
    dispatch({
      type: Actions.FAILED_RETRIEVING_PACY_V0USAGE,
      status : false,
    })
  })
  .catch((error)=>{
    console.warn(error)
    dispatch({
      type: Actions.FAILED_RETRIEVING_PACY_V0USAGE,
      status : true,
    })
    dispatch({type: Actions.RETRIEVE_PACY_V0USAGE, v0Usage:{}}) // clear (no data)
  })
  .then(()=>{
    dispatch({type: Actions.RETRIEVE_PACY_V0USAGE_AFTER_SAVING, status: false})
    dispatch({type: Actions.RETRIEVING_PACY_V0USAGE, status: false})
  })
}
