import "./SimpleKeyValueTable.css"
import * as React from 'react'
import {Translate} from "react-localize-redux"

interface SimpleKeyValueTableProps{
  keyValue:{
    [key:string]:any
  }
  spillRight    ?: boolean
  translateKeys ?: boolean
  gap           ?: number
}

const SimpleKeyValueTable : React.SFC<SimpleKeyValueTableProps> = (props:SimpleKeyValueTableProps) => {
  const addedClass = props.spillRight ?' spillRight':''
  return (
    <div className={'SimpleKeyValueTable'+addedClass}>
      {Object.keys(props.keyValue).map(key=>(
        <React.Fragment key={key}>
          <div className='key' style={{marginBottom:props.gap ? props.gap : 0}}>
            {props.translateKeys
              ? <Translate id={key}/>
              : key
            }
          </div>
          <div className='value'>{props.keyValue[key]}</div>
        </React.Fragment>
      ))}
    </div>
  )
}
SimpleKeyValueTable.defaultProps = {
  spillRight : false
}

export default SimpleKeyValueTable
