import Actions                      from "../../../../model/constant/actions"
import Keystone                     from "../../../../utils/Keystone"
import {getBudgetPlans}             from "../../../../actions/BudgetsPlan"
import {getToken}                   from "../../../../storeAccessor/Generic"

const savePlanPartValue = (payload:any) => {
  return {
    type    : Actions.SAVE_NEW_BUDGET_PLAN_PART_VALUE,
    payload : payload,
  }
}
export const savePlanPart = (accountId:number, provider:string, budgetId:number, period:string, amount:number) => (dispatch, getState) => {
  Keystone.post({
      token     : getToken(getState()),
      accountId : accountId,
      budgetId  : budgetId,
      month     : period,
      amount    : amount,
      provider  : provider,
      action    : "IDB2ChangeBudgetPlan",
    },
    (data) => dispatch(getBudgetPlans(accountId)),
    (data) => console.log(data.error)
  )
}
