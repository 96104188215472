import * as React                    from "react"
import Account                       from "../../../model/Store/Account/Account"
import KeyValueTable                 from "../../../components/table/KeyValueTable"
import ObjectUtils                   from "../../../utils/ObjectUtils"

interface AccountViewProps {
	account : Account
  title  ?: string
}
const AccountView = (props:AccountViewProps) => {
  const struct = {
    "Account ID" : props.account.id,
    "AdWords ID" : props.account.AWCustomerId,
    "Bing ID"    : props.account.bingCustomerId,
    "Currency"   : props.account.currency,
  }
  return (
    <div className="AccountView">
      <KeyValueTable
        title={props.title?props.title:props.account.name}
        keyValue={struct}
      />
    </div>
  )
}

export default AccountView
