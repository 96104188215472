import "./CopyAndReplace.css"
import * as React                      from "react"
import {connect}                       from "react-redux"
import {withRouter}                    from "react-router"
import {withLocalize, Translate}       from "react-localize-redux"
import GeoTargeting, {
  MultiLanguageGeoTargeting
}                                      from "../../model/Store/GeoBid/GeoTargeting"
import VSGL                            from "../../model/Store/Matcher/VSGL"
import ArrayUtils                      from "../../utils/ArrayUtils"
import RoutingUtils                    from "../../utils/RoutingUtils"
import GeoBidAccessors                 from "../../storeAccessor/GeoBid"
import {replaceGeos}                   from "../../actions/EditGeoBid"
import {Button}                        from "carbon-components-react"
import {MapDisplayOnly}                from "../../components/Map/MapDisplayOnly"
import {Panel}                         from "../../components/Panel/Panel"
import ExplainedLoading                from "../../components/loading"
import Alert                           from "../../components/alert/Alert"
import GoBack                          from "../../components/Button/ButtonGoBack"
import UserPermissionBoundary          from "../../components/permissions/UserPermissionBoundary"

interface GeoBidsProps{
  geoTargetings        : MultiLanguageGeoTargeting[]
  accountId            : number
  retrievingTargetings : boolean,
  replaceGeos       : (replacedMatchers:VSGL[], geoTargeting:GeoTargeting)=>void
  //from HOC
  translate         : (translateId:string)=>string
  history
}
const makeMapStateToProps = () => {
  const geoSelector = GeoBidAccessors.makeUniqueGeoWithoutLangSelector()
  return (state) => {
    const accountId = state.Accounts.selected
    return {
      geoTargetings        : geoSelector(state),
      accountId            : accountId,
      retrievingTargetings : state.Params.GeoBids.Retrieving,
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    replaceGeos : (accountId:number, replacedMatchers:VSGL[], geoTargeting:GeoTargeting)=>{
      dispatch(replaceGeos(accountId, replacedMatchers, geoTargeting))
    }
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    replaceGeos : (replacedMatchers:VSGL[], geoTargeting:GeoTargeting)=>{
      DP.replaceGeos(SP.accountId, replacedMatchers, geoTargeting)
    }
  }
}
interface GeoBidsState{
  copiedGeo : number
  selectedToBeReplaced : number[]
}
const formatMatcher = (matcher:Omit<VSGL, 'lang'>):string => {
  let type = matcher.vehicleType?matcher.vehicleType:""
  let state = matcher.state?matcher.state:""
  let group = matcher.group
  return type+"  "+state+"  "+group
}
const PermissionWrapper = (props) => (
  <UserPermissionBoundary
    userLevel={4}
    permissions={[]}
    onFailure={()=><div>Access denied</div>}
  >
    <CopyAndReplaceGeos {...props}/>
  </UserPermissionBoundary>
)
class CopyAndReplaceGeos extends React.Component<GeoBidsProps, GeoBidsState>{
  private circleOptions = {fillColor:"#01B9F5", strokeColor:"#012B74", strokeWeight:"1"}
  constructor(props){
    super(props)
    this.state = {
      copiedGeo : -1,
      selectedToBeReplaced : []
    }
    this.navigationGoBack = this.navigationGoBack.bind(this)
    this.resetState = this.resetState.bind(this)
    this.copyGeo = this.copyGeo.bind(this)
    this.selectToReplaceGeo = this.selectToReplaceGeo.bind(this)
    this.deselectToReplaceGeo = this.deselectToReplaceGeo.bind(this)
    this.replaceGeos = this.replaceGeos.bind(this)
  }
  componentDidUpdate(prevProps){
    if(prevProps.accountId != this.props.accountId || prevProps.geoTargetings.length != this.props.geoTargetings.length){
      this.resetState()
    }
  }
  navigationGoBack(){RoutingUtils.removeLastElementOfUrl(this.props.history)}
  resetState(){
    this.setState({
      copiedGeo : -1,
      selectedToBeReplaced : []
    })
  }
  copyGeo(index){this.setState({copiedGeo:index})}
  selectToReplaceGeo(index){this.setState(prevState=>({
    selectedToBeReplaced: [...prevState.selectedToBeReplaced,index]
  }))}
  deselectToReplaceGeo(index){this.setState(prevState=>({
    selectedToBeReplaced: prevState.selectedToBeReplaced.filter(x=>x!==index)
  }))}
  replaceGeos(){
    if(this.state.copiedGeo>=0 && this.state.selectedToBeReplaced.length>0){
      const matchers:VSGL[] = []
      for(const index of this.state.selectedToBeReplaced){
        for(const lang of this.props.geoTargetings[index].languages){
          matchers.push({...this.props.geoTargetings[index].matcher, lang})
        }
      }
      const {languages, ...targeting} = {
        ...this.props.geoTargetings[this.state.copiedGeo],
        matcher: {...this.props.geoTargetings[this.state.copiedGeo].matcher, lang: this.props.geoTargetings[this.state.copiedGeo].languages[0]}
      }
      this.props.replaceGeos(matchers, targeting)
      this.navigationGoBack()
    }
  }
  render(){
    if(this.props.retrievingTargetings){return <ExplainedLoading translateId="loadings.geoTargeting"/>}
    const hasCopied = this.state.copiedGeo>=0 && this.state.copiedGeo < this.props.geoTargetings.length
    return (
      <Panel title="GeoBids" subtitle={this.props.translate("geoTarget.copyAndReplace.subtitle")}>
        <GoBack onClick={this.navigationGoBack}/>
        <div className="CopyAndReplaceGeo">
          {hasCopied?this.renderReplacedSelection():this.renderCopySelection()}
        </div>
      </Panel>
    )
  }
  renderCopySelection(){
    return (
      <div>
        <div className="ExplanationText"><Translate id="geoTarget.copyAndReplace.explanationMessage1"/></div>
        <div className="GeoBidsParams">
          {this.props.geoTargetings.map((geoTargeting,i)=>
            <MapDisplayOnly
              key={this.props.accountId+" "+i}
              geoTargeting={geoTargeting}
              title={formatMatcher(geoTargeting.matcher)}
              onClick={()=>this.copyGeo(i)}
              useQuickZoom={false}
              circleOptions={this.circleOptions}
            />
          )}
        </div>
      </div>
    )
  }
  renderReplacedSelection(){
    const copiedGeo = this.props.geoTargetings[this.state.copiedGeo]
    return (
      <div className="ReplacedSectionContainer">
        <div className="ReplacedOptions">
          <Button onClick={this.replaceGeos} disabled={this.state.selectedToBeReplaced.length===0}>
            <Translate id="common.confirm"/>
          </Button>
          &nbsp;
          <Button onClick={this.resetState} className="Cancel">
            <Translate id="common.cancel"/>
          </Button>
        </div>
        <div className="ExplanationText"><Translate id="geoTarget.copyAndReplace.explanationMessage2"/></div>
        <MapDisplayOnly
          geoTargeting={copiedGeo}
          title={formatMatcher(copiedGeo.matcher)}
          useQuickZoom={false}
          circleOptions={this.circleOptions}
        />
        <div className="ExplanationText"><Translate id="geoTarget.copyAndReplace.explanationMessage3"/></div>
        <div className="GeoBidsParams">
          {this.props.geoTargetings.reduce((kept, geo, i)=>{
            if(i === this.state.copiedGeo){return kept}
            const selected = ArrayUtils.contain(this.state.selectedToBeReplaced, i)
            const onClick = selected ? ()=>{this.deselectToReplaceGeo(i)} : ()=>{this.selectToReplaceGeo(i)}
            kept.push(
              <span
                key={this.props.accountId+" "+i}
                className={selected?"Selected":"NotSelected"}
                onClick={onClick}
              >
                <MapDisplayOnly
                  geoTargeting={geo}
                  title={formatMatcher(geo.matcher)}
                  onClick={()=>{}}
                  useQuickZoom={false}
                  circleOptions={this.circleOptions}
                />
              </span>
            )
            return kept
          },[])}
        </div>
      </div>

    )
  }
}

export default (
  withRouter(
    withLocalize(
      connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
        PermissionWrapper
      )
    ) as any
  )
)
