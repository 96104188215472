import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"

interface StatusByAccount{
  [accountId:string] : boolean
}
const Done = ReducerUtils.createReducer<StatusByAccount>({},{
  [Actions.PACY_MIGRATION_DONE] : (state,action)=>({...state, [action.accountId]: action.status})
})

export default Done
