import {
  makeStyles,
  Theme,
  createStyles
} from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    containerMessage : {
      marginTop: "100px",
      marginBottom: "50px",
      display: "flex",
      justifyContent: "center",
    },
    message : {
      fontFamily: "ibm-plex-sans, Helvetica Neue, Arial, sans-serif",
      fontSize : "20px",
      textAlign: "center",
      width: "450px",
    },
    containerButtons : {
      display: "flex",
      gap: "20px",
      justifyContent: "center",
      alignItems: "center",
      position: "sticky",
      width: "100%",
    },
    button : {
      margin: "20px",
    }
  })
)

export default useStyles
