import Actions               from "../../model/constant/actions"
import ReducerUtils          from "../../utils/ReducerUtils"

const saveURL = (state, action) => {
  return action.URL
}

export const DesiredURL = ReducerUtils.createReducer<string>("",{
  [Actions.SAVE_DESIRED_URL] : saveURL
})
