import {combineReducers}             from 'redux'
import FetchedShapeCodes             from "./FetchedShapeCodes"
import PlacesShapes                  from "./PlacesShapes"
import ShapesPendingValidation       from "./ShapesPendingValidation"
import ShapesPendingSimplification   from "./ShapesPendingSimplification"
import DuplicateGreenShapes          from "./DuplicateGreenShapes"
import UniqueGreenShapes             from "./UniqueGreenShapes"
import AvailableShapes               from "./AvailableShapes"
import ShapesWithSameCode            from "./ShapesWithSameCode"

const Shape = combineReducers({
  FetchedShapeCodes,
  PlacesShapes,
  ShapesPendingValidation,
  DuplicateGreenShapes,
  UniqueGreenShapes,
  AvailableShapes,
  ShapesPendingSimplification,
  ShapesWithSameCode
})

export default Shape
