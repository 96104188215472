import { Alert }                           from "./Alert"
import { AgentCompatibleProvider }         from "../pacy"
import {
  ProxyGroup,
  getActiveProvidersFromProxyGroup,
  getProxyGroupsToAgentsTreeForProviders,
  ProxyGroupsToAgentsTree
}                                          from "../pacy/ProxyGroup"


export interface PacyV0UsageAlert extends Alert {
  data : AgentCompatibleProvider[]
}

export const displayV0Alert = (alert:PacyV0UsageAlert, proxyGroup:ProxyGroup): boolean => {
  let displayAlert = false
  if(alert?.data?.length > 0){
    const activeProviders = Array.from(getActiveProvidersFromProxyGroup(proxyGroup))
    if(alert.data.some(provider=>activeProviders.includes(provider))){
      displayAlert = true
    }
  }
  return displayAlert
}

export const getActiveProvidersTreeForV0Alert = (alert:PacyV0UsageAlert, proxyGroups:ProxyGroup[]):ProxyGroupsToAgentsTree => {
  if(alert?.data?.length > 0){
    return getProxyGroupsToAgentsTreeForProviders(alert.data, proxyGroups)
  } else {
    return {}
  }
}
