import './AccountWazeView.css'
import * as React                    from "react"
import ArrayUtils                    from "../../../utils/ArrayUtils"
import {Select, SelectItem}          from "carbon-components-react"

interface AccountWazeViewProps {
  currentCompanyId ?: any
  allCompanies      : any[]
  addSaveAction     : (informationName:string, action:()=>void)=>void
  assignCompany     : (companyId)=>void
  unassignCompany   : ()=>void
  editedInformation : string[]
}
const INFORMATION_NAME = "WazeCompany"
const NO_COMPANY = "NO_COMPANY"
interface AccountWazeViewState{
  selected : any
}
class AccountWazeView extends React.Component<AccountWazeViewProps,AccountWazeViewState>{
  constructor(props){
    super(props)
    this.state = {
      selected : this.props.currentCompanyId?this.props.currentCompanyId:NO_COMPANY
    }
    this.onSelectChange = this.onSelectChange.bind(this)
  }
  componentDidUpdate(prevProps){
    if(prevProps.currentCompanyId != this.props.currentCompanyId){
      this.setState({
        selected:this.props.currentCompanyId?this.props.currentCompanyId:NO_COMPANY
      })
    }
  }
  onSelectChange(event){
    const value = event.target.selectedOptions[0].value
    this.setState({selected:value})
    let action
    if(value === NO_COMPANY){ action = ()=>this.props.unassignCompany() }
    else{ action = ()=>this.props.assignCompany(value) }
    this.props.addSaveAction(INFORMATION_NAME, action)
  }
  render(){
    const companies = this.props.allCompanies.sort((a,b)=>ArrayUtils.comp(a.title, b.title))
    return (
      <div className="AccountWazeView">
        <div className="TopPart">
          <h4>Waze</h4>
        </div>
        <hr/>
        <div className="BotPart">
          <span className="Label">Company</span>
          <Select
            onChange={this.onSelectChange}
            className={"WazeCompanySelect"+(ArrayUtils.contain(this.props.editedInformation, INFORMATION_NAME)?" Edited":"")}
            hideLabel
            id="companySelect"
            value={this.state.selected}
          >
            <SelectItem
              value={NO_COMPANY}
              text="None"
            />
            {companies.map(x=>
              <SelectItem
                key={x.id}
                value={x.id}
                text={x.title}
              />
            )}
          </Select>
        </div>
      </div>
    )
  }
}

export default AccountWazeView
