import './BudgetPlannerChangesHistory'
import * as React                  from "react"
import {connect}                   from "react-redux"
import {withLocalize}              from "react-localize-redux"
import BootstrapTable              from 'react-bootstrap-table-next'
import filterFactory               from 'react-bootstrap-table2-filter'
import {textFilter}                from 'react-bootstrap-table2-filter'
import {selectFilter}              from 'react-bootstrap-table2-filter'
import paginationFactory           from 'react-bootstrap-table2-paginator'
import {Button}                    from "carbon-components-react"
import {Panel}                     from "../../../components/Panel/Panel"
import NumFormatter                from '../../../utils/NumberFormatter'
import ArrayUtils                  from "../../../utils/ArrayUtils"
import ObjectUtils                 from "../../../utils/ObjectUtils"
import BudgetPlan                  from "../../../model/Store/BudgetPlan/BudgetPlan"
import CSVUtils                    from "../../../utils/CSVUtils"
import GoBack                      from "../../../components/Button/LinkGoBack"
import requiresBudgetPlans         from "../../../components/loaders/budgetPlansLoader"
import requiresBudgets             from "../../../components/loaders/budgetsLoader"
import requiresAccounts            from "../../../components/loaders/accountsLoader"

interface TableViewBudgetPlanPart {
  Provider       : string
  BudgetName     : string
  Period         : string
  Amount         : number
  SavedOn        : Date
  User           : string
  IsLastRevision : boolean
  RandomID       : number
}

const mapStateToProps = (state,ownProps) => {
  return {
    accountId         : state.Accounts.selected,
    userDescriptions  : state.Users.Descriptions,
  }
}
const mapDispatchToProps = (dispatch) => {return {}}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
  }
}
interface BudgetPlannerChangesHistoryProps{
  accountId        : number
  budgetPlans      : BudgetPlan[]
  budgets          : {budgetId:number, budgetName:string}[]
  userDescriptions : any
  translate       ?: (translateId:string, b?:any, c?:any)=>string
}
const defaultFilter = [{dataField:'SavedOn',order:'desc'}]
class BudgetPlannerChangesHistory extends React.Component<BudgetPlannerChangesHistoryProps,{}>{
  private table = null
  constructor(props){
    super(props)
    this.generateCSV = this.generateCSV.bind(this)
  }
  generateCSV(){
    const header     = ["Period","BudgetName","Amount","IsLastRevision","User","SavedOn",]
    const dataFields = ["Period","BudgetName","Amount","IsLastRevision","User","SavedOn",]
    const data = (
      this.table.store._filteredData.length>0
      ? this.table.store._filteredData
      : this.table.store._data
    )
    CSVUtils.downloadCSV(
      "budgetPlanModifications_"+String(this.props.accountId)+".csv",
      CSVUtils.generateCSV(header, data.map(row=>dataFields.map(key=>row[key])), ",")
    )
  }
  render(){
    const allBudgetNames = []
    const modifications : TableViewBudgetPlanPart[] = this.props.budgetPlans.reduce((a,bp)=>{
      const provider = bp.getProvider()
      const budget = this.props.budgets.find(x=>x.budgetId == bp.getBudgetId())
      const budgetName = budget ? budget.budgetName : "Unknown"
      allBudgetNames.push(budgetName)
      const parts = bp.getParts().reduce((ac, bpp)=>{
        const row = {
          Period         : bpp.getPeriod(),
          Amount         : bpp.getAmount(),
          SavedOn        : bpp.getSavedOn().toLocaleString(),
          User           : bpp.getUserId(),
          Provider       : provider,
          BudgetName     : budgetName,
          IsLastRevision : "Yes",
        }
        const revisions = bpp.getRevisions().reduce((acc,bppr)=>{
          const revisionRow = {
            Period         : bppr.getPeriod(),
            Amount         : bppr.getAmount(),
            SavedOn        : bppr.getSavedOn().toLocaleString(),
            User           : bppr.getUserId(),
            Provider       : provider,
            BudgetName     : budgetName,
            IsLastRevision : "No",
          }
          return [...acc,revisionRow]
        },[])
        return [...ac, row, ...revisions]
      },[])
      return [...a, ...parts]
    },[])
    .map(x=>{
      const description = this.props.userDescriptions[x.User]
      return {
        ...x,
        RandomID:Math.random(),
        User : description!==undefined ? description.email : "UserID : "+String(x.User) // fallback to userId when no description known
      }
    })
    const budgetNameFilter = {
      options : ObjectUtils.getMirrorObjectFromArray(
        ArrayUtils.unique(allBudgetNames)
      )
    }
    return <div className='BudgetPlannerChangesHistory'>
      <Panel
        title={this.props.translate("budgets.history.title")}
        subtitle={this.props.translate("budgets.history.subtitle")}
      >
        <GoBack to={"Planner"}/>
        <Button className="CSVButton" onClick={this.generateCSV}>CSV</Button>
        <BootstrapTable
          hover
          data={modifications}
          ref={table=>this.table = table}
          keyField='RandomID'
          columns={[
            //{ dataField:'Provider',        text:"Provider",       sort:true, filter:textFilter({delay:100})},
            {
              dataField:'BudgetName',
              text:this.props.translate("budgets.history.budgetName"),
              headerClasses:"centeredColumn",
              sort:true,
              filter:selectFilter(budgetNameFilter),
            },{
              dataField:'Period',
              classes:"centeredColumn",
              headerClasses:"centeredColumn",
              text:this.props.translate("budgets.history.period"),
              sort:true,
              filter:textFilter({delay:100})
            },{
              dataField:'IsLastRevision',
              text:this.props.translate("budgets.history.isLastRevision"),
              sort:true,
              classes:"centeredColumn",
              headerClasses:"centeredColumn",
              filter:selectFilter({
                placeholder : this.props.translate("budgets.history.yesNo"),
                options : {
                  "Yes": this.props.translate("common.yes"),
                  "No": this.props.translate("common.no")
                },
              }),
              formatter:(cell,row)=>this.props.translate("common."+cell.toLowerCase(), null, {onMissingTranslation:()=>cell})
            },{
              dataField:'Amount',
              text:this.props.translate("budgets.history.amount"),
              headerClasses:"centeredColumn",
              classes:"centeredColumn",
              sort:true,
              filter:textFilter({delay:100}),
              formatter:amount=>NumFormatter.formatCurrency(amount)
            },{
              dataField:'User',
              text:this.props.translate("budgets.history.user"),
              headerClasses:"centeredColumn",
              classes:"centeredColumn",
              sort:true,
              filter:textFilter({delay:100})
            },{
              dataField:'SavedOn',
              text:this.props.translate("budgets.history.savedOn"),
              headerClasses:"centeredColumn",
              classes:"centeredColumn",
              sort:true,
              filter:textFilter({delay:100})
            },
          ]}
          defaultSorted={defaultFilter}
          filter={filterFactory()}
          pagination={paginationFactory({
            hideSizePerPage: true,
            sizePerPage : 50
          })}
          classes={"normalSizedDatatable BudgetPlannerChangesHistoryDatatable"}
        />
      </Panel>
    </div>
  }
}

export default requiresAccounts(
  requiresBudgetPlans(
    requiresBudgets(
      withLocalize(
        connect(mapStateToProps,mapDispatchToProps,mergeProps)(BudgetPlannerChangesHistory)
      )
    )
  )
)
