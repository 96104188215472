import "./AddGuidelineDetails.css"
import * as React                  from "react"
import {
  ProxyGroup,
}                                  from "../../../../../../model/pacy/ProxyGroup"
import {
  AddGuidelineDecision,
}                                  from "../../../../../../model/pacy/Decision"
import {
  AccountState
}                                  from "../../../../../../model/pacy/AccountState"
import {
  isPreciseGuideline,
  getPreviousGuideline
}                                  from "../../../../../../model/pacy/Guideline"
import PrecisedGuidelineDetails    from "./PrecisedGuidelineDetails"
import VagueGuidelineDetails       from "./VagueGuidelineDetails"
import { connect }                 from "react-redux"
import {retrieveAccountState}      from "../../../../../../actions/pacy/AccountState"
import { LinearProgress }          from "@material-ui/core"

interface AddGuidelineDetailsOwnProps{
  decision            : AddGuidelineDecision
  concernedProxyGroup : ProxyGroup
}
interface AddGuidelineDetailsProps extends AddGuidelineDetailsOwnProps {
  retrievingAccountState : boolean
  accountStates         ?: {[accountStateId:string] : AccountState}
  retrieveAccountState   : () => void
}

const mapStateToProps = (state,ownProps:AddGuidelineDetailsOwnProps)=>{
  const accountId = state.Accounts.selected
  return {
    accountId,
    retrievingAccountState : state.Pacy.Retrieving.AccountStates,
    accountStates          : state.Pacy.AccountStates[accountId]
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    retrieveAccountState : (accountStateId:string, accountId:number) => {
      dispatch(retrieveAccountState(accountId,accountStateId))
    }
  }
}
const mergeProps = (SP,DP,ownProps:AddGuidelineDetailsOwnProps)=>{
  return {
    ...SP,...DP,...ownProps,
    retrieveAccountState : () => {
      DP.retrieveAccountState(ownProps.decision.details.accountStateId, SP.accountId)
    }
  }
}

const AddGuidelineDetails = (props:AddGuidelineDetailsProps) => {
  const stateId = props.decision.details.accountStateId
  if(!props.accountStates || !props.accountStates[stateId]){
    if(!props.retrievingAccountState) {
      props.retrieveAccountState()
    }
    return (
      <div className="AddGuidelineDetails Loader">
        <LinearProgress variant="indeterminate" />
      </div>
    )
  }
  const prevGuideline = getPreviousGuideline(props.decision.details.guideline, props.concernedProxyGroup.guidelines)
  const accountStateProxyGroups = props.accountStates[stateId].proxyGroups
  const concernedProxyGroup = accountStateProxyGroups.find(proxyGroup => proxyGroup.id === props.concernedProxyGroup.id) as any
  return (
    <div className="AddGuidelineDetails">
      {isPreciseGuideline(props.decision.details.guideline)
        ? <PrecisedGuidelineDetails
            guideline={props.decision.details.guideline}
            concernedProxyGroup={concernedProxyGroup}
            prevGuideline={prevGuideline}
          />
        : <VagueGuidelineDetails
            guideline={props.decision.details.guideline}
            concernedProxyGroup={concernedProxyGroup}
            prevGuideline={prevGuideline}
          />
      }
    </div>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(AddGuidelineDetails) as React.ComponentType<AddGuidelineDetailsOwnProps>
