import './ProxyGroupParams.css'
import * as React                                 from 'react'
import {
  Typography,
  Switch,
  Icon,
  Button,
  Modal
}                                                 from '@material-ui/core'
import { ProxyGroup }                             from '../../../../model/pacy/ProxyGroup'
import EditableMuiText                            from '../../../../components/EditableMuiText/EditableMuiText'
import WeightsConfiguration                       from './WeightsConfiguration'
import {
  AccountModifier,
  createSetProxyGroupDescriptionAccountModifier,
  createSetProxyWeightAccountModifier,
  createToggleProxyAccountModifier,
  createToggleProxyGroupSharingAccountModifier
}                                                 from '../../../../model/pacy/AccountModifiers'
import CreateProxy                                from './Components/CreateProxy'
import { Translate }                              from 'react-localize-redux'

interface ProxyGroupParamsOwnProps {
  proxyGroups         : ProxyGroup[]
  proxyGroup          : ProxyGroup
  addAccountModifiers : (newActions:AccountModifier[]) => void
}
interface ProxyGroupParamsProps extends ProxyGroupParamsOwnProps {}

const ProxyGroupParams = (props:ProxyGroupParamsProps) => {
  const [openCreateProxy,setOpenCreateProxy] = React.useState(false)
  const handleOpenCreateProxy = () => setOpenCreateProxy(true)
  const handleCloseCreateProxy = () => setOpenCreateProxy(false)
  const [openModal,setOpenModal] = React.useState(false)
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleEnableSharing = (event) => {
    props.addAccountModifiers([createToggleProxyGroupSharingAccountModifier(props.proxyGroup.id,event.target.checked)])
  }
  const handleDescriptionChange = (event) => {
    props.addAccountModifiers([createSetProxyGroupDescriptionAccountModifier(props.proxyGroup.id,event.target.value)])
  }
  const handleWeightsChange =(proxiesChanged:{[id:string]:number},proxiesActivated:string[])=>{
    const weightsChangeActions = Object.keys(proxiesChanged).map(proxyId=>{
      return createSetProxyWeightAccountModifier(proxyId,proxiesChanged[proxyId],props.proxyGroup.id)
    })
    const activateActions = proxiesActivated.map(proxyId=>{
      return createToggleProxyAccountModifier(proxyId,true,props.proxyGroup.id)
    })
    const newActions = [...weightsChangeActions,...activateActions]
    props.addAccountModifiers(newActions)
  }

  return (
    <div className="ProxyGroupParams">
      <div>
        <Typography className="Sharing" variant="button">
          <Icon color="primary" className="ShareIcon">call_split</Icon>
          <Translate id="pacyConfigs.sharing" />
          <Switch
            onChange={handleEnableSharing}
            checked={props.proxyGroup.sharing}
            color="primary"
          />
        </Typography>
        <EditableMuiText
          baliseType="body1"
          content={props.proxyGroup.description}
          handleInputChange={handleDescriptionChange}
          iconStyle={{color:'#CCC'}}
          type="textArea"
          maxLength={256}
        />
      </div>
      <div className="Buttons">
        <Button
          onClick={handleOpenModal}
          style={{display:'flex',alignItems:'center',marginTop:10}}
          size='large'
          color="primary"
          disabled={props.proxyGroup.proxies.length === 0}
        >
          <Translate id="pacyConfigs.weightsConfig" />&nbsp;
          <Icon>build</Icon>
        </Button>
        <Button
          onClick={handleOpenCreateProxy}
          size="small"
          variant="outlined"
          color="primary"
          style={{color:'#00B2E3',display:'flex',alignItems:'center'}}
        >
          <Icon style={{marginRight:'5px'}}>add</Icon>
          <Translate id="pacyConfigs.createProxy" />
        </Button>
      </div>
      <Modal
        style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}
        open={openModal}
        onClose={handleCloseModal}
      >
        <div>
          <WeightsConfiguration
            proxies={props.proxyGroup.proxies}
            handleCloseModal={handleCloseModal}
            handleWeightsChange={handleWeightsChange}
          />
        </div>
      </Modal>
      <Modal className="CreateProxyModal" open={openCreateProxy} onClose={handleCloseCreateProxy}>
        <div>
          <CreateProxy
            addAccountModifiers={props.addAccountModifiers}
            proxyGroups={props.proxyGroups}
            selectedProxyGroupId={props.proxyGroup.id}
            handleCloseModal={handleCloseCreateProxy}
          />
        </div>
      </Modal>
    </div>
  )
}

export default ProxyGroupParams
