import './TrendyCars.css'
import * as React                from "react"
import {Translate}               from "react-localize-redux"
import BootstrapTable            from 'react-bootstrap-table-next'
import filterFactory             from 'react-bootstrap-table2-filter'
import paginationFactory         from 'react-bootstrap-table2-paginator'
import InventoryFriend           from "../../../model/Store/Campaign/InventoryFriend"
import ArrayUtils                from "../../../utils/ArrayUtils"
import ObjectUtils               from "../../../utils/ObjectUtils"
import StatisticsUtils           from "../../../utils/StatisticsUtils"
import NumFormatter              from '../../../utils/NumberFormatter'
import ExplainedLoading          from '../../../components/loading'
import Icon                      from "./HighlightIcon"

interface TrendyCarsProps{
  campaigns     : any[]
  campaignStats : any[]
  highlighted  ?: any[]
}
interface VehicleData{
  id                : number
  name              : string
  impressions       : number
  marketImpressions : number
  delta             : number
}

const compileData = (campaigns, campaignStats, highlighted:any[]):VehicleData[] => {
  let vehicles = []
  let marketAverage
  const friends = campaigns.filter(x=>x instanceof InventoryFriend)
  let statsByVehicle = campaignStats
  .reduce((byVehicle,stat)=>{
    for(let friend of friends){
      if(ArrayUtils.contain(friend.getIds(),stat.GenericId)){
        for(let vehicle of friend.getInventory()){
          if(vehicle.getYear()!=0){
            stat.YMM = [
              vehicle.getYear(),
              vehicle.getMake(),
              vehicle.getModel(),
            ].join(" ")
          }
          else{
            stat.YMM = [
              vehicle.getMake(),
              vehicle.getModel(),
            ].join(" ")
          }
          if(!byVehicle[stat.YMM]){byVehicle[stat.YMM] = [stat]}
          else{byVehicle[stat.YMM].push(stat)}
        }
        break
      }
    }
    return byVehicle
  },{})
  if(Object.keys(statsByVehicle).length > 0){
    vehicles = ObjectUtils.getObjectValues(
      ObjectUtils.mapOnObject(
        statsByVehicle,
        (key,value)=>{
          const impressions = value.reduce((imp, stat)=>imp+stat.Impressions,0)
          const impShareSet = (
            value
            .filter(x=>x.SearchImpressionShare!==null)
            .map(x=>[x.Impressions,x.SearchImpressionShare])
          )
          const impShare = impShareSet.length ? StatisticsUtils.calculateImpressionShare(impShareSet) : 0
          return {
            id : key,
            name : key,
            impressions : impressions,
            marketImpressions : impShare ? impressions*100/impShare : undefined,
        }}
      )
    )
    .filter(x=>x.marketImpressions!==undefined)
    marketAverage = vehicles.reduce((total,vehicle)=>total+vehicle.marketImpressions,0)/vehicles.length
    vehicles = vehicles
    .map(v=>({
      ...v,
      delta : 100*v.impressions/marketAverage,
      highlighted : ArrayUtils.contain(highlighted, v.name)
    }))
    .sort((a,b)=>ArrayUtils.comp(b.delta,a.delta))
  }
  return vehicles
}
const headerTranslator = (col, _, components)=>(
  <div>
    <Translate id={col.text}/>
    {components.sortElement}
    {components.filterElement}
  </div>
)
const TrendyCars = React.forwardRef((props:TrendyCarsProps, ref) => {
  if(!props.campaignStats){return <ExplainedLoading translateId="loadings.stats"/>}
  if(!props.campaigns){return <ExplainedLoading translateId="loadings.campaigns"/>}
  const vehicles = compileData(props.campaigns, props.campaignStats, props.highlighted||[])
  if(!vehicles.length){
    return (
      <div className="EmptyMissedOpportunities">
        <h4><Translate id="dashboard.trendy.noTrendyCars"/></h4>
      </div>
    )
  }
  return (
    <div className="TrendyCars">
      <BootstrapTable
        ref={ref}
        key={vehicles.length} // remount if new data comes in
        keyField='id'
        data={vehicles}
        pagination={paginationFactory({
          sizePerPage : 5,//000,
          hideSizePerPage : true,
          hidePageListOnlyOnePage : true,
          showTotal : false,
        })}
        defaultSorted={[{dataField:'delta',order:'desc'},]}
        columns={[
          {dataField:"id",hidden:true},
          {
            dataField:"name",
            text:"dashboard.trendy.trendy",
            headerFormatter:headerTranslator,
            formatter: (name, row)=>{
              if(row.highlighted){
                return (
                  <div className="highlightedRow">
                    <Icon tooltipTranslateId="dashboard.trendy.highlightTooltip"/>
                    {name}
                  </div>
                )
              }
              return name
            }
          },{
            dataField:"delta",
            text: "dashboard.trendy.interest",
            headerFormatter:headerTranslator,
            classes:"fixedSizedColumn",
            headerClasses:"fixedSizedColumn",
            formatter:x=>"+"+NumFormatter.formatNumber(x, 2)+"%"
          },
        ]}
        filter={filterFactory()}
        classes="normalSizedDatatable TrendyCarsDataTable"
      />
    </div>
  )
})

export default TrendyCars
