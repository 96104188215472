import './RatioChart.css'
import * as React         from 'react'
import NumberFormatter    from "../../utils/NumberFormatter"


interface RatioChartProps {
  numberOk : number
  numberTotal : number
  colorOk ?: string
  colorNotOk ?: string
}
const RatioChart:React.SFC<RatioChartProps> = (props:RatioChartProps) => {
  const ratio = 100*props.numberOk/props.numberTotal
  let widthLeft  = ratio
  let widthRight = 100-ratio
  if(ratio!==100 && ratio!==0){
    if(widthLeft<10){
      widthLeft = 10
      widthRight = 90
    }
    else if (widthLeft>90){
      widthLeft = 90
      widthRight = 10
    }
  }
  return (
    <div className='RatioCharParts'>
      <div className="RatioCharPart" style={{backgroundColor:props.colorOk, width:String(widthLeft)+"%"}}>{ratio?NumberFormatter.formatNumber(props.numberOk):""}</div>
      <div className="RatioCharPart" style={{backgroundColor:props.colorNotOk, width:String(widthRight)+"%"}}>{ratio!==100?NumberFormatter.formatNumber(props.numberTotal-props.numberOk):""}</div>
    </div>
  )
}
RatioChart.defaultProps = {
  colorOk : "#3cba54",
  colorNotOk : "#db3236",
}

export default RatioChart
