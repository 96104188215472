import ReducerUtils       from "../../utils/ReducerUtils"
import Actions            from "../../model/constant/actions"

const setStatus = (state:SaveStatus, action):SaveStatus => {
  return {
    status : action.status
  }
}

interface SaveStatus{
  status  : boolean
}
const ConfirmationModal = ReducerUtils.createReducer<SaveStatus>({status: false},{
  [Actions.SHOW_NAVIGATION_CONFIRMATION_MODAL] : setStatus
})

export default ConfirmationModal
