import './DisabledPageOverlay.css'
import * as React      from "react"
import {Translate}     from "react-localize-redux"

interface DisabledPageOverlayProps{
  translateIds : string[] //One id per row
  dismissable  : boolean
}
interface DisabledPageOverlayState{
  dismissed : boolean
}

class DisabledPageOverlay extends React.Component<DisabledPageOverlayProps,DisabledPageOverlayState>{
  constructor(props:DisabledPageOverlayProps){
    super(props)
    this.state = {
      dismissed : false
    }
    this.dismiss = this.dismiss.bind(this)
  }
  dismiss(){this.setState({dismissed: true})}
  render(){
    if(this.state.dismissed){return null}
    return (
      <div className="DisabledPageOverlay">
        {this.props.translateIds.map(x=>
          <React.Fragment key={x}>
            <div><Translate id={x}/></div>
            <br/>
          </React.Fragment>
        )}
        {this.props.dismissable &&
          <div className="DismissOverlayButton" onClick={this.dismiss}>
            <Translate id="common.close"/>
          </div>
        }
      </div>
    )
  }
}

export default DisabledPageOverlay
