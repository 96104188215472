import Actions                                from "../model/constant/actions"
import {StandardVehicleType}                  from '../model/constant/VehicleType'
import {StandardVehicleState}                 from '../model/constant/VehicleState'
import {StandardVehicleTypeEnumReversing}     from '../model/constant/VehicleType'
import {StandardVehicleStateEnumReversing}    from '../model/constant/VehicleState'
import UserActions                            from "../model/constant/UserAction"
import SelectorGroup                          from '../model/engagementRules/SelectorGroup'
import Factory                                from '../model/engagementRules/IDB2ToHeadstoneSelectorFactory'
import HeadstoneEngagementRule, {
  SelectorType,
}                                             from '../model/engagementRules/HeadstoneEngagementRule'
import LogBuilder                             from "../controller/log/LogBuilder"
import Headstone                              from "../utils/Headstone"
import {
  getToken,
  getEngagementRules,
}                                             from "../storeAccessor/Generic"

const getAppliersSuccess = (accountId, response) => {
  return {
    type           : Actions.RECEIVE_APPLIERS,
    selectorGroups : response.data,
    accountId,
  }
}
const getAppliersError = (response) => {
  return {
    type : Actions.RECEIVE_APPLIERS_FAILED,
  }
}

export const loadAppliers = (accountId:number) => (dispatch, getState) => {
  dispatch({type : Actions.RETRIEVING_APPLIERS})
  Headstone.getEngagementRule(getToken(getState()), accountId)
  .then((response) => dispatch(getAppliersSuccess(accountId, response)))
  .catch((error) => dispatch(getAppliersError(error)))
}
export const saveAppliers = (
  accountId         : number,
  vehicleType       : StandardVehicleType,
  vehicleState      : StandardVehicleState,
  rawSelectorGroups : SelectorGroup[]
) => (dispatch, getState) => {
  const token       = getToken(getState())
  const typeString  = StandardVehicleTypeEnumReversing(vehicleType)
  const stateString = StandardVehicleStateEnumReversing(vehicleState)
  const selectorGroups : HeadstoneEngagementRule = rawSelectorGroups.map(group=>({
    name      : `IDB (${typeString}/${stateString})`,
    selectors : [
      ...group.selectors.map(Factory),
      {type : SelectorType.VehicleType,  vehicleType : typeString as any },
      {type : SelectorType.VehicleState, state       : stateString},
    ]
  }))
  // This is what we currently have
  const currentSelectorGroups = getEngagementRules(getState())[accountId]
  // we need to re-send all those selectorGroups
  // that do not fit the (vehicleType,vehicleState) we are currently trying to save
  let keptSelectorGroups : HeadstoneEngagementRule = [...selectorGroups]
  for(const vType of Object.keys(currentSelectorGroups)){
    for(const vState of Object.keys(currentSelectorGroups[vType])){
      if(typeString !== vType || stateString !== vState){
        // we are not replacing those, we need to include them or they will get erased
        keptSelectorGroups = [
          ...keptSelectorGroups,
          ...currentSelectorGroups[vType][vState].map(x=>({
            name      : `IDB (${vType}/${vState})`,
            selectors : [
              ...x.selectors.map(Factory),
              {type : SelectorType.VehicleType,  vehicleType : vType },
              {type : SelectorType.VehicleState, state       : vState},
            ]
          }))
        ]
      }
    }
  }
  LogBuilder.log(UserActions.SAVED_ENG_RULES,token, {
    accountId,
    selectorGroups,
    vehicleType    : typeString,
    vehicleState   : stateString,
  })
  Headstone.setEngagementRule(token, accountId, keptSelectorGroups)
  .then((response) => dispatch(loadAppliers(accountId)))
  .catch((error)=>{console.error(error)})
}
