import {combineReducers}              from "redux"
import {Web}                          from "./Web"
import {NeedToFetchWebLeads}          from "./NeedToFetchWebLeads"
import {NeedToFetchNavigations}       from "./NeedToFetchNavigations"
import {FetchingNavigations}          from "./FetchingNavigations"
import {FetchingWebLeads}             from "./FetchingWebLeads"
import {SoldVehicles}                 from "./SoldVehicles"
import {SoldVehiclesCompiling}        from "./SoldVehiclesCompiling"


export const Tracking = combineReducers({
  Web,
  NeedToFetchWebLeads,
  NeedToFetchNavigations,
  FetchingWebLeads,
  FetchingNavigations,
  SoldVehicles,
  SoldVehiclesCompiling,
})
