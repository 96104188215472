import './index.css'
import * as React              from "react"
import {connect}               from 'react-redux'
import requiresUsers           from "../../components/loaders/usersLoader"
import requiresUserPermissions from "../../components/loaders/userPermissionsLoader"
import ProfileViewer           from "../../page/profile/component/ProfileViewer"
import AccountView             from "../account/component/AccountView"
import Account                 from "../../model/Store/Account/Account"
import requiresAccounts        from "../../components/loaders/accountsLoader"
import GoBack                  from "../../components/Button/LinkGoBack"
import {Panel}                 from "../../components/Panel/Panel"
import PermissionsManager      from "../../components/permissions/PermissionsManager/"
import { setTargetUser }       from '../../actions/user/setTargetUser'

interface UserAccountViewOwnProps {
  userId       : number
  accountId    : number
  users        : any[]
  account      : Account
  accounts     : Account[]
  path         : string
}
const mapStateToProps = (state,ownProps)=>{
  const accountId = ownProps.accountId
  return {
    account : accountId===0 ? null : ownProps.accounts.find(a=>accountId==a.id),
    statusUpdate : state.Permissions.removePermissionMessage,
    targetUser   : state.Users.TargetUser,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    setTargetUser : (userId: number) => dispatch(setTargetUser(userId)),
  }
}

const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps,
    setTargetUser : (userId: number) => DP.setTargetUser(userId),
  }
}

type UserAccountViewProps = ReturnType<typeof mergeProps>

const UserAccountView = (props:UserAccountViewProps) => {
  const user = props.users.find(x=>x.id === props.userId)

  if (props.userId !== props.targetUser) {
    props.setTargetUser(props.userId)
  }

  if(!user){return <span>User doesn't exist</span>}

  const allAccounts = props.account===null

  return (
    <Panel title="Permissions">
      <GoBack to={props.path.split("/").slice(0,-1).join("/")}/>
      <div className='UserAccountView'>
        <div className='UserAccountView-header'>
          <div><ProfileViewer user={user} /></div>
          <div>
            {
              allAccounts
              ? <h3>All Accounts</h3>
              : <AccountView account={props.account}/>
            }
          </div>
        </div>
        <PermissionsManager targetedUser={user} targetedAccount={props.account}/>
      </div>
    </Panel>
  )
}
export default requiresAccounts(
  requiresUsers(
    requiresUserPermissions(
      connect(mapStateToProps,mapDispatchToProps,mergeProps)(UserAccountView)
    )
  )
)