import './KPIs.css'
import * as React                        from 'react'
import {connect}                         from "react-redux"
import {withLocalize}                    from "react-localize-redux"
import {ScreenSize}                      from "../../../../actions/ScreenSize"
import {setDashboardKPIs}                from "../../../../actions/user/userPreferences"
import ObjectUtils                       from '../../../../utils/ObjectUtils'
import StatisticsAccessors               from '../../../../storeAccessor/Statistics'
import {AVAILABLE_KPIS, DEPRECATED_KPIS} from "../../../../model/constant/KPIs"
import compileStruct                     from './compiler'
import Card                              from '../../../../components/card/Card'
import BudgetsInsight                    from "../budgetsInsight/BudgetsInsight"
import { Tooltip, withStyles, Fade }     from '@material-ui/core'

interface KPIsProps{
  currentStats
  symmetricalStats
  screenWidth
  getScreenSize
  translate
  classes
  userPreferences  : any
  setDashboardKPIs : (kpis:string[])=>void
}
const makeMapStateToProps = ()=>{
  const currentStatsSelector = StatisticsAccessors.makeCampaignsStatsInCurrentPeriodSelector()
  const symmetricalStatsSelector = StatisticsAccessors.makeCampaignsStatsInSymmetricalPeriodSelector()
  return (state,ownProps)=>{
    return {
      currentStats     : currentStatsSelector(state),
      symmetricalStats : symmetricalStatsSelector(state),
      screenWidth      : state.ScreenSize,
      userPreferences  : state.Login.userLogged.preferences,
      userId           : state.Login.userLogged.profile.id,
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getScreenSize : () => dispatch(ScreenSize()),
    setDashboardKPIs : (userId:number, kpis:string[])=>dispatch(setDashboardKPIs(userId, kpis, true))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    setDashboardKPIs : (kpis:string[])=>DP.setDashboardKPIs(SP.userId, kpis)
  }
}
interface KPIsState{
  selectedKPIIndex : number
  kpis : string[]
}

const DEFAULT_KPIS = [
  AVAILABLE_KPIS.impressions,
  AVAILABLE_KPIS.clicks,
  AVAILABLE_KPIS.clickshare,
  AVAILABLE_KPIS.cpc,
  AVAILABLE_KPIS.cost,
  AVAILABLE_KPIS.impressionshare,
]
const KPI_KEY_TO_BUDGET_DETAILS_NAME = {
  [AVAILABLE_KPIS.impressions]     : "impressions",
  [AVAILABLE_KPIS.clicks]          : "clicks",
  [AVAILABLE_KPIS.clickshare]      : "clickShare",
  [AVAILABLE_KPIS.cpc]             : "cpc",
  [AVAILABLE_KPIS.cost]            : "cost",
  [AVAILABLE_KPIS.impressionshare] : "impShare",
  [AVAILABLE_KPIS.conversions]     : "conversions",
  [AVAILABLE_KPIS.ctr]             : "ctr",
  [AVAILABLE_KPIS.conversionrate]  : "conversionrate",
  [AVAILABLE_KPIS.cpcon]           : "cpcon",
}

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#3c3b3bdd',
    color: 'white',
    padding: '5px',
    borderRadius: '5px',
    boxShadow: '1px 3px 7px 2px rgba(0, 0, 0, 0.5)',
    fontSize: '17px',
  }
})(Tooltip);

class KPIs extends React.Component<KPIsProps,KPIsState>{
  constructor(props){
    super(props)
    this.state = {
      kpis             : this.getDefaultSelected(),
      selectedKPIIndex : -1,
    }
    this.deselectKPI         = this.deselectKPI.bind(this)
    this.selectKPIForDetails = this.selectKPIForDetails.bind(this)
    this.onChangeOfKPI       = this.onChangeOfKPI.bind(this)
    this.getDefaultSelected  = this.getDefaultSelected.bind(this)
  }
  componentDidMount(){
    window.addEventListener('resize', this.props.getScreenSize)
  }
  componentWillUnmount(){
    window.removeEventListener("resize", this.props.getScreenSize)
  }
  getDefaultSelected():string[]{
    if(this.props.userPreferences["dashboardkpis"]){
      const userSelected = this.props.userPreferences["dashboardkpis"].split(",")

      // If a user holds preferences for KPIs that don't exist, replace it at random with one that does.
      const KPIsThatExist = userSelected.map((KPI) => {
        if (!AVAILABLE_KPIS[KPI]) {
          const keys = Object.keys(AVAILABLE_KPIS)
          return AVAILABLE_KPIS[keys[Math.floor(Math.random() * keys.length)]]
        }
        return KPI
      })

      if(Object.values(DEPRECATED_KPIS).every(kpi=>!KPIsThatExist.includes(kpi))){
        return KPIsThatExist
      }
    }
    return DEFAULT_KPIS
  }
  selectKPIForDetails(KPIIndex:number){
    if(KPIIndex != this.state.selectedKPIIndex){
      this.setState({selectedKPIIndex:KPIIndex})
    }
    else{ //Clicking on an opened kpi closes it
      this.deselectKPI()
    }
  }
  deselectKPI(){
    this.setState({selectedKPIIndex:-1})
  }
  onChangeOfKPI(replacedKPIIndex:number, selectedKPI:string){
    const kpis = [...this.state.kpis]
    kpis.splice(replacedKPIIndex, 1, selectedKPI)
    this.props.setDashboardKPIs(kpis)
    this.setState({kpis})
  }

  render(){
    if(this.props.screenWidth === -1){this.props.getScreenSize(0)}
    const extraClass = this.state.selectedKPIIndex===-1?"DetailsClosed":"DetailsOpened"
    const compiled = compileStruct(this.state.kpis, this.props.currentStats, this.props.symmetricalStats, this.props.translate)

    return (
      <div className={"MainKPIs " + extraClass}>
        <div className="KPIList">
          {this.state.kpis.map((key,i)=>{
            const value = compiled[key]
            const isSelected = this.state.selectedKPIIndex===i
            return (
              <div key={key+i} className="TransitionWrapper">
                <div
                  className={isSelected?"Selected":""}
                  onClick={()=>this.selectKPIForDetails(i)}
                >
                  {(isSelected && this.props.screenWidth > 1200) &&
                    <div className="BorderHider"/>
                  }
                  <CustomTooltip
                    title={this.props.translate("dashboard.budgetsInsight.KPITooltip"+(isSelected?"Close":"Open"))}
                    enterDelay={450}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 350 }}
                    placement="top"
                  >
                    <div>
                      <Card
                        icon={value.icon}
                        percentBGColor={value.color}
                        number={value.number}
                        text={value.text}
                        percent={value.percent}
                        menuConfig={
                          isSelected
                          ? undefined
                          : {
                              options: ObjectUtils.getObjectValues(AVAILABLE_KPIS).sort().reduce((options, kpi)=>{
                                if(kpi === key){return options} //Don't include the current kpi in the list
                                options.push({
                                  text  : this.props.translate("dashboard.KPIs."+kpi),
                                  value : kpi
                                })
                                return options
                              },[]),
                              onSelect: (selected:string)=>this.onChangeOfKPI(i, selected)
                            }
                        }
                      />

                    </div>
                  </CustomTooltip>
                  {(isSelected && this.props.screenWidth <= 1200) &&
                    <div onClick={(e)=>e.stopPropagation()}>
                      <BudgetsInsight
                        key={this.state.selectedKPIIndex}
                        details={KPI_KEY_TO_BUDGET_DETAILS_NAME[this.state.kpis[this.state.selectedKPIIndex]]}
                        closeHandler={this.deselectKPI}
                        campaignsStats={this.props.currentStats}
                      />
                    </div>
                  }
                </div>
              </div>
            )
          })}
        </div>
        {(this.state.selectedKPIIndex>=0 && this.props.screenWidth > 1200) &&
          <BudgetsInsight
            key={this.state.selectedKPIIndex}
            details={KPI_KEY_TO_BUDGET_DETAILS_NAME[this.state.kpis[this.state.selectedKPIIndex]]}
            closeHandler={this.deselectKPI}
            campaignsStats={this.props.currentStats}
          />
        }
      </div>
    )
  }
}
export default (
  withLocalize(
    connect(makeMapStateToProps, mapDispatchToProps, mergeProps)(
      KPIs
    )
  )
)
