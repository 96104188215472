import * as React          from "react"
import {ToastNotification} from "carbon-components-react"

export default class ErrorBoundary extends React.Component<any,any> {

  constructor(props){
    super(props)
    this.state = {
      hasError  : false,
      error     : "",
      errorInfo : null,
    }
  }
  // Need to be changed for `getDerivedStateFromProps` when we pass to 16.3
  UNSAFE_componentWillUpdate(){
    if(this.state.hasError){this.setState({...this.state,hasError:false})}
  }
  componentDidCatch(error, info) {
    this.setState({
      hasError  : true,
      error     : error,
      errorInfo : info,
    });
  }
  render(){
    if(this.state.hasError){
      return (
        <ToastNotification
          onCloseButtonClick={(e)=> e.preventDefault}
          className="some-class"
          title="Something went wrong"
          caption=""
          subtitle={this.state.error.toString()}
        />
      )
    }
    return this.props.children
  }
}
