import Actions           from "../../../model/constant/actions"
import ReducerUtils      from "../../../utils/ReducerUtils"
import ObjectUtils       from "../../../utils/ObjectUtils"
import BingCampaignStats from "../../../model/Store/Statistics/CampaignStats"

const retrieveCampaignsStatsForAccount = (state,action) =>{
  if (state[action.accountId]){
    var newState = {}
    for (let stat of state[action.accountId] ){ newState[stat.CampaignBingId+stat.Day] = stat }
    for (let stat of action.stats            ){ newState[stat.CampaignBingId+stat.Day] = stat }
    return {...state, [action.accountId] : ObjectUtils.getObjectValues(newState)}
  }
  return {...state,[action.accountId] : action.stats}
}
interface BingCampaignStatsByAccount{
  [accountId:number] : BingCampaignStats[]
}
export const CampaignsStats = ReducerUtils.createReducer<BingCampaignStatsByAccount>({},{
  [Actions.RETRIEVE_BING_CAMPAIGNS_STATS_FOR_ACCOUNTID] : retrieveCampaignsStatsForAccount
})
