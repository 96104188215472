import './MapSpend.css'
import * as React             from 'react'
import {connect}              from "react-redux"
import {createSelector}       from "reselect"
import MapStyle               from './MapStyle'
import MapBuilder             from '../../../../components/Map/MapBuilder'
import {addCodesToQueue}      from "../../../../actions/EditGeoBid"
import loadShapes             from "../../../../actions/shapes/RetrieveShapes"
import ArrayUtils             from '../../../../utils/ArrayUtils'
import MapUtils               from '../../../../utils/MapUtils'
import Statistics             from '../../../../storeAccessor/Statistics'
import ExplainedLoading       from '../../../../components/loading'
import ShowTargetingControl   from "./ShowTargetingControl"
import {filterStatRow}        from "./filterStats"

interface MapSpendOwnProps{
  strategiesLooseName  : string[]
  showPlacesWithShapes : boolean
}
interface MapSpendProps extends MapSpendOwnProps{
  loadingShapes : boolean
  geoTargetings
  AWPlaces
  cache
  metacache
  codesWithoutGPS //Only
  clicksOnAdWords
  showPlacesWithShapes
  placesShapes
}
const filterGeos = (geos:any[], strategies:string[]):any[] => {
  if(geos === undefined){return undefined}
  if(strategies.length === 0){return []}
  const NEW_USED_CONCERNED = ["VDP","SRP_MM","SRP_MMY"]
  let returned = geos.filter(x=>ArrayUtils.contain(strategies, x.matcher.group))
  if(ArrayUtils.contain(strategies, "NEW")){
    returned = [
      ...returned,
      ...geos.filter(x=>x.matcher.state === "NEW" && ArrayUtils.contain(NEW_USED_CONCERNED,x.matcher.group))
    ]
  }
  if(ArrayUtils.contain(strategies, "USED")){
    returned = [
      ...returned,
      ...geos.filter(x=>x.matcher.state === "USED" && ArrayUtils.contain(NEW_USED_CONCERNED,x.matcher.group))
    ]
  }
  return returned
}
const makeMapStateToProps = () => {
  const buildFilter = (state,ownProps) => (row, index) => filterStatRow(row, ownProps.strategiesLooseName)
  const compiledStatsSelector = Statistics.makeCompiledForMapGeoBidStatsSelector(buildFilter)
  const filteredGeoTargetingsSelector = createSelector(
    (state:any)=>state.Accounts.selected,
    (state:any)=>state.Params.GeoBids.GeoTargetings,
    (_,strategiesLooseName)=>strategiesLooseName,
    (accountId, geoTargetings, strategiesLooseName)=>filterGeos(geoTargetings[accountId], strategiesLooseName)
  )
  return (state,ownProps:MapSpendOwnProps) => {
    const compiled = compiledStatsSelector(state, ownProps)
    return {
      token             : state.Login.userLogged.token,
      geoTargetings     : filteredGeoTargetingsSelector(state, ownProps.strategiesLooseName),
      codesWithoutGPS   : compiled.codesWithoutGPS,
      clicksOnAdWords   : compiled.clicksOnAdWords,
      AWPlaces          : state.Params.GeoBids.AdWordsPlaces,
      queue             : state.Params.GeoBids.GeocodingQueue,
      cache             : state.Params.GeoBids.GeocodingCache,
      metacache         : state.Params.GeoBids.GeocodingMetaCache,
      fetchedShapeCodes : state.Params.GeoBids.Shape.FetchedShapeCodes,
      placesShapes      : state.Params.GeoBids.Shape.PlacesShapes,
    }
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    addCodesToQueue : (codes:number[]) => dispatch(addCodesToQueue(codes)),
    loadShapes      : (codes:number[]) => dispatch(loadShapes(codes))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  let loadingShapes = false
  if(SP.codesWithoutGPS.length){
    const codesNotInQueue = SP.codesWithoutGPS.filter(x=>ArrayUtils.contain(SP.queue, x))
    if(codesNotInQueue.length){
      DP.addCodesToQueue(codesNotInQueue)
    }
  }
  if(SP.geoTargetings && SP.geoTargetings.length){
    let codesNeedingShape = []
    for(let geo of SP.geoTargetings){
      codesNeedingShape.push(
        ...geo.places.map(x=>x.code).filter(code=>!ArrayUtils.contain(SP.fetchedShapeCodes, code))
      )
    }
    codesNeedingShape = ArrayUtils.unique(codesNeedingShape)
    if(codesNeedingShape.length){
      loadingShapes = true
      DP.loadShapes(codesNeedingShape)
    }
  }
  return {
    ...SP,...DP,...ownProps,
    loadingShapes
  }
}
class MapSpend extends React.Component<any,any>{
  static defaultProps = {
    showPlacesWithShapes : true
  }
  constructor(props){
    super(props)
    this.state = {
      showTargeting : true
    }
    this.buildCircles = this.buildCircles.bind(this)
    this.buildPlaces  = this.buildPlaces.bind(this)
  }
  buildCircles(){
    return ArrayUtils.uniqueFunc(
      (this.props.geoTargetings ? this.props.geoTargetings : [])
      .reduce((acc,g)=>{
        for(let x of g.radiuses){acc.push(x)}
        return acc
      },[])
      .map((x,i)=>({
        id : i,
        edited : false,
        bidModifier : x.bidModifier,
        circleProps : {
          visible : this.state.showTargeting,
          center : {lat:x.lat, lng:x.lng},
          radius : x.radius*1000,
          editable : false,
          options : {
            zIndex : 1,
            fillColor : "#01B9F5",
            strokeColor : "#012B74",
            strokeWeight : 1,
          }
        }
      })),
      x=>[
        x.circleProps.center.lat,
        x.circleProps.center.lng,
        x.circleProps.radius,
        x.bidModifier
      ].join("-")
    )
  }
  buildPlaces(){
    return ArrayUtils.uniqueFunc(
      (this.props.geoTargetings ? this.props.geoTargetings : [])
      .reduce((acc,g)=>{
        for(let x of g.places){acc.push(x)}
        return acc
      },[]),
      x=>[x.code,x.include?1:0].join("-")
    )
    .map((x,i)=>({
      id : i,
      code : parseInt(x.code),
      edited : false,
      include : x.include,
      address : this.props.AWPlaces.find(p=>p[0]==x.code)[2].split(",").join(", "),
      bidModifier : x.bidModifier,
      placeProps : {
        visible : this.state.showTargeting,
        position : (
          this.props.cache[x.code] !== undefined
          ? this.props.cache[x.code]
          : this.props.metacache[x.code]
        ),
        editable : false,
        options : {
          zIndex : 2,
          icon : (
            x.include
            ? "../../../../../assets/img/includedPlace.png"
            : "../../../../../assets/img/excludedPlace.png"
          )
        }
      }
    }))
    .filter(x=>x.placeProps.position!==undefined)
  }
  render(){
    if(this.props.geoTargetings === undefined || this.props.loadingShapes){return <ExplainedLoading translateId="loadings.geoData"/>}
    const heatmapData = MapUtils.buildHeatmapData(
      this.props.clicksOnAdWords,
      this.props.cache,
      this.props.metacache
    )

    return (
      <div className='MapSpend'>
        <div className='Map'>
          <div className="Controls">
            <ShowTargetingControl
              defaultChecked={true}
              onChange={(checked)=>this.setState({showTargeting:checked})}
            />
          </div>
          <MapBuilder
            circlesDefinitions={this.buildCircles()}
            placesDefinitions={this.buildPlaces()}
            heatmapData={heatmapData}
            resizeOnNewCircles
            ignoreExcludedPlaces
            shapes={this.props.showPlacesWithShapes?this.props.placesShapes:{}}
            mapProps={{
              defaultOptions : {
                styles : MapStyle,
                maxZoom : 12,
              }
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(MapSpend)
