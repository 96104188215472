import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const setTargetUser = (state,action) => {
  return action.userId
}
const Retrieving = ReducerUtils.createReducer<string>("",{
  [Actions.SET_TARGET_USER] : setTargetUser,
})
export default Retrieving
