import ReportTableTemplate    from "../ReportTableTemplate"
import ColumnDefinition       from "../ColumnDefinition"
import StatisticsUtils        from "../../../../utils/StatisticsUtils"
import {FORMATTER_NAMES}      from "../../../constant/DataTableFormatters"
import Contexts               from "../../../constant/ReportTableTemplateContexts"
import {Provider}             from "../../../Provider"

export default class BingKeywordReportTableTemplate extends ReportTableTemplate{
  protected context = Contexts.KEYWORD
  protected KEY_COLUMN = "KeywordId"
  protected DEFAULT_COLUMNS = [
    //                    text                datafield       order iskey  hidden sort  formatter  filter  filteroptions  aggregatingKey
    new ColumnDefinition("Keyword Id",       "KeywordId",       0,  true,  true,  true),
    new ColumnDefinition("Keyword",          "Keyword",         1,  false, false, true, undefined, "TEXT",  undefined,  "Keyword"),
    new ColumnDefinition("Conversions",      "Conversions",     3,  false, false, true, FORMATTER_NAMES.ROUND2),
    new ColumnDefinition("Clicks",           "Clicks",          4,  false, false, true, FORMATTER_NAMES.ROUND2),
    new ColumnDefinition("Cost",             "Cost",            5,  false, false, true, FORMATTER_NAMES.MONEY),
    new ColumnDefinition("Impressions",      "Impressions",     6,  false, false, true, FORMATTER_NAMES.ROUND2),
    new ColumnDefinition("CPC",              "CPC",             7,  false, false, true, FORMATTER_NAMES.CPC),
    new ColumnDefinition("CTR",              "CTR",             8,  false, false, true, FORMATTER_NAMES.PERCENTAGE),
  ]
  constructor(id:string, name:string, options:any={defaultSorted:[{dataField:"Keyword",order:"asc"}]}){
    super(id, name, options)
    this.setProvider(Provider.BING)
    this.columns = this.DEFAULT_COLUMNS
  }
  clone(){
    let clone = new BingKeywordReportTableTemplate(
      this.id,
      this.name,
      this.options
    )
    clone.setColumns(this.columns.map(c=>c.clone()))
    clone.setSizePerPage(this.sizePerPage)
    clone.setRevisionOf(this.revisionOf)
    clone.setProvider(this.provider)
    return clone
  }
  protected compile(stats : any[]):any{
    const x = stats.reduce((a,c)=>{
      if(!Object.keys(a).length){
        return {
          ...c,
        }
      }
      return {
        ...c,
        ...a,
        Cost            : a.Cost + c.Cost,
        Clicks          : a.Clicks + c.Clicks,
        Conversions     : a.Conversions + c.Conversions,
        Impressions     : a.Impressions + c.Impressions,
    }},{})
    const y = {
      CPC             : StatisticsUtils.calculateCPC(x.Cost, x.Clicks),
      CTR             : StatisticsUtils.calculateCTR(x.Clicks, x.Impressions),
    }
    return {...x,...y}
  }
}
