import axios             from 'axios'
import Actions           from "../../model/constant/actions"
import {getToken}        from "../../storeAccessor/Generic"
import LogBuilder        from '../../controller/log/LogBuilder'
import UserActions       from "../../model/constant/UserAction"
import {HYPERFEED_HOST}  from "./Feeds"


const declareFeedsSignedUrlsLoading = (dispatch)=>{
  dispatch({type : Actions.RETRIEVING_FEEDS_SIGNED_URLS, status : true})
}

export const retrieveFeedsSignedUrls = (accountId:number) => (dispatch, getState) => {
  declareFeedsSignedUrlsLoading(dispatch)
  axios.get(
    `${HYPERFEED_HOST}/Account/${accountId}/SignedUrls`,
    {
      params  : {legacy: false, showLocked: true},
      headers : {"Authorization" : "Bearer " + getToken(getState())}
    }
  )
  .then((response) => {
    dispatch({
      type : Actions.RETRIEVE_FEEDS_SIGNED_URLS,
      accountId,
      signedUrls : response.data
    })
  })
  .catch(error => console.log(error))
  .then(()=>{dispatch({type : Actions.RETRIEVING_FEEDS_SIGNED_URLS, status : false})})
}
export const deleteFeedsSignedUrl = (accountId:number, url:string) => (dispatch, getState) => {
  const token = getToken(getState())
  const feedToken = url.slice(url.lastIndexOf("/")+1)

  LogBuilder.log(UserActions.DELETE_FEED_SIGNED_URL, token, {accountId, url})

  declareFeedsSignedUrlsLoading(dispatch)
  axios.delete(
    `${HYPERFEED_HOST}/Account/${accountId}/SignedUrl/${feedToken}`,
    {headers : {"Authorization" : "Bearer " + token}}
  )
  .catch(error => console.log(error))
  .then(()=>{retrieveFeedsSignedUrls(accountId)(dispatch, getState)})
}
export const createFeedsSignedUrl = (accountId:number, feedId:string) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.CREATE_FEED_SIGNED_URL, token, {accountId, feedId})

  declareFeedsSignedUrlsLoading(dispatch)
  axios.post(
    `${HYPERFEED_HOST}/Account/${accountId}/Feed/${feedId}/SignedUrl`,
    {}, // payload
    {headers : {"Authorization" : "Bearer " + getToken(getState())}}
  )
  .catch(error => console.log(error))
  .then(()=>{retrieveFeedsSignedUrls(accountId)(dispatch, getState)})
}
