import SearchCampaign                               from "./SearchCampaign"
import Friend                                       from "./Friend"
import {KNOWN_VEHICLE_STATE, StandardVehicleState}  from "../../constant/VehicleState"
import ArrayUtils                                   from "../../../utils/ArrayUtils"

export default class InventoryFriend extends Friend {

  public static validGroups = [
    "SRP_MM",
    "SRP_MMY",
    "VDP",
    "CONQUEST",
  ]

  private inventory : any[]
  private vehicleState : StandardVehicleState

  constructor(accountId:number,campaigns:SearchCampaign[], vehicleState:StandardVehicleState, inventory:any[]=[]){
    super(accountId, campaigns)
    this.vehicleState = vehicleState
    this.setInventory(inventory)
  }
  protected additionnalCampaignsCheck(campaigns:SearchCampaign[]):boolean{
    // same type for every campaign is already asserted
    // atleast one campaign already asserted
    const ok = ArrayUtils.contain(InventoryFriend.validGroups, campaigns[0].type)
    if(!ok){throw Error("InventoryFriend should only have campaigns of type "+InventoryFriend.validGroups.join(", "))}
    return ok
  }
  public clone(){
    return new InventoryFriend(
      this.accountId,
      this.campaigns,
      this.vehicleState,
      this.inventory,
    )
  }
  private setInventory(inventory:any[]){
    this.inventory = inventory
  }
  public withInventory(inventory:any[]){
    this.setInventory(inventory)
    return this
  }
  public getVehicleState():StandardVehicleState{return this.vehicleState}
  public getVehicleStateString():string{
    let vState = KNOWN_VEHICLE_STATE.find(vs=>vs.state===this.vehicleState)
    if(!vState){throw Error("Inventory friend's vehicle state is unknown")}
    return vState.text
  }
  public getInventory():any[]{return this.inventory}
}
