import './Tags.css'
import * as React from 'react'
import {
  Card,
  Paper
}                 from '@material-ui/core'
import Tag        from './Tag'

interface TagBoxOwnProps{
  tags : string[]
}
const Tags = (props:TagBoxOwnProps) => {
  return (
    <Paper className="Tags">
      {props.tags.map((tag,i)=>
        <Tag key={i} tag={tag}/>
      )}
   </Paper>
  )
}

export default Tags
