import ArrayUtils        from "../../../../utils/ArrayUtils"

const NEW_USED_CONCERNED = {
  VDP     : 1,
  SRP_MM  : 1,
  SRP_MMY : 1
}
const NEW_USED_CONCERNED_LIST = ["VDP","SRP_MM","SRP_MMY"]

const filterStats = (stats:any[], strategies:string[], group:boolean=false):any[]|any => {
  if(stats === undefined){return []}
  if(strategies.length === 0){return []}
  const validStats = stats.filter(x=>x.Group)
  let returned = validStats.filter(x=>ArrayUtils.contain(strategies, x.Group))
  if(ArrayUtils.contain(strategies, "NEW")){
    returned = [
      ...returned,
      ...validStats.filter(x=>x.VehicleState === "NEW" && ArrayUtils.contain(NEW_USED_CONCERNED_LIST,x.Group))
    ]
  }
  if(ArrayUtils.contain(strategies, "USED")){
    returned = [
      ...returned,
      ...validStats.filter(x=>x.VehicleState === "USED" && ArrayUtils.contain(NEW_USED_CONCERNED_LIST,x.Group))
    ]
  }
  if(group){
    return strategies.reduce((byStrat:any, strat)=>{
      const isNewUsed = strat === "USED" || strat === "NEW"
      byStrat[strat] = returned.filter(x=>{
        if(isNewUsed){
          return x.VehicleState === strat && ArrayUtils.contain(NEW_USED_CONCERNED_LIST, x.Group)
        }
        return x.Group === strat
      })
      return byStrat
    },{})
  }
  return returned
}
export const filterStatRow = (row, strategies:string[]):boolean => {

  if(strategies.length === 0){return false}
  if(!row.Clicks){return false}
  if(!row.Group){return false}
  const strats = strategies.reduce((acc,s)=>({...acc, [s]:1}),{})
  if(strats["USED"] && row.VehicleState === "USED"){return NEW_USED_CONCERNED[row.Group]}
  if(strats["NEW"]  && row.VehicleState === "NEW" ){return NEW_USED_CONCERNED[row.Group]}
  return strats[row.Group]!==undefined
}
export default filterStats
