import * as React              from "react"
import {Translate}             from "react-localize-redux"
import ReportTableTemplate     from "../../../model/Store/Report/ReportTableTemplate"

interface TemplateChooserProps{
  templates          : ReportTableTemplate[]
  selectedTemplateId : string
  selectHandler      : (templateId:string)=>void
}
const TemplateChooser = (props:TemplateChooserProps) => {
  const chooser =
    <select onChange={(e)=>props.selectHandler(e.currentTarget.value)} value={props.selectedTemplateId}>
      {props.templates.map((template,i)=>
        <option key={template.getId()} value={template.getId()} >{template.getName()}</option>
      )}
    </select>

  return(
    <div>
      <Translate id="reports.ads.view"/>{" : "}
      {props.templates.length>1?
        chooser
      :
        props.templates[0].getName()
      }
    </div>
  )
}

export default TemplateChooser
