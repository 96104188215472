import {ProxyGroup}        from "./ProxyGroup"
import {PacingRatioConfig} from "./PacingRatioConfig"

export interface PacyAccount {
  accountId         : number
  enabled          ?: boolean
  AWCustomerId     ?: string
  bingClientId     ?: string
  pacingRatioConfig : PacingRatioConfig
  proxyGroups       : ProxyGroup[]
  stateId           : string
}

export interface Proxy {
  id                    : string
  name                  : string
  agents                : Agent[]
  weight                : number
  active                : boolean
  sharing               : boolean
  lastUpdatedOn         : string
  beginningOfMonthBoost : BeginningOfMonthBoostConfig
  maxSharing            : number
}

export enum BeginningOfMonthShape {
  "FLAT"   = "FLAT",
  "LINEAR" = "LINEAR"
}
export interface BeginningOfMonthBoostConfig {
  active : boolean
  boost  : number
  length : number
  shape  : keyof typeof BeginningOfMonthShape
}

export enum AgentCompatibleProvider {
  "AW"       = "AW",
  "BING"     = "BING",
  "FACEBOOK" = "FACEBOOK",
}
export interface Agent {
  id            : string
  provider      : AgentCompatibleProvider
  providerId    : string
  name          : string
  lastUpdatedOn : string
  weight        : number
  active        : boolean
  spendAttempts : SpendAttempt[]
  maxSharing    : number
}

export interface SpendAttempt {
  id        : string
  amount    : number
  day       : string
  createdOn : string
}

export interface PossibleAgent {
  provider   : AgentCompatibleProvider
  providerId : string
  name       : string
}

export interface V0Usage {
  readonly accountId: number
  readonly provider: AgentCompatibleProvider[]
}

export const filterPossibleAgentsToUnused = (possibleAgents:PossibleAgent[], proxyGroups:ProxyGroup[]):PossibleAgent[] => {
  //return possibleAgents // YOU CAN USE THIS TO TEST ON 1084
  const allAgents:Agent[] = []
  for(const proxyGroup of proxyGroups){
    for(const proxy of proxyGroup.proxies){
      allAgents.push(...proxy.agents)
    }
  }
  const unusedPossibleAgents = possibleAgents.filter(possibleAgent=>{
    for(const agent of allAgents){
      if(possibleAgent.provider === agent.provider && possibleAgent.providerId === agent.providerId){
        return false
      }
    }
    return true
  })
  return unusedPossibleAgents
}
