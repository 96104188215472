import Authentification     from "../utils/Authentification"
import Actions              from "../model/constant/actions"
import {LogInLoggly}        from "./logger/Logger"

const storeMessage = (error) => {
  let givenToReducer = ""
  if(error instanceof Array){
    if(error.length){givenToReducer = error.join(" - ")}
    else{givenToReducer="OK"}
  }
  else{givenToReducer = String(error)}
  return {
    type    : Actions.FORGOT_PASSWORD_RETRIEVED,
    message : givenToReducer,
  }
}
const resetPassword = (email:string) => dispatch => {
  Authentification.get({
      action : "ForgotPassword",
      email  : email,
    },
    data => {
      LogInLoggly("Successful ForgotPassword", data)
      dispatch(storeMessage(data.error))
    },
    error => {
      LogInLoggly("Failed ForgotPassword", error)
      dispatch(storeMessage(
        "Unable to process your request, please contact technical support at 1-888-912-0668"
      ))
    }
  )
}
export default resetPassword
