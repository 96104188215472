import "./ModalActions.css"
import * as React from "react"
import Button                            from "@material-ui/core/Button"

interface ModalActionsProps{
  migrating       : boolean
  alreadyMigrated : boolean
  done            : boolean
  error          ?: string
  close           : ()=>void
  migrate         : ()=>void
  migrateAgain    : ()=>void
  finish          : ()=>void
}

const ModalActions = (props:ModalActionsProps) => {
  if(props.migrating){return null}
  if(props.error){
    return (
      <Button onClick={props.close} color="primary">
        Ok
      </Button>
    )
  }
  const confirmAction = props.done ? props.finish :
    props.alreadyMigrated ? props.migrateAgain :
      props.migrate
  return (
    <>
      {!props.done &&
        <Button onClick={props.close} color="primary">
          Cancel
        </Button>
      }
      <Button onClick={confirmAction} color="primary">
        {props.done ? "Complete" :
          props.alreadyMigrated ? "Continue" : "Confirm"
        }
      </Button>
    </>
  )
}

export default ModalActions
