import * as React                    from "react"
import {HashRouter,Route,Redirect}   from "react-router-dom"
import {Switch}                      from "react-router-dom"
import {connect}                     from "react-redux"
import {modifySelectedAccountsList}  from "../actions/account/modifySelected"
import ArrayUtils                    from "../utils/ArrayUtils"
import AccountsAccessors             from "../storeAccessor/Accounts"
import AccountPage                   from "../page/account/"
import DashBoard                     from "../page/dashboard"
import AdBuilderRouting              from "./adBuilderRoutes"
import requiresAccounts              from "../components/loaders/accountsLoader"
import {routing as LeadsRouting}     from "./leadsRoutes"
import {routing as CampaignsRouting} from "./campaignsRoutes"
import {routing as ParamsRouting}    from "./paramsRoutes"
import InventoryTurnAnalysis         from "../page/inventoryTurnAnalysis"
import ReportsRouting                from "./reportsRoutes"

interface RouterAccountMapping{
  path      : string
  component : any // Component instance
}
interface AccountRouteProps{
  token              : any
  selectedAccountId  : any
  accounts           : any[]
  selectAccount      : any
  accountId          : any
  defaultAccountPage : string
  allAccountIds      : number[]
}
const mapping : RouterAccountMapping[] = [
  {path:"*/account/:accountid/Dashboard",     component: DashBoard                },
  {path:"*/account/:accountid/account/",      component: AccountPage              },
  {path:"*/account/:accountid/",              component: DashBoard                },
  {path:"*/account",                          component: DashBoard                },
]
const makeMapStateToProps = () => {
  const accountIdsSelector = AccountsAccessors.makeAllAccountIdsSelector()

  return (state, ownProps) => {
    return {
      selectedAccountId  : state.Accounts.selected,
      token              : state.Login.userLogged.token,
      defaultAccountPage : state.Login.userLogged.defaultAccountPage,
      allAccountIds      : accountIdsSelector(state),
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    selectAccount : (accountId:number) =>dispatch(modifySelectedAccountsList(accountId))
  }
}

const AccountRoutingComponent = (props:AccountRouteProps) => {
  let id = ArrayUtils.contain(props.allAccountIds, parseInt(props.accountId, 10))?parseInt(props.accountId, 10):""
  if(id != "" && props.selectedAccountId != props.accountId){
    props.selectAccount(id)
  }
  return (
    <HashRouter>
      <Switch>
        {mapping.map((route:RouterAccountMapping)=>
          <Route exact key={route.path} component={route.component}  path={route.path} />
        )}
        <Route  render={(props)=>
          <LeadsRouting accountId={id}/>}                             path="*/account/:accountid/Lead(s)?/"
        />
        <Route  render={(props)=>
          <AdBuilderRouting accountId={id} history={props.history}/>} path="*/account/:accountid/AdBuilder/"
        />
        <Route  render={(props)=>
          <ReportsRouting/>}                                          path="*/account/:accountid/Report(s)?/"
        />
        <Route  render={(props)=>
          <CampaignsRouting accountId={id}/>}                         path="/account/:accountid/campaign(s)?/"
        />
        <Route  render={(props)=>
          <ParamsRouting accountId={id}/>}                            path="*/Param(s)?/"
        />
        <Route  render={(props)=>
          <InventoryTurnAnalysis />}                                  path="*/InventoryTurnAnalysis/"
        />
        <Redirect from="/" to={"/Account/"+(id?id:0)+props.defaultAccountPage}/>
      </Switch>
    </HashRouter>
  )
}
export const routing = requiresAccounts(connect(makeMapStateToProps,mapDispatchToProps)(AccountRoutingComponent))
