import * as React                   from "react"
import {connect}                    from "react-redux"
import {withLocalize, Translate}    from "react-localize-redux"
import {createSelector}             from "reselect"
import StatisticsUtils              from "../../../../utils/StatisticsUtils"
import NumFormatter                 from '../../../../utils/NumberFormatter'
import Statistics                   from "../../../../storeAccessor/Statistics"
import ExplainedLoading             from "../../../../components/loading/"
import filterStats                  from "./filterStats"

const makeMapStateToProps = () => {
  const campaignsStatsSelector = Statistics.makeCampaignsStatsInCurrentPeriodSelector()
  const compiledStatsSelector = createSelector(
    campaignsStatsSelector,
    state=>state.Statistics.Retrieving.Campaigns,
    (_,props)=>props.strategies,
    (campaignsStats, retrievingCampaignsStats, strategies)=>{
      if(retrievingCampaignsStats){return undefined}
      const stratNames = strategies.map(x=>x.looseName)
      const statsByStrategy = filterStats(campaignsStats, stratNames, true)
      if(Array.isArray(statsByStrategy) && statsByStrategy.length === 0){return {}}
      const stats = []
      for(const strat of stratNames){
        Array.prototype.push.apply(stats, statsByStrategy[strat])
      }
      const impressionShare = StatisticsUtils.calculateImpressionShare(
        stats.reduce((acc,x)=>{
          if(x.SearchImpressionShare===null){return acc}
          acc.push([x.Impressions,x.SearchImpressionShare])
          return acc
        },[])
      )
      const lostImpressionShare = StatisticsUtils.calculateLostImpressionShare(
        stats.reduce((acc,x)=>{
          if(x.SearchImpressionShare===null || x.SearchBudgetLostImpressionShare===null){return acc}
          // Approximating market impressions
          acc.push([x.Impressions * 100 / x.SearchImpressionShare, x.SearchBudgetLostImpressionShare])
          return acc
        },[])
      )

      const lostImpShareByBudget = {}
      for(const strat of stratNames){
        const budget = strategies.find(x=>x.looseName===strat).name
        lostImpShareByBudget[budget] = (
          StatisticsUtils.calculateLostImpressionShare(
            statsByStrategy[strat].reduce((acc,x)=>{
              if(x.SearchImpressionShare===null || x.SearchBudgetLostImpressionShare===null){return acc}
              acc.push([x.Impressions * 100 / x.SearchImpressionShare, x.SearchBudgetLostImpressionShare])
              return acc
            },[])
          )
        )
      }

      return {impressionShare, lostImpressionShare, lostImpShareByBudget}
    }
  )
  return (state,ownProps) => {
    return {
      budgets : state.Params.BudgetsPlans.Budgets[state.Accounts.selected],
      ...compiledStatsSelector(state, ownProps)
    }
  }
}
const calculateAdditionalBudget = (spent:number, lostImpressionShare:number, daysCount:number):number => {
  return 30.4*(spent*(lostImpressionShare/100)/daysCount)
}
const formatPercentage = (x)=> <span className='amount'>{NumFormatter.formatNumber((x<0?0:x), 2)}%</span>
const formatCurrency = (x)=> <span className='amount'>{NumFormatter.formatCurrency((x<0?0:x))}</span>
const SpendInfo = (props) => {
  if(props.lostImpressionShare===undefined || props.impressionShare===undefined){
    return <ExplainedLoading translateId="loadings.stats"/>
  }
  const daysCount = Object.keys(props.budgetStats.reduce((acc,x)=>{acc[x.Day]=1;return acc;},{})).length
  let spentSum = 0
  let additionnalBudget = 0
  for(const budget of props.budgetNames){
    const spent = props.budgetStats.filter(x=>x.BudgetName === budget).reduce((sum,x)=>sum+x.Spent,0)
    additionnalBudget += calculateAdditionalBudget(spent, props.lostImpShareByBudget[budget], daysCount)
    spentSum += spent
  }

  return (
    <div className="SpendInfo">
      <h3><Translate id="dashboard.mapSpend.adSpend"/></h3>
      <h5>{props.strategies.map(x=>props.translate("common."+x.translationId)).join(" + ")}</h5>
      <div>
        <Translate
          id="dashboard.mapSpend.spent"
          data={{value:formatCurrency(spentSum), day:NumFormatter.formatNumber(daysCount)}}
        />
      </div>
      <div>
        <Translate
          id="dashboard.mapSpend.share"
          data={{value:formatPercentage(props.impressionShare)}}
        />
      </div>
      <div>
        <Translate
          id="dashboard.mapSpend.shareLost"
          data={{value:formatPercentage(props.lostImpressionShare)}}
        />
      </div>
      <div>
        <Translate
          id="dashboard.mapSpend.recommended"
          data={{value:<span className="RecommendedBudget">{formatCurrency(additionnalBudget)}</span>}}
        />
      </div>
    </div>
  )
}
export default connect(makeMapStateToProps)(withLocalize(SpendInfo))
