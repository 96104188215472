import {Selector}             from './Selector'
import {SelectorAbstract}     from './Selector'
import {SelectorStandardName} from './Selector'
import PossibleSelectors      from './PossibleSelectors'

var selectorTempId = -1

export default (stdName:SelectorStandardName, addedBy:number, addedOn:Date=null) : Selector =>{
  const abstract = PossibleSelectors.find(ps => ps.name === stdName)
  return {
    ...abstract,
    id       : selectorTempId--,
    value    : abstract.defaultValue(),
    operator : abstract.defaultOperator,
    addedOn  : addedOn ? addedOn : new Date(),
    addedBy  : addedBy,
  }
}
