import Actions              from "../../../model/constant/actions"
import ReducerUtils         from "../../../utils/ReducerUtils"
import ArrayUtils           from "../../../utils/ArrayUtils"
import Strategy             from "../../../model/Store/Strategy/Strategy"

const serializeStrategy = (strategy:Strategy):string => {
  return [
    strategy.lang,
    strategy.group,
    strategy.vehicleState,
    strategy.vehicleType,
  ].join("|")
}
const addStrategies = (state, action) => {
  const accountId = action.accountId
  const strategies = action.strategies
  if(state[accountId] === undefined || state[accountId].length === 0){
    return {
      ...state,
      [accountId] : strategies
    }
  }
  return {
    ...state,
    [accountId] : ArrayUtils.mergeBySerialization(state[accountId], strategies, serializeStrategy)
  }
}
interface StrategiesByAccount{
  [accountId:number] : Strategy[]
}
const StrategiesList = ReducerUtils.createReducer<StrategiesByAccount>({},{
  [Actions.RETRIEVE_STRATEGIES] : addStrategies,
})

export default StrategiesList
