import './index.css'
import * as React                           from "react"
import {connect}                            from 'react-redux'
import {withLocalize, Translate}            from "react-localize-redux"
import {loadAppliers, saveAppliers}         from '../../actions/Appliers'
import loadInventory, { clearInventory }    from '../../actions/inventory/retrieveInventory'
import ArrayUtils                           from '../../utils/ArrayUtils'
import {
  SelectorAbstract,
  Selector,
  SelectorOperator,
  ValueTypeMapping,
}                                           from '../../model/engagementRules/Selector'
import SelectorGroupInterface               from '../../model/engagementRules/SelectorGroup'
import SelectorFactory                      from "../../model/engagementRules/SelectorFactory"
import {
  StandardVehicleType,
  VehicleType,
  VehicleTypeEnumReversing as getVehicleTypeName,
}                                           from '../../model/constant/VehicleType'
import {
  StandardVehicleState,
  VehicleState,
  VehicleStateEnumReversing as getVehicleStateName,
}                                           from '../../model/constant/VehicleState'
import Permissions                          from '../../model/constant/Permissions'
import SelectorGroup                        from './components/SelectorGroup'
import {Panel}                              from "../../components/Panel/Panel"
import GoBack                               from "../../components/Button/LinkGoBack"
import AccountPermissionBoundary            from "../../components/permissions/AccountPermissionBoundary"
import Notice                               from "../../components/alert/Notice"
import ExplainedLoading                     from "../../components/loading"
import requireCleaner                       from "../../components/loaders/cleanerLoader"
import CleanerRow                           from '../../model/CleanerRow'
import { clearVehiclesFromFriends }         from '../../actions/campaign/campaignInventory'
import Friend                               from '../../model/Store/Campaign/Friend'

var tempId = -1000

const SelectorGroupFactory = (vehicleState:StandardVehicleState)=>{
  return {
    id           : tempId--,
    vehicleState : vehicleState,
    selectors    : [],
  }
}
const renderSwitchAddSelectorGroup = (selectorGroups:SelectorGroupInterface[], onClick:()=>void) : any => {
  const allSelectorGroupsHaveAtLeastOneSelector = (
    selectorGroups.length === 0 ||
    selectorGroups.filter(sg=>sg.selectors.length === 0).length === 0
  )
  if(!allSelectorGroupsHaveAtLeastOneSelector){return <></>}
  return(
    <div key="add" className='AddSelectorGroup'>
      <button className='btn tx--btn SaveButton' onClick={onClick} >
         <i className='material-icons'>add</i>
          <Translate id="rules.addFilter"/>
      </button>
    </div>
  )
}
const removeMultipleEmptySelectorGroups = (selectorGroups:SelectorGroupInterface[]):SelectorGroupInterface[] => {
  var once = false
  var found = []
  for(let selectorGroup of selectorGroups){
    if(selectorGroup.selectors.length>0){found.push(selectorGroup)}
    else if(!once){once = true;found.push(selectorGroup)}
  }
  return found
}
export interface RulesComponentPublicProps {
  vehicleState : VehicleState
  vehicleType  : VehicleType
}
interface RulesComponentProps extends RulesComponentPublicProps{
  accountId             : number
  selectorGroups        : SelectorGroupInterface[]
  fetchingRules         : boolean
  friends               : Friend[]
  fetchMostRecentRules  : ()=>{}
  saveRules             : (groups:SelectorGroupInterface[])=>void
  reloadInventory       : ()=>void
  clearInventory        : ()=>void
  clearFriendsInventory : ()=>void
  cleanerMakes          : CleanerRow[]
  loadingCleaner        : boolean
  readOnly             ?: boolean
  translate            ?: (translateId:string, data?:any)=>string
}

const mapStateToProps = (state,ownProps:RulesComponentPublicProps)=>{
  const accountId = state.Accounts.selected
  const selectorGroups = (
    state.Params.EngagementRules.CurrentRules[accountId] &&
    state.Params.EngagementRules.CurrentRules[accountId][getVehicleTypeName(ownProps.vehicleType)] &&
    state.Params.EngagementRules.CurrentRules[accountId][getVehicleTypeName(ownProps.vehicleType)][getVehicleStateName(ownProps.vehicleState)]
    ? state.Params.EngagementRules.CurrentRules[accountId][getVehicleTypeName(ownProps.vehicleType)][getVehicleStateName(ownProps.vehicleState)]
    : []
  )
  return {
    accountId      : accountId,
    selectorGroups : selectorGroups,
    fetchingRules  : state.Params.EngagementRules.IsFetching,
    cleanerMakes   : state.Cleaner.Makes[accountId],
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    fetchMostRecentRules  : (accountId)=>dispatch(loadAppliers(accountId)),
    saveRules             : (accountId, type:StandardVehicleType, state:StandardVehicleState, groups:SelectorGroupInterface[])=>{
      dispatch(saveAppliers(accountId, type, state, groups))
    },
    reloadInventory       : (accountId) => dispatch(loadInventory(parseInt(accountId, 10))),
    clearInventory        : (accountId) => dispatch(clearInventory(parseInt(accountId, 10))),
    clearFriendsInventory : (accountId) => dispatch(clearVehiclesFromFriends(accountId))
  }
}
const mergeProps = (sp, dp, op) => {
  return {
    ...op,...sp,...dp,
    fetchMostRecentRules  : () => dp.fetchMostRecentRules(sp.accountId),
    saveRules             : (groups:SelectorGroupInterface[]) => dp.saveRules(sp.accountId, op.vehicleType.vehicleType, op.vehicleState.state, groups),
    reloadInventory       : () => dp.reloadInventory(sp.accountId),
    clearInventory        : () => dp.clearInventory(sp.accountId),
    clearFriendsInventory : () => dp.clearFriendsInventory(sp.accountId),
  }
}

const PermissionWrapper = (props) => {
  return (
    <AccountPermissionBoundary
      permissions={[Permissions.Keystone_ChangeEngagementRules]}
      onFailure={<RulesComponent {...props} readOnly/>}
    >
      <RulesComponent {...props}/>
    </AccountPermissionBoundary>
  )
}
class RulesComponent extends React.Component<RulesComponentProps,any>{
  static defaultProps = {
    readOnly : false
  }

  componentDidMount(){
    this.props.fetchMostRecentRules()
  }
  constructor(props){
    super(props)
    this.state = {
      selectorGroups : removeMultipleEmptySelectorGroups([...this.props.selectorGroups]),
      modified : false,
      saving   : false,
    }
    this.setModified                   = this.setModified.bind(this)
    this.removeSelector                = this.removeSelector.bind(this)
    this.changeSelectorValue           = this.changeSelectorValue.bind(this)
    this.addSelector                   = this.addSelector.bind(this)
    this.reset                         = this.reset.bind(this)
    this.save                          = this.save.bind(this)
    this.addSelectorGroup              = this.addSelectorGroup.bind(this)
  }
  componentDidUpdate(prevProps){
    if(prevProps.accountId != this.props.accountId){this.props.fetchMostRecentRules()}
    if(prevProps.selectorGroups != this.props.selectorGroups){
      this.setState({
        selectorGroups : removeMultipleEmptySelectorGroups([...this.props.selectorGroups])
      })
    }
    if(this.state.saving && this.props.selectorGroups != prevProps.selectorGroups){
      this.setState({saving:false})
    }
  }
  setModified(){
    this.setState(prevState=>({
      modified:true,
    }))
  }
  removeSelector(selector:Selector, selectorGroup:SelectorGroupInterface){
    this.setState(prevState=>{
      const toModify = prevState.selectorGroups.find(sg=>sg.id===selectorGroup.id)
      return {
        selectorGroups : removeMultipleEmptySelectorGroups([
          ...prevState.selectorGroups.filter(sg=>sg.id!==selectorGroup.id),
          {
            ...toModify,
            selectors : toModify.selectors.filter(s=>selector.id !== s.id)
          }
        ])
      }
    })
    this.setModified()
  }
  changeSelectorValue(selector:Selector, content:string, selectorGroup:SelectorGroupInterface){
    if(!ValueTypeMapping[selector.valueType].validate(content)){
      alert(this.props.translate("rules.incorrectValue"))
      return
    }
    this.setState(prevState=>{
      const toModify = prevState.selectorGroups.find(sg=>sg.id===selectorGroup.id)
      return {
        selectorGroups : [
          ...prevState.selectorGroups.filter(sg=>sg.id!==selectorGroup.id),
          {
            ...toModify,
            selectors : [
              ...toModify.selectors.filter(s=>s.id!==selector.id),
              {...selector, value:content},
            ],
          },
        ],
      }
    })
    this.setModified()
  }
  changeSelectorOperator(selector:Selector, operator:SelectorOperator, selectorGroup:SelectorGroupInterface){
    this.setState(prevState=>{
      const toModify = prevState.selectorGroups.find(sg=>sg.id===selectorGroup.id)
      return {
        selectorGroups : [
          ...prevState.selectorGroups.filter(sg=>sg.id!==selectorGroup.id),
          {
            ...toModify,
            selectors : [
              ...toModify.selectors.filter(s=>s.id!==selector.id),
              {...selector, operator:operator},
            ],
          },
        ],
      }
    })
    this.setModified()
  }
  addSelectorGroup(){
    this.setState(prevState=>{
      return {
        selectorGroups : removeMultipleEmptySelectorGroups(
          [...prevState.selectorGroups, SelectorGroupFactory(this.props.vehicleState.state)]
        )
      }
    })
  }
  addSelector(abstract:SelectorAbstract, selectorGroup:SelectorGroupInterface){
    this.setState(prevState=>{
      const toModify = prevState.selectorGroups.find(sg=>sg.id===selectorGroup.id)
      return {
        selectorGroups : [
          ...prevState.selectorGroups.filter(sg=>sg.id!==selectorGroup.id),
          {
            ...toModify,
            selectors: [...toModify.selectors,SelectorFactory(abstract.name,0,new Date())]
          }
        ]
      }
    })
    this.setModified()
  }
  reset(){
    this.setState(prevState=>({
      selectorGroups : [...this.props.selectorGroups],
      modified : false,
    }))
  }
  save(){
    const filteredSelectorGroups = this.state.selectorGroups.filter(x => x.selectors.length > 0)
    this.props.saveRules(filteredSelectorGroups)
    this.setState({
      saving   : true,
      modified : false
    })
    this.props.clearFriendsInventory()
    this.props.clearInventory()
    this.props.reloadInventory()
  }
  render(){
    if(this.state.saving){return <ExplainedLoading translateId="loadings.saving"/>}
    if(this.props.fetchingRules){return <ExplainedLoading translateId="loadings.rules"/>}
    if(this.props.loadingCleaner){return <ExplainedLoading translateId="loadings.cleanerData"/>}
    return (
      <Panel
        title={this.props.translate(
          "rules.editorSubtitle",
          {state:this.props.translate("rules."+this.props.vehicleState.text.toLowerCase()+"Plural")}
        )}
      >
        <div className='EngagementRules'>
          <GoBack to={"/Account/"+this.props.accountId+"/Params/Rules/"}/>
          <Notice text="You are not allowed to edit engagement rules" isActive={this.props.readOnly}/>
          <div key='options' className='MainOptions'>
            <button className='btn tx--btn CancelButton' disabled={this.state.modified===false} onClick={this.reset}>
              <Translate id="common.cancel"/>
            </button>
            <span className={this.props.readOnly?"readOnlyBanner":""}>
              <button className='btn tx--btn SaveButton' disabled={this.props.readOnly || this.state.modified===false} onClick={this.save}>
                <Translate id="common.save"/>
              </button>
            </span>
          </div>
          <div key="list" className='SelectorGroupList'>
            {
              this.state.selectorGroups
              .sort((x,y)=>ArrayUtils.comp(-x.id,-y.id))
              .map(selectorGroup=>
                <SelectorGroup
                  key                    = {selectorGroup.id}
                  selectors              = {selectorGroup.selectors}
                  addSelector            = {(abstract:SelectorAbstract)=>{this.addSelector(abstract,selectorGroup)}}
                  removeSelector         = {(selector:Selector)=>{this.removeSelector(selector,selectorGroup)}}
                  changeSelectorValue    = {(selector:Selector,content:string)=>{this.changeSelectorValue(selector,content,selectorGroup)}}
                  changeSelectorOperator = {(selector:Selector,operator:SelectorOperator)=>this.changeSelectorOperator(selector, operator, selectorGroup)}
                  cleanerMakes           = {this.props.cleanerMakes}
                />
              )
            }
          </div>
        {renderSwitchAddSelectorGroup(this.state.selectorGroups, this.addSelectorGroup)}
        </div>
      </Panel>
    )
  }

}

export default (
  requireCleaner(true)(
    withLocalize(
      connect(mapStateToProps,mapDispatchToProps,mergeProps)(
        PermissionWrapper
      )
    )
  )
)
