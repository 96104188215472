import {combineReducers}   from "redux"
import ConfirmationModal   from "./ConfirmationModal"
import ShouldBlock         from "./ShouldBlock"

const Navigation = combineReducers({
  ConfirmationModal,
  ShouldBlock,
})

export default Navigation
