import axios             from 'axios'
import Actions           from "../../model/constant/actions"
import {FeedConfig}      from "../../model/feed/FeedConfig"
import {getToken}        from "../../storeAccessor/Generic"
import LogBuilder        from '../../controller/log/LogBuilder'
import UserActions       from "../../model/constant/UserAction"


const declareFeedsLoading = (dispatch)=>{
  dispatch({type : Actions.RETRIEVING_FEEDS, status : true})
}

export const HYPERFEED_HOST = process.env.REACT_APP_HYPERFEED_URL

export const retrieveFeeds = (accountId:number) => (dispatch, getState) => {
  declareFeedsLoading(dispatch)
  axios.get(`${HYPERFEED_HOST}/Account/${accountId}/Feeds`, {
    headers : {
      "Authorization" : "Bearer " + getToken(getState()),
    }
  })
  .then((response) => {
    dispatch({
      type : Actions.RETRIEVE_FEEDS,
      accountId,
      feeds : response.data
    })
  })
  .catch(error => console.log(error))
  .then(()=>{
    dispatch({
      type : Actions.RETRIEVING_FEEDS,
      status : false
    })
  })
}
export const retrieveAvailableFeeds = (accountId: number) => (dispatch, getState) => {
  dispatch({type : Actions.RETRIEVING_AVAILABLE_FEEDS, status : true})
  axios.get(`${HYPERFEED_HOST}/Account/${accountId}/AvailableFeeds`, {
    headers : {
      "Authorization" : "Bearer " + getToken(getState()),
    }
  })
  .then((response) => {
    dispatch({
      type : Actions.RETRIEVE_AVAILABLE_FEEDS,
      accountId,
      availableFeeds : response.data
    })
  })
  .catch(error => console.log(error))
  .then(()=>{
    dispatch({
      type : Actions.RETRIEVING_AVAILABLE_FEEDS,
      status : false
    })
  })
}

export const requestFeeds = (feedConfig:FeedConfig, sync:boolean) => (dispatch, getState) => {
  declareFeedsLoading(dispatch)
  axios.post(
    `${HYPERFEED_HOST}/Account/${feedConfig.accountId}/Feed?sync=${sync?"true":"false"}`,
    feedConfig, // config is the payload
    {headers : {"Authorization" : "Bearer " + getToken(getState()),}}
  )
  .catch(error => console.log(error))
  .then(() => retrieveFeeds(feedConfig.accountId)(dispatch, getState))
}
export const refreshFeed = (accountId:number, feedId:string, sync:boolean) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.REFRESH_FEED, token, {accountId, feedId})

  declareFeedsLoading(dispatch)
  axios.post(
    `${HYPERFEED_HOST}/Account/${accountId}/Feed/${feedId}/Run?sync=${sync?"true":"false"}`,
    {},
    {headers : {"Authorization" : "Bearer " + getToken(getState()),}}
  )
  .catch(error => console.log(error))
  .then(() => retrieveFeeds(accountId)(dispatch, getState))
}
export const deleteFeed = (accountId:number, feedId:string) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.DELETE_FEED, token, {accountId, feedId})

  declareFeedsLoading(dispatch)
  axios.delete(
    `${HYPERFEED_HOST}/Account/${accountId}/Feed/${feedId}`,
    {headers : {"Authorization" : "Bearer " + getToken(getState()),}}
  )
  .catch(error => console.log(error))
  .then(() => retrieveFeeds(accountId)(dispatch, getState))
}
