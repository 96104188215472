import './LanguagePreferenceChooser.css'
import * as React                      from "react"
import {connect}                       from "react-redux"
import {withLocalize, Translate}       from "react-localize-redux"
import {setUserLanguage}               from "../../../actions/user/userPreferences"
import { Select }                      from '@material-ui/core'
import ExplainedLoading                from '../../../components/loading'

interface LanguagePreferenceChooser{
  settingLanguage     : boolean
  languages          ?: any[]
  activeLanguage     ?: any
  setPreferedLanguage : (language:string)=>void
  translate          ?: (translateId:string)=>string
  setActiveLanguage  ?: (code:string)=>void
}

const mapStateToProps = (state,ownProps)=>{
  return {
    userId          : state.Login.userLogged.profile.id,
    settingLanguage : state.Users.SettingLanguage
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    setPreferedLanguage : (userId, language)=>dispatch(setUserLanguage(userId, language, true))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    setPreferedLanguage : (language)=>DP.setPreferedLanguage(SP.userId, language)
  }
}

const LanguagePreferenceChooser = (props:LanguagePreferenceChooser) => {
  const handleChange = (item) => {
    if(item.target.value != props.activeLanguage.code){
      props.setActiveLanguage(item.target.value)
      props.setPreferedLanguage(item.target.value)
    }
  }

  return (
    <div className="LanguagePreferenceChooser">
      <div className="Container">
        <h4>
          <Translate id="profile.languageTitle"/>
        </h4>
        <Select
          native
          name="Prefered language"
          onChange={handleChange}
          value={props.activeLanguage.code}
        >
          {props.languages.map(lang=>
            <option
              key={lang.code}
              value={lang.code}
            >
              {props.translate("common."+lang.name.toLowerCase())}
            </option>
          )}
        </Select>
      </div>
      <div className="Loader">
      {
        props.settingLanguage &&
        <ExplainedLoading small />
      }
      </div>
    </div>
  )
}

export default (
  withLocalize(
    connect(mapStateToProps,mapDispatchToProps,mergeProps)(
      LanguagePreferenceChooser
    )
  )
)
