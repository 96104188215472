import './Panel.css'
import * as React from "react"

interface PanelProps {
  title?: string,
  subtitle?: string,
  children
}
export const Panel = (props: PanelProps) => {
  const [isDomLoaded, setIsDomLoaded] = React.useState(false);
  React.useEffect(() => {
    if (document.getElementsByClassName('GoBack').length > 0 ||
      document.getElementsByClassName('ButtonGoBackLarge').length > 0 ||
      document.getElementsByClassName('GoBackLarge').length > 0 ||
      document.getElementsByClassName('LinkGoBackLarge').length > 0
    )
      setIsDomLoaded(true);

  });

  let title = <span></span>
  if (props.title) {
    title =
      <div className={isDomLoaded ? 'card-header-custom header-with-back-button' : 'card-header-custom'}>
        <h5 className="title">{props.title}</h5>
        <p className="category">{props.subtitle}</p>
      </div>
  }
  return (
    <div className="card">
      {title}
      <div className='card-content'>{props.children}</div>
    </div>
  )
}
