import {combineReducers}            from "redux"
import Message                      from "./Message"
import PermissionProfiles           from "./profiles/PermissionProfiles"

const Permissions = combineReducers({
  Message : Message,
  Profiles : PermissionProfiles,
})

export default Permissions
