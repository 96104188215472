import IDBAPI     from "../../utils/IDBAPI"

//This action wont be dispatched. It needs to receive the token manually
export default (token, userAction:string, details:string, context:string="") => {
  IDBAPI.post({
      action     : "LogUserAction",
      token      : token,
      userAction : userAction,
      context    : context,
      details    : details,
    },
    (data)=>{},
    (data) => {console.log(data)}
  )
}

export const LogInLoggly = (title:string, details:any, logInDev:boolean=false) => {
  if(logInDev || process.env.NODE_ENV === "production"){
    IDBAPI.post({
        action  : "Log",
        details : JSON.stringify({
          title,
          data : details
        })
      },
      (data)=>{},
      (data) => {}
    )
  }
}
