import Actions            from "../../model/constant/actions"
import ReducerUtils       from "../../utils/ReducerUtils"
import BiddingStrategy    from "../../model/Store/BiddingStrategy"

const setBiddingStrategies = (state, action) => {
  const accountId = action.accountId
  if(action.biddingStrategies === undefined || accountId === undefined){
    return state
  }
  if(state[accountId] === undefined || state[accountId].length === 0){
    return {
      ...state,
      [accountId] : action.biddingStrategies
    }
  }
  return {
    ...state,
    [accountId] : [
      ...state[accountId],
      ...action.biddingStrategies
    ]
  }
}

interface BiddingStrategiesByAccount{
  [accountId:number] : BiddingStrategy[]
}
const BiddingStrategiesList = ReducerUtils.createReducer<BiddingStrategiesByAccount>({},{
  [Actions.RETRIEVE_BIDDING_STRATEGIES] : setBiddingStrategies,
})

export default BiddingStrategiesList
