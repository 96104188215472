import {combineReducers}        from "redux"
import {UserLogged}             from "./UserLogged"
import {LoginFailedMessage}     from "./LoginFailedMessage"
import {ForgotPasswordMessage}  from "./ForgotPasswordMessage"
import {DesiredURL}             from "./DesiredURL"
import IsLoggingIn              from "./IsLoggingIn"

export const Login = combineReducers({
  userLogged            : UserLogged,
  loginFailedMessage    : LoginFailedMessage,
  forgotPasswordMessage : ForgotPasswordMessage,
  desiredURL            : DesiredURL,
  isLoggingIn           : IsLoggingIn,
})
