const google = (window as any).google
import * as React           from "react"
import MathUtils            from "../../../../utils/MathUtils"

interface AreaResumeProps{
  originalShape : any
  newShape : any
}

const computeArea = (shape:any) => {
  return MathUtils.round(
    shape.polygons.reduce((total, polygon)=>{
      const points = polygon.map(x=>new google.maps.LatLng(x.lat, x.lng))
      return total+google.maps.geometry.spherical.computeArea(points)
    },0),
    2
  )
}

const AreaResume = (props:AreaResumeProps) => {
  if(!props.newShape){return null}
  const originalArea = computeArea(props.originalShape)
  const newArea = computeArea(props.newShape)
  const difference = MathUtils.round(newArea*100/originalArea-100, 2)
  return (
    <div>
      <div>Original area (m²) : {originalArea}</div>
      <div>New area (m²) : {newArea}</div>
      <div>Difference : {(difference>=0&&"+")+difference+"%"}</div>
    </div>
  )
}

export default AreaResume
