import ReducerUtils from "../../../utils/ReducerUtils"
import Actions from "../../../model/constant/actions"

const setActive = (state, action) => {
  return {
    ...state,
    [action.accountId] : action.active
  }
}

interface ActiveByAccount{
  [accountId:number] : boolean
}

const IsActive = ReducerUtils.createReducer<ActiveByAccount>({},{
  [Actions.RETRIEVE_IF_BUDGET_PLAN_IS_ACTIVE] : setActive,
})

export default IsActive
