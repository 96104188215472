import * as React from "react"
import {
  HashRouter,
  Route,
  Switch,
  Redirect
}                 from "react-router-dom"
import LeadTables from "../page/lead"

export const routing = (props) => {
  return (
    <HashRouter>
      <Switch>
        <Route
          exact
          key={"*/Leads"}
          path={"*/Leads"}
          component={LeadTables}
        />
        <Redirect from="/" to={"/Account/"+props.accountId+"/Leads"}/>
      </Switch>
    </HashRouter>
  )
}
