import {combineReducers}            from "redux"
import Accounts                     from "./Accounts"
import AccountStates                from "./AccountStates"
import SpendAttempts                from "./SpendAttempts"
import PossibleAgents               from "./PossibleAgents"
import Decisions                    from "./Decisions"
import Retrieving                   from "./retrieving/Retrieving"
import RetrievedRange               from "./retrievedRange/RetrievedRange"
import Failed                       from "./failed/Failed"
import Save                         from "./save/Save"
import Migration                    from "./migration/Migration"
import ChangeHistoryFilters         from "./changeHistoryFilters/ChangeHistoryFilters"
import Users                        from "./Users"
import Retrieve                     from "./retrieve/Retrieve"

export default combineReducers({
  Accounts,
  SpendAttempts,
  Decisions,
  Retrieving,
  RetrievedRange,
  Failed,
  Save,
  Migration,
  ChangeHistoryFilters,
  AccountStates,
  Users,
  Retrieve,
  PossibleAgents
})
