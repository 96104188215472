import Actions                 from "../../../model/constant/actions"
import ReducerUtils            from "../../../utils/ReducerUtils"
import ArrayUtils              from "../../../utils/ArrayUtils"
import Strategy                from "../../../model/Store/Strategy/Strategy"
import BiddingStrategySettings from "../../../model/Store/Strategy/BiddingStrategySettings"

const serializeStrategy = (strategy:Strategy):string => {
  return [
    strategy.lang,
    strategy.group,
    strategy.vehicleState,
    strategy.vehicleType,
  ].join("|")
}

const mergeStrategies = (state, action) => {
  const accountId = action.accountId
  const biddingStrategySettings = action.biddingStrategies
  if(state[accountId] === undefined || state[accountId].length === 0){
    return {
      ...state,
      [accountId] : biddingStrategySettings
    }
  }
  return {
    ...state,
    [accountId] : ArrayUtils.mergeBySerialization(state[accountId], biddingStrategySettings, serializeStrategy)
  }
}

interface StrategySettingsByAccount{
  [accountId:number] : BiddingStrategySettings[]
}

const StrategySettingsList = ReducerUtils.createReducer<StrategySettingsByAccount>({},{
  [Actions.RETRIEVE_BIDDING_STRATEGY_SETTINGS] : mergeStrategies,
})

export default StrategySettingsList
