import './PlacementsTable.css'
import * as React                  from "react"
import {connect}                   from "react-redux"
import {withLocalize, Translate}   from "react-localize-redux"
import BootstrapTable              from 'react-bootstrap-table-next'
import filterFactory               from 'react-bootstrap-table2-filter'
import {textFilter}                from 'react-bootstrap-table2-filter'
import paginationFactory           from 'react-bootstrap-table2-paginator'
import retrievePlacementsStats     from "../../../../../../../actions/statistics/placementsStats"
import NumFormatter                from '../../../../../../../utils/NumberFormatter'
import CSVUtils                    from '../../../../../../../utils/CSVUtils'
import StatisticsAccessors         from "../../../../../../../storeAccessor/Statistics"
import ExplainedLoading            from "../../../../../../../components/loading"
import {Button}                    from "carbon-components-react"



const makeMapStateToProps = () => {
  const statsSelector = StatisticsAccessors.makePlacementStatsInCurrentPeriodForCampaignSelector()
  return (state, ownProps) => {
    return {
      stats : statsSelector(state, ownProps.campaign),
      accountId : state.Accounts.selected,
      period : state.Period,
      retrieving : state.Statistics.Retrieving.Placements
    }
  }
}
const mapDispatchToProps = (dispatch) => ({
  retrievePlacementsStats : (accountId, period)=>dispatch(retrievePlacementsStats(accountId, period))
})
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    retrievePlacementsStats : ()=>DP.retrievePlacementsStats(SP.accountId, SP.period)
  }
}
class PlacementsTable extends React.Component<any,any>{
  private table
  constructor(props){
    super(props)
    this.table = React.createRef()
    this.generateCSV = this.generateCSV.bind(this)
  }
  generateCSV(){
    let header     = ["Placement","Clicks","Cost","Impressions","CPC"]
    let dataFields = ["Placement","Clicks","Cost","Impressions","CPC"]
    const data = (
      this.table.current.store._filteredData.length>0
      ? this.table.current.store._filteredData
      : this.table.current.store._data
    )
    CSVUtils.downloadCSV(
      "Placements_"+this.props.campaign.name.split(" ").join("-")+".csv",
      CSVUtils.generateCSV(header, data.map(row=>dataFields.map(key=>row[key])), ",")
    )
  }
  render(){
    if(!this.props.stats){
      if(!this.props.retrieving){this.props.retrievePlacementsStats()}
      return <ExplainedLoading translateId="loadings.stats"/>
    }
    const columns = [
      {
        text      : "Id",
        dataField : "Id",
        sort      : true,
        isKey     : true,
        hidden    : true,
      },{
        text      : this.props.translate("campaigns.overview.placement"),
        dataField : "Placement",
        sort      : true,
        filter    : textFilter({delay:50}),
        style     : {
          width: "40%",
        }
      },
      {
        text      : this.props.translate("common.clicks"),
        dataField : "Clicks",
        sort      : true,
        formatter : (x) => NumFormatter.formatNumber(x, 2),
        style     : {
          width: "5%",
        }
      },
      {
        text      : this.props.translate("common.cost"),
        dataField : "Cost",
        sort      : true,
        formatter : (x,_)=>NumFormatter.formatCurrency(x),
        style     : {
          width: "5%",
          textAlign: "center",
        }
      },
      {
        text      : this.props.translate("common.impressions"),
        dataField : "Impressions",
        sort      : true,
        formatter : (x) => NumFormatter.formatNumber(x, 2),
        style     : {
          width: "5%",
          textAlign: "center",
        }
      },
      {
        text      : this.props.translate("common.cpc"),
        dataField : "CPC",
        sort      : true,
        formatter : (x,_)=>NumFormatter.formatCurrency(x),
        style     : {
          width: "5%",
          textAlign: "center",
        }
      }
    ]
    const sizePerPage = 5
    const paginationOptions = {
      hideSizePerPage: true,
      sizePerPage : sizePerPage,
      hidePageListOnlyOnePage: true,
    }
    const extraProps = {
      pagination : paginationFactory(paginationOptions)
    }
    return (
      <div className="DataTablekeywordsForFriend">
        <div className="Header">
          <h4><Translate id="campaigns.overview.placementsTitle"/></h4>
          <Button onClick={this.generateCSV}>
            CSV
          </Button>
        </div>
        <BootstrapTable
          ref={this.table}
          data={this.props.stats}
          columns={columns}
          filter={filterFactory()}
          keyField='Id'
          defaultSorted={[{dataField: 'Clicks',order: 'desc'}]}
          classes={"normalSizedDatatable OverviewDataTable"}
          {...extraProps}
        />
      </div>
    )
  }
}

export default withLocalize(connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(PlacementsTable))
