import {
  withStyles,
  Badge,
  makeStyles,
  createStyles,
  Tooltip,
}                 from "@material-ui/core"


export const StyledBadge = withStyles({
  root: {
    float: "right",
  },
  badge: {
    minWidth: 16,
    height: 16,
    padding: 0,
    top: -15,
    right: 10,
    backgroundColor: "#d3450d",
  },
})(Badge);

export const StyledBadgeTooltip = withStyles({
  tooltip: {
    fontSize: 15,
  },
  tooltipPlacementTop: {
    right: -20,
  },
})(Tooltip);

export const useStyles = makeStyles(() =>
  createStyles({
    badgeMenuToggled: {
      top: -25,
      right: 0,
    },
    badgeAdvancedConfig: {
      top: 12,
      right: -5,
    },
    badgeProxyGroup: {
      top: 16,
      right: -20,
    },
    rootAdvancedConfig: {
      float: "left",
    }
  })
)
