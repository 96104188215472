import React                            from 'react'
import {
  Guideline,
  isPreciseGuideline,
  PreciseGuideline
}                                       from '../../../../../../model/pacy/Guideline'
import GuidelinesDiffFormat             from '../../GuidelineDiff/GuideLineDiffFormat/GuidelinesDiffFormat'
import { ProxyGroup }                   from '../../../../../../model/pacy/ProxyGroup'

interface PrecisedGuidelineDetailsProps {
  guideline           : PreciseGuideline
  prevGuideline       : Guideline
  concernedProxyGroup : ProxyGroup
}

const PrecisedGuidelineDetails = (props:PrecisedGuidelineDetailsProps) => {
  const {guideline,prevGuideline,concernedProxyGroup} = props
  const totalWeight = concernedProxyGroup.proxies.reduce((total, proxy) => total + proxy.weight, 0)
  return (
    <div className="PreciseGuidelineDetails">
      {
        Object.keys(guideline.amounts).map(proxyId => {
          const proxy = concernedProxyGroup.proxies.find((concernedProxy) => {
            return concernedProxy.id === proxyId
          })
          const amount = guideline.amounts[proxyId]
          const prevAmount = isPreciseGuideline(prevGuideline)
            ? prevGuideline.amounts[proxyId]
            : prevGuideline.amount * proxy.weight / totalWeight

          return (
            <div key={proxy.id}>
              <p>{proxy.name}</p>
              <GuidelinesDiffFormat amount={amount} prevAmount={prevAmount} />
            </div>
          )
        })
      }
    </div>

  )
}

export default PrecisedGuidelineDetails
