import './PermissionsDatatable.css'
import * as React                          from 'react'
import {connect}                           from 'react-redux'
import BootstrapTable                      from 'react-bootstrap-table-next'
import filterFactory                       from 'react-bootstrap-table2-filter'
import {textFilter}                        from 'react-bootstrap-table2-filter'
import Account                             from "../../model/Store/Account/Account"

interface PermissionsDatatableProps {
  permissions : any[]
  accounts    : Account[]
  exceptions  : any[]
}
const defaultFilter = [{dataField:'id',order:'asc'}]

const mapStateToProps = (state,ownProps)=>{
  return {
    accounts : state.Accounts.list,
  }
}

const PermissionsDatable = (props:PermissionsDatatableProps)=>{
  const data = props.permissions.map(p=>{
    const account = props.accounts.find(a=>a.id===p.accountId)
    return {
      ...p,
      accountIdHuman : p.accountId===null ? "All" : ""+p.accountId,
      account : account,
      key : p.id+"-"+p.name,
      accountName : account?account.name:"All",
    }
  })
  .concat(
    props.exceptions.map(e=>{
      const account = props.accounts.find(a=>a.id===e.accountId)
      return {
        ...e,
        accountIdHuman : e.accountId===null ? "All" : ""+e.accountId,
        account : account,
        key : e.id+"-"+e.name,
        accountName : account?account.name:"All",
        exception : true
      }
    })
  )
  return (
    <BootstrapTable
      hover
      data={data}
      keyField='key'
      columns={[
        { dataField:"key", text:"key",  hidden:true},
        { dataField:'id',              sort:true, text:"Permission Group ID",                                },
        { dataField:'name',            sort:true, text:"Permission Name",     filter:textFilter({delay:100}) },
        { dataField:'accountIdHuman',  sort:true, text:"Account ID",          filter:textFilter({delay:100}) },
        { dataField:'accountName',     sort:true, text:"Account Name",        filter:textFilter({delay:100}) },
        { dataField:'sourceProfiles',
          sort:true,
          text:"Profiles",
          formatter:(x,_)=>(
            <div style={{whiteSpace:"nowrap"}}>
              {x.map(p=>
                <div key={p}>{p}</div>
              )}
            </div>
          )
        },
      ]}
      defaultSorted={defaultFilter}
      filter={filterFactory()}
      classes={"normalSizedDatatable PermissionsDataTable"}
      rowClasses={(permission) => {
        if(permission.exception){return "DisabledPermissionRow"}
        return ""
      }}
    />
  )
}

export default connect(mapStateToProps)(PermissionsDatable)
