import "./AdCopyEditor.css"
import * as React                          from "react"
import {
  withLocalize,
  TranslateFunction
}                                          from "react-localize-redux"
import ArrayUtils                          from "../../utils/ArrayUtils"
import NumFormatter                        from "../../utils/NumberFormatter"
import TargetLimitedInput                  from "../input/TargetLengthLimitedTextInput"
import StyleInjector                       from "../StyleInjector"
import {
  ProtoMarketingEvent,
  ProtoMarketingApproach,
  AdCopyStringLengths,
}                                          from "../../model/adbuilder"
import { MakeModelsRow }                   from "../../model/Store/Cleaner"
import RowsToCullAlert                     from "./RowsToCullAlert"
import {
  getLongestMonthString,
  divideRowsInCulledOrUnculled,
  checkErrorsInField,
}                                          from "./utils"

const POSSIBLE_HEADLINES    = [1,2,3]
const POSSIBLE_DESCRIPTIONS = [1,2]
const DEFAULT_START_DATE    = new Date('2000-01-01')
const DEFAULT_END_DATE      = new Date('2001-01-01')

interface AdCopyEditorOwnProps{
  headlines              : string[]
  descriptions           : string[]
  onHeadlineUpdate       : (index:number, text:string)=>void
  onDescriptionUpdate    : (index:number, text:string)=>void
  lang                   : string
  tags                  ?: string[]
  stringLengths         ?: AdCopyStringLengths
  availableRows         ?: Array<MakeModelsRow&{inStock:boolean}>
  showErrorText         ?: boolean
  textLengthCalculation ?: (text:string, stringLengths: AdCopyStringLengths)=>number
  onErrorChange         ?: (errors:string[])=>void
  eventAndApproach      ?: {event:ProtoMarketingEvent, approach:ProtoMarketingApproach}
}
interface AdCopyEditorProps extends AdCopyEditorOwnProps{
  translate  : TranslateFunction
  style
}
interface AdCopyEditorState{
  errors : {
    headlines    : string[][]
    descriptions : string[][]
    notifiedParentOnce : boolean
  }
  text : {
    headlines    : string[]
    descriptions : string[]
  }
  longestMonthString : string
}
const responsiveStyle = {
  editor : {
    850 : {gridTemplateColumns: "1fr"}
  },
  description : {
    500 : {gridColumnEnd: 2, gridTemplateColumns: "1fr"},
    850 : {gridColumnEnd: 2}
  },
  headLines : {
    500 : {gridTemplateColumns: "1fr"}
  }
}

class AdCopyEditor extends React.Component<AdCopyEditorProps, AdCopyEditorState>{
  constructor(props:AdCopyEditorProps){
    super(props)
    this.state = {
      errors : {
        headlines : POSSIBLE_HEADLINES.map(_=>[]),
        descriptions : POSSIBLE_DESCRIPTIONS.map(_=>[]),
        notifiedParentOnce : false
      },
      text : {
        headlines    : POSSIBLE_HEADLINES.map((x,i)=>props.headlines[i]||""),
        descriptions : POSSIBLE_DESCRIPTIONS.map((x,i)=>props.descriptions[i]||"")
      },
      longestMonthString : getLongestMonthString(
        this.props.lang,
        this.props.eventAndApproach ? this.props.eventAndApproach.event.startDate : DEFAULT_START_DATE,
        this.props.eventAndApproach ? this.props.eventAndApproach.event.endDate   : DEFAULT_END_DATE,
      )
    }
    this.handleHeadlineInputError    = this.handleHeadlineInputError.bind(this)
    this.handleDescriptionInputError = this.handleDescriptionInputError.bind(this)
  }

  componentDidUpdate(prevProps:AdCopyEditorProps, prevState:AdCopyEditorState){
    if(this.props.onErrorChange){
      const previousErrors = Array.from(new Set([
        ...prevState.errors.descriptions.reduce((flattened, errors)=>[...flattened, ...errors],[]),
        ...prevState.errors.headlines.reduce(   (flattened, errors)=>[...flattened, ...errors],[]),
      ]))
      const newErrors = Array.from(new Set([
        ...this.state.errors.descriptions.reduce((flattened, errors)=>[...flattened, ...errors],[]),
        ...this.state.errors.headlines.reduce(   (flattened, errors)=>[...flattened, ...errors],[]),
      ]))
      if(!this.state.errors.notifiedParentOnce || !ArrayUtils.isLooselySameArray(previousErrors, newErrors)){
        this.props.onErrorChange(newErrors)
        if(!this.state.errors.notifiedParentOnce){
          this.setState(prevState=>({
            errors : {...prevState.errors, notifiedParentOnce: true}
          }))
        }
      }
    }
  }
  handleHeadlineInputError(index:number, errors:string[]){
    if( !ArrayUtils.isLooselySameArray(this.state.errors.headlines[index],errors)){
      this.setState(prevState=>({
        errors : {
          ...prevState.errors,
          headlines: prevState.errors.headlines.map((x,i)=>i===index ? errors : x)
        }
      }))
    }
  }
  handleDescriptionInputError(index:number, errors:string[]){
    if(!ArrayUtils.isLooselySameArray(this.state.errors.descriptions[index],errors)){
      this.setState(prevState=>({
        errors : {
          ...prevState.errors,
          descriptions: prevState.errors.descriptions.map((x,i)=>i===index ? errors : x)
        }
      }))
    }
  }
  saveHeadlineText(index:number, text:string){
    this.props.onHeadlineUpdate(index, text)
    this.setState(prevState=>({
      text : {
        ...prevState.text,
        headlines : prevState.text.headlines.map((x,i)=>i===index ? text : x)
      }
    }))
  }
  saveDescriptionText(index:number, text:string){
    this.props.onDescriptionUpdate(index, text)
    this.setState(prevState=>({
      text : {
        ...prevState.text,
        descriptions : prevState.text.descriptions.map((x,i)=>i===index ? text : x)
      }
    }))
  }
  render(){
    return (
      <div className="AdCopyEditor" style={this.props.style.editor}>
        <div className="Headlines">
          {POSSIBLE_HEADLINES.map((x,i)=> {
            const LIMIT = 30
            const text = this.state.text.headlines[i]
            const {culled,unculled} = divideRowsInCulledOrUnculled(this.props.availableRows, text, this.state.longestMonthString, LIMIT)
            return (
              <div key={x} className="Field HeadLine" style={this.props.style.headLines}>
                <TargetLimitedInput
                  label={`${this.props.translate("adBuilder.createEvent.headline")} ${NumFormatter.formatNumber(x)}`}
                  dragTextKey="tag"
                  lengthLimit={LIMIT}
                  softLimit
                  defaultValue={this.props.headlines[i]}
                  customError={(text)=>checkErrorsInField(this.props.tags||[], text, true)}
                  onChange={(text)=>{this.saveHeadlineText(i, text)}}
                  lengthCalculator={(text: string) => this.props.textLengthCalculation(text, this.props.stringLengths)}
                  showErrorText={this.props.showErrorText}
                  minimumLength={x<=2?5:0} //two first headlines must have at least 5 length
                  onError={(errors)=>this.handleHeadlineInputError(i, errors)}
                />
                {(
                  this.props.eventAndApproach===undefined || culled.length===0 || unculled.length===0 ? null :
                  <div className="ExpansionPanelContainer">
                    <RowsToCullAlert
                      culled={culled}
                      unculled={unculled}
                      warningString={(this.props.translate("adBuilder.rowsToCullAlert.headline")) as string}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div className="Descriptions">
          {POSSIBLE_DESCRIPTIONS.map((x,i)=> {
            const LIMIT = 90
            const text = this.state.text.descriptions[i]
            const {culled,unculled} = divideRowsInCulledOrUnculled(this.props.availableRows, text, this.state.longestMonthString, LIMIT)
            return (
              <div key={x} className="Field Description" style={this.props.style.description}>
                <TargetLimitedInput
                  label={`Description ${NumFormatter.formatNumber(x)}`}
                  dragTextKey="tag"
                  lengthLimit={LIMIT}
                  softLimit
                  defaultValue={this.props.descriptions[i]}
                  customError={(text)=>checkErrorsInField(this.props.tags||[], text, false)}
                  onChange={(text)=>{
                    this.saveDescriptionText(i, text)
                  }}
                  lengthCalculator={(text: string) => this.props.textLengthCalculation(text, this.props.stringLengths)}
                  showErrorText={this.props.showErrorText}
                  minimumLength={x===1?5:0} //first description must have at least 5 length
                  onError={(errors)=>this.handleDescriptionInputError(i, errors)}
                />
                {(
                  this.props.eventAndApproach===undefined || culled.length===0 || unculled.length===0 ? null :
                  <div className="ExpansionPanelContainer">
                    <RowsToCullAlert
                      culled={culled}
                      unculled={unculled}
                      warningString={(this.props.translate("adBuilder.rowsToCullAlert.description")) as string}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default (
  withLocalize(
    StyleInjector(responsiveStyle)(AdCopyEditor)
  ) as React.ComponentType<AdCopyEditorOwnProps>
)
