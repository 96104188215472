import './FilterInputWithButtons.css'
import * as React                       from 'react'
import {
  Card,
  TextField,
  Button
}                                       from '@material-ui/core'
import { ProxyGroup }                   from '../../model/pacy/ProxyGroup'
import {
  TranslateFunction,
  withLocalize
}                                       from 'react-localize-redux'
import keydown                          from 'react-keydown'


interface FilterInputWithButtonsOwnProps {
  proxyGroups : ProxyGroup[]
  onSelect    : (event,proxyGroupId:string) => void
}
interface FilterInputWithButtonsProps extends FilterInputWithButtonsOwnProps {
  translate : TranslateFunction
}
interface FilterInputWithButtonsState {
  searchText: string,
  targetedRowIndex: number
}

class FilterInputWithButtons extends React.Component<FilterInputWithButtonsProps,FilterInputWithButtonsState>{
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      targetedRowIndex: 0
    }
    this.targetHigherRow = this.targetHigherRow.bind(this)
    this.targetLowerRow = this.targetLowerRow.bind(this)
  }

  proxyGroupsFiltered

  componentDidMount(){
    const input = document.querySelector('.FilterInputWithButtons')
    if(input && input instanceof HTMLElement){
      input.addEventListener("keydown",((e:KeyboardEvent)=>{
        this.handleKeydown(e)
      }).bind(this))
    }
  }

  handleSearchText = (event) => {
    this.setState({
      searchText: event.target.value
    })
  }

  handleKeydown = (e: KeyboardEvent) => {
    if(e.key === "Enter" && this.proxyGroupsFiltered.length !==0){
      this.props.onSelect(event,this.proxyGroupsFiltered[this.state.targetedRowIndex].id)
    }
    else if(e.key === "ArrowDown"){this.targetLowerRow()}
    else if(e.key === "ArrowUp"){this.targetHigherRow()}
    else{
      //reselect the first account when typing something
      this.setState({
        targetedRowIndex : 0,
      })
    }
  }

  filter() {
    const filter = this.state.searchText.toLowerCase()
    const filterParts = filter.split(/\s/g)
    this.proxyGroupsFiltered = this.props.proxyGroups.filter(proxyGroup=>{
      if(proxyGroup.name.toLowerCase().includes(filter)){return true}
      if(filterParts.every((part)=>proxyGroup.name.toLowerCase().includes(part))){return true}
      return false
    })
  }

  scrollIntoView(){
    document.querySelectorAll('.FilterInputWithButtons__Button')[this.state.targetedRowIndex].scrollIntoView()
  }

  @keydown('up')
  targetHigherRow(){
    // Go back around to bottom item
    if (this.state.targetedRowIndex === 0 ) {
      this.setState({
        targetedRowIndex : this.proxyGroupsFiltered.length-1
      })
    }
    else if(this.state.targetedRowIndex > 0){
      this.setState(prevState=>({
        targetedRowIndex : prevState.targetedRowIndex-1,
      }))
    }
    this.scrollIntoView()
  }
  @keydown('down')
  targetLowerRow(){
    if(this.state.targetedRowIndex < this.proxyGroupsFiltered.length-1){
      this.setState(prevState=>({
        targetedRowIndex : prevState.targetedRowIndex+1,
      }))
    }
    // Go back around to top item
    else if(this.state.targetedRowIndex === this.proxyGroupsFiltered.length-1) {
      this.setState({
        targetedRowIndex : 0
      })
    }
    this.scrollIntoView()
  }

  render() {
    this.filter()
    return (
      <div className="FilterInputWithButtons">
        <TextField
          autoFocus
          placeholder={this.props.translate("pacyConfigs.searchProxyGroup") as string}
          fullWidth
          value={this.state.searchText}
          onChange={this.handleSearchText}
        />
        <div className="ProxyGroupsList">
          {this.proxyGroupsFiltered.map((proxyGroup,i)=>
            <Button
              className="FilterInputWithButtons__Button"
              onClick={(event)=>this.props.onSelect(event,proxyGroup.id)}
              onMouseOver={(event)=>{this.setState({targetedRowIndex: i})}}
              key={proxyGroup.id}
              size="large"
              fullWidth
              color="primary"
              variant={this.state.targetedRowIndex === i ? "contained" : "text"}
            >
              {proxyGroup.name}
            </Button>
          )}
        </div>
      </div>
    )
  }
}

export default withLocalize(FilterInputWithButtons as any) as React.ComponentType<FilterInputWithButtonsOwnProps>
