import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const saveBool = (state,action) => {
  return action.status
}
const SettingLanguage = ReducerUtils.createReducer<boolean>(false,{
  [Actions.SETTING_USER_LANGUAGE] : saveBool,
})
export default SettingLanguage
