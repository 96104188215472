import Actions                     from "../../model/constant/actions"
import MathUtils                   from "../../utils/MathUtils"
import GeoMapInfo                  from "../../model/Store/GeoBid/GeoMapInfo"
import ReducerUtils                from "../../utils/ReducerUtils"

//utility
const getNewId = (currentIds:number[]):number => {
  let newId = -1
  for(var i = 0; newId < 0; i++){
    if(currentIds.indexOf(i) === -1){newId=i}
  }
  return newId
}
//main functions
const addGeoTargetingCopy = (state,action) => {
  let newGeoMapInfo = action.geoMapInfo
  newGeoMapInfo.id = getNewId(state.map(map=>map.id))
  return [...state, newGeoMapInfo]
}
const addInitialPlace = (state,action) => {
  let mapId = action.mapId
  let newPlace = action.place
  let newMaps = state.map(geoMapInfo => {
    if(geoMapInfo.id === mapId){
      newPlace = {
        ...newPlace,
        id : getNewId(geoMapInfo.places.map(place=>place.id)),
      }
      return {
        ...geoMapInfo,
        places : [
          ...geoMapInfo.places,
          newPlace
        ],
        initialPlacesPending : geoMapInfo.initialPlacesPending.filter(place=>place.code!=newPlace.code)
      }
    }
    return geoMapInfo
  })
  return newMaps
}
const removeGeoMapInfo = (state,action) => {
  return state.filter(geoMapInfo => geoMapInfo.id !== action.mapId)
}
const removeParamGeoMapInfos = (state,action) => {
  return state.filter(geoMapInfo => !geoMapInfo.matcher.instanceof)
}
const removeCampaignGeoMapInfos = (state,action) => {
  return state.filter(geoMapInfo => geoMapInfo.matcher.instanceof)
}
const addCircle = (state,action) => {
  return state.map(geoMapInfo => {
    if(geoMapInfo.id === action.mapId){
      return {
        ...geoMapInfo,
        edited : true,
        circles : [
          ...geoMapInfo.circles,
          {
            ...action.circle,
            id : getNewId(geoMapInfo.circles.map(circle=>circle.id)),
            circleProps : {
              ...action.circle.circleProps,
              radius : action.circle.circleProps.radius
            }

          }
        ]
      }
    }
    return geoMapInfo
  })
}
const addPendingPlace = (state,action) => {
  return state.map(geoMapInfo => {
    if(geoMapInfo.id === action.mapId){
      return {
        ...geoMapInfo,
        placesPending : [
          ...geoMapInfo.placesPending,
          action.placePending,
        ]
      }
    }
    return geoMapInfo
  })
}
const addPlace = (state,action) => {
  let newPlace = action.place
  return state.map(geoMapInfo => {
    if(geoMapInfo.id === action.mapId){
      newPlace = {
        ...newPlace,
        id : getNewId(geoMapInfo.places.map(place=>place.id)),
      }
      return {
        ...geoMapInfo,
        edited : true,
        places : [
          ...geoMapInfo.places,
          newPlace
        ],
        placesPending : geoMapInfo.placesPending.filter(place=>place.code!=action.place.code)
      }
    }
    return geoMapInfo
  })
}
const updateCircle = (state,action) => {
  let updatedCircle = {
    ...action.circle,
    circleProps : {
      ...action.circle.circleProps,
      center : {
        lat : MathUtils.round(action.circle.circleProps.center.lat, 6),
        lng : MathUtils.round(action.circle.circleProps.center.lng, 6),
      },
      radius : MathUtils.round(action.circle.circleProps.radius, 6),
    },
  }
  return state.map(geoMapInfo => {
    if(geoMapInfo.id === action.mapId){
      return {
        ...geoMapInfo,
        edited : true,
        circles : geoMapInfo.circles.map(circle=>{
          if(circle.id === updatedCircle.id){return {...circle, ...updatedCircle}}
          return circle
        })
      }
    }
    return geoMapInfo
  })
}
const updatePlace = (state,action) => {
  let updatedPlace = {
    ...action.place,
    placeProps : {
      ...action.place.placeProps,
      position : {
        lat : MathUtils.round(action.place.placeProps.position.lat, 6),
        lng : MathUtils.round(action.place.placeProps.position.lng, 6),
      },
    },
  }
  return state.map(geoMapInfo => {
    if(geoMapInfo.id === action.mapId){
      return {
        ...geoMapInfo,
        edited : true,
        places : geoMapInfo.places.map(place=>{
          if(place.id === updatedPlace.id){return {...place, ...updatedPlace}}
          return place
        })
      }
    }
    return geoMapInfo
  })
}
const deleteCircle = (state,action) => {
  return state.map(geoMapInfo => {
    if(geoMapInfo.id === action.mapId){
      return {
        ...geoMapInfo,
        edited : true,
        circles : geoMapInfo.circles.filter(circle=>circle.id!==action.circleId)
      }
    }
    return geoMapInfo
  })
}
const deletePlace = (state,action) => {
  return state.map(geoMapInfo => {
    if(geoMapInfo.id === action.mapId){
      return {
        ...geoMapInfo,
        edited : true,
        places : geoMapInfo.places.filter(place=>place.id!==action.placeId)
      }
    }
    return geoMapInfo
  })
}
const selectCircle = (state,action) => {
  return state.map(geoMapInfo => {
    if(geoMapInfo.id === action.mapId){
      return {
        ...geoMapInfo,
        selectedCircle : action.circleId
      }
    }
    return geoMapInfo
  })
}
const deselectCircle = (state,action) => {
  return state.map(geoMapInfo => {
    if(geoMapInfo.id === action.mapId){
      return {
        ...geoMapInfo,
        selectedCircle : -1
      }
    }
    return geoMapInfo
  })
}
export const GeoBids = ReducerUtils.createReducer<GeoMapInfo[]>([],{
  [Actions.START_EDITING_GEOBID]               : addGeoTargetingCopy,
  [Actions.CANCEL_EDITING_GEOBID]              : removeGeoMapInfo,
  [Actions.RETRIEVE_GEOBID]                    : removeParamGeoMapInfos,
  [Actions.RETRIEVE_DESIRED_GEOBID_EXCEPTIONS] : removeCampaignGeoMapInfos,
  [Actions.ADD_CIRCLE_EDITING_GEOBID]          : addCircle,
  [Actions.ADD_PENDING_PLACE_EDITING_GEOBID]   : addPendingPlace,
  [Actions.ADD_PLACE_EDITING_GEOBID]           : addPlace,
  [Actions.UPDATE_CIRCLE_EDITING_GEOBID]       : updateCircle,
  [Actions.UPDATE_PLACE_EDITING_GEOBID]        : updatePlace,
  [Actions.DELETE_CIRCLE_EDITING_GEOBID]       : deleteCircle,
  [Actions.DELETE_PLACE_EDITING_GEOBID]        : deletePlace,
  [Actions.SELECT_CIRCLE_EDITING_GEOBID]       : selectCircle,
  [Actions.DESELECT_CIRCLE_EDITING_GEOBID]     : deselectCircle,
  [Actions.ADD_INITIAL_PLACE_EDITING_GEOBID]   : addInitialPlace
})
