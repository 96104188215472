import countryList      from "countries-list"
import { LocaleObject } from "../model/constant/LocaleObjects"


const sortFunction = (a: LocaleObject, b: LocaleObject) => {
  if (a.readableLocale > b.readableLocale) return 1
  if (a.readableLocale < b.readableLocale) return -1
  return 0
}

const constructLocaleCode = (countryKey: string, language: string): string => {
  return language.toLowerCase() + "-" + countryKey.toUpperCase()
}

export const getReadableLocale = (localeCode: string): string => {
  if(localeCode.length === 5 && localeCode.charAt(2) === "-"){
    const [languageKey, countryKey] = localeCode.split("-")
    return countryList.languagesAll[languageKey].name + ` (${countryList.countries[countryKey].name})`
  }
  return localeCode
}

export const getSortedLocales = (): LocaleObject[] => {
  const localeObjects: LocaleObject[] = []

  for (const countryKey of Object.keys(countryList.countries)) {
    let uniqueKeyNumber = 0
    for (const languageKey of countryList.countries[countryKey].languages) {
      const localeCode = constructLocaleCode(countryKey, languageKey)
      localeObjects.push({
        countryKey     : countryKey,
        localeCode     : localeCode,
        key            : countryKey + uniqueKeyNumber,
        readableLocale : getReadableLocale(localeCode)
      })
      uniqueKeyNumber ++
    }
    uniqueKeyNumber = 0
  }
  return localeObjects.sort(sortFunction)
}
