import "./index.css"
import * as React                                from "react"
import Permissions                               from "../../model/constant/Permissions"
import {PacyAccount}                             from "../../model/pacy/"
import Period                                    from "../../model/Period"
import RoutingUtils                              from "../../utils/RoutingUtils"
import requiresPacy                              from "../../components/loaders/pacyLoader"
import AccountPermissionBoundary                 from "../../components/permissions/AccountPermissionBoundary"
import GoBack                                    from "../../components/Button/ButtonGoBack"
import ProxyGroups                               from "./ProxyGroups/"
import Decisions                                 from "./Decisions"
import {Switch, Route,Redirect, withRouter}      from "react-router-dom"
import Button                                    from "@material-ui/core/Button"
import Icon                                      from "@material-ui/core/Icon"
import {
  withLocalize,
  LocalizeContextProps
}                                                from "react-localize-redux"

interface PacyReportOwnProps extends LocalizeContextProps {

}
interface PacyReportProps extends PacyReportOwnProps{
  history
  account                  : PacyAccount
  requestReportingDataLoad : (period:Period)=>boolean
}

const PacyReport = (props:PacyReportProps) => {
  return (
    <div className="PacyReport">
      <h4 style={{paddingTop: "15px", paddingBottom: "10px"}}>{props.translate('reports.budgets.pacingReport')}</h4>
      <Switch>
        <Route exact path="*/Budgets" render={()=>
          <>
            <Button variant="contained" color="primary"
              className="NavButton"
              onClick={()=>RoutingUtils.appendToUrl(props.history, "Decisions")}
            >
              {/* Decisions&nbsp; */}
              {props.translate('reports.budgets.decisions')}
              <Icon>receipt</Icon>
            </Button>
            <ProxyGroups
              account={props.account}
              requestReportingDataLoad={props.requestReportingDataLoad}
            />
          </>
        }/>
        <Route exact path="*/Budgets/decisions" render={()=>
          <>
            <GoBack onClick={()=>RoutingUtils.removeLastElementOfUrl(props.history)}/>
            <Decisions
              account={props.account}
              //requestReportingDataLoad={props.requestReportingDataLoad}
            />
          </>
        }/>
        <Redirect to={RoutingUtils.removeAndReturnLastElementOfUrl(props.history)}/>
      </Switch>
    </div>
  )
}

const PacyReportConnected = (
  withLocalize(
    requiresPacy({needStats: true, needSpendAttempts: true, needDecisions: true, loadWhenInactive: true})(
      withRouter(
        PacyReport as any
      )
    )
  )
) as React.ComponentType<PacyReportOwnProps>
export default (props) => (
  <AccountPermissionBoundary permissions={[Permissions.Pacy_View]}>
    <PacyReportConnected {...props}/>
  </AccountPermissionBoundary>
)
