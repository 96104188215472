import ReducerUtils                   from "../../../utils/ReducerUtils"
import Actions                        from "../../../model/constant/actions"
import {PacyAccount}                  from "../../../model/pacy/"
import { MoveProxySimulationAccount } from "../../../model/pacy/AccountModifiers"


const updatePacyAccount = (state:MoveProxySimulationAccount,action:{account:PacyAccount,moveInvestment:boolean}):MoveProxySimulationAccount => {
  if(action.moveInvestment) {
    return {...state,movingInvestment:action.account}
  }
  if(!action.moveInvestment) {
    return {...state,notMovingInvestment:action.account}
  } else {
    return state
  }
}
const MoveProxySimulation = ReducerUtils.createReducer<MoveProxySimulationAccount>(null,{
  [Actions.MOVE_PROXY_SIMULATION] : updatePacyAccount,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST] : () => null
})

export default MoveProxySimulation
