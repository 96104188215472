import Actions          from "../../../model/constant/actions"
import ReducerUtils     from "../../../utils/ReducerUtils"
import Position         from "../../../model/Store/GeoBid/GeoPosition"

const addToCache = (state,action) => {
  if(state[action.code]){return state}
  return {...state, [action.code]:action.position}
}

interface PositionByAWCode{
  [AWCode:number] : Position
}

export const GeocodingCache = ReducerUtils.createReducer<PositionByAWCode>({},{
  [Actions.ADD_POSITION_TO_CACHE] : addToCache
})
