import "./index.css"
import * as React             from "react"
import {connect}              from "react-redux"
import {Translate}            from "react-localize-redux"
import RoutingUtils           from "../../utils/RoutingUtils"
import {MarketingEvent}       from "../../model/adbuilder"
import {startEditing}         from "../../actions/adBuilder/editing"
import {deleteMarketingEvent} from "../../actions/adBuilder/events"
import EventOverview          from "./components/EventOverview"
import Pagination             from "@material-ui/lab/Pagination"
import ArrayUtils             from "../../utils/ArrayUtils"
import {
  Button,
  ButtonGroup,
  Icon,
  Snackbar
}                             from "@material-ui/core"
import Actions                from "../../model/constant/actions"

interface AdBuilderOwnProps{
  history   : any //History?
  events    : MarketingEvent[]
  readOnly ?: boolean
}

const mapStateToProps = (state,ownProps)=>{
  return {
    latestCopyId : state.AdBuilder.LatestCopyId,
    accountId    : state.Accounts.selected
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    prepareEventForEdit  : (event:MarketingEvent)=>dispatch(startEditing(event)),
    deleteMarketingEvent : (accountId:number, eventId:number) => dispatch(deleteMarketingEvent(accountId, eventId)),
    reset                : () => dispatch({type: Actions.CLEAR_MARKETING_EVENT_CREATE_COPY}),
    resetCopy            : () => dispatch({type: Actions.SET_MARKETING_EVENT_LATEST_COPY_ID, id:null})
  }
}

const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AdBuilderOwnProps)=>{
  return {
    ...SP,...DP,...ownProps
  }
}

type AdBuilderProps = ReturnType<typeof mergeProps>

const AdBuilder = (props:AdBuilderProps) => {
  const buttonData = [
    {
      translation : "adBuilder.status.all",
      value : "all"
    },
    {
      translation : "adBuilder.status.active",
      value : "active"
    },
    {
      translation : "adBuilder.status.paused",
      value : "paused"
    },
    {
      translation : "adBuilder.status.ended",
      value : "ended"
    }
  ]

  const redirectToCreate = () => {
    props.reset()
    RoutingUtils.appendToUrl(props.history, "Create")
  }
  const redirectToEvent = (eventId)=>RoutingUtils.appendToUrl(props.history, ""+eventId)
  
  const sortEvents = (eventsToSort:MarketingEvent[]):MarketingEvent[] => {
    return [...eventsToSort].sort((a,b) => {
      if(a.endDate < b.endDate) {return 1}
      else if (a.endDate > b.endDate) {return -1}
      else return 0
    })
  }

  const [events, setEvents] = React.useState<MarketingEvent[][]>(ArrayUtils.chunkArray(sortEvents(props.events),12))
  const [page, setPage] = React.useState<number>(1)
  const [currentFilter, setCurrentFilter] = React.useState<string>("all")
  const [copySnack, setCopySnack] = React.useState<boolean>(props.latestCopyId!==null)

  React.useEffect(()=>{
    switch(currentFilter){
      case "all":{
        setEvents(ArrayUtils.chunkArray(sortEvents(props.events),12))
        break
      }
      case "active":{
        setEvents(ArrayUtils.chunkArray(sortEvents(props.events).filter(event=>event.active && new Date() < event.endDate),12))
        break
      }
      case "paused":{
        setEvents(ArrayUtils.chunkArray(sortEvents(props.events).filter(event=>!event.active),12))
        break
      }
      case "ended":{
        setEvents(ArrayUtils.chunkArray(sortEvents(props.events).filter(event=>new Date() > event.endDate),12))
        break
      }
    }
  },[currentFilter])

  React.useEffect(()=>{
    setEvents(ArrayUtils.chunkArray(sortEvents(props.events),12))
  },[props.events])

  const handleChangePage = (newPage:number) => {
    setPage(newPage)
    window.scrollTo(0, 0)
  }
  
  const handleUndo = () => {
    props.deleteMarketingEvent(props.accountId, props.latestCopyId)
    props.resetCopy()
  }

  const handleEdit = () => {
    const event = props.events.find(x=>x.id===props.latestCopyId)
    props.prepareEventForEdit(event)
    redirectToEvent(event.id)
  }

  const handleCloseSnack = () => {
    props.resetCopy()
    setCopySnack(false)
  }

  return (
    <div className="AdBuilder">
      <Snackbar
        open={copySnack}
        onClose={handleCloseSnack}
        anchorOrigin={{vertical:"top", horizontal:"center"}}
      >
        <div className="Alert">
          <div className="CopyAlertText">
            <Translate id="adBuilder.saved"/>
          </div>
          <div className="Undo" onClick={handleUndo}>
            <Translate id="common.undo"/>
          </div>
          <Button className="Edit" onClick={handleEdit}>
            <Translate id="common.edit"/>
          </Button>
          <Button className="CloseSnack" onClick={handleCloseSnack}>
            X
          </Button>
        </div>
      </Snackbar>
      <h5 className="Title"><Translate id="adBuilder.title"/></h5>
      <div className="Headers">
        <ButtonGroup variant="text">
          {buttonData.map(button=>{
            return (
              <Button
                key={button.value}
                className={currentFilter === button.value ? "Selected" : "NotSelected"}
                onClick={()=>setCurrentFilter(button.value)}
              >
                <Translate id={button.translation} />
              </Button>
            )
          })}
        </ButtonGroup>
        <div onClick={redirectToCreate} className="Add">
          <p className="Icon"><Icon>add_circle</Icon></p>
          <p className="AddLabel"><Translate id="adBuilder.newEventButton"/></p>
        </div>
      </div>
      <div className="EventOverviews">
        {events[page-1] && events[page-1].map(x=>{
          const onOpen = () => {
            props.prepareEventForEdit(x)
            redirectToEvent(x.id)
          }
          return (
            <EventOverview
              key={x.id + x.title}
              event={x}
              goToEvent={onOpen}
              goToCreate={redirectToCreate}
              copyCreated={() => setCopySnack(true)}
              readOnly={props.readOnly}
            />
          )
        })}
      </div>
      {events.length === 0 &&
        <div className="NoEventsOverview">
          <Translate id="adBuilder.noEvents"/>
        </div>
      }
      <div className="Pagination">
        <Pagination count={events.length} page={page} onChange={(_e,v)=>handleChangePage(v)} />
      </div>
    </div>
  )
}

export default connect(mapStateToProps,mapDispatchToProps)(AdBuilder)

