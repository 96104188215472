import {combineReducers}      from "redux"
import AlreadyMigrated        from "./AlreadyMigrated"
import Done                   from "./Done"
import Running                from "./Running"
import Error                  from "./Error"

export default combineReducers({
  AlreadyMigrated,
  Done,
  Running,
  Error,
})
