import Actions          from "../../../model/constant/actions"
import ReducerUtils     from "../../../utils/ReducerUtils"
import Position         from "../../../model/Store/GeoBid/GeoPosition"

const addToCache = (state,action) => {
  let newState = {...state}
  for(let x of action.data){
    newState[parseInt(x.CriterionID)] = {
      lat : x.Lat,
      lng : x.Long,
    }
  }
  return newState
}
interface PositionByCriterionId{
  [code:string] : Position
}
export const GeocodingMetaCache = ReducerUtils.createReducer<PositionByCriterionId>({},{
  [Actions.RETRIEVE_ADWORDS_PLACES_META_GEOCODING_CACHE] : addToCache
})
