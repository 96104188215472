import './GTMModal.css'
import * as React                        from "react"
import axios                             from "axios"
import ArrayUtils                        from "../../../utils/ArrayUtils"
import {ModalWrapper, TextInput}         from "carbon-components-react"

interface GTMModalProps{
  GAId ?: string
  accountName ?: string
}
interface GTMModalState{
  inputs : {
    [inputName:string] : string
  }
  inputsWithError : string[]
}
class GTMModal extends React.Component<GTMModalProps,GTMModalState>{
  static defaultProps = {
    GAId : "",
    accountName : "Account"
  }
  constructor(props){
    super(props)
    this.state = {
      inputs : {
        GAId           : props.GAId,
        CallRailScript : "",
        AWConversionId : "",
      },
      inputsWithError : []
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleGenerateClick = this.handleGenerateClick.bind(this)
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.GAId != this.props.GAId){
      this.setState(prevState=>({
        inputs : {
          ...prevState.inputs,
          GAId : this.props.GAId
        }
      }))
    }
  }
  handleInputChange(e){
    const name = e.target.name
    const value = e.target.value
    this.setState(prevState=>({
      inputs : {
        ...prevState.inputs,
        [name] : value
      }
    }))
    if(ArrayUtils.contain(this.state.inputsWithError, name)){
      this.setState(prevState=>({
        inputsWithError : prevState.inputsWithError.filter(x=>x!=name)
      }))
    }
  }
  handleGenerateClick(){
    const inputNames = Object.keys(this.state.inputs)
    let invalidInputs = []
    for(let inputName of inputNames){
      if(this.state.inputs[inputName].length === 0){
        invalidInputs.push(inputName)
      }
    }
    if(invalidInputs.length > 0){
      this.setState({inputsWithError : invalidInputs})
      return
    }
    this.generateJSON()
  }
  generateJSON(){
    axios.get(
      "../../../../assets/json/gtm_template.txt",
      {responseType:"text"}
    )
    .then(response=>{
      let template = response.request.response
      const CallRailScript = this.state.inputs.CallRailScript.replace(/\"/g,'\\"')
      template = template.replace("%AccountGA%", this.state.inputs.GAId)
      template = template.replace("%scriptCallRail%", CallRailScript)
      template = template.replace("%AdWordsConversionID%", this.state.inputs.AWConversionId)

      const data = "data:text/json;charset=utf-8,"+encodeURIComponent(template)
      const fileName = this.props.accountName+"_GTM.json"

      let download = document.createElement("a")
      download.setAttribute('href', data)
      download.setAttribute('download', fileName)
      document.body.appendChild(download)
      download.click()
      document.body.removeChild(download) //Not sure if useless/doesn't break anything

    })
    .catch(error=>console.log(error))
  }
  render(){
    return (
      <div className="GTMModal">
        <ModalWrapper
          modalProps={{}}
          id="transactional-modal"
          buttonTriggerText="Generate GTM JSON"
          modalHeading="Generate JSON"
          handleSubmit={this.handleGenerateClick}
          primaryButtonText="Generate"
          shouldCloseAfterSubmit
        >
          <div>
            <TextInput
              id="GAId"
              name="GAId"
              labelText="Google Analytics ID"
              value={this.state.inputs.GAId}
              onChange={this.handleInputChange}
              invalid={ArrayUtils.contain(this.state.inputsWithError, "GAId")}
              invalidText={"This field is required"}
            />

            <TextInput
              id="CallRailScript"
              name="CallRailScript"
              labelText="CallRail Script Snippet"
              value={this.state.inputs.CallRailScript}
              onChange={this.handleInputChange}
              invalid={ArrayUtils.contain(this.state.inputsWithError, "CallRailScript")}
              invalidText={"This field is required"}
            />

            <TextInput
              id="AWConversionId"
              name="AWConversionId"
              labelText="AdWords Conversion ID"
              value={this.state.inputs.AWConversionId}
              onChange={this.handleInputChange}
              invalid={ArrayUtils.contain(this.state.inputsWithError, "AWConversionId")}
              invalidText={"This field is required"}
            />
          </div>
        </ModalWrapper>
      </div>
    )
  }
}

export default GTMModal
