import WebInfo                      from "../../../model/Store/Tracking/WebInfo"
import WebLead                      from "../../../model/Store/Tracking/WebLead"
import BrowserIdCompiledWebInfo     from "../model/BrowserIdCompiledWebInfo"
import ArrayUtils                   from "../../../utils/ArrayUtils"


const checkFromDB = (webInfo:WebInfo) : boolean => {
  const leads = webInfo.leads.filter(x=>x.fromDB)
  return Boolean(leads.length)
}
const compile = (leads:WebLead[]) : BrowserIdCompiledWebInfo[] =>{
  let byBrowserId = {}
  for(let lead of leads){
    if(!byBrowserId[lead.browserId]){
      byBrowserId[lead.browserId] = {
        leads       : [],
        navigations : [],
      }
    }
    byBrowserId[lead.browserId].leads.push(lead)
  }
  return ArrayUtils.getArrayFromObject(byBrowserId)
  .map(x=>({
    browserId : x.leads[0].browserId,
    fromDB    : checkFromDB(x),
    leads     : x.leads,
  }))
  .filter(x => x.fromDB)
}
export default compile
