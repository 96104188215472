import Actions      from "../../../../model/constant/actions"
import ReducerUtils from "../../../../utils/ReducerUtils"

const addShapes = (state, action) => {
  return {
    ...state,
    ...action.data
  }
}
const removeShapes = (state, action) => {
  const stateCopy = {...state}
  delete stateCopy[action.code]
  return stateCopy
}
interface DuplicateShapes{
   [code:number] : Shape[]
}
interface Shape{
  id       : string
  name     : string
  code     : number
  polygons : number[][][]
}
const DuplicateGreenShapes = ReducerUtils.createReducer<DuplicateShapes>({},{
  [Actions.RETRIEVE_DUPLICATE_GREEN_SHAPES] : addShapes,
  [Actions.REMOVE_DUPLICATE_GREEN_SHAPES]   : removeShapes
})
export default DuplicateGreenShapes
