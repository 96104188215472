import './index.css'
import * as React                            from "react"
import {withLocalize}                        from "react-localize-redux"
import Permissions                           from "../../model/constant/Permissions"
import KeywordsManager                       from "./component/KeywordsManager"
import {Panel}                               from "../../components/Panel/Panel"
import Button                                from "../../components/Button/MinimalistButton"
import AccountPermissionBoundary             from "../../components/permissions/AccountPermissionBoundary"

interface KeywordPatternsState {
  selectedCategory : string
}
class KeywordPatterns extends React.Component<any,KeywordPatternsState>{
  constructor(props) {
    super(props)
    this.state = {
      selectedCategory : ""
    }
    this.goBack = this.goBack.bind(this)
    this.selectCategory = this.selectCategory.bind(this)
  }
  selectCategory(category:string){
    this.setState({selectedCategory:category})
  }
  goBack(){
    this.setState({selectedCategory:""})
  }
  render(){
    if(this.state.selectedCategory){
      return (
        <AccountPermissionBoundary
          permissions={[Permissions.Keystone_ChangeKeywords]}
          onFailure={<KeywordsManager category={this.state.selectedCategory} goBack={this.goBack} readOnly/>}
        >
          <KeywordsManager category={this.state.selectedCategory} goBack={this.goBack}/>
        </AccountPermissionBoundary>
      )
    }
    return(
      <Panel title={this.props.translate("keywords.title")} subtitle={this.props.translate("keywords.subtitle")}>
        <div className="MinimalistButtonsList">
          <Button
            text={this.props.translate("common.new")}
            subtitle={this.props.translate("keywords.newSubtitle")}
            onClick={()=>this.selectCategory("NEW")}
          />
          <Button
            text={this.props.translate("common.used")}
            subtitle={this.props.translate("keywords.usedSubtitle")}
            onClick={()=>this.selectCategory("USED")}
          />
        </div>
      </Panel>
    )
  }
}

export default withLocalize(KeywordPatterns)
