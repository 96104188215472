import * as React                                       from "react"
import {connect}                                        from "react-redux"
import {retrieveUniqueGreenShapes, validateUniqueGreenShape} from "../../../actions/shapes/ShapeValidation"
import {addCodeToQueue}                                 from "../../../actions/EditGeoBid"
import ArrayUtils                                       from "../../../utils/ArrayUtils"
import {Button}                                         from "carbon-components-react"
import MapBuilder                                       from "../../../components/Map/MapBuilder"
import ExplainedLoading                                 from "../../../components/loading"
import GoBack                                           from "../../../components/Button/ButtonGoBack"
import {Panel}                                          from "../../../components/Panel/Panel"

interface Step3OwnProps{
  exit ?: ()=>void
}
interface Step3Props extends Step3OwnProps{
  shapes         : any[]
  cache          : any
  metaCache      : any
  loadShapes     : ()=>void
  addCodeToQueue : (code:number)=>void
  validateShape  : (shapeId:number, valid:boolean)=>void
}
const mapStateToProps = (state,ownProps:Step3OwnProps)=>{
  return {
    shapes    : state.Params.GeoBids.Shape.UniqueGreenShapes,
    cache     : state.Params.GeoBids.GeocodingCache,
    metaCache : state.Params.GeoBids.GeocodingMetaCache,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadShapes     : ()=>dispatch(retrieveUniqueGreenShapes()),
    validateShape  : (shapeId:number, valid:boolean)=>dispatch(validateUniqueGreenShape(shapeId, valid)),
    addCodeToQueue : (code:number)=>dispatch(addCodeToQueue(code))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
interface Step3State{
  currentShape ?: any
  score : number
}
class Step3 extends React.Component<Step3Props,Step3State>{

  constructor(props){
    super(props)
    this.state = {
      score : 0
    }
    this.handleChoice = this.handleChoice.bind(this)
  }
  static getDerivedStateFromProps(props, state){
    if(state.currentShape === undefined && props.shapes.length > 0){
      return {
        currentShape : props.shapes[0]
      }
    }
    return null
  }
  componentDidMount(){
    if(this.props.shapes.length === 0){
      this.props.loadShapes()
    }
  }
  componentDidUpdate(prevProps){
    if(prevProps.shapes.length === 1 && this.props.shapes.length === 0){
      this.props.loadShapes()
      this.setState({currentShape:undefined})
    }
    if(prevProps.shapes.length === 0 && this.props.shapes.length > 0){
      for(let shape of this.props.shapes){
        const code = shape.code
        if(!this.props.cache[code] && !this.props.metaCache[code]){
          this.props.addCodeToQueue(code)
        }
      }
    }
  }
  handleChoice(valid:boolean){
    this.props.validateShape(this.state.currentShape.id, valid)
    this.setState(prevState=>({
      currentShape:undefined,
      score : prevState.score+1
    }))
  }
  render(){
    if(!this.state.currentShape){return <ExplainedLoading text="Waiting for shapes"/>}
    const currentCode = this.state.currentShape.code
    if(!this.props.cache[currentCode] && !this.props.metaCache[currentCode]){
      return <ExplainedLoading text="Waiting for geocoding"/>
    }
    const placeOfShapes = {
      id : currentCode,
      code : currentCode,
      edited : false,
      include : true,
      address : "",
      bidModifier : 1,
      placeProps : {
        position : (
          this.props.metaCache[currentCode]
          ? this.props.metaCache[currentCode]
          : this.props.cache[currentCode]
        ),
        editable : false
      }
    }
    return (
      <Panel title="Step3" subtitle="Are those shapes like AdWords'">
        <GoBack onClick={this.props.exit}/>
        <h4>Score: {this.state.score}</h4>
        <h3>{this.state.currentShape.name}</h3>
        <MapBuilder
          placesDefinitions={[placeOfShapes]}
          shapes={{[currentCode]:this.state.currentShape}}
          displayPinAndShape
        />
        <div>
              <Button onClick={()=>this.handleChoice(true)} style={{backgroundColor:"green"}}>Good</Button>
              <Button onClick={()=>this.handleChoice(false)} style={{backgroundColor:"red"}}>Edit required</Button>
        </div>
      </Panel>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(Step3)
