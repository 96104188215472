import './SoldCarsTable.css'
import * as React                             from 'react'
import {connect}                              from "react-redux"
import {Button}                               from "carbon-components-react"
import {Link}                                 from "react-router-dom"
import {Translate}                            from "react-localize-redux"
import PreferencesAccessors                   from "../../storeAccessor/Preferences"
import SoldCarsDataTable                      from "./SoldCarsDataTable"
import SoldCarsKPIs                           from "./SoldCarsKPIs"
import DataVerification                       from "./DataVerification"
import SoldCarsPercent                        from './SoldCarsPercent'
import requiresInventoryTurnCompilation       from '../inventoryTurnAnalysis/inventoryTurnCompilationLoader'
import NumberFormatter                        from "../../utils/NumberFormatter"


export class SoldCarsTable_dumb_missing_period extends React.Component<any,any>{
  shouldComponentUpdate(prevProps){
    if(this.props.compiled && prevProps.compiled && this.props.compiled.length === prevProps.compiled.length){return false}
    return true
  }
  render(){
    const vehicles = this.props.vehicles
    const proportionPercent = vehicles.length === 0 ? "-%" : NumberFormatter.formatNumber(this.props.compiled.length/vehicles.length*100, 0)+"%"
    return (
      <div className='SoldCarsTable'>
        <SoldCarsKPIs
          compiledCount={this.props.compiled.length}
          vehiclesCount={vehicles.length}
          companyName={this.props.companyName}
        />
        <DataVerification
          period={this.props.period}
          vehicles={vehicles}
          compiled={this.props.compiled}
        >
          <SoldCarsDataTable
            vehiclesWithNavigations={this.props.compiled}
            accountId={this.props.accountId}
            companyName={this.props.companyName}
          />
        </DataVerification>
        <div className='ProportionKPIWrapper'>
          <div className='SeeMore'>
            {
              this.props.showMoreButton !== undefined && this.props.showMoreButton===true
              ? <Link to='InventoryTurnAnalysis'><Button><Translate id="inventoryTurn.moreButton" /></Button></Link>
              : null
            }
          </div>
          <SoldCarsPercent percent={proportionPercent} companyName={this.props.companyName}/>
        </div>
      </div>
    )
  }
}
const makeMapStateToProps = () => {
  const companyNameSelector = PreferencesAccessors.makeCompanyNameSelector()
  return (state,ownProps)=>{
    return {
      period : state.Period,
      accountId : state.Accounts.selected,
      companyName : companyNameSelector(state)
    }
  }
}
const mapDispatchToProps = (dispatch)=>{return {}}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
export const SoldCarsTable_dumb = connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(SoldCarsTable_dumb_missing_period)
const SoldCarsTable = requiresInventoryTurnCompilation(SoldCarsTable_dumb)

export default SoldCarsTable
