import './VehicleStateChooser.css'
import * as React                   from "react"
import {withLocalize}               from "react-localize-redux"
import {Panel}                      from "../../components/Panel/Panel"
import Button                       from "../../components/Button/MinimalistButton"

const constructURL = (currentLocation:string, toAdd:string):string => {
  return (currentLocation+toAdd).replace("//","/")
}
const Chooser = (props) => {
  return (
    <Panel title={props.translate("rules.title")} subtitle={props.translate("rules.subtitle")}>
      <div className='MinimalistButtonsList'>
        <Button
          text={props.translate("common.new")}
          subtitle={props.translate("rules.newSubtitle")}
          onClick={()=>props.history.push(constructURL(props.history.location.pathname, "/New"))}
        />
        <Button
          text={props.translate("common.used")}
          subtitle={props.translate("rules.usedSubtitle")}
          onClick={()=>props.history.push(constructURL(props.history.location.pathname, "/Used"))}
        />
     </div>
   </Panel>
  )
}

export default withLocalize(Chooser)
