import './Alert.css'
import * as React      from "react"
import {Translate}     from "react-localize-redux"

interface AlertProps{
  text        : any
  title      ?: string
  pathText   ?: string
  path       ?: string
  titleColor ?: string
  textColor  ?: string
  titleFontColor ?: string
  textFontColor ?: string
  isActive   ?: boolean
  translateId ?: string
}
class Alert extends React.Component<AlertProps,any> {
  static defaultProps = {
    title      : "Information",
    text       : "This Campaign was automatically ...",
    titleColor : "#f08e87",
    textColor  : "#e6534866",
    titleFontColor : "white",
    textFontColor : "white",
    isActive   : true
  }
  private textStyle
  private titleStyle

  constructor(props){
    super(props)
    this.state = {
      isActive: this.props.isActive,
    }
    this.textStyle  = {backgroundColor:this.props.textColor}
    this.titleStyle = {backgroundColor:this.props.titleColor, color:this.props.titleFontColor}
    this.hideAlert = this.hideAlert.bind(this)
  }
  componentDidUpdate(prevProps){
    if(prevProps.isActive !== this.props.isActive){
      this.setState({isActive:this.props.isActive})
    }
  }
  hideAlert(){
    this.setState({isActive: false})
  }
  renderText(){
    if(this.props.translateId){return <Translate id={this.props.translateId}/>}
    return this.props.text
  }
  render(){
    if(!this.state.isActive){return null}
    return (
      <div className="Alert" style={this.textStyle}>
        <div className="AlertTitle" style={this.titleStyle}>{this.props.title}</div>
        <div className="AlertText" style={{color:this.props.textFontColor}} >
          {this.renderText()}
        </div>
        <div className="AlertClose">
          <a className="close" data-dismiss="alert" onClick={this.hideAlert}>&times;</a>
        </div>
      </div>
    )
  }
}

export default Alert
