import axios      from "axios"
import FVUtils    from "../../utils/FieldsValuesDefinitionUtils"
import Actions    from "../../model/constant/actions"
import {getToken} from "../../storeAccessor/Generic"
import Headstone  from "../../utils/Headstone"

export default (accountId:number) => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_CLEANER_REFERENCE,
    status : true
  })
  axios.get(Headstone.getApiUrl() + `/${accountId}/Cleaner`, {
    headers : {
      Authorization : "Bearer "+getToken(getState())
    },
  })
  .then((response)=>{
    dispatch({
      type      : Actions.RETRIEVE_CLEANER_REFERENCE,
      accountId : accountId,
      cleaner   : FVUtils.decompress(response.data),
    })
  })
  .catch((error)=>{
    console.log(error)
  })
  .then(()=>{
    dispatch({
      type   : Actions.RETRIEVING_CLEANER_REFERENCE,
      status : false
    })
  })
}
