import './AccountModalWrapper.css'
import * as React                   from "react"
import {connect}                    from 'react-redux'
import {withRouter}                 from "react-router"
import {Translate}                  from "react-localize-redux"
import keydown                      from 'react-keydown'
import Account                      from "../../../../../model/Store/Account/Account"
import getAccount                   from "../../../../../actions/account/retrieveAccounts"
import {modifySelectedAccountsList} from "../../../../../actions/account/modifySelected"
import Modal                        from "./AccountsModal"
import AccountName from '../../header/AccountName'

interface AccountModalWrapperProps{
  areAccountsFetching : boolean
  areAccountsFetched  : boolean
  accounts            : Account[]
  selectedId          : number
  loadAccounts        : ()=>any
  onClick             : (history,accountId)=>void
  history             : any
  isToggled           : boolean
}
const ignoredSection = [
  "/profile",
  "/accounts",
  "/account",
]
const onClick = (history, accountId, defaultAccountPage, selectAccountId) => {
  if(history.location.pathname.split("/").filter(x=>x)[0].toLowerCase() !== "account"){
    //If we are not in an "account based" page, simply select the account
    selectAccountId(accountId)
    return
  }
  let section = history.location.pathname.replace(/\/account(s)?\/[\d]+/gi,"")
  if(section === ""){section = defaultAccountPage}
  const wished = "/Account/"+accountId+section
  if(history.location.pathname !== wished){history.push(wished)}
}
const mapStateToProps = (state, ownProps) => {
  return {
    areAccountsFetching : state.Accounts.isFetching,
    areAccountsFetched  : state.Accounts.isFetched,
    accounts            : state.Accounts.list,
    selectedId          : state.Accounts.selected,
    defaultAccountPage  : state.Login.userLogged.defaultAccountPage,
  }
}
const mapDispatchToProps = dispatch => {
  const selectAccountId = (accountId)=>dispatch(modifySelectedAccountsList(accountId))
  return {
    loadAccounts : () => dispatch(getAccount()),
    onClick      : (history,accountId,defaultAccountPage) =>(
      onClick(history,accountId,defaultAccountPage,selectAccountId)
    ),
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    onClick      : (history,accountId) => DP.onClick(history,accountId,SP.defaultAccountPage)
  }
}
interface AccountModalWrapperState{
  isOpen    : boolean
}
const startsWithAccount = new RegExp(/^#\/account/, "i")
class AccountModalWrapper extends React.Component<AccountModalWrapperProps,AccountModalWrapperState> {
  constructor(props){
    super(props)
    this.state = {
      isOpen    : props.accounts.length > 0 && !props.selectedId && startsWithAccount.test(document.location.hash),
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.onAccountSelect = this.onAccountSelect.bind(this)
  }
  componentDidUpdate(prevProps, prevState){
    if(
      !prevState.isOpen &&
      !this.props.selectedId &&
      prevProps.accounts.length === 0 &&
      this.props.accounts.length > 0 &&
      startsWithAccount.test(document.location.hash)
    ){
      //If we just received accounts and no accounts were selected, open the modal
      this.setState({isOpen:true})
    }
  }
  @keydown(['ctrl+alt+a'])
  toggleModal(){
    this.setState(prevState=>({
      isOpen: !prevState.isOpen,
    }))
  }
  @keydown(['esc'])
  close(){
    if(this.state.isOpen){this.setState({isOpen:false})}
  }
  onAccountSelect(accountId){
    this.props.onClick(this.props.history, accountId)
    this.toggleModal()
  }
  render(){
    const props = this.props
    if(!props.areAccountsFetched){
      if(!props.areAccountsFetching){
        props.loadAccounts()
      }
      return <div className="account-modal-wrapper"><Translate id="menu.accountsModal.loading"/></div>
    }
    return (
      <>
        <div onClick={this.toggleModal} className={props.isToggled ? "account-modal-wrapper" : "account-modal-wrapper-toggled"}>
          <span><AccountName /></span>
        </div>
        <Modal
          accounts={props.accounts}
          onRequestClose={this.toggleModal}
          onRowClick={this.onAccountSelect}
          open={this.state.isOpen}
        />
      </>
    )
  }
}
export default (
  withRouter(
    connect(mapStateToProps,mapDispatchToProps,mergeProps)(
      AccountModalWrapper
    )
  )
)
