import {
  HeadstoneGeoBidRadius,
  HeadstoneGeoBidPlace
}                           from "../model/headstone/geoBids"

export const isRadius = (geoBid: HeadstoneGeoBidRadius | HeadstoneGeoBidPlace): geoBid is HeadstoneGeoBidRadius => {
  return (geoBid as HeadstoneGeoBidRadius).radius !== undefined
}
export const isPlace = (geoBid: HeadstoneGeoBidRadius | HeadstoneGeoBidPlace): geoBid is HeadstoneGeoBidPlace => {
  return (geoBid as HeadstoneGeoBidPlace).code !== undefined
}
