import './AccountName.css'
import * as React from "react"
import { connect } from 'react-redux'
import { Translate } from "react-localize-redux"
import DesignedDarkTooltip from "../../../tooltip/DesignedDarkTooltip"

const mapStateToProps = (state, ownProps) => {
  let selectedAccount = state.Accounts.list.find(account => account.id == state.Accounts.selected)
  return {
    account: selectedAccount,
  }
}
const mapDispatchToProps = dispatch => {
  return {}
}

const AccountName = (props) => {
  return (
    <DesignedDarkTooltip position="top" text={props.account
      ? props.account.name
      : <Translate id="menu.noAccount" />} arrowColor={props.color}>
      <div className='menu-account-name'>
        {props.account
          ? props.account.name
          : <Translate id="menu.noAccount" />}
      </div>
    </DesignedDarkTooltip>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountName)
