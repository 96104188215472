import { createMuiTheme } from "@material-ui/core"

export interface ThemeParams {
  primary   : string
  secondary : string
}

const generateTheme = (params:ThemeParams) => {
  return {
    palette : {
      primary   : {
        main: params.primary,
        contrastText : "#ffffff",
      },
      secondary : {
        main: params.secondary,
      },
    },
    typography : {
      //useNextVariants : true,
      fontFamily : [
        "Oswald semi Bold",
        "Lato",
        "Open Sans",

      ].join(",")
    },
    overrides : {
      MuiButton : {
        contained : {
          fontWeight : 600
        },
        containedPrimary : {
          fontWeight : 600
        },
      },
      MuiTableCell : {
        stickyHeader: {
          backgroundColor: params.primary,
          color: "white",
        },
      }
    }
  }
}

export default generateTheme
