import axios         from "axios"
import Keystone      from "../utils/Keystone"
import FVDUtils      from "../utils/FieldsValuesDefinitionUtils"
import Actions       from "../model/constant/actions"
import {getToken}    from "../storeAccessor/Generic"

export const loadAdWordsPlaces = () => dispatch => {
  axios.get(process.env.PUBLIC_URL+"/assets/AdWordsPlaces.json")
  .then(response=>{
    dispatch({
      type : Actions.RETRIEVE_ADWORDS_PLACES,
      data : response.data
    })
  })
  .catch(error=>console.log(error))
}
export const loadMetaGeoCodingCache = () => (dispatch, getState) => {
  Keystone.get({
      action : "GetAdWordsPlaceGeoCodingCache",
      token  : getToken(getState()),
    },
    response => dispatch({
      type : Actions.RETRIEVE_ADWORDS_PLACES_META_GEOCODING_CACHE,
      data : FVDUtils.decompress(response.data.data)
    }),
    error => console.log(error)
  )
}
interface PlacePosition {
  code : number
  position : {
    lat : number
    lng : number
  }
}
export const saveMetaGeoCodingCache = (placePositions:PlacePosition[]) => (dispatch, getState) => {
  Keystone.post({
      action : "SaveAdWordsPlaceGeoCodingCache",
      token  : getToken(getState()),
      placePositions : JSON.stringify(
        placePositions.map(pp=>[
          String(pp.code),
          pp.position.lat,
          pp.position.lng,
        ])
      )
    },
    response => dispatch(loadMetaGeoCodingCache()),
    error    => console.log(error)
  )
}
