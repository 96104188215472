import "./PacyEnablement.css"
import * as React                                 from "react"
import moment                                     from "moment"
import {PacyEnablementDecision}                   from "../../../../../model/pacy/Decision"
import {Typography}                               from '@material-ui/core'
import Accordion                                  from '@material-ui/core/Accordion'
import AccordionSummary                           from '@material-ui/core/AccordionSummary'
import {Translate}                                from "react-localize-redux"

interface  PacyEnablementProps extends PacyEnablementOwnProps {
}

interface PacyEnablementOwnProps{
  decision : PacyEnablementDecision
  users : {[userId:string]: string}
}


class PacyEnablement extends React.Component<PacyEnablementProps,any>{
  shouldComponentUpdate(nextProps:PacyEnablementProps, nextState){
    return this.props.decision.id !== nextProps.decision.id
  }
  render(){
    const date = moment(this.props.decision.createdOn).format("LLLL") //Format the Date to Locale
    const userEmail = this.props.users[this.props.decision.details.userId] || this.props.decision.details.userId

    return (
      <Accordion TransitionProps={{mountOnEnter: true}}>
        <AccordionSummary>
          <div className="PacyEnablement">
            <div className="Content">
              <div>
                <Typography className="title" color="textSecondary"><Translate id={"pacyChangesHistory.changeType.enablement"}/></Typography>
                <Typography color="textSecondary">
                  <Translate id={"pacyChangesHistory.status.status"}/>:&nbsp;
                  {this.props.decision.details.status ? <Translate id={"pacyChangesHistory.status.activated"}/> : <Translate id={"pacyChangesHistory.status.deactivated"}/>}
                </Typography>
              </div>
            </div>
            <div className="Author">
              <Typography color="textSecondary"><em>{date}</em></Typography>
              <Typography color="textSecondary">{userEmail}</Typography>
            </div>
          </div>
        </AccordionSummary>
      </Accordion>
    )
  }
}

export default PacyEnablement
