import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"

const setStatus = (state, action) => {
  return action.status
}

const Retrieving = ReducerUtils.createReducer<boolean>(false,{
  [Actions.RETRIEVING_CAMPAIGNS_BASE_INFO] : setStatus
})

export default Retrieving
