import ArrayUtils  from "../utils/ArrayUtils"
import Permissions from "../model/constant/Permissions"

export default class PermissionsUtils{
  private accountId = null
  private permissions = []
  private profile = null

  constructor(accountId:number, permissions:any[], profile:any){
    this.accountId   = accountId
    this.permissions = permissions
    this.profile     = profile
  }
  private getPermissionsForAccount(){
    let permissions = this.permissions.filter(permissionGroup =>{
      return permissionGroup.AccountID == this.accountId || permissionGroup.AccountID == null
    })
    if(permissions.length && this.accountId && permissions.find(x=>x.AccountID === this.accountId) !== undefined){
      //If we have permissions specific to the selected account, remove permission for all accounts
      permissions = permissions.filter(x=>x.AccountID !== null)
    }
    return permissions ? permissions:[]
  }
  public validateStaffLevel(staffLevel:number) : boolean {
    return this.profile && this.profile.userLevel <= staffLevel
  }
  public validatePermission(permissionName:string) : boolean {
    let valid = this.getPermissionsForAccount().find(permissionGroup => {
      return ArrayUtils.contain(permissionGroup.Permissions,permissionName)
    })
    return !!valid
  }
  public validatePermissions(requiredPermissions : Permissions[]) : boolean {
    for (let permission of requiredPermissions) {
      if(!this.validatePermission(permission)){
        return false
      }
    }
    return true
  }
}
