import Actions   from "../model/constant/actions"

let pausedAction  = []

const navigationBlocker = (store) => (next) => (action) => {
  const shouldBlockNavigation = store.getState().Navigation.ShouldBlock.status

  // Will need to implement 'connected-react-router' to make it works
  if(action.type==="@@router/LOCATION_CHANGE" && shouldBlockNavigation){
    pausedAction.push(action)
    store.dispatch({type: Actions.SHOW_NAVIGATION_CONFIRMATION_MODAL, status: true})
    return
  }
  // Not blocking navigation, just a reminder to save next time for now
  if(action.type===Actions.CHANGE_ACTIVE_BUTTON_IN_MENU && shouldBlockNavigation){
    store.dispatch({type: Actions.SHOW_NAVIGATION_CONFIRMATION_MODAL, status: true})
  }
  if(action.type===Actions.CONFIRM_NAVIGATION){
    for (const nextAction of pausedAction){
      next(nextAction)
    }
    store.dispatch({type: Actions.SHOULD_BLOCK_NAVIGATION, status: false})
    pausedAction = []
    return
  }
  if(action.type===Actions.CANCEL_NAVIGATION){
    store.dispatch({type: Actions.SHOULD_BLOCK_NAVIGATION, status: false})
    pausedAction = []
    return
  }
  next(action)
}

export default navigationBlocker
