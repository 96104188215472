import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const saveBool = (state,action) => {
  return action.status
}
const Retrieving = ReducerUtils.createReducer<boolean>(false,{
  [Actions.RETRIEVING_TARGET_USER_PREFERENCES] : saveBool,
})
export default Retrieving
