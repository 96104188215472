import Actions          from "../../../model/constant/actions"
import ReducerUtils     from "../../../utils/ReducerUtils"
import ObjectUtils      from "../../../utils/ObjectUtils"
import BingKeywordStats from "../../../model/Store/Statistics/KeywordStats"

const retrieveKeywordsStatsForAccount = (state,action) =>{
  if (action.stats){
    if (state[action.accountId]){
      var newState={}
      for (let stat of state[action.accountId] ){ newState[stat.CampaignBingId+stat.Day+stat.KeywordId] = stat }
      for (let stat of action.stats            ){ newState[stat.CampaignBingId+stat.Day+stat.KeywordId] = stat }
      return {...state, [action.accountId] : ObjectUtils.getObjectValues(newState)}
    }
    return {...state,[action.accountId] : action.stats}
  }
  return {...state,[action.accountId] : []}
}
interface BingKeywordByAccount{
  [accountId:number] : BingKeywordStats[]
}
export const KeywordsStats = ReducerUtils.createReducer<BingKeywordByAccount>({},{
  [Actions.RETRIEVE_BING_KEYWORDS_STATS_OF_ACCOUNT] : retrieveKeywordsStatsForAccount
})
