import './SimpleCard.css'
import * as React     from "react"

interface SimpleCardProps{
  iconPath        : string
  text            : any
  number          : string
}
const SimpleCard = (props:SimpleCardProps) => {
  return(
    <div className="SimpleCard">
      <div className="SimpleCardIcon">
        <img src={props.iconPath}/>
        <div className="SimpleCardText">{props.text}</div>
      </div>
      <div className="SimpleCardNumberText"> {props.number}</div>
    </div>
  )
}

export default SimpleCard
