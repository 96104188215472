import Actions             from "../../model/constant/actions"
import ReducerUtils        from "../../utils/ReducerUtils"
import ObjectUtils         from "../../utils/ObjectUtils"
import {MakeModelsReducer} from "../../model/Store/Cleaner"

const save = (state,action) => ({
  ...state,
  [action.accountId] : ObjectUtils.getObjectValues(
    action.cleaner.reduce((byMakeModel, row)=>{
      byMakeModel[row.DisplayMakeModel] = {
        ID               : row.ID,
        DisplayMake      : row.DisplayMake,
        DisplayModel     : row.DisplayModel,
        DisplayMakeModel : row.DisplayMakeModel,
      }
      return byMakeModel
    },{})
  )
})

export default ReducerUtils.createReducer<MakeModelsReducer>({},{
  [Actions.RETRIEVE_CLEANER_REFERENCE] : save
})
