import BudgetPlanPart  from "./BudgetPlanPart"

export default class BudgetPlan {

  private accountId
  private budgetId
  private provider
  private parts = []

  public constructor(accountId:number, budgetId:number, provider:string, parts:BudgetPlanPart[]=[]){
    this.accountId = accountId
    this.budgetId  = budgetId
    this.provider  = provider
    this.parts     = parts
  }
  clone() : BudgetPlan {
    return new BudgetPlan(
      this.accountId,
      this.budgetId,
      this.provider,
      this.parts.map(p=>p.clone()),
    )
  }
  public getAccountId():number       {return this.accountId}
  public getBudgetId():number        {return this.budgetId}
  public getProvider():string        {return this.provider}
  public getParts():BudgetPlanPart[] {return this.parts}
}


