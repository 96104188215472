import Keystone          from "../../utils/Keystone"
import Actions           from "../../model/constant/actions"
import {getToken}        from "../../storeAccessor/Generic"

export const retrieveDisplayCampaigns = (accountId:number) => (dispatch, getState) => {
  dispatch({
    type : Actions.RETRIEVING_DISPLAY_CAMPAIGNS,
    status : true
  })
  Keystone.get({
      accountId,
      token : getToken(getState()),
      action : "GetDisplayCampaigns"
    },
    (response)=>{
      const campaigns = response.data.campaigns.map(campaign=>{
        return {
          externalCampaignId : campaign.campaignAWId,
          accountId,
          name               : campaign.campaignName,
          status             : campaign.status,
          budgetId           : campaign.budgetId,
          budgetName         : campaign.budgetName,
          geoBids            : campaign.geoBids.reduce((grouped, geo)=>{
            const group = geo.instanceof === "AWPlace" ? "places" : "radiuses"
            grouped[group].push(geo)
            return grouped
          },{radiuses:[], places:[]})
        }
      })
      dispatch({
        type : Actions.RETRIEVE_DISPLAY_CAMPAIGNS,
        accountId,
        campaigns
      })
      dispatch({
        type : Actions.RETRIEVING_DISPLAY_CAMPAIGNS,
        status : false
      })
    },
    (error)=>{
      console.log(error)
      dispatch({
        type : Actions.RETRIEVING_DISPLAY_CAMPAIGNS,
        status : false
      })
    }
  )
}
