import './Hint.css'
import * as React                 from "react"
import {withLocalize}             from "react-localize-redux"
import Friend                     from "../../../../../../model/Store/Campaign/Friend"
import KeyValueTable              from "../../../../../../components/table/KeyValueTable"
import HintCards                  from "./component/HintCards"
import {Provider}                 from "../../../../../../model/Provider"

interface HintProps{
  object    : Friend
  translate : (translateId:string)=>string
}
const getProviderIdLabelTranslationKey = (provider:Provider) : string => {
  return provider === Provider.BING ? "campaigns.hint.BingID" : "campaigns.hint.AWID"
}
const Hint = (props:HintProps) => {
  return(
    <div className="Hint">
      <HintCards friend={props.object}/>
      <div key='CampaignsInfos' className="CampaignInternalInfo">
        {props.object.getCampaigns().map((campaign,i)=>
          <div key={i} className='MultiKeyValueTable MultiKeyValueTable-horizontal'>
            <KeyValueTable
              title={props.translate("campaigns.hint.campaign")+" #"+campaign.externalCampaignId}
              keyValue={{
                [props.translate("campaigns.hint.status")]   : campaign.status,
                [props.translate("campaigns.hint.language")] : campaign.lang,
                [props.translate(getProviderIdLabelTranslationKey(campaign.provider))] : campaign.externalCampaignId,
                [props.translate("campaigns.hint.name")]     : campaign.name,
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default withLocalize(Hint as any)
