import * as React                       from "react"
import {connect}                        from "react-redux"
import ExplainedLoading                 from "../loading"
import ErrorBoundary                    from "../Error/ErrorBoundary"
import retrieveFriends                  from "../../actions/campaign/retrieveCampaigns"

const mapStateToProps = (state,ownProps)=>{
  return {
    accountId      : state.Accounts.selected,
    friends        : state.Campaigns.list,
    retrieving     : state.Campaigns.retrievingFriends
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    retrieveFriends : (accountId:number) => {dispatch(retrieveFriends(accountId))}
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    retrieveFriends : ()=>{
      if(SP.accountId){DP.retrieveFriends(SP.accountId)}
    }
  }
}
const FriendsLoader = props => {
  if(props.friends[props.accountId]===undefined || props.friends[props.accountId].friends===undefined){
    if(!props.retrieving){props.retrieveFriends()}
    if(!props.letThrough){
      return <ExplainedLoading translateId="loadings.campaigns"/>
    }
  }
  const friends = props.friends[props.accountId] && props.friends[props.accountId].friends
  const allLangFriends = props.friends[props.accountId] && props.friends[props.accountId].allLangFriends
  return <ErrorBoundary>{props.render(friends, allLangFriends, props.retrieving)}</ErrorBoundary>
}
const FriendsConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(FriendsLoader)

const requiresFriends = (letThrough?:boolean) => Component => props => (
  <FriendsConnected
    letThrough={letThrough || false}
    render={ (friends, allLangFriends, loading) => <Component friends={friends} allLangFriends={allLangFriends} loadingFriends={loading} {...props} /> }
  />
)

export default requiresFriends
