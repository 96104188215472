import Column               from "./Column"
import SimplifiedColumn     from "./SimplifiedColumn"
import AgglomeratedStats    from "./AgglomeratedStats"
import {FILTERS}            from "../../constant/DataTableFilters"
import {FORMATTERS}         from "../../constant/DataTableFormatters"
import ObjectUtils          from "../../../utils/ObjectUtils"


export default class ColumnDefinition{
  private text                : string
  private dataField           : string
  private orderIndex          : number
  private isKey               : boolean = false
  private hidden              : boolean = false
  private sort                : boolean = true
  private formatter           : string  = undefined
  private filterType          : string  = undefined
  private filterOptions       : any     = {}
  private agglomeratingKey    : string  = undefined
  private aggValueExtractor   : (value:any)=>any = (x)=>x
  private filterFunc          : (value:any)=>void = undefined

  constructor(
    text              :   string,
    dataField         :   string,
    orderIndex        :   number,
    isKey             ?:  boolean,
    hidden            ?:  boolean,
    sort              ?:  boolean,
    formatter         ?:  string,
    filterType        ?:  string,
    filterOptions     ?:  any,
    agglomeratingKey  ?:  string,
    aggValueExtractor ?:  (value:any)=>any
  ){
    this.text          = text
    this.dataField     = dataField
    this.orderIndex    = orderIndex
    this.isKey         = isKey           ? isKey         : this.isKey
    this.hidden        = hidden          ? hidden        : this.hidden
    this.sort          = sort!=undefined ? sort          : this.sort
    this.filterOptions = filterOptions   ? filterOptions : this.filterOptions
    if(formatter){
      this.formatter = (
        FORMATTERS[formatter.toUpperCase()]
        ? formatter.toUpperCase()
        : this.formatter
      )
    }
    if(filterType){
      this.filterType = (
        FILTERS[filterType.toUpperCase()]
        ? filterType.toUpperCase()
        : this.filterType
       )
    }
    this.agglomeratingKey = agglomeratingKey
    this.aggValueExtractor = aggValueExtractor ? aggValueExtractor : this.aggValueExtractor
  }
  clone(){
    return new ColumnDefinition(
      this.text,
      this.dataField,
      this.orderIndex,
      this.isKey,
      this.hidden,
      this.sort,
      this.formatter,
      this.filterType,
      {...this.filterOptions},
      this.agglomeratingKey,
      this.aggValueExtractor
    )
  }

  public setHidden(hidden:boolean)         { this.hidden           = hidden  }
  public setOrderIndex(index:number)       { this.orderIndex       = index   }
  public setFilterOptions(options:any)     { this.filterOptions    = options }
  public setAgglomeratingKey(key:string)   { this.agglomeratingKey = key     }
  public setAggValueExtractor(func:(value:any)=>any) { this.aggValueExtractor = func }
  public setFormatter(formatter:string)    { if(FORMATTERS[formatter.toUpperCase()]){this.formatter = formatter.toUpperCase()}}
  public setFilterType(fType:string)       { if(FILTERS[fType.toUpperCase()]){this.filterType = fType.toUpperCase()} }

  public getText()          : string  { return this.text          }
  public getDataField()     : string  { return this.dataField     }
  public getOrderIndex()    : number  { return this.orderIndex    }
  public getIsKey()         : boolean { return this.isKey         }
  public getHidden()        : boolean { return this.hidden        }
  public getSort()          : boolean { return this.sort          }
  public getFormatter()     : string  { return this.formatter     }
  public getFilterType()    : string  { return this.filterType    }
  public getFilterOptions() : any     { return this.filterOptions }

  public toggleHidden()                    { this.hidden = !this.hidden }
  public getComputed() : Column {
    return {
      text      : this.text,
      dataField : this.dataField,
      formatter : this.formatter?FORMATTERS[this.formatter]:undefined,
      filter    : this.filterType
                    ? FILTERS[this.filterType]({
                        ...this.filterOptions,
                        getFilter:this.extractFilterFunc.bind(this)
                      })
                    : undefined,
      isKey     : this.isKey,
      hidden    : this.hidden,
      sort      : this.sort
    }
  }
  public getSimplifiedObject():SimplifiedColumn{
    return {
      dataField : this.dataField,
      orderIndex : this.orderIndex,
      filterOptions : this.filterOptions
    }
  }

  public doFilterWithDefaultValue(){
    if(this.filterFunc && this.filterOptions.defaultValue){
      this.filterFunc(this.filterOptions.defaultValue)
    }
  }

  public produceAgglomeratingFunction() : (x:AgglomeratedStats|any[]) => AgglomeratedStats|any[] {
    if(this.hidden || !this.agglomeratingKey){return x=>x}
    const func = x=>{
      if(x === undefined){return x}
      if(x instanceof Array){
        if(!x.length){return x}
        return ColumnDefinition.agglomerate(this.agglomeratingKey,x,this.aggValueExtractor)
      }
      const newValues = ObjectUtils.mapOnObject(x.values,(key:string,values:AgglomeratedStats|any[])=>{
        return func(values)
      })
      return {...x,values:newValues}
    }
    return func
  }
  private static agglomerate(key:string, stats:any[], valueExtractor:(value:any)=>any) : AgglomeratedStats|any[]{
    var values = {}
    for(let stat of stats){
      let statC = {...stat}
      const value = valueExtractor(statC[key])
      delete statC[key]
      if(!values[value]){values[value] = []}
      values[value].push(statC)
    }
    if(Object.keys(values).length<2){return stats}
    return {
      instanceof : "AgglomeratedStats",
      key        : key,
      values     : values,
    }
  }

  private extractFilterFunc(filter:any){
    this.filterFunc = filter
  }
}
