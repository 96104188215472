import axios, {Method}                    from "axios"
import IdentityVariants                   from '../model/branding/IdentityVariants'
import {
  AdGroupConfigsByLang,
  AdGroupConfigsByName
}                                         from "../model/branding/AdGroupConfigs"
import { AudienceAd }                     from "../model/branding/AudienceAd"
import HeadstoneEngagementRule            from "../model/engagementRules/HeadstoneEngagementRule"
import {
  AdTemplate,
  MarketingEventResponse,
  SavedMarketingEvent,
}                                         from "../model/adbuilder/index"
import AdCopy                             from "../model/Store/AdCopyPatterns/AdCopy"
import { CreateAdCopyPatternBody }        from '../actions/AdCopyPatterns'
import {
  HeadstoneStrategy,
  HeadstoneActiveStrategies
}                                         from "../model/Store/Strategy/Strategy"
import { GeoExceptionRequestBody }        from "../page/campaign/component/search/view/Geo/Geo"
import {
  SetNewGeoParamBody,
  HeadstoneGeoBidResponse,
  GeoBidExceptions
}                                         from "../model/headstone/geoBids"
import { SimplifiedIdentityVariants }     from "../model/branding/SimplifiedIdentityVariants"
import {AdCopyRSAPatterns}                from "../model/adcopy"
import BiddingStrategySettings            from "../model/Store/Strategy/BiddingStrategySettings";

export interface StatusExceptions {
  campaignName : string
  status       : 'PAUSED' | 'ENABLED'
}

interface AdCopyPatternsResponse {
  id          : number
  audienceId  : string
  bidModifier : number
  matcher     : {
    group       : string
    instanceof  : string
    lang        : string
    state       : string
    vehicleType : string
  }
  templates   : AdCopy[]
}

export default class Headstone {
  public static getApiUrl(){
      return process.env.REACT_APP_HEADSTONE_URL
  }
  public static doRequest<T>(method:Method, timeout:number, endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    const url = ("/"+endpoint).replace("//","/")

    return axios.request<T>({
      method,
      baseURL : Headstone.getApiUrl(),
      url     : url,
      params  : queryParams,
      data    : body,
      headers : {
        ...headers,
        Authorization : `Bearer ${token}`
      },
      timeout : 10*1000,
    })
  }
  public static get<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Headstone.doRequest<T>("get", 10*1000, endpoint, token, queryParams, body, headers)
  }
  public static post<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Headstone.doRequest<T>("post", 60*1000, endpoint, token, queryParams, body, headers)
  }
  public static put<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Headstone.doRequest<T>("put", 60*1000, endpoint, token, queryParams, body, headers)
  }
  public static patch<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Headstone.doRequest<T>("patch", 60*1000, endpoint, token, queryParams, body, headers)
  }
  public static delete<T>(endpoint:string,token?:string,queryParams?:any,body?:any,headers?:any){
    return Headstone.doRequest<T>("delete",60*1000,endpoint,token,queryParams,body,headers)
  }

  public static getAdGroupConfig(token:string, accountId:number) {
    return Headstone.get<AdGroupConfigsByLang>(accountId+"/BrandingStrategy/AdGroupConfig", token)
  }

  public static getIdentityVariants(token:string,accountId:number){
    return Headstone.get<IdentityVariants[]>(accountId+"/IdentityVariant/Branding",token)
  }
  public static saveIdentityVariants(token:string, accountId:number, lang:string, identityVariants:SimplifiedIdentityVariants) {
    return Headstone.put(
      accountId+"/IdentityVariant/Branding",
      token,
      undefined,
      {params: JSON.stringify({...identityVariants, lang})}
    )
  }
  public static createIdentityVariants(token:string, accountId:number, lang:string, identityVariants:SimplifiedIdentityVariants) {
    return Headstone.post(
      accountId+"/IdentityVariant/Branding",
      token,
      undefined,
      {params: JSON.stringify({...identityVariants, lang})}
    )
  }
  public static getNegativeKeywords(token:string,accountId:number){
    return Headstone.get(accountId+"/BrandingStrategy/NegativeKeywords",token)
  }
  public static saveNegativeKeywords(token:string, accountId:number, lang:string, keywords:string[]){
    return Headstone.put<any>(
      accountId+"/BrandingStrategy/NegativeKeywords/"+lang,
      token, undefined,
      {keywordPatterns: keywords}
    )
  }
  public static savePositiveKeywords(token:string,accountId:number,lang:string,keywords:string[],adGroup:string){
    return Headstone.patch(accountId+"/BrandingStrategy/AdGroupConfig/"+lang+"/KeywordPatterns",token,undefined,
    {params:{
      [adGroup] : keywords
    }}
    )
  }
  public static saveAdCopyPatterns(token:string,accountId:number,audienceId:number ,adCopyPatterns:any,lang:string) {
    return Headstone.patch(accountId+"/BrandingStrategy/AdGroupConfig/"+lang+"/AudienceAds/"+audienceId+"/AdCopyPatterns",token,undefined,{adCopyPatterns:adCopyPatterns})
  }
  public static createAdGroup(token:string,accountId:number,adGroupConfig:AdGroupConfigsByName[],lang:string) {
    return Headstone.post(accountId+"/BrandingStrategy/AdGroupConfig/"+lang,token,null,{params:[adGroupConfig]})
  }
  public static deleteAdGroup(token:string,accountId:number,adGroupName:string,lang:string) {
    return Headstone.delete(accountId+"/BrandingStrategy/AdGroupConfig/"+lang,token,null,{adGroupName:adGroupName})
  }
  public static editAudienceAds(token:string,accountId:number,audienceAds:AudienceAd[]) {
    return Headstone.put(accountId+"/BrandingStrategy/AdGroupConfig/AudienceAds",token,null,{params:audienceAds})
  }
  public static createAudienceAds(token:string,accountId:number,audienceAds:AudienceAd[]) {
    return Headstone.post(accountId+"/BrandingStrategy/AdGroupConfig/AudienceAds",token,null,audienceAds)
  }
  public static getStatusAllStatusExceptions(token:string,accountId:number) {
    return Headstone.get<StatusExceptions[]>(accountId+"/StatusException",token)
  }
  public static removeStatusException(token:string,accountId:number,campaignNames:string[]) {
    return Headstone.delete<{}>(accountId+"/StatusException",token,null,campaignNames)
  }
  public static addStatusException(token:string,accountId:number,statusExceptions:StatusExceptions[]) {
    return Headstone.post<{}>(accountId+"/StatusException",token,null,statusExceptions)
  }
  public static setStatusException(token:string,accountId:number,statusExceptions:StatusExceptions[]) {
    return Headstone.put<{}>(accountId+"/StatusException",token,null,statusExceptions)
  }
  public static getMarketingEvents(token:string,accountId:number) {
    return Headstone.get<MarketingEventResponse[]>(accountId+"/MarketingEvent/RSA/all",token)
  }
  public static createMarketingEvent(token:string,accountId:number,marketingEvent:SavedMarketingEvent/*ForCreation*/) {
    return Headstone.post<{}>(accountId+"/MarketingEvent/RSA",token,null,marketingEvent,null)
  }
  public static deleteMarketingEvent(token:string,accountId:number,marketingEventId:number) {
    return Headstone.delete<{}>(accountId+"/MarketingEvent/RSA/"+marketingEventId,token)
  }
  public static updateMarketingEventStatus(token:string, accountId:number, marketingEventId:number, status:boolean) {
    return Headstone.patch<{}>(accountId+"/MarketingEvent/RSA/"+marketingEventId,token,null,{active:status},null)
  }
  public static getEngagementRule(token:string,accountId:number){
    return Headstone.get<HeadstoneEngagementRule>(accountId+"/Inventory/EngagementRules",token)
  }
  public static setEngagementRule(token:string,accountId:number, engagementRules:HeadstoneEngagementRule){
    return Headstone.put<HeadstoneEngagementRule>(accountId+"/Inventory/EngagementRules",token,{},engagementRules)
  }
  public static getAdCopyPatterns(token: string, accountId: number) {
    return Headstone.get<AdCopyPatternsResponse[]>(accountId + "/InventoryStrategy/AudienceAds", token)
  }
  public static createAdCopyPattern(token: string, accountId: number, body: Omit<AdCopyPatternsResponse,"id">) {
    return Headstone.put<{}>(accountId + "/InventoryStrategy/AudienceAds", token, null, body)
  }
  public static updateAdCopyPattern(token: string, accountId: number, patterns: AdCopy[], audienceAdsContainerId: number) {
    return Headstone.patch<{}>(accountId + "/InventoryStrategy/AudienceAds/" + audienceAdsContainerId + "/AdCopyPatterns", token, null, patterns)
  }
  public static deleteAdCopyPattern(token: string, accountId: number, adCopyPatternId: number) {
    return Headstone.delete<{}>(accountId + "/InventoryStrategy/AudienceAds/AdCopyPattern/" + adCopyPatternId, token)
  }
  public static getStrategies(token: string, accountId: number) {
    return Headstone.get<HeadstoneStrategy[]>(accountId + "/Strategy", token)
  }
  public static getActiveStrategies(token: string, accountId: number) {
    return Headstone.get<HeadstoneActiveStrategies[]>(accountId + "/ActiveStrategies", token)
  }
  public static getGeoBidSettings(token: string, accountId: number, provider: string) {
    return Headstone.get<HeadstoneGeoBidResponse[]>(accountId+ "/GeoBid/" + provider, token)
  }
  public static setNewGeoParam(token: string, accountId: number, body: SetNewGeoParamBody) {
    return Headstone.put<{}>(accountId + "/GeoBid", token, null, body)
  }
  public static getGeoBidExceptionsForCampaignNames(token: string, accountId: number, campaignNames: string) {
    return Headstone.get<GeoBidExceptions[]>(accountId + "/GeoBidException", token, {campaignNames})
  }
  public static addGeoBidException(token: string, accountId: number, body: GeoExceptionRequestBody) {
    return Headstone.post<{}>(accountId + "/GeoBidException", token, null, body)
  }
  public static setGeoBidException(token: string, accountId: number, body: GeoExceptionRequestBody) {
    return Headstone.put<{}>(accountId + "/GeoBidException", token, null, body)
  }
  public static deleteGeoBidException(token: string, accountId: number, campaignNames: string[]) {
    return Headstone.delete<{}>(accountId + "/GeoBidException", token, null, campaignNames)
  }
  public static getDefaultAdSet(token: string, accountId: number, language: string, adGroupName: string) {
    return Headstone.get<AdTemplate[]>(accountId + `/DefaultAdSet/BRANDING/${language}`, token, {adGroupName, RLSA: 0})
  }
  public static getRSAAdCopies(token: string, accountId: number, campaignGroup: string) {
    return Headstone.get<AdCopyRSAPatterns[]>(`${accountId}/AdCopy/${campaignGroup}`, token)
  }
  public static saveRSAAdCopies(token: string, accountId: number, campaignGroup: string, body:AdCopyRSAPatterns[]) {
    return Headstone.patch<{}>(`${accountId}/AdCopy/${campaignGroup}`, token, null, body)
  }
  public static getBidingStrategySettings(token: string, accountId: number) {
    return Headstone.get<BiddingStrategySettings[]>("BiddingStrategySettings?accountId=" + accountId, token)
  }
  public static saveBidingStrategySettings(token: string, body) {
    return Headstone.post<BiddingStrategySettings[]>("BiddingStrategySettings/saveBiddingStrategy", token, null, body)
  }
  public static deleteBidingStrategySettings(token: string, accountId: number, group: string, lang: string, vehicleState: string) {
    return Headstone.delete<BiddingStrategySettings[]>(
        "BiddingStrategySettings/deleteBiddingStrategy?accountId=" + accountId + "&campaignGroup=" + group +
        "&lang=" + lang + "&vehicleState=" + vehicleState,
        token
    )
  }
}
