import * as React                   from "react"
import {HashRouter,Route,Redirect}  from "react-router-dom"
import {Link,RouteProps,Switch}     from "react-router-dom"
import GoogleAds                    from "../page/report"
import Bing                         from "../page/bingReport"
import Waze                         from "../page/waze"
import Pacy                         from "../page/pacyReport"
import ReportChoice                 from "../page/ReportChoice"
import {AdsTabs}                    from '../model/report/AdsTabs'

interface RouterReportsMapping{
  path : string
  content : (props)=>any // Component instance
  exact ?: boolean
}
const mapping : RouterReportsMapping[] = [
  { path:"*/Report(s)?/Ads/",                            content:(props)=> <GoogleAds/> },
  { path:"*/Report(s)?/Ads/Campaigns",                   content:(props)=> <GoogleAds tab={AdsTabs.Campaigns} /> },
  { path:"*/Report(s)?/Ads/Keywords",                    content:(props)=> <GoogleAds tab={AdsTabs.Keywords} />},
  { path:"*/Report(s)?/MicrosoftAdvertising/",           content:(props)=> <Bing/> },
  { path:"*/Report(s)?/MicrosoftAdvertising/Campaigns/", content:(props)=> <Bing tab={AdsTabs.Campaigns} />},
  { path:"*/Report(s)?/MicrosoftAdvertising/Keywords/",  content:(props)=> <Bing tab={AdsTabs.Keywords} />},
  { path:"*/Report(s)?/Waze/",                           content:(props)=> <Waze/> },
  { path:"*/Report(s)?/Budgets/", exact:false,           content:(props)=> <Pacy/> },
  { path:"*/Report(s)?/",                                content:(props)=> <ReportChoice history={props.history}/>},
]
export default (props) => {
  return (
    <HashRouter>
      <Switch>
        {mapping.map((route:RouterReportsMapping)=>
          <Route
            exact={route.exact!==undefined?route.exact:true}
            key={route.path}
            path={route.path}
            render={(props)=>route.content(props)}
          />
        )}
      </Switch>
    </HashRouter>
  )
}
