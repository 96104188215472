import './AddAccount.css'
import * as React                   from 'react'
import {connect}                    from 'react-redux'
import {createSelector}             from 'reselect'
import ArrayUtils                   from "../../../utils/ArrayUtils"
import PickBasePermissionSetup      from "./PickBasePermissionSetup"
import StatusUpdate                 from "../../../components/alert/StatusUpdate"
import AssignPermissionProfile      from "../../../actions/permission/assignPermissionProfile"

const filterAccounts = (filter,accounts)=>{
  if(filter.length===0){return accounts}
  return accounts.filter(account=>
    String(account.id) === filter ||
    (account.name as string).toLowerCase().indexOf(filter)!==-1
  )
}
interface AddAccountState {
  filter : string
  accountId : number
}
const makeMapStateToProps = ()=>{
  const accountsOfUserSelector = createSelector(
    (user:any)=>user,
    user=>ArrayUtils.unique(user.permissions.map(p=>p.accountId))
  )
  return (state,ownProps) =>{
    return {
      statusUpdate   : state.Permissions.Message,
      accountsOfUser : accountsOfUserSelector(ownProps.user)
    }
  }
}
const mapDispatchToProps = (dispatch) =>{
  return {
    assign : (accountId:number, userId:number, profile:string)=>(
      dispatch(AssignPermissionProfile(accountId,userId,profile))
    )
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    assign : (accountId:number, profile:string)=>DP.assign(accountId,ownProps.user.id,profile)
  }
}

class AddAccount extends React.Component<any,AddAccountState>{
  constructor(props){
    super(props)
    this.state = {
      filter : "",
      accountId : 0,
    }
    this.handleFilterChange = this.handleFilterChange.bind(this)
  }
  handleFilterChange(event){
    const filter = event.target.value.toLowerCase()
    const accounts = filterAccounts(filter, this.props.accounts)
    this.setState({
      filter    : filter,
      accountId : accounts.length===1 && filter.length!==0 ? accounts[0].id : 0,
    })
  }
  render(){
    const accounts = filterAccounts(this.state.filter,this.props.accounts)
    .sort((a,b)=>ArrayUtils.comp(a.name,b.name))
    .map(account=>({
      ...account,
      disabled : ArrayUtils.contain(this.props.accountsOfUser,account.id)
    }))
    const pick = accounts.length===1?accounts[0].id:'NONE'
    return (
      <div className='AddAccountToUser'>
        <h3>Add access to an account</h3>
        <div>
          <input
            className='longInput'
            type='text'
            placeholder='Filter'
            onChange={this.handleFilterChange}
          />
        </div>
        <div>
          <select
            key={pick /*remount because changes to defaultValue aren't enough*/}
            className='accountSelection longInput'
            defaultValue={pick}
            onChange={e=>this.setState({
              accountId:parseInt(e.target.value),
              filter:""
            })}
          >
            {pick==="NONE"?<option key="NONE" value="NONE" disabled>Select Account ({accounts.length} found)</option>:<></>}
            {accounts.map(account=>
              <option
                key={account.id}
                value={account.id}
                disabled={account.disabled}
              >
                {account.name} ({account.id})
              </option>
            )}
          </select>
        </div>
        <div>
          {
            this.state.accountId === 0 ? <></> :
            <PickBasePermissionSetup
              onPick={(pick)=>this.props.assign(this.state.accountId,pick)}
            />
          }
          <StatusUpdate text={this.props.statusUpdate}/>
        </div>
      </div>
    )
  }
}
export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(AddAccount)
