import Actions from "../../model/constant/actions"
import ReducerUtils from "../../utils/ReducerUtils"


export default ReducerUtils.createReducer<{[accountId:string]:{[feedId:string]:string[]}}>({},{
    [Actions.RETRIEVE_FEEDS_SIGNED_URLS] : (state, action)=>{
      return {
        ...state,
        [action.accountId] : {
          ...action.signedUrls
        }
      }
    }
})
