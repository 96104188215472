import * as React                           from "react"
import {connect}                            from "react-redux"
import {getBiddingStrategySettings, retrieveStrategies} from "../../actions/strategies/retrieveStrategies"
import ExplainedLoading                     from "../loading"
import ErrorBoundary                        from "../Error/ErrorBoundary"
import {isEmpty} from "lodash";
import Strategy from "../../model/Store/Strategy/Strategy";

function mergeSettings(oldStrategies:Strategy[], settings) {
  const mergedStrategies:Strategy[] = []

  for (const strategy of oldStrategies) {
    const match = settings.find(
      settings => settings.lang === strategy.lang
        && settings.campaignGroup === strategy.group
        && (isEmpty(strategy.vehicleState) || settings.vehicleState === strategy.vehicleState)
    )

    if (match) {
      // if there is a match, fill the new fields
      strategy.bidSetting = match.bidSetting
      strategy.biddingStrategyType = match.biddingStrategyType
      strategy.properties = match.properties
    }
    mergedStrategies.push(strategy)
  }

  // return the list of all strategies with or without bidding strategy settings
  return mergedStrategies
}

const mapStateToProps = (state,ownProps) => {
  const accountId = state.Accounts.selected
  if (!isEmpty(state.Params.Strategies.list[accountId]) && !isEmpty(state.Params.Strategies.strategiesSettingsList[accountId])) {
    mergeSettings(
      state.Params.Strategies.list[accountId],
      state.Params.Strategies.strategiesSettingsList[accountId]
    )
  }
  return {
    accountId                       : accountId,
    fetching                        : state.Params.Strategies.fetching,
    fetchingBiddingStrategySettings : state.Params.Strategies.fetchingBiddingStrategySettings,
    strategies                      : state.Params.Strategies.list[accountId],
    savingStrategies                : state.Params.Branding.savingStrategies
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadStrategies : (accountId:number)=>dispatch(retrieveStrategies(accountId)),
    loadStrategySettings : (accountId:number)=>dispatch(getBiddingStrategySettings(accountId)),
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    loadStrategies : () => DP.loadStrategies(SP.accountId),
    loadStrategySettings : () => DP.loadStrategySettings(SP.accountId),
  }
}
const StrategiesLoader = props => {
  let loading = false
  if(props.strategies === undefined || props.fetching || props.savingStrategies && props.letThrough === false){
    loading = true
    if(props.savingStrategies && props.letThrough === false) {
      return <ExplainedLoading translateId="loadings.saving"/>
    }
    if(!props.fetching){
      props.loadStrategies()
      if(!props.fetchingBiddingStrategySettings){
        props.loadStrategySettings()
      }
      if(props.letThrough === false) {
        return <ExplainedLoading translateId="loadings.strategies"/>
      }
    }
    if(!props.letThrough && props.letThrough === false){
      return <ExplainedLoading translateId="loadings.strategies"/>
    }
  }
  return (
    <ErrorBoundary>
      {props.render({
        strategies              : props.strategies,
        loadingStrategies       : loading,
        saving                  : props.savingStrategies
      })}
    </ErrorBoundary>
  )
}
const StrategiesLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(StrategiesLoader)

const requiresStrategies = (letThrough?:boolean) => Component => props => (
  <StrategiesLoaderConnected
    letThrough={letThrough || false}
    render={ strategiesContent => <Component {...strategiesContent} {...props} /> }
  />
)

export default requiresStrategies
