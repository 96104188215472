import ReportTableTemplateContexts      from "../model/constant/ReportTableTemplateContexts"
import ReportTableTemplate              from "../model/Store/Report/ReportTableTemplate"
import AccountReportTableTemplate       from "../model/Store/Report/CustomTemplates/AccountReportTableTemplate"
import AdReportTableTemplate            from "../model/Store/Report/CustomTemplates/AdReportTableTemplate"
import BudgetReportTableTemplate        from "../model/Store/Report/CustomTemplates/BudgetReportTableTemplate"
import CampaignReportTableTemplate      from "../model/Store/Report/CustomTemplates/CampaignReportTableTemplate"
import KeywordReportTableTemplate       from "../model/Store/Report/CustomTemplates/KeywordReportTableTemplate"
import BingCampaignReportTableTemplate  from "../model/Store/Report/CustomTemplates/BingCampaignReportTableTemplate"
import BingKeywordReportTableTemplate   from "../model/Store/Report/CustomTemplates/BingKeywordReportTableTemplate"
import {Provider}                       from "../model/Provider"

export default class ReportTableTemplateUtils{

  public static TemplateClassesByContexts = {
    [Provider.AW] : {
      [ReportTableTemplateContexts.ACCOUNT]  : AccountReportTableTemplate,
      [ReportTableTemplateContexts.AD]       : AdReportTableTemplate,
      [ReportTableTemplateContexts.BUDGET]   : BudgetReportTableTemplate,
      [ReportTableTemplateContexts.CAMPAIGN] : CampaignReportTableTemplate,
      [ReportTableTemplateContexts.KEYWORD]  : KeywordReportTableTemplate,
    },
    [Provider.BING] : {
      [ReportTableTemplateContexts.CAMPAIGN] : BingCampaignReportTableTemplate,
      [ReportTableTemplateContexts.KEYWORD]  : BingKeywordReportTableTemplate,
    },
  }

  public static ContextsByTemplateClasses = {
    AccountReportTableTemplate  : ReportTableTemplateContexts.ACCOUNT,
    AdReportTableTemplate       : ReportTableTemplateContexts.AD,
    BudgetReportTableTemplate   : ReportTableTemplateContexts.BUDGET,
    CampaignReportTableTemplate : ReportTableTemplateContexts.CAMPAIGN,
    KeywordReportTableTemplate  : ReportTableTemplateContexts.KEYWORD,
  }

  public static getContextFromTemplate(template:ReportTableTemplate):string{
    const className = template.constructor.name
    let context = ReportTableTemplateUtils.ContextsByTemplateClasses[className]
    if(!context){throw Error("No context found for given template.")}
    return context
  }

  public static getTemplateClassFromContext(provider:Provider,context:string):any{
      let templateClass = ReportTableTemplateUtils.TemplateClassesByContexts[provider][context]
      if(!templateClass){throw Error(`Unknown context for provider. ${provider} ${context} given.`)}
      return templateClass
  }
}
