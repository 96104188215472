import './AudienceAdCreator.css'
import * as React                   from 'react'
import AWSearch                     from '../../../model/Store/Audiences/AWSearch'
import { AudienceAd }               from '../../../model/branding/AudienceAd'
import {
  Card,
  FormControl,
  Select,
  MenuItem,
  Button,
  Icon,
  Typography,
  Divider,
  Modal,
  CardContent,
  FormHelperText,
  TextField,
}                                   from '@material-ui/core'
import ArrayWithEditAndDelete       from '../../../components/branding/ArrayWithEditAndDelete/ArrayWithEditAndDelete'
import { AdGroupConfigsByLang }     from "../../../model/branding/AdGroupConfigs"
import {
  withLocalize,
  Translate
}                                   from 'react-localize-redux'
import ChoosingOptions              from './ChoosingOptions'
import AudienceAdsList              from './AudienceAdsEdit/AudienceAdsList'


interface AudienceAdCreatorOwnProps {
  awAudiences           : AWSearch[]
  buttons               : boolean
  adGroupName           : string
  availableLanguages    : string[]
  existingAudiencesAds  : AudienceAd[]
  addAudienceAd        ?: (audienceAd:AudienceAd,lang?: string) => void
  deleteAudienceAd     ?: (audienceAdId: number) => void
  enableSave           ?: (state: boolean) => void
  adGroupConfigsByLang ?: AdGroupConfigsByLang
}
interface AudienceAdCreatorProps extends AudienceAdCreatorOwnProps {}

interface AudienceAdByLang {
  [lang: string]: AudienceAd
}
const generateBaseAudienceAd = (name: string): AudienceAdByLang => ({
  EN: {
    id: 0,
    audienceId: "0",
    templates: [
      { id: -1, headlines: ['', '', ''], descriptions: ['', ''] },
      { id: -2, headlines: ['', '', ''], descriptions: ['', ''] },
      { id: -3, headlines: ['', '', ''], descriptions: ['', ''] }
    ],
    matcher: {
      adGroupName: name,
      group: 'BRANDING',
      lang: "EN"
    }
  },
  FR: {
    id: 0,
    audienceId: "0",
    templates: [
      { id: -1, headlines: ['', '', ''], descriptions: ['', ''] },
      { id: -2, headlines: ['', '', ''], descriptions: ['', ''] },
      { id: -3, headlines: ['', '', ''], descriptions: ['', ''] }
    ],
    matcher: {
      adGroupName: name,
      group: 'BRANDING',
      lang: 'FR'
    }
  }
})

const AudienceAdCreator = (props: AudienceAdCreatorProps) => {
  //Available Audiences with New Visitors
  const availableAudiences = [{ id: 0, name: 'New visitors' }, ...props.awAudiences].filter(audience => {
    const found = props.existingAudiencesAds.find(existingAudience => parseInt(existingAudience.audienceId,10) == audience.id)
    if (found === undefined) { return true }
    return false
  })
  //Array with audiences ID to Name Matcher
  const idToName = props.awAudiences.reduce((acc, audience) => {
    return [...acc, { id: audience.id, name: audience.name }]
  }, [{ id: 0, name: 'New visitors' }])
  //STATE
  const [initialError, setInitialError] = React.useState(false)
  const [errors, setErrors] = React.useState([])
  const [newAudienceAdId, setNewAudienceAdId] = React.useState(0)
  const [selectedAudience, setSelectedAudience] = React.useState(0)
  const [openAdsEdit, setOpenAdsEdit] = React.useState(false)
  const [audienceToEdit, setAudienceToEdit] = React.useState(generateBaseAudienceAd(props.adGroupName))
  const [update, setUpdate] = React.useState(0)
  const [choosing,setChoosing] = React.useState(false)
  const [openAudiencesListModal,setOpenAudiencesListModal] = React.useState(false)
  //Reset Select when Adding new AudienceAd
  React.useEffect(() => {
    setSelectedAudience(availableAudiences.length !==0 ? availableAudiences[0].id : null)
  }, [props.existingAudiencesAds])
  //Function passed to AdsEdit Comonent
  function saveFunction() {
    props.availableLanguages.forEach(lang => {
      props.addAudienceAd(audienceToEdit[lang],lang)
    })
    setAudienceToEdit(generateBaseAudienceAd(props.adGroupName))
    setUpdate(prevState => prevState + 1)
    closeAdsEditModal()
  }
  //Handlers
  function handleChange(id:number) {
    setSelectedAudience(id)
    handleCloseAudiencesListModal()
  }
  //Modal
  function openAdsEditModal() {
    setOpenAdsEdit(true)
  }
  function closeAdsEditModal() {
    setOpenAdsEdit(false)
  }
  function handleOpenAudiencesListModal() {
    setOpenAudiencesListModal(true)
  }
  function handleCloseAudiencesListModal() {
    setOpenAudiencesListModal(false)
  }
  //Edit Function
  function editExistingAudienceAd(id: number) {
    const current = props.existingAudiencesAds.filter(x => parseInt(x.audienceId,10) == id).reduce((acc, audience) => {
      return {
        ...acc,
        [audience.matcher.lang]: audience
      }
    }, {})
    setChoosing(false)
    setAudienceToEdit(current)
    setInitialError(false)
    openAdsEditModal()
  }
  function createNewAudienceAd() {
    setChoosing(true)
    setNewAudienceAdId(prevState => prevState + 1)
    setAudienceToEdit((prevState) => {
      return {
        ...generateBaseAudienceAd(props.adGroupName),
        EN: { ...generateBaseAudienceAd(props.adGroupName)['EN'], audienceId: selectedAudience+'', id: newAudienceAdId },
        FR: { ...generateBaseAudienceAd(props.adGroupName)['FR'], audienceId: selectedAudience+'', id: newAudienceAdId }
      }
    })
    setInitialError(true)
    openAdsEditModal()
  }
  function deleteAudienceAd(id: number) {
    props.deleteAudienceAd(id)
  }
  function updateAudienceAd(lang: string, templates) {
    setAudienceToEdit(prevState => {
      return { ...prevState, [lang]: { ...prevState[lang], templates: templates } }
    })
  }
  const selectedAudienceAd = props.awAudiences.find(x=>selectedAudience===x.id)
  const selectedAudienceName = selectedAudienceAd && selectedAudienceAd.name || ''
  return (
    <Card className="AudienceAdCreator">
      <CardContent>
        <div className="AudienceSelector">
          <Typography className="Instructions" variant="subtitle2"><Translate id="branding.creation.audienceAdCreator" /></Typography>
          <FormControl>
            <Button
              variant="outlined"
              disabled={availableAudiences.length === 0}
              onClick={availableAudiences.length ? handleOpenAudiencesListModal : null}
            >
              {selectedAudienceName}
            </Button>
            <FormHelperText>{availableAudiences.length === 0 && <Translate id="adGroupCreator.noAvailableAudience"/>}</FormHelperText>
          </FormControl>
          <Button disabled={availableAudiences.length === 0} color="primary" onClick={createNewAudienceAd}>
            <Icon className="Icon">add_circle</Icon>
          </Button>
        </div>
        <Divider variant="fullWidth" />
        <div className="ExistingAudiences">
          <Typography variant="subtitle2"><Translate id="branding.creation.currentAudiences" /></Typography>
          <ArrayWithEditAndDelete
            key={update}
            audienceAds={props.existingAudiencesAds}
            idToName={idToName}
            editFunction={editExistingAudienceAd}
            deleteFunction={deleteAudienceAd}
            enableSave={props.enableSave}
          />
        </div>
      </CardContent>
      {/* AdsEditModal */}
      <Modal className="AdsEditModal" open={openAdsEdit} onClose={closeAdsEditModal}>
        <div>
          <ChoosingOptions
            adGroupConfigsByLang={props.adGroupConfigsByLang}
            audienceToEdit={audienceToEdit}
            availableLanguages={props.availableLanguages}
            errors={errors}
            initialError={initialError}
            saveFunction={saveFunction}
            setErrors={setErrors}
            updateAudienceAd={updateAudienceAd}
            choosing={choosing}
          />
        </div>
      </Modal>
      <Modal className="AdsEditModal" open={openAudiencesListModal} onClose={handleCloseAudiencesListModal}>
        <AudienceAdsList availableAudiences={availableAudiences} onSelect={handleChange}/>
      </Modal>
    </Card>
  )
}
export default withLocalize(AudienceAdCreator as any) as React.ComponentType<AudienceAdCreatorOwnProps>
