import * as React     from "react"
import NumFormatter   from "../../../../utils/NumberFormatter"
import MathUtils      from "../../../../utils/MathUtils"

interface BudgetPlanPartProps {
  year            : number
  month           : number
  amount          : number
  spent           : number
  active          : boolean
  onClick         : ()=>any
  amountOnChange  ?: (amountChanged:number)=>any
  newAmount       : (newAmount:number)=>any
  deactivate      : ()=>void
}

export class BudgetPlanPart extends React.Component<BudgetPlanPartProps,any>{
  static defaultProps = {
    active  : false,
  }
  constructor(props){
    super(props)
    this.state = {
      inputAmount:isNaN(props.amount) ? "" : MathUtils.round(props.amount,2).toFixed(2),
    }
    this.getInputValue       = this.getInputValue.bind(this)
    this.handleInputKeyPress = this.handleInputKeyPress.bind(this)
    this.assureNumberValue   = this.assureNumberValue.bind(this)
  }
  componentDidMount(){
    this.setState({amount:this.props.amount})
  }
  static getDerivedStateFromProps(props, state){
    if(state.amount != props.amount){
      return {
        amount : props.amount
      }
    }
    return null
  }
  handleInputKeyPress(event){
    if(event.key === "Enter"){event.target.blur()} // only keypress sends this one
    else if(event.key === "Escape"){this.props.deactivate()} // only keydown sends this one
  }
  getInputValue(value:string){
    if(value === "" || value == this.state.amount){
      this.props.deactivate()
      return
    }
    const amount = parseFloat(value)
    if(isNaN(amount) || amount < 0 || value == "") {
      alert("Error value")
      return
    }
    this.props.newAmount(amount)
    this.props.deactivate()
  }
  assureNumberValue(event){
    let value = event.target.value
    if(isNaN(value)) {
      event.target.value = value.substr(0,value.length-1)
    }
  }
  render () {
    let props = this.props
    let planned = isNaN(this.state.amount) ? "" : MathUtils.round(this.state.amount,2).toFixed(2)
    const spent = NumFormatter.formatCurrency(props.spent)
    return (
      <div onDoubleClick={props.onClick}>
        {
          props.active
          ? <input
              autoFocus
              type         = "text"
              defaultValue = {this.state.amount /* could put default value here with ternary */}
              onBlur       = {(e)=>{this.getInputValue(e.target.value)}}
              onKeyPress   = {this.handleInputKeyPress}
              onKeyDown    = {this.handleInputKeyPress}
              onChange     = {(e)=>this.assureNumberValue(e)}
            />
          : <div>{typeof planned === "number" ? NumFormatter.formatCurrency(planned) : "-"}</div>
        }
        <div>{spent}</div>
      </div>
    )
  }
}
