import * as React         from 'react'
import {
  Chip,
  Icon,
  Tooltip,
}                         from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                                          from "react-localize-redux"
import {
  AdWordsShoppingV1FeedParams,
}                         from "../../../model/feed/Feed"
import FeedSummaryProps   from "./props"
import LangChip           from "./LangChip"
import InactiveChip       from "./InactiveChip"

interface FeedSummaryAdWordsShoppingV1Props extends LocalizeContextProps, FeedSummaryProps<AdWordsShoppingV1FeedParams>{}

const FeedSummaryAdWordsShoppingV1 = (props:FeedSummaryAdWordsShoppingV1Props) => {
  const deletionLocked = props.urls.some( url => url.isLocked )

  return (
    <div className='FeedSummary'>
      <Tooltip placement="top" title={<p>{props.translate("feeds.tooltip.GOOGLE_SHOPPING")}</p>}>
        <img className="ADWORDS" src="./assets/img/google-shopping.svg"/>
      </Tooltip>
      <Tooltip placement="top" title={<p>{props.translate("feeds.tooltip.vehicleType")}</p>}>
        <Chip color="primary" variant="outlined" label={props.translate("feeds.vehicleType."+props.config.params.vehicleType)}/>
      </Tooltip>
      <LangChip lang={props.config.params.lang} inventoryLang={props.config.params.inventoryLang}/>
      <Tooltip placement="top" title={<p>{props.translate("feeds.tooltip.storeCode")}</p>}>
        <Chip color="primary" variant="outlined" label={props.config.params.storeCode}/>
      </Tooltip>
      <InactiveChip active={props.urls.length!==0}/>
      {
        deletionLocked &&
          <Tooltip placement="top" title={<p>{props.translate('feeds.deleteLocked')}</p>}>
            <div className="AlignCenter">
              <Icon style={{color: "gray"}}>lock</Icon>
            </div>
          </Tooltip>
      }
    </div>
  )
}
export default withLocalize(FeedSummaryAdWordsShoppingV1)
