import ReducerUtils from "../../utils/ReducerUtils"
import Actions from "../../model/constant/actions"

const setStatus = (state, action) => {
  return action.status
}

const IsLoggingIn = ReducerUtils.createReducer<boolean>(false,{
  [Actions.LOGGING_IN] : setStatus,
})

export default IsLoggingIn
