import ReducerUtils                         from "../../../utils/ReducerUtils"
import Actions                              from "../../../model/constant/actions"

const selectedBrandingLanguage = (state,action:{lang:string}) => {
  return {...state,
    lang : action.lang
  }
}
const selectedBrandingAdGroup = (state,action) => {
  return {...state,
    adGroup : action.adGroup
  }
}
const selectedBrandingAudienceAd = (state,action) => {
  return {...state,
    audienceAd : action.audienceAd
  }
}

const brandingConfigs = ReducerUtils.createReducer<any>({},{
  [Actions.CHANGE_BRANDING_LANGUAGE] : selectedBrandingLanguage,
  [Actions.CHANGE_BRANDING_ADGROUP]  : selectedBrandingAdGroup,
  [Actions.CHANGE_BRANDING_AUDIENCEAD] : selectedBrandingAudienceAd
})

export default brandingConfigs
