import * as React               from 'react'
import {
  TableRow,
  TableCell,
  Button,
  Icon
}                               from '@material-ui/core'
import { AdGroupConfigsByLang } from '../../../../model/branding/AdGroupConfigs'
import AWSearch                 from '../../../../model/Store/Audiences/AWSearch'
import DialogMui                from '../../../../components/Modals/DialogMui'
import {
  withLocalize,
  TranslateFunction
}                               from 'react-localize-redux'

interface AudienceAdRowOwnProps {
  adGroupName                 : string
  audience                    : AWSearch
  handleClickEditAudienceAd   : (adGroupName:string,audienceAdName:string) => void
  handleClickDeleteAudienceAd : (adGroupName:string,audienceAdId:string) => void
  adGroupConfigsByLang        : AdGroupConfigsByLang
  lang                        : string
}
interface AudienceAdRowProps extends AudienceAdRowOwnProps {
  translate : TranslateFunction
}

const AudienceAdRow = (props:AudienceAdRowProps) => {
  const [openDialog,setOpenDialog] = React.useState(false)
  function handleClickOpenDialog() {
    setOpenDialog(true)
  }
  function handleClickCloseDialog() {
    setOpenDialog(false)
  }
  function deleteAudienceAd() {
    props.handleClickDeleteAudienceAd(props.adGroupName,props.audience.id.toString())
  }
  return (
    <>
      <TableRow>
        <TableCell>{props.audience.name}</TableCell>
        <TableCell className="CenterText">
          <Button onClick={()=>{props.handleClickEditAudienceAd(props.adGroupName,props.audience.name)}} color="primary" disabled>
            <Icon className="Actions">edit</Icon>
          </Button>
        </TableCell>
        <TableCell className="CenterText">
          <Button
            onClick={handleClickOpenDialog} color="secondary"
            disabled={props.adGroupConfigsByLang[props.lang][props.adGroupName].audienceAds.length < 2}
          >
            <Icon className="Actions">delete</Icon>
          </Button>
        </TableCell>
      </TableRow>
      <DialogMui
        cancelText={props.translate('common.cancel') as string}
        confirmText={props.translate('common.yes') as string}
        title={props.translate('common.delete') as string}
        content={props.translate('dialog.deleteAudienceAd') as string}
        open={openDialog}
        handleSave={deleteAudienceAd}
        handleClose={handleClickCloseDialog}
      />
    </>
  )
}

export default withLocalize(AudienceAdRow as any) as React.ComponentType<AudienceAdRowOwnProps>
