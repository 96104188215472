import ReducerUtils from "../../../utils/ReducerUtils"
import Actions from "../../../model/constant/actions"

const setStatus = (state:boolean, action) => {
  return action.status
}

const AWSearch = ReducerUtils.createReducer<boolean>(false,{
  [Actions.RETRIEVING_AWSEARCH_AUDIENCES] : setStatus
})

export default AWSearch
