import './TemplateEditor.css'
import * as React                           from "react"
import {connect}                            from "react-redux"
import {
  Translate,
  withLocalize,
  LocalizeContextProps
}                                           from "react-localize-redux"
import ReportTableTemplate                  from "../../../../model/Store/Report/ReportTableTemplate"
import ColumnDefinition                     from "../../../../model/Store/Report/ColumnDefinition"
import DragColumn                           from "../../../../model/report/DragColumn"
import * as Template                        from "../../../../actions/ReportTableTemplate/ReportTableTemplate"
import * as EditTemplate                    from "../../../../actions/ReportTableTemplate/EditReportTableTemplate"
import {Button, Loading}                    from "carbon-components-react"
import ColumnsManager                       from "./ColumnsManager"
import NameManager                          from "./NameManager"
import SizePerPageManager                   from "./SizePerPageManager"
import DefaultSortedManager                 from "./DefaultSortedManager"
import { Checkbox, Tooltip }                from "@material-ui/core"

interface TemplateEditorOwnProps {
  template              : ReportTableTemplate
  templateCount         : number
  context               : string
  exit                  : ()=>void
  cancel                : ()=>void
  selectedTemplateId    : string
  selectDefaultTemplate : (templateId:string)=>void
  defaultTemplateId     : string
}
interface TemplateEditorProps extends TemplateEditorOwnProps, LocalizeContextProps{
  templateCopy            : ReportTableTemplate
  saveTemplate            : ()=>void
  deleteTemplate          : ()=>void
  editTemplate            : ()=>void
  editColumns             : (columns:DragColumn[])=>void
  editName                : (name:string)=>void
  editSizePerPage         : (size:number)=>void
  editDefaultSortedColumn : (dataField:string)=>void
  editDefaultSortedOrder  : (order:string)=>void
  editDefaultFilter       : (dataField:string, filterValue:string)=>void
}
const mapStateToProps = (state,ownProps:TemplateEditorOwnProps) => {
  return {
    templateCopy : state.EditCopies.Templates.find(template=>template.getId()===ownProps.template.getId())
  }
}
const mapDispatchToProps = dispatch => {
  return {
    saveTemplate : (template:ReportTableTemplate)=>{
      dispatch(Template.saveTemplate(template))
    },
    deleteTemplate : (templateId:string)=>{
      dispatch(Template.deleteTemplate(templateId))
    },
    editTemplate : (template:ReportTableTemplate)=>{
      dispatch(EditTemplate.editTemplate(template))
    },
    editColumns : (templateId:string, columns:DragColumn[])=>{
      dispatch(EditTemplate.editColumns(templateId, columns))
    },
    editName : (templateId:string, name:string)=>{
      dispatch(EditTemplate.editName(templateId, name))
    },
    editSizePerPage : (templateId:string, size:number)=>{
      dispatch(EditTemplate.editSizePerPage(templateId, size))
    },
    editDefaultSortedColumn : (templateId:string, dataField:string)=>{
      dispatch(EditTemplate.editDefaultSortedColumn(templateId, dataField))
    },
    editDefaultSortedOrder : (templateId:string, order:string)=>{
      dispatch(EditTemplate.editDefaultSortedOrder(templateId, order))
    },
    editDefaultFilter : (templateId:string, dataField:string, filterValue:string)=>{
      dispatch(EditTemplate.editDefaultFilter(templateId, dataField, filterValue))
    },
  }
}
const mergeProps = (SP, DP, ownProps) => {
  const {token, ...stateProps} = SP
  const templateId = ownProps.template.getId()
  return {
    ...stateProps, ...DP, ...ownProps,
    saveTemplate            : ()                                     => DP.saveTemplate(SP.templateCopy),
    deleteTemplate          : ()                                     => DP.deleteTemplate(templateId),
    editTemplate            : ()                                     => DP.editTemplate(ownProps.template),
    editColumns             : (columns:DragColumn[])                 => DP.editColumns(templateId, columns),
    editName                : (name:string)                          => DP.editName(templateId, name),
    editSizePerPage         : (size:number)                          => DP.editSizePerPage(templateId, size),
    editDefaultSortedColumn : (dataField:string)                     => DP.editDefaultSortedColumn(templateId, dataField),
    editDefaultSortedOrder  : (order:string)                         => DP.editDefaultSortedOrder(templateId, order),
    editDefaultFilter       : (dataField:string, filterValue:string) => DP.editDefaultFilter(templateId, dataField, filterValue)
  }
}
interface TemplateEditorState{
  defaultViewChecked : boolean
}
class TemplateEditor extends React.Component<TemplateEditorProps,TemplateEditorState>{
  constructor(props){
    super(props)
    this.state = {
      defaultViewChecked: props.selectedTemplateId === props.defaultTemplateId && props.defaultTemplateId !== "null"
    }
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.reset = this.reset.bind(this)
  }
  componentDidMount(){
    this.props.editTemplate()
  }
  componentDidUpdate(prevProps){
    if(prevProps.template.getId() !== this.props.template.getId()){
      this.props.editTemplate()
    }
  }
  createDragColumnFromColumn(column:ColumnDefinition):DragColumn{
    return {
      dataField : column.getDataField(),
      text : column.getText(),
      hidden : column.getHidden(),
      orderIndex : column.getOrderIndex()
    }
  }
  createDragColumnsFromTemplate(template:ReportTableTemplate):DragColumn[]{
    let dragCols = template.getColumns().filter(c=>!c.getHidden()).map(column=>this.createDragColumnFromColumn(column))
    dragCols.sort((a,b) => {
      if(a.orderIndex<b.orderIndex){return -1}
      if(a.orderIndex>b.orderIndex){return 1}
      return 0
    })
    return dragCols
  }
  reset(){
    this.props.editTemplate()
  }

  handleCheckboxChange(){
    this.setState({
      defaultViewChecked: !this.state.defaultViewChecked
    })
  }

  handleDefaultTemplate() {
    if(this.state.defaultViewChecked && this.props.selectedTemplateId !== this.props.defaultTemplateId) {
      this.props.selectDefaultTemplate(this.props.selectedTemplateId)
    }
    if (!this.state.defaultViewChecked && this.props.selectedTemplateId === this.props.defaultTemplateId) {
      this.props.selectDefaultTemplate("null")
    }
  }

  render(){
    const disableDefaultCheckbox = this.props.templateCount > 1
    if(!this.props.templateCopy){return <Loading/>}
    const template = this.props.templateCopy
    let columnsBank = template.getColumns().map(column=>this.createDragColumnFromColumn(column))
    columnsBank.sort((a,b)=>{
      if(a.text < b.text){return -1}
      if(a.text > b.text){return 1}
      return 0
    })
    return (
      <div className="TemplateEditor">
        <div className="Buttons">
          <Button className="Confirm"
            onClick={()=>{
              this.props.saveTemplate()
              this.props.exit()
              this.handleDefaultTemplate()
            }}>
            <Translate id="common.confirm"/>
          </Button>
          <Button className="Cancel" onClick={this.props.cancel}>
            <Translate id="common.cancel"/>
          </Button>
          <Button className="Reset" onClick={this.reset}>
            <Translate id="reports.ads.editor.reset"/>
          </Button>
          <Button className="Delete" onClick={()=>{this.props.deleteTemplate();this.props.exit()}}>
            <Translate id="reports.ads.editor.delete"/>
          </Button>
        </div>
        <br/>
        <div className="Options">
          <div className="AlignedItems">
            <span><Translate id="reports.ads.editor.name"/>:</span>
            <NameManager
              originalName={this.props.template.getName()}
              editedName={template.getName()}
              setName={this.props.editName}
            />
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            { this.props.translate('reports.ads.editor.defaultView') }
            <Tooltip
              disableFocusListener={disableDefaultCheckbox}
              disableHoverListener={disableDefaultCheckbox}
              disableTouchListener={disableDefaultCheckbox}
              arrow
              title={this.props.translate('reports.ads.editor.checkboxDisabled')}
            >
              <div>
                <Checkbox
                  disabled={!disableDefaultCheckbox}
                  checked={this.state.defaultViewChecked}
                  color="primary"
                  onClick={this.handleCheckboxChange}
                />
              </div>
            </Tooltip>
          </div>
          <div>
            <Translate id="reports.ads.editor.defaultSorted"/> :
            <DefaultSortedManager
              dataFields={template.getColumns().map(c=>c.getDataField())}
              originalDefaultSortedColumn={this.props.template.getDefaultSortedColumn()}
              editedDefaultSortedColumn={template.getDefaultSortedColumn()}
              setDefaultSortedColumn={this.props.editDefaultSortedColumn}
              originalDefaultSortedOrder={this.props.template.getDefaultSortedOrder()}
              editedDefaultSortedOrder={template.getDefaultSortedOrder()}
              setDefaultSortedOrder={this.props.editDefaultSortedOrder}
            />
          </div>
          <div className="AlignedItems">
            <span><Translate id="reports.ads.editor.size"/> :</span>
            <SizePerPageManager
              originalSize={this.props.template.getSizePerPage()}
              editedSize={template.getSizePerPage()}
              setSize={this.props.editSizePerPage}
            />
          </div>
        </div>
        <hr/>
        <ColumnsManager
          columns={this.createDragColumnsFromTemplate(template)}
          columnsBank={columnsBank}
          setColumns={this.props.editColumns}
          originalDefaultFilters={this.props.template.getDefaultFilters()}
          editedDefaultFilters={template.getDefaultFilters()}
          setDefaultFilter={this.props.editDefaultFilter}
        />
        <hr/>
      </div>
    )
  }
}

export default withLocalize(
  connect(mapStateToProps,mapDispatchToProps,mergeProps)(TemplateEditor)
)
