import ReducerUtils       from "../../../../utils/ReducerUtils"
import ObjectUtils        from "../../../../utils/ObjectUtils"
import Actions            from "../../../../model/constant/actions"
import CampaignBaseInfo   from "../../../../model/Store/Campaign/CampaignBaseInfo"

const setData = (state, action) => {
  const accountId = action.accountId
  if(!state[accountId]){
    return {
      ...state,
      [accountId] : action.info
    }
  }

  return {
    ...state,
    [accountId] : ObjectUtils.getObjectValues({
      ...state[accountId].reduce((byId, info)=>{byId[info.AWId]=info; return byId},{}),
      ...action.infoById
    })
  }
}

interface ListByAccount{
  [accountId:number] : CampaignBaseInfo[]
}
const BaseInfoList = ReducerUtils.createReducer<ListByAccount>({},{
  [Actions.RETRIEVE_BING_CAMPAIGNS_BASE_INFO] : setData
})

export default BaseInfoList
