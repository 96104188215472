import * as React              from 'react'
import BudgetPlan              from "../../../../model/Store/BudgetPlan/BudgetPlan"
import MonthlyBudgetStat       from "../../../../model/Store/Statistics/MonthlyBudgetsStats"
import Budget                  from "../model/Budget"
import BudgetPlannerGrid       from "./BudgetPlannerGrid"

interface BudgetPlannerProviderProps{
  accountId         : number
  year              : number
  month             : number
  providerName      : string
  providerNiceName  : string
  budgetPlans       : BudgetPlan[]
  budgets           : Budget[]
  monthlyBudgetStats: MonthlyBudgetStat[]
  ongoingBudgetStats: MonthlyBudgetStat[]
  screenWidth       : number
  isProviderActive  : boolean
  activeBudget      : Budget
  activeYear        : string
  activeMonth       : string
  selectPart        : (budget:Budget,year:string,month:string)=>void
  changedPartAmount : (budgetId:number,period:string,amount:number)=>void
}

const BudgetPlannerProvider = (props:BudgetPlannerProviderProps)=>{
  const nbrMonths = Math.floor(props.screenWidth/140)
  return (
    <div>
      {props.providerNiceName}
      <BudgetPlannerGrid
        accountId={props.accountId}
        provider={props.providerName}
        year={props.year}
        month={props.month}
        budgetPlans={props.budgetPlans}
        budgets={props.budgets}
        monthlyBudgetStats={props.monthlyBudgetStats}
        ongoingBudgetStats={props.ongoingBudgetStats}
        nbrMonths={nbrMonths}
        isProviderActive={props.isProviderActive}
        activeBudget={props.activeBudget}
        activeYear={props.activeYear}
        activeMonth={props.activeMonth}
        selectPart={props.selectPart}
        changedPartAmount={props.changedPartAmount}
      />
    </div>
   )
}

export default BudgetPlannerProvider
