import * as React                                       from "react"
import {connect}                                        from "react-redux"
import {retrieveShapesPendingValidation, validateShape} from "../../../actions/shapes/ShapeValidation"
import {addCodeToQueue}                                 from "../../../actions/EditGeoBid"
import {Button}                                         from "carbon-components-react"
import MapBuilder                                       from "../../../components/Map/MapBuilder"
import ExplainedLoading                                 from "../../../components/loading"
import {Panel}                                          from "../../../components/Panel/Panel"
import GoBack                                           from "../../../components/Button/ButtonGoBack"

interface Step1OwnProps{
  exit ?: ()=>void
}
interface Step1Props extends Step1OwnProps{
  shapes         : any[]
  cache          : any
  metaCache      : any
  loadShapes     : ()=>void
  addCodeToQueue : (code:number)=>void
  validateShape  : (shapeId:number, valid:boolean)=>void
}
const mapStateToProps = (state,ownProps:Step1OwnProps)=>{
  return {
    shapes    : state.Params.GeoBids.ShapesPendingValidation,
    cache     : state.Params.GeoBids.GeocodingCache,
    metaCache : state.Params.GeoBids.GeocodingMetaCache,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadShapes     : ()=>dispatch(retrieveShapesPendingValidation()),
    validateShape  : (shapeId:number, valid:boolean)=>dispatch(validateShape(shapeId, valid)),
    addCodeToQueue : (code:number)=>dispatch(addCodeToQueue(code))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
interface Step1State{
  currentShape ?: any
  score : number
}
class Step1 extends React.Component<Step1Props,Step1State>{
  constructor(props){
    super(props)
    this.state = {
      score : 0
    }
    this.handleChoice = this.handleChoice.bind(this)
  }
  static getDerivedStateFromProps(props, state){
    if(props.shapes && props.shapes.length > 0 && state.currentShape === undefined){
      return {
        currentShape : props.shapes[0],
      }
    }
    return null
  }
  componentDidMount(){
    if(!this.props.shapes || this.props.shapes.length === 0){
      this.props.loadShapes()
    }
  }
  componentDidUpdate(prevProps){
    if(prevProps.shapes.length === 1 && this.props.shapes.length === 0){
      this.props.loadShapes()
      this.setState({currentShape:undefined})
    }
    if(prevProps.shapes.length === 0 && this.props.shapes.length > 0){
      for(let shape of this.props.shapes){
        if(!this.props.cache[shape.code] && !this.props.metaCache[shape.code]){
          this.props.addCodeToQueue(shape.code)
        }
      }
    }
  }
  handleChoice(valid:boolean){
    this.props.validateShape(this.state.currentShape.id, valid)
    this.setState(prevState=>({
      currentShape:undefined,
      score : prevState.score+1
    }))
  }
  render(){
    if(!this.state.currentShape){return <ExplainedLoading text="Waiting for shapes"/>}
    const currentCode = this.state.currentShape.code
    if(!this.props.cache[currentCode] && !this.props.metaCache[currentCode]){
      return <ExplainedLoading text="Waiting for geocoding"/>
    }
    const placeOfShape = {
      id : currentCode,
      code : currentCode,
      edited : false,
      include : true,
      address : "",
      bidModifier : 1,
      placeProps : {
        position : (
          this.props.metaCache[currentCode]
          ? this.props.metaCache[currentCode]
          : this.props.cache[currentCode]
        ),
        editable : false
      }
    }
    return (
      <Panel title="Step1" subtitle="Is the shape visibly off?">
        <GoBack onClick={this.props.exit}/>
        <h4>Score: {this.state.score}</h4>
        <h3>{this.state.currentShape.name}</h3>
        <MapBuilder
          circlesDefinitions={[]}
          placesDefinitions={[placeOfShape]}
          shapes={{[this.state.currentShape.code]:this.state.currentShape}}
          displayPinAndShape
        />
        <Button style={{backgroundColor:"Green"}} onClick={()=>this.handleChoice(true)}>Good</Button>
        <Button style={{backgroundColor:"Red"}} onClick={()=>this.handleChoice(false)}>Bad</Button>
        <div>A good shape is a shape with the red pin inside and without irregularities (Like a line going across the shape)</div>
      </Panel>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(Step1)
