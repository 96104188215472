import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"

const setStatus = (state, action) => {
  return action.status
}

const Failed = ReducerUtils.createReducer<boolean>(false,{
  [Actions.FAILED_RETRIEVING_CAMPAIGNS_BASE_INFO] : setStatus,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST] : ()=>false
})

export default Failed
