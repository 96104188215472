import * as React from 'react'
import {Translate} from "react-localize-redux"

interface SoldCarsPercentProps{
  percent
  companyName : string
}

const SoldCarsPercent = (props:SoldCarsPercentProps)=>(
  <div className="ProportionKPI">
    <div className='percent'>{props.percent}</div>
    <div>
      <hr/>
      <div className='title'><Translate id="inventoryTurn.KPIs.proportion"/></div>
      <div className='title'><Translate id="inventoryTurn.KPIs.influenced" data={{company: props.companyName}}/></div>
    </div>
  </div>
)

export default SoldCarsPercent
