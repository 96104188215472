import './KeywordsInputContainer.css'
import * as React        from 'react'
import KeywordsInput     from '../../../../components/keywordsInput/KeywordsInput'
import {
  withLocalize,
  TranslateFunction,
  Translate
}                        from 'react-localize-redux'
import {
  Snackbar,
  SnackbarContent,
  Icon,
  IconButton
}                        from '@material-ui/core'
import KeywordsUtils     from '../../../../utils/KeywordsUtils'
import { BRANDING_TAGS } from '../../../../model/constant/KeywordsTags'

interface KeywordsInputContainerOwnProps {
  keywords                : string[]
  saveFunction            : (keywords:string[]) => void
  savingPosKeywords       : boolean
  failedSavingPosKeywords : boolean
  resetFailedPos          : () => void
}
interface KeywordsInputContainerProps extends KeywordsInputContainerOwnProps {
  translate : TranslateFunction
}

const KeywordsInputContainer = (props:KeywordsInputContainerProps) => {
  const [keywords,setKeywords] = React.useState(props.keywords)
  const handleInput = (keywords:string) => {
    setKeywords(keywords.split('\n'))
  }
  const resetFunction = () => {
    setKeywords(props.keywords)
  }
  const saveFunction = () => {
    const temp = keywords.map(keyword=>keyword.trim()).filter(keyword=>keyword)
    props.saveFunction(temp)
  }
  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if(reason !== 'clickaway'){props.resetFailedPos()}
  }
  const positiveKeywords = keywords.map(keyword => keyword.trim()).filter(keyword => keyword)
  const errors = KeywordsUtils.positiveKeywordsError(positiveKeywords,BRANDING_TAGS)

  return (
    <div className="KeywordsInputContainer">
      <Snackbar
        autoHideDuration={5000}
        color="secondary"
        open={props.failedSavingPosKeywords}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <SnackbarContent
          className="ErrorSnackBar"
          message={
            <span className="Error" >
              <Icon className="Icon">error</Icon>
              <span className="Message"><Translate id="keyword.keywordsError" /></span>
            </span>
          }
          action={
            <IconButton onClick={props.resetFailedPos}>
              <Icon style={{color:'white'}}>close</Icon>
            </IconButton>
          }
        />
      </Snackbar>
      <KeywordsInput
        keywords={keywords}
        title={props.translate('keywords.posKeywords') as string}
        functionnality='save'
        type="positive"
        description={false}
        errors={errors}
        handleInput={handleInput}
        originalKeywords={props.keywords}
        resetFunction={resetFunction}
        saveFunction={saveFunction}
        savingPosKeywords={props.savingPosKeywords}
        translateErrors
      />
    </div>
  )
}

export default withLocalize(KeywordsInputContainer as any) as React.ComponentType<KeywordsInputContainerOwnProps>
