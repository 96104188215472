import Actions         from "../../../model/constant/actions"
import ReducerUtils    from "../../../utils/ReducerUtils"
import AdCopyPattern   from "../../../model/Store/AdCopyPatterns/AdCopyPattern"

const store = (state, action) => {
  return {
    errorString : action.errorString,
    when        : new Date().getTime(),
  }
}
interface AdCopyPatternSaveErrorState{
  errorString : string
  when        : number
}
const AdCopyPatternSaveError = ReducerUtils.createReducer<AdCopyPatternSaveErrorState>(
  {errorString:"",when:0},
  {
    [Actions.STORE_SAVE_ADCOPY_PATTERN_ERROR] : store,
  }
)

export default AdCopyPatternSaveError
