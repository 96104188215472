import './SoldCarsDataTable.css'
import * as React         from 'react'
import {
  withLocalize,
  TranslateFunction
}                         from "react-localize-redux"
import BootstrapTable     from 'react-bootstrap-table-next'
import filterFactory      from 'react-bootstrap-table2-filter'
import {textFilter}       from 'react-bootstrap-table2-filter'
import paginationFactory  from 'react-bootstrap-table2-paginator'
import ObjectUtils        from "../../utils/ObjectUtils"
import DateUtils          from "../../utils/DateUtils"
import CSVUtils           from "../../utils/CSVUtils"
import {Button}           from "carbon-components-react"
import NumberFormatter    from "../../utils/NumberFormatter"


interface SoldCarsDataTableProps{
  vehiclesWithNavigations : any[]
  accountId               : number
  companyName             : string
  allowPagination        ?: boolean
  showExtraColumns       ?: boolean
  showCSVButton          ?: boolean
  translate              ?: TranslateFunction
}

const calculateVisitsRank = obj=> Math.round(
  100*obj.navigationsFromDB.length/(obj.navigationsNotFromDB.length+obj.navigationsFromDB.length)
)
// returns number of unique visitors
const calculateVisitors = navigations => ObjectUtils.getObjectValues(
  navigations.reduce((a,b)=>{
    a[b.browserId] = 1
    return a
  },{})
).length
const calculateVisitorsRank = obj=>{
  const fromDB = calculateVisitors(obj.navigationsFromDB)
  return Math.round(100*fromDB/(calculateVisitors(obj.navigationsNotFromDB)+fromDB))
}
const formatDate = x => x ? DateUtils.format(x) : "-"
const columnsText = {
  visitorsRank : <div style={{display:"inline-block"}}><div>Visitors</div><div>from DB</div></div>,
  days : <div style={{display:"inline-block"}}><div>Days in </div><div>Inventory</div></div>,
}
class SoldCarsDataTable extends React.Component<SoldCarsDataTableProps,any>{
  private table
  constructor(props){
    super(props)
    this.generateCSV = this.generateCSV.bind(this)
  }
  generateCSV(){
    let header     = ["Date","Year Make Model","Visitors from DB","Days in inventory"]
    let dataFields = ["removedOn","YMM","visitorsRank","days"]
    if(this.props.showExtraColumns){
      header     = [...header, "Unique visitors", "Visits rank"]
      dataFields = [...dataFields, "visitors", "visitsRank"]
    }
    const data = (
      this.table.store._filteredData.length>0
      ? this.table.store._filteredData
      : this.table.store._data
    )
    CSVUtils.downloadCSV(
      "soldCars_"+String(this.props.accountId)+".csv",
      CSVUtils.generateCSV(header, data.map(row=>dataFields.map(key=>row[key])), ",")
    )
  }
  render(){
    let columns:any = [
      {dataField:"id",hidden:true,sort:true},
      {
        sort:true,
        dataField:"removedOn",
        text:"Date\r\n",
        classes:"centeredColumn dateColumn",
        headerClasses:"centeredColumn dateColumn handleManualLineBreak",
      },{
        dataField:"YMM",
        text:this.props.translate("inventoryTurn.table.YMM"),
        classes:"YMM",
        filter:textFilter({delay:20}),
        formatter:(YMM,row)=><a href={row.url} target='_blank'><div>{YMM}</div></a>
      },{
        sort:true,
        dataField:"visitorsRank",
        text: this.props.translate("inventoryTurn.table.visitors", {company: this.props.companyName}),
        formatter:x=> NumberFormatter.formatNumber(x)+"%",
        classes:"centeredColumn",
        headerClasses:"centeredColumn handleManualLineBreak",
      },{
        sort:true,
        dataField:"days",
        text: this.props.translate("inventoryTurn.table.days"),
        formatter:x=>NumberFormatter.formatNumber(x),
        classes:"centeredColumn",
        headerClasses:"centeredColumn handleManualLineBreak",
      },
    ]
    if(this.props.showExtraColumns === true){
      columns = [
        ...columns,
        {
          sort:true,
          dataField:"visitors",
          text: this.props.translate("inventoryTurn.table.uniqueVisitors"),
          formatter:x=>NumberFormatter.formatNumber(parseFloat(x)),
          classes:"centeredColumn",
          headerClasses:"centeredColumn handleManualLineBreak",
        },{
          sort:true,
          dataField:"visitsRank",
          text: this.props.translate("inventoryTurn.table.visitsRank"),
          formatter:x=>NumberFormatter.formatNumber(parseFloat(x))+"%",
          classes:"centeredColumn",
          headerClasses:"centeredColumn handleManualLineBreak",
        }
      ]
    }
    return (
      <div className="SoldCarsDataTable">
        {this.props.showCSVButton===true?
          <Button className="CSVButton" onClick={this.generateCSV}>CSV</Button>
          :
          <></>
        }
        <BootstrapTable
          key={this.props.vehiclesWithNavigations.length} // remount if new data comes in
          ref={table=>this.table = table}
          keyField='id'
          data={
            this.props.vehiclesWithNavigations.map(shown=>({
              id        : shown.vehicle.getId(),
              url       : shown.vehicle.getUrl(),
              YMM       : [
                shown.vehicle.getYear(),
                shown.vehicle.getMake(),
                shown.vehicle.getModel(),
                "("+shown.vehicle.getMostRepresentativeId()+")",
              ].join(" "),
              visitors     : this.props.allowPagination===true?calculateVisitors(shown.navigationsFromDB):undefined,
              visitorsRank : calculateVisitorsRank(shown),
              visitsRank   : this.props.allowPagination===true?calculateVisitsRank(shown):undefined,
              days         : shown.vehicle.getDaysInInventory(),
              removedOn    : formatDate(shown.vehicle.getRemovedOn()),
            }))
          }
          pagination={paginationFactory({
            sizePerPage : (this.props.allowPagination===true ? 10 : 5000),
            hideSizePerPage : true,
          })}
          defaultSorted={[{dataField:'visitorsRank',order:'desc'},]}
          columns={columns}
          filter={filterFactory()}
          classes="normalSizedDatatable SoldCarsDataTable"
        />
      </div>
    )
  }
}
export default withLocalize(SoldCarsDataTable)
