import * as React                          from "react"
import {connect}                           from "react-redux"
import ExplainedLoading                    from "../loading"
import ErrorBoundary                       from "../Error/ErrorBoundary"
import { retrieveTargetUserPreferences }   from "../../actions/user/userPreferences"
import TargetUserPreferences               from "../../model/Store/Management/TargetUserPreferences"


interface LoaderProps {
  targetUserPrefs     : TargetUserPreferences
  retrievingUserPrefs : boolean
  userId              : number
  targetUserId        : number
  loadUserPrefs       : (targetUserId) => void
  render              : (prefs: TargetUserPreferences) => React.Component
}

const mapStateToProps = (state)=>{
  const userId = state.Login.userLogged.profile.id
  const targetUser = state.Users.TargetUser

  return {
    userId              : userId,
    targetUserId        : state.Users.TargetUser,
    targetUserPrefs     : state.Users.PreferencesList[targetUser],
    retrievingUserPrefs : state.Users.RetrievingPreferences,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadUserPrefs : (targetUserId: number) => dispatch(retrieveTargetUserPreferences(targetUserId))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

const UserLoader = (props: LoaderProps) => {
  if(props.targetUserPrefs === undefined){
    if(props.retrievingUserPrefs===false){props.loadUserPrefs(props.targetUserId)}
    return <ExplainedLoading horizontal small translateId="loadings.targetUserPreferences"/>
  }
  if (props.targetUserPrefs && props.retrievingUserPrefs) {
    return <ExplainedLoading horizontal small translateId="loadings.targetUserPreferences"/>
  }
  return <ErrorBoundary>{props.render(props.targetUserPrefs)}</ErrorBoundary>
}
const UserLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(UserLoader)
const requiresTargetUserPreferencesMini = Component => props => (
  <UserLoaderConnected
    render={ targetUserPrefs => <Component targetUserPrefs={targetUserPrefs} {...props} /> }
  />
)
export default requiresTargetUserPreferencesMini
