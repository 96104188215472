import logUserAction from "../../actions/logger/Logger"
import Context       from "../../model/constant/context"

export default class LogBuilder{
  public static log = (action,token,details) => {
    if(!LogBuilder[action]){throw Error(`Invalid logAction ${action}`) }
    const payload = LogBuilder[action](token,details)
    LogBuilder.stringifyAndLog(token,payload)
  }
  private static stringifyAndLog = (token, payload) => {
    payload = {...payload, details:JSON.stringify(payload.details)}
    logUserAction(
      token,
      payload.userAction,
      payload.details,
      payload.context ? payload.context:""
    )
  }
  private static SAVE_GEO = (token, details) => {
    return {
      userAction:"Saved",
      context:Context.GEO,
      details:{
        accountId : details.accountId,
        matcher   : details.matcher,
        radiuses  : details.circles,
        places    : details.places,
      },
    }
  }

  private static SAVE_KEYWORD = (token, details) => {
    return {
      userAction:"Saved",
      context:Context.KEYWORD,
      details
    }
  }
  private static CREATE_ADCOPY = (token, details) => {
    return {
      userAction:"Created",
      context:Context.ADCOPY,
      details
    }
  }
  private static REMOVE_ADCOPY = (token, details) => {
    return {
      userAction:"Removed",
      context:Context.ADCOPY,
      details
    }
  }
  private static EDIT_ADCOPY = (token, details) => {
    return {
      userAction:"Saved",
      context:Context.ADCOPY,
      details
    }
  }
  private static TOGGLE_MENU = (token, details) => {
    return {
      userAction:"ToggledMenu",
      details
    }
  }
  private static LOGIN_SUCCESS = (token, details)=>{
    return {
      userAction:"Login",
      details
    }
  }
  private static SAVE_GEO_EXCEPTION = (token, details)=>{
    return {
      userAction:"SavedGeoException",
      context:Context.CAMPAIGN,
      details
    }
  }
  private static REMOVE_GEO_EXCEPTION = (token, details)=>{
    return {
      userAction:"RemovedGeoException",
      context:Context.CAMPAIGN,
      details
    }
  }
  private static SAVE_STATUS_EXCEPTION = (token, details)=>{
    return {
      userAction:"SavedStatusException",
      context:Context.CAMPAIGN,
      details
    }
  }
  private static REMOVE_STATUS_EXCEPTION = (token, details)=>{
    return {
      userAction:"RemovedStatusException",
      context:Context.CAMPAIGN,
      details
    }
  }
  private static ASSIGN_WAZE_COMPANY = (token, details)=>{
    return {
      userAction:"AssignWazeCompany",
      context:Context.ACCOUNT,
      details
    }
  }
  private static SAVE_TRACKING_INFO = (token, details)=>{
    return {
      userAction:"SaveTrackingInfo",
      context:Context.ACCOUNT,
      details
    }
  }
  private static SAVE_WHITE_LABEL = (token, details)=>{
    return {
      userAction:"SaveWhiteLabel",
      context:Context.ACCOUNT,
      details
    }
  }
  private static CHANGE_PERIOD = (token, details)=>{
    return {
      userAction:"ChangedPeriod",
      context:Context.APPLICATION,
      details
    }
  }
  private static REPLACE_GEOS = (token, details)=>{
    return {
      userAction:"ReplaceGeos",
      context:Context.GEO,
      details
    }
  }
  private static SAVED_ENG_RULES = (token, details)=>{
    return {
      userAction:"SavedEngRules",
      context:Context.RULES,
      details
    }
  }
  private static CREATE_MARKETING_EVENT = (token, details)=>{
    return {
      userAction:"CreateMarketingEvent",
      context:Context.ADBUILDER,
      details
    }
  }
  private static DELETE_MARKETING_EVENT = (token, details)=>{
    return {
      userAction:"DeleteMarketingEvent",
      context:Context.ADBUILDER,
      details
    }
  }
  private static EDIT_MARKETING_EVENT = (token, details)=>{
    return {
      userAction:"EditMarketingEvent",
      context:Context.ADBUILDER,
      details
    }
  }
  private static EDIT_STRATEGY = (token, details)=>{
    return {
      userAction:"EditStrategy",
      context:Context.STRATEGY,
      details
    }
  }
  private static BRANDING_EDIT_STRATEGY = (token, details)=>{
    return {
      userAction:"EditStrategy",
      context:Context.BRANDING,
      details
    }
  }
  private static BRANDING_CREATE_ADGROUP = (token,details) => {
    return {
      userAction:"createAdGroup",
      context:Context.BRANDING,
      details
    }
  }
  private static BRANDING_DELETE_ADGROUP = (token,details) => {
    return {
      userAction:"deleteAdGroup",
      context:Context.BRANDING,
      details
    }
  }

  private static BRANDING_UPDATE_AUDIENCE_ADS = (token,details) => {
    return {
      userAction:"updateAudienceAds",
      context:Context.BRANDING,
      details
    }
  }
  private static BRANDING_CREATE_AUDIENCE_ADS = (token,details) => {
    return {
      userAction:"createAudienceAds",
      context:Context.BRANDING,
      details
    }
  }
  private static BRANDING_SAVE_AD_COPIES = (token,details) => {
    return {
      userAction:"saveAdCopies",
      context:Context.BRANDING,
      details
    }
  }
  private static BRANDING_SAVE_IDENTITY_VARIANTS = (token,details) => {
    return {
      userAction:"saveIdentityVariants",
      context:Context.BRANDING,
      details
    }
  }
  private static BRANDING_SAVE_POSITIVE_KEYWORDS = (token,details) => {
    return {
      userAction:"savePositiveKeywords",
      context:Context.BRANDING,
      details
    }
  }
  private static BRANDING_SAVE_NEGATIVE_KEYWORDS = (token,details) => {
    return {
      userAction:"saveNegativeKeywords",
      context:Context.BRANDING,
      details
    }
  }
  private static DELETE_FEED = (token, details) => {
    return {
      userAction:"deleteFeed",
      context: Context.FEEDS,
      details
    }
  }
  private static DELETE_FEED_SIGNED_URL = (token, details) => {
    return {
      userAction:"deleteFeedSignedUrl",
      context: Context.FEEDS,
      details
    }
  }
  private static CREATE_FEED_SIGNED_URL = (token, details) => {
    return {
      userAction:"createFeedSignedUrl",
      context: Context.FEEDS,
      details
    }
  }
  private static REFRESH_FEED = (token, details) => {
    return {
      userAction:"refreshFeed",
      context: Context.FEEDS,
      details
    }
  }
}
