import TrackingAPI from "../../utils/TrackingAPI"
import Period      from "../../model/Period"
import Actions     from "../../model/constant/actions"
import DateUtils   from "../../utils/DateUtils"
import StringUtils from "../../utils/StringUtils"
import {getToken}  from "../../storeAccessor/Generic"

const getNavigationSuccess = (response, accountId) => {
  return {
    type        : Actions.RETRIEVE_USER_NAVIGATION_OF_ACCOUNT,
    accountId   : accountId,
    navigations : response.data.navigations.values.map(value=>{
      let returned = {}
      for(let i=0; i<response.data.navigations.fields.length; i++){
        returned[response.data.navigations.fields[i]] = value[i]
      }
      returned["simplePath"] = StringUtils.getPath(returned["path"])
      return returned
    })
  }
}
const getNavigationError = (response, accountId) => {
  return {
    type        : Actions.RETRIEVE_USER_NAVIGATION_OF_ACCOUNT,
    accountId   : accountId,
    navigations : []
  }
}

export const loadNavigation = (accountId:number, period:Period) => (dispatch, getState) => {
  dispatch({
    type      : Actions.RETRIEVING_USER_NAVIGATIONS_OF_ACCOUNT,
    accountId : accountId,
    status    : true
  })
  Promise.all(
    DateUtils.dividePeriod(period, 5).map(bloc=>
      TrackingAPI.get({
          action    : "GetUserNavigation",
          accountId : accountId,
          token     : getToken(getState()),
          dateFrom  : DateUtils.format(bloc.dateFrom),
          dateTo    : DateUtils.format(bloc.dateTo),
        },
        response =>{
          if(response.error.length === 0){
            dispatch(getNavigationSuccess(response, accountId))
          }
          else{
            dispatch(getNavigationError(response, accountId))
          }
        },
        error => dispatch(getNavigationError(error, accountId))
      )
    )
  ).then(()=>dispatch({
    type      : Actions.RETRIEVING_USER_NAVIGATIONS_OF_ACCOUNT,
    accountId : accountId,
    status    : false
  }))
}
