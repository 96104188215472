import {combineReducers}       from "redux"
import Events                  from "./MarketingEvents"
import Retrieving              from "./retrieving/Retrieving"
import Saving                  from "./Saving"
import FailedSaving            from "./FailedSaving"
import Deleting                from "./Deleting"
import LatestCopyId            from "./LatestCopyId"

const AdBuilder = combineReducers({
  Events,
  Retrieving,
  Saving,
  FailedSaving,
  Deleting,
  LatestCopyId
})

export default AdBuilder
