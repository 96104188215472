import styles                     from './AdCopyEditorStyles'
import * as React                 from 'react'
import AdCopies                   from './AdCopies'
import Tag                        from './Tag'
import {
  Icon,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  Checkbox
}                                 from '@material-ui/core'
import AdCopyEditingForm          from '../../../components/adCopy/AdCopyEditingForm'
import {
  Translate,
  LocalizeContextProps,
  withLocalize
}                                 from 'react-localize-redux'
import {
  AdCopyRSATemplates,
  AdCopyParents,
  AdgroupsToCopy
}                                 from '../../../model/adcopy'
import InventoryTester            from '../../../components/adCopy/InventoryTesterRSA'
import AWSearch                   from '../../../model/Store/Audiences/AWSearch'
import {provideAudienceName}      from '../../adBuilder/utils/adBuilderUtils'
import StringUtils                from '../../../utils/StringUtils'
import {checkErrorsInField}       from "../../../components/adCopy/utils"
import {calculateLengthForEditor} from '../../adBuilder/utils/FindAdCopyLength'

interface AdCopyEditorProps extends LocalizeContextProps {
  templates                      : AdCopyRSATemplates[]
  tags                           : string[]
  lang                           : string
  handleCancel                   : ()=>void
  availableCopyOptions           : AdCopyParents
  saveFunction                   : (templates:AdCopyRSATemplates[])=>void
  saveAndClose                   : (templates:AdCopyRSATemplates[])=>void
  readOnly                       : boolean
  forceDisableInteractions       : boolean
  adgroupsToCopy                 : (value:boolean, strategy:string, adgroup:string, allAdgroupsOptions?:{[adgroup:string]:boolean}) => void
  disableCopying                 : boolean
  inventoryGroup                 : string
  AWAudiences                    : AWSearch[]
  resetAdgroupsToCopy            : () => void
}

const AdCopyEditor = (props:AdCopyEditorProps) => {
  // State
  const [newAdCopyId,setNewAdCopyId] = React.useState<number>(-1)
  const [templates,setTemplates] = React.useState<AdCopyRSATemplates[]>(props.templates)
  const [selectedTemplateId,setSelectedTemplateId] = React.useState(templates[0]?.id || -1)
  const selectedTemplate = templates.find(template=>template.id === selectedTemplateId)
    || {id: -1, headlines: [], descriptions: []}
  const [hasError, setHasError] = React.useState<boolean>(false)
  const [numberError, setNumberError] = React.useState<boolean>(false)
  const [templatesHaveErrors, setTemplatesHaveErrors] = React.useState<boolean>(false)
  const [idsToDelete, setIdsToDelete] = React.useState<number[]>([])
  const [adgroupsToCopy, setAdgroupsToCopy] = React.useState<AdgroupsToCopy>(
    Object.keys(props.availableCopyOptions).reduce((o, strategy) =>
      ({...o, [strategy]: Object.keys(props.availableCopyOptions[strategy]).reduce((o, adgroup) =>
        ({...o, [adgroup]: false}),{
          "All adgroups" : false
        })
      }),{}
    )
  )

  const markedForDeletion = idsToDelete.some(value => value === selectedTemplateId)
  const blockSaving = props.readOnly || props.forceDisableInteractions || hasError || numberError || templatesHaveErrors

  //Sync the templates state with the props
  React.useEffect(()=>{
    setTemplates(props.templates)
    if (!props.templates.find(x=>x.id===selectedTemplateId)){
      setSelectedTemplateId(props.templates[0]?.id || -1)
    }
    // Reset the adgroups to copy state when switching templates
    setAdgroupsToCopy(Object.keys(props.availableCopyOptions).reduce((o, strategy) =>
      ({...o, [strategy]: Object.keys(props.availableCopyOptions[strategy]).reduce((o, adgroup) =>
        ({...o, [adgroup]: false}),{
          "All adgroups" : false
        })
      }),{}
    ))
    props.resetAdgroupsToCopy()
  },[props.templates])

  const invalidNumberOfParts = template =>
    (template.headlines.filter(text => text !="").length<3 || template.descriptions.filter(text => text !="").length<2 )
      && !idsToDelete.some(value => value === template.id)
      
  const templateHasErrors = template =>
    template.headlines.some(x => checkErrorsInField(props.tags, x.text, false).length > 0)
    || template.descriptions.some(x => checkErrorsInField(props.tags, x.text, false).length > 0)

  React.useEffect(()=>{
    setNumberError(invalidNumberOfParts(selectedTemplate))
  },[selectedTemplate, idsToDelete])

  // Check if other templates are invalid, even if we're not editing that template
  React.useEffect(() => {
    const templateNumbers = templates.some(template => {
      // Ignore showing that some templates have errors if
      // it's the template you're already on and or if it's marked for deletion
      if(idsToDelete.some(x => x === template.id) || template.id === selectedTemplateId) {
        return false;
      }
      if(template.headlines.length <= 0 && template.descriptions.length <= 0) {
        return true;
      }
      return templateHasErrors(template) || invalidNumberOfParts(template)
    })
    setTemplatesHaveErrors(templateNumbers)
  }, [selectedTemplateId, idsToDelete])

  // Hack to ensure that the current template is checked again when we mark it for deletion
  React.useEffect(() => {
    if(idsToDelete.some(x => x === selectedTemplateId)) {
      setHasError(false)
      return
    }
    setHasError(templateHasErrors(selectedTemplate))
  }, [idsToDelete])

  // If the current campaign group being edited is some inventory group,
  // We need to apply copying restrictions, because not all tags are
  // available for all strategies
  // This method will trim the available strategies and adgroups to what
  // is permitted.
  const provideInventoryCopyingOptions = ():AdCopyParents => {
    let availableCopyOptions:AdCopyParents = {}
    if (props.inventoryGroup === "VDP" || props.inventoryGroup === "SRP_MMY"){
      for (let strategy of Object.keys(props.availableCopyOptions)){
        if (strategy !== "SRP_MM"){
          for (let adgroup of Object.keys(props.availableCopyOptions[strategy])){
            if (props.availableCopyOptions[strategy][adgroup].includes(props.lang)){
              availableCopyOptions = {
                ...availableCopyOptions,
                [strategy] : props.availableCopyOptions[strategy]
              }
            }
          }
        }
      }
    }
    if (props.inventoryGroup === "SRP_MM"){
      for (let strategy of Object.keys(props.availableCopyOptions)){
        if (strategy === "SRP_MM"){
          for (let adgroup of Object.keys(props.availableCopyOptions[strategy])){
            if (props.availableCopyOptions[strategy][adgroup].includes(props.lang)){
              availableCopyOptions = {
                ...availableCopyOptions,
                [strategy] : props.availableCopyOptions[strategy]
              }
            }
          }
        }
      }
    }
    return availableCopyOptions
  }

  const filterCopyingOptionsToOnlyCurrentLang = ():AdCopyParents => {
    let copyOptions:AdCopyParents = {}
    Object.keys(props.availableCopyOptions).forEach(strategy=>{
      Object.keys(props.availableCopyOptions[strategy]).forEach(adgroup=>{
        if (props.availableCopyOptions[strategy][adgroup].includes(props.lang)){
          copyOptions = {
            ...copyOptions,
            [strategy] : props.availableCopyOptions[strategy]
          }
        }
      })
    })
    return copyOptions
  }

  const availableCopyOptions = props.inventoryGroup
    ? provideInventoryCopyingOptions()
    : filterCopyingOptionsToOnlyCurrentLang()

  const handleClick = (event) => {
    setSelectedTemplateId(parseInt(event.currentTarget.id,10))
  }

  const onHeadlineUpdate = (id:number, text:string, position?:number) => {
    setTemplates(prevState=>{
      return prevState.map(template=>{
        if(template.id === selectedTemplateId) {
          const temp = {
            ...template,
            descriptions:[...template.descriptions],
            headlines:[...template.headlines]
          } as AdCopyRSATemplates
          temp.headlines.find(x=>x.id===id).text = text
          temp.headlines.find(x=>x.id===id).position = position ? position : temp.headlines.find(x=>x.id===id).position
          return temp
        }
        return template
      })
    })
  }

  const onDescriptionUpdate = (id:number, text:string, position?:number) => {
    setTemplates(prevState=>{
      return prevState.map(template=>{
        if(template.id === selectedTemplateId) {
          const temp = {
            ...template,
            descriptions:[...template.descriptions],
            headlines:[...template.headlines]
          } as AdCopyRSATemplates
          temp.descriptions.find(x=>x.id===id).text = text
          temp.descriptions.find(x=>x.id===id).position = position ? position : temp.descriptions.find(x=>x.id===id).position
          return temp
        }
        return template
      })
    })
  }

  const createNewAdCopy = () => {
    setTemplates(prevState=>{
      return [
        {
          id:newAdCopyId,
          headlines:[{text:"",position:-1, id:1}, {text:"",position:-1, id:2},{text:"",position:-1, id:3}],
          descriptions:[{text:"",position:-1, id:1}, {text:"",position:-1, id:2}]
        },
      ...prevState]
    })
    setSelectedTemplateId(newAdCopyId)
    setNewAdCopyId(prevState => prevState-1)
  }

  const handleNewHeadline = () => {
    setTemplates(prev=>{
      return prev.map(template=>{
        if (template.id === selectedTemplateId) {
          template.headlines.push({text: "", position: -1, id: template.headlines.length+1})
        }
        return template
      })
    })
  }

  const handleNewDescription = () => {
    setTemplates(prev=>{
      return prev.map(template=>{
        if (template.id === selectedTemplateId) {
          template.descriptions.push({text: "", position: -1, id: template.descriptions.length+1})
        }
        return template
      })
    })
  }

  const handleCopySelection = (value:boolean, strategy:string, adgroup:string) => {
    if (adgroup === "All adgroups"){
      const copy = JSON.parse(JSON.stringify(adgroupsToCopy[strategy]))
      Object.keys(copy).forEach(x=>
        copy[x] = value
      )
      setAdgroupsToCopy(prev=>{
        return {
          ...prev,
          [strategy] : {...copy}
        }
      })
      props.adgroupsToCopy(value, strategy, adgroup, {...copy})
    } else {
      setAdgroupsToCopy(prev=>{
        return {
          ...prev,
          [strategy] : {
            ...prev[strategy],
            [adgroup] : value
          }
        }
      })
      props.adgroupsToCopy(value, strategy, adgroup)
    }
  }

  const generateAdgroupToCopyFeedback = ():JSX.Element[] => {
    // This method allows us to display which adgroups are about to be copied to
    // under the copy select component.
    // If we are using copy to All adgroups, don't display all of the specific adgroups,
    // just display All adgroups.
    const toDisplay:string[] = []
    Object.keys(adgroupsToCopy).forEach(strategy=>{
      for (let adgroup of Object.keys(adgroupsToCopy[strategy])){
        if (adgroup==="All adgroups" && adgroupsToCopy[strategy][adgroup]){
          toDisplay.push(strategy + " > " + provideAudienceName(adgroup, props.AWAudiences))
          break
        }
        if (adgroupsToCopy[strategy][adgroup]){
          toDisplay.push(strategy + " > " + provideAudienceName(adgroup, props.AWAudiences))
        }
      }
    })
    return toDisplay.map(x=><p key={x}>{x}</p>)
  }

  const handleSetToDelete = (id:number) => {
    if (idsToDelete.includes(id)){
      setIdsToDelete(idsToDelete.filter(x=>x!==id))
    } else {
      setIdsToDelete(prev=>[...prev, id])
    }
  }

  const handleSave = () => {
    props.saveFunction(templates.filter(template=>!idsToDelete.includes(template.id)))
    setIdsToDelete([])
  }

  const handleSaveAndClose = () => {
    props.saveAndClose(templates.filter(template=>!idsToDelete.includes(template.id)))
    setIdsToDelete([])
  }

  const classes = styles()
  return (
    <div>
      <div className={classes.adCopyEditor}>
        <div className={classes.adcopies}>
          <div className={classes.adCopiesCards}>
            <AdCopies
              templates={templates}
              selectedTemplateId={selectedTemplateId}
              setSelectedTemplate={handleClick}
              setToDelete={handleSetToDelete}
              idsToDelete={idsToDelete}
            />
          </div>
          {templates.length < 3 &&
            <div onClick={createNewAdCopy} className={classes.add}>
              <p className={classes.icon}><Icon fontSize="large">add_circle</Icon></p>
              <p className={classes.addLabel}><Translate id="adCopies.addNew" /></p>
            </div>
          }
        </div>
        <div className={classes.form}>
          <AdCopyEditingForm
            lang={props.lang}
            key={selectedTemplateId}
            headlines={selectedTemplate.headlines}
            descriptions={selectedTemplate.descriptions}
            onHeadlineUpdate={onHeadlineUpdate}
            onDescriptionUpdate={onDescriptionUpdate}
            newHeadline={handleNewHeadline}
            newDescription={handleNewDescription}
            showErrorText
            tags={props.tags}
            textLengthCalculation={(text)=>calculateLengthForEditor(text)}
            onError={(errorsOnTemplate)=>!markedForDeletion ? setHasError(errorsOnTemplate) : null}
            onPinError={(pinError)=>setHasError(pinError)}
          />
        </div>
        <div className={classes.tagsAndCopy}>
          <div className={classes.tags}>
            <p className={classes.tagTitle}><Translate id="adCopies.tagTitle" /></p>
            {props.tags.map((tag,i)=>
              <Tag key={i} tag={tag}/>
            )}
          </div>
          { !props.disableCopying &&
            <div className={classes.copier}>
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.label}><Translate id="adCopies.copy" /></InputLabel>
                <Select
                  className={classes.selects}
                >
                  {Object.keys(availableCopyOptions).map(strategy=>{
                    return (
                      <div key={strategy}>
                        <ListSubheader><Translate id="adCopies.strategy" />: {strategy}</ListSubheader>
                        { Object.keys(availableCopyOptions?.[strategy]).length > 1 &&
                          <MenuItem value={"All adgroups"}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={adgroupsToCopy[strategy]["All adgroups"]}
                                  color="primary"
                                  onChange={(_e,c)=>handleCopySelection(c, strategy,"All adgroups")}
                                />
                              }
                              label={props.translate("adCopies.allAdgroups")}
                            />
                          </MenuItem>
                        }
                        {Object.keys(availableCopyOptions?.[strategy] ?? []).map(adgroup=>{
                          return <MenuItem key={adgroup} value={adgroup}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={adgroupsToCopy[strategy][adgroup]}
                                  color="primary"
                                  onChange={(_e,c)=>handleCopySelection(c, strategy,adgroup)}
                                />
                              }
                              label={provideAudienceName(adgroup, props.AWAudiences)}
                            />
                          </MenuItem>
                        })}
                      </div>
                    )
                  })}
                </Select>
              </FormControl>
              <div className={classes.adGroupsToCopy}>
              { generateAdgroupToCopyFeedback() }
              </div>
            </div>
          }
        </div>
      </div>
      { numberError &&
        <div className={classes.errorMessage}>
          <Translate id="adCopies.errorTextNumber" />
        </div>
      }
      { templatesHaveErrors &&
        <div className={classes.errorMessage}>
          <Translate id="adCopies.errorsInOtherTemplates" />
        </div>
      }
      <div className={classes.footer}>
        <Button
          className={classes.button}
          onClick={handleSave}
          style={{color:'white'}}
          variant="contained"
          size="large"
          disabled={blockSaving}
        >
          <Translate id="common.save" />
        </Button>
        <Button
          className={classes.button}
          onClick={handleSaveAndClose}
          size="large"
          variant="contained"
          disabled={blockSaving}
        >
          <Translate id="common.saveAndClose" />
        </Button>
        <Button
          onClick={props.handleCancel}
          variant="outlined"
          size="large"
          color="secondary"
        >
          <Translate id="common.cancel" />
        </Button>
      </div>
      <InventoryTester
        adCopy={selectedTemplate}
        maxChar={{
          headlines    : 30,
          descriptions : 90
        }}
        lang={props.lang}
        state={"new"}
      />
    </div>
  )
}

export default withLocalize(AdCopyEditor)
