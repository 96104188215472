import './AdCopy.css'
import * as React     from "react"
import AdGroupView    from "./AdGroupView"
import Friend from "../../../../../../model/Store/Campaign/Friend"

interface AdCopyComponentProps{
  object : Friend
}
const AdCopyComponent = (props:AdCopyComponentProps) => {
  let groupedAdCopies = []
  for(let campaign of props.object.getCampaigns()){
    if(!campaign.adGroups){continue}
    for(let adGroup of campaign.adGroups){
      let copy = adGroup.adCopies.map(x=>({...x}))
      copy.sort((a, b) =>{
        var x = a.status.toLowerCase()
        var y = b.status.toLowerCase()
        if(x === "enabled" && y === "paused"){return -1}
        if(y === "enabled" && x === "paused"){return 1}
        return 0
      })
      groupedAdCopies.push({
        name: adGroup.name+" "+campaign.lang,
        adCopies: copy
      })
    }
  }
  groupedAdCopies.sort((a, b) =>{
    var x = a.name.toLowerCase()
    var y = b.name.toLowerCase()
    if(x < y){return 1}
    if(x > y){return -1}
    return 0
  })
  return(
    <div className="CampaignAdCopies">
      {groupedAdCopies.map((adCopiesGroup,i)=>
        <AdGroupView key={adCopiesGroup.name} {...adCopiesGroup}/>
      )}
    </div>
  )
}

export default AdCopyComponent
