import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const setTrue = (state,action) => {
  return {
    ...state,
    [action.accountId] : true
  }
}
const IsFetched = ReducerUtils.createReducer<{[accountId:number]:boolean}>({},{
  [Actions.RETRIEVE_WAZE_PERFORMANCE_DATA] : setTrue,
  [Actions.RETRIEVE_WAZE_DATA_NO_ACCOUNT] : setTrue,
  [Actions.CHANGE_SELECTED_PERIOD] : ()=>({}) //reload on period change
})
export default IsFetched
