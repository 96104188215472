import Pacy                                              from "../../utils/Pacy"
import DateUtils                                         from "../../utils/DateUtils"
import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import UserActions                                       from "../../model/constant/UserAction"
import Period                                            from "../../model/Period"
import {Guideline}                                       from "../../model/pacy/Guideline"
import {getToken}                                        from "../../storeAccessor/Generic"
import LogBuilder                                        from "../../controller/log/LogBuilder"

const extractMessageFromError = (error:any) => {
  if(error.response && error.response.data){
    return error.response.data.error
  }
}

export const addGuideline = (accountId:number, proxyGroupId:string, guideline:Guideline) => (dispatch, getState) => {
  dispatch({type: Actions.SAVING_PACY_GUIDELINE, status: true, proxyGroupId})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Pacy.addGuideline(token, accountId, proxyGroupId, guideline)
  )
  .then(response=>{
    dispatch({
      type      : Actions.ADD_PACY_GUIDELINE,
      guideline : response.data,
      proxyGroupId,
      accountId,
    })
    dispatch({type: Actions.SAVING_PACY_GUIDELINE, status: false})
    dispatch({type:Actions.RETRIEVE_PACY_DECISIONS_AFTER_SAVING,status:true})
    dispatch({type:Actions.RETRIEVE_PACY_V0USAGE_AFTER_SAVING,status:true})
  })
  .catch((error)=>{
    console.warn(error)
    dispatch({
      type   : Actions.SAVING_PACY_GUIDELINE,
      status : false,
      error  : extractMessageFromError(error)||true,
      proxyGroupId,
    })
  })


}
