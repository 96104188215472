export default class Vehicle{

  protected id              : number
  protected make            : string
  protected model           : string
  protected fuzzyModel      : string
  protected year            : number
  protected state           : string
  protected price           : number
  protected cleanerId       : number
  protected SRPPoolId       : number
  private   color           : string
  private   engine          : string
  private   url             : string
  private   passedRules     : boolean
  private   numPictures     : number
  private   daysInInventory : number
  private   current         : boolean
  private   removedOn       : Date|null

  constructor(id:number,make:string,model:string,year:number,url:string){
    this.id    = id
    this.make  = make
    this.model = model
    this.year  = year
    this.url   = url
  }

  public isCurrent()               : boolean {return this.current}
  public getMostRepresentativeId() : string  {return this.id.toString()}
  public getId()                   : number  {return this.id}
  public getMake()                 : string  {return this.make}
  public getModel()                : string  {return this.model}
  public getFuzzyModel()           : string  {return this.fuzzyModel}
  public getYear()                 : number  {return this.year}
  public getState()                : string  {return this.state}
  public getPrice()                : number  {return this.price}
  public getCleanerId()            : number  {return this.cleanerId}
  public getSRPPoolId()            : number  {return this.SRPPoolId}
  public getColor()                : string  {return this.color}
  public getEngine()               : string  {return this.engine}
  public getUrl()                  : string  {return this.url}
  public getPassedRules()          : boolean {return this.passedRules}
  public getNumPictures()          : number  {return this.numPictures}
  public getDaysInInventory()      : number  {return this.daysInInventory}
  public getRemovedOn()            : Date|null {return this.removedOn?this.removedOn:null}

  public withId(id:number){
    this.id = id
    return this
  }
  public withMake(make:string){
    this.make = make
    return this
  }
  public withModel(model:string){
    this.model = model
    return this
  }
  public withFuzzyModel(fuzzyModel:string){
    this.fuzzyModel = fuzzyModel
    return this
  }
  public withYear(year:number){
    this.year = year
    return this
  }
  public withState(state:string){
    this.state = state
    return this
  }
  public withPrice(price:number){
    this.price = price
    return this
  }
  public withCleanerId(cleanerId:number){
    this.cleanerId = cleanerId
    return this
  }
  public withSRPPoolId(SRPPoolId:number){
    this.SRPPoolId = SRPPoolId
    return this
  }
  public withColor(color:string){
    this.color = color
    return this
  }
  public withEngine(engine:string){
    this.engine = engine
    return this
  }
  public withUrl(url:string){
    this.url = url
    return this
  }
  public withPassedRules(passedRules:boolean){
    this.passedRules = passedRules
    return this
  }
  public withNumPictures(numPictures:number){
    this.numPictures = numPictures
    return this
  }
  public withDaysInInventory(daysInInventory:number){
    this.daysInInventory = daysInInventory
    return this
  }
  public withStatusCurrent(current:boolean){
    this.current = current
    return this
  }
  public withRemovedOn(removedOn:Date){
    this.removedOn = removedOn
    return this
  }
}

