import * as React                    from "react"
import Permissions                   from "../../model/constant/Permissions"
import AdCopyManager                 from "./components/AdCopyManager"
import AccountPermissionBoundary     from "../../components/permissions/AccountPermissionBoundary"

interface AdCopyProps {
  pathname     : string
  category    ?: string
  application ?: string
  strategy    ?: string
}

const AdCopy = (props:AdCopyProps) => {
  return (
    <AccountPermissionBoundary
      permissions={[Permissions.Keystone_ChangeAdCopies]}
      onFailure={<AdCopyManager pathname={props.pathname} readOnly/>}
    >
      <AdCopyManager pathname={props.pathname}/>
    </AccountPermissionBoundary>
  )
}

export default AdCopy
