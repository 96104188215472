import './index.css'
import * as React                      from "react"
import {connect}                       from "react-redux"
import {withLocalize, Translate}       from "react-localize-redux"
import {TextInput}                     from "carbon-components-react"
import {Button}                        from "carbon-components-react"
import {Link}                          from "react-router-dom"
import Hint                            from "../../components/alert/Hint"
import resetPassword                   from "../../actions/ForgotPassword"

interface ForgotPasswordProps{
  message       : string
  resetPassword : (email:string)=>void
  translate    ?: (translateId:string)=>string
}
const mapStateToProps = (state) => {
  return {
    message : state.Login.forgotPasswordMessage,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (email:string)=>{dispatch(resetPassword(email))},
  }
}
const renderError = (error:string) => {
  if(error === "Invalid Email"){
    return <Translate id={"login.errors.invalidEmail"}/>
  }
  return <div>{error}</div>
}
class ForgotPassword extends React.Component<ForgotPasswordProps,{email:string}> {
  constructor(props){
    super(props)
    this.state = {
      email:"",
    }
    this.handleInputChange = this.handleInputChange.bind(this)
  }
  handleInputChange(e){this.setState({email : e.target.value})}
  render(){
    const rawMessage = this.props.message ? this.props.message : ""
    let error = ""
    let isOk = false
    if(rawMessage.length){
      if(rawMessage === "OK"){isOk = true}
      else{error = rawMessage}
    }
    return (
      <div className="pageLogin">
        <div className = "bodyLogin">
          <img src={process.env.PUBLIC_URL+"/assets/img/dealerbreacher.svg"} className="headerLogin"/>
          <div className="bodyFormLogin">
            {error.length===0 ? <></> :
              <div className="alert alert-danger">
                {renderError(error)}
              </div>
            }
            {isOk===true ?
              <div className="bodyform">
                <Hint text={this.props.translate("forgotPassword.doneMessage")}/>
                <Link to='/Login'>
                  <Button kind="primary">
                    <Translate id="forgotPassword.return"/>
                  </Button>
                </Link>
              </div>
              :
              <div className="bodyform">
                <TextInput
                  className="loginInput"
                  name="email"
                  labelText=""
                  required
                  id='forgotpasswordemail'
                  type="email"
                  placeholder={this.props.translate("forgotPassword.yourEmail")}
                  onChange={this.handleInputChange}
                />
                <Button
                  kind="primary"
                  className="ResetPasswordButton"
                  onClick={()=>this.props.resetPassword(this.state.email)}
                >
                  <Translate id="forgotPassword.resetPassword"/>
                </Button>
                <div className='clickable forgotPassword'>
                  <Link to='/Login'>
                    <Translate id="common.cancel"/>
                  </Link>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
export default (
  withLocalize(
    connect(mapStateToProps,mapDispatchToProps)(
      ForgotPassword
    ) as any
  )
)
