export default class BiddingStrategyTypes{
  public static TARGET_SPEND = "TARGET_SPEND";
  public static MAXIMIZE_CONVERSIONS = 'MAXIMIZE_CONVERSIONS';
  public static MAXIMIZE_CONVERSION_VALUE = 'MAXIMIZE_CONVERSION_VALUE';
  public static TARGET_IMPRESSION_SHARE = 'TARGET_IMPRESSION_SHARE';
}
export const BiddingStrategyTypesList = [
  BiddingStrategyTypes.TARGET_SPEND,
  BiddingStrategyTypes.MAXIMIZE_CONVERSIONS,
  BiddingStrategyTypes.MAXIMIZE_CONVERSION_VALUE,
  BiddingStrategyTypes.TARGET_IMPRESSION_SHARE,
]
