import {
  MakeModelsRow,
}                    from "../../../model/Store/Cleaner"
import {AdCopiesTag} from "../../../model/constant/AdCopiesTags"
import StringUtils   from "../../../utils/StringUtils"
import ArrayUtils    from "../../../utils/ArrayUtils"
import DateUtils     from "../../../utils/DateUtils"
import {ErrorData}   from "../../input/TargetLengthLimitedTextInput"
import {RSAText}     from "../../../model/adcopy"

export const getLongestMonthString = (adLanguage:string, dateFrom:Date, dateTo:Date) => {
  const dateArray = DateUtils.getDatesBetween({dateFrom,dateTo})
  const locale = adLanguage.slice(0,2).toUpperCase() === "FR" ? "fr-FR" : "en-CA"
  let _longestMonthString = ""
  // Shortest month has 28 days - at worst it checks the same month twice.
  for (let i = 0; i < dateArray.length; i += 28) {
    const date = dateArray[i]
    if (date.toLocaleString(locale, {month: 'long'}).length > _longestMonthString.length) {
      _longestMonthString = date.toLocaleString(locale, {month: 'long'})
    }
  }
  return _longestMonthString
}
export const compareLongestStringToLimit = (
  row                : MakeModelsRow,
  longestMonthString : string,
  text               : string,
  maxLength          : number
) => {
  const makeOccurrences      = StringUtils.occurrences(text.toLowerCase(), AdCopiesTag.Make.toLowerCase())
  const modelOccurrences     = StringUtils.occurrences(text.toLowerCase(), AdCopiesTag.Model.toLowerCase())
  const makeModelOccurrences = StringUtils.occurrences(text.toLowerCase(), AdCopiesTag.MakeModel.toLowerCase())
  const _monthOccurrences    = StringUtils.occurrences(text.toLowerCase(), AdCopiesTag._Month.toLowerCase())
  const monthOccurrences     = StringUtils.occurrences(text.toLowerCase(), AdCopiesTag.Month.toLowerCase())
  const yearOccurrences      = StringUtils.occurrences(text.toLowerCase(), AdCopiesTag.Year.toLowerCase())
  const priceOccurrences     = StringUtils.occurrences(text.toLowerCase(), AdCopiesTag.Price.toLowerCase())

  const stringLength = (text||"").length
  // Add based on actual strings where available
  + makeOccurrences      * row.DisplayMake.length
  + modelOccurrences     * row.DisplayModel.length
  + makeModelOccurrences * row.DisplayMakeModel.length
  + _monthOccurrences    * longestMonthString.length
  + monthOccurrences     * longestMonthString.length
  + yearOccurrences      * 4
  + priceOccurrences     * 6

  // Subtract the length of the tags that'll be replaced
  - makeOccurrences      * AdCopiesTag.Make.length
  - modelOccurrences     * AdCopiesTag.Model.length
  - makeModelOccurrences * AdCopiesTag.MakeModel.length
  - _monthOccurrences    * AdCopiesTag._Month.length
  - monthOccurrences     * AdCopiesTag.Month.length
  - yearOccurrences      * AdCopiesTag.Year.length
  - priceOccurrences     * AdCopiesTag.Price.length

  return stringLength > maxLength
}
export const divideRowsInCulledOrUnculled = (
  rows               : Array<MakeModelsRow&{inStock:boolean}>,
  text               : string,
  longestMonthString : string,
  maxLength          : number
) => {
  const culled   : Array<MakeModelsRow&{inStock:boolean}> = []
  const unculled : Array<MakeModelsRow&{inStock:boolean}> = []
  if(!rows || !rows.length){return {culled,unculled}}
  const seen = new Set<string>()
  for(const row of rows) {
    const rowText = row.DisplayMakeModel||(row.DisplayMake+" "+row.DisplayModel)
    if(seen.has(rowText)){continue}
    else{seen.add(rowText)}
    const toCull = compareLongestStringToLimit(row, longestMonthString, text, maxLength)
    if(toCull){culled.push(row)}
    else{unculled.push(row)}
  }
  return {culled,unculled}
}
export const checkErrorsInField = (
  tags       : string[],
  text       : string,
  isHeadline : boolean
) : ErrorData[] => {
  let errors : ErrorData[] = []
  const textWithoutValidTags = tags ? text.replace(new RegExp(tags.join("|"), "gi"), "") : text
  const invalidTags = textWithoutValidTags.match(/<[^<>]*>/gi)
  if(invalidTags && invalidTags.length > 0){
    errors.push({
      text : "adCopies.errorInvalidTags",
      data : {tags : ArrayUtils.unique(invalidTags).map(x=>`${x}`).join(", ")},
      id   : 'INVALID_TAGS'
    })
  }
  const textWithoutAllTags = (
    invalidTags
    ? textWithoutValidTags.replace(new RegExp(invalidTags.join("|"), "gi"), "")
    : textWithoutValidTags
  ).trim()
  const invalidChars = textWithoutAllTags.match(/[^\p{L}\s\[\]\d\-\/'’!$%+.,?_&#@^*()=<>:™®©℠€“”»«¡]/giu) ;
  if(invalidChars && invalidChars.length > 0){
    errors.push({
      text : "adCopies.errorInvalidCharacters",
      data : {characters : ArrayUtils.unique(invalidChars).map(x=>`${x}`).join(", ")},
      id   : 'INVALID_CHARACTERS'
    })
  }
  const forbiddenWords = textWithoutAllTags.match(/(jaw(\s|-)*dropping)/gi)
  if(forbiddenWords && forbiddenWords.length > 0){
    errors.push({
      text : "adCopies.errorForbiddenWords",
      data : {words : ArrayUtils.unique(forbiddenWords).map(x=>`${x}`).join(", ")},
      id   : 'FORBIDDEN_WORDS'
    })
  }
  const nonStdSpace = textWithoutAllTags.replace(/ /g, "").match(/\s/)
  if(nonStdSpace){
    errors.push({
      text: "adCopies.errorNonStandardSpace",
      data: {position: text.indexOf(nonStdSpace[0])+1},
      id:'NON_STANDARD_SPACE'
    })
  }
  if(isHeadline && /!/.test(textWithoutAllTags)){
    errors.push({
      text: "adCopies.errorHeadlineExclamation",
      id:'HEADLINE_EXCLAMATION'
    })
  }
  return errors
}

export const compareRSATextByPin = (a:RSAText, b:RSAText):number => {
  return a.position - b.position
}

export const reorderRSAText = (array:RSAText[]):RSAText[] => {
  const withoutUnpinned = array.filter(x=>x.position !== -1)
  const sorted = withoutUnpinned.sort(compareRSATextByPin)
  return sorted.concat(array.filter(x=>x.position === -1))
}

export const assignIdentifiersToRSAText = (text:RSAText[]):RSAText[] => {
  return text.map((text, i) => {
    return {...text, id: i+1}
  })
}
