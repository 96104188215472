import Reporting   from "../../utils/Reporting"
import CSVUtils    from "../../utils/CSVUtils"
import ActionUtils from "../../utils/ActionUtils"
import FVDUtils    from "../../utils/FieldsValuesDefinitionUtils"
import Actions     from "../../model/constant/actions"
import {getToken}  from "../../storeAccessor/Generic"
import {Provider}  from "../../model/Provider"

const budgetStatsFieldRenaming = {
  BudgetId      : "BudgetID",
  Date          : "Day",
  BingAccountId : "ExternalCustomerId",
  AccountId     : "ExternalCustomerId", // For Facebook stats
}
const renameHeaders = (header)=>budgetStatsFieldRenaming[header]||header

const getStatsOfBudgets = (accountId:number, accountAWId:string, accountBingId:string, accountFacebookId:string, period) => async (dispatch, getState) => {
  const token = getToken(getState())
  dispatch({
    type   : Actions.RETRIEVING_BUDGETS_STATISTICS,
    status : true
  })
  try{
    const actions = await Promise.all([
      getGoogleBudgetStatsAction(token, accountId, accountAWId, period),
      getBingBudgetStatsAction(token, accountId, accountBingId, period),
      getFacebookBudgetStatsAction(token, accountId, accountFacebookId, period),
    ])
    for(const action of actions){
      if(action){dispatch(action)}
    }
  }
  catch(e){
    console.log("General error while retrieving budget stats", e)
  }
  dispatch({
    type   : Actions.RETRIEVING_BUDGETS_STATISTICS,
    status : false
  })
}
export default getStatsOfBudgets

const getGoogleBudgetStatsAction = (token:string, accountId:number, accountAWId:string, period) => {
  return ActionUtils.retryOnFailure(()=>
    Reporting.getGoogleBudgetStats(token, accountAWId, period)
  )
  .then((response)=>{
    const stats = CSVUtils.parseCSVWithHeaders(response.data, renameHeaders)
    .map(x=>{
      x.Provider=Provider.AW
      return x
    })
    return {
      type : Actions.RETRIEVE_BUDGETS_STATS_BY_DAY,
      accountId,
      stats,
      period
    }
  })
  .catch((error)=>{console.log(error)})
}
const getBingBudgetStatsAction = (token:string, accountId:number, accountBingId:string, period) => {
  const baseAction = {
    type : Actions.RETRIEVE_BING_BUDGETS_STATS,
    accountId,
    stats : [],
    period
  }
  if(accountBingId === "0"){return baseAction}
  return ActionUtils.retryOnFailure(()=>
    Reporting.getBingBudgetStats(token, accountBingId, period)
  )
  .then((response)=>{
    baseAction.stats = CSVUtils.parseCSVWithHeaders(response.data, renameHeaders)
    .map(x=>{
      x.Provider=Provider.BING
      return x
    })
    return baseAction
  })
  .catch((error)=>{
    console.log(error)
  })
}
const getFacebookBudgetStatsAction = (token:string, accountId:number, accountFacebookId:string, period) => {
  const baseAction = {
    type : Actions.RETRIEVE_FACEBOOK_BUDGETS_STATS,
    accountId,
    stats : [],
    period
  }
  if(accountFacebookId.toString() === "0"){return baseAction}
  return ActionUtils.retryOnFailure(()=>
    Reporting.getFacebookBudgetStats(token, accountFacebookId, period)
  )
  .then((response)=>{
    baseAction.stats = CSVUtils.parseCSVWithHeaders(response.data, renameHeaders)
    .map(x=>{
      x.Provider=Provider.FACEBOOK
      return x
    })
    return baseAction
  })
  .catch((error)=>{
    console.log(error)
  })
}


const loadStatSuccess = (response, accountId, period) => {
}
const MONTHLY_BUDGET_STATS_RENAME_KEYS_MAPPING = {
  //current key : wanted key
  Cost   : "Spent"
}
export const getMonthlyStatsOfBudgets = (accountId:number, accountAWId:string) => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_MONTHLY_BUDGETS_STATISTICS,
    status : true
  })
  ActionUtils.retryOnFailure(()=>
    Reporting.getMonthlyStatsOfBudgets(getToken(getState()), accountAWId)
  )
  .then(
    (response) => {
      const fv = response.data.data
      fv.fields = fv.fields.map(field=>MONTHLY_BUDGET_STATS_RENAME_KEYS_MAPPING[field] || field)
      const stats = FVDUtils.decompress(fv)
      dispatch({
        type : Actions.RETRIEVE_MONTHLY_BUDGETS_STATS,
        accountId,
        stats
      })
    },
    (error) => console.log(error)
  ).then(()=>
    dispatch({
      type   : Actions.RETRIEVING_MONTHLY_BUDGETS_STATISTICS,
      status : false
    })
  )

}
