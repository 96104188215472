import * as React                from "react"
import {
  HashRouter,
  Route,
  Switch,
  Redirect
}                                from "react-router-dom"
import FeedsTable                from "../page/feeds"
import AccountPermissionBoundary from "../components/permissions/AccountPermissionBoundary"

export const routing = (props) => {
  return (
    <AccountPermissionBoundary
      permissions={["Keystone_ChangeStrategy"]}
    >
      <HashRouter>
        <Switch>
          <Route
            exact
            key={"*/Feeds"}
            path={"*/Feeds"}
            component={FeedsTable}
          />
          <Redirect from="/" to={"/Account/"+props.accountId+"/Feeds"}/>
        </Switch>
      </HashRouter>
    </AccountPermissionBoundary>
  )
}
