import * as React                                     from 'react'
import {
  isPreciseGuideline,
  isVagueGuideline,
  VagueGuideline,
  Guideline
}                                                     from '../../../../../../model/pacy/Guideline'
import GuidelinesDiffFormat                           from '../../GuidelineDiff/GuideLineDiffFormat/GuidelinesDiffFormat'
import { ProxyGroup }                                 from '../../../../../../model/pacy/ProxyGroup'

interface VagueGuidelineDetailsProps {
  guideline           : VagueGuideline
  concernedProxyGroup : ProxyGroup
  prevGuideline       : Guideline
}

const VagueGuidelineDetails = (props:VagueGuidelineDetailsProps) => {
  const {guideline, concernedProxyGroup} = props
  const totalWeight = concernedProxyGroup.proxies.reduce((total, proxy) => total + proxy.weight, 0)

  return (
    <div className="VagueGuidelineDetails">
    {
      concernedProxyGroup.proxies.map(proxy => {
        const prevAmount = isPreciseGuideline(props.prevGuideline)
        ? props.prevGuideline.amounts[proxy.id]
        : isVagueGuideline(props.prevGuideline) ? props.prevGuideline.amount * proxy.weight / totalWeight : null
        return (
          <div key={proxy.id}>
            <p>{proxy.name}</p>
            <GuidelinesDiffFormat amount={guideline.amount * proxy.weight / totalWeight} prevAmount={prevAmount} />
          </div>
        )
      })
    }
    </div>
  )
}

export default VagueGuidelineDetails
