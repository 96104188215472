import {translate} from "../../utils/localisation/Localisation"

export enum StandardVehicleState {
  USED,
  NEW,
}
export const StandardVehicleStateEnumReversing = (x:StandardVehicleState)=>{
  if(x===StandardVehicleState.USED){return "USED"}
  if(x===StandardVehicleState.NEW ){return "NEW" }
}
export const VehicleStateEnumReversing = (x:VehicleState)=>{
  if(x.state===StandardVehicleState.USED){return "USED"}
  if(x.state===StandardVehicleState.NEW ){return "NEW" }
}
export interface VehicleState {
  state : StandardVehicleState,
  text  : string,
}
export const KNOWN_VEHICLE_STATE : VehicleState[] = [
  {state: StandardVehicleState.USED, text:translate("VehicleStates","Used")},
  {state: StandardVehicleState.NEW,  text:translate("VehicleStates","New")},
]
export const findVehicleState = (s: string):StandardVehicleState =>{
  if(s.toLowerCase().trim().includes("used")){
    return StandardVehicleState.USED
  }
  if(s.toLowerCase().trim().includes("new")){
    return StandardVehicleState.NEW
  }
  throw Error("Unknown vehicle state. "+s+" given.")
}
