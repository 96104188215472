import * as React from "react"
import ObjectUtils                       from "../../../../utils/ObjectUtils"
import MapUtils                          from "../../../../utils/MapUtils"
import {Polygon, Marker}                 from "react-google-maps"
import {Button}                          from "carbon-components-react"
import {Map}                             from "../../../../components/Map/Map"
import PolygonWithTooltip                from "../../../../components/Map/PolygonWithTooltip"

interface ShapeMapProps{
  shapes : any[]
  editableShape ?: any
  onShapeMouseUp : (shapeId, newPaths)=>void
  pointMarker ?: PointMarker
  showPoints ?: boolean
}
interface PointMarker{
  lat : number
  lng : number
}
class ShapeMap extends React.Component<ShapeMapProps,any>{
  private map
  private polygons = {}
  constructor(props){
    super(props)
    this.map = React.createRef()
  }
  componentDidMount(){
    setTimeout(this.resetMapViewport, 500)
  }
  componentDidUpdate(prevProps){
    if((this.map && prevProps.editableShape && this.props.editableShape && prevProps.editableShape.id != this.props.editableShape.id)
        || (!prevProps.editableShape && this.props.editableShape)
    ){
      this.resetMapViewport()
    }
  }
  resetMapViewport(){
    let bounds = new google.maps.LatLngBounds()
    let foundBounds = false
    for(let entityRef of ObjectUtils.getObjectValues(this.polygons)){
      const entity = entityRef.current
      if(!entity){continue}
      bounds.union(MapUtils.getBoundsOfPolygon(entity))
      foundBounds = true
    }
    if(foundBounds){this.map.current.fitBounds(bounds)}
  }
  onPolygonMouseUp(shapeId){
    const rawPaths = this.polygons[shapeId].current.getPaths().getArray()
    const paths = rawPaths.map(path=>
      path.getArray().map(latlng=>({
        lat : latlng.lat(),
        lng : latlng.lng()
    })))
    this.props.onShapeMouseUp(shapeId, paths)
  }
  render(){
    if(this.props.editableShape){this.polygons[this.props.editableShape.id] = React.createRef()}
    const googleMapProps = {
      ref : this.map,
      options : {
        gestureHandling: "greedy", //So holding Ctrl isn't required to zoom with scrollwheel
      },
    }
    return (
      <div className="ShapeMap">
        <Map
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: "400px",}} />}
          mapElement={<div style={{ height: '100%' }} />}
          googleMapProps={googleMapProps}
        >
          {this.props.shapes.map(x=>{
            return (
              <PolygonWithTooltip
                tooltipContent={x.name}
                key={x.id}
                paths={x.polygons}
                editable={false}
                options={{
                  strokeColor:"blue",
                  strokeWeight:1
                }}
              />
          )})}
          {this.props.editableShape &&
            <Polygon
              key={this.props.editableShape.id}
              ref={this.polygons[this.props.editableShape.id]}
              paths={this.props.editableShape.polygons}
              editable={this.props.showPoints?this.props.showPoints:false}
              onMouseUp={()=>this.onPolygonMouseUp(this.props.editableShape.id)}
              options={{
                strokeColor:"red",
                strokeWeight:1
              }}
            />
          }
          {this.props.pointMarker
            ? <Marker
                position={{
                  lat : this.props.pointMarker.lat,
                  lng : this.props.pointMarker.lng
                }}
              />
            : <></>
          }
        </Map>
      </div>
    )
  }
}

export default ShapeMap
