import MathUtils from "../MathUtils"
import StringUtils from "../StringUtils"

export default class BidModifierFormatter{
  public static formatBidModifierToString(bidModifier:number):string{
    return (bidModifier>=1?"+":"")+MathUtils.round((bidModifier-1)*100,2)+"%"
  }
  public static reverseFormatBidModifier(bidModifier:string):number{
    const isPositive = bidModifier.indexOf("-")===-1
    const _ = ["%","-","+"].map(r=>bidModifier=StringUtils.removeOccurences(bidModifier,r))
    return MathUtils.round((isPositive?1:-1)*parseFloat(bidModifier)/100+1,2)
  }
}
