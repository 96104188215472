import "./AccountSelection.css"
import * as React        from "react"
import keydown           from 'react-keydown'
import ArrayUtils        from "../../../../../utils/ArrayUtils"
import StringUtils       from "../../../../../utils/StringUtils"
import Account           from "../../../../../model/Store/Account/Account"
import {Translate}       from "react-localize-redux"
import {
  FixedSizeList as List,
  ListItemKeySelector
}                        from "react-window"

interface AccountSelectionProps{
  onAccountSelection : (accountId:number)=>void
  accounts           : Account[]
}
interface SimpleAccount{
  id : number
  name : string
  simplifiedName : string
}
interface AccountSelectionState{
  targetedRowIndex : number
  simpleAccounts   : SimpleAccount[]
  filter           : string
}
const saintRegex = /(-|\s|^)st(e?)(-|\s|$)/gi
class AccountSelection extends React.Component<AccountSelectionProps,AccountSelectionState>{

  private filteredAccounts

  constructor(props){
    super(props)
    this.state = {
      targetedRowIndex : 0,
      filter : "",
      simpleAccounts : this.simplifyAccounts()
    }
    this.targetHigherRow = this.targetHigherRow.bind(this)
    this.targetLowerRow = this.targetLowerRow.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.filterAccounts = this.filterAccounts.bind(this)
  }
  componentDidMount(){
    const input = document.querySelectorAll(".AccountSelection .AccountsInput")[0]
    if(input && input instanceof HTMLElement){
      input.focus()
      input.addEventListener("keydown",((e:KeyboardEvent)=>{
        // 13 == enter
        if(e.key === "Enter"){
          let accountId
          for(let i = this.state.targetedRowIndex; i >= 0; i--){
            if(this.filteredAccounts[i]){
              accountId = this.filteredAccounts[i].id
              break
            }
          }
          if(accountId){
            this.props.onAccountSelection(accountId)
          }
        }
        else if(e.key === "ArrowDown"){this.targetLowerRow()}
        else if(e.key === "ArrowUp"){this.targetHigherRow()}
        else{
          //reselect the first account when typing something
          this.setState({
            targetedRowIndex : 0,
          })
        }
      }).bind(this))
    }
  }
  componentDidUpdate(prevProps:AccountSelectionProps){
    if(prevProps.accounts !== this.props.accounts){
      this.setState({simpleAccounts: this.simplifyAccounts()})
    }
  }
  @keydown('up')
  targetHigherRow(){
    if(this.state.targetedRowIndex > 0){
      this.setState(prevState=>({
        targetedRowIndex : prevState.targetedRowIndex-1,
      }))
    }
  }
  @keydown('down')
  targetLowerRow(){
    if(this.state.targetedRowIndex < 4){
      this.setState(prevState=>({
        targetedRowIndex : prevState.targetedRowIndex+1,
      }))
    }
  }
  handleInputChange(e){
    this.setState({filter: e.target.value})
  }
  simplifyAccounts():SimpleAccount[]{
    return this.props.accounts.map(x=>{
      return {
        id : x.id,
        name : x.name,
        simplifiedName : StringUtils.deaccent(x.name).toLowerCase().replace(saintRegex, "$1saint$2$3")+" "+x.id
      }
    })
  }
  filterAccounts(){
    const filter = StringUtils.deaccent(this.state.filter).toLowerCase().replace(saintRegex, "$1saint$2$3")
    const filterParts = filter.split(/\s/g)

    this.filteredAccounts = this.state.simpleAccounts
    .filter(x=>{
      if(x.simplifiedName.includes(filter)){return true}
      if(filterParts.every((part)=>x.simplifiedName.includes(part))){return true}
      return false
    })
    .sort((a,b)=>ArrayUtils.comp(a.simplifiedName, b.simplifiedName))
  }
  render(){
    this.filterAccounts()
    return (
      <div className="AccountSelection">
        <div className="InputWrapper">
          <input
            type="text"
            className="AccountsInput"
            placeholder="Enter ..."
            value={this.state.filter}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="AccountsList">
          {this.renderAccounts()}
        </div>
      </div>
    )
  }
  renderAccounts(){
    if(this.filteredAccounts.length===0){
      return (
        <div className="Account NoAccount">
          <Translate id="menu.accountsModal.noAccount"/>
        </div>
      )
    }
    const itemKeyFunc:ListItemKeySelector = (index, data)=>data.accounts[index].id
    return (
      <List
        height={269}
        itemCount={this.filteredAccounts.length}
        itemSize={53}
        width={404}
        itemData={{
          accounts   : this.filteredAccounts,
          select     : this.props.onAccountSelection,
          isSelected : (index:number) => this.state.targetedRowIndex === index
        }}
        itemKey={itemKeyFunc}
      >
        {AccountRow}
      </List>
    )
  }
}

interface AccountRowProps{
  index : number
  style : object
  data : {
    accounts   : Account[],
    select     : (accountId:number)=>void
    isSelected : (index:number)=>boolean
  }
}
class AccountRow extends React.PureComponent<AccountRowProps>{
  render(){
    const {index, style, data} = this.props
    const account = data.accounts[index]
    const onClick = () => data.select(account.id)
    const selected = data.isSelected(index)
    const className = "Account"+(selected?" Selected":"")

    return (
      <div
        style={style}
        className={className}
        onClick={onClick}
      >
        {account.name}
      </div>
    )
  }
}

export default AccountSelection
