import styles                          from './EditorStateManagerStyles'
import * as React                      from 'react'
import AdCopyEditor                    from './Editor'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
}                                      from '@material-ui/core'
import {
  withLocalize,
  LocalizeContextProps
}                                      from 'react-localize-redux'
import { DragDropContext }             from 'react-dnd'
import HTML5Backend                    from 'react-dnd-html5-backend'
import {
  AdCopyParents,
  AdCopyRSATemplates,
  AdgroupsToCopy,
  INVENTORY_GROUPS
}                                      from '../../../model/adcopy'
import {
  MarketingEvent,
  RSAAdTemplate,
  AdCopyStringLengths,
  NonInventoryMarketingApproach,
  NonInventoryMarketingEventBoundaries
}                                      from '../../../model/adbuilder'
import {ADBUILDER_TAGS}                from '../../../model/constant/AdCopiesTags'
import calculateAdCopyTextLength       from '../utils/FindAdCopyLength'
import {checkErrorsInField}            from '../../../components/adCopy/utils'
import {ErrorData}                     from "../../../components/input/LengthLimitedTextInput"
import {HeadstoneActiveStrategies}     from "../../../model/Store/Strategy/Strategy"

interface EditorStateManagerProps extends LocalizeContextProps {
  event                     : MarketingEvent
  handleCancel              : () => void
  handleBackToHomepage      : () => void
  saveEvent                 : () => void
  stringLengths             : AdCopyStringLengths
  readOnly                 ?: boolean
  forceDisableInteractions ?: boolean
  activeStrategies          : HeadstoneActiveStrategies
}

const EditorStateManager = (props:EditorStateManagerProps) => {
  const isInventory = INVENTORY_GROUPS.includes(props.event.rsaApproaches[0].group)
  const campaignType = !isInventory 
    ? (props.event.boundaries[0] as NonInventoryMarketingEventBoundaries).campaignType
    : null
  // State
  // represents VDP, MM or MMY for inventory, campaign name for credit/service and adgroup name for branding
  const [firstDropdown, setFirstDropdown] = React.useState<string>(props.event.rsaApproaches[0].group)
  // represents New Visitors or RLSA for inventory and adgroup for non inventory
  const [secondDropdown, setSecondDropdown] = React.useState<string>(
    isInventory || campaignType === "BRANDING"
      ? "New visitors"
      : (props.event.rsaApproaches[0] as NonInventoryMarketingApproach).adGroupName
  )
  const [lang, setLang] = React.useState<string>(
    isInventory
    ? props.event.rsaApproaches.find(x=>x.group===props.event.rsaApproaches[0].group && x.audienceAWId===undefined).lang
    : props.event.rsaApproaches[0].lang
  )
  const [adgroupsToCopy, setAdgroupsToCopy] = React.useState<AdgroupsToCopy>({})

  const provideTemplates = (dropDown:string, secondDropdown:string, newLang:string):RSAAdTemplate[] => {
    const selectedTemplates = props.event.rsaApproaches.find(approach=>
      approach.group === dropDown
        && ((isInventory || campaignType === "BRANDING") && secondDropdown === "New visitors"
          ? approach.audienceAWId === undefined
          : approach.audienceAWId !== undefined
        || (approach as NonInventoryMarketingApproach).adGroupName === secondDropdown)       
        && approach.lang === newLang
    )?.patterns
    return selectedTemplates ?? []
  }

  const [templates,setTemplates] = React.useState<RSAAdTemplate[]>(provideTemplates(firstDropdown,secondDropdown,lang))

  let availableOptions:AdCopyParents = {}
  // inventory options
  if (isInventory || campaignType === "BRANDING"){
    availableOptions = props.event.rsaApproaches.reduce((options, approach)=>{
      if (approach.audienceAWId === undefined){
        return {
          ...options,
          [approach.group] : {
            ...options[approach.group],
            ["New visitors"] : [...options?.[approach.group]?.["New visitors"]??[], approach.lang]
          }
        }
      }
      if (approach.audienceAWId !== undefined){
        return {
          ...options,
          [approach.group] : {
            ...options[approach.group],
            ["RLSA"] : [...options?.[approach.group]?.["RLSA"]??[], approach.lang]
          }
        }
      }
    },{})
  } 
  // other campaign types options
  else {
    const strategy = Object.keys(props.activeStrategies).find(strategy=>strategy.toUpperCase() === campaignType)
    availableOptions = props.activeStrategies[strategy]
  }

  const handleStrategyChange = (value:string) => {
    setFirstDropdown(value)
    const approach = props.event.rsaApproaches.find(approach=>approach.group === value)
    const nextAdgroup = isInventory || campaignType === "BRANDING"
      ? "New visitors"
      : (approach as NonInventoryMarketingApproach).adGroupName
    setSecondDropdown(nextAdgroup)
    const nextLang = approach.lang
    setLang(nextLang)
    setTemplates(provideTemplates(value, nextAdgroup, nextLang))
  }

  const handleAdgroupChange = (value:string) => {
    setSecondDropdown(value)
    const nextLang = props.event.rsaApproaches.find(approach=>
      approach.group === firstDropdown
        && ((isInventory || campaignType === "BRANDING") && secondDropdown === "New visitors"
          ? approach.audienceAWId === undefined
          : approach.audienceAWId !== undefined
        || (approach as NonInventoryMarketingApproach).adGroupName===value)
    ).lang
    setLang(nextLang)
    setTemplates(provideTemplates(firstDropdown, value, nextLang))
  }

  const handleLangChange = (value:string) => {
    setLang(value)
    setTemplates(provideTemplates(firstDropdown, secondDropdown, value))
  }

  const handleSave = (templatesToSave:AdCopyRSATemplates[]) => {
    const oldApproach = props.event.rsaApproaches.find(approach=>
      approach.group === firstDropdown
        && ((isInventory || campaignType === "BRANDING") && secondDropdown === "New visitors"
          ? approach.audienceAWId === undefined
          : approach.audienceAWId !== undefined
        || (approach as NonInventoryMarketingApproach).adGroupName === secondDropdown)       
        && approach.lang === lang
    )
    // Trimming the empty Headlines & Descriptions is the only way to get rid of them in the UI
    templatesToSave.forEach(template=>{
      template.headlines = template.headlines.filter(x=>x.text !== "")
      template.descriptions = template.descriptions.filter(x=>x.text !== "")
    })
    oldApproach.patterns = templatesToSave as any // Typescript drunk, like wise men once said

    // Handling copying current templates into other adgroups
    if (Object.keys(adgroupsToCopy).length > 0){
      Object.keys(adgroupsToCopy).forEach(strategyToCopy=>{
        Object.keys(adgroupsToCopy[strategyToCopy]).forEach(adgroupToCopy=>{
          if (adgroupsToCopy[strategyToCopy][adgroupToCopy]){
            const approachToReplace = props.event.rsaApproaches.find(approach=>
              approach.group === strategyToCopy
                && (adgroupToCopy === "RLSA" ? approach.audienceAWId  !==  undefined : approach.audienceAWId === undefined)
                && approach.lang === lang
            )
            if (approachToReplace){
              approachToReplace.patterns = templatesToSave as any 
            }
          }
        })
      })
    }
    props.saveEvent()
  }

  const handleSaveAndClose = (templatesToSave:AdCopyRSATemplates[]) => {
    handleSave(templatesToSave)
    props.handleBackToHomepage()
  }

  // 4th argument is to recreate the structure from scratch if user wants to copy all adgroups. We don't build a default
  // state in this component as opposed to its child that does for checkboxes purposes.
  const handleAdgroupsToCopy = (value:boolean, strategyToCopy:string, adgroupToCopy:string, allAdgroupsOptions?:{[adgroup:string]:boolean}) => {
    if (secondDropdown === "All adgroups"){
      setAdgroupsToCopy(prev=>{
        return {
          ...prev,
          [strategyToCopy] : {...allAdgroupsOptions}
        }
      })
    } else {
      setAdgroupsToCopy(prev=>{
        return {
          ...prev,
          [strategyToCopy] : {
            ...prev[strategyToCopy], 
            [adgroupToCopy] : value
          }
        }
      })    
    }
  }
  const provideAvailableCopyOptions = ():AdCopyParents => {
    // Goal is to remove the currently selected adgroup (in the currently selected strategy/campaign)
    // from the pool of available options to copy to
    // *Deep cloning because we are mutating the object
    const filteredOptions = JSON.parse(JSON.stringify(availableOptions))
    delete filteredOptions[firstDropdown][secondDropdown]
    return filteredOptions
  }

  const validateTemplates = ():ErrorData[] => {
    const findAdCopyLength = calculateAdCopyTextLength(props.stringLengths)
    const errorData:ErrorData[] = []
    const approaches = [...props.event.rsaApproaches]

    approaches.forEach(approach=>{
      for(const template of approach.patterns){
        const tags = isInventory ? ADBUILDER_TAGS[approach.group] : ADBUILDER_TAGS[campaignType]
        if (template.headlines.filter(headline => headline.text !== "").length<3 
          || template.descriptions.filter(description => description.text !== "").length<2
        ){
          errorData.push({text:"adCopies.errorTextNumber", id:''})
        }
        for (const headline of template.headlines){
          length = findAdCopyLength(headline.text || "")
          if (length > 30){
            errorData.push({text:"adBuilder.createEvent.errors.adcopies", id:''})
          }
          errorData.push(...checkErrorsInField(tags, headline.text,true))
        }
        for (const description of template.descriptions){
          length = findAdCopyLength(description.text || "")
          if (length > 90){
            errorData.push({text:"adBuilder.createEvent.errors.adcopies", id:''})
          }
          errorData.push(...checkErrorsInField(tags, description.text, false))
        }
      }

    })
    return errorData
  }
  
  const [errors, setErrors] = React.useState<ErrorData[]>(validateTemplates())
  const checkForErrors = () => {
    setErrors(validateTemplates())
  }
  const provideCampaignSelectLabel = () => {
    if (isInventory) return props.translate("adCopies.strategy")
    if (campaignType==="BRANDING") return props.translate("adCopies.adGroup")
    return props.translate("adCopies.campaignName")
  }
  const classes = styles()
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.label}>
            {provideCampaignSelectLabel()}
          </InputLabel>
          <Select
            className={classes.selects}
            value={firstDropdown}
            onChange={(e)=>handleStrategyChange(e.target.value as string)}
          >
            {Object.keys(availableOptions).map(strategy=>{
              return <MenuItem key={strategy} value={strategy}>{strategy}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.label}>
            {campaignType==="BRANDING"
              ? props.translate("adCopies.audience")
              : props.translate("adCopies.adGroup")
            }
          </InputLabel>
          <Select
            className={classes.selects}
            value={secondDropdown}
            onChange={(e)=>handleAdgroupChange(e.target.value as string)}
          >
            {Object.keys(availableOptions?.[firstDropdown] ??[]).map(adgroup=>{
              return <MenuItem key={adgroup} value={adgroup}>{adgroup}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.label}>{props.translate("adCopies.language")}</InputLabel>
          <Select
            className={classes.selects}
            value={lang}
            onChange={(e)=>handleLangChange(e.target.value as string)}
          >
            {(availableOptions?.[firstDropdown]?.[secondDropdown] ?? []).map(x=>{
              return <MenuItem key={x} value={x}>{x}</MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
    <div className={classes.editing}>
      <AdCopyEditor
        templates={templates}
        tags={
          isInventory 
            ? ADBUILDER_TAGS[firstDropdown] 
            : ADBUILDER_TAGS[campaignType]}
        lang={lang}
        availableCopyOptions={provideAvailableCopyOptions()}
        handleCancel={props.handleCancel}
        saveFunction={handleSave}
        saveAndClose={handleSaveAndClose}
        adgroupsToCopy={handleAdgroupsToCopy}
        readOnly={props.readOnly}
        inventoryGroup={firstDropdown}
        errorsInTemplates={errors}
        checkForErrors={checkForErrors}
        resetAdgroupsToCopy={() => setAdgroupsToCopy({})}
      />
    </div>
  </div>
  )
}

export default withLocalize(
  DragDropContext(HTML5Backend)(EditorStateManager)
)
