import { createMuiTheme }           from '@material-ui/core/styles'
import generateTheme, {ThemeParams} from './GenerateTheme'

const PARAMS:ThemeParams = {
  primary   : "#dc0505",
  secondary : "#1F1F1F",
}

const theme = createMuiTheme(generateTheme(PARAMS))

export default theme
