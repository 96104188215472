import './InventoryTesterRSA.css'
import * as React                      from "react"
import {connect}                       from "react-redux"
import {Translate}                     from "react-localize-redux"
import {dictionary}                    from "../../utils/localisation/Dictionary"
import NumFormatter                    from "../../utils/NumberFormatter"
import DateUtils                       from "../../utils/DateUtils"
import MathUtils                       from "../../utils/MathUtils"
import ArrayUtils                      from "../../utils/ArrayUtils"
import {AdCopyRSATemplates}            from "../../model/adcopy"
import {RSAAdTemplate}                 from "../../model/adbuilder"
import ExplainedLoading                from "../loading"
import Ad                              from "./AdCopy"
import {
  FixedSizeList as List,
}                                      from "react-window"

interface InventoryTesterOwnProps{
  adCopy  : AdCopyRSATemplates|RSAAdTemplate
  maxChar : MaxChar
  lang    : string
  state   : string
}
interface InventoryTesterProps extends InventoryTesterOwnProps{
  inventory : any[]
}
interface MaxChar{
  headlines    : number
  descriptions : number
}

const VALID_STATES = ["NEW","USED","CONQUEST"]
const mapStateToProps = (state, ownProps:InventoryTesterOwnProps)=>{
  const accountId = state.Accounts.selected
  return {
    inventory : state.Inventory.list[accountId],
    state : ArrayUtils.contain(VALID_STATES, ownProps.state.toUpperCase())?ownProps.state.toUpperCase():undefined
  }
}
const mapDispatchToProps = (_dispatch)=>{
  return {}
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
const currentMonth = (DateUtils.leftPadMonth((new Date()).getUTCMonth()+1))
const punctuationRegExp = new RegExp(/[\.\?\!]/)
const TAGS_TO_VALUE_EXTRACTOR = {
  "<MONTH>"               : (_vehicle,lang)=>dictionary["Months"]["M"+currentMonth][lang.toUpperCase()==="EN"?0:1],
  "<_MONTH>"              : (vehicle,lang,index,text)=>{
    const month = TAGS_TO_VALUE_EXTRACTOR["<MONTH>"](vehicle,lang)
    lang = lang.toUpperCase()
    if(lang==="EN"){return month}
    if(lang==="FR" && (index===0 || punctuationRegExp.test(text.substring(0, index).trim().slice(-1)))){return month}
    return month.toLowerCase()
  },
  "<YEAR>"                : (vehicle)=>vehicle.year,
  "<MAKE>"                : (vehicle)=>vehicle.make,
  "<MODEL>"               : (vehicle)=>vehicle.model,
  "<MAKEMODEL>"           : (vehicle)=>vehicle.displayMakeModel?vehicle.displayMakeModel:vehicle.make+" "+vehicle.model,
  "<PRICE>"               : (vehicle)=>vehicle.price,
  "<COUNT>"               : (_vehicle)=>MathUtils.round((Math.random()*5+10),0), //NOT GOOD
  //"<CITY>"                : (vehicle)=>vehicle,
  //"<NAME>"                : (vehicle)=>vehicle,
  //"<CREDITTYPE>"          : (vehicle)=>vehicle,
  //"<VEHICLESUBTYPE>"      : (vehicle)=>vehicle,

  "<CONQUERINGMAKEMODEL>" : (vehicle)=>vehicle.displayMakeModel?vehicle.displayMakeModel:vehicle.make+" "+vehicle.model,
  "<CONQUERINGMAKE>"      : (vehicle)=>vehicle.make,
  "<CONQUERINGMODEL>"     : (vehicle)=>vehicle.model,
  "<CONQUERINGYEAR>"      : (vehicle)=>vehicle.year,
  //"<SEARCHEDMAKEMODEL>"   : (vehicle)=>vehicle,
  //"<SEARCHEDMAKE>"        : (vehicle)=>vehicle,
  //"<SEARCHEDMODEL>"       : (vehicle)=>vehicle,
  //"<SEARCHEDYEAR>"        : (vehicle)=>vehicle,
}
class InventoryTester extends React.Component<InventoryTesterProps,any>{
  constructor(props){
    super(props)
    this.replaceTags = this.replaceTags.bind(this)
  }
  replaceTags(text:string, vehicle:any):string{
    return text.replace(/\<.*?\>/g, (match, index, text)=>{
      const func = TAGS_TO_VALUE_EXTRACTOR[match.toUpperCase()]
      if(func === undefined){return match}
      return func(vehicle, this.props.lang, index, text)
    })
  }
  render(){
    if(this.props.inventory === undefined){return <ExplainedLoading translateId="loadings.inventory"/>}
    const adCopies = this.props.inventory
    .filter(x=>{
      if(!x.current || !x.passedRules){return false}
      if(this.props.state === undefined){return true}
      if(this.props.state === "USED"){return x.state.toUpperCase() === "USED"}
      return x.state.toUpperCase() === "NEW"
    })
    .reduce((sorted, vehicle)=>{
      const headlines = this.props.adCopy.headlines.map(x=>this.replaceTags(x.text, vehicle))
      const descriptions = this.props.adCopy.descriptions.map(x=>this.replaceTags(x.text, vehicle))
      const ad = {
        id          : vehicle.id,
        headlines,
        descriptions,
        overLimit : headlines.some(x=>x.length > this.props.maxChar.headlines) ||
                    descriptions.some(x=>x.length > this.props.maxChar.descriptions)
      }
      if(ad.overLimit){sorted.invalid.push(ad)}
      else{sorted.valid.push(ad)}
      return sorted
    },{valid:[],invalid:[]})

    return (
      <div className="InventoryTester">
        <div className="Valids">
          <h4>
            <Translate id="adCopies.numberValid" data={{value:NumFormatter.formatNumber(adCopies.valid.length)}}/>
          </h4>
          <div className="AdCopyPatternList">
            <List
              height={375}
              itemCount={adCopies.valid.length}
              itemSize={125}
              width={626}
              itemData={{
                data   : adCopies.valid,
              }}
              itemKey={(index, data)=>data.data[index].id}
            >
              {AdRow}
            </List>
          </div>
        </div>
        <div className="Invalids">
          <h4>{
            adCopies.invalid.length > 0
            ? <Translate id="adCopies.numberInvalid" data={{value:NumFormatter.formatNumber(adCopies.invalid.length)}}/>
            : <Translate id="adCopies.allGood"/>
          }</h4>
          <div className="AdCopyPatternList">
            <List
              height={375}
              itemCount={adCopies.invalid.length}
              itemSize={125}
              width={626}
              itemData={{
                data : adCopies.invalid
              }}
              itemKey={(index, data)=>data.data[index].id}
            >
              {AdRow}
            </List>
          </div>
        </div>
      </div>
    )
  }
}
interface AdRowProps{
  index : number
  style : object
  data : {
    data : Array<{
      id           : string
      headlines    : string[]
      descriptions : string[]
      overLimit    : boolean
    }>,
  }
}
class AdRow extends React.PureComponent<AdRowProps>{
  render(){
    const {index, style, data} = this.props
    const ad = data.data[index]
    return (
      <div
        key={ad.id}
        style={{
          ...style,
          marginBottom: "15px"
        }}
        className="SingleAdCopy"
      >
        <Ad
          headlines={ad.headlines}
          descriptions={ad.descriptions}
          url={"www.google.com"}
          status=""
        />
      </div>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(InventoryTester)
