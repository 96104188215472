import * as React       from 'react'
import {Modal}          from "carbon-components-react"
import {Button}         from "carbon-components-react"

interface TokenModalProps{
  shown : boolean
  token : string
  close : ()=>void
}

class TokenModal extends React.Component<TokenModalProps,any>{
  constructor(props: TokenModalProps){
    super(props)
  }
  componentDidUpdate(prevProps: TokenModalProps){
    if(!prevProps.shown && this.props.shown){
      const input = document.querySelectorAll("#userToken")[0]
      if(input && input instanceof HTMLInputElement){
        input.focus()
        input.select()
      }
    }
  }
  render(){
    return (
      <Modal
        open={this.props.shown}
        passiveModal
        modalHeading="DealerBreacher Token"
        onRequestClose={this.props.close}
      >
        <input
          style={{width:'100%', padding:'5px', marginBottom:'15px', fontSize:'13px'}}
          defaultValue={this.props.token}
          id="userToken"
          readOnly
        />
        <Button onClick={this.props.close}>Ok</Button>
      </Modal>
    )
  }
}

export default TokenModal
