import {createSelector}       from "reselect"
import ArrayUtils             from "../utils/ArrayUtils"
import Status                 from "../model/constant/status"
import * as Generic           from "./Generic"

export default class StatusExceptionAccessors {
  public static makeStatusExceptionForCampaignsSelector(){
    return createSelector(
      Generic.getStatusExceptions,
      Generic.getAccountId,
      (_,props)=>props.campaigns,
      (exceptions, accountId, campaigns)=>{
        const campaignNames = campaigns.map(x=>x.name)
        let statusException = []
        let statusExceptionType = ""
        if(exceptions[accountId]){
          if(exceptions[accountId].Paused){
            statusException = exceptions[accountId].Paused.filter(name=>ArrayUtils.contain(campaignNames,name))
            if(statusException.length > 0 && statusException.length == campaignNames.length){
              statusExceptionType = Status.PAUSED
            }
          }
          if(exceptions[accountId].Enabled){
            const findStatus = exceptions[accountId].Enabled.filter(name=>ArrayUtils.contain(campaignNames,name))
            statusException = statusException.concat(findStatus)
            if(findStatus.length > 0 && statusException.length == campaignNames.length){
              statusExceptionType = Status.ENABLED
            }
            else if(findStatus.length>0){
              statusExceptionType = "Campaigns have different exceptions"
            }
          }
        }
        return{
          statusException     : statusException,
          statusExceptionType : statusExceptionType
        }
      }
    )
  }
  public static getStatusExceptionForFriend(state,friend){
    let accountId = friend.getAccountId()
    let campaignNames = friend.getCampaignNames()
    let statusException = []
    let statusExceptionType = ""
    if (state.Campaigns.DesiredStatusExceptions[accountId]){
      if (state.Campaigns.DesiredStatusExceptions[accountId].Paused ){
        statusException = state.Campaigns.DesiredStatusExceptions[accountId].Paused.filter(name => ArrayUtils.contain(campaignNames,name))
        if (statusException.length > 0 && statusException.length == campaignNames.length){
          statusExceptionType = Status.PAUSED
        }
      }
      if (state.Campaigns.DesiredStatusExceptions[accountId].Enabled){
        let findStatus = state.Campaigns.DesiredStatusExceptions[accountId].Enabled.filter(name => ArrayUtils.contain(campaignNames,name))
        statusException = statusException.concat(findStatus)
        if (findStatus.length > 0 && statusException.length == campaignNames.length){
          statusExceptionType = Status.ENABLED
        }
        else if (findStatus.length>0){
          statusExceptionType = "Friend Dont Have Same StatusException"
        }
      }
    }
    return{
      statusException     : statusException,
      statusExceptionType : statusExceptionType
    }
  }

}
