import {createSelector}       from "reselect"
import ArrayUtils             from "./ArrayUtils"
import DateUtils              from "./DateUtils"
import Period                 from "../model/Period"
import MONTHS                 from "../model/constant/MonthsAbbreviation"

export default class QueryStringUtils{
  private static periodInURLSelector = createSelector(
    ()=>window.location.search,
    (query)=>{
      const dateRegex = new RegExp(/\d{4}-[A-Z]{3}-\d{1,2}/ig)
      const result = query.match(dateRegex)
      if(result != null && result.length >= 2){
        let dates = []
        for(let dateString of result){
          if(dates.length === 2){break}
          const parts = dateString.split("-")
          const monthIndex = DateUtils.findMonthIndexFromAbbreviation(parts[1])
          if(monthIndex === null){continue}
          const day = parseInt(parts[2])
          if(day > 31){continue}
          const year = parseInt(parts[0])
          dates.push(new Date(year, monthIndex, day))
        }
        if(dates.length === 2){
          dates.sort((a,b)=>a-b)
          return {
            dateFrom:dates[0],
            dateTo:dates[1]
          }
        }
      }
      return null
    }
  )
  public static getPeriod():Period|null{
    return QueryStringUtils.periodInURLSelector({})
  }
  public static setPeriod(period:Period){
    const periodString = QueryStringUtils.dateToURLFormat(period.dateFrom)+"/"+QueryStringUtils.dateToURLFormat(period.dateTo)
    window.history.replaceState("", "", "?"+periodString+window.location.hash)
  }
  private static dateToURLFormat(date:Date):string{
    const availableLangs = ["en","fr"]
    let langIndex = 0
    for(let lang of navigator.languages){
      const shortLang = lang.substring(0,2).toLowerCase()
      const index = availableLangs.indexOf(shortLang)
      if(index > -1){
        langIndex = index
        break
      }
    }
    let month = MONTHS[date.getMonth()][langIndex]
    month = month[0].toUpperCase()+month.slice(1)
    return [
      date.getFullYear(),
      month,
      date.getDate()
    ].join("-")
  }
}
