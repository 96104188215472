import * as React                 from "react"
import {connect}                  from "react-redux"
import { retrieveAvailableFeeds } from "../../actions/feeds/Feeds"
import ExplainedLoading           from "../loading"
import ErrorBoundary              from "../Error/ErrorBoundary"

const mapStateToProps = (state,ownProps) => {
  const accountId = state.Accounts.selected
  return {
    accountId,
    retrievingAvailable : state.Feeds.AvailableFeedsRetrieving,
    availableFeeds      : state.Feeds.AvailableFeeds[accountId],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadAvailableFeeds : (accountId: number) => dispatch(retrieveAvailableFeeds(accountId))
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    loadAvailableFeeds : () => DP.loadAvailableFeeds(SP.accountId),
  }
}
const AvailableFeedsLoader = props => {
  let loading = false
  if(props.availableFeeds === undefined || props.retrievingAvailable){
    loading = true
    if(!props.retrievingAvailable){props.loadAvailableFeeds()}
    if(!props.letThrough){
      return <ExplainedLoading translateId="loadings.availableFeeds"/>
    }
  }
  return <ErrorBoundary>{props.render(props.availableFeeds, loading)}</ErrorBoundary>
}
const AvailableFeedsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(AvailableFeedsLoader)

const requiresAvailableFeeds = (letThrough?:boolean) => Component => props => (
  <AvailableFeedsLoaderConnected
    letThrough={letThrough || false}
    render={ (availableFeeds, loadingAvailable) => (
      <Component
        availableFeeds={availableFeeds}
        loadingAvailableFeeds={loadingAvailable}
        {...props}
      />
    )}
  />
)

export default requiresAvailableFeeds
