import "./index.css"
import * as React       from "react"
import {ProxyGroup}     from "../../../../../model/pacy/ProxyGroup"
import {StringMap}      from "../../../../../model/generics"
import Collapse         from "@material-ui/core/Collapse"
import Toggle           from "./Toggle"
import SetupTable       from "./SetupTable"

interface PreciseGuidelineSetupProps{
  proxyGroup    : ProxyGroup
  color         : string
  shownMonths   : string[]
  setGuideline  : (year:string, month:string, amount:number|StringMap<number>)=>void
  defaultOpen  ?: boolean
  onOpenChange ?: (opened:boolean)=>void
  readOnly     ?: boolean
}
interface PreciseGuidelineSetupState{
  opened : boolean
}

class PreciseGuidelineSetup extends React.Component<PreciseGuidelineSetupProps,PreciseGuidelineSetupState>{
  static defaultProps = {
    defaultOpen : false,
    onOpenChange : ()=>{}
  }
  constructor(props:PreciseGuidelineSetupProps){
    super(props)
    this.state = {
      opened : this.props.defaultOpen
    }
    this.toggle = this.toggle.bind(this)
  }
  componentDidUpdate(prevProps:PreciseGuidelineSetupProps, prevState:PreciseGuidelineSetupState){
    if(this.state.opened !== prevState.opened){this.props.onOpenChange(this.state.opened)}
  }
  toggle(){
    this.setState(prevState=>({opened: !prevState.opened}))
  }
  render(){
    const months = this.props.shownMonths.slice(2)
    const toggleText = this.state.opened ? "pacyPlanner.closePreciseToggle" : "pacyPlanner.openPreciseToggle"
    return (
      <div className="PreciseGuidelineSetup">
          <Collapse in={this.state.opened} timeout={"auto"}>
            <SetupTable
              proxyGroup={this.props.proxyGroup}
              shownMonths={months}
              color={this.props.color}
              setGuideline={this.props.setGuideline}
              readOnly={this.props.readOnly}
            />
          </Collapse>
          <Toggle text={toggleText} onClick={this.toggle} color={this.props.color} translate/>
      </div>
    )
  }
}

export default PreciseGuidelineSetup
