import {createSelector}                 from 'reselect'
import ReportTableTemplate              from "../model/Store/Report/ReportTableTemplate"
import ReportTableTemplateContexts      from "../model/constant/ReportTableTemplateContexts"
import * as Generic                     from "./Generic"
import {Provider}                       from "../model/Provider"

export default class TemplatesAccessors{
  public static makeTemplatesSelector(provider:Provider){
    return createSelector(
      Generic.getTemplates,
      templates=>{
        return ReportTableTemplateContexts.CONTEXTS.reduce((returned, context)=>{
          returned[context.toLowerCase()] = templates.filter(
            template=>template.getContext()===context.toUpperCase()
            && template.getProvider() === provider
            )
          return returned
        },{})
      }
    )
  }
  public static getTemplatesForContext(state, context:string):ReportTableTemplate[]{
    return state.Reports.Templates.list.filter(template=>
      template.getContext()===ReportTableTemplateContexts[context.toUpperCase()]
    )
  }
}
