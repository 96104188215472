import ReducerUtils from "../../utils/ReducerUtils"
import Actions      from "../../model/constant/actions"

const setTrue = (state, action) => {
  return {
    ...state,
    [action.accountId] : true
  }
}
const setFalse = (state, action) => {
  return {
    ...state,
    [action.accountId] : false
  }
}
interface fetchingByAccount{
  [accountId:number] : boolean
}
const IsFetchingPreferences = ReducerUtils.createReducer<fetchingByAccount>({},{
  [Actions.RETRIEVING_ACCOUNT_PREFERENCES] : setTrue,
  [Actions.RETRIEVE_ACCOUNT_PREFERENCES] : setFalse,
})

export default IsFetchingPreferences
