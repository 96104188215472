import "./AddGuideline.css"
import * as React                                      from "react"
import moment                                          from "moment"
import {AddGuidelineDecision}                          from "../../../../../model/pacy/Decision"
import {ProxyGroup}                                    from "../../../../../model/pacy/ProxyGroup"
import { getPreviousGuideline }                        from "../../../../../model/pacy/Guideline"
import GuidelinesDiff                                  from "../GuidelineDiff/GuidelinesDiff"
import {Typography}                                    from '@material-ui/core'
import ExpansionPanel                                  from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary                           from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails                           from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon                                  from '@material-ui/icons/ExpandMore'
import AddGuidelineDetails                             from "./AddGuidelineDetails/AddGuidelineDetails"
import {Translate}                                     from "react-localize-redux"

interface  AddGuidelineProps extends AddGuidelineOwnProps {
}

interface AddGuidelineOwnProps{
  decision            : AddGuidelineDecision
  concernedProxyGroup : ProxyGroup
  users               : {[userId:string]: string}
}


class AddGuideline extends React.Component<AddGuidelineProps,any>{
  shouldComponentUpdate(nextProps:AddGuidelineProps, nextState){
    return this.props.decision.id !== nextProps.decision.id || this.props.concernedProxyGroup.id !== nextProps.concernedProxyGroup.id
  }
  render(){
    const guideline = this.props.decision.details.guideline
    const date = moment(guideline.createdOn).format("LLLL") //Format the Date to Locale
    const userEmail = this.props.users[guideline.createdBy] || guideline.createdBy
    return (
      <ExpansionPanel TransitionProps={{mountOnEnter: true}}>
        <ExpansionPanelSummary  expandIcon = {<ExpandMoreIcon/>}>
          <div className="AddGuideline">
            <div className="Content">
              <div className="InfoSummary">
                <Typography color="textPrimary" variant="h6">{this.props.concernedProxyGroup.name}</Typography>
                <Typography variant="subtitle1">
                  <Translate id={`common.months.${guideline.month}`}/> {guideline.year}
                </Typography>
              </div>
              <GuidelinesDiff
                guideline={guideline}
                previousGuideline={getPreviousGuideline(guideline, this.props.concernedProxyGroup.guidelines)}
              />
            </div>
            <div className="Author">
              <Typography color="textSecondary"><em>{date}</em></Typography>
              <Typography color="textSecondary">{userEmail}</Typography>
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="AddGuidelineDetails">
          <AddGuidelineDetails
            decision={this.props.decision}
            concernedProxyGroup={this.props.concernedProxyGroup}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default AddGuideline
