import WebInfo                      from "../../../model/Store/Tracking/WebInfo"
import ArrayUtils                   from "../../../utils/ArrayUtils"
import PageCompiledWebInfo          from "../model/PageCompiledWebInfo"

const HARD_LIMIT          = 10*60 // 10 minutes
const ACCEPTABLE_DELTA    = 150   // 2 minutes 30 seconds
const DELTA_GROWTH_FACTOR = 10    // 10 seconds

const compileOne = (byPage:PageCompiledWebInfo) : any => {
  let byMoment = []
  const leads = (
    byPage.leads
    .sort((x,y)=>ArrayUtils.comp(x.timestamp,y.timestamp))
  )
  const firstLead  = leads[0]
  let beginTime    = firstLead.timestamp
  let workspace    = [ firstLead ]
  let growingDelta = 0
  for(let lead of leads.slice(1)){
    const lastLead = workspace[workspace.length-1]
    let softLimit = lastLead.timestamp+ACCEPTABLE_DELTA+growingDelta
    let hardLimit = lastLead.timestamp+HARD_LIMIT
    if(lead.timestamp < softLimit && lead.timestamp < hardLimit){
      growingDelta += DELTA_GROWTH_FACTOR
      workspace.push(lead)
    }
    else{
      byMoment.push(workspace)
      growingDelta = 0
      workspace = [ lead ]
    }
  }
  if(workspace){byMoment.push(workspace)}
  return byMoment
  .map(leads=>({
    ...byPage,
    leads : leads,
    begin : leads[0].timestamp,
    end   : leads[leads.length-1].timestamp,
  }))
}

const compile = (byPages:PageCompiledWebInfo[]) : any[] =>{
  return byPages
  .map(compileOne)
  .reduce((a,b)=>[...a,...b],[])
  .filter(x=>x.leads.length)
}
export default compile
