import './DormantTextInput.css'
import * as React from "react"

interface DormantTextInputProps{
  initialText  : string
  placeholder ?: string
  onChange    ?: (text:string)=>void
  onBlur      ?: (text:string)=>void
  edited      ?: boolean
}
interface DormantTextInputState{
  active : boolean
  text   : string
}


class DormantTextInput extends React.Component<DormantTextInputProps,DormantTextInputState>{
  static defaultProps = {
    placeholder : "",
    onChange : ()=>{},
    onBlur : ()=>{},
    edited : false,
  }
  private input
  constructor(props){
    super(props)
    this.state = {
      active : false,
      text : props.initialText
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur   = this.handleBlur.bind(this)
  }
  componentDidUpdate(prevProps, prevState){
    if(!prevState.active && this.state.active){
      this.input.focus()
      this.input.select()
    }
    if(prevProps.initialText != this.props.initialText){
      this.setState({text : this.props.initialText})
    }
  }
  handleChange(e){
    const value = e.target.value
    this.setState({text:value})
    this.props.onChange(value)
  }
  handleBlur(e){
    const value = e.target.value
    this.setState({active:false})
    this.props.onBlur(value)
  }
  handleKeyPress(e){
    if(e.key === "Enter"){e.target.blur()}
  }
  render(){
    if(this.state.active){
      return (
        <div className={"DormantTextInput Active"+(this.props.edited?" Edited":"")}>
          <input
            ref={ref=>this.input = ref}
            id="activeDormantInput"
            value={this.state.text}
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onKeyPress={this.handleKeyPress}
          />
        </div>
      )
    }
    return (
      <div
        className={"DormantTextInput Dormant"+(this.props.edited?" Edited":"")}
        onClick={()=>this.setState({active:true})}
      >
        <div className="Text">{this.state.text}</div>
        <i className="EditButton material-icons">create</i>
      </div>
    )
  }
}

export default DormantTextInput
