import './FilterCheckbox.css'
import * as React                   from 'react'
import { ListItemText,
  ListItemSecondaryAction,
  Checkbox
}                                   from '@material-ui/core'

const FilterCheckbox = (props) => {
  const checkboxRef = React.createRef<any>()
  return(
    <div className="FilterCheckbox">
      <ListItemText className="FilterName" onClick={()=>{if(checkboxRef.current){checkboxRef.current.click()}}}>
          {typeof(props.filter.name) === "function" ? props.filter.name() : props.filter.name}
      </ListItemText>
      <ListItemSecondaryAction>
        <Checkbox
          inputRef={checkboxRef}
          checked={props.filter.checked}
          onChange={props.handleToggle}
          id={props.filter.id.toString()}
        />
      </ListItemSecondaryAction>
    </div>
  )
}

export default FilterCheckbox
