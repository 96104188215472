import * as React       from 'react'
import {connect}        from 'react-redux'
import {Translate}      from "react-localize-redux"
import {Modal, Button}  from "carbon-components-react"
import {rememberUser}   from "../../page/login/action/RememberMe"
import Actions          from "../../model/constant/actions"
import MaintenanceUtils from "../../utils/MaintenanceUtils"

const mapStateToProps = (state,ownProps)=>{
  return {
    validUntil : state.Login.userLogged.validUntil,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    rememberUser: (email:string ,rememberId:string)=>dispatch(rememberUser(email,rememberId)),
    dumpUser: (email:string ,rememberId:string)=>dispatch({type:Actions.DUMP_USER}),
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

class SessionExpiredModal extends React.Component<any,any>{
  private interval
  constructor(props){
    super(props)
    this.checkExpired = this.checkExpired.bind(this)
    this.state = {
      expired : false
    }
    this.interval = setInterval(this.checkExpired,5000)
  }
  componentWillUnmount(){clearInterval(this.interval)}
  checkExpired(){
    if(this.props.validUntil){
      const date = new Date(this.props.validUntil.replace(" ","T")+"Z")
      const expired = date < new Date()
      if(expired || MaintenanceUtils.isInMaintenance()){
        this.setState({expired : true})
        clearInterval(this.interval)
      }
    }
  }
  render(){
    if(!this.state.expired){return null}
    return <Modal
      onBlur={()=>{}}
      onClick={()=>{}}
      onFocus={()=>{}}
      open
      passiveModal
      modalHeading=""
      modalLabel=""
      primaryButtonText=""
      secondaryButtonText=""
    >
      <div><Translate id={"login.sessionExpired"}/></div>
      <Button onClick={()=>document.location.reload()}><Translate id={"login.login"}/></Button>
    </Modal>
  }
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(SessionExpiredModal)
