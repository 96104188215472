import {OutputGeoStatRow} from "../model/Store/Statistics/GeoStats"
import ObjectUtils        from "../utils/ObjectUtils"

export default class MapUtils {
  public static getBoundsOfPolygon(polygon:google.maps.Polygon) : google.maps.LatLngBounds{
    let bounds = new google.maps.LatLngBounds()
    let paths = polygon.getPaths()
    let path
    for(let i=0; i<paths.getLength(); i++){
      path = paths.getAt(i)
      for(let j=0; j<path.getLength(); j++){
        bounds.extend(path.getAt(j))
      }
    }
    return bounds
  }
  public static simplifyShape(shape:any, minimalDistance:number, distanceLeniency:number):any{
    return {
      ...shape,
      polygons : shape.polygons.map(polygon=>{
        if(polygon.length < 3){return polygon}
        let newPolygon = [...polygon]
        let removedPointsCount = 0
        let iterator = 0
        while(true){
          let triplet = []
          if(iterator+2 >= newPolygon.length){
            //now looping back to the begining
            break
          }
          triplet = [
            newPolygon[iterator],
            newPolygon[iterator+1],
            newPolygon[iterator+2],
          ]
          if(MapUtils.computeIfMiddlePointUseless(triplet, minimalDistance, distanceLeniency)){
            newPolygon.splice(iterator+1, 1)
            removedPointsCount++
          }
          else{iterator++}
        }
        return newPolygon
      })
    }
  }
  public static computeIfMiddlePointUseless(pointsTriplet:any[]/*length=3*/, minimalDistance:number, distanceLeniency:number):boolean{
    const A = pointsTriplet[0]
    const B = pointsTriplet[1]
    const C = pointsTriplet[2]
    //Calculate distance between two points, in meters
    const AC = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(A.lat, A.lng),
      new google.maps.LatLng(C.lat, C.lng),
    )
    if(AC < minimalDistance){
      const AB = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(A.lat, A.lng),
        new google.maps.LatLng(B.lat, B.lng),
      )
      const BC = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(B.lat, B.lng),
        new google.maps.LatLng(C.lat, C.lng),
      )
      if(AB+BC < AC*distanceLeniency){
        return true
      }
    }
    return false
  }
  public static computeShapeArea(shape:any):number{
    return shape.polygons.reduce((total, polygon)=>{
      const points = polygon.map(x=>new google.maps.LatLng(x.lat, x.lng))
      return total+google.maps.geometry.spherical.computeArea(points)
    },0)
  }
  public static computeShapeNumberOfPoints(shape:any):number{
    return shape.polygons.reduce((total, polygon)=>(total+polygon.length),0)
  }
  public static buildHeatmapData(stats:OutputGeoStatRow[], cache, metacache){
    return ObjectUtils.getObjectValues(
      stats
      .reduce((acc,stat)=>{
        const position = (
          cache[stat.code]
          ? cache[stat.code]
          : metacache[stat.code]
        )
        if(!position){return acc}
        if(acc[stat.code] === undefined){
          acc[stat.code] = {
            location : new google.maps.LatLng(position.lat, position.lng),
            weight   : 0
          }
        }
        acc[stat.code].weight += stat.Clicks
        return acc
      }, {})
    ).map(x=>({...x, weight : Math.ceil(Math.log10(x.weight)*10)}))
  }
}
