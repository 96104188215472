import * as React   from "react"
import * as d3      from "d3"
import {GraphData}  from "../../model/graph/Graph"
import MathUtils    from "../../utils/MathUtils"


interface CircleProps{
  data     : GraphData[]
  scalingX : (x:any)=>any
  scalingY : (x:any)=>any
  color    : string
  tip      : any
}
class Circle extends React.Component<CircleProps,any>{
  private node

  constructor(props){
    super(props)
    this.renderGraph = this.renderGraph.bind(this)
    this.clearGraph = this.clearGraph.bind(this)
  }
  componentDidMount(){this.renderGraph()}
  componentDidUpdate(){this.renderGraph()}
  renderGraph(){
    this.clearGraph()
    const graph = d3.select(this.node)
    const circles = graph.selectAll("circle").data(this.props.data)
    const tip = this.props.tip
    circles.enter()
      .append("circle")
        .attr("class","lineCircle")
        .attr("cx",d => this.props.scalingX(d.xValue))
        .attr("cy",d => this.props.scalingY(d.yValue))
        .attr("r",0)
        .attr("fill",d3.hsl(this.props.color))
        .on("mouseover",function(d){
          d.yValue = MathUtils.round(parseFloat(d.yValue),2)+""
          d3.select(this).transition()
            .attr("r",6)
          tip.show(d, this)
        })
        .on("mouseout",function(d){
          d3.select(this).transition()
            .attr("r",3)
          tip.hide(d, this)
        })
      .transition()
        .duration(800)
        .attr("r",3)
  }
  clearGraph(){ d3.select(this.node).selectAll("circle").remove() }
  render(){ return <g ref={node=>this.node=node} className={"line"}></g> }
}

export default Circle
