import {combineReducers} from 'redux'
import Reference         from "./Reference"
import Retrieving        from "./IsRefreshing"
import Makes             from "./Makes"
import MakeModels        from "./MakeModels"

export default combineReducers({
  Retrieving,
  Reference,
  Makes,
  MakeModels,
})
