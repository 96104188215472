import './UserLevel.css'
import * as React from 'react'

interface UserLevelProps {
  level : number
}
const levelToLevelName = {
  1 : "Root",
  2 : "Admin",
  3 : "Staff",
  4 : "PowerUser",
  5 : "Editor",
  6 : "Read-Only",
}
const UserLevel = (props:UserLevelProps)=>{
  const hint = " ("+props.level+")"
  let name = levelToLevelName[props.level]
  if(!name){name = "Not Standard"}
  return (
    <span>
      <span key='name'>{name}</span>
      <span key='hint' className="UserLevelHint">{hint}</span>
    </span>
  )
}

export default UserLevel
