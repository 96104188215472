import ReducerUtils from "../../../utils/ReducerUtils"
import Actions from "../../../model/constant/actions"

const setInfo = (state:FailedInfo, action):FailedInfo => {
  return {
    status : action.status,
    statusCode : action.statusCode,
    message : action.message
  }
}

export interface FailedInfo {
  status : boolean
  statusCode ?: number
  message ?: string
}
const Accounts = ReducerUtils.createReducer<FailedInfo>({status: false},{
  [Actions.FAILED_RETRIEVING_PACY_ACCOUNT]   : setInfo,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST] : ()=>({status: false}),
  [Actions.PACY_CLEAR]                       : ()=>({status: false})
})

export default Accounts
