const MONTHS_ABBREVIATION = [
  ["jan","jan"], //0
  ["feb","fev"], //1
  ["mar","mar"], //2
  ["apr","avr"], //3
  ["may","mai"], //4
  ["jun","jun"], //5
  ["jul","jul"], //6
  ["aug","aou"], //7
  ["sep","sep"], //8
  ["oct","oct"], //9
  ["nov","nov"], //10
  ["dec","dec"], //11
]
export default MONTHS_ABBREVIATION
