import './AgentMove.css'
import * as React                         from 'react'
import {
  Card,
  CardHeader,
  Typography,
  Icon,
  Divider,
  CardContent,
  FormControl,
  Select,
  MenuItem,
	CardActions,
	Button,
  Modal
}                                         from '@material-ui/core'
import { ProxyGroup }                     from '../../../../model/pacy/ProxyGroup'
import AgentWeightsConfiguration          from './AgentWeightsConfiguration'
import { Agent }                          from '../../../../model/pacy'
import {
  AccountModifier,
  createMoveAgentAccountModifier,
  createSetAgentWeightAccountModifier,
  createToggleAgentAccountModifier
}                                         from '../../../../model/pacy/AccountModifiers'
import { Translate }                      from 'react-localize-redux'

interface AgentMoveOwnProps {
  addAccountModifiers : (newActions:AccountModifier[])=>void
  proxyGroups         : ProxyGroup[]
  closeModal          : () => void
  agentToMove         : Agent
  proxyGroupId        : string
  proxyId             : string
}
interface AgentMoveProps extends AgentMoveOwnProps {}

const AgentMove = (props:AgentMoveProps) => {
  const [openAgentsWeightsModal,setOpenAgentsWeightModal] = React.useState(false)
  const [selectedProxyGroupId,setSelectedProxyGroupId] = React.useState('')
  const [selectedProxyId,setSelectedProxyId] = React.useState('')
  const handleProxyGroupSelectChange = (event) => {
    setSelectedProxyGroupId(event.target.value)
    setSelectedProxyId('')
  }
  const handleProxySelectChange = (event) => {
    setSelectedProxyId(event.target.value)
  }
  const handleOpenAgentsWeightsModal = () => {
    setOpenAgentsWeightModal(true)
  }
  const handleCloseAgentsWeightsModal = () => {
    setOpenAgentsWeightModal(false)
  }
  const handleWeightsChange = (agentsChanged:{[id:string]:number},agentsActivated:string[]) => {
    const moveAgent = createMoveAgentAccountModifier(props.agentToMove.id,selectedProxyGroupId,props.proxyGroupId,selectedProxyId,props.proxyId)
    const weightsChangeActions = Object.keys(agentsChanged).map(agentId=>{
      return createSetAgentWeightAccountModifier(agentId,agentsChanged[agentId],selectedProxyId,selectedProxyGroupId)
    })
    const activateActions = agentsActivated.map(agentId=>{
      return createToggleAgentAccountModifier(agentId,true,selectedProxyId,selectedProxyGroupId)
    })
    const newActions = [moveAgent,...weightsChangeActions,...activateActions]
    props.addAccountModifiers(newActions)
  }
  const agents = selectedProxyId
    ? [
        props.agentToMove,
        ...(
          props.proxyGroups
          .find(proxyGroup=>proxyGroup.id === selectedProxyGroupId).proxies
          .find(proxy=>proxy.id === selectedProxyId)
          .agents
        )
      ]
    : null
  return (
    <Card className={selectedProxyId ? "AgentMove Wide" : "AgentMove Narrow"}>
      <CardHeader
        className="Header"
        title={<Typography variant="h6">Move Agent</Typography>}
        avatar={<Icon color="primary">double_arrow</Icon>}
      />
      <Divider />
      <CardContent>
        <div className="Selects">
          <Typography className="Instruction" variant="h6">Choose a Proxy Group</Typography>
          <FormControl className="Select">
            <Select value={selectedProxyGroupId} onChange={handleProxyGroupSelectChange}>
              {props.proxyGroups.map(proxyGroup=>{
                return <MenuItem key={proxyGroup.id} value={proxyGroup.id}>{proxyGroup.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          <Typography className="Instruction" variant="h6">Choose a Proxy</Typography>
          <FormControl className="Select">
            <Select value={selectedProxyId} onChange={handleProxySelectChange}>
              {selectedProxyGroupId && props.proxyGroups
                .find(proxyGroup=>{
                  return proxyGroup.id == selectedProxyGroupId
                }).proxies
                .filter(proxy=>proxy.id!==props.proxyId)
                .map(proxy=>{
                  return <MenuItem key={proxy.id} value={proxy.id}>{proxy.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
      </CardContent>
      <Divider />
      <CardActions className="AgentMoveFooter">
        <Button onClick={props.closeModal} color="secondary" size="large" variant="outlined">Cancel</Button>
        <Button
          onClick={handleOpenAgentsWeightsModal}
          disabled={!selectedProxyId}
          style={{color:'#fff'}}
          color="primary"
          size="large"
          variant="contained"
        >
          <Translate id='pacyConfigs.configureWeights' />
        </Button>
      </CardActions>
      {selectedProxyId && <Modal className="AgentsWeightsInputsModal" open={openAgentsWeightsModal} onClose={handleCloseAgentsWeightsModal}>
        <div>
          <AgentWeightsConfiguration handleCloseModal={props.closeModal} agents={agents} handleWeightsChange={handleWeightsChange} />
        </div>
      </Modal>}
    </Card>
  )
}

export default AgentMove
