import './BoostConfig.css'
import * as React                      from 'react'
import {
  Card,
  CardHeader,
  Typography,
  Icon,
  Divider,
  CardContent,
  CardActions,
  Button,
}                                      from '@material-ui/core'
import BoostShapeGraph                 from './BoostShapeGraph'
import BoostConfigInputs               from './BoostConfigInputs'
import { BeginningOfMonthBoostConfig } from '../../../../../model/pacy'
import { Translate }                   from 'react-localize-redux'

interface BoostConfigOwnProps {
  handleBoostConfirm        : () => void
  boost                     : BeginningOfMonthBoostConfig
  handleDurationChange      : (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleShapeChange         : (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleBoostChange         : (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleBoostEnable         : (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCloseModal          : () => void
  handleActivateBoost       : () => void
  handleDurationBlur       ?: () => void
  handleBoostBlur          ?: () => void
  noButtons                ?: boolean
}
interface BoostConfigProps extends BoostConfigOwnProps{}

const BoostConfig = (props:BoostConfigProps) => {
  return (
    <Card className="BoostConfig">
      <CardHeader
        title={<Typography variant="h6"><Translate id="pacyConfigs.boostConfiguration" /></Typography>}
        avatar={<Icon>build</Icon>}
      />
      <Divider />
      <CardContent>
        <BoostConfigInputs
          boost={props.boost}
          handleBoostChange={props.handleBoostChange}
          handleDurationChange={props.handleDurationChange}
          handleShapeChange={props.handleShapeChange}
          handleBoostBlur={props.handleBoostBlur}
          handleDurationBlur={props.handleDurationBlur}
          handleBoostEnable={props.handleBoostEnable}
        />
        {props.boost.active && <BoostShapeGraph
          mutiplier={props.boost.boost}
          duration={props.boost.length}
          selected={props.boost.shape}
        />}
        {!props.boost.active && <div style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/img/pacyConfigs/graph2.png)`}} className="BoostDisabled">
          <div className="InsideBoostDisabled">
            <Button onClick={props.handleActivateBoost} style={{color:'white'}} size="large" color="primary" variant="contained">
              <Translate id="pacyConfigs.activateBoost" />
            </Button>
          </div>
        </div>}
        <Divider />
      </CardContent>
      {!props.noButtons && <CardActions className="BoostConfigFooter">
        <Button onClick={props.handleCloseModal} variant="outlined" color="secondary"><Translate id="common.cancel" /></Button>
        <Button onClick={()=>{props.handleBoostConfirm();props.handleCloseModal()}} style={{color:'white'}} variant="contained" color="primary">
          <Translate id="pacyConfigs.done" />
        </Button>
      </CardActions>}
    </Card>
  )
}

export default BoostConfig
