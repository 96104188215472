import StatisticsUtils                from '../../../../utils/StatisticsUtils'
import MathUtils                      from '../../../../utils/MathUtils'
import ObjectUtils                    from '../../../../utils/ObjectUtils'
import NumFormatter                   from '../../../../utils/NumberFormatter'
import {AVAILABLE_KPIS as KPIS}       from "../../../../model/constant/KPIs"

const struct = {
  [KPIS.impressions] : {
    icon:"pageview",
    text:"dashboard.KPIs.impressions",
    number:"-",
    percent:undefined,
    color:"grey",
    compiled:false,
  },
  [KPIS.clicks] : {
    icon:"touch_app",
    text:"dashboard.KPIs.clicks",
    number:"-",
    percent:undefined,
    color:"grey",
    compiled:false,
  },
  [KPIS.cpc] : {
    icon:"monetization_on",
    text:"dashboard.KPIs.CPC",
    number:"-",
    percent:undefined,
    color:"grey",
    compiled:false,
  },
  [KPIS.cost] : {
    icon:"monetization_on",
    text:"dashboard.KPIs.cost",
    number:"-",
    percent:undefined,
    color:"grey",
    compiled:false,
  },
  [KPIS.impressionshare] : {
    icon:"pie_chart_outlined",
    text:"dashboard.KPIs.impressionShare",
    number:"-",
    percent:undefined,
    color:"grey",
    compiled:false,
  },
  [KPIS.clickshare] : {
    icon:"pie_chart_outlined",
    text:"dashboard.KPIs.clickShare",
    number:"-",
    percent:undefined,
    color:"grey",
    compiled:false,
  },
  [KPIS.conversions] : {
    icon:"flag",
    text:"dashboard.KPIs.conversions",
    number:"-",
    percent:undefined,
    color:"grey",
    compiled:false,
  },
  [KPIS.ctr] : {
    icon:"call_split",
    text:"dashboard.KPIs.CTR",
    number:"-",
    percent:undefined,
    color:"grey",
    compiled:false,
  },
  [KPIS.conversionrate] : {
    icon:"flip_camera_android",
    text:"dashboard.KPIs.conversionrate",
    number: "-",
    percent:undefined,
    color:"grey",
    compiled:false,
  },
  [KPIS.cpcon] : {
    icon:"arrow_circle_up",
    text:"dashboard.KPIs.cpcon",
    number: "-",
    percent:undefined,
    color:"grey",
    compiled:false,
  },
}

const compileStruct = (wantedKPIs:string[], currentCampaignsStats:any[]|undefined, symmetricalCampaignsStats:any[]|undefined, translate:(translationId:string)=>string)=>{
  let returned = ObjectUtils.mapOnObject(struct, (key, value)=>({
    ...value,
    text : translate(value.text)
  }))
  if(currentCampaignsStats===undefined){return returned}
  //We need to put cpc at the end since it needs other stats already compiled
  //Also we copy the list first so we don't modify the array that was sent to us
  const orderedKPIS = [...wantedKPIs].sort((a,b)=>a===KPIS.cpc?1:0)
  for(let KPI of orderedKPIS){
    if(KPIS[KPI] === undefined){throw Error(`KPI ${KPI} doesn't exist`)}
    const compile = compilationFunctionsByKPI[KPI]
    if(compile === undefined){throw Error(`KPI ${KPI} doesn't have a compilation function associated`)}
    returned[KPI] = compile(returned, currentCampaignsStats, symmetricalCampaignsStats)
  }

  return returned
}
const compilationFunctionsByKPI = {
  [KPIS.clicks]          : (obj, cStats, symmetricalCStats) => compileClicks(obj[KPIS.clicks], cStats, symmetricalCStats),
  [KPIS.cost]            : (obj, cStats, symmetricalCStats) => compileCost(obj[KPIS.cost], cStats, symmetricalCStats),
  [KPIS.impressions]     : (obj, cStats, symmetricalCStats) => compileImpressions(obj[KPIS.impressions], cStats, symmetricalCStats),
  [KPIS.impressionshare] : (obj, cStats, symmetricalCStats) => compileImpressionShare(obj[KPIS.impressionshare], cStats, symmetricalCStats),
  [KPIS.clickshare]      : (obj, cStats, symmetricalCStats) => compileClickShare(obj[KPIS.clickshare], cStats, symmetricalCStats),
  [KPIS.cpc]             : (obj, cStats, symmetricalCStats) => {
    const requiredPrecompiled = [KPIS.clicks, KPIS.cost]
    for(let KPI of requiredPrecompiled){
      if(!obj[KPI].compiled){
        obj[KPI] = compilationFunctionsByKPI[KPI](obj, cStats, symmetricalCStats)
      }
    }
    return compileCPC(obj[KPIS.cpc], obj[KPIS.clicks], obj[KPIS.cost])
  },
  [KPIS.conversions]     : (obj, cStats, symmetricalCStats) => compileConversions(obj[KPIS.conversions], cStats, symmetricalCStats),
  [KPIS.ctr]             : (obj, cStats, symmetricalCStats) => {
    const requiredPrecompiled = [KPIS.clicks, KPIS.impressions]
    for(let KPI of requiredPrecompiled){
      if(!obj[KPI].compiled){
        obj[KPI] = compilationFunctionsByKPI[KPI](obj, cStats, symmetricalCStats)
      }
    }
    return compileCTR(obj[KPIS.ctr], obj[KPIS.clicks], obj[KPIS.impressions])
  },
  [KPIS.conversionrate]  : (obj, cStats, symmetricalCStats) => {
    const requiredPrecompiled = [KPIS.conversions, KPIS.clicks]
    for(let KPI of requiredPrecompiled){
      if(!obj[KPI].compiled){
        obj[KPI] = compilationFunctionsByKPI[KPI](obj, cStats, symmetricalCStats)
      }
    }
    return compileConversionRate(obj[KPIS.conversionrate], obj[KPIS.conversions], obj[KPIS.clicks])
  },
  [KPIS.cpcon]           : (obj, cStats, symmetricalCStats) => {
    const requiredPrecompiled = [KPIS.cost, KPIS.conversions]
    for(let KPI of requiredPrecompiled){
      if(!obj[KPI].compiled){
        obj[KPI] = compilationFunctionsByKPI[KPI](obj, cStats, symmetricalCStats)
      }
    }
    return compileCostPerConversion(obj[KPIS.cpcon], obj[KPIS.cost], obj[KPIS.conversions])
  },
}
const applyDiffDataToObj = (obj, currentData, pastData, absoluteDiff:boolean=false) => {
  let diff
  if(absoluteDiff){
    diff = (currentData-pastData).toFixed(2)
  }
  else{
    diff = pastData!=0 ? (100*(currentData - pastData)/pastData).toFixed(2) : 0
  }
  obj.diff = diff
  obj.percent = formatPercent(diff)
  obj.color = findColorBackground(diff)
  return obj
}
const findColorBackground = (data) => {
  return data >=0 ? "#3cba54" : "#db3236"
}
const formatPercent = (data) => {
  return data > 0 ? "+"+NumFormatter.formatNumber(data, 2)+"%" : NumFormatter.formatNumber(data, 2)+"%"
}
const compileCPC = (obj, objClicks, objCost)=>{
  const symmetricalCPC = StatisticsUtils.calculateCPC(objCost.symmetricalRawNumber, objClicks.symmetricalRawNumber)
  const currentCPC = StatisticsUtils.calculateCPC(objCost.rawNumber, objClicks.rawNumber)
  obj.number = NumFormatter.formatCurrency(currentCPC)
  obj = applyDiffDataToObj(obj, currentCPC, symmetricalCPC)
  obj.color = findColorBackground(-obj.diff) //Inverse color since lower CPC is a good thing
  obj.compiled = true
  return obj
}
const compileClicks = (obj,stats:any[],symmetricalStats:any[])=>{
  const symmetricalClicks = symmetricalStats.reduce((a,b)=>b.Clicks+a,0)
  const currentClicks = stats.reduce((a,b)=>b.Clicks+a,0)
  obj.symmetricalRawNumber = symmetricalClicks
  obj.rawNumber = currentClicks
  obj.number = NumFormatter.formatNumber(currentClicks, 2)
  obj = applyDiffDataToObj(obj, currentClicks, symmetricalClicks)
  obj.compiled = true
  return obj
}
const compileCost = (obj,stats:any[],symmetricalStats:any[])=>{
  const symmetricalCost = symmetricalStats.reduce((a,b)=>b.Cost+a,0)
  const currentCost = stats.reduce((a,b)=>b.Cost+a,0)
  obj.symmetricalRawNumber = symmetricalCost
  obj.rawNumber = currentCost // so compileCPC can work properly
  obj.number = NumFormatter.formatCurrency(currentCost)
  obj = applyDiffDataToObj(obj, currentCost, symmetricalCost)
  obj.compiled = true
  return obj
}
const compileImpressions = (obj,stats:any[],symmetricalStats:any[])=>{
  const symmetricalImpressions = symmetricalStats.reduce((a,b)=>b.Impressions+a,0)
  const currentImpressions = stats.reduce((a,b)=>b.Impressions+a,0)
  obj.symmetricalRawNumber = symmetricalImpressions
  obj.rawNumber = currentImpressions
  obj.number = NumFormatter.formatNumber(currentImpressions, 2)
  obj = applyDiffDataToObj(obj, currentImpressions, symmetricalImpressions)
  obj.compiled = true
  return obj
}
const compileImpressionShare = (obj,stats:any[],symmetricalStats:any[])=>{
  const symmetricalImpressionShare = StatisticsUtils.calculateImpressionShare(
    symmetricalStats.reduce((a,b)=>{
      if(b.SearchImpressionShare !== null){
        a.push([b.Impressions,b.SearchImpressionShare])
      }
      return a
    },[])
  )
  const currentImpressionShare = StatisticsUtils.calculateImpressionShare(
    stats.reduce((a,b)=>{
      if(b.SearchImpressionShare !== null){
        a.push([b.Impressions,b.SearchImpressionShare])
      }
      return a
    },[])
  )
  obj.number = NumFormatter.formatNumber(currentImpressionShare, 2)+"%"
  obj = applyDiffDataToObj(obj, currentImpressionShare, symmetricalImpressionShare, true)
  obj.compiled = true
  return obj
}
const compileClickShare = (obj,stats:any[],symmetricalStats:any[])=>{
  const symmetricalClickShare = StatisticsUtils.calculateClickShare(
    symmetricalStats.reduce((a,b)=>{
      if(b.SearchClickShare !== null){
        a.push([b.Clicks,b.SearchClickShare])
      }
      return a
    },[])
  )
  const currentClickShare = StatisticsUtils.calculateClickShare(
    stats.reduce((a,b)=>{
      if(b.SearchClickShare !== null){
        a.push([b.Clicks,b.SearchClickShare])
      }
      return a
    },[])
  )
  obj.number = NumFormatter.formatNumber(currentClickShare, 2)+"%"
  obj = applyDiffDataToObj(obj, currentClickShare, symmetricalClickShare, true)
  obj.compiled = true
  return obj
}
const compileConversions = (obj,stats:any[],symmetricalStats:any[])=>{
  const symmetricalConversions = symmetricalStats.reduce((a,b)=>b.Conversions+a,0)
  const currentConversions = stats.reduce((a,b)=>b.Conversions+a,0)
  obj.symmetricalRawNumber = symmetricalConversions
  obj.rawNumber = currentConversions
  obj.number = NumFormatter.formatNumber(currentConversions, 2)
  obj = applyDiffDataToObj(obj, currentConversions, symmetricalConversions)
  obj.compiled = true
  return obj
}
const compileCTR = (obj, objClicks, objImpressions)=>{
  const symmetricalCTR = StatisticsUtils.calculateCTR(objClicks.symmetricalRawNumber, objImpressions.symmetricalRawNumber)
  const currentCTR = StatisticsUtils.calculateCTR(objClicks.rawNumber, objImpressions.rawNumber)
  obj.number = NumFormatter.formatNumber(currentCTR, 2)+"%"
  obj = applyDiffDataToObj(obj, currentCTR, symmetricalCTR, true)
  obj.compiled = true
  return obj
}
const compileConversionRate = (obj, objConversions, objClicks) => {
  const symmetricalConversionRate = StatisticsUtils.calculateConversionRate(objConversions.symmetricalRawNumber, objClicks.symmetricalRawNumber)
  const currentConversionRate = StatisticsUtils.calculateConversionRate(objConversions.rawNumber, objClicks.rawNumber)
  obj.number = NumFormatter.formatNumber(currentConversionRate, 2) + "%"
  obj = applyDiffDataToObj(obj, currentConversionRate, symmetricalConversionRate, true)
  obj.compiled = true
  return obj
}
const compileCostPerConversion = (obj, objCost, objConversions) => {
  const symmetricalCPCon = StatisticsUtils.calculateCostPerConversion(objCost.symmetricalRawNumber, objConversions.symmetricalRawNumber)
  const currentCPCon = StatisticsUtils.calculateCostPerConversion(objCost.rawNumber, objConversions.rawNumber)
  obj.number = NumFormatter.formatCurrency(currentCPCon)
  obj = applyDiffDataToObj(obj, currentCPCon, symmetricalCPCon)
  obj.compiled = true
  return obj
}

export default compileStruct
