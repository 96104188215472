import './Languages.css'
import * as React    				from 'react'
import {
	Switch,
	Typography,
	CardContent,
  Checkbox,
  CardHeader,
  Card,
  CardActions,
  Icon,
} 								  			  from '@material-ui/core'
import {
	withLocalize,
	TranslateFunction,
  Translate
} 									 				from 'react-localize-redux'
import Strategy      				from '../../../../model/Store/Strategy/Strategy'

interface LanguagesOwnProps {
  strategies   	: Strategy[]
	setStrategies : any
}
interface LanguagesProps extends LanguagesOwnProps {
	translate : TranslateFunction
}
const Languages = (props:LanguagesProps) => {
	const [strategies,setStrategies] = React.useState(props.strategies)
	function handleChange(event) {
		props.setStrategies(prevState=>
			prevState.map(x=>{
				if(x.lang === event.target.id){
					return {
						...x,
						active : event.target.checked
					}
				}
				return x
			})
		)
	}
React.useEffect(()=>{
	setStrategies(props.strategies)
},[props.strategies])
	//generate Switches
	const languages = strategies.map(strategy=>{
		return(
			<div key={strategy.lang}>
				<Typography  variant="overline" className="Text">
					{strategy.lang === 'EN'
						? props.translate('common.english')
						: props.translate('common.french')
					}
				</Typography>
				<Checkbox
					key={strategy.lang}
					id={strategy.lang}
					color="primary"
					checked={strategy.active}
					onChange={handleChange}
				/>
			</div>
		)
	})
	return (
		<div className="Languages">
      <CardHeader
        title={<Typography variant="h6"><Translate id="strategies.status" /></Typography>}
        subheader={<Typography variant="caption"><Translate id="branding.descriptions.status" /></Typography>}
      />
			<CardContent className="Switch">
				{languages}
			</CardContent>
      <CardActions>
        <Typography className="Tip" variant="caption"><Icon>feedback</Icon><Translate id="branding.descriptions.languagesTip"/></Typography>
      </CardActions>
		</div>
  )
}
export default withLocalize(Languages as any) as React.ComponentType<LanguagesOwnProps>
