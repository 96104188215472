import Keystone   from "../utils/Keystone"
import Actions    from "../model/constant/actions"
import {getToken} from "../storeAccessor/Generic"
import {Dispatch} from "react-redux"

const createBudget = (budget) => {
  return {
    budgetId   : budget.id,
    AWId       : budget.AWId,
    budgetName : budget.name,
    amount     : budget.amount,
  }
}
const getBudgetsSuccess = (response, accountId) => {
  return {
    type      : Actions.RETRIEVE_BUDGETS_FOR_ACCOUNTID,
    budgets   : response.data.budgets.map((budget)=>createBudget(budget)),
    accountId : accountId
  }
}
export const getBudgets = (accountId:number) => (dispatch:Dispatch<any>, getState) => {
  dispatch({type:Actions.RETRIEVING_BUDGETS_FOR_ACCOUNTID, status:true})
  Keystone.get({
      action    : "GetBudgets",
      token     : getToken(getState()),
      accountId : accountId
    },
    response => {
      dispatch(getBudgetsSuccess(response, accountId)),
      dispatch({type:Actions.RETRIEVING_BUDGETS_FOR_ACCOUNTID, status:false})
    },
    error => console.log(error)
  )
}
