import "./TwoLineGraph.css"
import * as React               from "react"
import { connect }              from "react-redux"
import {
  withLocalize,
  Translate,
  TranslateFunction,
  Language
}                               from "react-localize-redux"
import LineSelector             from "../../../../components/graph/LineSelector"
import DualAxisGraph            from "../../../../components/graph/ComparisonLineGraph"
import ExplainedLoading         from "../../../../components/loading/"
import NumFormatter             from '../../../../utils/NumberFormatter'
import ArrayUtils               from "../../../../utils/ArrayUtils"
import MathUtils                from "../../../../utils/MathUtils"
import StatisticsUtils          from "../../../../utils/StatisticsUtils"
import StatisticsAccessors      from "../../../../storeAccessor/Statistics"
import { Toggle }               from "carbon-components-react"
import { Typography }           from "@material-ui/core"

interface TwoLineGraphProps{
  data : any[]
  symmetricalData : any[]
  translate : TranslateFunction
  activeLanguage : Language
}
const makeMapStateToProps = ()=>{
  const statsSelector = StatisticsAccessors.makeCampaignsStatsInCurrentAndSymmetricalSeparatedPeriodSelector()
  return (state,ownProps)=>{
    const stats = statsSelector(state)
    return {
      data : stats.current,
      symmetricalData : stats.symmetrical
    }
  }
}
const mapDispatchToProps = (dispatch) => ({})
const ITEMS_TRANSLATION_KEY = {
  Impressions           : "impressions",
  Clicks                : "clicks",
  Cost                  : "cost",
  Conversions           : "conversions",
  SearchImpressionShare : "searchImpressionShare",
  SearchClickShare      : "searchClickShare",
  CPC                   : "CPC",
  ConversionRate        : "conversionRate",
  CPCon                 : "CPCon",
}

const COMPILATION_BY_STAT = {
  Impressions           : (data)=>data.reduce((result, data)=>result+data.Impressions,0),
  Clicks                : (data)=>data.reduce((result, data)=>result+data.Clicks,0),
  Cost                  : (data)=>data.reduce((result, data)=>result+data.Cost,0),
  Conversions           : (data)=>data.reduce((result, data)=>result+data.Conversions,0),
  SearchImpressionShare : (data)=>StatisticsUtils.calculateImpressionShare(data.reduce((impShareData, data)=>{
    if(data.SearchImpressionShare !== null){impShareData.push([data.Impressions, data.SearchImpressionShare])}
    return impShareData
  },[])),
  SearchClickShare      : (data)=>StatisticsUtils.calculateClickShare(data.reduce((clickShareData, data)=>{
    if(data.SearchClickShare !== null){clickShareData.push([data.Clicks, data.SearchClickShare])}
    return clickShareData
  },[])),
  CPC                   : (data)=>StatisticsUtils.calculateCPC(COMPILATION_BY_STAT.Cost(data), COMPILATION_BY_STAT.Clicks(data)),
  ConversionRate        : (data)=>StatisticsUtils.calculateConversionRate(COMPILATION_BY_STAT.Conversions(data), COMPILATION_BY_STAT.Clicks(data)),
  CPCon                 : (data)=>StatisticsUtils.calculateCostPerConversion(COMPILATION_BY_STAT.Cost(data), COMPILATION_BY_STAT.Conversions(data)),
}


const matchDataByWeekDay = (data, comparedData) => {
  let lastDataIndexMatched = data.length-1
  for(let i = comparedData.length-1; i >=0; i--){
    comparedData[i].originalXValue = comparedData[i].xValue
    const comparedWeekDay = (new Date(comparedData[i].xValue)).getUTCDay()
    for(let j = lastDataIndexMatched; j >= 0; j--){
      const weekDay = (new Date(data[j].xValue)).getUTCDay()
      if(comparedWeekDay === weekDay){
        comparedData[i].xValue = data[j].xValue
        lastDataIndexMatched--
        break
      }
    }
  }
}
class DashboardGraph extends React.Component<TwoLineGraphProps,any>{
  constructor(props){
    super(props)
    this.state = {
      primary   : {
        id    : "Impressions",
        text  : props.translate("common.impressions"),
        data  : [],
        color : "#00b1e4"
      },
      secondary : {
        id    : "Clicks",
        text  : props.translate("common.clicks"),
        data  : [],
        color : "#3cba54"
      },
      compare: false
    }
    this.setPrimary   = this.setPrimary.bind(this)
    this.setSecondary = this.setSecondary.bind(this)
    this.setComparing = this.setComparing.bind(this)
  }
  shouldComponentUpdate(nextProps, nextState) {
    if(!this.props.data && nextProps.data){return true}
    if(this.props.data && !nextProps.data){return true}
    if(this.props.data && nextProps.data && this.props.data.length != nextProps.data.length){return true}
    if(this.state.primary.id !== nextState.primary.id){return true}
    if(this.state.secondary.id !== nextState.secondary.id){return true}
    if(this.state.compare !== nextState.compare){return true}
    return false
  }
  setPrimary(item){
    this.setState(prevState=>({
      primary : {
        ...prevState.primary,
        id : item.id,
        text : item.text
      }
    }))
  }
  setSecondary(item){
    this.setState(prevState=>({
      secondary : {
        ...prevState.secondary,
        id : item.id,
        text : item.text
      }
    }))
  }
  setComparing(e){
    this.setState({compare: e.target.checked})
  }
  compileStats(stats){
    const statsByDay = stats.reduce((byDay, stat)=>{
      if(!byDay[stat.Day]){byDay[stat.Day] = []}
      byDay[stat.Day].push(stat)
      return byDay
    },{})
    return Object.keys(statsByDay).map(day=>({
      xValue : day,
      [this.state.primary.id] : COMPILATION_BY_STAT[this.state.primary.id](statsByDay[day]),
      [this.state.secondary.id] : COMPILATION_BY_STAT[this.state.secondary.id](statsByDay[day])
    }))
    .sort((a,b)=>ArrayUtils.comp(a.xValue, b.xValue))
  }
  getXValueFormatter(){
    const weekDays = {
      0 : "sun",
      1 : "mon",
      2 : "tue",
      3 : "wed",
      4 : "thu",
      5 : "fri",
      6 : "sat",
    }
    return (dataKey, xValue)=>{
      const date = new Date(xValue)
      if(!date){return xValue}
      if(this.props.activeLanguage && this.props.activeLanguage.code === "fr"){
        return (
          this.props.translate("common.weekdays."+weekDays[date.getUTCDay()])+
          ". "+
          date.getUTCDate()+
          " "+
          this.props.translate("common.shortMonths."+date.getUTCMonth())+
          ". "+
          date.getUTCFullYear()
        ).toLowerCase()
      }
      return (
        this.props.translate("common.weekdays."+weekDays[date.getUTCDay()])+
        ", "+
        this.props.translate("common.shortMonths."+date.getUTCMonth())+
        " "+
        date.getUTCDate()+
        ", "+
        date.getUTCFullYear()
      )
    }
  }
  getYValueFormatter(){
    const DATA_KEY_TO_FORMATTER = {
      Impressions           : (yValue)=>NumFormatter.formatNumber(yValue, 2),
      Clicks                : (yValue)=>NumFormatter.formatNumber(yValue, 2),
      Cost                  : (yValue)=>NumFormatter.formatCurrency(yValue),
      Conversions           : (yValue)=>NumFormatter.formatNumber(yValue, 2),
      SearchImpressionShare : (yValue)=>NumFormatter.formatNumber(yValue, 2)+"%",
      SearchClickShare      : (yValue)=>NumFormatter.formatNumber(yValue, 2)+"%",
      CPC                   : (yValue)=>NumFormatter.formatCurrency(yValue),
      ConversionRate        : (yValue)=>NumFormatter.formatNumber(yValue, 2)+"%",
      CPCon                 : (yValue)=>NumFormatter.formatCurrency(yValue),
    }
    return (dataKey, yValue)=>{
      if(!DATA_KEY_TO_FORMATTER[dataKey]){throw Error(`DataKey ${dataKey} doesn't have a formatter`)}
      return DATA_KEY_TO_FORMATTER[dataKey](yValue)
    }
  }
  getDataKeyFormatter(){
    return (dataKey)=>{
      return ITEMS_TRANSLATION_KEY[dataKey] ? this.props.translate("dashboard.graph."+ITEMS_TRANSLATION_KEY[dataKey]) : dataKey
    }
  }

  render(){
    if(this.props.data===undefined){return <ExplainedLoading translateId="loadings.data"/>}
    const data = this.compileStats(this.props.data)
    const hasData = data.length > 0
    const symmetricalData = this.compileStats(this.props.symmetricalData)
    matchDataByWeekDay(data, symmetricalData)
    const items = Object.keys(COMPILATION_BY_STAT).map(index=>({
      id:index,
      text:ITEMS_TRANSLATION_KEY[index]?this.props.translate("dashboard.graph."+ITEMS_TRANSLATION_KEY[index]) : index
    }))
    const lines:any = [{
      name: this.state.primary.text,
      dataKey: this.state.primary.id,
      color: this.state.primary.color,
      strokeWidth: 2,
      showDot: false
    }]
    //Only add the second line if both selection is different
    if(this.state.secondary.id !== this.state.primary.id){
      lines.push({
        name: this.state.secondary.text,
        dataKey: this.state.secondary.id,
        color: this.state.secondary.color,
        strokeWidth: 2,
        showDot: false,
        axis:"right"
      })
    }
    return (
      <div className={"TwoLineGraph"}>
        <div className={"Header"} >
          <div className="Title">
            <h4><Translate id="dashboard.graph.title"/></h4>
          </div>
          <div style={hasData ? {} : {display: "none"}} className="GraphControls">
            <LineSelector
              items           = {items}
              itemToString    = {item => item.text}
              updatePrimary   = {this.setPrimary}
              updateSecondary = {this.setSecondary}
              label           = {{primary:this.state.primary.text,secondary:this.state.secondary.text}}
              fill            = {{primary:this.state.primary.color,secondary:this.state.secondary.color}}
            />
            <div style={hasData ? {} : {display: "none"}} className="ToggleButton">
              <Toggle
                id="Compare toggle"
                labelA=""
                labelB={this.props.translate("dashboard.graph.compare")}
                onChange={this.setComparing}
                toggled={this.state.compare}
                className={"CompareToggle"+(this.state.compare?" Toggled":"")}
              />
            </div>
          </div>
        </div>
        {
          hasData ?
            <div className = {"statPanelGraph"}>
              <DualAxisGraph
                width={"99%"}
                height={220}
                data={data}
                comparisonData={symmetricalData}
                lines={lines}
                compare={this.state.compare}
                doubleYAxis
                tooltipXValueFormatter={this.getXValueFormatter()}
                tooltipYValueFormatter={this.getYValueFormatter()}
                tooltipDataKeyFormatter={this.getDataKeyFormatter()}
              />
            </div>
          :
            <div className="NoData">
              <Typography>
                <Translate id="dashboard.graph.noData"/>
              </Typography>
            </div>
        }
      </div>
    )
  }
}
export default connect(makeMapStateToProps,mapDispatchToProps)(withLocalize(DashboardGraph))
