import ReducerUtils from "../../../utils/ReducerUtils"
import Actions      from "../../../model/constant/actions"

const setInfo = (state:FailedInfo, action):FailedInfo => {
  return {
    status : action.status,
    statusCode : action.statusCode,
    message : action.message
  }
}

export interface FailedInfo {
  status : boolean
  statusCode ?: number
  message ?: string
}
const FailedPacyV0Usage = ReducerUtils.createReducer<FailedInfo>({status: false},{
  [Actions.FAILED_RETRIEVING_PACY_V0USAGE]   : setInfo,
})

export default FailedPacyV0Usage
