import Actions                       from "../../../model/constant/actions"
import Authentification, {
  LoginReturn
}                                    from "../../../utils/Authentification"
import {retrieveUserPreferences}     from "../../../actions/user/userPreferences"
import {LogInLoggly}                 from "../../../actions/logger/Logger"
import Cookies                       from "js-cookie"
import Auth2, { UserReturn }         from "../../../utils/Auth2"
import { AxiosResponse }             from "axios"

const IS_DEV_ENV = process.env.NODE_ENV === 'development'

export const getUser = (email:string, password:string, rememberMe:boolean = false) => (dispatch) => {
  const payload = {
    action     : "Login",
    email      : email,
    password   : password,
    rememberMe : rememberMe
  }
  dispatch({
    type   : Actions.LOGGING_IN,
    status : true
  })
  Authentification.post(payload)
  .then((auth1Response:LoginReturn)=>{
    if(auth1Response.error && auth1Response.error.length > 0){
      throw Error("Auth error: " + (auth1Response.error || "Unknown problem with the server has occured"))
    }
    const userId          = auth1Response.data.user.id
    const token           = auth1Response.data.token
    const tokenValidUntil = auth1Response.data.tokenValidUntil
    const permissions     = auth1Response.data.permissions
    const rememberMeId    = auth1Response.data.rememberMe
    const userLevel       = auth1Response.data.user.userLevel

    Auth2.getUser(token, userId)
    .then((auth2Response: AxiosResponse<UserReturn>)=>{
      // Once we have the full profile, login is good
      LogInLoggly("Successful Login", {
        token,
        tokenValidUntil,
        user : auth2Response.data
      })
      if(rememberMe){
        Cookies.set("email",payload.email,{sameSite:'strict',secure:!IS_DEV_ENV})
        Cookies.set("UID",rememberMeId,{sameSite:'strict',secure:!IS_DEV_ENV})
      }
      dispatch({
        type : Actions.AUTHENTIFY_LOGIN_SUCCESS,
        user : {
          profile     : {...auth2Response.data, userLevel},
          token       : token,
          validUntil  : tokenValidUntil,
          permissions : permissions,
        }
      })
      dispatch(retrieveUserPreferences())
      dispatch({type: Actions.LOGGING_IN, status: false})
    })
    .catch((error)=>{
      throw Error("Auth2 error: " + (error.data || "Unknown problem with the server has occured"))
    })
  })
  .catch((error)=>{
    dispatch({
      type  : Actions.AUTHENTIFY_LOGIN_ERROR,
      error : error.message
    })
    dispatch({type: Actions.LOGGING_IN, status: false})
  })
}
