import Actions                  from "../model/constant/actions"
import GeoTargeting             from "../model/Store/GeoBid/GeoTargeting"
import GeoMapInfo               from "../model/Store/GeoBid/GeoMapInfo"
import Radius                   from "../model/Store/GeoBid/Radius"
import Place                    from "../model/Store/GeoBid/Place"
import GeoPosition              from "../model/Store/GeoBid/GeoPosition"
import VSGL                     from "../model/Store/Matcher/VSGL"
import CircleDefinition         from "../model/map/CircleDefinition"
import PlaceDefinition          from "../model/map/PlaceDefinition"
import UserActions              from "../model/constant/UserAction"
import {getGeoBid}              from "./GetGeoBid"
import LogBuilder               from "../controller/log/LogBuilder"
import {getToken}               from "../storeAccessor/Generic"
import ActionUtils              from "../utils/ActionUtils"
import Headstone                from "../utils/Headstone"
import { SetNewGeoParamBody }   from "../model/headstone/geoBids"

const convertRadiusToCircleDefinition = (radiusId, radius:Radius):CircleDefinition => {
  return {
    id : radiusId,
    edited : false,
    bidModifier : radius.bidModifier,
    circleProps : {
      center : {
        lat:radius.lat,
        lng:radius.lng
      },
      radius : radius.radius*1000,
      editable : true,
    }
  }
}

export const addCodeToQueue = (code:number) => {
  return {
    type : Actions.ADD_CODE_TO_GEOCODING_QUEUE,
    code : code
  }
}
export const addCodesToQueue = (codes:number[]) => {
  return {
    type  : Actions.ADD_CODES_TO_GEOCODING_QUEUE,
    codes : codes
  }
}
export const removeCodeFromQueue = (code:number) => {
  return {
    type : Actions.REMOVE_CODE_FROM_GEOCODING_QUEUE,
    code : code
  }
}
export const moveFirstCodeToEndOfQueue = () => {
  return {
    type : Actions.MOVE_FIRST_CODE_TO_END_OF_GEOCODING_QUEUE
  }
}
export const addPositionToCache = (AWCode:number, position:GeoPosition) => {
  return {
    type     : Actions.ADD_POSITION_TO_CACHE,
    code     : AWCode,
    position : position
  }
}

export const start = (geoTargeting:GeoTargeting) => {
  let geoMapInfo:GeoMapInfo = {
    id                   : -1,
    accountId            : geoTargeting.accountId,
    matcher              : geoTargeting.matcher,
    circles              : geoTargeting.radiuses.map((radius,i)=>convertRadiusToCircleDefinition(i, radius)),
    places               : [],
    initialPlacesPending : geoTargeting.places,
    placesPending        : [],
    edited               : false,
    selectedCircle       : -1
  }
  return {
    type       : Actions.START_EDITING_GEOBID,
    geoMapInfo : geoMapInfo
  }
}
export const cancel = (mapId:number) => {
  return {
    type  : Actions.CANCEL_EDITING_GEOBID,
    mapId : mapId
  }
}
export const addCircle = (circle:CircleDefinition, mapId:number) => {
  return {
    type   : Actions.ADD_CIRCLE_EDITING_GEOBID,
    circle : circle,
    mapId  : mapId
  }
}
export const addPendingPlace = (place:Place, mapId:number) => {
  return {
    type         : Actions.ADD_PENDING_PLACE_EDITING_GEOBID,
    placePending : place,
    mapId        : mapId
  }
}
export const addPlace = (place:PlaceDefinition, mapId:number) => {
  return {
    type  : Actions.ADD_PLACE_EDITING_GEOBID,
    place : place,
    mapId : mapId
  }
}
export const addInitialPlace = (place:PlaceDefinition, mapId:number) => {
  return {
    type  : Actions.ADD_INITIAL_PLACE_EDITING_GEOBID,
    place : place,
    mapId : mapId
  }
}
export const updateCircle = (circle:CircleDefinition, mapId:number) => {
  return {
    type   : Actions.UPDATE_CIRCLE_EDITING_GEOBID,
    circle : circle,
    mapId  : mapId
  }
}
export const updatePlace = (place, mapId:number) => {
  return {
    type  : Actions.UPDATE_PLACE_EDITING_GEOBID,
    place : place,
    mapId : mapId
  }
}
export const deleteCircle = (circle, mapId:number) => {
  return {
    type     : Actions.DELETE_CIRCLE_EDITING_GEOBID,
    circleId : circle.id,
    mapId    : mapId
  }
}
export const deletePlace = (place, mapId:number) => {
  return {
    type    : Actions.DELETE_PLACE_EDITING_GEOBID,
    placeId : place.id,
    mapId   : mapId
  }
}
export const selectCircle = (circleId, mapId:number) => {
  return {
    type     : Actions.SELECT_CIRCLE_EDITING_GEOBID,
    circleId : circleId,
    mapId    : mapId
  }
}
export const deselectCircle = (mapId:number) => {
  return {
    type  : Actions.DESELECT_CIRCLE_EDITING_GEOBID,
    mapId : mapId
  }
}

export const save = (geoMapInfo:GeoMapInfo, languages?:string[]) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.SAVE_GEO,token,geoMapInfo)
  let basePayload : SetNewGeoParamBody = {
    matcher : {
      instanceof : geoMapInfo.matcher.instanceof,
      group      : geoMapInfo.matcher.group,
      lang       : geoMapInfo.matcher.lang,
    },
    geoBids : [
      ...geoMapInfo.circles.map(circle=>({
        lat         : circle.circleProps.center.lat,
        long        : circle.circleProps.center.lng,
        radius      : circle.circleProps.radius/1000,
        bidModifier : circle.bidModifier
      })),
      ...geoMapInfo.places.map(place=>({
        code        : place.code,
        include     : place.include,
        bidModifier : place.bidModifier
      })),
    ]
  }
  if(geoMapInfo.matcher.vehicleType){
    basePayload.matcher = {
      ...basePayload.matcher,
      group       : geoMapInfo.matcher.group,
      vehicleType : geoMapInfo.matcher.vehicleType,
      state       : geoMapInfo.matcher.state,
    }
  }
  const buildLangPayload = (lang:string)=>({...basePayload, matcher: {...basePayload.matcher, lang}})
  Promise.all((languages||[geoMapInfo.matcher.lang]).map(lang=>
    ActionUtils.retryOnFailure(
      () => Headstone.setNewGeoParam(token, geoMapInfo.accountId, buildLangPayload(lang))
    )
  ))
  .then((response) => dispatch(getGeoBid(geoMapInfo.accountId)))
  .catch((error) => console.log(error))
}

export const replaceGeos = (accountId:number, replacedMatchers:VSGL[], geoTargeting:GeoTargeting) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.REPLACE_GEOS,token,{accountId, replacedMatchers, geoTargeting})

  dispatch({
    type : Actions.RETRIEVING_GEOBIDS,
    status : true
  })
  Promise.all(replacedMatchers.map(matcher=>{
    let payload : SetNewGeoParamBody = {
      matcher: {
        instanceof : matcher.instanceof,
        group      : matcher.group,
        lang       : matcher.lang,
      },
      geoBids: [
        ...geoTargeting.radiuses.map(radius=>({
          lat         : radius.lat,
          long        : radius.lng,
          radius      : radius.radius,
          bidModifier : radius.bidModifier
        })),
        ...geoTargeting.places.map(place=>({
          code        : place.code,
          include     : place.include,
          bidModifier : place.bidModifier
        })),
      ]
    }

    if(matcher.vehicleType){
      payload.matcher = {
        ...payload.matcher,
        group       : matcher.group,
        vehicleType : matcher.vehicleType,
        state       : matcher.state,
      }
    }

    return ActionUtils.retryOnFailure(
      () => Headstone.setNewGeoParam(token, accountId, payload)
    )
  }))
  .then(()=>{
    dispatch(getGeoBid(accountId))
  })
  .catch((error) => console.log(error))
}
