import ArrayUtils              from '../../utils/ArrayUtils'
import {SelectorAbstract}      from './Selector'
import {SelectorValueType}     from './Selector'
import {SelectorOperator}      from './Selector'
import {SelectorStandardName}  from './Selector'
import Makes                   from '../constant/Makes'
import Models                  from '../constant/Models'

const possibleSelectors : SelectorAbstract[] = [
  {
    name            : SelectorStandardName.NumberOfPhotos,
    niceName        : "Number of Pictures",
    niceNameId      : "rules.picturesRule",
    icon            : "photo_library",
    operators       : [
      SelectorOperator.AT_LEAST,
      SelectorOperator.AT_MOST,
    ],
    valueType       : SelectorValueType.POSITIVE_NUMBER,
    defaultValue    : ()=> 5,
    defaultOperator : SelectorOperator.AT_LEAST,
  },
  {
    name            : SelectorStandardName.NumberOfDays,
    niceName        : "Vehicle's number of days in inventory",
    niceNameId      : "rules.daysRule",
    icon            : "date_range",
    operators       : [
      SelectorOperator.AT_LEAST,
      SelectorOperator.AT_MOST,
    ],
    valueType       : SelectorValueType.POSITIVE_NUMBER,
    defaultValue    : ()=> 60,
    defaultOperator : SelectorOperator.AT_MOST,
  },
  {
    name            : SelectorStandardName.Make,
    niceName        : "Vehicle's Make",
    niceNameId      : "rules.makeRule",
    icon            : "emoji_transportation",
    operators       : [
      SelectorOperator.IS_NOT,
      SelectorOperator.IS
    ],
    valueType       : SelectorValueType.NOT_EMPTY_STRING,
    defaultValue    : ()=> ArrayUtils.randomChoice(Makes),
    defaultOperator : SelectorOperator.IS_NOT,
  },
  {
    name            : SelectorStandardName.Model,
    niceName        : "Vehicle's Model",
    niceNameId      : "rules.modelRule",
    icon            : "drive_eta",
    operators       : [
      SelectorOperator.IS_NOT,
      SelectorOperator.IS,
      SelectorOperator.IS_NOT_REGEX,
      SelectorOperator.IS_REGEX,
    ],
    valueType       : SelectorValueType.NOT_EMPTY_STRING,
    defaultValue    : ()=> ArrayUtils.randomChoice(Models),
    defaultOperator : SelectorOperator.IS_NOT,
  },
  {
    name            : SelectorStandardName.StockNumber,
    niceName        : "Vehicle's Stock Number",
    niceNameId      : "rules.stockNumberRule",
    icon            : "short_text",
    operators       : [
      SelectorOperator.IS_NOT,
      SelectorOperator.IS,
      SelectorOperator.IS_NOT_REGEX,
      SelectorOperator.IS_REGEX,
    ],
    valueType       : SelectorValueType.NOT_EMPTY_STRING,
    defaultValue    : () => "ABC-123",
    defaultOperator : SelectorOperator.IS_NOT
  },
  {
    name            : SelectorStandardName.Year,
    niceName        : "Vehicle's Year",
    niceNameId      : "rules.yearRule",
    icon            : "update",
    operators       : [
      SelectorOperator.AT_LEAST,
      SelectorOperator.AT_MOST,
      SelectorOperator.IS_NOT,
      SelectorOperator.IS,
    ],
    valueType       : SelectorValueType.POSITIVE_NUMBER,
    defaultValue    : ()=> (new Date()).getFullYear()-2,
    defaultOperator : SelectorOperator.AT_MOST,
  },
  {
    name            : SelectorStandardName.Price,
    niceName        : "Vehicle's Price",
    niceNameId      : "rules.priceRule",
    icon            : "attach_money",
    operators       : [
      SelectorOperator.AT_LEAST,
      SelectorOperator.AT_MOST,
    ],
    valueType       : SelectorValueType.POSITIVE_NUMBER,
    defaultValue    : ()=> 10000,
    defaultOperator : SelectorOperator.AT_MOST,
  },
]
export default possibleSelectors
