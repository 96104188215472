import './CreateProxyGroup.css'
import * as React                           from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Divider,
  TextField,
  Switch,
  Icon
}                                           from '@material-ui/core'
import {
  AccountModifier,
  createAddProxyGroupAccountModifier
}                                           from '../../../../../model/pacy/AccountModifiers'
import {
  Translate,
  TranslateFunction,
  withLocalize,
}                                           from 'react-localize-redux'
import Hint                                 from '../../../../../components/Hint/Hint'

interface CreateProxyGroupOwnProps {
  closeModal          : () => void
  addAccountModifiers : (newActions:AccountModifier[]) => void
  accountId           : number
}
interface CreateProxyGroupProps extends CreateProxyGroupOwnProps {
  translate : TranslateFunction
}

const CreateProxyGroup = (props:CreateProxyGroupProps) => {
  const [name,setName] = React.useState('')
  const handleNameChange = (event) => {
    if(event.target.value.length <= 128) {
      setName(event.target.value)
    }
  }
  const [description,setDescription] = React.useState('')
  const handleDescriptionChange = (event) => {
    if(event.target.value.length <= 256) {setDescription(event.target.value)}
  }
  const [sharing,setSharing] = React.useState(false)
  const handleSetSharing = (event) => setSharing(event.target.checked)
  const handleSetSharingButton = () => setSharing(prevState=>prevState ? !prevState : true)
  const handleProxyGroupConfirm = () => {
    props.addAccountModifiers([createAddProxyGroupAccountModifier({accountId:props.accountId ,name:name,description:description,sharing:sharing})])
  }
  return (
    <Card className="CreateProxyGroup">
      <CardHeader
        title={
          <Typography style={{display:'flex',alignItems:'center'}} variant="h6">
            <Icon style={{marginRight:5}}>add</Icon>
            <Translate id="pacyConfigs.createProxyGroup" />
          </Typography>
        }
      />
      <Divider />
      <CardContent className="Content">
        <div className="FirstRow">
          <div className="Name">
            <TextField fullWidth label={<Translate id="pacyConfigs.name"/>} variant="outlined" value={name} onChange={handleNameChange} />
          </div>
          <div className="Sharing">
            <Button onClick={handleSetSharingButton} size="large" variant="outlined" color="primary">
              <Icon>call_split</Icon>
              <Translate id="pacyConfigs.sharing" />
              <Switch color="primary" size="medium" checked={sharing} onChange={handleSetSharing}/>
            </Button>
          </div>
        </div>
        <div className="Description">
          <TextField label={<Translate id="pacyConfigs.description"/>} variant="outlined" value={description} onChange={handleDescriptionChange} multiline rows={2} fullWidth />
        </div>
      </CardContent>
      <Divider />
      <CardActions className="CreateProxyGroupFooter">
        <Hint hint={props.translate('pacyConfigs.sharingHint') as string} />
        <div className="Buttons">
          <Button onClick={props.closeModal} color="secondary" variant="outlined" size="large" >
            <Translate id="common.cancel"/>
          </Button>
          <Button disabled={!name} onClick={()=>{handleProxyGroupConfirm();props.closeModal()}} style={{color:'white'}} color="primary" variant="contained" size="large" >
            <Translate id="pacyConfigs.done" />
          </Button>
        </div>
      </CardActions>
    </Card>
  )
}

export default withLocalize(CreateProxyGroup as any) as React.ComponentType<CreateProxyGroupOwnProps>
