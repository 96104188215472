import ReducerUtils from "../../utils/ReducerUtils"
import ArrayUtils from "../../utils/ArrayUtils"
import Actions from "../../model/constant/actions"
import Audience from "../../model/Store/Audiences/AWSearch"

const saveAudiences = (state:AudiencesByAccount, action) => {
  if(!state[action.accountId]){
    return {
      ...state,
      [action.accountId] : action.audiences
    }
  }
  const newAudiences = ArrayUtils.mergeBySerialization(
    state[action.accountId],
    action.audiences,
    (audience:Audience)=>audience.id+""
  )
  return {
    ...state,
    [action.accountId] : newAudiences
  }
}

interface AudiencesByAccount{
  [accountId:number] : Audience[]
}

const AWSearch = ReducerUtils.createReducer<AudiencesByAccount>({},{
  [Actions.RETRIEVE_AWSEARCH_AUDIENCES] : saveAudiences
})

export default AWSearch
