import styles                     from './EditorStyles'
import * as React                 from 'react'
import AdCopies                   from '../../adcopy/components/AdCopies'
import Tag                        from '../../adcopy/components/Tag'
import {
  Icon,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  Checkbox
}                                 from '@material-ui/core'
import AdCopyEditingForm          from '../../../components/adCopy/AdCopyEditingForm'
import {
  Translate,
  LocalizeContextProps,
  withLocalize
}                                 from 'react-localize-redux'
import {
  AdCopyParents,
  AdgroupsToCopy
}                                 from '../../../model/adcopy'
import InventoryTester            from '../../../components/adCopy/InventoryTesterRSA'
import {RSAAdTemplate}            from '../../../model/adbuilder'
import {Tooltip}                  from "react-tippy"
import PriorityHighIcon           from "@material-ui/icons/PriorityHigh"
import {ErrorData}                from "../../../components/input/LengthLimitedTextInput"
import {calculateLengthForEditor} from '../utils/FindAdCopyLength'

interface AdCopyEditorProps extends LocalizeContextProps{
  templates                      : RSAAdTemplate[]
  tags                           : string[]
  lang                           : string
  handleCancel                   : () => void
  availableCopyOptions           : AdCopyParents
  checkForErrors                 : () => void
  saveFunction                   : (templates:RSAAdTemplate[])=>void
  saveAndClose                   : (templates:RSAAdTemplate[])=>void
  readOnly                       : boolean
  adgroupsToCopy                 : (value:boolean, strategy:string, adgroup:string, allAdgroupsOptions?:{[adgroup:string]:boolean}) => void
  inventoryGroup                 : string
  errorsInTemplates              : ErrorData[]
  resetAdgroupsToCopy            : () => void
}

const AdCopyEditor = (props:AdCopyEditorProps) => {
  // State
  const [newAdCopyId,setNewAdCopyId] = React.useState<number>(0)
  const [templates,setTemplates] = React.useState<RSAAdTemplate[]>(props.templates)
  const [selectedTemplateId,setSelectedTemplateId] = React.useState(templates[0].id)
  const selectedTemplate = templates.find(template=>template.id === selectedTemplateId)
  const [hasError, setHasError] = React.useState<boolean>(false)
  const [idsToDelete, setIdsToDelete] = React.useState<number[]>([])
  const [adgroupsToCopy, setAdgroupsToCopy] = React.useState<AdgroupsToCopy>(
    Object.keys(props.availableCopyOptions).reduce((o, strategy) => 
      ({...o, [strategy]: Object.keys(props.availableCopyOptions[strategy]).reduce((o, adgroup) => 
        ({...o, [adgroup]: false}),{
          "All adgroups" : false
        })
      }),{}
    )
  )

  //Sync the templates state with the props
  React.useEffect(()=>{
    setTemplates(props.templates)
    setSelectedTemplateId(props.templates[0].id)
    // Reset the adgroups to copy state when switching templates
    setAdgroupsToCopy(Object.keys(props.availableCopyOptions).reduce((o, strategy) =>
      ({...o, [strategy]: Object.keys(props.availableCopyOptions[strategy]).reduce((o, adgroup) =>
        ({...o, [adgroup]: false}),{
          "All adgroups" : false
        })
      }),{}
    ))
    props.resetAdgroupsToCopy()
  },[props.templates])

  // Notify parent that we need to scan for errors again
  React.useEffect(()=>{
    props.checkForErrors()
  },[templates])

  // If the current campaign group being edited is some inventory group,
  // We need to apply copying restrictions, because not all tags are 
  // available for all strategies
  // This method will trim the available strategies and adgroups to what
  // is permitted.
  // *includes language consideration
  const provideInventoryCopyingOptions = ():AdCopyParents => {
    let availableCopyOptions:AdCopyParents = {}
    if (props.inventoryGroup === "VDP" || props.inventoryGroup === "SRP_MMY"){
      for (let strategy of Object.keys(props.availableCopyOptions)){
        if (strategy !== "SRP_MM"){
          for (let adgroup of Object.keys(props.availableCopyOptions[strategy])){
            if (props.availableCopyOptions[strategy][adgroup].includes(props.lang)){
              availableCopyOptions = {
                ...availableCopyOptions,
                [strategy] : props.availableCopyOptions[strategy]
              }       
            }
          }
        }
      }
    }
    if (props.inventoryGroup === "SRP_MM"){
      for (let strategy of Object.keys(props.availableCopyOptions)){
        if (strategy === "SRP_MM"){
          for (let adgroup of Object.keys(props.availableCopyOptions[strategy])){
            if (props.availableCopyOptions[strategy][adgroup].includes(props.lang)){
              availableCopyOptions = {
                ...availableCopyOptions,
                [strategy] : props.availableCopyOptions[strategy]
              }            
            }
          }
        }
      }
    }
    return availableCopyOptions
  }

  const availableCopyOptions = provideInventoryCopyingOptions()

  const handleClick = (event) => {
    setSelectedTemplateId(parseInt(event.currentTarget.id,10))
  }

  const onHeadlineUpdate = (id:number, text:string, position?:number) => {
    setTemplates(prevState=>{
      return prevState.map(template=>{
        if(template.id === selectedTemplateId) {
          const temp = {
            ...template,
            descriptions:[...template.descriptions],
            headlines:[...template.headlines]
          } as RSAAdTemplate
          temp.headlines.find(x=>x.id===id).text = text
          temp.headlines.find(x=>x.id===id).position = position ? position : temp.headlines.find(x=>x.id===id).position
          return temp
        }
        return template
      })
    })
  }

  const onDescriptionUpdate = (id:number, text:string, position?:number) => {
    setTemplates(prevState=>{
      return prevState.map(template=>{
        if(template.id === selectedTemplateId) {
          const temp = {
            ...template,
            descriptions:[...template.descriptions],
            headlines:[...template.headlines]
          } as RSAAdTemplate
          temp.descriptions.find(x=>x.id===id).text = text
          temp.descriptions.find(x=>x.id===id).position = position ? position : temp.descriptions.find(x=>x.id===id).position
          return temp
        }
        return template
      })
    })
  }

  const createNewAdCopy = () => {
    setTemplates(prevState=>{
      return [{id:newAdCopyId,headlines:[],descriptions:[]},...prevState]
    })
    setSelectedTemplateId(newAdCopyId)
    setNewAdCopyId(prevState => prevState+1)
  }

  const handleNewHeadline = () => {
    setTemplates(prev=>{
      return prev.map(template=>{
        if (template.id === selectedTemplateId) {
          template.headlines.push({text: "", position: -1, id: template.headlines.length+1})
        }
        return template
      })
    })
  }

  const handleNewDescription = () => {
    setTemplates(prev=>{
      return prev.map(template=>{
        if (template.id === selectedTemplateId) {
          template.descriptions.push({text: "", position: -1, id: template.descriptions.length+1})
        }
        return template
      })
    })
  }

  const handleCopySelection = (value:boolean, strategy:string, adgroup:string) => {
    if (adgroup === "All adgroups"){
      const copy = JSON.parse(JSON.stringify(adgroupsToCopy[strategy]))
      Object.keys(copy).forEach(x=>
        copy[x] = value
      )
      setAdgroupsToCopy(prev=>{
        return {
          ...prev,
          [strategy] : {...copy}
        }
      })
      props.adgroupsToCopy(value, strategy, adgroup, {...copy})
    } else {
      setAdgroupsToCopy(prev=>{
        return {
          ...prev,
          [strategy] : {
            ...prev[strategy], 
            [adgroup] : value
          }
        }
      })    
      props.adgroupsToCopy(value, strategy, adgroup)
    }
  }

  const generateAdgroupToCopyFeedback = ():JSX.Element[] => {
    // This method allows us to display which adgroups are about to be copied to
    // under the copy select component.
    // If we are using copy to All adgroups, don't display all of the specific adgroups,
    // just display All adgroups.
    const toDisplay:string[] = []
    Object.keys(adgroupsToCopy).forEach(strategy=>{
      for (let adgroup of Object.keys(adgroupsToCopy[strategy])){
        if (adgroup==="All adgroups" && adgroupsToCopy[strategy][adgroup]){
          toDisplay.push(strategy + " > " + adgroup)
          break
        }
        if (adgroupsToCopy[strategy][adgroup]){
          toDisplay.push(strategy + " > " + adgroup)
        }            
      }
    })
    return toDisplay.map(x=><p key={x}>{x}</p>)
  }

  const handleSetToDelete = (id:number) => {
    if (idsToDelete.includes(id)){
      setIdsToDelete(idsToDelete.filter(x=>x!==id))
    } else {
      setIdsToDelete(prev=>[...prev, id])
    }
  }

  const handleSaveAndClose = () => {
    const templatesToSave = templates.filter(template=>!idsToDelete.includes(template.id))
    props.saveAndClose(templatesToSave)
  }

  const markedForDeletion = idsToDelete.some(value => value === selectedTemplateId)
  const classes = styles()
  return (
    <div>
      <div className={classes.adCopyEditor}>
        <div className={classes.adcopies}>
          <div className={classes.adCopiesCards}>
            <AdCopies
              templates={templates}
              selectedTemplateId={selectedTemplateId}
              setSelectedTemplate={handleClick}
              setToDelete={handleSetToDelete}
              idsToDelete={idsToDelete}
            />
          </div>
          {templates.length < 3 &&
            <div onClick={createNewAdCopy} className={classes.add}>
              <p className={classes.icon}><Icon fontSize="large">add_circle</Icon></p>
              <p className={classes.addLabel}><Translate id="adCopies.addNew" /></p>
            </div>
          }
        </div>
        <div className={classes.form}>
          <AdCopyEditingForm
            lang={props.lang}
            headlines={selectedTemplate.headlines}
            descriptions={selectedTemplate.descriptions}
            onHeadlineUpdate={onHeadlineUpdate}
            onDescriptionUpdate={onDescriptionUpdate}
            newHeadline={handleNewHeadline}
            newDescription={handleNewDescription}
            showErrorText
            tags={props.tags}
            textLengthCalculation={(text)=>calculateLengthForEditor(text)}
            onError={(errorsOnTemplate)=>!markedForDeletion ? setHasError(errorsOnTemplate) : null}
            onPinError={(pinError)=>setHasError(pinError)}
          />
        </div>
        <div className={classes.tagsAndCopy}>
          <div className={classes.tags}>
            <p className={classes.tagTitle}><Translate id="adCopies.tagTitle" /></p>
            {props.tags.map((tag,i)=>
              <Tag key={i} tag={tag}/>
            )}
          </div>
          <div className={classes.copier}>
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.label}><Translate id="adCopies.copy" /></InputLabel>
              <Select
                className={classes.selects}
              >
                {Object.keys(availableCopyOptions).map(strategy=>{
                  return (
                    <div key={strategy}>
                      <ListSubheader><Translate id="adCopies.strategy" />: {strategy}</ListSubheader>
                      { Object.keys(availableCopyOptions?.[strategy]).length > 1 &&
                        <MenuItem value={"All adgroups"}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={adgroupsToCopy[strategy]["All adgroups"]}
                                color="primary"
                                onChange={(_e,c)=>handleCopySelection(c, strategy,"All adgroups")}
                              />
                            }
                            label={props.translate("adCopies.allAdgroups")}
                          />
                        </MenuItem>
                      }
                      {Object.keys(availableCopyOptions?.[strategy] ?? []).map(adgroup=>{
                        return <MenuItem key={adgroup} value={adgroup}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={adgroupsToCopy[strategy][adgroup]}
                                color="primary"
                                onChange={(_e,c)=>handleCopySelection(c, strategy,adgroup)}
                              />
                            }
                            label={adgroup}
                          />
                        </MenuItem>            
                      })}
                    </div>
                  )
                })}
              </Select>
            </FormControl>
            <div className={classes.adGroupsToCopy}>
            { generateAdgroupToCopyFeedback() }
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          onClick={props.handleCancel}
          variant="outlined"
          size="large"
        >
          <Translate id="adBuilder.createEvent.previousStep" />
        </Button>
        {props.errorsInTemplates.length > 0 &&
          <Tooltip
            className={classes.tooltip}
            html={(
              <div className={classes.errors}>
                {props.errorsInTemplates.map((x,i)=>
                  <div key={`${x.text} ${i}`}>
                    - {props.translate(x.text, x.data)}
                  </div>
                )}
              </div>
            )}
            delay={[450,0]}
            position="top"
            followCursor={false}
          >
            <PriorityHighIcon className={classes.iconError}>priority_high</PriorityHighIcon>
          </Tooltip>
        }
        <Button
          className={classes.button}
          onClick={handleSaveAndClose}
          size="large"
          variant="contained"
          disabled={props.readOnly || hasError || props.errorsInTemplates.length>0}
        >
          <Translate id="adBuilder.createEvent.publishAndClose" />
        </Button>
      </div>
      <InventoryTester
        adCopy={selectedTemplate}
        maxChar={{
          headlines    : 30,
          descriptions : 90
        }}
        lang={props.lang}
        state={"new"}
      />
    </div>
  )
}

export default withLocalize(AdCopyEditor)
