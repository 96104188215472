import Actions      from "../../../../model/constant/actions"
import ReducerUtils from "../../../../utils/ReducerUtils"

const addShapes = (state, action) => {
  return [
    ...state,
    ...action.data
  ]
}
const removeShape = (state, action) => {
  return state.filter(x=>x.id != action.shapeId)
}
interface Shape{
  id       : string
  name     : string
  code     : number
  polygons : number[][][]
}
const UniqueGreenShapes = ReducerUtils.createReducer<Shape[]>([],{
  [Actions.RETRIEVE_UNIQUE_GREEN_SHAPES] : addShapes,
  [Actions.REMOVE_UNIQUE_GREEN_SHAPE]    : removeShape
})
export default UniqueGreenShapes
