import ReducerUtils                         from "../../../utils/ReducerUtils"
import Actions                              from "../../../model/constant/actions"
import {AdGroupConfigsByLang}               from "../../../model/branding/AdGroupConfigs"
import {AdGroupConfigsByAccountId}          from "../../../model/branding/AdGroupConfigs"

const saveData = (state,action:{adGroupConfig: AdGroupConfigsByLang, accountId: number}):AdGroupConfigsByAccountId => {
  return {
    ...state,
    [action.accountId] : {
      ...(state[action.accountId]||{}),
      ...action.adGroupConfig
    }
  }
}

const retrieveAdGroupConfigs = ReducerUtils.createReducer<AdGroupConfigsByAccountId>({},{
  [Actions.RETRIEVE_AD_GROUP_CONFIG] : saveData,
})

export default retrieveAdGroupConfigs
