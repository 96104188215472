import {combineReducers} from "redux"
import Dates             from "./Dates"
import Users             from "./Users"
import ProxyGroups       from "./ProxyGroups"
import DecisionTypes     from "./DecisionTypes"


export default combineReducers({
  Dates,
  Users,
  ProxyGroups,
  DecisionTypes,
})
