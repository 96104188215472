import './ColumnDefaultFilter.css'
import * as React                from "react"
import {withLocalize}            from "react-localize-redux"
import ArrayUtils                from "../../../../utils/ArrayUtils"
import {TextInput}               from "carbon-components-react"

interface ColumnDefaultFilterProps{
  originalFilter : any
  editedFilter   : any
  setFilter      : (value)=>void
  translate     ?: (translateId:string)=>string
}
const ColumnDefaultFilter = (props:ColumnDefaultFilterProps) => {
  if(props.originalFilter === undefined){return null}
  if(typeof props.originalFilter == "string"){
    return(
      <TextInput
        className={"TemplateFiltersInput"+(props.originalFilter!=props.editedFilter?" Edited":"")}
        id={"defaultFilter"}
        labelText=""
        value={props.editedFilter}
        onChange={(e)=>props.setFilter(e.target.value)}
      />
    )
  }
  if(typeof props.originalFilter == "object"){
    return(
      <select onChange={(e)=>props.setFilter(e.currentTarget.value)} value={props.editedFilter.value}>
        <option value={""}>
          {props.translate("reports.ads.editor.noFilter")}
        </option>
        {ArrayUtils.getArrayFromObject(props.originalFilter.choices).map((choice,i)=>
          <option key={i} value={choice}>{choice}</option>
        )}
      </select>
    )
  }
  return null
}

export default withLocalize(ColumnDefaultFilter as any) as any
