import Actions            from "../../model/constant/actions"
import ReducerUtils       from "../../utils/ReducerUtils"
import NumberFormatter    from "../../utils/NumberFormatter"


const modifySelectedAccountsByList = (state, action) => {
  NumberFormatter.setCurrency(action.currency)
  return action.id
}
const handleNewAccounts = (state,action) => {
  if(!state && action.accounts.length === 1){
    return action.accounts[0].id
  }
  return state
}
export const SelectedAccounts = ReducerUtils.createReducer<number>(null,{
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST] : modifySelectedAccountsByList,
  [Actions.RETRIEVE_ACCOUNTS_FOR_TOKEN]      : handleNewAccounts
})
