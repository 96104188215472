import * as React             from "react"
import {Translate}            from "react-localize-redux"


interface AccessDeniedProps {
  messageTranslateId ?: string
}

const AccessDenied = (props: AccessDeniedProps) => {
  return (
    <div><Translate id={props.messageTranslateId ?? "common.accessDenied"} /></div>
  )
}

export default AccessDenied as React.ComponentType<AccessDeniedProps>
