import * as React                                       from "react"
import {connect}                                        from "react-redux"
import {
  retrieveShapesWithSameCode,
  chooseShapeWithSameCode
}                                                       from "../../../actions/shapes/ShapeValidation"
import {addCodeToQueue}                                 from "../../../actions/EditGeoBid"
import ArrayUtils                                       from "../../../utils/ArrayUtils"
import {Button}                                         from "carbon-components-react"
import MapBuilder                                       from "../../../components/Map/MapBuilder"
import ExplainedLoading                                 from "../../../components/loading"
import {Panel}                                          from "../../../components/Panel/Panel"
import GoBack                                           from "../../../components/Button/ButtonGoBack"

interface ShapesWithSameCodeChooserOwnProps{
  exit ?: ()=>void
}
interface ShapesWithSameCodeChooserProps extends ShapesWithSameCodeChooserOwnProps{
  groupedShapes  : any
  cache          : any
  metaCache      : any
  AWPlaces       : any
  loadShapes     : ()=>void
  addCodeToQueue : (code:number)=>void
  chooseShape  : (shapeId:number, code:number)=>void
}
const mapStateToProps = (state,ownProps:ShapesWithSameCodeChooserOwnProps)=>{
  return {
    groupedShapes : state.Params.GeoBids.Shape.ShapesWithSameCode,
    cache         : state.Params.GeoBids.GeocodingCache,
    metaCache     : state.Params.GeoBids.GeocodingMetaCache,
    AWPlaces      : state.Params.GeoBids.AdWordsPlaces,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadShapes     : ()=>dispatch(retrieveShapesWithSameCode()),
    chooseShape    : (shapeId:number, code:number)=>dispatch(chooseShapeWithSameCode(shapeId, code)),
    addCodeToQueue : (code:number)=>dispatch(addCodeToQueue(code))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
interface ShapesWithSameCodeChooserState{
  currentShapes ?: any[]
  activeShapes ?: number[]
  score : number
}
const COLOR_POOL = [
  "blue",
  "red",
  "yellow",
  "green",
]
class ShapesWithSameCodeChooser extends React.Component<ShapesWithSameCodeChooserProps,ShapesWithSameCodeChooserState>{

  constructor(props){
    super(props)
    this.state = {
      score : 0
    }
    this.handleChoice = this.handleChoice.bind(this)
    this.changeCurrentColors = this.changeCurrentColors.bind(this)
  }
  static getDerivedStateFromProps(props, state){
    const keys = Object.keys(props.groupedShapes)
    if(state.currentShapes === undefined && keys.length > 0){
      const colors = ArrayUtils.shuffle(COLOR_POOL)
      let active = []
      return {
        currentShapes : props.groupedShapes[keys[0]].map((x,i)=>{
          active.push(i)
          return {
            ...x,
            strokeColor : colors[i],
            fillColor : colors[i],
          }
        }),
        activeShapes : active
      }
    }
    return null
  }
  componentDidMount(){
    if(Object.keys(this.props.groupedShapes).length === 0){
      this.props.loadShapes()
    }
  }
  componentDidUpdate(prevProps){
    if(Object.keys(prevProps.groupedShapes).length === 1 && Object.keys(this.props.groupedShapes).length === 0){
      this.props.loadShapes()
      this.setState({currentShapes:undefined})
    }
    else if(Object.keys(prevProps.groupedShapes).length !== Object.keys(this.props.groupedShapes).length
            && Object.keys(this.props.groupedShapes).length > 0
    ){
      const keys = Object.keys(this.props.groupedShapes)
      const colors = ArrayUtils.shuffle(COLOR_POOL)
      let active = []
      this.setState({
        currentShapes : this.props.groupedShapes[keys[0]].map((x,i)=>{
          active.push(i)
          return {
            ...x,
            strokeColor : colors[i],
            fillColor : colors[i],
          }
        }),
        activeShapes : active
      })
    }
    if(Object.keys(prevProps.groupedShapes).length === 0 && Object.keys(this.props.groupedShapes).length > 0){
      for(let codeString of Object.keys(this.props.groupedShapes)){
        const code = parseInt(codeString)
        if(!this.props.cache[code] && !this.props.metaCache[code]){
          this.props.addCodeToQueue(code)
        }
      }
    }
  }
  handleChoice(shape:any){
    this.props.chooseShape(shape.id, shape.code)
    this.setState(prevState=>({
      currentShapes:undefined,
      score : prevState.score+1
    }))
  }
  changeCurrentColors(){
    const colors = ArrayUtils.shuffle(COLOR_POOL)
    this.setState(prevState=>({
      currentShapes : prevState.currentShapes.map((x,i)=>({
        ...x,
        strokeColor : colors[i],
        fillColor : colors[i],
      }))
    }))
  }
  toggleShape(shapeIndex){
    if(!ArrayUtils.contain(this.state.activeShapes, shapeIndex)){
      this.setState(prevState=>({
        activeShapes : [...prevState.activeShapes, shapeIndex]
      }))
    }
    else{
      this.setState(prevState=>({
        activeShapes : prevState.activeShapes.filter(x=>x!=shapeIndex)
      }))
    }
  }
  render(){
    if(!this.state.currentShapes){return <ExplainedLoading text="Waiting for shapes"/>}
    const currentCode = this.state.currentShapes[0].code
    if(!this.props.cache[currentCode] && !this.props.metaCache[currentCode]){
      return <ExplainedLoading text="Waiting for geocoding"/>
    }
    const currentPlaceAWInfo = this.props.AWPlaces.find(x=>x[0] == currentCode)
    const placeOfShapes = {
      id : currentCode,
      code : currentCode,
      edited : false,
      include : true,
      address : "",
      bidModifier : 1,
      placeProps : {
        position : (
          this.props.metaCache[currentCode]
          ? this.props.metaCache[currentCode]
          : this.props.cache[currentCode]
        ),
        editable : false
      }
    }
    console.log(placeOfShapes)
    console.log(this.state.currentShapes)
    return (
      <Panel title="ShapesWithSameCodeChooser" subtitle="Which of those shapes is the right one for the given code?">
        <GoBack onClick={this.props.exit}/>
        <h4>Score: {this.state.score}</h4>
        <h3>{this.state.currentShapes[0].name}</h3>
        <div>
          This place's information from Google Ads
          <br/>
          {JSON.stringify(currentPlaceAWInfo, null, 2)}
        </div>
        <Button onClick={this.changeCurrentColors}>Change colors</Button>
        <MapBuilder
          placesDefinitions={[placeOfShapes]}
          standaloneShapes={this.state.currentShapes.filter((x,i)=>ArrayUtils.contain(this.state.activeShapes, i))}
          displayPinAndShape
        />
        <div>
          {this.state.currentShapes.map((x,i)=>
            <div key={x.id} style={{display:"grid", gridColumnGap:"10px", gridTemplateColumns:"10px 100px"}}>
              <input
                type="checkbox"
                onChange={e=>this.toggleShape(i)}
                checked={ArrayUtils.contain(this.state.activeShapes, i)}
              />
              <Button
                onClick={()=>this.handleChoice(x)}
                style={{backgroundColor:x.fillColor}}
              >
                Shape {i+1}
              </Button>
            </div>
          )}
        </div>
      </Panel>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(ShapesWithSameCodeChooser)
