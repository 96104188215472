import Pacy                                              from "../../utils/Pacy"
import DateUtils                                         from "../../utils/DateUtils"
import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import UserActions                                       from "../../model/constant/UserAction"
import Period                                            from "../../model/Period"
import {Guideline}                                       from "../../model/pacy/Guideline"
import {getToken}                                        from "../../storeAccessor/Generic"
import LogBuilder                                        from "../../controller/log/LogBuilder"

const extractMessageFromError = (error:any) => {
  if(error.response && error.response.data){
    return error.response.data.error
  }
}

export const migrate = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.PACY_MIGRATION_RUNNING, accountId, status: true})
  const token = getToken(getState())
  Pacy.migrateAccount(token, accountId)
  .then(()=>{
    dispatch(enable(accountId))
  }).catch((error)=>{
    if(error.response.status === 409){
      dispatch({type: Actions.PACY_MIGRATION_ALREADY_MIGRATED, accountId, status: true})
    }
    else{
      const errorMessage = extractMessageFromError(error) || "An unknown error has occured"
      dispatch({type: Actions.PACY_MIGRATION_ERROR, accountId, error: errorMessage})
    }
    dispatch({type: Actions.PACY_MIGRATION_RUNNING, accountId, status: false})
  })
}

export const migrateAgain = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.PACY_MIGRATION_RUNNING, accountId, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(()=>
    Pacy.deleteAccount(token, accountId)
  ).then(()=>{
    dispatch(migrate(accountId))
  }).catch((error)=>{
    const errorMessage = extractMessageFromError(error) || "An unknown error has occured"
    dispatch({type: Actions.PACY_MIGRATION_ERROR, accountId, error: errorMessage})
    dispatch({type: Actions.PACY_MIGRATION_RUNNING, accountId, status: false})
  })
}

export const enable = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.PACY_MIGRATION_RUNNING, accountId, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(()=>
    Pacy.enableAccount(token, accountId)
  ).then(()=>{
    dispatch({type: Actions.PACY_MIGRATION_DONE, accountId, status: true})
  }).catch((error)=>{
    const errorMessage = extractMessageFromError(error) || "An unknown error has occured"
    dispatch({type: Actions.PACY_MIGRATION_ERROR, accountId, error: errorMessage})
  }).then(()=>{
    dispatch({type: Actions.PACY_MIGRATION_RUNNING, accountId, status: false})
  })
}
