import * as React        from "react"
import {connect}         from "react-redux"
import ExplainedLoading  from "../loading"
import ErrorBoundary     from "../Error/ErrorBoundary"

const mapStateToProps = (state,ownProps)=>{
  return {
    inventory : state.Inventory.list[state.Accounts.selected],
  }
}
const InventoryLoader = props => {
  if(props.inventory===undefined){
    return <ExplainedLoading translateId="loadings.inventory"/>
  }
  return <ErrorBoundary>{props.render(props.inventory)}</ErrorBoundary>
}
const InventoryLoaderConnected = connect(mapStateToProps)(InventoryLoader)

const requiresinventory = Component => props => (
  <InventoryLoaderConnected
    render={ inventory => <Component inventory={inventory} {...props} /> }
  />
)

export default requiresinventory
