import * as React               from "react"
import {connect}                from "react-redux"
import retrieveReplacements     from "../../actions/grammarBooster/replacements"
import ExplainedLoading         from "../loading"
import ErrorBoundary            from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  return {
    // For forward-compatibility reasons, GrammarBooster will be by accountId eventually
    accountId : state.Accounts.selected || 1048,
    replacements : state.GrammarBooster.List,
    retrievingReplacements : state.GrammarBooster.Retrieving,
    error : state.GrammarBooster.Error
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadReplacements : (accountId:number)=>dispatch(retrieveReplacements(accountId)),
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP, ...ownProps,
    loadReplacements : ()=>DP.loadReplacements(SP.accountId)
  }
}
const GrammarBoosterLoader = props => {
  if(props.replacements===null){
    if(props.retrievingReplacements===false){props.loadReplacements()}
    return <ExplainedLoading translateId="loadings.grammarBooster.replacements"/>
  }
  return <ErrorBoundary>{props.render(props.replacements, props.error)}</ErrorBoundary>
}
const GrammarBoosterLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(GrammarBoosterLoader)
const requiresGrammarBooster = Component => props => (
  <GrammarBoosterLoaderConnected
    render={ (replacements, error) => <Component replacements={replacements} error={error} {...props} /> }
  />
)
export default requiresGrammarBooster
