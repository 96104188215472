import ReducerUtils                         from "../../../utils/ReducerUtils"
import Actions                              from "../../../model/constant/actions"
import {
  AdTemplate,
  AdBuilderCompatibleLang
}                                           from "../../../model/adbuilder/"


export const saveDefaultAds = (state, action:{accountId: number, categorizedAds: CategorizedDefaultAds}) => {
  return {
    ...state,
    [action.accountId] : action.categorizedAds
  }
}

interface CategorizedDefaultAds {
  [language:string] : {
    [adGroupName:string] : AdTemplate[]
  }
}
interface DefaultAdsState{
  [accountId:number] : CategorizedDefaultAds
}

const DefaultAds = ReducerUtils.createReducer<DefaultAdsState>({},{
  [Actions.ADD_BRANDING_DEFAULT_AD_SET]       : saveDefaultAds
})

export default DefaultAds
