import * as React              from 'react'
import AccountsDatatable       from "./AccountsTable"
import ArrayUtils              from "../../../utils/ArrayUtils"
import {Link}                  from "react-router-dom"

const CurrentAccounts = props => {
  const accountsOfUser = ArrayUtils.unique([
    ...props.user.permissions.map(p=>p.accountId),
    ...props.user.permissionExceptions.map(e=>e.accountId)
  ])
  const doNotFilter = accountsOfUser.indexOf(null)>=0
  const accounts = doNotFilter ? props.accounts : (
    props.accounts.filter(a=>ArrayUtils.contain(accountsOfUser,a.id))
  )
  return (
    <div className='CurrentAccountsOfUser'>
      <h3>{"Has access to "}{
        doNotFilter
        ? <Link to={document.location.hash.replace("#","")+'/0'}>all clients</Link>
        : <span>{accountsOfUser.length} accounts</span>
      }</h3>
      <AccountsDatatable
        accounts={accounts}
        userProfiles={props.user.permissionProfiles}
        allProfiles={props.profiles}
        onRowClick={row=>document.location.hash += "/"+row.id}
        pageSize={5}
      />
    </div>
  )
}

export default CurrentAccounts
