import "./Tag.css"
import * as React   from "react"
import {DragSource} from "react-dnd"
import ItemTypes    from "../../../../model/constant/ItemTypes"
import {
  Card
}                   from '@material-ui/core'

const tagSource = {
  beginDrag(props){
    return {
      tag : props.tag
    }
  }
}
function collect(connect, monitor){
  return{
    connectDragSource : connect.dragSource(),
    isDragging : monitor.isDragging()
  }
}
interface TagProps{
  tag               : string
  connectDragSource : any
  isDragging        : any
}
class Tag extends React.Component<TagProps,any>{
  render(){
    let tagClass = this.props.isDragging?"Tag-Dragging":"Tag"
    return this.props.connectDragSource(
      <div className={tagClass}>
        {this.props.tag}
      </div>
    )
  }
}
export default DragSource(ItemTypes.TAG, tagSource, collect)(Tag)
