import './index.css'
import * as React                              from 'react'
import {withLocalize}                          from "react-localize-redux"
import ArrayUtils                              from "../../utils/ArrayUtils"
import NumFormatter                            from "../../utils/NumberFormatter"
import MathUtils                               from "../../utils/MathUtils"
import ObjectUtils                             from "../../utils/ObjectUtils"
import {requiresFilteredWazePerformanceData}   from '../../components/loaders/wazeLoader'
import {Panel}                                 from "../../components/Panel/Panel"
import Card                                    from '../../components/card/SimpleCard'
import NumberBreakdown                         from "../../components/card/NumberBreakdown"
import WazeGraph                               from "./Graph"


const COUNTS_AS_CLICKS = [
  "ad_clicked",
  "deep_link_clicked",
  "offer_url_clicked",
  "inbox_used",
  "favorite_brand_opt_in",
]
const COUNTS_AS_CALLS = [
  "popup_phone_clicked",
  "preview_phone_clicked",
  "special_phone_clicked",
]
const compile = wazePerformanceData => {

  const actions     = wazePerformanceData.reduce((a,row)=>a+row["actions"],0)
  const navigations = wazePerformanceData.reduce((a,row)=>a+row["navigate_total"],0)
  const info        = wazePerformanceData.reduce((a,row)=>a+row["more_info"],0)
  const uniqueDays  = ArrayUtils.unique(wazePerformanceData.map(x=>x["day"]))

  const spent = wazePerformanceData
  .map(x=>x["spent"])
  .reduce((a,spent)=>spent<=0 ? a : a+spent, 0)

  const clicks = COUNTS_AS_CLICKS
  .map(key=>wazePerformanceData.reduce((a,row)=>a+row[key],0))
  .reduce((a,x)=>a+x,0)

  const calls = COUNTS_AS_CALLS
  .map(key=>wazePerformanceData.reduce((a,row)=>a+row[key],0))
  .reduce((a,x)=>a+x,0)

  const pinImpressions = wazePerformanceData
  .filter(x=>x["channel"]==="Pin")
  .reduce((a,row)=>a+row["displayed"],0)

  const searchImpressions = wazePerformanceData
  .filter(x=>x["channel"]==="Search")
  .reduce((a,row)=>a+row["displayed"],0)

  return {
    clicks            : clicks,
    calls             : calls,
    spent             : spent,
    actions           : actions,
    navigations       : navigations,
    info              : info,
    pinImpressions    : pinImpressions,
    searchImpressions : searchImpressions,
    uniqueDays        : uniqueDays,
  }
}
const compileGraphData = wazePerformanceData => {
  return ObjectUtils.getObjectValues(
    wazePerformanceData
    .map(x=>({
      day         : x.day,
      actions     : x.actions,
      impressions : x.displayed,
    }))
    .reduce((acc,row)=>{
      if(acc[row.day]===undefined){
        acc[row.day] = row
        return acc
      }
      acc[row.day].actions += row.actions,
      acc[row.day].impressions += row.impressions
      return acc
    },{})
  )
}

const WazePage = (props) => {
  const compiled = compile(props.wazePerformanceData)
  const graphData = compileGraphData(props.wazePerformanceData)
  const impressionsValues = {
    search : {
      title : props.translate("reports.waze.search"),
      value : NumFormatter.formatNumber(compiled.searchImpressions),
    },
    pin    : {
      title : props.translate("reports.waze.pin"),
      value : NumFormatter.formatNumber(compiled.pinImpressions),
    },
    total  : {
      title : props.translate("reports.waze.total"),
      value : NumFormatter.formatNumber(compiled.pinImpressions+compiled.searchImpressions),
    },
  }

  const actions = MathUtils.round(compiled.actions/compiled.uniqueDays.length,2)
  const summaryValues = {
    totalActions  : {
      title : props.translate("reports.waze.totalActions"),//"Total\r\nActions",
      value : NumFormatter.formatNumber(compiled.actions)
    },
    actionsPerDay : {
      title : props.translate("reports.waze.actionsPerDay"),//"Actions\r\nper Day",
      value : actions ? NumFormatter.formatNumber(actions) : "-"
    },
    totalSpent    : {
      title : props.translate("reports.waze.totalSpent"),//"Total\r\nSpent",
      value : NumFormatter.formatCurrency(compiled.spent)
    },
    spentPerDay   : {
      title : props.translate("reports.waze.spentPerDay"),//"Spent\r\nper Day",
      value : compiled.uniqueDays.length ? NumFormatter.formatCurrency(compiled.spent/compiled.uniqueDays.length) : "-"
    },
  }
  return (
    <Panel title={props.translate("reports.waze.title")} subtitle={props.translate("reports.waze.subtitle")}>
      <div className='WazePage'>
        <img className='waze-logo' src={"./assets/img/waze-logo.png"} />
        <div className='analysis'>
          <NumberBreakdown
            imgPath="assets/img/waze/summary.svg"
            title={props.translate("reports.waze.summary")}
            keyValue={summaryValues}
          />
          <NumberBreakdown
            imgPath="assets/img/waze/impressions.svg"
            title={props.translate("common.impressions")}
            keyValue={impressionsValues}
          />
        </div>
        <div className='KPIs'>
          <Card
            key="click"
            iconPath={"assets/img/waze/clicks.svg"}
            number={NumFormatter.formatNumber(compiled.clicks)}
            text={props.translate("common.clicks")}
          />
          <Card
            key="nav"
            iconPath={"assets/img/waze/navigation.svg"}
            number={NumFormatter.formatNumber(compiled.navigations)}
            text={props.translate("reports.waze.navigations")}
          />
          <Card
            key="info"
            iconPath={"assets/img/waze/moreinfo.svg"}
            number={NumFormatter.formatNumber(compiled.info)}
            text={props.translate("reports.waze.moreInfo")}
          />
          <Card
            key="call"
            iconPath={"assets/img/waze/calls.svg"}
            number={NumFormatter.formatNumber(compiled.calls)}
            text={props.translate("reports.waze.calls")}
          />
        </div>
        <div className='Graph'>
          <WazeGraph dataPerDay={graphData} />
        </div>
      </div>
    </Panel>
  )
}

export default requiresFilteredWazePerformanceData(withLocalize(WazePage))
