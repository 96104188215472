import './BudgetsInsight.css'
import * as React                     from "react"
import {connect}                      from "react-redux"
import {withLocalize, Translate}      from "react-localize-redux"
import BootstrapTable                 from 'react-bootstrap-table-next'
import filterFactory                  from 'react-bootstrap-table2-filter'
import paginationFactory              from 'react-bootstrap-table2-paginator'
import {BarChart, Bar, XAxis, Cell,
        YAxis, CartesianGrid,
        Tooltip, ResponsiveContainer,
        AxisDomain
}                                     from "recharts"
import StatisticsAccessor             from "../../../../storeAccessor/Statistics"
import Donut                          from "../../../../components/graph/Donut2"
import ExplainedLoading               from "../../../../components/loading"
import NoInsightData                  from "./NoInsightData"
import {
  Tooltip as MuiTooltip,
  withStyles
}                                     from "@material-ui/core"
import { HelpOutline }                from "@material-ui/icons"
import { COLORS, toolsByDetails }     from './BudgetsInsightResources'

interface BudgetsInsightOwnProps{
  campaignsStats ?: any[]
}
export interface BudgetsInsightProps extends BudgetsInsightOwnProps{
  accountId           : number
  budgetsStats        : any[]
  campaignsStats      : any[]
  wazePerformanceData : any[]
  details            ?: DetailsOptions
  closeHandler       ?: ()=>void
  translate          ?: (translateId:string, data?:any, options?:any)=>string
}
type DetailsOptions = "impressions" | "clicks" | "cpc" | "cost" | "impShare" | "clickShare" | "conversions" | "ctr" | "conversionrate" | "cpcon"
const makeMapStateToProps = () => {
  const budgetsStatsSelector = StatisticsAccessor.makeBudgetsStatsInCurrentPeriodSelector()
  const campaignsStatsSelector = StatisticsAccessor.makeCampaignsStatsInCurrentPeriodSelector()
  return (state,ownProps:BudgetsInsightOwnProps)=>{
    return {
      accountId : state.Accounts.selected,
      budgetsStats : budgetsStatsSelector(state),
      campaignsStats : ownProps.campaignsStats || campaignsStatsSelector(state),
    }
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {}
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

const BudgetsInsight = (props:BudgetsInsightProps) => {
  if(props.budgetsStats === undefined){return <ExplainedLoading text={props.translate("loadings.budgetsStats")}/>}
  if(props.campaignsStats === undefined){return <ExplainedLoading text={props.translate("loadings.campaignsStats")}/>}
  const translatedDetailsName = props.translate("dashboard.budgetsInsight."+props.details)
  const translatedTooltip = props.translate('dashboard.budgetsInsight.tooltips.' + props.details)
  const tools = toolsByDetails[props.details]?toolsByDetails[props.details]:toolsByDetails.cost
  let data = tools.compiler(props)
  if(data.length === 0){
    return <NoInsightData dataName={translatedDetailsName} />
  }
  if(tools.removeZeros){data = data.filter(x=>!!x.value)}
  //Translate the names
  for(let item of data){
    item.name = props.translate("dashboard.budgetsInsight.budgets."+item.rawName.toLowerCase(), null, {onMissingTranslation:()=>item.rawName})
  }
  //Use pagination only if required
  const sizePerPage = 10
  const extraTableOptions = {
    pagination : paginationFactory({
      sizePerPage : sizePerPage,
      hideSizePerPage : true,
      hidePageListOnlyOnePage : true,
      showTotal : false,
    })
  }

  const StyledTooltip = withStyles({
    tooltip: {
      fontSize: 14,
      textAlign: "center",
    }
  })(MuiTooltip);

  return (
    <div className="BudgetsInsight">
      <div className="Header">
        <div className="HeaderTitle">
          <h4>
            <Translate id="dashboard.budgetsInsight.title" data={{dataName:translatedDetailsName}}/>
          </h4>
          <StyledTooltip title={translatedTooltip}>
            <HelpOutline />
          </StyledTooltip>
        </div>
        {
          props.closeHandler
          ? <div className="CloseButton" onClick={props.closeHandler}/>
          : <div/>
        }
      </div>
      {tools.chartType === "pie"?
        <Donut
          data={data}
          colors={COLORS}
          defaultColor="#AAAAAA"
          labelFormatter={(x)=>tools.formatter(x.value) as string}
          height={400}
          width="99%"
          withTooltip={true}
          tooltipFormatter={(x)=>tools.formatter(x) as string}
        />
      :
        <ResponsiveContainer width="99%" height={400}>
          <BarChart layout="vertical" data={data} margin={{top: 20, right: 20, bottom: 20, left: 70}}>
            <CartesianGrid  strokeDasharray="3 3"/>
            <XAxis
              type="number"
              domain={tools.domain as [AxisDomain, AxisDomain]}
            />
            <YAxis
              dataKey="name"
              name="Budget"
              type="category"
            />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              formatter={tools.formatter}
            />
            <Bar
              dataKey="value"
              name={translatedDetailsName}
            >
              {data.map(x=>
                <Cell key={x.name} fill={COLORS[x.name] || "#AAAAAA"}/>
              )}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      }
      <BootstrapTable
        keyField='name'
        data={data}
        defaultSorted={[{dataField:'value',order:'desc'},]}
        columns={[
          {
            dataField:"name",
            text:"Budget",
          },
          {
            dataField:"value",
            sort:true,
            text: translatedDetailsName,
            formatter:tools.tableFormatter
          },
        ]}
        filter={filterFactory()}
        classes="normalSizedDatatable TrendyCarsDataTable"
        {...extraTableOptions}
      />
    </div>
  )
}

export default (
  withLocalize(
    connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
      BudgetsInsight
    )
  )
)
