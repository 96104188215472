import "./SetupTableRow.css"
import * as React                from "react"
import moment                    from "moment"
import classNames                from "classnames"
import DateUtils                 from "../../../../../utils/DateUtils"
import MathUtils                 from "../../../../../utils/MathUtils"
import {
  Guideline,
  isVagueGuideline,
  isPreciseGuideline
}                                from "../../../../../model/pacy/Guideline"
import Tooltip                   from "../../../../../components/tooltip/DesignedTooltip"
import { ProxyGroup }            from "../../../../../model/pacy/ProxyGroup"
import {
  withLocalize,
  LocalizeContextProps
}                                from "react-localize-redux"
import { Proxy }                 from '../../../../../model/pacy'
import { StringMap }             from "../../../../../model/generics"
import GuidelinePie              from "./GuidelinePie"
import NumFormatter              from "../../../../../utils/NumberFormatter"


interface SetupTableRowProps extends LocalizeContextProps {
  index              : number
  proxy              : Proxy
  proxyGroup         : ProxyGroup
  shownMonths        : string[]
  guidelinesPerMonth : StringMap<Guideline>
  color              : string
  setSelected        : React.Dispatch<React.SetStateAction<number>>
}

const SetupTableRow = (props: SetupTableRowProps) => {
  const currentMonth = moment().format("YYYY-MM")
  const guideline = props.guidelinesPerMonth[props.shownMonths[0]]

  const proxiesWithAdjustedWeights = props.proxyGroup.proxies.map((proxy) => {
    if (!proxy.active) {
      return {
        ...proxy,
        weight: 0
      }
    }
    return proxy
  })

  let totalPreciseSpend = 0
  let totalVagueWeight = 0

  const preciseStartingAngles = []
  const vagueStartingAngles = []


  if (isPreciseGuideline(guideline)) {
    for (const value of Object.values(guideline.amounts)) {
      totalPreciseSpend += value
    }
    for (const value of Object.values(guideline.amounts)) {
      preciseStartingAngles.push(preciseStartingAngles.length === 0 ?
        (value/totalPreciseSpend)*360 || 0 // These zeries prevent us from returning a NaN arrived at by dividing by a zero total.
      : preciseStartingAngles[preciseStartingAngles.length - 1] + ((value/totalPreciseSpend)|| 0) * 360)
    }
  }
  else {
    for (const proxy of props.proxyGroup.proxies) {
      totalVagueWeight += proxy.weight
    }
    for (const proxy of proxiesWithAdjustedWeights) {
      vagueStartingAngles.push(vagueStartingAngles.length === 0 ?
        (proxy.weight/totalVagueWeight)*360 || 0
      : vagueStartingAngles[vagueStartingAngles.length - 1] + ((proxy.weight/totalVagueWeight) || 0) * 360)
    }
  }

  return (
    <tr key={props.proxy.id}>
      <td>{props.proxy.name}</td>
      <td className="guidelinePie">
        <GuidelinePie
          arrowColor={props.color}
          totalPreciseSpend={totalPreciseSpend}
          totalVagueWeight={totalVagueWeight}
          proxy={props.proxy}
          proxyGroup={props.proxyGroup}
          preciseStartingAngle={preciseStartingAngles[props.index]}
          vagueStartingAngle={vagueStartingAngles[props.index]}
          currentGuideline={props.guidelinesPerMonth[props.shownMonths[0]]}
        />
      </td>
      {props.shownMonths.map((month,i)=>{
        const guideline:Guideline = props.guidelinesPerMonth[month]
        const isEditable = DateUtils.isCurrentOrFutureYM(month)
        const onClick = isEditable ? ()=>props.setSelected(i) : ()=>{}
        return (
          <td
            key={month}
            className={classNames("AmountCell", "ColMonth", "Index"+i, {
              Editable     : isEditable,
              CurrentMonth : currentMonth === month,
              PastMonth    : month < currentMonth,
            })}
            onClick={onClick}
          >
            <Tooltip
              text={props.translate("pacyPlanner.clickPreciseTooltip",
                {month: props.translate("common.months."+moment(month).format("MM"))}
              ) as string}
              arrowColor={props.color}
              disabled={!isEditable}
            >
              <span>
                {isVagueGuideline(guideline)
                  ? "-"
                  : MathUtils.round(guideline.amounts[props.proxy.id], 2) || 0
                }
              </span>
            </Tooltip>
          </td>
        )
      })}
    </tr>
  )
}

export default withLocalize(SetupTableRow)
