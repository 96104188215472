import './HighlightIcon.css'
import * as React         from "react"
import {withLocalize}     from "react-localize-redux"
import {Tooltip}          from "react-tippy"

interface HighlightIconProps{
  tooltip ?: string
  tooltipTranslateId ?: string
  translate ?: (translateId:string)=>string
}

const HighlightIcon = (props:HighlightIconProps) => {
  if(!props.tooltip && !props.tooltipTranslateId){return <i className="material-icons">error_outline</i>}
  return (
    <Tooltip
      html={(
        <div className="TablesTooltipContent">
          {props.tooltipTranslateId
            ? props.translate(props.tooltipTranslateId)
            : props.tooltip
          }
        </div>
      )}
      delay={[0,0]}
      position="right"
    >
      <i className="material-icons">error_outline</i>
    </Tooltip>
  )
}

export default withLocalize(HighlightIcon as any) as any

