import Actions      from "../model/constant/actions"
import ReducerUtils from "../utils/ReducerUtils"

const setMessage = (state, action) => {
  return action.message
}
const setMessageCreating = (state, action) => {
  return "Creating..."
}
const CreateUser = ReducerUtils.createReducer<string>("",{
  [Actions.USER_CREATED] : setMessage,
  [Actions.CREATING_USER] : setMessageCreating,
})
export default CreateUser
