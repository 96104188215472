import * as React        from 'react'
import {Translate}       from "react-localize-redux"
import ArrayUtils        from "../../../../utils/ArrayUtils"

interface YearMonthTableHeaderProps{
  periods      : YearMonth[]
  leftColumns  : string[]
  rightColumns : string[]
}

interface YearMonth{
  year  : number
  month : number
}

interface YearVisible{
  year : number
  nb   : number
}

const getNbColumnsForYearsOfPeriods = (periods:YearMonth[]) : YearVisible[] => {
  return periods
  .map(p=>p.year)
  .reduce((a,year)=>{
    let x = a.find(yearVisible=>yearVisible.year===year)
    return [
      ...a.filter(yearVisible=>yearVisible.year!==year),
      {year:year, nb : x?x.nb+1:1}
    ]
  },[])
}

const YearMonthTableHeader = (props:YearMonthTableHeaderProps)=>{
  return (
    <thead>
      <tr>
        {props.leftColumns.map((column,i)=>{return <th key={i} rowSpan={2}>{column}</th>})}
        {getNbColumnsForYearsOfPeriods(props.periods).map((yearVisible)=>
          <th key={yearVisible.year} colSpan={yearVisible.nb}>{yearVisible.year}</th>
        )}
        {props.rightColumns.map((column,i)=>{return <th key={i} rowSpan={2}>{column}</th>})}
      </tr>
      <tr>
        {props.periods.map((period)=>
          <th key={period.year+"-"+period.month}>
            <Translate id={"common.months."+period.month}/>
          </th>
        )}
      </tr>
    </thead>
  )
}

export default YearMonthTableHeader
