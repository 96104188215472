import './PieChartOverview.css'
import * as React                   from "react"
import { connect }                  from "react-redux"
import { withLocalize }             from "react-localize-redux"
import { Loading }                  from "carbon-components-react"
import AccountsAccessors            from "../../../../storeAccessor/Accounts"
import StatisticsAccessors          from "../../../../storeAccessor/Statistics"
import NumFormatter                 from '../../../../utils/NumberFormatter'
import ArrayUtils                   from "../../../../utils/ArrayUtils"
import { fetchImpressionsByDevice } from "../../../../actions/statistics/impressionsByDevice"
import Donut                        from "../../../../components/graph/Donut2"
import * as chroma                  from "chroma-js"
import { DeviceStatistics }         from "../../../../model/constant/DeviceStatistics"
import {
  FormControl,
  Select,
  MenuItem
}                                   from "@material-ui/core"


interface PieCharOverviewOwnProps {
  campaignAWIds: string[]
}

interface DeviceData {
  name  : string
  value : {
    clicks      : number
    conversions : number
    cost        : number
    impressions : number
  }
}

interface SpecificDeviceDatum {
  name  : string
  value: number
}

const makeMapStateToProps = (state, ownProps) => {
  const statsSelector = StatisticsAccessors.makeStatsByDeviceStatsInCurrentPeriodForCampaignAWIdsSelector()
  const AWIdSelector = AccountsAccessors.makeSelectedAccountAWIdSelector()
  return (state, ownProps) => {
    return {
      token: state.Login.userLogged.token,
      accountId: state.Accounts.selected,
      accountAWId: AWIdSelector(state),
      period: state.Period,
      stats: statsSelector(state, ownProps),
      retrieving: state.Statistics.Retrieving.ImpressionsByDevice
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchImpressionsByDevice: (accountId: number, accountAWId: string, period) => dispatch(
      fetchImpressionsByDevice(accountId, accountAWId, period)
    )
  }
}
const mergeProps = (SP, DP, ownProps) => {
  return {
    ...SP, ...DP, ...ownProps,
    fetchImpressionsByDevice: () => DP.fetchImpressionsByDevice(SP.accountId, SP.accountAWId, SP.period),
  }
}
const DEVICES = ["desktop", "high_end_mobile", "connected_tv", "tablet", "unnkown"]
const COLORS = chroma.scale(['#26aadb', '#2A4858']).mode('lch').colors(DEVICES.length)

const getDataArray = (data: DeviceData, desiredDataKey: string): SpecificDeviceDatum[] => {
  const newArray: SpecificDeviceDatum[] = []

  for (const key in Object.keys(data)) {
    newArray.push({
        name  : data[key].name,
        value : data[key].value[desiredDataKey]
      })
  }
  return newArray
}


const PieChartOverview = (props) => {
  const [dataKey, setDataKey] = React.useState<string>(DeviceStatistics.impressions)

  const getTooltip = (x) => {
    if (dataKey === DeviceStatistics.cost)
      return NumFormatter.formatCurrency(x)

    else {
      return NumFormatter.formatNumber(x, 2) + " " + props.translate('campaigns.overview.deviceData.' + dataKey)
    }
  }


  if (!props.stats) {
    if (!props.retrieving) { props.fetchImpressionsByDevice() }
    return <div className="LoadingPieChart"><Loading withOverlay={false} /></div>
  }
  const getName = device => (
    props.translate("campaigns.overview.platforms." + device.toLowerCase())
  )
  const colors = ArrayUtils.zip(DEVICES.map(getName), COLORS)
    .reduce((acc, b) => ({ ...acc, [b[0]]: b[1] }), {})
  const data = props.stats.map(x => ({ ...x, name: getName(x.name) }))
  return (
    <div>
      <div className="ByDeviceHead">
        <FormControl variant="outlined">
          <Select
            value={dataKey}
            onChange={(e) => setDataKey(e.target.value as string)}
          >
            <MenuItem value={DeviceStatistics.impressions}> {props.translate('campaigns.overview.deviceData.impressions')} </MenuItem>
            <MenuItem value={DeviceStatistics.clicks}>      {props.translate('campaigns.overview.deviceData.clicks')}      </MenuItem>
            <MenuItem value={DeviceStatistics.conversions}> {props.translate('campaigns.overview.deviceData.conversions')} </MenuItem>
            <MenuItem value={DeviceStatistics.cost}>        {props.translate('campaigns.overview.deviceData.cost')}        </MenuItem>
          </Select>
        </FormControl>
        <h4>{props.translate("campaigns.overview.byDevice")}</h4>
      </div>
      <div className="DonutDiv">
        <Donut
          data={getDataArray(data, dataKey)}
          colors={colors}
          withTooltip
          labelFormatter={x => NumFormatter.formatNumber(x.value, 2)}
          tooltipFormatter={getTooltip}
        />
      </div>
    </div>
  )
}
export default withLocalize(connect(makeMapStateToProps, mapDispatchToProps, mergeProps)(PieChartOverview))
