import "./AdCopy.css"
import * as React     from "react"
import ArrayUtils     from "../../utils/ArrayUtils"

interface AdCopyInterface{
    headlines    : string[]
    url          : string
    descriptions : string[]
    status       : string
    destination ?: string
}

const finishWithPonctuation = new RegExp("[.!?]{1}$")
const formatDescriptions = (descriptions:string[]):string => {
  const filtered = descriptions.map(x=>x.trim()).filter(x=>x) //remove empty
  return finishWithPonctuation.test(filtered[0])
    ? filtered.join(" ")
    : filtered.join(". ")
}

const formatHeadlines = (headlines:string[]):string => {
  const filtered = headlines.map(x=>x.trim()).filter(x=>x) //remove empty
  return filtered.join(" | ")
}

const AdCopyViewer = (props:AdCopyInterface) => {
  return (
    <div
      className={"AdCopyViewer "+ props.status}
      onClick={()=>{
        if(props.destination){
          window.open(props.destination, "_blank")
        }
      }}
    >
      <div className="Title">
        <h3>
          <p className="Title">{formatHeadlines(ArrayUtils.shuffle(props.headlines).slice(0,3))}</p>
        </h3>
      </div>
      <div className="Url">
        <a className="Url">{props.url}</a>
      </div>
      <div >
        <p className="Description">{formatDescriptions(ArrayUtils.shuffle(props.descriptions).slice(0,2))}</p>
      </div>
    </div>
  )
}

export default AdCopyViewer
