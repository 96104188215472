import {combineReducers}           from "redux"
import {SelectedAccounts}          from "./SelectedAccounts"
import {AccountList}               from "./AccountList"
import {IsFetched}                 from "./IsFetched"
import {IsFetching}                from "./IsFetching"
import AccountsPreferences         from "./AccountsPreferences"
import IsFetchingPreferences       from "./IsFetchingPreferences"

export const Accounts = combineReducers({
	list : AccountList,
	selected : SelectedAccounts,
  isFetching : IsFetching,
  isFetched : IsFetched,
  preferences : AccountsPreferences,
  isFetchingPreferences : IsFetchingPreferences
})
