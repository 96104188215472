import './index.css'
import * as React                 from 'react'
import {connect}                  from 'react-redux'
import {withLocalize}             from "react-localize-redux"
import {Button}                   from "carbon-components-react"
import {Panel}                    from "../../components/Panel/Panel"
import GoBack                     from "../../components/Button/LinkGoBack"
import CreateUserAction           from "../../actions/user/createUser"
import {
  getSortedLocales,
  getReadableLocale
}                                 from '../../utils/LocaleUtils'


interface CreateUserState {
  firstName      : string
  lastName       : string
  officePhone    : string
  cellPhone      : string
  email          : string
  language       : string
  locale         : string
  [input:string] : any
}

const mapStateToProps = (state,ownProps)=>{
  return {
    createUserMessage : state.CreateUser,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    createUser : (userKeyValues:any[], language:string, locale:string) => dispatch(CreateUserAction(userKeyValues, language, locale))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

const mandatoryKeys = [
  "firstName",
  "lastName",
  "email",
]
const sentToActionKeys = [
  "firstName",
  "lastName",
  "email",
  "officePhone",
  "cellPhone",
]
const nameAdjuster = inputName => {
  return inputName
}

const locales = getSortedLocales()

class CreateUser extends React.Component<any,CreateUserState>{
  constructor(props){
    super(props)
    this.state = {
      firstName            : "",
      lastName             : "",
      officePhone          : "",
      cellPhone            : "",
      email                : "",
      language             : "en",
      locale               : "en-CA",
    }
    this.changeInputValue = this.changeInputValue.bind(this)
    this.onButtonSubmit = this.onButtonSubmit.bind(this)
  }
  changeInputValue(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>){
    this.setState({
      [e.target.name] : e.target.value
    })
  }
  onButtonSubmit(status){
    if(mandatoryKeys.map(k=>this.state[k]).filter(x=>x.length).length !== mandatoryKeys.length){
      alert("Please complete all the mandatory fields")
      return
    }
    if(!status.emailOk){return}
    const keyValues = sentToActionKeys
    .map(k=>[nameAdjuster(k),this.state[k]])
    .filter(kv=>kv[1].length)
    this.props.createUser(keyValues, this.state.language, this.state.locale)
  }

  locales = locales

  render(){
    const pos = this.state.email.indexOf("@")
    const emailOk = pos>0 && pos<this.state.email.length-2 && this.state.email.length>=4
    const status = {emailOk}

    return (
      <div className='CreateUser'>
        <Panel title='Create User'>
          <GoBack/>
          <div>
            <div>First Name</div>
            <input
              name='firstName'
              type='text'
              onChange={e=>this.changeInputValue(e)}
            />
          </div>
          <div>
            <div>Last Name</div>
            <input
              name='lastName'
              type='text'
              onChange={e=>this.changeInputValue(e)}
            />
          </div>
          <div>
            <div>Office Phone (optional)</div>
            <input
              name='officePhone'
              type='text'
              onChange={e=>this.changeInputValue(e)}
            />
          </div>
          <div>
            <div>Mobile Phone (optional)</div>
            <input
              name='cellPhone'
              type='text'
              onChange={e=>this.changeInputValue(e)}
            />
          </div>
          <hr/>
          <div>
            <div>Email <span className="SmallMessage">( Serves as username )</span></div>
            {emailOk || !this.state.email.length?<></>:<div className="ErrorDiv">Email not valid</div>}

            <input
              name='email'
              type='email'
              onChange={e=>this.changeInputValue(e)}
            />
          </div>
          <div className="Dropdown">
            <div>Language</div>
            <select
              className="Select"
              name="language"
              onChange={e=>this.changeInputValue(e)}
              defaultValue={this.state.language}
            >
              {this.props.languages.map(x=>
                <option
                  key={x.code}
                  value={x.code}
                >
                  {x.name}
                </option>
              )}
            </select>
          </div>
          <div className="Dropdown">
            <div>Locale</div>
            <select
              className="Select"
              name="locale"
              onChange={e=>this.changeInputValue(e)}
              defaultValue={this.state.locale}
            >
              {this.locales.map((localeObj) =>
                <option
                  key={localeObj.key}
                  value={localeObj.localeCode}
                >
                  {localeObj.readableLocale}
                </option>
              )}
            </select>
          </div>
          <div className='SubmitButton'>
            <Button className="btn btn-info" onClick={()=>this.onButtonSubmit(status)}>Create User</Button>
            {this.renderSwitch(this.props.createUserMessage)}
          </div>
        </Panel>
      </div>
    )
  }
  renderSwitch(message){
    if(message.length){return <div><div>STATUS :</div><pre>{message}</pre></div>}
  }
}
export default (
  withLocalize(
    connect(mapStateToProps,mapDispatchToProps,mergeProps)(
      CreateUser
    )
  )
)
