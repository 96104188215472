import './GeoTargeting.css'
import * as React             from 'react'
import { MapWithEditor }      from '../../../components/Map/MapWithEditor'
import GeoTargetingInterface  from '../../../model/Store/GeoBid/GeoTargeting'
import {
  Card,
}                             from '@material-ui/core'
import requiresGeoTargetings  from '../../../components/loaders/geoTargetingLoader'
import ExplainedLoading       from '../../../components/loading'

interface GeoTargetingOwnProps {
}
interface GeoTargetingProps extends GeoTargetingOwnProps {
  geoTargetings       : GeoTargetingInterface[]
  geoTargetingLoading : boolean
}

const GeoTargeting = (props:GeoTargetingProps) => {
  const editedCircleOptions = {fillColor:"#FFFF66", strokeColor:"#FF9900", strokeWeight:"1"}
  const selectedCircleOptions = {fillColor:"#A4FBA6", strokeColor:"#4AE54A", strokeWeight:"1"}
  const circleOptions = {fillColor:"#01B9F5", strokeColor:"#012B74", strokeWeight:"1"}
  const geoTargeting = props.geoTargetings.find(gt => {
    return gt.matcher.group === 'BRANDING'
  })
  return (
    <Card className="GeoTargeting">
      {geoTargeting ?
        <MapWithEditor
          geoTargeting={geoTargeting}
          circleOptions={circleOptions}
          selectedCircleOptions={selectedCircleOptions}
          editedCircleOptions={editedCircleOptions}
        />
      : <ExplainedLoading translateId="loadings.geoTargeting"/>}
    </Card>
  )
}

export default requiresGeoTargetings({letThrough:false})(GeoTargeting)
