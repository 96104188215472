import './index.css'
import * as React                from 'react'
import {connect}                 from "react-redux"
import runTest                   from "../../actions/grammarBooster/test"
import saveReplacement           from "../../actions/grammarBooster/save"
import Replacement               from '../../model/grammarBooster/Replacement'
import {createEmptyReplacement}  from '../../model/grammarBooster/Replacement'
import PERMISSIONS               from '../../model/constant/Permissions'
import requiresGrammarBooster    from '../../components/loaders/grammarBoosterLoader'
import ErrorMessage              from '../../components/alert/ErrorMessage'
import UserPermissionBoundary    from '../../components/permissions/UserPermissionBoundary'
import GrammarBoosterTable       from './table'
import GrammarBoosterEditor      from './editor'
import GrammarBoosterTester      from './tester'
import {Button}                  from "carbon-components-react"

interface GrammarBoosterIndexProps{
  replacements : Replacement[]
  runTest      : (lang:string, text:string)=>void
  testResult  ?: string
  error        : null|string
  save         : (replacement:Replacement)=>void
}
const mapStateToProps = (state,ownProps)=>{
  return {
    // For forward-compatibility reasons, GrammarBooster will be by accountId eventually
    accountId  : state.Accounts.selected || 1048,
    testResult : state.GrammarBooster.TestResult,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    runTest : (lang:string, test:string)=>dispatch(runTest(lang,test)),
    save    : (accountId:number, replacement:Replacement)=>dispatch(saveReplacement(accountId, replacement)),
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    save : (replacement:Replacement)=>DP.save(SP.accountId, replacement)
  }
}
interface GrammarBoosterIndexState{
  selected    : number|null
  justCreated : boolean
  standout    : boolean
}

class GrammarBoosterIndex extends React.Component<GrammarBoosterIndexProps,GrammarBoosterIndexState>{
  constructor(props){
    super(props)
    this.state = {
      selected    : null,
      justCreated : false,
      standout    : false,
    }
  }
  componentDidUpdate(prevProps:GrammarBoosterIndexProps, prevState:GrammarBoosterIndexState){
    if(prevState.justCreated && this.state.justCreated && !this.props.error && prevProps.replacements!==this.props.replacements){
      this.setState({selected:null, justCreated:false})
    }
  }
  render(){
    return (
      <div className='GrammarBooster'>
        <h3>GrammarBooster</h3>
        {(this.props.error!==null && this.props.error!=='') &&
          <ErrorMessage text={this.props.error}></ErrorMessage>
        }
        <GrammarBoosterTester
          runTest={this.props.runTest}
          testResult={this.props.testResult}
        />
        <UserPermissionBoundary
          userLevel={3}
          permissions={[[PERMISSIONS.Keystone_GrammarBooster_Create]]}
          onFailure={()=>null}
        >
          {this.state.selected!==null &&
            <GrammarBoosterEditor
              standout={this.state.standout}
              replacement={this.state.selected ? this.props.replacements.find(x=>x.id===this.state.selected) : createEmptyReplacement()}
              saveReplacement={(replacement:Replacement)=>{
                this.setState({justCreated : true})
                this.props.save(replacement)
              }}
            />
          }
          <div className='CreateNew'>
            <Button className='CreateNewButton' onClick={()=>this.setState({selected:0})}>
              Create new
            </Button>
          </div>
        </UserPermissionBoundary>
        <GrammarBoosterTable
          replacements={this.props.replacements}
          onSelectReplacement={(replacementId:number)=>{
            this.setState({selected:replacementId,standout:true})
            setTimeout(()=>this.setState({standout:false}),400)
          }}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(requiresGrammarBooster(GrammarBoosterIndex))
