import {createSelector}  from "reselect"
import * as Generic      from "./Generic"

export default class GeoBidAccessors {
  public static makePatternsOfStateSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getKeywords,
      (_,state)=>state,
      Generic.getGeoEditCopies,
      (accountId, patterns, state)=>{
        if(!patterns[accountId]){return undefined}
        return patterns[accountId].filter(pattern=>
          pattern.accountId === accountId && pattern.matcher.state.toUpperCase() === state.toUpperCase()
        )
      }
    )
  }
}
