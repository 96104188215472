import ReducerUtils     from "../../../utils/ReducerUtils"
import Actions          from "../../../model/constant/actions"
import ArrayUtils       from "../../../utils/ArrayUtils"

const saveStats = (state, action) => {
  //Those stats are not divided by day, they are already aggregated. So everytime there is new stats we just erased the previous ones
  return {
    ...state,
    [action.accountId] : action.stats
  }
}

const PlacementsStats = ReducerUtils.createReducer<any>({},{
  [Actions.RETRIEVE_PLACEMENTS_STATISTICS] : saveStats,
  [Actions.CHANGE_SELECTED_PERIOD] : ()=>({}) //clear on period change
})

export default PlacementsStats
