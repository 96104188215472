import ReportTableTemplate    from "../ReportTableTemplate"
import ColumnDefinition       from "../ColumnDefinition"
import ObjectUtils            from "../../../../utils/ObjectUtils"
import StatisticsUtils        from "../../../../utils/StatisticsUtils"
import {FORMATTER_NAMES}      from "../../../constant/DataTableFormatters"
import Contexts               from "../../../constant/ReportTableTemplateContexts"

export default class BudgetReportTableTemplate extends ReportTableTemplate{
  private STATUS_FILTER_OPTIONS = ["ENABLED"]
  private PERIOD_TYPE_FILTER_OPTIONS = ["FULL_DAY"]
  protected context = Contexts.BUDGET
  protected KEY_COLUMN = "BudgetID"
  protected DEFAULT_COLUMNS = [
    //                    text                datafield       order iskey  hidden sort  formatter  filter  filteroptions
    new ColumnDefinition("Budget ID"    ,     "BudgetID"    ,   1,  true,  true,  true, undefined, undefined, undefined, "BudgetID"),
    new ColumnDefinition("Name"         ,     "BudgetName"  ,   2,  false, false, true, undefined, "TEXT",    undefined, "BudgetName"),
    new ColumnDefinition("Amount"       ,     "BudgetAmount",   3,  false, false, true, FORMATTER_NAMES.MONEY ),
    new ColumnDefinition("Clicks"       ,     "Clicks"      ,   4,  false, false, true, FORMATTER_NAMES.ROUND2),
    new ColumnDefinition("Impressions"  ,     "Impressions" ,   5,  false, false, true, FORMATTER_NAMES.ROUND2),
    new ColumnDefinition("Conversions"  ,     "Conversions" ,   6,  false, false, true, FORMATTER_NAMES.ROUND2),
    new ColumnDefinition("Spent"        ,     "Spent"       ,   7,  false, false, true, FORMATTER_NAMES.MONEY ),
    new ColumnDefinition("Status"       ,     "BudgetStatus",   8,  false, false, true, undefined, "SELECT" , {options:ObjectUtils.getMirrorObjectFromArray(this.STATUS_FILTER_OPTIONS)}, "BudgetStatus"),
    new ColumnDefinition("Period Type"  ,     "PeriodType"  ,   9,  false, false, true, undefined, "SELECT" , {options:ObjectUtils.getMirrorObjectFromArray(this.PERIOD_TYPE_FILTER_OPTIONS)}, "PeriodType"),
    new ColumnDefinition("CPC",               "CPC",            10, false, false, true, FORMATTER_NAMES.CPC),
    new ColumnDefinition("CTR",               "CTR",            11, false, false, true, FORMATTER_NAMES.PERCENTAGE),
  ]
  constructor(id:string, name:string, options:any={defaultSorted:[{dataField:"",order:"asc"}]}){
    super(id, name, options)
    this.columns = this.DEFAULT_COLUMNS
  }
  clone(){
    let clone = new BudgetReportTableTemplate(
      this.id,
      this.name,
      this.options
    )
    clone.setColumns(this.columns.map(c=>c.clone()))
    clone.setSizePerPage(this.sizePerPage)
    clone.setRevisionOf(this.revisionOf)
    clone.setProvider(this.provider)
    return clone
  }

  protected compile(stats : any[]):any{ //TODO
    return stats.reduce((a,c)=>{
      if(!Object.keys(a).length){
        return {
          ...c,
          CPC : StatisticsUtils.calculateCPC(c.Spent, c.Clicks),
          CTR : StatisticsUtils.calculateCTR(c.Clicks, c.Impressions),
        }
      }
      const spent = a.Spent + c.Spent
      const clicks = a.Clicks + c.Clicks
      const impressions = a.Impressions + c.Impressions
      return {
        ...c,...a,
        BudgetAmount    : a.BudgetAmount + c.BudgetAmount,
        Clicks          : clicks,
        Conversions     : a.Conversions + c.Conversions,
        Spent           : spent,
        Impressions     : impressions,
        ImpressionShare : (a.ImpressionShare + c.ImpressionShare)/2, //TEMP LOGIC
        CPC             : StatisticsUtils.calculateCPC(spent, clicks),
        CTR             : StatisticsUtils.calculateCTR(clicks, impressions),
    }},{})
  }
}
