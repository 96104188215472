import Actions              from "../../../../model/constant/actions"
import ReducerUtils         from "../../../../utils/ReducerUtils"
import ArrayUtils           from "../../../../utils/ArrayUtils"

const addCodes = (state, action) => {
  return [...state, ...action.codes]
}
const removeCodes = (state, action) => {
  return state.filter(code=>!ArrayUtils.contain(action.codes, code))
}

const FetchedShapeCodes = ReducerUtils.createReducer<number[]>([],{
  [Actions.ADD_FETCHED_SHAPE_CODES]    : addCodes,
  [Actions.REMOVE_FETCHED_SHAPE_CODES] : removeCodes,
})

export default FetchedShapeCodes
