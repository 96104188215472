import Period          from "../model/Period"
import DateUtils       from "./DateUtils"

export default class StatisticsUtils{
  public static filterToPeriod(period:Period,dateKey:string,stats:any[]){
    const dateFrom = DateUtils.format(period.dateFrom)
    const dateTo = DateUtils.format(period.dateTo)
    return stats.filter(stat => {
      return stat[dateKey] >= dateFrom && dateTo >= stat[dateKey]
    })
  }

  public static calculateCPC(cost, clicks):number{
    if(clicks === 0){return 0}
    return cost/clicks
  }
  public static calculateCTR(clicks, impressions):number{
    if(impressions === 0){return 0}
    return clicks/impressions*100
  }

  //data : array of tuples. [impressions:number, impressionShare:number]
  public static calculateImpressionShare(data:number[][]):number{
    if(!data || data.length===0){return 0}
    let numerator = 0
    let denominator = 0
    for(let info of data){
      if(info[0]===0 || info[0]===null || info[1]===0 || info[1]===null){continue}
      numerator += info[0]
      denominator += info[0] / (info[1]/100)
    }
    if(denominator===0){return 0}
    return 100*numerator/denominator
  }
  //data : array of tuples. [clicks:number, clickShare:number]
  public static calculateClickShare(data:number[][]) : number {
    return StatisticsUtils.calculateImpressionShare(data)
  }
  //data : array of tuples. [impressionsInMarket:number, SearchBudgetLostImpressionShare:number]
  public static calculateLostImpressionShare(data:number[][]):number{
    if(!data || data.length===0){return 0}
    let numerator = 0
    let denominator = 0
    for(let info of data){
      if(info[0]===0 || info[0]===null || info[1]===0 || info[1]===null){continue}
      numerator += info[0] * (info[1]/100)
      denominator += info[0]
    }
    if(denominator===0){return 0}
    return 100*numerator/denominator
  }

  // public static calculateConversionRate(conversions: number, impressions: number) {
  public static calculateConversionRate(conversions: number, clicks: number) {
    if(conversions === 0 || clicks === 0){return 0}
    return conversions / clicks * 100
  }
  public static calculateCostPerConversion(cost: number, conversions: number) {
    if(cost === 0 || conversions === 0){return 0}
    return cost / conversions
  }
}
