import './FilterableSelect.css'
import * as React                   from 'react'

interface FilterableSelectProps{
  data                : any[]
  filterFunc          : (object:any, filter:string)=>boolean
  keyFinder           : (object:any)=>any
  nameFinder          : (object:any)=>string
  onSelect            : (obj?:any)=>any
  sortFunc           ?: (obj1:any, obj2:any)=>number
  nameOfDataSet      ?: string
  inputPlaceholder   ?: string
  defaultSelectedKey ?: any
}
const filterAccounts = (filter,accounts)=>{
  if(filter.length===0){return accounts}
  return accounts.filter(account=>
    String(account.id) === filter ||
    (account.name as string).toLowerCase().indexOf(filter)!==-1
  )
}

interface FilterableSelectState {
  filterValue : string
  selected ?: any
}

const NONE_SELECTED = "NO_OPTIONS_HAS_BEEN_SELECTED_FILTERABLESELECT"
class FilterableSelect extends React.Component<FilterableSelectProps,FilterableSelectState>{
  static defaultProps = {
    inputPlaceholder : "Filter"
  }
  constructor(props){
    super(props)
    this.state = {
      filterValue : "",
      selected    : props.defaultSelectedKey
    }
    this.handleFilterChange = this.handleFilterChange.bind(this)
  }
  handleFilterChange(event){
    const filter = event.target.value
    const filtered = this.props.data.filter(x=>this.props.filterFunc(x, filter))
    this.setState({
      filterValue : filter,
      selected    : undefined,
    })
    if(filtered.length===1){
      this.doOnSelect(this.props.keyFinder(filtered[0]))
    }
  }
  doOnSelect(value){
    this.setState({selected : value})
    this.props.onSelect(this.props.data.find(x=>this.props.keyFinder(x)==value))
  }
  render(){
    const filtered = this.props.data.filter(x=>this.props.filterFunc(x, this.state.filterValue))
    if(this.props.sortFunc){filtered.sort(this.props.sortFunc)}
    const pick = this.state.selected || NONE_SELECTED
    return (
      <div className='FilterableSelect'>
        <div>
          <input
            className='longInput'
            type='text'
            placeholder={this.props.inputPlaceholder}
            onChange={this.handleFilterChange}
          />
        </div>
        <div>
          <select
            key={pick /*remount because changes to defaultValue aren't enough*/}
            className='accountSelection longInput'
            defaultValue={pick}
            onChange={(e)=>this.doOnSelect(e.target.value)}
          >
            {pick===NONE_SELECTED &&
              <option key={NONE_SELECTED} value={NONE_SELECTED} disabled>
                Select {this.props.nameOfDataSet||"something"} ({filtered.length} found)
              </option>
            }
            {filtered.map(obj=>{
              const key = this.props.keyFinder(obj)
              return (
                <option
                  key={key}
                  value={key}
                >
                  {this.props.nameFinder(obj)}
                </option>
            )})}
          </select>
        </div>
      </div>
    )
  }
}
export default FilterableSelect
