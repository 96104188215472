import * as React  from "react"
import * as d3     from "d3"
import * as d3Tip  from "d3-tip"
import {GraphData} from "../../model/graph/Graph"

interface PathProps{
  data     : GraphData[]
  scalingX : (x:any)=>any
  scalingY : (x:any)=>any
  color    : string
}
class Path extends React.Component<PathProps,any>{
  private node
  constructor(props){
    super(props)
    this.renderGraph = this.renderGraph.bind(this)
    this.clearGraph = this.clearGraph.bind(this)
  }
  componentDidMount(){this.renderGraph()}
  componentDidUpdate(){this.renderGraph()}
  renderGraph(){
    this.clearGraph()
    const graph = d3.select(this.node)
    const line = d3.line()
      .x(d => this.props.scalingX(d.xValue))
      .y(d => this.props.scalingY(d.yValue))
    const path = graph.append("path")
      .datum(this.props.data)
        .attr("fill", "none")
        .attr("stroke", this.props.color)
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .attr("stroke-width", 2)
        .attr("d", line)
    const length = path.node().getTotalLength()
    path.attr("stroke-dasharray", length + " " + length)
      .attr("stroke-dashoffset", length)
      .transition()
        .duration(400)
        .attr("stroke-dashoffset", 0)
  }
  clearGraph(){d3.select(this.node).selectAll("path").remove()}
  render(){ return <g ref={node=>this.node=node} className={"line"}></g> }
}

export default Path
