import Actions              from "../model/constant/actions"
import UserActions          from "../model/constant/UserAction"
import LogBuilder           from "../controller/log/LogBuilder"
import {getToken}           from "../storeAccessor/Generic"
import DateUtils            from "../utils/DateUtils"

let tokenWaitInterval = null

export const ChangePeriod = (dateFrom:Date, dateTo:Date) => (dispatch, getState) => {
  const token = getToken(getState())
  const formattedFrom = DateUtils.format(dateFrom)
  const formattedTo = DateUtils.format(dateTo)
  if(!token){
    clearInterval(tokenWaitInterval)
    tokenWaitInterval = setInterval(()=>{
      const token = getToken(getState())
      if(token){
        LogBuilder.log(UserActions.CHANGE_PERIOD,token,{dateFrom:formattedFrom, dateTo:formattedTo})
        clearInterval(tokenWaitInterval)
      }
    }, 1000)
  }
  else{
    LogBuilder.log(UserActions.CHANGE_PERIOD,token,{dateFrom:formattedFrom, dateTo:formattedTo})
  }
  dispatch({
    type     : Actions.CHANGE_SELECTED_PERIOD,
    dateFrom : dateFrom,
    dateTo   : dateTo,
  })
}

