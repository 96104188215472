import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"
import ArrayUtils       from "../../utils/ArrayUtils"

const storeReplacements = (state,action) => {
  return action.replacements
}
const List = ReducerUtils.createReducer<any[]|null>(null,{
  [Actions.RETRIEVE_GRAMMAR_BOOST_REPLACEMENTS] : storeReplacements,
})
export default List
