import Actions              from "../../../../model/constant/actions"
import ReducerUtils         from "../../../../utils/ReducerUtils"
import ArrayUtils           from "../../../../utils/ArrayUtils"

const setList = (state, action) => {
  return action.list
}

interface ShapeInfo{
  id   : number
  code : number
  name : string
}

const AvailableShapes = ReducerUtils.createReducer<ShapeInfo[]>([],{
  [Actions.RETRIEVE_AVAILABLE_SHAPES_LIST] : setList,
})

export default AvailableShapes
