import Actions    from "../model/constant/actions"
import {Provider} from "../model/Provider"

const catchedActionTypes = [
  Actions.RETRIEVE_AW_CAMPAIGNS_STATS_FOR_ACCOUNTID,
  Actions.RETRIEVE_AW_GEOBIDS_STATS_OF_ACCOUNT,
  Actions.RETRIEVE_AW_KEYWORDS_STATS_OF_ACCOUNT,
  Actions.RETRIEVE_AW_SEARCHTERMS_STATS_OF_ACCOUNT,
  Actions.RETRIEVE_IMPRESSIONS_BY_DEVICE
]
let heldActions = []

const statsCompletion = store => next => action => {
  if(action.type === Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST){
    //On change of account, clear held actions
    heldActions = []
    return next(action)
  }
  if(action.type === Actions.RETRIEVE_AW_CAMPAIGNS_BASE_INFO && heldActions.length>0){
    //Campaigns base info got dispatch while we are holding actions. Let's match
    for(let heldAction of heldActions){
      next({ //We use next instead of store.dispatch() since these actions have already been computed by past middlewares
        ...heldAction,
        stats : matchStatsWithInfo(heldAction.stats, action.infoById)
      })
    }
    return next(action)
  }
  if(catchedActionTypes.indexOf(action.type)===-1){
    //Let these action continue their flow
    return next(action)
  }
  //Catched stats that need campaigns base info
  const accountId = action.accountId
  const state = store.getState()
  if(state.Campaigns.BaseInfo.Google.ById[accountId]){
    //Already have campaigns base info for the account
    action.stats = matchStatsWithInfo(
      action.stats,
      state.Campaigns.BaseInfo.Google.ById[accountId]
    )
    return next(action)
  }
  //We hold the action until the campaigns base info are dispatched
  heldActions.push(action)
}
const matchStatsWithInfo = (stats, baseInfo) => {
  stats.forEach((stat,index)=>{
    if(!stat.CampaignAWId){return}
    if('Provider' in stat && stat.Provider !== Provider.AW){return}
    const info = baseInfo[stat.CampaignAWId]
    if(info){
      const keys = Object.keys(info)
      for(const key of keys){stat[key] = info[key]}
      delete stat["AWId"]
    }
  })
  return stats
}

export default statsCompletion
