import Actions                   from "../../model/constant/actions"
import {
  MarketingEvent,
  ProtoMarketingApproach,
  MarketingEventBoundariesTypes
}                                from "../../model/adbuilder/"

export const updateLang = (lang: string) => (dispatch) => {
  dispatch({
    type: Actions.UPDATE_EDITING_MARKETING_EVENT_LANG,
    lang
  })
}

export const startEditing = (event?:MarketingEvent) => (dispatch, getState) => {
  dispatch({
    type : Actions.START_EDITING_MARKETING_EVENT,
    event
  })
}

export const updateTitle = (title:string) => (dispatch, getState) => {
  dispatch({
    type : Actions.UPDATE_EDITING_MARKETING_EVENT_TITLE,
    title
  })
}

export const updateDate = (start?:Date, end?:Date) => (dispatch, getState) => {
  dispatch({
    type  : Actions.UPDATE_EDITING_MARKETING_EVENT_DATES,
    start,
    end
  })
}

export const updateBoundaries = (boundariesPart:any, boundaryIndex:number) => (dispatch, _getState) => {
  dispatch({
    type       : Actions.UPDATE_EDITING_MARKETING_EVENT_BOUNDARIES,
    boundaries : boundariesPart,
    index      : boundaryIndex
  })
}

export const addBoundary = (type?:MarketingEventBoundariesTypes) => (dispatch, _getState) => {
  dispatch({
    type : Actions.UPDATE_EDITING_MARKETING_EVENT_ADD_BOUNDARY,
    hasType : type
  })
}

export const removeBoundary = (index:number) => (dispatch, _getState) => {
  dispatch({
    type : Actions.UPDATE_EDITING_MARKETING_EVENT_REMOVE_BOUNDARY,
    index
  })
}

export const setApproaches = (approaches:ProtoMarketingApproach[]) => (dispatch, getState) => {
  dispatch({
    type : Actions.SET_EDITING_MARKETING_EVENT_APPROACHES,
    approaches
  })
}
