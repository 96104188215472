import './index.css'
import * as React                                         from 'react'
import RoutingUtils                                       from "../../utils/RoutingUtils"
import Settings                                           from './Settings/Settings'
import GeoTargeting                                       from './GeoTargeting/GeoTargeting'
import Ads                                                from './Ads/Ads'
import Keywords                                           from './Keywords/Keywords'
import {getAccountId}                                     from '../../storeAccessor/Generic'
import requiresAdGroupConfigs                             from '../../components/loaders/adGroupConfigsLoader'
import requiresBrandingDefaultAdSets                      from '../../components/loaders/brandingDefaultAdSetsLoader'
import { connect }                                        from 'react-redux'
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Card
}                                                         from '@material-ui/core'
import {AdGroupConfigsByLang}                             from '../../model/branding/AdGroupConfigs'
import GeoTargetingInterface                              from '../../model/Store/GeoBid/GeoTargeting'
import Account                                            from '../../model/Store/Account/Account'
import {
  Translate,
  TranslateFunction,
  withLocalize
}                                                         from 'react-localize-redux'
import {withRouter, Switch, Route, Redirect}              from "react-router-dom"
import Actions                                            from '../../model/constant/actions'
import AdGroups                                           from './AdGroups/AdGroups'
import { AudienceAd }                                     from '../../model/branding/AudienceAd'
import {
  editAudienceAds,
  createMultipleAudienceAds,
}                                                         from '../../actions/branding/AdGroupConfigs'
import {State}                                            from "../../reducers/generic/reducerAssembly"
import ExplainedLoading                                   from "../../components/loading"
import {
  SimplifiedIdentityVariants,
}                                                         from '../../model/branding/SimplifiedIdentityVariants'
import {
  IdentityVariantsByLang
}                                                         from '../../model/branding/IdentityVariants'
import ForceIdentityVariantCreation                       from './Settings/ForceIdentityVariantCreation/ForceIdentityVariantCreation'
import requiresIdentityVariants                           from '../../components/loaders/identityVariantsLoader'
import { createIdentityVariants }                         from '../../actions/branding/IdentityVariants'



interface BrandingGivenProps {

}
interface BrandingOwnProps extends BrandingGivenProps{
  adGroupConfigsByLang : AdGroupConfigsByLang
  translate            : TranslateFunction
}

interface BrandingProps extends BrandingOwnProps {
  history
  accountId                  : number
  geoTargetings              : GeoTargetingInterface[]
  account                    : Account
  lang                       : string
  adGroup                    : string
  audienceAd                 : string
  audienceAdsToEdit          : AudienceAd[]
  defaultAds                 : State["Params"]["Branding"]["DefaultAds"][number]
  savingAudienceAds          : boolean
  identityVariantsByLang     : IdentityVariantsByLang
  savingIdentityVariants     : boolean
  loadingIdentityVariants    : boolean
  translate                  : TranslateFunction
  setLang                    : (lang:string) => void
  setAdGroup                 : (adGroup:string) => void
  setAudienceAd              : (audienceAd:string) => void
  changeAudienceAdsToEdit    : (audienceAds:AudienceAd[]) => void
  addToAudienceAdToEdit      : (audienceAd:AudienceAd) => void
  deleteFromAudienceAdToEdit : (audienceAdId:number|string) => void
  editAudienceAds            : (accountId:number,audienceAds:AudienceAd[]) => void
  getDefaultAdSet            : (accountId: number, adGroup: string, language: string) => void
  createMultipleAudienceAds  : (accountId:number,audienceAds:AudienceAd[]) => void
  createIdentityVariants     : (accountId:number, lang:string, identityVariants:SimplifiedIdentityVariants) => void
}

const mapStateToProps = (state:State,ownProps:BrandingOwnProps) => {
  const accountId = getAccountId(state)
  return {
    accountId,
    account           : state.Accounts.list.find(account => account.id === accountId),
    geoTargetings     : state.Params.GeoBids.GeoTargetings[accountId],
    lang              : state.Params.Branding.brandingConfigs.lang,
    adGroup           : state.Params.Branding.brandingConfigs.adGroup,
    audienceAd        : state.Params.Branding.brandingConfigs.audienceAd,
    audienceAdsToEdit : state.EditCopies.AudienceAdToEdit,
    defaultAds        : state.Params.Branding.DefaultAds[accountId],
    savingAudienceAds : state.Params.Branding.editingAudienceAds,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setLang                    : (lang:string) => dispatch({type:Actions.CHANGE_BRANDING_LANGUAGE,lang:lang}),
    setAdGroup                 : (adGroup:string) => dispatch({type:Actions.CHANGE_BRANDING_ADGROUP,adGroup:adGroup}),
    setAudienceAd              : (audienceAd:string) => dispatch({type:Actions.CHANGE_BRANDING_AUDIENCEAD,audienceAd:audienceAd}),
    changeAudienceAdsToEdit    : (audienceAds:AudienceAd[]) => dispatch({type:Actions.CHANGE_AUDIENCEAD_TOEDIT,audienceAds:audienceAds}),
    addToAudienceAdToEdit      : (audienceAd:AudienceAd) => dispatch({type:Actions.ADD_AUDIENCEAD_TO_AUDIENCEADS_TOEDIT,audienceAd:audienceAd}),
    deleteFromAudienceAdToEdit : (audienceAdId:number|string) => dispatch({type:Actions.DELETE_AUDIENCEAD_FROM_AUDIENCEADS_TOEDIT,audienceAdId:audienceAdId}),
    editAudienceAds            : (accountId:number,audienceAds:AudienceAd[]) => {
      dispatch(editAudienceAds(accountId,audienceAds))
    },
    createMultipleAudienceAds  : (accountId:number,audienceAds:AudienceAd[]) => {
      dispatch(createMultipleAudienceAds(accountId,audienceAds))
    },
    createIdentityVariants : (accountId:number, lang:string, identityVariants:SimplifiedIdentityVariants) =>
      dispatch(
        createIdentityVariants(accountId, lang, identityVariants)
      )
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
  }
}

const generateTabProps = (index:string|number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const tabs = [
  {label: "branding.tabs.settings",      value: "Settings"},
  {label: "branding.tabs.geoTargeting",  value: "Geo"},
  {label: "branding.tabs.adGroups",      value: "AdGroups"},
  {label: "branding.tabs.keywords",      value: "Keywords"},
]


const Branding = (props:BrandingProps) => {
  const langs = Object.keys(props.adGroupConfigsByLang)
  if(props.lang === undefined || langs.indexOf(props.lang) === -1) {
    props.setLang(langs[0])
  }
  const [tab, setTab]   = React.useState(tabs[0].value)

  // There is a possibility that there is no audience ads inside an adgroup config. If that ever happens, create one using the default ads.
  const audienceAdsToCreate:AudienceAd[] = []
  for(const lang in props.adGroupConfigsByLang){
    for(const adGroupName in props.adGroupConfigsByLang[lang]){
      if(props.adGroupConfigsByLang[lang][adGroupName].audienceAds.length === 0){
        const defaultAdsAdGroupName = (adGroupName.toUpperCase() !== "GENERAL" && adGroupName.toUpperCase() !== "NOM")
          ? "GENERAL"
          : adGroupName.toUpperCase()
        audienceAdsToCreate.push({
          id : -1,
          audienceId : "0",
          matcher : {
            lang : lang.toUpperCase(),
            group : "BRANDING",
            adGroupName
          },
          templates : props.defaultAds[lang.toUpperCase()][defaultAdsAdGroupName].slice(0,3)
        })
      }
    }
  }

  if(audienceAdsToCreate.length > 0){
    if(!props.savingAudienceAds){props.createMultipleAudienceAds(props.accountId, audienceAdsToCreate)}
    return <ExplainedLoading translateId="loadings.brandingAudienceAdsSetup"/>
  }

  React.useEffect(()=>{
    for(const tabParam of tabs){
      const tabPath = new RegExp(`/Branding/${tabParam.value}`, 'i')
      if(props.history.location.pathname.match(tabPath) && tabParam.value !== tab){
        setTab(tabParam.value)
        break
      }
    }
  },[props.history.location.pathname])
  if(props.lang === undefined || langs.length === 0){
    return (
      <Card className="Branding NoCampaign">
        <Typography variant="h6"><Translate id="branding.noCampaign" /></Typography>
      </Card>
    )
  }
  const handleTabChange = (event: React.ChangeEvent<{}>, value) => {
    RoutingUtils.replaceLastElementOfUrl(props.history, value)
    setTab(value)
  }
  const handleChangeLang = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setLang(event.target.value)
  }

  // If an identityVariant language is missing, force the user to create some before continuing.
  for (const lang of Object.keys(props.adGroupConfigsByLang)) {
    if (!props.identityVariantsByLang[lang]) {
      return (
        <div>
          <ForceIdentityVariantCreation
            identityVariantsByLang={props.identityVariantsByLang}
            savingIdentityVariants={props.savingIdentityVariants}
            loadingIdentityVariants={props.loadingIdentityVariants}
            adGroupConfigsByLang={props.adGroupConfigsByLang}
            account={props.account}
            createIdentityVariants={(lang, identityVariant)=>props.createIdentityVariants(props.accountId, lang, identityVariant)}
          />
        </div>
      )
    }
  }

  return (
    <div className="Branding" key={props.accountId}>
      <h3><Translate id="branding.title" /></h3>
      <AppBar style={{color:'white'}}  position="static">
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
        >
          {tabs.map(x=>
            <Tab {...x} label={props.translate(x.label)} {...generateTabProps(x.label)} />
          )}
        </Tabs>
      </AppBar>
      <Typography
        component="div"
        role="tabpanel"
        id={`simple-tabpanel-branding`}
        aria-labelledby={`simple-tab-branding`}
      >
        <Switch>
          <Route
            exact
            path={"*/Branding/"+tabs[0].value}
            render={()=>
              <Box p={3}>
                <Settings
                  key={props.accountId}
                  handleChangeLang={handleChangeLang}
                  lang={props.lang}
                  adGroupConfigsByLang={props.adGroupConfigsByLang}
                  accountId={props.accountId}
                />
              </Box>
            }
          />
          <Route
            exact
            path={"*/Branding/"+tabs[1].value}
            render={()=>
              <Box p={3}>
                <GeoTargeting lang={props.lang}/>
              </Box>
            }
            />
          <Route
            exact
            path={"*/Branding/"+tabs[2].value}
            render={()=>
              <Box p={3}>
                <AdGroups
                  adGroupConfigsByLang={props.adGroupConfigsByLang}
                  lang={props.lang}
                  handleChangeLang={handleChangeLang}
                  setAdGroup={props.setAdGroup}
                  setAudienceAd={props.setAudienceAd}
                />
              </Box>
            }
            />
          <Route
            exact
            path={"*/Branding/"+tabs[3].value}
            render={()=>
              <Box p={3}>
                <Keywords
                  adGroup = {props.adGroup}
                  setAdGroup = {props.setAdGroup}
                  lang={props.lang}
                  adGroupConfigsByName={props.adGroupConfigsByLang[props.lang]}
                  adGroupConfigsByLang ={props.adGroupConfigsByLang}
                  handleChangeLang ={handleChangeLang}
                />
              </Box>
            }
            />
          <Redirect from="/" to="/"/>
        </Switch>
      </Typography>
    </div>
  )
}
const Remounter = (props)=><Branding key={props.accountId} {...props}/>
export default (
  withLocalize(
    requiresIdentityVariants({letThrough:false})(
      requiresAdGroupConfigs({letThrough:false})(
        requiresBrandingDefaultAdSets()(
          connect(mapStateToProps,mapDispatchToProps,mergeProps)(
            withRouter(Remounter as any)
          )
        )
      )
    )
  )
) as React.ComponentType<BrandingGivenProps>
