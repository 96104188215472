import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    eventBoundaries : {
      display : "flex",
      gap : 10,
      justifyContent : "center",
      marginBottom : 15
    },
  })
)

export default useStyles
