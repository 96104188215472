import ObjectUtils           from "../../../../utils/ObjectUtils"
import ArrayUtils            from "../../../../utils/ArrayUtils"
import StatisticsUtils       from "../../../../utils/StatisticsUtils"
import {Provider}            from "../../../../model/Provider"
import BudgetStats           from "../../../../model/Store/Statistics/BudgetStats"

const findBudgetName = (budgetId:number, provider:Provider, budgetsStats:BudgetStats[]) => {
  const budget = budgetsStats.find(x=>x.BudgetID == budgetId && x.Provider == provider)
  if(!budget || !budget.BudgetName){return "unknown"}
  return budget.BudgetName
}
const formatBudgetName = (name:string):string => {
  if(!name.includes("Automated - ")){return "Others"}
  return name.replace("Automated - ","")
}
type Context = "impressions" | "clicks" | "cpc" | "cost" | "impShare" | "conversions" | "ctr" | "clickShare" | "conversionrate" | "cpcon"
const cacheKeyer = (context, accountId, bStats, cStats) => [context,accountId,bStats.length,cStats.length].join("-")
//It is possible for the cache to have false positives if the stats are reloaded after
//the initial loading and that those stats are different while having the same length.
//This is because of the use of length for the key instead of the whole list.
//Objective of this: not slowing down the whole thing
export const compileDispatcher:any = (context:Context, accountId:number, budgetsStats:any[], campaignsStats:any[]):any[] => {
  const key = cacheKeyer(context, accountId, budgetsStats, campaignsStats)
  if(!compileDispatcher.cache){compileDispatcher.cache = {}}
  else if(compileDispatcher.cache[key]){return compileDispatcher.cache[key]}
  let result
  switch(context){
    case "impressions"    : result = compileImpressions(budgetsStats, campaignsStats)    ; break
    case "clicks"         : result = compileClicks(budgetsStats, campaignsStats)         ; break
    case "cpc"            : result = compileCPC(budgetsStats, campaignsStats)            ; break
    case "cost"           : result = compileCost(budgetsStats, campaignsStats)           ; break
    case "impShare"       : result = compileImpShare(budgetsStats, campaignsStats)       ; break
    case "clickShare"     : result = compileClickShare(budgetsStats, campaignsStats)     ; break
    case "conversions"    : result = compileConversions(budgetsStats, campaignsStats)    ; break
    case "ctr"            : result = compileCTR(budgetsStats, campaignsStats)            ; break
    case "conversionrate" : result = compileConversionRate(budgetsStats, campaignsStats) ; break
    case "cpcon"          : result = compileCPCon(budgetsStats, campaignsStats)          ; break
    default : result = []
  }
  compileDispatcher.cache[key] = result
  return result
}
const compileImpressions = (budgetsStats:any[], campaignsStats:any[]):any[] => {
  return ObjectUtils.getObjectValues(
    campaignsStats.reduce((compiled, stat)=>{
      const budgetName = formatBudgetName(findBudgetName(stat.BudgetId,stat.Provider,budgetsStats))
      if(!compiled[budgetName]){
        compiled[budgetName] = {
          rawName : budgetName,
          value : 0
        }
      }
      compiled[budgetName].value += stat.Impressions
      return compiled
    },{})
  )
}
const compileClicks = (budgetsStats:any[], campaignsStats:any[]):any[] => {
  return ObjectUtils.getObjectValues(
    campaignsStats.reduce((compiled, stat)=>{
      const budgetName = formatBudgetName(findBudgetName(stat.BudgetId,stat.Provider,budgetsStats))
      if(!compiled[budgetName]){
        compiled[budgetName] = {
          rawName : budgetName,
          value : 0
        }
      }
      compiled[budgetName].value += stat.Clicks
      return compiled
    },{})
  )
}
const compileCPC = (budgetsStats:any[], campaignsStats:any[]):any[] => {
  return ObjectUtils.getObjectValues(
    campaignsStats.reduce((compiled, stat)=>{
      const budgetName = formatBudgetName(findBudgetName(stat.BudgetId,stat.Provider,budgetsStats))
      if(!compiled[budgetName]){
        compiled[budgetName] = {
          rawName : budgetName,
          cost : 0,
          clicks : 0
        }
      }
      compiled[budgetName].cost += stat.Cost
      compiled[budgetName].clicks += stat.Clicks
      return compiled
    },{})
  )
  .map(x=>({
    rawName : x.rawName,
    value : StatisticsUtils.calculateCPC(x.cost, x.clicks)
  }))
}
const compileCost = (budgetsStats:any[], campaignsStats:any[]):any[] => {
  return ObjectUtils.getObjectValues(
    campaignsStats.reduce((compiled, stat)=>{
      const budgetName = formatBudgetName(findBudgetName(stat.BudgetId,stat.Provider,budgetsStats))
      if(!compiled[budgetName]){
        compiled[budgetName] = {
          rawName : budgetName,
          value : 0
        }
      }
      compiled[budgetName].value += stat.Cost
      return compiled
    },{})
  )
}
const compileImpShare = (budgetsStats:any[], campaignsStats:any[]):any[] => {
  return ObjectUtils.getObjectValues(
    campaignsStats.reduce((compiled, stat)=>{
      if(stat.SearchImpressionShare === null){return compiled}
      const budgetName = formatBudgetName(findBudgetName(stat.BudgetId,stat.Provider,budgetsStats))
      if(!compiled[budgetName]){
        compiled[budgetName] = {
          rawName : budgetName,
          value : []
        }
      }
      compiled[budgetName].value.push([stat.Impressions, stat.SearchImpressionShare])
      return compiled
    },{})
  )
  .map(x=>({...x, value : StatisticsUtils.calculateImpressionShare(x.value)}))
}
const compileClickShare = (budgetsStats:any[], campaignsStats:any[]):any[] => {
  return ObjectUtils.getObjectValues(
    campaignsStats.reduce((compiled, stat)=>{
      if(stat.SearchClickShare === null){return compiled}
      const budgetName = formatBudgetName(findBudgetName(stat.BudgetId,stat.Provider,budgetsStats))
      if(!compiled[budgetName]){
        compiled[budgetName] = {
          rawName : budgetName,
          value : []
        }
      }
      compiled[budgetName].value.push([stat.Clicks, stat.SearchClickShare])
      return compiled
    },{})
  )
  .map(x=>({...x, value : StatisticsUtils.calculateClickShare(x.value)}))
}
const compileConversions = (budgetsStats:any[], campaignsStats:any[]):any[] => {
  return ObjectUtils.getObjectValues(
    campaignsStats.reduce((compiled, stat)=>{
      const budgetName = formatBudgetName(findBudgetName(stat.BudgetId,stat.Provider,budgetsStats))
      if(!compiled[budgetName]){
        compiled[budgetName] = {
          rawName : budgetName,
          value : 0
        }
      }
      compiled[budgetName].value += stat.Conversions
      return compiled
    },{})
  )
}
const compileCTR = (budgetsStats:any[], campaignsStats:any[]):any[] => {
  return ObjectUtils.getObjectValues(
    campaignsStats.reduce((compiled, stat)=>{
      const budgetName = formatBudgetName(findBudgetName(stat.BudgetId,stat.Provider,budgetsStats))
      if(!compiled[budgetName]){
        compiled[budgetName] = {
          rawName : budgetName,
          value : []
        }
      }
      compiled[budgetName].value.push([stat.Clicks, stat.Impressions])
      return compiled
    },{})
  )
  .map(x=>{
    const values = x.value.reduce((values, data)=>({
      c : values.c+data[0],
      i : values.i+data[1]
    }),{c:0,i:0})
    x.value = StatisticsUtils.calculateCTR(values.c, values.i)
    return x
  })
}

const compileConversionRate = (budgetsStats:any[], campaignsStats:any[]) => {
  return ObjectUtils.getObjectValues(
    campaignsStats.reduce((compiled, stat)=>{
      const budgetName = formatBudgetName(findBudgetName(stat.BudgetId,stat.Provider,budgetsStats))
      if(!compiled[budgetName]){
        compiled[budgetName] = {
          rawName : budgetName,
          value   : []
        }
      }
      compiled[budgetName].value.push([stat.Conversions, stat.Clicks])
      return compiled
    },{})
  )
  .map( x => {
    const values = x.value.reduce((values, data) => ({
      conversions : values.conversions + data[0],
      clicks      : values.clicks + data[1]
    }), {conversions: 0, clicks: 0})
    x.value = StatisticsUtils.calculateConversionRate(values.conversions, values.clicks)
    return x
  })
}

const compileCPCon = (budgetsStats:any[], campaignsStats:any[]) => {
  return ObjectUtils.getObjectValues(
    campaignsStats.reduce((compiled, stat)=>{
      const budgetName = formatBudgetName(findBudgetName(stat.BudgetId,stat.Provider,budgetsStats))
      if(!compiled[budgetName]){
        compiled[budgetName] = {
          rawName     : budgetName,
          cost        : 0,
          conversions : 0,
        }
      }
      compiled[budgetName].cost += stat.Cost
      compiled[budgetName].conversions += stat.Conversions
      return compiled
    },{})
  )
  .map(x=>({
    rawName : x.rawName,
    value   : StatisticsUtils.calculateCostPerConversion(x.cost, x.conversions)
  }))
}
