import ReducerUtils       from "../../../utils/ReducerUtils"
import Actions            from "../../../model/constant/actions"

const addDate = (state:string[],action) => {
  return [...state, action.date]
}
const removeDate = (state:string[],action) => {
  return state.filter(date=> date !== action.date)
}
const clearDates = (state:string[],action) => {
  return []
}

const Dates = ReducerUtils.createReducer<string[]>([],{
  [Actions.ADD_DATE]                         : addDate,
  [Actions.REMOVE_DATE]                      : removeDate,
  [Actions.CLEAR_DATES]                      : clearDates,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST] : clearDates
})

export default Dates
