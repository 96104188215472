import axios              from "axios"
import Actions            from "../../model/constant/actions"
import Vehicle            from "../../model/Store/Vehicle/Vehicle"
import Car                from "../../model/Store/Vehicle/Car"
import {getToken}         from "../../storeAccessor/Generic"
import Headstone          from "../../utils/Headstone";

const PASSED_ENGAGEMENT_RULES_TAG = "engagement-rules:passed"

enum InvLang {
  ALL     = "ALL",
  DEFAULT = "DEFAULT"
}

const retrieveInventorySuccess = (accountId:number, response, invLang: InvLang) => {
  const inventory = response.map(vehicle => {
    let v = null
    if(vehicle.instanceof === "Car"){
      v = new Car(
        vehicle.id,
        vehicle.make,
        vehicle.model,
        vehicle.year,
        vehicle.url,
        vehicle.bodystyle
      )
      v.withVIN(vehicle.VIN)
      v.withStockNumber(vehicle.stockNumber)
      v.withDisplayMakeModel(vehicle.displayMakeModel)
    }
    else{
      v = new Vehicle(
        vehicle.id,
        vehicle.make,
        vehicle.model,
        vehicle.year,
        vehicle.url
      )
    }
    if(vehicle.removedOn!==null){v.withRemovedOn(new Date(vehicle.removedOn))}
    return v
    .withNumPictures(vehicle.numPhotos)
    .withPrice(vehicle.price.amount)
    .withCleanerId(vehicle.cleanerId)
    .withSRPPoolId(vehicle.SRPPoolId)
    .withPassedRules(vehicle.tags.includes(PASSED_ENGAGEMENT_RULES_TAG))
    .withStatusCurrent(vehicle.current)
    .withState(vehicle.state)
    .withDaysInInventory(vehicle.daysInInventory)
  })

  if (invLang === InvLang.ALL) {
    return {
      type      : Actions.RETRIEVE_ALL_LANG_INVENTORY_FOR_ACCOUNT,
      inventory : inventory,
      accountId,
    }
  }

  return {
    type      : Actions.RETRIEVE_INVENTORY_FOR_ACCOUNT,
    inventory : inventory,
    accountId,
  }
}


export const clearInventory = (accountId: number) => (dispatch, getState) => {
  return {
    type      : Actions.RETRIEVE_INVENTORY_FOR_ACCOUNT,
    inventory : [],
    accountId
  }
}

export const retrieveAllInventory = (accountId:number) => (dispatch, getState) => {
  dispatch({
    type : Actions.RETRIEVING_ALL_LANG_INVENTORY
  })
  axios.get(Headstone.getApiUrl() + `/${accountId}/Inventory/${InvLang.ALL}/NO_LIMIT`, {
    headers : {
      Authorization : "Bearer "+getToken(getState())
    },
  })
  .then(response => {
    dispatch(retrieveInventorySuccess(accountId, response.data, InvLang.ALL))
  })
  .catch(error=> {
    dispatch(retrieveInventorySuccess(accountId, [], InvLang.ALL))
  })
}


export default (accountId:number) => (dispatch, getState) => {
  // Same call, but with ALL language inventory
  // It'll be used in Campaigns->Search's Days In Inventory column - DEFAULT lang data will continue to be used everywhere else.
  dispatch(retrieveAllInventory(accountId))

  dispatch({
    type : Actions.RETRIEVING_INVENTORY
  })
  axios.get(Headstone.getApiUrl() + `/${accountId}/Inventory/${InvLang.DEFAULT}/NO_LIMIT`, {
    headers : {
      Authorization : "Bearer "+getToken(getState())
    },
  })
  .then(response => {
    dispatch(retrieveInventorySuccess(accountId, response.data, InvLang.DEFAULT))
  })
  .catch(error=> {
    console.error(error)
    dispatch(retrieveInventorySuccess(accountId, [], InvLang.DEFAULT))
  })
}

