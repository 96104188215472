import * as React                     from "react"
import {connect}                      from "react-redux"
import retrievePermissionProfiles     from "../../actions/permission/permissionProfiles"
import ExplainedLoading               from "../loading"
import ErrorBoundary                  from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  return {
    profiles           : state.Permissions.Profiles.List.all,
    retrievingProfiles : state.Permissions.Profiles.Retrieving,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadProfiles : ()=>dispatch(retrievePermissionProfiles())
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
const PermissionProfileLoader = props => {
  if(props.profiles===undefined || props.profiles.length===0){
    if(props.retrievingProfiles===false){props.loadProfiles()}
    return <ExplainedLoading translateId="loadings.permissionProfiles"/>
  }
  return <ErrorBoundary>{props.render(props.profiles)}</ErrorBoundary>
}
const PermissionProfileLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(PermissionProfileLoader)

const requiresPermissionProfiles = Component => props => (
  <PermissionProfileLoaderConnected
    render={ profiles => <Component profiles={profiles} {...props} /> }
  />
)
export default requiresPermissionProfiles
