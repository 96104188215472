import './CreateProxy.css'
import * as React                             from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Icon,
  Divider,
  Switch,
}                                             from '@material-ui/core'
import { ProxyGroup }                         from '../../../../../model/pacy/ProxyGroup'
import {
  Proxy, BeginningOfMonthBoostConfig
}                                             from '../../../../../model/pacy'
import CreateProxyParams                      from './CreateProxyParams'
import WeightsConfiguration                   from '../WeightsConfiguration'
import {
  AccountModifier,
  createAddProxyAccountModifier,
  createToggleProxyAccountModifier,
  createSetProxyWeightAccountModifier,
  generate
}                                             from '../../../../../model/pacy/AccountModifiers'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                                             from 'react-localize-redux'

interface CreateProxyOwnProps {
  proxyGroups          ?: ProxyGroup[]
  selectedProxyGroupId ?: string
  handleCloseModal      : () => void
  addAccountModifiers   : (newActions:AccountModifier[]) => void
}
interface CreateProxyProps extends CreateProxyOwnProps {
  translate : TranslateFunction
}

const CreateProxy = (props:CreateProxyProps) => {
  const [step,setStep] = React.useState(1)
  const [newProxy,setNewProxy] = React.useState<Proxy>(()=>{
    return {
      active : true,
      beginningOfMonthBoost : {active:false,boost:1,length:0,shape:"FLAT"},
      agents : [] as any ,
      id : generate(),
      maxSharing : 0,
      name : '',
      sharing : true,
      weight : 0,
      lastUpdatedOn : ''
    }
  })
  const handleBoostConfirm = (boost:BeginningOfMonthBoostConfig) => setNewProxy(prevState=>({...prevState,beginningOfMonthBoost:boost}))
  const handleSetActive = (event) => setNewProxy(prevState=>({...prevState,active:event.target.checked}))
  const handleNameChange = (event) => {
    const temp = event.target.value
    if(temp.length <= 128) {
      setNewProxy(prevState=>({
        ...prevState,name:temp
      }))
    }
  }
  const handleSetMaxSharing = (newValue) => setNewProxy(prevState=>({...prevState,maxSharing:newValue/100}))
  const [selectedProxyGroupId,setSelectedProxyGroupId] = React.useState(props.selectedProxyGroupId || '')
  const handleChangeProxyGroup = (event) => setSelectedProxyGroupId(event.target.value)
  const handleConfirmProxy = (proxiesChanged:{[id:string]:number},proxiesActivated:string[]) => {

    const proxiesToActivate = proxiesActivated.map(id=>(
      createToggleProxyAccountModifier(id,true,selectedProxyGroupId)
    ))
    const weightsChanged = Object.keys(proxiesChanged).map(id=>(
      createSetProxyWeightAccountModifier(id,proxiesChanged[id], selectedProxyGroupId)
    ))

    props.addAccountModifiers([createAddProxyAccountModifier(selectedProxyGroupId,{
      name:newProxy.name,
      weight:newProxy.weight,
      active:newProxy.active,
      sharing:true,
      maxSharing:newProxy.maxSharing,
      beginningOfMonthBoost:newProxy.beginningOfMonthBoost,
      id:newProxy.id
    }),...proxiesToActivate,...weightsChanged])
    props.handleCloseModal()
  }
  if(step === 1) {
    return (
      <Card className="CreateProxy">
        <CardHeader
          title={
            <div className="CreateProxyHeader">
              <Typography variant="h6" style={{display:'flex',alignItems:'center'}}>
                <Icon style={{marginRight:5}}>add</Icon>
                  <Translate id="pacyConfigs.createProxy" />
                </Typography>
              <div className="Active">
                <span style={{color : newProxy.active ?'green':'red'}  }>
                  {newProxy.active ? props.translate("pacyConfigs.active") : props.translate("common.paused")}
                </span>
                <Switch color="primary" size="medium" checked={newProxy.active} onChange={handleSetActive}/>
              </div>
            </div>
          }
        />
        <Divider />
        <CardContent className="Content">
          <div className="ProxyParams">
            <CreateProxyParams
              handleBoostConfirm={handleBoostConfirm}
              maxSharing={newProxy.maxSharing}
              handleSetMaxSharing={handleSetMaxSharing}
              boost={newProxy.beginningOfMonthBoost}
              name={newProxy.name}
              handleNameChange={handleNameChange}
              active={newProxy.active}
              handleChangeProxyGroup={handleChangeProxyGroup}
              handleSetActive={handleSetActive}
              selectedProxyGroupId={selectedProxyGroupId}
              proxyGroups={props.proxyGroups}
            />
          </div>
        </CardContent>
        <Divider />
        <CardActions className="CreateProxyFooter">
          <Button onClick={props.handleCloseModal} variant="outlined" color="secondary" size="large">
            <Translate id="common.cancel" />
          </Button>
          <Button disabled={!selectedProxyGroupId || !newProxy.name} onClick={()=>setStep(2)} style={{color:'white'}} variant="contained" color="primary" size="large">
            <Translate id="pacyConfigs.weightsConfig" />
          </Button>
        </CardActions>
      </Card>
    )
  } else {
    return (
      <WeightsConfiguration
        proxies={[newProxy,...props.proxyGroups.find(proxyGroup=>proxyGroup.id===selectedProxyGroupId).proxies]}
        handleCloseModal={()=>setStep(1)}
        handleWeightsChange={handleConfirmProxy}
      />
    )
  }
}

export default withLocalize(CreateProxy as any) as React.ComponentType<CreateProxyOwnProps>
