import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container : {
      display : "grid",
      gridTemplateColumns : "1fr 1fr 1fr 1fr",
      gridColumnGap : 10,
      gridRowGap : 20,
      [theme.breakpoints.down("lg")] : {
        gridTemplateColumns : "1fr 1fr 1fr"
      },
      [theme.breakpoints.down("md")] : {
        gridTemplateColumns : "1fr 1fr"
      }
    }
  })
)

export default useStyles
