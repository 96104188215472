import './IdentityVariantInput.css'
import * as React                 from 'react'
import { Divider, Typography }    from '@material-ui/core'
import ChipInput                  from 'material-ui-chip-input'
interface IdentityVariant {
  cities      : string[]
  dealerNames : string[]
  makes       : string[]
}
interface IdentityVariantInputOwnProps {
  type                : string
  identityVariants    : IdentityVariant
  setIdentityVariants : (IdentityVariant) => void
  variants            : string[]
  title               : string
  setValues           : (values:string[]) => void
}
interface IdentityVariantInputProps extends IdentityVariantInputOwnProps {

}
const IdentityVariantInput = (props:IdentityVariantInputProps) => {
  function handleAdd(chip) {
    props.setValues([...props.variants,chip])
    props.setIdentityVariants({
      ...props.identityVariants,
      [props.type] : [...props.identityVariants[props.type],chip]
    })
  }
  function handleDelete(chip) {
    props.setValues(
      props.variants.filter(i=>chip != i)
    )
    props.setIdentityVariants({
      ...props.identityVariants,
      [props.type] : props.variants.filter(i=>chip != i)
    })
  }
  return (
    <div className="IdentityVariantInput">
      <Typography
        variant="body1"
      >{props.title}</Typography>
      <Divider variant="fullWidth"/>
      <ChipInput
        blurBehavior="add"
        value={props.variants}
        fullWidth
        style={{marginTop:'10px'}}
        onAdd={handleAdd}
        onDelete={handleDelete}
      />
    </div>
  )
}

export default IdentityVariantInput
