import * as React                             from "react"
import {connect}                              from "react-redux"
import {Link}                                 from "react-router-dom"
import BootstrapTable                         from 'react-bootstrap-table-next'
import filterFactory                          from 'react-bootstrap-table2-filter'
import {textFilter}                           from 'react-bootstrap-table2-filter'
import {Button}                               from "carbon-components-react"
import RoutingUtils                           from "../../utils/RoutingUtils"
import ArrayUtils                             from "../../utils/ArrayUtils"
import Account                                from "../../model/Store/Account/Account"
import {Panel}                                from "../../components/Panel/Panel"
import GoBack                                 from "../../components/Button/LinkGoBack"

interface AccountsOwnProps{
}
interface AccountsProps extends AccountsOwnProps{
  accounts                   : Account[]
}
const mapStateToProps = (state,ownProps:AccountsOwnProps)=>{
  return {
    accounts              : state.Accounts.list,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

const Accounts = (props:AccountsProps) => {
  const columns = [
    {
      text:"ID",
      dataField:"id",
      sort:true,
      filter:textFilter(),
    },{
      text:"Name",
      dataField:"name",
      sort:true,
      filter:textFilter(),
    },{
      text:"Action",
      isDummyField:true,
      dataField:"kek",
      sort:false,
      formatter : (_,row)=>{
        return (
          <div>
            <Link to={"/Management/Accounts/"+row.id}>
              Manage
            </Link>
          </div>
        )
      },
    }
  ]
  return (
    <Panel title="Accounts">
      <GoBack to=""/>
      <BootstrapTable
        hover
        keyField="id"
        data={props.accounts.sort((a,b)=>ArrayUtils.comp(a.name.toLowerCase(), b.name.toLowerCase()))}
        columns={columns}
        filter={filterFactory()}
        classes={"normalSizedDatatable"}
      />
    </Panel>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(Accounts)
