import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"
import ObjectUtils      from "../../utils/ObjectUtils"
import WebLead          from "../../model/Store/Tracking/WebLead"
import Navigation       from "../../model/Store/Tracking/Navigation"

const getCurrentLeads = (state,accountId) : WebLead[] => {
  return (
    state[accountId] && state[accountId]["leads"]
    ? state[accountId]["leads"]
    : []
  )
}
const getCurrentNavs = (state,accountId) : Navigation[] => {
  return (
    state[accountId] && state[accountId]["navigations"]
    ? state[accountId]["navigations"]
    : []
  )
}
const merge = (oldElements:WebLead[]|Navigation[],newElements:WebLead[]|Navigation[]) => {
  var complete = {}
  for(let old of oldElements){complete[ old.browserId+old.timestamp ] = old}
  for(let add of newElements){
    let casted = parseFloat(add.browserId)
    complete[ casted+add.timestamp] = { ...add, browserId:casted }
  }
  return ObjectUtils.getObjectValues(complete)
}
const retrieveWebLeadsForAccount = (state,action) => {
  if(!action.leads){return state}
  const accountId = action.accountId
  const current = getCurrentLeads(state,accountId)
  const other   = getCurrentNavs(state,accountId)
  return {
    ...state,
    [accountId] : {
      "leads"       : merge(current,action.leads),
      "navigations" : other,
    }
  }
}
const retrieveNavigationsForAccount = (state,action) => {
  if(!action.navigations){return state}
  const accountId = action.accountId
  const current = getCurrentNavs(state,accountId)
  const other   = getCurrentLeads(state,accountId)
  const merged = merge(current,action.navigations)
  return {
    ...state,
    [accountId] : {
      "navigations" : merged,
      "leads"       : other,
    }
  }
}
interface state {
  [accountId:number] : {
    leads       : WebLead[]
    navigations : Navigation[]
  }
}
export const Web = ReducerUtils.createReducer<state>({},{
  [Actions.RETRIEVE_WEB_LEADS_OF_ACCOUNT]       : retrieveWebLeadsForAccount,
  [Actions.RETRIEVE_USER_NAVIGATION_OF_ACCOUNT] : retrieveNavigationsForAccount
})
