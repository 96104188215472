import {Guideline} from "./Guideline"
import {PacingRatioConfig} from "./PacingRatioConfig"

export enum DecisionType {
  "ATTEMPT_SPEND"  = "ATTEMPT_SPEND",
  "USE_GUIDELINE"  = "USE_GUIDELINE",
  "BOOST_BY"       = "BOOST_BY",
  "ENABLE_PACY"    = "ENABLE_PACY",
  "DISABLE_PACY"   = "DISABLE_PACY",
  "FORGET_ACCOUNT" = "FORGET_ACCOUNT",
  "ADD_GUIDELINE"  = "ADD_GUIDELINE",
  "MISSING_STATS"  = "MISSING_STATS",
  "CUTOFF_SPEND"   = "CUTOFF_SPEND",
  "UPDATE_PACING_RATIO_CONFIG" = "UPDATE_PACING_RATIO_CONFIG",
  // This type does not exist in Pacy. It is only used in iDB to ease the changeHistory logic
  "PACY_ENABLEMENT" = "PACY_ENABLEMENT",
}


export interface BaseDecision {
  readonly id        : string
  readonly type      : DecisionType
  readonly accountId : number
  readonly summary   : string
  readonly details   : any
  readonly createdOn : string
}
export interface UseGuidelineDecision extends BaseDecision {
  type     : DecisionType.USE_GUIDELINE
  details  : {
    proxyGroupId : string
    guidelineId  : string
  }
}
export interface AttemptSpendDecision extends BaseDecision {
  type     : DecisionType.ATTEMPT_SPEND
  details  : {
    agentId        : string
    spendAttemptId : string
    newAmount      : number
    previousAmount : number
  }
}
export interface BoostByDecision extends BaseDecision {
  type     : DecisionType.BOOST_BY
  details  : {
    proxyId     : string
    baseline    : number
    boost       : number
  }
}
export interface ForgetAccountDecision extends BaseDecision {
  type     : DecisionType.FORGET_ACCOUNT
  details  : {
    userId : number
  }
}
export interface AddGuidelineDecision extends BaseDecision {
  type     : DecisionType.ADD_GUIDELINE
  details  : {
    guideline : Guideline
    accountStateId : string
  }
}
export interface EnablePacyDecision extends BaseDecision {
  type     : DecisionType.ENABLE_PACY
  details  : {
    userId : number
  }
}
export interface DisablePacyDecision extends BaseDecision {
  type     : DecisionType.DISABLE_PACY
  details  : {
    userId : number
  }
}
export interface PacyEnablementDecision extends BaseDecision {
  type     : DecisionType.PACY_ENABLEMENT
  details  : {
    status : boolean
    userId : number
  }
}
export interface MissingStatsDecision extends BaseDecision {
  type    : DecisionType.MISSING_STATS,
  details : {
    proxyGroupId           : string
    guidelineId            : string
    minStatsUnderGuideline : number
  }
}
export interface CutOffDecision extends BaseDecision {
  type     : DecisionType.CUTOFF_SPEND
  details  : {
    proxyGroupId : string
    guidelineId  : string
  }
}
export interface UpdatePacingRatioConfigDecision extends BaseDecision {
  type     : DecisionType.UPDATE_PACING_RATIO_CONFIG
  details  : {
    userId            : number
    pacingRatioConfig : PacingRatioConfig
    accountStateId    : string
  }
}

export type Decision = (
    ForgetAccountDecision
  | AddGuidelineDecision
  | UseGuidelineDecision
  | AttemptSpendDecision
  | BoostByDecision
  | EnablePacyDecision
  | DisablePacyDecision
  | MissingStatsDecision
  | CutOffDecision
  | UpdatePacingRatioConfigDecision
  | PacyEnablementDecision
)

const isForgetAccountDecision = (decision:BaseDecision):decision is ForgetAccountDecision => {
  return decision.type === DecisionType.FORGET_ACCOUNT
}
const determineDecisionType = (decision:Decision):Decision => {
  if(isForgetAccountDecision(decision)){
    return decision as ForgetAccountDecision
  }
  return decision
}
