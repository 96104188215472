import './index.css'
import * as React        from "react"
import {withLocalize}    from "react-localize-redux"
import {Tabs, Tab}       from "carbon-components-react"
import {Panel}           from "../../components/Panel/Panel"
import WebLeadsTable     from "./component/WebLeadsTable"
import Calls             from "./component/Calls"

class LeadsModule extends React.Component<any,any> {
  constructor(props){
    super(props)
    this.state = {
      selectedTab : 0
    }
  }
  render(){return (
    <div className='LeadsModule'>
      <Panel title={this.props.translate("leads.title")} >
        <Tabs className="TabsContainer" selected={this.state.selectedTab}>
          <Tab className="Tab" label={this.props.translate("leads.webLeads.webLeads")} onClick={()=>this.setState({selectedTab:0})} key='0'>
            <WebLeadsTable />
          </Tab>
          <Tab className="Tab" label={this.props.translate("leads.calls.calls")} onClick={()=>this.setState({selectedTab:1})} key='1'>
            <Calls />
          </Tab>
        </Tabs>
      </Panel>
    </div>
  )}
}

export default withLocalize(LeadsModule)
