import Buttons       from "../../../../model/constant/menuButtons"
import Permissions   from "../../../../model/constant/Permissions"
import {
  AlertButtonConfig
}                    from "../../../../model/alerting/Alert"
import {AlertType}   from "../../../../model/constant/AlertType"

export interface MenuButtonDescription{
  name                 : string
  icon                 : string
  path                 : string
  accountBased         : boolean
  staffOnly            : boolean
  requiredPermissions ?: Permissions[]
  subButtons          ?: MenuButtonDescription[]
  possibleAlerts      ?: AlertButtonConfig[]
}
export const MENU_BUTTONS : MenuButtonDescription[] = [
  {
    name:Buttons.DASHBOARD,
    icon:"home",
    path:"/Dashboard",
    accountBased:true,
    staffOnly:false,
  },{
    name:Buttons.CAMPAIGNS,
    icon:"perm_media",
    path:"/Campaigns",
    accountBased:true,
    staffOnly:false,
    subButtons:[
      {name:Buttons.SEARCH, icon:"search", path:"/Search", accountBased:true, staffOnly:false},
      {name:Buttons.DISPLAY, icon:"image_aspect_ratio", path:"/Display", accountBased:true, staffOnly:false},
    ]
  },{
    name:Buttons.LEADS ,
    icon:"highlight",
    path:"/Leads",
    accountBased:true,
    staffOnly:false,
  },{
    name:Buttons.REPORTS,
    icon:"list_alt",
    path:"/Reports",
    accountBased:true,
    staffOnly:false,
    subButtons:[
      {name:Buttons.ADS_REPORT,  icon:"report", path:"/Ads",  accountBased:true, staffOnly:false},
      {name:Buttons.BING_REPORT, icon:"report", path:"/MicrosoftAdvertising",  accountBased:true, staffOnly:false},
      {name:Buttons.WAZE,        icon:"textsms", path:"/Waze", accountBased:true, staffOnly:false},
      {name:Buttons.PACY,        icon:"account_balance", path:"/Budgets", accountBased:true, staffOnly:false, requiredPermissions:[Permissions.Pacy_View]},
    ]
  },{
    name:Buttons.PARAMS,
    icon:"list",
    path:"/Params",
    accountBased:true,
    staffOnly:false,
    subButtons:[
      {
        name:Buttons.BUDGETS,
        icon:"receipt",
        path:"/Budgets",
        accountBased:true,
        staffOnly:false,
        possibleAlerts:[
          {
            type:AlertType.PACY_V0USAGE,
            requiredPermissions:[Permissions.Pacy_Edit_Config]
          },
        ]
      },
      {name:Buttons.BRANDING,   icon:"loyalty",         path:"/Branding",   accountBased:true, staffOnly:false , requiredPermissions:[Permissions.Branding_View]},
      {name:Buttons.GEOTARGET,  icon:"place",           path:"/Geobid",     accountBased:true, staffOnly:false},
      {name:Buttons.ADCOPY,     icon:"edit",            path:"/Adcopy",     accountBased:true, staffOnly:false},
      {name:Buttons.KEYWORDS,   icon:"vpn_key",         path:"/Keywords",   accountBased:true, staffOnly:false},
      {name:Buttons.RULES,      icon:"filter_list",     path:"/Rules",      accountBased:true, staffOnly:false},
      {name:Buttons.STRATEGIES, icon:"monetization_on", path:"/Strategies", accountBased:true, staffOnly:false},
      {name:Buttons.FEEDS,      icon:"view_agenda",     path:"/Feeds",      accountBased:true, staffOnly:false, requiredPermissions:[Permissions.Keystone_ChangeStrategy]},
    ]
  },{
    name:Buttons.AD_BUILDER,
    icon:"build",
    path:"/AdBuilder",
    accountBased:true,
    staffOnly:false,
    requiredPermissions:[Permissions.Keystone_ViewAdBuilder]
  },{
    name:Buttons.ACCOUNT,
    icon:"view_module",
    path:"/Account",
    accountBased:true,
    staffOnly:true,
  },{
    name:Buttons.PROFILE,
    icon:"perm_contact_calendar",
    path:"/Profile",
    accountBased:false,
    staffOnly:false,
  },{
    name:Buttons.MANAGEMENT,
    icon:"perm_contact_calendar",
    path:"/Management",
    accountBased:false,
    staffOnly:true,
    subButtons:[
      {name:Buttons.CREATE_USER,  icon:"person_add",    path:"/CreateUser",  accountBased:true, staffOnly:true},
      {name:Buttons.USERS,        icon:"search",        path:"/Users",       accountBased:true, staffOnly:true},
      {name:Buttons.ACCOUNTS,     icon:"recent_actors", path:"/Accounts",    accountBased:true, staffOnly:true},
    ]
  },{
    name:Buttons.SYSTEM,
    icon:"settings",
    path:"/System",
    accountBased:false,
    staffOnly:true,
  }
]

