import UserActions                     from "../../model/constant/UserAction"
import retrieveDesiredStatus           from "./retrieveDesiredStatusExceptions"
import LogBuilder                      from "../../controller/log/LogBuilder"
import {getToken}                      from "../../storeAccessor/Generic"
import Headstone, { StatusExceptions } from "../../utils/Headstone"
import ActionUtils                     from "../../utils/ActionUtils"

export default (accountId:number, campaignNames:string[], campaignStatus:StatusExceptions['status']) => (dispatch, getState) => {
  const statusExceptions = campaignNames.map((campaignName)=>{
    return {campaignName,status:campaignStatus}
  })
  const token = getToken(getState())
  LogBuilder.log(UserActions.SAVE_STATUS_EXCEPTION,token,{
    accountId     : accountId,
    campaignNames : campaignNames,
    status        : campaignStatus
  })
  ActionUtils.retryOnFailure(
    ()=>Headstone.addStatusException(token,accountId,statusExceptions)
    .catch((error)=>{
      console.log(error)
      if(error.response.status !== 409 && error.response.data === "STATUS_EXCEPTION_ALREADY_EXISTS") {
        throw error
      }
      dispatch(setDesiredStatusException(accountId,token,statusExceptions,false))
    })
  )
  .then((response)=>{
    dispatch(retrieveDesiredStatus(accountId))
  })
  .catch((error)=>{
    console.log(error.response)
  })
}
export const setDesiredStatusException = (accountId:number,token:string,statusExceptions:StatusExceptions[],retrieveStatus:boolean=true) => (dispatch,getState) => {
  ActionUtils.retryOnFailure(
    ()=>Headstone.setStatusException(token,accountId,statusExceptions)
  )
  .then((response)=>{
    if(retrieveStatus) {
      dispatch(retrieveDesiredStatus(accountId))
    }
  })
  .catch(error=>{
    console.log(error)
  })
}
