import Actions               from "../../model/constant/actions"
import ReducerUtils          from "../../utils/ReducerUtils"

const loginError = (state, action) => {
  return action.error
}

export const LoginFailedMessage = ReducerUtils.createReducer<string>(null,{
  [Actions.AUTHENTIFY_LOGIN_ERROR] : loginError,
})
