import {combineReducers}          from 'redux'
import List                       from "./FeedsList"
import SignedUrlsList             from "./SignedUrlsList"
import Retrieving                 from "./FeedsListRetrieving"
import SignedUrlsRetrieving       from "./SignedUrlsRetrieving"
import AvailableFeeds             from './AvailableFeeds'
import AvailableFeedsRetrieving   from './AvailableFeedsRetrieving'


export default combineReducers({
  List,
  Retrieving,
  SignedUrlsList,
  SignedUrlsRetrieving,
  AvailableFeeds,
  AvailableFeedsRetrieving,
})
