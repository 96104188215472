import "./SetupTable.css"
import * as React                from "react"
import moment                    from "moment"
import classNames                from "classnames"
import {
  withLocalize,
  Translate,
  TranslateFunction
}                                from "react-localize-redux"
import {StringMap}               from "../../../../../model/generics"
import {ProxyGroup}              from "../../../../../model/pacy/ProxyGroup"
import {
  Guideline,
  getGuidelineForDay,
}                                from "../../../../../model/pacy/Guideline"
import MonthSetupModal           from "./MonthSetupModal"
import SetupTableRow             from "./SetupTableRow"

interface SetupTableOwnProps{
  proxyGroup   : ProxyGroup
  shownMonths  : string[]
  color        : string
  setGuideline : (year:string, month:string, amount:number|StringMap<number>)=>void
  readOnly    ?: boolean
}
interface SetupTableProps extends SetupTableOwnProps{
  translate : TranslateFunction
}

const SetupTable = (props:SetupTableProps) => {
  const [selectedMonthIndex, setSelected] = React.useState<number | undefined>()
  const modalOpened = selectedMonthIndex !== undefined
  const guidelinesPerMonth:StringMap<Guideline> = props.shownMonths.reduce((perMonth, month)=>{
    perMonth[month] = getGuidelineForDay(props.proxyGroup.guidelines, moment(month).endOf("month").format("YYYY-MM-DD"))
    return perMonth
  },{})
  const currentMonth = moment().format("YYYY-MM")

  return (
    <div className="SetupTable">
      <table>
        <thead>
          <tr>
            <th style={{width:"40%"}}><Translate id="pacyPlanner.budget"/></th>
            <th style={{width: "10%"}}></th>
            {props.shownMonths.map((month,i)=>
              <th
                key={month}
                className={classNames("MonthCell", "ColMonth", "Index"+i, {
                  CurrentMonth : currentMonth === month,
                  PastMonth    : month < currentMonth,
                })}
                style={{width:"20%"}}
              >
                <Translate id={"common.months."+moment(month).format("MM")}/>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {props.proxyGroup.proxies.map((proxy, i) =>
            <SetupTableRow
              index={i}
              proxy={proxy}
              proxyGroup={props.proxyGroup}
              shownMonths={props.shownMonths}
              guidelinesPerMonth={guidelinesPerMonth}
              color={props.color}
              setSelected={setSelected}
            />
          )}
        </tbody>
      </table>

      {modalOpened &&
        <MonthSetupModal
          open={true}
          month={props.shownMonths[selectedMonthIndex]}
          proxyGroup={props.proxyGroup}
          currentGuideline={guidelinesPerMonth[props.shownMonths[selectedMonthIndex]]}
          onRequestClose={()=>setSelected(undefined)}
          onConfirm={(values)=>{
            const g = guidelinesPerMonth[props.shownMonths[selectedMonthIndex]]
            const year = props.shownMonths[selectedMonthIndex].slice(0,4)
            const month = props.shownMonths[selectedMonthIndex].slice(5,7)
            props.setGuideline(year, month, values)
            setSelected(undefined)
          }}
          readOnly={props.readOnly}
        />
      }
    </div>
  )
}

export default withLocalize(SetupTable as any) as React.ComponentType<SetupTableOwnProps>
