import './FilterCheckboxesList.css'
import * as React                                   from 'react'
import {withLocalize,TranslateFunction}             from "react-localize-redux"
import List                                         from '@material-ui/core/List'
import ListItem                                     from '@material-ui/core/ListItem'
import FilterCheckbox                               from './FilterCheckbox/FilterCheckbox'

interface FilterCheckboxesListOwnProps {
  data         : Row[]
  handleToggle : (e)=>void
}
interface FilterCheckboxesListProps extends FilterCheckboxesListOwnProps{
  translate : TranslateFunction
}
export interface Row{
  name       : string | (()=>React.ReactNode | string)
  id         : string | number
  checked    : boolean
}

const FilterCheckboxesList = (props:FilterCheckboxesListProps) => {
  const checkboxesList = props.data.map( filter => {
    return (
      <ListItem className="ListItem" key={filter.id}>
        <FilterCheckbox filter={filter} handleToggle={props.handleToggle} />
      </ListItem>
    )
  })
  return (
    <List>
      <div className="FilterCheckboxesList">{checkboxesList}</div>
    </List>
  )
}

export default withLocalize(FilterCheckboxesList as any) as React.ComponentType<FilterCheckboxesListOwnProps>
