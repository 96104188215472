import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    alert : {
      textAlign : "center",
      paddingTop : 12,
      verticalAlign : "center",
      backgroundColor : "#E4FAE8",
      width : 500,
      height: 50,
      borderRadius: 4,
    },
    errorAlert : {
      textAlign : "center",
      paddingTop : 12,
      verticalAlign : "center",
      backgroundColor : "#ED646480",
      width : 500,
      height: 50,
      borderRadius: 4,
    }
  })
)

export default useStyles
