import "./ProxyGroupReport.css"
import * as React from "react"
import {connect}                     from "react-redux"
import moment                        from "moment"
import chroma                        from "chroma-js"
import PacyAccessors                 from "../../../storeAccessor/Pacy"
import {StringMap}                   from "../../../model/generics"
import Period                        from "../../../model/Period"
import BudgetStats                   from "../../../model/Store/Statistics/BudgetStats"
import {ProxyGroup}                  from "../../../model/pacy/ProxyGroup"
import ArrowButton                   from "../../../components/Button/ArrowButton"
import Increment                     from "../../../components/input/Increment"
import SpentAndPacingChart           from "../../budget/pacy/planner/ProxyGroupSummaryChart"
import AgentAttemptsChart            from "./AgentAttemptsChart"
import AgentSpentChart               from "./AgentSpentChart"

interface ProxyGroupReportOwnProps{
  proxyGroup               : ProxyGroup
  requestReportingDataLoad : (period:Period)=>boolean
}
interface ProxyGroupReportProps extends ProxyGroupReportOwnProps{
  budgetStatsByMonth       : StringMap<BudgetStats[]>
}

const makeMapStateToProps = () => {
  const statsSelector = PacyAccessors.makeStatsForProxyGroupByMonthSelector()
  return (state,ownProps:ProxyGroupReportOwnProps)=>{
    return {
      budgetStatsByMonth : statsSelector(state, ownProps)
    }
  }
}

const colorScaleForProxies = chroma.scale(["f00","0f0","00f"])

const ProxyGroupReport = (props:ProxyGroupReportProps) => {
  const [rightMostMonth, setMonth] = React.useState(moment().format("YYYY-MM"))
  const [numberMonth, setNumberMonth] = React.useState(3)
  let months = []
  for(let i = numberMonth-1; i >= 0; --i){
    months.push(moment(rightMostMonth).subtract(i, "months").format("YYYY-MM"))
  }
  const colors = colorScaleForProxies.colors(props.proxyGroup.proxies.length)
  const colorPerProxy = props.proxyGroup.proxies.reduce((perProxy, proxy, i)=>{
    perProxy[proxy.id] = colors[i]
    return perProxy
  },{})
  return (
    <div className="ProxyGroupReport">
      <div className="Navigation">
        <ArrowButton
          orientation="left"
          onClick={()=>setMonth(moment(rightMostMonth).subtract(1, "month").format("YYYY-MM"))}
          small
        />
        <ArrowButton
          orientation="right"
          onClick={()=>setMonth(moment(rightMostMonth).add(1, "month").format("YYYY-MM"))}
          small
        />
        {moment(rightMostMonth).format("MMMM YYYY")}
      </div>
      <Increment min={1} max={24} initial={numberMonth} onChange={setNumberMonth} small/>
      <div className="Charts">
        <SpentAndPacingChart
          proxyGroup={props.proxyGroup}
          shownMonths={months}
          requestReportingDataLoad={props.requestReportingDataLoad}
          budgetStatsByMonth={props.budgetStatsByMonth}
          syncId={props.proxyGroup.id}
        />
        <hr/>
        <AgentAttemptsChart
          proxyGroup={props.proxyGroup}
          shownMonths={months}
          requestReportingDataLoad={props.requestReportingDataLoad}
          budgetStatsByMonth={props.budgetStatsByMonth}
          syncId={props.proxyGroup.id}
          colorPerProxy={colorPerProxy}
        />
        <hr/>
        <AgentSpentChart
          proxyGroup={props.proxyGroup}
          shownMonths={months}
          requestReportingDataLoad={props.requestReportingDataLoad}
          budgetStatsByMonth={props.budgetStatsByMonth}
          syncId={props.proxyGroup.id}
          colorPerProxy={colorPerProxy}
        />
      </div>
    </div>
  )
}

export default connect(makeMapStateToProps)(ProxyGroupReport)
