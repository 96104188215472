import * as React                       from 'react'
import BootstrapTable                   from 'react-bootstrap-table-next'
import filterFactory                    from 'react-bootstrap-table2-filter'
import {textFilter}                     from 'react-bootstrap-table2-filter'
import {selectFilter}                   from 'react-bootstrap-table2-filter'
import paginationFactory                from 'react-bootstrap-table2-paginator'
import Hint                             from "../../../components/alert/Hint"
import ArrayUtils                       from "../../../utils/ArrayUtils"
import requiresPermissionProfiles       from "../../../components/loaders/permissionProfilesLoader"

interface PickBasePermissionSetupProps{
  onPick   : (pickCode:string)=>void
  profiles : {code:string,name:string,description:string}[]
}
const pointer = {
  cursor : "pointer"
}
const PickBasePermissionSetup = (props:PickBasePermissionSetupProps) =>{
  return (
    <div className='PickBasePermissionSetup'>
      <Hint text="Pick a permission profile to give this user (you can edit it later)"/>
      <BootstrapTable
        data={props.profiles}
        columns={[
          {dataField:"code",hidden:true},
          {dataField:"name",        text:"Name",           sort:true},
          {dataField:"description", text:"Description",    sort:true},
        ]}
        keyField='code'
        rowEvents={{
          onClick : (e,row,rowIndex)=>props.onPick(row.code)
        }}
        rowStyle={{cursor:"pointer"}}
        classes="normalSizedDatatable"
      />
    </div>
  )
}
export default requiresPermissionProfiles(PickBasePermissionSetup)
