export const AVAILABLE_KPIS = {
  impressions     : "impressions",
  clicks          : "clicks",
  cpc             : "cpc",
  cost            : "cost",
  impressionshare : "impressionshare",
  clickshare      : "clickshare",
  conversions     : "conversions",
  ctr             : "ctr",
  conversionrate  : "conversionrate",
  cpcon           : "cpcon", // cost per conversion
}

export const DEPRECATED_KPIS = {
  averageposition : "averageposition"
}
