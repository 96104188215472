import ReducerUtils            from "../../../utils/ReducerUtils"
import Actions                 from "../../../model/constant/actions"
import { AudienceAd }          from "../../../model/branding/AudienceAd"
import { newAdGroupsByLang }   from "../../../model/branding/newAdGroup"
import SupportedLanguages      from "../../../model/branding/SupportedLanguages"

const updateNewAdGroupName = (state, action: { name: string }) => {
  return {
    ...state,
    EN: {
      ...state['EN'],
      name : action.name,
      audienceAds : [...state['EN'].audienceAds].map((audienceAd=>{
        return {...audienceAd,matcher:{...audienceAd.matcher,adGroupName : action.name}}
      }))
    },
    FR: {
      ...state['FR'],
      name : action.name,
      audienceAds : [...state['FR'].audienceAds].map((audienceAd=>{
        return {...audienceAd,matcher:{...audienceAd.matcher,adGroupName : action.name}}
      }))
    }
  }
}
const updateNewAdGroupKeywords = (state,action:{lang:string,keywords:string[]}) => {
  return {
    ...state,
    [action.lang]:{
      ...state[action.lang],
      keywordPatterns : action.keywords
    }
  }
}
const addAudienceAd = (state,action:{audienceAd:AudienceAd,lang:string}) => {
  return {
    ...state,
    [action.lang]:{
      ...state[action.lang],
      audienceAds: [...state[action.lang]['audienceAds'].filter(audience=>audience.audienceId!==action.audienceAd.audienceId),action.audienceAd]
    }
  }
}
const deleteAudienceAd = (state,action:{audienceAdId:number}) => {
  return SupportedLanguages.reduce((acc,lang)=>{
    if(state[lang]) {
      acc = {
        ...acc,[lang] : {
          ...acc[lang], audienceAds : [...acc[lang].audienceAds].filter(audience=>audience.audienceId!==action.audienceAdId)
        }
      }
    }
    return acc
  },{...state})
}
const checkNewAdGroupExistingAudienceAds = (state,action:{lang:string}) => {
  if(state[action.lang] && state[action.lang].audienceAds.length === 0){
    let existingAudienceAds
    for(const language of SupportedLanguages){
      if(language !== action.lang && state[language] && state[language].audienceAds.length > 0){
        existingAudienceAds = state[language].audienceAds
        break
      }
    }
    if(existingAudienceAds !== undefined){
      return {
        ...state,
        [action.lang] : {
          ...state[action.lang],
          audienceAds : existingAudienceAds.map(audience=>{
            return {
              ...audience,
              matcher : {
                ...audience.matcher,
                lang : action.lang
              },
              templates: [
                {id : -1, headlines:['','',''], descriptions:['','']},
                {id : -2, headlines:['','',''], descriptions:['','']},
                {id : -3, headlines:['','',''], descriptions:['','']}
              ]
            }
          })
        }
      }
    }
  }
  if(state[action.lang] && state[action.lang].audienceAds.length !== 0 ) {
    const existingAudienceAdsId = SupportedLanguages.reduce((acc,lang)=>{
      if(lang !== action.lang && state[lang] && state[lang].audienceAds.length > 0) {
        state[lang].audienceAds.forEach((ad)=>{
          if(acc.find(audienceAd=>audienceAd.audienceId === ad.audienceId) === undefined) {
            acc.push(ad)
          }
        })
      }
      return acc
    },[])
    const currentLangExistingAdsId = state[action.lang].audienceAds.map((audienceAd)=>{
      return audienceAd.audienceId
    })
    const audiencesToAdd = existingAudienceAdsId.filter(ad=>!currentLangExistingAdsId.includes(ad.audienceId)).map(ad=>{
      return {
        ...ad,
        templates : [
          {id : -1, headlines:['','',''], descriptions:['','']},
          {id : -2, headlines:['','',''], descriptions:['','']},
          {id : -3, headlines:['','',''], descriptions:['','']}
        ],
        matcher: {
          ...ad.matcher,lang:action.lang
        }
      }
    })
    return {
      ...state,
      [action.lang]: {
        ...state[action.lang],audienceAds:[
          ...state[action.lang].audienceAds,...audiencesToAdd
        ]
      }
    }
  }
  return state
}

const resetNewAdGroup = (state,action) => {
  return {
    'EN': {
      name : '',
      keywordPatterns : [],
      audienceAds : []
    },
    'FR': {
      name : '',
      keywordPatterns : [],
      audienceAds : []
    }
  }
}
const defaultValue = SupportedLanguages.reduce((defaultValue, lang)=>{
  defaultValue[lang] = {
    name : '',
    keywordPatterns : [],
    audienceAds : []
  }
  return defaultValue
}, {})
const newAdGroupConfig = ReducerUtils.createReducer<newAdGroupsByLang>(defaultValue, {
    [Actions.UPDATE_NEWADGROUP_NAME]: updateNewAdGroupName,
    [Actions.UPDATE_NEWADGROUP_KEYWORDS] : updateNewAdGroupKeywords,
    [Actions.ADD_NEWADGROUP_AUDIENCEAD] : addAudienceAd,
    [Actions.DELETE_AUDIENCEAD_NEWADGROUP] : deleteAudienceAd,
    [Actions.RESET_NEWADGROUP] : resetNewAdGroup,
    [Actions.CHECK_NEWADGROUP_EXISTING_AUDIENCEADS] : checkNewAdGroupExistingAudienceAds
  })

export default newAdGroupConfig
