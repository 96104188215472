import {combineReducers} from "redux"
import AWSearch          from "./AWSearch"
import Retrieving        from "./Retrieving/Retrieving"

const Audiences = combineReducers({
  AWSearch,
  Retrieving
})

export default Audiences
