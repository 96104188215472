import './Hint.css'
import * as React   from 'react'
import {
  Icon,
  Typography
}                   from '@material-ui/core'

interface HintOwnProps {
  hint : string
}
interface HintProps extends HintOwnProps {}

const Hint = (props:HintProps) => {
  return (
    <div className="Hint">
      <Icon className="Icon">emoji_objects</Icon>
      <Typography className="Tip" variant="caption">{props.hint}</Typography>
    </div>
  )
}

export default Hint
