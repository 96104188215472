import * as React                     from "react"
import {connect}                      from "react-redux"
import Period                         from "../../model/Period"
import {loadNavigation}               from "../../actions/tracking/Navigation"
import ExplainedLoading               from "../loading"
import ErrorBoundary                  from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  const accountId = state.Accounts.selected
  const webInfos = state.Tracking.Web[accountId]
  return {
    accountId              : accountId,
    period                 : state.Period,
    navigations            : webInfos ? webInfos.navigations : undefined,
    needtoFetchNavigations : state.Tracking.NeedToFetchNavigations,
    fetching               : state.Tracking.FetchingNavigations[accountId],
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    fetchNavigations : (accountId:number, period:Period) => {
      dispatch(loadNavigation(accountId, period))
    },
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,
    ...DP,
    ...ownProps,
    fetchNavigations : ()=>DP.fetchNavigations(SP.accountId, SP.period)
  }
}
const NavigationsLoader = props => {
  if(props.navigations===undefined || props.fetching || props.needtoFetchNavigations){
    if(!props.fetching && props.needtoFetchNavigations && props.accountId){props.fetchNavigations()}
    return <ExplainedLoading translateId="loadings.navigations"/>
  }
  return <ErrorBoundary>{props.render(props.navigations)}</ErrorBoundary>
}
const NavigationsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(NavigationsLoader)

const requiresNavigations = Component => props => (
  <NavigationsLoaderConnected
    render={ navigations => <Component navigations={navigations} {...props} /> }
  />
)
export default requiresNavigations
