import * as React                from "react"
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography, Divider,
  ExpansionPanelDetails,
  Icon,
  Chip,
}                                from "@material-ui/core"
import ExpandMoreIcon            from '@material-ui/icons/ExpandMore'
import { MakeModelsRow }         from "../../model/Store/Cleaner"
import ArrayUtils                from "../../utils/ArrayUtils"
import {
  withLocalize,
  LocalizeContextProps,
  Translate
}                                from "react-localize-redux"
import {
  FixedSizeList as List,
  ListItemKeySelector
}                                from "react-window"

interface RowsToCullAlertOwnProps extends LocalizeContextProps {
  culled        : Array<MakeModelsRow&{inStock:boolean}>,
  unculled      : Array<MakeModelsRow&{inStock:boolean}>,
  warningString : string
}

const RowsToCullAlert = (props: RowsToCullAlertOwnProps) => {
  const sortFn = <T extends MakeModelsRow&{inStock:boolean}>(a:T, b:T) : 0|1|-1 => {
    // inStock first
    // sort by makemodel alphanumeric instead
    if(a.inStock && !b.inStock){return -1}
    if(!a.inStock && b.inStock){return 1}
    return a.DisplayMakeModel < b.DisplayMakeModel ? -1 : (
      a.DisplayMakeModel > b.DisplayMakeModel ? 1 : 0
    )
  }
  const sortedCulled   = React.useMemo(()=>ArrayUtils.uniqueFunc(props.culled.sort(sortFn), x=>x.DisplayMakeModel),[props.culled])
  const sortedUnculled = React.useMemo(()=>ArrayUtils.uniqueFunc(props.unculled.sort(sortFn), x=>x.DisplayMakeModel),[props.unculled])
  const vehicleItemKeyFunc:ListItemKeySelector = (index, data)=>data.vehicles[index].DisplayMakeModel
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <div className="WarningYellow">
          <Typography
            variant="body2"
            color="inherit"
          >
            {props.warningString}
          </Typography>
        </div>
      </ExpansionPanelSummary>
      <Divider/>
      <ExpansionPanelDetails>
        <div className="ExpansionPanelDetails">
          <div className="CulledVehicles">
            <div className="WarningYellow CulledVehiclesTitle">
              <Icon className="CulledVehiclesIcon">warning</Icon>
              <Typography
                variant="body2"
                color="inherit"
              >
                {props.translate('adBuilder.rowsToCullAlert.culledRows')}
              </Typography>
            </div>
            <ol className="CulledVehiclesList">
              <List
                height={150}
                itemCount={sortedCulled.length}
                itemSize={24}
                itemData={{vehicles : sortedCulled}}
                itemKey={vehicleItemKeyFunc}
              >
                {VehicleRow}
              </List>
            </ol>
          </div>
          <Divider/>
          <div className="CulledVehicles">
            <div className="SafeBlue CulledVehiclesTitle">
              <Icon className="CulledVehiclesIcon">check_circle_outline</Icon>
              <Typography
                variant="body2"
                color="inherit"
              >
                {props.translate('adBuilder.rowsToCullAlert.unculledRows')}
              </Typography>
            </div>
            <ol className="CulledVehiclesList">
              <List
                height={150}
                itemCount={sortedUnculled.length}
                itemSize={24}
                itemData={{vehicles : sortedUnculled}}
                itemKey={vehicleItemKeyFunc}
              >
                {VehicleRow}
              </List>
            </ol>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

interface VehicleRowProps{
  index : number
  style : object
  data : {
    vehicles   : Array<MakeModelsRow&{inStock:boolean}>
  }
}
class VehicleRow extends React.PureComponent<VehicleRowProps>{
  render(){
    const {index, style, data} = this.props
    const row = data.vehicles[index]
    return (
      <li style={style}>
        <Typography variant="caption">
          {row.DisplayMakeModel}
          {row.inStock && <>&nbsp;<Chip size="small" disabled label={<Translate id='adBuilder.rowsToCullAlert.inStock'/>}/></>}
        </Typography>
      </li>
    )
  }
}

export default withLocalize(RowsToCullAlert)
