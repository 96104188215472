import UserActions                       from "../../model/constant/UserAction"
import Friend                            from "../../model/Store/Campaign/Friend"
import retrieveDesiredGeoBidExceptions   from "./retrieveDesiredGeoBidExceptions"
import LogBuilder                        from "../../controller/log/LogBuilder"
import {getToken}                        from "../../storeAccessor/Generic"
import ActionUtils                       from "../../utils/ActionUtils"
import Headstone                         from "../../utils/Headstone"
import {
  DesiredGeoPlace,
  DesiredGeoCircle
}                                        from "../../page/campaign/component/search/view/Geo/Geo"


export const createDesiredGeoBidException = (friend:Friend, desiredGeos:(DesiredGeoPlace | DesiredGeoCircle)[]) => (dispatch, getState) => {
  const token = getToken(getState())
  const accountId = friend.getAccountId()
  const campaignNames = friend.getCampaignNames()

  const body = {
    "campaignNames": campaignNames,
    "geos": desiredGeos
  }

  LogBuilder.log(UserActions.SAVE_GEO_EXCEPTION,token,{
    accountId     : accountId,
    campaignNames : campaignNames,
    geos          : desiredGeos
  })

  ActionUtils.retryOnFailure(
    ()=>Headstone.addGeoBidException(token, accountId, body)
  )
  .then((response) => dispatch(retrieveDesiredGeoBidExceptions(friend)))
  .catch((error) => console.log(error.response))
}

export const updateGeoBidException = (friend:Friend, desiredGeos: (DesiredGeoPlace | DesiredGeoCircle)[]) => (dispatch, getState) => {
  const token = getToken(getState())
  const accountId = friend.getAccountId()
  const campaignNames = friend.getCampaignNames()

  const body = {
    "campaignNames": campaignNames,
    "geos": desiredGeos
  }

  ActionUtils.retryOnFailure(
    () => Headstone.setGeoBidException(token, accountId, body)
  )
  .then((response) => {
    dispatch(retrieveDesiredGeoBidExceptions(friend))
  })
  .catch(error=> {
    console.log(error)
  })
}
