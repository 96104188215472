
export enum SelectorType {
  MakeIs                 = "MakeIs",
  MakeIsNot              = "MakeIsNot",
  ModelIs                = "ModelIs",
  ModelIsNot             = "ModelIsNot",
  YearIs                 = "YearIs",
  YearIsNot              = "YearIsNot",
  YearAtLeast            = "YearAtLeast",
  YearAtMost             = "YearAtMost",
  PriceAtLeast           = "PriceAtLeast",
  PriceAtMost            = "PriceAtMost",
  NumberOfPhotosAtLeast  = "NumberOfPhotosAtLeast",
  NumberOfPhotosAtMost   = "NumberOfPhotosAtMost",
  DaysInInventoryAtLeast = "DaysInInventoryAtLeast",
  DaysInInventoryAtMost  = "DaysInInventoryAtMost",
  VehicleState           = "VehicleState",
  VehicleType            = "VehicleType",
  StockNumberIs          = "StockNumberIs",
  StockNumberIsNot       = "StockNumberIsNot",
}
interface SelectorBase<T extends SelectorType> {
  /**
   * @min 0
   */
  id   ?: number
  type :  T
}

export interface MakeIs extends SelectorBase<SelectorType.MakeIs>{
  /**
   * @minLength 1
   */
   make : string
}
export interface MakeIsNot extends SelectorBase<SelectorType.MakeIsNot>{
  /**
   * @minLength 1
   */
   make : string
}
export interface YearIs extends SelectorBase<SelectorType.YearIs>{
  /**
   * @min 1700
   * @max 2050
   */
  year : number
}
export interface YearIsNot extends SelectorBase<SelectorType.YearIsNot>{
  /**
   * @min 1700
   * @max 2050
   */
  year : number
}
export interface YearAtLeast extends SelectorBase<SelectorType.YearAtLeast>{
  /**
   * @min 1700
   * @max 2050
   */
  year : number
}
export interface YearAtMost extends SelectorBase<SelectorType.YearAtMost>{
  /**
   * @min 1700
   * @max 2050
   */
  year : number
}
export interface VehicleState extends SelectorBase<SelectorType.VehicleState>{
  state : "USED"|"NEW"
}
export interface VehicleType extends SelectorBase<SelectorType.VehicleType>{
  vehicleType : "CAR"
}
export interface DaysInInventoryAtLeast extends SelectorBase<SelectorType.DaysInInventoryAtLeast>{
  /**
   * @min 0
   */
  days : number
}
export interface DaysInInventoryAtMost extends SelectorBase<SelectorType.DaysInInventoryAtMost>{
  /**
   * @min 0
   */
  days : number
}
export interface NumberOfPhotosAtLeast extends SelectorBase<SelectorType.NumberOfPhotosAtLeast>{
  /**
   * @min 0
   */
  number : number
}

export interface NumberOfPhotosAtMost extends SelectorBase<SelectorType.NumberOfPhotosAtMost>{
  /**
   * @min 0
   */
  number : number
}
export interface PriceAtLeast extends SelectorBase<SelectorType.PriceAtLeast>{
  /**
   * @min 0
   */
  price : number
}
export interface PriceAtMost extends SelectorBase<SelectorType.PriceAtMost>{
  /**
   * @min 0
   */
  price : number
}
export interface ModelIs extends SelectorBase<SelectorType.ModelIs>{
  /**
   * @minLength 1
   */
  model   : string,
  isRegex : boolean
}
export interface ModelIsNot extends SelectorBase<SelectorType.ModelIsNot>{
  /**
   * @minLength 1
   */
  model   : string,
  isRegex : boolean
}
export interface StockNumberIs extends SelectorBase<SelectorType.StockNumberIs> {
  /**
   * @minLength 1
   */
  stockNumber : string,
  isRegex     : boolean
}
export interface StockNumberIsNot extends SelectorBase<SelectorType.StockNumberIsNot> {
  /**
   * @minLength 1
   */
  stockNumber : string,
  isRegex     : boolean
}

export type Selector = MakeIs|MakeIsNot|YearIs|YearIsNot|YearAtLeast|YearAtMost|VehicleState|VehicleType|DaysInInventoryAtLeast|DaysInInventoryAtMost|NumberOfPhotosAtLeast|NumberOfPhotosAtMost|PriceAtLeast|PriceAtMost|ModelIs|ModelIsNot|StockNumberIs|StockNumberIsNot

export interface SelectorGroup{
  /**
   * @min 0
   */
  id       ?: number
  name      : string
  selectors : ReadonlyArray<Selector>
}

type HeadstoneEngagementRule = ReadonlyArray<SelectorGroup>

export default HeadstoneEngagementRule
