import ReducerUtils       from "../../../utils/ReducerUtils"
import Actions            from "../../../model/constant/actions"

const addProxyGroup = (state:string[],action) => {
  return [...state , action.proxyGroupId]
}

const removeProxyGroup = (state:string[],action) => {
  return state.filter(proxyGroupId => proxyGroupId !== action.proxyGroupId)
}

const clearProxyGroups = (state:string[],action) => {
  return []
}

const ProxyGroups = ReducerUtils.createReducer<string[]>([],{
  [Actions.ADD_PROXYGROUP]                   : addProxyGroup,
  [Actions.REMOVE_PROXYGROUP]                : removeProxyGroup,
  [Actions.CLEAR_PROXYGROUP]                 : clearProxyGroups,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST] : clearProxyGroups
})

export default ProxyGroups
