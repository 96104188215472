import Actions               from "../../../model/constant/actions"
import ReducerUtils          from "../../../utils/ReducerUtils"
import ObjectUtils           from "../../../utils/ObjectUtils"
import GoogleSearchTermStats from "../../../model/Store/Statistics/SearchTermStats"

const retrieveSearchTermsForAccount = (state,action) =>{
  if (action.stats){
    if (state[action.accountId]){
      var newState={}
      for (let stat of state[action.accountId] ){ newState[stat.CampaignAWId+stat.Day] = stat }
      for (let stat of action.stats            ){ newState[stat.CampaignAWId+stat.Day] = stat }
      return {...state, [action.accountId] : ObjectUtils.getObjectValues(newState)}
    }
    return {...state,[action.accountId] : action.stats}
  }
  return {...state,[action.accountId] : []}
}
interface GoogleSearchTermByAccount{
  [accountId:number] : GoogleSearchTermStats[]
}
export const SearchTermsStats = ReducerUtils.createReducer<GoogleSearchTermByAccount>({},{
  [Actions.RETRIEVE_AW_SEARCHTERMS_STATS_OF_ACCOUNT] : retrieveSearchTermsForAccount
})
