import styles                       from "./EventBoundariesStyles"
import * as React                   from "react"
import {
  MarketingEvent,
  ConcreteMarketingEventBoundaries as ConcreteMEB,
  MarketingEventBoundariesTypes as MEBTypes
}                                   from "../../../model/adbuilder"
import KeyValueTable                from "../../../components/table/SimpleKeyValueTable"
import requireCleaner               from "../../../components/loaders/cleanerLoader"
import {ReferenceRows}              from "../../../model/Store/Cleaner"

interface EventBoundariesProps{
  spillRight       : boolean
  boundary         : ConcreteMEB
  cleanerReference : ReferenceRows
}

const extractBoundariesValue = (boundaries:ConcreteMEB, key:string) => boundaries[key] || <span className='notset'>-</span>

const extractFromCleaner = (key:string, cleanerReference:ReferenceRows, cleanerIdkey:string|undefined) => {
  const vehicleData = cleanerReference.find(ref=>ref.ID==cleanerIdkey)?.[key]
  return vehicleData ? vehicleData : ""
}

const EventBoundaries = (props:EventBoundariesProps) => {
  const cleanerId = extractBoundariesValue(props.boundary,"cleanerId")
  const state = extractBoundariesValue(props.boundary, "vehicleState")
  const campaignType = extractBoundariesValue(props.boundary, "campaignType")
  let KV = {}
  switch(props.boundary.type){
    case MEBTypes.SPECIFIC_CAMPAIGN :
      KV["adBuilder.eventOverview.boundariesCampaign"] = props.boundary.campaignName
      break
    case MEBTypes.SPECIFIC_M :
      KV["adBuilder.eventOverview.boundariesState"]    = state
      KV["adBuilder.eventOverview.boundariesMake"]     = extractFromCleaner("DisplayMake",props.cleanerReference, cleanerId)
      break
    case MEBTypes.SPECIFIC_MM :
      KV["adBuilder.eventOverview.boundariesState"]    = state
      KV["adBuilder.eventOverview.boundariesModel"]    = extractFromCleaner("DisplayMakeModel",props.cleanerReference, cleanerId)
      break
    case MEBTypes.SPECIFIC_MMY :
      KV["adBuilder.eventOverview.boundariesState"]    = state
      KV["adBuilder.eventOverview.boundariesModel"]    = extractFromCleaner("DisplayMakeModel",props.cleanerReference, cleanerId)
      KV["adBuilder.eventOverview.boundariesYear"]     = props.boundary.year
      break
    case MEBTypes.SPECIFIC_MY :
      KV["adBuilder.eventOverview.boundariesState"]    = state
      KV["adBuilder.eventOverview.boundariesMake"]     = extractFromCleaner("DisplayMake",props.cleanerReference, cleanerId)
      KV["adBuilder.eventOverview.boundariesYear"]     = props.boundary.year
      break
    case MEBTypes.SPECIFIC_Y :
      KV["adBuilder.eventOverview.boundariesState"]    = state
      KV["adBuilder.eventOverview.boundariesYear"]     = props.boundary.year
      break
    case MEBTypes.NON_INVENTORY : 
      KV["adBuilder.eventOverview.boundariesState"]    = campaignType
      break
    default:
      KV["adBuilder.eventOverview.boundariesState"]    = state
  }
  const classes = styles()
  return (
    <div className={classes.eventBoundaries}>
      <KeyValueTable
        spillRight={props.spillRight}
        keyValue={KV}
        translateKeys
        gap={10}
      />
    </div>
  )
}
export default requireCleaner()(EventBoundaries)
