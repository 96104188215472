import { combineReducers }                from "redux"
import AdGroupConfigs                     from './AdGroupConfigs'
import IdentityVariants                   from './IdentityVariants'
import retrievingAdGroupConfigs           from "./retrievingAdGroupConfigs"
import retrievingIdentityVariants         from "./retrievingIdentityVariants"
import failedRetrievingAdGroupConfigs     from "./failedRetrievingAdGroupConfigs"
import failedRetrievingIdentityVariants   from "./failedRetrievingIdentityVariants"
import NegativeKeywords                   from "./NegativeKeywords"
import retrievingNegativeKeywords         from "./retrievingNegativeKeywords"
import savingNegativeKeywords             from './savingNegativeKeywords'
import savingPositiveKeywords             from './savingPositiveKeywords'
import savingIdentityVariants             from './savingIdentityVariants'
import savingStrategies                   from './savingStrategies'
import savingAdCopyPatterns               from './savingAdCopyPatterns'
import failedSavingPositiveKeywords       from './failedSavingPositiveKeywords'
import brandingConfigs                    from './brandingConfigs'
import creatingNewAdGroup                 from './creatingAdGroup'
import editingAudienceAds                 from './editingAudienceAds'
import deletingAdGroup                    from './deletingAdGroup'
import retrievingDefaultAds               from './retrievingDefaultAds'
import DefaultAds                         from './DefaultAds'
import failedCreatingIdentityVariants     from './failedCreatingIdentityVariants'

export const Branding = combineReducers({
  AdGroupConfigs,
  IdentityVariants,
  retrievingAdGroupConfigs,
  retrievingIdentityVariants,
  failedRetrievingAdGroupConfigs,
  failedRetrievingIdentityVariants,
  NegativeKeywords,
  retrievingNegativeKeywords,
  savingNegativeKeywords,
  savingPositiveKeywords,
  savingIdentityVariants,
  savingStrategies,
  savingAdCopyPatterns,
  failedSavingPositiveKeywords,
  brandingConfigs,
  creatingNewAdGroup,
  editingAudienceAds,
  deletingAdGroup,
  retrievingDefaultAds,
  DefaultAds,
  failedCreatingIdentityVariants,
})
