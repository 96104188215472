import * as React                        from "react"
import {withLocalize, Translate}         from "react-localize-redux"
import {Modal}                           from "carbon-components-react"
import {
  Selector,
  SelectorAbstract,
  SelectorOperator,
}                                        from "../../../model/engagementRules/Selector"
import PossibleSelectors                 from '../../../model/engagementRules/PossibleSelectors'
import ArrayUtils                        from '../../../utils/ArrayUtils'
import ClickableSelector                 from './ClickableSelector'
import SelectedSelector                  from "./SelectedSelector"
import CleanerRow                        from "../../../model/CleanerRow"

interface SelectorGroupProps {
  selectors               :  Selector[]
  cleanerMakes           ?:  CleanerRow[]
  addSelector             :  (selector:SelectorAbstract)=>void
  removeSelector          :  (selector:Selector)=>void
  changeSelectorValue     :  (selector:Selector,content:string)=>void
  changeSelectorOperator  :  (selector:Selector,operator:SelectorOperator)=>void
  translate              ?: (translateId:string)=>string
}
interface SelectorGroupState{
  openModal : boolean
}
class SelectorGroupComponent extends React.Component<SelectorGroupProps,SelectorGroupState>{
  constructor(props){
    super(props)
    this.state = {
      openModal : false
    }
    this.closeModal = this.closeModal.bind(this)
    this.renderModal = this.renderModal.bind(this)
  }
  closeModal(){this.setState({openModal:false})}
  renderModal(){
    if(!this.state.openModal){return <></>}
    return (
      <Modal
        onRequestClose={this.closeModal}
        modalHeading={this.props.translate("rules.whichRule")}
        open passiveModal
      >
        <div className='SelectorsClickFromList'>
          {
            PossibleSelectors
            .map((selector,i)=>
              <div key={i} onClick={(event)=>{this.props.addSelector(selector);this.closeModal()}}>
                <ClickableSelector selector={selector}/>
              </div>
            )
          }
        </div>
      </Modal>
    )
  }
  render(){
    return (
      <div className='SelectorGroup'>
        <div className='Workspace'>
          <div key="list" className='SelectedSelectorsList'>
            {this.props.selectors.sort((s,o)=>ArrayUtils.comp(s.id,o.id)).map(selector=>
              <SelectedSelector
                key={selector.id}
                selector={selector}
                onChange={(content:string)=>{this.props.changeSelectorValue(selector,content)}}
                removeSelector={()=>{this.props.removeSelector(selector)}}
                changeOperator={(operator:SelectorOperator)=>this.props.changeSelectorOperator(selector, operator)}
                cleanerMakes={this.props.cleanerMakes}
              />
            )}
          </div>
          <div key="add" className='AddSelector'>
            <button className='btn tx--btn' onClick={()=>this.setState({openModal:true})}>
              <i className='material-icons'>playlist_add</i>
              <Translate id="rules.addRule"/>
            </button>
          </div>
          <div key="or" className='SelectorGroup_OR_Widget'>
            <span>
              <Translate id="rules.OR"/>
            </span>
          </div>
        </div>
        {this.renderModal()}
      </div>
    )
  }
}
export default withLocalize(SelectorGroupComponent)
