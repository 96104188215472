import AccentMap            from "../model/constant/AccentMap"

export default class StringUtils {
  /** Function that count occurrences of a substring in a string;
  * @param {String} string               The string
  * @param {String} subString            The sub string to search for
  * @param {Boolean} [allowOverlapping]  Optional. (Default:false)
  *
  * @author Vitim.us https://gist.github.com/victornpb/7736865
  * @see Unit Test https://jsfiddle.net/Victornpb/5axuh96u/
  * @see http://stackoverflow.com/questions/4009756/how-to-count-string-occurrence-in-string/7924240#7924240
  */
  public static occurrences(string, subString, allowOverlapping=false):number {
    string += ""
    subString += ""
    if (subString.length <= 0){return (string.length + 1)}
    var n = 0,
        pos = 0,
        step = allowOverlapping ? 1 : subString.length
    while (true) {
      pos = string.indexOf(subString, pos)
      if (pos >= 0) {
        ++n
        pos += step
      } else break
    }
    return n
  }
  public static removeOccurences(s:string, remove:string):string{
    while(s.indexOf(remove)>-1){
      s = s.slice(0,s.indexOf(remove))+s.slice(s.indexOf(remove)+1)
    }
    return s
  }
  public static getPath = url => (new URL(url)).pathname

  public static deaccent(s:string):string{
    let deaccented = ""
    const length = s.length
    for(let i=0; i<length; i++){
      deaccented += AccentMap[s[i]] || s[i]
    }
    return deaccented
  }

  public static capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  public static capitalizeOnlyFirstLetter(string: string): string {
    const lowerCaseString = string.toLowerCase()
    return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)
  }

}
