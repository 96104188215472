import * as React                     from "react"
import {connect}                      from "react-redux"
import {retrieveAdGroupConfigs}       from "../../actions/branding/AdGroupConfigs"
import ExplainedLoading               from "../loading"
import ErrorBoundary                  from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  const accountId = state.Accounts.selected
  return {
    accountId,
    adGroupConfigs                 : state.Params.Branding.AdGroupConfigs[accountId],
    retrievingAdGroupConfigs       : state.Params.Branding.retrievingAdGroupConfigs,
    failedRetrievingAdGroupConfigs : state.Params.Branding.failedRetrievingAdGroupConfigs[accountId],
    savingAdCopyPatterns           : state.Params.Branding.savingAdCopyPatterns,
    creatingNewAdGroup             : state.Params.Branding.creatingNewAdGroup,
    deletingAdGroup                : state.Params.Branding.deletingAdGroup,
    editingAudienceAds             : state.Params.Branding.editingAudienceAds,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    retrieveAdGroupConfig : (accountId:number) => {
      dispatch(retrieveAdGroupConfigs(accountId))
    }
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,
    ...DP,
    ...ownProps,
    retrieveAdGroupConfig : ()=>DP.retrieveAdGroupConfig(SP.accountId),
    getDefaultAdSet       : () => DP.getDefaultAdSet(SP.accountId, SP.lang ? SP.lang : "EN")
  }
}
const AdGroupConfigLoader = props => {
  if(props.failedRetrievingAdGroupConfigs && props.failedRetrievingAdGroupConfigs.status ) {
    return <p>{props.failedRetrievingAdGroupConfigs.message}</p>
  }
  if(props.savingAdCopyPatterns && props.config.letThrough===false) {
    return <ExplainedLoading translateId="loadings.saving"/>
  }
  if(props.creatingNewAdGroup && props.config.letThrough === false) {
    return <ExplainedLoading translateId="loadings.saving"/>
  }
  if(props.deletingAdGroup && props.config.letThrough === false) {
    return <ExplainedLoading translateId="loadings.saving"/>
  }
  if(props.editingAudienceAds && props.config.letThrough === false) {
    return <ExplainedLoading translateId="loadings.saving"/>
  }
  if(props.adGroupConfigs===undefined && props.retrievingAdGroupConfigs === false){
    props.retrieveAdGroupConfig()
    if(props.config.letThrough===false){
      return <ExplainedLoading translateId="loadings.adGroupConfig"/>
    }
  }
  if(props.retrievingAdGroupConfigs && props.config.letThrough===false){
    return <ExplainedLoading translateId="loadings.adGroupConfig"/>
  }
  return <ErrorBoundary>{props.render(props.adGroupConfigs, props.retrievingAdGroupConfigs, props.savingAdCopyPatterns)}</ErrorBoundary>
}
const AdGroupConfigLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(AdGroupConfigLoader)

interface AdGroupConfigLoaderConfig {
  letThrough : boolean
}
interface AdGroupConfigGivenConfig {
  letThrough ?: boolean
}
const baseConfig : AdGroupConfigLoaderConfig = {
  letThrough : false
}

const requiresAdGroupConfigs = (givenConfig:AdGroupConfigGivenConfig={}) => Component => props => {
  const config : AdGroupConfigLoaderConfig = {...baseConfig, ...givenConfig}
  return <AdGroupConfigLoaderConnected
    config = {config}
    render = { (adGroupConfigs, loading,saving) => <Component adGroupConfigsByLang={adGroupConfigs} adGroupConfigsLoading={loading} adGroupConfigsSaving={saving} {...props} /> }
  />
}
export default requiresAdGroupConfigs
