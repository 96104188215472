import {combineReducers}           from 'redux'

import Account                     from "./Account"
import AccountModifiers            from "./AccountModifiers"
import TryingPush                  from "./TryingPush"
import MoveProxySimulation         from "./MoveProxySimulation"
import TryingMoveProxySimulation   from "./TryingMoveProxySimulation"
import FailedMoveProxySimulation   from "./FailedMoveProxySimulation"
import FailedPush                  from "./FailedPush"
import Saving                      from "./Saving"

const PacyConfigs = combineReducers({
  Account,
  AccountModifiers,
  TryingPush,
  MoveProxySimulation,
  TryingMoveProxySimulation,
  FailedMoveProxySimulation,
  FailedPush,
  Saving
})

export default PacyConfigs
