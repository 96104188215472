import Actions      from "../../../../model/constant/actions"
import ReducerUtils from "../../../../utils/ReducerUtils"

const addShapes = (state, action) => {
  return [
    ...state,
    ...action.data
  ]
}
const removeShape = (state, action) => {
  return state.filter(x=>x.id != action.shapeId)
}
interface Shape{
  id       : string
  name     : string
  code     : number
  polygons : number[][][]
}
const ShapesPendingSimplification = ReducerUtils.createReducer<Shape[]>([],{
  [Actions.RETRIEVE_SHAPES_PENDING_SIMPLIFICATION] : addShapes,
  [Actions.REMOVE_SHAPE_PENDING_SIMPLIFICATION]    : removeShape,
  [Actions.FLUSH_SHAPES_PENDING_SIMPLIFICATION]    : ()=>[]
})
export default ShapesPendingSimplification
