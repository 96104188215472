import "./index.css"
import * as React                        from "react"
import {connect}                         from "react-redux"
import {migrate, migrateAgain, enable}   from "../../../actions/pacy/Migration"
import ReduxActions                      from "../../../model/constant/actions"
import Dialog                            from "@material-ui/core/Dialog"
import DialogTitle                       from "@material-ui/core/DialogTitle"
import DialogContent                     from "@material-ui/core/DialogContent"
import DialogContentText                 from "@material-ui/core/DialogContentText"
import DialogActions                     from "@material-ui/core/DialogActions"
import Content                           from "./ModalContent"
import Actions                           from "./ModalActions"

interface MigrationModalOwnProps{
  show : boolean
  onClose ?: ()=>void
}
interface MigrationModalProps extends MigrationModalOwnProps{
  migrating       : boolean
  error           : string
  done            : boolean
  alreadyMigrated : boolean
  migrate         : ()=>void
  migrateAgain    : ()=>void
  reloadPacy      : ()=>void
}

const mapStateToProps = (state,ownProps)=>{
  const accountId = state.Accounts.selected
  return {
    accountId,
    migrating       : state.Pacy.Migration.Running[accountId],
    error           : state.Pacy.Migration.Error[accountId],
    done            : state.Pacy.Migration.Done[accountId],
    alreadyMigrated : state.Pacy.Migration.AlreadyMigrated[accountId],
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    migrate      : (accountId:number)=>dispatch(migrate(accountId)),
    migrateAgain : (accountId:number)=>dispatch(migrateAgain(accountId)),
    reloadPacy   : (accountId:number)=>dispatch({type: ReduxActions.PACY_CLEAR, accountId})
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    migrate      : ()=>DP.migrate(SP.accountId),
    migrateAgain : ()=>DP.migrateAgain(SP.accountId),
    reloadPacy   : ()=>DP.reloadPacy(SP.accountId)
  }
}

const MigrationModal = (props:MigrationModalProps) => {
  const [open, setOpen] = React.useState(props.show)
  React.useEffect(()=>{
    if(props.show !== open){setOpen(props.show)}
  },[props.show])
  const close = ()=>{
    if(!props.migrating){
      if(props.done){props.reloadPacy()}
      if(props.onClose){props.onClose()}
      else{setOpen(false)}
    }
  }
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      //maxWidth={}
    >
      <DialogTitle id="alert-dialog-title">
        Budget planner migration
      </DialogTitle>
      <DialogContent>
        <Content
          migrating={props.migrating}
          error={props.error}
          done={props.done}
          alreadyMigrated={props.alreadyMigrated}
        />
      </DialogContent>
      <DialogActions>
        <Actions
          migrating={props.migrating}
          alreadyMigrated={props.alreadyMigrated}
          done={props.done}
          close={close}
          error={props.error}
          migrate={props.migrate}
          migrateAgain={props.migrateAgain}
          finish={props.reloadPacy}
        />
      </DialogActions>
    </Dialog>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(MigrationModal)
