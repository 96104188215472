import './KPI.css'
import * as React from 'react'


interface KPIProps {
  subtitleFirst ?: boolean
  amount   : any
  units    : any
  subtitle : any
  title    : any
}

const KPI:React.SFC<KPIProps> = (props:KPIProps) => (
  <div className='KPI'>
    <div key='number'   className='number'>{props.amount +" "+props.units}</div>
    <div key='text'     className='text'>
      {
        props.subtitleFirst
        ? <div key='subtitle' className='subtitle'>{props.subtitle}</div>
        : <div key="title"    className='title' >{props.title}</div>
      }{
        props.subtitleFirst
        ? <div key="title"    className='title' >{props.title}</div>
        : <div key='subtitle' className='subtitle'>{props.subtitle}</div>
      }
    </div>
  </div>
)


KPI.defaultProps = {
  subtitleFirst : false
}
export default KPI
