import Actions      from "../../model/constant/actions"
import ReducerUtils from "../../utils/ReducerUtils"
import Vehicle      from "../../model/Store/Vehicle/Vehicle"

interface state{
  [accountId:number] : Vehicle[]
}

const retrieveAllLangInventoryForAccount = (state,action) =>{
  return {...state,[action.accountId]:action.inventory}
}

export const AllLangInventoryList = ReducerUtils.createReducer<state>({},{
  [Actions.RETRIEVE_ALL_LANG_INVENTORY_FOR_ACCOUNT] : retrieveAllLangInventoryForAccount
})
