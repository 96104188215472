import * as React       from 'react'
import {
  withLocalize,
  TranslateFunction,
  Translate
}                       from 'react-localize-redux'
import {
  TableRow,
  TableCell,
  Icon
}                       from '@material-ui/core'
import DialogMui        from '../../Modals/DialogMui'

interface MUITableRowOwnProps {
  name           : string
  id             : number
  errors         : number[]
  editFunction   : (id:number) => void
  deleteFunction : (id:number) => void
}
interface MUITableRowProps extends MUITableRowOwnProps {
  translate : TranslateFunction
}

const MUITableRow = (props:MUITableRowProps) => {
  const [openDialog,setOpenDialog] = React.useState(false)
  function handleClickOpenDialog(e) {
    e.stopPropagation()
    setOpenDialog(true)
  }
  function handleClickCloseDialog() {
    setOpenDialog(false)
  }
  function deleteAudienceAd() {
    props.deleteFunction(props.id)
    setOpenDialog(false)
  }
  return (
    <>
      <TableRow>
        <TableCell>{props.name}</TableCell>
        <TableCell onClick={()=>{props.editFunction(props.id)}} className="Edit"><span ><Icon className="Actions">edit</Icon></span></TableCell>
        <TableCell className="Delete" onClick={handleClickOpenDialog}><span ><Icon className="Actions">delete_forever</Icon></span></TableCell>
        <TableCell>{props.errors.includes(props.id) && <p className="Error"><Icon>error</Icon><Translate id="branding.creation.missingLanguage" /></p>}</TableCell>
      </TableRow>
      <DialogMui
        title={props.translate('common.delete') as string}
        cancelText={props.translate('common.cancel') as string}
        confirmText={props.translate('common.yes') as string}
        content={props.translate('dialog.deleteAudienceAd') as string}
        open={openDialog}
        handleSave={deleteAudienceAd}
        handleClose={handleClickCloseDialog}
      />
    </>
  )
}

export default withLocalize(MUITableRow as any) as React.ComponentType<MUITableRowOwnProps>
