import * as React                         from "react"
import {connect}                          from "react-redux"
import {HashRouter}                       from "react-router-dom"
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles"
import PreferencesAccessors               from "./storeAccessor/Preferences"
import {retrieveAccountPreferences}       from "./actions/account/accountPreferences"
import MainRouting                        from "./routing/mainRouting"
import requiresCampaignsBaseInfo          from "./components/loaders/campaignsBaseInfoLoader"
import requiresBingCampaignsBaseInfo      from "./components/loaders/bingCampaignsBaseInfoLoader"
import DealerBreacher                     from "./Themes/DealerBreacher"
import TRFFK                              from "./Themes/TRFFK"
import Convertus                          from "./Themes/Convertus"
import keydown                            from 'react-keydown'
import Actions                            from './model/constant/actions'
import NavigationBlockerDialog            from "./components/Modals/NavigationBlockerDialog"

export const THEMES = {
  DealerBreacher,
  TRFFK,
  Convertus
}
const THEMES_KEYBIND = Object.keys(THEMES)

interface ApplicationProps{
  accountId                    : number
  preferences                  : any
  isFetchingAccountPreferences : boolean
  whiteLabel                   : string
  fetchPreferences             : ()=>void
  changeWhiteLabel             : (whiteLabel:string) => void
}
const makeMapStateToProps = () => {
  const whiteLabelAccessor = PreferencesAccessors.makeActiveWhiteLabelSelector()
  return (state,ownProps)=>{
    const accountId = state.Accounts.selected
    return {
      accountId : accountId,
      isFetchingAccountPreferences : (
        state.Accounts.isFetchingPreferences[accountId]
        ? state.Accounts.isFetchingPreferences[accountId]
        : false
      ),
      preferences : state.Accounts.preferences[accountId],
      whiteLabel : whiteLabelAccessor(state)
    }
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    fetchPreferences : (accountId:number)=>dispatch(retrieveAccountPreferences(accountId)),
    changeWhiteLabel : (whiteLabel:string)=>dispatch({type:Actions.DEV_CHANGE_WHITELABEL_DEV,whiteLabel})
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    fetchPreferences : ()=>DP.fetchPreferences(SP.accountId)
  }
}

const createGA = () => {
  const ga = (window as any).ga
  if(ga){ga("create", "UA-107037410-1", "auto")}
  else{setTimeout(createGA, 1500)}
}

class Application extends React.Component<ApplicationProps,any>{
  @keydown(["alt+w"])
  changeWhiteLabel(){
    if((process.env.REACT_APP_ENABLE_SHORTCUTS||"") === "1"){
      if(!THEMES_KEYBIND.includes(this.props.whiteLabel)) {
        this.props.changeWhiteLabel(THEMES_KEYBIND[1])
      }
      else if(THEMES_KEYBIND.indexOf(this.props.whiteLabel) === THEMES_KEYBIND.length-1) {
        this.props.changeWhiteLabel(THEMES_KEYBIND[0])
      }
      else {
        this.props.changeWhiteLabel(THEMES_KEYBIND[THEMES_KEYBIND.indexOf(this.props.whiteLabel)+1])
      }
    }
  }
  componentDidMount(){
    createGA()
    if(this.props.accountId && this.props.preferences === undefined && !this.props.isFetchingAccountPreferences){
      this.props.fetchPreferences()
    }
  }
  componentDidUpdate(){
    if(this.props.accountId && this.props.preferences === undefined && !this.props.isFetchingAccountPreferences){
      this.props.fetchPreferences()
    }
  }
  render(){
    const activeTheme = THEMES[this.props.whiteLabel]
    return (
      <div className={this.props.whiteLabel}>
        <MuiThemeProvider theme={ activeTheme || DealerBreacher}>
          <HashRouter>
            <MainRouting/>
          </HashRouter>
          <NavigationBlockerDialog />
        </MuiThemeProvider>
      </div>
    )
  }
}
export default (
  requiresBingCampaignsBaseInfo(true)(
    requiresCampaignsBaseInfo(true)(
      connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(Application)
    )
  )
)
