import './ProfileList.css'
import * as React           from "react"
import {Button}             from "carbon-components-react"

interface ProfileListProps{
  permissionProfiles : any[]
  action             : (profileCode:string)=>void
  actionText         : string
}

const ProfileList = (props:ProfileListProps) => {
  if(props.permissionProfiles.length === 0){return <div>No profiles</div>}
  return (
    <div className="ProfileList">
      {props.permissionProfiles.map(x=>
        <div className="ProfileRow" key={x.code}>
          <div>{x.name+(x.accountId===null?" (ALL)":"")} </div>
          <Button
            className="ActionButton"
            onClick={()=>props.action(x.code)}
            disabled={x.accountId===null}
          >
            {props.actionText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ProfileList
