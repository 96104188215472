import ReportTableTemplate  from "../ReportTableTemplate"
import ColumnDefinition     from "../ColumnDefinition"
import ObjectUtils          from "../../../../utils/ObjectUtils"
import StatisticsUtils      from "../../../../utils/StatisticsUtils"
import {getLangs}           from "../../../../utils/localisation/Localisation"
import {FORMATTER_NAMES}    from "../../../constant/DataTableFormatters"
import Contexts             from "../../../constant/ReportTableTemplateContexts"
import {Comparator}         from 'react-bootstrap-table2-filter'
import {Provider}           from "../../../Provider"

export default class CampaignReportTableTemplate extends ReportTableTemplate{

  private GROUP_FILTER_OPTIONS = ["VDP","SRP_MM","SRP_MMY","CONQUEST","BRANDING","SERVICE","CREDIT"]
  private STATE_FILTER_OPTIONS = ["New", "Used"]
  private TYPE_FILTER_OPTIONS  = ["Car"]
  private NAME_AGG_EXTRACTOR = (value)=>{return value?value.replace((new RegExp(`(\\s\\||\\|)|(\\s(` +getLangs().join('|')+ `))`, "g")),""):value}

  protected context = Contexts.CAMPAIGN
  protected KEY_COLUMN = "GenericId"
  protected DEFAULT_COLUMNS = [
    //                    text                datafield        order iskey  hidden sort   formatter  filter  filteroptions  aggKey
    new ColumnDefinition("Generic Id",       "GenericId",       0,   true,  true,  true,  undefined, undefined, undefined, "GenericId"),
    new ColumnDefinition("Name",             "Name",            1,   false, false, true,  FORMATTER_NAMES.NAME, "text", undefined,    "Name", this.NAME_AGG_EXTRACTOR),
    new ColumnDefinition("Clicks",           "Clicks",          3,   false, false, true,  FORMATTER_NAMES.ROUND2),
    new ColumnDefinition("Conversions",      "Conversions",     4,   false, false, true,  FORMATTER_NAMES.ROUND2),
    new ColumnDefinition("Cost",             "Cost",            5,   false, false, true,  FORMATTER_NAMES.MONEY),
    new ColumnDefinition("Impressions",      "Impressions",     6,   false, false, true,  FORMATTER_NAMES.ROUND2),
    new ColumnDefinition("Impression Share", "ImpressionShare", 7,   false, false, true,  FORMATTER_NAMES.IMPSHARE),
    new ColumnDefinition("Click Share",      "ClickShare",      8,   false, false, true,  FORMATTER_NAMES.CLICKSHARE),
    new ColumnDefinition("Group",            "Group",           9,   false, false, false, FORMATTER_NAMES.NAME, "select", {options:ObjectUtils.getMirrorObjectFromArray(this.GROUP_FILTER_OPTIONS)}, "Group"),
    new ColumnDefinition("State",            "VehicleState",    10,  false, false, false, FORMATTER_NAMES.UPPERCAMEL, "select", {options:ObjectUtils.getMirrorObjectFromArray(this.STATE_FILTER_OPTIONS), comparator:Comparator.LIKE, caseSensitive:false}, "VehicleState"),
    new ColumnDefinition("Type",             "VehicleType",     11,  false, false, false, FORMATTER_NAMES.UPPERCAMEL, "select", {options:ObjectUtils.getMirrorObjectFromArray(this.TYPE_FILTER_OPTIONS), comparator:Comparator.LIKE, caseSensitive:false}, "VehicleType"),
    new ColumnDefinition("CPC",              "CPC",             12,  false, false, true,  FORMATTER_NAMES.CPC),
    new ColumnDefinition("CTR",              "CTR",             13,  false, false, true,  FORMATTER_NAMES.PERCENTAGE),
  ]
  constructor(id:string, name:string, options:any={defaultSorted:[{dataField:"Name",order:"asc"}]}){
    super(id, name, options)
    this.setProvider(Provider.AW)
    this.columns = this.DEFAULT_COLUMNS
  }
  clone(){
    let clone = new CampaignReportTableTemplate(
      this.id,
      this.name,
      this.options
    )
    clone.setColumns(this.columns.map(c=>c.clone()))
    clone.setSizePerPage(this.sizePerPage)
    clone.setRevisionOf(this.revisionOf)
    clone.setProvider(this.provider)
    return clone
  }

  protected compile(stats : any[]):any{
    const x = stats.reduce((a,c)=>{
      if(!Object.keys(a).length){return {...c}}
      return {
        ...c,
        ...a,
        Cost            : a.Cost        + c.Cost,
        Clicks          : a.Clicks      + c.Clicks,
        Impressions     : a.Impressions + c.Impressions,
        Conversions     : a.Conversions + c.Conversions,
        ImpressionShare : [...a.ImpressionShare ||[], [c.Impressions,c.SearchImpressionShare||0]],
        ClickShare      : [...a.ClickShare      ||[], [c.Clicks,c.SearchClickShare||0]],
    }},{})
    const y = {
      CPC             : StatisticsUtils.calculateCPC(x.Cost, x.Clicks),
      CTR             : StatisticsUtils.calculateCTR(x.Clicks, x.Impressions),
      ImpressionShare : StatisticsUtils.calculateImpressionShare(x.ImpressionShare),
      ClickShare      : StatisticsUtils.calculateClickShare(x.ClickShare),
    }
    return {...x,...y}
  }
}
