import Keystone       from "../utils/Keystone"
import Actions        from "../model/constant/actions"
import {getToken}     from "../storeAccessor/Generic"

const retrieveBiddingStrategiesSuccess = (response, accountId) => {
  if(response.error.length > 0){
    return {
      type              : Actions.RETRIEVE_BIDDING_STRATEGIES,
      accountId         : accountId,
      biddingStrategies : [],
    }
  }
  return {
    type              : Actions.RETRIEVE_BIDDING_STRATEGIES,
    accountId         : accountId,
    biddingStrategies : response.data.biddingStrategies
  }
}
const retrieveBiddingStrategies = (accountId:number) => (dispatch, getState) => {
  dispatch({
    type      : Actions.RETRIEVING_BIDDING_STRATEGIES,
    accountid : accountId
  })
  Keystone.get({
      token     : getToken(getState()),
      action    : "GetRawAdWordsBiddingStrategies",
      accountId : accountId
    },
    (response) => dispatch(retrieveBiddingStrategiesSuccess(response, accountId)),
    (error) => console.log(error)
  )
}

export default retrieveBiddingStrategies
