import './StatusUpdate.css'
import * as React from 'react'

const StatusUpdate = (props:{text:string})=>{
  if(props.text===undefined || props.text.trim().length===0){
    return null
  }
  return <div className='StatusUpdate'><div>STATUS :</div><pre>{props.text}</pre></div>
}

export default StatusUpdate
