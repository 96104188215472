import * as React             from 'react'
import BootstrapTable         from 'react-bootstrap-table-next'
import filterFactory          from 'react-bootstrap-table2-filter'
import {textFilter}           from 'react-bootstrap-table2-filter'
import {selectFilter}         from 'react-bootstrap-table2-filter'
import paginationFactory      from 'react-bootstrap-table2-paginator'
import ArrayUtils             from '../../utils/ArrayUtils'
import ObjectUtils            from '../../utils/ObjectUtils'

interface GrammarBoosterTableProps {
  replacements : any[]
  onSelectReplacement : (id:number)=>void
}

const GrammarBoosterTable = (props:GrammarBoosterTableProps)=>{
  const langsFilter = {
    options : ObjectUtils.getMirrorObjectFromArray(
      ArrayUtils.unique(props.replacements.map(r=>r.lang))
    )
  }
  const isRegexFilter = {
    options : ObjectUtils.getMirrorObjectFromArray(
      ArrayUtils.unique(props.replacements.map(r=>String(r.isARegex)))
    )
  }
  const isActiveFilter = {
    options : ObjectUtils.getMirrorObjectFromArray(
      ArrayUtils.unique(props.replacements.map(r=>String(r.active)))
    ),
    defaultValue:"true",
  }
  const modifiedByFilter = {
    options : ObjectUtils.getMirrorObjectFromArray(
      ArrayUtils.unique(props.replacements.map(r=>r.modifiedBy))
    )
  }
  return (
    <BootstrapTable
      hover
      data={props.replacements}
      keyField='id'
      columns={[
        { dataField:'id',   text:"ID",   sort:true, filter:textFilter({delay:100})},
        { dataField:'lang', text:"Lang", sort:true, filter:selectFilter(langsFilter)},
        {
          dataField:'wrong',
          text:"Wrong",
          sort:true,
          filter:textFilter({delay:100}),
          formatter : e=>e.replace(/\s/g,"_")
        },
        {
          dataField:'correct',
          text:"Correct",
          sort:true,
          filter:textFilter({delay:100}),
          formatter : e=>e.replace(/\s/g,"_")
        },
        { dataField:'isARegex',   text:"IsRegex",    sort:true, filter:selectFilter(isRegexFilter)},
        { dataField:'active',     text:"Active",     sort:true, filter:selectFilter(isActiveFilter)},
        { dataField:'modifiedBy', text:"ModifiedBy", sort:true, filter:selectFilter(modifiedByFilter)},
        {
          dataField : 'modifiedOn',
          text      : "ModifiedOn",
          sort      : true,
          filter    : textFilter({delay:100}),
          formatter : e=>e.date.substring(0,19)
        },
      ]}
      defaultSorted={[{dataField:'id',order:'asc'}]}
      filter={filterFactory()}
      pagination={paginationFactory({hideSizePerPage: true})}
      classes={"normalSizedDatatable GrammarBoostDataTable"}
      rowEvents={{
        onClick : (e,row,rowIndex)=> props.onSelectReplacement(row.id)
      }}
      rowStyle={{cursor:"pointer"}}
    />
  )
}
export default GrammarBoosterTable
