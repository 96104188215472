import Actions               from "../../../model/constant/actions"
import ReducerUtils          from "../../../utils/ReducerUtils"
import ObjectUtils           from "../../../utils/ObjectUtils"
import {KeywordPatternInfos} from "../../../page/keyword/model/KeywordPatternInfos"

const serializeMatcher = (matcher) : string => {
  if(matcher.vehicleType){
    return [
      matcher.group,
      matcher.lang,
      matcher.vehicleType,
      matcher.state,
    ].join("|")
  }
  return [
    matcher.group,
    matcher.lang,
  ].join("|")
}
const createStructure = (accountId, matcher) => {
  return {
    accountId : accountId,
    matcher   : matcher,
    patterns  : []
  }
}
const storeKeywordPatterns = (state,action) => {
  const accountId = action.accountId
  let builtStructure = {}
  for(let patternAndMatcher of action.keywordPatterns){
    const key = serializeMatcher(patternAndMatcher.matcher)
    if(!builtStructure[key]){
      builtStructure[key] = createStructure(accountId, patternAndMatcher.matcher)
    }
    builtStructure[key].patterns.push({
      text : patternAndMatcher.pattern,
      positive : patternAndMatcher.positive
    })
  }
  return {
    ...state,
    [accountId] : ObjectUtils.getObjectValues(builtStructure)
  }
}
interface KeywordPatternsByAccount{
  [accountId:number] : KeywordPatternInfos[]
}
export const Patterns = ReducerUtils.createReducer<KeywordPatternsByAccount>({},{
  [Actions.RETRIEVE_KEYWORD_PATTERNS_FOR_ACCOUNTID] : storeKeywordPatterns,
})
