import './ColumnsContainer.css'
import * as React                          from "react"
import {DropTarget}                        from "react-dnd"
import ItemTypes                           from "../../../../model/constant/ItemTypes"
import DragColumn                          from "../../../../model/report/DragColumn"
import Column                              from "./Column"

interface ColumnsContainerProps{
  columns                : DragColumn[]
  isDragHappening        : boolean
  addColumn             ?: (dataField, hoverIndex)=>void
  removeColumn          ?: (dragIndex)=>void
  originalDefaultFilters : {[dataField:string]:any}
  editedDefaultFilters   : {[dataField:string]:any}
  setDefaultFilter       : (dataField:string, value:string)=>void
  connectDropTarget      : any
}
const containerTarget = {
  drop(props, monitor, component){
    const dragDataField = monitor.getItem().dataField
    if(!monitor.didDrop()){
      props.addColumn(dragDataField, props.columns.length)
    }
  }
}
function collectDrop(connect, monitor){
  return {
    connectDropTarget : connect.dropTarget(),
  }
}
class ColumnsContainer extends React.Component<ColumnsContainerProps,any>{
  render(){
    return this.props.connectDropTarget(
      <div className={"ColumnsContainer"+(this.props.isDragHappening?" DragHappening":"")}>
        {this.props.columns.map((column,i)=>{
          let columnProps : any = {
            ...column,
            key : i,
            index : i,
            addColumn    : this.props.addColumn?this.props.addColumn:()=>{},
            removeColumn : this.props.removeColumn?this.props.removeColumn:()=>{},
          }
          if(this.props.originalDefaultFilters[column.dataField] !== undefined){
            columnProps = {
              ...columnProps,
              originalFilter : this.props.originalDefaultFilters[column.dataField],
              editedFilter : this.props.editedDefaultFilters[column.dataField],
              setFilter : this.props.setDefaultFilter
            }
          }
          return(
            <Column {...columnProps}/>
        )})}
      </div>
    )
  }
}
export default DropTarget(ItemTypes.COLUMN, containerTarget, collectDrop)(ColumnsContainer)
