import {
  MarketingEventBoundariesTypes as MEBTypes,
  ProtoMarketingEventBoundaries,
}                          from "../../../model/adbuilder/"
import { ReferenceRow }    from "../../../model/Store/Cleaner/"
import Vehicle             from "../../../model/Store/Vehicle/Vehicle"
import InventoryFriend     from "../../../model/Store/Campaign/InventoryFriend"

type VehicleFilterByMarketingEventBoundariesTypes = {
  [MEBType in MEBTypes] ?: (vehicle:Vehicle)=>boolean
}

const buildStruct = (
  year         : number,
  SRPPoolIds   : Set<number>,
  vehicleState : string,
  campaignRef  : InventoryFriend|null
) : VehicleFilterByMarketingEventBoundariesTypes => {
  return {
    [MEBTypes.SPECIFIC_MY]       : v=>v.getPassedRules() && SRPPoolIds.has(v.getSRPPoolId()) && v.getYear()+"" === year+"",
    [MEBTypes.SPECIFIC_MMY]      : v=>v.getPassedRules() && SRPPoolIds.has(v.getSRPPoolId()) && v.getYear()+"" === year+"",
    [MEBTypes.SPECIFIC_MM]       : v=>v.getPassedRules() && SRPPoolIds.has(v.getSRPPoolId()),
    [MEBTypes.SPECIFIC_M]        : v=>v.getPassedRules() && SRPPoolIds.has(v.getSRPPoolId()),
    [MEBTypes.SPECIFIC_Y]        : v=>v.getPassedRules() && v.getYear()+"" === year+"",
    [MEBTypes.SPECIFIC_S]        : v=>v.getPassedRules() && (v.getState()+"").toLowerCase().replace("state_","") === vehicleState,
    [MEBTypes.SPECIFIC_CAMPAIGN] : v=>{
      if(!campaignRef){return false}
      if(!v.getPassedRules()){return false}
      for(const id of campaignRef.getAllVehicleIds()){
        if(v.getId()+"" === id+""){
          return true
        }
      }
      return false
    },
  }
}

const isBoundedIn = (
  boundaries        : ProtoMarketingEventBoundaries,
  cleanerReference  : ReferenceRow[],
  campaigns         : InventoryFriend[]
) => {
  const campaignRef = (
    boundaries.type === MEBTypes.SPECIFIC_CAMPAIGN && boundaries.campaignName
    ? campaigns.find(x=>x.getCampaigns().find(c=>c.name === boundaries.campaignName))
    : undefined
  )
  const vehicleState = (boundaries.vehicleState+"").toLowerCase().replace("state_","")
  const SRPPoolIds : Set<number> = new Set<number>()
  if(
    boundaries.type === MEBTypes.SPECIFIC_MMY      ||
    boundaries.type === MEBTypes.SPECIFIC_MM       ||
    boundaries.type === MEBTypes.SPECIFIC_MY       ||
    boundaries.type === MEBTypes.SPECIFIC_M
  ){
    if(boundaries.cleanerId){
      for(const row of cleanerReference){
        if(row.ID+"" === boundaries.cleanerId+""){
          SRPPoolIds.add(parseInt(row.SRPable+"",10))
          //console.log("adding row [0]", {ID:row.ID, SRPable:row.SRPable, make:row.DisplayMake, model:row.DisplayModel})
          break
        }
      }
    }
    if(
      !boundaries.cleanerId                    ||
      boundaries.type === MEBTypes.SPECIFIC_MY ||
      boundaries.type === MEBTypes.SPECIFIC_M
    ){
      const cleanUpMakeModel = (mOrM:string) : string => {
        return mOrM.toLowerCase().replace(/[^0-9a-z]/g,"")
      }
      const makeRow      = cleanerReference.find(x=>x.ID+"" === boundaries.make+"")
      const modelRow     = cleanerReference.find(x=>x.ID+"" === boundaries.model+"")
      const cleanedMake  = makeRow  ? cleanUpMakeModel(makeRow.DisplayMake  ) : ""
      const cleanedModel = modelRow ? cleanUpMakeModel(modelRow.DisplayModel) : ""
      for(const row of cleanerReference){
        const cleanedRowMake  = cleanUpMakeModel(row.DisplayMake+"")
        const cleanedRowModel = cleanUpMakeModel(row.DisplayModel+"")
        if(cleanedMake && cleanedRowMake===cleanedMake){
          if(
            boundaries.type === MEBTypes.SPECIFIC_MY ||
            boundaries.type === MEBTypes.SPECIFIC_M
          ){
            SRPPoolIds.add(parseInt(row.SRPable+"",10))
            //console.log("adding row [1]", {ID:row.ID, SRPable:row.SRPable, make:row.DisplayMake, model:row.DisplayModel})
            continue
          }
        }
        if(cleanedModel && cleanedRowModel===cleanedModel){
          if(!cleanedMake){
            SRPPoolIds.add(parseInt(row.SRPable+"",10))
            //console.log("adding row [2]", {ID:row.ID, SRPable:row.SRPable, make:row.DisplayMake, model:row.DisplayModel})
            continue
          }
          if(cleanedMake && cleanedRowMake===cleanedMake){
            SRPPoolIds.add(parseInt(row.SRPable+"",10))
            //console.log("adding row [3]", {ID:row.ID, SRPable:row.SRPable, make:row.DisplayMake, model:row.DisplayModel})
            continue
          }
        }
      }
    }
  }
  else if(
    boundaries.type === MEBTypes.SPECIFIC_S ||
    boundaries.type === MEBTypes.SPECIFIC_Y
  ){
    for(const row of cleanerReference){
      SRPPoolIds.add(parseInt(row.SRPable+"",10))
    }
  }
  else if(boundaries.type === MEBTypes.SPECIFIC_CAMPAIGN){
    if(campaignRef){
      const vehicleIds = new Set<number>(campaignRef.getAllVehicleIds())
      for(const v of campaignRef.getInventory()){
        if(vehicleIds.has(v.id)){
          SRPPoolIds.add(parseInt(v.SRPPoolId+"",10))
        }
      }
    }
    else{
      for(const row of cleanerReference){
        SRPPoolIds.add(parseInt(row.SRPable+"",10))
      }
    }
  }
  else if (boundaries.type !== MEBTypes.NON_INVENTORY){
    const assertUnreachable = (x:never) : never => {
      throw new Error("INVALID MARKETING EVENT BOUNDARIES TYPE : "+JSON.stringify(x))
    }
    assertUnreachable(boundaries.type)
  }
  const isVehicleBoundedIn = buildStruct(
    boundaries.year || 0,
    SRPPoolIds,
    vehicleState    || "",
    campaignRef     || null
  )[boundaries.type]
  return {SRPPoolIds, isVehicleBoundedIn}
}

export default isBoundedIn
