import "./Increment.css"
import * as React     from "react"
import Icon           from "@material-ui/core/Icon"
import IconButton     from "@material-ui/core/IconButton"
import NumFormatter   from "../../utils/NumberFormatter"


interface IncrementProps{
  initial  ?: number
  min      ?: number
  max      ?: number
  onChange ?: (number:number)=>void
  small    ?: boolean
}
interface IncrementState{
  number : number
}

class Increment extends React.Component<IncrementProps,IncrementState>{
  constructor(props:IncrementProps){
    super(props)
    this.state = {
      number : props.initial || props.min || 0
    }
    this.increment = this.increment.bind(this)
    this.decrement = this.decrement.bind(this)
  }
  componentDidUpdate(prevProps:IncrementProps, prevState:IncrementState){
    if(prevState.number !== this.state.number){
      this.props.onChange(this.state.number)
    }
  }
  increment(){
    if(this.props.max === undefined || this.state.number < this.props.max){
      this.setState(prevState=>({number: prevState.number+1}))
    }
  }
  decrement(){
    if(this.props.min === undefined || this.state.number > this.props.min){
      this.setState(prevState=>({number: prevState.number-1}))
    }
  }
  render(){
    return (
      <div className="Increment">
        <IconButton onClick={this.decrement} disabled={this.props.min!==undefined && this.state.number===this.props.min}>
          <Icon fontSize={this.props.small?"small":"large"}>remove</Icon>
        </IconButton>
        <div className="Number">{NumFormatter.formatNumber(this.state.number)}</div>
        <IconButton onClick={this.increment} disabled={this.props.max!==undefined && this.state.number===this.props.max}>
          <Icon fontSize={this.props.small?"small":"large"}>add</Icon>
        </IconButton>
      </div>
    )
  }
}

export default Increment
