import * as React        from "react"
import {combineReducers} from 'redux'
import Retrieving        from "./Retrieving"
import Failed            from "./Failed"
import ActiveStrategies  from "./ActiveStrategies"

const Strategies = combineReducers({
  Retrieving,
  Failed,
  ActiveStrategies
})

export default Strategies
