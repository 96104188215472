import Pacy                                              from "../../utils/Pacy"
import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import {getToken}                                        from "../../storeAccessor/Generic"

const extractMessageFromError = (error:any) => {
  if(error.response && error.response.data){
    return error.response.data.error
  }
}

export const retrievePossibleAgents = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_PACY_POSSIBLE_AGENTS, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Pacy.getPossibleAgents(token, accountId)
  )
  .then(response=>{
    dispatch({
      type           : Actions.RETRIEVE_PACY_POSSIBLE_AGENTS,
      possibleAgents : response.data,
      accountId
    })
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_RETRIEVING_PACY_POSSIBLE_AGENTS,
      status : true,
      message : extractMessageFromError(error)
    })
  })
  .then(()=>{
    dispatch({type: Actions.RETRIEVING_PACY_POSSIBLE_AGENTS, status: false})
  })
}
