import * as React                    from "react"
import {connect}                     from "react-redux"
import {withRouter}                  from "react-router"
import {renderToStaticMarkup}        from "react-dom/server"
import keydown                       from 'react-keydown'
import {withLocalize}                from "react-localize-redux"
import ArrayUtils                    from "../utils/ArrayUtils"
import logUserAction                 from "../actions/logger/Logger"
import Actions                       from "../model/constant/actions"
import {HashRouter, Route, Redirect} from "react-router-dom"
import {RouteProps, Switch}          from "react-router-dom"
import {Menu}                        from "../components/Menu/MainMenu/Menu"
import {routing as AccountRouting}   from "./accounts"
import ForgotPassword                from "../page/forgotPassword"
import Login                         from "../page/login"
import Profile                       from "../page/profile"
import SystemRouting                 from "./systemRoutes"
import Management                    from "../page/management"
import ShapesValidation              from "../page/shapesValidation"
import UserAction                    from "../model/constant/UserAction"
import translations                  from "../translation/translations"

interface RouterPathMapping{
  path        : string
  setMenu     : boolean
  component   : any // Component instance
}
const defaultMapping : RouterPathMapping[] = [
  {path:"/Profile",         setMenu:true,  component: ()=><Profile/>  },
  {path:"/Management",      setMenu:true,  component: ()=><Management/>},
  {path:"/System",          setMenu:true,  component: ()=><SystemRouting/>},
  {path:"/Account",         setMenu:true,  component: ()=><AccountRouting/>},
  {path:"/TinderBreacher",  setMenu:true,  component: ()=><ShapesValidation/>},
]
const toMainRouteRender = (route) => {
  if(route.setMenu){return <Menu key='menu'>{route.component()}</Menu >}
  else{return route.component()}
}
const mapStateToProps = state => {
  return {
    userLogged : state.Login.userLogged,
    desiredURL : state.Login.desiredURL
  }
}
const mapDispatchToProps = dispatch => {
  return {
    saveDesiredURL : (path:string)=>dispatch({type:Actions.SAVE_DESIRED_URL, URL:path}),
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    removeDesiredURL : ()=>DP.saveDesiredURL("")
  }
}
interface MainRoutingState{
}
class MainRouting extends React.Component<any,MainRoutingState>{
  constructor(props){
    super(props)
    this.state = {
    }
    const availableLangs = ["en","fr"]
    let defaultLang = "en"
    for(let lang of navigator.languages){
      const shortLang = lang.substring(0,2).toLowerCase()
      if(ArrayUtils.contain(availableLangs, shortLang)){
        defaultLang = shortLang
        break
      }
    }
    this.props.initialize({
      languages : [
        {name : "English", code : "en"},
        {name : "French", code : "fr"}
      ],
      translation : translations,
      options : {
        renderToStaticMarkup,
        defaultLanguage : defaultLang
      }
    })
  }
  @keydown(["alt+q"])
  toggleLang() {
    if((process.env.REACT_APP_ENABLE_SHORTCUTS||"") ==="1"){
      this.props.setActiveLanguage(
        this.props.activeLanguage.code === "fr" ? "en" : "fr"
      )
    }
  }
  componentDidUpdate(prevProps, prevState:MainRoutingState){
    if(prevProps.userLogged.preferences.language === undefined
      && this.props.userLogged.preferences.language !== undefined
      && this.props.userLogged.preferences.language != this.props.activeLanguage.code){
      this.props.setActiveLanguage(this.props.userLogged.preferences.language)
    }
    const ga = (window as any).ga
    if(prevProps.location.pathname !== this.props.location.pathname && ga !== undefined){
      const page = this.props.location.pathname+this.props.location.search+this.props.location.hash
      ga('send', 'pageview', {page})
    }
  }
  render(){
    let mapping=defaultMapping
    let redirectPath = "/Account/0/Dashboard"
    if(this.props.userLogged.token === ""){
      const path = this.props.location.pathname
      if(this.props.desiredURL==="" && !path.toUpperCase().includes("LOGIN")){this.props.saveDesiredURL(path)}
      redirectPath = "/Login"
      mapping = [
        {path:"/ForgotPassword", setMenu:false, component:()=> <ForgotPassword/>},
        {path:"/Login",          setMenu:false, component:()=> <Login/>},
      ]
    }
    else{
      logUserAction(this.props.userLogged.token,"NavigatedTo",this.props.location.pathname)
      if(this.props.desiredURL && this.props.desiredURL.length > 0){
        this.props.removeDesiredURL()
        logUserAction(this.props.userLogged.token,"NavigatedTo",this.props.desiredURL)
        return <Redirect from="/" to={this.props.desiredURL}/>
      }
    }

    // Notice the redirect acting as default when path unkown
    return(
      <HashRouter>
        <Switch>
          {mapping.map((route:RouterPathMapping)=>
            <Route key={route.path} render={props=> toMainRouteRender(route)} path={route.path} />
          )}
          <Redirect from="/" to={redirectPath}/>
        </Switch>
      </HashRouter>
    )
  }
}

export default withRouter(withLocalize(connect(mapStateToProps,mapDispatchToProps,mergeProps)(MainRouting)) as any)
