import './AddGuidelineListFilters.css'
import * as React                                           from 'react'
import {Translate}                                          from "react-localize-redux"
import { Icon }                                             from "@material-ui/core"
import FilterCheckboxesList, {Row}                          from './FiltersCheckboxesList/FilterCheckboxesList'
import ExpansionPanel                                       from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary                                from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails                                from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon                                       from '@material-ui/icons/ExpandMore'

interface AddGuidelineListFiltersProps {
  filterType   : string
  data         : Row[]
  handleToggle : (e) => void
  handleClear  : ( ) => void
}

const AddGuidelineListFilters = (props:AddGuidelineListFiltersProps) => {
  const [expanded,setExpanded] = React.useState(true)
  const handleExpansionToggle = () => setExpanded(!expanded)
  const { data } = props
  return (
    <div className="AddGuidelineListFilters">
      <ExpansionPanel expanded={expanded} >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}  onClick={handleExpansionToggle}>
          <div className="Type">
            <p> <Icon style={{marginRight:'10px'}}>filter_list</Icon> </p>
            <p> {props.filterType} </p>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="Details">
          <FilterCheckboxesList data={data} handleToggle={props.handleToggle} />
          <div style={{textAlign:'center'}}>
            <div onClick={props.handleClear} className="ClearAll">
              <p>
                <span><Icon>clear</Icon></span>
                <Translate id="pacyChangesHistory.clearFilters"/>
              </p>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default AddGuidelineListFilters
