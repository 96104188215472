import './index.css'
import * as React                                         from "react"
import {connect}                                          from "react-redux"
import PERMISSIONS                                        from "../../../../model/constant/Permissions"
import ObjectUtils                                        from "../../../../utils/ObjectUtils"
import {
  retrieveAvailableShapesList,
  editShape,
  saveSimplifiedShape
}                                                         from "../../../../actions/shapes/ShapeValidation"
import {retrieveAllShapes}                                from "../../../../actions/shapes/RetrieveShapes"
import {Button}                                           from "carbon-components-react"
import ExplainedLoading                                   from "../../../../components/loading"
import {Panel}                                            from "../../../../components/Panel/Panel"
import UserPermissionBoundary                             from "../../../../components/permissions/UserPermissionBoundary"
import GoBack                                             from "../../../../components/Button/ButtonGoBack"
import ShapeSelector                                      from "./ShapeSelector"
import ShapePointsList                                    from "./ShapePointsList"
import ShapeMap                                           from "./ShapeMap"
import ShapeSimplifier                                    from "../shapeSimplifier/"

interface ShapeEditorPropsOwnProps{
  exit ?: ()=>void
}
interface ShapeEditorProps extends ShapeEditorPropsOwnProps{
  shapes                  : any
  fetchedShapes           : number[]
  availableShapes         : any[]
  retrieveAvailableShapes : ()=>void
  fetchShapes             : ()=>void
  saveShape               : (shapeId:number, code:number, polygons:any[][], simplification?:boolean)=>void
}

const mapStateToProps = (state,ownProps:ShapeEditorPropsOwnProps)=>{
  return {
    shapes          : state.Params.GeoBids.Shape.PlacesShapes,
    fetchedShapes   : state.Params.GeoBids.Shape.FetchedShapeCodes,
    availableShapes : state.Params.GeoBids.Shape.AvailableShapes,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    retrieveAvailableShapes : ()=>dispatch(retrieveAvailableShapesList()),
    fetchShapes             : ()=>dispatch(retrieveAllShapes()),
    saveShape               : (shapeId:number, code:number, polygons:any[][], simplification?:boolean)=>{
      if(simplification){dispatch(saveSimplifiedShape(shapeId, code, polygons))}
      else{dispatch(editShape(shapeId, code, polygons))}
    },
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

interface ShapeEditorState{
  selectedCode  ?: number
  selectedShape ?: any
  selectedShapeLastState ?: any
  selectedPoint ?: any
  saving         : boolean
  simplifying    : boolean
  showPoints     : boolean
  showOriginal   : boolean
  showAllShapes  : boolean
}
const PermissionWrapper = (props) => (
  <UserPermissionBoundary
    userLevel={3}
    permissions={[[PERMISSIONS.IDB2_Modify_Shape]]}
    onFailure={()=><div>You are not allowed</div>}
  >
    <ShapeEditor {...props}/>
  </UserPermissionBoundary>
)
class ShapeEditor extends React.Component<ShapeEditorProps,ShapeEditorState>{
  private shapeMap
  constructor(props){
    super(props)
    this.state = {
      saving : false,
      simplifying : false,
      showPoints : false,
      showOriginal : false,
      showAllShapes : true,
    }
    this.shapeMap = React.createRef()
    this.selectShape = this.selectShape.bind(this)
    this.selectPoint = this.selectPoint.bind(this)
    this.onShapeMouseUp = this.onShapeMouseUp.bind(this)
    this.removePointFromShape = this.removePointFromShape.bind(this)
    this.removePolygonFromShape = this.removePolygonFromShape.bind(this)
    this.separatePolygon = this.separatePolygon.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
    this.saveCurrentShape = this.saveCurrentShape.bind(this)
    this.saveSimplifiedShape = this.saveSimplifiedShape.bind(this)
    this.undoLastChange = this.undoLastChange.bind(this)
    this.renderEditingControls = this.renderEditingControls.bind(this)
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.availableShapes.length === 0 && this.props.availableShapes.length > 0
       && this.props.availableShapes.length != this.props.shapes.length
    ){this.props.fetchShapes()}
    if(
      (this.state.selectedShape === undefined && this.state.selectedCode) ||
      (this.state.selectedShape && this.state.selectedShape.code != this.state.selectedCode)
    ){
      const shape = this.props.shapes[this.state.selectedCode]
      if(shape){
        this.setState({
          selectedShape : {
            ...shape,
            code : this.state.selectedCode
          }
        })
      }
    }
    if(this.state.saving){
      this.setState({saving:false})
    }
  }
  componentDidMount(){
    this.props.retrieveAvailableShapes()
  }
  selectShape(code){
    this.setState({selectedCode:code})
  }
  selectPoint(lat:number, lng:number){
    this.setState({
      selectedPoint : {
        lat : lat,
        lng : lng,
      }
    })
  }
  renderSpinner(){
    if(this.state.selectedCode && !this.state.selectedShape){
      return <ExplainedLoading text="Loading selected shape"/>
    }
    return null
  }
  renderSelectedShapeName(){
    if(this.state.selectedCode){
      const name = this.props.availableShapes.find(x=>x.code === this.state.selectedCode).name
      return (
        <div className="SelectedShapeName">
          <h5>Selected: {name}</h5>
          <Button onClick={()=>this.setState({simplifying:true})}>Simplify</Button>
        </div>
      )
    }
    return <h5>No shapes selected</h5>
  }
  renderEditingControls(){
    if(this.state.selectedShape){
      return (
        <div style={{gridColumnStart:1, gridColumnEnd:3}}>
          <input
            type="checkbox"
            checked={this.state.showPoints}
            onChange={()=>this.setState(prevState=>({showPoints:!prevState.showPoints}))}
          />&nbsp;Show shape's points (Can cause lags)
          <br/>
          <input
            type="checkbox"
            checked={this.state.showOriginal}
            onChange={()=>this.setState(prevState=>({showOriginal:!prevState.showOriginal}))}
          />&nbsp;Show original shape
          <br/>
          <input
            type="checkbox"
            checked={this.state.showAllShapes}
            onChange={()=>this.setState(prevState=>({showAllShapes:!prevState.showAllShapes}))}
          />&nbsp;Show all shapes
          <br/>
          <Button disabled={this.state.selectedShapeLastState===undefined} onClick={this.undoLastChange}>Undo</Button>
        </div>
      )
    }
    return null
  }
  onShapeMouseUp(shapeId, newPaths){
    this.setState(prevState=>({
      selectedShapeLastState : prevState.selectedShape,
      selectedShape : {
        ...prevState.selectedShape,
        polygons : newPaths
      }
    }))
  }
  removePointFromShape(shapeId, polygonIndex, pointIndex){
    this.setState(prevState=>({
      selectedShapeLastState : prevState.selectedShape,
      selectedShape : {
        ...prevState.selectedShape,
        polygons : [
          ...prevState.selectedShape.polygons.slice(0,polygonIndex),
          [
            ...prevState.selectedShape.polygons[polygonIndex].slice(0,pointIndex),
            ...prevState.selectedShape.polygons[polygonIndex].slice(pointIndex+1)
          ],
          ...prevState.selectedShape.polygons.slice(polygonIndex+1)
        ]
      }
    }))
  }
  removePolygonFromShape(shapeId, polygonIndex){
    this.setState(prevState=>({
      selectedShapeLastState : prevState.selectedShape,
      selectedShape : {
        ...prevState.selectedShape,
        polygons : [
          ...prevState.selectedShape.polygons.slice(0,polygonIndex),
          ...prevState.selectedShape.polygons.slice(polygonIndex+1)
        ]
      }
    }))
  }
  separatePolygon(shapeId, polygonIndex, separationIndex){
    this.setState(prevState=>({
      selectedShapeLastState : prevState.selectedShape,
      selectedShape : {
        ...prevState.selectedShape,
        polygons : [
          ...prevState.selectedShape.polygons.slice(0,polygonIndex),
          [...prevState.selectedShape.polygons[polygonIndex].slice(0, separationIndex)],
          [...prevState.selectedShape.polygons[polygonIndex].slice(separationIndex)],
          ...prevState.selectedShape.polygons.slice(polygonIndex+1)
        ]
      }
    }))
  }
  saveCurrentShape(){
    if(this.state.selectedShape){
      this.props.saveShape(
        this.state.selectedShape.id,
        this.state.selectedShape.code,
        this.state.selectedShape.polygons
      )
      this.setState({saving:true})
    }
  }
  saveSimplifiedShape(simplifiedPolygons:any[][]){
    if(this.state.selectedShape){
      this.props.saveShape(
        this.state.selectedShape.id,
        this.state.selectedShape.code,
        simplifiedPolygons
      )
      this.setState(prevState=>({
        saving:true,
        selectedShape : {
          ...prevState.selectedShape,
          polygons : simplifiedPolygons
        }
      }))
    }
  }
  cancelEditing(){
    this.setState({
      selectedShape : this.props.shapes[this.state.selectedCode]
    })
  }
  undoLastChange(){
    if(this.state.selectedShapeLastState){
      this.setState(prevState=>({
        selectedShape : prevState.selectedShapeLastState,
        selectedShapeLastState : prevState.selectedShape
      }))
    }
  }
  render(){
    if(this.state.saving){return <ExplainedLoading text="Saving..."/>}
    if(Object.keys(this.props.shapes).length===0){return <ExplainedLoading text="Loading shapes..."/>}
    if(this.state.simplifying){return (
      <ShapeSimplifier
        shape={this.state.selectedShape}
        exit={()=>this.setState({simplifying:false})}
        save={this.saveSimplifiedShape}
      />
    )}
    let shapes = {}
    if(!this.state.selectedShape || this.state.showAllShapes){
      shapes = {...this.props.shapes}
    }
    if(this.state.selectedShape){
      if(this.state.showOriginal){
        if(shapes[this.state.selectedCode] === undefined){
          shapes[this.state.selectedCode] = this.props.shapes[this.state.selectedCode]
        }
      }
      else if(shapes[this.state.selectedCode] !== undefined){
        delete shapes[this.state.selectedCode]
      }
    }
    return (
      <Panel title="Shape Editor" subtitle="Edit shapes">
        {this.props.exit && <GoBack onClick={this.props.exit}/>}
        {this.renderSpinner()}
        {this.renderSelectedShapeName()}
        <div className="ShapeEditor">
          <div className="MapAndOptions">
            <ShapeMap
              ref={this.shapeMap}
              shapes={ObjectUtils.getObjectValues(shapes)}
              editableShape={this.state.selectedShape}
              onShapeMouseUp={this.onShapeMouseUp}
              pointMarker={this.state.selectedPoint}
              showPoints={this.state.showPoints}
            />
            <div className="MapOptions">
              <Button onClick={this.saveCurrentShape}>Save</Button>
              <Button onClick={this.cancelEditing}>Cancel</Button>
            </div>
          </div>
          {this.renderEditingControls()}
          <ShapeSelector
            shapes={this.props.availableShapes}
            selectShape={this.selectShape}
          />
          <ShapePointsList
            shapes={this.state.selectedShape?[this.state.selectedShape]:[]}
            selectedPoint={this.state.selectedPoint}
            removePoint={this.removePointFromShape}
            selectPoint={this.selectPoint}
            separatePolygon={this.separatePolygon}
            removePolygon={this.removePolygonFromShape}
          />
        </div>
      </Panel>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(PermissionWrapper)
