export default class MathUtils {
  public static round(value, decimals:number) {
    decimals = Math.pow(10,Math.round(decimals))
    return Math.round(value*decimals)/decimals
  }
  public static getOpacityForBidModifier(bidModifier:number):number{
    if(bidModifier>=2){return 0.6}
    else{return (0.4/1.9*(bidModifier-0.1)+0.2)}
  }
}
