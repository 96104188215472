import {combineReducers}               from "redux"
import Campaigns                       from "./Campaigns"
import SearchTerms                     from "./SearchTerms"
import Ads                             from "./Ads"
import Keywords                        from "./Keywords"
import GeoBids                         from "./GeoBids"
import Budgets                         from "./Budgets"
import Placements                      from "./Placements"
import MonthlyBudgets                  from "./MonthlyBudgets"
import ImpressionsByDevice             from "./ImpressionsByDevice"
import GeoMapping                      from "./GeoMapping"

const Retrieving = combineReducers({
  Campaigns,
  ImpressionsByDevice,
  SearchTerms,
  Ads,
  Keywords,
  GeoBids,
  Budgets,
  MonthlyBudgets,
  Placements,
  GeoMapping
})

export default Retrieving
