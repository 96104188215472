import Keystone                     from "../../../utils/Keystone"
import VSGL                         from "../../../model/Store/Matcher/VSGL"
import UserActions                  from "../../../model/constant/UserAction"
import LogBuilder                   from "../../../controller/log/LogBuilder"
import {fetchKeywords}              from "./Keywords"

const createPattern = (kws,pos) => {
  return {
    text : kws,
    positive : pos,
  }
}
const separateKeywords = (keywordString:string, positive:boolean) => {
  return keywordString.split("\n").map(k=>k.trim()).filter(k=>k).map((posKw)=>createPattern(posKw,positive))
}
export const saveKeywords = (token:string, accountId:number, matcher:VSGL, positiveKws:string, negativeKws:string) => {
  LogBuilder.log(UserActions.SAVE_KEYWORD,token,{accountId, matcher, positiveKws, negativeKws})
  const newPositiveKeywords = separateKeywords(positiveKws, true)
  const newNegativeKeywords = separateKeywords(negativeKws, false)
  let type = matcher.vehicleType ? "VSGL" : "GL"
  let payload : any = {
    token     : token,
    accountId : accountId,
    action    : "UpdateKeywordPatterns",
    keywords  : JSON.stringify(newPositiveKeywords.concat(newNegativeKeywords)),
    type      : type,
    group     : matcher.group,
    lang      : matcher.lang,
  }
  if(type === "VSGL"){
    payload = {
      ...payload,
      VehicleType  : matcher.vehicleType,
      VehicleState : matcher.state,
    }
  }
  return (dispatch) => {
    let onResult = (data) => dispatch(fetchKeywords(token, accountId))
    let onError = (data) => console.log(data.error)
    return Keystone.post(payload,onResult,onError)
  }
}
