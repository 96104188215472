import * as React             from "react"
import {combineReducers}      from 'redux'
import Retrieving             from "./Retrieving"
import AdCopyPatterns         from "./AdCopyPatterns"
import AdCopyPatternSaveError from "./AdCopyPatternSaveError"
import Strategies             from "./strategies"
import RSAAdcopies            from "./rsaAdcopies"

export const AdCopies = combineReducers({
  Retrieving,
  AdCopyPatterns,
  AdCopyPatternSaveError,
  Strategies,
  RSAAdcopies
})
