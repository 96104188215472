import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    a : {
      textDecoration : "underline",
      '&:hover':{
        cursor: "pointer"
      }
    },
    popover : {
      display : "flex",
      flexDirection : "column",
      gap : 10,
      height : 420,
      width : 380,
    },
    adcopies : {
      padding : "0px 20px 20px 20px",
      display : "flex",
      flexDirection : "column",
      alignItems : "center",
      gap : 15,
    },
    button : {
      color : "#707070",
      alignSelf : "end",
      fontWeight : "bold",
      height : 30,
      width : 30,
    },
  })
)

export default useStyles
