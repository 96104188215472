import './index.css'
import * as React                     from 'react'
import PERMISSIONS                    from "../../model/constant/Permissions"
import RoutingUtils                   from "../../utils/RoutingUtils"
import MinimalistButton               from "../../components/Button/MinimalistButton"
import UserPermissionBoundary         from "../../components/permissions/UserPermissionBoundary"
import {Panel}                        from "../../components/Panel/Panel"

interface SystemButtonsProps{
  history
}
const SystemButtons = (props:SystemButtonsProps) => {
  return (
    <Panel title="System" subtitle="Configuration of elements affecting the whole system">
      <div className="MinimalistButtonsList">
        <UserPermissionBoundary
          userLevel={3}
          permissions={[/*[PERMISSIONS.Keystone_CleanerWhatever]*/]}
          onFailure={()=>null}
        >
          <MinimalistButton
            text="Cleaner"
            subtitle="Manage the Cleaner's data source"
            onClick={()=>{alert("Not yet implemented")}}
          />
        </UserPermissionBoundary>
        <UserPermissionBoundary
          userLevel={3}
          permissions={[[PERMISSIONS.Keystone_GrammarBooster_Read]]}
          onFailure={()=>null}
        >
          <MinimalistButton
            text="Grammar Booster"
            subtitle="Manage the GrammarBooster's replacements"
            onClick={()=>RoutingUtils.appendToUrl(props.history, "GrammarBooster")}
          />
        </UserPermissionBoundary>
        <UserPermissionBoundary
          userLevel={3}
          permissions={[[PERMISSIONS.IDB2_Modify_Shape]]}
          onFailure={()=>null}
        >
          <MinimalistButton
            text="Shape editor"
            subtitle="Edit the shapes used for geo targeting (ONLY VISUAL)"
            onClick={()=>RoutingUtils.appendToUrl(props.history, "ShapeEditor")}
          />
        </UserPermissionBoundary>
      </div>
    </Panel>
  )
}

export default SystemButtons
