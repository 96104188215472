import './ShowTargetingControl.css'
import * as React     from "react"
import {Translate}    from "react-localize-redux"

interface ShowTargetingControlProps{
  defaultChecked ?: boolean
  onChange       ?: (checked:boolean)=>void
}
interface ShowTargetingControlState{
  checked : boolean
}
class ShowTargetingControl extends React.Component<ShowTargetingControlProps,ShowTargetingControlState>{
  static defaultProps = {
    defaultChecked : true,
    onChange : ()=>{}
  }
  constructor(props){
    super(props)
    this.state = {
      checked : props.defaultChecked
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e){
    this.props.onChange(e.target.checked)
    this.setState({
      checked : e.target.checked
    })
  }
  render(){
    return (
      <div className={"ShowTargetingControl"+(this.state.checked?" Checked":"")}>
        <input type="checkbox" onChange={this.handleChange} checked={this.state.checked}/>
        <Translate id="dashboard.mapSpend.showTargeting"/>
      </div>
    )
  }
}

export default ShowTargetingControl
