import Actions               from "../../../../model/constant/actions"
import ReducerUtils          from "../../../../utils/ReducerUtils"
import {AdCopyRSAPatterns}   from "../../../../model/adcopy"
import {
  reorderRSAText,
  assignIdentifiersToRSAText
}                            from "../../../../components/adCopy/utils"

interface AdCopiesByCampaignGroupByAccountID {
  [accountId:number] : {
    [campaignGroup:string] : AdCopyRSAPatterns[]
  }
}

const RSAAdcopies = ReducerUtils.createReducer<AdCopiesByCampaignGroupByAccountID>({},{
  [Actions.RETRIEVE_RSA_ADCOPIES] : (state,action) => {
    const {accountId, campaignGroup, adcopies} = action
    return {
      ...state,
      [accountId] : {
        ...state[accountId],
        [campaignGroup] : adcopies.map((pattern:AdCopyRSAPatterns)=>{
          return {
            ...pattern,
            templates: pattern.templates.map(template=>{
              return {
                ...template,
                headlines    : reorderRSAText(assignIdentifiersToRSAText(template.headlines)),
                descriptions : reorderRSAText(assignIdentifiersToRSAText(template.descriptions))
              }
            })
          }
        })
      } 
    }
  }
})

export default RSAAdcopies
