import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"
import ArrayUtils       from "../../utils/ArrayUtils"

const storeTest = (state,action) => {
  return action.result
}
const TestResult = ReducerUtils.createReducer<any[]|null>(null,{
  [Actions.RETRIEVE_GRAMMAR_BOOST_TEST] : storeTest,
})
export default TestResult
