import * as React         from 'react'
import {
  Chip,
  Icon,
  Tooltip,
}                         from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                                          from "react-localize-redux"

interface InactiveChipProps extends LocalizeContextProps {
  active : boolean
}
const InactiveChip = (props:InactiveChipProps) => {
  if(props.active===true){return null}
  return (
    <Tooltip placement="top" title={<p>{props.translate("feeds.tooltip.inactive")}</p>}>
      <Chip
        style={{paddingLeft:6}}
        color="secondary"
        variant="outlined"
        label={props.translate("feeds.inactive")}
        icon={<Icon>warning</Icon>}
      />
    </Tooltip>
  )
}
export default withLocalize(InactiveChip)
