import Auth       from "../../utils/Authentification"
import Actions    from "../../model/constant/actions"
import {getToken} from "../../storeAccessor/Generic"

const getPermissionProfilesSuccess = (response) => {
  return {
    type     : Actions.PERMISSION_PROFILE_RETRIEVED,
    profiles : response.data.profiles,
  }
}
const retrievePermissionProfiles = () => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_PERMISSION_PROFILE,
    status : true
  })
  Auth.get({
      token  : getToken(getState()),
      action : "GetAvailableProfiles",
    },
    data  => dispatch(getPermissionProfilesSuccess(data)),
    error => console.log(error)
  ).then(()=>dispatch({
    type   : Actions.RETRIEVING_PERMISSION_PROFILE,
    status : false
  }))
}
export default retrievePermissionProfiles
