import {combineReducers}       from 'redux'
import {FetchedAccounts}       from "./FetchedAccounts"
import {BudgetsPlanned}        from "./BudgetsPlanned"
import {Budgets}               from "./Budgets"
import RetrievingBudgetPlans   from "./RetrievingBudgetPlans"
import RetrievingBudgets       from "./RetrievingBudgets"
import IsActive                from "./IsActive"

export const BudgetsPlans = combineReducers({
  FetchedAccounts,
  BudgetsPlanned,
  Budgets,
  RetrievingBudgetPlans,
  RetrievingBudgets,
  IsActive,
})
