import Shapes           from "../../utils/ShapesAPI"
import Actions          from "../../model/constant/actions"
import {getToken}       from "../../storeAccessor/Generic"

const retrieveShapes = (codes:number[]) => (dispatch, getState) => {
  const onError = error => dispatch({
    type : Actions.REMOVE_FETCHED_SHAPE_CODES,
    codes : codes
  })
  dispatch({
    type : Actions.ADD_FETCHED_SHAPE_CODES,
    codes : codes
  })
  Shapes.get({
      token  : getToken(getState()),
      action : "GetShapesByCode",
      codes  : codes.join(","),
    },
    response=>{
      if(response.error.length > 0){onError(response)}
      else{
        dispatch({
          type : Actions.RETRIEVE_PLACES_SHAPES,
          data : response.data.shapes.map(x=>({
            ...x,
            polygons : JSON.parse(x.polygons).map(polygon=>polygon.map(x=>({
              lat : x[0],
              lng : x[1]
            })))
          }))
        })
      }
    },
    onError
  )
}
export const retrieveAllShapes = () => (dispatch, getState) => {
  Shapes.get({
      token  : getToken(getState()),
      action : "GetShapes",
    },
    response=>{
      dispatch({
        type : Actions.RETRIEVE_PLACES_SHAPES,
        data : response.data.shapes.map(x=>({
          ...x,
          polygons : JSON.parse(x.polygons).map(polygon=>polygon.map(x=>({
            lat : x[0],
            lng : x[1]
          })))
        }))
      })
    },
    error=>console.log(error)
  )
}

export default retrieveShapes
