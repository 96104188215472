const Models:string[] = [
  "F-150",
  "RAV4",
  "CRV",
  "Civic",
  "Gallardo",
  "Raptor",
  "Silverado",
  "Corolla",
  "Elantra",
  "Tucson",
  "Ranger",
  "HiLux",
  "i30",
  "CX-5",
  "Cerato",
  "Camry",
  "Golf",
  "Lancer",
  "Roadster",
  "Stradale",
  "Chiron",
]
export default Models
