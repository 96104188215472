import * as React                       from "react"
import {connect}                        from "react-redux"
import ExplainedLoading                 from "../loading"
import ErrorBoundary                    from "../Error/ErrorBoundary"
import retrieveBingBaseInfo             from "../../actions/campaign/bingCampaignsBaseInfo"

const mapStateToProps = (state,ownProps)=>{
  return {
    accountId  : state.Accounts.selected,
    baseInfo   : state.Campaigns.BaseInfo.Bing.List[state.Accounts.selected],
    retrieving : state.Campaigns.BaseInfo.Retrieving,
    failed     : state.Campaigns.BaseInfo.Failed
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    retrieveBaseInfo : (accountId:number) => dispatch(retrieveBingBaseInfo(accountId))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    retrieveBaseInfo : ()=>{
      if(SP.accountId){DP.retrieveBaseInfo(SP.accountId)}
    }
  }
}
const BaseInfoLoader = props => {
  if(props.baseInfo===undefined){
    if(!props.retrieving && !props.failed){props.retrieveBaseInfo()}
    if(!props.letThrough){
      return <ExplainedLoading translateId="loadings.campaignsInfo"/>
    }
  }
  return <ErrorBoundary>{props.render(props.baseInfo,props.retrieving)}</ErrorBoundary>
}
const BaseInfoLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(BaseInfoLoader)

const requiresCampaignsBaseInfo = (letThrough?:boolean) => Component => props => (
  <BaseInfoLoaderConnected
    letThrough={letThrough || false}
    render={ (baseInfo, loading) => <Component campaignsBaseInfo={baseInfo} loadingCampaignsBaseInfo={loading} {...props} /> }
  />
)

export default requiresCampaignsBaseInfo
