import './ProxyParams.css'
import * as React                              from 'react'
import {
  Typography,
  Icon,
  Button,
  Dialog,
  Card,
  Switch,
  Modal,
}                                              from '@material-ui/core'
import {
  Proxy,
  BeginningOfMonthBoostConfig,
  BeginningOfMonthShape,
}                                              from '../../../../model/pacy'
import MaxSharingSlider                        from './Components/MaxSharingSlider'
import AgentWeightsConfiguration               from './AgentWeightsConfiguration'
import BoostConfig                             from './Components/BoostConfig'
import {
  AccountModifier,
  createToggleProxyAccountModifier,
  createSetBOMBAccountModifier,
  createSetAgentWeightAccountModifier,
  createToggleAgentAccountModifier,
  createSetProxyMaxSharingAccountModifier
}                                              from '../../../../model/pacy/AccountModifiers'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                                              from 'react-localize-redux'

interface ProxyParamsOwnProps {
  addAccountModifiers : (newActions:AccountModifier[])=>void
  proxy               : Proxy
  proxyGroupId        : string
}
interface ProxyParamsProps extends ProxyParamsOwnProps {
  translate : TranslateFunction
}

const ProxyParams = (props:ProxyParamsProps) => {
  const [openModal,setOpenModal] = React.useState(false)
  const [openAgentsConfig,setOpenAgentsConfig] = React.useState(false)
  const [boost,setBoost] = React.useState<BeginningOfMonthBoostConfig>({
    length   : props.proxy.beginningOfMonthBoost.length,
    boost    : props.proxy.beginningOfMonthBoost.boost,
    shape    : props.proxy.beginningOfMonthBoost.shape,
    active   : props.proxy.beginningOfMonthBoost.active
  })
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCancelBoostChanges = () => {
    setOpenModal(false)
    setBoost({
      length   : props.proxy.beginningOfMonthBoost.length,
      boost    : props.proxy.beginningOfMonthBoost.boost,
      shape    : props.proxy.beginningOfMonthBoost.shape,
      active   : props.proxy.beginningOfMonthBoost.active
    })
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenAgents = () => {
    setOpenAgentsConfig(true)
  }
  const handleCloseAgents = () => {
    setOpenAgentsConfig(false)
  }
  const handleShapeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBoost(prevState=>({...prevState,shape:event.target.value as BeginningOfMonthShape}))
  }
  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const temp=Number(event.target.value)
    setBoost(prevState=>({...prevState,length:temp}))
  }
  const handleDurationChangeBlur = () => {
    if(boost.length<1) {
      setBoost(prev=>({...prev,length:1}))
    }
    if(boost.length>15) {
      setBoost(prev=>({...prev,length:15}))
    }
  }
  const handleBoostEnable = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBoost(prev=>({...prev,active:e.target.checked}))
  }
  const handleActivateBoost = () => {
    setBoost(prev=>({...prev,active:true}))
  }
  const handleBoostChangeBlur = () => {
    if(boost.boost<0) {
      setBoost(prev=>({...prev,boost:0}))
    }
    if(boost.boost>2) {
      setBoost(prev=>({...prev,boost:2}))
    }
  }
  const handleBoostConfirm =()=>{
    props.addAccountModifiers([createSetBOMBAccountModifier(props.proxy.id,boost.active,boost.boost,boost.length,boost.shape,props.proxyGroupId)])
  }
  const handleBoostChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const temp = parseInt(event.target.value, 10)/100+1
    setBoost(prevState=>({...prevState,boost:temp}))
  }
  const handleProxyEnable = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.addAccountModifiers([createToggleProxyAccountModifier(props.proxy.id,event.target.checked,props.proxyGroupId)])
  }
  const handleSharingConfirm = (event,newValue:number) => {
    props.addAccountModifiers([createSetProxyMaxSharingAccountModifier(props.proxy.id,newValue/100,props.proxyGroupId)])
  }
  const handleSharingInputConfirm = (value:number) => {
    props.addAccountModifiers([createSetProxyMaxSharingAccountModifier(props.proxy.id,value/100,props.proxyGroupId)])
  }
  const handleWeightsChange =(agentsChanged:{[id:string]:number},agentsActivated:string[])=>{
    const weightsChangeActions = Object.keys(agentsChanged).map(proxyId=>{
      return createSetAgentWeightAccountModifier(proxyId,agentsChanged[proxyId],props.proxy.id,props.proxyGroupId)
    })
    const activateActions = agentsActivated.map(agentId=>{
      return createToggleAgentAccountModifier(agentId,true,props.proxy.id,props.proxyGroupId)
    })
    const newActions = [...weightsChangeActions,...activateActions]
    props.addAccountModifiers(newActions)
  }

  React.useEffect(() => {
    setBoost({
      length   : props.proxy.beginningOfMonthBoost.length,
      boost    : props.proxy.beginningOfMonthBoost.boost,
      shape    : props.proxy.beginningOfMonthBoost.shape,
      active   : props.proxy.beginningOfMonthBoost.active
    })
  }, [props.proxy.beginningOfMonthBoost])

  return (
    <Card className="ProxyParams">
      <div>
        <div className="Status">
          <Icon style={{marginRight:5}} color="primary">power_settings_new</Icon>
          <Typography variant="button"><Translate id="pacyConfigs.activate" /></Typography>
          <Switch color="primary" checked={props.proxy.active} onChange={handleProxyEnable}/>
        </div>
        <Typography className="Boost">
          <Icon style={{color:'#FFA500'}}>flash_on</Icon>
            <Translate id='pacyConfigs.boost' />
          <Button style={props.proxy.beginningOfMonthBoost.active ? {color:'green'} : {color:'red'}}disabled color="primary">
            {props.proxy.beginningOfMonthBoost.active ? props.translate('pacyConfigs.active') : props.translate('common.paused')}
          </Button>
          <Button variant="outlined" onClick={handleOpenModal} >
            <Translate id="pacyConfigs.configure"/>
            <Icon style={{marginLeft:5}}>build</Icon>
          </Button>
        </Typography>
      </div>
      <div className="WeightsAndSharing">
        <Button
          style={{color:'#FFF'}}
          variant="contained"
          color="primary"
          onClick={handleOpenAgents}
          disabled={props.proxy.agents.length === 0}
        >
          <Translate id="pacyConfigs.agentsWeights" />&nbsp;&nbsp;
          <Icon>build</Icon></Button>
        <div className="MaxSharing">
          <MaxSharingSlider
            handleSharingInputConfirm = {handleSharingInputConfirm}
            handleSharingConfirm={handleSharingConfirm}
            maxSharing = {props.proxy.maxSharing}
          />
        </div>
      </div>
      <Dialog open={openModal} onClose={handleCancelBoostChanges} >
        <BoostConfig
          boost={boost}
          handleBoostEnable={handleBoostEnable}
          handleBoostChange={handleBoostChange}
          handleDurationChange={handleDurationChange}
          handleShapeChange={handleShapeChange}
          handleCloseModal={handleCloseModal}
          handleDurationBlur={handleDurationChangeBlur}
          handleBoostBlur={handleBoostChangeBlur}
          handleBoostConfirm={handleBoostConfirm}
          handleActivateBoost={handleActivateBoost}
        />
      </Dialog>
      <Modal className="AgentsWeightsInputsModal" open={openAgentsConfig} onClose={handleCloseAgents}>
        <div>
          <AgentWeightsConfiguration
            agents={props.proxy.agents}
            handleWeightsChange={handleWeightsChange}
            handleCloseModal={handleCloseAgents}
          />
        </div>
      </Modal>
    </Card>
  )
}

export default withLocalize(ProxyParams as any) as React.ComponentType<ProxyParamsOwnProps>
