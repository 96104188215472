import ReducerUtils from "../../utils/ReducerUtils"
import Actions from "../../model/constant/actions"

const setSaving = (state:SavingState, action) => {
  if(action.saving){
    return {
      status : true,
      urlOnSuccess : action.urlOnSuccess
    }
  }
  return {
    ...state,
    status : false
  }
}

interface SavingState{
  status        : boolean
  urlOnSuccess ?: string
}

const Saving = ReducerUtils.createReducer<SavingState>({status: false},{
  [Actions.SAVING_MARKETING_EVENT_ELEMENT] : setSaving
})

export default Saving
