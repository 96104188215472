import './AdCopies.css'
import * as React     from 'react'
import SimpleAdCopy   from '../../../components/adCopy/SimpleAdCopy'
import { Card }       from '@material-ui/core'
import { AdTemplate } from '../../../model/adbuilder'


interface AdCopiesOwnProps {
  templates           : AdTemplate[],
  setSelectedTemplate : (e) => void,
  selectedTemplateId  : number
}
interface AdCopiesProps extends AdCopiesOwnProps {
}

const AdCopies = (props:AdCopiesProps) => {
  const templates = props.templates.map(template => {
    return (
      <Card
        className="AdCopies"
        onClick={props.setSelectedTemplate}
        key={template.id}
        id={template.id.toString()}
        style={template.id == props.selectedTemplateId ? {backgroundColor:'rgba(0, 178, 226, 0.08)'} : null}
      >
        <SimpleAdCopy headlines={template.headlines} descriptions={template.descriptions} url={'www.google.com'}/>
      </Card>
    )
  })

  return (
    <>
      {templates}
    </>
  )
}

export default AdCopies
