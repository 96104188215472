import * as React                        from "react"
import {
  PieChart, Pie, Cell, Legend,
  ResponsiveContainer, Tooltip, Sector
}                                        from "recharts"
import WidthInjector                     from "../WidthInjector"
import DonutActiveShape                  from "./DonutActiveShape"


interface Donut2OwnProps{
  data              : DataPoint[]
  colors            : Colors
  defaultColor     ?: string
  labelFormatter   ?: (value)=>string
  width            ?: number|string
  height           ?: number|string
  withTooltip      ?: boolean
  tooltipFormatter ?: (value)=>string
}
interface Donut2Props extends Donut2OwnProps {
  elementWidth ?: number
}
interface DataPoint{
  name : string
  value : string|number
}
interface Colors{
  [valueName:string] : string
}
interface Donut2State{
  activeIndex : number
}

class Donut2 extends React.Component<Donut2Props,Donut2State>{
  static defaultProps = {
    width : "100%",
    height : "100%",
    defaultColor : "#AAAAAA",
    withTooltip : false,
    tooltipFormatter:x=>""+x,
  }
  constructor(props){
    super(props)
    this.state = {
      activeIndex : 0,
    }
    this.onLegendMouseEnter = this.onLegendMouseEnter.bind(this)
  }
  onLegendMouseEnter(payload){
    this.setState({
      activeIndex : this.props.data.map(x=>x.name).indexOf(payload.value)
    })
  }
  render(){
    const DonutActiveShapeRenderer = DonutActiveShape({
      formatter : this.props.tooltipFormatter,
    })
    const activeShapeProps = (
      this.props.elementWidth && this.props.elementWidth > 800 ? {
        activeIndex : this.state.activeIndex,
        activeShape : DonutActiveShapeRenderer
      } : {}
    )
    const legendPosition : any  = (
        this.props.elementWidth && this.props.elementWidth > 800
      ? {align:"right",  verticalAlign:"middle"}
      : {align:"center", verticalAlign:"bottom"}
    )
    return (
      <ResponsiveContainer width={this.props.width} height={this.props.height}>
        <PieChart>
          <Legend
            layout="vertical"
            {...legendPosition}
            wrapperStyle={{paddingRight:"40px"}}
            onMouseEnter={this.onLegendMouseEnter}
          />
          {this.props.withTooltip && <Tooltip formatter={this.props.tooltipFormatter}/>}
          <Pie
            data={this.props.data}
            dataKey="value"
            innerRadius={70}
            outerRadius={110}
            paddingAngle={5}
            minAngle={2}
            animationBegin={1}
            animationDuration={500}
            {...activeShapeProps}
          >
            {this.props.data.map(entry=>
              <Cell
                key={entry.name}
                fill={this.props.colors[entry.name]||this.props.defaultColor}
              />
            )}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    )
  }
}

export default WidthInjector(Donut2) as React.ComponentType<Donut2OwnProps>
