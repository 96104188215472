import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"
import ArrayUtils       from "../../utils/ArrayUtils"

const storeError = (state,action) => {return action.error}
const empty = (state,action) => {return null}

const TestResult = ReducerUtils.createReducer<any[]|null>(null,{
  [Actions.STORE_GRAMMAR_BOOST_ERROR] : storeError,
  [Actions.RETRIEVE_GRAMMAR_BOOST_REPLACEMENTS] : empty,
  [Actions.RETRIEVE_GRAMMAR_BOOST_TEST] : empty,
})
export default TestResult
