export enum AdCopiesTag{
  "Month"               = "<Month>",
  "_Month"              = "<_Month>",
  "Price"               = "<Price>",
  "Make"                = "<Make>",
  "CreditType"          = "<CreditType>",
  "VehicleSubtype"      = "<VehicleSubtype>",
  "City"                = "<City>",
  "Name"                = "<Name>",
  "Model"               = "<Model>",
  "Year"                = "<Year>",
  "MakeModel"           = "<MakeModel>",
  "Count"               = "<Count>",
  "ConqueringMakeModel" = "<ConqueringMakeModel>",
  "ConqueringMake"      = "<ConqueringMake>",
  "ConqueringModel"     = "<ConqueringModel>",
  "ConqueringYear"      = "<ConqueringYear>",
  "SearchedMakeModel"   = "<SearchedMakeModel>",
  "SearchedMake"        = "<SearchedMake>",
  "SearchedModel"       = "<SearchedModel>",
  "SearchedYear"        = "<SearchedYear>",
}
export default class TAGS{
  public static ALL       = [AdCopiesTag.Month,AdCopiesTag._Month]
  public static VDP       = [...TAGS.ALL,AdCopiesTag.Year,AdCopiesTag.Make,AdCopiesTag.Model,AdCopiesTag.MakeModel,AdCopiesTag.Price]
  public static MM        = [...TAGS.ALL,AdCopiesTag.Make,AdCopiesTag.Model,AdCopiesTag.MakeModel,AdCopiesTag.Count,AdCopiesTag.Price]
  public static BRANDING  = [...TAGS.ALL,AdCopiesTag.City,AdCopiesTag.Name,AdCopiesTag.Make]
  public static CREDIT    = [...TAGS.ALL,AdCopiesTag.CreditType,AdCopiesTag.VehicleSubtype,AdCopiesTag.City,AdCopiesTag.Name]
  public static SERVICE   = [...TAGS.ALL,AdCopiesTag.City,AdCopiesTag.Name,AdCopiesTag.Make,AdCopiesTag.Model]
  public static INVENTORY = [...TAGS.ALL,AdCopiesTag.Year,AdCopiesTag.Make,AdCopiesTag.Model,AdCopiesTag.MakeModel,AdCopiesTag.Count,AdCopiesTag.Price]
  public static CONQUEST  = [...TAGS.ALL,AdCopiesTag.ConqueringMakeModel,AdCopiesTag.ConqueringMake,AdCopiesTag.ConqueringModel,AdCopiesTag.ConqueringYear,
    AdCopiesTag.SearchedMakeModel,AdCopiesTag.SearchedMake,AdCopiesTag.SearchedModel,AdCopiesTag.SearchedYear,AdCopiesTag.Price]
}

export const ADBUILDER_TAGS = {
  VDP      : TAGS.VDP,
  SRP_MM   : TAGS.MM,
  SRP_MMY  : [...TAGS.MM, AdCopiesTag.Year],
  BRANDING : TAGS.BRANDING,
  // handling both names
  CREDIT   : TAGS.CREDIT,
  FINANCE  : TAGS.CREDIT,
  SERVICE  : TAGS.SERVICE
}
