import ReducerUtils       from "../../utils/ReducerUtils"
import Actions            from "../../model/constant/actions"
import {StringMap}        from "../../model/generics"
import {PacyAccount}      from "../../model/pacy/"
import {ProxyGroup}       from "../../model/pacy/ProxyGroup"
import {sortGuidelines}   from "../../model/pacy/Guideline"

const saveAccount = (state:State, action):State => {
  return {
    ...state,
    [action.account.accountId] : action.account
  }
}

const addGuideline = (state:State, action):State => {
  const accountId = action.accountId
  if(!state[accountId]){return state}
  return {
    ...state,
    [accountId] : {
      ...state[accountId],
      proxyGroups : state[accountId].proxyGroups.map((pGroup:ProxyGroup)=>{
        if(pGroup.id !== action.proxyGroupId){return pGroup}
        return {
          ...pGroup,
          guidelines : [...pGroup.guidelines, action.guideline].sort(sortGuidelines)
        }
      })
    }
  }
}

const updatePacingRatio = (state:State, action):State => {
  const accountId = action.accountId
  if(!state[accountId]){return state}
  return {
    ...state,
    [accountId] : {
      ...state[accountId],
      pacingRatioConfig : action.pacingRatioConfig
    }
  }
}

const clear = (state:State, action):State => {
  if(!state[action.accountId]){return state}
  return {
    ...state,
    [action.accountId] : undefined
  }
}

type State = StringMap<PacyAccount>
const Accounts = ReducerUtils.createReducer<State>({},{
  [Actions.RETRIEVE_PACY_ACCOUNT] : saveAccount,
  [Actions.ADD_PACY_GUIDELINE]    : addGuideline,
  [Actions.UPDATE_PACY_RATIO]     : updatePacingRatio,
  [Actions.PACY_CLEAR] : clear
})

export default Accounts
