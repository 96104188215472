import {combineReducers}            from "redux"
import SpendAttempts                from "./SpendAttempts"
import Decisions                    from "./Decisions"

const RetrievedRange = combineReducers({
  SpendAttempts,
  Decisions,
})

export default RetrievedRange
