import './Editor.css'
import * as React                   from "react"
import {withLocalize}               from "react-localize-redux"
import RadiusManager                from "./RadiusManager"
import PlaceEditor                  from "./Place"
import CircleDefinition             from "../../../model/map/CircleDefinition"
import PlaceDefinition              from "../../../model/map/PlaceDefinition"
import {
  Card,
  Tabs,
  Tab,
  Typography,
  Box
}                                   from "@material-ui/core"

interface EditorProps{
  circles         : CircleDefinition[]
  places          : PlaceDefinition[]
  addCircle       : (circle)=>void
  deleteCircle    : (circle)=>void
  updateCircle    : (circle)=>void
  selectCircle    : (circle)=>void
  deselectCircle  : ()      =>void
  addPendingPlace : (place) =>void
  addCodeToQueue  : (code)  =>void
  deletePlace     : (place) =>void
  updatePlace     : (place) =>void
  setSaveActive   : (active:boolean) =>void
  translate      ?: (translateId:string)=>string
}
interface EditorState{
  selectedTab : number
  errorLat    : boolean
  errorLng    : boolean
}
interface TabPanelProps {
  children ?: React.ReactNode
  index     : number | string
  value     : any
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}
class Editor extends React.Component<EditorProps,EditorState> {
  constructor(props){
    super(props)
    this.state={
      selectedTab : 0,
      errorLat    : false,
      errorLng    : false
    }
    this.handleChange = this.handleChange.bind(this)
    this.hideLatError = this.hideLatError.bind(this)
    this.showLatError = this.showLatError.bind(this)
    this.hideLongError = this.hideLongError.bind(this)
    this.showLongError = this.showLongError.bind(this)
  }
  handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    this.setState({
      selectedTab : newValue,
    })
  }
  showLatError() {
    this.setState({
      errorLat : true
    })
  }
  hideLatError() {
    this.setState({
      errorLat : false
    })
  }
  showLongError() {
    this.setState({
      errorLng : true
    })
  }
  hideLongError() {
    this.setState({
      errorLng : false
    })
  }
  render(){
    return(
      <div className="MapEditor">
        <Tabs indicatorColor="primary" value={this.state.selectedTab} onChange={this.handleChange} aria-label="GeoTargeting-Tabs">
          <Tab label={this.props.translate('geoTarget.radius')} {...a11yProps(0)} />
          <Tab label={this.props.translate('geoTarget.places')} {...a11yProps(1)} />
        </Tabs>
        <div className="Errors">
          {this.state.errorLat &&
            <Typography variant="subtitle2">{this.props.translate('geoTarget.errors.latitude')}</Typography>}
          {this.state.errorLng &&
            <Typography variant="subtitle2">{this.props.translate('geoTarget.errors.longitude')} </Typography>}
        </div>
        <TabPanel value={this.state.selectedTab} index={0}>
          {this.props.circles.length !==0 ?
            (<div>
              <div className="Info">
                <Typography variant="caption"> {this.props.circles.length} {this.props.circles.length < 2 ? this.props.translate('geoTarget.oneRadius') : this.props.translate('geoTarget.radius')} </Typography>
              </div>
              <Card className="RadiusManagerDiv">
                <RadiusManager
                  circles={this.props.circles}
                  deleteCircle={this.props.deleteCircle}
                  updateCircle={this.props.updateCircle}
                  selectCircle={this.props.selectCircle}
                  deselectCircle={this.props.deselectCircle}
                  setSaveActive={this.props.setSaveActive}
                  showLatError={this.showLatError}
                  showLngError={this.showLongError}
                  hideLatError={this.hideLatError}
                  hideLngError={this.hideLongError}
                />
              </Card>
            </div>) :
            <Card className="NoRadius">
              <Typography variant="body1">{this.props.translate('geoTarget.addRadius')}</Typography>
            </Card>
          }
        </TabPanel>
        <TabPanel value={this.state.selectedTab} index={1}>
          <Card className="PlaceEditor">
            <PlaceEditor
              places={this.props.places}
              deletePlace={this.props.deletePlace}
              updatePlace={this.props.updatePlace}
              addPendingPlace={this.props.addPendingPlace}
              addCodeToQueue={this.props.addCodeToQueue}
            />
          </Card>
        </TabPanel>
      </div>
    )
  }
}

export default withLocalize(Editor)
