import * as React     from 'react'
import ObjectUtils    from "../../../../utils/ObjectUtils"
import NumFormatter   from '../../../../utils/NumberFormatter'

interface TotalRowProps{
  periodValues  : PeriodValues[]
  currentPeriod : string
  periodKey     : string
}

interface PeriodValues{
  period : string
  values : {[summableKey:string]:number}[]
}

const getTotals = (periodValues:PeriodValues[],periodKey:string) => {
  let totalOfTotals = {}
  let totals =  periodValues
  .reduce((a,current)=>{
    let x = a.find(v=>v.period===current.period)
    let b = {}
    for(let key in current){
      if(key===periodKey){b[periodKey] = current[key]}
      else{
        b[key] = x?x[key]+current[key]:current[key]
        totalOfTotals[key] = totalOfTotals[key]?totalOfTotals[key]+current[key]:current[key]
      }
    }
    return [...a.filter(v=>v.period!==current.period),b]
  },[])
  totals.push(totalOfTotals)
  return totals
}

const TotalRow = (props:TotalRowProps)=>{
  return (
    <tr>
      <td>Total</td>
      {getTotals(props.periodValues,props.periodKey).map(values=>(
        <td key={values.period || "TOTAL"} className = {"TotalByMonth amount"+(values.period==props.currentPeriod ? " CurrentMonth" : "")}>
          {ObjectUtils.getObjectValues(
            ObjectUtils.mapOnObject(values,(a,b)=>a==props.periodKey?"IGNORE_IT":b)
          )
          .filter(x=>x!=="IGNORE_IT")
          .map((t,i)=><div key={i}>{NumFormatter.formatCurrency(t)}</div>
          )}
        </td>
      ))}
    </tr>
  )
}

export default TotalRow
