import * as React       from "react"
import {connect}        from "react-redux"
import retrieveCleaner  from "../../actions/cleaner/cleaner"
import ExplainedLoading from "../loading"
import ErrorBoundary    from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  const accountId = state.Accounts.selected
  return {
    accountId,
    makes      : state.Cleaner.Makes[accountId],
    makeModels : state.Cleaner.MakeModels[accountId],
    reference  : state.Cleaner.Reference[accountId],
    retrieving : state.Cleaner.Retrieving,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadCleaner : (accountId)=>dispatch(retrieveCleaner(accountId))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  const {accountId, ...rest} = SP
  return {
    ...rest,
    ...DP,
    loadCleaner : ()=>DP.loadCleaner(accountId),
    ...ownProps,
  }
}
const CleanerLoader = props => {
  const {makes,makeModels,reference,retrieving} = props
  let loading = false
  if(reference === undefined){
    loading = true
    if(retrieving===false){props.loadCleaner()}
    if(!props.letThrough){
      return <ExplainedLoading translateId="loadings.cleanerData"/>
    }
  }
  return (
    <ErrorBoundary>
      {props.render({
        cleanerMakes      : makes,
        cleanerMakeModels : makeModels,
        cleanerReference  : reference,
        loadingCleaner    : loading
      })}
    </ErrorBoundary>
  )
}
const CleanerLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(CleanerLoader)

export default (letThrough?:boolean) => Component => props => (
  <CleanerLoaderConnected
    letThrough={letThrough || false}
    render={cleanerContent => <Component {...cleanerContent} {...props} />}
  />
)
