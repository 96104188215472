import './MinimalistButton.css'
import * as React from "react"

interface MinimalistButtonProps{
  text     : string
  subtitle : string
  onClick  : ()=>void
  displayAlert ?: boolean
}

const MinimalistButton = (props:MinimalistButtonProps) => {
  return (
    <div className="MinimalistButton" onClick={props.onClick}>
      <div className="Alert" style={props.displayAlert ? {backgroundColor: '#d3450d'} : {}}></div>
      <div className="Text">
        {props.text}
      </div>
      <div className="Subtitle">
        {props.subtitle}
      </div>
    </div>
  )
}

export default MinimalistButton
