import './index.css'
import * as React                         from "react"
import {connect}                          from "react-redux"
import {withLocalize}                     from "react-localize-redux"
import {fetchTemplates, createTemplate}   from "../../actions/ReportTableTemplate/ReportTableTemplate"
import TemplatesAccessors                 from "../../storeAccessor/Templates"
import ReportTableTemplateContexts        from "../../model/constant/ReportTableTemplateContexts"
import ReportTableTemplate                from "../../model/Store/Report/ReportTableTemplate"
import {Panel}                            from "../../components/Panel/Panel"
import {Tabs, Tab}                        from "carbon-components-react"
import {Loading}                          from "carbon-components-react"
import Report                             from "./component/Report"
import Notice                             from "../../components/alert/Notice"
import {Provider}                         from "../../model/Provider"
import { selectDefaultTemplate }          from "../../actions/user/userPreferences"
import { DefaultReportViewPreferences }   from "../../model/report/DefaultReportViewPreferences"
import { withRouter }                     from "react-router"
import { AdsTabs }                        from "../../model/report/AdsTabs"
import StringUtils                        from "../../utils/StringUtils"

interface ReportPageProps{
  templates : {
    account  : ReportTableTemplate[]
    ad       : ReportTableTemplate[]
    budget   : ReportTableTemplate[]
    campaign : ReportTableTemplate[]
    keyword  : ReportTableTemplate[]
  }
  areTemplatesFetched              : boolean
  defaultViewGoogleCampaigns       : string
  defaultViewGoogleKeywords        : string
  history                          : History
  activeLanguage                  ?: any
  tab                             ?: AdsTabs
  fetchTemplates                   : ()=>void
  createTemplate                   : (template:ReportTableTemplate)=>void
  selectDefaultTemplate            : (preferenceName: DefaultReportViewPreferences, templateId: string)=>void
  translate                       ?: (translateId:string)=>string
}

const makeMapStateToProps = () => {
  const templatesSelector = TemplatesAccessors.makeTemplatesSelector(Provider.AW)
  return (state) => {
    return {
      templates                     : templatesSelector(state),
      areTemplatesFetched           : state.Reports.Templates.isFetched,
      defaultViewGoogleCampaigns    : state.Login.userLogged.preferences[DefaultReportViewPreferences.googlecampaigns],
      defaultViewGoogleKeywords     : state.Login.userLogged.preferences[DefaultReportViewPreferences.googlekeywords],
      userId                        : state.Login.userLogged.profile.id,
    }
  }
}
const mapDispatchToProps = dispatch => {
    return {
      fetchTemplates         : ()=>dispatch(fetchTemplates()),
      createTemplate         : (template:ReportTableTemplate)=>dispatch(createTemplate(template)),
      selectDefaultTemplate  : (userId: number, preferenceName: DefaultReportViewPreferences, templateId: string)=>dispatch(selectDefaultTemplate(userId, preferenceName, templateId))
    }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    selectDefaultTemplate  : (preferenceName: DefaultReportViewPreferences, templateId: string)=>DP.selectDefaultTemplate(SP.userId, preferenceName, templateId)
  }
}
interface ReportPageState{
  selectedTab               : number
  selectedTemplateId        : {[context:string]:string}
  selectedDefaultTemplateId : {[context:string]:string}
}
class ReportPage extends React.Component<ReportPageProps,ReportPageState>{
  currentURL : string
  location   : string
  constructor(props){
    super(props)
    this.state = {
      selectedTab : 0,
      selectedTemplateId : {
        campaigns : props.defaultViewGoogleCampaigns,
        keywords  : props.defaultViewGoogleKeywords
      },
      selectedDefaultTemplateId: {
        campaigns : props.defaultViewGoogleCampaigns,
        keywords  : props.defaultViewGoogleKeywords
      }
    }
    this.currentURL = window.location.href
    this.location =  StringUtils.capitalizeFirstLetter(this.currentURL.substring(this.currentURL.lastIndexOf("/") + 1))
    this.selectTemplate = this.selectTemplate.bind(this)
  }
  UNSAFE_componentWillMount(){
    this.props.fetchTemplates()
  }
  selectTemplate(context, templateId: string){
    this.setState(prevState=>({
      selectedTemplateId : {
        ...prevState.selectedTemplateId,
        [context] : templateId
      }
    }))
  }
  selectDefaultTemplate(preferenceName: DefaultReportViewPreferences, context, templateId: string) {
    this.setState(prevState=>({
      selectedDefaultTemplateId : {
        ...prevState.selectedDefaultTemplateId,
        [context] : templateId
      }
    }))

    this.props.selectDefaultTemplate(preferenceName, templateId)
  }
  handleTabSelect(tabIndex: number, path: string) {
    this.currentURL = window.location.href

    if (Object.values(AdsTabs).includes(AdsTabs[this.location])) {
      window.history.pushState("", "", this.currentURL.substring(0, this.currentURL.lastIndexOf("/")) + path);
    }
    else {
      window.history.pushState("", "", this.currentURL + path);
    }
    this.location =  StringUtils.capitalizeFirstLetter(window.location.href.substring(window.location.href.lastIndexOf("/") + 1))
    this.setState({selectedTab: tabIndex})
  }
  assignTabFromProps(tab: AdsTabs) {
    if (Object.values(AdsTabs).includes(AdsTabs[this.location]) && this.location !== tab) {
      return
    }
    if (tab === AdsTabs.Campaigns && this.state.selectedTab !== 0)
      this.setState({selectedTab: 0})
    if (tab === AdsTabs.Keywords && this.state.selectedTab !== 1)
      this.setState({selectedTab: 1})
  }

  render(){
    this.props.tab && this.assignTabFromProps(this.props.tab)
    if(!this.props.areTemplatesFetched){return <Loading/>}
    return (
      <Panel title={this.props.translate("reports.ads.title")} subtitle={this.props.translate("reports.ads.subtitle")}>
        {this.props.activeLanguage.code === "fr"
          ? <Notice text="La traduction des colonnes des tables de cette section est à venir"/>
          : <></>
        }
        <Tabs className="TabsContainer" selected={this.state.selectedTab}>
          <Tab className="Tab" label={this.props.translate("reports.ads.campaign")} onClick={()=>this.handleTabSelect(0, "/Campaigns")}>
            {this.state.selectedTab === 0
              ? <Report
                  templates={this.props.templates.campaign}
                  selectedTemplateId={this.state.selectedTemplateId["campaigns"]}
                  selectTemplate={(templateId:string)=>this.selectTemplate("campaigns", templateId)}
                  context={ReportTableTemplateContexts.CAMPAIGN}
                  provider={Provider.AW}
                  createTemplate={(template:ReportTableTemplate)=>this.props.createTemplate(template)}
                  defaultTemplateId={this.state.selectedDefaultTemplateId.campaigns}
                  selectDefaultTemplate={ (templateId: string) => this.selectDefaultTemplate(DefaultReportViewPreferences.googlecampaigns, "campaigns", templateId)}
                />
              : <></>
            }
          </Tab>
          <Tab className="Tab" label={this.props.translate("reports.ads.keyword")} onClick={()=>this.handleTabSelect(1, "/Keywords")}>
            {this.state.selectedTab === 1
              ? <Report
                  templates={this.props.templates.keyword}
                  selectedTemplateId={this.state.selectedTemplateId["keywords"]}
                  selectTemplate={(templateId:string)=>this.selectTemplate("keywords", templateId)}
                  context={ReportTableTemplateContexts.KEYWORD}
                  provider={Provider.AW}
                  createTemplate={(template:ReportTableTemplate)=>this.props.createTemplate(template)}
                  defaultTemplateId={this.state.selectedDefaultTemplateId.keywords}
                  selectDefaultTemplate={ (templateId: string) => this.selectDefaultTemplate(DefaultReportViewPreferences.googlekeywords, "keywords", templateId)}
                />
              : <></>
            }
          </Tab>
          {/*
          <Tab label={"Budget"} onClick={()=>this.setState({selectedTab:2})}>
            <Report
              templates={this.props.templates.budget}
              context={ReportTableTemplateContexts.BUDGET}
              createTemplate={(template:ReportTableTemplate)=>this.props.createTemplate(this.props.token, template)}
            />
          </Tab>
          */}
        </Tabs>
      </Panel>
    )
  }
}

export default (
  withLocalize(
    connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
      withRouter(
        ReportPage
      )
    )
  )
)
