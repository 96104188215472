import Actions                     from "../../model/constant/actions"
import Friend                      from "../../model/Store/Campaign/Friend"
import ObjectUtils                 from "../../utils/ObjectUtils"
import {getToken}                  from "../../storeAccessor/Generic"
import ActionUtils                 from "../../utils/ActionUtils"
import Headstone                   from "../../utils/Headstone"
import { AxiosResponse }           from "axios"
import { GeoBidExceptions }        from "../../model/headstone/geoBids"
import { isRadius, isPlace }       from "../../utils/GeoBidUtils"

function createRadius(radius){
  return {
    lat         : radius.lat,
    lng         : radius.long,
    radius      : radius.radius,
    bidModifier : radius.bidModifier
  }
}
function createPlace(place){
  return {
    code        : place.code,
    include     : place.include,
    bidModifier : place.bidModifier
  }
}
export default (friend:Friend) => (dispatch, getState) => {
  dispatch({type:Actions.RETRIEVING_DESIRED_GEOBID_EXCEPTIONS})
  ActionUtils.retryOnFailure(
    ()=>Headstone.getGeoBidExceptionsForCampaignNames(getToken(getState()), friend.getAccountId(), friend.getCampaignNames().join(","))
  )
    .then((response) => dispatch(loadDesiredGeoBidExceptionsSuccess(response, friend)))
    .catch((error) => dispatch(loadDesiredGeoBidExceptionsError(error)))
}
const loadDesiredGeoBidExceptionsSuccess = (response: AxiosResponse<GeoBidExceptions[]>, friend: Friend) => {
  let places = {}
  let radius = {}

  for(let campaignName of friend.getCampaignNames()){
    for (let obj of response.data) {
      if(obj.campaignName === campaignName){
        for(let geo of obj.geos){
          if(isRadius(geo)){
            radius[""+geo.lat+geo.long+geo.radius+geo.bidModifier] = createRadius(geo)
            continue
          }
          if(isPlace(geo)){
            places[(geo.include?"include":"exclude")+geo.code] = createPlace(geo)
            continue
          }
        }
      }
    }
  }

  return {
    type      : Actions.RETRIEVE_DESIRED_GEOBID_EXCEPTIONS,
    friend    : friend,
    places    : ObjectUtils.getObjectValues(places),
    radiuses  : ObjectUtils.getObjectValues(radius)
  }
}
const loadDesiredGeoBidExceptionsError = (error) => {
  return {
    type   : Actions.RETRIEVE_DESIRED_GEOBID_EXCEPTIONS,
    failed : true
  }
}
