export default class ObjectUtils {

  // Cookie Expected to be something lioke this "Key1=Value1;key2=value2"
  public static cookieToObj(cookie) {
    let array = cookie.split(';')
    var result = {}
     for (var i = 0; i < array.length; i++) {
      var cur = array[i].trim().split('=')
      result[cur[0]] = cur[1];
    }
    return result
  }
  public static deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
}
