import Pacy                                              from "../../utils/Pacy"
import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import {PacingRatioConfig}                               from "../../model/pacy/PacingRatioConfig"
import {getToken}                                        from "../../storeAccessor/Generic"

const extractMessageFromError = (error:any) => {
  if(error.response && error.response.data){
    return error.response.data.error
  }
}

export const updatePacingRatio = (accountId:number, pacingRationConfig:PacingRatioConfig) => (dispatch, getState) => {
  dispatch({type: Actions.SAVING_PACY_RATIO, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Pacy.updatePacingRatio(token, accountId, pacingRationConfig)
  )
  .then(response=>{
    dispatch({
      type              : Actions.UPDATE_PACY_RATIO,
      pacingRatioConfig : response.data,
      accountId,
    })
    dispatch({type: Actions.PACY_CLEAR, accountId})
    dispatch({type: Actions.SAVING_PACY_RATIO, status: false})
  })
  .catch((error)=>{
    console.warn(error)
    dispatch({
      type   : Actions.SAVING_PACY_RATIO,
      status : false,
      error  : extractMessageFromError(error)||true,
    })
  })
}
