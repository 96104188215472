import axios, {Method}            from "axios"

export type GetAccountsReturn = Array<{
  id                : number
  name              : string
  currency          : string
  timezone          : string
  website           : string
  street            : string
  city              : string
  province          : string
  country           : string
  zipCode           : string
  latitude          : number
  longitude         : number
  AWCustomerId      : number
  bingAccountId     : number
  facebookAccountId : number
}>

export type UserReturn = {
  id          : number
  firstName   : string
  lastName    : string
  officePhone : string
  cellPhone   : string
  email       : string
  locale      : string
}

export default class Auth2 {
  protected static getApiUrl(){
    return process.env.REACT_APP_AUTH2_URL
  }
  public static doRequest<T>(method:Method, timeout:number, endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    const url = ("/"+endpoint).replace("//","/")
    return axios.request<T>({
      method,
      baseURL : Auth2.getApiUrl(),
      url     : url,
      params  : queryParams,
      data    : body,
      headers : {
        ...headers,
        Authorization : `Bearer ${token}`
      },
      timeout
    })
  }
  public static get<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Auth2.doRequest<T>("get", 10*1000, endpoint, token, queryParams, body, headers)
  }
  public static post<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Auth2.doRequest<T>("post", 60*1000, endpoint, token, queryParams, body, headers)
  }
  public static put<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Auth2.doRequest<T>("put", 60*1000, endpoint, token, queryParams, body, headers)
  }
  public static patch<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Auth2.doRequest<T>("patch", 60*1000, endpoint, token, queryParams, body, headers)
  }

  public static getAccounts(token:string) {
    return Auth2.get<GetAccountsReturn>("/Accounts", token)
  }

  public static getUser(token: string, accountId: number) {
    return Auth2.get<UserReturn>("/Users/" + accountId, token)
  }

  public static setUserLocale(token: string, userId: number, locale: string) {
    return Auth2.patch<UserReturn>(`/Users/${userId}`, token, {}, {locale: locale} )
  }
}
