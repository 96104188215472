import * as React             from "react"
import {connect}              from "react-redux"
import {getBudgetPlans}       from "../../actions/BudgetsPlan"
import ExplainedLoading       from "../loading"
import ErrorBoundary          from "../Error/ErrorBoundary"

const mapStateToProps = (state,ownProps) => {
  const accountId = state.Accounts.selected
  return {
    accountId           : accountId,
    fetchingBudgetPlans : state.Params.BudgetsPlans.RetrievingBudgetPlans,
    budgetPlans         : state.Params.BudgetsPlans.BudgetsPlanned[accountId] || [],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadBudgetPlans : (accountId:number)=>dispatch(getBudgetPlans(accountId)),
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    loadBudgetPlans : () => DP.loadBudgetPlans(SP.accountId),
  }
}
const BudgetPlansLoader = props => {
  if(props.budgetPlans === undefined){
    if(!props.fetchingBudgetPlans){props.loadBudgetPlans()}
    return <ExplainedLoading translateId="loadings.budgetPlans"/>
  }
  return <ErrorBoundary>{props.render(props.budgetPlans)}</ErrorBoundary>
}
const BudgetPlansLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(BudgetPlansLoader)

const requiresBudgetPlans = Component => props => (
  <BudgetPlansLoaderConnected
    render={ budgetPlans => <Component budgetPlans={budgetPlans} {...props} /> }
  />
)

export default requiresBudgetPlans
