import ReducerUtils        from "../../utils/ReducerUtils"
import ArrayUtils          from "../../utils/ArrayUtils"
import Actions             from "../../model/constant/actions"
import {Decision}          from "../../model/pacy/Decision"
import {StringMap}         from "../../model/generics"

const save = (state:State, action):State => {
  const accountId = action.accountId
  if(!state[accountId]){
    return {
      ...state,
      [accountId] : action.decisions
    }
  }
  return {
    ...state,
    [accountId] : ArrayUtils.mergeBySerialization(state[accountId], action.decisions, (i:Decision)=>i.id)
  }
}

const clear = (state:State, action):State => {
  if(!state[action.accountId]){return state}
  return {
    ...state,
    [action.accountId] : undefined
  }
}

interface State{
  [accountId:number] : Decision[]
}
const Decisions = ReducerUtils.createReducer<State>({},{
  [Actions.RETRIEVE_PACY_DECISIONS] : save,
  [Actions.PACY_CLEAR] : clear
})

export default Decisions
