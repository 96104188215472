import * as React                       from "react"
import {connect}                        from "react-redux"
import {HashRouter,Route,Redirect}      from "react-router-dom"
import {Switch}                         from "react-router-dom"
import RoutingUtils                     from "../utils/RoutingUtils"
import {MarketingEvent}                 from "../model/adbuilder"
import Permissions                      from "../model/constant/Permissions"
import requiresMarketingEvents          from "../components/loaders/marketingEventsLoader"
import requiresAWSearchAudiences        from "../components/loaders/AWSearchAudiencesLoader"
import requireCleaner                   from "../components/loaders/cleanerLoader"
import requiresStrategies               from "../components/loaders/strategiesLoader"
import Loading                          from "../components/loading/"
import AlertModal                       from "../components/Modals/Alert"
import readOnlyInjector                 from "../components/permissions/readOnlyInjector"
import requiresPermission               from "../components/context/permissionContext/requiresPermission"
import AdBuilder                        from "../page/adBuilder"
import MarketingEventSetup              from "../page/adBuilder/components/MarketingEventSetup"

interface AdBuilderRoutesOwnProps{
  accountId : number
  history
}
interface AdBuilderRoutesProps extends AdBuilderRoutesOwnProps{
  savingElement            : boolean
  deletingElement          : boolean
  marketingEvents          : MarketingEvent[]
  failedSaving             : boolean
  loadingCleaner           : boolean
  loadingAWAudiences       : boolean
  loadingMarketingEvents   : boolean
  urlOnSaved              ?: string
  failedMessage           ?: string
  readOnly                ?: boolean
}

const mapStateToProps = (state,ownProps)=>{
  return {
    savingElement            : state.AdBuilder.Saving.status,
    deletingElement          : state.AdBuilder.Deleting,
    failedSaving             : state.AdBuilder.FailedSaving.failed,
    failedMessage            : state.AdBuilder.FailedSaving.message,
    urlOnSaved               : state.AdBuilder.Saving.urlOnSuccess,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {}
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

interface AdBuilderRoutesState{
}

class adBuilderRoutes extends React.Component<AdBuilderRoutesProps, AdBuilderRoutesState>{
  constructor(props){
    super(props)
    this.state = {
    }
  }
  componentDidUpdate(prevProps:AdBuilderRoutesProps, prevState:AdBuilderRoutesState){
    if(prevProps.savingElement && !this.props.savingElement){ //just finished saving
      if(this.props.failedSaving){
        //If saving failed, display an error
        const message = this.props.failedMessage || "We encountered an error while saving"
        alert(message)
      }
      else if(this.props.urlOnSaved){
        RoutingUtils.setUrl(this.props.history, this.props.urlOnSaved) //if url was given, redirect to it on success
      }
    }
  }
  render(){
    //check if data is loading
    if(this.props.loadingMarketingEvents){return <Loading translateId="loadings.marketingEvents"/>}
    if(this.props.loadingAWAudiences){return <Loading translateId="loadings.AWSearchAudiences"/>}
    if(this.props.loadingCleaner){return <Loading translateId="loadings.cleanerData"/>}

    //check if saving/deleting
    if(this.props.savingElement){return <Loading translateId="loadings.saving"/>}
    if(this.props.deletingElement){return <Loading translateId="loadings.deleting"/>}

    const redirectPath="/Account/"+this.props.accountId+"/Adbuilder/"
    return (
      <div>
        <HashRouter>
          <Switch>
            <Route exact path="*/AdBuilder/"
              render={(props)=>(
                <AdBuilder
                  events={this.props.marketingEvents}
                  history={props.history}
                  readOnly={this.props.readOnly}
                />
              )}
            />
            <Route exact path="*/AdBuilder/create"
              render={(props)=>(
                <MarketingEventSetup
                  key="create"
                  history={props.history}
                  readOnly={this.props.readOnly}
                />
              )}
            />
            <Route exact path="*/AdBuilder/:eventId"
              render={(props)=>(
                <MarketingEventSetup
                  key={props.match.params.eventId}
                  history = {props.history}
                  eventId = {parseInt(props.match.params.eventId, 10)}
                  readOnly={this.props.readOnly}
                />
              )}
            />
            <Redirect to={redirectPath}/>
          </Switch>
        </HashRouter>
        <AlertModal
          open={this.props.readOnly}
          title={"adBuilder.readOnlyModal.title"}
          contentText={"adBuilder.readOnlyModal.content"}
          buttons={["adBuilder.readOnlyModal.button1"]}
          translate
        />
      </div>
    )
  }
}

export default (
  requiresPermission(Permissions.Keystone_ViewAdBuilder)(
    requiresMarketingEvents(true)(
      requiresAWSearchAudiences(true)(
        requireCleaner(true)(
          requiresStrategies(true)(
            readOnlyInjector([Permissions.Keystone_ChangeAdCopies])(
              connect(mapStateToProps,mapDispatchToProps,mergeProps)(adBuilderRoutes)
            )
          )
        )
      )
    )
  )
)
