import './AdsEdit.css'
import * as React                                   from 'react'
import Tags                                         from './Tags/Tags'
import AdCopies                                     from './AdCopies'
import {
  Card,
  Icon,
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Modal,
  LinearProgress,
  Typography,
}                                                   from '@material-ui/core'
import AdCopyEditor                                 from '../../../components/adCopy/AdCopyEditor'
import { Translate }                                from 'react-localize-redux'
import { connect }                                  from 'react-redux'
import { getAccountId }                             from '../../../storeAccessor/Generic'
import { saveAdCopyPatterns }                       from '../../../actions/branding/AdGroupConfigs'
import { AudienceAd }                               from '../../../model/branding/AudienceAd'
import ArrayUtils                                   from '../../../utils/ArrayUtils'
import { AdTemplate }                               from '../../../model/adbuilder'
import Tester                                       from './Tester'
import Preview                                      from './Preview'
import AdCopiesList                                 from "../AudienceAdsSelection"
import { AdGroupConfigsByName }                     from "../../../model/branding/AdGroupConfigs"
import { defaultErrors }                            from '../../../components/input/LengthLimitedTextInput'

interface AdsEditOwnProps {
  audienceAd                     : AudienceAd
  lang                           : string
  adGroupConfigsByName          ?: AdGroupConfigsByName
  save                          ?: boolean
  enableSave                    ?: boolean
  updateAudienceAd              ?: (lang:string,template:any) => void
  initialErrors                 ?: boolean//For Creation mode.
  setErrors                     ?: any
  saveFunction                  ?: ()=>void
  initialImport                 ?: boolean
}
interface AdsEditProps extends AdsEditOwnProps {
  accountId : number
  saveAdCopyPatterns : (accountId:number,audienceId:number,adCopyPatterns:AdTemplate[],lang:string) => void
}
const mapStateToProps = (state,props) => {
  const accountId = getAccountId(state)
  return {
    accountId
  }
}
const mapDispatchToProps = dispatch => {
  return {
    saveAdCopyPatterns : (accountId:number,audienceId:number,adCopyPatterns:AdTemplate[],lang:string) =>
      dispatch(
        saveAdCopyPatterns(accountId,audienceId,adCopyPatterns,lang)
      )
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
  }
}

const AdsEdit = (props:AdsEditProps) => {
  const [timeout,setStateTimeout] = React.useState(null)
  const [openPreview,setOpenPreview] = React.useState(false)
  const [passing,setPassing] = React.useState(0)
  const [failing,setFailing] = React.useState(0)
  const [errors,setErrors] = React.useState(
    props.initialErrors || props.audienceAd.templates[0].headlines[0]==="" ? [-1,-2,-3] : []
  )
  const [newAdCopyId,setNewAdCopyId] = React.useState(0)
  const [openDelete,setOpenDelete] = React.useState(false)
  const [openReset, setOpenReset] = React.useState(false)
  const [templates,setTemplates] = React.useState(props.audienceAd.templates)
  const [selectedTemplateId,setSelectedTemplateId] = React.useState(templates[0].id)
  const [openAdCopiesList,setOpenAdCopiesList] = React.useState(props.initialImport || false)
  const tags = ['<_month>','<month>','<make>','<name>','<city>']
  const selectedTemplate = templates.find(template=>template.id === selectedTemplateId)

  function updateAudienceAd() {
    props.updateAudienceAd(props.lang,templates)
  }
  if(props.setErrors){
    updateErrors()
  }
  React.useEffect(()=>{
    setTemplates(props.audienceAd.templates)
  },[props.audienceAd])
  React.useEffect(()=>{
    if(props.updateAudienceAd){
      if(timeout) {
        clearTimeout(timeout)
      }
      setStateTimeout(prevState=>{
        return setTimeout(updateAudienceAd,200)
      })
    }
  },[templates])
  function updateErrors() {
    if(errors.length!==0) {
      props.setErrors(prevState=>{
        if(prevState.indexOf(props.lang)===-1) {return [...prevState,props.lang]}
        return prevState
      })
    }
    if(errors.length===0) {
      props.setErrors(prevState=>{
        if(prevState.indexOf(props.lang)!==-1){
          return prevState.filter(err=>err!==props.lang)
        }
        return prevState
      })
    }
  }
  function handleClick(event) {
    setSelectedTemplateId(parseInt(event.currentTarget.id,10))
  }
  function onHeadlineUpdate(index:number,text:string){
    setTemplates(prevState=>{
      return prevState.map(template=>{
        if(template.id === selectedTemplateId) {
          const temp = {
            ...template,
            descriptions:[...template.descriptions],
            headlines:[...template.headlines]
          } as AdTemplate
          temp.headlines[index] = text
          return temp
        }
        return template
      })
    })
  }
  function onDescriptionUpdate(index:number,text:string){
    setTemplates(prevState=>{
      return prevState.map(template=>{
        if(template.id === selectedTemplateId) {
          const temp = {
            ...template,
            descriptions:[...template.descriptions],
            headlines:[...template.headlines]
          } as AdTemplate
          temp.descriptions[index] = text
          return temp
        }
        return template
      })
    })
  }
  function onErrorChange(newErrors:string[]) {
    if(newErrors.length !==0 && errors.indexOf(selectedTemplateId) === -1){
      if( !(newErrors.length === 1 && newErrors[0] === defaultErrors.OVERLIMIT) ) {
        setErrors(prev=>[...prev,selectedTemplateId ])
      }
    }
    else if(newErrors.length===0 || (newErrors.length === 1 && newErrors[0] === defaultErrors.OVERLIMIT) ) {
      setErrors(prev=>prev.filter(id=>id!==selectedTemplateId))
    }
  }
  function deleteSelectedTemplate() {
    setTemplates(prevState=>prevState.filter(el=>el.id !== selectedTemplateId))
    const temp =  templates.filter(el=>el.id !== selectedTemplateId)
    setSelectedTemplateId(
      temp[templates.indexOf(selectedTemplate)-1] === undefined
        ? temp[0].id
        : temp[templates.indexOf(selectedTemplate)-1].id
    )
    setErrors(prevState=>prevState.filter(id=>id!==selectedTemplateId))
  }
  function createNewAdCopy() {
    if(templates.length<=50) {
      setTemplates(prevState=>{
        return [{id:newAdCopyId,headlines:[],descriptions:[]},...prevState] as any
      })
      setSelectedTemplateId(newAdCopyId)
      setNewAdCopyId(prevState => prevState+1)
    }
    else {
      alert('You cannot create more than 50 AdCopies')
    }
  }
  function compareFields(initialHeadlines,currentHeadlines) {
    for(let i=0;i<initialHeadlines.length;i++) {
      if(ArrayUtils.isSameArray(initialHeadlines[i].filter(Boolean),currentHeadlines[i].filter(Boolean))===false) {
        return false
      }
    }
    return true
  }
  function compareChanges(){
    const initialHeadlines = props.audienceAd.templates.map(template=>template.headlines)
    const initialDescriptions = props.audienceAd.templates.map(template=>template.descriptions)
    const currentHeadlines = templates.map(template=>template.headlines)
    const currentDescriptions = templates.map(template=>template.descriptions)
    if(props.audienceAd.templates.length === templates.length) {
      return compareFields(initialHeadlines,currentHeadlines) && compareFields(initialDescriptions,currentDescriptions)
    }
    return false
  }
  //DIALOG HANDLERS
  function handleOpenAdCopiesList () {
    setOpenAdCopiesList(true)
  }
  function handleCloseAdCopiesList () {
    setOpenAdCopiesList(false)
  }
  function handleOpenPreview() {
    setOpenPreview(true)
  }
  function handleClosePreview() {
    setOpenPreview(false)
  }
  function handleCloseDelete() {
    setOpenDelete(false)
  }
  function handleCloseReset() {
    setOpenReset(false)
  }
  function handleClickOpenDelete(){
    setOpenDelete(true)
  }
  function handleClickOpenReset() {
    setOpenReset(true)
  }
  function handleReset(){
    setTemplates(props.audienceAd.templates)
    setSelectedTemplateId(props.audienceAd.templates[0].id)
    setErrors([])
  }
  function adCopiesListSave(templates){
    setTemplates(templates)
    setSelectedTemplateId(templates[0].id)
    handleCloseAdCopiesList()
    setErrors([])
  }
  return (
    <div className="AdsEditContainer">
      <div style={{height:20}}>
        {passing === 0 && failing === 0 && <span><LinearProgress style={{width:100}} color="primary"/></span>}
        <Tester
          passing={passing}
          failing={failing}
          lang={props.lang}
          adCopy={selectedTemplate}
          setPassing={setPassing}
          setFailing={setFailing}
        />
      </div>
      <div className="AdsEdit">
        <div className="AdCopies">
          <div className="AdCopiesCards">
            <AdCopies
              templates={templates}
              selectedTemplateId={selectedTemplateId}
              setSelectedTemplate={handleClick}
            />
          </div>
          <Card onClick={createNewAdCopy} className="Add">
            <p className="Icon"><Icon fontSize="large">add_circle_outline</Icon></p>
            <p><Translate id="adCopies.createAdCopy" /></p>
          </Card>
        </div>
        <div className="Form">
          <AdCopyEditor
            lang={props.lang}
            key={selectedTemplateId}
            headlines={selectedTemplate.headlines}
            descriptions={selectedTemplate.descriptions}
            onHeadlineUpdate={onHeadlineUpdate}
            onDescriptionUpdate={onDescriptionUpdate}
            showErrorText
            tags={tags}
            onErrorChange={onErrorChange}
            textLengthCalculation={(text)=>text.trim().length}
          />
          { templates.length >3 &&
            <div className="Delete">
              <Button variant ="contained" onClick={handleClickOpenDelete} disabled={templates.length<=3} color="secondary">
                <Icon style={{marginLeft:-10,marginRight:5}}>delete</Icon>
                <Translate id="common.delete" />
              </Button>
            </div>
          }
        </div>
        <div className="Tags">
          <Tags tags={tags} />
        </div>
      </div>
      <CardActions className="Footer">
        <div>
          <Typography className="Tip" variant="caption"><Icon className="Icon">emoji_objects</Icon><Translate id="branding.descriptions.adsTip" /></Typography>
        </div>
        <div className="Buttons">
          <Button color="primary" onClick={handleOpenAdCopiesList} size="large"><Translate id="common.import" /></Button>
          <Button onClick={handleClickOpenReset} variant="outlined" size="large" color="secondary"><Translate id="common.reset" /></Button>
          {props.save ?
            <Button
              onClick={props.saveFunction}
              style={{color:'white'}}
              color="primary"
              variant="contained"
              size="large"
              disabled={!props.enableSave || false}
            >
              <Translate id="common.save" />
            </Button>
            :<Button
              disabled={errors.length!==0}
              onClick={handleOpenPreview}
              style={{color:'white'}}
              variant="contained" size="large" color="primary"
            >
            <Translate id="branding.identityVariantsTester.review" />
          </Button>}
        </div>
      </CardActions>
      <Dialog
        open={openReset}
        onClose={handleCloseReset}
        aria-labelledby="reset-dialog-title"
        aria-describedby="reset-dialog-description"
      >
        <DialogTitle id="reset-dialog-title"><Translate id="common.reset"/></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Translate id="branding.resetMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReset} color="secondary" autoFocus>
            <Translate id="common.cancel" />
          </Button>
          <Button onClick={()=>{handleCloseReset();handleReset()}} color="primary">
            <Translate id="common.reset"/>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title"><Translate id="common.delete"/></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Translate id="branding.deleteMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="secondary" autoFocus>
            <Translate id="common.cancel" />
          </Button>
          <Button
            onClick={()=>{deleteSelectedTemplate();handleCloseDelete()}}
            color="primary"
          >
            <Translate id="common.delete"/>
          </Button>
        </DialogActions>
      </Dialog>
     {!props.save && <Modal className="PreviewModal" open={openPreview} onClose={handleClosePreview}>
        <Card className="Preview">
          <Preview
            lang={props.lang}
            closeModal={handleClosePreview}
            accountId={props.accountId}
            audienceAd={props.audienceAd}
            compareChanges={compareChanges}
            saveAdCopyPatterns={props.saveAdCopyPatterns}
            templates={templates}
          />
        </Card>
      </Modal>}
      <Modal className="PreviewModal" open={openAdCopiesList} onClose={handleCloseAdCopiesList}>
        <div className="AdCopiesListModal">
          <AdCopiesList
            lang={props.lang}
            adGroupConfigsByName={props.adGroupConfigsByName}
            saveFunction={adCopiesListSave}
            closeFunction={handleCloseAdCopiesList}
          />
        </div>
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(AdsEdit) as React.ComponentType<AdsEditOwnProps>
