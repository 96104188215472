import * as React from "react"
import {ResponsiveContainer,LineChart,XAxis,YAxis,Tooltip,Line as RechartLine, LineType} from "recharts"

interface LineGraph2Props{
  data           : DataPoint[]
  lines          : Line[]
  height         : string|number
  width          : string|number
  doubleYAxis   ?: boolean
  renderTooltip ?: any
  hideXAxis     ?: boolean
  hideXAxisTicks?: boolean
}
export interface DataPoint{
  xValue : string|number
  [lineDataKey:string] : any//number?
}
export interface Line{
  name : string
  dataKey : string
  color : string
  type ?: LineType
  axis ?: "left"|"right"
  strokeWidth ?: number
  strokeDasharray ?: string
  showDot ?: boolean
}

const LineGraph2 = (props:LineGraph2Props) => {
  return (
    <ResponsiveContainer width={props.width} height={props.height} >
      <LineChart data={props.data} margin={{top: 20, right: 5, left: 5, bottom: 0}}>
        <XAxis
          dataKey="xValue"
          hide={props.hideXAxis||false}
          tick={props.hideXAxisTicks!==undefined?!props.hideXAxisTicks:undefined}
        />
        <YAxis yAxisId="left" />
        {props.doubleYAxis && <YAxis yAxisId="right" orientation="right"/>}
        <Tooltip content={props.renderTooltip||undefined} isAnimationActive={false}/>
        {props.lines.map(x=>
          <RechartLine
            key={x.dataKey}
            yAxisId={x.axis||"left"}
            type={x.type||"linear"}
            dataKey={x.dataKey}
            stroke={x.color}
            fill={x.color}
            dot={x.showDot!==undefined?x.showDot:true}
            activeDot={{r: 6}}
            strokeWidth={x.strokeWidth||1}
            strokeDasharray={x.strokeDasharray||""}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineGraph2
