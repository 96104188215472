import './Overview.css'
import * as React                from "react"
import {connect}                 from "react-redux"
import {createSelector}          from "reselect"
import SearchTermsTable          from "./component/SearchTermsTable"
import DualGraph                 from "../../../shared/DualGraph"
import KPIs                      from "../../../shared/KPIs"
import SimpleMap                 from "../../../shared/SimpleMap"
import Friend                    from "../../../../../../model/Store/Campaign/Friend"
import {getLangs}                from "../../../../../../utils/localisation/Localisation"
import ObjectUtils               from "../../../../../../utils/ObjectUtils"
import DateUtils                 from '../../../../../../utils/DateUtils'
import StatisticsUtils           from '../../../../../../utils/StatisticsUtils'
import Statistics                from "../../../../../../storeAccessor/Statistics"
import PieChartOverview          from "../../../shared/PieChartOverview"
import {Provider}                from "../../../../../../model/Provider"

interface OverviewProps{
  friend          : Friend
  currentData     : any
  symmetricalData : any
  geoExceptions   : any
  period          : any
  changeView      : (view:string)=>void
}

const makeMapStateToProps = () => {
  const campaignsStatsSelector = Statistics.makeCampaignsStatsInCurrentAndSymmetricalPeriodSelector()
  const compiledFriendStatsSelector = createSelector(
    campaignsStatsSelector,
    (_,friend) => friend,
    (state:any) => state.Period,
    (campaignsStats, friend, period)=>{
      if(campaignsStats === undefined){return undefined}
      if(campaignsStats.length === 0){
        return {
          currentData     : [],
          symmetricalData : [],
        }
      }
      const campaignNameSimplificationRegex = new RegExp(`(\\s\\||\\|)|(\\s(` +getLangs().join('|')+ `))`, "g")
      const simplifyCampaignName = (campaignName:string):string => {
        return campaignName.replace(campaignNameSimplificationRegex, "")
      }
      const friendName = friend.getName()
      const compiledByDay = ObjectUtils.getObjectValues(
        ObjectUtils.mapOnObject(
          campaignsStats.reduce((byDay, stat)=>{
            if(!stat.Name || friendName !== simplifyCampaignName(stat.Name)){return byDay}
              const cleanedStat = {
                Day                   : stat.Day,
                Clicks                : stat.Clicks,
                Conversions           : stat.Conversions,
                Cost                  : stat.Cost,
                Impressions           : stat.Impressions,
                SearchImpressionShare : stat.SearchImpressionShare || 0,
                SearchClickShare      : stat.SearchClickShare || 0,
              }
              if(!byDay[cleanedStat.Day]){
                byDay[cleanedStat.Day] = {
                  Day                   : cleanedStat.Day,
                  Clicks                : 0,
                  Conversions           : 0,
                  Cost                  : 0,
                  Impressions           : 0,
                  ImpressionShareData   : [],
                  ClickShareData        : [],
                }
              }
              byDay[cleanedStat.Day].Clicks      += cleanedStat.Clicks
              byDay[cleanedStat.Day].Conversions += cleanedStat.Conversions
              byDay[cleanedStat.Day].Cost        += cleanedStat.Cost
              byDay[cleanedStat.Day].Impressions += cleanedStat.Impressions
              byDay[cleanedStat.Day].ImpressionShareData.push([
                cleanedStat.Impressions,
                cleanedStat.SearchImpressionShare
              ])
              byDay[cleanedStat.Day].ClickShareData.push([
                cleanedStat.Clicks,
                cleanedStat.SearchClickShare
              ])
              return byDay
          },{}),
          (day, stats)=>({ //Compile SearchImpressionShare after grouping by day
            ...stats,
            SearchImpressionShare : StatisticsUtils.calculateImpressionShare(stats.ImpressionShareData),
            SearchClickShare      : StatisticsUtils.calculateClickShare(stats.ClickShareData),
            CPC                   : StatisticsUtils.calculateCPC(stats.Cost, stats.Clicks)
          })
        )
      ).sort((a,b)=> -(+new Date(b.Day) - +new Date(a.Day))) //for graph
      const symmetricalPeriod = DateUtils.getSymmetricalPeriod(period)
      return {
        currentData     : StatisticsUtils.filterToPeriod(period, "Day", compiledByDay),
        symmetricalData : StatisticsUtils.filterToPeriod(symmetricalPeriod, "Day", compiledByDay)
      }
    }
  )
  return (state, ownProps) => {
    const geoExceptions = (
      state.Campaigns.DesiredGeoExceptions[state.Accounts.selected]
      ? state.Campaigns.DesiredGeoExceptions[state.Accounts.selected][ownProps.object.getName()]
      : undefined
    )
    return {
      friend          : ownProps.object,
      geoExceptions   : geoExceptions,
      period          : state.Period,
      ...compiledFriendStatsSelector(state, ownProps.object),
    }
  }
}
const mapDispatchToProps = (dispatch) => ({})
const Overview = (props:OverviewProps) => {
  return(
    <div className="Overview">
      <div className="OverviewElement Graph">
        <DualGraph
          data={props.currentData}
          primaryColor="#00b1e4"
          secondaryColor="#3cba54"
          period={props.period}
          wantedMetrics={["Impressions","Clicks","Cost","CPC","Conversions","SearchImpressionShare","SearchClickShare"]}
        />
      </div>
      <KPIs data={props.currentData} symmetricalData={props.symmetricalData} wantedKPIs={["clicks","cpc","cost","impressionShare"]}/>
      <div className="MapAndRapport">
        <div className="OverviewElement">
          <SimpleMap
            accountId={props.friend.getAccountId()}
            radiuses={props.friend.getRadiuses()}
            places={props.friend.getPlaces()}
            ids={props.friend.getExternalIds(Provider.AW)}
            onClick={()=>props.changeView("Geo")}
            geoExceptions={props.geoExceptions}
          />
        </div>
        <div className="OverviewRapport">
          <PieChartOverview campaignAWIds={props.friend.getExternalIds(Provider.AW)}/>
        </div>
      </div>
      <div className="OverviewElement">
        <SearchTermsTable campaignNames={props.friend.getCampaignNames()} friend={props.friend}/>
      </div>
    </div>
  )
}

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Overview)
