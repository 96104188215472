import Keystone   from "../../utils/Keystone"
import FVDUtils   from "../../utils/FieldsValuesDefinitionUtils"
import ArrayUtils from "../../utils/ArrayUtils"
import Actions    from "../../model/constant/actions"
import {getToken} from "../../storeAccessor/Generic"
import {Provider} from "../../model/Provider"

const retrieveCampaignsBaseInfo = (accountId:number, campaignAWIds?:number[], loadDuplicates:boolean=false) => (dispatch, getState) => {
  const state = getState()
  const payload:any = {
    accountId : accountId,
    action    : "GetCampaignsBaseInfo",
    token     : getToken(state),
  }
  if(campaignAWIds && campaignAWIds.length > 0){
    const uniqueIds = ArrayUtils.unique(campaignAWIds)
    let ids
    if(loadDuplicates){
      ids = uniqueIds
    }
    else{
      const alreadyRetrieved = Object.keys(state.Campaigns.BaseInfo.ById[accountId])
      ids = uniqueIds.filter(id=>!ArrayUtils.contain(alreadyRetrieved, id))
    }
    payload.campaignAWIds = ids
  }
  dispatch({
    type   : Actions.RETRIEVING_CAMPAIGNS_BASE_INFO,
    status : true
  })
  Keystone.get(
    payload,
    (response) => {
      const info = FVDUtils.decompress(response.data.campaignsInfo)
      .map(x=>{
        x.Provider=Provider.AW
        return x
      })
      const infoById = info.reduce((byId, info)=>{byId[info.AWId]=info;return byId},{})
      dispatch({
        type      : Actions.RETRIEVE_AW_CAMPAIGNS_BASE_INFO,
        accountId : accountId,
        info,
        infoById
      })
    },
    (error) => console.log(error)
  )
  .catch((e)=>{
    console.error(e)
    dispatch({
      type   : Actions.FAILED_RETRIEVING_CAMPAIGNS_BASE_INFO,
      status : true
    })
  })
  .then(()=>dispatch({
    type   : Actions.RETRIEVING_CAMPAIGNS_BASE_INFO,
    status : false
  }))
}

export default retrieveCampaignsBaseInfo
