import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"

interface StatusByAccount{
  [accountId:string] : boolean
}
const AlreadyMigrated = ReducerUtils.createReducer<StatusByAccount>({},{
  [Actions.PACY_MIGRATION_ALREADY_MIGRATED] : (state,action)=>({...state, [action.accountId]: action.status})
})

export default AlreadyMigrated
