import * as React                     from "react"
import {connect}                      from "react-redux"
import ExplainedLoading               from "../loading"
import ErrorBoundary                  from "../Error/ErrorBoundary"
import GeoBidAccessors                from "../../storeAccessor/GeoBid"


const makeMapStateToProps = () => {
  const geoSelector = GeoBidAccessors.makeUniqueGeoWithoutLangSelector()
  return (state) => {
    const accountId = state.Accounts.selected
    return {
      geoTargetings        : geoSelector(state),
      accountId            : accountId,
      retrievingTargetings : state.Params.GeoBids.Retrieving,
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
  }
}
const geoTargetingLoader = props => {
  if(props.retrievingTargetings && props.letThrough === false) {
    return <ExplainedLoading translateId="loadings.geoTargeting"/>
  }
  return <ErrorBoundary>{props.render(props.geoTargetings, props.retrievingTargetings)}</ErrorBoundary>
}
const GeoTargetingsLoaderConfig = connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(geoTargetingLoader)

interface gegeoTargetingingsLoaderConfig {
  letThrough : boolean
}
interface geoTargetingGivenConfig {
  letThrough ?: boolean
}
const baseConfig : gegeoTargetingingsLoaderConfig = {
  letThrough : false
}

const requiresGeoTargetings = (givenConfig:geoTargetingGivenConfig={}) => Component => props => {
  const config : gegeoTargetingingsLoaderConfig = {...baseConfig, ...givenConfig}
  return <GeoTargetingsLoaderConfig
    config = {config}
    render = { (geoTargetingConfigs, loading) => <Component geoTargetings={geoTargetingConfigs} geoTargetingsLoading={loading} {...props} /> }
  />
}
export default requiresGeoTargetings
