import './SoldCarsKPIs.css'
import * as React                from 'react'
import {Translate}               from "react-localize-redux"
import NumFormatter              from '../../utils/NumberFormatter'
import KPI                       from '../../components/card/KPI'

interface SoldCarsKPIsProps{
  compiledCount
  vehiclesCount
  companyName : string
}

const SoldCarsKPIs = (props:SoldCarsKPIsProps) =>
  <div className='SoldCarsTableKPIs'>
    <Translate>{({translate})=>
      <>
        <KPI
          key='db'
          amount={NumFormatter.formatNumber(props.compiledCount, 2)}
          units={""}
          title={translate("inventoryTurn.KPIs.visited")}
          subtitle={translate("inventoryTurn.KPIs.DBTraffic", {company: props.companyName})}
        />
        <hr/>
        <KPI
          key='all'
          amount={NumFormatter.formatNumber(props.vehiclesCount, 2)}
          units={""}
          title={translate("inventoryTurn.KPIs.sold")}
          subtitle={<i>{translate("inventoryTurn.KPIs.estimated")}</i>}
          subtitleFirst
        />
      </>
    }</Translate>
  </div>

export default SoldCarsKPIs
