import "./UnusedPossibleAgentSelect.css"
import * as React               from "react"
import {
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Button,
  CardContent,
  Icon,
  Dialog,
}                               from "@material-ui/core"
import MaxSharingSlider         from "../MaxSharingSlider"
import { ProxyGroup }           from "../../../../../../model/pacy/ProxyGroup"
import BoostConfig              from "../BoostConfig"
import {
  PossibleAgent
}                               from "../../../../../../model/pacy"
import {
  Translate,
  withLocalize,
  TranslateFunction
}                               from "react-localize-redux"

interface UnusedPossibleAgentSelectOwnProps {
  selectedProviderId : string
  possibleAgents     : PossibleAgent[]
  proxyGroups        : ProxyGroup[]
  onSelect           : (possibleAgent:PossibleAgent)=>void
  disabled          ?: boolean
  hideLabel         ?: boolean
}
interface UnusedPossibleAgentSelectProps extends UnusedPossibleAgentSelectOwnProps {
  translate : TranslateFunction
}

const UnusedPossibleAgentSelect = (props:UnusedPossibleAgentSelectProps) => {
  return (
    <div className="UnusedPossibleAgentSelect">
      <Select
        variant="outlined"
        fullWidth
        onChange={(e)=>{
          const value = e.target.value
          const chosen = props.possibleAgents.find(x=>x.providerId === value)
          props.onSelect(chosen)
        }}
        value={props.selectedProviderId}
        disabled={props.disabled}
      >
        {props.possibleAgents.map(possibleAgent=>
          <MenuItem key={possibleAgent.providerId} value={possibleAgent.providerId}>{possibleAgent.name}</MenuItem>
        )}
      </Select>
      <FormHelperText style={{color:props.selectedProviderId ? "gray" : "red", visibility: props.hideLabel ? "hidden" : "visible"}}>
        <Translate id={"pacyConfigs.selectPossibleAgent"+props.possibleAgents[0].provider} />
      </FormHelperText>

    </div>
  )
}

export default withLocalize(UnusedPossibleAgentSelect as any) as React.ComponentType<UnusedPossibleAgentSelectOwnProps>
