import './PolygonWithTooltip.css'
import * as React  from 'react'
import {Polygon}   from "react-google-maps"

interface PolygonWithTooltipProps{
  tooltipContent    : string
  defaultDraggable ?: boolean
  defaultEditable  ?: boolean
  defaultOptions   ?: any
  defaultPath      ?: any
  defaultPaths     ?: any
  defaultVisible   ?: boolean
  draggable        ?: boolean
  editable         ?: boolean
  options          ?: any
  path             ?: any
  paths            ?: any
  visible          ?: boolean
  onDblClick       ?: (e)=>void
  onDragEnd        ?: (e)=>void
  onDragStart      ?: (e)=>void
  onMousedown      ?: (e)=>void
  onMouseMove      ?: (e)=>void
  onMouseOut       ?: (e)=>void
  onMouseOver      ?: (e)=>void
  onMouseUp        ?: (e)=>void
  onRightClick     ?: (e)=>void
  onClick          ?: (e)=>void
  onDrag           ?: (e)=>void
}

let tipObj = null
let eventPropertyNameToAccessMousePos

const offsetY = 20
const offsetX = 20
function injectTooltip(event, tooltipContent){
  if(!tipObj && event){
    if(!eventPropertyNameToAccessMousePos || eventPropertyNameToAccessMousePos.length === 0){
      eventPropertyNameToAccessMousePos = getEventPropertyNameToAccessMousePos(event)
    }
    //create the tooltip object
    tipObj = document.createElement("div");
    tipObj.className = "PolygonTooltip"
    tipObj.innerHTML = tooltipContent;

    //position it
    tipObj.style.position = "fixed";
    tipObj.style.top = event[eventPropertyNameToAccessMousePos].clientY + offsetY + "px";
    tipObj.style.left = event[eventPropertyNameToAccessMousePos].clientX + offsetX + "px";

    //add it to the body
    document.body.appendChild(tipObj);
  }
}
function moveTooltip(event) {
  if(tipObj && event){
    //position it
    tipObj.style.top = event[eventPropertyNameToAccessMousePos].clientY + offsetY + "px";
    tipObj.style.left = event[eventPropertyNameToAccessMousePos].clientX + offsetX + "px";
  }
}
function deleteTooltip(event) {
  if(tipObj){
    //delete the tooltip if it exists in the DOM
    document.body.removeChild(tipObj);
    tipObj = null;
  }
}
function getEventPropertyNameToAccessMousePos(event):string{
  //The key to access `clientX` and `clientY` changes randomly, so we have to find it programmatically
  for(let key of Object.keys(event)){
    if(MouseEvent.prototype.isPrototypeOf(event[key])){
      if(event[key].clientX !== undefined){
        return key
      }
    }
  }
}

const PolygonWithTooltip = React.forwardRef((props:PolygonWithTooltipProps, ref:any) => {
  const onMouseOver = (e) => {
    injectTooltip(e, props.tooltipContent)
    if(props.onMouseOver){
      props.onMouseOver(e)
    }
  }
  const onMouseMove = (e) => {
    moveTooltip(e)
    if(props.onMouseMove){
      props.onMouseMove(e)
    }
  }
  const onMouseOut = (e) => {
    deleteTooltip(e)
    if(props.onMouseOut){
      props.onMouseOut(e)
    }
  }
  const {tooltipContent, ...polygonProps} = props
  return (
    <Polygon
      ref={ref}
      {...polygonProps}
      onMouseOver={onMouseOver}
      onMouseMove={onMouseMove}
      onMouseOut={onMouseOut}
    />
  )
})
export default PolygonWithTooltip
