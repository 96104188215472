import * as React                     from "react"
import {connect}                      from "react-redux"
import Actions                        from "../../model/constant/actions"
import Car                            from "../../model/Store/Vehicle/Car"
import StringUtils                    from "../../utils/StringUtils"
import ExplainedLoading               from "../../components/loading"
import ErrorBoundary                  from "../../components/Error/ErrorBoundary"
import requiresNavigations            from "../../components/loaders/navigationsLoader"


function compareUrl(url:string, url2:string) : boolean {
  /*
    The amount of calls to this is in the hundreds of millions or worse.
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
  */
  if(url.length>url2.length){return false}
  return url2.includes(url)
}
const backgroundCompile = (vehicles:any[],navigations:any[]) => dispatch => {
  setTimeout(function(){
    const begin = (new Date()).getTime()/1000
    dispatch({
      type         : Actions.BACKGROUND_COMPILE_SOLD_VEHICLES,
      soldVehicles : compile(vehicles,navigations),
      timeTaken    : (new Date()).getTime()/1000 - begin,
    })
  },1)
}
const compile = (vehicles, navigations) => {
  let navigationsFromDB = []
  let navigationsNotFromDB = []
  for(let nav of navigations){
    if(nav.fromDB){navigationsFromDB.push(nav)}
    else{navigationsNotFromDB.push(nav)}
  }
  const soldVehiclesShown = vehicles
  .reduce((final, v) => {
    const url = StringUtils.getPath(v.getUrl())
    let foundFromDB = []
    let url2 = ""
    for(let i=0;i<navigationsFromDB.length;i++){
      url2 = navigationsFromDB[i].simplePath
      if(url.length <= url2.length && url2.includes(url)){
        foundFromDB.push(navigationsFromDB.splice(i,1)[0])
        i--
      }
    }
    if(foundFromDB.length === 0){return final}
    let foundNotFromDB = []
    for(let i=0; i<navigationsNotFromDB.length; i++){
      url2 = navigationsNotFromDB[i].simplePath
      if(url.length <= url2.length && url2.includes(url)){
        foundNotFromDB.push(navigationsNotFromDB.splice(i,1)[0])
        i--
      }
    }
    final.push({
      vehicle : v,
      url : url,
      navigationsFromDB : foundFromDB,
      navigationsNotFromDB : foundNotFromDB
    })
    return final
  }, [])
  return soldVehiclesShown
}

const mapStateToProps = (state)=>{
  const accountId = state.Accounts.selected
  const webInfos = state.Tracking.Web[accountId]
  return {
    vehicles      : state.Inventory.list[accountId],
    period        : state.Period,
    compiled      : state.Tracking.SoldVehicles,
    compiling     : state.Tracking.SoldVehiclesCompiling,
  }
}
const mapDispatchToProps = (dispatch) => ({
  backgroundCompiling : (compiling:boolean)=>dispatch({type : Actions.BACKGROUND_COMPILING_SOLD_VEHICLES, compiling:compiling}),
  backgroundCompile : (vehicles,navigations)=>dispatch(backgroundCompile(vehicles,navigations))
})
const mergeProps = (SP,DP,ownProps)=>{
  const {token,...fromState} = SP
  return {
    ...fromState,
    ...DP,
    ...ownProps
  }
}
const InventoryTurnCompilationLoader = props => {
  if(props.vehicles === undefined){return <ExplainedLoading translateId="loadings.inventory"/>}
  const vehicles = props.vehicles
  .filter(v=>(
    v instanceof Car && // Extend
    v.getRemovedOn()!==null &&
    v.getRemovedOn()>=props.period.dateFrom &&
    v.getRemovedOn()<=props.period.dateTo
  ))
  if(!props.compiled && !props.compiling){
    props.backgroundCompiling(true)
    props.backgroundCompile(vehicles, props.navigations)
    return <ExplainedLoading translateId="loadings.compiling"/>
  }
  if(!props.compiled){return <ExplainedLoading translateId="loadings.compiling"/>}
  props.backgroundCompiling(false)
  return <ErrorBoundary>{props.render(props.compiled, vehicles)}</ErrorBoundary>
}
const InventoryTurnCompilationLoaderConnected = requiresNavigations(connect(mapStateToProps,mapDispatchToProps,mergeProps)(InventoryTurnCompilationLoader))

const requiresInventoryTurnCompilation = Component => props => (
  <InventoryTurnCompilationLoaderConnected render = {
    (compiled,vehicles) =>  (
      <Component
        compiled={compiled}
        vehicles={vehicles}
        {...props}
      />
    )}
  />
)
export default requiresInventoryTurnCompilation
