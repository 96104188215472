import * as React           from "react"
import {withLocalize}       from "react-localize-redux"

interface DefaultSortedManagerProps{
  dataFields                  : string[]
  originalDefaultSortedColumn : string
  editedDefaultSortedColumn   : string
  setDefaultSortedColumn      : (dataField:string)=>void
  originalDefaultSortedOrder  : string
  editedDefaultSortedOrder    : string
  setDefaultSortedOrder       : (order:string)=>void
  translate                  ?: (translateId:string)=>string
}
const DefaultSortedManager = (props:DefaultSortedManagerProps) => {
  return (
    <div className="DefaultSortedManager">
      <select
        onChange={(e)=>props.setDefaultSortedColumn(e.currentTarget.value)}
        defaultValue={props.originalDefaultSortedColumn}
      >
        {props.dataFields.map((dataField,i)=>
          <option key={i} value={dataField}>
            {dataField}
          </option>
        )}
      </select>
      <select
        onChange={(e)=>props.setDefaultSortedOrder(e.currentTarget.value)}
        defaultValue={props.originalDefaultSortedOrder}
      >
        <option value="asc">
          {props.translate("reports.ads.editor.asc")}
        </option>
        <option value="desc">
          {props.translate("reports.ads.editor.desc")}
        </option>
      </select>
    </div>
  )
}

export default withLocalize(DefaultSortedManager as any) as any
