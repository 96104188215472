import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adCopyEditor : {
      display: "grid",
      gridTemplateColumns: "1.2fr 1.5fr 1fr",
      gridColumnGap : 20,
      alignItems: "center"
    },
    form : {
      paddingTop: 20,
      alignSelf : "start"
    },
    tags : {
      width : 300,
      display : "flex",
      flexWrap : "wrap",
      margin: "10px 0px 0px 100px",
      [theme.breakpoints.down("lg")] : {
        margin: "10px 0px 0px 10px",
      }
    },
    delete : {
      display: "flex",
      justifyContent: "flex-start",
      marginLeft: 10,
      marginTop: 10
    },
    adcopies : {
      paddingTop : 20,
      alignSelf : "start"
    },
    adCopiesCards : {
      paddingBottom: 1,
      alignSelf : "start"
    },
    add : {
      cursor: "pointer",
      width: "100%",
      minHeight: 80,
      marginTop: 20,
      display: "flex",
      alignItems: "center",
      color: "#797878",
      '&:hover' : {
        color: "#333333",
      }
    },
    addLabel : {
      fontSize : 14,
      fontWeight:"bold",
    },
    icon : {
      marginTop: 5,
      paddingLeft: 10,
      marginRight: 10,
    },
    footer : {
      display: "flex",
      gap: "20px",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 20,
      position: "sticky",
      width: "100%",
    },
    copier : {
      marginTop : 50,
      width : 300,
      margin: "10px 0px 0px 100px",
      [theme.breakpoints.down("lg")] : {
        margin: "10px 0px 0px 10px",
      }
    },
    tagsAndCopy : {
      alignSelf : "start",
    },
    tagTitle : {
      marginBottom : 30,
      fontWeight : "bold",
    },
    button : {
      fontFamily : "Roboto",
      backgroundColor : "#707070",
      color : "#FFFFFF",
    },
    selects : {},
    label : {},
    formControl : {
      width : 300,
    },
    adGroupsToCopy : {
      fontColor : "#70707099"
    },
    errorMessage : {
      color:"red", 
      textAlign:"center"
    }
  })
)

export default useStyles
