import * as React               from "react"
import {connect}                from "react-redux"
import Place                    from "../../../../model/Store/GeoBid/Place"
import Radius                   from "../../../../model/Store/GeoBid/Radius"
import {GeoStatsCompiledForMap} from "../../../../model/Store/Statistics/GeoStats"
import {MapDisplayOnly}         from "../../../../components/Map/MapDisplayOnly"
import Statistics               from "../../../../storeAccessor/Statistics"
import * as Generic             from "../../../../storeAccessor/Generic"
import MapUtils                 from "../../../../utils/MapUtils"


interface SimpleMapOwnProps{
  accountId     : number
  radiuses      : Radius[]
  places        : Place[]
  ids           : (string|number)[]
  onClick       : ()=>void
  geoExceptions : any
}
interface SimpleMapProps extends SimpleMapOwnProps{
  heatmapStats : GeoStatsCompiledForMap
  cache        : any
  metacache    : any
}
const makeMapStateToProps = () => {
  const statsSelector = Statistics.makeCompiledForMapGeoBidStatsSelector(
    (state,ownProps:SimpleMapOwnProps) => (row,index) => {
      if(!row.Clicks){return false}
      const baseInfosById = Generic.getCampaignsBaseInfoById(state)
      const accountId     = Generic.getAccountId(state)
      const campaignAWIds = ownProps.ids.map(x=>x+"")
      const campaignNames = campaignAWIds.reduce((acc,AWId)=>{
        const baseInfo = baseInfosById[accountId][AWId]
        if(baseInfo){acc.push(baseInfo.Name)}
        return acc
      },[])
      return (
        campaignNames.indexOf(row.Name)!==-1 ||
        campaignAWIds.indexOf(row.CampaignAWId+"")!==-1
      )
    }
  )
  return (state, ownProps)=>{
    return {
      cache        : Generic.getGeocodingCache(state),
      metacache    : Generic.getGeocodingMetaCache(state),
      heatmapStats : {//statsSelector(state, ownProps), // deactivated for performance since not used lower
        codesWithoutGPS : [],
        clicksOnAdWords : []
      },
    }
  }
}
interface SimpleMapProps{
  accountId     : number
  radiuses      : Radius[]
  places        : Place[]
  ids           : (string|number)[]
  onClick       : ()=>void
  geoExceptions : any
  stats         : GeoStatsCompiledForMap
}
const SimpleMap = (props:SimpleMapProps) => {
  const identifier = props.ids.join(",")
  let geoTargeting = {
    accountId : props.accountId,
    matcher   : {group:identifier, lang:identifier},
    radiuses  : props.radiuses,
    places    : props.places,
  }
  if(props.geoExceptions != undefined){
    geoTargeting = {
      ...geoTargeting,
      ...props.geoExceptions
    }
  }
  const heatmapData = props.heatmapStats.clicksOnAdWords && MapUtils.buildHeatmapData(
    props.heatmapStats.clicksOnAdWords,
    props.cache,
    props.metacache
  )

  return (
    <div className="OverviewMap">
      <MapDisplayOnly
        geoTargeting={geoTargeting}
        onClick={props.onClick}
        circleOptions={{fillColor:"#01B9F5", strokeColor:"#012B74", strokeWeight:"1"}}
        mapSize={460}
        // heatmapData is not displayed because it's a very bad UX right now, would need a lot more work
        // heatmapData={heatmapData}
      />
    </div>
  )
}
export default connect(makeMapStateToProps)(SimpleMap)
