import Actions              from "../../../model/constant/actions"
import ReducerUtils         from "../../../utils/ReducerUtils"
import ArrayUtils           from "../../../utils/ArrayUtils"

const Fetching = ReducerUtils.createReducer<boolean>(false,{
  [Actions.RETRIEVING_STRATEGIES] : ()=>true,
  [Actions.RETRIEVE_STRATEGIES]   : ()=>false
})

export default Fetching
