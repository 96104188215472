import Actions               from "../../model/constant/actions"
import ReducerUtils          from "../../utils/ReducerUtils"

const storeMessage = (state, action) => {
  return action.message
}

export const ForgotPasswordMessage = ReducerUtils.createReducer<string>(null,{
  [Actions.FORGOT_PASSWORD_RETRIEVED] : storeMessage,
})
