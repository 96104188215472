import axios                            from "axios"
import Actions                          from "../../model/constant/actions"
import {getToken}                       from "../../storeAccessor/Generic"
import Headstone                        from "../../utils/Headstone";

const getReplacementsSuccess = (response) => {
  if(response.error){return onError(response.error)}
  return {
    type : Actions.RETRIEVE_GRAMMAR_BOOST_REPLACEMENTS,
    replacements : response.data
  }
}
const onError = (error)=>({
  type : Actions.STORE_GRAMMAR_BOOST_ERROR,
  error : error
})
const retrieveReplacements = (accountId:number) => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_GRAMMAR_BOOST_REPLACEMENTS,
    status : true
  })
  axios.get(Headstone.getApiUrl() + `/${accountId}/GrammarBooster`, {
    headers : {
      Authorization : "Bearer "+getToken(getState())
    },
  })
  .then(response=>{
    dispatch(getReplacementsSuccess(response))
  })
  .catch(error=>{
    dispatch(onError(error))
  }).then(()=>{
    dispatch({
      type   : Actions.RETRIEVING_GRAMMAR_BOOST_REPLACEMENTS,
      status : false
    })
  })
}
export default retrieveReplacements
