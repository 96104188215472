import './MenuHeader.css'
import * as React from "react"
import { MenuToggle } from "../sidebar/MenuToggle"
import PeriodPicker from "../../../../components/date/PeriodPicker"

interface MenuHeaderProps {
  period: { dateFrom: Date, dateTo: Date }
  onUpdate: (dateFrom, dateTo) => void
  doToggle: () => void
}
class MenuHeader extends React.PureComponent<MenuHeaderProps, any>{
  render() {
    return (
      <div className='MenuHeader'>
        <div className='date-picker-container'>
          <PeriodPicker
            format={"Y-m-d"}
            dateFrom={this.props.period.dateFrom}
            dateTo={this.props.period.dateTo}
            updateDate={this.props.onUpdate}
          />
        </div>
        <div className={"showOnlyWhenMenuClosedOnMobile"}>
          <MenuToggle icon="menu" onClick={this.props.doToggle} className={"header-menu-toggle"} />
        </div>
      </div>
    )
  }
}

export default MenuHeader
