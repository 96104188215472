import './KeyValueTable.css'
import * as React from 'react'

interface KeyValueTableProps{
  title:string
  keyValue:{
    [key:string]:any
  }
}

const KeyValueTable = (props:KeyValueTableProps) => {
  const keys = Object.keys(props.keyValue)
  return (
    <div className='KeyValueTableWithTitle'>
      <h4>{props.title}</h4>
      <hr/>
      <div className='KeyValueTable'>
        {keys.map(key=>(
          <React.Fragment key={key}>
            <div className='key'>{key}</div>
            <div className='value'>{props.keyValue[key]}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default KeyValueTable
