import Actions                from "../../model/constant/actions"
import ReducerUtils           from "../../utils/ReducerUtils"
import User                   from "../../model/Store/Login/UserLogged"
import NumberFormatter        from "../../utils/NumberFormatter"


const loginSuccess = (state, action) => {
  NumberFormatter.setLocale(action.user.profile.locale)
  return {...state, ...action.user}
}
const setPreferences = (state, action) => {
  if(state === null || state.profile === undefined || action.userId != state.profile.id){
    return state
  }
  if(Array.isArray(action.preferences)){
    return {
      ...state,
      preferences : {}
    }
  }
  return {
    ...state,
    preferences : action.preferences
  }
}
const changeWhiteLabel = (state,action) => {
  return {
    ...state,
    preferences : {
      ...state.preferences,whitelabel:action.whiteLabel
    }
  }
}

const setLocale = (state, action) => {
  return {
    ...state,
    profile : {
      ...state.profile,
      locale: action.payload
    }
  }
}


const defaultState = {
  permissions        : [],
  preferences        : {},
  token              : "",
  profile            : undefined,
  defaultAccountPage : "/Dashboard"
}


export const UserLogged = ReducerUtils.createReducer<User>(defaultState,{
  [Actions.AUTHENTIFY_LOGIN_SUCCESS]         : loginSuccess,
  [Actions.DUMP_USER]                        : ()=>null,
  [Actions.RETRIEVE_USER_PREFERENCES]        : setPreferences,
  [Actions.DEV_CHANGE_WHITELABEL_DEV]        : changeWhiteLabel,
  [Actions.SET_USER_LOCALE]                  : setLocale
})
