import "./DesignedTooltip.css"
import * as React     from "react"
import {withStyles}   from "@material-ui/core/styles"
import Tooltip        from "@material-ui/core/Tooltip"

const CustomTooltip = withStyles({
  tooltip : {
    backgroundColor: "white",
    color: "#3c4858",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
    borderRadius: "0px",
    padding: "0px",
    transform: "scale(1) translateZ(0px) translateY(100%) !important",
  },
  popper : {
    opacity: 1,
  }
})(Tooltip)

interface DesignedTooltipProps{
  text : string
  arrowColor : string
  disabled ?: boolean
  children
}

const DesignedTooltip = (props:DesignedTooltipProps) => {
  return (
    <CustomTooltip
      title={<Content text={props.text} arrowColor={props.arrowColor}/>}
      placement="left-end"
      open={props.disabled ? false : undefined}
    >
      {props.children}
    </CustomTooltip>

  )
}
const Content = ({text, arrowColor}) => (
  <div className="DesignedTooltip">
    {text}
    <div className="CornerArrow" style={{borderRightColor:arrowColor, borderTopColor:arrowColor}}/>
  </div>
)

export default DesignedTooltip
