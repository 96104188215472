import "./ModalContent.css"
import * as React from "react"
import Loading    from "../../../components/loading/"

interface ModalContentProps{
  migrating        : boolean
  done             : boolean
  alreadyMigrated  : boolean
  error           ?: string
}

const ModalContent = (props:ModalContentProps) => {
  if(props.migrating){
    return <Loading translateId="loadings.migrating"/>
  }
  if(props.done){
    return <div>Done</div>
  }
  if(props.error){
    return <div>{props.error}</div>
  }
  if(props.alreadyMigrated){
    return (
      <div>
        <p>This account is already migrated but was not enabled.</p>
        <p>
          The account must be migrated again, resetting the account to what is currently set in the Budget planner.
        </p>
        <br/>
        <p>Do you want to continue?</p>
      </div>
    )
  }
  return (
    <div>
      <p>You are about to migrate the account to the new Budget planner.</p>
      <br/>
      <p>
        Before going forward, make sure that all the amounts currently set are exactly
        what should be spent in the span of a month (i.e. remove any manual rollover)
      </p>
    </div>
  )
}

export default ModalContent
