import * as React           from 'react'
import BudgetPlan           from "../../../../model/Store/BudgetPlan/BudgetPlan"
import MonthlyBudgetStat    from "../../../../model/Store/Statistics/MonthlyBudgetsStats"
import Budget               from "../model/Budget"
import BudgetPlanRow        from "./BudgetPlan"
import YearMonthTableHeader from "./YearMonthTableHeader"
import TotalRow             from "./TotalRow"
import DateUtils            from "../../../../utils/DateUtils"
import ArrayUtils           from "../../../../utils/ArrayUtils"

interface BudgetPlannerGridProps{
  accountId         : number
  provider          : string
  year              : number
  month             : number
  monthlyBudgetStats: MonthlyBudgetStat[]
  ongoingBudgetStats: MonthlyBudgetStat[]
  budgetPlans       : BudgetPlan[]
  budgets           : Budget[]
  nbrMonths         : number
  isProviderActive  : boolean
  activeBudget      : Budget
  activeYear        : string
  activeMonth       : string
  selectPart        : (budget:Budget,year:string,month:string)=>void
  changedPartAmount : (budgetId:number,period:string,amount:number)=>void
}

const getNbColumnsForYears = (periods:string[])=>{
  const years = ArrayUtils.unique(periods.map((p)=>{return p.split("-")[0]}))
  return years.map((y)=>{
    let nbCol = periods.filter((p)=>{return p.split("-")[0]==y}).length
    return {nbCol:nbCol,year:y}
  })
}

const filterStatToBudget = (stat, budget) => {
  if(stat.BudgetId == budget.AWId){return true}
  if(stat.BudgetName === budget.budgetName){return true}
  if(!stat.BudgetName){return false}
  const a = stat.BudgetName.toLowerCase().replace("rmkt", "remarketing ")
  const b = budget.budgetName.toLowerCase().replace("rmkt", "remarketing ")
  return a===b
}
const BudgetPlannerGrid = (props:BudgetPlannerGridProps)=>{
  let currentDate = new Date()
  const currentMonth = DateUtils.leftPadMonth(currentDate.getUTCMonth()+1)
  const currentYear = currentDate.getUTCFullYear()
  const currentPeriod = currentYear+"-"+currentMonth
  let periods = []
  var totals = {}
  let sumPlanned = 0
  let sumSpent = 0
  var y
  var nbCol = 0
  var yearCells = []
  var monthCells = []
  for(let i=0;i<props.nbrMonths;i++){
    let month = props.month+i>12 ? props.month+i-12 : props.month+i
    let year = props.month+i>12 ? props.year+1 : props.year
    periods.push(year+"-"+DateUtils.leftPadMonth(month))
  }
  var periodValues = []
  return (
    <table className='table-bordered BudgetPlannerTable'>
      <YearMonthTableHeader
        leftColumns = {["Budget"]}
        rightColumns = {["Total"]}
        periods = {periods.map((p)=>({
            year  : p.split("-")[0],
            month : p.split("-")[1],
          })
        )}
      />
      <tbody>
        {props.budgets.map((budget, key)=>{
          const budgetPlan = props.budgetPlans.find((bp)=>{return bp.getBudgetId()==budget.budgetId})
          const monthlyStats = props.monthlyBudgetStats.filter((stat)=>filterStatToBudget(stat, budget))
          const ongoingStats = props.ongoingBudgetStats.filter((stat)=>filterStatToBudget(stat, budget))
          let spentByPeriod = {}
          for(let p of periods){
            let amount = 0
            let spent
            if(budgetPlan){
              let part = budgetPlan.getParts().find((part)=>part.getPeriod()==p)
              if(part){amount = part.getAmount()}
            }
            //if it's current month, look at ongoing stats
            if(p+"-01" === DateUtils.format(DateUtils.getCurrentMonth().dateFrom)){
              const currStat = ongoingStats.find(x=>x.Month===p)
              spent = currStat?currStat.Spent:undefined
            }
            else if(monthlyStats){
              const monthStats = monthlyStats.filter((s)=>s.Month===p)
              if(monthStats.length === 0){spent = undefined}
              else{spent = monthStats.reduce((total, stat)=>total+stat.Spent,0)}
            }
            periodValues.push({
              period : p,
              amount : amount,
              spent  : spent||0
            })
            spentByPeriod[p] = spent
          }
          return(
            <BudgetPlanRow
              key               = {key}
              periods           = {periods}
              spentByPeriod     = {spentByPeriod}
              budgetPlan        = {budgetPlan?budgetPlan:new BudgetPlan(props.accountId,budget.budgetId,props.provider,[])}
              budget            = {budget}
              isProviderActive  = {props.isProviderActive}
              activeBudget      = {props.activeBudget}
              activeYear        = {props.activeYear}
              activeMonth       = {props.activeMonth}
              currentMonth      = {currentMonth}
              currentYear       = {currentYear}
              currentPeriod     = {currentPeriod}
              selectPart        = {(year:string,month:string)=>props.selectPart(budget,year,month)}
              changedPartAmount = {(period:string,amount:number)=>props.changedPartAmount(budget.budgetId,period,amount)}
            />
          )
        })}
        <TotalRow
          periodValues  = {periodValues}
          currentPeriod = {DateUtils.getCurrentYearMonth()}
          periodKey     = "period"
        />
      </tbody>
    </table>
  )
}

export default BudgetPlannerGrid
