import './AccountsTable.css'
import * as React        from 'react'
import BootstrapTable    from 'react-bootstrap-table-next'
import filterFactory     from 'react-bootstrap-table2-filter'
import {textFilter}      from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import Account           from "../../../model/Store/Account/Account"
import CSVUtils          from "../../../utils/CSVUtils"
import {Button}          from "carbon-components-react"

interface AccountsTableProps {
  accounts       : Account[]
  userProfiles   : any[]
  allProfiles    : any[]
  onRowClick    ?: (row:Account)=>any
  showAdWordsId ?: boolean
  pageSize      ?: number
}
const defaultFilter = [{dataField:'id',order:'asc'}]

class AccountsTable extends React.Component<AccountsTableProps,any>{
  private table

  constructor(props){
    super(props)
    this.generateCSV = this.generateCSV.bind(this)
  }

  generateCSV(){
    const header = ["Name","Account ID","AdWords ID",]
    const dataFields = ['name','id','AWCustomerId',]
    const data = (
      this.table.store._filteredData.length>0
      ? this.table.store._filteredData
      : this.table.store._data
    )
    CSVUtils.downloadCSV(
      "accounts.csv",
      CSVUtils.generateCSV(
        header,
        data.map(row=>dataFields.map(key=>row[key])),
        ","
      )
    )
  }
  render(){
    const userProfiles = this.props.userProfiles.map(p=>({
      ...p,
      name : this.props.allProfiles.find(x=>x.code===p.code) !== undefined
      ? this.props.allProfiles.find(x=>x.code===p.code).name
      : ""
    }))
    const data = this.props.accounts.map(account=>({
      ...account,
      profiles : userProfiles.filter(x=>!x.accountId || x.accountId == account.id).map(x=>x.name)
    }))
    return <div className='AccountsDatatable'>
      <Button className="CSVButton" onClick={this.generateCSV}>CSV</Button>
      <BootstrapTable
        hover
        data={data}
        ref={table=>this.table = table}
        keyField='id'
        columns={[
          { dataField:'name',         text:"Name",         sort:true, filter:textFilter({delay:100})},
          { dataField:'id',           text:"Account ID",   sort:true, filter:textFilter({delay:100})},
          { dataField:'AWCustomerId', text:"AdWords ID",   sort:true, filter:textFilter({delay:100}), hidden:this.props.showAdWordsId===false},
          {
            dataField:"profiles",
            text:"Permission profiles",
            sort:true,
            filter:textFilter({delay:100}),
            formatter:(profiles,row)=>(
              profiles?
                <div style={{whiteSpace:"nowrap"}}>
                  {profiles.map(p=>
                    <div key={p}>{p}</div>
                  )}
                </div>
              :
                "None"
            )
          }
        ]}
        defaultSorted={defaultFilter}
        filter={filterFactory()}
        pagination={paginationFactory({
          hideSizePerPage: true,
          sizePerPage : this.props.pageSize!==undefined?this.props.pageSize:10
        })}
        classes={"normalSizedDatatable AccountsDataTable"}
        rowEvents={{
          onClick : (e,row,rowIndex)=> this.props.onRowClick ? this.props.onRowClick(row) : null
        }}
        rowStyle={{cursor:"pointer"}}
      />
    </div>
  }
}
export default AccountsTable
