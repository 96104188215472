import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    buttons : {
      display: "flex",
      gap : "20px",
      justifyContent : "center"
    },
    button : {
      fontFamily : "Roboto",
      backgroundColor : "#707070",
      color : "#FFFFFF",
    },
    errors: {
      backgroundColor: "#424242",
      color: "white",
      padding: "5px 10px",
      borderRadius: "5px",
      fontSize: "12px",
      display: "grid",
      gridRowGap: "2px"
    },
    tooltip : {
      "&:hover" :{
        cursor: "default"
      },
    },
    icon : {
      marginTop: "7px",
      color: "#ef0202",
    }
  })
)

export default useStyles
