import UserActions           from "../../model/constant/UserAction"
import retrieveDesiredStatus from "./retrieveDesiredStatusExceptions"
import LogBuilder            from "../../controller/log/LogBuilder"
import {getToken}            from "../../storeAccessor/Generic"
import Headstone             from "../../utils/Headstone"
import ActionUtils           from "../../utils/ActionUtils"

export default (accountId:number, campaignNames:string[]) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.REMOVE_STATUS_EXCEPTION,token,{
    accountId     : accountId,
    campaignNames : campaignNames
  })
  ActionUtils.retryOnFailure(
    ()=> Headstone.removeStatusException(token,accountId,campaignNames)
  )
  .then((response)=>{
    dispatch(retrieveDesiredStatus(accountId))
  })
  .catch((error)=>{
    console.log(error)
  })
}
