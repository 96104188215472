import {RestrictedLengthList} from '../generics'
import {RSAText}              from '../adcopy'

export enum AdTemplateType{
  HHD = "HHD"
}
export interface AdTemplate{
  id           ?: number
  headlines     : string[]
  descriptions  : string[]
}
export interface RSAAdTemplate{
  id            : number
  headlines     : RSAText[]
  descriptions  : RSAText[]
}
export enum AdBuilderCompatibleCampaignGroup {
  VDP     = "VDP",
  SRP_MMY = "SRP_MMY",
  SRP_MM  = "SRP_MM",
}
export enum AdBuilderCompatibleLang {
  FR = "FR",
  EN = "EN",
}
export enum AdBuilderCompatibleVehicleType {
  CAR = "CAR",
}
export enum AdBuilderCompatibleVehicleState {
  USED     = "USED",
  NEW      = "NEW"
}
export enum AdBuilderCompatibleNonInventoryCampaignType{
  BRANDING = "BRANDING",
  SERVICE  = "SERVICE",
  CREDIT   = "CREDIT"
}
export const AdBuilderCompatibleCampaignType = {...AdBuilderCompatibleVehicleState, ...AdBuilderCompatibleNonInventoryCampaignType}
export type AdBuilderCompatibleCampaignType = typeof AdBuilderCompatibleCampaignType
export type AdBuilderCompatibleGroup = AdBuilderCompatibleCampaignGroup | AdBuilderCompatibleNonInventoryCampaignType
type PermittedLengths = (1|2|3)
type NO_AUDIENCE = "NO_AUDIENCE"
export interface MarketingApproach{
  group        : AdBuilderCompatibleGroup|string
  lang         : AdBuilderCompatibleLang
  audienceAWId : NO_AUDIENCE|string
  patterns     : RestrictedLengthList<RSAAdTemplate,PermittedLengths>
  id          ?: number
}
export interface NonInventoryMarketingApproach extends MarketingApproach{
  adGroupName  : string
  campaignName : string
}  
export enum MarketingEventBoundariesTypes {
  SPECIFIC_CAMPAIGN = "SPECIFIC_CAMPAIGN",
  SPECIFIC_MMY      = "SPECIFIC_MMY",
  SPECIFIC_MM       = "SPECIFIC_MM",
  SPECIFIC_MY       = "SPECIFIC_MY",
  SPECIFIC_M        = "SPECIFIC_M",
  SPECIFIC_Y        = "SPECIFIC_Y",
  SPECIFIC_S        = "SPECIFIC_S",
  NON_INVENTORY     = "NON_INVENTORY"
}
interface YearSpecifying {year:number}
interface CleanerIdSpecifying {cleanerId:number}
interface CampaignSpecifying {campaignName : string}
interface CampaignTypeSpecifying {campaignType : AdBuilderCompatibleNonInventoryCampaignType}
export type providerType = "AW" | "BING"
interface MarketingEventBoundaries<TMarketingEventBoundariesType extends MarketingEventBoundariesTypes> {
  provider     : providerType
  type         : TMarketingEventBoundariesType
  vehicleType  : AdBuilderCompatibleVehicleType | null
  vehicleState : AdBuilderCompatibleVehicleState | null
}

export type NonInventoryMarketingEventBoundaries = 
  MarketingEventBoundaries<MarketingEventBoundariesTypes.NON_INVENTORY>
  & CampaignTypeSpecifying

export type CampaignSpecificMarketingEventBoundaries =
  MarketingEventBoundaries<MarketingEventBoundariesTypes.SPECIFIC_CAMPAIGN>
  & CampaignSpecifying

export type MMYSpecificMarketingEventBoundaries =
  MarketingEventBoundaries<MarketingEventBoundariesTypes.SPECIFIC_MMY>
  & YearSpecifying & CleanerIdSpecifying

export type MYSpecificMarketingEventBoundaries =
  MarketingEventBoundaries<MarketingEventBoundariesTypes.SPECIFIC_MY>
  & YearSpecifying
  & CleanerIdSpecifying

export type MMSpecificMarketingEventBoundaries =
  MarketingEventBoundaries<MarketingEventBoundariesTypes.SPECIFIC_MM>
  & CleanerIdSpecifying

export type YSpecificMarketingEventBoundaries =
  MarketingEventBoundaries<MarketingEventBoundariesTypes.SPECIFIC_Y>
  & YearSpecifying

export type MSpecificMarketingEventBoundaries =
  MarketingEventBoundaries<MarketingEventBoundariesTypes.SPECIFIC_M>
  & CleanerIdSpecifying

export type StateSpecificMarketingEventBoundaries =
  MarketingEventBoundaries<MarketingEventBoundariesTypes.SPECIFIC_S>

  // One nice type for the generic usage
export type ConcreteMarketingEventBoundaries = (
    CampaignSpecificMarketingEventBoundaries
  | MMYSpecificMarketingEventBoundaries
  | MMSpecificMarketingEventBoundaries
  | MYSpecificMarketingEventBoundaries
  | MSpecificMarketingEventBoundaries
  | YSpecificMarketingEventBoundaries
  | StateSpecificMarketingEventBoundaries
  | NonInventoryMarketingEventBoundaries
)
export interface SavedMarketingEvent{
  title          : string
  active         : boolean
  startDate      : Date
  endDate        : Date
  boundaries     : ConcreteMarketingEventBoundaries[]
  rsaApproaches  : MarketingApproach[]
}
export interface MarketingEvent{
  title          : string
  active         : boolean
  boundaries     : ConcreteMarketingEventBoundaries[]
  rsaApproaches  : RestrictedLengthList<MarketingApproach|NonInventoryMarketingApproach,number>
  startDate      : Date
  endDate        : Date
  lastModifiedOn : Date
  lastModifiedBy : number
  isDuplicate   ?: boolean
  id            ?: number
}
export interface NonInventoryMarketingEvent extends MarketingEvent{}
export interface DateResponse {
  date           : string,
  timezone_type  : number,
  timezone       : string
}
export interface MarketingEventResponse{
  id            ?: number
  title          : string
  active         : boolean
  boundaries     : ConcreteMarketingEventBoundaries[]
  rsaApproaches  : RestrictedLengthList<MarketingApproach,number>
  startDate      : DateResponse
  endDate        : DateResponse
  lastModifiedOn : DateResponse
  lastModifiedBy : number
}
export type PriorityGradeMapping = {[MEBType in MarketingEventBoundariesTypes] : number}
export type MEBCampaignGroupsMapping = {
  [MEBType in MarketingEventBoundariesTypes] ?: AdBuilderCompatibleCampaignGroup[]
}

export const MEBCampaignGroupsMapping:MEBCampaignGroupsMapping = {
  [MarketingEventBoundariesTypes.SPECIFIC_CAMPAIGN] : [], //Only one of the groups at a time for this type
  [MarketingEventBoundariesTypes.SPECIFIC_MMY] : [
    AdBuilderCompatibleCampaignGroup.VDP,
    AdBuilderCompatibleCampaignGroup.SRP_MMY
  ],
  [MarketingEventBoundariesTypes.SPECIFIC_MM] : [
    AdBuilderCompatibleCampaignGroup.VDP,
    AdBuilderCompatibleCampaignGroup.SRP_MM,
    AdBuilderCompatibleCampaignGroup.SRP_MMY
  ],
  [MarketingEventBoundariesTypes.SPECIFIC_MY] : [
    AdBuilderCompatibleCampaignGroup.VDP,
    AdBuilderCompatibleCampaignGroup.SRP_MMY
  ],
  [MarketingEventBoundariesTypes.SPECIFIC_M] : [
    AdBuilderCompatibleCampaignGroup.VDP,
    AdBuilderCompatibleCampaignGroup.SRP_MM,
    AdBuilderCompatibleCampaignGroup.SRP_MMY
  ],
  [MarketingEventBoundariesTypes.SPECIFIC_Y] : [
    AdBuilderCompatibleCampaignGroup.VDP,
    AdBuilderCompatibleCampaignGroup.SRP_MMY
  ],
  [MarketingEventBoundariesTypes.SPECIFIC_S] : [
    AdBuilderCompatibleCampaignGroup.VDP,
    AdBuilderCompatibleCampaignGroup.SRP_MM,
    AdBuilderCompatibleCampaignGroup.SRP_MMY
  ],
}

export const computeProtoBoundariesType = (boundaries:ProtoMarketingEventBoundaries):MarketingEventBoundariesTypes => {
  if(boundaries.campaignName){
    return MarketingEventBoundariesTypes.SPECIFIC_CAMPAIGN
  }
  if(boundaries.year && boundaries.make && boundaries.model){
    return MarketingEventBoundariesTypes.SPECIFIC_MMY
  }
  if(boundaries.year && boundaries.make){
    return MarketingEventBoundariesTypes.SPECIFIC_MY
  }
  if(boundaries.year){
    return MarketingEventBoundariesTypes.SPECIFIC_Y
  }
  if(boundaries.make && boundaries.model){
    return MarketingEventBoundariesTypes.SPECIFIC_MM
  }
  if(boundaries.make || boundaries.model){
    return MarketingEventBoundariesTypes.SPECIFIC_M
  }
  if(boundaries.campaignType){
    return MarketingEventBoundariesTypes.NON_INVENTORY
  }
  return MarketingEventBoundariesTypes.SPECIFIC_S
}

export interface ProtoMarketingEvent{
  rsaApproaches ?: ProtoMarketingApproach[]
  id            ?: number
  boundaries    ?: ProtoMarketingEventBoundaries[]
  startDate     ?: Date
  endDate       ?: Date
  lang          ?: string
  title         ?: string
  active        ?: boolean
}
export interface ProtoMarketingEventBoundaries {
  type          ?: MarketingEventBoundariesTypes
  vehicleType   ?: AdBuilderCompatibleVehicleType | null
  vehicleState  ?: AdBuilderCompatibleVehicleState | null
  year          ?: number
  campaignName  ?: string
  campaignGroup ?: AdBuilderCompatibleGroup
  campaignType  ?: AdBuilderCompatibleNonInventoryCampaignType
  cleanerId     ?: number
  make          ?: number
  model         ?: number
  lang          ?: AdBuilderCompatibleLang
}
export interface ProtoMarketingApproach{
  group         : string
  lang          : AdBuilderCompatibleLang
  patterns      : RestrictedLengthList<RSAAdTemplate,PermittedLengths>
  id           ?: number
  audienceAWId ?: string[] //While editing, all rlsa of same group are put together
  adGroupName  ?: string
  campaignName ?: string
}
export interface AdCopyStringLengths {
  shortestMake      : number
  shortestModel     : number
  shortestMakeModel : number
}
