import axios                 from "axios"
import Actions               from "../../model/constant/actions"
import Replacement, {
  formatToCreate,
  formatToUpdate
}                            from "../../model/grammarBooster/Replacement"
import {getToken}            from "../../storeAccessor/Generic"
import retrieveReplacements  from "./replacements"
import Headstone             from "../../utils/Headstone"

const saveHTTPSuccess = (accountId:number, response) => {
  if(response.error){return onError(response.error)}
  return retrieveReplacements(accountId)
}
const onError = (error)=>({
  type  : Actions.STORE_GRAMMAR_BOOST_ERROR,
  error : error
})
const save = (accountId:number, replacement:Replacement) => (dispatch, getState) => {
  if(replacement.id){
    // Updating
    axios.put(Headstone.getApiUrl() + `/${accountId}/GrammarBooster/${replacement.id}`, formatToUpdate(replacement), {
      headers : {
        Authorization : "Bearer "+getToken(getState())
      },
    })
    .then(data=>{
      dispatch(saveHTTPSuccess(accountId, data))
    })
    .catch(error=>{
      dispatch(onError(String(error)))
    })
  }
  else{
    // Creating
    axios.post(Headstone.getApiUrl() + `/${accountId}/GrammarBooster`, formatToCreate(replacement), {
      headers : {
        Authorization : "Bearer "+getToken(getState())
      },
    })
    .then(data=>{
      dispatch(saveHTTPSuccess(accountId, data))
    })
    .catch(error=>{
      dispatch(onError(String(error)))
    })
  }
}
export default save
