import './Preview.css'
import * as React                              from 'react'
import IdentityVariantsTester                  from '../../../components/branding/IdentityVariantsTester'
import AdCopy                                  from '../../../model/Store/AdCopyPatterns/AdCopy'
import SimpleAdCopy                            from '../../../components/adCopy/SimpleAdCopy'
import {
  Card,
  CardContent,
  Divider,
  Icon,
  CardActions,
  Button,
  CardHeader,
  Typography,
  Slide
}                                              from '@material-ui/core'
import { AdTemplate }                          from '../../../model/adbuilder'
import { AudienceAd }                          from '../../../model/branding/AudienceAd'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                                              from 'react-localize-redux'

interface PreviewOwnProps {
  lang               : string
  templates          : AdTemplate[]
  closeModal         : () => void
  accountId          : number
  audienceAd         : AudienceAd
  saveAdCopyPatterns : (accountId: number, audienceId: number, adCopyPatterns: AdTemplate[], lang: string) => void
  compareChanges     : () => boolean
}
interface PreviewProps extends PreviewOwnProps {
  translate: TranslateFunction
}

const Preview = (props: PreviewProps) => {
  const [selectedAdCopyIndex, setSelectedAdCopyIndex] = React.useState(0)
  const [passingByAdCopy, setpassingByAdCopy] = React.useState({})
  const [direction, setDirection] = React.useState("down")
  const totalPassing = Object.values(passingByAdCopy).reduce((acc: number, curr: number) => {
    return acc + curr
  }, 0)
  return (
    <Card className="Preview">
      <CardHeader
        avatar={<Icon>search</Icon>}
        title={props.translate('branding.identityVariantsTester.review')}
        titleTypographyProps={{ variant: 'h5' }}
        subheader={<Typography style={{ fontSize: '0.8rem' }} variant="caption"><Translate id="branding.identityVariantsTester.previewDescription" /></Typography>}
      />
      <Divider variant="middle" />
      <CardContent className="Display">
        <div className="PreviewPlusPagination">
          <Button
            className="NavigationButton"
            disabled={selectedAdCopyIndex === 0}
            onClick={() => { setSelectedAdCopyIndex(prevState => prevState - 1); setDirection("left") }}
            color="primary"><Icon>chevron_left</Icon>
          </Button>
          <Card className="Variations">
            <Slide
              key={selectedAdCopyIndex}
              in
              direction={direction as any}
              exit
              timeout={500}
            >
              <div>
                <Card className="Header">
                  <SimpleAdCopy
                    headlines={props.templates[selectedAdCopyIndex].headlines}
                    descriptions={props.templates[selectedAdCopyIndex].descriptions}
                    url="www.google.com"
                  />
                </Card>
                <div className="Content">
                  <IdentityVariantsTester
                    maxChar={{ headlines: 30, descriptions: 90 }}
                    lang={props.lang}
                    adCopy={props.templates[selectedAdCopyIndex] as AdCopy}
                  />
                </div>
              </div>
            </Slide>
          </Card>
          <Button
            className="NavigationButton"
            disabled={selectedAdCopyIndex === props.templates.length - 1}
            onClick={() => { setSelectedAdCopyIndex(prevState => prevState + 1); setDirection("right") }}
            color="primary"><Icon>chevron_right</Icon>
          </Button>
        </div>
        <div className="Pagination">
          {props.templates.map((adCopy, i) => {
            return (
              <Button
                key={i}
                color="primary"
                disabled={i === selectedAdCopyIndex}
                onClick={() => { setSelectedAdCopyIndex(i); setDirection(i < selectedAdCopyIndex ? 'right' : 'left') }}>{i + 1}
              </Button>
            )
          })}
        </div>
      </CardContent>
      <Divider variant="fullWidth" />
      <CardActions className="Footer">
        <Typography variant="caption">
          <Icon className="Icon">emoji_objects</Icon>
          <span className="Tip" ><Translate id="branding.identityVariantsTester.tip" /></span>
        </Typography>
        <div className="Buttons">
          <div className="Actions">
            <Button
              onClick={props.closeModal}
              variant="outlined"
              size="large"
              color="secondary"
            >
              <Translate id="common.cancel" />
            </Button>
            <Button
              onClick={() => {
                props.saveAdCopyPatterns(props.accountId, props.audienceAd.id, props.templates, props.lang)
              }}
              disabled={totalPassing < 3 || props.compareChanges()}
              style={{ color: "white" }}
              variant="contained"
              size="large"
              color="primary"
            >
              <Translate id="common.save" />
            </Button>
          </div>
            {totalPassing < 3 &&
              <Typography className="Error" color="secondary" variant="caption">
                <Translate id="branding.identityVariantsTester.error" />
              </Typography>
            }
        </div>
      </CardActions>
      {props.templates.map((adCopy, i) => {
        return (
          <div key={i} className="Hidden">
            <IdentityVariantsTester
              maxChar={{ headlines: 30, descriptions: 90 }}
              lang={props.lang}
              adCopy={adCopy as AdCopy}
              setPassing={(passing: number) => {
                if (passingByAdCopy[adCopy.id] === undefined || passing !== passingByAdCopy[adCopy.id]) {
                  setpassingByAdCopy(prevState => ({
                    ...prevState,
                    [adCopy.id]: passing
                  }))
                }
              }}
            />
          </div>
        )
      })}
    </Card>
  )
}
export default withLocalize(Preview as any) as React.ComponentType<PreviewOwnProps>
