export default class Contexts{
  public static ADCOPY      = "Adcopy"
  public static GEO         = "Geo"
  public static KEYWORD     = "Keyword"
  public static CAMPAIGN    = "Campaign"
  public static ACCOUNT     = "Account"
  public static APPLICATION = "Application"
  public static RULES       = "RULES"
  public static ADBUILDER   = "ADBUILDER"
  public static STRATEGY    = "STRATEGY"
  public static BRANDING    = "BRANDING"
  public static FEEDS       = "FEEDS"
}
