import Actions     from "../../model/constant/actions"
import Reporting   from "../../utils/Reporting"
import CSVUtils    from "../../utils/CSVUtils"
import ActionUtils from "../../utils/ActionUtils"
import {getToken}  from "../../storeAccessor/Generic"
import {Provider}  from "../../model/Provider"

const googleCampaignStatsFieldRenaming = {
  CampaignId : "CampaignAWId",
  Date : "Day"
}
const bingCampaignStatsFieldRenaming = {
  CampaignId : "CampaignBingId",
  Date : "Day"
}
const renameAWHeaders = (header)=>googleCampaignStatsFieldRenaming[header]||header
const renameBingHeaders = (header)=>bingCampaignStatsFieldRenaming[header]||header

const loadCampaignsStats = (accountId:number, accountAWId:string, accountBingId:string, period) => async (dispatch, getState) => {
  const token = getToken(getState())
  dispatch({
    type   : Actions.RETRIEVING_CAMPAIGNS_STATISTICS,
    status : true
  })
  try{
    const actions = await Promise.all([
      getAWCampaignStatsAction(token,accountId,accountAWId,period),
      getBingCampaignStatsAction(token,accountId,accountBingId,period)
    ])
    for(const action of actions){
      if(action){dispatch(action)}
    }
  }
  catch(e){
    console.log("General error while retrieving campaign stats", e)
  }
  dispatch({
    type   : Actions.RETRIEVING_CAMPAIGNS_STATISTICS,
    status : false
  })
}
export default loadCampaignsStats

const getAWCampaignStatsAction = (token:string, accountId:number, accountAWId:string, period) => (dispatch, getState) => {
  return ActionUtils.retryOnFailure(()=>
    Reporting.getAWCampaignStats(token, accountAWId, period)
  )
  .then(
    response => dispatch(loadStatSuccess(response.data, accountId)),
    error    => dispatch(loadStatError(error)),
  )
}
const loadStatSuccess = (response, accountId) => {
  const stats = CSVUtils.parseCSVWithHeaders(response, renameAWHeaders)
  .map(x=>{
    x.Provider=Provider.AW
    return x
  })
  return {
    type      : Actions.RETRIEVE_AW_CAMPAIGNS_STATS_FOR_ACCOUNTID,
    accountId,
    stats,
  }
}
const loadStatError = (response) => {
  return {
    type:Actions.RETRIEVE_AW_CAMPAIGNS_STATS_FOR_ACCOUNTID,
    campaigns:undefined
  }
}
const getBingCampaignStatsAction = (token:string, accountId:number, accountBingId:string, period) => {
  const baseAction = {
    type : Actions.RETRIEVE_BING_CAMPAIGNS_STATS_FOR_ACCOUNTID,
    accountId,
    stats : [],
    period
  }
  if(accountBingId === "0"){return baseAction}
  return ActionUtils.retryOnFailure(()=>
    Reporting.getBingCampaignStats(token,accountBingId,period)
  )
  .then((response)=>{
    baseAction.stats = CSVUtils.parseCSVWithHeaders(response.data, renameBingHeaders)
    .map(x=>{
      x.Provider=Provider.BING
      return x
    })
    return baseAction
  })
  .catch((error)=>{
    console.log(error)
  })
}
