import * as React                                         from "react"
import {connect}                                          from "react-redux"
import MapUtils                                           from "../../../../utils/MapUtils"
import PERMISSIONS                                        from "../../../../model/constant/Permissions"
import {
  retrieveShapesPendingSimplification,
  flushShapesPendingSimplification,
  saveSimplifiedShape
}                                                         from "../../../../actions/shapes/ShapeValidation"
import UserPermissionBoundary                             from "../../../../components/permissions/UserPermissionBoundary"
import GoBack                                             from "../../../../components/Button/ButtonGoBack"
import MapBuilder                                         from "../../../../components/Map/MapBuilder"

interface ShapeAutoSimplifierOwnProps{
  exit ?: ()=>void
}
interface ShapeAutoSimplifierProps extends ShapeAutoSimplifierOwnProps{
  shapes : any[]
  loadShapes : ()=>void
  flushShapes : ()=>void
  saveSimplifiedShape : (shapeId, code, polygons)=>void
}

const mapStateToProps = (state,ownProps:ShapeAutoSimplifierOwnProps)=>{
  return {
    shapes : state.Params.GeoBids.Shape.ShapesPendingSimplification,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadShapes  : ()=>dispatch(retrieveShapesPendingSimplification(2000)),
    flushShapes : ()=>dispatch(flushShapesPendingSimplification()),
    saveSimplifiedShape : (shapeId, code, polygons)=>dispatch(saveSimplifiedShape(shapeId, code, polygons))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

interface ShapeAutoSimplifierState{
  simplified : boolean
  simplifiedPairs : {original:any, simplified:any}[]
}

const PermissionWrapper = (props) => (
  <UserPermissionBoundary
    userLevel={3}
    permissions={[[PERMISSIONS.IDB2_Modify_Shape]]}
    onFailure={()=><div>You are not allowed</div>}
  >
    <ShapeAutoSimplifier {...props}/>
  </UserPermissionBoundary>
)
class ShapeAutoSimplifier extends React.Component<ShapeAutoSimplifierProps,ShapeAutoSimplifierState>{
  constructor(props:ShapeAutoSimplifierProps){
    super(props)
    this.state = {
      simplified : false,
      simplifiedPairs : []
    }
    this.doSimplification = this.doSimplification.bind(this)
    this.loadNextShapes = this.loadNextShapes.bind(this)
  }
  componentDidMount(){
    if(!this.props.shapes || this.props.shapes.length === 0){
      this.props.loadShapes()
    }
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.shapes.length > 0 && this.props.shapes.length === 0){
      this.props.loadShapes()
    }
    if(!prevState.simplified && this.state.simplified){
      console.log(this.state.simplifiedPairs)
    }
  }
  loadNextShapes(){
    for(let pair of this.state.simplifiedPairs){
      this.props.saveSimplifiedShape(pair.simplified.id, pair.simplified.code, pair.simplified.polygons)
    }
    this.setState({
      simplified : false,
      simplifiedPairs : []
    })
    this.props.flushShapes()
  }
  doSimplification(){
    this.setState({
      simplifiedPairs : (
        this.props.shapes.reduce((pairs, shape)=>{
          const simplified = this.findBestSimplification(shape)
          if(!simplified){return pairs}
          pairs.push({
            original : shape,
            simplified : simplified
          })
          return pairs
        }, [])
      ),
      simplified : true
    })
  }
  findBestSimplification(shape):any|undefined{
    const minimalDistancePool = [1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250, 3500, 3750, 4000, 4250, 4500, 4750, 5000]
    const distanceLeniencyPool = [1.025, 1.05, 1.075, 1.1, 1.125, 1.15, 1.175, 1.2, 1.225, 1.25, 1.275, 1.3]

    const originalArea = MapUtils.computeShapeArea(shape)
    const originalPoints = MapUtils.computeShapeNumberOfPoints(shape)
    let bestShape
    let bestShapePointsCount = originalPoints
    for(let i=0; i<minimalDistancePool.length; i++){
      for(let j=0; j<distanceLeniencyPool.length; j++){
        const tempSimplified = MapUtils.simplifyShape(shape, minimalDistancePool[i], distanceLeniencyPool[j])

        const simplifiedPoints = MapUtils.computeShapeNumberOfPoints(tempSimplified)
        const simplifiedArea = MapUtils.computeShapeArea(tempSimplified)
        const difference = originalArea - simplifiedArea
        const percentDiff = Math.abs(difference/originalArea)
        if(percentDiff <= 0.03 && simplifiedPoints < bestShapePointsCount){
          bestShape = tempSimplified
          bestShapePointsCount = simplifiedPoints
        }
      }
    }
    if(bestShape && bestShapePointsCount/originalPoints > 0.75){return undefined} //We want at most 75% of original points
    return bestShape
  }
  render(){
    return (
      <div>
        <GoBack onClick={this.props.exit}/>
        {this.state.simplified
          ? <div>
              <div>Simplification done</div>
              <button onClick={this.loadNextShapes}>Load next shapes</button>
            </div>

          : <div>
              <button onClick={this.doSimplification}>
                Go
              </button>
            </div>
        }
        <div>Shapes loaded : {this.props.shapes.length}</div>
        <div>Shapes simplified : {this.state.simplifiedPairs.length}</div>
        <MapBuilder
          standaloneShapes={this.props.shapes}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(PermissionWrapper)
