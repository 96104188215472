import * as React                  from "react"
import {HashRouter,Route,Redirect} from "react-router-dom"
import {Link, RouteProps, Switch}  from "react-router-dom"
import {routing as AccountRouting} from "./accountsRoutes"

export const routing = ()=>(
  <HashRouter>
    <AccountRoute />
  </HashRouter>
)
const AccountRoute = (props) => {
  return (
  	<Switch>
    	<Route path="/account/:accountid" render={props=><AccountRouting accountId={props.match.params.accountid} />} />
    	<Route path="/account" exact render={props=><AccountRouting accountId=""/>} />
    </Switch>
  )
}
