import Actions         from "../../../model/constant/actions"
import ReducerUtils    from "../../../utils/ReducerUtils"

let rememberAccountLoaded = (state,action) => {
  return [...state, action.accountId]
}

export const FetchedAccounts = ReducerUtils.createReducer<number[]>([],{
  [Actions.RETRIEVE_BUDGET_PLANS_FOR_ACCOUNTID] : rememberAccountLoaded,
})
