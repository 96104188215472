import {combineReducers}            from 'redux'
import Retrieving                   from "./Retrieving"
import {CampaignsList}              from "./CampaignsList"
import {FetchedAccounts}            from "./FetchedAccounts"
import {DesiredStatusExceptions}    from "./DesiredStatusExceptions"
import DesiredGeoException          from "./DesiredGeoExceptions"
import Display                      from "./Display/Display"
import CampaignsBaseInfo            from "./BaseInfo/CampaignsBaseInfo"

export const Campaigns = combineReducers({
  list                    : CampaignsList,
  FetchedAccounts         : FetchedAccounts,
  DesiredStatusExceptions : DesiredStatusExceptions,
  DesiredGeoExceptions    : DesiredGeoException,
  BaseInfo                : CampaignsBaseInfo,
  Display,
  retrievingFriends       : Retrieving
})
