import * as React from "react"
import {connect} from "react-redux"
import "./index.css"

interface WidthInjectorProps{
  children : React.ReactElement<any>
}

const mapStateToProps = (state,ownProps)=>{
  return {
    _ : state.Menu.isMenuToggle
  }
}


interface WidthInjectorState{
  elementWidth ?: number
}

class WidthInjectorComponent extends React.Component<WidthInjectorProps,WidthInjectorState>{
  private element
  constructor(props:WidthInjectorProps){
    super(props)
    this.state = {

    }
    this.element = React.createRef()

    this.updateElementWidth = this.updateElementWidth.bind(this)
  }
  updateElementWidth(){
    if(this.element.current){
      if(this.element.current.offsetWidth !== this.state.elementWidth){
        this.setState({elementWidth: this.element.current.offsetWidth})
      }
    }
  }
  componentDidMount(){
    window.addEventListener('resize', this.updateElementWidth)
    this.updateElementWidth()
  }
  componentDidUpdate(){
    this.updateElementWidth()
  }
  componentWillUnmount(){
    window.removeEventListener("resize", this.updateElementWidth)
  }
  render(){
    return (
      <div className='WidthInjector' ref={this.element}>
        {React.cloneElement(this.props.children,{
          elementWidth: this.state.elementWidth
        })}
      </div>
    )
  }
}
const WidthInjector = connect(mapStateToProps)(WidthInjectorComponent)

const injector = (Component) => (props) => (
  <WidthInjector>
    <Component {...props}/>
  </WidthInjector>
)

export default injector
