import * as React                    from "react"
import {HashRouter, Route}           from "react-router-dom"
import {Switch,RouteProps}           from "react-router-dom"
import {Redirect}                    from "react-router-dom"
import {connect}                     from "react-redux"
import Params                        from "../page/params/"
import {Strategies}                  from "../page/strategy/"
import KeywordPatterns               from "../page/keyword/"
import {routing as AdCopiesRouting}  from "./adCopiesRoutes"
import RulesRouting                  from "./rulesRoutes"
import GeobidsRouting                from "./geobidsRoutes"
import BudgetRouting                 from "./budgetRoutes"
import BrandingRouting               from "./brandingRoutes"
import FeedsTable                    from "../page/feeds"
import {routing as FeedsRouting}     from "./feedsRoutes"


interface RouterMapping{
  path      : string
  render    : (prePath?:string, accountId?:number)=>any
  exact     : boolean
}
let mapping : RouterMapping[] = [
  {path:"*/Param(s)?/Budget(s)?/",          render: ()=><BudgetRouting/>,                                        exact:false},
  {path:"*/Param(s)?/Strateg(y|ies)?/",     render: ()=><Strategies/>,                                           exact:true},
  {path:"*/Param(s)?/Keyword(s)?/",         render: ()=><KeywordPatterns/>,                                      exact:true},
  {path:"*/Param(s)?/Geobid(s)?/",          render: ()=><GeobidsRouting/>,                                       exact:false},
  {path:"*/Param(s)?/Rule(s)?/",            render: ()=><RulesRouting/>,                                         exact:false},
  {path:"*/Param(s)?/Adcop(y|ies)?/",       render: ()=><AdCopiesRouting/>,                                      exact:false},
  {path:"*/Param(s)?/Branding",             render: (prePath)=><BrandingRouting prePath={prePath}/>,             exact:false},
  {path:"*/Param(s)?/Feed(s)?/",            render: (prePath, accountId)=><FeedsRouting accountId={accountId}/>, exact:true},
  {path:"*/Param(s)?/",                     render: ()=><Params/>,                                               exact:true},
]

export const routing = (props) => {
  let redirectPath="/Account/"+props.accountId+"/Params"
  return (
    <HashRouter>
      <Switch>
        {mapping.map((route:RouterMapping)=>
          <Route key={route.path} exact={route.exact} render={()=>route.render(redirectPath, props.accountId)} path={route.path} />
        )}
        <Redirect from="/" to={redirectPath}/>
      </Switch>
    </HashRouter>
  )
}
