import {combineReducers}    from "redux"
import List                 from "./List"
import Retrieving           from "./Retrieving"
import TestResult           from "./TestResult"
import Error                from "./Error"

export const GrammarBooster = combineReducers({
  List,
  Retrieving,
  TestResult,
  Error,
})
