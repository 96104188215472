import Actions    from "../model/constant/actions"

export const ToggleMenu = () => {
  return {type:Actions.TOGGLE_MENU}
}
export const changeButtonActive = (index:string) => {
  return {
    type  : Actions.CHANGE_ACTIVE_BUTTON_IN_MENU,
    index : index
  }
}
