import Actions         from "../../model/constant/actions"
import ReducerUtils    from "../../utils/ReducerUtils"

const rememberAccountLoaded = (state,action) => {
  if ( state.indexOf(action.account)==-1 ){
    return [...state, action.account]
  }
  return state
}

const removeAccountNotLoaded = (state,action) => {
  let index =  state.indexOf(action.account)
  if(index != -1){return [...state.slice(0,index), ...state.slice(index+1)]}
  return state;

}

export const FetchedAccounts = ReducerUtils.createReducer<number[]>([],{
  [Actions.ADD_FETCHED_ACCOUNTIDS_OF_BUDGETS_FOR_STRATEGIES] : rememberAccountLoaded,
  [Actions.REMOVE_FETCHED_ACCOUNTIDS_NOT_LOADED] : removeAccountNotLoaded,
})
