import Actions          from "../../../model/constant/actions"
import ReducerUtils     from "../../../utils/ReducerUtils"

const IsFetching = ReducerUtils.createReducer<boolean>(false,{
  [Actions.RETRIEVING_APPLIERS] : ()=>true,
  [Actions.RECEIVE_APPLIERS] : ()=>false,
  [Actions.RECEIVE_APPLIERS_FAILED] : ()=>false,
})

export default IsFetching
