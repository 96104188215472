import "./index.css"
import * as React       from "react"
import {connect}        from "react-redux"
import {
  ProxyGroup
}                       from "../../../../model/pacy/ProxyGroup"
import {PacyAccount}    from "../../../../model/pacy/"
import {
  Decision,
  DecisionType,
  BaseDecision,
  PacyEnablementDecision
}                       from "../../../../model/pacy/Decision"
import PacyLoader       from "../../../../components/loaders/pacyLoader"
import AddGuidelineList from "./AddGuidelineList/AddGuidelineList"
import { StringMap }    from "../../../../model/generics"
import PermissionsUtils from "../../../../utils/PermissionsUtils"
import {UserPermissions} from "../../../../model/Store/Login/UserLogged"
import UserConnected    from "../../../../model/Store/Login/UserConnected"
import Permissions      from "../../../../model/constant/Permissions"
import ArrayUtils       from "../../../../utils/ArrayUtils"


interface ChangeHistoryOwnProps{
  singleProxyGroupId ?: string
}
interface ChangeHistoryProps extends ChangeHistoryOwnProps{
  account   : PacyAccount
  decisions : Decision[]
  permissions : UserPermissions[],
  profile : UserConnected,
}

const mapStateToProps = (state,ownProps:ChangeHistoryOwnProps)=>{
  const accountId = state.Accounts.selected
  return {
    account   : state.Pacy.Accounts[accountId],
    decisions : state.Pacy.Decisions[accountId] || [],
    permissions : state.Login.userLogged.permissions,
    profile : state.Login.userLogged.profile,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {}
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

export const filterDecisions = (
  decisions : Decision[],
  restrictedDecisions:DecisionType[],
  wantedGuidelineIdsToProxyGroup:StringMap<ProxyGroup>
) : BaseDecision[] => {
  return (decisions.filter(x=>
    x.type === DecisionType.ADD_GUIDELINE && wantedGuidelineIdsToProxyGroup[x.details.guideline.id] ||
    ArrayUtils.contain(restrictedDecisions, x.type)
  ) as BaseDecision[]).map(x=>{
    // Return ENABLE_PACY or DISABLE_PACY as an unique decision type (PACY_ENABLEMENT) with a boolean status value
    if(x.type === DecisionType.ENABLE_PACY || x.type === DecisionType.DISABLE_PACY){
      return {
        ...x,
        type: DecisionType.PACY_ENABLEMENT,
        details: {
          ...x.details,
          status: x.type === DecisionType.ENABLE_PACY
        }
      } as PacyEnablementDecision
    }
    return x
  })
}

const ChangeHistory = (props:ChangeHistoryProps) => {
  const proxyGroups = props.account.proxyGroups.filter(x=>props.singleProxyGroupId ? x.id === props.singleProxyGroupId : true)
  const wantedGuidelineIdsToProxyGroup:StringMap<ProxyGroup> = proxyGroups.reduce((gIds, pg)=>{
    for(const guideline of pg.guidelines){
      gIds[guideline.id] = pg
    }
    return gIds
  },{})

  let restrictedDecisions:DecisionType[] = []
  const permissionsUtils = new PermissionsUtils(props.account.accountId, props.permissions, props.profile)
  if(permissionsUtils.validatePermissions([Permissions.Pacy_Edit_Config])){
    restrictedDecisions.push(
      DecisionType.UPDATE_PACING_RATIO_CONFIG,
      DecisionType.ENABLE_PACY,
      DecisionType.DISABLE_PACY
    )
  }

  const decisions = filterDecisions(props.decisions ?? [], restrictedDecisions, wantedGuidelineIdsToProxyGroup)

  return (
    <div className="ChangeHistory">
      <AddGuidelineList
        proxyGroups={proxyGroups}
        decisions={decisions}
        wantedGuidelineIdsToProxyGroup={wantedGuidelineIdsToProxyGroup}
      />
    </div>
  )
}

export default PacyLoader({needDecisions: true, needUsers: true, loadWhenInactive: true})(
  connect(mapStateToProps,mapDispatchToProps,mergeProps) (ChangeHistory)) as React.ComponentType<ChangeHistoryOwnProps>
