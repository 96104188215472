import * as React from "react"
import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import { Fade } from "@material-ui/core"

const CustomTooltip = withStyles({
    tooltip: {
        backgroundColor: '#3c3b3bdd',
        color: 'white',
        padding: '5px',
        borderRadius: '5px',
        boxShadow: '1px 3px 7px 2px rgba(0, 0, 0, 0.5)',
        fontSize: '17px',
    }
})(Tooltip);

interface DesignedDarkTooltipProps {
    text: string
    arrowColor?: string
    disabled?: boolean
    position?: "top" | "right" | "bottom" | "left"
    children
}

const DesignedDarkTooltip = (props: DesignedDarkTooltipProps) => {
    return (
        <CustomTooltip
            title={props.text}
            enterDelay={450}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 350 }}
            placement={props.position}
        >
            {props.children}
        </CustomTooltip>

    )
}
export default DesignedDarkTooltip
