import * as React                     from "react"
import {connect}                      from "react-redux"
import {getDefaultAdSet}              from "../../actions/branding/DefaultAds"
import ExplainedLoading               from "../loading"
import ErrorBoundary                  from "../Error/ErrorBoundary"
import {State}                        from "../../reducers/generic/reducerAssembly"

const mapStateToProps = (state:State)=>{
  const accountId = state.Accounts.selected
  return {
    accountId,
    brandingDefaultAdSets : state.Params.Branding.DefaultAds,
    retrieving            : state.Params.Branding.retrievingDefaultAds,
  }
}
const mapDispatchToProps = {getDefaultAdSet}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,
    ...DP,
    ...ownProps,
    getDefaultAdSet : ()=>DP.getDefaultAdSet(SP.accountId)
  }
}
const brandingDefaultAdSetsLoader = props => {
  if(props.brandingDefaultAdSets===undefined || !props.brandingDefaultAdSets[props.accountId] || props.retrieving){
    if(!props.retrieving){props.getDefaultAdSet()}
    if(!props.letThrough){return <ExplainedLoading translateId="loadings.adGroups"/>}
  }
  return <ErrorBoundary>{props.render({
    brandingDefaultAdSets : props.brandingDefaultAdSets[props.accountId],
    loadingBrandingDefaultAdSets : props.retrieving
  })}</ErrorBoundary>
}
const BrandingDefaultAdSetsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(brandingDefaultAdSetsLoader)

interface BrandingDefaultAdSetsLoaderConfig {
  letThrough : boolean
}
interface BrandingDefaultAdSetsGivenConfig {
  letThrough ?: boolean
}
const baseConfig : BrandingDefaultAdSetsLoaderConfig = {
  letThrough : false
}

const requiresBrandingDefaultAdSets = (givenConfig:BrandingDefaultAdSetsGivenConfig={}) => Component => props => {
  const config : BrandingDefaultAdSetsLoaderConfig = {...baseConfig, ...givenConfig}
  return (
    <BrandingDefaultAdSetsLoaderConnected
      letThrough={config.letThrough}
      render={ ({brandingDefaultAdSets, loadingBrandingDefaultAdSets}) => (
        <Component
          brandingDefaultAdSets={brandingDefaultAdSets}
          loadingBrandingDefaultAdSets={loadingBrandingDefaultAdSets}
          {...props}
        />
      )}
    />
  )
}
export default requiresBrandingDefaultAdSets
