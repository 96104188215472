import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"
import ArrayUtils       from "../../utils/ArrayUtils"

const storeDescriptions = (state,action) => {
  let newState = {...state}
  action.descriptions.map(desc=>{newState[desc.id] = desc})
  return newState
}
const Descriptions = ReducerUtils.createReducer<{[id:number]:any}>({},{
  [Actions.STORE_USERS_DESCRIPTIONS] : storeDescriptions,
})
export default Descriptions
