import './index.css'
import * as React                    from "react"
import {connect}                     from 'react-redux'
import keydown                       from 'react-keydown'
import {withLocalize, Translate}     from "react-localize-redux"
import {Button}                      from "carbon-components-react"
import ProfileViewer                 from "./component/ProfileViewer"
import LanguageChooser               from "./component/LanguagePreferenceChooser"
import UserConnected                 from "../../model/Store/Login/UserConnected"
import {Panel}                       from "../../components/Panel/Panel"
import TokenModal                    from "../../components/alert/TokenModal"
import LocalePreferenceChooser       from "./component/LocalePreferenceChooser"

interface ProfileViewState{
  tokenModalShown : boolean
}
interface ProfileViewProps{
  user               : UserConnected
  token              : string
  translate         ?: (translateId:string)=>string
  languages         ?: any[]
  setActiveLanguage ?: (code:string)=>void
  activeLanguage    ?: any
}
const mapStateToProps = (state, ownProps) => {
  return {
    user  : state.Login.userLogged.profile,
    token : state.Login.userLogged.token
  }
}
const mapDispatchToProps = dispatch => {
  return {}
}

class Profile extends React.Component<ProfileViewProps,ProfileViewState>{
  constructor(props: ProfileViewProps){
    super(props)
    this.state = {
      tokenModalShown : false,
    }
    this.handleShow  = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  @keydown(["alt+t"])
  handleShow() {this.setState({ tokenModalShown: true  })}
  @keydown(["esc"])
  handleClose(){this.setState({ tokenModalShown: false })}
  render(){
    return (
      <Panel title={this.props.translate("profile.title")} subtitle={this.props.translate("profile.subtitle")}>
        <ProfileViewer user={this.props.user} />
        <div>
          <Button className="btn btn-info" onClick={()=>{
            document.cookie="UID=;"
            document.location.reload()
          }}>
            <Translate id="profile.logout"/>
          </Button>
        </div>
        <TokenModal shown={this.state.tokenModalShown} close={this.handleClose} token={this.props.token}/>
        <div className="PreferenceChoosers">
          <LanguageChooser/>
          <LocalePreferenceChooser />
        </div>
      </Panel>
    )
  }
}

export default (
  withLocalize(
    connect(mapStateToProps,mapDispatchToProps)(
      Profile
    )
  )
)
