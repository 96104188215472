import "./SingleCampaignSelection.css"
import * as React                                   from "react"
import {connect}                                    from "react-redux"
import {
  withLocalize,
  Translate,
  LocalizeContextProps
 }                                                  from "react-localize-redux"
import ArrayUtils                                   from "../../../utils/ArrayUtils"
import CampaignsAccessor                            from "../../../storeAccessor/Campaigns"
import {
  AdBuilderCompatibleVehicleState, 
  AdBuilderCompatibleLang,
  AdBuilderCompatibleCampaignGroup,
  AdBuilderCompatibleNonInventoryCampaignType
}                                                   from "../../../model/adbuilder"
import Loading                                      from "../../../components/loading/"
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem
}                                                   from "@material-ui/core"

interface SingleCampaignSelectionOwnProps{
  campaignType        : any
  lang                : AdBuilderCompatibleLang|undefined
  onChange            : (campaignName:string, campaignGroup:AdBuilderCompatibleCampaignGroup|string)=>void
  campaignName       ?: string
  disabled           ?: boolean
  originalEventId    ?: number
  otherCampaignNames ?: string[]
}

const makeMapStateToProps = () => {
  const campaignsSelector = CampaignsAccessor.makeAllCampaignsSelector()
  return (state, _ownProps:SingleCampaignSelectionOwnProps)=>{
    return {
      campaigns : campaignsSelector(state),
      events    : state.AdBuilder.Events[state.Accounts.selected]
    }
  }
}

const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, ownProps:SingleCampaignSelectionOwnProps)=>{
  return {
    ...SP,...ownProps
  }
}

type SingleCampaignSelectionProps = ReturnType<typeof mergeProps> & LocalizeContextProps

interface SingleCampaignSelectionState {
  vehicleState       : AdBuilderCompatibleVehicleState
  lang               : AdBuilderCompatibleLang
  type               : AdBuilderCompatibleCampaignGroup
  name               : string
  originallySelected : boolean
  doesntExist        : boolean
}

const SingleCampaignSelection = (props:SingleCampaignSelectionProps) => {
  const getDerivedStateFromProps = () => {
    if (props.originalEventId){
      const originalEvent:any = props.events.find(x=>x.id==props.originalEventId)
      if(originalEvent && originalEvent.boundaries[0].campaignName){
        return {
          id                 : -1,
          vehicleState       : originalEvent.boundaries[0].vehicleState.toUpperCase(),
          lang               : originalEvent.rsaApproaches[0].lang.toUpperCase(),
          type               : originalEvent.rsaApproaches[0].group.toUpperCase(),
          name               : originalEvent.boundaries[0].campaignName,
          originallySelected : true,
          doesntExist        : props.campaigns.find(x=>x.name===originalEvent.boundaries[0].campaignName) === undefined
        }
      }
    }
    return null
  }
  const originalCampaign:SingleCampaignSelectionState = getDerivedStateFromProps()
  const filterCampaigns = () => {
    let campaigns = props.campaigns
    if (Object.values(AdBuilderCompatibleVehicleState).includes(
      props.campaignType as unknown as AdBuilderCompatibleVehicleState
    )){
      campaigns = props.campaigns.filter(x=>
        x.vehicleState 
          && x.vehicleState.toLowerCase() === props.campaignType.toString().toLowerCase() 
          && !x.name.toLowerCase().includes("conquest")
          && !x.name.toLowerCase().includes("branding")
          && !props.otherCampaignNames.includes(x.name)
      )
    }
    if (Object.keys(AdBuilderCompatibleNonInventoryCampaignType).includes(
      props.campaignType as unknown as AdBuilderCompatibleNonInventoryCampaignType
    )){
      campaigns = props.campaigns.filter(x=>
        x.type.toLowerCase() === props.campaignType.toString().toLowerCase()
        && !props.otherCampaignNames.includes(x.name)
      )
    }
    return campaigns
  }
  const [filteredCampaigns,setFilteredCampaigns] = React.useState<any[]>(filterCampaigns())

  React.useEffect(()=>{
    setFilteredCampaigns(filterCampaigns())
  },[props.campaignType])

  if(!props.campaigns){return <Loading small/>}

  const onCampaignSelect = (e:React.ChangeEvent<HTMLSelectElement>) => {
    if(e.target.value === "NONE"){
      props.onChange(e.target.value, e.target.value)
    }
    else{
      let campaign = props.campaigns.find(x=>x.name === e.target.value)
      if(!campaign && originalCampaign && e.target.value === originalCampaign.name){
        campaign = originalCampaign
      }
      props.onChange(campaign.name, campaign.type)
    }
  }
  if(originalCampaign 
    && originalCampaign.doesntExist
    && (originalCampaign.vehicleState === props.campaignType.toUpperCase() || originalCampaign.type === props.campaignType.toUpperCase())
  ){
    filteredCampaigns.push(originalCampaign)
  }
  const originalSelectedAndDoesntExist = (
    originalCampaign &&
    originalCampaign.doesntExist &&
    props.campaignName === originalCampaign.name
  )
  return (
    <div className="SingleCampaignSelection">
      <FormControl>
        <InputLabel>
          <Translate id="adBuilder.createEvent.selectCampaign"/>
        </InputLabel>
        <Select
          className="Input"
          value={props.campaignName||""}
          onChange={onCampaignSelect}
          disabled={props.disabled || false}
        >
          {filteredCampaigns.map(x=>
            <MenuItem key={x.id} value={x.name}>
              {x.name + (
                x.originallySelected
                  ? " ("+<Translate id="adBuilder.createEvent.originallySelected"/>+")"
                  : ""
                )
              }
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {originalSelectedAndDoesntExist &&
        <div className="DoesntExist">
          <Translate id="adBuilder.createEvent.campaignNotExist"/>
        </div>
      }
    </div>
  )
}

export default connect(makeMapStateToProps)(withLocalize(SingleCampaignSelection))
