import {allReducers}                 from "./reducerAssembly"
import Thunk                         from "redux-thunk"
import {createStore,applyMiddleware} from 'redux'
import {logger}                      from "../../middleware/logger"
import CampaignInfoMatcher           from "../../middleware/campaignInfoMatcher"
import BingCampaignInfoMatcher       from "../../middleware/bingCampaignInfoMatcher"
import navBlockerMiddleware     from "../../middleware/navigationBlocker"

export const store = createStore(
  allReducers,
  applyMiddleware(Thunk,navBlockerMiddleware,CampaignInfoMatcher,BingCampaignInfoMatcher,logger)
)

