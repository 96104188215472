import './OppTrendHighlighter.css'
import * as React               from "react"
import {connect}                from "react-redux"
import StatisticsAccessors      from "../../../storeAccessor/Statistics"
import ArrayUtils               from "../../../utils/ArrayUtils"
import requiresInventory        from "../../../components/loaders/inventoryLoader"
import requiresFriends          from "../../../components/loaders/friendsLoader"
import TrendyCarsTable          from "./TrendyCars"
import MissedOppTable           from "./opportunities/MissedOpportunities"

interface OppTrendHighlighterProps{
  friends : any[]
  campaignsStats : any[]
}
interface OppTrendHighlighterState{

}
const makeMapStateToProps = () => {
  const statsSelector = StatisticsAccessors.makeCampaignsStatsInCurrentPeriodSelector()
  return (state, ownProps)=>{
    const accountId = state.Accounts.selected
    return {
      campaignsStats : statsSelector(state)
    }
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {}
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
class OppTrendHighlighter extends React.Component<OppTrendHighlighterProps,OppTrendHighlighterState>{
  private trendyTableRef
  private oppTableRef

  constructor(props){
    super(props)
    this.trendyTableRef = React.createRef()
    this.oppTableRef = React.createRef()

    this.findVehiclesInBothTables = this.findVehiclesInBothTables.bind(this)
  }
  componentDidMount(){
    this.forceUpdate() //Refs are now set, rerender to calculate highlighted
  }
  componentDidUpdate(){
    if(!this.trendyTableRef.current || !this.oppTableRef.current){
      setTimeout(this.forceUpdate.bind(this), 200)
    }
  }
  findVehiclesInBothTables():any[]{
    if(this.trendyTableRef.current && this.oppTableRef.current){
      const trendyCars = this.trendyTableRef.current.props.data.slice(0,5).map(x=>x.name)
      const missedOpps = this.oppTableRef.current.props.data.slice(0,5).map(x=>x.name)
      return trendyCars.filter(x=>ArrayUtils.contain(missedOpps, x))
    }
    return []
  }
  render(){
    const duplicates = this.findVehiclesInBothTables()
    return (
      <div className="OppTrendHighlighter">
        <MissedOppTable
          ref={this.oppTableRef}
          campaigns={this.props.friends}
          campaignStats={this.props.campaignsStats}
          highlighted={duplicates}
        />
        <TrendyCarsTable
          ref={this.trendyTableRef}
          campaigns={this.props.friends}
          campaignStats={this.props.campaignsStats}
          highlighted={duplicates}
        />

      </div>
    )
  }
}

export default (
  requiresFriends()(
    requiresInventory(
      connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
        OppTrendHighlighter
      )
    )
  )
)
