import * as React        from "react"
import {PacyAccount}     from "../../model/pacy/"
import PacyChangeHistory from "./pacy/changeHistory/"
import ChangeHistory1    from "./budgetPlanner1/BudgetPlannerChangesHistory"
import PacyLoader        from "../../components/loaders/pacyLoader"
import Loading           from "../../components/loading/"

interface ChangeHistoryOwnProps{
}
interface ChangeHistoryProps extends ChangeHistoryOwnProps{
  account     : PacyAccount
  loadingPacy : boolean
}

const ChangeHistory = (props:ChangeHistoryProps) => {
  if(props.loadingPacy){return <Loading translateId="loadings.budgetPlanner"/>}
  if(!props.account){return <ChangeHistory1/>} // Old Budget Planner history, should be shown if Pacy account is not migrated/created yet
  return <PacyChangeHistory/>
}

export default PacyLoader()(ChangeHistory) as React.ComponentType<ChangeHistoryOwnProps>
