import './Graph.css'
import * as React             from 'react'
import * as d3                from 'd3'
import DualAxisGraph          from '../../components/graph/DualAxisGraph'

const UNDESIRABLE_ITEMS = [
  "campaignawid",
  "genericid",
  "group",
  "includeinimpshare",
  "searchbudgetlostimpressionshare",
  "name",
  "day",
  "vehiclestate",
  "vehicletype",
]
const ADDITIVES = [
  "impressions",
  "clicks",
  "conversions",
  "impressions",
]
const filterUndesirables = (key:string) : boolean => {
  return UNDESIRABLE_ITEMS.indexOf(key.toLowerCase())===-1
}
const doAgglomeration = (key,currentValue,value) =>{
  return currentValue + value
}
const formatData = (data)=>{
  let formattedData = {}
  for(let dataPoints of data){
    for(let index of Object.keys(dataPoints)){
      formattedData = {
        ...formattedData,
        [index]:[
          ...(formattedData[index] ? formattedData[index]:[]),
          {
            xValue : d3.timeParse("%Y-%m-%d")(dataPoints.day),
            yValue : dataPoints[index],
            line   : index
          }
        ]
      }
    }
  }
  return formattedData
}
const WazeGraph = props => {
  // +new Date() -> Cast to number
  const actions     = props.dataPerDay
  .sort((a,b)=> +new Date(a.day) - +new Date(b.day))
  .map(x=>({
    xValue : d3.timeParse("%Y-%m-%d")(x.day),
    yValue : x.actions,
    line   : "actions"
  }))
  const impressions = props.dataPerDay
  .sort((a,b)=> +new Date(a.day) - +new Date(b.day))
  .map(x=>({
    xValue : d3.timeParse("%Y-%m-%d")(x.day),
    yValue : x.impressions,
    line   : "impressions"
  }))
  const primary = {
    id    : "Impressions",
    color : "#00b1e4",
    data  : actions?actions:[]
  }
  const secondary = {
    id    : "Actions",
    color : "#3cba54",
    data  : impressions?impressions:[]
  }
  return (
    <div className={"statPanel DualGraph"}>
      <div className = {"statPanelGraph"}>
        <DualAxisGraph
          primary   = {primary}
          secondary = {secondary}
        />
      </div>
    </div>
  )
}
export default WazeGraph
