import BUTTONS from '../../model/constant/menuButtons'

export interface Dictionary{
  [context:string] : FieldName
}
interface FieldName{
  [propName:string]: [string,string]
}

export const dictionary:Dictionary = {
  Province:{
    AB:['Alberta', 'Alberta'],
    BC:['British Colombia', 'Colombie-Britannique'],
    MB:['Manitoba', 'Manitoba'],
    NB:['New Brunswick', 'Nouveau-Brunswick'],
    NL:['Newfoundland and Labrador', 'Terre-Neuve et Labrador'],
    NS:['Nova Scotia', 'Nouvelle-Écosse'],
    NT:['Northwest Territories', 'Territoires du Nord-Ouest'],
    NU:['Nunavut', 'Nunavut'],
    ON:['Ontario', 'Ontario'],
    PE:['Prince Edward Island', 'Île du Prince Édouard'],
    QC:['Quebec', 'Québec'],
    SK:['Saskatchewan', 'Saskatchewan'],
    YT:['Yukon', 'Yukon'],
  },
  Months:{
    M01: ['January', 'Janvier'],
    M02: ['February', 'Février'],
    M03: ['March', 'Mars'],
    M04: ['April', 'Avril'],
    M05: ['May', 'Mai'],
    M06: ['June', 'Juin'],
    M07: ['July', 'Juillet'],
    M08: ['August', 'Août'],
    M09: ['September', 'Septembre'],
    M10: ['October', 'Octobre'],
    M11: ['November', 'Novembre'],
    M12: ['December', 'Décembre'],
  },
  Time:{
    ThisWeek  : ['This week', 'Cette semaine'],
    LastWeek  : ['Last week', 'Semaine dernière'],
    ThisMonth : ['This month', 'Mois courant'],
    LastMonth : ['Last month', 'Mois dernier'],
    Custom    : ['Custom dates', 'Personalisée'],
    Yesterday : ['Yesterday', 'Hier'],
  },
  Languages:{
    EN : ['English', 'Anglais'],
    FR : ['French', 'Francais'],
    ES : ['Spanish', 'Espagnol'],
  },
  Login:{
    Email       : ["Email","Adresse de Courriel"],
    Password    : ["Password","Mot de Passe"],
    LoginButton : ["Login","Connexion"],
  },
  Error:{
    Email     : ["Invalid email","Adresse courriel invalide"],
    Password  : ["Invalid password","Mot de passe invalide"],
    Attempts  : ["Too many attempts to 'Login'", "Tentatives de connexion épuisées"],
  },
  Profile:{
    ID          : ["Id","Id"],
    FirstName   : ["First Name","Prénom"],
    LastName    : ["Last Name","Nom"],
    OfficePhone : ["Office Phone","Téléphone de bureau"],
    CellPhone   : ["Cell phone","Téléphone portable"],
    Mail        : ["Mail","Courriel"],
  },
  Campaign:{
    StatusEnabled    : ["Active","Active"],
    StatusDisabled   : ["Paused","En Pause"],
    CampaignNotFound : ["Selected campaign does not exist in selected account(s)","La campagne sélectionnée n'existe pas dans le(s) compte(s) sélectionné(s)"],
    ErrorCampaignID  : ["Campaign indentifier must be a positive number.","L'identifiant de campagne doit être un nombre positif."]
  },
  Campaigns:{
    Status        : ["Status","Statut"],
    Campaign      : ["Campaign","Campagne"],
    VehicleState  : ["Inventory","Inventaire"],
    Group         : ["Group","Groupe"],
    Clicks        : ["Clicks","Clils"],
    Cost          : ["Cost","Coût"],
  },
  Dashboard:{
    ImpShare      : ["Impression Share","Partage d'impressions"],
    Impressions   : ["Campaign","Campagne"],
    Clicks        : ["Clicks","Clics"],
    Cost          : ["Cost","Coût"],
  },
  VehicleStates:{
    New  : ["New","Neuf"],
    Used : ["Used","Usagé"],
  },
  Strategy:{
    PasStrategy  : ["No Strategies","Aucune stratégies"],
  },
  Menu:{
    [BUTTONS.ACCOUNT]       : ["Account","Compte"],
    [BUTTONS.DASHBOARD]     : ["Dashboard","Tableau de Bord"],
    [BUTTONS.PARAMS]        : ["Params","Params"],
    [BUTTONS.ADCOPY]        : ["AdCopies","AdCopies"],
    [BUTTONS.KEYWORDS]      : ["Keywords","Mots-clés"],
    [BUTTONS.GEOTARGET]     : ["Geo target","Geo ciblage"],
    [BUTTONS.BUDGETS]       : ["Budgets","Budgets"],
    [BUTTONS.RULES]         : ["Rules","Règles"],
    [BUTTONS.CAMPAIGNS]     : ["Campaigns","Campagnes"],
    [BUTTONS.LEADS]         : ["Leads","Leads"],
    [BUTTONS.STRATEGIES]    : ["Strategies","Stratégies"],
    [BUTTONS.REPORTS]       : ["Reports","Rapports"],
    [BUTTONS.BUILDER]       : ["Ads Builder","Constructeur d'annonces"],
    [BUTTONS.PROFILE]       : ["Profile","Profil"],
    [BUTTONS.SEARCH]        : ["Search","Recherche"],
    [BUTTONS.REMARKETING]   : ["Remarketing","Remarketing"],
    [BUTTONS.DISPLAY]       : ["Display","Afficher"],
    [BUTTONS.LEAD_ADS]      : ["Leads Ads","Leads Ads"],
    [BUTTONS.WAZE]          : ["Waze","Waze"],
    [BUTTONS.ADS_REPORT]    : ["Google Ads","Google Ads"],
    [BUTTONS.MANAGEMENT]    : ["Management","Management"],
    [BUTTONS.CREATE_USER]   : ["Create User","Create User"],
    [BUTTONS.USER_ACCOUNT]  : ["User-Account","User-Account"],
    [BUTTONS.USERS]         : ["Users","Users"],
  },
  PeriodPicker:{
    Today        : ["Today","Aujourd'hui"],
    Yesterday    : ["Yesterday","Hier"],
    LastWeek     : ["Last week","La semaine dernière"],
    CurrentWeek  : ["Current week","Cette semaine"],
    LastMonth    : ["Last month","Le mois dernier"],
    CurrentMonth : ["Current month","Ce mois-ci"],
    Last30Day    : ["Last 30 days","30 derniers jours"],
    Last7Day     : ["Last 7 days","7 derniers jours"],
    Last14Day    : ["Last 14 days","14 derniers jours"],
  },
}
