import {combineReducers}            from "redux"
import List                         from "./List"
import Retrieving                   from "./Retrieving"

const WhiteLabels = combineReducers({
  list       : List,
  retrieving : Retrieving
})

export default WhiteLabels
