import * as React                         from "react"
import {connect}                          from 'react-redux'
import QueryStringUtils                   from '../../utils/QueryStringUtils'
import DateUtils                          from '../../utils/DateUtils'
import {ChangePeriod}                     from "../../actions/Period"

const mapStateToProps = state => {
  return {
    period : state.Period
  }
}
const mapDispatchToProps = dispatch => {
  return {
    changePeriod : (period)=>dispatch(ChangePeriod(period.dateFrom, period.dateTo))
  }
}
const mergeProps = (sp,dp) => {
  return {
    ...sp,...dp,
  }
}
class PeriodSentry extends React.Component<any,any>{
  analyzePeriod(){
    const urlPeriod = QueryStringUtils.getPeriod()
    if(urlPeriod && !DateUtils.areSamePeriods([urlPeriod, this.props.period])){
      this.props.changePeriod(urlPeriod)
    }
  }
  componentDidMount(){this.analyzePeriod()}
  componentDidUpdate(){this.analyzePeriod()}
  render(){return null}
}
export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(PeriodSentry)
