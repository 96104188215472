import * as React                  from 'react'
import {
  PieChart,
  Pie,
  Cell,
}                                  from 'recharts'
import { ProxyGroup }              from '../../../../../model/pacy/ProxyGroup'
import { Proxy }                   from '../../../../../model/pacy'
import {
  LocalizeContextProps,
  withLocalize
}                                  from 'react-localize-redux'
import {
  Guideline,
  isPreciseGuideline,
  isVagueGuideline
}                                  from '../../../../../model/pacy/Guideline'
import Tooltip                     from "../../../../../components/tooltip/DesignedTooltip"
import NumFormatter                from "../../../../../utils/NumberFormatter"


interface ProxyGroupSharingPieOwnProps extends LocalizeContextProps {
  arrowColor           : string
  totalPreciseSpend    : number
  totalVagueWeight     : number
  proxy                : Proxy
  proxyGroup           : ProxyGroup
  currentGuideline     : Guideline
  preciseStartingAngle : number
  vagueStartingAngle   : number
}

interface ProxyGroupSharingPie extends ProxyGroupSharingPieOwnProps {}

const ProxyGroupSharingPie = (props: ProxyGroupSharingPie) => {
  const proxies = props.proxyGroup.proxies

  const totalActiveWeight = proxies.reduce((acc, proxy)=>{
    if(proxy.active || proxy.id === props.proxy.id) {
      return acc + proxy.weight
    }
    return acc
  }, 0)


  let otherSpend
  let otherWeight
  if (isPreciseGuideline(props.currentGuideline)) {
    otherSpend = props.totalPreciseSpend - props.currentGuideline.amounts[props.proxy.id]
  }
  else {
    otherWeight = totalActiveWeight - props.proxy.weight
  }

  const data = [
    {
      id: "others",
      name: "others",
      preciseSpend: otherSpend || 1, // No graph is drawn if these remain zero - occurs when a proxy group has zero budget anywhere
      vagueWeight: otherWeight || 1,
    },
    {
      id: props.proxy.id,
      preciseSpend: isPreciseGuideline(props.currentGuideline) ? props.currentGuideline.amounts[props.proxy.id] : null,
      vagueWeight: isVagueGuideline(props.currentGuideline) ? props.proxy.weight : null,
    }
  ]

  let pieColors = {
    "others": '#ddd',
    [props.proxy.id]: props.proxy.active ? '#00b2e3' : "#666666"
  }

  const determineTooltip = () => {
    if (!props.proxy.active) {
      return props.translate('pacyPlanner.disabled') as string
    }
    if (isPreciseGuideline(props.currentGuideline)) {
      return props.translate('pacyPlanner.attemptingSpend', {amount: NumFormatter.formatCurrency(props.currentGuideline.amounts[props.proxy.id])}) as string
    }
    if (isVagueGuideline(props.currentGuideline)) {
      const percentage = props.totalVagueWeight === 0 ? 0 : (props.proxy.weight / props.totalVagueWeight) * 100
      return props.translate('pacyPlanner.receivingInvestment', {
        percentage: NumFormatter.formatNumber(percentage, 0),
        amount: NumFormatter.formatCurrency(props.currentGuideline.amount * (percentage / 100))
      }) as string
    }
  }

  return (
    <Tooltip
      disabled={!props.currentGuideline}
      text={props.currentGuideline ? determineTooltip() : ""}
      arrowColor={props.arrowColor}
    >
      <div>
        <PieChart width={40} height={40}>
          <Pie
            dataKey={isPreciseGuideline(props.currentGuideline) ? "preciseSpend" : "vagueWeight"}
            isAnimationActive={false}
            data={data}
            cx={"50%"}
            cy={"50%"}
            fill={"#00B2E3"}
            blendStroke
            outerRadius={15}
            startAngle={props.preciseStartingAngle ? props.preciseStartingAngle || 0 : props.vagueStartingAngle || 0}
            endAngle={props.preciseStartingAngle ? (props.preciseStartingAngle + 360) || 360 : (props.vagueStartingAngle + 360) || 360}
          >
            {
              data.map((entry, index) => <Cell key={`cell-${index}`} fill={pieColors[entry.id]} />)
            }
          </Pie>
        </PieChart>
      </div>
    </Tooltip>
  )
}

export default withLocalize(ProxyGroupSharingPie)
