import * as React             from "react"
import {connect}              from "react-redux"
import ArrayUtils             from "../../utils/ArrayUtils"
import {PermissionContext}    from "../context/permissionContext"
import AccessDenied           from "./AccessDenied"

interface AccountPermissionBoundaryOwnProps{
  permissions : string[]
  accountId  ?: number
  onFailure  ?: JSX.Element //component
}
interface AccountPermissionBoundaryProps extends AccountPermissionBoundaryOwnProps{
  userPermissions : any[]
  children
}
const mapStateToProps = (state,ownProps:AccountPermissionBoundaryOwnProps)=>{
  return {
    userPermissions : state.Login.userLogged.permissions,
    accountId : ownProps.accountId?ownProps.accountId:state.Accounts.selected
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {}
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
class AccountPermissionBoundary extends React.Component<AccountPermissionBoundaryProps,any>{
  static defaultProps = {
    onFailure : <AccessDenied />
  }
  private accountSpecificPermissions
  private allAccountsPermissions
  constructor(props){
    super(props)
    this.extractPermissions = this.extractPermissions.bind(this)
    this.checkPermissions = this.checkPermissions.bind(this)
    this.extractPermissions()
  }
  componentDidUpdate(prevProps){
    if(prevProps.accountId != this.props.accountId || prevProps.userPermissions != this.props.userPermissions){
      this.extractPermissions()
    }
  }
  extractPermissions(){
    this.accountSpecificPermissions = this.props.userPermissions.find(x=>parseInt(x.AccountID,10) === this.props.accountId)
    this.allAccountsPermissions = this.props.userPermissions.find(x=>x.AccountID === null)
  }
  checkPermissions(context){
    const permissionsOk = this.props.permissions.filter(permission=>{
      // Check for account specific permissions
      if(this.accountSpecificPermissions){
        if(ArrayUtils.contain(this.accountSpecificPermissions.Permissions, permission)){
          return true
        }
      }
      // Check for account==null permissions - only if nothing in accountSpecificPermissions
      else if(this.allAccountsPermissions){
        if(ArrayUtils.contain(this.allAccountsPermissions.Permissions, permission)){
          return true
        }
      }
      return false
    })
    if(permissionsOk.length!==this.props.permissions.length){return this.props.onFailure}
    return this.props.children
  }
  render(){
    return <PermissionContext.Consumer>{this.checkPermissions}</PermissionContext.Consumer>
  }
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(AccountPermissionBoundary) as React.ComponentType<AccountPermissionBoundaryOwnProps>
