import "./GuidelinesList.css"
import * as React                from "react"
import moment                    from "moment"
import DateUtils                 from "../../../../utils/DateUtils"
import BudgetStats               from "../../../../model/Store/Statistics/BudgetStats"
import {ProxyGroup}              from "../../../../model/pacy/ProxyGroup"
import {
  Guideline,
  VagueGuideline,
  getLastGuidelinePerMonth,
  getGuidelineForDay
}                                from "../../../../model/pacy/Guideline"
import {StringMap}               from "../../../../model/generics"
import GuidelineDisplay          from "./Guideline"

interface GuidelinesListProps{
  proxyGroup         : ProxyGroup
  shownMonths        : string[]
  color              : string
  budgetStatsByMonth : StringMap<BudgetStats[]>
  setGuideline       : (year:string, month:string, amount:number)=>void
  readOnly          ?: boolean
  showConfirmModal  ?: boolean
}

const createFakeGuideline = (YM:string):VagueGuideline => ({
  id        : "",
  amount    : 0,
  year      : YM.slice(0,4),
  month     : YM.slice(5,7),
  createdBy : 0,
  createdOn : moment(YM).format("YYYY-MM-DD")
})

const GuidelinesList = (props:GuidelinesListProps) => {
  return (
    <div className="GuidelinesList">
      {props.shownMonths.map(YM=>{
        const day = moment(YM).endOf("month").format("YYYY-MM-DD")
        const guideline = getGuidelineForDay(props.proxyGroup.guidelines, day) || createFakeGuideline(YM)
        let setAmount
        if(DateUtils.isCurrentOrFutureYM(YM)){
          setAmount = (amount:number)=>props.setGuideline(YM.slice(0,4), YM.slice(5), amount)
        }
        return (
          <GuidelineDisplay
            key={guideline.id+YM}
            proxyGroup={props.proxyGroup}
            guideline={guideline}
            month={YM}
            setAmount={setAmount}
            color={props.color}
            highlight={YM===moment().format("YYYY-MM")}
            budgetStatsForMonth={props.budgetStatsByMonth ? props.budgetStatsByMonth[YM] : null} //To see after presentation
            isCarry={guideline.year+"-"+guideline.month !== YM}
            readOnly={props.readOnly}
            showConfirmModal={props.showConfirmModal}
          />
        )
      })}
    </div>
  )
}

export default GuidelinesList
