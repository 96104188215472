import "./Toggle.css"
import * as React from "react"
import {Translate} from "react-localize-redux"

interface ToggleProps{
  text       : string
  onClick    : ()=>void
  color      : string
  translate ?: boolean
}

const Toggle = (props:ToggleProps) => {
  return (
    <div className="Toggle" onClick={props.onClick} style={{backgroundColor: props.color}}>
      {props.translate
        ? <Translate id={props.text}/>
        : props.text
      }
    </div>
  )
}

export default Toggle
