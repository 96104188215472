import './AdGroupView.css'
import * as React     from "react"
import AdCopyViewer   from "../../../../../../components/adCopy/AdCopy"
import AdCopy         from "../../../../../../model/Store/AdCopyPatterns/AdCopy"

interface AdGroupViewProps{
  name     : string
  adCopies : AdCopy[]
}

const extractAdCopyUrl = (adCopy: AdCopy) => {
  const hostname = adCopy.finalUrl ? new URL(adCopy.finalUrl).hostname : "www.google.com"
  const path = adCopy.displayUrlPaths ? adCopy.displayUrlPaths : ""

  return hostname + "/" + path
}

const AdGroupView = (props:AdGroupViewProps) => {
  return (
    <div
      className={"AdCopiesRow"+(props.name.toUpperCase().includes("RLSA")?" RLSA":"")}
      key={props.name}
    >
      <div className="Name">
        <h4>{props.name}</h4>
      </div>
      <div className="AdCopies">
        {props.adCopies.map((adCopy,i)=>
          <AdCopyViewer
            key={adCopy.id}
            url={extractAdCopyUrl(adCopy)}
            headlines={adCopy.headlines}
            descriptions={adCopy.descriptions}
            status={adCopy.status}
            destination={adCopy.finalUrl}
          />
        )}
      </div>
    </div>
  )
}

export default AdGroupView
