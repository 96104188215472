import * as React                              from "react"
import { Translate }                           from "react-localize-redux"
import { MenuButtonDescription }               from "./menuButtonSignature"
import { Link }                                from "react-router-dom"
import SubMenu                                 from "./SubMenu"
import { withStyles, Tooltip }                 from "@material-ui/core"
import { connect }                             from "react-redux"
import { Alert }                               from "../../../../model/alerting/Alert"
import { StringMap }                           from "../../../../model/generics"
import { StyledBadge, useStyles }              from "../../../notifications/StyledBadge"
import PermissionsUtils                        from "../../../../utils/PermissionsUtils"
import { UserPermissions }                     from "../../../../model/Store/Login/UserLogged"
import UserConnected                           from "../../../../model/Store/Login/UserConnected"

interface MenuButtonOwnProps {
  button            : MenuButtonDescription
  accountPath       : string
  activeButton      : string
  activateButton    : (button: MenuButtonDescription) => void
  parentButton      : MenuButtonDescription
}
interface MenuButtonProps extends MenuButtonOwnProps {
  isMenuToggled : boolean,
  alerts : StringMap<Alert>,
  account : number,
  permissions : UserPermissions[],
  profile : UserConnected,
}

const mapStateToProps = (state) => {
  return {
    isMenuToggled : state.Menu.isMenuToggle,
    alerts : state.Alerts.Retrieved,
    account : state.Accounts.selected,
    permissions : state.Login.userLogged.permissions,
    profile : state.Login.userLogged.profile,
  }
}
const mapDispatchToProps = (_dispatch) => {
  return {}
}
const mergeProps = (SP, DP, ownProps) => {
  return {
    ...SP, ...DP, ...ownProps,
  }
}

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: 8,
  },
  arrow: {
    color: theme.palette.primary.main
  }
}))(Tooltip)

export const getAlertCount = (button : MenuButtonDescription, alerts : StringMap<Alert>, permissionUtils : PermissionsUtils) : number => {
  let alertCount = 0
  if (button.possibleAlerts){
    for (let possibleAlert of button.possibleAlerts){
      if(possibleAlert.requiredPermissions){
        if (!permissionUtils.validatePermissions(possibleAlert.requiredPermissions)) {
          continue
        }
      }
      if (alerts[possibleAlert.type]){
        alertCount += alerts[possibleAlert.type].count
      }
    }
  }
  return alertCount
}

const MenuButton = React.forwardRef((props: MenuButtonProps, ref: any) => {
  const path = (props.button.accountBased ? props.accountPath : "") + props.button.path
  let active = false
  let alertCount = 0

  const {isMenuToggled, accountPath, activeButton, activateButton, parentButton, ...remainingProps} = props

  const permissionUtils = new PermissionsUtils(props.account, props.permissions, props.profile)

  if (props.alerts) {
    alertCount += getAlertCount(props.button, props.alerts, permissionUtils)
    props.button.subButtons?.forEach((button) => {
      alertCount += getAlertCount(button, props.alerts, permissionUtils)
    })
  }

  if (props.button.name === props.activeButton) {
    active = true
  }
  else {
    props.button.subButtons?.forEach((button) => {
      if (button.name === props.activeButton) { active = true }
    })
  }

  let invisible = false
  if(active && props.button.subButtons){
    invisible = true
  }

  const classes = useStyles()

  return (
    <li
      {...remainingProps}
      ref={ref}
      className={"MenuButton" + (active ? " active" : "")}
      onClick={() => props.activateButton(props.button)}
    >
      {
        !props.isMenuToggled ?
          <HtmlTooltip
            arrow
            placement='right'
            title={
              props.parentButton ?
                <><Translate id={"menu.buttons." + props.parentButton.name} /> {">"} <Translate id={"menu.buttons." + props.button.name} /></>
              :
                <Translate id={"menu.buttons." + props.button.name} />
            }
          >
            <Link to={path}>
              <i className="material-icons" >{props.button.icon}</i>
              <p><Translate id={"menu.buttons." + props.button.name} /></p>
              <StyledBadge
                classes={{badge: classes.badgeMenuToggled}}
                badgeContent={alertCount}
                color="secondary"
                invisible={invisible || !alertCount}
              ></StyledBadge>
            </Link>
          </HtmlTooltip>
        :
          <Link to={path}>
            <i className="material-icons" >{props.button.icon}</i>
            <p><Translate id={"menu.buttons." + props.button.name} /></p>
            <StyledBadge
              badgeContent={alertCount}
              color="secondary"
              invisible={invisible || !alertCount}
            ></StyledBadge>
          </Link>
      }
      <SubMenu
        parentButton={props.button}
        buttons={props.button.subButtons}
        path={path}
        parentActive={active}
        activeButton={props.activeButton}
        activateButton={props.activateButton}
      />
    </li>
  )
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MenuButton)
