import {translate} from "../../utils/localisation/Localisation"

export enum StandardVehicleType {
  CAR,
}
export const StandardVehicleTypeEnumReversing = (x:StandardVehicleType)=>{
  if(x === StandardVehicleType.CAR){return "CAR"}
}
export const VehicleTypeEnumReversing = (x:VehicleType)=>{
  if(x.vehicleType===StandardVehicleType.CAR){return "CAR"}
}
export interface VehicleType {
  vehicleType : StandardVehicleType,
  text        : string,
}
export const KNOWN_VEHICLE_TYPE : VehicleType[] = [
  {vehicleType: StandardVehicleType.CAR, text:"Car"},
]
export const findVehicleType = (s:string):StandardVehicleType => {
  s = s.toLowerCase().trim()
  if(s.includes("car")){return StandardVehicleType.CAR}
  throw Error("Unknown vehicle type. "+s+" given.")
}
