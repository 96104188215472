import "./index.css"
import * as React         from 'react'
import {connect}          from 'react-redux'
import {
  Drawer,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  TextField
}                         from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                         from "react-localize-redux"
import CustomSelect       from "../../components/input/CustomSelect"

import {
  requestFeeds,
}                         from "../../actions/feeds/Feeds"
import {
  AW_V1,
  MI_AW_V1,
  FB_V1,
  MI_FB_V1,
  GEOFENCING_PV_V1,
  GEOFENCING_NPV_V1,
  DCM_V1,
  MI_DCM_V1,
  AW_SHOPPING_V1,
  FeedConfig
}                         from "../../model/feed/FeedConfig"

interface OwnProps extends LocalizeContextProps {
  open    : boolean
  onClose : ()=>void
  loading : boolean
  options : string[]
}
const mapStateToProps = (state, ownProps)=>{
  return {
    accountId : state.Accounts.selected as number,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    requestFeeds : (feedConfig:FeedConfig, sync:boolean) => dispatch(requestFeeds(feedConfig, sync)),
  }
}

const CreationModal = (props:OwnProps&ReturnType<typeof mapStateToProps>&ReturnType<typeof mapDispatchToProps>) => {
  const [lang,             setLang             ] = React.useState<"FR"|"EN">("EN")
  const [inventoryLang,    setInventoryLang    ] = React.useState<"FR"|"EN">("EN")
  const [vehicleState,     setVehicleState     ] = React.useState<"USED"|"NEW">("USED")
  const [vehicleType,      setVehicleType      ] = React.useState<"CAR"|"NPV">("CAR")
  const [feedType,         setFeedType         ] = React.useState<FeedConfig["type"]>("AW_V1")
  const [storeCode,        setStoreCode        ] = React.useState<string>("")
  const [errorStoreCode,   setErrorStoreCode   ] = React.useState<string>("")

  const validateStoreCode = () : boolean => {
    if(!storeCode){
      setErrorStoreCode(props.translate("feeds.create-feed.storeCodeRequired")+"")
      return false
    } else {
      if (storeCode.length > 64 || /(https?:\/\/)|(www\.)|[<>]/i.test(storeCode)) {
        setErrorStoreCode(props.translate("feeds.create-feed.storeCodeError")+"")
        return false
      } else {
        setErrorStoreCode("")
        return true
      }
    }
  }

  const returnOptions = () => {
    if (props.options) {
      const optObj = {}
      for (const option of props.options) {
        optObj[option] = props.translate(`feeds.create-feed.${option}`)+""
      }
      return optObj
    }
    return  {}
  }

  const loadingKey = "loading"

  return (
    <Drawer
      anchor="right"
      className="CreateFeedDrawer"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>
        <h3>{props.translate("feeds.create-feed.title")}</h3>
      </DialogTitle>
      <DialogContent>
        <CustomSelect
          className="Select"
          fullWidth
          value={props.loading ? loadingKey : feedType}
          onChange={(e) => {
            setFeedType(e)
            setStoreCode("")
            setErrorStoreCode("")
          }}
          helperText={props.translate("feeds.create-feed.feedType")+""}
          key="type"
          options={props.loading ? {[loadingKey]: "Loading feed options..."} : returnOptions()}
        />
        <CustomSelect
          className="Select"
          fullWidth
          value={lang}
          onChange={(e)=>{
            setLang(e)
            setInventoryLang(e)
          }}
          helperText={props.translate("feeds.create-feed.language")+""}
          key="lang"
          options={{
            FR : props.translate("feeds.language-lookup.FR")+"",
            EN : props.translate("feeds.language-lookup.EN")+"",
          }}
        />
        <CustomSelect
          className="Select"
          fullWidth
          value={inventoryLang}
          onChange={(e)=>{setInventoryLang(e)}}
          helperText={props.translate("feeds.create-feed.inv-language")+""}
          key="inventoryLang"
          options={{
            FR : props.translate("feeds.language-lookup.FR")+"",
            EN : props.translate("feeds.language-lookup.EN")+"",
          }}
        />
        <CustomSelect
          className="Select"
          fullWidth
          value={vehicleType}
          onChange={(e)=>{setVehicleType(e)}}
          helperText={props.translate("feeds.create-feed.vehicleType")+""}
          key="vehicleType"
          options={feedType === "DCM_V1" || feedType === "FB_V1" ?
            {
              CAR : props.translate("feeds.vehicleType.CAR")+"",
              NPV : props.translate("feeds.vehicleType.NPV")+"",
            }
            :
            {
              CAR : props.translate("feeds.vehicleType.CAR")+"",
            }
          }
        />
        {(feedType === "AW_V1"|| feedType === "MI_AW_V1" || feedType === "DCM_V1" || feedType === "MI_DCM_V1") && (
          <CustomSelect
            className="Select"
            fullWidth
            value={vehicleState}
            onChange={(e)=>{setVehicleState(e)}}
            helperText={props.translate("feeds.create-feed.vehicleSte")+""}
            key="vehicleState"
            options={{
              USED : props.translate("feeds.vehicleState.USED")+"",
              NEW  : props.translate("feeds.vehicleState.NEW")+"",
            }}
          />
        )}
        {(feedType === "AW_SHOPPING_V1") && (
          <TextField
            id="storeCode"
            margin="normal"
            type="text"
            label={props.translate("feeds.tooltip.storeCode")}
            variant="outlined"
            required
            helperText={errorStoreCode}
            onChange={(e)=>{setStoreCode(e.target.value.trim())}}
            onKeyUp={()=>{validateStoreCode()}}
            error={errorStoreCode.length === 0 ? false : true}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
            disabled={props.loading}
            color="secondary"
            variant="outlined"
            onClick={props.onClose}
          >
          {props.translate("feeds.create-feed.cancel")}
        </Button>
        <Button
          disabled={props.loading}
          color="primary"
          variant="contained"
          onClick={()=>{
            if(feedType === "AW_V1"){
              const feedConfig : AW_V1 = {
                type           : "AW_V1",
                accountId      : props.accountId,
                lang,
                inventoryLang,
                vehicleType,
                vehicleState,
              }
              props.requestFeeds(feedConfig, true)
            }
            else if(feedType === "MI_AW_V1"){
              const feedConfig : MI_AW_V1 = {
                type           : "MI_AW_V1",
                accountId      : props.accountId,
                lang,
                inventoryLang,
                vehicleType,
                vehicleState,
              }
              props.requestFeeds(feedConfig, true)
            }
            else if (feedType === "FB_V1"){
              const feedConfig : FB_V1 = {
                type           : "FB_V1",
                accountId      : props.accountId,
                lang,
                inventoryLang,
                vehicleType,
              }
              props.requestFeeds(feedConfig, true)
            }
            else if (feedType === "MI_FB_V1"){
              const feedConfig : MI_FB_V1 = {
                type           : "MI_FB_V1",
                accountId      : props.accountId,
                lang,
                inventoryLang,
                vehicleType,
              }
              props.requestFeeds(feedConfig, true)
            }
            else if (feedType === "GEOFENCING_PV_V1") {
              const feedConfig : GEOFENCING_PV_V1 = {
                type           : "GEOFENCING_PV_V1",
                accountId      : props.accountId,
                lang,
                inventoryLang,
                vehicleType,
              }
              props.requestFeeds(feedConfig, true)
            }
            else if (feedType === "GEOFENCING_NPV_V1") {
              const feedConfig : GEOFENCING_NPV_V1 = {
                type           : "GEOFENCING_NPV_V1",
                accountId      : props.accountId,
                lang,
                inventoryLang,
                vehicleType,
              }
              props.requestFeeds(feedConfig, true)
            }
            else if (feedType === "DCM_V1") {
              const feedConfig : DCM_V1 = {
                type           : "DCM_V1",
                accountId      : props.accountId,
                lang,
                inventoryLang,
                vehicleType,
                vehicleState,
              }
              props.requestFeeds(feedConfig, true)
            }
            else if (feedType === "MI_DCM_V1") {
              const feedConfig : MI_DCM_V1 = {
                type           : "MI_DCM_V1",
                accountId      : props.accountId,
                lang,
                inventoryLang,
                vehicleType,
                vehicleState,
              }
              props.requestFeeds(feedConfig, true)
            }
            else if (feedType === "AW_SHOPPING_V1"){
              if(validateStoreCode()){
                const feedConfig : AW_SHOPPING_V1 = {
                  type           : "AW_SHOPPING_V1",
                  accountId      : props.accountId,
                  lang,
                  inventoryLang,
                  vehicleType,
                  storeCode
                }
                props.requestFeeds(feedConfig, true)
                setStoreCode("")
              }
            }
          }}
        >
          {props.translate("feeds.create-feed.confirm")}
        </Button>
      </DialogActions>
    </Drawer>
  )
}

export default (
  connect(mapStateToProps,mapDispatchToProps)(
    withLocalize(
      CreationModal
    )
  )
)
