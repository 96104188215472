import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const setSaving = (state,action) => {
  return {
    ...state,
    isSaving: action.status
  }
}

const setFailed = (state, action) => {
  return {
    ...state,
    failed: action.status
  }
}

interface LocaleState {
  isSaving : boolean
  failed   : boolean
}

const defaultState = {
  isSaving : false,
  failed   : false
}

const SettingLocale = ReducerUtils.createReducer<LocaleState>(defaultState,{
  [Actions.SETTING_USER_LOCALE]    : setSaving,
  [Actions.FAILED_SET_USER_LOCALE] : setFailed,
})
export default SettingLocale
