import * as React            from "react"
import {PacingRatioConfig}   from "../../../../../model/pacy/PacingRatioConfig"
import PacingRatioDiffFormat from './PacingRatioDiffFormat/PacingRatioDiffFormat'

interface PacingRatioDiffProps{
  pacingRatioConfig         : PacingRatioConfig
  previousPacingRatioConfig : PacingRatioConfig
}

const PacingRatioDiff = (props:PacingRatioDiffProps) => {
  // Previously and currently set to AllProviders Active
  if (props.previousPacingRatioConfig.allActive === props.pacingRatioConfig.allActive && props.pacingRatioConfig.allActive) {
    return (
      <div>
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.all} ratio={props.pacingRatioConfig.all} provider="pacingRatio.setup.NA" />
      </div>
    )
  }
  // Previously and currently set to AllProviders Inactive (so it's by provider)
  if (props.previousPacingRatioConfig.allActive === props.pacingRatioConfig.allActive && !props.pacingRatioConfig.allActive) {
    return (
      <div>
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.aw} ratio={props.pacingRatioConfig.aw} provider="pacingRatio.setup.aw" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.bing} ratio={props.pacingRatioConfig.bing} provider="pacingRatio.setup.bing" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.facebook} ratio={props.pacingRatioConfig.facebook} provider="pacingRatio.setup.facebook" />
      </div>
    )
  }
  // Previously set to AllProviders Active and currently set to AllProviders Inactive (so it's now by provider)
  if (props.previousPacingRatioConfig.allActive !== props.pacingRatioConfig.allActive && !props.pacingRatioConfig.allActive) {
    return (
      <div>
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.all} ratio={props.pacingRatioConfig.aw} provider="pacingRatio.setup.aw" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.all} ratio={props.pacingRatioConfig.bing} provider="pacingRatio.setup.bing" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.all} ratio={props.pacingRatioConfig.facebook} provider="pacingRatio.setup.facebook" />
      </div>
    )
  }
  // Previously set to AllProviders Inactive (so it was by provider) and currently set to AllProviders Active
  if (props.previousPacingRatioConfig.allActive !== props.pacingRatioConfig.allActive && props.pacingRatioConfig.allActive) {
    return (
      <div>
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.aw} ratio={props.pacingRatioConfig.all} provider="pacingRatio.setup.aw" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.bing} ratio={props.pacingRatioConfig.all} provider="pacingRatio.setup.bing" />
        <PacingRatioDiffFormat prevRatio={props.previousPacingRatioConfig.facebook} ratio={props.pacingRatioConfig.all} provider="pacingRatio.setup.facebook" />
      </div>
    )
  }
}

export default PacingRatioDiff
