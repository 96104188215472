import * as React       from "react"
import {Translate}      from "react-localize-redux"
import DateUtils        from "../../utils/DateUtils"

interface DataVerificationProps{
  period   : any
  vehicles : any[]
  compiled : any[]
  children
}
const DataVerification = (props:DataVerificationProps) => {
  if(props.vehicles.length === 0){
    return (
      <div className="EmptySoldCarsTable">
        <h4>
          <Translate id="inventoryTurn.table.noRemovedMessage" data={{days:DateUtils.getPeriodLengthInDays(props.period)}}/>
        </h4>
      </div>
    )
  }
  if(props.compiled.length === 0){
    return (
      <div className="EmptySoldCarsTable">
        <h4>
          <Translate id="inventoryTurn.table.noInfluencedMessage" data={{days:DateUtils.getPeriodLengthInDays(props.period)}}/>
        </h4>
      </div>
    )
  }
  return props.children
}

export default DataVerification
