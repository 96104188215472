import "./ArrowButton.css"
import * as React from "react"
import Icon       from "@material-ui/core/Icon"
import IconButton from "@material-ui/core/IconButton"

interface  ArrowButtonProps {
  orientation : "left" | "right" | "up" | "down"
  onClick     : ()=>void
  small      ?: boolean
}

const styleByOrientation = {
  left  : {transform: "rotate(180deg)"},
  right : {},
  up    : {transform: "rotate(270deg)"},
  down  : {transform: "rotate(90deg)"},
}

const ArrowButton = (props:ArrowButtonProps) => {
  return (
    <IconButton
      className={"ArrowButton "+props.orientation}
      style={styleByOrientation[props.orientation]}
      onClick={props.onClick}
    >
      <Icon fontSize={props.small?"small":"large"}>play_arrow</Icon>
    </IconButton>
  )
}

export default ArrowButton
