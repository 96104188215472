import ObjectUtils from "../../utils/ObjectUtils"
import {
  AdBuilderCompatibleCampaignGroup,
  AdBuilderCompatibleLang,
  AdBuilderCompatibleVehicleState,
  ConcreteMarketingEventBoundaries,
  MarketingApproach,
  MarketingEvent,
  MarketingEventBoundariesTypes as MEBTypes,
  ProtoMarketingApproach,
  ProtoMarketingEvent,
  ProtoMarketingEventBoundaries,
  AdBuilderCompatibleNonInventoryCampaignType,
  AdBuilderCompatibleGroup,
  NonInventoryMarketingApproach,
  RSAAdTemplate,
} from "./index"
import {RestrictedLengthList} from "../generics"

export const ProtoMarketingEventFactory = (event:MarketingEvent & {lang: string}):ProtoMarketingEvent => {
  return {
    id            : event.id,
    boundaries    : ProtoMarketingEventBoundariesFactory(event),
    rsaApproaches : ProtoMarketingApproachesFactory(event),
    startDate     : event.startDate,
    endDate       : event.endDate,
    title         : event.title,
    lang          : event.lang
  }
}

const ProtoMarketingEventBoundariesFactory = (event:MarketingEvent):ProtoMarketingEventBoundaries[] => {
  return event.boundaries.map(boundary=>{
    let typeSpecificParams
    switch(boundary.type){
      case MEBTypes.SPECIFIC_CAMPAIGN :
        typeSpecificParams = {
          campaignName  : boundary.campaignName,
          //Put this in boundaries for easier edit. an approach's group should always be the same as the campaign
          campaignGroup : event.rsaApproaches[0].group
        }
        break
      case MEBTypes.SPECIFIC_M :
        typeSpecificParams = {
          cleanerId : boundary.cleanerId,
          make      : boundary.cleanerId
        }
        break
      case MEBTypes.SPECIFIC_MM :
        typeSpecificParams = {
          cleanerId : boundary.cleanerId,
          make      : boundary.cleanerId,
          model     : boundary.cleanerId
        }
        break
      case MEBTypes.SPECIFIC_MMY :
        typeSpecificParams = {
          cleanerId : boundary.cleanerId,
          make      : boundary.cleanerId,
          model     : boundary.cleanerId,
          year      : boundary.year
        }
        break
      case MEBTypes.SPECIFIC_MY :
        typeSpecificParams = {
          cleanerId : boundary.cleanerId,
          make      : boundary.cleanerId,
          year      : boundary.year
        }
        break
      case MEBTypes.SPECIFIC_Y :
        typeSpecificParams = {
          year : boundary.year
        }
        break
      case MEBTypes.NON_INVENTORY :
        typeSpecificParams = {
          campaignType : boundary.campaignType
        }
        break
      default:
        typeSpecificParams = {}
    }
    return {
      type          : boundary.type.toUpperCase(),
      vehicleType   : boundary.vehicleType?.toUpperCase() ?? null,
      vehicleState  : boundary.vehicleState?.toUpperCase() ?? null,
      lang          : event.rsaApproaches[0].lang.toUpperCase(),
      ...typeSpecificParams
    }
  })
}

const ProtoMarketingApproachesFactory = (event:MarketingEvent):ProtoMarketingApproach[] => {
  const protoApproaches = ObjectUtils.getObjectValues(
    event.rsaApproaches.reduce((byGroup:any, approach:MarketingApproach)=>{
      const group = approach.group + (approach.audienceAWId!=="NO_AUDIENCE" ? "_RLSA" : "") + ("_"+approach.lang)
      if(!byGroup[group]){byGroup[group] = []}
      byGroup[group].push(approach)
      return byGroup
    },{})
  ).map((approaches:MarketingApproach[])=>{
    //We merge together all audiences
    const audienceIds = approaches[0].audienceAWId === "NO_AUDIENCE"
      ? undefined
      : [...new Set(approaches.reduce((ids, approach)=>{
          ids.push(approach.audienceAWId)
          return ids
        },[]))]
    return {
      id           : approaches[0].id,
      group        : approaches[0].group,
      lang         : approaches[0].lang,
      audienceAWId : audienceIds,
      patterns     : approaches[0].patterns,
      ...(approaches[0] as NonInventoryMarketingApproach).campaignName
        ? {campaignName : (approaches[0] as NonInventoryMarketingApproach).campaignName}
        : {},
      ...(approaches[0] as NonInventoryMarketingApproach).adGroupName
        ? {adGroupName : (approaches[0] as NonInventoryMarketingApproach).adGroupName}
        : {}

    }
  })
  return protoApproaches
}

export const MarketingEventFactory = (protoEvent:ProtoMarketingEvent):MarketingEvent => {
  if(protoEvent.rsaApproaches.length < 1){throw Error("A marketing event must have at least one approach")}

  return {
    id             : protoEvent.id,
    active         : true,
    boundaries     : MarketingEventBoundariesFactory(protoEvent.boundaries),
    rsaApproaches  : MarketingApproachesFactory(protoEvent.rsaApproaches) as any,
    startDate      : protoEvent.startDate,
    endDate        : protoEvent.endDate,
    lastModifiedOn : new Date(),
    lastModifiedBy : 0,
    title          : protoEvent.title,
  }
}

const MarketingEventBoundariesFactory = (protoBoundaries:ProtoMarketingEventBoundaries[]):ConcreteMarketingEventBoundaries[] => {
  return protoBoundaries.map(protoBoundary=>{
    const base:any = {
      type         : protoBoundary.type as any,
      vehicleType  : protoBoundary?.vehicleType ?? null,
      vehicleState : protoBoundary?.vehicleState ?? null,
    }
    if(base.type === MEBTypes.NON_INVENTORY){
      return {
        ...base,
        campaignType : protoBoundary.campaignType
      }
    }
    if(base.type === MEBTypes.SPECIFIC_S){
      return base
    }
    if(base.type === MEBTypes.SPECIFIC_CAMPAIGN){
      return {
        ...base,
        campaignName : protoBoundary.campaignName
      }
    }
    if(protoBoundary.year){
      base.year = protoBoundary.year
    }
    return {
      ...base,
      cleanerId : protoBoundary.model || protoBoundary.make //Model as priority if set
    }
  })
}

const MarketingApproachesFactory = (
  protoApproaches:ProtoMarketingApproach[]
):MarketingApproach[] => {
  const approaches = protoApproaches.reduce((all, proto)=>{
    if(proto.audienceAWId === undefined){
      all.push({
        group          : proto.group,
        lang           : proto.lang,
        audienceAWId   : "NO_AUDIENCE",
        patterns       : proto.patterns,
        ...(proto.adGroupName && {adGroupName : proto.adGroupName}),
        ...(proto.campaignName && {campaignName : proto.campaignName})
      })
    }
    else{
      //one approach per audience selected
      all = all.concat(proto.audienceAWId.map(audienceId=>({
        group          : proto.group,
        lang           : proto.lang,
        audienceAWId   : audienceId,
        patterns       : proto.patterns,
        ...(proto.adGroupName && {adGroupName : proto.adGroupName}),
        ...(proto.campaignName && {campaignName : proto.campaignName})
      })))
    }
    return all
  },[])
  return approaches
}

export const getEmptyProtoMarketingApproach = (
  // group = CampaignGroup for Inventory, Campaign name for credit/service, adgroup name for branding
  // adGroupName for credit/servce, audience id for branding 
  group             : string,
  lang              : AdBuilderCompatibleLang,
  isRLSA            : boolean,
  audienceIds      ?: string[],
  vehicleState     ?: AdBuilderCompatibleVehicleState,
  adGroupName      ?: string,
  campaignType     ?: string,
  existingPatterns ?: RestrictedLengthList<RSAAdTemplate, 1|2|3>
):ProtoMarketingApproach => {
  campaignType = campaignType?.toUpperCase()
  const isSRPMM = group === AdBuilderCompatibleCampaignGroup.SRP_MM;
  // position -1 = unpinned
  if (vehicleState === AdBuilderCompatibleVehicleState.NEW && lang === AdBuilderCompatibleLang.EN){
    return {
      group        : group,
      lang         : lang,
      audienceAWId : isRLSA ? audienceIds||[] : undefined,
      patterns     : existingPatterns || [{
        id : -1,
        headlines    : [
          {text : "<make> <model>", position : -1, id : 0},
          {text : "Starting at $<price>", position : -1, id : 1},
          {text : "Schedule a Test Drive Today", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Searching for a brand new <MakeModel>? Visit us today.", position : -1, id : 0},
          {text : "Our experts are ready to help you find the vehicle you have been looking for.", position : -1, id : 1}
        ],
      },{
        id : -11,
        headlines    : [
          {text : "Save Big, Only in <Month>", position : -1, id : 0},
          {text : "Browse Available Inventory", position : -1, id : 1},
          {text : "Shop from Home", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Reserve your new <MakeModel> today!", position : -1, id : 0},
          {text : "Apply for financing, value your trade, & schedule your test drive online.", position : -1, id : 1}
        ],
      },{
        id : -111,
        headlines    : [
          {text : "Find the Perfect Car", position : -1, id : 0},
          {text : "Starting at $<price>", position : -1, id : 1},
          {text : "Schedule a Test Drive Today", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Reserve your new <MakeModel> today!", position : -1, id : 0},
          {text : "Our experts are ready to help you find the vehicle you have been looking for.", position : -1, id : 1}
        ],
      }],
    }
  }
  if (vehicleState === AdBuilderCompatibleVehicleState.USED && lang === AdBuilderCompatibleLang.EN){
    return {
      group        : group,
      lang         : lang,
      audienceAWId : isRLSA ? audienceIds||[] : undefined,
      patterns     : existingPatterns || [{
        id : -1,
        headlines    : [
          {text : "<make> <model>", position : -1, id : 0},
          {text : "Starting at $<price>", position : -1, id : 1},
          {text : "Schedule a test drive today", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Reserve a pre-owned <MakeModel> today!", position : -1, id : 0},
          {text : "Apply for financing, value your trade, & schedule your test drive online.", position : -1, id : 1}
        ],
      },{
        id : -11,
        headlines    : [
          {text : "Find the Perfect Car", position : -1, id : 0},
          {text : isSRPMM ? "<make> <model>" : "<year> <make> <model>", position : -1, id : 1},
          {text : "Shop from Home", position : -1, id : 2}
        ],
        descriptions : [
          {text : "The <month> sale is on. Time to save on your next <model>!", position : -1, id : 0},
          {text : "Apply for financing, value your trade, & schedule your test drive online.", position : -1, id : 1}
        ],
      },{
        id : -111,
        headlines    : [
          {text : "Find the Perfect Car", position : -1, id : 0},
          {text : "Browse Available Inventory", position : -1, id : 1},
          {text : "Schedule a Test Drive Today", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Searching for a used <MakeModel>? Visit us today.", position : -1, id : 0},
          {text : "The <month> sale is on. Time to save on your next <model>!", position : -1, id : 1}
        ],
      }],
    }
  }
  if (vehicleState === AdBuilderCompatibleVehicleState.NEW && lang === AdBuilderCompatibleLang.FR){
    return {
      group        : group,
      lang         : lang,
      audienceAWId : isRLSA ? audienceIds||[] : undefined,
      patterns     : existingPatterns || [{
        id : -1,
        headlines    : [
          {text : "<Make> <Model>", position : -1, id : 0},
          {text : "À partir de <price>$", position : -1, id : 1},
          {text : "Planifiez un essai routier", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Votre <MakeModel> vous attend.", position : -1, id : 0},
          {text : "Nos experts vous aideront à prendre la bonne décision!", position : -1, id : 1}
        ],
      },{
        id : -11,
        headlines    : [
          {text : "À partir de <price>$", position : -1, id : 0},
          {text : "Découvrez notre inventaire", position : -1, id : 1},
          {text : "Magasinez en ligne", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Achetez maintenant, profitez des meilleurs prix !", position : -1, id : 0},
          {text : "Nous sommes votre partenaire pour l'achat de votre prochain véhicule.", position : -1, id : 1}
        ],
      },{
        id : -111,
        headlines    : [
          {text : "Trouvez la voiture parfaite", position : -1, id : 0},
          {text : "À partir de <price>$", position : -1, id : 1},
          {text : "Planifiez un essai routier", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Profitez des promotions en cours chez votre concessionnaire.", position : -1, id : 0},
          {text : "Nos experts vous aideront à prendre la bonne décision!", position : -1, id : 1}
        ],
      }],
    }
  }
  if (vehicleState === AdBuilderCompatibleVehicleState.USED && lang === AdBuilderCompatibleLang.FR){
    return {
      group        : group,
      lang         : lang,
      audienceAWId : isRLSA ? audienceIds||[] : undefined,
      patterns     : existingPatterns || [{
        id : -1,
        headlines    : [
          {text : "<Make> <Model>", position : -1, id : 0},
          {text : "À partir de <price>$", position : -1, id : 1},
          {text : "Planifiez un essai routier", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Profitez des promotions en cours chez votre concessionnaire.", position : -1, id : 0},
          {text : "Contactez nos experts pour trouver le véhicule qui vous convient.", position : -1, id : 1}
        ],
      },{
        id : -11,
        headlines    : [
          {text : "Trouvez la voiture parfaite", position : -1, id : 0},
          {text : isSRPMM ? "<Make> <Model>" : "<Make> <Model> <Year>", position : -1, id : 1},
          {text : "Magasinez en ligne", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Vous avez eu un coup de cœur pour le <Model>? N’hésitez plus!", position : -1, id : 0},
          {text : "Nos experts vous aideront à prendre la bonne décision!", position : -1, id : 1}
        ],
      },{
        id : -111,
        headlines    : [
          {text : "Trouvez la voiture parfaite", position : -1, id : 0},
          {text : "Découvrez notre inventaire", position : -1, id : 1},
          {text : "Planifiez un essai routier", position : -1, id : 2}
        ],
        descriptions : [
          {text : "Achetez maintenant, profitez des meilleurs prix de l'année!", position : -1, id : 0},
          {text : "Nous sommes votre partenaire pour l'achat de votre prochain véhicule d'occasion.", position : -1, id : 1}
        ],
      }],
    }
  }
  if (adGroupName && campaignType === AdBuilderCompatibleNonInventoryCampaignType.BRANDING && lang === AdBuilderCompatibleLang.EN){
    return {
      group,
      lang,
      audienceAWId : isRLSA ? audienceIds||[] : undefined,
      adGroupName  : group,
      patterns     :  existingPatterns || [{
        id : -1,
        headlines:[
          {text:"<name>",position:-1, id:0},
          {text:"Your <make> Dealership", position:-1, id:1},
          {text:"Located in <city>", position:-1, id:2},
          {text:"Browse Available Inventory", position:-1, id:3},
          {text:"Schedule a Test Drive Today", position:-1, id:4},
          {text:"Shop from Home", position:-1, id:5},
          {text:"Contact our Trusted Experts", position:-1, id:6},
          {text:"Find the Perfect Car", position:-1, id:7}
        ],
        "descriptions":[
          {text:"Searching for a new or pre-owned vehicle? Visit us today.", position:-1, id:0},
          {text:"Our experts are ready to help you find the vehicle you have been looking for.", position:-1, id:1},
          {text:"Try our online tool to shop and buy your car from the comfort of your home!", position:-1, id:2},
          {text:"Our experts are ready to help you find, buy & service the vehicle you've always wanted.", position:-1, id:3}
        ]
      }]
    }
  }
  if (adGroupName && campaignType === AdBuilderCompatibleNonInventoryCampaignType.BRANDING && lang === AdBuilderCompatibleLang.FR){
    return {
      group,
      lang,
      audienceAWId : isRLSA ? audienceIds||[] : undefined,
      adGroupName  : group,
      patterns     : existingPatterns || [{
        id : -1,
        headlines:[
          {text:"<name>",position:-1, id : 0},
          {text:"Votre concessionnaire <make>", position:-1, id:1},
          {text:"Situé à <city>", position:-1, id:2},
          {text:"Découvrez notre inventaire", position:-1, id:3},
          {text:"Planifiez un essai routier", position:-1, id:4},
          {text:"Magasinez en ligne", position:-1, id:5},
          {text:"Contactez nos experts", position:-1, id:6},
          {text:"Trouvez la voiture parfaite", position:-1, id:7}
        ],
        descriptions:[
          {text:"<name> est votre partenaire pour l'achat de votre prochain véhicule.", position:-1, id:0},
          {text:"Profitez des nouvelles promotions en cours chez <name> à <city>.", position:-1, id:1},
          {text:"Nos experts vous aideront à prendre la bonne décision!", position:-1, id:2},
          {text:"Nous avons le véhicule neuf ou d'occasion qui répond à vos besoins chez <name>.", position:-1, id:3}
        ]
      }]
    }
  }
  if (adGroupName && campaignType === AdBuilderCompatibleNonInventoryCampaignType.CREDIT && lang === AdBuilderCompatibleLang.EN){
    return {
      group,
      lang,
      audienceAWId : isRLSA ? audienceIds||[] : undefined,
      adGroupName,
      campaignName : group,
      patterns     : existingPatterns || [{
        id : -1,
        headlines:[
          {text:"<VehicleSubType> <CreditType>", position:-1, id: 0},
          {text:"Get Pre-Approved Now", position:-1, id: 1},
          {text:"Financing in <city>", position:-1, id: 2},
          {text:"Loan at the Lowest Rate", position:-1, id: 3},
          {text:"Contact our Trusted Experts", position:-1, id: 4},
          {text:"All Types of Credit", position:-1, id: 5}
        ],
        descriptions:[
          {text:"Looking to finance your next vehicle? Get the lowest rate today.", position:-1, id: 0},
          {text:"We offer the most attractive financing rates regardless of your financial situation!", position:-1, id: 1},
          {text:"We make sure that you get the financing that's right for you. Contact us today!", position:-1, id: 2},
          {text:"Get pre-approved for your car loan within minutes. Call our experts!", position:-1, id: 3}
        ]
      }]
    }
  }
  if (adGroupName && campaignType === AdBuilderCompatibleNonInventoryCampaignType.CREDIT && lang === AdBuilderCompatibleLang.FR){
    return {
      group,
      lang,
      audienceAWId : isRLSA ? audienceIds||[] : undefined,
      adGroupName,
      campaignName : group,
      patterns     : existingPatterns || [{
        id : -1,
        headlines:[
          {text:"<VehicleSubType> <CreditType>", position:-1, id: 0},
          {text:"Pré-approbation rapide", position:-1, id: 1},
          {text:"Financement à <city>", position:-1, id: 2},
          {text:"Prêt auto au plus bas taux", position:-1, id: 3},
          {text:"Contactez nos experts", position:-1, id: 4},
          {text:"Tous les types de crédit", position:-1, id: 5}
        ],
        descriptions:[
          {text:"À la recherche de financement pour votre véhicule? Obtenez le plus bas taux.", position:-1, id: 0},
          {text:"Nous offrons les taux les plus intéressants quelle que soit votre situation financière.", position:-1, id: 1},
          {text:"Nous nous assurons de vous offrir le financement qui vous convient. Contactez-nous!", position:-1, id: 2},
          {text:"Obtenez une pré-approbation pour votre prêt auto en quelques minutes. Appelez-nous!", position:-1, id: 3}
        ]
      }]
    }
  }
  if (adGroupName && campaignType === AdBuilderCompatibleNonInventoryCampaignType.SERVICE && lang === AdBuilderCompatibleLang.EN){
    return {
      group,
      lang,
      audienceAWId : isRLSA ? audienceIds||[] : undefined,
      adGroupName,
      campaignName : group,
      patterns: existingPatterns || [{
        id : -1,
        headlines:[
          {text:"Repair of <Model>", position:-1, id: 0},
          {text:"Maintenance of your <Model>", position:-1, id: 1},
          {text:"<Model> Parts", position:-1, id: 2},
          {text:"<Model> Dealership", position:-1, id: 3},
          {text:"<Model> in <city>", position:-1, id: 4},
          {text:"Certified Technicians", position:-1, id: 5},
          {text:"Schedule Appointment Online", position:-1, id: 6},
          {text:"Contact our Trusted Experts", position:-1, id: 7}
        ],
        descriptions:[
          {text:"Ask the experts for the complete check-up of your <model>. Book an appointment online!", position:-1, id: 0},
          {text:"From oil change to transmission replacement, we're dedicated to a top customer service.", position:-1, id: 1},
          {text:"Looking to service your <model>? Contact our certified technicians today.", position:-1, id: 2},
          {text:"Our team is ready to give your <model> vehicle the attention and care it deserves.", position:-1, id: 3}
        ]
      }]
    }
  }
  if (adGroupName && campaignType === AdBuilderCompatibleNonInventoryCampaignType.SERVICE && lang === AdBuilderCompatibleLang.FR){
    return {
      group,
      lang,
      audienceAWId : isRLSA ? audienceIds||[] : undefined,
      adGroupName,
      campaignName : group,
      patterns     : existingPatterns || [{
        id : -1,
        headlines:[
          {text:"Réparation de <Model>", position:-1, id: 0},
          {text:"Entretien de votre <Model>", position:-1, id: 1},
          {text:"Pièces <Model>", position:-1, id: 2},
          {text:"Concessionnaire <Model>", position:-1, id: 3},
          {text:"<Model> à <city>", position:-1, id: 4},
          {text:"Techniciens certifiés", position:-1, id: 5},
          {text:"Rendez-vous en ligne", position:-1, id: 6},
          {text:"Contactez nos experts", position:-1, id: 7}
        ],
        descriptions:[
          {text:"Nous vous proposons une multitude d'accessoires et de pièces d'auto au meilleur prix.", position:-1, id: 0},
          {text:"Faites confiance aux experts pour les meilleures pièces <Make> pour votre véhicule.", position:-1, id: 1},
          {text:"Service, pièces et mécanique à <city>. Contactez nos experts.", position:-1, id: 2},
          {text:"Faites confiance à nos techniciens certifiés pour tous vos besoins d'entretien.", position:-1, id: 3}
        ]
      }]
    }
  }
  return {
    group,
    lang,
    audienceAWId: isRLSA ? audienceIds||[] : undefined,
    patterns: [{id: -1, headlines: [], descriptions: []}],
    adGroupName
  }
}

export const getEmptyProtoMarketingEvent = () => {
  return {
    title        : undefined,
    startDate    : undefined,
    endDate      : undefined,
    lang         : "ALL",
    boundaries   : [{
      type: MEBTypes.SPECIFIC_S, //important
    }],
    rsaApproaches : [],
  }
}
