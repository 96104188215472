export default class GeoTargetingTypes{
  public static  DONT_CARE = "DONT_CARE"
  public static  AREA_OF_INTEREST = "AREA_OF_INTEREST"
  public static  LOCATION_OF_PRESENCE = "LOCATION_OF_PRESENCE"
}
export const GeoTargetingTypesList = [
  GeoTargetingTypes.DONT_CARE,
  GeoTargetingTypes.AREA_OF_INTEREST,
  GeoTargetingTypes.LOCATION_OF_PRESENCE,
]
