import './KeywordsInput.css'
import * as React     from 'react'
import {
  CardHeader,
  Typography,
  Divider,
  CardContent,
  TextField,
  FormHelperText,
  CardActions,
  Button,
  CircularProgress,
}                     from '@material-ui/core'
import { Translate }  from 'react-localize-redux'
import ArrayUtils     from '../../utils/ArrayUtils'

interface KeywordsInputOwnProps {
  functionnality     : 'save' |'creation'
  type               : 'positive' | 'negative'
  description        : boolean
  title              : string
  errors 	 		    	 : string[]
	keywords 		    	 : string[]
	handleInput 	  	 : (keywords:string) => void
  resetFunction      : ()=>void
  originalKeywords   : string[]
  saveFunction      ?: ()=> void
	updateKeywords 		?: (keywords:string) => void
  savingPosKeywords ?: boolean
  translateErrors   ?: boolean
}
interface KeywordsInputProps extends KeywordsInputOwnProps { }

const KeywordsInput = (props: KeywordsInputProps) => {
	const handleInput = (event) => {
    props.handleInput(event.target.value)
  }
	return (
		<div className="KeywordsInput">
      <CardHeader
        title={<Typography variant="h6">{props.title}</Typography>}
        subheader={ props.description &&
          <Typography className="Tip" variant="caption">
          {props.type === 'positive'
            ? <Translate id="branding.descriptions.positiveKeywords"/>
            : <Translate id="branding.descriptions.negativeKeywords" />
          }
          </Typography>
        }
      >
      </CardHeader>
      <Divider variant="middle" />
      <CardContent>
        <TextField
          rows={15}
          multiline
          fullWidth={true}
          value={props.keywords.length !==0 ? props.keywords.join('\n') : ''}
          onChange={handleInput}
          error={props.errors.length!==0}
        />
        {props.errors.map((error,i)=>{
          return <FormHelperText key={i} style={{color: '#e53935'}}>{props.translateErrors ? <Translate id={error as string} /> : error}</FormHelperText>
        })}
      </CardContent>
      <CardActions className="Buttons">
        <Button onClick={props.resetFunction} variant="outlined" color="secondary"><Translate id="common.reset" /></Button>
        {props.functionnality === 'save' && <Button
          disabled={
            props.errors.length !== 0 ||
            props.savingPosKeywords ||
            props.keywords && ArrayUtils.isSameArray(props.keywords, props.originalKeywords)
          }
          onClick={props.saveFunction}
          style={{ color: "white" }}
          variant="contained"
          color="primary"
        >
          {props.savingPosKeywords && props.type === 'positive' && <CircularProgress style={{marginRight:10}} size={15} />}
          <Translate id="common.save"/>
        </Button>}
      </CardActions>
		</div>
  )
}

export default KeywordsInput
