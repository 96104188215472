import Actions         from "../../../../model/constant/actions"
import ReducerUtils    from "../../../../utils/ReducerUtils"

interface RSAAdcopiesFailedSaving {
  status : boolean
  message : string
}
const Failed = ReducerUtils.createReducer<RSAAdcopiesFailedSaving>({status:false, message:""},{
  [Actions.FAILED_SAVING_RSA_ADCOPIES] : (_state,action) => {
    return {
      status  : action.status,
      message : action.errorString
    }
  }
})

export default Failed
