import * as React       from 'react'
import Budget           from "../model/Budget"
import {BudgetPlanPart} from "./BudgetPlanPart"
import NumFormatter     from '../../../../utils/NumberFormatter'

interface BudgetPlanProps{
  periods            : any
  budgetPlan         : any
  spentByPeriod      : any
  budget             : Budget
  isProviderActive   : boolean
  activeBudget       : Budget
  activeYear         : string
  activeMonth        : string
  currentMonth       : string
  currentYear        : number
  currentPeriod      : string
  selectPart         : (year:string,month:string)=>void
  changedPartAmount  : (period:string,amount:number)=>void
  amountChanged     ?: (amountChanged:number)=>void
}

const isActive = (props:BudgetPlanProps,year:string,month:string)=>{
  return (
    props.isProviderActive
    && props.budget.budgetId         === props.activeBudget.budgetId
    && props.activeYear              === year
    && props.activeMonth             === month
    && (
      parseInt(props.activeYear)     ==  props.currentYear
      && parseInt(props.activeMonth) >=  parseInt(props.currentMonth)
      || parseInt(props.activeYear)  >   props.currentYear
    )
  )
}

const BudgetPlanRow = (props:BudgetPlanProps)=>{
  let totalPlanned = 0
  let totalSpent = 0
  return (
    <tr>
      <td className="BudgetName">{props.budget.budgetName.replace("Automated - ","")}</td>
      {props.periods.map((period, key)=>{
        const part = props.budgetPlan.parts.find((p)=>p.period==period)
        const spent = props.spentByPeriod[period]
        if(part){totalPlanned += part.amount}
        if(spent){totalSpent += spent}
        let amount = part?part.amount:undefined
        const YM = period.split("-")
        const year  = YM[0]
        const month = YM[1]
        return(
          <td className = {period == props.currentPeriod ? "CurrentMonth amount" : "amount"} key = {key}>
            <BudgetPlanPart
              year           = {year}
              month          = {month}
              amount         = {amount}
              spent          = {spent}
              active         = {isActive(props,year,month)}
              onClick        = {()=>props.selectPart(year,month)}
              newAmount      = {(newAmount:number)=>props.changedPartAmount(period,newAmount)}
              deactivate     = {()=>props.selectPart("","")}
            />
          </td>
        )
      })}
      <td className="TotalByBudget amount">
        <div>{NumFormatter.formatCurrency(totalPlanned)}</div>
        <div>{NumFormatter.formatCurrency(totalSpent)}</div>
      </td>
    </tr>
  )
}
export default BudgetPlanRow
