import * as React                    from "react"
import {withGoogleMap}               from "react-google-maps"
import {GoogleMap}                   from "react-google-maps"

interface MapProps{
  googleMapProps : any
  children
}
export const Map = withGoogleMap((props:any) =>{
  /*
  This component must always be instanciated with at least these 3 props :
  <Map
    --googleMapURL="https://maps.googleapis.com/maps/api/js?key=_____YOUR_KEY_______&v=3.exp&libraries=geometry,drawing,places"
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `400px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
  */
  return(
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 45.478, lng: -73.448 }}
      {...props.googleMapProps}
    >
      {props.children}
    </GoogleMap>
  )
})

