import Tracking          from "../../utils/TrackingAPI"
import Actions           from "../../model/constant/actions"
import {getToken}        from "../../storeAccessor/Generic"

const retrieveTrackingInformationSuccess = (response, accountId) => {
  if(response.data === undefined){
    return {
      type        : Actions.RETRIEVE_ACCOUNT_TRACKING_INFORMATION,
      information : {AccountID:accountId}
    }
  }
  return {
    type        : Actions.RETRIEVE_ACCOUNT_TRACKING_INFORMATION,
    information : response.data.information
  }
}
const retrieveTrackingInformation = (accountId:number) => (dispatch, getState) => {
  Tracking.get({
      token     : getToken(getState()),
      action    : "GetTrackingInformation",
      accountId : accountId
    },
    (data) => dispatch(retrieveTrackingInformationSuccess(data, accountId)),
    (data) => console.log(data)
  )
}

export default retrieveTrackingInformation
