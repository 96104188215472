import Authentification, { LoginReturn }  from '../../utils/Authentification'
import Actions                            from "../../model/constant/actions"
import {setUserLanguage}                  from "./userPreferences"
import LoadUsers                          from "./users"
import {getToken}                         from "../../storeAccessor/Generic"
import Auth2                              from '../../utils/Auth2'


const onError = error => {
  return ({
    type : Actions.USER_CREATED,
    message : JSON.stringify(error,null,4)
  })
}

const generateRandomPassword = ():string => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let randomlyGeneratedPassword = ""
  for(let i=0; i<32; i++){
    randomlyGeneratedPassword += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return randomlyGeneratedPassword
}

const save = (userKeyValues, language:string, locale:string) => (dispatch, getState) => {
  const token = getToken(getState())
  dispatch({type:Actions.CREATING_USER})
  Authentification.post({
      token     : getToken(getState()),
      action    : "CreateUser",
      password  : generateRandomPassword(),
      ...userKeyValues.reduce((final,kv)=>{
        final[kv[0]] = kv[1]
        return final
      },{})
    },
    (response1: LoginReturn) => {
      if (response1.error && response1.error.length > 0) {
        dispatch(onError(response1.error))
      }
      else {
        Auth2.setUserLocale(token, response1.data.user.id, locale)
        .then((response2) => {
          dispatch(setUserLanguage(response2.data.id, language))
          dispatch({
            type : Actions.USER_CREATED,
            message : JSON.stringify(response2.data, null, 4)
          })
          dispatch(LoadUsers())
        })
        .catch((error) => {
          throw Error("Auth2 error: " + (error.data || "Unknown problem with the server has occurred"))
        })
      }
    },
    (error) => dispatch(onError(error))
  )
}
export default save
