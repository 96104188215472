import {createSelector}                 from 'reselect'
import * as Generic                     from "./Generic"

const companyNames = {
  Convertus : 'Convertus',
  TRFFK     : 'TRFFK'
}

export default class TemplatesAccessors{
  public static makeActiveWhiteLabelSelector(){
    return createSelector(
      Generic.getAccountId,
      state => state.Accounts.preferences,
      state => state.Login.userLogged.preferences,
      (accountId:number, accountPreferences:any, userPreferences:any)=>{
        let whiteLabel = "DealerBreacher"
        if(accountPreferences[accountId] && accountPreferences[accountId].whitelabel){
          whiteLabel = accountPreferences[accountId].whitelabel
        }
        if(userPreferences && userPreferences.whitelabel){
          whiteLabel = userPreferences.whitelabel
        }
        return whiteLabel
      }
    )
  }

  public static makeCompanyNameSelector(){
    return createSelector(
      TemplatesAccessors.makeActiveWhiteLabelSelector(),
      (whiteLabel:string)=>{
        //Logic is simple for now. Always DealerBreacher unless it's Convertus
        return companyNames[whiteLabel] || 'DealerBreacher'
      }
    )
  }
}
