import ReducerUtils                     from "../../../utils/ReducerUtils"
import Actions                          from "../../../model/constant/actions"
import {
  ProtoMarketingEvent,
  computeProtoBoundariesType,
  MEBCampaignGroupsMapping,
  MarketingEventBoundariesTypes,
  AdTemplate,
  ProtoMarketingEventBoundaries,
  AdBuilderCompatibleCampaignGroup
}                                       from "../../../model/adbuilder/"
import {
  ProtoMarketingEventFactory,
  getEmptyProtoMarketingEvent
}                                       from "../../../model/adbuilder/factories"


const NO_EVENT_ERROR = "Trying to update editing event but none was started"

const start = (state, action) => {
  let event:ProtoMarketingEvent
  if(action.event){event = ProtoMarketingEventFactory(action.event)}
  else{event = getEmptyProtoMarketingEvent()}
  return {
    current : event
  }
}

const updateTitle = (state:EventEditCopiesState, action):EventEditCopiesState => {
  if(!state.current){throw Error(NO_EVENT_ERROR)}
  return {
    ...state,
    current : {
      ...state.current,
      title : action.title
    }
  }
}

const updateLang = (state:EventEditCopiesState, action):EventEditCopiesState => {
  if(!state.current){throw Error(NO_EVENT_ERROR)}
  return {
    ...state,
    current : {
      ...state.current,
      lang : action.lang
    }
  }
}

const updateDates = (state:EventEditCopiesState, action):EventEditCopiesState => {
  if(!state.current){throw Error(NO_EVENT_ERROR)}
  const newEvent = {...state.current}
  if(action.start){newEvent.startDate = action.start}
  if(action.end){newEvent.endDate = action.end}
  return {
    ...state,
    current : newEvent
  }
}

const addBoundary = (state:EventEditCopiesState, action):EventEditCopiesState => {
  // conditionally defining the new boundary based on the first one
  // boundaries of the same event should not vary on these attributes
  const newBoundary:ProtoMarketingEventBoundaries = {
    ...(state.current.boundaries[0].vehicleType) && {vehicleType: state.current.boundaries[0].vehicleType},
    ...(state.current.boundaries[0].vehicleState) && {vehicleState: state.current.boundaries[0].vehicleState},
    ...(state.current.boundaries[0].campaignGroup) && {campaignGroup: state.current.boundaries[0].campaignGroup},
    type : action.hasType ? action.hasType : MarketingEventBoundariesTypes.SPECIFIC_S
  }
  return {
    ...state,
    current : {
      ...state.current,
      boundaries : [
        ...state.current.boundaries,
        newBoundary
      ]
    }
  }
}

const removeBoundary = (state:EventEditCopiesState, action):EventEditCopiesState => {
  const currentBoundaries = [...state.current.boundaries]
  currentBoundaries.splice(action.index, 1)

  // Finding all current inventory strategies (groups)
  let groups = []

  currentBoundaries.forEach(boundary=>{
    if(boundary.type === MarketingEventBoundariesTypes.SPECIFIC_CAMPAIGN){
      //When it's a specific campaign, we have to look at the campaign's group
      groups.push(boundary.campaignGroup)
    }
    if (boundary.type){
      groups.push(...MEBCampaignGroupsMapping[boundary.type])
    }
  })

  const newGroups = [...new Set(groups.filter(x=>x!==undefined))]

  const newApproaches = [...state.current.rsaApproaches.filter(approach=>newGroups.includes(approach.group))]

  return {
    ...state,
    current : {
      ...state.current,
      boundaries : currentBoundaries,
      rsaApproaches : newApproaches
    }
  }
}

const updateBoundaries = (state:EventEditCopiesState, action):EventEditCopiesState => {
  if(!state.current){throw Error(NO_EVENT_ERROR)}
  const index = action.index
  let newBoundaries = {
    ...state.current.boundaries[index],
    ...action.boundaries
  }

  // Remove this so we can use the details of the newBoundary (make,model,etc.)
  // to find matching vehicles and cleanerRows in AdBuilder, to offer some feedback.
  // Getting rid of the cleanerId here means we might have issues when sending that back to the backend,
  // we'll have to generate a new one based on the information available then
  if (
    action.boundaries.vehicleState || Object.keys(AdBuilderCompatibleCampaignGroup).includes(action.boundaries.campaignGroup)
  ){
    newBoundaries.cleanerId = undefined
  }

  // The boundaries switched from inventory to non inventory
  if (action.boundaries.campaignType && newBoundaries.vehicleState && newBoundaries.vehicleType){
    newBoundaries = {...action.boundaries}
  }

  // The boundaries switched from non inventory to inventory
  if (action.boundaries.vehicleState && !Object.keys(AdBuilderCompatibleCampaignGroup).includes(newBoundaries.campaignType)){
    delete newBoundaries.campaignType
  }

  const currentBoundaries = {...state.current.boundaries[index]}

  //Updating the state or the lang of the boundaries resets the selected campaign
  //since it might not fit the new boundaries
  //state was set and was changed
  if(newBoundaries.campaignName && (currentBoundaries.vehicleState && currentBoundaries.vehicleState !== newBoundaries.vehicleState)
  ){
    newBoundaries.campaignName = undefined
  }
  //On make change, reset model
  if(currentBoundaries.make !== newBoundaries.make){
    newBoundaries.model = undefined
  }

  //computing the new boundaries type and associated groups
  const newType = computeProtoBoundariesType(newBoundaries)
  let groups = MEBCampaignGroupsMapping[newType]
  if(newType === MarketingEventBoundariesTypes.SPECIFIC_CAMPAIGN){
    if(!newBoundaries.campaignGroup){throw Error("Event boundaries type was SPECIFIC_CAMPAIGN but campaignGroup wasn't set")}
    groups = [newBoundaries.campaignGroup]
  }

  //backup all previous patterns
  const patternsBackup = state.current.rsaApproaches.reduce((patternsByGroup, approach)=>{
    const group = approach.group + (approach.audienceAWId ? "_RLSA" : "")
    patternsByGroup[group] = approach.patterns
    return patternsByGroup
  },{})
  const audiencesBackup = state.current.rsaApproaches.reduce((audiencesByGroup, approach)=>{
    if(approach.audienceAWId !== undefined){
      audiencesByGroup[approach.group] = approach.audienceAWId
    }
    return audiencesByGroup
  },{})

  let newApproaches = []
  // Condition is to dodge this logic for non inventory events
  if (groups){
    newApproaches = groups.reduce((approaches, group)=>{
      const currentNonRLSA = state.current.rsaApproaches.filter(x=>x.group === group && x.audienceAWId === undefined)
      const currentRLSA = state.current.rsaApproaches.filter(x=>x.group === group && x.audienceAWId !== undefined)
      if(currentNonRLSA.length){
        approaches.push(...currentNonRLSA)
      }
      else if(state.patternsBackup && state.patternsBackup[group]){
        approaches.push({
          group        : group,
          lang         : state.current.boundaries[0].lang,
          patterns     : state.patternsBackup[group] as any,
        })
      }
      if(currentRLSA.length){
        approaches.push(...currentRLSA)
      }
      else if(state.patternsBackup && state.patternsBackup[group+"_RLSA"]){
        approaches.push({
          group        : group,
          lang         : state.current.boundaries[0].lang,
          audienceAWId : state.audiencesBackup[group] || [],
          patterns     : state.patternsBackup[group+"_RLSA"] as any,
        })
      }
      return approaches
    },[]).map(approach=>{
      return approach
    })
  } else {
    newApproaches = state.current.rsaApproaches
  }

  // Added logic to synchronize all vehicleStates when a vehicleState change is dispatched
  let allBoundaries:ProtoMarketingEventBoundaries[] = []
  // if going from inventory to non inventory, trim excess boundaries
  if(newBoundaries.campaignType){
    allBoundaries.push({...newBoundaries, type : newType})
  }
  else {
    allBoundaries = state.current.boundaries.map((x,i)=>{
      if (i===index){
        return {
          ...newBoundaries,
          type : newType
        }
      }
      if (newBoundaries.vehicleState){
        return {
          ...x,
          vehicleState : newBoundaries.vehicleState
        }
      }
      return x
    })
  }

  console.log({...newBoundaries,type : newType})

  return {
    ...state,
    current : {
      ...state.current,
      boundaries : allBoundaries,
      rsaApproaches : newApproaches
    },
    patternsBackup : {
      ...state.patternsBackup,
      ...patternsBackup
    },
    audiencesBackup : {
      ...state.audiencesBackup,
      ...audiencesBackup
    }
  }
}

const setApproach = (state:EventEditCopiesState, action):EventEditCopiesState => {
  if(!state.current){throw Error(NO_EVENT_ERROR)}
  return {
    ...state,
    current : {
      ...state.current,
      rsaApproaches : action.approaches
    }
  }
}

interface EventEditCopiesState{
  current ?: ProtoMarketingEvent
  patternsBackup ?: {
    [group:string] : AdTemplate[]
  },
  audiencesBackup ?: {
    [group:string] : string[]
  }
}

const Event = ReducerUtils.createReducer<EventEditCopiesState>({},{
  [Actions.START_EDITING_MARKETING_EVENT]                  : start,
  [Actions.UPDATE_EDITING_MARKETING_EVENT_LANG]            : updateLang,
  [Actions.UPDATE_EDITING_MARKETING_EVENT_TITLE]           : updateTitle,
  [Actions.UPDATE_EDITING_MARKETING_EVENT_DATES]           : updateDates,
  [Actions.UPDATE_EDITING_MARKETING_EVENT_BOUNDARIES]      : updateBoundaries,
  [Actions.SET_EDITING_MARKETING_EVENT_APPROACHES]         : setApproach,
  [Actions.UPDATE_EDITING_MARKETING_EVENT_ADD_BOUNDARY]    : addBoundary,
  [Actions.UPDATE_EDITING_MARKETING_EVENT_REMOVE_BOUNDARY] : removeBoundary,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST]               : ()=>({}), //clear on change of account
  [Actions.CLEAR_MARKETING_EVENT_CREATE_COPY]              : ()=>({})
})

export default Event
