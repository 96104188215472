import Actions      from "../../../model/constant/actions"
import ReducerUtils from "../../../utils/ReducerUtils"
import Budget       from "../../../page/budget/budgetPlanner1/model/Budget"

const storeBudgets = (state,action) => {
  return {
    ...state,
    [action.accountId] : action.budgets
  }
}
interface BudgetsByAccount{
  [accountId:number] : Budget[]
}
export const Budgets = ReducerUtils.createReducer<BudgetsByAccount>({},{
  [Actions.RETRIEVE_BUDGETS_FOR_ACCOUNTID] : storeBudgets,
})
