import './AudienceAdsSelection.css'
import * as React                from 'react'
import { AdGroupConfigsByName }  from "../../model/branding/AdGroupConfigs"
import {
  MenuItem,
  FormControl,
  Select,
  Input,
  FormHelperText,
  Card,
  Divider,
  CardActions,
  Button,
  Typography,
}                                from '@material-ui/core'
import AWSearch                  from '../../model/Store/Audiences/AWSearch'
import requiresAWSearchAudiences from '../../components/loaders/AWSearchAudiencesLoader'
import { Translate }             from 'react-localize-redux'
import SimpleAdCopy              from "../../components/adCopy/SimpleAdCopy"

interface AudienceAdsSelectionOwnProps {
  adGroupConfigsByName : AdGroupConfigsByName
  saveFunction         : (templates)=> void
  closeFunction        : () => void
  lang                 : string
}
interface AudienceAdsSelectionProps extends AudienceAdsSelectionOwnProps {
  AWAudiences          : AWSearch[]
}

const AudienceAdsSelection = (props:AudienceAdsSelectionProps) => {
  const [adGroup,setAdGroup] = React.useState(Object.keys(props.adGroupConfigsByName)[0])
  //Generate AdGroups
  const adGroupList = Object.keys(props.adGroupConfigsByName).map(groupConfig=>{
    return (
      <MenuItem key={groupConfig} value={groupConfig}>{groupConfig}</MenuItem>
    )
  })
  //Generate AudienceAds
  const audiences = {}
  for(const audienceAd of props.adGroupConfigsByName[adGroup].audienceAds) {
    if(audienceAd.audienceId === "0") {audiences["New visitors"]=audienceAd}
    else {
      props.AWAudiences.forEach(audience => {
        if (parseInt(audienceAd.audienceId,10) == audience.id) {audiences[audience.name]=audienceAd}
      })
    }
  }
  const [audienceAd,setAudienceAd]=React.useState(Object.keys(audiences)[0])
  //Generating audienceAd select
  const audiencesList = Object.keys(audiences).map(audience => {
    return <MenuItem key={audience} value={audience}>{audience}</MenuItem>
  })
  //Select Handlers
  function handleChangeAdGroup(event:React.ChangeEvent<{value:string}>) {
    setAdGroup(event.target.value)
    const firstAudience = props.AWAudiences.find(audience=>
      parseInt(props.adGroupConfigsByName[event.target.value].audienceAds[0].audienceId,10) == audience.id
    )
    setAudienceAd(firstAudience ? firstAudience.name : "New visitors")
  }
  function handleChangeAudienceAd(event:React.ChangeEvent<{value:string}>){
    setAudienceAd(event.target.value)
  }
  return (
    <Card className="AudienceAdsSelection">
      <div className="Selects">
        <FormControl className="SelectAdGroup">
          <Select
            value={adGroup}
            input={<Input name="adGroup" id="adGroup" />}
            onChange={handleChangeAdGroup}
            inputProps={{
              name: 'AdGroups',
              id: 'AdGroups-Select',
            }}
          >
            {adGroupList}
          </Select>
          <FormHelperText><Translate id="branding.chooseAdGroup" /></FormHelperText>
        </FormControl>
        <FormControl className="SelectAudienceAd">
          <Select
            value={audienceAd}
            input={<Input name="audienceAd" id="audienceAd" />}
            onChange={handleChangeAudienceAd}
          >
            {audiencesList}
          </Select>
          <FormHelperText><Translate id="branding.chooseAudience"/></FormHelperText>
        </FormControl>
      </div>
      <Divider />
      <div className="AdCopiesCard">
        {audiences[audienceAd].templates.map((template,i)=>{
          return (
            <Card key={i} className="AdCopyCard">
              <SimpleAdCopy headlines={template.headlines} descriptions={template.descriptions} url="www.google.com"  />
            </Card>
          )
        })}
      </div>
      <Divider />
      <CardActions className="Buttons">
        <Button onClick={props.closeFunction} color="secondary" variant="outlined"><Translate id="common.cancel" /></Button>
        <Button onClick={()=>props.saveFunction(audiences[audienceAd].templates)} style={{color:'white'}} color="primary" variant="contained"><Translate id="common.confirm"/></Button>
      </CardActions>
    </Card>
  )
}

export default requiresAWSearchAudiences()(AudienceAdsSelection) as React.ComponentType<AudienceAdsSelectionOwnProps>
