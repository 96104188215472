import * as React                         from "react"
import {connect}                          from 'react-redux'
import ArrayUtils                         from '../../utils/ArrayUtils'
import { addVehiclesToFriends }           from "../../actions/campaign/campaignInventory"
import retrieveInventory                  from "../../actions/inventory/retrieveInventory"
import InventoryFriend                    from "../../model/Store/Campaign/InventoryFriend"
import Friend                             from "../../model/Store/Campaign/Friend"

export interface FriendAndVehicles{
  friend   : Friend
  vehicles : any[]
}

const mapStateToProps = state => {
  return {
    accountId         : state.Accounts.selected,
    friends           : state.Campaigns.list,
    vehicles          : state.Inventory.list,
    allLangVehicles   : state.Inventory.allLangList,
    isFetching        : state.Inventory.isFetching,
    allLangIsFetching : state.Inventory.allLangIsFetching
  }
}
const mapDispatchToProps = dispatch => {
  return{
    retrieveInventory    : (accountId:number)=>dispatch(retrieveInventory(accountId)),
    addVehiclesToFriends : (friendsAndVehicles:FriendAndVehicles[], allLangFriendsAndVehicles:FriendAndVehicles[]) =>
                                  dispatch(addVehiclesToFriends(friendsAndVehicles, allLangFriendsAndVehicles)),
  }
}
const mergeProps = (SP,DP, ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    retrieveInventory : ()=>DP.retrieveInventory(SP.accountId)
  }
}
class InventoryWorker extends React.Component<any,any>{
  work(){
    const accountId = this.props.accountId

    if(!accountId){return}
    if(this.props.isFetching || this.props.allLangIsFetching){return}
    if(this.props.vehicles[accountId]===undefined){this.props.retrieveInventory()}
    if(!this.props.vehicles[accountId] || !this.props.friends[accountId]){return}

    const friends = this.props.friends[accountId].friends

    if(friends.length === 0){return}

    if (!this.props.vehicles[accountId] || !this.props.allLangVehicles[accountId]) {
      return
    }

    const inventory = this.props.vehicles[accountId].filter(v=>v.isCurrent())
    const allLangInventory = this.props.allLangVehicles[accountId].filter(v => v.isCurrent())

    if(inventory.length === 0){return}

    this.workOnAccount(friends,inventory, allLangInventory)
  }
  workOnAccount(friends:Friend[],inventory:any[], allLangInventory: any){
    let toProcess:FriendAndVehicles[] = []
    let allLangToProcess:FriendAndVehicles[] = []

    for(let friend of friends){
      if(!(friend instanceof InventoryFriend)){continue}
      if(friend.getInventory().length > 0){continue} //not sure about that

      const allVehicleIds = friend.getAllVehicleIds()
      const relatedVehicles = inventory.filter(vehicle=>ArrayUtils.contain(allVehicleIds, vehicle.id))
      const allLangRelatedVehicles = allLangInventory.filter(vehicle=>ArrayUtils.contain(allVehicleIds, vehicle.id))

      if(relatedVehicles.length){
        toProcess.push({
          friend : friend,
          vehicles : relatedVehicles
        })
      }
      if(allLangRelatedVehicles.length){
        allLangToProcess.push({
          friend : friend,
          vehicles : allLangRelatedVehicles
        })
      }
    }

    if(toProcess.length && allLangToProcess.length){this.props.addVehiclesToFriends(toProcess, allLangToProcess)}
  }
  componentDidUpdate(){this.work()}
  render(){return null}
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(InventoryWorker)
