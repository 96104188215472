import "./StickyTop.css"
import * as React from "react"

interface StickyTopProps{
  top ?: number
  children
}

interface StickyTopState{
  sticky : boolean
}

class StickyTop extends React.Component<StickyTopProps,StickyTopState>{
  static defaultProps = {
    top : 0
  }
  private element
  private elementDefaultOffset
  private elementWidth

  constructor(props){
    super(props)
    this.element = React.createRef()
    this.state = {
      sticky : false
    }

    this.onScroll = this.onScroll.bind(this)
  }
  componentDidMount(){
    window.addEventListener("scroll", this.onScroll)
    this.elementDefaultOffset = this.element.current.offsetTop - this.props.top //consider the wanted top as part of the element
    this.elementWidth = this.element.current.offsetWidth
  }
  componentDidUpdate(){
    if(this.element.current.offsetWidth !== this.elementWidth){
      this.elementWidth = this.element.current.offsetWidth
    }
  }
  componentWillUnmount(){
    window.removeEventListener("scroll", this.onScroll)
  }
  onScroll(e){
    if(window.scrollY > this.elementDefaultOffset && !this.state.sticky){
      this.setState({sticky: true})
    }
    else if(window.scrollY <= this.elementDefaultOffset && this.state.sticky){
      this.setState({sticky: false})
    }
  }
  render(){
    let style
    if(this.state.sticky){
      style = {top: this.props.top+"px", position: "sticky"}
    }
    return (
      <div
        ref={this.element}
        className="StickyTop"
        style={style}
      >
        {this.props.children}
      </div>
    )
  }
}

export default StickyTop
