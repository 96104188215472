import './KPIs.css'
import * as React            from "react"
import {
  withLocalize,
  TranslateFunction
}                            from "react-localize-redux"
import StatisticsUtils       from "../../../../utils/StatisticsUtils"
import MathUtils             from "../../../../utils/MathUtils"
import NumFormatter          from '../../../../utils/NumberFormatter'
import Card                  from "../../../../components/card/Card"
import ExplainedLoading      from "../../../../components/loading/index"

interface CardsOverviewProps{
  data            : any[]
  symmetricalData : any[]
  wantedKPIs      : AvailableKPI[]
  translate      ?: TranslateFunction
}
type AvailableKPI = "clicks" | "cpc" | "cost" | "impressions" | "impressionShare"| "clickShare"
const compileStats = (data:any[]):any => {
  const returned = data.reduce((compiled,stat)=>{
    if(stat.Clicks){compiled.clicks += stat.Clicks}
    if(stat.Cost){compiled.cost += stat.Cost}
    if(stat.Impressions){compiled.impressions += stat.Impressions}
    if(stat.ImpressionShareData){compiled.impressionShareData = compiled.impressionShareData.concat(stat.ImpressionShareData)}
    return compiled
  },{
    clicks : 0,
    cost : 0,
    impressions : 0,
    impressionShareData : [],
    impressionShare : 0,
  })
  if(returned.impressionShareData.length > 0){
    returned.impressionShare = StatisticsUtils.calculateImpressionShare(returned.impressionShareData)
  }
  return returned
}
const calculateDiff = (currentData, pastData) => {
  return pastData!=0 ? (100*(currentData - pastData)/pastData).toFixed(2) : 0
}
const findColorBackground = (data) => {
  return data >=0 ? "#3cba54" : "#db3236"
}
const formatPercent = (data) => {
  return data > 0 ? "+"+NumFormatter.formatNumber(data, 2)+"%" : NumFormatter.formatNumber(data, 2)+"%"
}
const KPIS_BY_NAME = {
  ["clicks"] : {
    id             : "clicks",
    text           : "campaigns.overview.totalClicks",
    icon           : "touch_app",
    generateData   : (currentStats, pastStats)=>{
      const clicksDiff = calculateDiff(currentStats.clicks, pastStats.clicks)
      const number = ""+NumFormatter.formatNumber(currentStats.clicks)
      const percent = formatPercent(clicksDiff)
      const percentBGColor = findColorBackground(clicksDiff)
      return {
        number,
        percent,
        percentBGColor
      }
    }
  },
  ["cpc"] : {
    id             : "cpc",
    text           : "campaigns.overview.avgCPC",
    icon           : "monetization_on",
    generateData   : (currentStats, pastStats)=>{
      const CPC = StatisticsUtils.calculateCPC(currentStats.cost, currentStats.clicks).toFixed(2)
      const pastCPC = StatisticsUtils.calculateCPC(pastStats.cost, pastStats.clicks).toFixed(2)
      const CPCDiff = calculateDiff(CPC, pastCPC)
      const number = NumFormatter.formatCurrency(parseFloat(CPC))
      const percent = formatPercent(CPCDiff)
      const percentBGColor = findColorBackground(-CPCDiff)
      return {
        number,
        percent,
        percentBGColor
      }
    }
  },
  ["cost"] : {
    id             : "cost",
    text           : "campaigns.overview.totalCost",
    icon           : "monetization_on",
    generateData   : (currentStats, pastStats)=>{
      const costDiff = calculateDiff(currentStats.cost, pastStats.cost)
      const number = NumFormatter.formatCurrency(currentStats.cost)
      const percent = formatPercent(costDiff)
      const percentBGColor = findColorBackground(costDiff)
      return {
        number,
        percent,
        percentBGColor
      }
    }
  },
  ["impressions"] : {
    id             : "impressions",
    text           : "common.impressions",
    icon           : "pageview"  ,
    generateData   : (currentStats, pastStats)=>{
      const impressionsDiff = calculateDiff(currentStats.impressions, pastStats.impressions)
      const number = ""+NumFormatter.formatNumber(currentStats.impressions)
      const percent = formatPercent(impressionsDiff)
      const percentBGColor = findColorBackground(impressionsDiff)
      return {
        number,
        percent,
        percentBGColor
      }
    }
  },
  ["impressionShare"] : {
    id             : "impressionShare",
    text           : "common.impressionShare",
    icon           : "pie_chart_outlined",
    generateData   : (currentStats, pastStats)=>{
      console.warn(currentStats, pastStats)
      const impShareDiff = calculateDiff(currentStats.impressionShare, pastStats.impressionShare)
      const number = NumFormatter.formatNumber(currentStats.impressionShare, 2)+"%"
      const percent = formatPercent(impShareDiff)
      const percentBGColor = findColorBackground(impShareDiff)
      return {
        number,
        percent,
        percentBGColor
      }
    },
    ["clickShare"] : {
      id             : "clickShare",
      text           : "common.clickShare",
      icon           : "pie_chart_outlined",
      generateData   : (currentStats, pastStats)=>{
        const clickShareDiff = calculateDiff(currentStats.clickShare, pastStats.clickShare)
        const number = NumFormatter.formatNumber(currentStats.clickShare, 2)+"%"
        const percent = formatPercent(clickShareDiff)
        const percentBGColor = findColorBackground(clickShareDiff)
        return {
          number,
          percent,
          percentBGColor
        }
      }
    },
  }
}
const getCompiledKPIs = (wanted:AvailableKPI[], data:any[], symmetricalData:any[], translate:TranslateFunction):any[] => {
  const currentStats = compileStats(data)
  const pastStats = compileStats(symmetricalData)
  const KPIs = wanted.map(kpi=>{
    const obj = KPIS_BY_NAME[kpi]
    if(!obj){throw Error(`Invalid KPI given. ${kpi} doesn't exist`)}
    return {
      id : obj.id,
      text : translate(obj.text),
      icon : obj.icon,
      ...obj.generateData(currentStats, pastStats)
    }
  })
  return KPIs
}
const CardsOverview = (props:CardsOverviewProps) => {
  if(!props.data){return <ExplainedLoading translateId="loadings.stats"/>}
  const KPIs = getCompiledKPIs(props.wantedKPIs, props.data, props.symmetricalData, props.translate)
  return(
    <div className="OverviewCards">
      {KPIs.map(card=>
        <Card
          key={card.id}
          {...card}
        />
      )}
    </div>
  )
}

export default withLocalize(CardsOverview as any) as any
