import Actions      from "../../../model/constant/actions"
import ReducerUtils from "../../../utils/ReducerUtils"
import ArrayUtils   from "../../../utils/ArrayUtils"

const setStats = (state,action) =>{
  if(action.stats === undefined){return state}
  const accountId = action.accountId
  if(state[accountId] === undefined){
    return {
      ...state,
      [accountId] : action.stats
    }
  }
  const mergedStats = ArrayUtils.mergeBySerialization(
    state[accountId],
    action.stats,
    (stat)=>stat.Month+stat.BudgetId
  )
  return {
    ...state,
    [accountId] : mergedStats
  }
}
interface MonthlyBudgetsByAccount{
  [accountId:number] : any[]
}
export default ReducerUtils.createReducer<MonthlyBudgetsByAccount>({},{
  [Actions.RETRIEVE_MONTHLY_BUDGETS_STATS] : setStats,
})
