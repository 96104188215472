import MomentCompiledWebInfo        from "../model/MomentCompiledWebInfo"

const PHONE_NUMBER_REGEX = /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g
const MASKED_PHONE_NUMBER_REGEX = /\*\*\*-\*\*\*-\d{4}/g

const findInsight = (byMoment:MomentCompiledWebInfo) : string => {
  let found = []
  for(let lead of byMoment.leads){
    if(lead.inputValue.indexOf("@")>1 ||
      lead.inputValue.match(PHONE_NUMBER_REGEX) ||
      lead.inputValue.match(MASKED_PHONE_NUMBER_REGEX)
    ){
      return lead.inputValue
    }
  }
  return ""
}
const compile = (byMoments:MomentCompiledWebInfo[]) : any[] => {
  return byMoments
  .map(x=>({ ...x, insight : findInsight(x) }))
  .filter(x=>x.insight)
}
export default compile
