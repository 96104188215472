import * as React        from "react"
import BootstrapTable    from 'react-bootstrap-table-next'
import filterFactory     from 'react-bootstrap-table2-filter'
import {textFilter}      from 'react-bootstrap-table2-filter'
import {Link}            from 'react-router-dom'
import requiresUsers     from "../../components/loaders/usersLoader"
import UserLevel         from "../../components/user/UserLevel"
import GoBack            from "../../components/Button/LinkGoBack"
import {Panel}           from "../../components/Panel/Panel"

const defaultFilter = [{dataField:'id',order:'asc'}]

const nameFormatterAction = (name,row) => <Link to={'/Management/Users/'+row.id}>{name}</Link>
const emailFormatter = (email,row) => <a href={'mailto:'+email}>{email}</a>

const UsersDatatable = props => (
  <Panel title="All users">
    <GoBack/>
    <BootstrapTable
      hover
      data={props.users.map(u=>({
        ...u,
        fullName:u.firstName+" "+u.lastName
      }))}
      keyField='id'
      columns={[
        { dataField:'id',        text:"Id",     sort:true,                                                                  },
        { dataField:'fullName',  text:"Name",   sort:true,  formatter:nameFormatterAction, filter:textFilter({delay:100}),  },
        { dataField:'email',     text:"Email",  sort:true,  formatter:emailFormatter, filter:textFilter({delay:100})        },
        { dataField:'userLevel', text:"Level",  sort:true,  formatter:level => <UserLevel level={level}/>                   },
      ]}
      defaultSorted={defaultFilter}
      filter={filterFactory()}
      classes={"normalSizedDatatable UsersDataTable"}
    />
  </Panel>
)
export default requiresUsers(UsersDatatable)