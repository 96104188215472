import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

interface Retrieving {
  RetrievingUsersList : boolean,
  RetrievingUserPermissions : boolean
}

const Retrieving = ReducerUtils.createReducer<Retrieving>(
  {
    RetrievingUsersList : false,
    RetrievingUserPermissions : false    
  },
  {
  [Actions.RETRIEVING_USERS_LIST] : (state,action) => ({
    ...state,
    RetrievingUsersList : action.status
  }),
  [Actions.RETRIEVING_USER_PERMISSIONS] : (state,action) => ({
    ...state,
    RetrievingUserPermissions : action.status
  }),
})
export default Retrieving
