import './ForceIdentityVariantCreation.css'
import React                                              from "react"
import {
  Paper,
  Card,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Icon,
  Typography
}                                                         from "@material-ui/core"
import ExplainedLoading                                   from "../../../../components/loading"
import IdentityVariants                                   from "../IdentityVariants/IdentityVariants"
import {
  SimplifiedIdentityVariants,
  SimplifiedIdentityVariantsByLang
}                                                         from '../../../../model/branding/SimplifiedIdentityVariants'
import { AdGroupConfigsByLang }                           from "../../../../model/branding/AdGroupConfigs"
import ArrayUtils                                         from "../../../../utils/ArrayUtils"
import { Translate, withLocalize, LocalizeContextProps }  from "react-localize-redux"
import requiresStrategies                                 from "../../../../components/loaders/strategiesLoader"
import Strategy                                           from "../../../../model/Store/Strategy/Strategy"
import Account                                            from "../../../../model/Store/Account/Account"


interface ForceIdentityVariantCreationOwnProps {
  identityVariantsByLang  : SimplifiedIdentityVariantsByLang
  savingIdentityVariants  : boolean
  loadingIdentityVariants : boolean
  account                 : Account
  adGroupConfigsByLang    : AdGroupConfigsByLang
  createIdentityVariants  : (lang:string, identityVariants:SimplifiedIdentityVariants)=>void
}

interface ForceIdentityVariantCreationProps extends ForceIdentityVariantCreationOwnProps, LocalizeContextProps {
}

const ForceIdentityVariantCreation = (props: ForceIdentityVariantCreationProps) => {
  const [openSave, setOpenSave] = React.useState(false)
  const emptyShell = {
    cities         : [props.account.city],
    dealerNames    : [props.account.name],
    makes          : [],
  }
  const [editedIdentityVariants, setEditedIdentityVariants] = React.useState<SimplifiedIdentityVariants>(emptyShell)

  const missingLanguages = Object.keys(props.adGroupConfigsByLang).filter(lang=>!props.identityVariantsByLang[lang]).sort()
  if(missingLanguages.length === 0){ // Escape route, should not happen really
    return <div>Error: No language without identity variants</div>
  }
  const selectedLanguage = missingLanguages[0]

  function allFieldsFilled() {
    return (
      editedIdentityVariants.dealerNames.length > 0 &&
      editedIdentityVariants.cities.length > 0 &&
      editedIdentityVariants.makes.length > 0
    )
  }

  function handleClickOpenSave() {
    setOpenSave(true)
  }
  function handleCloseSave() {
    setOpenSave(false)
	}

  return (
    <div className="ForceCreateIdentityVariants">
      <Paper className="Content" style={{padding: 10}}>
        <Card className="IdentityVariants">
          { props.savingIdentityVariants ?
              <ExplainedLoading translateId="loadings.saving"/>
            : props.loadingIdentityVariants ?
                <ExplainedLoading translateId="loadings.identityVariants"/>
              : <IdentityVariants
                  initialIdentityVariants={emptyShell}
                  setIdentityVariants={setEditedIdentityVariants}
                  identityVariants={editedIdentityVariants}
                  title={
                    <div className="MissingVariantsTitle">
                      <Icon color="inherit" className="WarningYellow">warning</Icon>
                      <Typography variant="h6">
                        <Translate id="branding.missingIdentityVariantsLang" data={{
                          lang: <Translate id={"common."+selectedLanguage.toUpperCase()+"CapsFeminine"}/>
                        }}
                      />
                      </Typography>
                    </div>
                  }
                  description={
                    <Typography className="Caption" variant="caption">
                      <Translate id="branding.descriptions.missingIdentityVariants" />
                    </Typography>
                  }
                />
          }
        </Card>
        <CardActions className="Buttons">
          <Button
            disabled={!allFieldsFilled()}
            style={{color:'white'}}
            size="large"
            color="primary"
            onClick={handleClickOpenSave}
            variant="contained"
          >
            <Translate id="common.save" />
          </Button>
        </CardActions>
      </Paper>
      <Dialog
        open={openSave}
        onClose={handleCloseSave}
        aria-labelledby="save-dialog-title"
        aria-describedby="save-dialog-description"
      >
        <DialogTitle id="save-dialog-title"><Translate id="common.save"/></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Translate id="branding.saveMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSave} color="secondary" autoFocus>
            <Translate id="common.cancel" />
          </Button>
          <Button
            onClick={()=>{
              handleCloseSave()
              props.createIdentityVariants(selectedLanguage, editedIdentityVariants)
            }}
            color="primary"
          >
            <Translate id="common.save"/>
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  )
}


export default (
  withLocalize(
    ForceIdentityVariantCreation
  )
) as React.ComponentType<ForceIdentityVariantCreationOwnProps>
