import ReducerUtils       from "../../utils/ReducerUtils"
import Actions            from "../../model/constant/actions"
interface Users {
  [accountId:string] : {
    [userId:string] : string
  }
}

const saveData = (state:Users, action):Users => {
  const accountId = action.accountId
  const users = action.users
  return {
    ...state,
    [accountId] : users
  }
}

const Users = ReducerUtils.createReducer<Users>({},{
  [Actions.RETRIEVE_PACY_USERS] : saveData
})

export default Users
