import * as React                   from "react"
import {HashRouter,Route,Redirect}  from "react-router-dom"
import {Link,RouteProps,Switch}     from "react-router-dom"
import RulesModule                  from "../page/rules"
import {GeoBids}                    from "../page/geobid/"
import CopyAndReplace               from "../page/geobid/CopyAndReplace"


interface RouteDefinition{
  path      : string
  component : any // Component
}
const mapping : RouteDefinition[] = [
  { path:"*/GeoBid(s)?/CopyAndReplace/", component: CopyAndReplace},
  { path:"*/GeoBid(s)?/",                component: GeoBids},
]
export default (props) => {
  return (
    <HashRouter>
      <Switch>
        {mapping.map((route:RouteDefinition)=>
          <Route key={route.path} path={route.path} component={route.component}/>
        )}
      </Switch>
    </HashRouter>
  )
}
