
export default class BudgetPlanPart {

  private period
  private amount
  private userId
  private savedOn
  private revisions

  public constructor(period:string,amount:number,userId:number,savedOn:Date,revisions:BudgetPlanPart[]=[]){
    if(!userId){throw Error("Invalid userId given")}
    this.userId = userId
    if(amount<0){throw Error("Amount shouldn't be negative")}
    this.amount = amount
    this.savedOn = savedOn
    this.period = period
    this.revisions = revisions
  }
  clone() : BudgetPlanPart {
    return new BudgetPlanPart(
      this.period,
      this.amount,
      this.userId,
      this.savedOn,
      this.revisions,
    )
  }
  public getPeriod()     {return this.period}
  public getAmount()     {return this.amount}
  public getUserId()     {return this.userId}
  public getSavedOn()    {return this.savedOn}
  public getRevisions()  {return this.revisions}
}


