import './ModalContent.css'
import * as React        from "react"
import {withLocalize}    from "react-localize-redux"
import {Button}          from "carbon-components-react"
import Status            from "../../../../../model/constant/status"

interface StatusModalContentProps{
  hasException              : boolean
  statusExceptionType       : string
  close                     : ()=>void
  addDesiredStatusException : (status:string)=>void
  deleteDesiredStatus       : ()=>void
  translate                ?: (any)=>any
  readOnly                 ?: boolean
}

const StatusModalContent:React.SFC<StatusModalContentProps> = (props:StatusModalContentProps) => {
  const onClickAddButton = (status) => {
    props.addDesiredStatusException(status)
    props.close()
  }
  const onClickRemoveButton = ()=>{
    props.deleteDesiredStatus()
    props.close()
  }
  const buttonEnabled = {
    buttonName : props.translate("campaigns.exceptionModal.forceEnabled"),
    onClick    : ()=>{onClickAddButton(Status.ENABLED)}
  }
  const buttonPaused = {
    buttonName : props.translate("campaigns.exceptionModal.forcePaused"),
    onClick    : ()=>{onClickAddButton(Status.PAUSED)}
  }
  const buttonRemoved = {
    buttonName : props.translate("campaigns.exceptionModal.removeException"),
    onClick    : ()=>{onClickRemoveButton()}
  }
  let buttons = []
  let messageText
  if(props.hasException){
    if(props.statusExceptionType === Status.ENABLED ){
      messageText = props.translate("campaigns.exceptionModal.currentlyEnabled")
      buttons.push(buttonPaused)
    }
    else if(props.statusExceptionType === Status.PAUSED){
      messageText = props.translate("campaigns.exceptionModal.currentlyPaused")
      buttons.push(buttonEnabled)
    }
    else{
      messageText = "Campaigns Don't have same Exception, do you want to "
      buttons.push(buttonPaused)
      buttons.push(buttonEnabled)
    }
    buttons.push(buttonRemoved)
  }
  else{
    messageText = props.translate("campaigns.exceptionModal.noException")
    buttons.push(buttonEnabled)
    buttons.push(buttonPaused)
  }
  return (
    <div className="StatusModalContent">
      <div className="ModelStatusExceptionText">{messageText}</div>
      {buttons.map((attributeButton,i)=>
        <span key={i} className={props.readOnly?"ReadOnlyStatusExceptionButton":""}>
          <Button
            onClick={attributeButton.onClick}
            className="StatusExceptionButton"
            disabled={props.readOnly}
          >
            {attributeButton.buttonName}
          </Button>
        </span>
      )}
    </div>
  )
}
StatusModalContent.defaultProps = {
  readOnly : false
}
export default withLocalize(StatusModalContent as any) as any
