import './BudgetPlanner.css'
import * as React                  from "react"
import {connect}                   from "react-redux"
import {withLocalize, Translate}   from "react-localize-redux"
import {Link, withRouter}          from "react-router-dom"
import {
  getBudgetPlans,
  retrieveIfBudgetPlanActive
}                                  from "../../../actions/BudgetsPlan"
import {getBudgets}                from "../../../actions/Budgets"
import {ScreenSize}                from "../../../actions/ScreenSize"
import {getMonthlyStatsOfBudgets}  from "../../../actions/statistics/budgetsStats"
import {savePlanPart}              from "./action/SaveBudgetPlanPart"
import AccountsAccessors           from "../../../storeAccessor/Accounts"
import StatsAccessors              from "../../../storeAccessor/Statistics"
import NumFormatter                from "../../../utils/NumberFormatter"
import ArrayUtils                  from "../../../utils/ArrayUtils"
import DateUtils                   from "../../../utils/DateUtils"
import BudgetPlan                  from "../../../model/Store/BudgetPlan/BudgetPlan"
import MonthlyBudgetStat           from "../../../model/Store/Statistics/MonthlyBudgetsStats"
import {
  KNOWN_PROVIDER,
  StandardProvider
}                                  from "../../../model/constant/Provider"
import PERMISSIONS                 from "../../../model/constant/Permissions"
import Budget                      from "./model/Budget"
import {Button, Modal}             from "carbon-components-react"
import {Panel}                     from "../../../components/Panel/Panel"
import AccountPermissionBoundary   from "../../../components/permissions/AccountPermissionBoundary"
import Notice                      from "../../../components/alert/Notice"
import ExplainedLoading            from "../../../components/loading"
import DisabledPageOverlay         from "../../../components/overlay/DisabledPageOverlay"
import BudgetPlannerProvider       from "./component/BudgetPlannerProvider"
import MigrationModal              from "../MigrationModal/"

interface BudgetPlannerProps{
  accountId                     : number
  selectedAccountName           : string
  isAccountLoaded               : boolean
  screenWidth                   : number
  isBudgetPlanActive            : boolean
  budgetPlans                   : BudgetPlan[]
  allBudgets                    : Budget[]
  monthlyBudgetStats            : MonthlyBudgetStat[]
  ongoingBudgetStats            : MonthlyBudgetStat[]
  retrieveIfBudgetPlanActive    : ()=>void
  getBudgetName                 : (budgetId:string)=>string
  loadBudgetPlans               : ()=>void
  loadBudgets                   : ()=>void
  loadMonthlyBudgetStats        : ()=>void
  saveChangedPartAmount         : (provider:string,budgetId:number,period:string,amount:number)=>void
  getScreenSize                 : (event)=>any
  fetchingBudgetPlans           : boolean
  fetchingBudgets               : boolean
  retrievingMonthlyBudgetStats  : boolean
  readOnly                     ?: boolean
  translate                    ?: (translateId:string)=>string
  history                      ?: any
}
const makeMapStateToProps = () => {
  const accountAWIdSelector = AccountsAccessors.makeSelectedAccountAWIdSelector()
  const budgetStatsByMonthSelector = StatsAccessors.makeBudgetStatsByMonthSelector()
  return (state, ownProps) => {
    const accountId = state.Accounts.selected
    const monthlyBudgetStats = state.Statistics.Google.MonthlyBudgets[accountId]
    const currentMonth = (new Date()).getUTCFullYear()+"-"+DateUtils.leftPadMonth((new Date()).getUTCMonth()+1)
    return {
      accountId             : accountId,
      accountAWId           : accountAWIdSelector(state),
      selectedAccountName   : state.Accounts.list.find(x=>x.id==accountId).name,
      isAccountLoaded       : ArrayUtils.contain(state.Params.BudgetsPlans.FetchedAccounts, accountId),
      allBudgets            : state.Params.BudgetsPlans.Budgets[accountId],
      monthlyBudgetStats    : monthlyBudgetStats,
      ongoingBudgetStats    : monthlyBudgetStats ? monthlyBudgetStats.filter(x=>x.Month===currentMonth) : undefined,
      budgetPlans           : state.Params.BudgetsPlans.BudgetsPlanned[accountId]||[],
      fetchingBudgetPlans   : state.Params.BudgetsPlans.RetrievingBudgetPlans,
      fetchingBudgets       : state.Params.BudgetsPlans.RetrievingBudgets,
      screenWidth           : state.ScreenSize,
      isBudgetPlanActive    : state.Params.BudgetsPlans.IsActive[accountId],
      retrievingBudgetStats : state.Statistics.Retrieving.MonthlyBudgets
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadBudgetPlans            : (accountId:number)=>dispatch(getBudgetPlans(accountId)),
    loadBudgets                : (accountId:number)=>dispatch(getBudgets(accountId)),
    getScreenSize              : (event) => dispatch(ScreenSize()),
    retrieveIfBudgetPlanActive : (accountId:number)=>dispatch(retrieveIfBudgetPlanActive(accountId)),
    saveChangedPartAmount      : (accountId:number, provider:string, budgetId:number, period:string, amount:number)=>{
      dispatch(savePlanPart(accountId, provider, budgetId, period, amount))
    },
    loadMonthlyBudgetStats            : (accountId:number, accountAWId:string)=>(
      dispatch(getMonthlyStatsOfBudgets(accountId, accountAWId))
    )
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    loadBudgetPlans       : ()=>DP.loadBudgetPlans(SP.accountId),
    loadBudgets           : ()=>DP.loadBudgets(SP.accountId),
    retrieveIfBudgetPlanActive : ()=>DP.retrieveIfBudgetPlanActive(SP.accountId),
    saveChangedPartAmount : (provider:string, budgetId:number, period:string, amount:number)=>{
      DP.saveChangedPartAmount(SP.accountId, provider, budgetId, period, amount)
    },
    loadMonthlyBudgetStats : ()=>DP.loadMonthlyBudgetStats(SP.accountId, SP.accountAWId)
  }
}
interface BudgetPlannerState{
  activeProvider : string
  activeBudget   : Budget
  activeYear     : string
  activeMonth    : string
  startingYear   : number
  startingMonth  : number
  isModalOpened  : boolean
  migrating      : boolean
  dataToSave     : {
    provider ?: any
    budgetId ?: any
    period   ?: any
    amount   ?: any
  }
}
const PermissionWrapper = (props) => (
  <AccountPermissionBoundary
    permissions={[PERMISSIONS.Keystone_ChangeBudget]}
    onFailure={<BudgetPlanner {...props} readOnly/>}
  >
    <BudgetPlanner {...props}/>
  </AccountPermissionBoundary>
)
class BudgetPlanner extends React.Component<BudgetPlannerProps,BudgetPlannerState>{
  static defaultProps = {
    readOnly : false
  }
  constructor(props){
    super(props)
    var month = (new Date()).getUTCMonth() // given as 0-11
    month += 1 // we want 1-12
    month -= 1 // we want the month previous to the current
    const year  = (new Date()).getUTCFullYear()
    const startingMonth = month!=0 ? month : 12 // leftmost month displayed
    const startingYear = month!=0 ? year  : year-1 // year of the leftmost month
    this.state = {
      activeProvider   : null,
      activeBudget     : null,
      activeMonth      : "",
      activeYear       : "",
      startingMonth    : startingMonth,
      startingYear     : startingYear,
      isModalOpened    : false,
      migrating        : false,
      dataToSave       : {},
    }
    this.setActiveBudgetPlanPart = this.setActiveBudgetPlanPart.bind(this)
    this.goLeft                  = this.goLeft.bind(this)
    this.goRight                 = this.goRight.bind(this)
    this.openConfirmationModal   = this.openConfirmationModal.bind(this)
    this.closeConfirmationModal  = this.closeConfirmationModal.bind(this)
    this.saveNewAmount           = this.saveNewAmount.bind(this)
  }
  setActiveBudgetPlanPart(provider:string,budget:Budget,year:string,month:string){
    if(this.props.isBudgetPlanActive){
      this.setState({
        activeBudget   : budget,
        activeYear     : year,
        activeMonth    : month,
        activeProvider : provider
      })
    }
  }
  componentDidMount(){
    window.addEventListener('resize', this.props.getScreenSize)
    this.props.loadMonthlyBudgetStats()
    if(this.props.isBudgetPlanActive === undefined){this.props.retrieveIfBudgetPlanActive()}
  }
  componentWillUnmount(){
    window.removeEventListener("resize", this.props.getScreenSize)
  }
  componentDidUpdate(prevProps){
    if(prevProps.accountId !== this.props.accountId){
      if(this.props.monthlyBudgetStats === undefined){this.props.loadMonthlyBudgetStats()}
      if(this.props.isBudgetPlanActive === undefined){this.props.retrieveIfBudgetPlanActive()}
    }
  }
  getBudgetName(budgetId):string{
    if(this.props.allBudgets===undefined){return "Unknown budget"}
    const budget = this.props.allBudgets.find(b=>b.budgetId == this.state.dataToSave.budgetId)
    if(budget===undefined){return "Unknown budget"}
    return budget.budgetName.replace("Automated - ","")
  }
  goLeft(){
    this.setState(prevState=>({
      startingMonth : prevState.startingMonth-1>0?prevState.startingMonth-1:12,
      startingYear  : prevState.startingMonth-1>0?prevState.startingYear:prevState.startingYear-1,
    }))
  }
  goRight(){
    this.setState(prevState=>({
      startingMonth : prevState.startingMonth+1>12?1:prevState.startingMonth+1,
      startingYear  : prevState.startingMonth+1>12?prevState.startingYear+1:prevState.startingYear,
    }))
  }
  openConfirmationModal(provider, budgetId, period, amount){
    this.setState({
      isModalOpened : true,
      dataToSave : {
        provider : provider,
        budgetId : budgetId,
        period   : period,
        amount   : amount
      }
    })
  }
  closeConfirmationModal(){
    this.setState({isModalOpened:false})
  }
  saveNewAmount(){
    this.props.saveChangedPartAmount(
      this.state.dataToSave.provider,
      this.state.dataToSave.budgetId,
      this.state.dataToSave.period,
      this.state.dataToSave.amount,
    )
  }
  render(){
    if(this.props.screenWidth === -1){this.props.getScreenSize(0)}
    if(!this.props.isAccountLoaded){
      if(!this.props.fetchingBudgets && !this.props.allBudgets){this.props.loadBudgets()}
      if(!this.props.fetchingBudgetPlans){this.props.loadBudgetPlans()}
      return <ExplainedLoading translateId="loadings.data"/>
    }
    if(this.props.isBudgetPlanActive === undefined){
      return <ExplainedLoading translateId="loadings.data"/>
    }
    if(this.props.monthlyBudgetStats===undefined){
      return <ExplainedLoading translateId="loadings.budgetsStats"/>
    }
    var providers = []
    for(let p in KNOWN_PROVIDER){
      let provider = KNOWN_PROVIDER[p]
      for(let b in this.props.allBudgets){
        let budget = this.props.allBudgets[b]
        if(KNOWN_PROVIDER[p].IDKey in budget && budget[provider.IDKey]){
          providers.push(provider)
        }
      }
    }
    providers = ArrayUtils.unique(providers)
    let accountId = this.props.accountId
    return (
      <div className={"BudgetPlanner"}>
        {!this.props.isBudgetPlanActive &&
          <DisabledPageOverlay
            translateIds={["budgets.inactiveMessage1","budgets.inactiveMessage2"]}
            dismissable
          />
        }
        <Panel title={this.props.translate("budgets.title")} subtitle={this.props.translate("budgets.subtitle")}>
          <Notice
            text="You are not allowed to edit budgets"
            isActive={this.props.readOnly}
          />
          <div className='viewChangesBtn'>
            <AccountPermissionBoundary
              permissions={[PERMISSIONS.Pacy_MigrateAccount]}
              onFailure={null}
            >
              <Button onClick={()=>this.setState(prevState=>({migrating: !prevState.migrating}))} style={{marginRight: "10px"}}>
                <Translate id="budgets.migrateButton"/>
              </Button>
            </AccountPermissionBoundary>
            <Link to='ChangesHistory'>
              <Button>
                <Translate id="budgets.historyButton"/>
              </Button>
            </Link>
          </div>
          <div className="MainBudgetsContent">
            <div className="NavigationButtons">
              <Button className="btn btn-info previous" onClick={this.goLeft}>&lt;</Button>
              <Button className="btn btn-info next" onClick={this.goRight}>&gt;</Button>
            </div>
            {providers.map((provider, key)=>
              <BudgetPlannerProvider
                key               = {key}
                year              = {this.state.startingYear}
                month             = {this.state.startingMonth}
                providerName      = {StandardProvider[provider.name]}
                providerNiceName  = {provider.niceName}
                accountId         = {accountId}
                screenWidth       = {this.props.screenWidth}
                budgets           = {this.props.allBudgets}
                budgetPlans       = {this.props.budgetPlans.filter((bp)=>{return bp.getProvider()===StandardProvider[provider.name]})}
                monthlyBudgetStats= {this.props.monthlyBudgetStats}
                ongoingBudgetStats= {this.props.ongoingBudgetStats}
                isProviderActive  = {this.state.activeProvider === provider.name}
                activeBudget      = {this.state.activeBudget}
                activeYear        = {this.state.activeYear}
                activeMonth       = {this.state.activeMonth}
                selectPart        = {(budget:Budget,year:string,month:string)=>this.setActiveBudgetPlanPart(provider.name,budget,year,month)}
                changedPartAmount = {(budgetId:number,period:string,amount:number)=>this.openConfirmationModal(StandardProvider[provider.name],budgetId,period,amount)}
              />
            )}
          </div>
          <Modal
            className={this.props.readOnly?"ReadOnlyConfirmationModal":""}
            open={this.state.isModalOpened}
            primaryButtonText={this.props.translate("common.yes")}
            secondaryButtonText={this.props.translate("common.cancel")}
            onRequestClose={this.closeConfirmationModal}
            onRequestSubmit={()=>{this.saveNewAmount();this.closeConfirmationModal()}}
            onSecondarySubmit={this.closeConfirmationModal}
            primaryButtonDisabled={this.props.readOnly}
          >
            <div>
              <div><Translate id="budgets.confirmationMessage"/></div>
              <div><Translate id="budgets.account"/>: {this.props.selectedAccountName}</div>
              <div><Translate id="budgets.provider"/>: {this.state.dataToSave.provider}</div>
              <div><Translate id="budgets.period"/>: {this.state.dataToSave.period}</div>
              <div><Translate id="budgets.budget"/>: {this.getBudgetName(this.state.dataToSave.budgetId)}</div>
              <div><Translate id="budgets.amount"/>: {this.state.dataToSave.amount ? NumFormatter.formatCurrency(this.state.dataToSave.amount) : NumFormatter.formatCurrency(0)}</div>
            </div>
          </Modal>
          <MigrationModal
            show={this.state.migrating}
            onClose={()=>this.setState({migrating: false})}
          />
        </Panel>
      </div>
    )
  }
}

export default (
  withLocalize(
    withRouter (
      connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
        PermissionWrapper
      )
    ) as any
  )
)
