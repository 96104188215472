import * as React              from 'react'
import ArrayUtils              from '../../utils/ArrayUtils'
import ObjectUtils             from '../../utils/ObjectUtils'
import Replacement, {
  spacesToUnderscore,
  underscoreToSpaces
}                              from '../../model/grammarBooster/Replacement'
import {Button}                from "carbon-components-react"


interface GrammarBoosterTesterProps {
  runTest : (lang:string, test:string)=>void
  testResult ?: string
}
interface GrammarBoosterTesterState {
  testing   : string
  lang      : string
  forceTest : boolean
}

class GrammarBoosterTester extends React.Component<GrammarBoosterTesterProps,GrammarBoosterTesterState>{

  constructor(props:GrammarBoosterTesterProps){
    super(props)
    this.state = {
      testing   : "test_here",
      lang      : "EN",
      forceTest : false
    }
    this.handleInputKeyPress = this.handleInputKeyPress.bind(this)
    this.onTestBlur = this.onTestBlur.bind(this)
    this.onTestChange = this.onTestChange.bind(this)
    this.onLangChange = this.onLangChange.bind(this)
    this.runTest = this.runTest.bind(this)
  }
  componentDidUpdate(prevProps:GrammarBoosterTesterProps, prevState:GrammarBoosterTesterState){
    if(!prevState.forceTest && this.state.forceTest){
      this.runTest()
      this.setState({forceTest: false})
    }
  }
  onTestBlur(e){
    this.setState({
      testing   : e.target.value,
      forceTest : true
    })
  }
  onTestChange(e){
    this.setState({testing : e.target.value})
  }
  onLangChange(e){
    this.setState({
      lang      : e.target.value,
      forceTest : true
    })
  }
  runTest(){
    this.props.runTest(this.state.lang, underscoreToSpaces(this.state.testing))
  }
  handleInputKeyPress(event){
    if(event.key === "Enter"){
      this.onTestBlur(event)
    }
  }
  render(){
    return (
      <div className='GrammarBoosterTester'>
        <div><h4>Testing</h4></div>
        <div className='Grid'>
          <div className='TestingLang'>
            <select
              className="ReplacementKeyStrings wrong"
              defaultValue={"EN"}
              onChange={this.onLangChange}
              placeholder="EN"
            >
              <option value="EN">EN</option>
              <option value="FR">FR</option>
              <option value="ES">ES</option>
            </select>
          </div>
          <div className='TestingValue'>
            <input
              className="ReplacementKeyStrings wrong"
              value={spacesToUnderscore(this.state.testing)}
              onChange={this.onTestChange}
              onKeyPress={this.handleInputKeyPress}
              onBlur={this.onTestBlur}
            ></input>
          </div>
          <div className='TestResult'>
            {this.props.testResult===undefined?"No test ran yet":this.props.testResult}
          </div>
          <div className="RunTest">
            <Button onClick={this.runTest}>Test</Button>
          </div>
        </div>
      </div>
    )
  }
}
export default GrammarBoosterTester
