import './Tester.css'
import * as React             from 'react'
import {
   Button,
   Dialog,
   Icon,
}                             from '@material-ui/core'
import IdentityVariantsTester from '../../../components/branding/IdentityVariantsTester'
import { AdTemplate }         from '../../../model/adbuilder'
import AdCopy                 from '../../../model/Store/AdCopyPatterns/AdCopy'
import NumFormatter           from '../../../utils/NumberFormatter'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                             from 'react-localize-redux'

interface TesterOwnProps{
  passing     : number
  failing     : number
  lang        : string
  adCopy      : AdTemplate
  setPassing ?: any
  setFailing ?: any
}
interface TesterProps extends TesterOwnProps {
  translate : TranslateFunction
}

const Tester = (props:TesterProps) => {
  const [openPassing,setOpenPassing] = React.useState(false)
  const [openFailing,setOpenFailing] = React.useState(false)
  function handleOpenPassing() {
    setOpenPassing(true)
  }
  function handleOpenFailing() {
    setOpenFailing(true)
  }
  function handleClosePassing() {
    setOpenPassing(false)
  }
  function handleCloseFailing(){
    setOpenFailing(false)
  }
  return (
    <div className="Tester">
      {props.passing > 0 &&
        <Button onClick={handleOpenPassing} variant="contained" color="primary" className="Passing">
          <Icon className="Icon">search</Icon>
          <strong>{props.passing > 1
            ? props.translate("branding.identityVariantsTester.valid")+'s'
            : props.translate("branding.identityVariantsTester.valid")}: {NumFormatter.formatNumber(props.passing)}
          </strong>
        </Button>
      }
      {props.failing > 0 &&
        <Button onClick={handleOpenFailing} variant="contained" color="secondary" className="Failing">
          <Icon className="Icon">search</Icon>
          <strong>{props.failing > 1
            ? props.translate("branding.identityVariantsTester.invalid")+'s'
            : props.translate("branding.identityVariantsTester.invalid")} : {NumFormatter.formatNumber(props.failing)}
          </strong>
        </Button>
      }
      <Dialog open={openPassing} onClose={handleClosePassing}>
        <IdentityVariantsTester
          specification="passing"
          maxChar={{headlines:30,descriptions:90}}
          lang={props.lang}
          adCopy={props.adCopy as AdCopy}
          setPassing={props.setPassing}
          setFailing={props.setFailing}
        />
      </Dialog>
      <Dialog keepMounted open={openFailing} onClose={handleCloseFailing}>
        <IdentityVariantsTester
          specification="failing"
          maxChar={{headlines:30,descriptions:90}}
          lang={props.lang}
          adCopy={props.adCopy as AdCopy}
          setPassing={props.setPassing}
          setFailing={props.setFailing}
        />
      </Dialog>
    </div>
  )
}

export default withLocalize(Tester as any) as React.ComponentType<TesterOwnProps>
