import {combineReducers}           from 'redux'
import {GeoBids}                   from "./GeoBids"
import {Templates}                 from "./Templates"
import AdBuilder                   from "./adBuilder/AdBuilder"
import brandingNewAdGroup          from "./BrandingNewAdGroup/brandingNewAdGroup"
import AudienceAdToEdit            from "./BrandingNewAdGroup/AudienceAdToEdit"
import PacyConfigs                 from "./PacyConfigs/PacyConfigs"

export const EditCopies = combineReducers({
  GeoBids,
  Templates,
  AdBuilder,
  brandingNewAdGroup,
  AudienceAdToEdit,
  PacyConfigs,
})

