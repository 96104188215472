import styles            from "./TagStyles"
import * as React        from "react"
import {DragSource}      from "react-dnd"
import ItemTypes         from "../../../model/constant/ItemTypes"

const tagSource = {
  beginDrag(props){
    return {
      tag : props.tag
    }
  }
}
function collect(connect, monitor){
  return{
    connectDragSource : connect.dragSource(),
    isDragging : monitor.isDragging()
  }
}
interface TagProps {
  tag               : string
  connectDragSource : any
  isDragging        : any
}
const Tag = (props:TagProps) => {
  const classes = styles()
  return(
    props.connectDragSource(
      <div className={props.isDragging?classes.dragging:classes.tag}>
        {props.tag}
      </div>
    )
  )
}
export default DragSource(ItemTypes.TAG, tagSource, collect)(Tag)
