import './KeywordsEditor.css'
import * as React                   from "react"
import {withLocalize, Translate}    from "react-localize-redux"
import Pattern                      from "../model/Pattern"
import {Button, TextArea}           from "carbon-components-react"
import ExplainedLoading             from "../../../components/loading"
import KeywordsUtils                from "../../../utils/KeywordsUtils"
import { INVENTORY_TAGS }           from "../../../model/constant/KeywordsTags"
import { withStyles }               from "@material-ui/styles"
import { Theme, Tooltip, Icon }     from "@material-ui/core"

interface KeywordsEditorProps{
  keywords     : Pattern[]
  group        : string
  saveKeywords : (positiveKeywords:string,negativeKeywords:string)=>void
  readOnly    ?: boolean
  translate   ?: (translateId:string)=>string
}
interface KeywordsEditorState {
  edited           : boolean
  saving           : boolean
  positiveValueTxt : string
  negativeValueTxt : string
  [input:string]   : any
}

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: 10,
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

class KeywordsEditor extends React.Component<KeywordsEditorProps,KeywordsEditorState>{
  static defaultProps = {
    readOnly : false
  }
  constructor(props){
    super(props)
    this.state = {
      edited:false,
      saving:false,
      positiveValueTxt:this.extractKeywordsText(this.props.keywords, true),
      negativeValueTxt:this.extractKeywordsText(this.props.keywords, false)
    }
    this.resetInitial = this.resetInitial.bind(this)
    this.saveChanges = this.saveChanges.bind(this)
    this.updateValueTxtArea = this.updateValueTxtArea.bind(this)
  }
  componentDidUpdate(prevProps, prevState){
    if(this.state.saving && prevProps.keywords != this.props.keywords){
      this.setState({
        saving:false,
        edited:false
      })
    }
  }
  extractKeywordsText(keywords:any[], positive:boolean):string{
    const ordered = keywords
    .filter((kw)=>kw.positive === positive)
    .map((kw)=>{return kw.text})
    .sort()
    return ordered.join("\n")

  }
  resetInitial(event){
    this.setState({
      edited : false,
      positiveValueTxt : this.extractKeywordsText(this.props.keywords, true),
      negativeValueTxt : this.extractKeywordsText(this.props.keywords, false)
    })
  }
  updateValueTxtArea(event){
    this.setState({
      [event.target.name] : event.target.value,
      edited : true
    })
  }
  saveChanges = (event) => {
    this.props.saveKeywords(this.state.positiveValueTxt,this.state.negativeValueTxt)
    this.setState({saving : true})
  }
  render() {
    const tags = this.props.group === 'SRP_MM' ? INVENTORY_TAGS.MM : INVENTORY_TAGS.ALL
    const positiveKeywords = this.state.positiveValueTxt.split('\n').map(keyword=>keyword.trim()).filter(keyword=>keyword)
    const negativeKeywords = this.state.negativeValueTxt.split('\n').map(keyword=>keyword.trim()).filter(keyword=>keyword)
    const negativeErrors = KeywordsUtils.negativeKeywordsErrors(negativeKeywords)
    const positiveErrors = KeywordsUtils.positiveKeywordsError(positiveKeywords,tags)
    if(this.state.saving){return <ExplainedLoading translateId="loadings.saving"/>}
    return (
      <div className="KeywordsGroup">
        <div className="KeywordsGroupTitle">
          {this.props.group}
        </div>
        <div className="KeywordsTextArea">
          <TextArea
            name      = "positiveValueTxt"
            labelText = {
              <div style={{display:'flex',alignItems:'center'}}>
                {this.props.translate("keywords.posKeywords")}
                {positiveErrors.length !==0 && <HtmlTooltip
                  placement="right"
                  title={positiveErrors.map((error,i)=>
                    <p key={i} style={{color: '#e53935'}}>-<Translate id={error as string} /></p>
                  )}
                >
                  <Icon style={{marginBottom:3,marginLeft:5}} color="secondary">error</Icon>
                </HtmlTooltip>}
              </div>
            }
            value     = {this.state.positiveValueTxt}
            onChange  = {this.updateValueTxtArea}
          />
        </div>
        <div className="KeywordsTextArea">
          <TextArea
            name      = "negativeValueTxt"
            labelText = {
              <div style={{display:'flex',alignItems:'center'}}>
                {this.props.translate("keywords.negKeywords")}
                {negativeErrors.length !==0 && <HtmlTooltip
                  placement="right"
                  title={negativeErrors.map((error,i)=>
                    <p key={i} style={{color: '#e53935'}}>-<Translate id={error as string} /></p>
                )}
                >
                  <Icon style={{marginBottom:3,marginLeft:5}} color="secondary">error</Icon>
                </HtmlTooltip>}
              </div>
            }
            value     = {this.state.negativeValueTxt}
            onChange  = {this.updateValueTxtArea}
          />
        </div>
        <div className={"KeywordsButtons"}>
          <div className={(this.props.readOnly?" readOnlyBanner":"")}>
            <Button
              className={"SubmitChangesOnKeywords"}
              type="submit" kind="primary"
              disabled={this.props.readOnly || !this.state.edited || positiveErrors.length !==0 || negativeErrors.length !==0}
              onClick={this.saveChanges}
            >
              <Translate id="common.save"/>
            </Button>
          </div>
          <Button
            type="submit" className="ResetInitialKeywords" kind="primary"
            disabled={!this.state.edited}
            onClick={this.resetInitial}
          >
            <Translate id="common.reset"/>
          </Button>
        </div>
      </div>
    )
  }
}

export default withLocalize(KeywordsEditor)
