import styles                       from "./EventOverviewStyles"
import * as React                   from "react"
import {connect}                    from "react-redux"
import {Translate}                  from "react-localize-redux"
import DateUtils                    from "../../../utils/DateUtils"
import {
  updateMarketingEventStatus,
  copyMarketingEvent
}                                   from "../../../actions/adBuilder/events"
import {MarketingEvent}             from "../../../model/adbuilder"
import {AdCopyRSATemplates}         from "../../../model/adcopy"
import KeyValueTable                from "../../../components/table/SimpleKeyValueTable"
import StatusDisplayButton          from "./StatusDisplayButton"
import AdPreview                    from "./AdPreview"
import {
  Button,
  Tooltip
}                                   from "@material-ui/core"
import EventBoundaries              from "./EventBoundaries"
import QueueOutlinedIcon            from '@material-ui/icons/QueueOutlined'
import Carousel                     from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

interface EventOverviewOwnProps{
  event       : MarketingEvent
  goToEvent   : () => void
  goToCreate  : () => void
  copyCreated : () => void
  readOnly   ?: boolean
}

const mapStateToProps = (state, _ownProps:EventOverviewOwnProps)=>{
  return {
    accountId : state.Accounts.selected
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    copyMarketingEvent       : (...args:Parameters<typeof copyMarketingEvent>)=>dispatch(copyMarketingEvent(...args)),
    updateMarketingEventStatus : (...args:Parameters<typeof updateMarketingEventStatus>)=>dispatch(updateMarketingEventStatus(...args))
  }
}

const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:EventOverviewOwnProps)=>{
  return {
    ...SP,...DP,...ownProps
  }
}

type EventOverviewProps = ReturnType<typeof mergeProps>

const EventOverview = (props:EventOverviewProps) => {
  const copyEvent = () => {
    props.copyMarketingEvent(props.accountId, props.event)
    props.copyCreated()
  }

  const handleChangeStatus = () => {
    props.updateMarketingEventStatus(props.accountId, props.event)
  }
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  }

  const classes = styles()
  return (
    <div className={classes.eventOverview}>
      <Tooltip title={props.event.title}>
        <div className={classes.title}>
          {props.event.title.slice(0,25)+(props.event.title.length>25?"...":"")}
          <QueueOutlinedIcon className={classes.icon} onClick={copyEvent} />
        </div>
      </Tooltip>
      <div className={classes.statusAndDates}>
        <StatusDisplayButton event={props.event} changeStatus={handleChangeStatus}/>
        <KeyValueTable
          keyValue={{
            "adBuilder.eventOverview.startDate" : DateUtils.format(props.event.startDate, "%d-%m-%Y"),
            "adBuilder.eventOverview.endDate"   : DateUtils.format(props.event.endDate, "%d-%m-%Y"),
          }}
          translateKeys
        />
      </div>
      <div className={classes.boundaries}>
        { props.event.boundaries.length > 1 
          ? <Carousel
              swipeable={false}
              draggable={false}
              showDots={true}
              responsive={responsive}
              ssr={true} 
              infinite={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="boundaries"
            >
              {props.event.boundaries.map(boundary=>{
                return (<EventBoundaries key={boundary} boundary={boundary} spillRight={false}/>)
              })}
            </Carousel>
          : <EventBoundaries boundary={props.event.boundaries[0]} spillRight={false}/>
        }
      </div>
      <AdPreview event={props.event} />
      <div className={classes.buttonContainer}>
        <Button 
          className={classes.button}
          color="primary"
          onClick={props.goToEvent}
        >
          <Translate id="common.edit" />
        </Button>
        { props.event.isDuplicate &&
          <div className={classes.isCopy}>
            • <Translate id="adBuilder.copy" />
          </div>
        }
      </div>
    </div>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(EventOverview)
