import {combineReducers}                                     from "redux"
import {CampaignsStats}                                      from "./Campaigns"
import {ImpressionsByDevice}                                 from "./ImpressionsByDevice"
import {SearchTermsStats}                                    from "./SearchTerms"
import {AdsStats}                                            from "./Ads"
import {KeywordsStats}                                       from "./Keywords"
import {GeoBidsStats}                                        from "./GeoBids"
import {BudgetsStats}                                        from "./Budgets"
import MonthlyBudgets                                        from "./MonthlyBudgets"
import Placements                                            from "./Placements"

const GoogleStats = combineReducers({
  Campaigns                                     : CampaignsStats,
  ImpressionsByDevice                           : ImpressionsByDevice,
  SearchTerms                                   : SearchTermsStats,
  Ads                                           : AdsStats,
  Keywords                                      : KeywordsStats,
  GeoBids                                       : GeoBidsStats,
  Budgets                                       : BudgetsStats,
  MonthlyBudgets,
  Placements,
})

export default GoogleStats
