import Actions      from "../../../model/constant/actions"
import ReducerUtils from "../../../utils/ReducerUtils"

const storeBudgetPlans = (state,action) => {
  return {
    ...state,
    [action.accountId] : action.budgetPlans
  }
}
interface BudgetPlansByAccount{
  [accountId:number] : any[]
}
export const BudgetsPlanned = ReducerUtils.createReducer<BudgetPlansByAccount>({},{
  [Actions.RETRIEVE_BUDGET_PLANS_FOR_ACCOUNTID] : storeBudgetPlans,
})
