import UserAction from "../model/constant/UserAction"
import LogBuilder from "../controller/log/LogBuilder"

export const logger = store => next => action => {
	console.group(action.type)
  console.info('dispatching', action)
  const result = next(action)
  const token = store.getState().Login.userLogged.token
  if(UserAction[action.type] && token !== ""){
    LogBuilder.log(UserAction[action.type],token,action)
  }
  console.log('next state', store.getState())
  console.groupEnd()
  return result
}
