import './GeoTargetingType.css'
import * as React 	 				from 'react'
import {
	Typography,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	CardContent,
  CardHeader,
} 									 				from '@material-ui/core'
import {
	Translate,
	withLocalize,
	TranslateFunction
} 									 				from 'react-localize-redux'
import Strategy      				from '../../../../model/Store/Strategy/Strategy'


interface GeoTargetingTypeOwnProps {
  strategy    	: Strategy
	setStrategy 	: any
	setStrategies : any
  geoType     	: string
  lang        	: string
  accountId   	: number
}
interface GeoTargetingTypeProps extends GeoTargetingTypeOwnProps{
	translate : TranslateFunction
}

const GeoTargetingType = (props:GeoTargetingTypeProps) => {
  const [geoType, setGeoType] = React.useState(props.geoType)
  React.useEffect(()=>{
    setGeoType(props.geoType)
  },[props.geoType,props.lang,props.accountId])
	function handleChange(event) {
		setGeoType(event.target.value)
		const geoTType = event.target.value
		props.setStrategies((prevState)=>
			prevState.map(x=>{
				if(x.lang === props.lang){
					return {
						...x,
						geoTargetingType : geoTType
					}
				}
				return x
			})
		)
		props.setStrategy({...props.strategy, geoTargetingType:event.target.value})
  }

  return (
    <div className="GeoTargetingType">
      <CardHeader
        title={<Typography variant="h6"><Translate id="strategies.geoTargetingType" /></Typography>}
        subheader={<Typography variant="caption"><Translate id="branding.descriptions.geoTargeting" /></Typography>}
      />
			<CardContent className="RadioInput">
				<FormControl component="fieldset">
					<RadioGroup aria-label="geoTargetingTye" name="geoType" value={geoType} onChange={handleChange}>
						<FormControlLabel
							className="RadioLabel"
							value="DONT_CARE"
							control={<Radio color="primary" />}
							label={props.translate('branding.geoTargetingType.dontCare')}
						/>
						<FormControlLabel
							className="RadioLabel"
							value="AREA_OF_INTEREST"
							control={<Radio
							color="primary"/>}
							label={props.translate('branding.geoTargetingType.areaOfInterest')}
						/>
						<FormControlLabel
							className="RadioLabel"
							value="LOCATION_OF_PRESENCE"
							control={<Radio color="primary" />}
							label={props.translate('branding.geoTargetingType.locationOfPresence')}
						/>
					</RadioGroup>
				</FormControl>
			</CardContent>
  	</div>
    )
}

export default withLocalize(GeoTargetingType as any) as React.ComponentType<GeoTargetingTypeOwnProps>
