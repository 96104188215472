import './CampaignsTable.css'
import * as React                        from "react"
import {withLocalize, TranslateFunction} from "react-localize-redux"
import {withRouter}                      from "react-router"
import {Link}                            from "react-router-dom"
import BootstrapTable                    from "react-bootstrap-table-next"
import filterFactory                     from "react-bootstrap-table2-filter"
import {textFilter}                      from "react-bootstrap-table2-filter"
import {selectFilter}                    from "react-bootstrap-table2-filter"
import paginationFactory                 from "react-bootstrap-table2-paginator"
import SearchCampaign                    from "../../../../model/Store/Campaign/SearchCampaign"
import RoutingUtils                      from "../../../../utils/RoutingUtils"
import NumFormatter                      from '../../../../utils/NumberFormatter'
import StatusModal                       from "./StatusModal/"

interface CampaignsListProps{
  data      : CampaignsTableData[]
  columns   : CampaignsColumns[]
  //Given by higher order component
  translate : TranslateFunction
  history
}
interface CampaignsTableData{
  accountId     : number
  campaigns     : SearchCampaign[]
  id           ?: any
  status       ?: any
  stock        ?: any
  name         ?: any
  vehicleState ?: any
  group        ?: any
  clicks       ?: any
  CPC          ?: any
  cost         ?: any
}
type CampaignsColumns = "id" | "status" | "stock" | "name" | "vehicleState" | "group" | "clicks" | "CPC" | "cost"

const daysInInventoryFormatter = (cell: string | number) => {
  if (typeof cell === 'number') {
    return NumFormatter.formatNumber(cell, 2)
  }
  return cell
}

const COLUMNS_BY_NAME = {
  id : {
    dataField:"id",
    isKey:true,
    hidden:true,
    text:"",
    style:{width: "5%" }
  },
  status : {
    text:"campaigns.list.status",
    dataField:"status",
    sort:true,
    formatter : (cell,row)=>{
      return <StatusModal accountId={row.accountId} campaignName={row.name} campaigns={row.campaigns} showStatusCircle={false}/>
    },
    generateFilterFunc : (data, translate)=>selectFilter({options:generateFilters(data.map(f=>f.status), translate)}),
    style:{width: "5%", padding:"0px", height: "60px" }
  },
  simpleStatus : {
    text:"campaigns.list.status",
    dataField:"status",
    sort:true,
    formatter : (cell,row)=> <div className="SimpleStatusCell" onClick={()=>alert("Cannot change status of display campaigns yet.")}>{cell}</div>,
    generateFilterFunc : (data, translate)=>selectFilter({options:generateFilters(data.map(f=>f.status), translate)}),
    style:{width: "5%", padding:"0px", height: "60px" }
  },
  stock : {
    dataField:"stock",
    filter:textFilter({delay:10}),
    text:"campaigns.list.stock",
    style:{width: "6%" }
  },
  name : {
    text:"campaigns.list.campaign",
    dataField:"name",
    sort:true,
    generateFormatterFunc : (translate, history)=>(cell,row)=><Link to={RoutingUtils.appendAndReturnUrl(history, row.id)}>{cell}</Link>,
    filter:textFilter({delay:10}),
    style:{width: "60%" }
  },
  vehicleState : {
    text:"campaigns.list.inventory",
    dataField:"vehicleState",
    sort:true,
    generateFormatterFunc : (translate, history)=>(cell,row)=>translate("common."+cell.toLowerCase(), null, {onMissingTranslation:()=>cell}),
    generateFilterFunc : (data, translate)=>selectFilter({options:generateFilters(data.map(f=>f.vehicleState), translate)}),
    style:{width: "6%" }
  },
  group : {
    text:"campaigns.list.group",
    dataField : "group",
    sort:true,
    generateFilterFunc : (data, translate)=>selectFilter({options:generateFilters(data.map(f=>f.group), translate)}),
    style:{width: "6%" }
  },
  clicks : {
    text:"common.clicks",
    dataField:"clicks",
    sort:true,
    formatter : (x,_)=>NumFormatter.formatNumber(x, 2),
    style:{width: "5%" }
  },
  CPC : {
    text:"CPC",
    dataField:"CPC",
    sort:true,
    formatter: (x) => NumFormatter.formatCurrency(x),
    style:{width: "5%" }
  },
  cost : {
    text:"common.cost",
    dataField:"cost",
    sort:true,
    formatter: (x) => NumFormatter.formatCurrency(x),
    style:{width: "5%" }
  },
  daysInInventory : {
    text: "campaigns.list.daysInInventory",
    dataField: "daysInInventory",
    sort: true,
    formatter: daysInInventoryFormatter,
    style:{width: "5%"},
    sortFunc: (a, b, order) => {
      let aVals = null
      let bVals = null

      if (typeof a === "string") {
        aVals = a.split(" ")
      }
      if (typeof b === "string") {
        bVals = b.split(" ")
      }


      if (order === "asc") {
        if (aVals && aVals[0] === "N/A") {
          return -1
        }
        if ((bVals && bVals[0] === "N/A")) {
          return 1
        }

        return (aVals ? aVals[1] : a) - (bVals ? bVals[1] : b)
      }

      else {
        if (aVals && aVals[0] === "N/A") {
          return 1
        }
        if ((bVals && bVals[0] === "N/A")) {
          return -1
        }

        return (bVals ? bVals[1] : b) - (aVals ? aVals[1] : a)
      }
    }
  }
}

const generateFilters = (data:string[], translate:TranslateFunction) => {
  return data.reduce((filters, obj)=>{
    filters[obj] = translate("common."+obj.toLowerCase(), null, {onMissingTranslation:()=>obj})
    return filters
  },{})
}
const getRowClasses = (friend) => {
  if(friend.status=="Active"){return "ActiveCampaign"}
  return "PausedCampaign"
}
class CampaignsList extends React.Component<CampaignsListProps,any>{
  constructor(props){
    super(props)
    this.generateColumns = this.generateColumns.bind(this)
  }

  generateColumns(){
    return this.props.columns.map(x=>{
      const col:any = COLUMNS_BY_NAME[x]
      if(!col){throw Error(`Invalid column choice given. '${x}' doesn't exist`)}
      return {
        ...col,
        text : this.props.translate(col.text, null, {onMissingTranslation:()=>col.text}),
        filter : col.generateFilterFunc ? col.generateFilterFunc(this.props.data, this.props.translate) : col.filter,
        formatter : col.generateFormatterFunc ? col.generateFormatterFunc(this.props.translate, this.props.history) : col.formatter,
      }
    })
  }

  render(){
    const columns = this.generateColumns()
    const sizePerPage = 500
    const paginationOptions = {
      hideSizePerPage: true,
      hidePageListOnlyOnePage : true,
      sizePerPage : sizePerPage,
    }
    const extraProps = {
      pagination: paginationFactory(paginationOptions)
    }

    return(
      <BootstrapTable
        data={this.props.data}
        keyField="id"
        columns={columns}
        defaultSorted={[{dataField:"cost",order:"desc"}]}
        filter={filterFactory()}
        classes={"normalSizedDatatable FriendsListDataTable"}
        rowClasses={getRowClasses}
        {...extraProps}
      />
    )
  }
}

export default withLocalize(withRouter(CampaignsList) as any) as any
