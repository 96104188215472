import './MaxSharingSlider.css'
import * as React    from 'react'
import {
  Grid,
  Icon,
  Slider,
  Input,
  Typography
}                    from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import NumFormatter  from "../../../../../utils/NumberFormatter"

interface MaxSharingSliderOwnProps {
  maxSharing                 : number
  handleSharingInputConfirm ?: (newValue:number) => void
  handleSharingConfirm      ?: (event,newValue:number) => void
  onlyInput                 ?: boolean
}
interface MaxSharingSliderProps extends MaxSharingSliderOwnProps{}

const MaxSharingSlider = (props:MaxSharingSliderProps) => {
  const [value, setValue] = React.useState<number>(props.maxSharing*100)
  const handleSliderChange = (event: any, newValue: number) => {
    setValue(newValue)
  }
  const handleInput = (event) => {
    setValue(Number(event.target.value))
  }
  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
      props.handleSharingInputConfirm(0)
    } else if (value > 100) {
      setValue(100);
      props.handleSharingInputConfirm(100)
    } else {
      props.handleSharingInputConfirm(value)
    }
  }

  React.useEffect(() => {
    if (props.maxSharing*100 !== value) {
      setValue(props.maxSharing*100)
    }
  }, [props.maxSharing])

  return (
    <Grid className="MaxSharingSlider" container spacing={2} alignItems="center">
      {!props.onlyInput &&
        <Grid item>
          <div className="Label">
            <Icon color="primary" >call_split</Icon>
            <div className="LabelText">
              <Typography variant="button"><Translate id="pacyConfigs.sharing" /></Typography>
            </div>
          </div>
        </Grid>}
        <Grid item xs>
          <Slider
            onChangeCommitted={props.handleSharingConfirm}
            onChange={handleSliderChange}
            className="Slider"
            value={typeof value === 'number' ? value : 0}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            onChange={handleInput}
            onBlur={handleBlur}
            style={{width:50}}
            value={value}
            margin="dense"
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          /> %
        </Grid>
      </Grid>
  )
}

export default MaxSharingSlider
