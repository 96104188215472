import Keystone   from "../../utils/Keystone"
import FVDUtils   from "../../utils/FieldsValuesDefinitionUtils"
import DateUtils  from "../../utils/DateUtils"
import Actions    from "../../model/constant/actions"
import Period     from "../../model/Period"
import {getToken} from "../../storeAccessor/Generic"

export default (accountId, period:Period) => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_PLACEMENTS_STATISTICS,
    status : true
  })
  const periodStart = DateUtils.format(period.dateFrom)
  const periodEnd = DateUtils.format(period.dateTo)
  Keystone.get({
      accountId,
      token  : getToken(getState()),
      action : "GetPlacementsStats",
      periodStart,
      periodEnd
    },
    (response) =>{
      const stats = FVDUtils.decompress(response.data.placementsStats)
      dispatch({
        type : Actions.RETRIEVE_PLACEMENTS_STATISTICS,
        accountId,
        stats
      })
    },
    (error) => console.log(error)
  ).then(()=>dispatch({
    type   : Actions.RETRIEVING_PLACEMENTS_STATISTICS,
    status : false
  }))
}
