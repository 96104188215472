import Actions             from "../../model/constant/actions"

export const modifySelectedAccountsList = (id:number) => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type : Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST,
      id   : id,
      currency : state.Accounts.list.find(account => account.id === id).currency
    })
  }
}
