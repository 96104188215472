import Actions      from "../../../model/constant/actions"
import ReducerUtils from "../../../utils/ReducerUtils"
import ObjectUtils  from "../../../utils/ObjectUtils"
import BingSearchTermStats from "../../../model/Store/Statistics/SearchTermStats"

const retrieveSearchTermsForAccount = (state,action) =>{
  if (action.stats){
    if (state[action.accountId]){
      var newState={}
      for (let stat of state[action.accountId] ){ newState[stat.CampaignBingId+stat.Day] = stat }
      for (let stat of action.stats            ){ newState[stat.CampaignBingId+stat.Day] = stat }
      return {...state, [action.accountId] : ObjectUtils.getObjectValues(newState)}
    }
    return {...state,[action.accountId] : action.stats}
  }
  return {...state,[action.accountId] : []}
}
interface BingSearchTermByAccount{
  [accountId:number] : BingSearchTermStats[]
}
export const SearchTermsStats = ReducerUtils.createReducer<BingSearchTermByAccount>({},{
  [Actions.RETRIEVE_BING_SEARCHTERMS_STATS_OF_ACCOUNT] : retrieveSearchTermsForAccount
})
