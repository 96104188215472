import * as React        from "react"
import {connect}         from "react-redux"
import ExplainedLoading  from "../loading"
import ErrorBoundary     from "../Error/ErrorBoundary"

const mapStateToProps = (state,ownProps)=>{
  return {
    accounts : state.Accounts.list,
    accountsFetched : state.Accounts.isFetched
  }
}
const AccountsLoader = props => {
  if(props.accounts===undefined || props.accounts.length===0){
    if(props.accountsFetched){return <div>This page requires an account but you do not have access to any.</div>}
    return <ExplainedLoading translateId="loadings.accounts"/>
  }
  return <ErrorBoundary>{props.render(props.accounts)}</ErrorBoundary>
}
const AccountsLoaderConnected = connect(mapStateToProps)(AccountsLoader)

const requiresAccounts = Component => props => (
  <AccountsLoaderConnected
    render={ accounts => <Component accounts={accounts} {...props} /> }
  />
)

export default requiresAccounts
