import * as React from "react"
import {PermissionContext}    from "../context/permissionContext"

interface UserPermissionBoundaryProps{
  userLevel    : number
  permissions ?: string[][]
  onFailure   ?: ()=>any //component
  children
}

class UserPermissionBoundary extends React.Component<UserPermissionBoundaryProps,any>{
  static defaultProps = {
    onFailure : ()=><div>Access denied</div>,
    permissions : [],
  }
  render(){
    return (
      <PermissionContext.Consumer>{context=>{
        //You need the userlevel and all the permission(s) to succeed
        if(!context.validateStaffLevel(this.props.userLevel)){return this.props.onFailure()}
        if(this.props.permissions && this.props.permissions.length){
          let oneGroupPassed = false
          let thisGroupPassed
          for(const pGroup of this.props.permissions){
            thisGroupPassed = true
            for(const permission of pGroup){
              if(!context.validatePermission(permission)){
                thisGroupPassed = false
                break
              }
            }
            if(thisGroupPassed){
              oneGroupPassed = true
              break
            }
          }
          if(!oneGroupPassed){return this.props.onFailure()}
        }
        return this.props.children ? this.props.children : null
      }}
      </PermissionContext.Consumer>
    )
  }
}

export default UserPermissionBoundary
