import ReducerUtils        from "../../utils/ReducerUtils"
import ArrayUtils        from "../../utils/ArrayUtils"
import Actions             from "../../model/constant/actions"
import {SpendAttempt}      from "../../model/pacy"
import {StringMap}         from "../../model/generics"

const save = (state:State, action):State => {
  const accountId = action.accountId
  if(!state[accountId]){
    return {
      ...state,
      [accountId] : action.spendAttempts
    }
  }
  const mergedAccount = Object.keys(action.spendAttempts).reduce((merged, agentId)=>{
    merged[agentId] = (
      merged[agentId] !== undefined
      ? ArrayUtils.mergeBySerialization(merged[agentId], action.spendAttempts[agentId], (i:SpendAttempt)=>i.id)
      : action.spendAttempts[agentId]
    )
    return merged
  },{...state[accountId]})
  return {
    ...state,
    [accountId] : mergedAccount
  }
}

const clear = (state:State, action):State => {
  if(!state[action.accountId]){return state}
  return {
    ...state,
    [action.accountId] : undefined
  }
}

interface State{
  [accountId:number] : {
    [agentId:string] : SpendAttempt[]
  }
}
const SpendAttempts = ReducerUtils.createReducer<State>({},{
  [Actions.RETRIEVE_PACY_SPEND_ATTEMPTS] : save,
  [Actions.PACY_CLEAR] : clear
})

export default SpendAttempts
