import './LocalePreferenceChooser.css'
import * as React                      from "react"
import {connect}                       from "react-redux"
import {
  withLocalize,
  Translate,
  LocalizeContextProps
}                                      from "react-localize-redux"
import setUserLocale                   from '../../../actions/user/setLocale'
import ExplainedLoading                from '../../../components/loading'
import {
  getSortedLocales,
  getReadableLocale
}                                      from '../../../utils/LocaleUtils'
import { Select }                      from '@material-ui/core'

interface LocalePreferenceChooserProps extends LocalizeContextProps {
  userLocale    : string
  settingLocale : boolean
  setUserLocale : (locale: string) => void
}

const mapStateToProps = (state,ownProps)=>{
  return {
    userId        : state.Login.userLogged.profile.id,
    userLocale    : state.Login.userLogged.profile.locale,
    settingLocale : state.Users.SettingLocale.isSaving,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    setUserLocale : (userId, locale)=>dispatch(setUserLocale(userId, locale))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    setUserLocale : (locale)=>DP.setUserLocale(SP.userId, locale)
  }
}

const LocalePreferenceChooser = (props:LocalePreferenceChooserProps) => {
  const locales = getSortedLocales()
  const [locale, setLocale] = React.useState<string>(props.userLocale)

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if(e.target.value != props.userLocale){
      setLocale(e.target.value)
      props.setUserLocale(e.target.value)
    }
  }

  return (
    <div className="LocalePreferenceChooser">
      <div className="Container">
        <h4>
          <Translate id="profile.localeTitle"/>
        </h4>
        <Select
          native
          name="locale"
          value={locale}
          onChange={handleChange}
        >
          {locales.map(localeObj =>
            <option
              key={localeObj.key}
              value={localeObj.localeCode}
            >
              {localeObj.readableLocale}
            </option>
          )}
        </Select>
      </div>
      <div className="Loader">
        {
          props.settingLocale &&
          <ExplainedLoading small />
        }
      </div>
    </div>
  )
}

export default (
  withLocalize(
    connect(mapStateToProps,mapDispatchToProps,mergeProps)(
      LocalePreferenceChooser
    )
  )
)
