import {Selector}                     from "./Selector"
import {SelectorStandardName}         from "./Selector"
import {SelectorOperator}             from "./Selector"
import {
  SelectorType,
  MakeIs,
  MakeIsNot,
  YearIs,
  YearIsNot,
  YearAtLeast,
  YearAtMost,
  DaysInInventoryAtLeast,
  DaysInInventoryAtMost,
  NumberOfPhotosAtLeast,
  NumberOfPhotosAtMost,
  PriceAtLeast,
  PriceAtMost,
  ModelIs,
  ModelIsNot,
  Selector as HeadstoneSelector,
  StockNumberIs,
  StockNumberIsNot,
}                                    from "./HeadstoneEngagementRule"


interface KeystoneSelectorInfos{
  type : string
  params : string
}
const keystoneSelectors = {
  DAYS   : "DaysInInventory",
  PHOTOS : "NumberOfPhotos",
  PRICE  : "Price",
  MAKE   : "Make",
  YEAR   : "Year",
}
const keystoneOperators = {
  AT_LEAST : "AtLeast",
  AT_MOST  : "AtMost",
  IS_NOT   : "IsNot",
  IS       : "Is",
}

type LookupType  = {
  [name in SelectorStandardName] ?: {
    [operator in SelectorOperator] ?: (selector:Selector) => HeadstoneSelector
  }
}

const LOOKUP : LookupType = {
  [SelectorStandardName.NumberOfDays] : {
    [SelectorOperator.AT_LEAST] : (selector : Selector) : DaysInInventoryAtLeast => ({
      type : SelectorType.DaysInInventoryAtLeast,
      days : parseInt(selector.value+"",10),
    }),
    [SelectorOperator.AT_MOST]  : (selector : Selector) : DaysInInventoryAtMost => ({
      type : SelectorType.DaysInInventoryAtMost,
      days : parseInt(selector.value+"",10),
    }),
  },
  [SelectorStandardName.NumberOfPhotos] : {
    [SelectorOperator.AT_LEAST] : (selector : Selector) : NumberOfPhotosAtLeast => ({
      type : SelectorType.NumberOfPhotosAtLeast,
      number : parseInt(selector.value+"",10),
    }),
    [SelectorOperator.AT_MOST]  : (selector : Selector) : NumberOfPhotosAtMost => ({
      type : SelectorType.NumberOfPhotosAtMost,
      number : parseInt(selector.value+"",10),
    }),
  },
  [SelectorStandardName.Price] : {
    [SelectorOperator.AT_LEAST] : (selector : Selector) : PriceAtLeast => ({
      type : SelectorType.PriceAtLeast,
      price : parseInt(selector.value+"",10),
    }),
    [SelectorOperator.AT_MOST]  : (selector : Selector) : PriceAtMost => ({
      type : SelectorType.PriceAtMost,
      price : parseInt(selector.value+"",10),
    }),
  },
  [SelectorStandardName.Make] : {
    [SelectorOperator.IS]           : (selector : Selector) : MakeIs    => ({
      type:SelectorType.MakeIs,
      make:selector.value.toString(),
    }),
    [SelectorOperator.IS_NOT]           : (selector : Selector) : MakeIsNot    => ({
      type:SelectorType.MakeIsNot,
      make:selector.value.toString(),
    }),
  },
  [SelectorStandardName.Model] : {
    [SelectorOperator.IS]           : (selector : Selector) : ModelIs    => ({
      type:SelectorType.ModelIs,
      isRegex:false,
      model:selector.value.toString(),
    }),
    [SelectorOperator.IS_REGEX]     : (selector : Selector) : ModelIs    => ({
      type:SelectorType.ModelIs,
      isRegex:true,
       model:selector.value.toString(),
     }),
    [SelectorOperator.IS_NOT]       : (selector : Selector) : ModelIsNot => ({
      type:SelectorType.ModelIsNot,
      isRegex:false,
      model:selector.value.toString(),
    }),
    [SelectorOperator.IS_NOT_REGEX] : (selector : Selector) : ModelIsNot => ({
      type:SelectorType.ModelIsNot,
      isRegex:true,
       model:selector.value.toString(),
     }),
  },
  [SelectorStandardName.Year] : {
    [SelectorOperator.AT_LEAST] : (selector : Selector) : YearAtLeast => ({
      type : SelectorType.YearAtLeast,
      year : parseInt(selector.value+"",10),
    }),
    [SelectorOperator.AT_MOST]  : (selector : Selector) : YearAtMost => ({
      type : SelectorType.YearAtMost,
      year : parseInt(selector.value+"",10),
    }),
    [SelectorOperator.IS]       : (selector : Selector) : YearIs => ({
      type : SelectorType.YearIs,
      year : parseInt(selector.value+"",10),
    }),
    [SelectorOperator.IS_NOT]   : (selector : Selector) : YearIsNot => ({
      type : SelectorType.YearIsNot,
      year : parseInt(selector.value+"",10),
    }),
  },
  [SelectorStandardName.StockNumber] : {
    [SelectorOperator.IS] : (selector : Selector) : StockNumberIs => ({
      type: SelectorType.StockNumberIs,
      isRegex: false,
      stockNumber: selector.value.toString()
    }),
    [SelectorOperator.IS_REGEX] : (selector : Selector) : StockNumberIs => ({
      type: SelectorType.StockNumberIs,
      isRegex: true,
      stockNumber: selector.value.toString()
    }),
    [SelectorOperator.IS_NOT] : (selector : Selector) : StockNumberIsNot => ({
      type: SelectorType.StockNumberIsNot,
      isRegex: false,
      stockNumber: selector.value.toString()
    }),
    [SelectorOperator.IS_NOT_REGEX] : (selector : Selector) : StockNumberIsNot => ({
      type: SelectorType.StockNumberIsNot,
      isRegex: true,
      stockNumber: selector.value.toString()
    })
  }
}

const formatForHeadstone = (selector:Selector) : HeadstoneSelector => {
  if(LOOKUP[selector.name] && LOOKUP[selector.name][selector.operator]){
    return LOOKUP[selector.name][selector.operator](selector)
  }
  alert("Error number : cdebb9ee-007a-4593-b6a4-05a79ca009f0")
  throw new Error("CANNOT MAP TO HEADSTONE FORMAT FROM IDB SELECTOR")
}
export default formatForHeadstone
