import './EditableMuiText.css'
import * as React                            from 'react'
import {
  Typography,
  Input,
  Icon,
  Button,
  TextField,
}                                            from '@material-ui/core'
import {TypographyProps}                     from '@material-ui/core/Typography'

interface EditableMuiTextOwnProps {
  baliseType        : TypographyProps["variant"]
  content           : string
  handleInputChange : (event) => void
  type              : 'input' | 'textArea'
  maxLength        ?: number
  contentStyle     ?: React.CSSProperties
  inputStyle       ?: React.CSSProperties
  iconStyle        ?: React.CSSProperties
  textWidth        ?: number
}

interface EditableMuiTextProps extends EditableMuiTextOwnProps {}

const EditableMuiText = (props:EditableMuiTextProps) => {
  const textWidth = props.textWidth ? props.textWidth : 400
  const [text,setText] = React.useState(props.content)
  const handleTextChange = (event) => {
    if(props.maxLength) {
      if(event.target.value.length <= props.maxLength) {setText(event.target.value)}
    } else {
      setText(event.target.value)
    }
  }
  const [edit,setEdit] = React.useState(false)
  const closeEditMode = (event) => {
    event.stopPropagation()
    setEdit(false)
  }
  const openEditMode = (event) => {
    event.stopPropagation()
    setEdit(true)
  }
  const handleCloseEditMode = (event)=> {
    closeEditMode(event)
    event.stopPropagation()
  }
  const handleKeyDown = (event) => {
    if(event.keyCode===13) {
      event.target.blur()
      handleCloseEditMode(event)
    }
  }
  return (
    <div className="EditableMuiText">
      {
      edit === false
        ? <>
            <Typography
              className="TextWrap"
              title={props.content}
              style={{...props.contentStyle,maxWidth:textWidth ,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}
              variant={props.baliseType}
            >
              {props.content}
            </Typography>
            <Icon
              onClick={openEditMode}
              className="Icon"
              style={props.iconStyle}
            >
              edit
            </Icon>
          </>
        : props.type === 'input'
            ? <>
                <Input
                  onKeyDown={handleKeyDown}
                  onChange={handleTextChange}
                  autoFocus
                  style={props.inputStyle}
                  value={text}
                  onBlur={(event)=>{props.handleInputChange(event);closeEditMode(event)}}
                  onClick={(event)=>{event.stopPropagation()}}
                />
                <Button onClick={handleCloseEditMode} color="primary"><Icon className="DoneIcon" style={props.iconStyle}>done</Icon></Button>
              </>
            : <>
                <TextField
                  onKeyDown={handleKeyDown}
                  onChange={handleTextChange}
                  autoFocus
                  style={props.inputStyle}
                  value={text}
                  onBlur={(event)=>{props.handleInputChange(event);closeEditMode(event)}}
                  onClick={(event)=>{event.stopPropagation()}}
                  multiline
                  rowsMax={3}
                  fullWidth
                />
                <Button onClick={handleCloseEditMode} color="primary"><Icon className="DoneIcon" style={props.iconStyle}>done</Icon></Button>
              </>
      }
    </div>
  )
}

export default EditableMuiText
