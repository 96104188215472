type KeyValueMap<T> = Record<string, T[keyof T]>;

export default class CaseUtils{
  public static convertKeysToCamelCase<T extends KeyValueMap<T>>(obj: T): KeyValueMap<T> {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = key.replace(/_([a-z])/g, (_, match) => match.toUpperCase())
      acc[camelCaseKey] = obj[key]
      return acc
    }, {} as KeyValueMap<T>)
  }

  public static convertKeysToSnakeCase<T extends KeyValueMap<T>>(obj: T): KeyValueMap<T> {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeCaseKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
      acc[snakeCaseKey] = obj[key]
      return acc
    }, {} as KeyValueMap<T>)
  }
}
