import './ReportTable.css'
import * as React                             from "react"
import ReportTableTemplate                    from "../../../model/Store/Report/ReportTableTemplate"
import CSVUtils                               from "../../../utils/CSVUtils"
import {Button}                               from "carbon-components-react"
import BootstrapTable                         from 'react-bootstrap-table-next'
import filterFactory                          from 'react-bootstrap-table2-filter'
import paginationFactory                      from 'react-bootstrap-table2-paginator'
import { Typography }                         from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                                             from "react-localize-redux"

interface ReportTableProps extends LocalizeContextProps{
  template         : ReportTableTemplate
  data             : any
  tellMeYoureAlive : ()=>void
  tellMeYoureDead  : ()=>void
  accountId        : number
}
interface ReportTableState{
}


class ReportTable extends React.Component<ReportTableProps,ReportTableState>{

  private filterFunctions = {}
  private table

  constructor(props){
    super(props)
    this.generateCSV = this.generateCSV.bind(this)
  }

  generateCSV(){
    const data = this.table.store._filteredData.length>0?this.table.store._filteredData:this.table.store._data
    const columns = this.props.template.getOrderedColumns().filter(c=>!c.getHidden())
    let dataFields = []
    let header = []
    for(let column of columns){
      dataFields.push(column.getDataField())
      header.push(column.getText())
    }
    CSVUtils.downloadCSV(
      this.props.template.getContext().toLowerCase()+"_report.csv",
      CSVUtils.generateCSV(
        header,
        data.map(row=>dataFields.map(key=>row[key])),
        ","
      )
    )
  }
  componentDidMount(){
    this.props.tellMeYoureAlive()
    for(const col of this.props.template.getColumns()){
      setTimeout(col.doFilterWithDefaultValue.bind(col), 250)
    }
  }
  componentWillUnmount(){
    this.props.tellMeYoureDead()
  }
  render(){
    const template = this.props.template
    const paginationOptions = {
      hideSizePerPage: true,
      sizePerPage : template.getSizePerPage(),
      hidePageListOnlyOnePage: true,
    }
    return(
      <div>
        {
          this.props.data.length === 0 ?
            <Typography variant="h5" style={{marginTop: 50}}>
              {this.props.translate('reports.ads.noStats')}
            </Typography>
            :
            <div className="ReportTable">
              <Button className="CSVButton" onClick={this.generateCSV}>CSV</Button>
              <BootstrapTable
                ref={table=>this.table = table}
                classes="ReportDataTable"
                data={this.props.data}
                columns={this.props.template.getComputedColumns()}
                keyField={template.getKeyField()}
                filter={filterFactory()}
                pagination={paginationFactory(paginationOptions)}
                {...template.getOptions()}
              />
            </div>
        }
      </div>
    )
  }
}


export default withLocalize(ReportTable)
