import Tracking                     from "../../utils/TrackingAPI"
import LogBuilder                   from "../../controller/log/LogBuilder"
import Actions                      from "../../model/constant/actions"
import UserActions                  from "../../model/constant/UserAction"
import retrieveTrackingInfo         from "./retrieveTrackingInformation"
import {getToken}                   from "../../storeAccessor/Generic"

export const assignGTMInformation = (accountId:number, GTMContainerName:string) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.SAVE_TRACKING_INFO,token,{accountId, GTMContainerName})
  Tracking.get({
      token            : token,
      action           : "AssignGTMInformation",
      accountId        : accountId,
      GTMContainerName : GTMContainerName
    },
    (data) => dispatch(retrieveTrackingInfo(accountId)),
    (data) => console.log(data)
  )
}

export const assignCallRailId = (accountId:number, callRaidId:number) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.SAVE_TRACKING_INFO,token,{accountId, callRaidId})
  Tracking.get({
      token      : token,
      action     : "AssignCallRailId",
      accountId  : accountId,
      callRailId : callRaidId
    },
    (data) => dispatch(retrieveTrackingInfo(accountId)),
    (data) => console.log(data)
  )
}

export const assignGoogleAnalyticsAccountId = (accountId:number, GAId:string) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.SAVE_TRACKING_INFO,token,{accountId, GAId})
  Tracking.get({
      token                    : token,
      action                   : "AssignGoogleAnalyticsAccountId",
      accountId                : accountId,
      googleAnalyticsAccountId : GAId
    },
    (data) => dispatch(retrieveTrackingInfo(accountId)),
    (data) => console.log(data)
  )
}
