import './CustomPeriod.css'
import * as React                       from "react"
import {withLocalize}                   from "react-localize-redux"
import {OverflowMenu, OverflowMenuItem} from "carbon-components-react"
import DateUtils                        from "../../../utils/DateUtils"


interface CustomPeriodProps{
  updateDate : (dateFrom,dateTo)=>void
}
const CustomPeriod = (props) => {
  const overflowMenuItems = [
    {label:props.translate("menu.periods.today"),        period:{dateFrom:DateUtils.getToday(),dateTo:DateUtils.getToday()}},
    {label:props.translate("menu.periods.yesterday"),    period:{dateFrom:DateUtils.getYesterday(),dateTo:DateUtils.getYesterday()}},
    {label:props.translate("menu.periods.lastWeek"),     period:DateUtils.getLastWeek()},
    {label:props.translate("menu.periods.last7Day"),     period:DateUtils.getLast7Day()},
    {label:props.translate("menu.periods.currentWeek"),  period:DateUtils.getCurrentWeek()},
    {label:props.translate("menu.periods.last14Day"),    period:DateUtils.getLast14Day()},
    {label:props.translate("menu.periods.currentMonth"), period:DateUtils.getCurrentMonth()},
    {label:props.translate("menu.periods.last30Day"),    period:DateUtils.getLast30Day()},
    {label:props.translate("menu.periods.lastMonth"),    period:DateUtils.getLastMonth()},
  ]
  return (
    <div className={"menu-period-custom"}>
      <OverflowMenu className="menu-period-custom-overflow" iconName={"hpa"} flipped>
        {overflowMenuItems.map( (item,i) => {
          return(
            <OverflowMenuItem
              key={i}
              itemText={item.label}
              onClick={() => props.updateDate(item.period.dateFrom,item.period.dateTo)}
            />
          )
        })}
      </OverflowMenu>
    </div>
  )
}

export default withLocalize(CustomPeriod)
