import * as React            from 'react'
import styles                from './AdCopyEditingSelectorStyles'
import {
  withLocalize,
  LocalizeContextProps
}                            from 'react-localize-redux'
import MenuItem              from '@material-ui/core/MenuItem'
import Select                from '@material-ui/core/Select'
import FormControl           from '@material-ui/core/FormControl'
import Button                from '@material-ui/core/Button'
import InputLabel            from '@material-ui/core/InputLabel'
import {
  AdCopyParents,
  INVENTORY_GROUPS
}                            from '../../../model/adcopy'
import AWSearch              from '../../../model/Store/Audiences/AWSearch'
import {provideAudienceName} from '../../adBuilder/utils/adBuilderUtils'

interface AdCopyEditingSelectorProps extends LocalizeContextProps{
  title         : string
  adCopyParents : AdCopyParents
  edit          : (title:string,strategy:string,adgroup:string,lang:string) => void
  AWAudiences  ?: AWSearch[]
}

const AdCopyEditingSelector = (props:AdCopyEditingSelectorProps) => {
  const [strategy,setStrategy] = React.useState<string>("")
  const [adgroup,setAdgroup]   = React.useState<string>("")
  const [lang,setLang]         = React.useState<string>("")

  const provideCampaignSelectLabel = () => {
    if (INVENTORY_GROUPS.includes(props.title)) return props.translate("adCopies.strategy")
    if (props.title==="Branding") return props.translate("adCopies.adGroup")
    return props.translate("adCopies.campaignName")
  }

  const classes = styles()
  return (
    <div className={classes.container}>
      <div className={classes.title}>{props.translate(`adCopies.${props.title}`)}</div>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.label}>
            {provideCampaignSelectLabel()}
          </InputLabel>
          <Select
            className={classes.selects}
            value={strategy}
            onChange={(e)=>setStrategy(e.target.value as string)}
          >
            {Object.keys(props.adCopyParents).map(campaign=>{
              return <MenuItem key={campaign} value={campaign}>{campaign}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.label}>
            {props.title==="Branding" 
              ? props.translate("adCopies.audience")
              : props.translate("adCopies.adGroup")
            }
          </InputLabel>
          <Select
            className={classes.selects}
            value={adgroup}
            onChange={(e)=>setAdgroup(e.target.value as string)}
          >
            {Object.keys(props.adCopyParents?.[strategy] ?? []).map(adgroup=>{
              return <MenuItem key={adgroup} value={adgroup}>
                {provideAudienceName(adgroup, props.AWAudiences)}
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.label}>{props.translate("adCopies.language")}</InputLabel>
          <Select
            className={classes.selects}
            value={lang}
            onChange={(e)=>setLang(e.target.value as string)}
          >
            {(props.adCopyParents?.[strategy]?.[adgroup] ?? []).map(x=>{
              return <MenuItem key={x} value={x}>{x}</MenuItem>
            })}
          </Select>
        </FormControl>
        <Button 
          className={classes.button}
          color="primary"
          onClick={()=>props.edit(
            props.title,
            strategy,
            adgroup,
            lang
          )}
        >
          {props.translate("common.edit")}
        </Button>
    </div>
  )
}

export default withLocalize(AdCopyEditingSelector)
