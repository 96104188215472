import NumFormatter      from "../../utils/NumberFormatter"


const removeUndefinedName = (value:string)=> value==='undefined'||!value ? "Unknown" : value
const removeUndefinedStat = (value:string)=> value==='undefined'||!value ? "-"       : value

export const FORMATTER_NAMES = {
  ROUND2     : "ROUND2",
  MONEY      : "MONEY",
  PERCENTAGE : "PERCENTAGE",
  CPC        : "CPC",
  IMPSHARE   : "IMPSHARE",
  CLICKSHARE : "CLICKSHARE",
  NAME       : "NAME",
  UPPERCAMEL : "UPPERCAMEL",
}

export const FORMATTERS = {
  [FORMATTER_NAMES.NAME] : (x,y)=>removeUndefinedName(x),
  [FORMATTER_NAMES.UPPERCAMEL] : (x,y)=>{
    if(!x){return removeUndefinedName(x)}
    return x[0].toUpperCase()+x.substring(1).toLowerCase()
  },
  [FORMATTER_NAMES.ROUND2] : (x,y)=>{
    if(isNaN(x)){return removeUndefinedStat(x)}
    return x ? NumFormatter.formatNumber(x, 2) : removeUndefinedStat(x)
  },
  [FORMATTER_NAMES.MONEY] : (x,y)=>{
    if(isNaN(x)){return removeUndefinedStat(x)}
    return x ? NumFormatter.formatCurrency(x) : removeUndefinedStat(x)
  },
  [FORMATTER_NAMES.PERCENTAGE] : (x,y)=>{
    if(isNaN(x)){return removeUndefinedStat(x)}
    return x ? NumFormatter.formatNumber(x, 2) + "%" : removeUndefinedStat(x)
  },
  [FORMATTER_NAMES.CPC] : (x,y)=>{
    if(!x){return removeUndefinedStat("-")}
    return removeUndefinedStat(FORMATTERS[FORMATTER_NAMES.MONEY](x,y))
  },
  [FORMATTER_NAMES.IMPSHARE] : (x,y)=>{
    if(!x){return removeUndefinedStat("-")}
    return removeUndefinedStat(FORMATTERS[FORMATTER_NAMES.PERCENTAGE](x,y))
  },
  [FORMATTER_NAMES.CLICKSHARE] : (x,y)=>{
    if(!x){return removeUndefinedStat("-")}
    return removeUndefinedStat(FORMATTERS[FORMATTER_NAMES.PERCENTAGE](x,y))
  },
}
