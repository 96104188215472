import './EditableKeyValueTable.css'
import * as React           from 'react'
import DormantInput         from "../input/DormantTextInput"

interface EditableKeyValueTableProps{
  title : string
  keyValue : {
    [key:string] : Value
  }
}
interface Value{
  value        : any
  placeholder ?: string
  onChange    ?: (text:string)=>void
  onBlur      ?: (text:string)=>void
  edited      ?: boolean
}

const EditableKeyValueTable = (props:EditableKeyValueTableProps) => {
  const keys = Object.keys(props.keyValue)
  return (
    <div className='KeyValueTableWithTitle'>
      <h4>{props.title}</h4>
      <hr/>
      <div className='KeyValueTable'>
        {keys.map(key=>(
          <React.Fragment key={key}>
            <div className='key'>{key}</div>
            <div className={'value'+(props.keyValue[key].edited?" edited":"")}>
              <DormantInput
                initialText={props.keyValue[key].value}
                placeholder={props.keyValue[key].placeholder}
                onChange={props.keyValue[key].onChange}
                onBlur={props.keyValue[key].onBlur}
                edited={props.keyValue[key].edited}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default EditableKeyValueTable
