import './index.css'
import * as React                   from "react"
import {withLocalize}               from "react-localize-redux"
import Permissions                  from "../../model/constant/Permissions"
import {Panel}                      from "../../components/Panel/Panel"
import Button                       from "../../components/Button/MinimalistButton"
import AccountPermissionBoundary    from "../../components/permissions/AccountPermissionBoundary"

const constructURL = (currentLocation:string, toAdd:string):string => {
  return (currentLocation+toAdd).replace("//","/")
}
const ReportNavigation = (props) => {
  return (
    <Panel title={props.translate("reports.navigation.title")} subtitle={props.translate("reports.navigation.subtitle")}>
      <div className='MinimalistButtonsList'>
        <Button
          text={props.translate("reports.navigation.ads")}
          subtitle={props.translate("reports.navigation.adsSubtitle")}
          onClick={()=>props.history.push(constructURL(props.history.location.pathname, "/Ads"))}
        />
        <Button
          text={props.translate("reports.navigation.bing")}
          subtitle={props.translate("reports.navigation.bingSubtitle")}
          onClick={()=>props.history.push(constructURL(props.history.location.pathname, "/MicrosoftAdvertising"))}
        />
        <Button
          text={props.translate("reports.navigation.waze")}
          subtitle={props.translate("reports.navigation.wazeSubtitle")}
          onClick={()=>props.history.push(constructURL(props.history.location.pathname, "/Waze"))}
        />
        <AccountPermissionBoundary
          permissions={[Permissions.Pacy_View]}
          onFailure={null}
        >
          <Button
            text={props.translate("reports.navigation.pacy")}
            subtitle={props.translate("reports.navigation.pacySubtitle")}
            onClick={()=>props.history.push(constructURL(props.history.location.pathname, "/Budgets"))}
          />
        </AccountPermissionBoundary>
     </div>
   </Panel>
  )
}

export default withLocalize(ReportNavigation)
