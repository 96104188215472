import {combineReducers}               from "redux"
import {InventoryList}                 from "./InventoryList"
import {InventoryFetching}             from "./InventoryFetching"
import {AllLangInventoryList}          from './AllLangInventoryList'
import {AllLangInventoryFetching}      from "./AllLangInventoryFetching"

export const Inventory = combineReducers({
  list              : InventoryList,
  allLangList       : AllLangInventoryList,
  isFetching        : InventoryFetching,
  allLangIsFetching : AllLangInventoryFetching,
})
