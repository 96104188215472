import Actions               from "../../model/constant/actions"
import ReducerUtils          from "../../utils/ReducerUtils"

const setPreferences = (state, action) => {
  if(Array.isArray(action.preferences)){
    return {
      ...state,
      [action.accountId] : {}
    }
  }
  return {
    ...state,
    [action.accountId] : action.preferences
  }
}
interface preferencesByAccountId{
  [accountId:number] : {
    [preferenceName:string] : string
  }
}
const AccountsPreferences = ReducerUtils.createReducer<preferencesByAccountId>({},{
  [Actions.RETRIEVE_ACCOUNT_PREFERENCES] : setPreferences,
})

export default AccountsPreferences
