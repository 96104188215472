import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import {getToken}                                        from "../../storeAccessor/Generic"
import Headstone                                         from "../../utils/Headstone"
import { AdTemplate }                                    from "../../model/adbuilder"
import { AdGroupConfigsByName }                          from "../../model/branding/AdGroupConfigs"
import { AudienceAd }                                    from "../../model/branding/AudienceAd"
import LogBuilder                                        from "../../controller/log/LogBuilder"
import UserActions                                       from "../../model/constant/UserAction"

const extractMessageFromError = (error:any) => {
  if(error.response && error.response.data){
    return error.response.data.error
  }
}

export const retrieveAdGroupConfigs = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_AD_GROUP_CONFIG, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Headstone.getAdGroupConfig(token, accountId)
  )
  .then((response)=>{
    dispatch({
      type          : Actions.RETRIEVE_AD_GROUP_CONFIG,
      adGroupConfig : response.data,
      accountId
    })
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_RETRIEVING_AD_GROUP_CONFIG,
      status : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type: Actions.RETRIEVING_AD_GROUP_CONFIG, status: false})
  })
}
export const savePositiveKeywords = (accountId:number, lang:string, keywords:string[],adGroup:string) => (dispatch, getState) => {
  dispatch({type: Actions.SAVING_POSITIVE_KEYWORDS, status: true})
  const token = getToken(getState())
  LogBuilder.log(UserActions.BRANDING_SAVE_POSITIVE_KEYWORDS,token,{accountId,adGroup,keywords,lang})
  ActionUtils.retryOnFailure(
    ()=>Headstone.savePositiveKeywords(token, accountId, lang, keywords,adGroup)
  )
  .then((response)=>{
    dispatch(retrieveAdGroupConfigs(accountId))
    dispatch({type : Actions.FAILED_SAVING_POSITIVE_KEYWORDS,status:false,message:''})
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_SAVING_POSITIVE_KEYWORDS,
      status : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type: Actions.SAVING_POSITIVE_KEYWORDS, status: false})
  })
}
export const saveAdCopyPatterns = (accountId:number,audienceId:number,adCopyPatterns:AdTemplate[],lang:string) => (dispatch,getState) => {
  dispatch({type: Actions.SAVING_AD_COPY_PATTERNS, status:true})
  const token = getToken(getState())
  LogBuilder.log(UserActions.BRANDING_SAVE_AD_COPIES,token,{accountId,audienceId,lang,adCopyPatterns})
  ActionUtils.retryOnFailure(
    ()=>Headstone.saveAdCopyPatterns(token,accountId,audienceId,adCopyPatterns,lang)
  )
  .then((response)=>{
    dispatch(retrieveAdGroupConfigs(accountId))
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_SAVING_AD_COPY_PATTERNS,
      status : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type: Actions.SAVING_AD_COPY_PATTERNS, status: false})
  })
}
export const createNewAdGroup = (accountId:number,adGroupConfig:AdGroupConfigsByName[],lang:string) => (dispatch,getState) => {
  dispatch({type: Actions.CREATING_NEW_ADGROUP,status:true})
  const token = getToken(getState())
  LogBuilder.log(UserActions.BRANDING_CREATE_ADGROUP,token,{accountId,lang,adGroupConfig})
  ActionUtils.retryOnFailure(
    ()=>Headstone.createAdGroup(token,accountId,adGroupConfig,lang)
  )
  .then((response) => {
    dispatch(retrieveAdGroupConfigs(accountId))
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_CREATING_ADGROUP,
      staus : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type:Actions.CREATING_NEW_ADGROUP,status:false})
  })
}
export const deleteAdGroup = (accountId:number,adGroupName:string,lang:string) => (dispatch,getState) => {
  dispatch({type: Actions.DELETING_ADGROUP,status:true})
  const token = getToken(getState())
  LogBuilder.log(UserActions.BRANDING_DELETE_ADGROUP,token,{accountId,lang,adGroupName})
  ActionUtils.retryOnFailure(
    ()=>Headstone.deleteAdGroup(token,accountId,adGroupName,lang)
  )
  .then((response) => {
    dispatch(retrieveAdGroupConfigs(accountId))
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_DELETING_ADGROUP,
      staus : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type:Actions.DELETING_ADGROUP,status:false})
  })
}
export const editAudienceAds = (accountId:number,audienceAds:AudienceAd[]) => (dispatch,getState) => {
  dispatch({type: Actions.EDITING_AUDIENCEADS,status:true})
  const token = getToken(getState())
  LogBuilder.log(UserActions.BRANDING_UPDATE_AUDIENCE_ADS,token,{accountId,audienceAds})
  ActionUtils.retryOnFailure(
    ()=>Headstone.editAudienceAds(token,accountId,audienceAds)
  )
  .then((response) => {
    dispatch(retrieveAdGroupConfigs(accountId))
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_TO_EDIT_AUDIENCEADS,
      staus : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type:Actions.EDITING_AUDIENCEADS,status:false})
  })
}

// The difference with `editAudienceAds` is that this action is going to send one request/audiencead to allow editing
// audience ads of different adgroups at the same time
export const createMultipleAudienceAds = (accountId:number,audienceAds:AudienceAd[]) => (dispatch,getState) => {
  dispatch({type: Actions.EDITING_AUDIENCEADS,status:true})
  const token = getToken(getState())
  LogBuilder.log(UserActions.BRANDING_UPDATE_AUDIENCE_ADS,token,{accountId,audienceAds})
  ActionUtils.retryOnFailure(
    ()=>Headstone.createAudienceAds(token,accountId,audienceAds)
  )
  .then((response) => {
    dispatch(retrieveAdGroupConfigs(accountId))
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_TO_EDIT_AUDIENCEADS,
      staus : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type:Actions.EDITING_AUDIENCEADS,status:false})
  })
}

