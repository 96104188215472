import './ChoosingOptions.css'
import * as React               from 'react'
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Card,
  Icon
}                               from '@material-ui/core'
import AdsEdit                  from '../Ads/AdsEdit'
import { AudienceAd }           from '../../../model/branding/AudienceAd'
import { AdGroupConfigsByLang } from '../../../model/branding/AdGroupConfigs'
import { Translate }            from 'react-localize-redux'

interface ChoosingOptionsOwnProps {
  choosing            ?: boolean
  availableLanguages   : string []
  adGroupConfigsByLang : AdGroupConfigsByLang
  setErrors           ?: any
  saveFunction        ?: ()=>void
  updateAudienceAd    ?: (lang:string,template:any) => void
  errors               : string[]
  initialError         : boolean
  audienceToEdit       : any
}
interface ChoosingOptionsProps extends ChoosingOptionsOwnProps{}

interface TabPanelProps {
  children ?: React.ReactNode
  index     : any
  value     : any
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}
const ChoosingOptions = (props:ChoosingOptionsProps) => {
  const [choosing,setChoosing] = React.useState(props.choosing)
  const [initialImport,setInitialImport] = React.useState(false)
  const [tabValue, setTabValue] = React.useState(0)
  function handleTabChange(event, newTabValue) {
    setTabValue(newTabValue)
  }
  const choosingInterface = (
    <Card className="ChoosingCard">
      <div>
        <Translate id="branding.creation.chooseOption" />
      </div>
      <div>
        <Card className="Option" onClick={()=>{setChoosing(false);setInitialImport(false)}}>
          <Icon>create</Icon>
          <Translate id="branding.creation.startFromScratch" />
        </Card>
        <Card className="Option" onClick={()=>{setChoosing(false);setInitialImport(true)}}>
          <Icon>arrow_downward</Icon>
          <Translate id="branding.creation.fromExistingTemplates" />
        </Card>
      </div>
    </Card>
  )
  return (
    <div>
      {choosing && choosingInterface}
      {!choosing && initialImport && <div className="AudienceAdsEdit">
          <Tabs indicatorColor='primary' value={tabValue} onChange={handleTabChange}>
            {props.availableLanguages.map((lang, i) => {
              return <Tab key={i} label={<Typography>{lang}</Typography>} />
            })}
          </Tabs>
          {props.availableLanguages.map((lang, i) => {
            return (
              <TabPanel value={tabValue} index={i} key={i}>
                <AdsEdit
                  adGroupConfigsByName={props.adGroupConfigsByLang[lang] as any}
                  setErrors={props.setErrors}
                  initialErrors={props.initialError}
                  updateAudienceAd={props.updateAudienceAd}
                  enableSave={props.errors.length === 0}
                  save
                  lang={lang}
                  audienceAd={props.audienceToEdit[lang] as AudienceAd}
                  saveFunction={props.saveFunction}
                  initialImport
                />
              </TabPanel>
            )
          })}
        </div>}
        {!choosing && !initialImport && <div className="AudienceAdsEdit">
          <Tabs indicatorColor='primary' value={tabValue} onChange={handleTabChange}>
            {props.availableLanguages.map((lang, i) => {
              return <Tab key={i} label={<Typography>{lang}</Typography>} />
            })}
          </Tabs>
          {props.availableLanguages.map((lang, i) => {
            return (
              <TabPanel value={tabValue} index={i} key={i}>
                <AdsEdit
                  adGroupConfigsByName={props.adGroupConfigsByLang[lang] as any}
                  setErrors={props.setErrors}
                  initialErrors={props.initialError}
                  updateAudienceAd={props.updateAudienceAd}
                  enableSave={props.errors.length === 0}
                  save
                  lang={lang}
                  audienceAd={props.audienceToEdit[lang] as AudienceAd}
                  saveFunction={props.saveFunction}
                />
              </TabPanel>
            )
          })}
        </div>}
    </div>
  )
}

export default ChoosingOptions
