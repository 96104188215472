import Actions                 from "../../model/constant/actions"
import { FriendAndVehicles }   from "../../components/worker/Inventory"

export const addVehiclesToFriends = (friendsAndVehicles:FriendAndVehicles[], allLangFriendsAndVehicles: FriendAndVehicles[]) => {
  return {
    type                      : Actions.ADD_INVENTORY_TO_FRIENDS,
    friendsAndVehicles        : friendsAndVehicles,
    allLangFriendsAndVehicles : allLangFriendsAndVehicles
  }
}

export const clearVehiclesFromFriends = (accountId: number) => {
  return {
    type      : Actions.CLEAR_INVENTORY_FROM_FRIENDS,
    accountId : accountId
  }
}
