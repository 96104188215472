import * as React                     from "react"
import {HashRouter, Route}            from "react-router-dom"
import {Switch}                       from "react-router-dom"
import {Redirect}                     from "react-router-dom"
import RoutingUtils                   from "../utils/RoutingUtils"
import PERMISSIONS                    from "../model/constant/Permissions"
import GrammarBooster                 from '../page/grammarBooster'
import ShapeEditor                    from "../page/shapesValidation/component/shapeEditor/"
import System                         from '../page/system'
import UserPermissionBoundary         from "../components/permissions/UserPermissionBoundary"

const routing = (props) => {
  return (
    <UserPermissionBoundary
      userLevel={3}
      permissions={[[PERMISSIONS.Keystone_GrammarBooster_Read], ["CleanerPermission"], [PERMISSIONS.IDB2_Modify_Shape]]}
      onFailure={()=><span>You do not have access to this section</span>}
    >
      <HashRouter>
        <Switch>
          <Route
            exact
            key={"*/System"}
            path={"*/System"}
            render={(props) => <System history={props.history}/>}
          />
          <Route
            exact
            key={"*/System/GrammarBooster"}
            path={"*/System/GrammarBooster"}
            component={GrammarBooster}
          />
          <Route
            exact
            key={"*/System/ShapeEditor"}
            path={"*/System/ShapeEditor"}
            render={(props)=> <ShapeEditor exit={()=>RoutingUtils.removeLastElementOfUrl(props.history)}/>}
          />
          <Redirect from="/" to={"/System"}/>
        </Switch>
      </HashRouter>
    </UserPermissionBoundary>
  )
}
export default routing
