import './ProxyMove.css'
import * as React                     from 'react'
import {
  Card,
  CardHeader,
  Icon,
  Divider,
  Typography,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  CardActions,
  Button,
  Switch,

}                                     from '@material-ui/core'
import { ProxyGroup }                 from '../../../../model/pacy/ProxyGroup'
import ProxiesAmountsDisplay          from './Components/ProxiesAmountsDisplay'
import FilterInputWithButtons         from '../../../../components/FilterInputWithButtons/FilterInputWithButtons'
import { Translate }                  from 'react-localize-redux'

interface ProxyMoveOwnProps {
  proxyGroups                : ProxyGroup[]
  setNewProxyGroupId         : (event,proxyGroupId:string) => void
  newProxyGroupId            : string
  handleSelectChange         : (event) => void
  proxyGroupId               : string
  handleMoveInvestment       : (moveInvestment: boolean) => void
  moveInvestment             : boolean
  saveFunction               : () => void
  proxyToMoveId              : string
  closeModal                ?: () => void
}
interface ProxyMoveProps extends ProxyMoveOwnProps {}

const ProxyMove = (props:ProxyMoveProps) => {
  return (
    <Card className={props.newProxyGroupId ? "ProxyMove Wide" : "ProxyMove Narrow"}>
      <CardHeader
        className="Header"
        title={<Typography variant="h6"><Translate id="pacyConfigs.moveProxy" /></Typography>}
        avatar={<Icon color="primary">double_arrow</Icon>}
      />
      <Divider />
      <CardContent>
        {!props.newProxyGroupId
          ?
            <>
              <Typography variant="h6"><Translate id="pacyConfigs.chooseProxyGroupToMoveTo" /></Typography>
              <FilterInputWithButtons
                onSelect={props.setNewProxyGroupId}
                proxyGroups={props.proxyGroups.filter(proxygroup=>proxygroup.id !== props.proxyGroupId)}
              />
            </>
          :
            <>
              <FormControl className="ProxyGroupSelector">
                <Select  onChange={props.handleSelectChange} value={props.newProxyGroupId}>
                  {props.proxyGroups
                    .filter(proxyGroup=>proxyGroup.id !== props.proxyGroupId)
                    .map(proxyGroup=>{
                    return <MenuItem key={proxyGroup.id} value={proxyGroup.id}>{proxyGroup.name}</MenuItem>
                  })}
                </Select>
                <FormHelperText><Translate id="pacyConfigs.selectProxyGroup" /></FormHelperText>
              </FormControl>
              <Divider  />
              <div className="MoveInvestment">
                <Typography className="Typo" variant="h6"><Translate id="pacyConfigs.moveInvestmentQuestion" /></Typography>
                <div>
                  <Button onClick={()=>props.handleMoveInvestment(true)} color="primary" variant={props.moveInvestment ? 'contained' : 'text'}>
                    <Translate id="common.yes" />
                  </Button>
                  <Button onClick={()=>props.handleMoveInvestment(false)} color="primary" variant={props.moveInvestment === false ? 'contained' : 'text'}>
                    <Translate id="common.no" />
                  </Button>
                </div>
              </div>
              <div className={ props.moveInvestment ? "Current Selected" : "Current" }>
                <Typography variant="h6"><Translate id="pacyConfigs.movingInvestment" /></Typography>
                <div className="Tables">
                  <ProxiesAmountsDisplay
                    proxyToMoveId={props.proxyToMoveId}
                    type="movingInvestment"
                    proxyGroupId={props.proxyGroupId}
                  />
                  <ProxiesAmountsDisplay
                    proxyToMoveId={props.proxyToMoveId}
                    type="movingInvestment"
                    proxyGroupId={props.newProxyGroupId}
                  />
                </div>
              </div>
              <div className={props.moveInvestment === false ? "Next Selected" :"Next"}>
                <Typography variant="h6"><Translate id="pacyConfigs.notMovingInvestment" /></Typography>
                <div className="Tables">
                  <ProxiesAmountsDisplay
                    proxyToMoveId={props.proxyToMoveId}
                    type="notMovingInvestment"
                    proxyGroupId={props.proxyGroupId}
                  />
                  <ProxiesAmountsDisplay
                    proxyToMoveId={props.proxyToMoveId}
                    type="notMovingInvestment"
                    proxyGroupId={props.newProxyGroupId}
                  />
                </div>
              </div>
            </>
        }
      </CardContent>
      <Divider />
      {props.newProxyGroupId && <CardActions className="CardFooter">
        <Button size="large" color="secondary" variant="outlined" onClick={props.closeModal}><Translate id="common.cancel" /></Button>
        <Button
          onClick={()=>{props.saveFunction();props.closeModal()}}
          disabled={props.moveInvestment === null}
          size="large"
          style={{color:'white'}}
          color="primary"
          variant="contained"
        >
          <Translate id="pacyConfigs.done" />
        </Button>
      </CardActions>}
    </Card>
  )
}

export default ProxyMove
