import * as React                     from "react"
import {connect}                      from "react-redux"
import {retrieveIdentityVariants}     from "../../actions/branding/IdentityVariants"
import ExplainedLoading               from "../loading"
import ErrorBoundary                  from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  const accountId = state.Accounts.selected
  return {
    accountId,
    identityVariants                 : state.Params.Branding.IdentityVariants[accountId],
    retrievingIdentityVariants       : state.Params.Branding.retrievingIdentityVariants,
    failedRetrievingIdentityVariants : state.Params.Branding.failedRetrievingIdentityVariants[accountId],
    savingIdentityVariants           : state.Params.Branding.savingIdentityVariants
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    retrieveIdentityVariants : (accountId:number) => {
      dispatch(retrieveIdentityVariants(accountId))
    },
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,
    ...DP,
    ...ownProps,
    retrieveIdentityVariants : ()=>DP.retrieveIdentityVariants(SP.accountId)
  }
}
const IdentityVariantsLoader = props => {
  if(props.failedRetrievingIdentityVariants) {
    if (props.failedRetrievingIdentityVariants.status){
      return <p>{props.failedRetrievingIdentityVariants.message}</p>
    }
  }
  if(props.identityVariants === undefined && props.retrievingIdentityVariants === false){
    props.retrieveIdentityVariants()
    if(props.config.letThrough === false) {
      return <ExplainedLoading translateId="loadings.identityVariants"/>
    }
  }
  if(props.retrievingIdentityVariants && props.config.letThrough===false){
    return <ExplainedLoading translateId="loadings.identityVariants"/>
  }
  if(props.savingIdentityVariants && props.config.letThrough ===false) {
    return <ExplainedLoading translateId="loadings.saving"/>
  }
  return <ErrorBoundary>{props.render(props.identityVariants, props.retrievingIdentityVariants,props.savingIdentityVariants)}</ErrorBoundary>
}
const IdentityVariantsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(IdentityVariantsLoader)

interface IdentityVariantsLoaderConfig {
  letThrough : boolean
}
interface IdentityVariantsGivenConfig {
  letThrough ?: boolean
}
const baseConfig : IdentityVariantsLoaderConfig = {
  letThrough : false
}

const requiresIdentityVariants = (givenConfig:IdentityVariantsGivenConfig={}) => Component => props => {
  const config : IdentityVariantsLoaderConfig = {...baseConfig, ...givenConfig}
  return <IdentityVariantsLoaderConnected
    config = {config}
    render = { (identityVariants, loading,saving) => {
      return (
        <Component
          identityVariantsByLang={identityVariants}
          identityVariantsLoading={loading}
          savingIdentityVariants={saving}
          {...props}
        />
      )
    }}
  />
}
export default requiresIdentityVariants
