import "./AgentAttemptsChart.css"
import * as React                                     from "react"
import {connect}                                      from "react-redux"
import moment                                         from "moment"
import chroma                                         from "chroma-js"
import DateUtils                                      from "../../../utils/DateUtils"
import NumFormatter                                   from "../../../utils/NumberFormatter"
import ObjectUtils                                    from "../../../utils/ObjectUtils"
import ArrayUtils                                     from "../../../utils/ArrayUtils"
import {
  SpendAttempt,
  Agent
}                                                     from "../../../model/pacy/"
import {ProxyGroup}                                   from "../../../model/pacy/ProxyGroup"
import BudgetStats                                    from "../../../model/Store/Statistics/BudgetStats"
import Period                                         from "../../../model/Period"
import {StringMap}                                    from "../../../model/generics"
import {
  ResponsiveContainer,XAxis,
  YAxis,Tooltip,
  AreaChart, Area, ReferenceLine
}                                                     from "recharts"

interface AgentAttemptsChartOwnProps{
  proxyGroup               : ProxyGroup
  shownMonths              : string[]
  requestReportingDataLoad : (period:Period)=>boolean
  budgetStatsByMonth       : StringMap<BudgetStats[]>
  colorPerProxy            : StringMap<string>
  guidelineColor          ?: string
  syncId                  ?: string
}
interface AgentAttemptsChartProps extends AgentAttemptsChartOwnProps{
  guidelineAmountByDay : Map<string, number>
  agentsSpendAttempts  : StringMap<SpendAttempt[]>
}

const makeMapStateToProps = () => {
  return (state,ownProps:AgentAttemptsChartOwnProps)=>{
    return {
      agentsSpendAttempts  : state.Pacy.SpendAttempts[state.Accounts.selected]
    }
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {}
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

const getPeriodForShownMonths = (months:string[]):Period|undefined => {
  let dates
  if(DateUtils.isPastOrCurrentYM(months[0])){
    dates = {}
    dates.dateFrom = moment(months[0]).toDate()
    if(DateUtils.isCurrentOrFutureYM(months[months.length-1])){
      dates.dateTo = moment().toDate()
    }
    else{
      dates.dateTo = moment(months[months.length-1]).endOf("month").toDate()
    }
  }
  return dates
}
const sortProxiesTogetherWithLargerAtBottom = (agents:(Agent&{proxyId:string})[], data:any[]) => {
  const totalByAgent = agents.reduce((byAgent, agent)=>{
    byAgent[agent.id] = data.reduce((total, x)=>total+(x[agent.id]||0),0)
    return byAgent
  },{})
  const groupedAgents = ObjectUtils.getObjectValues(agents.reduce((grouped, agent)=>{
    if(!grouped[agent.id]){grouped[agent.id] = []}
    grouped[agent.id].push(agent)
    return grouped
  },{}))
  groupedAgents.sort((a,b)=>{
    const aTotal = a.reduce((total, x:Agent)=>total+totalByAgent[x.id],0)
    const bTotal = b.reduce((total, x:Agent)=>total+totalByAgent[x.id],0)
    if(aTotal > bTotal){return -1}
    if(aTotal < bTotal){return 1}
    return 0
  })
  for(const agents of groupedAgents){
    agents.sort((a,b)=>{
      const aTotal = totalByAgent[a.id]
      const bTotal = totalByAgent[b.id]
      if(aTotal > bTotal){return -1}
      if(aTotal < bTotal){return 1}
      return 0
    })
  }
  return ArrayUtils.flatten(groupedAgents)
}

const colorScale = chroma.scale(["f00","0f0","00f"]).mode("lch")

class AgentAttemptsChart extends React.Component<AgentAttemptsChartProps,any>{
  static defaultProps = {
    guidelineColor : "blue"
  }
  constructor(props){
    super(props)
    this.generateData = this.generateData.bind(this)
    this.getColoredAgents = this.getColoredAgents.bind(this)
  }
  generateData(dates, agents:Agent[]){
    return DateUtils.enumerateDaysBetweenMomentDates(moment(dates.dateFrom), moment(dates.dateTo)).map(day=>{
      const dataPoint = {xValue: day}
      const stats = (this.props.budgetStatsByMonth[moment(day).format("YYYY-MM")] || []).filter(x=>x.Day===day)

      for(const agent of agents){
        const spendAttempts = this.props.agentsSpendAttempts[agent.id]
        if(spendAttempts){
          let attemptForDay:SpendAttempt
          for(const spendAttempt of spendAttempts){
            if(spendAttempt.day === day && (!attemptForDay || spendAttempt.createdOn > attemptForDay.createdOn)){
              attemptForDay = spendAttempt
            }
          }
          if(attemptForDay){dataPoint[agent.id] = attemptForDay.amount}
        }
      }
      return dataPoint
    })
  }
  getCustomTooltip(agents:(Agent&{color:string, proxyId:string})[]):any{
    const dataKeysFormatting = agents.reduce((formattings, agent)=>{
      const proxy = this.props.proxyGroup.proxies.find(x=>x.id === agent.proxyId)
      formattings[agent.id] = {
        readable  : (agent.name || proxy.name+" ("+agent.provider+")") + " - Attempt",
        formatter : (x) => NumFormatter.formatCurrency(x),
        color     : agent.color
      }
      return formattings
    },{})
    return ({active, label, payload}) => {
      if(active){
        return (
          <div className="CustomTooltip">
            <p>{label}</p>
            {payload && payload.map(x=>{
              const formatting = dataKeysFormatting[x.dataKey]
              return (
                <p className="Row" style={{color: formatting.color}}>
                  <span>{formatting.readable}</span>
                  &nbsp;:&nbsp;
                  <span>{formatting.formatter(x.value)}</span>
                </p>
              )
            })}
          </div>
        )
      }
      return null
    }
  }
  getColoredAgents():(Agent&{color:string, proxyId:string})[]{
    return this.props.proxyGroup.proxies.reduce((agents, proxy)=>{
      const baseColor = chroma(this.props.colorPerProxy[proxy.id])
      const colors = chroma.scale([baseColor.brighten(1), baseColor, baseColor.darken(1)]).colors(proxy.agents.length)
      const coloredAgents = proxy.agents.map((agent, i)=>({
        ...agent,
        proxyId : proxy.id,
        color : colors[i]
      }))
      return agents.concat(coloredAgents)
    },[])
  }
  render(){
    const dates = getPeriodForShownMonths(this.props.shownMonths)
    if(!dates){return <div>No dates</div>}
    if(this.props.requestReportingDataLoad(dates)){
      return <div>LOADING STATS</div>
    }
    const allAgents = this.getColoredAgents()
    const data = this.generateData(dates, allAgents)
    const ticks = this.props.shownMonths.reduce((ticks, YM, i)=>{
      const date = moment(YM)
      ticks.push(date.format("YYYY-MM-DD"))
      return ticks
    },[])
    return (
      <div className="AgentAttemptsChart">
        <ResponsiveContainer height={"99%"}>
          <AreaChart data={data} syncId={this.props.syncId}>
            <XAxis dataKey="xValue" tick={false} ticks={ticks}/>
            <YAxis hide domain={[0, (d)=>d*1.2]}/>*/
            <Tooltip
              isAnimationActive={false}
              content={this.getCustomTooltip(allAgents)}
            />
            {sortProxiesTogetherWithLargerAtBottom(allAgents, data).map((agent)=>
              <Area
                key={agent.id+"attempt"}
                type="step"
                dataKey={agent.id}
                isAnimationActive={false}
                stroke={"none"}
                fill={agent.color}
                //fillOpacity={0.5}
                stackId={"attempts"}
                dot={false}
                strokeWidth={2}
              />
            )}
            {ticks.map(x=>
              <ReferenceLine
                key={x}
                x={x}
                stroke={"#cccccc"}
                strokeWidth={1}
                isFront={false}
                strokeDasharray={"3 1"}
              />
            )}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(AgentAttemptsChart) as React.ComponentType<AgentAttemptsChartOwnProps>
