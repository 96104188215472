import './FinalUrl.css'
import * as React		 from 'react'
import {
	CardContent,
	Typography,
	FormControl,
	CardActions,
	Button,
	TextField,
	FormHelperText,
  CardHeader,
} 									 from '@material-ui/core'
import {
	Translate,
	withLocalize,
	TranslateFunction
} 									 from 'react-localize-redux'
import Strategy      from '../../../../model/Store/Strategy/Strategy'


interface FinalUrlOwnProps {
	setStrategies : any
  strategy    : Strategy
  accountId   : number
  url         : string
  lang        : string
  setStrategy : any
	initialUrl  : string
	setError    : any
	error 			: boolean
}
interface FinalUrlProps extends FinalUrlOwnProps {
	translate : TranslateFunction
}

const FinalUrl = (props:FinalUrlProps) => {
	const [url,setUrl] = React.useState(props.url)
	function handleChange(event) {
    setUrl(event.target.value)
		props.setStrategy({...props.strategy,finalUrl:event.target.value})
		const finalUrl = event.target.value
		props.setStrategies(prevState=>
			prevState.map(x=>{
				if(x.lang === props.lang){
					return {
						...x,
						finalUrl : finalUrl
					}
				}
				return x
			})
		)
	}
	React.useEffect(()=>{
		setUrl(props.url)
  },[props.url,props.accountId,props.lang])
  function handleReset() {
    setUrl(props.initialUrl)
		props.setStrategy({...props.strategy, finalUrl:props.initialUrl})
		props.setStrategies(prevState=>
			prevState.map(x=>{
				if(x.lang === props.lang){
					return {
						...x,
						finalUrl :props.initialUrl
					}
				}
				return x
			})
		)
	}
	function validateUrl(url:string) {
		try {
			const valid = new URL(url)
			if(valid.protocol === 'ftp:') {
				props.setError(true)
				return false
			}
			props.setError(false)
			return true
		}
		catch(err) {
			props.setError(true)
			return false
		}
	}
  return (
		<div className="FinalUrl">
      <CardHeader
        title={<Typography variant="h6"><Translate id="strategies.finalUrl" /></Typography>}
        subheader={
          <Typography className="Caption" variant="caption">
            <Translate id="branding.descriptions.finalUrl" />
            <br/>
            <em><Translate id="branding.urlFormatDescription" /></em>
          </Typography>
        }
      />
			<CardContent className="Inputs">
				<FormControl variant="outlined">
					<TextField
						fullWidth
						variant="outlined"
						label="Final URL"
						value={url.trim()}
						onChange={handleChange}
						error={!validateUrl(url)}
						aria-describedby="url-error-text"
					/>
					<FormHelperText
						style={{color:'#e53935'}}
						id="url-error-text"
					>
						{props.error && url.length > 1
							? props.translate('branding.urlValidationFormat')
							: url.length === 0 ? props.translate('branding.urlValidationLength')
							: null
						}
					</FormHelperText>
				</FormControl>
			</CardContent>
      <CardActions className="Buttons">
				<Button
					color="secondary"
					onClick={handleReset}>
						<Translate id="common.reset" />
				</Button>
      </CardActions>
		</div>
  )
}

export default withLocalize(FinalUrl as any) as React.ComponentType<FinalUrlOwnProps>
