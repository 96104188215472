import ReducerUtils       from "../../utils/ReducerUtils"
import Actions            from "../../model/constant/actions"
import {StringMap}        from "../../model/generics"
import {AccountState}      from "../../model/pacy/AccountState"

const saveData = (state:State, action):State => {
  const accountId = action.accountId
  const accountStateId = action.accountStateId
  const accountState = action.accountState
  if(!state[accountId]){
    return {
      ...state,
      [accountId] : {
        [accountStateId] : accountState
      }
    }
  }
  return {
    ...state,
    [accountId] : {
      ...state[accountId],
      [accountStateId] : accountState
    }
  }
}


type State = StringMap<StringMap<AccountState>>
const AccountStates = ReducerUtils.createReducer<State>({},{
  [Actions.RETRIEVE_PACY_ACCOUNT_STATE] : saveData
})

export default AccountStates
