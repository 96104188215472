import { createMuiTheme }                  from "@material-ui/core/styles"
import generateTheme, { ThemeParams }      from "./GenerateTheme"

const PARAMS:ThemeParams = {
  primary : "#00B2E3",
  secondary : "#e53935"
}

const theme = createMuiTheme(generateTheme(PARAMS))

export default theme
