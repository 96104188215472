import Keystone from "../../../utils/Keystone"
import Actions  from "../../../model/constant/actions"

const fetchKeywordsSuccess = (response, accountId) => {
  return {
    type            : Actions.RETRIEVE_KEYWORD_PATTERNS_FOR_ACCOUNTID,
    keywordPatterns : response.data.keywordPatterns,
    accountId       : accountId
  }
}
export const fetchKeywords = (token:string, accountId:number) => {
  const payload = {
    token     : token,
    action    : "GetKeywordPatterns",
    accountId : accountId
  }
  return (dispatch) => {
    const onResult = (data) => dispatch(fetchKeywordsSuccess(data, accountId))
    const onError = (data) => console.log(data.error)
    return Keystone.get(payload,onResult,onError)
  }
}
