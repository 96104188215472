import * as React         from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
}                         from "@material-ui/core"
interface SelectProps {
  value      : string
  helperText : string
  onChange   : (selected:any)=>void
  options    : {[key:string]:string}
  fullWidth ?: boolean
  className ?: string
}
const CustomSelect = (props:SelectProps)=>(
  <FormControl className={props.className || ''} fullWidth={!!props.fullWidth}>
    <Select value={props.value} onChange={e=>props.onChange(e.target.value as any)}>
      {Object.keys(props.options).map(key=>(
        <MenuItem value={key}>
          {props.options[key]}
        </MenuItem>
      ))}
    </Select>
    <FormHelperText>{props.helperText}</FormHelperText>
  </FormControl>
)
export default CustomSelect
