import ObjectUtils from "./ObjectUtils"

export default class ArrayUtils {
  public static unique=(a:any[]):any[] => {return Array.from(new Set(a))}
  public static uniqueFunc=(a:any[], hasher:(any)=>string):any[] => {
    var seen = []
    var returned = []
    for(let x of a){
      let hash = hasher(x)
      if(seen.indexOf(hash)===-1){
        seen.push(hash)
        returned.push(x)
      }
    }
    return returned
  }
  public static getArrayFromObject(object:any):any[]{
    return ObjectUtils.getObjectValues(object).reduce((array,currentValue)=>{
      if(Array.isArray(currentValue)){return [...array,...currentValue]}
      return [...array,currentValue]
    },[])
  }
  public static sum(array:number[]):number{
    return array.reduce((a, b) => a + b, 0);
  }
  public static flatten(array:any[]):any[]{
    return array.reduce((flat, toFlatten)=>{
      return flat.concat(Array.isArray(toFlatten) ? ArrayUtils.flatten(toFlatten) : toFlatten)
    }, [])
  }
  public static contain(array:any[], searched:any):boolean{
    return array.indexOf(searched)>-1
  }
  public static containAll(array:any[], searched:any[]):boolean{
    for(let searchTerm of searched){
      if(!this.contain(array, searchTerm)){return false}
    }
    return true
  }
  public static randomChoice(array:any[]):any {
    return array[Math.floor(Math.random()*array.length)]
  }
  public static comp(a,b):1|0|-1|number{return a>b?1:a===b?0:-1}
  public static isLooselySameArray(array:any[], array2:any[]):boolean{
    if (!array){return false}
    return array.length === array2.length && ArrayUtils.containAll(array, array2)
  }
  public static isSameArray(array:any[], array2:any[]):boolean{
    const n = array.length
    if(n !== array2.length){return false}
    for(let i=0;i<n;i++){
      if(array[i]!==array2[i]){return false}
    }
    return true
  }
  public static shuffle(array:any[]):any[]{
    for(let i = array.length - 1; i > 0; i--){
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  public static mergeBySerialization(initialArray:any[], newArray:any[], serializer:(item:any)=>string):any[]{
    let merged = {}
    for(let item of initialArray){merged[serializer(item)] = item}
    for(let item of newArray){merged[serializer(item)] = item}
    return ObjectUtils.getObjectValues(merged)
  }
  public static zip(...arrays:any[][]){return arrays[0].map((_,i)=>arrays.map(temp=>temp[i]))}
  public static chunkArray<T>(array:T[],perChunk:number):T[][] {
    return array.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index/perChunk)

      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
  }
}
