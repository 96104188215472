import './EngagementRulesInventoryResult.css'
import * as React         from "react"
import {connect}          from "react-redux"
import {Translate}        from "react-localize-redux"
import {Link}             from "react-router-dom"
import ExplainedLoading   from "../../../components/loading"
import Vehicle            from "../../../model/Store/Vehicle/Vehicle"
import Car                from "../../../model/Store/Vehicle/Car"
import VehiclesAccessors  from "../../../storeAccessor/Inventory"
import RatioChart         from "../../../components/chart/RatioChart"


interface EngagementRulesInventoryResultProps{
  inventory : Vehicle[]
  accountId : number
}
const makeMapStateToProps = ()=>{
  const selector = VehiclesAccessors.makeInventorySelector()
  return state => ({
    inventory : selector(state),
    accountId : state.Accounts.selected
  })
}
const compile = inventory => {
  return inventory
  .filter(v=>v.isCurrent() && v instanceof Car)
  .reduce((inventory, vehicle)=>{
    const vehicleState = vehicle.getState()
    if(!inventory[vehicleState]){inventory[vehicleState] = []}
    inventory[vehicleState].push(vehicle)
    return inventory
  },{})
}
const EngagementRulesInventoryResult = (props:EngagementRulesInventoryResultProps) => {
  if(!props.inventory){return <ExplainedLoading translateId="loadings.inventory"/>}
  const inventoryByState = compile(props.inventory)
  return (
    <div className="EngagementRulesInventoryResult">
      <h4><Translate id="dashboard.rules.engRules"/></h4>
      {
        Object.keys(inventoryByState).map(state=>{
          const total = inventoryByState[state].length
          const filteredLength = inventoryByState[state].filter(v=>v.getPassedRules()).length
          const url = "/Account/"+props.accountId+"/Params/Rules/"+state
          return (
            <div key={state} className='StateSummary'>
              <strong>
                <Translate
                  id="dashboard.rules.stateCars"
                  data={{
                    state : <Translate id={"common."+state.toLowerCase()}/>
                  }}
                />
              </strong>
              &nbsp;
              <Link to={url}><Translate id="dashboard.rules.edit"/></Link>
              <RatioChart numberOk={parseFloat(filteredLength)} numberTotal={total} />
            </div>
          )
        })
      }
    </div>
  )
}

export default connect(makeMapStateToProps)(EngagementRulesInventoryResult)
