import {combineReducers} from "redux"
import AWSearch          from "./AWSearch"
import Failed            from "./Failed"

const Retrieving = combineReducers({
  AWSearch,
  Failed
})

export default Retrieving
