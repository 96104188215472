import * as React         from 'react'
import {
  Chip,
  Icon,
  Tooltip,
}                         from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                         from "react-localize-redux"
import {
  FacebookV1FeedParams,
}                         from "../../../model/feed/Feed"
import FeedSummaryProps   from "./props"
import LangChip           from "./LangChip"
import InactiveChip       from "./InactiveChip"

interface FeedSumaryFacebookV1Props extends LocalizeContextProps, FeedSummaryProps<FacebookV1FeedParams>{
  isMI : boolean
}

const FeedSummaryFacebookV1 = (props:FeedSumaryFacebookV1Props) => {
  const deletionLocked = props.urls.some( url => url.isLocked )

  return (
    <div className='FeedSummary'>
      <Tooltip placement="top" title={<p>{props.translate("feeds.tooltip.FACEBOOK")}</p>}>
        <img className="FACEBOOK" src="./assets/img/facebook_logo.svg"/>
      </Tooltip>
      {props.isMI &&
        <Tooltip placement="top" title={<p>{props.translate("feeds.tooltip.MOTOINSIGHT")}</p>}>
          <img className="FACEBOOK" src="./assets/img/motologo.png"/>
        </Tooltip>
      }
      <Tooltip placement="top" title={<p>{props.translate("feeds.tooltip.vehicleType")}</p>}>
        <Chip color="primary" variant="outlined" label={props.translate("feeds.vehicleType."+props.config.params.vehicleType)}/>
      </Tooltip>
      <LangChip lang={props.config.params.lang} inventoryLang={props.config.params.inventoryLang}/>
      <InactiveChip active={props.urls.length!==0}/>
      {
        deletionLocked &&
          <Tooltip placement="top" title={<p>{props.translate('feeds.deleteLocked')}</p>}>
            <div className="AlignCenter">
              <Icon style={{color: "gray"}}>lock</Icon>
            </div>
          </Tooltip>
      }
    </div>
  )
}
export default withLocalize(FeedSummaryFacebookV1)
