import * as React                 from "react"
import {connect}                  from "react-redux"
import {retrieveFeedsSignedUrls}  from "../../actions/feeds/SignedUrls"
import ExplainedLoading           from "../loading"
import ErrorBoundary              from "../Error/ErrorBoundary"
import {State}                    from "../../reducers/generic/reducerAssembly"

const mapStateToProps = (state:State,ownProps) => {
  const accountId = state.Accounts.selected
  return {
    accountId,
    retrieving : state.Feeds.SignedUrlsRetrieving,
    signedUrls : state.Feeds.SignedUrlsList[accountId],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadFeedsSignedUrls : (accountId:number)=>dispatch(retrieveFeedsSignedUrls(accountId)),
  }
}
const mergeProps = (
  SP:ReturnType<typeof mapStateToProps>,
  DP:ReturnType<typeof mapDispatchToProps>,
  ownProps : {letThrough : boolean, render : (...args)=>any}
) => {
  return {
    ...SP,...DP,...ownProps,
    loadFeedsSignedUrls : () => DP.loadFeedsSignedUrls(SP.accountId),
  }
}
const FeedsSignedUrlsLoader = (props:ReturnType<typeof mergeProps>) => {
  let loading = false
  if(props.signedUrls === undefined || props.retrieving){
    loading = true
    if(!props.retrieving){props.loadFeedsSignedUrls()}
    if(!props.letThrough){
      return <ExplainedLoading translateId="loadings.feeds"/>
    }
  }
  return <ErrorBoundary>{props.render(props.signedUrls, loading)}</ErrorBoundary>
}
const FeedsSignedUrlsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(FeedsSignedUrlsLoader)

const requiresFeedsSignedUrls = (letThrough?:boolean) => Component => props => (
  <FeedsSignedUrlsLoaderConnected
    letThrough={letThrough || false}
    render={ (signedUrls, loading) => (
      <Component
        feedsSignedUrls={signedUrls}
        loadingFeedsSignedUrls={loading}
        {...props}
      />
    )}
  />
)

export default requiresFeedsSignedUrls
