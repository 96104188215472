import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"

const setStatus = (state, action) => {
  return action.status
}

const Retrieving = ReducerUtils.createReducer<boolean>(false,{
  [Actions.RETRIEVING_BING_GEO_MAPPING] : setStatus
})

export default Retrieving
