import './AdGroupCreator.css'
import * as React               from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  Icon,
}                               from '@material-ui/core'
import NameAndLanguages         from './NameAndLanguages'
import CreateKeywords           from './CreateKeywords'
import AudienceAdCreator        from './AudienceAdCreator'
import AWSearch                 from '../../../model/Store/Audiences/AWSearch'
import {
  AdGroupConfigsByLang,
  AdGroupConfigsByName
}                               from '../../../model/branding/AdGroupConfigs'
import { DragDropContext }      from 'react-dnd'
import HTML5Backend             from "react-dnd-html5-backend"
import { connect }              from 'react-redux'
import Actions                  from '../../../model/constant/actions'
import { AudienceAd }           from '../../../model/branding/AudienceAd'
import { newAdGroupsByLang }    from '../../../model/branding/newAdGroup'
import {
  Translate,
  TranslateFunction,
  withLocalize
}                               from 'react-localize-redux'
import {createNewAdGroup}       from '../../../actions/branding/AdGroupConfigs'
import { getAccountId }         from '../../../storeAccessor/Generic'
import DialogMui                from '../../../components/Modals/DialogMui'

interface AdGroupCreatorProps extends AdGroupCreatorOwnProps {
  deleteAudienceAd                   : (laudienceAdId:number) => void
  addAudienceAd                      : (audienceAd:AudienceAd,lang:string) => void
  updateNewAdGroupName               : (adGroupName:string) => void
  newAdGroup                         : newAdGroupsByLang
  updateNewAdGroupKeywords           : (lang:string,keywords:string[]) => void
  resetNewAdGroup                    : ()=> void
  checkNewAdGroupExistingAudienceAds : (lang:string) => void
  accountId                          : number
  createNewAdGroup                   : (accountId:number,adGroupConfig:AdGroupConfigsByName[],lang:string) => void
  translate                          : TranslateFunction
}
interface AdGroupCreatorOwnProps {
  allAdGroupsByLang        : AdGroupConfigsByLang
  toggleCreationMode       : (state:boolean) => void
  awAudiences              : AWSearch[]
}


const mapStateToProps = (state,ownProps:AdGroupCreatorOwnProps) => {
  const accountId = getAccountId(state)
  return {
    newAdGroup : state.EditCopies.brandingNewAdGroup,
    accountId  : accountId
  }
}
const mapDispatchToProps = dispatch => {
  return {
    updateNewAdGroupName : (adGroupName:string) => dispatch({type:Actions.UPDATE_NEWADGROUP_NAME,name:adGroupName}),
    updateNewAdGroupKeywords : (lang:string,keywords:string[],adGroupName:string) => {
      dispatch({type:Actions.UPDATE_NEWADGROUP_KEYWORDS,lang:lang,keywords:keywords,adGroup:adGroupName})
    },
    addAudienceAd : (audienceAd:AudienceAd,lang:string) => {
      dispatch({type:Actions.ADD_NEWADGROUP_AUDIENCEAD,lang:lang,audienceAd:audienceAd})
    },
    deleteAudienceAd : (audienceAdId:number) => {
      dispatch({type:Actions.DELETE_AUDIENCEAD_NEWADGROUP,audienceAdId:audienceAdId})
    },
    resetNewAdGroup : ()=> {
      dispatch({type:Actions.RESET_NEWADGROUP})
    },
    checkNewAdGroupExistingAudienceAds : (lang:string) => {
      dispatch({type:Actions.CHECK_NEWADGROUP_EXISTING_AUDIENCEADS,lang:lang})
    },
    createNewAdGroup : (accountId:number,adGroupConfig:AdGroupConfigsByName[],lang:string) => {
      dispatch(createNewAdGroup(accountId,adGroupConfig,lang))
    }
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
  }
}

const AdGroupCreator = (props:AdGroupCreatorProps) => {
  const [enableSave,setEnableSave] = React.useState(false)
  const [openExit,setOpenExit] = React.useState(false)
  const [errors,setError]=React.useState({
    keywords : false
  })
  const [selectedLanguages,setSelectedLanguages] = React.useState([] as string[])
  const [adGroupName,setAdGroupName] = React.useState(props.newAdGroup[Object.keys(props.newAdGroup)[0]].name)
  const steps = getSteps()
  const [activeStep, setActiveStep] = React.useState(0)
  //Find error in AdGroup name
  let nameError = false
  if(adGroupName.trim().length === 0) {
    nameError = true
  }
  else if(selectedLanguages.length > 0) {
    for(let lang of selectedLanguages) {
      const existingNames = Object.keys(props.allAdGroupsByLang[lang]).map(x=>x.toUpperCase())
      if(existingNames.includes(adGroupName.toUpperCase())) {
        nameError = true
        break
      }
    }
  }
  let nameErrorMessage = ''
  if(nameError && adGroupName.trim().length !==0) {
    nameErrorMessage = props.translate('branding.creation.existingAdGroupName') as string
  } else {
    nameErrorMessage = ''
  }

  function getSteps() {
    return [
      props.translate('branding.creation.nameAndLanguages') as string,
      props.translate('branding.creation.keywords') as string,
      props.translate('common.ads') as string
    ]
  }
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  function exitCreationMode() {
    props.toggleCreationMode(false)
    props.resetNewAdGroup()
    handleCloseExit()
  }
  //ERROR HANDLERS
  //Handle Language Change
  function setLanguagesFunction(e){
    if(e.target.checked){
      setSelectedLanguages(prevState=>{
        return [...prevState,e.target.value]
      })
      props.checkNewAdGroupExistingAudienceAds(e.target.value)
    }
    if(!e.target.checked) {
      setSelectedLanguages(prevState=>{
        const temp = [...prevState]
        temp.splice(temp.indexOf(e.target.value),1)
        return temp
      })
    }
  }
  //Handle Name Change
  function setAdGroupNameFunction(name:string){
    setAdGroupName(name)
    props.updateNewAdGroupName(name)
  }
  function setKeywordsError(error:boolean){
    if(errors.keywords !== error) {
      setError(prevState=>{
        return {...prevState,keywords:error}
      })
    }
  }
  //Modal Handlers
  function handleOpenExit() {
    setOpenExit(true)
  }
  function handleCloseExit() {
    setOpenExit(false)
  }
  //Save Handler
  function enableSaveFunction(state:boolean) {
    setEnableSave(state)
  }
  const saveFunction = () => {
    selectedLanguages.map(lang=>{
      delete props.newAdGroup[lang].name
      const formatData = {...props.newAdGroup[lang],audienceAds : [...props.newAdGroup[lang].audienceAds].map(audienceAd=>{
        if(audienceAd.audienceId === "0") {
          return {...audienceAd, audienceId :'NO_AUDIENCE'}
        }
        return {...audienceAd, audienceId : audienceAd.audienceId.toString() }
      })}
      props.createNewAdGroup(props.accountId,formatData as any,lang)
    })
    props.resetNewAdGroup()
  }
  //Steps
  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <NameAndLanguages
            allAdGroupsByLang={props.allAdGroupsByLang}
            newAdGroup={props.newAdGroup}
            adGroupName={adGroupName}
            selectedLanguages={selectedLanguages}
            updateName={props.updateNewAdGroupName}
            setAdGroupName={setAdGroupNameFunction}
            setSelectedLanguages={setLanguagesFunction}
            error={nameError}
          />
        )
      case 1:
        return (
          <CreateKeywords
            newAdGroup={props.newAdGroup}
            selectedLanguages={selectedLanguages}
            updateNewAdGroupKeywords={props.updateNewAdGroupKeywords}
            adGroupName={adGroupName}
            setKeywordsError={setKeywordsError}
          />
        )
      case 2:
        return (
          <AudienceAdCreator
            existingAudiencesAds={
              selectedLanguages.reduce((acc,lang)=>{
                acc.push(...props.newAdGroup[lang].audienceAds)
                return acc
              },[])
            }
            addAudienceAd={props.addAudienceAd}
            adGroupName={adGroupName}
            buttons={false}
            awAudiences = {props.awAudiences}
            availableLanguages={selectedLanguages}
            deleteAudienceAd={props.deleteAudienceAd}
            enableSave={enableSaveFunction}
            adGroupConfigsByLang={props.allAdGroupsByLang}
          />
        )
      default:
        return 'Unknown step'
    }
  }
  return (
    <div className={"AdGroupCreator ".concat(activeStep===0 ? 'NameAndLanguage' : activeStep===1 ? 'Keywords' : activeStep===2 ? 'Ads' : '')}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: { optional?: React.ReactNode } = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel  style={{color:'white'}} {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <div>
        <div>
          <div >{getStepContent(activeStep)}</div>
          <div className="Footer">
            <div className="Messages">
              {nameErrorMessage && <Typography className="Error"><Icon className="Icon">error</Icon>{nameErrorMessage}</Typography>}
            </div>
            <div className="Buttons">
              <Button variant="contained" color="secondary" onClick={handleOpenExit}><Translate id="common.exit"/></Button>
              <Button disabled={activeStep === 0} onClick={handleBack} color="secondary" size="large">
                <Translate id="branding.creation.previousStep" />
              </Button>
              {activeStep === steps.length - 1
                ?<Button
                  size="large"
                  style={{color:'white'}}
                  variant="contained"
                  color="primary"
                  onClick={saveFunction}
                  disabled={!enableSave}
                >
                  <Translate id="common.save"/>
                </Button>
                :<Button
                size="large"
                style={{color:'white'}}
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={activeStep === 0 ? nameError || selectedLanguages.length===0 : activeStep === 1 ? errors.keywords : true}
                >
                  <Translate id="branding.creation.nextStep" />
              </Button>}
            </div>
          </div>
        </div>
      </div>
      <DialogMui
        open={openExit}
        cancelText={props.translate('common.cancel') as string}
        confirmText={props.translate('common.yes') as string}
        title={props.translate('common.exit') as string}
        content={props.translate('branding.creation.leaveMessage') as string}
        handleClose={handleCloseExit}
        handleSave={exitCreationMode}
      />
    </div>
  )
}

export default withLocalize(
  connect(mapStateToProps,mapDispatchToProps,mergeProps)(
    DragDropContext(HTML5Backend)(AdGroupCreator)
  )
) as React.ComponentType<AdGroupCreatorOwnProps>
