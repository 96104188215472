import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const set = (state,action) => ({
  ...state,
  [action.accountId] : action.status
})
export const FetchingNavigations = ReducerUtils.createReducer<{[accountId:number]:boolean}>({},{
  [Actions.RETRIEVING_USER_NAVIGATIONS_OF_ACCOUNT] : set
})
