import './WeightsConfiguration.css'
import * as React            from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Icon,
  Divider,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell
}                            from '@material-ui/core'
import { Proxy }             from '../../../../model/pacy'
import WeightsGraph          from './Components/WeightsGraph'
import { Translate }         from 'react-localize-redux'
import { getColorsByProxy }  from '../../../../utils/Pacy'


interface WeightsConfigurationOwnProps {
  proxies             : Proxy[]
  handleCloseModal    : () => void
  handleWeightsChange : (proxiesChanged:{[id:string]:number},proxiesActivated:string[]) => void
}
interface WeigthsConfigurationProps extends WeightsConfigurationOwnProps {}

const WeightsConfiguration = (props:WeigthsConfigurationProps) => {
  const [activated, setActivated] = React.useState<string[]>([])
  const [weights, setWeights] = React.useState<{[proxyId:string]: number}>({})

  const colorsByProxy = getColorsByProxy(props.proxies)

  const handleInputChange = (event,proxyId:string) => {
    const temp =  Math.abs(Math.round(parseInt(event.target.value,10)))
    setWeights(prevState=>({
      ...prevState,
      [proxyId] : temp
    }))
  }
  const handleActivateProxy = (proxyId:string) => {
    setActivated(prev=>[...prev, proxyId])
  }
  const proxies = props.proxies.map(proxy=>({
    ...proxy,
    active : activated.includes(proxy.id) ? true : proxy.active,
    weight : weights[proxy.id] === undefined ? proxy.weight : weights[proxy.id] ,
  }))
  const finalWeights = Object.keys(weights).reduce((acc,proxyId)=>{
    if (isNaN(weights[proxyId])) {
      return {...acc,[proxyId]:0}
    }
    return {...acc,[proxyId]:weights[proxyId]}
  },{})
  return (
    <Card className="WeightsConfiguration">
      <CardHeader
        title={<Typography variant="h6"><Translate id="pacyConfigs.weightsConfig" /></Typography>}
        avatar={<Icon>build</Icon>}
      />
      <Divider />
      <CardContent className="Content">
        <div className="WeightInputs">
          <Table>
            <TableBody>
              {proxies.map((proxy,i)=>{
                return (
                  <TableRow key={i}>
                    <TableCell style={proxy.active ? {backgroundColor:colorsByProxy[proxy.id]} : {backgroundColor:'gray'}}>
                    </TableCell>
                    <TableCell style={{whiteSpace:'nowrap', overflow:'hidden'}}>
                      <div className="NameContainer">
                        <div className="Name">{proxy.name}</div>
                        {!proxy.active && <Button onClick={()=>{handleActivateProxy(proxy.id)}} color="primary">
                            <Translate id='pacyConfigs.activate' />
                        </Button>}
                      </div>
                    </TableCell>
                    <TableCell>
                    <TextField
                      type="number"
                      disabled={!proxy.active}
                      onChange={(event)=>handleInputChange(event,proxy.id)}
                      variant="outlined"
                      name={proxy.name}
                      value={proxy.weight}
                    />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <Divider orientation="vertical" />
        <div className="Graph">
          <WeightsGraph
            proxies={proxies}
            colors={colorsByProxy}
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions className="WeightsConfigurationFooter">
        <Button onClick={props.handleCloseModal} variant="outlined" color="secondary"><Translate id="common.cancel" /></Button>
        <Button onClick={()=>{props.handleWeightsChange(finalWeights,activated);props.handleCloseModal()}} style={{color:'#FFF'}} variant="contained" color="primary">
          <Translate id="pacyConfigs.done" />
        </Button>
      </CardActions>
    </Card>
  )
}

export default WeightsConfiguration
