import './TranslatedGoBackLarge.css'
import React              from 'react'
import ButtonGoBackLarge  from '../Button/ButtonGoBackLarge'
import LinkGoBackLarge    from '../Button/LinkGoBackLarge'
import { Translate }      from 'react-localize-redux'

type TranslatedGoBackLargeProps = {
  translationId : string
  type          : "link"
  to           ?: string
} | {
  translationId : string
  type          : "button"
  onClick       : ()=>void
}

const TranslatedGoBackLarge = (props: TranslatedGoBackLargeProps) => {
    return (
      <div className="TranslatedGoBackLarge">
        <p className="Text"><Translate id={props.translationId} /></p>
        {
          props.type === "button"
          ? <ButtonGoBackLarge onClick={props.onClick} />
          : <LinkGoBackLarge to={props.to ? props.to : ""} />
        }
      </div>
    )

}

export default TranslatedGoBackLarge
