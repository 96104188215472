import "./ProxyGroupsPlanningChart.css"
import * as React                                     from "react"
import moment, {Moment}                               from "moment"
import classNames                                     from "classnames"
import {withLocalize, Translate, TranslateFunction}   from "react-localize-redux"
import NumFormatter                                   from "../../../../utils/NumberFormatter"
import {ProxyGroup}                                   from "../../../../model/pacy/ProxyGroup"

import {
  getGuidelineForDay,
  getGuidelineAmount,
}                                                     from "../../../../model/pacy/Guideline"
import BudgetStats                                    from "../../../../model/Store/Statistics/BudgetStats"
import {StringMap}                                    from "../../../../model/generics"
import {
  ResponsiveContainer,BarChart,XAxis,
  YAxis,Tooltip,Bar
}                                                     from "recharts"

interface ProxyGroupsPlanningChartOwnProps{
  proxyGroups              : ProxyGroup[]
  idToColorMapping         : StringMap<string>
  statsByMonthByProxyGroup : StringMap<StringMap<BudgetStats[]>>
}
interface ProxyGroupsPlanningChartProps extends ProxyGroupsPlanningChartOwnProps{
  translate : TranslateFunction
}

const getSpentOfProxyGroupForMonth = (stats:StringMap<StringMap<BudgetStats[]>>, proxyGroup:ProxyGroup, month:Moment):number => {
  if(!stats[proxyGroup.id]){return 0}
  const m = month.format("YYYY-MM")
  if(!stats[proxyGroup.id][m]){return 0}
  return stats[proxyGroup.id][m].reduce((spent,x)=>spent+x.Spent,0)
}


const XTick = (props) => {
  const isCurrent = props.payload.value === moment().format("YYYY-MM")
  const classes = classNames("XTicks", {
    CurrentMonth : isCurrent
  })
  return (
    <g className={classes} transform={`translate(${props.x},${props.y})`}>
      <text className="BaseText" x={0} y={0} dx={0} dy={isCurrent?49:35} textAnchor={"middle"}>
        <Translate id={"common.months."+moment(props.payload.value).format("MM")}/>
      </text>
      {isCurrent &&
        <text className="ExtraText" x={0} y={0} dx={0} dy={90} textAnchor={"middle"}>
          <Translate id="pacyPlanner.currentMonth"/>
        </text>
      }
    </g>
  )
}

const tooltipFormatter = (x: string | number | React.ReactText[]) => {
  if (typeof x === "number") {
    return NumFormatter.formatCurrency(x)
  }
  if (typeof x === "string") {
    return NumFormatter.formatCurrency(parseFloat(x))
  }
  else {
    const value = x[0]
    typeof value === "number" ? NumFormatter.formatCurrency(value) : NumFormatter.formatCurrency(parseFloat(value))
  }
}

class ProxyGroupsPlanningChart extends React.Component<ProxyGroupsPlanningChartProps,any>{
  static defaultProps = {
  }
  constructor(props){
    super(props)
  }

  render(){
    const data = [
      moment().subtract(2, "months").endOf("month"),
      moment().subtract(1, "month").endOf("month"),
      moment().endOf("month"),
      moment().add(1, "month").endOf("month"),
      moment().add(2, "months").endOf("month"),
    ].map(month=>{
      const dataPoint = {xValue: month.format("YYYY-MM")}
      for(const proxyGroup of this.props.proxyGroups){
        const g = getGuidelineForDay(proxyGroup.guidelines, month.format("YYYY-MM-DD"))
        const gAmount = g ? getGuidelineAmount(g) : 0
        const spent = getSpentOfProxyGroupForMonth(this.props.statsByMonthByProxyGroup, proxyGroup, month)
        if(gAmount > 0 || spent > 0){ //remove proxygroups from months where they did nothing
          dataPoint[proxyGroup.id+"|guideline"] = gAmount
          dataPoint[proxyGroup.id+"|spent"]     = spent
        }
      }
      return dataPoint
    })
    return (
      <div className="ProxyGroupsPlanningChart">
        <ResponsiveContainer height={"99%"}>
          <BarChart
            data={data}
            barGap={10}
            barCategoryGap={"20%"}
            margin={{top: 20, right: 50, bottom: 0, left: 50}}
          >
            <XAxis dataKey="xValue" tick={XTick} height={100} tickLine={false} stroke="#9b9b9b"/>
            <YAxis
              tickLine={false}
              stroke="#9b9b9b"
              tick={{fontSize: "20px"}}
              tickFormatter={(value) => NumFormatter.formatCurrency(value)}
              tickMargin={15}
            />
            <Tooltip
              isAnimationActive={false}
              formatter={tooltipFormatter}
            />
            {this.props.proxyGroups.reduce((bars,x)=>{
              bars.push(
                <Bar
                  key={x.id+"guideline"}
                  dataKey={x.id+"|guideline"}
                  stackId="guideline"
                  fill={this.props.idToColorMapping[x.id]}
                  fillOpacity={1}
                  //minPointSize={6}
                  name={x.name+" ("+this.props.translate("pacyPlanner.investment")+")"}
                />
              )
              bars.push(
                <Bar
                  key={x.id+"spent"}
                  dataKey={x.id+"|spent"}
                  stackId="spent"
                  fill={this.props.idToColorMapping[x.id]}
                  fillOpacity={0.5}
                  //minPointSize={6}
                  name={x.name+" ("+this.props.translate("common.spent")+")"}
                />
              )
              return bars
            },[])}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

export default withLocalize(ProxyGroupsPlanningChart) as React.ComponentType<ProxyGroupsPlanningChartOwnProps>
