import AWSearch from "../../../model/Store/Audiences/AWSearch"

export const provideAudienceName = (audience:string, allAudiences:AWSearch[]):string => {
  if (audience==="NO_AUDIENCE"){
    return "New visitors"
  }
  if (parseInt(audience)===undefined || isNaN(parseInt(audience))){
    return audience
  }
  const audienceId = parseInt(audience)
  const audienceName = allAudiences.find(x=>x.id===audienceId)?.name
  return audienceName||audience
}