import {AdCopyStringLengths}  from "../../../model/adbuilder/"
import {ReferenceRow}         from "../../../model/Store/Cleaner"
import StringUtils            from "../../../utils/StringUtils"
import {AdCopiesTag}          from "../../../model/constant/AdCopiesTags"


const calculateAdCopyTextLength = (stringLengths?:AdCopyStringLengths) => (text:string) : number => {
  text = text.trim().replace(/\s\s+/g, ' ').toUpperCase()
  text = text.replace(/<MAKE>\s*<MODEL>/g, "<MAKEMODEL>")
  let length = text.length
  if (stringLengths) {
    // Add based on shortest possible vehicle strings
    length +=  stringLengths.shortestMake      * StringUtils.occurrences(text, AdCopiesTag.Make.toUpperCase())
    length +=  stringLengths.shortestModel     * StringUtils.occurrences(text, AdCopiesTag.Model.toUpperCase())
    length +=  stringLengths.shortestMakeModel * StringUtils.occurrences(text, AdCopiesTag.MakeModel.toUpperCase())
    // Subtract the length of tags to be replaced
    length -=  AdCopiesTag.Make.length      * StringUtils.occurrences(text, AdCopiesTag.Make.toUpperCase())
    length -=  AdCopiesTag.Model.length     * StringUtils.occurrences(text, AdCopiesTag.Model.toUpperCase())
    length -=  AdCopiesTag.MakeModel.length * StringUtils.occurrences(text, AdCopiesTag.MakeModel.toUpperCase())

    length -=  2*StringUtils.occurrences(text, AdCopiesTag.Year.toUpperCase())
    length -=  2*StringUtils.occurrences(text, AdCopiesTag.Price.toUpperCase())
    length -=  5*StringUtils.occurrences(text, AdCopiesTag.Count.toUpperCase())
    length -= -1*StringUtils.occurrences(text, AdCopiesTag._Month.toUpperCase())
    length -= -2*StringUtils.occurrences(text, AdCopiesTag.Month.toUpperCase())
  }
  else {
    length -=  2*StringUtils.occurrences(text, AdCopiesTag.Year.toUpperCase())
    length -=  2*StringUtils.occurrences(text, AdCopiesTag.Price.toUpperCase())
    length -=  5*StringUtils.occurrences(text, AdCopiesTag.Count.toUpperCase())
    length -=  3*StringUtils.occurrences(text, AdCopiesTag.Make.toUpperCase())
    length -=  5*StringUtils.occurrences(text, AdCopiesTag.Model.toUpperCase())
    length -=  5*StringUtils.occurrences(text, AdCopiesTag.MakeModel.toUpperCase())
    length -= -1*StringUtils.occurrences(text, AdCopiesTag._Month.toUpperCase())
    length -= -2*StringUtils.occurrences(text, AdCopiesTag.Month.toUpperCase())
  }
  return length
}
export const findShortestAndLongestStrings = (data: ReferenceRow[]) => {
  // ReferenceRows should be already filtered to what's in bound of the Boundaries
  let foundVehicle      = false
  let shortestMake      = 999
  let shortestModel     = 999
  let shortestMakeModel = 999
  for (const datum of data){
    foundVehicle = true
    if(datum.DisplayMake.length      < shortestMake     ){shortestMake      = datum.DisplayMake.length     }
    if(datum.DisplayModel.length     < shortestModel    ){shortestModel     = datum.DisplayModel.length    }
    if(datum.DisplayMakeModel.length < shortestMakeModel){shortestMakeModel = datum.DisplayMakeModel.length}
  }
  if(foundVehicle){
    return {
      shortestMake,
      shortestModel,
      shortestMakeModel,
    }
  }
  return null
}

export const calculateLengthForEditor = (text:string):number => {
    text = text.trim().toUpperCase().replace(/\s\s+/g, ' ')
    let length = text.length
    length -= 2*StringUtils.occurrences(text, "<YEAR>")
    length -= 2*StringUtils.occurrences(text, "<PRICE>")
    length -= 5*StringUtils.occurrences(text, "<COUNT>")
    length -= 3*StringUtils.occurrences(text, "<MAKE>")
    length -= 5*StringUtils.occurrences(text, "<MODEL>")
    length -= 5*StringUtils.occurrences(text, "<MAKEMODEL>")
    length -= 13*StringUtils.occurrences(text, "<SEARCHEDMAKEMODEL>")
    length -= 10*StringUtils.occurrences(text, "<SEARCHEDYEAR>")
    length -= 11*StringUtils.occurrences(text, "<SEARCHEDMAKE>")
    length -= 13*StringUtils.occurrences(text, "<SEARCHEDMODEL>")
    length -= 15*StringUtils.occurrences(text, "<CONQUERINGMAKEMODEL>")
    length -= 15*StringUtils.occurrences(text, "<CONQUERINGMODEL>")
    length -= 13*StringUtils.occurrences(text, "<CONQUERINGMAKE>")
    length -= 12*StringUtils.occurrences(text, "<CONQUERINGYEAR>")
    length -= -1*StringUtils.occurrences(text, "<_MONTH>")
    length -= -2*StringUtils.occurrences(text, "<MONTH>")
    return length
  }

export default calculateAdCopyTextLength
