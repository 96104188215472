import './Settings.css'
import * as React                                          from 'react'
import IdentityVariants                                    from './IdentityVariants/IdentityVariants'
import Languages                                           from './Languages/Languages'
import GeoTargetingType                                    from './GeoTargetingType/GeoTargetingType'
import FinalUrl                                            from './FinalUrl/FinalUrl'
import {
  Paper,
  Card,
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Select,
  FormHelperText,
  FormControl,
  Typography,
}                                                          from '@material-ui/core'
import { AdGroupConfigsByLang }                            from '../../../model/branding/AdGroupConfigs'
import requiresStrategies                                  from '../../../components/loaders/strategiesLoader'
import BiddingStrategy                                     from './BiddingStrategy/BiddingStrategy'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                                                          from 'react-localize-redux'
import { connect }                                         from 'react-redux'
import {setStrategy}                                       from '../../../actions/branding/Settings'
import Strategy                                            from '../../../model/Store/Strategy/Strategy'
import requiresIdentityVariants                            from '../../../components/loaders/identityVariantsLoader'
import { saveIdentityVariants }                            from '../../../actions/branding/IdentityVariants'
import ArrayUtils                                          from '../../../utils/ArrayUtils'
import ExplainedLoading                                    from '../../../components/loading'
import { SimplifiedIdentityVariants }                      from '../../../model/branding/SimplifiedIdentityVariants'
import{
  IdentityVariantsByLang
}                                                          from '../../../model/branding/IdentityVariants'

interface SettingsOwnProps {
  accountId             : number
  lang                  : string
  adGroupConfigsByLang  : AdGroupConfigsByLang
  handleChangeLang      : (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface SettingsProps extends SettingsOwnProps {
  strategies              : Strategy[]
  identityVariantsByLang  : IdentityVariantsByLang
  identityVariantsLoading : boolean
  savingIdentityVariants  : boolean
  setStrategy             : (accountId:number,strategy:Strategy) => void
  saveIdentityVariants    : (accountId:number, lang:string, identityVariants:SimplifiedIdentityVariants) => void
  translate               : TranslateFunction
}
const mapStateToProps = (state,props) => {
  return {}
}
const mapDispatchToProps = dispatch => {
  return {
    setStrategy : (accountId:number, strategy:any) => (
      dispatch(setStrategy(accountId,strategy))
    ),
    saveIdentityVariants : (accountId:number, lang:string, identityVariants:SimplifiedIdentityVariants) => (
      dispatch(saveIdentityVariants(accountId, lang, identityVariants))
    )
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
  }
}
const Settings = (props:SettingsProps) => {
  const [error,setError] = React.useState(false)

  const [openReset, setOpenReset] = React.useState(false)
  const [openSave, setOpenSave] = React.useState(false)

  const initialStrategies = props.strategies.filter(strategy=>strategy.group === 'BRANDING')
  const [strategies,setStrategies] = React.useState(props.strategies.filter(strategy=>strategy.group === 'BRANDING'))

  const initialStrategyByLang = props.strategies.find(strategy => strategy.group === 'BRANDING' && strategy.lang === props.lang)
  const [strategyByLang,setStrategyByLang] = React.useState(
    props.strategies.filter(strategy => strategy.group === 'BRANDING' && strategy.lang === props.lang)[0]
  )

  const initialIdentityVariants = {
    cities      : props.identityVariantsByLang[props.lang].cities,
    dealerNames : props.identityVariantsByLang[props.lang].dealerNames,
    makes       : props.identityVariantsByLang[props.lang].makes,
  }
  const [editedIdentityVariants,setEditedIdentityVariant] = React.useState<SimplifiedIdentityVariants>(initialIdentityVariants)
  const allFilled = editedIdentityVariants.cities.length > 0 && editedIdentityVariants.dealerNames.length > 0 && editedIdentityVariants.makes.length > 0

  React.useEffect(()=>{
    setStrategyByLang(props.strategies.filter(strategy => strategy.group === 'BRANDING' && strategy.lang === props.lang)[0])
    setEditedIdentityVariant(props.identityVariantsByLang[props.lang])
    setStrategies(props.strategies.filter(strategy=>strategy.group==='BRANDING'))
  },[props.lang,props.accountId,props.strategies])

  //Compare Functions
  function isSameIdentityVariants() {
    return ArrayUtils.isSameArray(initialIdentityVariants.cities,editedIdentityVariants.cities) &&
      ArrayUtils.isSameArray(initialIdentityVariants.dealerNames,editedIdentityVariants.dealerNames) &&
      ArrayUtils.isSameArray(initialIdentityVariants.makes,editedIdentityVariants.makes)
  }
  function isSameStrategies() {
    if(initialStrategyByLang.biddingStrategyId !== strategyByLang.biddingStrategyId) {return false}
    if(initialStrategyByLang.finalUrl !== strategyByLang.finalUrl) {return false}
    if(initialStrategyByLang.geoTargetingType !== strategyByLang.geoTargetingType) {return false}
    return true
  }
  function isSameActiveLanguages(){
    for(let i=0;i<initialStrategies.length;i++) {
      if(initialStrategies[i].active !== strategies[i].active) {return false}
    }
    return true
  }

  //Dialog Handlers
  function handleClickOpenReset() {
    setOpenReset(true)
  }
  function handleCloseReset() {
    setOpenReset(false)
  }
  function handleClickOpenSave() {
    setOpenSave(true)
  }
  function handleCloseSave() {
    setOpenSave(false)
  }
  function handleReset() {
    setEditedIdentityVariant(initialIdentityVariants)
    setStrategyByLang(initialStrategyByLang)
    setStrategies(props.strategies.filter(strategy=>strategy.group ==='BRANDING'))
  }

  //Generate Languages Radios
  const availableLanguages = Object.keys(props.adGroupConfigsByLang).map(lan => {
    return (
      <MenuItem
        value={lan}
        key={lan}
      >
        {lan === 'EN' ? props.translate('common.english') : props.translate('common.french')}
      </MenuItem>
    )
  })
  if (!strategyByLang) {
    return (
      <div className="NoStrategies">
        <Typography variant="h5">
          <Translate id="branding.noStrategies" />
        </Typography>
      </div>
    )
  }
  return (
    <div className="Settings">
      <FormControl className="LanguagesSelect">
        <Select
          value={props.lang}
          onChange={props.handleChangeLang}
        >
          {availableLanguages}
        </Select>
        <FormHelperText><Translate id="branding.chooseLanguage" /></FormHelperText>
      </FormControl>
      <Paper className="Content">
        <Card className="IdentityVariants">
          { props.savingIdentityVariants
            ? <ExplainedLoading translateId="loadings.saving"/>
            : props.identityVariantsLoading
              ? <ExplainedLoading translateId="loadings.identityVariants"/>
              : <IdentityVariants
                  initialIdentityVariants={initialIdentityVariants}
                  setIdentityVariants={setEditedIdentityVariant}
                  identityVariants={editedIdentityVariants}
                />
          }
        </Card>
        <Card className="Languages">
          <Languages
            strategies={strategies}
            setStrategies={setStrategies}
          />
        </Card>
        <Card className="FinalUrl">
          <FinalUrl
            error={error}
            setError={setError}
            setStrategies={setStrategies}
            setStrategy={setStrategyByLang}
            strategy = {strategyByLang}
            url={strategyByLang.finalUrl}
            accountId={props.accountId}
            lang={props.lang}
            initialUrl={initialStrategyByLang.finalUrl}
          />
        </Card>
        <Card className="GeoTargetingType">
          <GeoTargetingType
            setStrategies={setStrategies}
            setStrategy={setStrategyByLang}
            strategy = {strategyByLang}
            geoType={strategyByLang.geoTargetingType}
            accountId={props.accountId}
            lang={props.lang}
          />
        </Card>
        <Card className="BiddingStrategy">
          <BiddingStrategy
            setStrategies={setStrategies}
            setStrategy={setStrategyByLang}
            strategy = {strategyByLang}
            biddingStrategyId={strategyByLang.biddingStrategyId}
            accountId={props.accountId}
            lang={props.lang}
          />
        </Card>
        <CardActions className="Buttons">
          <Button size="large" color="secondary" onClick={handleClickOpenReset} variant="outlined" ><Translate id="common.reset" /></Button>
          <Button
            disabled={error || !allFilled || (isSameIdentityVariants() && isSameStrategies() && isSameActiveLanguages())}
            style={{color:'white'}}
            size="large"
            color="primary"
            onClick={handleClickOpenSave}
            variant="contained"
          >
            <Translate id="common.save" />
          </Button>
        </CardActions>
      </Paper>
      <Dialog
        open={openReset}
        onClose={handleCloseReset}
        aria-labelledby="reset-dialog-title"
        aria-describedby="reset-dialog-description"
      >
        <DialogTitle id="reset-dialog-title"><Translate id="common.reset"/></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Translate id="branding.resetMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReset} color="secondary" autoFocus>
            <Translate id="common.cancel" />
          </Button>
          <Button onClick={()=>{handleCloseReset();handleReset()}} color="primary">
            <Translate id="common.reset"/>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSave}
        onClose={handleCloseSave}
        aria-labelledby="save-dialog-title"
        aria-describedby="save-dialog-description"
      >
        <DialogTitle id="save-dialog-title"><Translate id="common.save"/></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Translate id="branding.saveMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSave} color="secondary" autoFocus>
            <Translate id="common.cancel" />
          </Button>
          <Button
            onClick={()=>{
              handleCloseSave()
              if(!isSameActiveLanguages() || !isSameStrategies()) {
                strategies.forEach(strategy=>props.setStrategy(props.accountId,strategy))
              }
              if(!isSameIdentityVariants()) {
                props.saveIdentityVariants(props.accountId, props.lang, editedIdentityVariants)
              }
            }}
            color="primary"
          >
            <Translate id="common.save"/>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(
  withLocalize(
    requiresIdentityVariants({letThrough:false})(
      requiresStrategies()(Settings)
    )
  )
) as React.ComponentType<SettingsOwnProps>
