import * as React                    from "react"
import {withLocalize}                from "react-localize-redux"
import {ModalWrapper, TextInput}     from "carbon-components-react"
import {Button}                      from "carbon-components-react"

interface CreateTemplateModalProps{
  saveHandler        : (name:string)=>void
  buttonTriggerText ?: string
  translate         ?: (translateId:string)=>string
}
interface CreateTemplateModalState{
  name  : string
  error : string
}
class CreateTemplateModal extends React.Component<CreateTemplateModalProps,CreateTemplateModalState>{
  constructor(props){
    super(props)
    this.state = {
      name : "",
      error : ""
    }
    this.submit = this.submit.bind(this)
  }
  submit(){
    if(this.state.name.length<3){
      this.setState({error:this.props.translate("reports.ads.viewNameLengthError")})
      return false
    }
    this.props.saveHandler(this.state.name)
    return true
  }
  render(){
    return(
      <ModalWrapper
        id="CreateTemplateModal"
        buttonTriggerText={this.props.buttonTriggerText?this.props.buttonTriggerText:this.props.translate("reports.ads.createView")}
        modalHeading={this.props.translate("reports.ads.createViewHeader")}
        primaryButtonText={this.props.translate("common.confirm")}
        secondaryButtonText={this.props.translate("common.cancel")}
        handleSubmit={this.submit}
        shouldCloseAfterSubmit
      >
        {
          this.state.error !== ""?
            <div className={"alert alert-danger"}>{this.state.error}</div>
          :
            <></>
        }
        <TextInput
          id="TemplateName"
          placeholder={this.props.translate("reports.ads.viewNamePlaceholder")}
          labelText={this.props.translate("reports.ads.nameLabel")}
          value={this.state.name}
          onChange={(e)=>this.setState({name:e.target.value})}
          onKeyDown={(e) => {
            if (e.keyCode === 13) { this.submit() }
          }}
        />
      </ModalWrapper>
    )
  }
}

export default withLocalize(CreateTemplateModal)
