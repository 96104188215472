import * as React                             from "react"
import {connect}                              from "react-redux"
import StatisticsAccessors                    from "../../../storeAccessor/Statistics"
import ReportTableTemplate                    from "../../../model/Store/Report/ReportTableTemplate"
import ReportTableTemplateContexts            from "../../../model/constant/ReportTableTemplateContexts"
import ReportTable                            from "./ReportTable"
import {Loading}                              from "carbon-components-react"

interface StatsExtractorOwnProps{
  context  : string
  template : ReportTableTemplate
}
interface StatsExtractorProps extends StatsExtractorOwnProps{
  stats : any[]
}
const makeMapStateToProps = () => {
  return (state,ownProps) => {
    const campaignsStatsSelector = StatisticsAccessors.makeReportCompiledCampaignsStatsSelector(ownProps.template.getProvider())
    const keywordsStatsSelector = StatisticsAccessors.makeReportCompiledKeywordsStatsSelector(ownProps.template.getProvider())
    let stats
    switch(ownProps.context){
      case ReportTableTemplateContexts.CAMPAIGN:
        stats = campaignsStatsSelector(state, ownProps.template)
      break
      case ReportTableTemplateContexts.KEYWORD:
        stats = keywordsStatsSelector(state, ownProps.template)
      break
      default:
        stats = []
    }
    return {
      stats : stats
    }
  }
}
const mapDispatchToProps = dispatch => {
  return{}
}
interface StatsExtractorState{
  isTableMounted : boolean
  shouldRenderNull : boolean
}
class StatsExtractor extends React.Component<StatsExtractorProps,StatsExtractorState>{
  constructor(props){
    super(props)
    this.state = {
      isTableMounted : false,
      shouldRenderNull : false,
    }
  }
  componentDidUpdate(prevProps){
    if(prevProps.template.getId() != this.props.template.getId()){
      this.setState({shouldRenderNull:true})
    }
  }
  render(){
    if(!this.props.stats){return <Loading/>}
    if(this.state.shouldRenderNull){return null}
    return (
      <ReportTable
        template={this.props.template}
        data={this.props.stats}
        tellMeYoureAlive={()=>this.setState({isTableMounted:true})}
        tellMeYoureDead={()=>this.setState({shouldRenderNull:false, isTableMounted:false})}
      />
    )
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(StatsExtractor)
