import "./TwoPartsWithLogoButton.css"
import React                    from 'react'
import {
  Button,
  Divider,
}                               from '@material-ui/core'

interface TwoPartsWithLogoButtonOwnProps {
  logo       : string|React.ReactElement
  topText    : string
  bottomText : string
  onClick    : ()=>void
  disabled  ?: boolean
}

const TwoPartsWithLogoButton = (props:TwoPartsWithLogoButtonOwnProps) => {
  return (
    <Button
      disabled={props.disabled}
      size="large"
      variant="contained"
      style={{
        backgroundColor: props.disabled?"default":"white",
        width: "300px",
      }}
      onClick={props.onClick}
    >
      <div style={{marginRight:15,paddingTop:5}}>
        {
          typeof props.logo === "string"
          ? <img style={{width: "30px"}} src={props.logo}/>
          : props.logo
        }
      </div>
      <div>
        <div>{props.topText}</div>
        <Divider/>
        <div>{props.bottomText}</div>
      </div>
    </Button>
  )
}

export default TwoPartsWithLogoButton
