import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"
import ObjectUtils      from "../../utils/ObjectUtils"

const getKey = row => {
  return ["day","campaign","offer","pin","channel"].map(x=>row[x]).join("-")
}
const storePerformanceData = (state,action) => {
  if (state[action.accountId]){
    var newState = {}
    for (let stat of state[action.accountId] ){ newState[getKey(stat)] = stat }
    for (let stat of action.rows             ){ newState[getKey(stat)] = stat }
    return {...state, [action.accountId] : ObjectUtils.getObjectValues(newState)}
  }
  return {...state,[action.accountId] : action.rows}
}
const setEmptyList = (state, action) => {
  return {
    ...state,
    [action.accountId] : []
  }
}
const PerformanceData = ReducerUtils.createReducer<{[accountId:number]:any[]}>({},{
  [Actions.RETRIEVE_WAZE_PERFORMANCE_DATA] : storePerformanceData,
  [Actions.RETRIEVE_WAZE_DATA_NO_ACCOUNT] : setEmptyList,
})
export default PerformanceData
