import * as React                  from "react"
import {connect}                   from "react-redux"
import {State}                     from '../../reducers/generic/reducerAssembly'
import {retrieveAWSearchAudiences} from "../../actions/audience/AWSearch"
import ExplainedLoading            from "../loading"
import ErrorBoundary               from "../Error/ErrorBoundary"
import {Translate}                 from "react-localize-redux"

const mapStateToProps = (state:State, _ownProps) => {
  const accountId = state.Accounts.selected
  return {
    accountId,
    retrieving : state.Audiences.Retrieving.AWSearch,
    audiences  : state.Audiences.AWSearch[accountId],
    failed     : state.Audiences.Retrieving.Failed,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadAWSearchAudiences : (accountId:number)=>dispatch(retrieveAWSearchAudiences(accountId)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    loadAWSearchAudiences : () => DP.loadAWSearchAudiences(SP.accountId),
  }
}
const AWSearchAudiencesLoader = (props:ReturnType<typeof mergeProps>) => {
  let loading = props.retrieving
  if (props.failed.status){
    return <div>
      <div style={{color:"red"}}><Translate id="adCopies.failedLoadingAudiences" /></div>
      <div><Translate id="adCopies.failedLoadingAudiencesExtraInfo" /></div>
    </div>
  }
  if((props.audiences === undefined || props.retrieving) && !props.failed.status){
    loading = true
    if(!props.retrieving){props.loadAWSearchAudiences()}
    if(!props.letThrough){
      return <ExplainedLoading translateId="loadings.AWSearchAudiences"/>
    }
  }
  return <ErrorBoundary>{props.render(props.audiences, loading)}</ErrorBoundary>
}
const AWSearchAudiencesLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(AWSearchAudiencesLoader)

const requiresAWSearchAudiences = (letThrough?:boolean) => Component => props => (
  <AWSearchAudiencesLoaderConnected
    letThrough={letThrough || false}
    render={ (AWAudiences, loading) => <Component AWAudiences={AWAudiences} loadingAWAudiences={loading} {...props} /> }
  />
)

export default requiresAWSearchAudiences
