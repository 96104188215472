import './WebLeadsTable.css'
import * as React                            from "react"
import {connect}                             from "react-redux"
import {withLocalize, Translate}             from "react-localize-redux"
import BootstrapTable                        from 'react-bootstrap-table-next'
import filterFactory, {textFilter}           from 'react-bootstrap-table2-filter'
import DateUtils                             from "../../../utils/DateUtils"
import CSVUtils                              from "../../../utils/CSVUtils"
import ArrayUtils                            from "../../../utils/ArrayUtils"
import Compile                               from '../compile/Compile'
import datetimeFormatter                     from './formatter/Date'
import pathFormatter                         from './formatter/Path'
import {Button}                              from "carbon-components-react"
import requiresLeads                         from "../../../components/loaders/leadsLoader"
import NumFormatter                          from '../../../utils/NumberFormatter'


const mapStateToProps = (state,ownProps) => {
  const accountId = state.Accounts.selected
  return {
    accountId   : accountId,
    period      : state.Period,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
const defaultFilter = [{dataField: 'timestamp', order: 'desc'}]
class WebLeadsTable extends React.Component<any,any>{
  private table
  constructor(props){
    super(props)
    this.generateCSV = this.generateCSV.bind(this)
  }
  generateCSV(){
    let header     = ["Date Time","Page","Contact"]
    let dataFields = ["end","path","insight"]
    const data = (
      this.table.store._filteredData.length>0
      ? this.table.store._filteredData
      : this.table.store._data
    )
    const extractData = (row, key) => {
      if(key === "end"){
        return (new Date(row[key]*1000)).toLocaleString()
      }
      return row[key]
    }
    CSVUtils.downloadCSV(
      "WebLeads_"+String(this.props.accountId)+".csv",
      CSVUtils.generateCSV(header, data.map(row=>dataFields.map(key=>extractData(row,key))), ",")
    )
  }
  render(){
    const compiled = Compile(this.props.leads)
    .filter(x => DateUtils.isInPeriod(new Date(x.end*1000), this.props.period))
    .map(x => ({ ...x, key : [x.browserId,x.begin,x.path,x.referrer,].join("-") }))
    const columns = [
      {
        dataField:'end',
        text:this.props.translate("leads.webLeads.dateTime"),
        sort:true,
        formatter:datetimeFormatter,
      },{
        dataField:'path',
        text:"Page",
        sort:true,
        filter:textFilter({delay:20}),
        formatter:pathFormatter,
      },{
        dataField:'insight',
        text:"Contact",
        sort:true,
        filter:textFilter({delay:20}),
      },
    ]
    const uniqueContactInfo = ArrayUtils.unique(compiled.map(x=>x.insight))
    const uniqueEmailsCount  = uniqueContactInfo.filter(x=>x.includes("@")).length
    const uniqueNumbersCount = uniqueContactInfo.length - uniqueEmailsCount
    return (
      <div className="WebLeadsModule" key={this.props.accountId}>
        <div className='Explanation'>
          <div><Translate id="leads.webLeads.explanation1"/></div>
          <div><Translate id="leads.webLeads.explanation2"/></div>
          <div><Translate id="leads.webLeads.explanation3"/></div>
        </div>
        <div className="divider">
          <Button className="CSVButton" onClick={this.generateCSV}>CSV</Button>
          <div className='WebLeadsCount'>
            <div><Translate id="leads.webLeads.foundPeriod" data={{value:""+NumFormatter.formatNumber(compiled.length)}}/></div>
            <div><Translate id="leads.webLeads.foundUniquePhone" data={{value:""+NumFormatter.formatNumber(uniqueNumbersCount)}}/></div>
            <div><Translate id="leads.webLeads.foundUniqueEmail" data={{value:""+NumFormatter.formatNumber(uniqueEmailsCount)}}/></div>
          </div>
        </div>
        <BootstrapTable
          hover
          data={compiled}
          ref={table=>this.table = table}
          keyField='key'
          columns={columns}
          defaultSorted={defaultFilter}
          filter={filterFactory()}
          classes={"normalSizedDatatable LeadsDataTable"}
        />
      </div>
    )
  }
}
export default (
  requiresLeads(
    withLocalize(
      connect(mapStateToProps,mapDispatchToProps)(
        WebLeadsTable
      )
    )
  )
)
