import axios, {Method}            from "axios"

export type GetAccountsReturn = Array<{accountId: number, bingAccountId: string}>
export type GetCampaignsReturn = {
  campaigns : Campaign[]
  nextPage ?: number
}
export interface Campaign {
  bingId              : number
  lang                : string
  type                : string
  finalUrl            : string
  name                : string
  status              : string
  GeoTargetingType    : string
  TrackingUrlTemplate : string
  vehicleState        : string
  vehicleType         : string
  vehicles            : string
}
export default class Brimstone {
  protected static getApiUrl(){
    return process.env.REACT_APP_BRIMSTONE_URL
  }
  public static doRequest<T>(method:Method, timeout:number, endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    const url = ("/"+endpoint).replace("//","/")
    return axios.request<T>({
      method,
      baseURL : Brimstone.getApiUrl(),
      url     : url,
      params  : queryParams,
      data    : body,
      headers : {
        ...headers,
        Authorization : `Bearer ${token}`
      },
      timeout : 10*1000,
    })
  }
  public static get<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Brimstone.doRequest<T>("get", 10*1000, endpoint, token, queryParams, body, headers)
  }
  public static post<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Brimstone.doRequest<T>("post", 60*1000, endpoint, token, queryParams, body, headers)
  }
  public static put<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Brimstone.doRequest<T>("put", 60*1000, endpoint, token, queryParams, body, headers)
  }
  public static patch<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Brimstone.doRequest<T>("patch", 60*1000, endpoint, token, queryParams, body, headers)
  }

  public static getAccounts(token:string) {
    return Brimstone.get<GetAccountsReturn>("/Accounts", token)
  }
  public static getCampaigns(token:string, accountId:number, pageNumber?:number) {
    return Brimstone.get<GetCampaignsReturn>("/Account/"+accountId+"/Campaigns", token, {Page : pageNumber})
  }
  public static getCampaignsBaseInfo(token:string, accountId:number, campaignBingIds?:number[]) {
    let additional = {}
    if(campaignBingIds){
      additional = {CampaignBingIDs : campaignBingIds.join(',')}
    }
    return Brimstone.get("/Account/"+accountId+"/CampaignsBaseInfo", token, additional)
  }
}
