import "./index.css"
import * as React                       from "react"
import {connect}                        from "react-redux"
import moment                           from "moment"
import chroma                           from "chroma-js"
import {Translate}                      from "react-localize-redux"
import PacySelectors                    from "../../../../storeAccessor/Pacy"
import ArrayUtils                       from "../../../../utils/ArrayUtils"
import {
  PacyAccount,
}                                       from "../../../../model/pacy/"
import {ProxyGroup}                     from "../../../../model/pacy/ProxyGroup"
import {
  getGuidelineForDay,
  getGuidelineAmount
}                                       from "../../../../model/pacy/Guideline"
import Period                           from "../../../../model/Period"
import {StringMap}                      from "../../../../model/generics"
import PERMISSIONS                      from "../../../../model/constant/Permissions"
import BudgetStats                      from "../../../../model/Store/Statistics/BudgetStats"
import requiresPacy                     from "../../../../components/loaders/pacyLoader"
import AccountPermissionBoundary        from "../../../../components/permissions/AccountPermissionBoundary"
import ProxyGroupsList                  from "./ProxyGroupsList"
import ProxyGroupsPlanningChart         from "./ProxyGroupsPlanningChart"
import { withRouter }                   from "react-router"
import RoutingUtils                     from "../../../../utils/RoutingUtils"
import PreferencesAccessors             from "../../../../storeAccessor/Preferences"
import { THEMES }                       from "../../../../Application"
import DealerBreacherTheme              from "../../../../Themes/DealerBreacher"
import UserLogged                       from "../../../../model/Store/Login/UserLogged"


interface PlannerOwnProps{
  account                  : PacyAccount
  user                     : UserLogged
  requestReportingDataLoad : (period:Period)=>boolean
}
interface PlannerProps extends PlannerOwnProps{
  statsByMonthByProxyGroup : StringMap<StringMap<BudgetStats[]>>
  readOnly   : boolean
  whiteLabel : string
  history
}

const makeMapStateToProps = () => {
  const whiteLabelAccessor = PreferencesAccessors.makeActiveWhiteLabelSelector()
  const separatedStatsSelector = PacySelectors.makeStatsByMonthByProxyGroupSelector()
  return (state,ownProps:PlannerOwnProps)=>{
    return {
      statsByMonthByProxyGroup : separatedStatsSelector(state, ownProps.account),
      whiteLabel               : whiteLabelAccessor(state),
      user                     : state.Login.userLogged,
    }
  }
}

const generateColorsFromPalette = (colors:string[], numberOfItems:number):string[] => {
  if(numberOfItems <= colors.length){return colors}

  //Each gap between colors in palette will have a number of colors to generate
  const numberOfColorsInEachGap = []
  for(let i = 0; i < colors.length-1; ++i){
    numberOfColorsInEachGap[i] = 0
  }
  const numberOfExtraItems = numberOfItems - colors.length

  for(let i = 0; i < numberOfExtraItems; ++i){
    numberOfColorsInEachGap[i%numberOfColorsInEachGap.length]++
  }

  const newPalette = [...colors]
  for(let i = 0; i < numberOfColorsInEachGap.length; ++i){
    const insertIndex = newPalette.indexOf(colors[i])+1
    const tempColors = chroma.scale([colors[i], colors[i+1]]).mode("lch").colors(2+numberOfColorsInEachGap[i])
    const toInsert = tempColors.slice(1, tempColors.length-1)
    newPalette.splice(insertIndex, 0, ...toInsert)
  }
  return newPalette
}
const getSortedProxyGroups = (proxyGroups:ProxyGroup[], order:string[]):ProxyGroup[] => {
  const sorted = []
  for(const proxyGroup of proxyGroups){
    const index = order.indexOf(proxyGroup.id)
    sorted[index] = proxyGroup
  }
  return sorted
}

//palettes
const TRFFK = [
  "#8B0000",
  "#FF0000",
  "#FFFFFF",
]
const DealerBreacher = [
  "#053b7e",
  "#0b5c99",
  "#3aa4d9",
  "#3ec2de",
  "#62d4ec",
  "#8ae8f8",
]
const Convertus = [
  "#006400",
  "#6B8E23",
  "#FFFFFF"
]
const palettes = {
  DealerBreacher,
  TRFFK,
  Convertus,
}
const PermissionWrapper = (props:PlannerProps) => (
  <AccountPermissionBoundary
    key={props.account ? props.account.accountId : 0}
    permissions={[PERMISSIONS.Keystone_ChangeBudget]}
    onFailure={<Planner {...props} readOnly/>}
  >
    <Planner {...props}/>
  </AccountPermissionBoundary>
)
const Planner = (props:PlannerProps) => {
  const [proxyGroupsOrder, _] = React.useState(()=>{
    const today = moment().format("YYYY-MM-DD")
    const currentGuidelinePerProxyGroupId = props.account.proxyGroups.reduce((byId, pg)=>{
      byId[pg.id] = getGuidelineAmount(getGuidelineForDay(pg.guidelines, today))
      return byId
    },{})
    const ids = Object.keys(currentGuidelinePerProxyGroupId)
    ids.sort((a,b)=>
      ArrayUtils.comp(
        currentGuidelinePerProxyGroupId[b],
        currentGuidelinePerProxyGroupId[a]
      )
    )
    return ids
  })
  const [fakingProxygroups, setFaking] = React.useState(false)
  const [activePalette, setActivePalette] = React.useState(2)

  let proxyGroups = getSortedProxyGroups(props.account.proxyGroups, proxyGroupsOrder)
  //proxyGroups.sort((a,b)=>ArrayUtils.comp(a.name, b.name)) //doing this before faking. I want fakes to be 'shuffled'
  if(fakingProxygroups){
    proxyGroups = [
      ...props.account.proxyGroups.map(x=>({...x})),
      ...props.account.proxyGroups.map(x=>({...x})),
      ...props.account.proxyGroups.map(x=>({...x})),
    ]
    proxyGroups.forEach(x=>{x.id = x.id+Math.random()})
  }

  const colors = generateColorsFromPalette(palettes[props.whiteLabel] || DealerBreacher, proxyGroups.length)
  const chartIdToColorMapping = {}
  const listIdToColorMapping = {}
  for(let i = 0; i < proxyGroups.length; ++i){
    chartIdToColorMapping[proxyGroups[i].id] = colors[i]
    listIdToColorMapping[proxyGroups[i].id] = THEMES[props.whiteLabel] ? THEMES[props.whiteLabel].palette.primary.main :DealerBreacherTheme.palette.primary.main
  }

  return (
    <div className="Planner">
      <div className="Header">
        <h3 className="Title"><Translate id="pacyPlanner.title"/></h3>
        <div className="HeaderButtons">
          <AccountPermissionBoundary
            permissions={[PERMISSIONS.Pacy_Edit_Config]}
            onFailure={null}
          >
            <a className="pacyOptions"
              onClick={()=>{
                RoutingUtils.replaceLastElementOfUrl(props.history,"Configs")
              }}
            >
              <Translate id="pacyConfigs.advancedConfig" />
            </a>
          </AccountPermissionBoundary>
          <AccountPermissionBoundary
            permissions={[PERMISSIONS.Pacy_Update_Pacing_Ratio_Config]}
            onFailure={null}
          >
            <a className="pacyOptions"
              onClick={()=>{
                RoutingUtils.replaceLastElementOfUrl(props.history,"PacingRatio")
              }}
            >
              <Translate id="pacingRatio.title" />
            </a>
          </AccountPermissionBoundary>
          <a className="pacyOptions"
              onClick={()=>{
                RoutingUtils.replaceLastElementOfUrl(props.history,"ChangesHistory")
              }}
            >
              <Translate id="budgets.historyButton" />
          </a>
        </div>
      </div>
      {props.account.enabled ?
      <div>
        <div className="TopColoredBar"/>
        <div className="Content">
          <div className="ContentTitle">- <Translate id="pacyPlanner.planningChartTitle"/> -</div>
          <ProxyGroupsPlanningChart
            proxyGroups={proxyGroups}
            idToColorMapping={chartIdToColorMapping}
            statsByMonthByProxyGroup={props.statsByMonthByProxyGroup}
          />
          <ProxyGroupsList
            proxyGroups={proxyGroups}
            idToColorMapping={listIdToColorMapping}
            statsByMonthByProxyGroup={props.statsByMonthByProxyGroup}
            requestReportingDataLoad={props.requestReportingDataLoad}
            readOnly={props.readOnly}
          />
        </div>
      </div>
      :
      <div className="PacyMessage">
        <p style={{fontSize: 'inherit'}}><Translate id="pacyPlanner.deactivated.generalMessage" /></p>
        <br />
        <p style={{fontSize: 'inherit'}}><Translate id="pacyPlanner.deactivated.contact" /></p>
        <br />
        <AccountPermissionBoundary
          key="PlannerPacyDeactivated"
          permissions={[PERMISSIONS.Pacy_Edit_Config]}
          onFailure={null}
        >
          <p style={{fontSize: 'inherit'}}><Translate id="pacyPlanner.deactivated.advancedConfig" /></p>
        </AccountPermissionBoundary>
      </div>
      }
    </div>
  )
}

export default (
  requiresPacy({needStats: true})(
    connect(makeMapStateToProps)(
      withRouter(PermissionWrapper as any) as any
    )
  )
)

