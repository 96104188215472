export default class ReportTableTemplateContexts{

  public static ACCOUNT  = 'ACCOUNT'
  public static AD       = 'AD'
  public static BUDGET   = 'BUDGET'
  public static CAMPAIGN = 'CAMPAIGN'
  public static KEYWORD  = 'KEYWORD'

  public static CONTEXTS = [
    ReportTableTemplateContexts.ACCOUNT,
    ReportTableTemplateContexts.AD,
    ReportTableTemplateContexts.BUDGET,
    ReportTableTemplateContexts.CAMPAIGN,
    ReportTableTemplateContexts.KEYWORD,
  ]
}
