import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"
import Period         from "../../../model/Period"
import moment         from "moment"

const setRangeByDay = (state:BudgetsRetrieveRange, action):BudgetsRetrieveRange => {
  if(!state.byDay[action.accountId]){
    return {
      ...state,
      byDay : {
        ...state.byDay,
        [action.accountId] : action.period
      }
    }
  }
  const currentPeriod = state.byDay[action.accountId]
  const updatedPeriod:Period = {
    dateFrom : moment(currentPeriod.dateFrom).startOf("day") < moment(action.period.dateFrom).startOf("day") ? currentPeriod.dateFrom : action.period.dateFrom,
    dateTo   : moment(currentPeriod.dateTo).startOf("day")   > moment(action.period.dateTo).startOf("day")   ? currentPeriod.dateTo   : action.period.dateTo
  }
  return {
    ...state,
    byDay : {
      ...state.byDay,
      [action.accountId] : updatedPeriod
    }
  }
}

interface BudgetsRetrieveRange{
  byDay : RetrievedRangeByAccount
}
interface RetrievedRangeByAccount{
  [accountId:number] : Period
}
const defaultValue = {byDay: {}}
const Budgets = ReducerUtils.createReducer<BudgetsRetrieveRange>(defaultValue,{
  [Actions.RETRIEVE_BUDGETS_STATS_BY_DAY] : setRangeByDay
})

export default Budgets
