import * as React     from "react"
import {PacyAccount}  from "../../model/pacy/"
import PacyPlanner    from "./pacy/planner"
import BudgetPlanner1 from "./budgetPlanner1/BudgetPlanner"
import PacyLoader     from "../../components/loaders/pacyLoader"
import Loading        from "../../components/loading/"

interface BudgetOwnProps{
}
interface BudgetProps extends BudgetOwnProps{
  account     : PacyAccount
  loadingPacy : boolean
}

const Budget = (props:BudgetProps) => {
  if(props.loadingPacy){return <Loading translateId="loadings.budgetPlanner"/>}
  if(!props.account){return <BudgetPlanner1/>} // Old Budget Planner, should be shown if Pacy account is not migrated/created yet
  return <PacyPlanner/>
}

export default PacyLoader()(Budget) as React.ComponentType<BudgetOwnProps>
