import './index.css'
import * as React        from "react"
import {connect}         from "react-redux"
import {createSelector}  from "reselect"
import ArrayUtils        from "../../../../utils/ArrayUtils"
import Statistics        from "../../../../storeAccessor/Statistics"
import FiltersMenu       from "./StrategyFilters"
import Map               from "./MapSpend"
import SpendInfo         from "./SpendInfo"
import ExplainedLoading  from "../../../../components/loading"


interface MapSpendIndexProps{
  availableStrategies : any[]
  budgetStats : any[]
}
interface MapSpendIndexState{
  selected : SelectedStrategy[]
}
interface SelectedStrategy{
  name : string
  looseName : string
  translationId : string
}
const BUDGET_NAME_TO_TRANSLATION_ID = {
  ["Automated - New"]      : "new",
  ["Automated - Used"]     : "used",
  ["Automated - Conquest"] : "conquest",
  ["Automated - Branding"] : "branding",
  ["Automated - Service"]  : "service",
  ["Automated - Credit"]   : "credit",
}
const BUDGET_NAME_TO_LOOSE_STRATEGIES = {
  ["Automated - New"]      : "NEW",
  ["Automated - Used"]     : "USED",
  ["Automated - Conquest"] : "CONQUEST",
  ["Automated - Branding"] : "BRANDING",
  ["Automated - Service"]  : "SERVICE",
  ["Automated - Credit"]   : "CREDIT",
}
const LOOSE_STRATEGIES_TO_BUDGET_NAME = {
  "NEW"      : "Automated - New",
  "USED"     : "Automated - Used",
  "CONQUEST" : "Automated - Conquest",
  "BRANDING" : "Automated - Branding",
  "SERVICE"  : "Automated - Service",
  "CREDIT"   : "Automated - Credit",
}
const makeMapStateToProps = () => {
  const budgetStatsSelector = Statistics.makeBudgetsStatsInCurrentPeriodSelector()
  const availableStrategiesSelector = createSelector(
    budgetStatsSelector,
    (budgetStats)=>{
      if(budgetStats === undefined){return []}
      return ArrayUtils.unique(
        budgetStats
        .filter(x=>x.Spent) // we do not care about budgets without spending
        .map(x=>x.BudgetName)
      )
      .map(x=>({
        name : x,
        looseName : BUDGET_NAME_TO_LOOSE_STRATEGIES[x],
        translationId : BUDGET_NAME_TO_TRANSLATION_ID[x]
      }))
      .filter(x=>x.translationId!==undefined)
    }
  )
  return (state, ownProps)=>{
    return {
      budgetStats         : budgetStatsSelector(state),
      availableStrategies : availableStrategiesSelector(state)
    }
  }
}
const mapDispatchToProps = (dispatch)=>{return {}}
const mergeProps = (SP,DP,ownProps)=>{return {...SP,...DP,...ownProps}}

class MapSpendIndex extends React.Component<MapSpendIndexProps,MapSpendIndexState>{
  constructor(props){
    super(props)
    this.state = {
      selected : this.props.availableStrategies
    }
    this.toggleStrategy = this.toggleStrategy.bind(this)
  }
  componentDidUpdate(prevProps){
    if(prevProps.availableStrategies.length != this.props.availableStrategies.length){
      this.setState({
        selected : this.props.availableStrategies
      })
    }
  }
  toggleStrategy(strategy:SelectedStrategy){
    this.setState(prevState=>{
      const found = prevState.selected.find(x=>x.name===strategy.name)
      if(found){
        return {selected : prevState.selected.filter(x=>x.name!==strategy.name)}
      }
      return {
        selected : [...prevState.selected, strategy]
      }
    })
  }
  render(){
    if(this.props.budgetStats===undefined || this.props.budgetStats.length===0){
      return <ExplainedLoading translateId="loadings.budgetsStats"/>
    }
    return(
      <div className="MapSpendIndex">
        <Map strategiesLooseName={this.state.selected.map(x=>x.looseName)}/>
        { this.state.selected.length === 0
          ? <div>Select a strategy to begin</div>
          : <SpendInfo
              strategies={this.state.selected}
              budgetNames={this.state.selected.map(x=>x.name)}
              budgetStats={this.props.budgetStats}
            />
        }
        <FiltersMenu
          strategies={this.props.availableStrategies}
          selected={this.state.selected}
          toggleStrategy={this.toggleStrategy}
        />
      </div>
    )
  }
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(MapSpendIndex)
