import "./PlaceSuggestion.css"
import * as React        from  "react"
import {Translate}       from  "react-localize-redux"
import Flag              from  "react-country-flag"
import {
  TableRow,
  TableCell,
  Button
}                        from "@material-ui/core"

interface PlaceSuggestionProps{
  suggestion : any
  onClick : (suggestion, include:boolean)=>any
  alreadySelected ?: boolean
}
const replaceFunc = (match) => match[1].toUpperCase()
const translateType = (type:string) => {
  const formattedType = type.toLowerCase().replace(/\s./, replaceFunc)
  return <Translate id={"geoTarget.placesTypes."+formattedType}/>
}
export const PlaceSuggestion = (props:PlaceSuggestionProps) => {
  return(
    <TableRow className={"PlaceSuggestion"+(props.alreadySelected?" selected":"")}>
      <TableCell>
        <Flag
          code={props.suggestion[3] }
          svg
          alt={props.suggestion[3]}
        />
      </TableCell>
      <TableCell><strong>{props.suggestion[1]}</strong></TableCell>
      <TableCell><small>{""+props.suggestion[2].split(",").join(", ")}</small></TableCell>
      <TableCell><small>{translateType(props.suggestion[4])}</small></TableCell>
      <TableCell>
        {
          props.alreadySelected
          ? <span>(<Translate id="geoTarget.selected"/>)</span>
          : <div className={"actions"}>
              <Button color="primary"  onClick={()=>props.onClick(props.suggestion, true)}>
                <Translate id="geoTarget.include"/>
              </Button>
              <Button color="secondary" onClick={()=>props.onClick(props.suggestion, false)}>
                <Translate id="geoTarget.exclude"/>
              </Button>
            </div>
        }
      </TableCell>
    </TableRow>
  )
}
