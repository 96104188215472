import * as React              from "react"
import {connect}               from "react-redux"
import {retrieveCompanies}     from "../../actions/Waze"
import ExplainedLoading        from "../loading"
import ErrorBoundary           from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  return {
    companies  : state.Waze.Companies.list,
    isFetching : state.Waze.Companies.isFetching,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadCompanies : ()=>dispatch(retrieveCompanies())
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
const WazeCompaniesLoader = props => {
  if(props.companies === undefined){
    if(props.isFetching===false){props.loadCompanies()}
    return <ExplainedLoading translateId="loadings.wazeCompanies"/>
  }
  return <ErrorBoundary>{props.render(props.companies)}</ErrorBoundary>
}
const WazeCompaniesLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(WazeCompaniesLoader)
const requiresWazeCompanies = Component => props => (
  <WazeCompaniesLoaderConnected
    render={ companies => <Component companies={companies} {...props} /> }
  />
)
export default requiresWazeCompanies
