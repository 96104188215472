import {combineReducers}            from 'redux'
import List                         from "./List"
import ById                         from "./ById"

const CampaignsBaseInfo = combineReducers({
  List,
  ById
})

export default CampaignsBaseInfo
