import IDBAPI                           from '../../utils/IDBAPI'
import Actions                          from "../../model/constant/actions"
import {getToken}                       from "../../storeAccessor/Generic"
import { DefaultReportViewPreferences } from '../../model/report/DefaultReportViewPreferences'


export const retrieveUserPreferences = () => (dispatch, getState) => {
  dispatch({type:Actions.RETRIEVING_USER_PREFERENCES})
  IDBAPI.get({
      token  : getToken(getState()),
      action : "GetUserPreferences",
      simple : true
    },
    response =>{
      dispatch({
        type        : Actions.RETRIEVE_USER_PREFERENCES,
        userId      : response.data.userId,
        preferences : response.data.preferences,
      })},
    error =>{console.log(error)}
  )
}

export const retrieveTargetUserPreferences = (targetUserId: number) => (dispatch, getState) => {
  dispatch({
    type:Actions.RETRIEVING_TARGET_USER_PREFERENCES,
    status: true
  })
  IDBAPI.get({
      token        : getToken(getState()),
      action       : "GetTargetUserPreferences",
      targetUserId : targetUserId,
      simple       : true
    },
    response =>{
      dispatch({
        type        : Actions.RETRIEVE_TARGET_USER_PREFERENCES,
        userId      : response.data.userId,
        preferences : response.data.preferences,
      })
      dispatch({
        type:Actions.RETRIEVING_TARGET_USER_PREFERENCES,
        status: false
      })
    },
    error =>{
      dispatch({type:Actions.RETRIEVING_TARGET_USER_PREFERENCES, status: false})
    }
  )
}
export const setUserLanguage = (userId:number, language:string, retrieveAfterUpload:boolean=false) => (dispatch, getState) => {
  dispatch({
    type: Actions.SETTING_USER_LANGUAGE,
    status: true
  })
  IDBAPI.post({
      token          : getToken(getState()),
      action         : "SetUserPreference",
      userId         : userId,
      preferenceName : "language",
      value          : language
    },
    response => {
      if(retrieveAfterUpload){dispatch(retrieveUserPreferences())}
    },
    error =>console.log(error)
  )
  .then(() => {
    dispatch({
      type: Actions.SETTING_USER_LANGUAGE,
      status: false,
    })
  })
}
export const setDashboardKPIs = (userId:number, kpis:string[], retrieveAfterUpload:boolean=false) => (dispatch, getState) => {
  if(kpis.length !== 6){throw Error("Dashboard kpis selection must be of length 6")}
  IDBAPI.post({
      token          : getToken(getState()),
      action         : "SetUserPreference",
      userId         : userId,
      preferenceName : "dashboardkpis",
      value          : kpis.join(",")
    },
    response => {
      if(retrieveAfterUpload){dispatch(retrieveUserPreferences())}
    },
    error => console.log(error)
  )
}
export const selectDefaultTemplate = (userId:number, preferenceName: DefaultReportViewPreferences, templateId: string, retrieveAfterUpload:boolean=true) => (dispatch, getState) => {
  IDBAPI.post({
      token          : getToken(getState()),
      action         : "SetUserPreference",
      userId         : userId,
      preferenceName : preferenceName,
      value          : templateId
    },
    response => {
      if(retrieveAfterUpload){dispatch(retrieveUserPreferences())}
    },
    error => console.log(error)
  )
}
export const setUserWhiteLabel = (userId:number, whiteLabel: string) => (dispatch, getState) => {

  IDBAPI.post({
      token          : getToken(getState()),
      action         : "SetUserPreference",
      userId         : userId,
      preferenceName : "whitelabel",
      value          : whiteLabel
    },
    response => {},
    error => console.log(error)
  )
}
