import Vehicle from "./Vehicle"

export default class Car extends Vehicle{

  private bodyStyle     : string
  private interiorColor : string
  private drive         : string
  private transmission  : string
  private trim          : string
  private kilometers    : number
  private stockNumber   : number
  private vin           : string
  private displayMakeModel : string

  constructor(id:number,make:string,model:string,year:number,url:string,body:string){
    super(id,make,model,year,url)
    this.bodyStyle = body
  }

  public getBodyStyle()     : string { return this.bodyStyle     }
  public getInteriorColor() : string { return this.interiorColor }
  public getDrive()         : string { return this.drive         }
  public getTransmission()  : string { return this.transmission  }
  public getTrim()          : string { return this.trim          }
  public getKilometers()    : number { return this.kilometers    }
  public getStockNumber()   : number { return this.stockNumber   }
  public getVIN()           : string { return this.vin           }
  public getDisplayMakeModel() : string { return this.displayMakeModel }
  public getMostRepresentativeId() : string {
    if(this.stockNumber){return this.stockNumber.toString()}
    if(this.vin){return this.vin}
    return super.getMostRepresentativeId()
  }
  public withBodyStyle(bodyStyle:string){
    this.bodyStyle = bodyStyle
    return this
  }
  public withInteriorColor(interiorColor:string){
    this.interiorColor = interiorColor
    return this
  }
  public withDrive(drive:string){
    this.drive = drive
    return this
  }
  public withTransmission(transmission:string){
    this.transmission = transmission
    return this
  }
  public withTrim(trim:string){
    this.trim = trim
    return this
  }
  public withKilometers(kilometers:number){
    this.kilometers = kilometers
    return this
  }
  public withStockNumber(stockNumber:number){
    this.stockNumber = stockNumber
    return this
  }
  public withVIN(vin:string){
    this.vin = vin
    return this
  }
  public withDisplayMakeModel(displayMakeModel:string){
    this.displayMakeModel = displayMakeModel
    return this
  }
}

