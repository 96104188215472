import axios                            from "axios"
import Actions                          from "../../model/constant/actions"
import {getToken}                       from "../../storeAccessor/Generic"
import Headstone                        from "../../utils/Headstone";

const getTestResultSuccess = (response) => {
  if(response.error){return onError(response.error)}
  return {
    type : Actions.RETRIEVE_GRAMMAR_BOOST_TEST,
    result : response.data
  }
}
const onError = (error)=>({
  type : Actions.STORE_GRAMMAR_BOOST_ERROR,
  error : error
})
const runTest = (lang:string, test:string) => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_GRAMMAR_BOOST_TEST,
    status : true
  })
  axios.get(Headstone.getApiUrl() + `/1048/GrammarBooster/RunThrough/${lang}`, {
    params  : {string: test},
    headers : {
      Authorization : "Bearer "+getToken(getState())
    },
  })
  .then(data=>{
    dispatch(getTestResultSuccess(data))
  })
  .catch(error=>{
    dispatch(onError(String(error)))
  })
  .then(()=>{
    dispatch({
      type   : Actions.RETRIEVING_GRAMMAR_BOOST_TEST,
      status : false
    })
  })
}
export default runTest
