export enum DetailsOptions {
  impressions    = "impressions",
  clicks         = "clicks",
  cpc            = "cpc",
  cost           = "cost",
  impShare       = "impShare",
  clickShare     = "clickShare",
  conversions    = "conversions",
  ctr            = "ctr",
  conversionrate = "conversionrate",
  cpcon          = "cpcon",
}
