import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const storeUserPreferences = (state,action) => {
  return {
    ...state,
    [action.userId]: action.preferences
  }
}

interface PreferencesList {}

const defaultState = {}

const PreferencesList = ReducerUtils.createReducer<PreferencesList>(defaultState,{
  [Actions.RETRIEVE_TARGET_USER_PREFERENCES] : storeUserPreferences,
})

export default PreferencesList
