import Actions from "../../../model/constant/actions"
import {Feed} from "../../../model/feed/Feed"
import ReducerUtils from "../../../utils/ReducerUtils"

interface BingGeoMapping{
  [bingCriteriaId:number] : number
}

export default ReducerUtils.createReducer<BingGeoMapping>({},{
    [Actions.RETRIEVE_BING_GEO_MAPPING] : (state, action)=>{
      return {
        ...state,
        ...action.bingGeoMapping
      }
    }
})
