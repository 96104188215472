import "./AddGuidelineList.css"
import * as React from "react"
import { ProxyGroup } from "../../../../../model/pacy/ProxyGroup"
import { withLocalize, TranslateFunction, Translate } from "react-localize-redux"
import {
  AddGuidelineDecision,
  BaseDecision,
  DecisionType,
  UpdatePacingRatioConfigDecision,
  PacyEnablementDecision
}                    from "../../../../../model/pacy/Decision"
import { Guideline } from "../../../../../model/pacy/Guideline"
import { StringMap } from "../../../../../model/generics"
import ArrayUtils from "../../../../../utils/ArrayUtils"
import AddGuideline from "../AddGuideline/AddGuideline"
import UpdatePacingRatio from "../UpdatePacingRatio/UpdatePacingRatio"
import PacyEnablement from "../PacyEnablement/PacyEnablement"
import AddGuidelineListFilters from "./AddGuidelineListFilters/AddGuidelineListFilters"
import {Row} from "./AddGuidelineListFilters/FiltersCheckboxesList/FilterCheckboxesList"
import { Card, Tooltip, } from "@material-ui/core"
import Actions from "../../../../../model/constant/actions"
import { connect } from "react-redux"
import StickyTop from "../../../../../components/positioning/StickyTop"
import RoutingUtils from "../../../../../utils/RoutingUtils"
import { withRouter } from "react-router"
import { Panel } from "../../../../../components/Panel/Panel"
import GoBack from "../../../../../components/Button/ButtonGoBack"

interface AddGuidelineListOwnProps {
  proxyGroups: ProxyGroup[]
  decisions: BaseDecision[]
  wantedGuidelineIdsToProxyGroup: StringMap<ProxyGroup>
}
interface AddGuidelineListProps extends AddGuidelineListOwnProps {
  dates: string[]
  addDate: (date: string) => void
  removeDate: (date: string) => void
  clearDates: () => void
  filterUsers: number[]
  addUser: (userId: number) => void
  removeUser: (userId: number) => void
  clearUsers: () => void
  filterProxyGroups: string[]
  addProxyGroup: (proxyGroupId: string) => void
  removeProxyGroup: (proxyGroupId: string) => void
  clearProxyGroups: () => void
  filterDecisionTypes: DecisionType[]
  addDecisionType: (decisionType: DecisionType) => void
  removeDecisionType: (decisionType: DecisionType) => void
  clearDecisionTypes: () => void
  users: { [userId: string]: string }
  translate: TranslateFunction
  history
}

//REDUX FUNCTIONS START
const mapStateToProps = (state, ownProps: AddGuidelineListOwnProps) => {
  return {
    dates: state.Pacy.ChangeHistoryFilters.Dates,
    filterUsers: state.Pacy.ChangeHistoryFilters.Users,
    filterProxyGroups: state.Pacy.ChangeHistoryFilters.ProxyGroups,
    filterDecisionTypes: state.Pacy.ChangeHistoryFilters.DecisionTypes,
    users: state.Pacy.Users[state.Accounts.selected],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addDate: (date: string) => {
      dispatch({
        type: Actions.ADD_DATE,
        date: date
      })
    },
    removeDate: (date: string) => {
      dispatch({
        type: Actions.REMOVE_DATE,
        date: date
      })
    },
    clearDates: () => {
      dispatch({
        type: Actions.CLEAR_DATES
      })
    },
    addUser: (userId: number) => {
      dispatch({
        type: Actions.ADD_USER,
        userId: userId
      })
    },
    removeUser: (userId: number) => {
      dispatch({
        type: Actions.REMOVE_USER,
        userId: userId
      })
    },
    clearUsers: () => {
      dispatch({
        type: Actions.CLEAR_USERS
      })
    },
    addProxyGroup: (proxyyGroupId: string) => {
      dispatch({
        type: Actions.ADD_PROXYGROUP,
        proxyGroupId: proxyyGroupId
      })
    },
    removeProxyGroup: (proxyyGroupId: string) => {
      dispatch({
        type: Actions.REMOVE_PROXYGROUP,
        proxyGroupId: proxyyGroupId
      })
    },
    clearProxyGroups: () => {
      dispatch({
        type: Actions.CLEAR_PROXYGROUP
      })
    },
    addDecisionType: (decisionType:DecisionType) => {
      dispatch({
        type: Actions.ADD_DECISION_TYPE, decisionType: decisionType
      })
    },
    removeDecisionType: (decisionType:DecisionType) => {
      dispatch({
        type: Actions.REMOVE_DECISION_TYPE, decisionType: decisionType
      })
    },
    clearDecisionTypes: () => {
      dispatch({
        type: Actions.CLEAR_DECISION_TYPE
      })
    },
  }
}
const mergeProps = (SP, DP, ownProps) => {
  return { ...SP, ...DP, ...ownProps }
}
//UTILS FUNCTIONS
const getGuidelineMonth = (guideline: Guideline): string => guideline.year + "/" + guideline.month
const getCreatedOnMonth = (date: string): string => date.split("-")[0] + "/" + date.split("-")[1]
const formatDate = (dateString: string): () => React.ReactNode => {
  return () => <div><Translate id={`common.months.${dateString.slice(5)}`}></Translate> {dateString.slice(0, 4)}</div>
}
const getChangeTypeName = (decisionType: DecisionType): () => React.ReactNode => {
  let translationId = ""
  switch (decisionType) {
    case DecisionType.ADD_GUIDELINE:
      translationId = "pacyChangesHistory.changeType.guideline"
      break
    case DecisionType.UPDATE_PACING_RATIO_CONFIG:
      translationId = "pacyChangesHistory.changeType.pacingRatio"
      break
    case DecisionType.PACY_ENABLEMENT:
      translationId = "pacyChangesHistory.changeType.enablement"
      break
  }
  return () => <div><Translate id={translationId}></Translate></div>
}
//FILTERS FUNCTIONS
const filterDecisionsByUsers = (decisions: BaseDecision[], userIds: number[]): BaseDecision[] => {
  if (userIds.length === 0) return decisions
  return decisions.filter((x) => {
    if (x.type===DecisionType.ADD_GUIDELINE) {
      return userIds.indexOf((x as AddGuidelineDecision).details.guideline.createdBy) > -1
    } else {
      return userIds.indexOf((x as UpdatePacingRatioConfigDecision | PacyEnablementDecision).details.userId) > -1
    }
  })
}
const filterDecisionsByDates = (decisions: BaseDecision[], dates: string[]): BaseDecision[] => {
  if (dates.length === 0) return decisions
  return decisions.filter((x) => {
    if (x.type===DecisionType.ADD_GUIDELINE) {
      return dates.indexOf(getGuidelineMonth((x as AddGuidelineDecision).details.guideline)) > -1
    } else {
      return dates.indexOf(getCreatedOnMonth(x.createdOn)) > -1
    }
  })
}
const filterDecisionsByProxyGroups = (decisions: BaseDecision[],
  proxyGroupIds: string[],
  wantedGuidelineIdsToProxyGroup: StringMap<ProxyGroup>) => {
  if (proxyGroupIds.length === 0) return decisions
  return decisions.filter((decision) => {
    if (decision.type===DecisionType.ADD_GUIDELINE) {
      return proxyGroupIds.indexOf(wantedGuidelineIdsToProxyGroup[(decision as AddGuidelineDecision).details.guideline.id].id) > -1
    } else {
      return false
    }
  })
}
const filterDecisionsByTypes = (decisions: BaseDecision[], decisionTypes: DecisionType[]): BaseDecision[] => {
  if (decisionTypes.length === 0) return decisions
  return decisions.filter(x => decisionTypes.indexOf(x.type) > -1)
}

//CHECKBOXES LIST GENERATORS
const generateDates = (decisions: BaseDecision[], selectedDecisionTypes: DecisionType[], selectedDates: string[], selectedUsers: number[],
  selectedProxyGroups: string[], wantedGuidelineIdsToProxyGroup: StringMap<ProxyGroup>): Row[] => {
  let filteredDecisions = filterDecisionsByTypes(decisions, selectedDecisionTypes)
  filteredDecisions = filterDecisionsByUsers(filteredDecisions, selectedUsers)
  filteredDecisions = filterDecisionsByProxyGroups(filteredDecisions, selectedProxyGroups, wantedGuidelineIdsToProxyGroup)
  const availableDates: Row[] = ArrayUtils.unique(
    filteredDecisions.map((x) => {
      if (x.type===DecisionType.ADD_GUIDELINE) {
        return getGuidelineMonth((x as AddGuidelineDecision).details.guideline)
      } else {
        return getCreatedOnMonth(x.createdOn)
      }
    })
  )
    .map(date => ({
      name: formatDate(date),
      id: date,
      checked: selectedDates.indexOf(date) > -1,
    }))
  //Aways Display Selected Checkboxes
  selectedDates.forEach(selectedDateId => {
    if (!availableDates.find(dateObj => dateObj.id === selectedDateId)) {
      const dateObjToPush = { name: formatDate(selectedDateId), id: selectedDateId, checked: true }
      availableDates.push(dateObjToPush)
    }
  })
  return availableDates.sort((a, b) => {
    if (a.id > b.id) return -1
    if (a.id < b.id) return 1
    return 0
  })
}
const generateUsers = (
  decisions: BaseDecision[],
  selectedDecisionTypes: DecisionType[],
  selectedUsers: number[],
  users: { [userId: string]: string },
  selectedDates: string[],
  selectedProxyGroups: string[],
  wantedGuidelineIdsToProxyGroup: StringMap<ProxyGroup>
): Row[] => {
  let filteredDecisions = filterDecisionsByTypes(decisions, selectedDecisionTypes)
  filteredDecisions = filterDecisionsByDates(filteredDecisions, selectedDates)
  filteredDecisions = filterDecisionsByProxyGroups(filteredDecisions, selectedProxyGroups, wantedGuidelineIdsToProxyGroup)
  const availableUsers: Row[] = ArrayUtils.unique(
    filteredDecisions.map((x) => {
      if (x.type===DecisionType.ADD_GUIDELINE) {
        return (x as AddGuidelineDecision).details.guideline.createdBy
      } else {
        return (x as UpdatePacingRatioConfigDecision | PacyEnablementDecision).details.userId
      }
    })
  )
    .sort()
    .map(userId => ({
      name: () => <Tooltip classes={{ tooltip: "TooltipFont" }}
        title={users[userId]}>
        <div className="UserEmail">{users[userId]}</div>
      </Tooltip>,
      id: userId,
      checked: selectedUsers.indexOf(userId) > -1,
      tooltip: true
    }))
  //Aways Display Selected Checkboxes
  selectedUsers.forEach(selectedUserId => {
    if (!availableUsers.find(userObj => userObj.id === selectedUserId)) {
      const userObjToPush = {
        name: () => <Tooltip classes={{ tooltip: "TooltipFont" }} title={users[selectedUserId]}>
          <div className="UserEmail">{users[selectedUserId]}</div>
        </Tooltip>,
        id: selectedUserId,
        checked: true,
        tooltip: true
      }
      availableUsers.push(userObjToPush)
    }
  })
  return availableUsers.sort((a, b) => {
    if (a.id < b.id) return -1
    if (a.id > b.id) return 1
    return 0
  })
}

const generateProxyGroups = (decisions: BaseDecision[], selectedDecisionTypes: DecisionType[], selectedProxyGroups: string[],
  wantedGuidelineIdsToProxyGroup: StringMap<ProxyGroup>, selectedUsers: number[], selectedDates: string[], proxyGroups: ProxyGroup[]): Row[] => {
  let filteredDecisions = filterDecisionsByTypes(decisions, selectedDecisionTypes)
  filteredDecisions = filterDecisionsByTypes(filteredDecisions, [DecisionType.ADD_GUIDELINE])
  filteredDecisions = filterDecisionsByDates(filteredDecisions, selectedDates)
  filteredDecisions = filterDecisionsByUsers(filteredDecisions, selectedUsers)
  const availableProxyGroups = ArrayUtils.uniqueFunc(
    filteredDecisions.map(decision => {
      return {
        name: () => <Tooltip classes={{ tooltip: "TooltipFont" }} title={wantedGuidelineIdsToProxyGroup[decision.details.guideline.id].name}>
          <div className="BudgetName">{wantedGuidelineIdsToProxyGroup[decision.details.guideline.id].name}</div>
        </Tooltip>,
        id: wantedGuidelineIdsToProxyGroup[decision.details.guideline.id].id,
        checked: selectedProxyGroups.indexOf(wantedGuidelineIdsToProxyGroup[decision.details.guideline.id].id) > -1,
        tooltip: true
      }
    }),
    x => x.id
  )
  //Aways Display Selected Checkboxes
  selectedProxyGroups.forEach(selectedProxyGroupId => {
    if (!availableProxyGroups.find(proxyGroupObj => proxyGroupObj.id === selectedProxyGroupId)) {
      const proxyGroupName = proxyGroups.find(proxyGroup => proxyGroup.id === selectedProxyGroupId).name
      const proxyGroupObjToPush = {
        name: () => <Tooltip classes={{ tooltip: "TooltipFont" }} title={proxyGroupName}>
          <div className="BudgetName">{proxyGroupName}</div>
        </Tooltip>,
        id: selectedProxyGroupId,
        checked: true,
        tooltip: true
      }
      availableProxyGroups.push(proxyGroupObjToPush)
    }
  })
  return availableProxyGroups.sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
}
const generateDecisionTypes = (decisions: BaseDecision[], selectedDecisionTypes: DecisionType[], selectedDates: string[], selectedUsers: number[],
  selectedProxyGroups: string[], wantedGuidelineIdsToProxyGroup: StringMap<ProxyGroup>, translate: TranslateFunction): Row[] => {
  let filteredDecisions = filterDecisionsByDates(decisions, selectedDates)
  filteredDecisions = filterDecisionsByUsers(filteredDecisions, selectedUsers)
  filteredDecisions = filterDecisionsByProxyGroups(filteredDecisions, selectedProxyGroups, wantedGuidelineIdsToProxyGroup)
  const availableDecisionTypes: Row[] = ArrayUtils.unique(
    filteredDecisions.map(x => x.type)
  )
    .map(type => ({
      name: getChangeTypeName(type),
      id: type,
      checked: selectedDecisionTypes.indexOf(type) > -1,
    }))
  //Aways Display Selected Checkboxes
  selectedDecisionTypes.forEach(selectedDecisionTypeId => {
    if (!availableDecisionTypes.find(dateObj => dateObj.id === selectedDecisionTypeId)) {
      const decisionTypeObjToPush = { name: selectedDecisionTypeId, id: selectedDecisionTypeId, checked: true }
      availableDecisionTypes.push(decisionTypeObjToPush)
    }
  })
  return availableDecisionTypes.sort((a, b) => {
    if (a.id > b.id) return -1
    if (a.id < b.id) return 1
    return 0
  })
}

const AddGuidelineList = (props: AddGuidelineListProps) => {
  //CheckboxToogle Handlers
  const handleToggleUsers = (event) => {
    const userId = parseInt(event.target.id, 10)
    if (props.filterUsers.find(id => id === userId)) { props.removeUser(userId) }
    else { props.addUser(userId) }
  }
  const handleToggleDates = (event) => {
    const dateId = event.target.id
    if (props.dates.find(id => id === dateId.toString())) { props.removeDate(dateId) }
    else { props.addDate(dateId) }
  }
  const handleToggleProxyGroups = (event) => {
    const proxyGroupId = event.target.id
    if (props.filterProxyGroups.find(id => id === proxyGroupId)) { props.removeProxyGroup(proxyGroupId) }
    else { props.addProxyGroup(proxyGroupId) }
  }
  const handleToggleDecisionTypes = (event) => {
    const decisionTypeId = event.target.id
    if (props.filterDecisionTypes.find(id => id === decisionTypeId)) { props.removeDecisionType(decisionTypeId) }
    else { props.addDecisionType(decisionTypeId) }
  }
  //Filter Decisions
  const filterDecisions = (users: number[], dates: string[], proxyGroups: string[]) => {
    return (
      filterDecisionsByProxyGroups(
        filterDecisionsByDates(
          filterDecisionsByUsers(
            filterDecisionsByTypes(
              props.decisions,
              props.filterDecisionTypes
            ),
            users
          ),
          dates
        ),
        props.filterProxyGroups,
        props.wantedGuidelineIdsToProxyGroup
      )
    )
  }

  const filteredDecisions = filterDecisions(props.filterUsers, props.dates, props.filterProxyGroups).sort((a, b) => {
    let dateA = ""
    let dateB = ""
    if (a.type===DecisionType.ADD_GUIDELINE) {
      dateA = (a as AddGuidelineDecision).details.guideline.createdOn
    } else {
      dateA = a.createdOn
    }
    if (b.type===DecisionType.ADD_GUIDELINE) {
      dateB = (b as AddGuidelineDecision).details.guideline.createdOn
    } else {
      dateB = b.createdOn
    }
    if (dateA > dateB) {
      return -1
    }
    if (dateA < dateB) {
      return 1
    }
    return 0
  })

  //Fetch the Available Users from Decisions
  const availableUsers = generateUsers(props.decisions, props.filterDecisionTypes, props.filterUsers, props.users, props.dates, props.filterProxyGroups, props.wantedGuidelineIdsToProxyGroup)
  //Fetch Available Dates from Decisions
  const availableDates = generateDates(props.decisions, props.filterDecisionTypes, props.dates, props.filterUsers, props.filterProxyGroups, props.wantedGuidelineIdsToProxyGroup)
  //Fetch Available ProxyGroups
  const availableProxyGroups = generateProxyGroups(props.decisions, props.filterDecisionTypes, props.filterProxyGroups, props.wantedGuidelineIdsToProxyGroup, props.filterUsers, props.dates, props.proxyGroups)
  //Fetch Available Decisions Types
  const availableDecisionTypes = generateDecisionTypes(props.decisions, props.filterDecisionTypes, props.dates, props.filterUsers, props.filterProxyGroups, props.wantedGuidelineIdsToProxyGroup, props.translate)

  return (
    <><Panel title={props.translate("pacyChangesHistory.changesHistory") as string} >
      <GoBack onClick={() => {
        RoutingUtils.replaceLastElementOfUrl(props.history, "Planner")
      }} />
    </Panel>
      <Card className="AddGuidelineList">
        <Card className="Interface">
          <div>
            <StickyTop top={10}>
              <Card className="Tools">
                <AddGuidelineListFilters
                  filterType="BUDGETS"
                  data={availableProxyGroups}
                  handleToggle={handleToggleProxyGroups}
                  handleClear={props.clearProxyGroups} />
                <AddGuidelineListFilters
                  filterType={(props.translate("common.users") as string).toUpperCase()}
                  data={availableUsers}
                  handleToggle={handleToggleUsers}
                  handleClear={props.clearUsers} />
                <AddGuidelineListFilters
                  filterType="DATES"
                  data={availableDates}
                  handleToggle={handleToggleDates}
                  handleClear={props.clearDates}
                />
                <AddGuidelineListFilters
                  filterType={(props.translate("pacyChangesHistory.changeType.title") as string).toUpperCase()}
                  data={availableDecisionTypes}
                  handleToggle={handleToggleDecisionTypes}
                  handleClear={props.clearDecisionTypes}
                />
              </Card>
            </StickyTop>
          </div>
          <Card className="Guidelines">
            {filteredDecisions.map(x => {
              switch (x.type) {
                case DecisionType.ADD_GUIDELINE:
                  return <AddGuideline
                    key={x.id}
                    decision={x as AddGuidelineDecision}
                    concernedProxyGroup={props.wantedGuidelineIdsToProxyGroup[x.details.guideline.id]}
                    users={props.users}
                  />
                case DecisionType.UPDATE_PACING_RATIO_CONFIG:
                  return <UpdatePacingRatio
                    key={x.id}
                    decision={x as UpdatePacingRatioConfigDecision}
                    decisions={filterDecisionsByTypes(filteredDecisions, [DecisionType.UPDATE_PACING_RATIO_CONFIG]) as UpdatePacingRatioConfigDecision[]}
                    users={props.users}
                  />
                case DecisionType.PACY_ENABLEMENT:
                  return <PacyEnablement
                    key={x.id}
                    decision={x as PacyEnablementDecision}
                    users={props.users}
                  />
              }
            })}
          </Card>
        </Card>
      </Card>
    </>
  )
}

export default (
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(
    withLocalize(
      withRouter(AddGuidelineList as any) as any
    )
  )
) as React.ComponentType<AddGuidelineListOwnProps>
