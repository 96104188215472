import SearchCampaign     from "./SearchCampaign"
import Friend             from "./Friend"

export default interface SimplifiedFriend{
  accountId : number
  name      : string
  campaigns : SearchCampaign[]
}

export const buildFromFriend = (friend:Friend):SimplifiedFriend => {
  return {
    accountId : friend.getAccountId(),
    name      : friend.getName(),
    campaigns : friend.getCampaigns()
  }
}
