import  './GuidelinesDiffFormat.css'
import * as React                     from 'react'
import NumFormatter                   from '../../../../../../utils/NumberFormatter'
import Icon                           from '@material-ui/core/Icon'
import classNames                     from 'classnames'
import { Chip }                       from '@material-ui/core'

interface GuidelinesDiffFormatProps {
  prevAmount ?: number
  amount      : number
}

const GuidelinesDiffFormat = (props:GuidelinesDiffFormatProps) => {
  const diffAmount = NumFormatter.formatCurrency(props.amount - props.prevAmount)
  const classes = classNames("GuidelinesDiffFormat", {
    Reduction    : props.amount < props.prevAmount,
    Augmentation : props.amount > props.prevAmount,
    Flat         : props.amount == props.prevAmount
  })
  return (
    <div className="GuidelinesDiffFormat GuidelinesDiffFormatContainer">
      <div className={classes}>
        <div className="Total">
          <Chip
            className={classes}
            variant="outlined"
            icon={<Icon className={classes}>swap_vert</Icon>}
            label={<p style={{fontWeight:'bold'}}>{props.amount > props.prevAmount ? '+ ' + diffAmount
            : props.prevAmount > props.amount ? '- ' + NumFormatter.formatCurrency(Math.abs(props.prevAmount-props.amount))
            : diffAmount}</p>}
          />
        </div>
        <div className="Details">
          <p>{NumFormatter.formatCurrency(props.prevAmount)}</p>
          <p>
            <Icon style={{marginTop:'5px'}}>{
              props.amount < props.prevAmount ? 'trending_down' :
                props.amount > props.prevAmount ? 'trending_up ' : 'trending_flat'
            }</Icon>
          </p>
          <p>{NumFormatter.formatCurrency(props.amount)}</p>
        </div>
      </div>
    </div>
  )
}

GuidelinesDiffFormat.defaultProps = {
  prevAmount : 0
}

export default GuidelinesDiffFormat
