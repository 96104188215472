import Actions                            from "../../model/constant/actions"
import ReducerUtils                       from "../../utils/ReducerUtils"
import ReportTableTemplate                from "../../model/Store/Report/ReportTableTemplate"

const addTemplate = (state, action) => {
  const newTemplate = action.template
  let replaced = false
  let newList = state.list.map(template=>{
    if(template.getId()===newTemplate.getId()){
      replaced = true
      return newTemplate
    }
    return template
  })
  if(!replaced){newList.push(newTemplate)}
  return {
    ...state,
    isFetched : true,
    list : newList
  }
}
const addTemplates = (state, action) => {
  let newState = {...state}
  for(let newTemplate of action.templates){
    newState = addTemplate(newState, {template:newTemplate})
  }
  return {...newState, isFetched : true}
}
const removeTemplate = (state, action) => {
  return {
    ...state,
    list : state.list.filter(x=>x.getId()!==action.templateId)
  }
}
interface Templates{
  list : ReportTableTemplate[]
  isFetched : boolean
}
const defaultState = {
  list : [],
  isFetched : false
}
export const Templates = ReducerUtils.createReducer<Templates>(defaultState,{
  [Actions.ADD_REPORT_TEMPLATE]    : addTemplate,
  [Actions.ADD_REPORT_TEMPLATES]   : addTemplates,
  [Actions.REMOVE_REPORT_TEMPLATE] : removeTemplate,
})
