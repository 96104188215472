import {State} from "../reducers/generic/reducerAssembly"

/*
  Most typing is commented out because it will require fixing errors. Right now I just want to fix a bug.
*/

export const getPeriod = (state/*:State*/) => state.Period
export const getAccountId = (state/*:State*/) => state.Accounts.selected
export const getCampaignsStats = (state/*:State*/) => state.Statistics.Google.Campaigns
export const getBingCampaignsStats = (state/*:State*/) => state.Statistics.Bing.Campaigns
export const getInventory = (state/*:State*/) => state.Inventory.list
export const getCampaigns = (state/*:State*/) => state.Campaigns.list
export const getCampaignsBaseInfoById = (state/*:State*/) => state.Campaigns.BaseInfo.Google.ById
export const getBingCampaignsBaseInfoById = (state/*:State*/) => state.Campaigns.BaseInfo.Bing.ById
export const getDisplayCampaigns = (state/*:State*/) => state.Campaigns.Display.Campaigns
export const getStatusExceptions = (state/*:State*/) => state.Campaigns.DesiredStatusExceptions
export const getAdCopies = (state/*:State*/) => state.Params.AdCopies.AdCopyPatterns
export const getTemplates = (state/*:State*/) => state.Reports.Templates.list
export const getImpressionsByDeviceStats = (state/*:State*/) => state.Statistics.Google.ImpressionsByDevice
export const getAccounts = (state/*:State*/) => state.Accounts.list
export const getGeoTargetings = (state:State) => state.Params.GeoBids.GeoTargetings
export const getGeoEditCopies = (state/*:State*/) => state.EditCopies.GeoBids
export const getGeocodingCache = (state/*:State*/) => state.Params.GeoBids.GeocodingCache
export const getGeocodingMetaCache = (state/*:State*/) => state.Params.GeoBids.GeocodingMetaCache
export const getAdWordsPlaces = (state/*:State*/) => state.Params.GeoBids.AdWordsPlaces
export const getKeywords = (state/*:State*/) => state.Params.Keywords.Patterns
export const getSearchTermsStats = (state/*:State*/) => state.Statistics.Google.SearchTerms
export const getBingSearchTermsStats = (state/*:State*/) => state.Statistics.Bing.SearchTerms
export const getKeywordsStats = (state/*:State*/) => state.Statistics.Google.Keywords
export const getBingKeywordsStats = (state/*:State*/) => state.Statistics.Bing.Keywords
export const getBudgetsStats = (state/*:State*/) => state.Statistics.Google.Budgets
export const getGeoBidsStats = (state/*:State*/) => state.Statistics.Google.GeoBids
export const getPlacementsStats = (state/*:State*/) => state.Statistics.Google.Placements
export const getFetchedShapeCodes = (state/*:State*/) => state.Params.GeoBids.Shape.FetchedShapeCodes
export const getToken = (state/*:State*/) => state.Login.userLogged.token
export const getRetrievingAdsStats = (state/*:State*/) => state.Statistics.Retrieving.Ads
export const getRetrievingBudgetsStats = (state/*:State*/) => state.Statistics.Retrieving.Budgets
export const getRetrievingCampaignsStats = (state/*:State*/) => state.Statistics.Retrieving.Campaigns
export const getRetrievingGeoMapping = (state/*:State*/) => state.Statistics.Retrieving.GeoMapping
export const getRetrievingGeoBidsStats = (state/*:State*/) => state.Statistics.Retrieving.GeoBids
export const getRetrievingKeywordsStats = (state/*:State*/) => state.Statistics.Retrieving.Keywords
export const getRetrievingSearchTermsStats = (state/*:State*/) => state.Statistics.Retrieving.SearchTerms
export const getRetrievingPlacementsStats = (state/*:State*/) => state.Statistics.Retrieving.Placements
export const getRetrievingImpressionsByDeviceStats = (state/*:State*/) => state.Statistics.Retrieving.ImpressionsByDevice
export const getBingBudgetsStats = (state/*:State*/) => state.Statistics.Bing.Budgets
export const getBingGeoBidsStats = (state/*:State*/) => state.Statistics.Bing.GeoBids
export const getBingGeoMapping = (state/*:State*/) => state.Statistics.Bing.GeoMapping
export const getEngagementRules = (state/*:State*/) => state.Params.EngagementRules.CurrentRules
export const getFacebookBudgetsStats = (state/*:State*/) => state.Statistics.Facebook.Budgets
export const getStrategies = (state/*:State*/) => state.Params.Strategies.list
export const getBiddingStrategySettings = (state/*:State*/) => state.Params.Strategies.strategiesSettingsList