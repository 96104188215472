import './AgentsTable.css'
import * as React                         from 'react'
import MaterialTable                      from 'material-table'
import {
  Proxy,
  AgentCompatibleProvider,
  PossibleAgent
}                                         from '../../../../model/pacy'
import NumFormatter                       from "../../../../utils/NumberFormatter"
import {
  Icon,
  Button,
  Switch,
  Modal,
  Card,
  Tooltip
}                                         from '@material-ui/core'
import MaxSharingSlider                   from './Components/MaxSharingSlider'
import AgentWeightPie                     from './Components/AgentWeightPie'
import {
  AccountModifier,
  createSetAgentMaxSharingAccountModifier,
  createToggleAgentAccountModifier
}                                         from '../../../../model/pacy/AccountModifiers'
import AgentMove                          from './AgentMove'
import {
  ProxyGroup,
  ProxyGroupsToAgentsTree
}                                         from '../../../../model/pacy/ProxyGroup'
import {
  TranslateFunction,
  withLocalize,
  Translate
}                                         from 'react-localize-redux'
import CreateAgent                        from "./Components/CreateAgent/"

interface AgentsTableOwnProps {
  addAccountModifiers : (newActions:AccountModifier[])=>void
  proxy               : Proxy
  proxyGroups         : ProxyGroup[]
  proxyGroupId        : string
  possibleAgents      : PossibleAgent[]
  activeProvidersAlertTree : ProxyGroupsToAgentsTree
}
interface AgentsTableProps extends AgentsTableOwnProps {
  translate : TranslateFunction
}

const injectButtonRows = <T extends any>(rowsPerPage:number, data:T[]):Array<T|{overrideWithAddButton:boolean}> => {
  const copy:Array<T|{overrideWithAddButton:boolean}> = [...data]
  const originalLength = copy.length
  const buttonRowsToAdd = originalLength/rowsPerPage<1?0:Math.ceil(originalLength/rowsPerPage)
  for (var i = 0; i<buttonRowsToAdd; i++){
    copy.splice(rowsPerPage-1+rowsPerPage*i, 0, {overrideWithAddButton: true})
  }
  copy.push({overrideWithAddButton: true})
  return copy
}

const AgentsTable = (props:AgentsTableProps) => {
  const [openModal,setOpenModal] = React.useState<boolean>(false)
  const [openCreateAgentModal,setOpenCreateAgentModal] = React.useState<boolean>(false)
  const [agentToMoveId,setAgentToMoveId] = React.useState<string>('')
  const [currentPage,setCurrentPage] = React.useState<number>(1)
  const [rowsPerPage,setRowsPerPage] = React.useState<number>(5)
  const handleOpenAgentMoveModal = (agentToMoveId:string) => {
    setOpenModal(true)
    setAgentToMoveId(agentToMoveId)
  }
  const handleCloseAgentMoveModal = () => {
    setOpenModal(false)
  }
  const handleSharingInput = (newValue:number,agentId:string) => {
    props.addAccountModifiers([createSetAgentMaxSharingAccountModifier(agentId,newValue/100,props.proxyGroupId,props.proxy.id)])
  }
  const handleSharingSlider = (event,newValue:number,agentId:string) => {
    props.addAccountModifiers([createSetAgentMaxSharingAccountModifier(agentId,newValue/100,props.proxyGroupId,props.proxy.id)])
  }
  const handleEnableAgent = (event,agentId:string) => {
    props.addAccountModifiers([createToggleAgentAccountModifier(agentId,event.target.checked,props.proxy.id,props.proxyGroupId)])
  }
  const totalAgentWeights = props.proxy.agents.reduce((acc,ag)=>{
    if(ag.active) {
      return acc + ag.weight
    }
    return acc
  },0)
  const data = injectButtonRows(rowsPerPage, props.proxy.agents.reduce((acc,agent,i)=>{
    acc.push({
      'startAngle' : i === 0 ? agent.weight/totalAgentWeights*360 : acc[i-1].startAngle+(agent.weight/totalAgentWeights*360),
      'id'         : agent.id,
      'active'     : agent.active,
      'maxSharing' : agent.maxSharing*100+"%",
      'name'       : agent.name || agent.provider + " - " + agent.providerId,
      'moveTo'     : 'moveTo',
      'weight'     : agent.weight,
      'totalWeight': totalAgentWeights,
      'provider'   : agent.provider // Not displayed in the table
    })
    return acc
  },[]))

  const totalPages = Math.ceil(data.length/rowsPerPage)

  const addIconToAgent = (agentProvider:AgentCompatibleProvider, agentName:string) => {
    if (agentProvider === AgentCompatibleProvider.AW) {
      return (
        <div className="NameContainer">
          <Tooltip title={props.translate('pacyConfigs.agents.google')}>
            <img className="AgentIcon" src="./assets/img/google_logo.svg"/>
          </Tooltip>
          <div className="AgentText">{agentName}</div>
        </div>
      )
    }
    if (agentProvider === AgentCompatibleProvider.BING) {
      return (
        <div className="NameContainer">
          <Tooltip title={props.translate('pacyConfigs.agents.bing')}>
            <img className="AgentIcon" src="./assets/img/bing.svg"/>
          </Tooltip>
          <div className="AgentText">{agentName}</div>
        </div>
      )
    }
    if (agentProvider === AgentCompatibleProvider.FACEBOOK) {
      return (
        <div className="NameContainer">
          <Tooltip title={props.translate('pacyConfigs.agents.facebook')}>
            <img className="AgentIcon" src="./assets/img/facebook_logo.svg"/>
          </Tooltip>
          <div className="AgentText">{agentName}</div>
        </div>
      )
    }
    return (
      <div className="NameContainer">
        <div className="AgentIcon" />&nbsp;
        <div className="AgentText">{agentName}</div>
      </div>
    )
  }

  return (
    <div className="AgentsTable">
      <MaterialTable
        localization={{
          toolbar : {
            searchPlaceholder : props.translate('pacyConfigs.search') as string
          },
          pagination : {
            previousTooltip : props.translate('common.previous') as string,
            nextTooltip : props.translate('common.next') as string,
            firstTooltip : props.translate('pacyConfigs.firstPage') as string,
            lastTooltip  : props.translate('pacyConfigs.lastPage') as string,
            labelRowsSelect : props.translate('pacyConfigs.rows') as string,
            labelDisplayedRows : `${NumFormatter.formatNumber(currentPage)} ${props.translate('pacyConfigs.on')} ${NumFormatter.formatNumber(totalPages)}`
          },
          body : {
            emptyDataSourceMessage : props.translate('pacyConfigs.emptyAgentsTable') as any
          }
        }}
        onChangePage={(page:number)=>setCurrentPage(page+1)}
        onChangeRowsPerPage={(pageSize:number)=>setRowsPerPage(pageSize)}
        style={{width:'100%'}}
        title={props.translate('pacyConfigs.sharedBudgets') as string}
        data={data}
        columns={[
          {
            field:'name',
            title:props.translate('pacyConfigs.name') as string,
            editable:'never',
            render : rowData => {
              if(rowData.overrideWithAddButton){
                return (
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={()=>setOpenCreateAgentModal(true)}
                    color="primary"
                  >
                    <Icon className="Icon">add</Icon>
                    <Translate id="pacyConfigs.createAgent" />
                  </Button>
                )
              }
              return (
                <div className="AlertAndNameContainer">
                  <div className="AgentAlert" style={props.activeProvidersAlertTree[props.proxyGroupId]?.[props.proxy.id]?.[rowData.id] ? {backgroundColor: '#d3450d'} : {}}></div>
                  {addIconToAgent(rowData.provider, rowData.name)}
                </div>
              )
            }
          },
          {
            field:'active',
            title:props.translate('pacyConfigs.status') as string,
            render : data => {
              if(data.overrideWithAddButton){
                return (<div/>)
              }
              return (
                <Switch onChange={(event)=>handleEnableAgent(event,data.id)} color="primary" checked={data.active}></Switch>
              )
            }
          },
          {
            field:'maxSharing',
            title:props.translate('pacyConfigs.unspentSharing') as string,
            render : data => {
              if(data.overrideWithAddButton){
                return (<div/>)
              }
              return (
                <MaxSharingSlider
                  handleSharingInputConfirm={(event)=>handleSharingInput(event,data.id)}
                  handleSharingConfirm={(event,newValue)=>handleSharingSlider(event,newValue,data.id)}
                  maxSharing={parseInt(data.maxSharing,10)/100}
                  onlyInput
                />
              )
            }
          },
          {
            field:'weight',
            title:props.translate('pacyConfigs.weight') as string,
            render : (data) => {
              if(data.overrideWithAddButton){
                return (<div/>)
              }
              return (
                <AgentWeightPie
                  active={data.active}
                  agentWeight={data.weight}
                  agentId={data.id}
                  width={40}
                  height={40}
                  agents={props.proxy.agents}
                  radius={15}
                  startAngle={data.startAngle}
                />
              )
            },
            editable:'never'
          },
          {
            editable:'never',
            field:'moveTo',
            title :props.translate('pacyConfigs.move') as string,
            render: (data, type) => {
              if(data.overrideWithAddButton){
                return (<div/>)
              }
              return (
                <Button onClick={()=>handleOpenAgentMoveModal(data.id)}>
                  <Icon
                    className="MoveIcon"
                  >
                    double_arrow
                  </Icon>
                </Button>
              )
            },
          },
          {field:'provider', hidden:true},
          {field:'overrideWithAddButton', hidden:true},
          {field:'id', hidden:true},
          {field:'startAngle', hidden:true},
        ]}
      />
      <Modal className="AgentMoveModal" open={openModal} onClose={handleCloseAgentMoveModal}>
        <Card>
          <AgentMove
            proxyGroups={props.proxyGroups}
            closeModal={handleCloseAgentMoveModal}
            agentToMove={props.proxy.agents.find(agent=>agent.id===agentToMoveId)}
            addAccountModifiers = {props.addAccountModifiers}
            proxyGroupId={props.proxyGroupId}
            proxyId={props.proxy.id}
          />
        </Card>
      </Modal>
      <Modal className="CreateAgentModal" open={openCreateAgentModal} onClose={()=>setOpenCreateAgentModal(false)}>
        <CreateAgent
          proxyGroups={props.proxyGroups}
          possibleAgents={props.possibleAgents}
          handleCloseModal={()=>setOpenCreateAgentModal(false)}
          addAccountModifiers={props.addAccountModifiers}
          selectedProxyId={props.proxy.id}
          selectedProxyGroupId={props.proxyGroupId}
        />
      </Modal>
    </div>
  )
}

export default withLocalize(AgentsTable as any) as React.ComponentType<AgentsTableOwnProps>
