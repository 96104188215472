import {createSelector}  from "reselect"
import * as Generic      from "./Generic"
import ArrayUtils        from "../utils/ArrayUtils"
import ObjectUtils       from "../utils/ObjectUtils"

export default class GeoBidAccessors {
  public static makeGeoMapInfoSelector(){
    return createSelector(
      (_,geoTargeting)=>geoTargeting,
      Generic.getGeoEditCopies,
      (geoTargeting, editCopies)=>{
        let geoMapInfo
        for(let map of editCopies){
          if(
            map.accountId === geoTargeting.accountId &&
            GeoBidAccessors.serializeMatcher(map.matcher) === GeoBidAccessors.serializeMatcher(geoTargeting.matcher)
          ){
            geoMapInfo = map
            break
          }
        }
        return geoMapInfo
      }
    )
  }
  public static makeGeoMapInfoPlacesToAddSelector(){
    return createSelector(
      (_,geoMapInfo)=>geoMapInfo,
      Generic.getGeocodingCache,
      Generic.getGeocodingMetaCache,
      Generic.getAdWordsPlaces,
      Generic.getFetchedShapeCodes,
      (geoMapInfo, cache, metaCache, AWPlaces, fetchedShapeCodes)=>{
        let initialPlacesToAdd = []
        let placesToAdd = []
        let placesNeedingShape = []
        if(geoMapInfo){
          const completePlaces = (places, pendingPlace)=>{
            let position
            if(cache[pendingPlace.code]){position = cache[pendingPlace.code]}
            else if(metaCache[pendingPlace.code]){position = metaCache[pendingPlace.code]}
            if(position){
              const address = AWPlaces.find(AWPlace=>AWPlace[0]==pendingPlace.code)[2].split(",").join(", ")
              places.push({
                id : -1,
                code : parseInt(pendingPlace.code),
                edited : false,
                include : pendingPlace.include,
                address : address,
                bidModifier : pendingPlace.bidModifier,
                placeProps : {
                  position : position,
                  editable : true
                }
              })
            }
            return places
          }
          initialPlacesToAdd = initialPlacesToAdd.concat(
            geoMapInfo.initialPlacesPending.reduce(completePlaces, [])
          )
          placesToAdd = placesToAdd.concat(
            geoMapInfo.placesPending.reduce(completePlaces, [])
          )
          placesNeedingShape = placesNeedingShape.concat([
            ...geoMapInfo.initialPlacesPending.filter(place=>!ArrayUtils.contain(fetchedShapeCodes, place.code)),
            ...geoMapInfo.placesPending.filter(place=>!ArrayUtils.contain(fetchedShapeCodes, place.code))
          ])
        }
        return {
          initialPlaces : initialPlacesToAdd,
          places : placesToAdd,
          placesNeedingShape : placesNeedingShape,
        }
      }
    )
  }
  private static serializeMatcher(matcher):string{
    if(matcher.vehicleType){
      return [
        matcher.group,
        matcher.lang,
        matcher.vehicleType,
        matcher.state,
      ].join("|")
    }
    return [
      matcher.group,
      matcher.lang,
    ].join("|")
  }
}
