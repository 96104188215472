import './index.css'
import * as React from "react"
import DayPickerInput from "react-day-picker/DayPickerInput"
import { withLocalize } from "react-localize-redux"
import DateUtils from "../../../utils/DateUtils"
import ObjectUtils from "../../../utils/ObjectUtils"
import CustomPeriod from "./CustomPeriod"

interface PeriodPickerProps {
  format: string
  dateTo: Date
  dateFrom: Date
  updateDate: (dateFrom, dateTo) => void
  hideCustomPeriod?: boolean
  translate?: (any: any) => any
  activeLanguage?: any
}
const MONTH_IDS = [
  "common.months.01",
  "common.months.02",
  "common.months.03",
  "common.months.04",
  "common.months.05",
  "common.months.06",
  "common.months.07",
  "common.months.08",
  "common.months.09",
  "common.months.10",
  "common.months.11",
  "common.months.12",
]
const WEEKDAYS_IDS = [
  "common.weekdays.sunday",
  "common.weekdays.monday",
  "common.weekdays.tuesday",
  "common.weekdays.wednesday",
  "common.weekdays.thursday",
  "common.weekdays.friday",
  "common.weekdays.saturday",
]
const SHORT_WEEKDAYS_IDS = [
  "common.weekdays.su",
  "common.weekdays.mo",
  "common.weekdays.tu",
  "common.weekdays.we",
  "common.weekdays.th",
  "common.weekdays.fr",
  "common.weekdays.sa",
]
const DayPickerInputComponent = DayPickerInput as any
interface PeriodPickerState {
  lastValidFrom
  lastValidTo
  isSelecting: boolean
  tempSelectedFrom?: any
}
class PeriodPicker extends React.Component<PeriodPickerProps, PeriodPickerState>{

  private to
  private timeout

  constructor(props) {
    super(props)
    this.state = {
      lastValidFrom: props.dateFrom,
      lastValidTo: props.dateTo,
      isSelecting: false
    }
    this.handleUpdateDate = this.handleUpdateDate.bind(this)
  }
  componentWillUnmount() { clearTimeout(this.timeout) }
  changeFocus(element) {
    this.timeout = setTimeout(() => element.getInput().focus(), 0)
  }
  componentDidUpdate(prevProps: PeriodPickerProps, prevState: PeriodPickerState) {
    if (prevProps.dateFrom != this.props.dateFrom || prevProps.dateTo != this.props.dateTo) {
      this.setState({
        lastValidFrom: this.props.dateFrom,
        lastValidTo: this.props.dateTo
      })
    }
    if (prevState.isSelecting && !this.state.isSelecting) {
      this.handleUpdateDate()
    }
  }
  handleUpdateDate() {
    if (!this.state.isSelecting && this.props.dateFrom.getTime() != this.state.lastValidFrom.getTime() || this.props.dateTo.getTime() != this.state.lastValidTo.getTime()) {
      this.props.updateDate(this.state.lastValidFrom, this.state.lastValidTo)
    }
  }
  handleInputKeyPress(event) {
    if (event.key === "Enter") { event.target.blur() }
  }
  render() {
    const ACTIVE_LANG = this.props.activeLanguage.code
    const MONTHS = ObjectUtils.getObjectValues(this.props.translate(MONTH_IDS))
    const WEEKDAYS = ObjectUtils.getObjectValues(this.props.translate(WEEKDAYS_IDS))
    const SHORT_WEEKDAYS = ObjectUtils.getObjectValues(this.props.translate(SHORT_WEEKDAYS_IDS))
    const dateFrom = this.state.isSelecting ? this.state.tempSelectedFrom : this.props.dateFrom
    const dateTo = this.props.dateTo
    const modifiers = { start: dateFrom, end: dateTo }
    const dayPickerProps = {
      className: "Selectable",
      selectedDays: [{ from: dateFrom, to: dateTo }],
      modifiers,
      numberOfMonths: 2,
      locale: ACTIVE_LANG,
      months: MONTHS,
      weekdaysLong: WEEKDAYS,
      weekdaysShort: SHORT_WEEKDAYS
    }
    const inputProps = {
      onBlur: () => setTimeout(this.handleUpdateDate, 0),
      onKeyPress: this.handleInputKeyPress
    }
    return (
      <div className={"menu-period"}>
        <div className={"menu-period-item"}>
          <DayPickerInputComponent
            id={"dateFrom"}
            value={dateFrom}
            inputProps={inputProps}
            formatDate={(date) => DateUtils.format(date, "%Y-%m-%d")}
            onDayChange={(day) => {
              if (day !== undefined && day <= dateTo) {
                this.setState({ lastValidFrom: day })
              }
            }}

            hideOnDayClick={false}
            dayPickerProps={{
              ...dayPickerProps,
              disabledDays: { after: dateTo },
              toMonth: dateTo,
              onDayClick: ((day) => {
                this.setState({
                  isSelecting: true,
                  tempSelectedFrom: day
                })
                this.changeFocus(this.to)
              })
            }}
          />
        </div>
        <div className={"menu-period-item"}>
          <DayPickerInputComponent
            id={"dateTo"}
            ref={el => this.to = el}
            value={dateTo}
            inputProps={inputProps}
            formatDate={(date) => DateUtils.format(date, "%Y-%m-%d")}
            onDayChange={(day) => {
              if (day !== undefined && day >= dateFrom) {
                this.setState({ lastValidTo: day })
              }
            }}
            hideOnDayClick={false}
            dayPickerProps={{
              ...dayPickerProps,
              selectedDays: dayPickerProps.selectedDays,
              modifiers: modifiers,
              disabledDays: { before: dateFrom },
              month: dateFrom,
              fromMonth: dateFrom,
              onDayClick: () => this.to.getInput().blur()
            }}
            onDayPickerHide={() => {
              this.setState({
                isSelecting: false,
                tempSelectedFrom: undefined
              })
            }}
          />
        </div>
        {!this.props.hideCustomPeriod &&
          <CustomPeriod updateDate={this.props.updateDate} />
        }
      </div>
    )
  }
}

export default withLocalize(PeriodPicker)
