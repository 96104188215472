import './Card.css'
import * as React             from "react"
import {Translate}            from "react-localize-redux"
import OverflowMenu,{Option}  from "../Menu/OverflowMenu/"


interface CardProps{
  icon               : string
  number             : string
  text               : any
  menuConfig        ?: MenuConfig
  percent           ?: any
  percentBGColor    ?: string
  textTranslationId ?: string
  onMouseEnter      ?: (e)=>void
  onMouseLeave      ?: (e)=>void
  onFocus           ?: (e)=>void
  onClick           ?: (e)=>void
}
interface MenuConfig{
  options  : Option[]
  onSelect : (selected:Option["value"])=>void
}
const Card = (props:CardProps) => {
  return (
    <div
      className="OverviewCard"
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onFocus={props.onFocus}
      onClick={props.onClick}
    >
      <div className="CardIcon">
        <i className="material-icons">{props.icon}</i>
      </div>
      <div className="CardIData">
        <div className="CardNumberText"> {props.number}</div>
        <div className="CardText">
          <p>{
            props.textTranslationId
              ? <Translate id={props.textTranslationId}/>
              : props.text
          }</p>
        </div>
      </div>
      {props.menuConfig &&
        <div className="OptionsMenu" onClick={(e)=>e.stopPropagation()}>
          <OverflowMenu
            options={props.menuConfig.options}
            onSelect={props.menuConfig.onSelect}
            closeOnSelect
          />
        </div>
      }
      {props.percent!=undefined?
        <div className="CardPercent">
          <span className="CardPercentText" style={{background:props.percentBGColor}}>{props.percent}</span>
        </div>
      :
        <div></div>
      }
    </div>
  )
}

export default Card
