import {combineReducers} from 'redux'
import Bing              from "./bing/CampaignsBaseInfo"
import Google            from "./google/CampaignsBaseInfo"
import Retrieving        from "./Retrieving"
import Failed        from "./Failed"

const CampaignsBaseInfo = combineReducers({
  Bing,
  Google,
  Retrieving,
  Failed,
})

export default CampaignsBaseInfo
