import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const check = (state,action) => {
  return action.compiling
}
const returnFalse = (state,action)=>{
  return false
}
export const SoldVehiclesCompiling = ReducerUtils.createReducer<boolean>(false,{
  [Actions.BACKGROUND_COMPILING_SOLD_VEHICLES] : check,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST] : returnFalse,
  [Actions.CHANGE_SELECTED_PERIOD]           : returnFalse,
})
