const google = (window as any).google
import * as React                      from "react"
import {connect}                       from 'react-redux'
import Place                           from "../../model/Store/GeoBid/Place"
import Position                        from "../../model/Store/GeoBid/GeoPosition"
import PlaceDefinition                 from "../../model/map/PlaceDefinition"
import User                            from "../../model/Store/Login/UserConnected"
import {removeCodeFromQueue}           from "../../actions/EditGeoBid"
import {moveFirstCodeToEndOfQueue}     from "../../actions/EditGeoBid"
import {addPositionToCache}            from "../../actions/EditGeoBid"
import {loadAdWordsPlaces}             from "../../actions/AdWordsPlaces"
import {loadMetaGeoCodingCache}        from "../../actions/AdWordsPlaces"
import {saveMetaGeoCodingCache}        from "../../actions/AdWordsPlaces"
import ObjectUtils                     from "../../utils/ObjectUtils"
import ArrayUtils                      from "../../utils/ArrayUtils"

interface PlaceGeocoderComponentProps{
  loggedUser            : User
  queue                 : number[]
  cache                 : PlaceDefinition[]
  metacache             : any[]
  adWordsPlaces         : any[]
  removeFromQueue       : (AWCode:number)=>void
  addToCache            : (AWCode:number, position:Position)=>void
  moveFirstToEndOfQueue : ()=>void
  loadPlaces            : ()=>void
  saveNewCache          : (placePositions:any[])=>void
  loadMetaCache         : ()=>void
  token                 : string
}
const mapStateToProps = state => {
  return{
    loggedUser        : state.Login.userLogged.profile,
    queue             : state.Params.GeoBids.GeocodingQueue,
    cache             : state.Params.GeoBids.GeocodingCache,
    metacache         : state.Params.GeoBids.GeocodingMetaCache,
    adWordsPlaces     : state.Params.GeoBids.AdWordsPlaces,
    token             : state.Login.userLogged.token,
  }
}
const mapDispatchToProps = (dispatch) => {
  return{
    removeFromQueue       : (AWCode:number) => dispatch(removeCodeFromQueue(AWCode)),
    addToCache            : (AWCode:number, position:Position) => dispatch(addPositionToCache(AWCode, position)),
    moveFirstToEndOfQueue : () => dispatch(moveFirstCodeToEndOfQueue()),
    saveNewCache          : (placePositions) => dispatch(saveMetaGeoCodingCache(placePositions)),
    loadMetaCache         : () => dispatch(loadMetaGeoCodingCache()),
    loadPlaces            : () => dispatch(loadAdWordsPlaces()),
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
class PlaceGeocoderComponent extends React.Component<PlaceGeocoderComponentProps, any>{
  private interval
  constructor(props){
    super(props)
    this.work = this.work.bind(this)
    this.geoCode = this.geoCode.bind(this)
  }
  componentDidUpdate(prevProps){
    if(String(prevProps.token).length===0 && this.props.token!==prevProps.token){
      if(ObjectUtils.getObjectKeys(this.props.metacache).length===0){
        this.props.loadMetaCache()
      }
    }
    const newPlacesPositions = (
      ObjectUtils.getObjectKeys(this.props.cache)
      .filter(x=>this.props.metacache[x] === undefined)
      .map(x=>({
        code : x,
        position : this.props.cache[x]
      }))
    )
    // TODO change this when we update permission system, since userlevel wont exist anymore
    if(newPlacesPositions.length && this.props.loggedUser && this.props.loggedUser.userLevel <= 3){
      this.props.saveNewCache(newPlacesPositions)
    }
  }
  work(){
    if(this.props.queue.length === 0){return}
    const AWCode = this.props.queue[0]
    if(this.props.cache[AWCode]){
      this.props.removeFromQueue(AWCode)
      this.work()
    }
    else{
      const AWPlace = this.props.adWordsPlaces.find(adWordsPlace=>adWordsPlace[0]==AWCode)
      if(!AWPlace){
        if(this.props.queue.length > 1){
          this.props.moveFirstToEndOfQueue()
        }
        return
      }
      let searchTerm = AWPlace[2] //Canonical name
      /*
        This helps Google's geocoding to make the difference between
        a province (or state) and a city of the same name.
      */
      if(AWPlace[4]==="Province"){searchTerm += " Province"}
      if(AWPlace[4]==="State")   {searchTerm += " State"}

      this.geoCode(searchTerm)
      .then((position:any)=>{
        this.props.removeFromQueue(AWCode)
        this.props.addToCache(AWCode, position)
      })
      .catch(err=>console.log("Error while geocoding places : " + err))
    }
  }
  componentDidMount(){
    if(this.props.adWordsPlaces.length===0){this.props.loadPlaces()}
    this.interval = setInterval(this.work,750)
  }
  componentWillUnmount(){
    clearInterval(this.interval)
  }
  geoCode = (searchTerm:string):Promise<any> => {
    return new Promise((resolve,reject)=>{
      new google.maps.Geocoder().geocode({address:searchTerm}, (results, status)=>{
        if(status.toString() === "OK"){
          resolve({
            lat : results[0].geometry.location.lat(),
            lng : results[0].geometry.location.lng()
          })
        }
        else{
          reject(status)
        }
      })
    })
  }
  render(){
    return null
  }
}
export const PlaceGeocoder = connect(mapStateToProps,mapDispatchToProps,mergeProps)(PlaceGeocoderComponent)
