import ReducerUtils       from "../../utils/ReducerUtils"
import Actions            from "../../model/constant/actions"

const setStatus = (state:SaveStatus, action):SaveStatus => {
  return {
    status : action.status,
    title : action.title,
    content : action.content
  }
}

interface SaveStatus{
  status  : boolean
  title  : string
  content  : string
}
const ShouldBlock = ReducerUtils.createReducer<SaveStatus>({status: false, title: "common.NOTHING", content: "common.NOTHING"},{
  [Actions.SHOULD_BLOCK_NAVIGATION] : setStatus
})

export default ShouldBlock
