import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"
import ArrayUtils       from "../../utils/ArrayUtils"

const storeUsers = (state,action) => {
  return {all:action.users}
}
const storePermissionExtraData = (state, action) => {
  if(state.all===undefined){return state}
  const profilesUserIds = Object.keys(action.profilesByUser).map(x=>parseInt(x))
  const exceptionsUserIds = Object.keys(action.exceptionsByUser).map(x=>parseInt(x))
  const newList = state.all.map(user=>{
    let profiles = []
    let exceptions = []
    if(ArrayUtils.contain(profilesUserIds,user.id)){
      profiles.push(...action.profilesByUser[user.id])
    }
    if(ArrayUtils.contain(exceptionsUserIds,user.id)){
      exceptions.push(...action.exceptionsByUser[user.id])
    }
    if(profiles.length === 0 && exceptions.length === 0){return user}
    return {
      ...user,
      permissionProfiles : profiles,
      permissionExceptions : exceptions
    }
  })
  return {
    all : newList
  }
}
const storeUserPermissions = (state, action) => {
  if (state.all===undefined){return state}
  const userToUpdate = action.user
  const newList = state.all.map(user=>{
    if (user.id === userToUpdate.id){
      return {
        ...user,
        permissions : userToUpdate.permissions.length !== 0 
          ? userToUpdate.permissions
          : ["NO_PERMISSIONS"]
      }
    }
    return {...user}
  })
  return {
    all : newList
  }
}
const storeUserPermissionsProfiles = (state, action) => {
  if (state.all===undefined){return state}
  const userIdToUpdate = action.userId
  const newList = state.all.map(user=>{
    if (user.id === userIdToUpdate){
      return {
        ...user,
        permissionProfiles : action.profiles.map(profile=>{
          return {
            id : profile.ID,
            userId : profile.UserID,
            accountId : profile.AccountID === null ? null : parseInt(profile.AccountID),
            code : profile.Code,
            name : profile.Name,
            description : profile.Description
          }
        })
      }
    }
    return {...user}
  })
  return {
    all : newList
  }
}
const List = ReducerUtils.createReducer<{all:any[]|undefined}>({all:undefined},{
  [Actions.RETRIEVE_USERS_LIST]                  : storeUsers,
  [Actions.RETRIEVE_USERS_PERMISSION_EXTRA_DATA] : storePermissionExtraData,
  [Actions.RETRIEVE_USER_PERMISSIONS]            : storeUserPermissions,
  [Actions.RETRIEVE_USER_PERMISSIONS_PROFILES]   : storeUserPermissionsProfiles,
})
export default List
