import Actions      from "../../model/constant/actions"
import ReducerUtils from "../../utils/ReducerUtils"

const retrieveDesiredStatusException = (state,action) =>{
  if (action.statusExceptions){
    return {
      ...state,
      [action.accountId] : {
        ...action.statusExceptions,
        isFetching : false
      }
    }
  }
  return {
    ...state,
    [action.accountId] : {
      isFetching : false
    }
  }
}
const setRetrieving = (state,action) => {
  const accountId = action.accountId
  return {
    ...state,
    [accountId] : {
      ...state[accountId],
      isFetching : true
    }
  }
}
interface DesiredStatusExceptionsByAccountId{
  [accountId:number] : {
    Paused   : string[]
    Enabled  : string[]
    isFetching : boolean
  }
}
export const DesiredStatusExceptions = ReducerUtils.createReducer< DesiredStatusExceptionsByAccountId>({},{
  [Actions.RETRIEVE_DESIRED_STATUS_EXCEPTIONS] : retrieveDesiredStatusException,
  [Actions.RETRIEVING_DESIRED_STATUS_EXCEPTIONS] : setRetrieving
})
