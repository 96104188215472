import Shapes           from "../../utils/ShapesAPI"
import Actions          from "../../model/constant/actions"
import ObjectUtils      from "../../utils/ObjectUtils"
import retrieveShapes   from "./RetrieveShapes"
import {getToken}       from "../../storeAccessor/Generic"

export const retrieveShapesPendingValidation = (amount:number=10) => (dispatch, getState) => {
  Shapes.get({
      token  : getToken(getState()),
      action : "GetShapesPendingValidation",
      amount : amount,
    },
    response=>{
      dispatch({
        type : Actions.RETRIEVE_VALIDATION_SHAPES,
        data : response.data.shapes.map(x=>({
          ...x,
          polygons : JSON.parse(x.polygons).map(polygon=>polygon.map(x=>({
            lat : x[0],
            lng : x[1]
          })))
        }))
      })
    },
    error=>console.log(error)
  )
}
export const retrieveShapesPendingSimplification = (amount:number=10) => (dispatch, getState) => {
  Shapes.get({
      token  : getToken(getState()),
      action : "GetShapesPendingSimplification",
      amount : amount,
    },
    response=>{
      dispatch({
        type : Actions.RETRIEVE_SHAPES_PENDING_SIMPLIFICATION,
        data : response.data.shapes.map(x=>({
          ...x,
          polygons : JSON.parse(x.polygons).map(polygon=>polygon.map(x=>({
            lat : x[0],
            lng : x[1]
          })))
        }))
      })
    },
    error=>console.log(error)
  )
}
export const flushShapesPendingSimplification = () => dispatch => {
  dispatch({type: Actions.FLUSH_SHAPES_PENDING_SIMPLIFICATION})
}
export const validateShape = (shapeId:number, valid:boolean) => (dispatch, getState) => {
  Shapes.post({
      token   : getToken(getState()),
      action  : "ValidateShape",
      shapeId : shapeId,
      valid   : valid
    },
    response=>{
      dispatch({
        type    : Actions.REMOVE_VALIDATION_SHAPE,
        shapeId : shapeId
      })
    },
    error=>console.log(error)
  )
}

export const retrieveDuplicateGreenShapes = (amount:number=10) => (dispatch, getState) => {
  Shapes.get({
      token  : getToken(getState()),
      action : "GetDuplicateGreenShapes",
      amount : amount
    },
    response=>{
      const formatted = ObjectUtils.mapOnObject(
        response.data.groupedShapes,
        (key,value)=>(
          value.map(place=>({
            ...place,
            polygons : JSON.parse(place.polygons).map(polygon=>polygon.map(x=>({
              lat : x[0],
              lng : x[1]
            })))
          }))
        )
      )
      dispatch({
        type : Actions.RETRIEVE_DUPLICATE_GREEN_SHAPES,
        data : formatted
      })
    },
    error=>{}
  )
}
export const chooseDuplicatedGreenShape = (shapeId:number, code:number) => (dispatch, getState) => {
  Shapes.post({
      token   : getToken(getState()),
      action  : "ChooseDuplicatedGreenShape",
      shapeId : shapeId
    },
    response=>{
      dispatch({
        type : Actions.REMOVE_DUPLICATE_GREEN_SHAPES,
        code : code
      })
    },
    error=>{}
  )
}

export const retrieveUniqueGreenShapes = (amount:number=10) => (dispatch, getState) => {
  Shapes.get({
      token  : getToken(getState()),
      action : "GetUniqueGreenShapes",
      amount : amount
    },
    response=>{
      dispatch({
        type : Actions.RETRIEVE_UNIQUE_GREEN_SHAPES,
        data : response.data.shapes.map(x=>({
          ...x,
          polygons : JSON.parse(x.polygons).map(polygon=>polygon.map(x=>({
            lat : x[0],
            lng : x[1]
          })))
        }))
      })
    },
    error=>{}
  )
}
export const validateUniqueGreenShape = (shapeId:number, valid:boolean) => (dispatch, getState) => {
  Shapes.post({
      token   : getToken(getState()),
      action  : "ValidateUniqueGreenShape",
      shapeId : shapeId,
      valid   : valid
    },
    response=>{
      dispatch({
        type    : Actions.REMOVE_UNIQUE_GREEN_SHAPE,
        shapeId : shapeId
      })
    },
    error=>console.log(error)
  )
}
export const retrieveAvailableShapesList = () => (dispatch, getState) => {
  Shapes.get({
      token  : getToken(getState()),
      action : "GetAvailableShapesList"
    },
    response=>{
      dispatch({
        type : Actions.RETRIEVE_AVAILABLE_SHAPES_LIST,
        list : response.data.list
      })
    },
    error=>{}
  )
}
export const editShape = (shapeId:number, code:number, polygons:any[][]) => (dispatch, getState) => {
  Shapes.post({
      token : getToken(getState()),
      action : "EditShape",
      shapeId : shapeId,
      polygons : JSON.stringify(polygons.map(poly=>poly.map(point=>[point.lat, point.lng]))) //Translate lat/lng objects to lists
    },
    response=>{
      dispatch(retrieveShapes([code]))
    },
    error=>{}
  )
}
export const saveSimplifiedShape = (shapeId:number, code:number, polygons:any[][]) => (dispatch, getState) => {
  Shapes.post({
      token : getToken(getState()),
      action : "EditShape",
      simplification : true,
      shapeId : shapeId,
      polygons : JSON.stringify(polygons.map(poly=>poly.map(point=>[point.lat, point.lng]))) //Translate lat/lng objects to lists
    },
    response=>{
      dispatch(retrieveShapes([code]))
    },
    error=>{}
  )
}
export const retrieveShapesWithSameCode = () => (dispatch, getState) => {
  Shapes.post({
      token : getToken(getState()),
      action : "GetShapesWithSameCode",
    },
    response=>{
      const formatted = ObjectUtils.mapOnObject(
        response.data.shapesByCode,
        (key,value)=>(
          value.map(place=>({
            ...place,
            polygons : JSON.parse(place.polygons).map(polygon=>polygon.map(x=>({
              lat : x[0],
              lng : x[1]
            })))
          }))
        )
      )
      dispatch({
        type : Actions.RETRIEVE_SHAPES_WITH_SAME_CODE,
        data : formatted
      })
    },
    error=>{}
  )
}
export const chooseShapeWithSameCode = (shapeId:number, code:number) => (dispatch, getState) => {
  Shapes.post({
      token   : getToken(getState()),
      action  : "ChooseShapeWithSameCode",
      shapeId : shapeId
    },
    response=>{
      dispatch({
        type : Actions.REMOVE_SHAPES_WITH_SAME_CODE,
        code : code
      })
    },
    error=>{}
  )
}
