import * as React from "react"

interface PointsResumeProps{
  originalShape : any
  newShape : any
}

const PointsResume = (props:PointsResumeProps) => {
  if(!props.newShape){return null}
  return (
    <div>
      <div>Original number of points : {props.originalShape.polygons.reduce((total, polygon)=>(total+polygon.length),0)}</div>
      <div>New number of points : {props.newShape.polygons.reduce((total, polygon)=>(total+polygon.length),0)}</div>
    </div>
  )
}

export default PointsResume
