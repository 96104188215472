import * as React         from 'react'
import {
  Chip,
  Icon,
  Button,
  Divider,
  Typography,
  CardContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Tooltip,
  withStyles,
}                         from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                         from "react-localize-redux"
import URL                from "./url"
import FacebookV1         from "./summary/FacebookV1"
import AdWordsV1          from "./summary/AdWordsV1"
import DeleteFeedModal    from "./deleteModal"
import {Feed as IFeed}    from "../../model/feed/Feed"
import ExpandMoreIcon     from '@material-ui/icons/ExpandMore'
import SimplifiPvV1       from './summary/SimplifiPvV1'
import SimplifiNpvV1      from './summary/SimplifiNpvV1'
import { UrlObject }      from '../../model/feed/UrlObject'
import DCMV1              from './summary/DCMV1'
import AdWordsShoppingV1  from './summary/AdWordsShoppingV1'


interface FeedProps extends LocalizeContextProps {
  id              : string
  stub            : string
  urls            : UrlObject[]
  config          : IFeed["config"]
  deleteSignedUrl : (url:string)=>void
  deleteFeed      : ()=>void
  createSignedUrl : ()=>void
  refreshFeed     : ()=>void
  expanded        : boolean
  setExpanded     : (expanded:boolean)=>void
  loading         : boolean
}

const summaryLookup : {[type in IFeed["config"]["params"]["type"]] : (props)=>React.ReactElement} = {
  FB_V1           : (props)=><FacebookV1        {...props} />,
  MI_FB_V1        : (props)=><FacebookV1        {...props} isMI />,
  AW_V1           : (props)=><AdWordsV1         {...props}/>,
  MI_AW_V1        : (props)=><AdWordsV1         {...props} isMI />,
  AW_SHOPPING_V1  : (props)=><AdWordsShoppingV1 {...props}/>,
  SIMPLIFI_PV_V1  : (props)=><SimplifiPvV1      {...props}/>,
  SIMPLIFI_NPV_V1 : (props)=><SimplifiNpvV1     {...props}/>,
  DCM_V1          : (props)=><DCMV1             {...props}/>,
  MI_DCM_V1       : (props)=><DCMV1             {...props} isMI />,
}

const LargerTooltip = withStyles({
  tooltip: {
    fontSize: 14,
    maxWidth: "none",
  }
})(Tooltip);

const Feed = (props:FeedProps) => {
  const [deleteFeedModalOpen, setDeleteFeedModalOpen] = React.useState<boolean>(false)
  const deletionLocked = props.urls.some( url => url.isLocked )

  const Summary = summaryLookup[props.config.params.type] || (
    (subProps)=>{
      return (
        <div className='FeedSummary'>
          {subProps.stub.split("-").map(part=>(
            <Chip
              color="primary"
              variant="outlined"
              label={part}
            />
          ))}
          {
            deletionLocked &&
              <Tooltip placement="top" title={<p>{props.translate('feeds.deleteLocked')}</p>}>
                <div className="AlignCenter">
                  <Icon style={{color: "gray"}}>lock</Icon>
                </div>
              </Tooltip>
          }
        </div>
      )
    }
  )
  return (
    <>
      <DeleteFeedModal
        open={deleteFeedModalOpen}
        onClose={()=>{setDeleteFeedModalOpen(false)}}
        confirm={props.deleteFeed}
        loading={props.loading}
      />
      <ExpansionPanel
        className='Feed'
        key={props.id}
        expanded={props.expanded}
        onChange={(foo:unknown, expanded:boolean)=>{props.setExpanded(expanded)}}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
        >
          <Summary stub={props.stub} urls={props.urls} config={props.config}/>
        </ExpansionPanelSummary>
        <Divider light/>
        <ExpansionPanelDetails className='SignedUrls'>
          <CardContent>
            <div className="HeightAnimationWrapper" style={{maxHeight:(46*props.urls.length+1)+"px"}}>
              {props.urls.map(url=>(
                <URL
                  key={url.url}
                  url={url}
                  deleteSignedUrl={()=>{props.deleteSignedUrl(url.url)}}
                  loading={props.loading}
                  type={props.config.params.type}
                />
              ))}
            </div>
            {
              props.urls.length===0 && <div className="NoSignedUrls">
                <div className="CTA">
                  <Typography display="inline">{props.translate("feeds.no-urls")}</Typography>
                  <Button color="primary" variant="contained" onClick={()=>{props.createSignedUrl()}}>
                    <div className="AlignCenter"><Icon>playlist_add</Icon>&nbsp;{props.translate("feeds.new-url-cta")}</div>
                  </Button>
                </div>
              </div>
            }
          </CardContent>
        </ExpansionPanelDetails>
        <Divider light/>
        <ExpansionPanelActions>
          {
            deletionLocked ?
              <LargerTooltip title={props.translate('feeds.deleteLocked')}>
                  <Button disabled color="secondary" variant="outlined" onClick={()=>{setDeleteFeedModalOpen(true)}}>
                    <div className="AlignCenter"><Icon>lock</Icon>&nbsp;{props.translate("feeds.delete")}</div>
                  </Button>
              </LargerTooltip>
            :
              <Button color="secondary" variant="outlined" onClick={()=>{setDeleteFeedModalOpen(true)}}>
                <div className="AlignCenter"><Icon>delete_forever</Icon>&nbsp;{props.translate("feeds.delete")}</div>
              </Button>
          }
          <Button color="primary" variant="outlined" onClick={()=>{props.createSignedUrl()}}>
            <div className="AlignCenter"><Icon>playlist_add</Icon>&nbsp;{props.translate("feeds.new-url-actions")}</div>
          </Button>
          <Button color="primary" variant="contained" onClick={()=>{props.refreshFeed()}}>
            <div className="AlignCenter"><Icon>cached</Icon>&nbsp;{props.translate("feeds.refresh")}</div>
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    </>
  )
}
export default withLocalize(Feed)
