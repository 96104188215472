import './LinkGoBack.css'
import * as React     from "react"
import {Translate}    from "react-localize-redux"
import {Link}         from "react-router-dom"

interface LinkGoBackProps{
  to ?: string
}
const LinkGoBack = (props:LinkGoBackProps) => {
  return (
    <Link className='GoBack' to={props.to?props.to:""}>
      <span className="GoBackText"><Translate id="common.goBack"/></span>
    </Link>
  )
}

export default LinkGoBack
