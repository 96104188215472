import Actions              from "../../model/constant/actions"
import ReducerUtils         from "../../utils/ReducerUtils"
import BudgetForStrategie   from "../../model/Store/BudgetsForStrategies/BudgetForStrategie"

const createReducer = ReducerUtils.createReducer

interface BudgetByAccountIds {
  [accountId:number] : BudgetForStrategie[]
}
const addBudgetForSelectedAccounts = (state, action) => {
  return {...state, [action.accountId]:action.budgetsForStrategies}
}
export const BudgetByAccount = createReducer<BudgetByAccountIds>({},{
  [Actions.RETRIEVE_BUDGET_FOR_STRATEGIES] : addBudgetForSelectedAccounts,
})
