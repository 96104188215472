import {combineReducers} from "redux"
import Campaigns from "./Campaigns"
import Retrieving from "./Retrieving"

const Display = combineReducers({
  Campaigns,
  Retrieving
})

export default Display
