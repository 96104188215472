import './AgentsWeightsGraph.css'
import * as React    from 'react'
import { Agent }     from '../../../../../model/pacy'
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
}                    from 'recharts'
import NumFormatter  from "../../../../../utils/NumberFormatter"


interface AgentWeightsGraphInputsOwnProps {
  agents : Agent[]
  colors : {[agentId:string]: string}
}
interface AgentWeightsGraphInputsProps extends AgentWeightsGraphInputsOwnProps {}

const AgentWeightsGraphInputs = (props:AgentWeightsGraphInputsProps) => {
  const totalWeight = props.agents.reduce((acc,agent)=>{
    if(agent.active) {
      return acc + agent.weight
    }
    return acc
  },0)
  const data = props.agents
  .filter(agent=>{
    return agent.active
  })
  .map(agent=>{
    return {
      agentId : agent.id,
      name:agent.name || agent.provider + " - " + agent.providerId,
      value:Math.round(agent.weight/totalWeight*100)
    }
  })
  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    if(percent>0.05) {
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {NumFormatter.formatNumber(percent * 100)}%
        </text>
      )
    }
  }
  return (
    <div className="AgentWeightsGraphInputs">
      <PieChart width={400} height={400}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          cx={200}
          cy={200}
          outerRadius={130}
          fill="#00B2E3"
          labelLine={false}
          blendStroke
          label={renderCustomizedLabel as any}
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={props.colors[entry.agentId]} />)
          }
        </Pie>
        <Tooltip
          formatter={x => (typeof x === "number" || "string") ? NumFormatter.formatNumber(parseFloat(x.toString())) : x }
        />
      </PieChart>
    </div>
  )
}

export default AgentWeightsGraphInputs
