import ReducerUtils     from "../../../../utils/ReducerUtils"
import Actions          from "../../../../model/constant/actions"

const setShapes = (state, action) => {
  return {
    ...state,
    ...action.data
  }
}
const removeShapes = (state, action) => {
  const stateCopy = {...state}
  delete stateCopy[action.code]
  return stateCopy
}

const ShapesWithSameCode = ReducerUtils.createReducer<any>({},{
  [Actions.RETRIEVE_SHAPES_WITH_SAME_CODE] : setShapes,
  [Actions.REMOVE_SHAPES_WITH_SAME_CODE]   : removeShapes
})

export default ShapesWithSameCode
