import "./MapDisplayOnly.css"
import * as React        from "react"
import {connect}         from "react-redux"
import ArrayUtils        from "../../utils/ArrayUtils"
import GeoTargeting      from "../../model/Store/GeoBid/GeoTargeting"
import PlaceDefinition   from "../../model/map/PlaceDefinition"
import GeoBidAccessors   from "../../storeAccessor/GeoBid"
import {addCodeToQueue}  from "../../actions/EditGeoBid"
import loadShapes        from "../../actions/shapes/RetrieveShapes"
import MapBuilder        from "./MapBuilder"

interface MapDisplayOnlyComponentOwnProps{
  geoTargeting          : GeoTargeting
  onClick               : ()=>void
  title                ?: string
  circleOptions        ?: any
  mapSize              ?: number
  showPlacesWithShapes ?: boolean
  useQuickZoom         ?: boolean
  heatmapData          ?: any
}
interface MapDisplayOnlyComponentProps extends MapDisplayOnlyComponentOwnProps{
  placeDefinitions  : PlaceDefinition[]
  placesShapes      : any[]
  fetchedShapeCodes : number[]
  addCodeToQueue    : (code)=>void
  loadShapes        : (codes:number[])=>void
}
const makeMapStateToProps = () => {
  const placeDefinitionsSelector = GeoBidAccessors.makeGeoTargetingPlaceDefinitionsSelector()
  return (state, ownProps:MapDisplayOnlyComponentOwnProps) => {
    return {
      placeDefinitions  : placeDefinitionsSelector(state, ownProps.geoTargeting),
      placesShapes      : state.Params.GeoBids.Shape.PlacesShapes,
      fetchedShapeCodes : state.Params.GeoBids.Shape.FetchedShapeCodes,
    }
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return{
    addCodeToQueue : (code) => dispatch(addCodeToQueue(code)),
    loadShapes     : (codes:number[]) => dispatch(loadShapes(codes))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
class MapDisplayOnlyComponent extends React.Component<MapDisplayOnlyComponentProps,any>{
  static defaultProps = {
    mapSize : 400,
    title : "",
    circleOptions : {},
    showPlacesWithShapes : true,
    useQuickZoom : true,
  }
  componentDidMount(){
    let codesNeedingShape = []
    const currentCodes = this.props.placeDefinitions.map(x=>x.code)
    for(let place of this.props.geoTargeting.places){
      if(!ArrayUtils.contain(currentCodes, place.code)){
        this.props.addCodeToQueue(place.code)
      }
      if(!ArrayUtils.contain(this.props.fetchedShapeCodes, place.code)){
        codesNeedingShape.push(place.code)
      }
    }
    if(codesNeedingShape.length){this.props.loadShapes(codesNeedingShape)}
  }
  render(){
    const type  = this.props.geoTargeting.matcher.vehicleType||""
    const state = this.props.geoTargeting.matcher.state||""
    const group = this.props.geoTargeting.matcher.group
    const circleDefinitions = this.props.geoTargeting.radiuses.map((circle,i)=>{
      return {
        id : i,
        edited : false,
        selected : false,
        bidModifier : circle.bidModifier,
        circleProps : {
          center : {lat:circle.lat, lng:circle.lng},
          radius : circle.radius*1000,
          editable : false,
          options : this.props.circleOptions
        }
      }
    })
    const includedPlaceOptions = {icon:"../../../../assets/img/includedPlace.png"}
    const excludedPlaceOptions = {icon:"../../../../assets/img/excludedPlace.png"}
    const editedIncludedPlaceOptions = {icon:"../../../../assets/img/includedPlaceEdited.png"}
    const editedExcludedPlaceOptions = {icon:"../../../../assets/img/excludedPlaceEdited.png"}
    const configuredPlaceDefinitions = this.props.placeDefinitions.map((place,i)=>{
      let options
      if(place.include){options = place.edited?editedIncludedPlaceOptions:includedPlaceOptions}
      else{options = place.edited?editedExcludedPlaceOptions:excludedPlaceOptions}
      return {
        ...place,
        id : i,
        placeProps : {
          ...place.placeProps,
          options : {
            ...place.placeProps.options,
            ...options
          }
        }
      }
    })
    const mapProps = {
      options : {
        disableDefaultUI : true,
        gestureHandling : this.props.useQuickZoom?"greedy":"cooperative"
      }
    }
    return(
      <div
        className={"MapDisplayOnly"+(this.props.title&&" hasMapMatcher")}
        onClick={this.props.onClick}
      >
        {this.props.title &&
          <span className="MapMatcher">
            {this.props.title}
          </span>
        }
        <MapBuilder
          circlesDefinitions={circleDefinitions}
          placesDefinitions={configuredPlaceDefinitions}
          onMapClick={this.props.onClick}
          heatmapData={this.props.heatmapData}
          mapProps={mapProps}
          mapSize={this.props.mapSize}
          shapes={this.props.showPlacesWithShapes?this.props.placesShapes:{}}
        />
      </div>
    )
  }
}

export const MapDisplayOnly = connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
  MapDisplayOnlyComponent
)
