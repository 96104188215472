import ReducerUtils         from "../../../utils/ReducerUtils"
import Actions              from "../../../model/constant/actions"
import IdentityVariants, {
  IdentityVariantsByLang
}                           from "../../../model/branding/IdentityVariants"

const saveData = (state,action:{identityVariants:IdentityVariants[],accountId:number}) => {
  return {
    ...state,
    [action.accountId] :{
       ...(state[action.accountId]||{}),
       ... action.identityVariants.reduce((acc, iv)=>{
         acc[iv.lang] = iv
         return acc
      }, {})
    }
  }
}

interface State {
  [accountId:string] : IdentityVariantsByLang
}

const retrieveIdentityVariants = ReducerUtils.createReducer<State>({},{
  [Actions.RETRIEVE_IDENTITY_VARIANTS] : saveData
})

export default retrieveIdentityVariants
