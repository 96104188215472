import * as React                             from "react"
import {Translate}                            from "react-localize-redux"
import ReportTableTemplateUtils               from "../../../utils/ReportTableTemplateUtils"
import ReportTableTemplate                    from "../../../model/Store/Report/ReportTableTemplate"
import ErrorBoundary                          from "../../../components/Error/ErrorBoundary"
import TemplateEditor                         from "./TemplateEditor/TemplateEditor"
import TemplateChooser                        from "./TemplateChooser"
import StatsExtractor                         from "./StatsExtractor"
import CreateTemplateModal                    from "./CreateTemplateModal"
import {Button}                               from "carbon-components-react"
import ExplainedLoading                       from "../../../components/loading"
import {Provider}                             from "../../../model/Provider"

interface ReportProps{
  templates               : ReportTableTemplate[]
  selectedTemplateId      : string
  selectTemplate          : (templateId: string)=>void
  context                 : string
  provider                : Provider
  createTemplate          : (template:ReportTableTemplate)=>void
  defaultTemplateId       : string
  selectDefaultTemplate   : (templateId: string)=>void
}
interface ReportState{
  editingTemplate          : boolean
  canceledLastEditing      : boolean
  creatingTemplate         : boolean
  waitingForTemplateToSave : boolean
  dialogOpen               : boolean
}

class Report extends React.Component<ReportProps,ReportState>{
  constructor(props){
    super(props)
    this.state = {
      editingTemplate : false,
      canceledLastEditing : false,
      creatingTemplate : false,
      waitingForTemplateToSave : false,
      dialogOpen: false
    }

    this.createNewTemplate = this.createNewTemplate.bind(this)
    this.startEditing = this.startEditing.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
  }
  componentDidUpdate(prevProps, prevState){
    if(this.state.creatingTemplate && prevProps.templates.length < this.props.templates.length){
      this.props.selectTemplate(this.props.templates[this.props.templates.length-1].getId())
      this.setState({
        editingTemplate : true,
        creatingTemplate : false
      })
    }
    if(prevState.editingTemplate && !this.state.editingTemplate && !this.state.canceledLastEditing){
      this.setState({
        waitingForTemplateToSave : true
      })
    }
    if(prevState.waitingForTemplateToSave){
      this.setState({
        waitingForTemplateToSave : false
      })
    }
  }
  startEditing(){
    this.setState({
      editingTemplate : true,
      canceledLastEditing : false
    })
  }
  cancelEditing(){
    this.setState({
      editingTemplate : false,
      canceledLastEditing : true
    })
  }
  createNewTemplate(name:string){
    const TemplateClass = ReportTableTemplateUtils.getTemplateClassFromContext(this.props.provider,this.props.context)
    this.setState({creatingTemplate:true})
    this.props.createTemplate(new TemplateClass(-1, name))
  }

  render(){
    const selectedTemplateIndex = this.props.templates.findIndex(template => template.getId() === this.props.selectedTemplateId)

    if(this.state.waitingForTemplateToSave){return <ExplainedLoading translateId="loadings.savingView"/>}
    if(this.props.templates.length > 0 && selectedTemplateIndex >= this.props.templates.length){
      this.props.selectTemplate(this.props.templates[0].getId())
      return <ExplainedLoading translateId="loadings.switchingView"/>
    }
    let template
    if(this.props.templates.length === 0){
      const TemplateClass = ReportTableTemplateUtils.getTemplateClassFromContext(this.props.provider,this.props.context)
      template = new TemplateClass("DEFAULT_TEMPLATE", "Default")
    }
    else{
      template = selectedTemplateIndex === -1 ? this.props.templates[0] : this.props.templates[selectedTemplateIndex]
    }

    return(
      <div>
        {this.state.editingTemplate?
          <TemplateEditor
            template={template}
            templateCount={this.props.templates.length}
            context={this.props.context}
            exit={()=>{this.setState({editingTemplate:false})}}
            cancel={this.cancelEditing}
            selectedTemplateId={this.props.selectedTemplateId}
            selectDefaultTemplate={this.props.selectDefaultTemplate}
            defaultTemplateId={this.props.defaultTemplateId}
          />
        :
          <div>
            <div className="TemplateActions">
              <div className="TemplateActionsChooserContainer">
                <TemplateChooser
                  templates={this.props.templates.length>0?this.props.templates:[template]}
                  selectedTemplateId={this.props.selectedTemplateId}
                  selectHandler={this.props.selectTemplate}
                />
              </div>
              <div className="TemplateActionButtons">
                <Button onClick={this.startEditing}>
                  <Translate id="reports.ads.editView"/>
                </Button>
                <CreateTemplateModal
                  saveHandler={this.createNewTemplate}
                />
              </div>
            </div>
            <ErrorBoundary>
              <StatsExtractor template={template} context={this.props.context}/>
            </ErrorBoundary>
          </div>
        }
      </div>
    )
  }
}

export default Report
