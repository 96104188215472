import * as React                        from "react"
import {Translate}                       from "react-localize-redux"
import Button                            from "@material-ui/core/Button"
import Dialog                            from "@material-ui/core/Dialog"
import DialogTitle                       from "@material-ui/core/DialogTitle"
import DialogContent                     from "@material-ui/core/DialogContent"
import DialogActions                     from "@material-ui/core/DialogActions"

interface ConfirmationProps{
  open        ?: boolean
  title       ?: string
  content     ?: string | React.ReactNode
  translate   ?: boolean
  onClose     ?: ()=>void
  onCancel    ?: ()=>void
  onConfirm   ?: ()=>void
  readOnly    ?: boolean
  confirmButtonText ?: string
}
interface ConfirmationState{
  open : boolean
}

class Confirmation extends React.Component<ConfirmationProps,ConfirmationState>{
  static defaultProps = {
    open      : false,
    onClose   : ()=>{},
    onConfirm : ()=>{},
  }
  constructor(props:ConfirmationProps){
    super(props)
    this.state = {
      open : props.open
    }
    this.close         = this.close.bind(this)
    this.handleClose   = this.handleClose.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleCancel  = this.handleCancel.bind(this)
  }
  componentDidUpdate(prevProps:ConfirmationProps, prevState:ConfirmationState){
    if(prevProps.open !== this.props.open && this.props.open !== this.state.open){
      this.setState({open: this.props.open})
    }
  }
  close(){
    this.setState({open: false})
  }
  handleClose(){
    this.props.onClose()
    this.close()
  }
  handleConfirm(){
    this.props.onConfirm()
    this.close()
  }
  handleCancel(){
    this.props.onCancel()
    this.close()
  }
  render(){
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {this.props.title &&
            <DialogTitle id="alert-dialog-title">
              {this.renderText(this.props.title)}
            </DialogTitle>
          }
          <DialogContent>
            {this.props.readOnly &&
              <div style={{fontSize: "10px", textAlign: "right"}}>
                <Translate id="common.readOnlyMessage"/>
              </div>
            }
            {typeof this.props.content === "string"
              ? this.renderText(this.props.content)
              : this.props.content
            }
          </DialogContent>
          <DialogActions>
            {this.props.onCancel !== undefined
              ?  <Button onClick={this.handleCancel} color="primary">
                  <Translate id="common.cancel"/>
                </Button>
              : <></>
            }
            <Button onClick={this.handleConfirm} color="primary" disabled={this.props.readOnly === true} >
              {this.props.confirmButtonText
                ? this.renderText(this.props.confirmButtonText)

                : <Translate id="common.confirm"/>
              }
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
  renderText(text:string){
    if(this.props.translate){return <Translate id={text}/>}
    return text
  }
}

export default Confirmation
