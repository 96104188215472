import * as React from "react"
import WidthInjector from "../WidthInjector/"

interface StyleInjectorProps{
  styleConfig : StyleConfiguration
  elementWidth : number
  children
}

const sortWidths = (a,b)=>a-b
const StyleInjector = WidthInjector((props:StyleInjectorProps) => {
  const computedStyle = Object.keys(props.styleConfig).reduce((computedStyle, name)=>{
    let chosenStyle = {}
    if(props.elementWidth){
      const widths = Object.keys(props.styleConfig[name]).sort(sortWidths)
      for(const width of widths){
        if(props.elementWidth <= parseInt(width, 10)){
          chosenStyle = props.styleConfig[name][width]
          break
        }
      }
    }
    computedStyle[name] = chosenStyle
    return computedStyle
  },{})
  return React.cloneElement(props.children,{
    style : computedStyle
  })
})

interface StyleConfiguration{
  [styleName:string] : {
    [maxWidth:string] : any
  }
}

const injector = (styleConfig:StyleConfiguration) => (Component) => (props) => (
  <StyleInjector styleConfig={styleConfig}>
    <Component {...props}/>
  </StyleInjector>
)
export default injector
