import './CreateAgentStep2.css'
import * as React                             from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Icon,
  Divider,
  Switch,
}                                             from '@material-ui/core'
import { ProxyGroup }                         from '../../../../../../model/pacy/ProxyGroup'
import {
  Agent,
  AgentCompatibleProvider,
  PossibleAgent
}                                             from '../../../../../../model/pacy'
import CreateAgentParams                      from './CreateAgentParams'
import AgentWeightsConfiguration              from '../../AgentWeightsConfiguration'
import {
  AccountModifier,
  createAddAgentAccountModifier,
  createToggleAgentAccountModifier,
  createSetAgentWeightAccountModifier,
  generate
}                                             from '../../../../../../model/pacy/AccountModifiers'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                                             from 'react-localize-redux'
import ProviderIcon                           from "./ProviderIcon"

interface CreateAgentStep2OwnProps {
  agent                 : Agent
  possibleAgents        : PossibleAgent[]
  proxyGroups          ?: ProxyGroup[]
  selectedProxyGroupId ?: string
  selectedProxyId      ?: string
  setActive             : (e)=>void
  setMaxSharing         : (e)=>void
  setName               : (name:string)=>void
  setProviderId         : (providerId:string)=>void
  selectProxyGroup      : (e)=>void
  selectProxy           : (e)=>void
  onCancel              : ()=>void
  onConfirm             : ()=>void
}
interface CreateAgentStep2Props extends CreateAgentStep2OwnProps {
  translate : TranslateFunction
}

const CreateAgentStep2 = (props:CreateAgentStep2Props) => {
  return (
    <Card className="CreateAgentStep2">
      <CardHeader
        title={
          <div className="CreateAgentStep2Header">
            <Typography variant="h6" style={{display:'flex',alignItems:'center',gap:"7px"}}>
              <ProviderIcon provider={props.agent.provider}/>
              <Translate id="pacyConfigs.createAgent" />
            </Typography>
            <div className="Active">
              <span style={{color : props.agent.active ?'green':'red'}  }>
                {props.agent.active ? props.translate("pacyConfigs.active") : props.translate("common.paused")}
              </span>
              <Switch color="primary" size="medium" checked={props.agent.active} onChange={props.setActive}/>
            </div>
          </div>
        }
      />
      <Divider />
      <CardContent className="Content">
        <CreateAgentParams
          maxSharing={props.agent.maxSharing}
          handleSetMaxSharing={props.setMaxSharing}
          handleNameChange={props.setName}
          handleChangeProxyGroup={props.selectProxyGroup}
          selectedProxyGroupId={props.selectedProxyGroupId}
          handleChangeProxy={props.selectProxy}
          selectedProxyId={props.selectedProxyId}
          selectedProviderId={props.agent.providerId}
          proxyGroups={props.proxyGroups}
          possibleAgents={props.possibleAgents}
          handlePossibleAgentChoice={(possibleAgent)=>{
            props.setName(possibleAgent.name)
            props.setProviderId(possibleAgent.providerId)
          }}
        />
      </CardContent>
      <Divider />
      <CardActions className="CreateAgentStep2Footer">
        <Button onClick={props.onCancel} variant="outlined" color="secondary" size="large">
          <Translate id="common.cancel" />
        </Button>
        <Button
          disabled={!props.selectedProxyGroupId || !props.selectedProxyId || !props.agent.providerId}
          onClick={props.onConfirm}
          style={{color:'white'}}
          variant="contained"
          color="primary"
          size="large"
        >
          <Translate id="pacyConfigs.weightsConfig" />
        </Button>
      </CardActions>
    </Card>
  )

}

export default withLocalize(CreateAgentStep2 as any) as React.ComponentType<CreateAgentStep2OwnProps>


