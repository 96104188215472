import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    adCopies : {
      marginTop: 10,
      cursor: "pointer",
      display: "flex",
      flexDirection : "column",
    },
    button : {
      color : "#D8D6D6",
      alignSelf : "end",
      fontWeight : "bold",
      height : 30,
      width : 30,
      margin:5,
    },
    redButton : {
      color : "#ED6464",
      alignSelf : "end",
      fontWeight : "bold",
      height : 30,
      width : 30,
      margin:5,
    },
    adcopy : {
      padding:20,
    }
  })
)

export default useStyles
