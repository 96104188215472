export default class ActionsConstant{
  public static TOGGLE_MENU                                      = 'TOGGLE_MENU'
  public static RETRIEVE_STRATEGIES                              = 'RETRIEVE_STRATEGIES'
  public static RETRIEVING_STRATEGIES                            = 'RETRIEVING_STRATEGIES'
  public static CHANGE_ACTIVE_BUTTON_IN_MENU                     = 'CHANGE_ACTIVE_BUTTON_IN_MENU'
  public static RETRIEVE_KEYWORD_PATTERNS_FOR_ACCOUNTID          = 'RETRIEVE_KEYWORD_PATTERNS'
  public static RETRIEVE_TOKEN                                   = 'RETRIEVE_TOKEN'
  public static RETRIEVE_GEOBID                                  = 'RETRIEVE_GEOBID'
  public static RETRIEVING_GEOBIDS                               = "RETRIEVING_GEOBIDS"
  public static RETRIEVING_ADCOPY_PATTERNS                       = "RETRIEVING_ADCOPY_PATTERNS"
  public static RETRIEVE_ADCOPIES_PATTERNS_FOR_ACCOUNTID         = 'RETRIEVE_ADCOPIES_PATTERNS_FOR_ACCOUNTID'
  public static CREATE_TEMP_ADCOPY_PATTERN                       = 'CREATE_TEMP_ADCOPY_PATTERN'
  public static STORE_SAVE_ADCOPY_PATTERN_ERROR                  = 'STORE_SAVE_ADCOPY_PATTERN_ERROR'
  public static RETRIEVE_ACCOUNTS_FOR_TOKEN                      = 'RETRIEVE_ACCOUNTS_FOR_TOKEN'
  public static RETRIEVING_ACCOUNTS_FOR_TOKEN                    = "RETRIEVING_ACCOUNTS_FOR_TOKEN"
  public static TOGGLE_SELECTED_ACCOUNT                          = 'TOGGLE_SELECTED_ACCOUNT'
  public static MODIFY_SELECTED_ACCOUNTS_BY_LIST                 = 'MODIFY_SELECTED_ACCOUNTS_BY_LIST'
  public static RETRIEVE_CAMPAIGNS_LIST_FOR_ACCOUNTID            = 'RETRIEVE_CAMPAIGNS_LIST_FOR_ACCOUNTID'
  public static RETRIEVE_CAMPAIGN                                = 'RETRIEVE_CAMPAIGN'
  public static ADD_CAMPAIGNID_TO_FETCHED                        = 'ADD_CAMPAIGNID_TO_FETCHED'
  public static REMOVE_CAMPAIGNID_TO_FETCHED                     = 'REMOVE_CAMPAIGNID_TO_FETCHED'
  public static MODIFY_SELECTED_CAMPAIGN                         = 'MODIFY_SELECTED_CAMPAIGN'
  public static DESELECT_SELECTED_CAMPAIGN                       = 'DESELECT_SELECTED_CAMPAIGN'
  public static AUTHENTIFY_LOGIN_ERROR                           = 'AUTHENTIFY_LOGIN_ERROR'
  public static AUTHENTIFY_LOGIN_SUCCESS                         = 'AUTHENTIFY_LOGIN_SUCCESS'
  public static LOGGING_IN                                       = 'LOGGING_IN'
  public static MODIFY_STATUS_CAMPAIGN                           = "MODIFY_STATUS_CAMPAIGN"
  public static START_EDITING_GEOBID                             = "START_EDITING_GEOBID"
  public static UPDATE_EDITING_GEOBID                            = "UPDATE_EDITING_GEOBID"
  public static CANCEL_EDITING_GEOBID                            = "CANCEL_EDITING_GEOBID"
  public static ADD_CIRCLE_EDITING_GEOBID                        = "ADD_CIRCLE_EDITING_GEOBID"
  public static ADD_PENDING_PLACE_EDITING_GEOBID                 = "ADD_PENDING_PLACE_EDITING_GEOBID"
  public static ADD_PLACE_EDITING_GEOBID                         = "ADD_PLACE_EDITING_GEOBID"
  public static UPDATE_CIRCLE_EDITING_GEOBID                     = "UPDATE_CIRCLE_EDITING_GEOBID"
  public static UPDATE_PLACE_EDITING_GEOBID                      = "UPDATE_PLACE_EDITING_GEOBID"
  public static DELETE_CIRCLE_EDITING_GEOBID                     = "DELETE_CIRCLE_EDITING_GEOBID"
  public static DELETE_PLACE_EDITING_GEOBID                      = "DELETE_PLACE_EDITING_GEOBID"
  public static RETRIEVE_ADWORDS_PLACES                          = "RETRIEVE_ADWORDS_PLACES"
  public static SELECT_CIRCLE_EDITING_GEOBID                     = "SELECT_CIRCLE_EDITING_GEOBID"
  public static DESELECT_CIRCLE_EDITING_GEOBID                   = "DESELECT_CIRCLE_EDITING_GEOBID"
  public static ADD_INITIAL_PLACE_EDITING_GEOBID                 = "ADD_INITIAL_PLACE_EDITING_GEOBID"
  public static ADD_POSITION_TO_CACHE                            = "ADD_POSITION_TO_CACHE"
  public static RETRIEVE_ADWORDS_PLACES_META_GEOCODING_CACHE     = "RETRIEVE_ADWORDS_PLACES_META_GEOCODING_CACHE"
  public static ADD_CODE_TO_GEOCODING_QUEUE                      = "ADD_CODE_TO_GEOCODING_QUEUE"
  public static ADD_CODES_TO_GEOCODING_QUEUE                     = "ADD_CODES_TO_GEOCODING_QUEUE"
  public static REMOVE_CODE_FROM_GEOCODING_QUEUE                 = "REMOVE_CODE_FROM_GEOCODING_QUEUE"
  public static MOVE_FIRST_CODE_TO_END_OF_GEOCODING_QUEUE        = "MOVE_FIRST_CODE_TO_END_OF_GEOCODING_QUEUE"
  public static CHANGE_SELECTED_PERIOD                           = "CHANGE_SELECTED_PERIOD"
  public static RETRIEVING_INVENTORY                             = "RETRIEVING_INVENTORY"
  public static RETRIEVING_ALL_LANG_INVENTORY                    = "RETRIEVING_ALL_LANG_INVENTORY"
  public static RETRIEVE_INVENTORY_FOR_ACCOUNT                   = "RETRIEVE_INVENTORY_FOR_ACCOUNT"
  public static RETRIEVE_ALL_LANG_INVENTORY_FOR_ACCOUNT          = "RETRIEVE_ALL_LANG_INVENTORY_FOR_ACCOUNT"
  public static RETRIEVING_FEEDS                                 = "RETRIEVING_FEEDS"
  public static RETRIEVE_FEEDS                                   = "RETRIEVE_FEEDS"
  public static RETRIEVING_AVAILABLE_FEEDS                       = "RETRIEVING_AVAILABLE_FEEDS"
  public static RETRIEVE_AVAILABLE_FEEDS                         = "RETRIEVE_AVAILABLE_FEEDS"
  public static RETRIEVING_FEEDS_SIGNED_URLS                     = "RETRIEVING_FEEDS_SIGNED_URLS"
  public static RETRIEVE_FEEDS_SIGNED_URLS                       = "RETRIEVE_FEEDS_SIGNED_URLS"
  public static ADD_INVENTORY_TO_FRIENDS                         = "ADD_INVENTORY_TO_FRIENDS"
  public static CLEAR_INVENTORY_FROM_FRIENDS                     = "CLEAR_INVENTORY_FROM_FRIENDS"
  public static RETRIEVE_AW_CAMPAIGNS_STATS_FOR_ACCOUNTID        = "RETRIEVE_AW_CAMPAIGNS_STATS_FOR_ACCOUNTID"
  public static RETRIEVE_CAMPAIGNS_BY_PAGE_FOR_ACCOUNTID         = "RETRIEVE_CAMPAIGNS_BY_PAGE_FOR_ACCOUNTID"
  public static RETRIEVING_CAMPAIGNS                             = "RETRIEVING_CAMPAIGNS"
  public static ADD_FETCHED_ACCOUNTIDS_OF_BUDGETS_FOR_STRATEGIES = "ADD_FETCHED_ACCOUNTIDS_OF_BUDGETS_FOR_STRATEGIES"
  public static RETRIEVE_BUDGET_FOR_STRATEGIES                   = "RETRIEVE_BUDGET_FOR_STRATEGIES"
  public static REMOVE_FETCHED_ACCOUNTIDS_NOT_LOADED             = "REMOVE_FETCHED_ACCOUNTIDS_NOT_LOADED"
  public static PROVIDE_SCREEN_SIZE                              = "PROVIDE_SCREEN_SIZE"
  public static RETRIEVE_USERS_LIST                              = "RETRIEVE_USERS_LIST"
  public static RETRIEVE_USER_PERMISSIONS                        = "RETRIEVE_USER_PERMISSIONS"
  public static RETRIEVE_USER_PERMISSIONS_PROFILES               = "RETRIEVE_USER_PERMISSIONS_PROFILES"
  public static RETRIEVING_USER_PERMISSIONS                      = "RETRIEVING_USER_PERMISSIONS"
  public static RETRIEVING_USERS_LIST                            = "RETRIEVING_USERS_LIST"
  public static RESET_INITIALKEYWORDS_ON_STATE                   = "RESET_INITIALKEYWORDS_ON_STATE"
  public static RETRIEVE_BUDGETS_FOR_ACCOUNTID                   = "RETRIEVE_BUDGETS_FOR_ACCOUNTID"
  public static RETRIEVING_BUDGETS_FOR_ACCOUNTID                 = "RETRIEVING_BUDGETS_FOR_ACCOUNTID"
  public static RETRIEVE_BUDGET_PLANS_FOR_ACCOUNTID              = "RETRIEVE_BUDGET_PLANS_FOR_ACCOUNTID"
  public static RETRIEVING_BUDGET_PLANS_FOR_ACCOUNTID            = "RETRIEVING_BUDGET_PLANS_FOR_ACCOUNTID"
  public static CAST_FRIEND_TO_INVENTORY_FRIEND                  = "CAST_FRIEND_TO_INVENTORY_FRIEND"
  public static UPDATE_CAMPAIGN_CHILDREN_LOADED                  = 'UPDATE_CAMPAIGN_CHILDREN_LOADED'
  public static DECLARE_CAMPAIGN_CHILDREN_LOADING                = "DECLARE_CAMPAIGN_CHILDREN_LOADING"
  public static FALLBACK_CAMPAIGN_CHILDREN_LOADING_FAILURE       = "FALLBACK_CAMPAIGN_CHILDREN_LOADING_FAILURE"
  public static RETRIEVE_IMPRESSIONS_BY_DEVICE                   = "RETRIEVE_IMPRESSIONS_BY_DEVICE"
  public static RETRIEVING_IMPRESSIONS_BY_DEVICE                 = "RETRIEVING_IMPRESSIONS_BY_DEVICE"
  public static RETRIEVE_AW_SEARCHTERMS_STATS_OF_ACCOUNT         = "RETRIEVE_AW_SEARCHTERMS_STATS_OF_ACCOUNT"
  public static RETRIEVE_BING_SEARCHTERMS_STATS_OF_ACCOUNT       = "RETRIEVE_BING_SEARCHTERMS_STATS_OF_ACCOUNT"
  public static ADD_REPORT_TEMPLATE                              = "ADD_REPORT_TEMPLATE"
  public static ADD_REPORT_TEMPLATES                             = "ADD_REPORT_TEMPLATES"
  public static REMOVE_REPORT_TEMPLATE                           = "REMOVE_REPORT_TEMPLATE"
  public static EDIT_TEMPLATE                                    = "EDIT_TEMPLATE"
  public static EDIT_TEMPLATE_COLUMNS                            = "EDIT_TEMPLATE_COLUMNS"
  public static EDIT_TEMPLATE_NAME                               = "EDIT_TEMPLATE_NAME"
  public static EDIT_TEMPLATE_SIZE_PER_PAGE                      = "EDIT_TEMPLATE_SIZE_PER_PAGE"
  public static EDIT_TEMPLATE_DEFAULT_SORTED_COLUMN              = "EDIT_TEMPLATE_DEFAULT_SORTED_COLUMN"
  public static EDIT_TEMPLATE_DEFAULT_SORTED_ORDER               = "EDIT_TEMPLATE_DEFAULT_SORTED_ORDER"
  public static EDIT_TEMPLATE_DEFAULT_FILTER                     = "EDIT_TEMPLATE_DEFAULT_FILTER"
  public static RETRIEVE_MONTHLY_BUDGET_STATS_FOR_ACCOUNTID      = "RETRIEVE_MONTHLY_BUDGET_STATS_FOR_ACCOUNTID"
  public static RETRIEVE_WEB_LEADS_OF_ACCOUNT                    = "RETRIEVE_WEB_LEADS_OF_ACCOUNT"
  public static RETRIEVING_WEB_LEADS_OF_ACCOUNT                  = "RETRIEVING_WEB_LEADS_OF_ACCOUNT"
  public static RETRIEVE_USER_NAVIGATION_OF_ACCOUNT              = "RETRIEVE_USER_NAVIGATION_OF_ACCOUNT"
  public static RETRIEVING_USER_NAVIGATIONS_OF_ACCOUNT           = "RETRIEVING_USER_NAVIGATIONS_OF_ACCOUNT"
  public static RETRIEVE_ADS_STATS_OF_ACCOUNT                    = "RETRIEVE_ADS_STATS_OF_ACCOUNT"
  public static RETRIEVE_AW_KEYWORDS_STATS_OF_ACCOUNT            = "RETRIEVE_AW_KEYWORDS_STATS_OF_ACCOUNT"
  public static RETRIEVE_AW_GEOBIDS_STATS_OF_ACCOUNT             = "RETRIEVE_AW_GEOBIDS_STATS_OF_ACCOUNT"
  public static RETRIEVE_BUDGETS_STATS_BY_DAY                    = "RETRIEVE_BUDGETS_STATS_BY_DAY"
  public static RETRIEVE_BUDGETS_STATS_BY_MONTH                  = "RETRIEVE_BUDGETS_STATS_BY_MONTH"
  public static RETRIEVE_BUDGETS_STATS_BY_ONGOING_MONTH          = "RETRIEVE_BUDGETS_STATS_BY_ONGOING_MONTH"
  public static RETRIEVE_DESIRED_STATUS_EXCEPTIONS               = "RETRIEVE_DESIRED_STATUS_EXCEPTIONS"
  public static ASSIGN_DESIRED_STATUS_EXCEPTION                  = "ASSIGN_DESIRED_STATUS_EXCEPTION"
  public static REMOVE_DESIRED_STATUS_EXCEPTION                  = "REMOVE_DESIRED_STATUS_EXCEPTION"
  public static RETRIEVE_DESIRED_GEOBID_EXCEPTIONS               = "RETRIEVE_DESIRED_GEOBID_EXCEPTIONS"
  public static RETRIEVING_DESIRED_GEOBID_EXCEPTIONS             = "RETRIEVING_DESIRED_GEO_EXCEPTIONS"
  public static RETRIEVING_DESIRED_STATUS_EXCEPTIONS             = "RETRIEVING_DESIRED_STATUS_EXCEPTIONS"
  public static REMOVE_DESIRED_GEOBID_EXCEPTION                  = "REMOVE_DESIRED_GEOBID_EXCEPTION"
  public static SAVE_NEW_BUDGET_PLAN_PART_VALUE                  = "SAVE_NEW_BUDGET_PLAN_PART_VALUE"
  public static RECEIVE_APPLIERS                                 = "RECEIVE_APPLIERS"
  public static RECEIVE_APPLIERS_FAILED                          = "RECEIVE_APPLIERS_FAILED"
  public static RETRIEVING_APPLIERS                              = "RETRIEVING_APPLIERS"
  public static BACKGROUND_COMPILE_SOLD_VEHICLES                 = "BACKGROUND_COMPILE_SOLD_VEHICLES"
  public static BACKGROUND_COMPILING_SOLD_VEHICLES               = "BACKGROUND_COMPILING_SOLD_VEHICLES"
  public static CREATING_USER                                    = "CREATING_USER"
  public static USER_CREATED                                     = "USER_CREATED"
  public static RETRIEVING_PERMISSION_PROFILE                    = "RETRIEVING_PERMISSION_PROFILE"
  public static PERMISSION_PROFILE_RETRIEVED                     = "PERMISSION_PROFILE_RETRIEVED"
  public static STORE_USERS_DESCRIPTIONS                         = "STORE_USERS_DESCRIPTIONS"
  public static SAVE_DESIRED_URL                                 = "SAVE_DESIRED_URL"
  public static CHANGE_REDIRECT_PATH_ON_ACCOUNT_SELECTION        = "CHANGE_REDIRECT_PATH_ON_ACCOUNT_SELECTION"
  public static FORGOT_PASSWORD_RETRIEVED                        = "FORGOT_PASSWORD_RETRIEVED"
  public static SET_PERMISSION_MESSAGE                           = "SET_PERMISSION_MESSAGE"
  public static RETRIEVE_USERS_PERMISSION_EXTRA_DATA             = "RETRIEVE_USERS_PERMISSION_EXTRA_DATA"
  public static RETRIEVING_USERS_PERMISSION_EXTRA_DATA           = "RETRIEVING_USERS_PERMISSION_EXTRA_DATA"
  public static RETRIEVE_WAZE_PERFORMANCE_DATA                   = "RETRIEVE_WAZE_PERFORMANCE_DATA"
  public static RETRIEVE_WAZE_DATA_NO_ACCOUNT                    = "RETRIEVE_WAZE_DATA_NO_ACCOUNT"
  public static RETRIEVING_WAZE_PERFORMANCE_DATA                 = "RETRIEVING_WAZE_PERFORMANCE_DATA"
  public static DUMP_USER                                        = "DUMP_USER"
  public static RETRIEVE_PLACES_SHAPES                           = "RETRIEVE_PLACES_SHAPES"
  public static ADD_FETCHED_SHAPE_CODES                          = "ADD_FETCHED_SHAPE_CODES"
  public static REMOVE_FETCHED_SHAPE_CODES                       = "REMOVE_FETCHED_SHAPE_CODES"
  public static RETRIEVE_VALIDATION_SHAPES                       = "RETRIEVE_VALIDATION_SHAPES"
  public static REMOVE_VALIDATION_SHAPE                          = "REMOVE_VALIDATION_SHAPE"
  public static RETRIEVE_DUPLICATE_GREEN_SHAPES                  = "RETRIEVE_DUPLICATE_GREEN_SHAPES"
  public static REMOVE_DUPLICATE_GREEN_SHAPES                    = "REMOVE_DUPLICATE_GREEN_SHAPES"
  public static RETRIEVE_UNIQUE_GREEN_SHAPES                     = "RETRIEVE_UNIQUE_GREEN_SHAPES"
  public static REMOVE_UNIQUE_GREEN_SHAPE                        = "REMOVE_UNIQUE_GREEN_SHAPE"
  public static RETRIEVE_AVAILABLE_SHAPES_LIST                   = "RETRIEVE_AVAILABLE_SHAPES_LIST"
  public static RETRIEVE_SHAPES_PENDING_SIMPLIFICATION           = "RETRIEVE_SHAPES_PENDING_SIMPLIFICATION"
  public static REMOVE_SHAPE_PENDING_SIMPLIFICATION              = "REMOVE_SHAPE_PENDING_SIMPLIFICATION"
  public static FLUSH_SHAPES_PENDING_SIMPLIFICATION              = "FLUSH_SHAPES_PENDING_SIMPLIFICATION"
  public static RETRIEVE_SHAPES_WITH_SAME_CODE                   = "RETRIEVE_SHAPES_WITH_SAME_CODE"
  public static REMOVE_SHAPES_WITH_SAME_CODE                     = "REMOVE_SHAPES_WITH_SAME_CODE"
  public static RETRIEVE_ACCOUNT_TRACKING_INFORMATION            = "RETRIEVE_ACCOUNT_TRACKING_INFORMATION"
  public static RETRIEVE_ACCOUNT_WAZE_COMPANY                    = "RETRIEVE_ACCOUNT_WAZE_COMPANY"
  public static RETRIEVING_WAZE_COMPANIES                        = "RETRIEVING_WAZE_COMPANIES"
  public static RETRIEVE_WAZE_COMPANIES                          = "RETRIEVE_WAZE_COMPANIES"
  public static RETRIEVING_USER_PREFERENCES                      = "RETRIEVING_USER_PREFERENCES"
  public static RETRIEVE_USER_PREFERENCES                        = "RETRIEVE_USER_PREFERENCES"
  public static RETRIEVE_TARGET_USER_PREFERENCES                 = "RETRIEVE_TARGET_USER_PREFERENCES"
  public static RETRIEVING_ACCOUNT_PREFERENCES                   = "RETRIEVING_ACCOUNT_PREFERENCES"
  public static RETRIEVE_ACCOUNT_PREFERENCES                     = "RETRIEVE_ACCOUNT_PREFERENCES"
  public static RETRIEVING_BIDDING_STRATEGIES                    = "RETRIEVING_BIDDING_STRATEGIES"
  public static RETRIEVE_BIDDING_STRATEGIES                      = "RETRIEVE_BIDDING_STRATEGIES"
  public static RETRIEVING_GRAMMAR_BOOST_REPLACEMENTS            = "RETRIEVING_GRAMMAR_BOOST_REPLACEMENTS"
  public static RETRIEVE_GRAMMAR_BOOST_REPLACEMENTS              = "RETRIEVE_GRAMMAR_BOOST_REPLACEMENTS"
  public static RETRIEVE_GRAMMAR_BOOST_TEST                      = "RETRIEVE_GRAMMAR_BOOST_TEST"
  public static RETRIEVING_GRAMMAR_BOOST_TEST                    = "RETRIEVING_GRAMMAR_BOOST_TEST"
  public static STORE_GRAMMAR_BOOST_ERROR                        = "STORE_GRAMMAR_BOOST_ERROR"
  public static RETRIEVING_ADS_STATISTICS                        = "RETRIEVING_ADS_STATISTICS"
  public static RETRIEVING_BUDGETS_STATISTICS                    = "RETRIEVING_BUDGETS_STATISTICS"
  public static RETRIEVING_CAMPAIGNS_STATISTICS                  = "RETRIEVING_CAMPAIGNS_STATISTICS"
  public static RETRIEVING_GEOBIDS_STATISTICS                    = "RETRIEVING_GEOBIDS_STATISTICS"
  public static RETRIEVING_IMPRESSIONS_BY_DEVICE_STATISTICS      = "RETRIEVING_IMPRESSIONS_BY_DEVICE_STATISTICS"
  public static RETRIEVING_KEYWORDS_STATISTICS                   = "RETRIEVING_KEYWORDS_STATISTICS"
  public static RETRIEVING_SEARCHTERMS_STATISTICS                = "RETRIEVING_SEARCHTERMS_STATISTICS"
  public static RETRIEVE_IF_BUDGET_PLAN_IS_ACTIVE                = "RETRIEVE_IF_BUDGET_PLAN_IS_ACTIVE"
  public static RETRIEVING_POSSIBLE_WHITE_LABELS                 = "RETRIEVING_POSSIBLE_WHITE_LABELS"
  public static RETRIEVE_POSSIBLE_WHITE_LABELS                   = "RETRIEVE_POSSIBLE_WHITE_LABELS"
  public static RETRIEVING_DISPLAY_CAMPAIGNS                     = "RETRIEVING_DISPLAY_CAMPAIGNS"
  public static RETRIEVE_DISPLAY_CAMPAIGNS                       = "RETRIEVE_DISPLAY_CAMPAIGNS"
  public static RETRIEVING_DISPLAY_CAMPAIGNS_STATISTICS          = "RETRIEVING_DISPLAY_CAMPAIGNS_STATISTICS"
  public static RETRIEVE_DISPLAY_CAMPAIGNS_STATISTICS            = "RETRIEVE_DISPLAY_CAMPAIGNS_STATISTICS"
  public static RETRIEVE_PLACEMENTS_STATISTICS                   = "RETRIEVE_PLACEMENTS_STATISTICS"
  public static RETRIEVING_PLACEMENTS_STATISTICS                 = "RETRIEVING_PLACEMENTS_STATISTICS"
  public static RETRIEVE_CLEANER_REFERENCE                       = "RETRIEVE_CLEANER_REFERENCE"
  public static RETRIEVING_CLEANER_REFERENCE                     = "RETRIEVING_CLEANER_REFERENCE"
  public static RETRIEVING_MARKETING_EVENTS                      = "RETRIEVING_MARKETING_EVENTS"
  public static RETRIEVE_MARKETING_EVENTS                        = "RETRIEVE_MARKETING_EVENTS"
  public static START_EDITING_MARKETING_EVENT                    = "START_EDITING_MARKETING_EVENT"
  public static UPDATE_EDITING_MARKETING_EVENT_TITLE             = "UPDATE_EDITING_MARKETING_EVENT_TITLE"
  public static UPDATE_EDITING_MARKETING_EVENT_LANG              = "UPDATE_EDITING_MARKETING_EVENT_LANG"
  public static UPDATE_EDITING_MARKETING_EVENT_DATES             = "UPDATE_EDITING_MARKETING_EVENT_DATES"
  public static UPDATE_EDITING_MARKETING_EVENT_BOUNDARIES        = "UPDATE_EDITING_MARKETING_EVENT_BOUNDARIES"
  public static UPDATE_EDITING_MARKETING_EVENT_GROUP             = "UPDATE_EDITING_MARKETING_EVENT_GROUP"
  public static UPDATE_EDITING_MARKETING_EVENT_ADD_BOUNDARY      = "UPDATE_EDITING_MARKETING_EVENT_ADD_BOUNDARY"
  public static UPDATE_EDITING_MARKETING_EVENT_REMOVE_BOUNDARY   = "UPDATE_EDITING_MARKETING_EVENT_REMOVE_BOUNDARY"
  public static SET_EDITING_MARKETING_EVENT_APPROACHES           = "SET_EDITING_MARKETING_EVENT_APPROACHES"
  public static DELETE_SPECIFIC_MARKETING_EVENT                  = "DELETE_SPECIFIC_MARKETING_EVENT"
  public static SAVING_MARKETING_EVENT_ELEMENT                   = "SAVING_MARKETING_EVENT_ELEMENT"
  public static FAILED_SAVING_MARKETING_EVENT_ELEMENT            = "FAILED_SAVING_MARKETING_EVENT_ELEMENT"
  public static DELETING_MARKETING_EVENT_ELEMENT                 = "DELETING_MARKETING_EVENT_ELEMENT"
  public static CLEAR_MARKETING_EVENT_CREATE_COPY                = "CLEAR_MARKETING_EVENT_CREATE_COPY"
  public static SET_MARKETING_EVENT_LATEST_COPY_ID               = "SET_MARKETING_EVENT_LATEST_COPY_ID"
  public static RETRIEVING_AWSEARCH_AUDIENCES                    = "RETRIEVING_AWSEARCH_AUDIENCES"
  public static FAILED_RETRIEVING_AWSEARCH_AUDIENCES             = "FAILED_RETRIEVING_AWSEARCH_AUDIENCES"
  public static RETRIEVE_AWSEARCH_AUDIENCES                      = "RETRIEVE_AWSEARCH_AUDIENCES"
  public static RETRIEVE_AW_CAMPAIGNS_BASE_INFO                  = "RETRIEVE_AW_CAMPAIGNS_BASE_INFO"
  public static RETRIEVE_BING_CAMPAIGNS_BASE_INFO                = "RETRIEVE_BING_CAMPAIGNS_BASE_INFO"
  public static RETRIEVING_CAMPAIGNS_BASE_INFO                   = "RETRIEVING_CAMPAIGNS_BASE_INFO"
  public static RETRIEVING_MONTHLY_BUDGETS_STATISTICS            = "RETRIEVING_MONTHLY_BUDGETS_STATISTICS"
  public static RETRIEVE_MONTHLY_BUDGETS_STATS                   = "RETRIEVE_MONTHLY_BUDGETS_STATS"
  public static RETRIEVING_PACY_ACCOUNT                          = "RETRIEVING_PACY_ACCOUNT"
  public static FAILED_RETRIEVING_PACY_ACCOUNT                   = "FAILED_RETRIEVING_PACY_ACCOUNT"
  public static RETRIEVE_PACY_ACCOUNT                            = "RETRIEVE_PACY_ACCOUNT"
  public static RETRIEVING_PACY_SPEND_ATTEMPTS                   = "RETRIEVING_PACY_SPEND_ATTEMPTS"
  public static FAILED_RETRIEVING_PACY_SPEND_ATTEMPTS            = "FAILED_RETRIEVING_PACY_SPEND_ATTEMPTS"
  public static RETRIEVE_PACY_SPEND_ATTEMPTS                     = "RETRIEVE_PACY_SPEND_ATTEMPTS"
  public static RETRIEVING_PACY_DECISIONS                        = "RETRIEVING_PACY_DECISIONS"
  public static RETRIEVE_PACY_DECISIONS                          = "RETRIEVE_PACY_DECISIONS"
  public static FAILED_RETRIEVING_PACY_DECISIONS                 = "FAILED_RETRIEVING_PACY_DECISIONS"
  public static SAVING_PACY_GUIDELINE                            = "SAVING_PACY_GUIDELINE"
  public static ADD_PACY_GUIDELINE                               = "ADD_PACY_GUIDELINE"
  public static SAVING_PACY_RATIO                                = "SAVING_PACY_RATIO"
  public static UPDATE_PACY_RATIO                                = "UPDATE_PACY_RATIO"
  public static CLEAR_PACY_RATIO_ERROR                           = "CLEAR_PACY_RATIO_ERROR"
  public static SETUP_PACY_RATIO                                 = "SETUP_PACY_RATIO"
  public static SHOW_NAVIGATION_CONFIRMATION_MODAL               = "SHOW_NAVIGATION_CONFIRMATION_MODAL"
  public static SHOULD_BLOCK_NAVIGATION                          = "SHOULD_BLOCK_NAVIGATION"
  public static CONFIRM_NAVIGATION                               = "CONFIRM_NAVIGATION"
  public static CANCEL_NAVIGATION                                = "CANCEL_NAVIGATION"
  public static PACY_MIGRATION_ALREADY_MIGRATED                  = "PACY_MIGRATION_ALREADY_MIGRATED"
  public static PACY_MIGRATION_RUNNING                           = "PACY_MIGRATION_RUNNING"
  public static PACY_MIGRATION_DONE                              = "PACY_MIGRATION_DONE"
  public static PACY_MIGRATION_ERROR                             = "PACY_MIGRATION_ERROR"
  public static PACY_CLEAR                                       = "PACY_CLEAR"
  public static ADD_DATE                                         = "ADD_DATE"
  public static REMOVE_DATE                                      = "REMOVE_DATE"
  public static ADD_USER                                         = "ADD_USER"
  public static REMOVE_USER                                      = "REMOVE_USER"
  public static ADD_PROXYGROUP                                   = "ADD_PROXYGROUP"
  public static REMOVE_PROXYGROUP                                = "REMOVE_PROXYGROUP"
  public static CLEAR_PROXYGROUP                                 = "CLEAR_PROXYGROUP"
  public static ADD_DECISION_TYPE                                = "ADD_DECISION_TYPE"
  public static REMOVE_DECISION_TYPE                             = "REMOVE_DECISION_TYPE"
  public static CLEAR_DECISION_TYPE                              = "CLEAR_DECISION_TYPE"
  public static CLEAR_USERS                                      = "CLEAR_USERS"
  public static CLEAR_DATES                                      = "CLEAR_DATES"
  public static RETRIEVE_PACY_ACCOUNT_STATE                      = "RETRIEVE_PACY_ACCOUNT_STATE"
  public static RETRIEVING_PACY_ACCOUNT_STATE                    = "RETRIEVING_PACY_ACCOUNT_STATE"
  public static FAILED_RETRIEVING_PACY_ACCOUNT_STATE             = "FAILED_RETRIEVING_PACY_ACCOUNT_STATE"
  public static RETRIEVE_AD_GROUP_CONFIG                         = "RETRIEVE_AD_GROUP_CONFIG"
  public static RETRIEVING_AD_GROUP_CONFIG                       = "RETRIEVING_AD_GROUP_CONFIG"
  public static FAILED_RETRIEVING_AD_GROUP_CONFIG                = "FAILED_RETRIEVING_AD_GROUP_CONFIG"
  public static RETRIEVE_IDENTITY_VARIANTS                       = "RETRIEVE_IDENTITY_VARIANTS"
  public static RETRIEVING_IDENTITY_VARIANTS                     = "RETRIEVING_IDENTITY_VARIANTS"
  public static FAILED_RETRIEVING_IDENTITY_VARIANTS              = "FAILED_RETRIEVING_IDENTITY_VARIANTS"
  public static RETRIEVE_BRANDING_KEYWORDS                       = "RETRIEVE_BRANDING_KEYWORDS"
  public static RETRIEVING_BRANDING_KEYWORDS                     = "RETRIEVING_BRANDING_KEYWORDS"
  public static FAILED_RETRIEVING_BRANDING_KEYWORDS              = "FAILED_RETRIEVING_BRANDING_KEYWORDS"
  public static RETRIEVING_PACY_USERS                            = "RETRIEVING_PACY_USERS"
  public static RETRIEVE_PACY_USERS                              = "RETRIEVE_PACY_USERS"
  public static FAILED_RETRIEVING_PACY_USERS                     = "FAILED_RETRIEVING_USERS"
  public static SAVING_NEGATIVE_KEYWORDS                         = "SAVING_NEGATIVE_KEYWORDS"
  public static SAVING_POSITIVE_KEYWORDS                         = "SAVING_POSITIVE_KEYWORDS"
  public static SAVING_IDENTITY_VARIANTS                         = "SAVING_IDENTITY_VARIANTS"
  public static SAVING_STRATEGIES                                = "SAVING_STRATEGIES"
  public static SAVING_AD_COPY_PATTERNS                          = "SAVING_AD_COPY_PATTERNS"
  public static FAILED_SAVING_AD_COPY_PATTERNS                   = "FAILED_SAVING_AD_COPY_PATTERNS"
  public static FAILED_SAVING_POSITIVE_KEYWORDS                  = "FAILED_SAVING_POSITIVE_KEYWORDS"
  public static CHANGE_BRANDING_LANGUAGE                         = "CHANGE_BRANDING_LANGUAGE"
  public static CHANGE_BRANDING_ADGROUP                          = "CHANGE_BRANDING_ADGROUP"
  public static CHANGE_BRANDING_AUDIENCEAD                       = "CHANGE_BRANDING_AUDIENCEAD"
  public static UPDATE_NEWADGROUP_NAME                           = "UPDATE_NEWADGROUP_NAME"
  public static UPDATE_NEWADGROUP_KEYWORDS                       = "UPDATE_NEWADGROUP_KEYWORDS"
  public static ADD_NEWADGROUP_AUDIENCEAD                        = "ADD_NEWADGROUP_AUDIENCEAD"
  public static DELETE_AUDIENCEAD_NEWADGROUP                     = "DELETE_AUDIENCEAD_NEWADGROUP"
  public static RESET_NEWADGROUP                                 = "RESET_NEWADGROUP"
  public static CHECK_NEWADGROUP_EXISTING_AUDIENCEADS            = "CHECK_NEWADGROUP_EXISTING_AUDIENCEADS"
  public static CHANGE_AUDIENCEAD_TOEDIT                         = "CHANGE_AUDIENCEAD_TOEDIT"
  public static ADD_AUDIENCEAD_TO_AUDIENCEADS_TOEDIT             = "ADD_AUDIENCEAD_TO_AUDIENCEADS_TOEDIT"
  public static DELETE_AUDIENCEAD_FROM_AUDIENCEADS_TOEDIT        = "DELETE_AUDIENCEAD_FROM_AUDIENCEADS_TOEDIT"
  public static CREATING_NEW_ADGROUP                             = "CREATING_NEW_ADGROUP"
  public static FAILED_CREATING_ADGROUP                          = "FAILED_CREATING_ADGROUP"
  public static DELETING_ADGROUP                                 = "DELETING_ADGROUP"
  public static FAILED_DELETING_ADGROUP                          = "FAILED_DELETING_ADGROUP"
  public static EDITING_AUDIENCEADS                              = "EDITING_AUDIENCEADS"
  public static FAILED_TO_EDIT_AUDIENCEADS                       = "FAILED_TO_EDIT_AUDIENCEADS"
  public static RETRIEVE_BING_BUDGETS_STATS                      = "RETRIEVE_BING_BUDGETS_STATS"
  public static DEV_CHANGE_WHITELABEL_DEV                        = "DEV_CHANGE_WHITELABEL_DEV"
  public static PUSH_NEW_PACY_CONFIGS_ACTION                     = "PUSH_NEW_PACY_CONFIGS_ACTION"
  public static TRYING_NEW_PACY_CONFIGS_ACTION                   = "TRYING_NEW_PACY_CONFIGS_ACTION"
  public static UPDATE_PACY_ACCOUNT                              = "UPDATE_PACY_ACCOUNT"
  public static FAILED_UPDATING_PACY_ACCOUNT                     = "FAILED_UPDATING_PACY_ACCOUNT"
  public static MOVE_PROXY_SIMULATION                            = "MOVE_PROXY_SIMULATION"
  public static TRYING_PROXY_MOVE_SIMULATION                     = "TRYING_PROXY_MOVE_SIMULATION"
  public static FAILED_SIMULATION_MOVING_PROXY                   = "FAILED_SIMULATION_MOVING_PROXY"
  public static PACY_CONFIGS_ACCOUNT_CHANGE                      = "PACY_CONFIGS_ACCOUNT_CHANGE"
  public static SAVING_NEW_PACY_CONFIGS                          = "SAVING_NEW_PACY_CONFIGS"
  public static RETRIEVE_PACY_DECISIONS_AFTER_SAVING             = "RETRIEVE_PACY_DECISIONS_AFTER_SAVING"
  public static RETRIEVING_BING_GEO_MAPPING                      = "RETRIEVING_BING_GEO_MAPPING"
  public static RETRIEVE_BING_GEO_MAPPING                        = "RETRIEVE_BING_GEO_MAPPING"
  public static RETRIEVE_BING_GEOBID_STATS_OF_ACCOUNT            = "RETRIEVE_BING_GEOBID_STATS_OF_ACCOUNT"
  public static RETRIEVE_BING_CAMPAIGNS_STATS_FOR_ACCOUNTID      = "RETRIEVE_BING_CAMPAIGNS_STATS_FOR_ACCOUNTID"
  public static RETRIEVE_BING_KEYWORDS_STATS_OF_ACCOUNT          = "RETRIEVE_BING_KEYWORDS_STATS_OF_ACCOUNT"
  public static RETRIEVING_TARGET_USER_PREFERENCES               = "RETRIEVING_TARGET_USER_PREFERENCES"
  public static SET_TARGET_USER                                  = "SET_TARGET_USER"
  public static ADD_BRANDING_DEFAULT_AD_SET                      = "ADD_BRANDING_DEFAULT_AD_SET"
  public static RETRIEVING_BRANDING_DEFAULT_AD_SET               = "RETRIEVING_BRANDING_DEFAULT_AD_SET"
  public static FAILED_CREATING_IDENTITY_VARIANTS                = "FAILED_CREATING_IDENTITY_VARIANTS"
  public static RETRIEVE_FACEBOOK_BUDGETS_STATS                  = "RETRIEVE_FACEBOOK_BUDGETS_STATS"
  public static RETRIEVING_PACY_POSSIBLE_AGENTS                  = "RETRIEVING_PACY_POSSIBLE_AGENTS"
  public static RETRIEVE_PACY_POSSIBLE_AGENTS                    = "RETRIEVE_PACY_POSSIBLE_AGENTS"
  public static FAILED_RETRIEVING_PACY_POSSIBLE_AGENTS           = "FAILED_RETRIEVING_PACY_POSSIBLE_AGENTS"
  public static SETTING_USER_LOCALE                              = "SETTING_USER_LOCALE"
  public static SETTING_USER_LANGUAGE                            = "SETTING_USER_LANGUAGE"
  public static SET_USER_LOCALE                                  = "SET_USER_LOCALE"
  public static FAILED_SET_USER_LOCALE                           = "FAILED_SET_USER_LOCALE"
  public static FAILED_RETRIEVING_CAMPAIGNS_BASE_INFO            = "FAILED_RETRIEVING_CAMPAIGNS_BASE_INFO"
  public static RETRIEVING_ADCOPY_ACTIVE_STRATEGIES              = "RETRIEVING_ADCOPY_ACTIVE_STRATEGIES"
  public static RETRIEVE_ADCOPY_ACTIVE_STRATEGIES                = "RETRIEVE_ADCOPY_ACTIVE_STRATEGIES"
  public static FAILED_RETRIEVING_ADCOPY_ACTIVE_STRATEGIES       = "FAILED_RETRIEVING_ADCOPY_ACTIVE_STRATEGIES"
  public static RETRIEVING_RSA_ADCOPIES                          = "RETRIEVING_RSA_ADCOPIES"
  public static RETRIEVE_RSA_ADCOPIES                            = "RETRIEVE_RSA_ADCOPIES"
  public static FAILED_RETRIEVING_RSA_ADCOPIES                   = "FAILED_RETRIEVING_RSA_ADCOPIES"
  public static SAVING_RSA_ADCOPIES                              = "SAVING_RSA_ADCOPIES"
  public static FAILED_SAVING_RSA_ADCOPIES                       = "FAILED_SAVING_RSA_ADCOPIES"
  public static RETRIEVING_BIDDING_STRATEGY_SETTINGS             = "RETRIEVING_BIDDING_STRATEGY_SETTINGS"
  public static RETRIEVE_BIDDING_STRATEGY_SETTINGS               = "RETRIEVE_BIDDING_STRATEGY_SETTINGS"
  public static FAILED_RETRIEVING_BIDDING_STRATEGY_SETTINGS      = "FAILED_RETRIEVING_BIDDING_STRATEGY_SETTINGS"
  public static FAILED_SAVING_BIDDING_STRATEGY_SETTINGS          = "FAILED_SAVING_BIDDING_STRATEGY_SETTINGS"
  public static RETRIEVING_PACY_V0USAGE                          = "RETRIEVING_PACY_V0USAGE"
  public static RETRIEVE_PACY_V0USAGE                            = "RETRIEVE_PACY_V0USAGE"
  public static FAILED_RETRIEVING_PACY_V0USAGE                   = "FAILED_RETRIEVING_PACY_V0USAGE"
  public static RETRIEVE_PACY_V0USAGE_AFTER_SAVING               = "RETRIEVE_PACY_V0USAGE_AFTER_SAVING"
}

