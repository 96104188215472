import Actions         from "../../../model/constant/actions"
import ReducerUtils    from "../../../utils/ReducerUtils"
import AdCopyPattern   from "../../../model/Store/AdCopyPatterns/AdCopyPattern"

export const addAdCopyPattern = (adCopyPatterns, action) => {
  return {
    ...adCopyPatterns,
    [action.accountId] : action.adCopyPatterns
  }
}

interface AdCopyPatternsByAccountIds {
  [accountId:number] : AdCopyPattern[]
}
const AdCopyPatterns = ReducerUtils.createReducer<AdCopyPatternsByAccountIds>({},{
  [Actions.RETRIEVE_ADCOPIES_PATTERNS_FOR_ACCOUNTID] : addAdCopyPattern,
})

export default AdCopyPatterns
