import * as React               from 'react'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Button,
  Icon,
  Divider,
  ExpansionPanelDetails,
}                               from '@material-ui/core'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                               from 'react-localize-redux'
import { AdGroupConfigsByLang } from '../../../../model/branding/AdGroupConfigs'
import ExpandMoreIcon           from '@material-ui/icons/ExpandMore'
import AWSearch                 from '../../../../model/Store/Audiences/AWSearch'
import AudiencesTable           from './AudiencesTable'
import DialogMui                from '../../../../components/Modals/DialogMui'


interface AdGroupViewOwnProps {
  handleClickEditKeywords     : (event) => void
  setAdGroup                  : (adGroupName:string) => void
  adGroupName                 : string
  lang                        : string
  adGroupConfigsByLang        : AdGroupConfigsByLang
  handleClickDeleteAdGroup    : (adGroupName:string) => void
  handleClickEditAudienceAd   : (adGroupName:string,audienceAdName:string) => void
  handleClickDeleteAudienceAd : (adGroupName:string,audienceAdId:string) => void
  AWAudiences                 : AWSearch[]
  index                       : number
}
interface AdGroupViewProps extends AdGroupViewOwnProps {
  translate : TranslateFunction
}

const AdGroupView = (props:AdGroupViewProps) => {
  const [openDialog,setOpenDialog] = React.useState(false)
  function handleClickOpenDialog(e) {
    e.stopPropagation()
    setOpenDialog(true)
  }
  function handleClickCloseDialog() {
    setOpenDialog(false)
  }
  function deleteAdGroup() {
    props.handleClickDeleteAdGroup(props.adGroupName)
  }
  return (
    <div>
      <ExpansionPanel defaultExpanded={props.index===0} key={props.index}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          className="PanelHeader"
        >
          <Typography variant="h6">{props.adGroupName}</Typography>
          <div className="EditAdGroupButtons">
            <Button color="primary" onClick={(e)=>{props.handleClickEditKeywords(e);props.setAdGroup(props.adGroupName)}}>
              <Icon className="Icon">edit</Icon>
              <Translate id="branding.editKeywords" />
            </Button>
            <Button
              color="secondary"
              onClick={(e)=>{handleClickOpenDialog(e)}}
              disabled={Object.keys(props.adGroupConfigsByLang[props.lang]).length < 2}
            >
              <Icon className="Icon">delete</Icon>
              <Translate id="common.delete" />
            </Button>
          </div>
        </ExpansionPanelSummary>
        <Divider variant="middle" />
        <ExpansionPanelDetails className="Audiences">
          <AudiencesTable
            AWAudiences={props.AWAudiences}
            adGroupConfigsByLang={props.adGroupConfigsByLang}
            adGroupName={props.adGroupName}
            handleClickDeleteAudienceAd={props.handleClickDeleteAudienceAd}
            handleClickEditAudienceAd={props.handleClickEditAudienceAd}
            lang={props.lang}
            key={props.index}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <DialogMui
        title={props.translate('common.delete') as string}
        content={props.translate('dialog.deleteAdGroup') as string}
        confirmText={props.translate('common.yes') as string}
        cancelText={props.translate('common.cancel') as string}
        open={openDialog}
        handleClose={handleClickCloseDialog}
        handleSave={deleteAdGroup}
      />
    </div>
  )
}

export default withLocalize(AdGroupView as any) as React.ComponentType<AdGroupViewOwnProps>
