import * as React                from 'react'
import {
  HashRouter,
  Switch,
  Route,
  Redirect
}                                from 'react-router-dom'
import Branding                  from '../page/branding/'
import RoutingUtils              from "../utils/RoutingUtils"
import Permissions               from '../model/constant/Permissions'
import AccountPermissionBoundary from '../components/permissions/AccountPermissionBoundary'
import AlertModal                from "../components/Modals/Alert"
import readOnlyInjector          from "../components/permissions/readOnlyInjector"



interface BrandingRoutesOwnProps{
  prePath : string
}
interface BrandingRoutesProps extends BrandingRoutesOwnProps{
  readOnly : boolean
}

const routing = (props:BrandingRoutesProps) => {
  return (
    <AccountPermissionBoundary permissions={[Permissions.Branding_View]} >
      <HashRouter>
        <Switch>
          <Route
            path={"*/Branding/(.+)"}
            component={Branding}
          />
          <Redirect from="/" to={RoutingUtils.getFormattedUrl(props.prePath+"/Branding/Settings")}/>
        </Switch>
      </HashRouter>
      <AlertModal
        open={props.readOnly}
        title={"branding.readOnlyModal.title"}
        contentText={"branding.readOnlyModal.content"}
        buttons={["branding.readOnlyModal.button1"]}
        translate
      />
    </AccountPermissionBoundary>
  )
}

export default (
  readOnlyInjector([Permissions.Branding_Edit])(
    routing
  )
)
