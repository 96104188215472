import ReducerUtils   from "../../utils/ReducerUtils"
import Actions        from "../../model/constant/actions"

const save = (state, action) => {
  return action.whiteLabels
}

const List = ReducerUtils.createReducer<string[]>(null,{
  [Actions.RETRIEVE_POSSIBLE_WHITE_LABELS] : save
})

export default List
