import * as React from "react"
import { withRouter } from "react-router"
import RoutingUtils from "../../../../../../utils/RoutingUtils"
import DisplayCampaign from "../../../../../../model/Store/Campaign/DisplayCampaign"
import { buildFromFriend } from "../../../../../../model/Store/Campaign/SimplifiedFriend"
import GoBack from "../../../../../../components/Button/ButtonGoBack"
import StatusModalWrapper from "../../../shared/StatusModal/"
interface HeaderProps {
  object: DisplayCampaign
  goBack: any
  history //from higher order component
}

const Header = (props: HeaderProps) => {
  return (
    <>
      <GoBack onClick={() => RoutingUtils.removeLastElementOfUrl(props.history)} />
      
      <StatusModalWrapper
        accountId={props.object.accountId}
        campaignName={props.object.name}
        campaigns={[props.object]}
        clickable={false}
      />
      <h4>{props.object.name}</h4>
    </>
  )
}

export default withRouter(Header as any) as any
