import "./MapWithEditor.css"
import * as React                                   from "react"
import {connect}                                    from 'react-redux'
import {withLocalize, Translate}                    from "react-localize-redux"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
}                                                   from "@material-ui/core"
import EditCopiesAccessors                          from "../../storeAccessor/EditCopies"
import MathUtils                                    from "../../utils/MathUtils"
import {start, cancel, save}                        from "../../actions/EditGeoBid"
import {addCircle, updateCircle}                    from "../../actions/EditGeoBid"
import {addPendingPlace, addCodeToQueue}            from "../../actions/EditGeoBid"
import {addPlace, updatePlace}                      from "../../actions/EditGeoBid"
import {addInitialPlace}                            from "../../actions/EditGeoBid"
import {deleteCircle, deletePlace}                  from "../../actions/EditGeoBid"
import {selectCircle, deselectCircle}               from "../../actions/EditGeoBid"
import loadShapes                                   from "../../actions/shapes/RetrieveShapes"
import GeoMapInfo                                   from "../../model/Store/GeoBid/GeoMapInfo"
import PlaceDefinition                              from "../../model/map/PlaceDefinition"
import MapBuilder                                   from "./MapBuilder"
import Editor                                       from "./MapEditor/Editor"
import {Panel}                                      from "../Panel/Panel"
import Notice                                       from "../alert/Notice"
import ExplainedLoading                             from "../loading"
import GeoTargeting, {MultiLanguageGeoTargeting}    from "../../model/Store/GeoBid/GeoTargeting"

interface MapWithEditorComponentOwnProps{
  geoTargeting          : any//GeoTargeting
  circleOptions         : any
  editedCircleOptions   : any
  selectedCircleOptions : any
  title                 ?: string
  subtitle              ?: string
  exit                  ?: ()=>any
  saveHandler           ?: (geoMapInfo:GeoMapInfo)=>void
  buttonSaveText        ?: string
  buttonSaveInvalidText ?: string
  optionalButton        ?: any
  showPlacesWithShapes  ?: boolean
  readOnly              ?: boolean
}
interface MapWithEditorComponentProps extends MapWithEditorComponentOwnProps{
  geoMapInfo            : GeoMapInfo
  initialPlacesToAdd    : PlaceDefinition[]
  placesToAdd           : PlaceDefinition[]
  placesNeedingShape    : PlaceDefinition[]
  placesShapes          : {}
  fetchedShapeCodes     : number[]
  loadShapes            : (codes:number[])=>void
  startEditing          : ()       =>void
  cancelEditing         : ()       =>void
  saveMap               : ()       =>void
  addCircle             : (circle) =>void
  addPlace              : (place)  =>void
  addPendingPlace       : (place)  =>void
  addCodeToQueue        : (code)   =>void
  addInitialPlace       : (place)  =>void
  updateCircle          : (circle) =>void
  updatePlace           : (place)  =>void
  deleteCircle          : (circle) =>void
  deletePlace           : (place)  =>void
  selectCircle          : (circle) =>void
  deselectCircle        : ()       =>void
  translate            ?: (translateId:string)=>string
}
const makeMapStateToProps = () => {
  const geoMapInfoSelector = EditCopiesAccessors.makeGeoMapInfoSelector()
  const placesToAddSelector = EditCopiesAccessors.makeGeoMapInfoPlacesToAddSelector()
  return (state, ownProps:MapWithEditorComponentOwnProps) => {
    const geoMapInfo = geoMapInfoSelector(state, ownProps.geoTargeting)
    const places = placesToAddSelector(state, geoMapInfo)
    return {
      geoMapInfo         : geoMapInfo,
      initialPlacesToAdd : places.initialPlaces,
      placesToAdd        : places.places,
      placesNeedingShape : places.placesNeedingShape,
      placesShapes       : state.Params.GeoBids.Shape.PlacesShapes,
    }
  }
}
const mapDispatchToProps = (dispatch, ownProps:MapWithEditorComponentOwnProps) => {
  return{
    saveMap         : (ownProps.saveHandler?ownProps.saveHandler:(geoMapInfo) => dispatch(save(geoMapInfo, ownProps.geoTargeting.languages))),
    startEditing    : (geoTargeting)         => dispatch(start(geoTargeting)),
    cancelEditing   : (geoMapInfo)           => dispatch(cancel(geoMapInfo.id)),
    addCircle       : (geoMapInfo, circle)   => dispatch(addCircle(circle, geoMapInfo.id)),
    addPendingPlace : (geoMapInfo, place)    => dispatch(addPendingPlace(place, geoMapInfo.id)),
    addPlace        : (geoMapInfo, place)    => dispatch(addPlace(place, geoMapInfo.id)),
    addInitialPlace : (geoMapInfo, place)    => dispatch(addInitialPlace(place, geoMapInfo.id)),
    addCodeToQueue  : (code)                 => dispatch(addCodeToQueue(code)),
    updateCircle    : (geoMapInfo, circle)   => dispatch(updateCircle(circle, geoMapInfo.id)),
    updatePlace     : (geoMapInfo, place)    => dispatch(updatePlace(place, geoMapInfo.id)),
    deleteCircle    : (geoMapInfo, circle)   => dispatch(deleteCircle(circle, geoMapInfo.id)),
    deletePlace     : (geoMapInfo, place)    => dispatch(deletePlace(place, geoMapInfo.id)),
    selectCircle    : (geoMapInfo, circleId) => dispatch(selectCircle(circleId, geoMapInfo.id)),
    deselectCircle  : (geoMapInfo)           => dispatch(deselectCircle(geoMapInfo.id)),
    loadShapes      : (codes:number[])       => dispatch(loadShapes(codes)),
  }
}
const mergeProps = (SP,DP,ownProps) => {
  const map = SP.geoMapInfo
  return {
    ...SP,...DP,...ownProps,
    saveMap         : ()         => DP.saveMap(map),
    startEditing    : ()         => DP.startEditing(ownProps.geoTargeting),
    cancelEditing   : ()         => DP.cancelEditing(map),
    addCircle       : (circle)   => DP.addCircle(map, circle),
    addPendingPlace : (place)    => DP.addPendingPlace(map, place),
    addPlace        : (place)    => DP.addPlace(map, place),
    addInitialPlace : (place)    => DP.addInitialPlace(map, place),
    updateCircle    : (circle)   => DP.updateCircle(map, circle),
    updatePlace     : (place)    => DP.updatePlace(map, place),
    deleteCircle    : (circle)   => DP.deleteCircle(map, circle),
    deletePlace     : (place)    => DP.deletePlace(map, place),
    selectCircle    : (circle)   => DP.selectCircle(map, circle.id),
    deselectCircle  : ()         => DP.deselectCircle(map),
  }
}
interface MapWithEditorComponentState{
  saving          : boolean
  isSaveActive    : boolean
  openReset       : boolean
  openSave        : boolean
  openInvalidSave : boolean
}
class MapWithEditorComponent extends React.Component<MapWithEditorComponentProps,MapWithEditorComponentState>{
  static defaultProps = {
    optionalButton       : null,
    showPlacesWithShapes : true,
    readOnly             : false
  }
  constructor(props){
    super(props)
    this.state={
      saving          : false,
      isSaveActive    : true,
      openReset       : false,
      openSave        : false,
      openInvalidSave : false
    }
    this.saveMap          = this.saveMap.bind(this)
    this.onMapClick       = this.onMapClick.bind(this)
    this.setSaveActive    = this.setSaveActive.bind(this)
    this.handleResetClose = this.handleResetClose.bind(this)
    this.handleResetOpen  = this.handleResetOpen.bind(this)
    this.handleSaveClose  = this.handleSaveClose.bind(this)
    this.handleSaveOpen   = this.handleSaveOpen.bind(this)
  }
  UNSAFE_componentWillMount(){
    if(!this.props.geoMapInfo){
      this.props.startEditing()
    }
  }
  componentDidUpdate(){
    if(!this.props.geoMapInfo){
      this.props.startEditing()
      if(this.state.saving){this.setState({saving:false})}
    }
    if(this.props.initialPlacesToAdd.length > 0){
      this.props.initialPlacesToAdd.map(place=>this.props.addInitialPlace(place))
    }
    if(this.props.placesToAdd.length > 0){
      this.props.placesToAdd.map(place=>this.props.addPlace(place))
    }
    if(this.props.placesNeedingShape.length > 0){
      this.props.loadShapes(this.props.placesNeedingShape.map(x=>x.code))
    }
  }
  saveMap(){
    this.setState({saving:true})
    this.props.saveMap()
  }
  onMapClick(e){
    let lat = MathUtils.round(e.latLng.lat(),6)
    let lng = MathUtils.round(e.latLng.lng(),6)
    let newCircle = {
      id          : -1,
      edited      : true,
      bidModifier : 1.0,
      circleProps : {
        center   : {lat:lat, lng:lng},
        radius   : 10000, //TODO : Based on zoom
        editable : true,
      }
    }
    this.props.addCircle(newCircle)
  }
  setSaveActive(active:boolean){
    if(active != this.state.isSaveActive){this.setState({isSaveActive:active})}
  }
  handleResetClose() {
    this.setState({
      openReset : false
    })
  }
  handleResetOpen() {
    this.setState({
      openReset : true
    })
  }
  handleSaveClose() {
    this.setState({
      openSave : false,
      openInvalidSave : false
    })
  }
  handleSaveOpen() {
    if (this.props.geoMapInfo.circles.length === 0 && this.props.geoMapInfo.places.length === 0) {
      this.setState( {
        openInvalidSave : true
      })
    }
    else {
      this.setState({
        openSave : true
      })
    }
  }
  render(){
    if(!this.props.geoMapInfo){return <ExplainedLoading />}
    if(this.state.saving){return <ExplainedLoading translateId="loadings.saving"/>}
    const savedCircleOptions    = this.props.circleOptions        ?this.props.circleOptions        :{fillColor:"#01B9F5"}
    const editedCircleOptions   = this.props.editedCircleOptions  ?this.props.editedCircleOptions  :{fillColor:"#FFFF66"}
    const selectedCircleOptions = this.props.selectedCircleOptions?this.props.selectedCircleOptions:{fillColor:"#A4FBA6"}
    let configuredCircles = this.props.geoMapInfo.circles.map((circle,i)=>{
      let options
      if(this.props.geoMapInfo.selectedCircle === circle.id){options=selectedCircleOptions}
      else{options = circle.edited?editedCircleOptions:savedCircleOptions}
      return {
        ...circle,
        circleProps : {
          ...circle.circleProps,
          options : {
            ...circle.circleProps.options,
            ...options
          }
        }
      }
    })
    const includedPlaceOptions       = {icon:"../../../../assets/img/includedPlace.png"}
    const excludedPlaceOptions       = {icon:"../../../../assets/img/excludedPlace.png"}
    const editedIncludedPlaceOptions = {icon:"../../../../assets/img/includedPlaceEdited.png"}
    const editedExcludedPlaceOptions = {icon:"../../../../assets/img/excludedPlaceEdited.png"}
    let configuredPlaces = this.props.geoMapInfo.places.map((place,i)=>{
      let options
      if(place.include){options = place.edited?editedIncludedPlaceOptions:includedPlaceOptions}
      else{options = place.edited?editedExcludedPlaceOptions:excludedPlaceOptions}
      return {
        ...place,
        placeProps : {
          ...place.placeProps,
          options : {
            ...place.placeProps.options,
            ...options
          }
        }
      }
    })
    const mapProps = {
      options : {
        disableDefaultUI:true,
        zoomControl:true,
      }
    }
    return(
      <Panel title={this.props.title?this.props.title:""} subtitle={this.props.subtitle?this.props.subtitle:""}>
        <Notice text="You are not allowed to edit geo targetings" isActive={this.props.readOnly}/>
        <div className="MapWithEditor MapEditorTopButtons">
          {this.props.exit
            ? <Button color="primary" variant="outlined" className="GeoBackButton" onClick={this.props.exit}>
                <Translate id="common.goBack"/>
              </Button>
            : <></>
          }
          {this.props.optionalButton}
        </div>
        <div>
          <MapBuilder
            onMapClick={(e) => this.onMapClick(e)}
            circlesDefinitions={configuredCircles}
            placesDefinitions={configuredPlaces}
            updateCircle={this.props.updateCircle}
            mapProps={mapProps}
            shapes={this.props.showPlacesWithShapes?this.props.placesShapes:{}}
          />
        </div>
        <div className="MapWithEditor MapEditorBottomButtons">
          <div className="ResetModalButton">
            <Button variant="outlined" color="secondary" onClick={this.handleResetOpen}>
              {this.props.translate("geoTarget.reset")}
            </Button>
            <Dialog
              open={this.state.openReset}
              onClose={this.handleResetClose}
              aria-labelledby="reset-dialog-title"
              aria-describedby="reset-dialog-description"
            >
              <DialogTitle id="reset-dialog-title">{this.props.translate("geoTarget.reset")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="reset-dialog-description">
                  <Translate id="geoTarget.resetMessage"/>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleResetClose} color="secondary">
                  {this.props.translate("common.cancel")}
                </Button>
                <Button onClick={()=> {this.props.cancelEditing();this.handleResetClose()}} color="primary" autoFocus>
                  {this.props.translate("common.yes")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className={(this.props.readOnly?"readOnlyBanner":"SaveButton")}>
            <Button
              style={{color:'white'}}
              disabled={this.props.readOnly || !this.props.geoMapInfo.edited || !this.state.isSaveActive}
              variant="contained"
              color="primary"
              onClick={this.handleSaveOpen}
            >
              {this.props.buttonSaveText?this.props.buttonSaveText:this.props.translate("common.save")}
            </Button>
            <Dialog
              open={this.state.openSave}
              onClose={this.handleSaveClose}
              aria-labelledby="save-dialog-title"
              aria-describedby="save-dialog-description"
            >
              <DialogTitle id="save-dialog-title">
                {this.props.buttonSaveText?this.props.buttonSaveText:this.props.translate("geoTarget.save")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="save-dialog-description">
                  <Translate id="geoTarget.saveMessage"/>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleSaveClose} color="secondary">
                  {this.props.translate("common.cancel")}
                </Button>
                <Button onClick={()=> {this.saveMap();this.handleSaveClose()}} color="primary" autoFocus>
                  {this.props.translate("common.yes")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.openInvalidSave}
              onClose={this.handleSaveClose}
              aria-labelledby="save-dialog-title"
              aria-describedby="save-dialog-description"
            >
              <DialogTitle id="save-dialog-title">
                {this.props.buttonSaveInvalidText?this.props.buttonSaveInvalidText:this.props.translate("geoTarget.saveInvalid")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="save-dialog-description">
                  <Translate id="geoTarget.saveInvalidMessage"/>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {this.handleSaveClose(); this.props.cancelEditing()}} color="secondary">
                  {this.props.translate("geoTarget.resetDefault")}
                </Button>
                <Button onClick={()=> this.handleSaveClose()} color="primary" autoFocus>
                  {this.props.translate("geoTarget.continueEditing")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <div style={{marginTop:20}}>
          <Editor
            addCircle={this.props.addCircle}
            circles={this.props.geoMapInfo.circles}
            deleteCircle={this.props.deleteCircle}
            updateCircle={this.props.updateCircle}
            selectCircle={this.props.selectCircle}
            deselectCircle={this.props.deselectCircle}
            addPendingPlace={this.props.addPendingPlace}
            addCodeToQueue={this.props.addCodeToQueue}
            places={this.props.geoMapInfo.places}
            deletePlace={this.props.deletePlace}
            updatePlace={this.props.updatePlace}
            setSaveActive={this.setSaveActive}
          />
        </div>
      </Panel>
    )
  }
}

export const MapWithEditor = (
  withLocalize(
    connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
      MapWithEditorComponent
    )
  )
) as React.ComponentType<MapWithEditorComponentOwnProps>
