import './Viewer.css'
import * as React from "react"
import ViewChanger from "./ViewChanger"
import { Panel } from "../Panel/Panel"

export interface Views {
  [key: string]: View
}
interface View {
  display: string
  name: string
  component: any
}
interface ViewerProps {
  object: any    //object that you want to view
  header: any    //Component that take the object as a parameter
  views: Views  //components that take the object as a parameter
  defaultView: string
  headerClasses?: string
  onViewChange?: (viewName: string) => void
}
interface ViewerState {
  currentViewName: string
}
export class Viewer extends React.Component<ViewerProps, ViewerState>{
  static defaultProps = {
    headerClasses: "",
    activeLanguage: "",
  }
  constructor(props) {
    super(props)
    this.state = {
      currentViewName: props.defaultView,
    }
    this.changeView = this.changeView.bind(this)
  }
  changeView(viewName: string) {
    if (this.props.onViewChange) { this.props.onViewChange(viewName) }
    this.setState({ currentViewName: viewName })
  }
  render() {
    const HeaderComponent = this.props.header
    const CurrentView = this.props.views[this.state.currentViewName].component
    return (

      <Panel>
        <div className="ViewerComponent">
          <div className={"HeaderViewer " + this.props.headerClasses}>
            <HeaderComponent object={this.props.object} />
            <div className='view-changer'>
              {Object.keys(this.props.views).length > 1 &&
                <ViewChanger
                  views={this.props.views}
                  currentView={this.state.currentViewName}
                  changeView={this.changeView}
                />
              }
            </div>
          </div>
          <div className="ViewerWithoutButton">
            <CurrentView object={this.props.object} changeView={this.changeView} />
          </div>
        </div>
      </Panel>
    )
  }
}
