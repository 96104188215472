import "./index.css"
import * as React                      from "react"
import {connect}                       from "react-redux"
import {withRouter}                    from "react-router"
import {withLocalize, Translate}       from "react-localize-redux"
import {MultiLanguageGeoTargeting}     from "../../model/Store/GeoBid/GeoTargeting"
import Permissions                     from "../../model/constant/Permissions"
import RoutingUtils                    from "../../utils/RoutingUtils"
import GeoBidAccessors                 from "../../storeAccessor/GeoBid"
import {Button}                        from "carbon-components-react"
import {MapWithEditor}                 from "../../components/Map/MapWithEditor"
import {MapDisplayOnly}                from "../../components/Map/MapDisplayOnly"
import {Panel}                         from "../../components/Panel/Panel"
import ExplainedLoading                from "../../components/loading"
import UserPermissionBoundary          from "../../components/permissions/UserPermissionBoundary"
import AccountPermissionBoundary       from "../../components/permissions/AccountPermissionBoundary"

interface GeoBidsProps{
  geoTargetings        : MultiLanguageGeoTargeting[]
  accountId            : number
  retrievingTargetings : boolean
  //from HOC
  translate            : (translateId:string)=>string
  history
}
const makeMapStateToProps = () => {
  const geoSelector = GeoBidAccessors.makeUniqueGeoWithoutLangSelector()
  return (state) => {
    const accountId = state.Accounts.selected
    return {
      geoTargetings        : geoSelector(state),
      accountId            : accountId,
      retrievingTargetings : state.Params.GeoBids.Retrieving,
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
  }
}
interface GeoBidsState{
  selectedGeo : number
}
const formatMatcher = (matcher):string => {
  let type = matcher.vehicleType?matcher.vehicleType:""
  let state = matcher.state?matcher.state:""
  let group = matcher.group
  return type+"  "+state+"  "+group
}
const editedCircleOptions = {fillColor:"#FFFF66", strokeColor:"#FF9900", strokeWeight:"1"}
const selectedCircleOptions = {fillColor:"#A4FBA6", strokeColor:"#4AE54A", strokeWeight:"1"}
class GeoBidsComponent extends React.Component<GeoBidsProps, GeoBidsState>{
  constructor(props){
    super(props)
    this.state = {selectedGeo: -1}
    this.selectGeo = this.selectGeo.bind(this)
  }
  componentDidUpdate(prevProps){
    if(prevProps.accountId != this.props.accountId){
      this.setState({selectedGeo:-1})
    }
  }

  selectGeo(index){this.setState({selectedGeo:index})}
  render(){
    if(this.props.retrievingTargetings){return <ExplainedLoading translateId="loadings.geoTargeting"/>}
    const circleOptions = {fillColor:"#01B9F5", strokeColor:"#012B74", strokeWeight:"1"}
    if(this.state.selectedGeo>=0 && this.state.selectedGeo < this.props.geoTargetings.length){
      return (
        <div className='GeoBidPage'>
          <Panel>
            <AccountPermissionBoundary
              permissions={[Permissions.Keystone_ChangeGeo]}
              onFailure={
                <MapWithEditor
                  geoTargeting={{...this.props.geoTargetings[this.state.selectedGeo]}}
                  title={formatMatcher(this.props.geoTargetings[this.state.selectedGeo].matcher)}
                  circleOptions={circleOptions}
                  editedCircleOptions={editedCircleOptions}
                  selectedCircleOptions={selectedCircleOptions}
                  exit={()=>this.selectGeo(-1)}
                  readOnly
                />
              }
            >
              <MapWithEditor
                geoTargeting={{...this.props.geoTargetings[this.state.selectedGeo]}}
                title={formatMatcher(this.props.geoTargetings[this.state.selectedGeo].matcher)}
                circleOptions={circleOptions}
                editedCircleOptions={editedCircleOptions}
                selectedCircleOptions={selectedCircleOptions}
                exit={()=>this.selectGeo(-1)}
              />
            </AccountPermissionBoundary>
          </Panel>
        </div>
      )
    }
    return(
      <div className='GeoBidPage'>
        <Panel title={this.props.translate("geoTarget.title")} subtitle={this.props.translate("geoTarget.subtitle")} >
          <UserPermissionBoundary
            userLevel={4}
            permissions={[]}
            onFailure={()=>null}
          >
            <Button className="CopyAndReplaceButton" onClick={()=>RoutingUtils.appendToUrl(this.props.history, "CopyAndReplace")}>
              <Translate id="geoTarget.copyAndReplace.openButton"/>
            </Button>
          </UserPermissionBoundary>
          <div className="GeoBidsParams">
            {this.props.geoTargetings.map((geoTargeting,i)=>
              <MapDisplayOnly
                key={this.props.accountId+" "+i}
                geoTargeting={geoTargeting}
                title={formatMatcher(geoTargeting.matcher)}
                onClick={()=>this.selectGeo(i)}
                circleOptions={circleOptions}
              />
            )}
          </div>
        </Panel>
      </div>
    )
  }
}

export const GeoBids = (
  withRouter(
    withLocalize(
      connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
        GeoBidsComponent
      )
    ) as any
  )
)
