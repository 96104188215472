import "./PacingRatio.css"
import * as React               from "react"
import {connect}                from "react-redux"
import ReduxActions             from "../../../../model/constant/actions"
import {updatePacingRatio}      from "../../../../actions/pacy/PacingRatio"
import {PacingRatioConfig}      from "../../../../model/pacy/PacingRatioConfig"
import PacyLoader               from "../../../../components/loaders/pacyLoader"
import PacingRatioConfigDisplay from "./PacingRatioConfigDisplay"
import PERMISSIONS              from '../../../../model/constant/Permissions'
import AccountPermissionBoundary from '../../../../components/permissions/AccountPermissionBoundary'
import {Translate}              from "react-localize-redux"
import {PacyAccount}            from "../../../../model/pacy/"

interface PacingRatioOwnProps {
}

interface PacyLoaderProps extends PacingRatioOwnProps {
  account : PacyAccount
}

const mapStateToProps = (state,ownProps)=>{
  const accountId = state.Accounts.selected
  return {
    accountId,
    pacingRatioConfig : state.Pacy.Accounts[accountId].pacingRatioConfig,
    isSaving : state.Pacy.Save.PacingRatio.status,
    isRetrievingAccount : state.Pacy.Retrieving.Accounts,
    error : state.Pacy.Save.PacingRatio.error,
    shouldBlockNavigation : state.Navigation.ShouldBlock.status
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    updatePacingRatio : (accountId:number, pacingRatioConfig:PacingRatioConfig)=>dispatch(updatePacingRatio(accountId, pacingRatioConfig)),
    clearPacyRatioError : ()=>dispatch({type: ReduxActions.CLEAR_PACY_RATIO_ERROR, status: false, error: undefined}),
    setShouldBlockNavigation : (shouldBlock:boolean, title:string, content:string)=>dispatch({type: ReduxActions.SHOULD_BLOCK_NAVIGATION, status: shouldBlock, title: title, content: content})
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>,DP:ReturnType<typeof mapDispatchToProps>,loaderProps:PacyLoaderProps)=>{
  return {...SP,...DP,...loaderProps}
}

type PacingRatioProps = ReturnType<typeof mergeProps>

const PacingRatio = (props:PacingRatioProps) => {
  const handleSetShouldBlockNavigation = React.useCallback((shouldBlock, title, content) => props.setShouldBlockNavigation(shouldBlock, title, content), [])
  return (
    <AccountPermissionBoundary
      permissions={[PERMISSIONS.Pacy_Edit_Config]}
    >
      <div className="PacingRatio">
      {props.account.enabled ?
        <PacingRatioConfigDisplay
          accountId={props.accountId}
          pacingRatioConfig={props.pacingRatioConfig}
          updatePacingRatio={(accountId, pacingRatioConfig) => props.updatePacingRatio(accountId, pacingRatioConfig)}
          isSaving={props.isSaving}
          isRetrievingAccount={props.isRetrievingAccount}
          clearPacyRatioError={() => props.clearPacyRatioError()}
          error={props.error}
          shouldBlockNavigation={props.shouldBlockNavigation}
          setShouldBlockNavigation={handleSetShouldBlockNavigation}
        />
      :
        <div className="PacyMessage">
          <p style={{fontSize: 'inherit'}}><Translate id="pacyPlanner.deactivated.generalMessage" /></p>
          <br />
          <p style={{fontSize: 'inherit'}}><Translate id="pacyPlanner.deactivated.advancedConfig" /></p>
        </div>
      }
      </div>
    </AccountPermissionBoundary>
  )
}

export default PacyLoader({})(
  connect(mapStateToProps,mapDispatchToProps,mergeProps)(PacingRatio)) as React.ComponentType<PacingRatioOwnProps>
