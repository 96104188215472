import Keystone                   from "../../utils/Keystone"
import Actions                    from "../../model/constant/actions"
import {getBiddingStrategySettings, retrieveStrategies} from "./retrieveStrategies"
import {getToken}                 from "../../storeAccessor/Generic"
import UserActions                from "../../model/constant/UserAction"
import LogBuilder                 from "../../controller/log/LogBuilder"
import ActionUtils                from "../../utils/ActionUtils";
import Headstone                  from "../../utils/Headstone";
import BidSettingsTypes           from "../../model/constant/BidSettingTypes";
import CaseUtils                  from "../../utils/CaseUtils";

export const setStrategy = (accountId:number, strategy:any) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.EDIT_STRATEGY, token, {accountId, strategy})
  let payload = {
    token     : token,
    action    : "UpdateAWStrategy",
    accountId : accountId,
    state     : strategy.vehicleState,
    ...strategy
  }

  // remove bidding strategy stuff from keystone update
  const { bidSetting, biddingStrategyType, properties, ...keystonePayload } = payload;

  Object.entries(payload).forEach(([key, value]) => {
    if (value === undefined) {
      delete payload[key];
    }
  });

  Keystone.post(
    keystonePayload,
    (data) => dispatch(retrieveStrategies(accountId)),
    (data) => console.log(data.error)
  )

  // create the payload
  let biddingStrategyPayload = {
    account_id: accountId,
    campaign_group: strategy.group,
    lang: strategy.lang,
    vehicle_state: strategy.vehicleState,
    type: strategy.biddingStrategyType,
    settings: {}
  }

  if (strategy.bidSetting === BidSettingsTypes.PORTFOLIO) {
    biddingStrategyPayload.type = BidSettingsTypes.PORTFOLIO.toUpperCase()
    biddingStrategyPayload.settings = JSON.stringify({bidding_strategy_id: strategy.biddingStrategyId})
  } else {
    // remove check values
    const {
      maxCpcCheck,
      targetCpaCheck,
      targetRoasCheck,
      ...settings
    } = strategy.properties;

    // convert to snake case for the payload
    let newSettings = CaseUtils.convertKeysToSnakeCase(settings)

    biddingStrategyPayload.type = strategy.biddingStrategyType
    biddingStrategyPayload.settings = JSON.stringify(newSettings)
  }

  // if there is something to save, send it to headstone
  ActionUtils.retryOnFailure(
    ()=>Headstone.saveBidingStrategySettings(token, biddingStrategyPayload)
  )
    .then((response) => dispatch(getBiddingStrategySettings(accountId)))
    .catch((error)=>{
      dispatch({
        type: Actions.FAILED_SAVING_BIDDING_STRATEGY_SETTINGS,
        status : true,
        message : error.message,
        accountId
      })
    })
}