import Pacy                                              from "../../utils/Pacy"
import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import {getToken}                                        from "../../storeAccessor/Generic"

const extractMessageFromError = (error:any) => {
  if(error.response && error.response.data){
    return error.response.data.error
  }
}

export const retrieveAccountState = (accountId:number, accountStateId:string) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_PACY_ACCOUNT_STATE, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Pacy.getAccountState(token, accountId, accountStateId)
  )
  .then(response=>{
    dispatch({
      type         : Actions.RETRIEVE_PACY_ACCOUNT_STATE,
      accountState : response.data.state,
      accountId,
      accountStateId,
    })
  })
  .catch((error)=>{
    console.warn(error)
    dispatch({
      type: Actions.FAILED_RETRIEVING_PACY_ACCOUNT_STATE,
      status : true,
      message : extractMessageFromError(error)
    })
  })
  .then(()=>{
    dispatch({type: Actions.RETRIEVING_PACY_ACCOUNT_STATE, status: false})
  })
}
