import "./CreateAgentParams.css"
import * as React                from "react"
import {
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Button,
  CardContent,
  Icon,
  Dialog,
  Collapse
}                                from "@material-ui/core"
import MaxSharingSlider          from "../MaxSharingSlider"
import { ProxyGroup }            from "../../../../../../model/pacy/ProxyGroup"
import BoostConfig               from "../BoostConfig"
import {
  PossibleAgent
}                                from "../../../../../../model/pacy"
import {
  Translate,
  withLocalize,
  TranslateFunction
}                                from "react-localize-redux"
import UnusedPossibleAgentSelect from "./UnusedPossibleAgentSelect"

interface CreateProxyParamsOwnProps {
  maxSharing                : number
  handleSetMaxSharing       : (newValue) => void
  selectedProxyGroupId      : string
  selectedProxyId           : string
  selectedProviderId        : string
  handleNameChange          : (event) => void
  proxyGroups               : ProxyGroup[]
  possibleAgents            : PossibleAgent[]
  handleChangeProxyGroup    : (event) => void
  handleChangeProxy         : (event) => void
  handlePossibleAgentChoice : (possibleAgent:PossibleAgent)=>void
}
interface CreateProxyParamsProps extends CreateProxyParamsOwnProps {
  translate : TranslateFunction
}

const CreateProxyParams = (props:CreateProxyParamsProps) => {

  return (
    <div className="CreateProxyParams">
      <CardContent className="CreateProxyContent">
        <div className="ProxyGroupSelection">
          <div className="SelectProxyGroup">
            <Select variant="outlined" fullWidth onChange={props.handleChangeProxyGroup} value={props.selectedProxyGroupId}>
              {props.proxyGroups.map(proxyGroup=>
                <MenuItem key={proxyGroup.id} value={proxyGroup.id}>{proxyGroup.name}</MenuItem>
              )}
            </Select>
            <FormHelperText style={{color:props.selectedProxyGroupId ? "gray" : "red"}}>
              <Translate id="pacyConfigs.selectProxyGroup" />
            </FormHelperText>
          </div>
          <Collapse in={Boolean(props.selectedProxyGroupId)}>
            <div className="SelectProxy">
              <Select variant="outlined" fullWidth onChange={props.handleChangeProxy} value={props.selectedProxyId} disabled={!props.selectedProxyGroupId}>
                {props.selectedProxyGroupId &&
                  props.proxyGroups
                  .find(proxyGroup=>proxyGroup.id == props.selectedProxyGroupId).proxies
                  .map(proxy=>
                    <MenuItem key={proxy.id} value={proxy.id}>{proxy.name}</MenuItem>
                  )
                }
              </Select>
              <FormHelperText style={{color:props.selectedProxyId ? "gray" : "red", visibility: props.selectedProxyGroupId ? "visible" : "hidden"}}>
                <Translate id="pacyConfigs.selectProxy" />
              </FormHelperText>
            </div>
          </Collapse>
        </div>
        <Collapse in={Boolean(props.selectedProxyId)}>
          <div className="SelectPossibleAgent">
            <UnusedPossibleAgentSelect
              possibleAgents={props.possibleAgents}
              proxyGroups={props.proxyGroups}
              selectedProviderId={props.selectedProviderId}
              onSelect={props.handlePossibleAgentChoice}
              disabled={!props.selectedProxyId}
              hideLabel={!props.selectedProxyId}
            />
          </div>
        </Collapse>
        <div className="MaxSharing">
          <MaxSharingSlider
            maxSharing={props.maxSharing}
            handleSharingConfirm={(event,newValue)=>props.handleSetMaxSharing(newValue)}
            handleSharingInputConfirm={props.handleSetMaxSharing}
          />
        </div>
      </CardContent>
    </div>
  )
}

export default withLocalize(CreateProxyParams as any) as React.ComponentType<CreateProxyParamsOwnProps>
