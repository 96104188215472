import WebInfo                      from "../../../model/Store/Tracking/WebInfo"
import BrowserIdCompiledWebInfo     from "../model/BrowserIdCompiledWebInfo"
import PageCompiledWebInfo          from "../model/PageCompiledWebInfo"
import ArrayUtils                   from "../../../utils/ArrayUtils"
import ObjectUtils                  from "../../../utils/ObjectUtils"
import ByBrowserId                  from "./ByBrowserId"

const KEY_SEP = " :: "

const compile = (byBrowserIds:BrowserIdCompiledWebInfo[]) : PageCompiledWebInfo[] => {
  return byBrowserIds
  .map(bbi=>{
    let byPage = {}
    for(let lead of bbi.leads){
      let key = lead.path+KEY_SEP+lead.referrer
      if(!byPage[key]){byPage[key] = []}
      byPage[key].push(lead)
    }
    return ArrayUtils.getArrayFromObject(
      ObjectUtils.mapOnObject(byPage,(key,leads)=>({
        ...bbi,
        path     : key.split(KEY_SEP)[0],
        referrer : key.split(KEY_SEP)[1],
        leads    : leads,
      }))
    )
  })
  .reduce((a,b)=>[...a,...b],[])
  .filter(x=>x.leads.length)
}
export default compile
