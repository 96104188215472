import './Campaign.css'
import * as React                from "react"
import {connect}                 from 'react-redux'
import {withLocalize, Translate, TranslateFunction} from "react-localize-redux"
import {withRouter}              from "react-router"
import RoutingUtils              from "../../../../utils/RoutingUtils"
import ObjectUtils               from "../../../../utils/ObjectUtils"
import ArrayUtils                from "../../../../utils/ArrayUtils"
import DisplayCampaign           from "../../../../model/Store/Campaign/DisplayCampaign"
import {retrieveDisplayCampaigns}from "../../../../actions/campaign/displayCampaigns"
import CampaignsAccessors        from "../../../../storeAccessor/Campaigns"
import Overview                  from "./view/Overview/Overview"
import Header                    from './view/Header/Header'
import {Viewer}                  from "../../../../components/Viewer/Viewer"
import ExplainedLoading          from "../../../../components/loading/"

interface CampaignProps {
  campaign                   : DisplayCampaign
  campaigns                  : undefined|DisplayCampaign[]
  accountId                  : number
  retrievingCampaigns        : boolean
  retrieveDisplayCampaigns   : ()=>void
  translate                  : TranslateFunction
  defaultView               ?: DisplayCampaignView
  history
}
type DisplayCampaignView = "Overview"
const makeMapStateToProps = () => {
  const campaignSelector = CampaignsAccessors.makeFindDisplayCampaignWithIdSelector()
  return (state, ownProps) => {
    const accountId =  state.Accounts.selected
    return {
      accountId           : accountId,
      campaign            : campaignSelector(state, ownProps.campaignId),
      campaigns           : state.Campaigns.Display.Campaigns[accountId],
      retrievingCampaigns : state.Campaigns.Display.Retrieving,
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    retrieveDisplayCampaigns : (accountId:number) => dispatch(retrieveDisplayCampaigns(accountId))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    retrieveDisplayCampaigns : ()=>DP.retrieveDisplayCampaigns(SP.accountId)
  }
}
const Campaign:React.SFC<CampaignProps> = (props:CampaignProps) => {
  if(!props.campaigns){
    if(!props.retrievingCampaigns){props.retrieveDisplayCampaigns()}
    return <ExplainedLoading translateId="loadings.campaigns"/>
  }
  if(!props.campaign){
    return(
      <div>
        <span>
          <Translate id="campaigns.campaignNotFound"/>
        </span>
        <br/>
        <div className="ImitateLink" onClick={()=>RoutingUtils.removeLastElementOfUrl(props.history)}>
          <Translate id="campaigns.goBackToList"/>
        </div>
      </div>
    )
  }
  const views = {
    Overview: {display:props.translate("campaigns.sections.overview") as string, name:"Overview", component:Overview },
  }
  const formattedDefaultView = props.defaultView[0].toUpperCase() + props.defaultView.slice(1).toLowerCase()
  const updateUrlWithViewName = (viewName:string) => {
    const lastElement = RoutingUtils.getLastElementOfUrl(props.history)
    const viewNames = ObjectUtils.getObjectValues(
      ObjectUtils.mapOnObject(views, (key, value)=>value.name.toLowerCase())
    )
    if(ArrayUtils.contain(viewNames, lastElement.toLowerCase())){
      RoutingUtils.replaceLastElementOfUrl(props.history, viewName)
    }
    else{
      RoutingUtils.appendToUrl(props.history, viewName)
    }
  }
  return (
    <Viewer
      key={props.defaultView}
      object={props.campaign}
      header={Header}
      views={views}
      defaultView={formattedDefaultView}
      headerClasses={"FriendViewViewerHeader"}
      onViewChange={updateUrlWithViewName}
    />
  )
}
Campaign.defaultProps = {
  defaultView : "Overview"
}
export default withLocalize(connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(withRouter(Campaign as any)))
