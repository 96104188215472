import Actions                 from "../../model/constant/actions"
import {getToken}              from "../../storeAccessor/Generic"
import ActionUtils             from "../../utils/ActionUtils"
import Headstone               from "../../utils/Headstone"
import { AxiosResponse }       from "axios"
import { HeadstoneStrategy }   from "../../model/Store/Strategy/Strategy"
import BiddingStrategySettings from "../../model/Store/Strategy/BiddingStrategySettings";
import BidSettingsTypes        from "../../model/constant/BidSettingTypes";
import BiddingStrategyTypes    from "../../model/constant/BiddingStrategyTypes";
import CaseUtils               from "../../utils/CaseUtils";

const retrieveStrategiesSuccess = (response: AxiosResponse<HeadstoneStrategy[]>, accountId: number) => {
  return {
    type       : Actions.RETRIEVE_STRATEGIES,
    accountId  : accountId,
    strategies : response.data.map(x=>({
      active              : x.active,
      budgetId            : x.budgetId,
      biddingStrategyId   : x.biddingStrategyId,
      geoTargetingType    : x.geoTargetingType,
      finalUrl            : x.finalUrl,
      vehicleType         : x.matcher.vehicleType,
      vehicleState        : x.matcher.state,
      group               : x.matcher.group,
      lang                : x.matcher.lang,
      bidSetting          : BidSettingsTypes.CAMPAIGN_LEVEL,
      biddingStrategyType : BiddingStrategyTypes.TARGET_SPEND,
      properties          : {}
    }))
  }
}

const addBiddingStrategies = (response: AxiosResponse<BiddingStrategySettings[]>, accountId: number) => {
  return {
    type       : Actions.RETRIEVE_BIDDING_STRATEGY_SETTINGS,
    accountId : accountId,
    biddingStrategies : response.data.map(x=>({
      campaignGroup       : x.campaign_group,
      lang                : x.lang,
      vehicleState        : x.vehicle_state,
      biddingStrategyType : x.type,
      bidSetting          : x.type === BidSettingsTypes.PORTFOLIO.toUpperCase() ? BidSettingsTypes.PORTFOLIO : BidSettingsTypes.CAMPAIGN_LEVEL,
      properties          : CaseUtils.convertKeysToCamelCase(JSON.parse(x.properties)),
    }))
  }
}

export const retrieveStrategies = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_STRATEGIES})
  ActionUtils.retryOnFailure(
    ()=>Headstone.getStrategies(getToken(getState()), accountId)
  )
  .then((response) => dispatch(retrieveStrategiesSuccess(response, accountId)))
  .catch((error)=> console.log(error))
}

export const getBiddingStrategySettings = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_BIDDING_STRATEGY_SETTINGS})
  ActionUtils.retryOnFailure(
      ()=>Headstone.getBidingStrategySettings(getToken(getState()), accountId)
  )
  .then((response) => dispatch(addBiddingStrategies(response, accountId)))
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_RETRIEVING_BIDDING_STRATEGY_SETTINGS,
      status : true,
      message : error.message,
      accountId
    })
  })
}