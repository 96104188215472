import ReducerUtils from "../../../utils/ReducerUtils"
import Actions from "../../../model/constant/actions"

interface FailedState {
  status  : boolean
  message : string
}

const Failed = ReducerUtils.createReducer<FailedState>({status:false, message:""},{
  [Actions.FAILED_RETRIEVING_AWSEARCH_AUDIENCES] : (state, action) => ({
    ...state,
    status : action.status,
    message : action.message
  }),
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST] : (state, _action) => ({
    ...state,
    status : false,
    message : ""
  })
})

export default Failed
