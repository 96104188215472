import * as React               from "react"
import {connect}                from 'react-redux'
import DateUtils                from "../../utils/DateUtils"
import AccountsSelector         from "../../storeAccessor/Accounts"
import loadCampaignsStat        from "../../actions/statistics/campaignsStats"
import {getGeoBid}              from "../../actions/GetGeoBid"
import loadAdsStat              from "../../actions/statistics/adsStats"
import loadSearchTermsStats     from "../../actions/statistics/searchTermsStats"
import loadKeywordsStats        from "../../actions/statistics/keywordsStats"
import loadGeoBidsStats         from "../../actions/statistics/geoBidsStats"
import getStatsOfBudgets        from "../../actions/statistics/budgetsStats"
import {retrieveV0Usage}        from "../../actions/pacy/Alerts"

const makeMapStateToProps =  () => {
  const selectedAccountSelector = AccountsSelector.makeSelectedAccountSelector()
  return (state) => {
    const accountId = state.Accounts.selected
    const selectedAccount = selectedAccountSelector(state)
    const accountAWId       = selectedAccount ? selectedAccount.AWCustomerId      : undefined
    const accountBingId     = selectedAccount ? selectedAccount.bingCustomerId    : undefined
    const accountFacebookId = selectedAccount ? selectedAccount.facebookAccountId : undefined
    return {
      accountAWId,
      accountBingId,
      accountFacebookId,
      accountId     : accountId,
      period        : state.Period,
      friends       : state.Campaigns.list[accountId],
    }
  }
}

const mapDispatchToProps = dispatch => {
  return{
    getGeoBidSettings   : (accountId:number)        => dispatch(getGeoBid(accountId)),
    getCampaignsStats   : (accountId:number, accountAWId:string, accountBingId:string, periodStart, periodEnd) =>(
      dispatch(loadCampaignsStat(accountId, accountAWId, accountBingId, {dateFrom:periodStart, dateTo:periodEnd}))
    ),
    getSearchTermsStats : (accountId:number, accountAWId:string, accountBingId:string, period)=> (
      dispatch(loadSearchTermsStats(accountId, accountAWId, accountBingId, period))
    ),
    getAdsStats         : (accountId:number,period) => dispatch(loadAdsStat(accountId, period)),
    getKeywordsStats    : (accountId:number, accountAWId:string, accountBingId:string, period)=> (
      dispatch(loadKeywordsStats(accountId, accountAWId, accountBingId, period))
    ),
    getGeoBidsStats     : (accountId:number, accountAWId:string, accountBingId:string, period) => (
      dispatch(loadGeoBidsStats(accountId, accountAWId, accountBingId, period))
    ),
    getBudgetsStats     : (accountId:number, accountAWId:string, accountBingId:string, accountFacebookId:string, period) =>(
      dispatch(getStatsOfBudgets(accountId, accountAWId, accountBingId, accountFacebookId, period))
    ),
    getV0Usage          : (accountId:number) => dispatch(retrieveV0Usage(accountId)),
  }
}
const generateStatsPayload = (accountId, period):any =>({
  accountId   : accountId,
  periodStart : DateUtils.format(period.dateFrom),
  periodEnd   : DateUtils.format(period.dateTo),
})
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    getGeoBidSettings   : () => DP.getGeoBidSettings   (SP.accountId),
    getSearchTermsStats : () => DP.getSearchTermsStats (SP.accountId, SP.accountAWId, SP.accountBingId, SP.period),
    getKeywordsStats    : () => DP.getKeywordsStats    (SP.accountId, SP.accountAWId, SP.accountBingId, SP.period),
    getAdsStats         : () => DP.getAdsStats         (SP.accountId, SP.period),
    getGeoBidsStats     : () => DP.getGeoBidsStats     (SP.accountId, SP.accountAWId, SP.accountBingId, SP.period),
    getCampaignsStats   : () => DP.getCampaignsStats   (SP.accountId, SP.accountAWId, SP.accountBingId, DateUtils.getSymmetricalPeriod(SP.period).dateFrom, SP.period.dateTo),
    getBudgetsStats     : () => DP.getBudgetsStats     (SP.accountId, SP.accountAWId, SP.accountBingId, SP.accountFacebookId, SP.period),
    getV0Usage          : () => DP.getV0Usage          (SP.accountId),
  }
}
class InitialLoadingsWorker extends React.Component<any, any>{
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (!nextState.isLoaded ) return true
    if (this.state.isLoaded != nextState.isLoaded ) return true
    if (this.state.isLoaded && (this.props.accountId != nextProps.accountId||this.props.period!=nextProps.period )){
      this.setState({isLoaded: false})
      return true
    }
    //if (this.props.friends.length != nextProps.friends.length){return true}
    return false
  }
  work(){
    if(!this.props.accountId){return}
    //if(!this.props.friends){return}
    if(!this.props.period){return}
    // We wait for friends to be loaded in store so we can dispatch this data more easily once it arrives
    //if(this.props.friends.length==0){return}
    if(!this.state.isLoaded){
      this.props.getGeoBidSettings()
      this.props.getCampaignsStats()
      this.props.getGeoBidsStats()
      this.props.getSearchTermsStats()
      this.props.getKeywordsStats()
      this.props.getBudgetsStats()
      this.props.getV0Usage()
      //this.props.getAdsStats()
      this.setState({isLoaded: true})
    }
  }
  componentDidUpdate(){
    this.work()
  }
  render(){return null}
}
export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(InitialLoadingsWorker)
