enum BUTTONS {
  ACCOUNT                       = "ACCOUNT",
  DASHBOARD                     = "DASHBOARD",
  PARAMS                        = "PARAMS",
  PROFILE                       = "PROFILE",
  CAMPAIGNS                     = "CAMPAIGNS",
  LEADS                         = "LEADS",
  REPORTS                       = "REPORTS",
  STRATEGIES                    = "STRATEGIES",
  BUILDER                       = "BUILDER",
  SEARCH                        = "SEARCH",
  REMARKETING                   = "REMARKETING",
  DISPLAY                       = "DISPLAY",
  DISPLAY_RMKT                  = "DISPLAY_RMKT",
  LEAD_ADS                      = "LEAD_ADS",
  WAZE                          = "WAZE",
  ADS_REPORT                    = "ADS_REPORT",
  BING_REPORT                   = "BING_REPORT",
  ADCOPY                        = "ADCOPY",
  KEYWORDS                      = "KEYWORDS",
  GEOTARGET                     = "GEOTARGET",
  BUDGETS                       = "BUDGETS",
  RULES                         = "RULES",
  MANAGEMENT                    = "MANAGEMENT",
  SYSTEM                        = "SYSTEM",
  CREATE_USER                   = "CREATE_USER",
  USERS                         = "USERS",
  USER_ACCOUNT                  = "USER_ACCOUNT",
  ACCOUNTS                      = "ACCOUNTS",
  AD_BUILDER                    = "AD_BUILDER",
  PACY                          = "PACY",
  BRANDING                      = "BRANDING",
  FEEDS                         = "FEEDS",
}
export default BUTTONS
