import ReducerUtils from "../../utils/ReducerUtils"
import Actions from "../../model/constant/actions"

const setFailed = (state:FailedSavingState, action) => {
  let message = action.message
  if(typeof message === "object"){
    if(message.response && message.response.data && message.response.data.error){
      //is an error 500 from keystone
      message = message.response.data.error
    }
    else if(message.message){
      //if no error from keystone, use default error message
      message = message.message
    }
  }
  if(Array.isArray(message)){
    //keystone error is sometimes
    message = message.join(", ")
  }
  if(!message || message.length === 0){
    //default message
    message = "Encountered an unknown error"
  }

  return {
    failed  : true,
    message : "Couldn't save properly: "+ message
  }
}
const reactToSaving = (state, action) => {
  if(action.saving === true){return {failed: false}} //don't want to set to false if saving just finished
  return state
}

interface FailedSavingState{
  failed   : boolean
  message ?: string
}

const FailedSaving = ReducerUtils.createReducer<FailedSavingState>({failed: false},{
  [Actions.FAILED_SAVING_MARKETING_EVENT_ELEMENT] : setFailed,
  [Actions.SAVING_MARKETING_EVENT_ELEMENT]        : reactToSaving
})

export default FailedSaving
