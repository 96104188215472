import Reporting        from "../../utils/Reporting"
import ActionUtils      from "../../utils/ActionUtils"
import FVDUtils         from "../../utils/FieldsValuesDefinitionUtils"
import Actions          from "../../model/constant/actions"
import {getToken}       from "../../storeAccessor/Generic"
import Period           from "../../model/Period"


const fieldRenaming = {
  CampaignId : "CampaignAWId",
  Date       : "Day"
}

const loadStatSuccess = (response, accountId) => {
  response.data.fields = response.data.fields.map(x=>fieldRenaming[x] || x)
  const stats = FVDUtils.decompress(response.data)
  return {
    type      : Actions.RETRIEVE_IMPRESSIONS_BY_DEVICE,
    accountId : accountId,
    stats     : stats
  }
}

export const fetchImpressionsByDevice = (accountId:number, accountAWId:string, period:Period) => (dispatch, getState) => {
  dispatch({
    type : Actions.RETRIEVING_IMPRESSIONS_BY_DEVICE,
    status : true
  })
  ActionUtils.retryOnFailure(()=>
    Reporting.getImpressionsByDevice(
      getToken(getState()),
      accountAWId,
      period
    )
  )
  .then(
    response => {
      dispatch(loadStatSuccess(response.data, accountId))
      dispatch({
        type : Actions.RETRIEVING_IMPRESSIONS_BY_DEVICE,
        status : false
      })
    },
    error => {
      console.log(error)
      dispatch({
        type : Actions.RETRIEVING_IMPRESSIONS_BY_DEVICE,
        status : false
      })
    }
  )
}
