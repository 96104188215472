import AxiosWrapper from "./AxiosWrapper"

export interface LoginReturn {
  error ?: string
  data : {
    rememberMe ?: string
    token : string
    tokenValidUntil : string
    user : {
      id          : number
      firstName   : string
      lastName    : string
      officePhone : string
      cellPhone   : string
      email       : string
      userLevel   : number
    }
    permissions : Array<{
      ID          : string
      UserID      : string
      AccountID   : null | unknown // String or number?
      Permissions : string[]
    }>
  }
}

export default class Authentification extends AxiosWrapper{
  protected static getApiUrl(){
    return process.env.REACT_APP_AUTH_URL + '/auth/index.php'
  }
}
