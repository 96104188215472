import * as React                                      from "react"
import Items                                           from "../../model/constant/ItemTypes"
import {DropTarget}                                    from "react-dnd"
import Input, {
  LengthLimitedTextInputProps,
  ErrorData as ErrorDataPiped,
}                                                      from "./LengthLimitedTextInput"

const target = {
  drop(props, monitor){
    let newText = (props.defaultValue||"")+" "+monitor.getItem()[props.dragTextKey]
    newText = newText.replace(/\s{2,}/," ").trim()
    if(props.onBlur){props.onBlur(newText)}
    if(props.onChange){props.onChange(newText)}
  }
}

const collect = (connect, monitor) => {
  return {
    connectDropTarget : connect.dropTarget(),
    isOver            : monitor.isOver()
  }
}

interface TargetLengthLimitedTextInputOwnProps extends LengthLimitedTextInputProps{
  dragTextKey       : string
}
interface TargetLengthLimitedTextInputProps extends TargetLengthLimitedTextInputOwnProps{
  connectDropTarget
}
interface TargetLengthLimitedTextInputState{
  text : string
}

//Wraps LengthLimitedTextInput to be a drop target for drag and drop
const TargetLengthLimitedTextInput = (props:TargetLengthLimitedTextInputProps) => {
  const {dragTextKey, connectDropTarget, ...inputProps} = props
  return connectDropTarget(
    <div><Input {...inputProps}/></div>
  )
}

export type ErrorData = ErrorDataPiped
export default DropTarget(Items.TAG, target, collect)(TargetLengthLimitedTextInput) as React.ComponentType<TargetLengthLimitedTextInputOwnProps>
