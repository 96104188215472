import * as React                from "react"
import {withLocalize,Translate}  from "react-localize-redux"
import {
  Selector,
  SelectorStandardName
}                                from '../../../model/engagementRules/Selector'
import {ValueTypeMapping}        from '../../../model/engagementRules/Selector'
import {SelectorOperatorTextId}  from '../../../model/engagementRules/Selector'
import {SelectorOperator}        from '../../../model/engagementRules/Selector'
import CleanerRow                from "../../../model/CleanerRow"

interface SelectedSelectorProps{
  selector        : Selector
  cleanerMakes   ?: CleanerRow[]
  changeOperator  : (operator:SelectorOperator)=>void
  removeSelector ?: ()=>void
  getUserName    ?: (userId:number)=>string
  onChange       ?: (content:string)=>void
  translate      ?: (translateId:string)=>string
}


const SelectedSelector:React.SFC<SelectedSelectorProps> = (props:SelectedSelectorProps)=>{
  // const userName = props.selector.addedBy===0?"Unknown":props.getUserName(props.selector.addedBy)
  const sortedMakes = props.cleanerMakes.sort((a, b) => a.DisplayMake > b.DisplayMake ? 1 : -1)

  return (
    <div className='SelectedSelector'>
      <div key={"and"} className='SelectorGroup_AND_Widget'>
        <span>
          <Translate id="rules.AND"/>
        </span>
      </div>
      <div className='SelectedSelectorGrid'>
        <div className="GridHalf HalfLeft">
          <span key="nicename" className="SelectorNicename">
            <Translate id={props.selector.niceNameId}/>
          </span>
          <select
            defaultValue={props.selector.operator+""}
            onChange={(e)=>props.changeOperator(e.target.value as SelectorOperator)}
          >
            {props.selector.operators.map(op=>{
              return (
                <option value={""+op} key={""+op}>
                  {props.translate(SelectorOperatorTextId[op]())}
                </option>
              )
            })}
          </select>
        </div>
        <div className="GridHalf HalfRight">
          {
            props.selector.name === SelectorStandardName.Make && sortedMakes && sortedMakes.length > 0 ?
              <div className="InputContainer">
                <select
                  style={{width: '224px', marginRight: "8px"}}
                  defaultValue={props.selector.value.toString() || sortedMakes[0].DisplayMake}
                  onChange={(e)=>props.onChange(e.target.value)}
                >
                  {
                    sortedMakes.map(makeItem => {
                      return (
                        <option value={makeItem.DisplayMake} key={makeItem.DisplayMake}>
                          {makeItem.DisplayMake}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
            :
              <div className="InputContainer">
                <input
                  key={props.selector.value}
                  type={ValueTypeMapping[props.selector.valueType].type}
                  defaultValue={""+props.selector.value}
                  onBlur={(event)=>{props.onChange(event.target.value)}}
                />
              </div>
          }
          <span key="metadata" className='SelectedSelectorMetadata'>
            <div></div>{//<Translate id="rules.addedOn" data={{date:DateUtils.format(props.selector.addedOn), someone:userName}}/>
                        }
          </span>
          <span key="remove" onClick={props.removeSelector} className='RemoveSelector btn bx--btn'>
            <Translate id="common.remove"/>
          </span>
        </div>
      </div>
    </div>
  )
}
SelectedSelector.defaultProps = {
  removeSelector : ()=>{},
  getUserName    : (userId:number):string=>"Unknown",
  onChange       : (content:string)=>{console.log(content)}
}
export default withLocalize(SelectedSelector as any) as any
