import * as React from "react"

interface CustomControlProps{
  mapRef   : any
  position : any
}
interface CustomControlState{
  isInMap : boolean
}
class CustomControl extends React.Component<CustomControlProps,CustomControlState>{
  constructor(props){
    super(props)
    this.state = {
      isInMap : false
    }
  }

  addToMap(self){
    if(this.props.mapRef && !this.state.isInMap){
      this.props.mapRef.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.controls[this.props.position].push(self)
      this.setState({isInMap:true})
    }
  }
  render(){
    return <div ref={this.addToMap.bind(this)}>{this.props.children}</div>
  }
}

export default CustomControl
