import {combineReducers}            from "redux"
import List                         from "./List"
import Retrieving                   from "./Retrieving"

const Profiles = combineReducers({
  List,
  Retrieving,
})

export default Profiles
