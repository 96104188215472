import "./GuidelinesDiff.css"
import * as React                        from "react"
import {Guideline, getGuidelineAmount}   from "../../../../../model/pacy/Guideline"
import GuidelinesDiffFormat              from './GuideLineDiffFormat/GuidelinesDiffFormat'
interface GuidelinesDiffProps{
  guideline         : Guideline
  previousGuideline : Guideline
}

const GuidelinesDiff = (props:GuidelinesDiffProps) => {
  const amount     = getGuidelineAmount(props.guideline)
  const prevAmount = getGuidelineAmount(props.previousGuideline)
  return (
    <GuidelinesDiffFormat amount={amount} prevAmount={prevAmount} />
  )
}

export default GuidelinesDiff
