export enum StandardProvider {
  AW,
}
interface Provider {
  name     : StandardProvider,
  niceName : string,
  IDKey    : string,
}
export const KNOWN_PROVIDER : Provider[] = [
  {name: StandardProvider.AW, niceName: "AdWords", IDKey: "AWId"},
]
