import Actions         from "../../../model/constant/actions"
import ReducerUtils    from "../../../utils/ReducerUtils"

let rememberAccountLoaded = (state,action) => {
  if (state.length===0){ return [action.accountId] }
  if ( state.find((accountId)=>accountId===action.accountId) === undefined){return [...state, action.accountId]}
  return state
}
export const AccountsFetched = ReducerUtils.createReducer<number[]>([],{
  [Actions.RETRIEVE_GEOBID] : rememberAccountLoaded,
})
