import './ProfilesManager.css'
import * as React                      from "react"
import ArrayUtils                      from "../../../utils/ArrayUtils"
import ProfileList                     from "./ProfileList"

interface ProfilesManager{
  currentProfiles : any[]
  profiles        : any[]
  assignProfile   : (profileCode:string)=>void
  removeProfile   : (profileCode:string)=>void
}

const ProfilesManager = (props:ProfilesManager) => {
  const currentCodes = props.currentProfiles.map(x=>x.code)
  const profilesToAdd = props.profiles.filter(x=>!ArrayUtils.contain(currentCodes, x.code))
  return (
    <div className="ProfilesManager">
      <div className="CurrentProfiles">
        <h3>Current profiles</h3>
        <ProfileList permissionProfiles={props.currentProfiles} action={props.removeProfile} actionText="Remove"/>
      </div>
    </div>
  )
}

export default ProfilesManager
