import './index.css'
import * as React                                 from "react"
import {connect}                                  from 'react-redux'
import requiresUsers                              from "../../components/loaders/usersLoader"
import requiresUserPermissions                    from "../../components/loaders/userPermissionsLoader"
import ProfileViewer                              from "../../page/profile/component/ProfileViewer"
import CurrentAccounts                            from "./component/CurrentAccounts"
import AddAccount                                 from "./component/AddAccount"
import UserLevelManager                           from "./component/UserLevelManager"
import GoBack                                     from "../../components/Button/LinkGoBack"
import {Panel}                                    from "../../components/Panel/Panel"
import requiresPermissionProfiles                 from "../../components/loaders/permissionProfilesLoader"
import WhiteLabelManager                          from './component/WhitelabelManager'
import { setUserWhiteLabel }                      from '../../actions/user/userPreferences'
import { Translate }                              from 'react-localize-redux'
import { setTargetUser }                          from '../../actions/user/setTargetUser'

interface UserViewOwnProps {
  userId            : number
  path              : string
}

const mapStateToProps = (state,ownProps)=>{
  const userId = state.Users.TargetUser

  return {
    userId                    : userId,
    accounts                  : state.Accounts.list,
    currentWhiteLabel         : state.Users.PreferencesList[userId] ? state.Users.PreferencesList[userId].whitelabel : null,
    targetUser                : state.Users.TargetUser,
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    setTargetUser    : (userId: number) => dispatch(setTargetUser(userId)),
    setWhiteLabel    : (userId: number, whiteLabel: string) => dispatch(setUserWhiteLabel(userId, whiteLabel)),
  }
}

const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps,
    setTargetUser     : (userId: number) => DP.setTargetUser(userId),
    setWhiteLabel     : (whiteLabel:string)=> DP.setWhiteLabel(SP.userId, whiteLabel),
  }
}

type UserViewProps = ReturnType<typeof mergeProps>

const UserView = (props:UserViewProps) => {
  const user = props.users.find(x=>x.id === props.userId)

  if (props.userId !== props.targetUser) {
    props.setTargetUser(props.userId)
  }
  if(!user){return <span>User doesn't exist</span>}

  return (
    <Panel title="User">
      <GoBack to={props.path.split("/").slice(0,-1).join("/")}/>
      <div className='UserView'>
        <ProfileViewer user={user} />
        <div className="DropdownManagers">

          <div className="IndividualManagerContainer">
            <Translate id="management.newUserLevel"></Translate>
            <UserLevelManager user={user}/>
          </div>

          <div className="IndividualManagerContainer">
            <Translate id="management.newWhiteLabel"></Translate>
            <WhiteLabelManager
              userId={props.userId}
              setWhiteLabelPref={props.setWhiteLabel}
              currentWhiteLabel={props.currentWhiteLabel}
            />
          </div>

        </div>
        <CurrentAccounts user={user} accounts={props.accounts} profiles={props.profiles}/>
        <div className="scrollablePage">
          <AddAccount user={user} accounts={props.accounts}/>
        </div>
      </div>
    </Panel>
  )
}

export default requiresUsers(
  requiresPermissionProfiles(
    requiresUserPermissions(
      connect(mapStateToProps,mapDispatchToProps,mergeProps)(UserView)
    )
  )
)