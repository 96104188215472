export default class KeywordsUtils {

  public static positiveKeywordsError = (keywordsArray:string[],tags:string[]) => {
    const BRACKETS_TEST = /^\{(\w|\[|\]|\s|\<|\>|\+|-)+\}$/
    const quoteTest = /^\+?(\w+|"[\s\w]+")(\s+\+?(\w+|"[(\s\w)]+"))*\s*$/
    const errors = new Set<string>()
    if(keywordsArray.map(keyword=>keyword.trim()).filter(keyword=>keyword).length === 0) {
      errors.add('keyword.empty')
    }
    for (let keyword of [...keywordsArray]) {
      if(keyword.length === 0) {
        errors.add('keyword.spaceError')
      }
      const spaceCheck = /\s{2}/g
      if(spaceCheck.test(keyword)) {
        errors.add('keyword.spaceError')
      }
      keyword = keyword
      .replace(/\{|\}|\[|\]|\<|\>|\+|"/g,'')
      if(keyword.split(" ").filter(keyword=>keyword).length>5) {
        errors.add('keyword.wordsError')
      }
      if(keyword.length > 80) {
        errors.add('keyword.lengthError')
      }
    }
    for( let keyword of [...keywordsArray]) {
      let temp = keyword.trim().replace( /(\w)-(\w)/g,'$1$2').replace(/[À-ÿ]/g,'z')
      if(keyword.includes('{') || keyword.includes('}')) {
        const isEmpty = temp.slice(1,temp.length-1).trim().length === 0
        if(!BRACKETS_TEST.test(keyword) || isEmpty) {
          errors.add('keyword.bracketsError')
        }
      }
      temp = (
        temp
        .toLowerCase()
        .trim()
        .replace(/^{(.+)}$/,"$1")
        .replace(new RegExp("("+tags.join("|")+")","g"),'tag')
      )
      if(temp.includes('<') || temp.includes('>')) {
        errors.add('keyword.invalidTag')
      }
      if(temp.includes('[') && !temp.includes(']')) {
        errors.add('keyword.missingClosingBrackets')
      }
      if(!temp.includes('[') && temp.includes(']')) {
        errors.add('keyword.missingOpeningBrackets')
      }
      if(temp.includes('[') && temp.includes(']')) {
        let bracketsString = temp.slice(0,temp.length+1)
        while (bracketsString.indexOf(']')>bracketsString.indexOf('[')) {
          const toTest = bracketsString.slice(bracketsString.indexOf('['),bracketsString.indexOf(']')+1)
          const isEmpty = toTest.replace(/(\[|\])/g,'').trim().length === 0
          if(!toTest.match(/^\[(\+?[A-Za-z0-9\s*])+\]/)|| isEmpty) {
            errors.add('keyword.nonValidBrackets')
            break;
          }
          bracketsString = bracketsString.replace(toTest,'')
        }
        if (bracketsString.trim().length===0) {
          errors.add('keyword.onlyBracketsInLine')
        }
        if(bracketsString.includes('[') && !bracketsString.includes(']')) {
          errors.add('keyword.missingClosingBrackets')
        }
        if(!bracketsString.includes('[') && bracketsString.includes(']')) {
          errors.add('keyword.missingOpeningBrackets')
        }
        if(bracketsString.indexOf(']')<bracketsString.indexOf('[')) {
          errors.add('keyword.wrongBracketsOrder')
        }
      }
      let squareBracketsString = temp.replace(/(\[|\])/g,'').trim()
      if(squareBracketsString.includes('"')) {
        if(quoteTest.test(squareBracketsString)) {
          squareBracketsString = squareBracketsString.replace(/([\w\s]+)"/g,"$1".trim())
        } else {
          errors.add('keyword.quoteError')
        }
      }
      temp = temp.replace(/(\{|\[|\s|^)\+(\w|")+/g,' plus')
      if(temp.includes('+')) {
        errors.add('keyword.plusError')
      }
      if(temp.includes('][')) {
        errors.add('keyword.bracketsSpaceError')
      }
      temp =
      temp.replace(/\[/g,'')
      .replace(/\]/g,'')
      .trim()
      .replace(/a/g,'a')
      const specialCharTest = /^[A-Za-z0-9À-ÿ \+\{\}\<\>"]*$/g
      if(!specialCharTest.test(temp)) {
        errors.add('keyword.specialCharError')
      }
    }
    return Array.from(errors)
  }
  public static negativeKeywordsErrors = (keywordsArray:string[]) => {
    const copy = [...keywordsArray]
    const errors = new Set<string>()
    const MINUS_START_TEST = /^-/g
    const TAG_TEST = /<.*>/g
    if(copy.map(keyword=>keyword.trim()).filter(keyword=>keyword).length === 0) {
      errors.add('keyword.empty')
    }
    for (let keyword of copy) {
      const spaceCheck = /\s{2}/g
      if(spaceCheck.test(keyword)) {
        errors.add('spaceError')
      }
      keyword = keyword
      .replace(/\{|\}|\[|\]|\<|\>|"/g,'')
      if(keyword.split(" ").filter(keyword=>keyword).length>5) {
        errors.add('keyword.wordsError')
      }
      if(keyword.length > 80) {
        errors.add('keyword.lengthError')
      }
    }
    for(let keyword of copy) {
      keyword = keyword.trim()
      if(MINUS_START_TEST.test(keyword)) {
        errors.add('keyword.minusSignError')
      }
      if(TAG_TEST.test(keyword)) {
        errors.add('keyword.tagError')
      }
    }
    return Array.from(errors)
  }
}
