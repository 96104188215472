import Actions            from "../../model/constant/actions"
import {ReferenceReducer} from "../../model/Store/Cleaner"
import ReducerUtils       from "../../utils/ReducerUtils"

const save = (state,action) => ({
  ...state,
  [action.accountId] : action.cleaner,
})

export default ReducerUtils.createReducer<ReferenceReducer>({},{
  [Actions.RETRIEVE_CLEANER_REFERENCE] : save
})
