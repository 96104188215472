import ReportTableTemplate    from "../ReportTableTemplate"
import ColumnDefinition       from "../ColumnDefinition"
import StatisticsUtils        from "../../../../utils/StatisticsUtils"
import Contexts               from "../../../constant/ReportTableTemplateContexts"

export default class AdReportTableTemplate extends ReportTableTemplate{
  protected context = Contexts.AD
  protected KEY_COLUMN = ""
  protected DEFAULT_COLUMNS = [
    //                    text                datafield       order iskey  hidden sort  formatter  filter  filteroptions
  ]
  constructor(id:string, name:string, options:any={defaultSorted:[{dataField:"",order:"asc"}]}){
    super(id, name, options)
    this.columns = this.DEFAULT_COLUMNS
  }
  clone(){
    let clone = new AdReportTableTemplate(
      this.id,
      this.name,
      this.options
    )
    clone.setColumns(this.columns.map(c=>c.clone()))
    clone.setSizePerPage(this.sizePerPage)
    clone.setRevisionOf(this.revisionOf)
    clone.setProvider(this.provider)
    return clone
  }

  protected compile(stats : any[]):any{ //TODO
    return stats.reduce((a,c)=>({
      ...c,...a,
      AveragePosition : a.AveragePosition + c.AveragePosition,
      Conversions     : a.Conversions + c.Conversions,
      Clicks          : a.Clicks + c.Clicks,
      Cost            : a.Cost + c.Cost,
      Impressions     : a.Impressions + c.Impressions,
      CPC             : 0,
      CTR             : a.CTR + c.CTR,
    }),{AveragePosition:0,Conversions:0,Clicks:0,Cost:0,Impressions:0})
  }
}
