import './index.css'
import * as React                                from 'react'
import {connect}                                 from 'react-redux'
import {withLocalize}                            from "react-localize-redux"
import NumFormatter                              from '../../utils/NumberFormatter'
import PreferencesAccessors                      from "../../storeAccessor/Preferences"
import PERMISSIONS                               from "../../model/constant/Permissions"
import {Panel}                                   from "../../components/Panel/Panel"
import Hint                                      from "../../components/alert/Hint"
import AccountPermissionBoundary                 from "../../components/permissions/AccountPermissionBoundary"
import ScatterChart                              from "./InventoryScatterChart"
import BarChart                                  from "./InventoryBarChart"
import SoldCarsDataTable                         from './SoldCarsDataTable'
import SoldCarsKPIs                              from "./SoldCarsKPIs"
import SoldCarsPercent                           from './SoldCarsPercent'
import DataVerification                          from "./DataVerification"
import requiresInventoryTurnCompilation          from '../inventoryTurnAnalysis/inventoryTurnCompilationLoader'
import GoBack                                    from "../../components/Button/LinkGoBack"

const makeMapStateToProps = () => {
  const companyNameSelector = PreferencesAccessors.makeCompanyNameSelector()
  return (state,ownProps)=>{
    return {
      period : state.Period,
      accountId : state.Accounts.selected,
      companyName : companyNameSelector(state)
    }
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {}
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
const SoldInventoryAnalysis = (props)=>{
  const proportionPercent = props.vehicles.length === 0 ? "-%" :
    NumFormatter.formatNumber(props.compiled.length/props.vehicles.length*100, 0)+"%"
  return (
    <Panel title={props.translate("inventoryTurn.title")} subtitle={props.translate("inventoryTurn.subtitle")}>
      <GoBack to={"/Account/"+props.accountId+"/Dashboard"}/>
      <div className='SoldCarsPage'>
        <div className='gridWrapper'>
          <div>
            <SoldCarsKPIs
              compiledCount={props.compiled.length}
              vehiclesCount={props.vehicles.length}
              companyName={props.companyName}
            />
          </div>
          <div>
            <SoldCarsPercent percent={proportionPercent} companyName={props.companyName}/>
          </div>
        </div>
        <div>
          <DataVerification
            period={props.period}
            vehicles={props.vehicles}
            compiled={props.compiled}
          >
            <SoldCarsDataTable
              vehiclesWithNavigations={props.compiled}
              accountId={props.accountId}
              companyName={props.companyName}
              allowPagination
              showExtraColumns
              showCSVButton
            />
          </DataVerification>
        </div>
        <AccountPermissionBoundary permissions={[PERMISSIONS.IDB2_ViewInventoryTurnGraphs]} onFailure={null}>
          <div>
            <Hint text={props.translate("inventoryTurn.graphs.staffSection")}/>
            <ScatterChart
              data={props.compiled}
              vehicles={props.vehicles}
            />
            <BarChart
              data={props.compiled}
              vehicles={props.vehicles}
            />
          </div>
        </AccountPermissionBoundary>
      </div>
    </Panel>
  )
}
export default (
  requiresInventoryTurnCompilation(
    connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
      withLocalize(SoldInventoryAnalysis)
    )
  )
)
