import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import {getToken}                                        from "../../storeAccessor/Generic"
import Headstone                                         from "../../utils/Headstone"
import LogBuilder                                        from "../../controller/log/LogBuilder"
import UserActions                                       from "../../model/constant/UserAction"

const extractMessageFromError = (error:any) => {
  if(error.response && error.response.data){
    return error.response.data.error
  }
}

export const retrieveNegativeKeywords = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_BRANDING_KEYWORDS, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Headstone.getNegativeKeywords(token, accountId)
  )
  .then((response)=>{
    dispatch({
      type             : Actions.RETRIEVE_BRANDING_KEYWORDS,
      negativeKeywords : response.data,
      accountId
    })
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_RETRIEVING_BRANDING_KEYWORDS,
      status : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type: Actions.RETRIEVING_BRANDING_KEYWORDS, status: false})
  })
}
export const saveNegativeKeywords = (accountId:number, lang:string, keywords:string[]) => (dispatch, getState) => {
  dispatch({type: Actions.SAVING_NEGATIVE_KEYWORDS, status: true})
  const token = getToken(getState())
  LogBuilder.log(UserActions.BRANDING_SAVE_NEGATIVE_KEYWORDS,token,{accountId,lang,keywords})
  ActionUtils.retryOnFailure(
    ()=>Headstone.saveNegativeKeywords(token, accountId, lang, keywords)
  )
  .then((response)=>{
    dispatch(retrieveNegativeKeywords(accountId))
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_RETRIEVING_BRANDING_KEYWORDS,
      status : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type: Actions.SAVING_NEGATIVE_KEYWORDS, status: false})
  })
}

