import * as React        from "react"
import {connect}         from "react-redux"
import ExplainedLoading  from "../loading"
import ErrorBoundary     from "../Error/ErrorBoundary"

const mapStateToProps = (state,ownProps)=>{
  return {
    moveProxySimulationAccount : state.EditCopies.PacyConfigs.MoveProxySimulation,
    fetchingAccounts           : state.EditCopies.PacyConfigs.TryingMoveProxySimulation
  }
}
const moveProxySimulationLoader = props => {
  if(
    !props.moveProxySimulationAccount ||
    !props.moveProxySimulationAccount.movingInvestment ||
    !props.moveProxySimulationAccount.notMovingInvestment ||
    props.fetchingAccounts
    ){
    return <ExplainedLoading translateId="loadings.loading"/>
  }
  return <ErrorBoundary>{props.render(props.moveProxySimulationAccount)}</ErrorBoundary>
}
const AccountsLoaderConnected = connect(mapStateToProps)(moveProxySimulationLoader)

const requiresProxyMoveAccountSimulation = Component => props => (
  <AccountsLoaderConnected
    render={ account => <Component moveProxySimulationAccount={account} {...props} /> }
  />
)

export default requiresProxyMoveAccountSimulation
