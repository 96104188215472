import Pacy                                              from "../../utils/Pacy"
import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import {getToken}                                        from "../../storeAccessor/Generic"

const extractMessageFromError = (error:any) => {
  if(error.response && error.response.data){
    return error.response.data.error
  }
}

export const retrieveUsers = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_PACY_USERS, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Pacy.getUsers(token, accountId)
  )
  .then(response=>{
    dispatch({
      type  : Actions.RETRIEVE_PACY_USERS,
      users : response.data,
      accountId,
    })
  })
  .catch((error)=>{
    console.warn(error)
    dispatch({
      type: Actions.FAILED_RETRIEVING_PACY_USERS,
      status : true,
      message : extractMessageFromError(error)
    })
  })
  .then(()=>{
    dispatch({type: Actions.RETRIEVING_PACY_USERS, status: false})
  })
}
