import Brimstone  from "../../utils/Brimstone"
import FVDUtils   from "../../utils/FieldsValuesDefinitionUtils"
import ArrayUtils from "../../utils/ArrayUtils"
import Actions    from "../../model/constant/actions"
import {getToken} from "../../storeAccessor/Generic"
import {Provider} from "../../model/Provider"

const retrieveBingCampaignsBaseInfo = (accountId:number, campaignBingIds?:number[], loadDuplicates:boolean=false) => (dispatch, getState) => {
  const state = getState()
  let ids
  if(campaignBingIds && campaignBingIds.length > 0){
    const uniqueIds = ArrayUtils.unique(campaignBingIds)
    if(loadDuplicates){
      ids = uniqueIds
    }
    else{
      const alreadyRetrieved = Object.keys(state.Campaigns.BaseInfo.ById[accountId])
      ids = uniqueIds.filter(id=>!ArrayUtils.contain(alreadyRetrieved, id))
    }
  }
  dispatch({
    type   : Actions.RETRIEVING_CAMPAIGNS_BASE_INFO,
    status : true
  })
  Brimstone.getCampaignsBaseInfo(getToken(state), accountId, ids)
  .then(
    (response)=>{
      const info = FVDUtils.decompress(response.data)
      .map(x=>{
        x.Provider=Provider.BING
        return x
      })
      const infoById = info.reduce((byId, info)=>{byId[info.BingId]=info;return byId},{})
      dispatch({
        type      : Actions.RETRIEVE_BING_CAMPAIGNS_BASE_INFO,
        accountId : accountId,
        info,
        infoById
      })
    },
    (error) => console.log(error)
  )
  .catch((e)=>{
    console.error(e)
    dispatch({
      type   : Actions.FAILED_RETRIEVING_CAMPAIGNS_BASE_INFO,
      status : true
    })
  })
  .then(()=>dispatch({
    type   : Actions.RETRIEVING_CAMPAIGNS_BASE_INFO,
    status : false
  }))
}

export default retrieveBingCampaignsBaseInfo
