import styles                    from './AdCopyEditorStateManagerStyles'
import * as React                from 'react'
import {State}                   from '../../../reducers/generic/reducerAssembly'
import AdCopyEditor              from './AdCopyEditor'
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
}                                from '@material-ui/core'
import {
  withLocalize,
  LocalizeContextProps
}                                from 'react-localize-redux'
import { DragDropContext }       from 'react-dnd'
import HTML5Backend              from 'react-dnd-html5-backend'
import {
  AdCopyParents,
  AdCopyRSATemplates,
  AdCopyRSAPatterns,
  AdgroupsToCopy,
  INVENTORY_GROUPS
}                                from '../../../model/adcopy'
import { getAccountId }          from '../../../storeAccessor/Generic'
import {
  getRSAAdCopies,
  saveRSAAdCopies
}                                from '../../../actions/AdCopyPatterns'
import ExplainedLoading          from '../../../components/loading/index'
import { connect }               from 'react-redux'
import AWSearch                  from '../../../model/Store/Audiences/AWSearch'
import {provideAudienceName}     from '../../adBuilder/utils/adBuilderUtils'
import TAGS from '../../../model/constant/AdCopiesTags'
import GROUPS from '../../../model/constant/Groups'
import ArrayUtils from '../../../utils/ArrayUtils'

interface AdCopyEditorStateManagerOwnProps extends LocalizeContextProps {
  availableOptions          : AdCopyParents
  campaignGroup             : string
  handleCancel              : ()=>void
  lang                     ?: string
  strategy                 ?: string
  adgroup                  ?: string
  readOnly                 ?: boolean
  forceDisableInteractions ?: boolean
  AWAudiences              ?: AWSearch[]
}

const mapStateToProps = (state:State,props:AdCopyEditorStateManagerOwnProps) => {
  const accountId = getAccountId(state)
  return {
    accountId,
    adcopies           : state.Params.AdCopies.RSAAdcopies.AdCopies[accountId]?.[props.campaignGroup],
    retrievingAdcopies : state.Params.AdCopies.RSAAdcopies.Retrieving,
    savingAdcopies     : state.Params.AdCopies.RSAAdcopies.Saving,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getRSAAdCopies : (...args:Parameters<typeof getRSAAdCopies>) => dispatch(getRSAAdCopies(...args)),
    saveRSAAdCopies : (...args:Parameters<typeof saveRSAAdCopies>) => dispatch(saveRSAAdCopies(...args))
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AdCopyEditorStateManagerOwnProps) => {
  return {
    ...SP,...DP,...ownProps,
    getRSAAdCopies : (accountId:number, campaignGroup:string) => DP.getRSAAdCopies(accountId, campaignGroup),
    saveRSAAdCopies : (accountId:number, campaignGroup:string, adCopies:AdCopyRSAPatterns[]) => DP.saveRSAAdCopies(accountId, campaignGroup, adCopies)
  }
}
type AdCopyEditorStateManagerProps = ReturnType<typeof mergeProps>

const AdCopyEditorStateManager = (props:AdCopyEditorStateManagerProps) => {
  // Loading if we don't have adcopies yet
  if(props.adcopies === undefined && !props.retrievingAdcopies){
    props.getRSAAdCopies(props.accountId, props.campaignGroup)
  }
  if (props.adcopies === undefined){return <ExplainedLoading  translateId="loadings.adCopies"/>}

  // State
  const [strategy, setStrategy] = React.useState<string>(
    props.strategy && props.strategy !== ""
      ? props.strategy
      : props.adcopies[0].campaign
  )
  const [adgroup,setAdgroup] = React.useState<string>(
    props.adgroup && props.adgroup !== "" 
      ? props.adgroup
      : props.adcopies.find(adcopy=>adcopy.campaign===strategy).adgroup
  )
  const [lang, setLang] = React.useState<string>(
    props.lang && props.lang !== "" 
      ? props.lang
      : props.adcopies.find(adcopy=>adcopy.campaign===strategy && adcopy.adgroup===adgroup).matcher.lang
  )
  const [adgroupsToCopy, setAdgroupsToCopy] = React.useState<AdgroupsToCopy>({})
  const provideTemplates = (newStrategy:string, newAdgroup:string, newLang:string):AdCopyRSATemplates[] => {
    const selectedTemplates = props.adcopies.find(adcopy => 
      adcopy.campaign===newStrategy
      && adcopy.adgroup===newAdgroup
      && adcopy.matcher.lang===newLang
    )?.templates
    return selectedTemplates ?? []
  }
  const [templates,setTemplates] = React.useState<AdCopyRSATemplates[]>(provideTemplates(strategy,adgroup,lang))

  React.useEffect(()=>{
    setTemplates(provideTemplates(strategy,adgroup,lang))
  },[props.adcopies])
  
  const inventoryCampaignGroups = ["Inventory - New", "Inventory - Used"]

  const handleStrategyChange = (value:string) => {
    setStrategy(value)
    const nextAdgroup = props.adcopies.find(adcopy=>adcopy.campaign===value).adgroup
    setAdgroup(nextAdgroup)
    const nextLang = props.adcopies.find(adcopy=>adcopy.campaign===value && adcopy.adgroup===nextAdgroup).matcher.lang
    setLang(nextLang)
    setTemplates(provideTemplates(value, nextAdgroup, nextLang))
  }

  const handleAdgroupChange = (value:string) => {
    setAdgroup(value)
    const nextLang = props.adcopies.find(adcopy=>adcopy.campaign===strategy && adcopy.adgroup===value).matcher.lang
    setLang(nextLang)
    setTemplates(provideTemplates(strategy, value, nextLang))
  }

  const handleLangChange = (value:string) => {
    setLang(value)
    setTemplates(provideTemplates(strategy, adgroup, value))
  }

  const handleSave = (templates:AdCopyRSATemplates[]) => {
    const oldAdCopies = props.adcopies.find(adcopy => 
      adcopy.campaign===strategy
        && adcopy.adgroup===adgroup
        && adcopy.matcher.lang===lang
    )
    // Trimming the empty Headlines & Descriptions is the only way to get rid of them in the UI
    templates.forEach(template=>{
      template.headlines = template.headlines.filter(x=>x.text!=="")
      template.descriptions = template.descriptions.filter(x=>x.text!=="")
    })
    const newAdCopies:AdCopyRSAPatterns[] = [{...oldAdCopies, templates}]

    // Handling copying current templates into other adgroups
    if (Object.keys(adgroupsToCopy).length > 0){
      Object.keys(adgroupsToCopy).forEach(campaignToCopy=>{
        Object.keys(adgroupsToCopy[campaignToCopy]).forEach(adgroupToCopy=>{
          if (adgroupsToCopy[campaignToCopy][adgroupToCopy]){
            const adCopiesToReplace = props.adcopies.find(adcopy => 
              adcopy.campaign===campaignToCopy
                && adcopy.adgroup===adgroupToCopy
                && adcopy.matcher.lang===lang
            )
            if (adCopiesToReplace){
              newAdCopies.push({...adCopiesToReplace, templates})
            }
          }
        })
      })
    }
    props.saveRSAAdCopies(props.accountId, props.campaignGroup, newAdCopies)
  }

  const handleSaveAndClose = (templates:AdCopyRSATemplates[]) => {
    handleSave(templates)
    props.handleCancel()
  }

  const handleAdgroupsToCopy = (value:boolean, strategy:string, adgroup:string, allAdgroupsOptions?:{[adgroup:string]:boolean}) => {
    if (adgroup === "All adgroups"){
      setAdgroupsToCopy(prev=>{
        return {
          ...prev,
          [strategy] : {...allAdgroupsOptions}
        }
      })
    } else {
      setAdgroupsToCopy(prev=>{
        return {
          ...prev,
          [strategy] : {
            ...prev[strategy], 
            [adgroup] : value
          }
        }
      })    
    }
  }

  const provideAvailableCopyOptions = ():AdCopyParents => {
    // Goal is to remove the currently selected adgroup (in the currently selected strategy/campaign)
    // from the pool of available options to copy to
    // *Deep cloning because we are mutating the object
    const filteredOptions = JSON.parse(JSON.stringify(props.availableOptions))
    delete filteredOptions[strategy][adgroup]
    return filteredOptions
  }

  const provideCampaignSelectLabel = () => {
    if (INVENTORY_GROUPS.includes(props.campaignGroup)) return props.translate("adCopies.strategy")
    if (props.campaignGroup==="Branding") return props.translate("adCopies.adGroup")
    return props.translate("adCopies.campaignName")
  }

  const getAppropriateTags = (group:string, strategy:string):string[] => {
    group = group.toUpperCase()
    strategy = strategy.toUpperCase()
    if(group.includes("INVENTORY")) {
      if(ArrayUtils.contain(GROUPS.VDP, strategy)){return TAGS.VDP}
      if(ArrayUtils.contain(GROUPS.MM, strategy)){return TAGS.MM}
    }
    if(group === "BRANDING"){return TAGS.BRANDING}
    if(group === "CREDIT"){return TAGS.CREDIT}
    if(group === "SERVICE"){return TAGS.SERVICE}
    if(group === "CONQUEST"){return TAGS.CONQUEST}
    return TAGS.INVENTORY
  }
  const classes = styles()
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.label}>
            {provideCampaignSelectLabel()}
          </InputLabel>
          <Select
            className={classes.selects}
            value={strategy}
            onChange={(e)=>handleStrategyChange(e.target.value as string)}
          >
            {Object.keys(props.availableOptions).map(campaign=>{
              return <MenuItem key={campaign} value={campaign}>{campaign}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.label}>
            {props.campaignGroup==="Branding" 
              ? props.translate("adCopies.audience")
              : props.translate("adCopies.adGroup")
            }
          </InputLabel>
          <Select
            className={classes.selects}
            value={adgroup}
            onChange={(e)=>handleAdgroupChange(e.target.value as string)}
          >
            {Object.keys(props.availableOptions?.[strategy] ?? []).map(adgroup=>{
              return <MenuItem key={adgroup} value={adgroup}>
                {provideAudienceName(adgroup, props.AWAudiences)}
              </MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.label}>{props.translate("adCopies.language")}</InputLabel>
          <Select
            className={classes.selects}
            value={lang}
            onChange={(e)=>handleLangChange(e.target.value as string)}
          >
            {(props.availableOptions?.[strategy]?.[adgroup] ?? []).map(x=>{
              return <MenuItem key={x} value={x}>{x}</MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
    <div className={classes.editing}>
      <AdCopyEditor
        templates={templates}
        tags={getAppropriateTags(props.campaignGroup, strategy)}
        lang={lang}
        availableCopyOptions={provideAvailableCopyOptions()}
        handleCancel={props.handleCancel}
        saveFunction={handleSave}
        saveAndClose={handleSaveAndClose}
        adgroupsToCopy={handleAdgroupsToCopy}
        readOnly={props.readOnly}
        disableCopying={props.campaignGroup==="Credit"}
        inventoryGroup={inventoryCampaignGroups.includes(props.campaignGroup) ? strategy : undefined}
        AWAudiences={props.AWAudiences}
        resetAdgroupsToCopy={() => setAdgroupsToCopy({})}
        forceDisableInteractions={props.savingAdcopies}
      />
    </div>
  </div>
  )
}

export default withLocalize(
  DragDropContext(HTML5Backend)(
    connect(mapStateToProps,mapDispatchToProps,mergeProps)(AdCopyEditorStateManager)
  )
)
