import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const compile = (state,action) => {
  return action.soldVehicles
}
const compileToUndefined = (state,action)=>{
  return null
}
const compileToUndefinedIfRetrieved = (state,action)=>{
  if(action.status){return state}
  return null
}
export const SoldVehicles = ReducerUtils.createReducer<any>(null,{
  [Actions.BACKGROUND_COMPILE_SOLD_VEHICLES]       : compile,
  [Actions.RETRIEVING_USER_NAVIGATIONS_OF_ACCOUNT] : compileToUndefinedIfRetrieved,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST]       : compileToUndefined,
  [Actions.CHANGE_SELECTED_PERIOD]                 : compileToUndefined,
})
