import * as React              from "react"
import {Translate}             from "react-localize-redux"
import {SelectorAbstract}      from '../../../model/engagementRules/Selector'

interface ClickableSelectorProps{
  selector          : SelectorAbstract
}
export default (props:ClickableSelectorProps)=>{
  return (
    <div className='ClickableSelector'>
      <i className='material-icons'>{props.selector.icon}</i>
      <Translate id={props.selector.niceNameId}/>
    </div>
  )
}
