import './index.css'
import * as React                       from "react"
import {Panel}                          from "../../components/Panel/Panel"
import TwoLineGraph                     from "./component/graph/TwoLineGraph"
import SoldCarsTable                    from "../inventoryTurnAnalysis/SoldCarsTable"
import EngRules                         from "./component/EngagementRulesInventoryResult"
import KPIs                             from "./component/KPI/KPIs"
import MapSpend                         from "./component/mapSpend/index"
import OppTrendTables                   from "./component/OppTrendHighlighter"
import ErrorBoundary                    from '../../components/Error/ErrorBoundary'
import {withLocalize}                   from "react-localize-redux"

const Dashboard = props => (
  <Panel title={props.translate("dashboard.title")} subtitle={props.translate("dashboard.subtitle")} >

    <div className='DashboardGrid'>
      <div key='SoldCarsTable' className="grid-panel grid-SoldCarsTable">
        <ErrorBoundary>
          <SoldCarsTable showMoreButton/>
        </ErrorBoundary>
      </div>
      <div key='TwoLineGraph' className="grid-panel grid-TwoLineGraph">
        <ErrorBoundary>
          <TwoLineGraph/>
        </ErrorBoundary>
      </div>
      <div key='KPIs' className="grid-panel grid-KPIs">
        <ErrorBoundary>
          <KPIs/>
        </ErrorBoundary>
      </div>
      <div key='MapVersusSpend' className="grid-panel grid-MapVersusSpend">
        <ErrorBoundary>
          <MapSpend/>
        </ErrorBoundary>
      </div>
      <div key='MissedOppAndTrendyCars' className="grid-panel grid-MissedOppAndTrendyCars">
        <ErrorBoundary>
          <OppTrendTables/>
        </ErrorBoundary>
      </div>
      <div key='F' className="grid-panel grid-F">
        <ErrorBoundary>
          <EngRules/>
        </ErrorBoundary>
      </div>
    </div>
  </Panel>
)
export default withLocalize(Dashboard)
