import axios             from 'axios'
import Actions           from "../../model/constant/actions"
import {getToken}        from "../../storeAccessor/Generic"
import Headstone         from "../../utils/Headstone";

export const retrieveBingGeoMapping = () => (dispatch, getState) => {
  dispatch({type : Actions.RETRIEVING_BING_GEO_MAPPING, status : true})
  axios.get(Headstone.getApiUrl() + `/GeoMapping`, {
    headers : {
      "Authorization" : "Bearer " + getToken(getState()),
    }
  })
  .then((response) => {
    dispatch({
      type : Actions.RETRIEVE_BING_GEO_MAPPING,
      bingGeoMapping : response.data
    })
  })
  .catch(error => console.log(error))
  .then(()=>{
    dispatch({
      type : Actions.RETRIEVING_BING_GEO_MAPPING,
      status : false
    })
  })
}
