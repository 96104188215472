import './Keywords.css'
import * as React               from 'react'
import requiresKeywords         from '../../../components/loaders/BrandingKeywordsLoader'
import negativeKeywords         from '../../../model/branding/NegativeKeywords'
import {
  FormControl,
  Select,
  Input,
  FormHelperText,
  MenuItem,
  Card,
  Snackbar,
  SnackbarContent,
  Icon,
  IconButton,
}                               from '@material-ui/core'
import {
  AdGroupConfigsByName,
  AdGroupConfigsByLang
}                               from '../../../model/branding/AdGroupConfigs'
import {
  withLocalize,
  Translate,
  TranslateFunction
}                               from 'react-localize-redux'
import KeywordsInput            from '../../../components/keywordsInput/KeywordsInput'
import DialogMui                from '../../../components/Modals/DialogMui'
import { connect }              from 'react-redux'
import { saveNegativeKeywords } from '../../../actions/branding/NegativeKeywords'
import { savePositiveKeywords } from '../../../actions/branding/AdGroupConfigs'
import { getAccountId }         from '../../../storeAccessor/Generic'
import Actions                  from '../../../model/constant/actions'
import KeywordsUtils            from '../../../utils/KeywordsUtils'

interface KeywordsOwnProps {
  adGroupConfigsByName : AdGroupConfigsByName
  lang                 : string
  negativeKeywords     : negativeKeywords
  adGroupConfigsByLang : AdGroupConfigsByLang[]
  handleChangeLang     : (event: React.ChangeEvent<HTMLInputElement>) => void
  setAdGroup           : (adGroup:string) => void
  adGroup              : string
}
interface KeywordsProps extends KeywordsOwnProps {
  translate            : TranslateFunction
  accountId            : number
  saveNegativeKeywords : (accountId: number, lang: string, keywords: string[]) => void
  savePositiveKeywords : (accountId: number, lang: string, keywords: string[], adGroup: string) => void
  failedSavingPos      : boolean
  resetFailedPos       : () => void
  savingPosKeywords    : boolean
}

const mapStateToProps = (state, props) => {
  const accountId = getAccountId(state)
  return {
    accountId,
    failedSavingPos: state.Params.Branding.failedSavingPositiveKeywords,
    savingPosKeywords: state.Params.Branding.savingPositiveKeywords
  }
}
const mapDispatchToProps = dispatch => {
  return {
    saveNegativeKeywords: (accountId: number, lang: string, keywords: string[]) =>
      dispatch(
        saveNegativeKeywords(accountId, lang, keywords)
      )
    ,
    savePositiveKeywords: (accountId: number, lang: string, keywords: string[], adGroup: string) =>
      dispatch(
        savePositiveKeywords(accountId, lang, keywords, adGroup)
      )
    ,
    resetFailedPos: () => {
      dispatch({ type: Actions.FAILED_SAVING_POSITIVE_KEYWORDS, status: false })
    }
  }
}
const mergeProps = (SP, DP, ownProps) => {
  return {
    ...SP, ...DP, ...ownProps,
  }
}

const Keywords = (props:KeywordsProps) => {
  //MODALS STATE
  const [openResetNegative,setOpenResetNegative] = React.useState(false)
  const [openSaveNegative,setOpenSaveNegative] = React.useState(false)
  const [openResetPositive,setOpenResetPositive] = React.useState(false)
  const [openSavePositive,setOpenSavePositive] = React.useState(false)
  //MODALS HANDLERS
  const handleOpenResetNegative = () => {setOpenResetNegative(true)}
  const handleCloseResetNegative = () => {setOpenResetNegative(false)}
  const handleOpenResetPositive = () => {setOpenResetPositive(true)}
  const handleCloseResetPositive = () => {setOpenResetPositive(false)}
  const handleOpenSaveNegative = () => {setOpenSaveNegative(true)}
  const handleCloseSaveNegative = () => {setOpenSaveNegative(false)}
  const handleOpenSavePositive = () => {setOpenSavePositive(true)}
  const handleCloseSavePositive = () => {setOpenSavePositive(false)}
  // - - - - - - - -
  const [adGroup,setAdGroup] = React.useState(
    Object.keys(props.adGroupConfigsByName).indexOf(props.adGroup) === -1
    ? Object.keys(props.adGroupConfigsByName)[0]
    : props.adGroup
  )
  const [negativeKeywords, setNegativeKeywords] = React.useState(props.negativeKeywords[props.lang]||[])
  const [positiveKeywords, setPositiveKeywords] = React.useState(props.adGroupConfigsByName[adGroup].keywordPatterns)
  React.useEffect(()=>{
    setNegativeKeywords(props.negativeKeywords[props.lang] || [])
    setPositiveKeywords(props.adGroupConfigsByName[adGroup].keywordPatterns)
  },[props.adGroup,props.lang])
  //handle adGroup change
  function handleChange(event:React.ChangeEvent<{value:string}>) {
    setAdGroup(event.target.value)
    props.setAdGroup(event.target.value)
  }
  //handle language change
  function handleChangeLang(e) {
    props.handleChangeLang(e)
    const newAdGroup = Object.keys(props.adGroupConfigsByLang[e.target.value])[0]
    setAdGroup(newAdGroup)
    props.setAdGroup(newAdGroup)
  }
  //Generate AdGroups
  const adGroupList = Object.keys(props.adGroupConfigsByName).map(groupConfig=>{
    return (
      <MenuItem key={groupConfig} value={groupConfig}>{groupConfig}</MenuItem>
    )
  })
  const availableLanguages = Object.keys(props.adGroupConfigsByLang).map(lan => {
    return (
      <MenuItem
        value={lan}
        key={lan}
      >
        {lan === 'EN' ? props.translate('common.english') : props.translate('common.french')}
      </MenuItem>
    )
  })
  //INPUTS HANDLERS
  const handleInputNegativeKeywords = (text:string) => {
    setNegativeKeywords(text.split('\n'))
  }
  const handleInputPositiveKeywords = (text:string) => {
    setPositiveKeywords(text.split('\n'))
  }
  // - - - - - - -
  const handleResetNegativeKeywords = () => {
    setNegativeKeywords(props.negativeKeywords[props.lang])
    handleCloseResetNegative()
  }
  const handleResetPositiveKeywords = () => {
    setPositiveKeywords(props.adGroupConfigsByName[adGroup].keywordPatterns)
    handleCloseResetPositive()
  }
  //Save Handlers
  const handleSaveNegativeKeywords = () => {
    props.saveNegativeKeywords(
      props.accountId,
      props.lang,
      negativeKeywords.map(keyword => keyword.trim()).filter(keyword => keyword),
    )
    handleCloseSaveNegative()
  }
  const handleSavePositiveKeywords = () => {
    props.savePositiveKeywords(
      props.accountId,
      props.lang,
      positiveKeywords.map(keyword => keyword.trim()).filter(keyword => keyword),
      adGroup
    )
    handleCloseSavePositive()
  }
  const negativeKeywordsArray = negativeKeywords ? negativeKeywords.map(keyword => keyword.trim()).filter(keyword => keyword) : []
  const positiveKeywordsArray = positiveKeywords.map(keyword=>keyword.trim()).filter(keyword=>keyword)
  const negativeErrors = KeywordsUtils.negativeKeywordsErrors(negativeKeywordsArray)
  const positiveErrors = KeywordsUtils.positiveKeywordsError(positiveKeywordsArray,['<make>','<name>','<city>'])

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if(reason !== 'clickaway'){props.resetFailedPos()}
  }

  return (
    <div className="Keywords">
      <Snackbar
        autoHideDuration={5000}
        color="secondary"
        open={props.failedSavingPos}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <SnackbarContent
          className="ErrorSnackBar"
          message={
            <span className="Error" >
              <Icon className="Icon">error</Icon>
              <span className="Message"><Translate id="keyword.keywordsError" /></span>
            </span>
          }
          action={
            <IconButton onClick={props.resetFailedPos}>
              <Icon style={{color:'white'}}>close</Icon>
            </IconButton>
          }
        />
      </Snackbar>
      <div className="Selects">
        <FormControl className="Languages">
          <Select
            value={props.lang}
            onChange={handleChangeLang}
          >
            {availableLanguages}
          </Select>
          <FormHelperText><Translate id="branding.chooseLanguage" /></FormHelperText>
        </FormControl>
        <FormControl className="SelectAdGroup">
          <Select
            value={adGroup}
            input={<Input name="adGroup" id="adGroup" />}
            onChange={handleChange}
            inputProps={{
              name: 'AdGroups',
              id: 'AdGroups-Select',
            }}
          >
            {adGroupList}
          </Select>
          <FormHelperText>{props.translate('branding.chooseAdGroup')}</FormHelperText>
        </FormControl>
      </div>
      <div className="Display">
        <div className="Inputs">
          <Card className="Positive">
            <KeywordsInput
              functionnality='save'
              type='positive'
              title={props.translate('keywords.posKeywords') as string}
              description
              originalKeywords={props.adGroupConfigsByName[adGroup].keywordPatterns}
              keywords={positiveKeywords}
              errors = {positiveErrors}
              handleInput={handleInputPositiveKeywords}
              resetFunction={handleOpenResetPositive}
              saveFunction={handleOpenSavePositive}
              savingPosKeywords={props.savingPosKeywords}
              translateErrors
            />
          </Card>
          <Card className="Negative">
            <KeywordsInput
              functionnality='save'
              type="negative"
              title={props.translate('keywords.negKeywords') as string}
              description
              originalKeywords={props.negativeKeywords[props.lang] || []}
              keywords={negativeKeywords}
              errors={negativeErrors}
              handleInput={handleInputNegativeKeywords}
              resetFunction={handleOpenResetNegative}
              saveFunction={handleOpenSaveNegative}
              translateErrors
            />
          </Card>
        </div>
      </div>
      <DialogMui
        title={props.translate('common.reset') as string}
        content={props.translate("branding.resetMessage") as string}
        confirmText={props.translate('common.reset') as string}
        cancelText={props.translate('common.cancel') as string}
        open={openResetNegative}
        handleSave={handleResetNegativeKeywords}
        handleClose={handleCloseResetNegative}
      />
      <DialogMui
        title={props.translate('common.save') as string}
        content={props.translate("branding.saveMessage") as string}
        confirmText={props.translate('common.save') as string}
        cancelText={props.translate('common.cancel') as string}
        open={openSaveNegative}
        handleClose={handleCloseSaveNegative}
        handleSave={handleSaveNegativeKeywords}
      />
      <DialogMui
        title={props.translate('common.reset') as string}
        content={props.translate("branding.resetMessage") as string}
        confirmText={props.translate('common.reset') as string}
        cancelText={props.translate('common.cancel') as string}
        open={openResetPositive}
        handleSave={handleResetPositiveKeywords}
        handleClose={handleCloseResetPositive}
      />
      <DialogMui
        title={props.translate('common.save') as string}
        content={props.translate("branding.saveMessage") as string}
        confirmText={props.translate('common.save') as string}
        cancelText={props.translate('common.cancel') as string}
        open={openSavePositive}
        handleClose={handleCloseSavePositive}
        handleSave={handleSavePositiveKeywords}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)
  (withLocalize(requiresKeywords({letThrough:false})(Keywords)))
