import './NumberBreakdown.css'
import * as React from "react"

interface NumberBreakdownProps{
  imgPath  : string
  title    : string
  keyValue : {
    [key:string] : Value
  }
}
interface Value{
  title : string
  value : string|number
}

const NumberBreakdown = (props:NumberBreakdownProps) => {
  const keys = Object.keys(props.keyValue)
  return (
    <div className="NumberBreakdown">
      <img className="LogoImage" src={props.imgPath}/>
      <div className="Title">{props.title}</div>
      <div className="KeyValues">
        {keys.map((x,i)=>{
          return (
            <React.Fragment key={x}>
              {i>0?<span className="KeyValueSeparator"/>:<></>}
              <div className="KeyValue">
                <div className="ValueTitle">{props.keyValue[x].title}</div>
                <div className="ValueValue">{props.keyValue[x].value}</div>
              </div>
            </React.Fragment>
        )})}
      </div>
    </div>
  )
}

export default NumberBreakdown
