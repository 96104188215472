export default class ObjectUtils {
	public static getObjectValues(obj) : any[]{
	  var values = []
	  for(let key in obj){values.push(obj[key])}
	  return values
	}
	public static getObjectKeys(obj) : string[]{
	  var keys = []
	  for(let key in obj){keys.push(key)}
	  return keys
	}
	public static updateObject(oldObject, newValues){
    return {...oldObject, ...newValues}
	}
	public static updateItemInArray(array, itemId, updateItemCallback) {
    const updatedItems = array.map(item => {
      if(item.id !== itemId) {return item}
      const updatedItem = updateItemCallback(item)
      return updatedItem
    });
    return updatedItems
	}
  public static getMirrorObjectFromArray(a:any[]){
    var returned = {}
    for(let x of a){returned[x] = x}
    return returned
  }
  public static mapToObject(a:any[],toKey:(x:any)=>string,toValue:(x:any)=>any){
    var returned = {}
    for(let x of a){returned[toKey(x)] = toValue(x)}
    return returned
  }
  public static mapOnObject(object:any,transform:(a:string,b:any)=>any) : any{
    var newObject = {}
    for(let key of Object.keys(object)){
      newObject[key] = transform(key,object[key])
    }
    return newObject
  }
  public static isObject(unknown:any) : boolean {
    return (typeof unknown === "object") && !Array.isArray(unknown)
  }
}
