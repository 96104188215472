import './NoInsightData.css'
import React          from 'react'
import { Translate }  from "react-localize-redux"
import { Typography } from '@material-ui/core'

interface NoInsightDataProps {
  dataName: string
}

const NoInsightData = (props: NoInsightDataProps) => {
  return (
    <div className="container">
        <Typography variant="h5">
          <Translate id="dashboard.budgetsInsight.noData" data={{dataName:props.dataName}}/>
        </Typography>
    </div>
  )
}

export default NoInsightData
