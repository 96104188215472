import "./MonthSetupModal.css"
import * as React              from "react"
import moment                  from "moment"
import {Translate}             from "react-localize-redux"
import {StringMap}             from "../../../../../model/generics"
import {
  Guideline,
  isPreciseGuideline,
}                              from "../../../../../model/pacy/Guideline"
import {
  ProxyGroup
}                              from "../../../../../model/pacy/ProxyGroup"
import Button                  from "@material-ui/core/Button"
import Dialog                  from "@material-ui/core/Dialog"
import DialogActions           from "@material-ui/core/DialogActions"
import DialogContent           from "@material-ui/core/DialogContent"
import DialogContentText       from "@material-ui/core/DialogContentText"
import DialogTitle             from "@material-ui/core/DialogTitle"
import Paper, { PaperProps }   from "@material-ui/core/Paper"
import Draggable               from "react-draggable"
import MoneyInput              from "../../MoneyInput"
import { Icon, Tooltip }       from "@material-ui/core"

interface MonthSetupModalProps{
  open             : boolean
  month            : string
  proxyGroup       : ProxyGroup
  currentGuideline : Guideline
  onRequestClose   : ()=>void
  onConfirm        : (values:StringMap<number>)=>void
  readOnly        ?: boolean
}

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const getProxyAmount = (guideline:Guideline, proxyId:string):number => (
  isPreciseGuideline(guideline) ? guideline.amounts[proxyId]||0 : 0
)

const MonthSetupModal = (props:MonthSetupModalProps) => {
  const [values, setValues] = React.useState({})
  for(const proxy of props.proxyGroup.proxies){
    if(values[proxy.id] === undefined){
      setValues(prevState=>({
        ...prevState,
        [proxy.id]: getProxyAmount(props.currentGuideline, proxy.id)
      }))
    }
  }
  const month = moment(props.month)

  return (
    <Dialog
      open={props.open}
      onClose={props.onRequestClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Translate id={"common.months."+month.format("MM")}/> {month.format("YYYY")}
      </DialogTitle>
      <DialogContent>
        {props.readOnly &&
          <div style={{fontSize: "10px", textAlign: "right"}}>
            <Translate id="common.readOnlyMessage"/>
          </div>
        }
        <DialogContentText>
          <Translate id="pacyPlanner.preciseModalContent"/>
        </DialogContentText>
        <div className="ProxiesValueSetup">
          {props.proxyGroup.proxies.map(proxy=>
            <div className="ProxyRow" key={proxy.id}>
              {proxy.name}
              <div className="ProxyRowDetails">
                <Tooltip
                  title={proxy.active ? <Translate id="pacyPlanner.modalActive" /> : <Translate id="pacyPlanner.modalInactive" />}
                  placement="top"
                >
                  <div>
                    <Icon style={proxy.active ? {color:'green'} : {color:'red'}}>
                      {proxy.active ? 'play_circle_filled' : 'pause_circle_filled'}
                    </Icon>&nbsp;
                  </div>
                </Tooltip>
                <MoneyInput
                  value={values[proxy.id]}
                  onChange={(e)=>setValues(prevState=>({...prevState, [proxy.id]: parseFloat(e.target.value)}))}
                />
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onRequestClose} color="primary">
          <Translate id="common.cancel"/>
        </Button>
        <Button onClick={()=>props.onConfirm(values)} color="primary" disabled={props.readOnly === true}>
          <Translate id="common.confirm"/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MonthSetupModal
