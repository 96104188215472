import './SearchTermsTable.css'
import * as React                  from "react"
import {connect}                   from "react-redux"
import {withLocalize, Translate}   from "react-localize-redux"
import BootstrapTable              from 'react-bootstrap-table-next'
import filterFactory               from 'react-bootstrap-table2-filter'
import {textFilter}                from 'react-bootstrap-table2-filter'
import paginationFactory           from 'react-bootstrap-table2-paginator'
import CSVUtils                    from '../../../../../../../utils/CSVUtils'
import NumFormatter                from '../../../../../../../utils/NumberFormatter'
import StatisticsAccessors         from "../../../../../../../storeAccessor/Statistics"
import ExplainedLoading            from "../../../../../../../components/loading"
import {Button}                    from "carbon-components-react"

const makeMapStateToProps = () => {
  const statsSelector = StatisticsAccessors.makeSearchTermsStatsInCurrentPeriodForCampaignExternalIdsSelector()
  return (state, ownProps) => {
    return {
      stats : statsSelector(state, ownProps)
    }
  }
}
const mapDispatchToProps = (dispatch) => ({})

class SearchTermsTable extends React.Component<any,any>{
  private table
  constructor(props){
    super(props)
    this.table = React.createRef()
    this.generateCSV = this.generateCSV.bind(this)
  }
  generateCSV(){
    let header     = ["Search terms","Keywords","Clicks","Avg CPC","Cost"]
    let dataFields = ["searchTerms","keywords","clicks","cpc","cost",]
    const data = (
      this.table.current.store._filteredData.length>0
      ? this.table.current.store._filteredData
      : this.table.current.store._data
    )
    CSVUtils.downloadCSV(
      "SearchTerms_"+this.props.friend.getName().split(" ").join("-")+".csv",
      CSVUtils.generateCSV(header, data.map(row=>dataFields.map(key=>row[key])), ",")
    )
  }

  // TODO: this doesn't seem to be called.
  pageListRenderer = ({
    pages,
    onPageChange
  }) => {
    // just exclude <, <<, >>, >
    const pageWithoutIndication = pages.filter(p => typeof p.page !== 'string');
    return (
      <div>
        {
          pageWithoutIndication.map(p =>{
              console.log("p")
              console.log(p)
            return <button className="btn btn-success" onClick={ () => onPageChange(p.page) }>{ NumFormatter.formatNumber(p.page) }</button>

          })
        }
      </div>
    );
  };



  render(){
    if(this.props.stats === undefined){return <ExplainedLoading translateId="loadings.stats"/>}
    const columns = [
      {
        text      : "Id",
        dataField : "id",
        sort      : true,
        isKey     : true,
        hidden    : true,
      },
      {
        text      : this.props.translate("campaigns.overview.searchTerms"),
        dataField : "searchTerms",
        sort      : true,
        filter    : textFilter({delay:50}),
        style     : {
          width: "40%",
        }
      },
      {
        text      : this.props.translate("campaigns.overview.keywords"),
        dataField : "keywords",
        sort      : true,
        filter    : textFilter({delay:50}),
        style     : {
          width: "35%",
        }
      },
      {
        text      : this.props.translate("common.clicks"),
        dataField : "clicks",
        sort      : true,
        formatter : (x,_)=>NumFormatter.formatNumber(x, 2),
        style     : {
          width: "5%",
          textAlign: "center",
        }
      },
      {
        text      : this.props.translate("campaigns.overview.avgCPC"),
        dataField : "cpc",
        sort      : true,
        formatter : (x,_)=>NumFormatter.formatCurrency(x),
        style     : {
          width: "5%",
          textAlign: "center",
        }
      },
      {
        text      : this.props.translate("common.cost"),
        dataField : "cost",
        sort      : true,
        formatter : (x,_)=>NumFormatter.formatCurrency(x),
        style     : {
          width: "5%",
          textAlign: "center",
        }
      },
    ]
    const sizePerPage = 5
    const paginationOptions = {
      hideSizePerPage: true,
      sizePerPage : sizePerPage,
      hidePageListOnlyOnePage: true,
      pageListRenderer: this.pageListRenderer
    }
    const extraProps = {
      pagination : paginationFactory(paginationOptions)
    }

    return (
      <div className="DataTablekeywordsForFriend">
        <div className="Header">
          <h4><Translate id="campaigns.overview.searchTermsTitle"/></h4>
          <Button onClick={this.generateCSV}>
            CSV
          </Button>
        </div>
        <BootstrapTable
          // pagination={paginationFactory({pageListRenderer: this.pageListRenderer})}
          ref={this.table}
          data={this.props.stats}
          columns={columns}
          filter={filterFactory()}
          keyField='id'
          defaultSorted={[{dataField: 'clicks',order: 'desc'}]}
          classes={"normalSizedDatatable OverviewDataTable"}
          {...extraProps}
        />
      </div>
    )
  }
}

export default withLocalize(connect(makeMapStateToProps,mapDispatchToProps)(SearchTermsTable))
