import "./AdCopyEditingForm.css"
import * as React                from "react"
import {
  withLocalize,
  LocalizeContextProps
}                                from "react-localize-redux"
import ArrayUtils                from "../../utils/ArrayUtils"
import TargetLimitedInput        from "../input/TargetLengthLimitedTextInput"
import {
  ProtoMarketingEvent,
  ProtoMarketingApproach,
  AdCopyStringLengths,
}                                from "../../model/adbuilder"
import { MakeModelsRow }         from "../../model/Store/Cleaner"
import {checkErrorsInField}      from "./utils"
import {RSAText}                 from "../../model/adcopy"
import {
  Icon,
  Menu,
  MenuItem
}                                from "@material-ui/core"
import {reorderRSAText}          from "./utils"

interface AdCopyEditingFormProps extends LocalizeContextProps {
  headlines              : RSAText[]
  descriptions           : RSAText[]
  onHeadlineUpdate       : (id:number, text:string, position?:number)=>void
  onDescriptionUpdate    : (id:number, text:string, position?:number)=>void
  lang                   : string
  newHeadline            : () => void
  newDescription         : () => void
  onError                : (errorsInTemplate:boolean) => void
  onPinError             : (pinError:boolean) => void
  tags                  ?: string[]
  stringLengths         ?: AdCopyStringLengths
  availableRows         ?: Array<MakeModelsRow&{inStock:boolean}>
  showErrorText         ?: boolean
  textLengthCalculation ?: (text:string, stringLengths: AdCopyStringLengths)=>number
  eventAndApproach      ?: {event:ProtoMarketingEvent, approach:ProtoMarketingApproach}
}

interface Errors {
  headlines    : string[][]
  descriptions : string[][]
  notifiedParentOnce : boolean
}

interface Text {
  headlines    : string[]
  descriptions : string[]
}

const AdCopyEditingForm = (props:AdCopyEditingFormProps) => {
  // State
  const [errors, setErrors] = React.useState<Errors>({
    headlines : props.headlines.map(_=>[]),
    descriptions : props.descriptions.map(_=>[]),
    notifiedParentOnce : false
  })
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  const [pin, setPin] = React.useState<{target:string,id:number}>({target:"",id:-1})
  
  const menuItemsTranslations = [
    "adCopies.unpinned",
    "adCopies.pinPos1",
    "adCopies.pinPos2",
    "adCopies.pinPos3",
  ]

  // Synchronize state when props change
  React.useEffect(()=>{
    setErrors({
      headlines : props.headlines.map(_=>[]),
      descriptions : props.descriptions.map(_=>[]),
      notifiedParentOnce : false
    })
    setPin({target:"",id:-1})
    validatePins()
  },[props.headlines, props.descriptions])

  // Each time the error state changes, verify if there are any errors and notify parent to block saving
  React.useEffect(()=>{
    let headlinesErrors = false
    for (let headlines of errors.headlines){
      if (headlines.length>0){
        headlinesErrors = true
        break
      }
    }
    let descriptionsErrors = false
    for (let descriptions of errors.descriptions){
      if (descriptions.length>0){
        descriptionsErrors = true
        break
      }
    }
    props.onError(headlinesErrors||descriptionsErrors)
  },[errors])

  const handleHeadlineInputError = (index:number, newErrors:string[]) => {
    if(newErrors.length > 0 && !ArrayUtils.isLooselySameArray(errors.headlines[index],newErrors)){
      setErrors(prev=>({
        ...prev,
        headlines: prev.headlines.map((x,i)=>i===index ? newErrors : x)
      }))
    }
  }

  const handleDescriptionInputError = (index:number, newErrors:string[]) => {
    if(newErrors.length > 0 && !ArrayUtils.isLooselySameArray(errors.descriptions[index],newErrors)){
      setErrors(prev=>({
        ...prev,
        descriptions: prev.descriptions.map((x,i)=>i===index ? newErrors : x)
      }))
    }
  }

  const handlePinClick = (event: React.MouseEvent<HTMLSpanElement>,target:string, id:number) => {
    setAnchor(event.currentTarget)
    setPin({target,id})
  }

  const handlePinChange = (value:string) => {
    const index = menuItemsTranslations.indexOf(value)
    if (pin.target === "headline"){
      props.onHeadlineUpdate(pin.id, props.headlines.find(x=>x.id===pin.id)?.text, index === 0 ? -1 : index)
    } else {
      props.onDescriptionUpdate(pin.id, props.descriptions.find(x=>x.id===pin.id)?.text, index === 0 ? -1 : index)
    }
    setAnchor(null)
    setPin({target:"",id:-1})
  }

  const findPinnedPosition = ():number => {
    const targetedHeadlinePosition = props.headlines.find(x=>x.id===pin.id)?.position
    const targetedDescriptionPosition = props.descriptions.find(x=>x.id===pin.id)?.position
    if (pin.target === "headline"){
      return targetedHeadlinePosition === -1 ? 0 : targetedHeadlinePosition
    }
    return targetedDescriptionPosition === -1 ? 0 : targetedDescriptionPosition
  }

  const validatePins = () => {
    const headlinesPositions = props.headlines.filter(x=>x.text!=="").map(x=>x.position)
    const descriptionsPositions = props.descriptions.filter(x=>x.text!=="").map(x=>x.position)
    const availablePositions = [1,2,3]

    // no pins
    if (headlinesPositions.every(pos=>pos===-1) && descriptionsPositions.every(pos=>pos===-1)){
      return
    }

    // pins, but all positions covered
    if (availablePositions.every(pos=>headlinesPositions.includes(pos))){
      return
    }

    // headlines checks
    const headlinesWithoutPins = []
    availablePositions.forEach(pos=>{
      if (!headlinesPositions.includes(pos)){
        headlinesWithoutPins.push(pos)
      }
    })
    const unpinnedHeadlinesNumber = headlinesPositions.filter(pos=>pos===-1).length

    // descriptions checks
    const descriptionsWithoutPins = []
    availablePositions.slice(0,-1).forEach(pos=>{
      if (!descriptionsPositions.includes(pos)){
        descriptionsWithoutPins.push(pos)
      }
    })
    const unpinnedDescriptionsNumber = descriptionsPositions.filter(pos=>pos===-1).length
    props.onPinError(descriptionsWithoutPins.length>unpinnedDescriptionsNumber || headlinesWithoutPins.length>unpinnedHeadlinesNumber)
  }

  validatePins()
  return (
    <div className="AdCopyEditingForm">
      <div className="Headlines">
        {reorderRSAText(props.headlines).map((headline,i)=> {
          return (
            <div key={"headline "+i} className="HeadLine">
              <div className="PinNumber">
                {headline.position !== -1 ? headline.position : undefined}
                <Icon
                  className={headline.position===-1?"Unpinned":"Pinned"}
                  onClick={(e)=>handlePinClick(e, "headline", headline.id)}
                >
                  push_pin
                </Icon>
              </div>
              <TargetLimitedInput
                label={`${props.translate("adBuilder.createEvent.headline")} ${headline.id}`}
                dragTextKey="tag"
                lengthLimit={30}
                softLimit
                defaultValue={headline.text}
                customError={(currentText)=>checkErrorsInField(props.tags||[], currentText, true)}
                onChange={(changedText)=>{props.onHeadlineUpdate(headline.id, changedText)}}
                lengthCalculator={(_text: string) => props.textLengthCalculation(_text, props.stringLengths)}
                showErrorText={props.showErrorText}
                onError={(newErrors)=>handleHeadlineInputError(i, newErrors)}
              />
            </div>
          )
        })}
        { props.headlines.length < 15 &&
          <div onClick={props.newHeadline} className="Add">
            <p className="Icon"><Icon>add_circle</Icon></p>
            <p className="AddLabel">{props.translate("adCopies.addHeadline")}</p>
          </div>
        }
      </div>
      <div className="Descriptions">
        {reorderRSAText(props.descriptions).map((description,i)=> {
          return (
            <div key={"description "+i} className="Description">
              <div className="PinNumber">
                {description.position !== -1 ? description.position : undefined}
                <Icon
                  className={description.position===-1?"Unpinned":"Pinned"}
                  onClick={(e)=>handlePinClick(e, "description", description.id)}
                >
                  push_pin
                </Icon>
              </div>
              <TargetLimitedInput
                label={`Description ${description.id}`}
                dragTextKey="tag"
                lengthLimit={90}
                softLimit
                defaultValue={description.text}
                customError={(currentText) => checkErrorsInField(props.tags||[], currentText, false)}
                onChange={(value)=>props.onDescriptionUpdate(description.id, value)}
                lengthCalculator={(_text: string) => props.textLengthCalculation(_text, props.stringLengths)}
                showErrorText={props.showErrorText}
                onError={(newErrors)=>handleDescriptionInputError(i, newErrors)}
              />
            </div>
          )
        })}
        { props.descriptions.length < 4 &&
          <div onClick={props.newDescription} className="Add">
            <p className="Icon"><Icon>add_circle</Icon></p>
            <p className="AddLabel">{props.translate("adCopies.addDescription")}</p>
          </div>
        }
      </div>
      <Menu
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={()=>setAnchor(null)}
      >
        {/*Headlines and descriptions don't have the same number of pinning options*/}
        {pin.target==="headline" && menuItemsTranslations.map((x,i)=>{
          return (
            <MenuItem
              key={x}
              onClick={()=>handlePinChange(x)}
              selected={findPinnedPosition() === i}
            >
              {props.translate(x)}
            </MenuItem>
          )
        })}
        {pin.target==="description" && [...menuItemsTranslations.slice(0,-1)].map((x,i)=>{
          return (
            <MenuItem
              key={x}
              onClick={()=>handlePinChange(x)}
              selected={findPinnedPosition() === i}
            >
              {props.translate(x)}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export default withLocalize(AdCopyEditingForm)
