import Actions                        from "../../../model/constant/actions"
import ReducerUtils                   from "../../../utils/ReducerUtils"
import SelectorGroup                  from "../../../model/engagementRules/SelectorGroup"
import {Selector}                     from "../../../model/engagementRules/Selector"
import Factory                        from "../../../model/engagementRules/HeadstoneToIDB2SelectorFactory"
import {findVehicleState}             from "../../../model/constant/VehicleState"
import {findVehicleType}              from "../../../model/constant/VehicleType"
import HeadstoneEngagementRule, {
  SelectorType,
  VehicleType as VehicleTypeSelector,
  VehicleState as VehicleStateSelector,
}                                     from "../../../model/engagementRules/HeadstoneEngagementRule"

const vehicleTypeOrVehicleStateSelector = (selector) => (
  selector.type+"" === SelectorType.VehicleType +"" ||
  selector.type+"" === SelectorType.VehicleState+""
)

const createErrorMsgVehicleType  = id=>"Unable to find a vehicleType in selectorGroup " +id
const createErrorMsgVehicleState = id=>"Unable to find a vehicleState in selectorGroup "+id

const currentRules = (state,action:{type:"RECEIVE_APPLIERS", accountId:number, selectorGroups:HeadstoneEngagementRule}) => {
  try{
    const organized = action.selectorGroups
    .reduce((organized,rawSelectorGroup)=>{
      // organized is alias for : rawSelectorGroupsByVehicleTypeByVehicleState
      const vehicleTypeSelector : VehicleTypeSelector = rawSelectorGroup.selectors.find(s=>s.type+""===""+SelectorType.VehicleType) as VehicleTypeSelector
      const vehicleStateSelector : VehicleStateSelector = rawSelectorGroup.selectors.find(s=>s.type+""===""+SelectorType.VehicleState) as VehicleStateSelector
      const vehicleType = vehicleTypeSelector.vehicleType
      const vehicleState = vehicleStateSelector.state
      const selectors : Selector[] = (
        rawSelectorGroup.selectors
        .filter(x=>!vehicleTypeOrVehicleStateSelector(x))
        .map(Factory)
      )
      const selectorGroup : SelectorGroup = {
        id           : rawSelectorGroup.id,
        vehicleState : findVehicleState(vehicleState),
        vehicleType  : findVehicleType(vehicleType),
        selectors    : selectors,
      }
      return {
        ...organized,
        [vehicleType] : {
          ...(organized[vehicleType] ? organized[vehicleType] : {}),
          [vehicleState] : [
            ...(
              (organized[vehicleType] && organized[vehicleType][vehicleState])
              ? organized[vehicleType][vehicleState]
              : []
            ),
            selectorGroup
          ]
        }
      }
    },{})
    return {...state, [action.accountId]:organized}
  }
  catch(error){
    console.error(error)
  }
}
interface SelectorGroupsStoreStructure {
  [accountId:number] : {
    [vehicleType:string] : {
      [vehicleState:string] : SelectorGroup[]
    }
  }
}
export default ReducerUtils.createReducer<SelectorGroupsStoreStructure>({},{
  [Actions.RECEIVE_APPLIERS] : currentRules,
})
