import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import {getToken}                                        from "../../storeAccessor/Generic"
import Headstone                                         from "../../utils/Headstone"
import {
  AdTemplate,
  AdBuilderCompatibleLang
}                                                        from "../../model/adbuilder/"

const AD_GROUP_NAMES = ["GENERAL", "NOM"]
const LANGUAGES = Object.values(AdBuilderCompatibleLang)

interface DefaultAdSetsRequestResponse {
  lang        : string
  adGroupName : string
  ads         : AdTemplate[]
}

export const getDefaultAdSet = (accountId: number) => (dispatch, getState) => {
  const token = getToken(getState())

  dispatch({type: Actions.RETRIEVING_BRANDING_DEFAULT_AD_SET, status: true})

  const defaultAdSetsRequests:Promise<DefaultAdSetsRequestResponse>[] = []
  for(const lang of LANGUAGES){
    for(const adGroupName of AD_GROUP_NAMES){
      defaultAdSetsRequests.push(
        ActionUtils.retryOnFailure(
          ()=>Headstone.getDefaultAdSet(token, accountId, lang, adGroupName)
        ).then(response=>{
          return {
            lang,
            adGroupName,
            ads : response.data
          }
        })
      )
    }
  }
  Promise.all(defaultAdSetsRequests)
    .then((responses) => {
      const action = responses.reduce((action, response)=>{
        if(!action.categorizedAds[response.lang]){action.categorizedAds[response.lang] = {}}
        action.categorizedAds[response.lang][response.adGroupName] = response.ads
        return action
      },{
        type : Actions.ADD_BRANDING_DEFAULT_AD_SET,
        accountId,
        categorizedAds : {}
      })
      dispatch(action)
    })
    .catch((error) => {
      console.log(error)
    })
    .then(
      dispatch({type: Actions.RETRIEVING_BRANDING_DEFAULT_AD_SET, status: false})
    )
}
