import ReducerUtils from "../../../utils/ReducerUtils"
import ArrayUtils from "../../../utils/ArrayUtils"
import Actions from "../../../model/constant/actions"
import DisplayCampaign from "../../../model/Store/Campaign/DisplayCampaign"

const saveCampaigns = (state:DisplayCampaignByAccount, action) => {
  const accountId = action.accountId
  if(!state[accountId]){
    return {
      ...state,
      [accountId] : action.campaigns
    }
  }
  return {
    ...state,
    [accountId] : [...state[accountId], ...action.campaigns]
  }
}

interface DisplayCampaignByAccount{
  [accountId:number] : DisplayCampaign[]
}

const Campaigns = ReducerUtils.createReducer<DisplayCampaignByAccount>({},{
  [Actions.RETRIEVE_DISPLAY_CAMPAIGNS] : saveCampaigns
})

export default Campaigns
