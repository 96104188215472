import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"

interface ErrorByAccount{
  [accountId:string] : string
}
const Running = ReducerUtils.createReducer<ErrorByAccount>({},{
  [Actions.PACY_MIGRATION_ERROR] : (state,action)=>({...state, [action.accountId]: action.error})
})

export default Running
