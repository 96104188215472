import './ButtonGoBack.css'
import * as React     from "react"
import {Translate}    from "react-localize-redux"

interface ButtonGoBackProps{
  onClick : ()=>void
}
const ButtonGoBack = (props:ButtonGoBackProps) => {
  return (
    <button className='GoBack ButtonGoBack' onClick={props.onClick}>
      <span className="GoBackText"><Translate id="common.goBack"/></span>
    </button>
  )
}

export default ButtonGoBack
