import axios           from "axios"
import Period          from "../model/Period"
import DateUtils       from "./DateUtils"

export default class Reporting{
  protected static getApiUrl(){
    return process.env.REACT_APP_REPORTING_URL
  }

  public static get(endpoint:string, queryParams?:any, body?:any, headers?:any){
    const url = ("/"+endpoint).replace("//","/")
    return axios.request({
      method  : "get",
      baseURL : Reporting.getApiUrl(),
      url     : url,
      params  : queryParams,
      data    : body,
      headers : headers,
      timeout : 10*1000,
    })
  }

  public static getAWCampaignStats(token:string, accountAWId:string, period:Period){
    const endpoint = "compiledReport/AW_CAMPAIGN_STATS/v1/AW/"+accountAWId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getBingCampaignStats(token:string, accountBingId:string, period:Period){
    const endpoint = "compiledReport/BING_CAMPAIGN_STATS/v0/BING/"+accountBingId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getAWGeoStats(token:string, accountAWId:string, period:Period){
    const endpoint = "compiledReport/AW_GEO_STATS/v0/AW/"+accountAWId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getBingGeoStats(token:string, accountBingId:string, period:Period){
    const endpoint = "compiledReport/BING_GEO_STATS/v0/BING/"+accountBingId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getAWSearchTermStats(token:string, accountAWId:string, period:Period){
    const endpoint = "compiledReport/AW_SEARCH_TERM_STATS/v0/AW/"+accountAWId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getBingSearchTermStats(token:string, accountBingId:string, period:Period){
    const endpoint = "compiledReport/BING_SEARCH_TERM_STATS/v0/BING/"+accountBingId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getAWKeywordStats(token:string, accountAWId:string, period:Period){
    const endpoint = "compiledReport/AW_KEYWORD_STATS/v0/AW/"+accountAWId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getBingKeywordStats(token:string, accountAWId:string, period:Period){
    const endpoint = "compiledReport/BING_KEYWORD_STATS/v0/BING/"+accountAWId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getGoogleBudgetStats(token:string, accountAWId:string, period:Period){
    const endpoint = "compiledReport/AW_BUDGET_STATS/v0/AW/"+accountAWId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getBingBudgetStats(token:string, accountBingId:string, period:Period){
    const endpoint = "compiledReport/BING_BUDGET_STATS/v0/BING/"+accountBingId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getFacebookBudgetStats(token:string, accountFacebookId:string, period:Period){
    const endpoint = "compiledReport/FACEBOOK_GHOST_BUDGET_STATS/v0/FB/"+accountFacebookId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getPlacementStats(token:string, accountAWId:string, period:Period){
    const endpoint = "compiledReport/AW_PLACEMENT_STATS/v0/AW/"+accountAWId
    const params = {
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getMonthlyStatsOfBudgets(token:string, accountAWId:string){
    const endpoint = `aw/${accountAWId}/stats/budget/monthly/`
    const params = {
      token
    }
    return Reporting.get(endpoint, params)
  }
  public static getImpressionsByDevice(token:string, accountAWId:string, period:Period){
    const endpoint = `aw/${accountAWId}/stats/bydevice/`
    const params = {
      token,
      dateFrom : DateUtils.format(period.dateFrom),
      dateTo   : DateUtils.format(period.dateTo),
    }
    return Reporting.get(endpoint, params)
  }
}
