import './LineSelector.css'
import * as React from "react"
import {DropdownV2}  from 'carbon-components-react'

interface Fill{
  primary   : string
  secondary : string
}
interface Label{
  primary   : string
  secondary : string
}
interface LineSelectorProps{
  items                 : any[]
  itemToString(item)    : string
  updatePrimary(item)   : void
  updateSecondary(item) : void
  fill                 ?: Fill
  label                ?: Label
}

const LineSelector:React.SFC<LineSelectorProps> = (props) => {
  return (
    <div className={"statPanelCheckbox"}>
      <div className={"statPanelCheckboxItem"}>
        <svg width="20" height="10">
          <rect width="20" height="10" style={{fill:props.fill.primary}} />
        </svg>
        <DropdownV2
          type="inline"
          label={props.label.primary}
          items={props.items}
          itemToString={(item)=> {
            if(item){return props.itemToString(item)}
            return props.label.primary
          }}
          onChange={item => props.updatePrimary(item.selectedItem)}
        />
      </div>
      <div className={"statPanelCheckboxItem"}>
        <svg width="20" height="10">
          <rect width="20" height="10" style={{fill:props.fill.secondary}} />
        </svg>
        <DropdownV2
          type="inline"
          label={props.label.secondary}
          items={props.items}
          itemToString={(item)=> {
            if(item){return props.itemToString(item)}
            return props.label.secondary
          }}
          onChange={item => props.updateSecondary(item.selectedItem)}
        />
      </div>
    </div>
  )
}
LineSelector.defaultProps = {
  items:[],
  itemToString:null,
  updatePrimary:null,
  updateSecondary:null,
  fill:{primary:"#4D5A75",secondary:"#72B9DD"},
  label:{primary:"Primary",secondary:"Secondary"},
}
export default LineSelector
