import moment           from "moment"
import {StringMap}      from "../generics"

export type Guideline = VagueGuideline|PreciseGuideline

interface BaseGuideline {
  readonly id        : string
  readonly year      : string
  readonly month     : string
  readonly createdBy : number
  readonly createdOn : string
}

export interface VagueGuideline extends BaseGuideline {
  readonly amount : number
}
export interface PreciseGuideline extends BaseGuideline {
  readonly amounts : {
    [proxyId:string] : number
  }
}


/********* UTILS *********/
export const isVagueGuideline = (guideline:Guideline) : guideline is VagueGuideline => {
  return (<VagueGuideline>guideline).amount !== undefined
}
export const isPreciseGuideline = (guideline:Guideline) : guideline is PreciseGuideline => {
  return (<PreciseGuideline>guideline).amounts !== undefined
}

//Ascending order of when its active
export const sortGuidelines = (a:Guideline, b:Guideline)=>{
  if     (a.year > b.year){return 1}
  else if(a.year < b.year){return -1}
  else{
    if     (a.month > b.month){return 1}
    else if(a.month < b.month){return -1}
    else{
      return (
        moment.utc(a.createdOn).isAfter(moment.utc(b.createdOn))  ?  1 :
        moment.utc(a.createdOn).isBefore(moment.utc(b.createdOn)) ? -1 : 0
      )
    }
  }
}

export const getGuidelineAmount = (guideline:Guideline):number => {
  if(isVagueGuideline(guideline)){return guideline.amount}
  return Object.keys(guideline.amounts).reduce((total,k)=>total+guideline.amounts[k],0)
}

export const getLastGuidelinePerMonth = (guidelines:Guideline[]):StringMap<Guideline> => {
  const byMonth = {}
  for(const x of guidelines){
    const month = x.year+"-"+x.month
    if(!byMonth[month] || x.createdOn > byMonth[month].createdOn){
      byMonth[month] = x
    }
  }
  return byMonth
}

const toDate = (x:string)=>moment(x).format("YYYY-MM-DD")
export const getGuidelineForDay = (guidelines:Guideline[], day:string):Guideline => {
  const year  = moment(day).format("YYYY")
  const month = moment(day).format("MM")
  let last : Guideline = {
    id        : "00000000-0000-0000-0000-000000000000",
    amount    : 0,
    createdBy : 0,
    createdOn : moment().format("YYYY-MM-DD"),
    year,
    month,
  }
  if(!guidelines.length){return last}
  const sorted = guidelines.sort(sortGuidelines)
  for(const guideline of sorted){
    if(guideline.year > year){break}
    if(guideline.year === year && guideline.month > month){break}
    if(toDate(guideline.createdOn) > day){break}
    last = guideline
  }
  return last
}
export const isGuidelineForDay = (guideline:Guideline, day:string):boolean => {
  const year  = moment(day).format("YYYY")
  const month = moment(day).format("MM")
  if(guideline.year > year){return false}
  if(guideline.year === year && guideline.month > month){return false}
  if(toDate(guideline.createdOn) > day){return false}
  return true
}

export const createTempGuideline = (year:string, month:string, amount:number|StringMap<number>):Guideline => {
  const temp:BaseGuideline = {
    year,
    month,
    id        : "",
    createdBy : 0,
    createdOn : "",
  }
  if(typeof amount === "number"){return {...temp, amount}}
  return {...temp, amounts: amount}
}


export const getPreviousGuideline = (guideline:Guideline, guidelines:Guideline[]):Guideline => {
  let previous:Guideline
  let guidelineYearmonth = guideline.year + "-" + guideline.month

  for(const arrayItem of guidelines){
    if (arrayItem.createdOn > guideline.createdOn) {
      continue
    }

    let arrayItemYearmonth = arrayItem.year + "-" + arrayItem.month

    if(arrayItemYearmonth <= guidelineYearmonth
      && arrayItem.createdOn !== guideline.createdOn
      && (!previous || arrayItemYearmonth > previous.year + "-" + previous.month)) {

      if ((arrayItem.createdOn < guideline.createdOn)) {
        previous = arrayItem
      }
    }

    else if (previous
      && arrayItemYearmonth >= previous.year + "-" + previous.month
      && arrayItemYearmonth <= guidelineYearmonth
      && arrayItem.createdOn !== guideline.createdOn) {

      if (arrayItem.createdOn > previous.createdOn){
        if (arrayItemYearmonth < guidelineYearmonth && arrayItem.createdOn < guideline.createdOn) {
          previous = arrayItem
        }
        else if (arrayItemYearmonth !== guidelineYearmonth) {
          previous = arrayItem
        }
        else if (arrayItemYearmonth === previous.year + "-" + previous.month && arrayItem.createdOn > previous.createdOn && arrayItem.createdOn < guideline.createdOn) {
          previous = arrayItem
        }
      }
    }
  }
  return previous || createTempGuideline(guideline.year, guideline.month, 0)
}
