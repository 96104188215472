import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    ended : {
      fontSize : 12,
      color: "#ED6464",
      display : "flex",
      paddingTop : 4,
      justifyContent : "center",
      width : 65,
      height : 35,
      background: "#ED646433 0% 0% no-repeat padding-box",
      border: "1px solid #ED6464",
      borderRadius : 4,
      '&:hover' : {
        cursor: "pointer"
      }
    },
    active : {
      fontSize : 12,
      color: "#379B4E",
      display : "flex",
      paddingTop : 4,
      justifyContent : "center",
      width : 65,
      height : 35,
      background: "#D9F8E0 0% 0% no-repeat padding-box",
      border: "1px solid #66D17F",
      borderRadius : 4,
      '&:hover' : {
        cursor: "pointer"
      }
    },
    paused : {
      fontSize : 12,
      color: "#D9CA71",
      display : "flex",
      paddingTop : 4,
      justifyContent : "center",
      width : 65,
      height : 35,
      background: "#F8F7D9 0% 0% no-repeat padding-box",
      border: "1px solid #D9CA71",
      borderRadius : 4,
      '&:hover' : {
        cursor: "pointer"
      }
    },
  })
)

export default useStyles
