import ReducerUtils       from "../../../utils/ReducerUtils"
import Actions            from "../../../model/constant/actions"
import {DecisionType}     from "../../../model/pacy/Decision"

const addDecisionType = (state:DecisionType[],action) => {
  return [...state , action.decisionType]
}

const removeDecisionType = (state:DecisionType[],action) => {
  return state.filter(decisionType => decisionType !== action.decisionType)
}

const clearDecisionTypes = (state:DecisionType[],action) => {
  return []
}

const DecisionTypes = ReducerUtils.createReducer<DecisionType[]>([],{
  [Actions.ADD_DECISION_TYPE]                : addDecisionType,
  [Actions.REMOVE_DECISION_TYPE]             : removeDecisionType,
  [Actions.CLEAR_DECISION_TYPE]              : clearDecisionTypes,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST] : clearDecisionTypes
})

export default DecisionTypes
