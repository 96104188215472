import {createSelector}  from "reselect"
import * as Generic      from "./Generic"
import ArrayUtils        from "../utils/ArrayUtils"
import ObjectUtils       from "../utils/ObjectUtils"
import GeoTargeting, {MultiLanguageGeoTargeting}                from "../model/Store/GeoBid/GeoTargeting"

export default class GeoBidAccessors {
  public static makeUniqueGeoWithoutLangSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getGeoTargetings,
      (accountId, geos)=>{
        if(!geos[accountId]){return []}
        const geoTargetings = geos[accountId].reduce((sorted:{[key:string]:MultiLanguageGeoTargeting},geo:GeoTargeting)=>{
          const key = this.serializeMatcherNoLang(geo.matcher)
          if(!sorted[key]){
            const {lang, ...cleanedMatcher} = geo.matcher
            sorted[key] = {
              ...geo,
              matcher : cleanedMatcher,
              languages : []
            }
          }
          sorted[key].languages.push(geo.matcher.lang)
          return sorted
        },{})
        return Object.values(geoTargetings)
      }
    )
  }
  public static makeGeoTargetingPlaceDefinitionsSelector(){
    return createSelector(
      (_,geoTargeting)=>geoTargeting,
      Generic.getGeocodingCache,
      Generic.getGeocodingMetaCache,
      Generic.getAdWordsPlaces,
      (geoTargeting, cache, metaCache, AWPlaces)=>{
        let placeDefinitions = []
        if(AWPlaces !== undefined && AWPlaces.length > 0){
          for(let i=0;i<geoTargeting.places.length;i++){
            const place = geoTargeting.places[i]
            let position
            if(cache[place.code]){position = cache[place.code]}
            else if(metaCache[place.code]){position = metaCache[place.code]}
            if(position){
              const tempAWPlace = AWPlaces.find(AWPlace=>AWPlace[0]==place.code)
              if(tempAWPlace){
                const address = tempAWPlace[2].split(",").join(", ")
                placeDefinitions.push({
                  id : i,
                  code : place.code,
                  edited : false,
                  include : place.include,
                  address : address,
                  bidModifier : place.bidModifier,
                  placeProps : {
                    position : position,
                    editable : false
                  }
                })

              }
            }
          }
        }
        return placeDefinitions
      }
    )
  }
  public static getGeoTargetingsForAccountIds(state,selectedAccountIds){
    var geoTargetings = []
    for(let accountId of selectedAccountIds){
      if(state.Params.GeoBids.GeoTargetings[accountId]){
        geoTargetings = geoTargetings.concat(
          state.Params.GeoBids.GeoTargetings[accountId]
        )
      }
    }
    return geoTargetings
  }
  public static getUniqueGeoTargetingsWithoutLangForAccountIds(state){
    const accountId = state.Accounts.selected
    let geoTargetings = {}
    if(state.Params.GeoBids.GeoTargetings[accountId]){
      geoTargetings = state.Params.GeoBids.GeoTargetings[accountId].reduce((geos,geo)=>{
        geos[this.serializeMatcherNoLang(geo.matcher)] = geo
        return geos
      },{})
    }
    return ObjectUtils.getObjectValues(geoTargetings)
  }
  private static serializeMatcherNoLang = (matcher) : string => {
    if(matcher.vehicleType){
      return [
        matcher.group,
        matcher.vehicleType,
        matcher.state,
      ].join("|")
    }
    return [
      matcher.group,
    ].join("|")
  }
}
