import * as React                     from "react"
import {connect}                      from "react-redux"
import Period                         from "../../model/Period"
import {loadWebLeads}                 from "../../actions/tracking/WebLead"
import ExplainedLoading               from "../loading"
import ErrorBoundary                  from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  const accountId = state.Accounts.selected
  const webInfos = state.Tracking.Web[accountId]
  const needToFetchWebLeads = (
    state.Tracking.NeedToFetchWebLeads &&
    state.Tracking.FetchingWebLeads === false
  )
  return {
    accountId           : accountId,
    period              : state.Period,
    leads               : webInfos ? webInfos.leads : undefined,
    needToFetchWebLeads : needToFetchWebLeads,
    fetching            : state.Tracking.FetchingWebLeads,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    fetchLeads : (accountId:number, period:Period) => {
      dispatch(loadWebLeads(accountId,period))
    },
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,
    ...DP,
    ...ownProps,
    fetchLeads : ()=>DP.fetchLeads(SP.accountId, SP.period)
  }
}
const LeadsLoader = props => {
  if(props.leads===undefined || props.fetching || props.needToFetchWebLeads){
    if(!props.fetching && props.needToFetchWebLeads){props.fetchLeads()}
    return <ExplainedLoading translateId="loadings.leads"/>
  }
  return <ErrorBoundary>{props.render(props.leads)}</ErrorBoundary>
}
const LeadsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(LeadsLoader)

const requiresLeads = Component => props => (
  <LeadsLoaderConnected
    render={ leads => <Component leads={leads} {...props} /> }
  />
)
export default requiresLeads
