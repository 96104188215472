import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"
import {PacyAccount}  from "../../../model/pacy/"

const updatePacyAccount = (state:PacyAccount,action:{account:PacyAccount}):PacyAccount => {
  return action.account
}
const getAccount = (state:PacyAccount,action:any):PacyAccount => {
  return action.account
}
const changeAccount = (state:PacyAccount,action:{account:PacyAccount}):PacyAccount => {
  return action.account
}
const Account = ReducerUtils.createReducer<PacyAccount>(null,{
  [Actions.UPDATE_PACY_ACCOUNT] : updatePacyAccount,
  [Actions.RETRIEVE_PACY_ACCOUNT] : getAccount,
  [Actions.PACY_CONFIGS_ACCOUNT_CHANGE] : changeAccount
})

export default Account
