import * as React                                 from "react"
import {ToastNotification}                        from "carbon-components-react"
import {withLocalize, TranslateFunction}          from "react-localize-redux"

interface PermissionErrorOwnProps{
  permission : string
}
interface PermissionErrorProps extends PermissionErrorOwnProps{
  translate : TranslateFunction
}

const PermissionError = (props:PermissionErrorProps) => {
  return (
    <ToastNotification
      onCloseButtonClick={(e)=> e.preventDefault}
      className="some-class"
      title={props.translate("permissions.errorTitle")}
      caption=""
      subtitle={props.translate("permissions.errorDescription", {permission: props.permission})}
    />
  )
}

export default withLocalize(PermissionError as any) as React.ComponentType<PermissionErrorOwnProps>
