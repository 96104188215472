import ReducerUtils       from "../../utils/ReducerUtils"
import Actions            from "../../model/constant/actions"
import {StringMap}        from "../../model/generics"
import {PossibleAgent}    from "../../model/pacy/"

const saveData = (state:State, action):State => {
  return {
    ...state,
    [action.accountId] : action.possibleAgents
  }
}


type State = StringMap<PossibleAgent[]>
const AccountStates = ReducerUtils.createReducer<State>({},{
  [Actions.RETRIEVE_PACY_POSSIBLE_AGENTS] : saveData
})

export default AccountStates
