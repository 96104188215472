import IDBAPI                    from '../../utils/IDBAPI'
import Actions                   from "../../model/constant/actions"
import UserActions               from "../../model/constant/UserAction"
import {getToken}                from "../../storeAccessor/Generic"
import LogBuilder                from "../../controller/log/LogBuilder"

const REMOVE_PREFERENCE_KEYWORD = "REMOVE_PREFERENCE"

export const retrieveAccountPreferences = (accountId:number) => (dispatch, getState) => {
  dispatch({
    type      : Actions.RETRIEVING_ACCOUNT_PREFERENCES,
    accountId : accountId
  })
  IDBAPI.get({
      token     : getToken(getState()),
      action    : "GetAccountPreferences",
      accountId : accountId,
    },
    response =>{
      dispatch({
        type        : Actions.RETRIEVE_ACCOUNT_PREFERENCES,
        accountId   : accountId,
        preferences : response.data.preferences
      })
    },
    error =>{console.log(error)}
  )
}
export const retrievePossibleWhiteLabels = () => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_POSSIBLE_WHITE_LABELS,
    status : true
  })
  IDBAPI.get({
      token  : getToken(getState()),
      action : "GetUniqueAccountWhiteLabels",
    },
    response =>{
      if(!response.error || response.error.length === 0){
        dispatch({
          type        : Actions.RETRIEVE_POSSIBLE_WHITE_LABELS,
          whiteLabels : response.data.whiteLabels
        })
      }
      dispatch({
        type   : Actions.RETRIEVING_POSSIBLE_WHITE_LABELS,
        status : false
      })
    },
    error =>{
      dispatch({
        type   : Actions.RETRIEVING_POSSIBLE_WHITE_LABELS,
        status : false
      })
    }
  )
}
export const setAccountWhiteLabel = (accountId:number, whiteLabel:string, retrieveAfterUpload:boolean=false) => (dispatch, getState) => {
  const token = getToken(getState())

  LogBuilder.log(UserActions.SAVE_WHITE_LABEL,token,{accountId, whiteLabel})

  IDBAPI.post({
      token          : token,
      action         : "SetAccountPreference",
      accountId      : accountId,
      preferenceName : "whitelabel",
      value          : whiteLabel
    },
    response => {
      if(retrieveAfterUpload){dispatch(retrieveAccountPreferences(accountId))}
    },
    error =>console.log(error)
  )
}
export const removeAccountWhiteLabel = (accountId:number, retrieveAfterUpload:boolean=false) => (dispatch, getState) => {
  const token = getToken(getState())

  LogBuilder.log(UserActions.SAVE_WHITE_LABEL,token,{accountId, whiteLabel:"REMOVE_PREFERENCE"})

  IDBAPI.post({
      token          : token,
      action         : "SetAccountPreference",
      accountId      : accountId,
      preferenceName : "whitelabel",
      value          : REMOVE_PREFERENCE_KEYWORD
    },
    response => {
      if(retrieveAfterUpload){dispatch(retrieveAccountPreferences(accountId))}
    },
    error =>console.log(error)
  )
}
