import Actions from "../../model/constant/actions"
import {Feed} from "../../model/feed/Feed"
import ReducerUtils from "../../utils/ReducerUtils"


export default ReducerUtils.createReducer<{[accountId:string]:{[feedId:string]:Feed}}>({},{
    [Actions.RETRIEVE_FEEDS] : (state, action)=>{
      return {
        ...state,
        [action.accountId] : [
          ...action.feeds
        ]
      }
    }
})
