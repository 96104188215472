import Period               from "../model/Period"
import moment               from "moment"
import MONTHS_ABBR          from "../model/constant/MonthsAbbreviation"
import ArrayUtils           from "./ArrayUtils"


export default class DateUtils{

  public static format(date:Date,format:string = "%Y-%m-%d"){
    return format
      .replace("%d",  `${date.getUTCDate() < 10 ? "0"+date.getUTCDate():date.getUTCDate()}`)
      .replace("%m",  `${date.getUTCMonth()+1 < 10 ? "0"+(date.getUTCMonth()+1):(date.getUTCMonth()+1)}`)
      .replace("%Y",  `${date.getUTCFullYear()}`)
      .replace("%M",  `${date.getUTCMinutes()}`)
      .replace("%MM", `${date.getUTCMilliseconds()}`)
      .replace("%H",  `${date.getUTCHours()}`)
      .replace("%S",  `${date.getUTCSeconds()}`)
  }
  public static getToday(){
    return new Date()
  }
  public static getYesterday(){
    let date = new Date()
    date.setUTCDate(date.getUTCDate()-1)
    return date
  }
  public static getLastWeek(){
    let dateFrom = new Date()
    let dateTo = new Date()
    dateFrom.setUTCDate((dateFrom.getUTCDate()-dateFrom.getDay())-7)
    dateTo.setUTCDate((dateTo.getUTCDate()-dateTo.getDay())-1)
    return {dateFrom:dateFrom,dateTo:dateTo}
  }
   public static getCurrentWeek(){
    let dateFrom = new Date()
    let dateTo = new Date()
    dateFrom.setUTCDate(dateFrom.getUTCDate()-dateFrom.getDay())
    dateTo.setUTCDate(dateTo.getUTCDate()+(6-dateTo.getDay()))
    return {dateFrom:dateFrom,dateTo:dateTo}
  }
  public static getLastMonth(){
    let dateFrom = new Date()
    let dateTo = new Date()
    dateFrom.setMonth(dateFrom.getMonth()-1)
    dateFrom.setUTCDate(1)
    dateTo.setUTCDate(0)
    return {dateFrom:dateFrom,dateTo:dateTo}
  }
  public static getCurrentMonth(){
    let dateFrom = new Date()
    let dateTo = new Date()
    dateTo.setMonth(dateFrom.getMonth()+1)
    dateTo.setUTCDate(0)
    dateFrom.setUTCDate(1)
    return {dateFrom:dateFrom,dateTo:dateTo}
  }
  public static getLast30Day(){
    let dateFrom = new Date()
    dateFrom.setUTCDate(dateFrom.getUTCDate()-29)
    return {dateFrom:dateFrom,dateTo:new Date()}
  }
  public static getLast7Day(){
    let dateFrom = new Date()
    dateFrom.setUTCDate(dateFrom.getUTCDate()-6)
    return {dateFrom:dateFrom,dateTo:new Date()}
  }
  public static getLast14Day(){
    let dateFrom = new Date()
    dateFrom.setUTCDate(dateFrom.getUTCDate()-13)
    return {dateFrom:dateFrom,dateTo:new Date()}
  }
  public static getPeriodLength(period:Period) : number {
    let d1 = new Date(period.dateFrom)
    let d2 = new Date(period.dateTo)
    return parseInt(new Number(d2.getTime() - d1.getTime()).toFixed(0))
  }
  public static getPeriodLengthInDays(period:Period) : number {
    return Math.ceil(
      (DateUtils.getPeriodLength(period)+1) / (1000*60*60*24)
    )
  }
  public static dividePeriod(period:Period,blocSize:number) : Period[]{
    // blocSize is in days and everything without stated units is calculated in days
    const MILISECONDS_IN_DAY = 60*60*24*1000
    const periodLength = DateUtils.getPeriodLength(period)/MILISECONDS_IN_DAY
    let periods = []
    let startingDate = period.dateFrom
    if(periodLength <= blocSize){return [period]}
    for(let i=0; i < Math.floor(periodLength/blocSize);i++){
      periods.push({
        dateFrom  : new Date(startingDate),
        dateTo    : new Date((new Date(startingDate)).getTime()+blocSize*MILISECONDS_IN_DAY),
      })
      startingDate = new Date((new Date(startingDate)).getTime()+blocSize*MILISECONDS_IN_DAY)
    }
    if(periodLength % blocSize >=1){
      periods.push({
        dateFrom  : new Date(startingDate),
        dateTo    : new Date((new Date(startingDate)).getTime()+(periodLength % blocSize)*MILISECONDS_IN_DAY),
      })
    }
    return periods
  }
  public static getSymmetricalPeriod(period:Period){
    let from = new Date(period.dateFrom.getTime() - DateUtils.getPeriodLength(period))
    from.setDate(from.getDate()-1)
    let to = new Date(period.dateFrom.getTime())
    to.setDate(period.dateFrom.getDate()-1)
    return {
      dateFrom : from,
      dateTo   : to
    }
  }
  public static leftPadMonth(month:number){
    var s = ""+month
    return s.length===1 ? "0"+s : s
  }
  public static getFormattedDate(date:Date,firstDayRound:boolean = false){
    return [
      date.getFullYear(),
      this.leftPadMonth(date.getMonth()+1),
      firstDayRound?"01":date.getDate()
    ].join("-")
  }
  public static substractMonths(date:Date,months:number){
    date.setMonth(date.getMonth()-months)
    return date
  }
  public static addMonths(date:Date,months:number){
    date.setMonth(date.getMonth()+months)
    return date
  }
  public static getCurrentYearMonth(){
    let now = new Date()
    return now.getFullYear()+"-"+now.getMonth()
  }
  public static isInPeriod(date:Date,period:Period):boolean{
    const d = DateUtils.format(date)
    return DateUtils.format(period.dateFrom) <= d && d <= DateUtils.format(period.dateTo)
  }
  // A month index is 0-based. January = 0 and December = 11.
  public static findMonthIndexFromAbbreviation(monthAbbr:string):number|null{
    monthAbbr = monthAbbr.trim().toLowerCase()
    if(monthAbbr.length != 3){return null}
    for(let i = 0; i < 12; i++){
      const data = MONTHS_ABBR[i]
      if(ArrayUtils.contain(data, monthAbbr)){
        return i
      }
    }
    return null
  }
  public static areSamePeriods(periods:Period[], timeOfDaySensitive:boolean=false):boolean{
    if(periods.length < 2){return true}
    if(timeOfDaySensitive){
      const fromTime = periods[0].dateFrom.getTime()
      const toTime = periods[0].dateTo.getTime()
      for(let i = 1; i < periods.length; i++){
        if(periods[i].dateFrom.getTime() != fromTime || periods[i].dateTo.getTime() != toTime){
          return false
        }
      }
      return true
    }
    const fromTime = periods[0].dateFrom.setHours(0,0,0,0)
    const toTime = periods[0].dateTo.setHours(0,0,0,0)
    for(let i = 1; i < periods.length; i++){
      if(periods[i].dateFrom.setHours(0,0,0,0) != fromTime || periods[i].dateTo.setHours(0,0,0,0) != toTime){
        return false
      }
    }
    return true
  }
  public static getDatesBetween(period:Period, includeStart:boolean=false, includeEnd:boolean=false):Date[]{
    if(DateUtils.getPeriodLengthInDays(period) <= 2){return [period.dateFrom, period.dateTo]}
    const dates = []
    let currentDate = new Date(period.dateFrom)
    if(!includeStart){currentDate.setDate(currentDate.getDate() + 1)}
    while(currentDate < period.dateTo){
      dates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }
    if(includeEnd){dates.push(period.dateTo)}
    return dates
  }

  public static YMFormat = "YYYY-MM"
  public static navigateYM(YM:string, movement:number, units:moment.unitOfTime.DurationConstructor="months"):string{
    return moment(YM).add(movement, units).format(DateUtils.YMFormat)
  }
  public static isCurrentOrFutureYM(YM:string):boolean{
    return moment(YM).format(DateUtils.YMFormat) >= moment().format(DateUtils.YMFormat)
  }
  public static isPastOrCurrentYM(YM:string):boolean{
    return moment(YM).format(DateUtils.YMFormat) <= moment().format(DateUtils.YMFormat)
  }

  public static enumerateDaysBetweenMomentDates(startDate:moment.Moment, endDate:moment.Moment):string[]{
    const days = []
    const now = moment(startDate)
    while(now.isSameOrBefore(endDate)){
      days.push(now.format("YYYY-MM-DD"))
      now.add(1, "days")
    }
    return days
  }
}
