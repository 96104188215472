import './ShapePointsList.css'
import * as React from "react"
import {Button} from "carbon-components-react"
import {FixedSizeList as List} from "react-window"

interface ShapePointsListProps{
  shapes
  selectedPoint  ?: any
  removePoint     : (shapeId:number, polygonIndex:number, pointIndex:number)=>void
  selectPoint     : (lat:number, lng:number)=>void
  separatePolygon : (shapeId:number, polygonIndex:number, separationIndex:number)=>void
  removePolygon   : (shapeId:number, polygonIndex:number)=>void
}
const ShapePointsList = (props:ShapePointsListProps) => {
  const onPointRemove = (e, id, polygonIndex, pointIndex) => {
    props.removePoint(id, polygonIndex, pointIndex)
    e.stopPropagation()
  }
  const onPointSelect = (e, lat, lng) => {
    props.selectPoint(lat, lng)
    e.stopPropagation()
  }
  const onPolygonRemove = (e, id, polygonIndex) => {
    props.removePolygon(id, polygonIndex)
    e.stopPropagation()
  }
  return (
    <div className="ShapePointsList">
      {props.shapes.map(shape=>
        <div key={shape.id} className="Shape">
          <h3>Selected shape's data</h3>
          {shape.polygons.map((polygon,i)=>
            <div key={i} className="Polygon">
              <div className="PolygonHeader" onClick={(e)=>onPolygonRemove(e, shape.id, i)}>
                <h4>Polygon #{i}</h4>
              </div>
              <div className="PointsList">
                <List
                  itemData={polygon}
                  height={200}
                  itemCount={polygon.length}
                  itemSize={55}
                  width={"100%"}
                >
                  {({index, style, data}) => {
                    const point = data[index]
                    const isSelected = props.selectedPoint && props.selectedPoint.lat === point.lat && props.selectedPoint.lng === point.lng
                    return (
                      <div style={style}>
                        <div
                          className={"Point"+(isSelected?" Selected":"")}
                          onClick={(e)=>onPointSelect(e, point.lat, point.lng)}
                        >
                          <Button className="RemovePointButton" onClick={(e)=>onPointRemove(e, shape.id, i, index)}>
                            X
                          </Button>
                          <div>lat: {point.lat}</div>
                          <div>lng: {point.lng}</div>
                        </div>
                        {index < data.length-1 &&
                          <div
                            key={index+"-Separator"}
                            className="PolygonSeparator"
                            onClick={()=>props.separatePolygon(shape.id, i, index+1)}
                          />
                        }
                      </div>
                    )
                  }}
                </List>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default ShapePointsList
