import "./Place.css"
import * as React                             from "react"
import {withLocalize}                         from "react-localize-redux"
import BidModifierFormatter                   from "../../../utils/localisation/BidModifier"
import PlaceDefinition                        from "../../../model/map/PlaceDefinition"
import {PlaceSearchBox}                       from "./PlaceSearchBox"
import MaterialTable                          from "material-table"
import { TextField }                          from "@material-ui/core"
import { connect }                            from "react-redux"
import PreferencesAccessors                   from "../../../storeAccessor/Preferences"
import {THEMES}                               from "../../../Application"
import DealerBreacherTheme                    from "../../../Themes/DealerBreacher"

interface PlaceEditorProps{
  places          : PlaceDefinition[]
  deletePlace     : (place)=>any
  updatePlace     : (place)=>any
  addPendingPlace : (place)=>void
  addCodeToQueue  : (code)=>void
  whiteLabel      : string
  translate      ?: (translateId:string)=>string
}
interface PlaceEditorState{
  editBidError:{
    id:number,
    error:string
  }
}
const makeMapStateToProps = () => {
  const whiteLabelAccessor = PreferencesAccessors.makeActiveWhiteLabelSelector()
  return (state,ownProps)=>{
    return {
      whiteLabel : whiteLabelAccessor(state)
    }
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {}
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
  }
}
class PlaceEditor extends React.Component<PlaceEditorProps,PlaceEditorState> {
  constructor(props){
    super(props)
    this.state={
      editBidError:{
        id:-1,
        error:""
      }
    }
  }
  updateBidModifier(place, bidModifier, target){
    // bidModifier is of format -0.4% or +10% or 44% (where 44% means +44%)
    const bid = bidModifier?BidModifierFormatter.reverseFormatBidModifier(bidModifier):1.0
    if(isNaN(bid)){this.setState({editBidError:{id:place.id,error:this.props.translate("geoTarget.bidErrorNaN")}})}
    else if(bid>10){this.setState({editBidError:{id:place.id,error:this.props.translate("geoTarget.bidErrorTooHigh")}})}
    else if(bid<0.1){this.setState({editBidError:{id:place.id,error:this.props.translate("geoTarget.bidErrorTooLow")}})}
    else{
      target.value = BidModifierFormatter.formatBidModifierToString(bid)
      if(this.state.editBidError.error!=""){
        this.setState({editBidError:{id:-1, error:""}})
      }
      if(place.bidModifier != bid){
        this.props.updatePlace({
          ...place,
          bidModifier:bid,
          edited:true
        })
      }
    }
  }
  handleInputKeyPress(event){
    if(event.key === "Enter"){event.target.blur()}
  }
  render(){
    const formatToInput=(bidModifier,place)=>{
      if(!place.include){return <></>}
      return (
        <div>
          <div className="PlaceBidModifierInput">
            <TextField
              style={{width:48}}
              required
              type="text"
              id={"Bid modifier-"+place.id}
              placeholder="Bid modifier"
              defaultValue={BidModifierFormatter.formatBidModifierToString(bidModifier)}
              onBlur={(event)=>this.updateBidModifier(place, event.target.value, event.target)}
              onKeyPress={this.handleInputKeyPress}
            />
          </div>
          {
            this.state.editBidError.id === place.id
            ? <div className={"alert alert-danger"}>{this.state.editBidError.error}</div>
            : <></>
          }
        </div>
      )
    }

    const actions=[
      {
        icon: 'delete',
        tooltip: 'Delete Targeting',
        onClick: (event, place) => {this.props.deletePlace(place)}
      }
    ]
    const columns = [
      {
        title : this.props.translate("geoTarget.targetingType"),
        field:"include" as "include",
        render : rowData => <span className={rowData.include ? 'Include' : 'Exclude'}>
            {rowData.include ? this.props.translate("geoTarget.inclusion") :this.props.translate("geoTarget.exclusion") }
          </span>
      },
      {
        title : this.props.translate("geoTarget.location"),
        field:"address" as "address",
      },
      {
        title : this.props.translate("geoTarget.bidModifier"),
        field:"bidModifier" as "bidModifier",
      }
    ]
    return (
      <div className="Place">
        <PlaceSearchBox
          currentPlaces={this.props.places}
          addPendingPlace={this.props.addPendingPlace}
          addCodeToQueue={this.props.addCodeToQueue}
        />
        <MaterialTable
          style={{marginTop:20}}
          title={this.props.translate('geoTarget.places')}
          columns={columns}
          data ={this.props.places.map(p=> ({
            ...p,bidModifier:formatToInput(p.bidModifier,p)
          }))}
          actions={actions}
          options={{
            headerStyle: {
              backgroundColor: THEMES[this.props.whiteLabel] ? THEMES[this.props.whiteLabel].palette.primary.main : DealerBreacherTheme.palette.primary.main,
              color :THEMES[this.props.whiteLabel] ? THEMES[this.props.whiteLabel].palette.primary.contrastText : DealerBreacherTheme.palette.primary.contrastText,
              fontSize:'15px'
            },
            actionsColumnIndex:-1
          }}
        />
      </div>
    )
  }
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(withLocalize(PlaceEditor))
