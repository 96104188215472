import "./RadiusManager.css"
import * as React                   from "react"
import {
  withLocalize,
  Translate,
   LocalizeContextProps
}                                   from "react-localize-redux"
import MathUtils                    from "../../../utils/MathUtils"
import ArrayUtils                   from "../../../utils/ArrayUtils"
import  {Modal}                     from "carbon-components-react"
import CircleDefinition             from "../../../model/map/CircleDefinition"
import BidModifierFormatter         from "../../../utils/localisation/BidModifier"
import {
  Button,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Input,
  Table,
  Icon,
}                                   from "@material-ui/core"


interface RadiusManagerProps extends LocalizeContextProps {
  circles        : CircleDefinition[]
  deleteCircle   : (circle)=>any
  updateCircle   : (circle)=>any
  selectCircle   : (circle)=>any
  deselectCircle : ()=>any
  setSaveActive  : (active:boolean) =>void
  showLatError   : () => void
  hideLatError   : () => void
  showLngError   : () => void
  hideLngError   : () => void
}
interface RadiusManagerState{
  errors : {
    [inputName:string] : {
      [id:number] : string
    }
  }
  isModalOpen : boolean
  errorLat    : boolean
  errorLng    : boolean
  errorLngIds : number[]
  errorLatIds : number[]
}
class RadiusManager extends React.Component<RadiusManagerProps, RadiusManagerState>{
  constructor(props){
    super(props)
    this.state = {
      errors : {
        bid : {},
        radius : {},
      },
      isModalOpen : false,
      errorLat    : false,
      errorLng    : false,
      errorLngIds : [],
      errorLatIds : []
    }
  }
  componentDidUpdate(prevProps:RadiusManagerProps, prevState:RadiusManagerState){
    for(const prevCircle of prevProps.circles){
      let currCircle = this.props.circles.find(x=>x.id === prevCircle.id)
      if(currCircle && prevCircle.circleProps.radius !== currCircle.circleProps.radius && currCircle.circleProps.radius < 1000){
        currCircle = {
          ...currCircle,
          circleProps : {
            ...currCircle.circleProps,
            radius : 1000
          }
        }
        this.props.updateCircle(currCircle)
      }
    }
  }
  setError(errorName, id, error){
    this.setState({
      errors : {
        ...this.state.errors,
        [errorName] : {
          ...this.state.errors[errorName],
          [id] : error
        },
      },
      isModalOpen : error!=""
    })
  }
  updateRadius(circle, radius, target){
    const rad = MathUtils.round(radius,0)*1000
    if(isNaN(rad) || rad < 1 || rad > 500000){
      this.setError("radius", circle.id, this.props.translate("geoTarget.radiusError"))
      return
    }
    else if(this.state.errors.radius[circle.id]!=""){
      this.setError("radius", circle.id, "")
    }
    target.value = MathUtils.round(radius, 0)
    if(circle.circleProps.radius != rad){
      this.props.updateCircle({
        ...circle,
        edited : true,
        circleProps : {
          ...circle.circleProps,
          radius : rad
        }
      })
    }
  }
  updateBidModifier(circle, bidModifier, target){
    // bidModifier is of format -0.4% or +10% or 44% (where 44% means +44%)
    const bid = bidModifier?BidModifierFormatter.reverseFormatBidModifier(bidModifier):1.0
    if(isNaN(bid)){this.setError("bid", circle.id, this.props.translate("geoTarget.bidErrorNaN"))}
    else if(bid>10){this.setError("bid", circle.id, this.props.translate("geoTarget.bidErrorTooHigh"))}
    else if(bid<0.1){this.setError("bid", circle.id, this.props.translate("geoTarget.bidErrorTooLow"))}
    else{
      target.value = BidModifierFormatter.formatBidModifierToString(bid)
      if(this.state.errors.bid[circle.id]!=""){this.setError("bid", circle.id, "")}
      if(circle.bidModifier != bid){
        this.props.updateCircle({
          ...circle,
          bidModifier:bid,
          edited:true
        })
      }
    }
  }
  handleInputKeyPress(event){
    if(event.key === "Enter"){event.target.blur()}
  }
  updateCircleLat(event,circle) {
    if( -85.05112878 <= event.target.value && event.target.value <= 85.05112878 ) {
      this.props.hideLatError()
      this.setState(prevState=>{
        return {
        ...prevState,
        errorLatIds : [...prevState.errorLatIds].filter(id=>id!=circle.id)
        }
      })
      this.props.updateCircle(
        {...circle,
          circleProps : {
            ...circle.circleProps,
            center : {
              ...circle.circleProps.center,
              lat : event.target.value
            }
          }
        }
      )
    }
    else{
      this.props.showLatError()
      this.setState(prevState=>{
        return {
          ...prevState,
          errorLatIds : [...prevState.errorLatIds,circle.id]
        }
      })
    }
  }
  updateCircleLng(event,circle) {
    if(Math.abs(event.target.value) < 180 ) {
      this.props.hideLngError()
      this.setState(prevState=>{
        return {
          ...prevState,
          errorLngIds : [...prevState.errorLngIds].filter(id=>id!=circle.id)
        }
      })
      this.props.updateCircle(
        {...circle,
          circleProps : {
            ...circle.circleProps,
            center : {
              ...circle.circleProps.center,
              lng : event.target.value,
            }
          },
        }
      )
    }
    else{
      this.props.showLngError()
      this.setState(prevState=>{
        return{
          ...prevState,
          errorLngIds : [...prevState.errorLngIds,circle.id]
        }
      })
    }
  }
  render(){
    const errors = ArrayUtils.unique(ArrayUtils.flatten(
      ArrayUtils.getArrayFromObject(this.state.errors)
      .map(inputErrors=>ArrayUtils.getArrayFromObject(inputErrors).filter(x=>x))
    ))

    this.props.setSaveActive(errors.length===0 )
    return(
      <div  className="RadiusManager">
        <Modal
          onRequestClose={()=>this.setState({isModalOpen:false})}
          className="some-class"
          passiveModal
          modalLabel="Error"
          open={this.state.isModalOpen}
        >
          {errors.map((error,i)=><div key={i} className={"alert alert-danger"}>{error}</div>)}
        </Modal>
        <Table color="primary" stickyHeader size="small">
         <TableHead >
            <TableRow>
              <TableCell style={{zIndex:100,fontSize:15}}  align="left"><Translate id="geoTarget.position"/></TableCell>
              <TableCell style={{zIndex:100,fontSize:15}}  align="left"><Translate id="geoTarget.radiusKM"/></TableCell>
              <TableCell style={{zIndex:100,fontSize:15}}  align="left"><Translate id="geoTarget.bidModifier"/></TableCell>
              <TableCell style={{zIndex:100,fontSize:15}}  ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody >
          {this.props.circles
            .sort((a,b)=>ArrayUtils.comp(b.id,a.id))
            .map((circle,i)=>{
              const hasRadiusError = this.state.errors.radius[circle.id] && this.state.errors.radius[circle.id]!=""
              const hasBidError = this.state.errors.bid[circle.id] && this.state.errors.bid[circle.id]!=""
              const radius = MathUtils.round(circle.circleProps.radius/1000, 0)
              return(
                <TableRow
                  key={circle.id}
                  className="RadiusInfo"
                  onMouseEnter={()=>this.props.selectCircle(circle)}
                  onMouseLeave={this.props.deselectCircle}
                >
                <TableCell>
                  <Input
                    error={this.state.errorLatIds.includes(circle.id)}
                    style={{minWidth:75}}
                    className="Inputs"
                    required
                    type="text"
                    id="Position"
                    key={circle.circleProps.center.lat}
                    defaultValue={circle.circleProps.center.lat}
                    onBlur={(event)=>this.updateCircleLat(event,circle)}
                  />
                  <span style={{fontWeight:"bold",fontSize:15}}>,&nbsp;</span>
                  <Input
                    error={this.state.errorLngIds.includes(circle.id)}
                    style={{minWidth:80,marginLeft:12}}
                    className="Inputs"
                    required
                    type="text"
                    id="Position"
                    key={circle.circleProps.center.lng}
                    defaultValue={circle.circleProps.center.lng}
                    onBlur={event=>this.updateCircleLng(event,circle)}
                  />
                </TableCell>
                  <TableCell >
                    <Input
                      className={hasRadiusError?"RadiusInfoInputError Inputs":"Inputs"}
                      required
                      type="text"
                      id="Radius"
                      placeholder="Radius"
                      key={radius}
                      defaultValue={radius}
                      onBlur={(event)=>this.updateRadius(circle, (event.target as unknown as HTMLInputElement).value, event.target)}
                      onKeyPress={this.handleInputKeyPress}
                    />
                  </TableCell>
                  <TableCell >
                    <Input
                      className={hasBidError?"RadiusInfoInputError Inputs":"Inputs"}
                      required
                      type="text"
                      id="Bid modifier"
                      placeholder="Bid modifier"
                      defaultValue={BidModifierFormatter.formatBidModifierToString(circle.bidModifier)}
                      onBlur={(event)=>this.updateBidModifier(circle, (event.target as unknown as HTMLInputElement).value, event.target)}
                      onKeyPress={this.handleInputKeyPress}
                    />
                  </TableCell>
                  <TableCell className="Actions">
                    <Button
                      size="small"
                      onClick={()=>this.props.deleteCircle(circle)}
                    >
                      <Icon>delete</Icon>
                    </Button>
                  </TableCell>
                </TableRow>
            )})}
          </TableBody>
        </Table>
      </div>    )
  }
}

export default withLocalize(RadiusManager)
