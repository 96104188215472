import "./SimpleRSAAdCopy.css"
import * as React  from "react"
import {RSAText}   from "../../../model/adcopy"

interface SimpleRSAAdCopyOwnProps{
  headlines    : RSAText[]
  descriptions : RSAText[]
  url          : string
}
interface SimpleRSAAdCopyProps extends SimpleRSAAdCopyOwnProps {}

const finishWithPonctuation = new RegExp("[.!?]{1}$")
const formatDescriptions = (descriptions:string[]):string => {
  const filtered = descriptions.map(x=>x.trim()).filter(x=>x) //remove empty
  return finishWithPonctuation.test(filtered[0])
    ? filtered.join(" ")
    : filtered.join(". ")
}

const formatHeadlines = (headlines:string[]):string => {
  const filtered = headlines.map(x=>x.trim()).filter(x=>x) //remove empty
  return filtered.join(" | ")
}
// Did something really quick to prioritize placing pinned texts in their correct positions
// fallbacks are a little weird
const positionHeadlines = (headlines:RSAText[]):string[] => {
  const pos1 = headlines.find(x=>x.position===1)||headlines[0]
  const pos2 = headlines.find(x=>x.position===2)||headlines[1]
  const pos3 = headlines.find(x=>x.position===3)||headlines[2]||headlines[0]
  return [pos1.text, pos2.text, pos3.text]
}

const positionDescriptions = (descriptions:RSAText[]):string[] => {
  const pos1 = descriptions.find(x=>x.position===1)||descriptions[0]
  const pos2 = descriptions.find(x=>x.position===2)||descriptions[1]||descriptions[0]
  return [pos1.text, pos2.text]
}

const SimpleRSAAdCopy = (props:SimpleRSAAdCopyProps) => {
  return (
    <div className="SimpleRSAAdCopy" >
      <div className="Title">
        {formatHeadlines(positionHeadlines(props.headlines))}
      </div>
      <div className="Url">{props.url}</div>
      <div className="Description">
        {formatDescriptions(positionDescriptions(props.descriptions))}
      </div>
    </div>
  )
}

export default SimpleRSAAdCopy
