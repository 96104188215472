import Reporting       from "../../utils/Reporting"
import CSVUtils        from "../../utils/CSVUtils"
import ActionUtils     from "../../utils/ActionUtils"
import Actions         from "../../model/constant/actions"
import {getToken}      from "../../storeAccessor/Generic"

const googleKeywordStatsFieldRenaming = {
  CampaignId  : "CampaignAWId",
  Date        : "Day",
  Criteria    : "Keyword",
  CriterionId : "KeywordId"
}
const bingKeywordStatsFieldRenaming = {
  CampaignId  : "CampaignBingId",
  TimePeriod  : "Day"
}
const renameAWHeaders = (header)=>googleKeywordStatsFieldRenaming[header]||header
const renameBingHeaders = (header)=>bingKeywordStatsFieldRenaming[header]||header

export default (accountId:number, accountAWId:string, accountBingId:string, period) => async (dispatch, getState) => {
  const token = getToken(getState())
  dispatch({
    type   : Actions.RETRIEVING_KEYWORDS_STATISTICS,
    status : true
  })
  try{
    const actions = await Promise.all([
      getAWKeywordStatsAction(token,accountId,accountAWId,period),
      getBingKeywordStatsAction(token,accountId,accountBingId,period)
    ])
    for(const action of actions){
      if(action){dispatch(action)}
    }
  }
  catch(e){
    console.log("General error while retrieving keyword stats", e)
  }
  dispatch({
    type   : Actions.RETRIEVING_KEYWORDS_STATISTICS,
    status : false
  })
}
const getAWKeywordStatsAction = (token:string, accountId:number, accountAWId:string, period) => (dispatch, getState) =>{
  return ActionUtils.retryOnFailure(()=>
    Reporting.getAWKeywordStats(token, accountAWId, period)
  )
  .then(
    (response) => dispatch(loadStatSuccess(response.data, accountId)),
    (error)    => console.log(error)
  )
}
const getBingKeywordStatsAction = (token:string, accountId:number, accountBingId:string, period) => {
  const baseAction = {
    type : Actions.RETRIEVE_BING_KEYWORDS_STATS_OF_ACCOUNT,
    accountId,
    stats : [],
    period
  }
  if(accountBingId === "0"){return baseAction}
  return ActionUtils.retryOnFailure(()=>
    Reporting.getBingKeywordStats(token,accountBingId,period)
  )
  .then((response)=>{
    baseAction.stats = CSVUtils.parseCSVWithHeaders(response.data, renameBingHeaders)
    return baseAction
  })
  .catch((error)=>{
    console.log(error)
  })
}
const loadStatSuccess = (response, accountId) => {
  const stats = CSVUtils.parseCSVWithHeaders(response, renameAWHeaders)
  return {
    type : Actions.RETRIEVE_AW_KEYWORDS_STATS_OF_ACCOUNT,
    accountId,
    stats
  }
}
