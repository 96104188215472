import Keystone       from "../utils/Keystone"
import Actions        from "../model/constant/actions"
import BudgetPlan     from "../model/Store/BudgetPlan/BudgetPlan"
import BudgetPlanPart from "../model/Store/BudgetPlan/BudgetPlanPart"
import ObjectUtils    from "../utils/ObjectUtils"
import {getToken}     from "../storeAccessor/Generic"

const getBudgetPlansSuccess = (response, accountId) => {
  const budgetPlans = response.data.budgetPlans.map((budgetPlan)=>{
    const bugetPlanParts = ObjectUtils.getObjectValues(
      budgetPlan.parts
    )
    .map((part)=>new BudgetPlanPart(
      part.period,
      part.amount,
      part.userId,
      new Date(part.savedOn.date),
      part.revisions.map(partRev=>new BudgetPlanPart(
        partRev.period,
        partRev.amount,
        partRev.userId,
        new Date(partRev.savedOn.date)
      ))
    ))
    return new BudgetPlan(
      accountId,
      budgetPlan.budgetId,
      budgetPlan.provider,
      bugetPlanParts
    )
  })
  return {
    type        : Actions.RETRIEVE_BUDGET_PLANS_FOR_ACCOUNTID,
    budgetPlans : budgetPlans,
    accountId   : accountId,
  }
}
const storeUsersDescription = response => {
  return {
    type : Actions.STORE_USERS_DESCRIPTIONS,
    descriptions : response.data.usersDescription,
  }
}

export const getBudgetPlans = (accountId:number) => (dispatch, getState) => {
  dispatch({type:Actions.RETRIEVING_BUDGET_PLANS_FOR_ACCOUNTID, status:true})
  Keystone.get({
      action    : "GetAllBudgetPlans",
      token     : getToken(getState()),
      accountId : accountId
    },
    response => {
      if(response?.data?.budgetPlans){
        dispatch(getBudgetPlansSuccess(response, accountId))
      }
      if(response.data !== undefined && response.data.usersDescription !== undefined){
        dispatch(storeUsersDescription(response))
      }
      dispatch({type:Actions.RETRIEVING_BUDGET_PLANS_FOR_ACCOUNTID, status:false})
    },
    error => console.log(error)
  )
}
export const retrieveIfBudgetPlanActive = (accountId:number) => (dispatch, getState) => {
  Keystone.get({
      action    : "GetIfBudgetPlanPushActive",
      token     : getToken(getState()),
      accountId : accountId
    },
    response => {
      dispatch({
        type      : Actions.RETRIEVE_IF_BUDGET_PLAN_IS_ACTIVE,
        accountId : accountId,
        active    : response.data.active
      })
    },
    error => console.log(error)
  )
}
