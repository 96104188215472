import ReducerUtils     from "../../../utils/ReducerUtils"
import Actions          from "../../../model/constant/actions"
import NegativeKeywords from "../../../model/branding/NegativeKeywords"

const saveData = (state,action:{negativeKeywords:NegativeKeywords,accountId:number}) => {
  return {
    ...state,
    [action.accountId] : {
      ...(state[action.accountId]||{}),
      ...action.negativeKeywords
    }
  }
}
const retrieveNegativeKeywords = ReducerUtils.createReducer({},{
  [Actions.RETRIEVE_BRANDING_KEYWORDS] : saveData
})

export default retrieveNegativeKeywords
