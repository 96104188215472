/*global google*/
import * as React                  from "react"
import keydown                     from 'react-keydown'
import {Marker, Circle, Polygon}   from "react-google-maps"
import {HeatmapLayer}              from "react-google-maps//lib/components/visualization/HeatmapLayer"
import CircleDefinition            from "../../model/map/CircleDefinition"
import PlaceDefinition             from "../../model/map/PlaceDefinition"
import {Map}                       from "./Map"
import CustomControl               from "./CustomControl"
import ObjectUtils                 from "../../utils/ObjectUtils"
import MathUtils                   from "../../utils/MathUtils"
import MapUtils                    from "../../utils/MapUtils"
import UserLevelRestrictedPolygon  from "../Map/UserLevelRestrictedPolygon"
import {
  withLocalize,
  LocalizeContextProps
}                                  from "react-localize-redux"
import {
  Snackbar,
  SnackbarContent
}                                  from "@material-ui/core"
import theme from "../../Themes/DealerBreacher"

interface MapBuilderProps extends LocalizeContextProps{
  circlesDefinitions   ?: CircleDefinition[]
  placesDefinitions    ?: PlaceDefinition[]
  heatmapData          ?: any
  onMapClick           ?: (e)=>void
  updateCircle         ?: (circle)=>void
  mapProps             ?: any
  mapSize              ?: number
  resizeOnNewCircles   ?: boolean
  ignoreExcludedPlaces ?: boolean
  shapes               ?: any
  standaloneShapes     ?: any[]
  displayPinAndShape   ?: boolean
  customControls       ?: CustomControlData[]
}
interface CustomControlData{
  name          : string
  renderControl : ()=>any
  position      : google.maps.ControlPosition
}
interface MapBuilderState{
  isSnackbarOpen : boolean
}

class MapBuilder extends React.Component<MapBuilderProps, MapBuilderState>{
  static defaultProps = {
    circlesDefinitions   : [],
    placesDefinitions    : [],
    heatmapData          : [],
    onMapClick           : ()=>{},
    updateCircle         : ()=>{},
    mapProps             : {},
    mapSize              : 400,
    resizeOnNewCircles   : false,
    ignoreExcludedPlaces : false,
    shapes               : {},
    standaloneShapes     : [],
    displayPinAndShape   : false,
    customControls       : [],
  }

  private map
  /*
  mapElements
    Elements by id+type (C=Circle, M=Marker, P=Polygon, SP=StandalonePolygon), ex: {
      1-C : circle1,
      2-C : circle2,
      1-M : marker1,
      ...
    }
  */
  private mapElements = {}
  constructor(props){
    super(props)
    this.state={
      isSnackbarOpen : false,
    }
    this.updateCircle = this.updateCircle.bind(this)
    this.resetMapViewport = this.resetMapViewport.bind(this)
    this.createElementRef = this.createElementRef.bind(this)
    this.generateHeatMap = this.generateHeatMap.bind(this)
    this.generatePlaces = this.generatePlaces.bind(this)
    this.generateCircles = this.generateCircles.bind(this)
    this.shouldResetMapViewport = this.shouldResetMapViewport.bind(this)
  }
  componentDidMount(){
    setTimeout(()=>this.resetMapViewport(), 500)
  }
  shouldResetMapViewport(prevProps) : boolean {
    const props = this.props
    if(prevProps.placesDefinitions.length !== props.placesDefinitions.length){return true}
    if(props.placesDefinitions.length && (props.shapes.length !== prevProps.shapes.length)){return true}
    if(props.resizeOnNewCircles && prevProps.circlesDefinitions.length !== props.circlesDefinitions.length){return true}
    const currentCodes = props.placesDefinitions.map(y=>y.code)
    const diff = prevProps.placesDefinitions.filter(x=>!currentCodes.find(code=>code===x.code))
    if(diff.length!==0){return true}
    if(props.resizeOnNewCircles){
      const keyer = c=>[
        c.circleProps.center.lat,
        c.circleProps.center.lng,
        c.circleProps.radius,
      ].join(",")
      const currentKeys = props.circlesDefinitions.map(keyer)
      const diffCircles = prevProps.circlesDefinitions.filter(x=>!currentKeys.find(key=>key===keyer(x)))
      if(diffCircles.length!==0){return true}
    }
    return false
  }
  componentDidUpdate(prevProps){
    if(this.shouldResetMapViewport(prevProps)){this.resetMapViewport()}
  }
  setError(errorName, id, error){
    this.setState({
      isSnackbarOpen : error!=""
    })
  }

  forceDraggableCircleResizeToMax(_updatedCircle, _ref, currentRadius) {
    this.setState({
      isSnackbarOpen : true
    })

    // If the circle radius has already been set to the maximum (500km), dragging the radius beyond again will not change the draggable circle's value. Good!
    // Except this means Google's map will not notice a difference and won't redraw. Users can drag the visual circle wherever they want,
    // despite used data values still being what *we* want. This forces the redraw.
    if (currentRadius === 500000) {
      this.props.updateCircle({
          ..._updatedCircle,
          edited : true,
          circleProps : {
            ..._updatedCircle.circleProps,
            center : {
              lat:_ref.getCenter().lat(),
              lng:_ref.getCenter().lng(),
            },
            radius: 499999,
          },
        }
      )
    }

    this.props.updateCircle({
        ..._updatedCircle,
        edited : true,
        circleProps : {
          ..._updatedCircle.circleProps,
          center : {
            lat:_ref.getCenter().lat(),
            lng:_ref.getCenter().lng(),
          },
          radius: 500000,
        },
      }
    )
  }

  updateCircle(circleId) {
    let updatedCircle
    for(let circle of this.props.circlesDefinitions){
      if(circle.id===circleId){
        updatedCircle=circle
        break
      }
    }
    if(updatedCircle){
      const ref = this.mapElements[circleId+"-C"].current
      if (ref.getRadius() > 500000) {
        this.forceDraggableCircleResizeToMax(updatedCircle, ref, updatedCircle.circleProps.radius)
      }
      else {
        this.props.updateCircle({
            ...updatedCircle,
            edited : true,
            circleProps : {
              ...updatedCircle.circleProps,
              center : {
                lat:ref.getCenter().lat(),
                lng:ref.getCenter().lng(),
              },
              radius: ref.getRadius(),
            },
          }
        )
      }
    }
  }
  @keydown(['alt+u'])
  resetMapViewport(){
    if(this.map){
      let bounds = new google.maps.LatLngBounds()
      for(let entityRef of ObjectUtils.getObjectValues(this.mapElements)){
        const entity = entityRef.current
        if(!entity){continue}
        if(entity.getBounds){
          bounds.union(entity.getBounds())
        }
        else if(entity.getPosition){
          //If a place is excluded, don't fit it in view port (If wanted)
          if(this.props.ignoreExcludedPlaces && entity.props.title.includes("(Excluded)")){continue}
          bounds.extend(entity.getPosition())
        }
        else{
          bounds.union(MapUtils.getBoundsOfPolygon(entity))
        }
      }
      this.map.fitBounds(bounds)
    }
  }
  createElementRef(ref, id){
    this.mapElements[id] = React.createRef()
    this.mapElements[id].current = ref
  }
  generateCircles(){
    return this.props.circlesDefinitions.map(circleDefinition=>{
      const circleProps = {
        ...circleDefinition.circleProps,
        options : {
          ...circleDefinition.circleProps.options,
          fillOpacity : MathUtils.getOpacityForBidModifier(circleDefinition.bidModifier)
        }
      }
      const id = circleDefinition.id+"-C"
      this.mapElements[id] = React.createRef()

      return (
        <Circle
          key={id}
          ref={this.mapElements[id]}
          onCenterChanged={()=>this.updateCircle(circleDefinition.id)}
          onRadiusChanged={()=>this.updateCircle(circleDefinition.id)}
          {...circleProps}
        />
    )})
  }

  generatePlaces(){
    return this.props.placesDefinitions.map(placeDefinition=>{
      const includeStatus = placeDefinition.include?"Included":"Excluded"
      if(this.props.shapes[placeDefinition.code]){
        //Draw a polygon if possible
        const polygon = this.props.shapes[placeDefinition.code]
        if(this.props.displayPinAndShape){
          return (
            <React.Fragment key={placeDefinition.id}>
              <UserLevelRestrictedPolygon
                userLevel={3}
                key={placeDefinition.id}
                shapes={this.props.shapes}
                placeDefinition={placeDefinition}
                refHandler={this.createElementRef}
                hideIfNoAvailableShape
              />
              <Marker
                key={placeDefinition.id+"-M"}
                //ref={(ref)=>this.state.refs.places[placeDefinition.id] = ref}
                title={placeDefinition.address+" ("+includeStatus+")"}
                animation={google.maps.Animation.DROP}
                {...placeDefinition.placeProps}
              />
            </React.Fragment>
          )
        }
        return (
          <UserLevelRestrictedPolygon
            userLevel={3}
            key={placeDefinition.id}
            shapes={this.props.shapes}
            placeDefinition={placeDefinition}
            refHandler={this.createElementRef}
          />
        )
      }
      const id = placeDefinition.id+"-M"
      this.mapElements[id] = React.createRef()
      return (
        <Marker
          key={id}
          ref={this.mapElements[id]}
          title={placeDefinition.address+" ("+includeStatus+")"}
          animation={google.maps.Animation.DROP}
          {...placeDefinition.placeProps}
        />
    )})
  }
  generateHeatMap(){
    if(this.props.heatmapData.length===0){return <></>}
    return(
      <HeatmapLayer
        data = {this.props.heatmapData}
        options = {{radius : 20}}
      />
    )
  }
  generateCustomControls(){
    if(this.props.customControls.length === 0){return <></>}
    return (
      this.props.customControls.map(x=>
        <CustomControl
          key = {x.name}
          mapRef={this.map}
          position={x.position}
        >
          {x.renderControl()}
        </CustomControl>
      )
    )
  }
  generateStandaloneShapes(){
    if(this.props.standaloneShapes.length === 0){return <></>}
    return (
      this.props.standaloneShapes.map(x=>{
        const id = x.id+"-SP"
        this.mapElements[id] = React.createRef()
        return (
          <Polygon
            key={id} //SP for StandalonePolygon
            ref={this.mapElements[id]}
            paths={x.polygons}
            options={{
              strokeColor  : x.strokeColor?x.strokeColor:"blue",
              strokeWeight : x.strokeWeight?x.strokeWeight:1,
              fillColor    : x.fillColor?x.fillColor:"blue",
            }}
          />
      )})
    )
  }
  handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({isSnackbarOpen:false})
  }
  render(){
    const googleMapProps = {
      ...this.props.mapProps,
      options : {
        gestureHandling: "greedy", //So holding Ctrl isn't required to zoom with scrollwheel
        ...this.props.mapProps.options,
      },
      ref : map=>{map && (this.map = map)},
      onClick : e=>{this.props.onMapClick(e)}
    }
    return(
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.isSnackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <SnackbarContent
            style={{backgroundColor: theme.palette.error.main, display: 'flex', justifyContent: 'center'}}
            message={this.props.translate('geoTarget.draggableRadiusError')}
          />
        </Snackbar>
        <Map
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: this.props.mapSize+"px",}} />}
          mapElement={<div style={{ height: '100%' }} />}
          googleMapProps={googleMapProps}
        >
          {this.generateCircles()}
          {this.generatePlaces()}
          {this.generateHeatMap()}
          {this.generateStandaloneShapes()}
          {this.generateCustomControls()}
        </Map>
      </>
    )
  }
}

export default withLocalize(MapBuilder)
