import {combineReducers}    from "redux"
import Accounts             from "./Accounts"
import SpendAttempts        from "./SpendAttempts"
import Decisions            from "./Decisions"
import AccountStates        from "./AccountStates"
import PossibleAgents       from "./PossibleAgents"
import Users                from "./Users"

export default combineReducers({
  Accounts,
  SpendAttempts,
  Decisions,
  AccountStates,
  Users,
  PossibleAgents
})
