import './KeywordsManager.css'
import * as React                  from "react"
import {connect}                   from "react-redux"
import {withLocalize, Translate}   from "react-localize-redux"
import ArrayUtils                  from "../../../utils/ArrayUtils"
import VSGL                        from "../../../model/Store/Matcher/VSGL"
import {KeywordPatternInfos}       from "../model/KeywordPatternInfos"
import {fetchKeywords}             from "../action/Keywords"
import {saveKeywords}              from "../action/EditKeywords"
import KeywordsAccessors           from "../../../storeAccessor/Keywords"
import {Tabs, Tab}                 from "carbon-components-react"
import {Panel}                     from "../../../components/Panel/Panel"
import GoBack                      from "../../../components/Button/ButtonGoBack"
import ExplainedLoading            from "../../../components/loading"
import Notice                      from "../../../components/alert/Notice"
import KeywordsEditor              from "./KeywordsEditor"
import TranslatedGoBackLarge       from '../../../components/notifications/TranslatedGoBackLarge'

interface KeywordsManagerOwnProps{
  category    : string
  goBack      : ()=>void
  readOnly   ?: boolean
}
interface KeywordsManagerProps extends KeywordsManagerOwnProps{
  isAccountLoaded   : boolean
  keywordPatterns   : KeywordPatternInfos[]
  loadKeywords      : ()=>void
  saveKeywords      : (matcher:VSGL, positiveKws:string, negativeKws:string)=>void
  translate        ?: (translateId:string)=>string
}

const makeMapStateToProps = ()=>{
  const patternsSelector = KeywordsAccessors.makePatternsOfStateSelector()
  return (state, ownProps:KeywordsManagerOwnProps) => {
    const accountId = state.Accounts.selected
    return {
      token             : state.Login.userLogged.token,
      accountId         : accountId,
      isAccountLoaded   : ArrayUtils.contain(state.Params.Keywords.FetchedAccounts, accountId),
      keywordPatterns   : patternsSelector(state, ownProps.category)
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadKeywords : (accountId:number,token:string)=>dispatch(fetchKeywords(token, accountId)),
    saveKeywords : (token:string, accountId:number, matcher:VSGL, positiveKws:string, negativeKws:string) => {
      dispatch(saveKeywords(token,accountId,matcher,positiveKws,negativeKws))
    },
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    loadKeywords : ()=>DP.loadKeywords(SP.accountId, SP.token),
    saveKeywords : (matcher:VSGL, posKws:string, negKws:string)=>DP.saveKeywords(SP.token, SP.accountId, matcher, posKws, negKws)
  }
}
interface KeywordsManagerState{
  selectedTab : number
}
class KeywordsManager extends React.Component<KeywordsManagerProps,KeywordsManagerState>{
  static defaultProps = {
    readOnly : false
  }
  constructor(props){
    super(props)
    this.state = {
      selectedTab : 0
    }
  }
  render(){
    if(!this.props.isAccountLoaded){
      this.props.loadKeywords()
      return <ExplainedLoading translateId="loadings.keywords"/>
    }
    if(this.props.keywordPatterns.length === 0){
      return (
        <TranslatedGoBackLarge type="button" translationId="keywords.noKeywords" onClick={this.props.goBack}/>
      )
    }
    const LANGUAGES = {
      EN : this.props.translate("common.english"),
      FR : this.props.translate("common.french")
    }
    return(
      <Panel title={this.props.translate("keywords.title")} subtitle={this.props.category+" "+this.props.keywordPatterns[0].matcher.vehicleType}>
        <GoBack onClick={this.props.goBack}/>
        <Notice text="You are not allowed to edit keyword patterns" isActive={this.props.readOnly}/>
        <Tabs selected={this.state.selectedTab}>
          {Object.keys(LANGUAGES).sort().map((langCode,i)=>{
            const patterns = this.props.keywordPatterns.filter(p=>p.matcher.lang===langCode)
            return(
              <Tab key={i} label={LANGUAGES[langCode]} onClick={()=>this.setState({selectedTab:i})}>
                {patterns.map(keywordsPattern=>
                  <KeywordsEditor
                    key={keywordsPattern.accountId+keywordsPattern.matcher.group+langCode}
                    keywords={keywordsPattern.patterns}
                    group={keywordsPattern.matcher.group}
                    saveKeywords={(pos:string,neg:string)=>this.props.saveKeywords(keywordsPattern.matcher, pos, neg)}
                    readOnly={this.props.readOnly}
                  />
                )}
              </Tab>
          )})}
        </Tabs>
      </Panel>
    )
  }
}

export default (
  withLocalize(
    connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
      KeywordsManager
    )
  )
)
