 import {dictionary,Dictionary} from "./Dictionary"

class Localisation{
  private numberOfLang = 2;
  public lang = 0;
  public dictionary:{};
  constructor(dictionaries:Dictionary){this.dictionary = dictionaries}
  public translate(context:string, field:string):string{
    try {return this.dictionary[context][field][this.lang]}
    catch(err){
      return "<span class='Localisation-error'>localisation."+context+"."+field+"</span>"
    }
  }
  public numberToLocale(x):string{
    let separator
    switch(this.lang){
      case 0: //EN
        separator = ","
      break
      case 1: //FR
        separator = " "
      break
      default:
        separator = ","
    }
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return parts.join(".");
  }
  public setCurrentLang(lang:number){
    if(lang<this.numberOfLang && lang>=0){this.lang = lang}
  }
  public addDictionary(dictionary:Dictionary){this.dictionary = {...this.dictionary,...dictionary}}
  public setDictionaries(dictionary:Dictionary){this.dictionary = dictionary}
  public getLangs(){return ["EN","FR"]}
}
const localisation = new Localisation(dictionary)
export const translate = (context:string, field:string) => localisation.translate(context,field)
export const getLangs = () => {return localisation.getLangs()}
