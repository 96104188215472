import './Footer.css'
import * as React          from 'react'
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Icon,
  Modal,
}                          from '@material-ui/core'
import CreateProxyGroup    from './CreateProxyGroup'
import CreateProxy         from './CreateProxy'
import CreateAgent         from './CreateAgent/'
import {
  PossibleAgent,
  filterPossibleAgentsToUnused
}                          from '../../../../../model/pacy'
import {ProxyGroup}        from '../../../../../model/pacy/ProxyGroup'
import {AccountModifier}   from '../../../../../model/pacy/AccountModifiers'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                          from 'react-localize-redux'
import DialogMui           from '../../../../../components/Modals/DialogMui'

interface FooterOwnProps {
  proxyGroups         : ProxyGroup[]
  addAccountModifiers : (newActions:AccountModifier[]) => void
  possibleAgents      : PossibleAgent[]
  accountId           : number
  reset               : () => void
  savePacyConfigs     : () => void
}
interface FooterProps extends FooterOwnProps {
  translate : TranslateFunction
}

const Footer = (props:FooterProps) => {
  const [openResetDialog,setOpenResetDialog] = React.useState<boolean>(false)
  const [openCreateProxyGroupModal,setOpenCreateProxyGroupModal] = React.useState<boolean>(false)
  const [openCreateProxyModal,setOpenCreateProxyModal] = React.useState<boolean>(false)
  const [openCreateAgentModal,setOpenCreateAgentModal] = React.useState<boolean>(false)
  const [openSaveDialog,setOpenSaveDialog] = React.useState<boolean>(false)
  const [openMenu,setOpenMenu] = React.useState<boolean>(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  //Handlers
  const handleOpenSaveDialog = () => setOpenSaveDialog(true)
  const handleCloseSaveDialog = () => setOpenSaveDialog(false)
  const handleOpenResetDialog = () => setOpenResetDialog(true)
  const handleCloseResetDialog = () => setOpenResetDialog(false)
  const handleOpenMenu = () => setOpenMenu(true)
  const handeCloseMenu = () => setOpenMenu(false)
  const handleOpenCreateProxyGroup = () => setOpenCreateProxyGroupModal(true)
  const handleCloseCreateProxyGroup = () => setOpenCreateProxyGroupModal(false)
  const handleOpenCreateProxy = () => setOpenCreateProxyModal(true)
  const handleCloseCreateProxy = () => setOpenCreateProxyModal(false)
  const handleOpenCreateAgent = () => setOpenCreateAgentModal(true)
  const handleCloseCreateAgent = () => setOpenCreateAgentModal(false)
  return (
    <div className="Footer">
      <Button size="large" onClick={handleOpenMenu} ref={anchorRef} className="MenuButton" color="primary" variant="outlined"><Icon className="Icon">menu</Icon>Menu</Button>
      <Button onClick={handleOpenResetDialog} className="CancelButton" color="secondary" variant="outlined" size="large"><Translate id="common.reset"/></Button>
      <Button onClick={handleOpenSaveDialog} className="SaveButton" style={{color:'white'}} color="primary" variant="contained" size="large"><Translate id="common.save" /></Button>
      <div>
        <Popper open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handeCloseMenu}>
                  <MenuList id="menu-list-grow" >
                    <MenuItem onClick={()=>{handeCloseMenu();handleOpenCreateProxyGroup()}}>
                      <Icon className="Icon">add</Icon>
                      <Translate id="pacyConfigs.createProxyGroup" />
                    </MenuItem>
                    <MenuItem onClick={()=>{handeCloseMenu();handleOpenCreateProxy()}}>
                      <Icon className="Icon">add</Icon>
                      <Translate id="pacyConfigs.createProxy" />
                    </MenuItem>
                    <MenuItem onClick={()=>{handeCloseMenu();handleOpenCreateAgent()}}>
                      <Icon className="Icon">add</Icon>
                      <Translate id="pacyConfigs.createAgent" />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <Modal className="CreateProxyGroupModal" open={openCreateProxyGroupModal} onClose={handleCloseCreateProxyGroup}>
        <div>
          <CreateProxyGroup
            accountId={props.accountId}
            closeModal={handleCloseCreateProxyGroup}
            addAccountModifiers={props.addAccountModifiers}
          />
        </div>
      </Modal>
      <Modal className="CreateProxyModal" open={openCreateProxyModal} onClose={handleCloseCreateProxy}>
        <div>
          <CreateProxy
            proxyGroups={props.proxyGroups}
            handleCloseModal={handleCloseCreateProxy}
            addAccountModifiers={props.addAccountModifiers}
          />
        </div>
      </Modal>
      <Modal className="CreateAgentModal" open={openCreateAgentModal} onClose={handleCloseCreateAgent}>
        <div>
          <CreateAgent
            proxyGroups={props.proxyGroups}
            possibleAgents={props.possibleAgents}
            handleCloseModal={handleCloseCreateAgent}
            addAccountModifiers={props.addAccountModifiers}
          />
        </div>
      </Modal>
      <DialogMui
        handleSave={()=>{props.reset();handleCloseResetDialog()}}
        handleClose={handleCloseResetDialog}
        cancelText={props.translate('common.cancel') as string}
        confirmText={props.translate('common.reset') as string}
        content={props.translate('branding.resetMessage') as string}
        open={openResetDialog}
        title={props.translate('common.reset') as string}
      />
      <DialogMui
        handleSave={()=>{props.savePacyConfigs();handleCloseSaveDialog()}}
        handleClose={handleCloseSaveDialog}
        cancelText={props.translate('common.cancel') as string}
        confirmText={props.translate('common.save') as string}
        content={props.translate('pacyConfigs.saveDialog') as string}
        open={openSaveDialog}
        title={props.translate('common.save') as string}
      />
    </div>
  )
}

export default withLocalize(Footer as any) as React.ComponentType<FooterOwnProps>
