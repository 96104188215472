enum Permissions{
  Keystone_ChangeGeo              = "Keystone_ChangeGeo",
  Keystone_ChangeBudget           = "Keystone_ChangeBudget",
  Keystone_ChangeAdCopies         = "Keystone_ChangeAdCopies",
  Keystone_ChangeKeywords         = "Keystone_ChangeKeywords",
  Keystone_ChangeEngagementRules  = "Keystone_ChangeEngagementRules",
  Keystone_ChangeCampaignStatus   = "Keystone_ChangeCampaignStatus",
  Keystone_Standard               = "Keystone_Standard",
  Keystone_ChangeStrategy         = "Keystone_ChangeStrategy",
  Keystone_CreateStrategy         = "Keystone_CreateStrategy",
  Keystone_GrammarBooster_Create  = "Keystone_GrammarBooster_Create",
  Keystone_GrammarBooster_Read    = "Keystone_GrammarBooster_Read",
  Keystone_ViewAdBuilder          = "Keystone_ViewAdBuilder",
  Scraping_View_inventory         = "Scraping_View_inventory",
  IDB2_Login                      = "IDB2_Login",
  IDB2_Modify_Shape               = "IDB2_Modify_Shape",
  IDB2_ViewInventoryTurnGraphs    = "IDB2_ViewInventoryTurnGraphs",
  IDB2_CopyGeoTargeting           = "IDB2_CopyGeoTargeting",
  Pacy_View                       = "Pacy_View",
  Pacy_Edit_Config                = "Pacy_Edit_Config",
  Pacy_MigrateAccount             = "Pacy_MigrateAccount",
  Branding_View                   = "Branding_View",
  Branding_Edit                   = "Branding_Edit",
  Pacy_Update_Pacing_Ratio_Config = "Pacy_Update_Pacing_Ratio_Config",
}
export default Permissions
