export default class AdLocationTypes{
  public static ANYWHERE_ON_PAGE = "ANYWHERE_ON_PAGE";
  public static TOP_OF_PAGE = 'TOP_OF_PAGE';
  public static ABSOLUTE_TOP_OF_PAGE = 'ABSOLUTE_TOP_OF_PAGE';
}
export const AdLocationTypesList = [
  AdLocationTypes.ANYWHERE_ON_PAGE,
  AdLocationTypes.TOP_OF_PAGE,
  AdLocationTypes.ABSOLUTE_TOP_OF_PAGE,
]
