import * as React         from "react"
import Alert              from "./Alert"

interface NoticeProps{
  title       ?: string
  isActive    ?: boolean
  text         : string
  translateId ?: string
}

class Notice extends React.Component<NoticeProps,any>{
  static defaultProps = {
    title : "Notice",
    isActive : true
  }
  render(){
    return (
      <Alert
        title={this.props.title}
        text={this.props.text}
        translateId={this.props.translateId}
        titleColor="#FFCC00"
        textColor="#FFDB4C"
        titleFontColor="black"
        textFontColor="black"
        isActive={this.props.isActive}
      />
    )
  }
}

export default Notice
