import Keystone                  from "../../../utils/Keystone"
import Actions                   from "../../../model/constant/actions"
import Friend                    from "../../../model/Store/Campaign/Friend"
import AdCopy                    from "../../../model/Store/AdCopyPatterns/AdCopy"
import retrieveDesiredGeoBid     from "../../../actions/desiredGeoBidException/retrieveDesiredGeoBidExceptions"
import {getToken}                from "../../../storeAccessor/Generic"

const createAdGroup=(adgroup, campaign)=>{
  return {
    name     : adgroup.name,
    adCopies : adgroup.adCopies.map(x=>({...x, finalUrl: x.finalUrl||campaign.finalUrl}))
  }
}
const createPlace = (geo) => {
  return {
    code        : parseInt(geo.AWId),
    level       : geo.level,
    literal     : geo.literal,
    include     : geo.include,
    bidModifier : geo.bidModifier
  }
}
const createRadius = (geo) => {
  return {
    lat         : geo.lat,
    lng         : geo.lng,
    radius      : geo.radius,
    bidModifier : geo.bidModifier
  }
}
const buildGeos = (geos) => {
  let places = []
  let radiuses = []
  for(let geo of geos){
    if(geo.instanceof === "AWPlace"){places.push(createPlace(geo));continue}
    if(geo.instanceof === "AWRadius"){radiuses.push(createRadius(geo));continue}
    throw Error(`Invalid geobid type given. (${geo.instanceof})`)
  }
  return {
    places   : places,
    radiuses : radiuses
  }
}
const loadCampaignSuccess = (response, friend:Friend) => {
  const campaign = {
    ...response.data.campaign,
    id       : parseInt(response.data.campaign.id),
    adGroups : response.data.campaign.adGroups.map((x)=>createAdGroup(x, response.data.campaign)),
    geoBids  : buildGeos(response.data.campaign.geoBids),
  }
  return {
    type     : Actions.UPDATE_CAMPAIGN_CHILDREN_LOADED,
    campaign : campaign,
    friend   : friend,
  }
}

const loadCampaignError = (response, campaignId:number, friend:Friend) => {
  return {
    type        : Actions.FALLBACK_CAMPAIGN_CHILDREN_LOADING_FAILURE,
    campaignsId : campaignId,
    friend      : friend
  }
}
export const loadCampaign = (campaignId:number, friend:Friend) => (dispatch, getState) => {
  dispatch({
    type       : Actions.DECLARE_CAMPAIGN_CHILDREN_LOADING,
    campaignId : campaignId,
    friend     : friend
  })
  Keystone.get({
      token      : getToken(getState()),
      accountid  : friend.getAccountId(),
      campaignid : campaignId,
      action     : "IDB2GetCampaign",
      legacy     : false,
    },
    (data) => {
      dispatch(loadCampaignSuccess(data, friend))
      dispatch(retrieveDesiredGeoBid(friend))
    },
    (data) => dispatch(loadCampaignError(data, campaignId, friend))
  )
}
