import * as React                   from "react"
import {HashRouter,Route,Redirect}  from "react-router-dom"
import {Link,RouteProps,Switch}     from "react-router-dom"
import AdCopy                       from "../page/adcopy"

interface RouterAdCopyMapping{
  path : string
  content : (props)=>any // Component instance
}
let mapping : RouterAdCopyMapping[] = [
  {
    path:"*/Adcop(y|ies)?/:application/:category/:strategy",
    content:(props)=>
      <AdCopy
        pathname={props.history.location.pathname}
        application={props.match.params.application}
        category={props.match.params.category}
        strategy={props.match.params.strategy}
      />
  },
  {
    path:"*/Adcop(y|ies)?/:application/:category/",
    content:(props)=>
      <AdCopy
        pathname={props.history.location.pathname}
        application={props.match.params.application}
        category={props.match.params.category}
      />
  },
  {
    path:"*/Adcop(y|ies)?/:category/",
    content:(props)=>
      <AdCopy
        pathname={props.history.location.pathname}
        category={props.match.params.category}
      />
  },
  {
    path:"*/Adcop(y|ies)?/",
    content:(props)=>
      <AdCopy
        pathname={props.history.location.pathname}
      />
  },
]
export const routing = (props) => {
  return (
    <HashRouter>
      <Switch>
        {mapping.map((route:RouterAdCopyMapping)=>
          <Route exact key={route.path} path={route.path} render={(props)=>route.content(props)}/>
        )}
      </Switch>
    </HashRouter>
  )
}
