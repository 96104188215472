import './NameManager.css'
import * as React      from "react"
import {withLocalize}  from "react-localize-redux"
import {TextInput}     from "carbon-components-react"

interface NameManagerProps{
  originalName : string
  editedName   : string
  setName      : (editedName)=>void
  translate   ?: (translateId:string)=>string
}

class NameManager extends React.Component<NameManagerProps,any>{
  constructor(props){
    super(props)
    this.state = {
      text : props.editedName,
      error : ""
    }
    this.handleInputChange = this.handleInputChange.bind(this)
  }
  handleInputChange(e){
    const value = e.target.value
    this.setState({
      text  : value,
      error : ""
    })
    if(value.trim().length < 3){
      this.setState({error:this.props.translate("reports.ads.editor.nameError")})
      return
    }
    this.props.setName(value)
  }
  render(){
    let className = "TemplateNameInput"
    if(this.props.editedName!=this.props.originalName){className += " Edited"}
    if(this.state.text.length < 3){className += " TooShort"}
    return(
      <div className="NameManager">
        <TextInput
          className={className}
          labelText=""
          required
          id="TemplateNameInput"
          placeholder={"Template name"}
          value={this.state.text}
          onChange={this.handleInputChange}
        />
        {this.state.error != ""?
          <span>{this.state.error}</span>
        :
          <></>
        }
      </div>
    )
  }
}

export default withLocalize(NameManager)
