import * as React                    from "react"
import {withLocalize}                from "react-localize-redux"
import {Modal}                       from "carbon-components-react"
import Permissions                   from "../../../../../model/constant/Permissions"
import StatusModalContent            from "./ModalContent"
import AccountPermissionBoundary     from "../../../../../components/permissions/AccountPermissionBoundary"

interface StatusModalProps{
  isOpen                    : boolean
  hasException              : boolean
  statusExceptionType       : string
  close                     : ()=>void
  addDesiredStatusException : (status:string)=>void
  deleteDesiredStatus       : ()=>void
  readOnly                 ?: boolean
  translate                ?: (any)=>any
}
const StatusModal = (props:StatusModalProps) => {
  return (
    <Modal
      onRequestClose={props.close}
      modalHeading={props.translate("campaigns.exceptionModal.header")}
      open={props.isOpen}
      passiveModal
    >
      {props.isOpen ?
        <AccountPermissionBoundary
          permissions={[Permissions.Keystone_ChangeCampaignStatus]}
          onFailure={
            <StatusModalContent
              hasException={props.hasException}
              statusExceptionType={props.statusExceptionType}
              close={props.close}
              addDesiredStatusException={props.addDesiredStatusException}
              deleteDesiredStatus={props.deleteDesiredStatus}
              readOnly
            />
          }
        >
          <StatusModalContent
            hasException={props.hasException}
            statusExceptionType={props.statusExceptionType}
            close={props.close}
            addDesiredStatusException={props.addDesiredStatusException}
            deleteDesiredStatus={props.deleteDesiredStatus}
          />
        </AccountPermissionBoundary>
      :
        <></>
      }
    </Modal>
  )
}
export default withLocalize(StatusModal as any) as any
