import Actions      from "../../../model/constant/actions"
import GeoTargeting       from "../../../model/Store/GeoBid/GeoTargeting"
import ReducerUtils from "../../../utils/ReducerUtils"

let addGeoTargetings = (state,action) => {
  let newGeoTargetings = action.geoTargetings
  if(newGeoTargetings === undefined || newGeoTargetings.length === 0){return state}
  return {...state, [action.accountId]:newGeoTargetings}
}
interface GeoTargetingsByAccountId{
  [accountId:number] : GeoTargeting[]
}
export const GeoTargetings = ReducerUtils.createReducer<GeoTargetingsByAccountId>({},{
  [Actions.RETRIEVE_GEOBID]  : addGeoTargetings,
})
