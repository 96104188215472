import "./index.css"
import * as React                    from "react"
import ArrayUtils                    from "../../../utils/ArrayUtils"
import {PacyAccount}                 from "../../../model/pacy/"
import Period                        from "../../../model/Period"
import ProxyGroupReport              from "./ProxyGroupReport"
import Tabs                          from "@material-ui/core/Tabs"
import Tab                           from "@material-ui/core/Tab"
import {
  LocalizeContextProps,
  withLocalize }                     from "react-localize-redux"

interface ProxyGroupsProps extends LocalizeContextProps {
  account                  : PacyAccount
  requestReportingDataLoad : (period:Period)=>boolean
}

const ProxyGroups = (props:ProxyGroupsProps) => {
  const [tab, setTab] = React.useState(0)
  props.account.proxyGroups.sort((a,b)=>ArrayUtils.comp(a.name, b.name))
  const proxyGroup = props.account.proxyGroups[tab]
  return (
    <div className="ProxyGroups">
      <Tabs
        value={tab}
        onChange={(e, t)=>setTab(t)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
      >
        {props.account.proxyGroups.map(proxyGroup=>
          <Tab key={proxyGroup.id} label={proxyGroup.name}/>
        )}
      </Tabs>

      <ProxyGroupReport
        proxyGroup={proxyGroup}
        requestReportingDataLoad={props.requestReportingDataLoad}
      />
    </div>
  )
}

export default withLocalize(ProxyGroups)
