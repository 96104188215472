import Actions                             from "../../../model/constant/actions"
import Authentification, { LoginReturn }   from "../../../utils/Authentification"
import CookieUtils                         from "../../../utils/CookieUtils"
import {retrieveUserPreferences}           from "../../../actions/user/userPreferences"
import {LogInLoggly}                       from "../../../actions/logger/Logger"
import Auth2, { UserReturn }               from "../../../utils/Auth2"
import { AxiosResponse }                   from "axios"


export const clearRememberMeCookies = () => {
  CookieUtils.deleteCookie("email")
  CookieUtils.deleteCookie("UID")
}

export const rememberUser = (email: string, rememberMeId: string) => (dispatch) => {
  const payload = {
    action       : "RememberMe",
    email        : email,
    rememberMeId : rememberMeId
  }
  dispatch({
    type   : Actions.LOGGING_IN,
    status : true
  })
  Authentification.post(payload)
  .then((auth1Response: LoginReturn) => {
    if(auth1Response.error && auth1Response.error.length > 0){
      throw Error("Auth error: " + (auth1Response.error || "Unknown problem with the server has occured"))
    }
    const userId          = auth1Response.data.user.id
    const token           = auth1Response.data.token
    const tokenValidUntil = auth1Response.data.tokenValidUntil
    const permissions     = auth1Response.data.permissions
    const userLevel       = auth1Response.data.user.userLevel

    Auth2.getUser(token, userId)
    .then((auth2Response: AxiosResponse<UserReturn>) => {
      LogInLoggly("Successful RememberMe", {
        token,
        tokenValidUntil,
        user : auth2Response.data
      })
      dispatch({
        type : Actions.AUTHENTIFY_LOGIN_SUCCESS,
        user : {
          profile     : {...auth2Response.data, userLevel},
          token       : token,
          validUntil  : tokenValidUntil,
          permissions : permissions,
        }
      })
      dispatch(retrieveUserPreferences())
      dispatch({type: Actions.LOGGING_IN, status: false})
    })
    .catch((error) => {
      clearRememberMeCookies()
      throw Error("Auth2 error: " + (error.data || "Unknown problem with the server has occured"))
    })
  })
  .catch((error) => {
    dispatch({
      type  : Actions.AUTHENTIFY_LOGIN_ERROR,
      error : error.message
    })
    dispatch({type: Actions.LOGGING_IN, status: false})
    clearRememberMeCookies()
  })
}
