import * as React         from 'react'
import {connect}          from 'react-redux'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  Button,
  Icon
}                         from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                         from "react-localize-redux"
import CustomSelect       from "../../components/input/CustomSelect"
import RequiresFeeds      from "../../components/loaders/feedsLoader"
import RequiresSignedUrls from "../../components/loaders/feedsSignedUrlsLoader"

import {
  requestFeeds,
}                         from "../../actions/feeds/Feeds"
import {
  AW_V1,
  FB_V1,
  FeedConfig
}                         from "../../model/feed/FeedConfig"
import "./index.css"

interface DeleteFeedSignedUrlModalProps extends LocalizeContextProps {
  open    : boolean
  onClose : ()=>void
  loading : boolean
  confirm : ()=>void
}

const DeleteFeedSignedUrlModal = (props:DeleteFeedSignedUrlModalProps) => {
  return (
    <Dialog
      className="DeleteFeedSignedUrlModal"
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>{props.translate("feeds.delete-url.cta")}</DialogTitle>
      <DialogContent>{props.translate("feeds.delete-url.warning")}</DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={props.loading}
          color="primary"
          variant="outlined"
          onClick={props.onClose}
        >
          {props.translate("feeds.cancel")}
        </Button>
        <Button
          disabled={props.loading}
          color="secondary"
          variant="contained"
          onClick={()=>{props.confirm()}}
        >
          <div className="AlignCenter">
            <Icon>delete_forever</Icon>&nbsp;{props.translate("feeds.delete")}
          </div>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withLocalize(DeleteFeedSignedUrlModal)
