import Actions                     from "../../../../model/constant/actions"
import ReducerUtils                from "../../../../utils/ReducerUtils"
import {HeadstoneActiveStrategies} from "../../../../model/Store/Strategy/Strategy"

interface ActiveStrategiesByAccountID {
  [accountId:number] : HeadstoneActiveStrategies
}

const ActiveStrategies = ReducerUtils.createReducer<ActiveStrategiesByAccountID>({},{
  [Actions.RETRIEVE_ADCOPY_ACTIVE_STRATEGIES] : (state,action) => {
    const {strategies, accountId} = action
    const sortedStrategies = Object.keys(strategies)
      .sort()
      .reduce((acc, key) => {
        acc[key] = strategies[key]

        return acc
      }, {})
    return {
      ...state,
      [accountId] : sortedStrategies 
    }
  }
})

export default ActiveStrategies
