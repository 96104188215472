import Actions      from "../../model/constant/actions"
import ReducerUtils from "../../utils/ReducerUtils"

const setException = (state,action) =>{
  if(action.failed){
    return {
      ...state,
      isFetching : false
    }
  }
  const accountId = action.friend.getAccountId()
  if(action.places.length === 0 && action.radiuses.length === 0){
    return {
      ...state,
      isFetching : false,
      [accountId] : {
        ...state[accountId]
      }
    }
  }
  return {
    ...state,
    isFetching : false,
    [accountId] : {
      ...state[accountId],
      [action.friend.getName()] : {
        places : action.places,
        radiuses : action.radiuses
      }
    }
  }
}
const removeException = (state,action) => {
  const accountId = action.friend.getAccountId()
  return {
    ...state,
    [accountId] : {
      ...state[accountId],
      [action.friend.getName()] : undefined
    }
  }
}
const setRetrieving = (state,action) => {
  return {
    ...state,
    isFetching : true
  }
}
interface GeoExceptionsByFriendNameAndAccount{
  isFetching : boolean
  [accountId:number] : {
    [friendName:string] : {
      places : any[]
      radiuses : any[]
    }
  }
}
const DesiredGeoExceptions = ReducerUtils.createReducer<GeoExceptionsByFriendNameAndAccount>({isFetching:false},{
  [Actions.RETRIEVE_DESIRED_GEOBID_EXCEPTIONS]   : setException,
  [Actions.RETRIEVING_DESIRED_GEOBID_EXCEPTIONS] : setRetrieving,
  [Actions.REMOVE_DESIRED_GEOBID_EXCEPTION]      : removeException,
})

export default DesiredGeoExceptions
