import * as React                  from "react"
import * as ReactDOM               from "react-dom"
import PermissionsContextProvider  from "./components/context/permissionContext"
import Inventory                   from "./components/worker/Inventory"
import InitialLoadings             from "./components/worker/InitialLoadings"
import PeriodSentry                from "./components/worker/PeriodSentry"
import {store}                     from "./reducers/generic/buildStore"
import {Provider}                  from "react-redux"
import {PlaceGeocoder}             from "./components/worker/PlaceGeocoder"
import {HashRouter}                from "react-router-dom"
import SessionExpiredModal         from "./components/alert/SessionExpiredModal"
import {LocalizeProvider}          from "react-localize-redux"
import Application                 from "./Application"
import { makeServer }              from "./mirage/server"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils                from '@date-io/date-fns'

if (process.env.NODE_ENV === "development" || process.env.REACT_APP_FORCE_MIRAGE === "1") {
  makeServer({ environment: "development" })
}

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider>
      <PermissionsContextProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Inventory/>
          <InitialLoadings/>
          <PlaceGeocoder/>
          <SessionExpiredModal/>
          <PeriodSentry/>
          <Application/>
        </MuiPickersUtilsProvider>
      </PermissionsContextProvider>
    </LocalizeProvider>
  </Provider>,
  document.getElementById("main")
)

/*
import * as serviceWorker from './serviceWorker';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
*/
