import './EditStrategyModal.css'
import * as React                      from "react"
import {withLocalize, Translate}       from "react-localize-redux"
import ArrayUtils                      from "../../../utils/ArrayUtils"
import {GeoTargetingTypesList}         from "../../../model/constant/GeoTargetingTypes"
import BiddingStrategy                 from "../../../model/Store/BiddingStrategy"
import Strategy                        from "../../../model/Store/Strategy/Strategy"
import {Modal, TextInput}              from "carbon-components-react"
import FilterableSelect                from "../../../components/Filter/FilterableSelect"
import Notice                          from "../../../components/alert/Notice"
import {getGeoTargetingString}         from '..'
import BidSettingsTypes, {BidSettingsTypesList} from "../../../model/constant/BidSettingTypes";
import AdLocationTypes, {AdLocationTypesList} from "../../../model/constant/AdLocationTypes";
import BiddingStrategyTypes, {
    BiddingStrategyTypesList
} from "../../../model/constant/BiddingStrategyTypes";
import {TextField} from "@material-ui/core"
import {isEmpty} from "lodash";

interface EditStrategyModalProps{
  open              : boolean
  onClose           : ()=>void
  strategy          : Strategy
  onConfirm         : (newStrategy:any)=>void
  biddingStrategies : BiddingStrategy[]
  translate        ?: (translateId:string)=>string
  readOnly         ?: boolean
}

interface EditStrategyModalState{
  strategy : Strategy
}
const EDITABLE_KEYS_TO_TRANSLATION_ID = {
  active              : "common.active",
  biddingStrategyId   : "strategies.biddingStrategy",
  geoTargetingType    : "strategies.geoTargetingType",
  finalUrl            : "strategies.finalUrl",
  bidSetting          : "strategies.bidSetting",
  biddingStrategyType : "strategies.biddingStrategyType",
}

class EditStrategyModal extends React.Component<EditStrategyModalProps,EditStrategyModalState>{
  static defaultProps = {
    readOnly : false
  }
  constructor(props){
    super(props)
    this.state = {
      strategy : {...props.strategy}
    }
    this.updateStrategyValue = this.updateStrategyValue.bind(this)
    this.updateSettingsValue = this.updateSettingsValue.bind(this)
  }
  updateStrategyValue(key, value){
    this.setState(prevState=>({
      strategy : {
        ...prevState.strategy,
        [key] : value
      }
    }))
  }
  updateSettingsValue(key, value){
      this.setState(prevState=>({
          strategy : {
              ...prevState.strategy,
              properties : {
                ...prevState.strategy.properties,
                [key] : value
              }
          }
      }))
  }
  saveNumberProperties(key, value) {
    value = value.replace(/[^0-9.,]+/g, '')
    this.updateSettingsValue(key, value)
  }
  changeBiddingStrategyType(key, value) {
    this.updateStrategyValue(key, value)
    this.setState(prevState=>({
      strategy : {
        ...prevState.strategy,
        properties : {}
      }
    }))
  }
  changeBidSetting(key, value) {
    this.updateStrategyValue(key, value)
    if (this.state.strategy.bidSetting === BidSettingsTypes.PORTFOLIO) {
      this.changeBiddingStrategyType('biddingStrategyType', BiddingStrategyTypes.TARGET_SPEND)
    }
  }

  renderInputBasedOnKey(key){
    let input = null
    switch(key){
      case "active":
        input = (
          <div key={key} className="EditStrategyModalInput StrategyActive">
            <span className="InputLabel"><Translate id={EDITABLE_KEYS_TO_TRANSLATION_ID[key]}/>:</span>
            <br/>
            <select
              defaultValue={this.state.strategy.active?"active":"paused"}
              onChange={(e)=>this.updateStrategyValue(key, e.target.value==="active")}
            >
              <option value="active">
                 {this.props.translate("common.active")}
              </option>
              <option value="paused">
                 {this.props.translate("common.paused")}
              </option>
            </select>
          </div>
        )
        break

      case "biddingStrategyId":
        if (this.state.strategy.bidSetting === BidSettingsTypes.PORTFOLIO) {
          input = (
            <div key={key} className="EditStrategyModalInput StrategyBiddingStrategy">
              <span className="InputLabel"><Translate id={EDITABLE_KEYS_TO_TRANSLATION_ID[key]}/>:</span>
              <FilterableSelect
                data={[...this.props.biddingStrategies, {id: "DEFAULT", name: "DEFAULT"}]}
                filterFunc={(biddingStrategy: BiddingStrategy, filter) => biddingStrategy.name.toLowerCase().includes(filter.toLowerCase())}
                keyFinder={(biddingStrategy: BiddingStrategy) => biddingStrategy.id}
                nameFinder={(biddingStrategy: BiddingStrategy) => biddingStrategy.name}
                onSelect={(biddingStrategy: BiddingStrategy) => this.updateStrategyValue(key, biddingStrategy.id + "")}
                sortFunc={(x, y) => {
                  if (y.id === "DEFAULT") {
                    return 1
                  }
                  return ArrayUtils.comp(x.name, y.name)
                }}
                nameOfDataSet={this.props.translate("strategies.biddingStrategies")}
                inputPlaceholder={this.props.translate("strategies.editModal.biddingStrategiesInputPlaceholder")}
                defaultSelectedKey={this.props.strategy.biddingStrategyId}
              />
            </div>
          )
        }
        break

      case "geoTargetingType":
        input = (
          <div key={key} className="EditStrategyModalInput StrategyGeoTargeting">
            <span className="InputLabel"><Translate id={EDITABLE_KEYS_TO_TRANSLATION_ID[key]}/>: </span>
            <select
              defaultValue={this.props.strategy.geoTargetingType}
              onChange={e=>this.updateStrategyValue(key, e.target.value)}
            >
              {GeoTargetingTypesList.map(x => <option key={x} value={x}>{getGeoTargetingString(x, this.props.translate)}</option> )}
            </select>
          </div>
        )
        break

      case "bidSetting":
        input = (
          <div key={key} className="EditStrategyModalInput BidSetting">
            <span className="InputLabel"><Translate id={EDITABLE_KEYS_TO_TRANSLATION_ID[key]}/>: </span>
            <select
              defaultValue={this.props.strategy.bidSetting}
              onChange={e => this.changeBidSetting(key, e.target.value)}
            >
              {BidSettingsTypesList.map(x => <option key={x} value={x}>{this.props.translate("strategies." + x)}</option>)}
            </select>
          </div>
        )
        break

        case "biddingStrategyType": {
          let biddingStrategyTypes = BiddingStrategyTypesList.map(val => ({
            id: val,
            name: this.props.translate("strategies." + val)
          }));
          if (this.state.strategy.bidSetting === BidSettingsTypes.CAMPAIGN_LEVEL) {
            input = (
              <div key={key} className="EditStrategyModalInput StrategyBiddingStrategy">
                <span className="InputLabel"><Translate id={EDITABLE_KEYS_TO_TRANSLATION_ID[key]}/>: </span>
                <FilterableSelect
                  data={[...biddingStrategyTypes]}
                  filterFunc={(biddingStrategyTypes, filter) => biddingStrategyTypes.name.toLowerCase().includes(filter.toLowerCase())}
                  keyFinder={(biddingStrategyTypes) => biddingStrategyTypes.id}
                  nameFinder={(biddingStrategyTypes) => biddingStrategyTypes.name}
                  onSelect={(biddingStrategyTypes) => this.changeBiddingStrategyType(key, biddingStrategyTypes.id)}
                  defaultSelectedKey={this.state.strategy.biddingStrategyType}
                />
              </div>
            )
          }
          break
        }
        case "properties":
          if(this.state.strategy.properties && this.state.strategy.bidSetting === BidSettingsTypes.CAMPAIGN_LEVEL) {
            switch(this.state.strategy.biddingStrategyType) {
              case BiddingStrategyTypes.MAXIMIZE_CONVERSIONS: {
                let cpaChecked = (!isEmpty(this.state.strategy.properties.targetCpa)
                  && isEmpty(this.state.strategy.properties.targetCpaCheck)) || this.state.strategy.properties.targetCpaCheck
                input = (
                  <div key={key} className="EditStrategyModalInput Properties">
                    <input
                      type="checkbox"
                      id="targetCpaCheck"
                      name="targetCpaCheck"
                      value="targetCpaCheck"
                      checked={cpaChecked ?? false}
                      onChange={(e) => {this.updateSettingsValue('targetCpaCheck', !cpaChecked); this.updateSettingsValue('targetCpa', '')}}
                    />
                    <span className="InputLabel"> {this.props.translate("strategies.properties.targetCpa")}</span><br/>
                    <TextField
                      style={{visibility: cpaChecked ? "visible" : "hidden"}}
                      InputProps={{startAdornment: "$ "}}
                      inputProps={{ maxLength: 10 }}
                      type="text"
                      id="targetCpa"
                      name="targetCpa"
                      className="TargetCpa"
                      value={this.state.strategy.properties.targetCpa ?? ''}
                      onChange={(e) => this.saveNumberProperties('targetCpa', e.target.value)}
                    />
                  </div>
                )
                break
              }
              case BiddingStrategyTypes.MAXIMIZE_CONVERSION_VALUE: {
                let roasChecked = (!isEmpty(this.state.strategy.properties.targetRoas)
                  && isEmpty(this.state.strategy.properties.targetRoasCheck)) || this.state.strategy.properties.targetRoasCheck
                input = (
                  <div key={key} className="EditStrategyModalInput Properties">
                    <input
                      type="checkbox"
                      id="targetRoasCheck"
                      name="targetRoasCheck"
                      value="targetRoasCheck"
                      checked={roasChecked ?? false}
                      onChange={(e) => {
                        this.updateSettingsValue('targetRoasCheck', !roasChecked);
                        this.updateSettingsValue('targetRoas', '')
                      }}
                    />
                    <span className="InputLabel"> {this.props.translate("strategies.properties.targetRoas")}</span><br/>
                    <TextField
                      style={{visibility: roasChecked ? "visible" : "hidden"}}
                      InputProps={{endAdornment: " %"}}
                      inputProps={{maxLength: 3}}
                      type="text"
                      id="targetRoas"
                      name="targetRoas"
                      className="TargetRoas"
                      value={this.state.strategy.properties.targetRoas ?? ''}
                      onChange={(e) => this.saveNumberProperties('targetRoas', e.target.value)}
                    />
                  </div>
                )
                break
              }
              case BiddingStrategyTypes.TARGET_IMPRESSION_SHARE: {
                if (isEmpty(this.state.strategy.properties.location)) {
                  this.updateSettingsValue('location', AdLocationTypes.ANYWHERE_ON_PAGE)
                }
                let tisWarning = (isEmpty(this.state.strategy.properties.percent) != isEmpty(this.state.strategy.properties.maxCpc))
                input = (
                  <div key={key} className="EditStrategyModalInput Properties TargetImpressionShare">
                    <div className="EditStrategyModalInput TargetImpressionShareLeft">
                      <span className="InputLabel">{this.props.translate("strategies.properties.adLocation")}: </span>
                      <br />
                      <select
                        defaultValue={this.state.strategy.properties.location}
                        onChange={e => this.updateSettingsValue('location', e.target.value)}
                      >
                        {AdLocationTypesList.map(x => <option key={x} value={x}>{this.props.translate("strategies.properties." + x)}</option>)}
                      </select>
                    </div>
                    <div className="EditStrategyModalInput TargetImpressionShareRight">
                      <div className="TargetImpressionShareField">
                        <span className="InputLabel">{this.props.translate("strategies.properties.percent")} </span>
                        <TextField
                          InputProps={{endAdornment: " %"}}
                          inputProps={{maxLength: 3}}
                          type="text"
                          id="percent"
                          name="percent"
                          className="Percent"
                          value={this.state.strategy.properties.percent ?? ''}
                          onChange={(e) => this.saveNumberProperties('percent', e.target.value)}
                        />
                      </div>
                      <div className="TargetImpressionShareField">
                      <span className="InputLabel">{this.props.translate("strategies.properties.maxBidLimit")}&nbsp;</span>
                        <TextField
                          InputProps={{startAdornment: "$ "}}
                          inputProps={{maxLength: 10}}
                          type="text"
                          id="maxCpc"
                          name="maxCpc"
                          className="MaxCpc"
                          value={this.state.strategy.properties.maxCpc ?? ''}
                          onChange={(e) => this.saveNumberProperties('maxCpc', e.target.value)}
                        />
                      </div>
                      <p
                        style={{visibility: tisWarning ? "visible" : "hidden"}}>
                        {this.props.translate("strategies.properties.targetImpressionShareRequired")}
                      </p>
                    </div>
                  </div>
                )
                break
              }
              case BiddingStrategyTypes.TARGET_SPEND:
              default: {
                let cpcChecked = (!isEmpty(this.state.strategy.properties.maxCpc)
                  && isEmpty(this.state.strategy.properties.maxCpcCheck)) || this.state.strategy.properties.maxCpcCheck
                input = (
                  <div key={key} className="EditStrategyModalInput Properties">
                    <input
                      type="checkbox"
                      id="maxCpcCheck"
                      name="maxCpcCheck"
                      value="maxCpcCheck"
                      checked={cpcChecked ?? false}
                      onChange={(e) => {
                        this.updateSettingsValue('maxCpcCheck', !cpcChecked);
                        this.updateSettingsValue('maxCpc', '')
                      }}
                    />
                    <span className="InputLabel"> {this.props.translate("strategies.properties.maxCpc")}</span><br/>
                    <TextField
                      style={{visibility: cpcChecked ? "visible" : "hidden"}}
                      InputProps={{startAdornment: "$ "}}
                      inputProps={{maxLength: 10}}
                      type="text"
                      id="maxCpc"
                      name="maxCpc"
                      className="MaxCpc"
                      value={this.state.strategy.properties.maxCpc ?? ''}
                      onChange={(e) => this.saveNumberProperties('maxCpc', e.target.value)}
                    />
                  </div>
                )
                break
              }
            }
          }
        break
      case "finalUrl":
        if(this.props.strategy.finalUrl){ //Making sure to not display the input if the strategy doesn't have one (because non-applicable)
          input = (
            <div key={key} className="EditStrategyModalInput StrategyFinalUrl">
              <span className="InputLabel"><Translate id={EDITABLE_KEYS_TO_TRANSLATION_ID[key]}/>:</span>
              <TextInput
                id={key}
                labelText=""
                value={this.state.strategy[key]}
                onChange={(e)=>this.updateStrategyValue(key, e.target.value)}
              />
            </div>
          )
        }
        break

      default: input = null
    }

    return input
  }

  render(){
    if (!this.props.open) {return null}
    let readOnlyForm = this.props.readOnly
    if (this.state.strategy.biddingStrategyType === BiddingStrategyTypes.TARGET_IMPRESSION_SHARE
      && (isEmpty(this.state.strategy.properties.percent) || isEmpty(this.state.strategy.properties.maxCpc))) {
      readOnlyForm = true
    }

    return (
      <Modal
        open
        modalHeading={this.props.translate("strategies.editModal.title")}
        primaryButtonText={this.props.translate("common.confirm")}
        secondaryButtonText={this.props.translate("common.cancel")}
        onRequestSubmit={()=>this.props.onConfirm(this.state.strategy)}
        onRequestClose={this.props.onClose}
        onSecondarySubmit={this.props.onClose}
        primaryButtonDisabled={readOnlyForm}
      >
        <Notice text="" translateId="strategies.notAllowedNotice" isActive={this.props.readOnly}/>
        <div className="EditStrategyModalContent">
          {Object.keys(this.state.strategy).map(key=>this.renderInputBasedOnKey(key))/*.filter(x=>x)*/}
        </div>
      </Modal>
    )
  }
}

export default withLocalize(EditStrategyModal)
