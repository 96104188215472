const google = (window as any).google
import * as React                             from "react"
import PERMISSIONS                            from "../../../../model/constant/Permissions"
import {Button}                               from "carbon-components-react"
import MapBuilder                             from "../../../../components/Map/MapBuilder"
import GoBack                                 from "../../../../components/Button/ButtonGoBack"
import UserPermissionBoundary                 from "../../../../components/permissions/UserPermissionBoundary"
import PointsResume                           from "./PointsResume"
import AreaResume                             from "./AreaResume"

interface ShapeSimplifierProps{
  shape : any
  exit
  save : (simplifiedPolygons:any[][])=>void
}
interface ShapeSimplifierState{
  showOriginal : boolean
  wasSimplified : boolean
  simplifiedShape ?: any
  minimalDistance : number
  distanceLeniency : number
  computedArea : number
  [input:string] : any
}
const INPUTS_NAMES = {
  minimalDistance : "minimalDistance",
  distanceLeniency : "distanceLeniency"
}
/*
  What is a good simplification?
  - less than 30% of the original points
  - Area difference within ~3%?
*/


const PermissionWrapper = (props) => (
  <UserPermissionBoundary
    userLevel={3}
    permissions={[[PERMISSIONS.IDB2_Modify_Shape]]}
    onFailure={()=><div>You are not allowed</div>}
  >
    <ShapeSimplifier {...props}/>
  </UserPermissionBoundary>
)
class ShapeSimplifier extends React.Component<ShapeSimplifierProps,ShapeSimplifierState>{
  constructor(props){
    super(props)
    this.state = {
      showOriginal : true,
      wasSimplified : false,
      minimalDistance : 1500,
      distanceLeniency : 1.05,
      computedArea : 0
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.simplify = this.simplify.bind(this)
    this.shouldMiddlePointBeDeleted = this.shouldMiddlePointBeDeleted.bind(this)
  }
  handleInputChange(event){
    const name = event.target.name
    const value = event.target.value
    if(INPUTS_NAMES[name]){
      this.setState({
        [INPUTS_NAMES[name]] : value
      })
    }
  }
  simplify(){
    this.setState(prevState=>({
      wasSimplified : true,
      simplifiedShape : {
        ...this.props.shape,
        id : this.props.shape.id+"-simplified",
        strokeColor : "red",
        fillColor : "red",
        polygons : this.props.shape.polygons.map(polygon=>{
          if(polygon.length < 3){return polygon}
          let newPolygon = [...polygon]
          let removedPointsCount = 0
          let iterator = 0
          while(true){
            let triplet = []
            if(iterator+2 >= newPolygon.length){
              //now looping back to the begining
              break
            }
            triplet = [
              newPolygon[iterator],
              newPolygon[iterator+1],
              newPolygon[iterator+2],
            ]
            if(this.shouldMiddlePointBeDeleted(triplet)){
              newPolygon.splice(iterator+1, 1)
              removedPointsCount++
            }
            else{iterator++}
          }
          console.log("Removed "+removedPointsCount+" points")
          return newPolygon
        })
      }
    }))

  }
  shouldMiddlePointBeDeleted(pointsTriplet:any[]):boolean{
    const A = pointsTriplet[0]
    const B = pointsTriplet[1]
    const C = pointsTriplet[2]
    //Calculate distance between two points, in meters
    const AC = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(A.lat, A.lng),
      new google.maps.LatLng(C.lat, C.lng),
    )
    if(AC < this.state.minimalDistance){
      const AB = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(A.lat, A.lng),
        new google.maps.LatLng(B.lat, B.lng),
      )
      const BC = google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(B.lat, B.lng),
        new google.maps.LatLng(C.lat, C.lng),
      )
      if(AB+BC < AC*this.state.distanceLeniency){
        return true
      }
    }
    return false
  }
  computeArea(shape:any){
    return shape.polygons.reduce((total, polygon)=>{
      const points = polygon.map(x=>new google.maps.LatLng(x.lat, x.lng))
      return total+google.maps.geometry.spherical.computeArea(points)
    },0)
  }
  render(){
    const shapes = []
    let originalArea = this.computeArea(this.props.shape)
    let simplifiedArea = "Simplify first"
    if(this.state.showOriginal){shapes.push(this.props.shape)}
    if(this.state.wasSimplified){
      shapes.push(this.state.simplifiedShape)
      simplifiedArea = this.computeArea(this.state.simplifiedShape)
    }
    return (
      <div>
        <GoBack onClick={this.props.exit}/>
        <br/>
        <MapBuilder
          standaloneShapes={shapes}
        />
        <Button onClick={this.simplify}>Simplify</Button>
        <Button
          onClick={()=>this.props.save(this.state.simplifiedShape.polygons)}
          style={{float:"right"}}
          disabled={!this.state.wasSimplified}
        >
          Confirm
        </Button>
        <div>
          <input
            type="checkbox"
            checked={this.state.showOriginal}
            onChange={()=>this.setState(prevState=>({showOriginal:!prevState.showOriginal}))}
          />
          &nbsp;Show original shape
        </div>
        <div>
          minimal distance (M) :
          <input
            value={this.state.minimalDistance}
            name={INPUTS_NAMES["minimalDistance"]}
            onChange={this.handleInputChange}
          />
        </div>
        <div>
          distance leniency :
          <input
            value={this.state.distanceLeniency}
            name={INPUTS_NAMES["distanceLeniency"]}
            onChange={this.handleInputChange}
          />
        </div>
        <PointsResume originalShape={this.props.shape} newShape={this.state.simplifiedShape}/>
        <AreaResume originalShape={this.props.shape} newShape={this.state.simplifiedShape}/>
      </div>
    )
  }
}

export default PermissionWrapper
