import './index.css'
import * as React                from "react"
import {withRouter}              from "react-router"
import {connect}                 from "react-redux"
import {withLocalize}            from "react-localize-redux"
import {Panel}                   from "../../components/Panel/Panel"
import Button                    from "../../components/Button/MinimalistButton"
import AccountPermissionBoundary from "../../components/permissions/AccountPermissionBoundary"
import Permissions               from "../../model/constant/Permissions"
import {AlertType}               from "../../model/constant/AlertType"
import {
  Alert,
  AlertButtonConfig
}                                from "../../model/alerting/Alert"
import { StringMap }             from "../../model/generics"
import PermissionsUtils          from "../../utils/PermissionsUtils"
import {UserPermissions}         from "../../model/Store/Login/UserLogged"
import UserConnected             from "../../model/Store/Login/UserConnected"


interface ParamsOwnProps {}

interface ComponentProps extends ParamsOwnProps {
  translate : (key:string) => string
  history : any
}

export interface ParamButton {
  text : string,
  subtitle : string,
  onClick : ()=>void,
  permissions ?: Permissions[]
  possibleAlerts ?: AlertButtonConfig[],
}

const mapStateToProps = (state) => {
  return {
    alerts : state.Alerts.Retrieved as StringMap<Alert>,
    account : state.Accounts.selected as number,
    permissions : state.Login.userLogged.permissions as UserPermissions[],
    profile : state.Login.userLogged.profile as UserConnected,
  }
}

const mapDispatchToProps = (_dispatch) => {
  return {}
}

const mergeProps = (SP:ReturnType<typeof mapStateToProps>,DP:ReturnType<typeof mapDispatchToProps>,ownProps:ComponentProps)=>{
  return {...SP,...DP,...ownProps}
}

type ParamsProps = ReturnType<typeof mergeProps>

export const displayAlert = (button : ParamButton, alerts : StringMap<Alert>, permissionUtils : PermissionsUtils) : boolean => {
  let display = false
  if (button.possibleAlerts){
    for (let possibleAlert of button.possibleAlerts){
      if(possibleAlert.requiredPermissions){
        if (!permissionUtils.validatePermissions(possibleAlert.requiredPermissions)) {
          continue
        }
      }
      if (alerts[possibleAlert.type]){
        display = alerts[possibleAlert.type].count > 0
      }
    }
  }
  return display
}

const reactOnClick = (title,history)=>{
  let currentUrl = history.location.pathname
  if(currentUrl[currentUrl.length-1] != "/"){currentUrl = currentUrl+"/"}
  history.push(currentUrl+title)
}

const Params = (props : ParamsProps)=>{
  const buttonParams : ParamButton[] = [
    {
      text         : props.translate("params.budgets"),
      subtitle     : props.translate("params.budgetsSubtitle"),
      onClick      : ()=>reactOnClick("Budgets",props.history),
      possibleAlerts : [
        {
          type:AlertType.PACY_V0USAGE,
          requiredPermissions:[Permissions.Pacy_Edit_Config]
        },
      ]
    },
    {
      text         : props.translate("params.branding"),
      subtitle     : props.translate("params.brandingSubtitle"),
      onClick      : ()=> reactOnClick("Branding",props.history),
      permissions  : [Permissions.Branding_View]
    },
    {
      text         : props.translate("params.geoTarget"),
      subtitle     : props.translate("params.geoTargetSubtitle"),
      onClick      : ()=>reactOnClick("Geobid",props.history),
    },
    {
      text         : props.translate("params.adCopies"),
      subtitle     : props.translate("params.adCopiesSubtitle"),
      onClick      : ()=>reactOnClick("Adcopy",props.history),
    },
    {
      text         : props.translate("params.keywords"),
      subtitle     : props.translate("params.keywordsSubtitle"),
      onClick      : ()=>reactOnClick("Keywords",props.history),
    },
    {
      text         : props.translate("params.rules"),
      subtitle     : props.translate("params.rulesSubtitle"),
      onClick      : ()=>reactOnClick("Rules",props.history),
    },
    {
      text         : props.translate("params.strategies"),
      subtitle     : props.translate("params.strategiesSubtitle"),
      onClick      : ()=>reactOnClick("Strategies",props.history),
    },
    {
      text         : props.translate("params.feeds"),
      subtitle     : props.translate("params.feedsSubtitle"),
      onClick      : ()=> reactOnClick("Feeds",props.history),
      permissions  : [Permissions.Keystone_ChangeStrategy]
    },
  ]

  const permissionUtils = new PermissionsUtils(props.account, props.permissions, props.profile)

  return(
    <Panel title={props.translate("params.title")} subtitle={props.translate("params.subtitle")}>
      <div className="MinimalistButtonsList">
        {buttonParams.map(button=> {
          let display = false
          if (props.alerts) {
            display = displayAlert(button, props.alerts, permissionUtils)
          }
          if(button.permissions){
            return (
              <AccountPermissionBoundary key={button.subtitle} permissions={button.permissions} onFailure={null} >
                <Button {...button} displayAlert={display}/>
              </AccountPermissionBoundary>
            )
          }
          return <Button key={button.text} {...button} displayAlert={display}/>
        })}
      </div>
    </Panel>
  )
}

export default (withRouter(
  withLocalize(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Params) as any) as any
)) as React.ComponentType<ParamsOwnProps>
