import './ShapeSelector.css'
import * as React                  from "react"
import {TextInput}                 from "carbon-components-react"

interface ShapeSelectorProps{
  shapes : any[]
  selectShape : (shapeCode:number)=>void
}
interface ShapeSelectorState{
  filter     : string
}
class ShapeSelector extends React.Component<ShapeSelectorProps,ShapeSelectorState>{
  constructor(props){
    super(props)
    this.state = {
      filter     : ""
    }
  }
  render(){
    const filterTokens = this.state.filter.toUpperCase().match(/\S+/g)
    let filteredShapes = this.props.shapes
    if(filterTokens){
      filteredShapes = filteredShapes.filter(shape=>{
        const shapeName = shape.name.toUpperCase()
        for(const token of filterTokens){
          if(!shapeName.includes(token)){return false}
        }
        return true
      })
    }
    return (
      <div className="ShapeSelector">
        <h3>Available shapes</h3>
        <div className="ChoicesSection">
          <TextInput
            id="filterInput"
            labelText="Filter"
            placeholder="Search for a shape name"
            value={this.state.filter}
            onChange={(e)=>this.setState({filter:e.target.value})}
          />
          <div className="ChoicesList">
            {filteredShapes.map(x=>
              <div key={x.id} className="ShapeChoice" onClick={()=>this.props.selectShape(x.code)}>
                {x.name}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default ShapeSelector
