import * as React                           from "react"
import {connect}                            from "react-redux"
import {retrieveMarketingEvents}            from "../../actions/adBuilder/events"
import ExplainedLoading                     from "../loading"
import ErrorBoundary                        from "../Error/ErrorBoundary"

const mapStateToProps = (state,ownProps) => {
  const accountId = state.Accounts.selected
  return {
    accountId,
    retrieving : state.AdBuilder.Retrieving.Events,
    events     : state.AdBuilder.Events[accountId],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadMarketingEvents : (accountId:number)=>dispatch(retrieveMarketingEvents(accountId)),
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    loadMarketingEvents : () => DP.loadMarketingEvents(SP.accountId),
  }
}
const MarketingEventsLoader = props => {
  let loading = false
  if(props.events === undefined || props.retrieving){
    loading = true
    if(!props.retrieving){props.loadMarketingEvents()}
    if(!props.letThrough){
      return <ExplainedLoading translateId="loadings.marketingEvents"/>
    }
  }
  return <ErrorBoundary>{props.render(props.events, loading)}</ErrorBoundary>
}
const MarketingEventsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(MarketingEventsLoader)

const requiresMarketingEvents = (letThrough?:boolean) => Component => props => (
  <MarketingEventsLoaderConnected
    letThrough={letThrough || false}
    render={ (events, loading) => <Component marketingEvents={events} loadingMarketingEvents={loading} {...props} /> }
  />
)

export default requiresMarketingEvents
