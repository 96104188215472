import axios, {AxiosResponse} from "axios"
import {stringify}            from "querystring"

interface action{
  type:string
}
export default class Authentification {
  protected static getApiUrl(){return ""}
  public static getAll(options:any[]){
    return Promise.all(options.map(option => {
      return axios.get(this.getApiUrl(),{params:option.payload})
        .then(
          response => option.onResult(response.data),
          error    => option.onError(error)
        )
    }))
  }
  public static postAll(options:any[]){
    return Promise.all(options.map(option => {
      return axios.post(this.getApiUrl(),{params:option.payload})
        .then(
          response => option.onResult(response.data),
          error    => option.onError(error)
        )
    }))
  }
  public static get<T=any>(options:any, onResult:(data)=>any=(r)=>r, onError:(error)=>any=(e)=>{throw e}):Promise<AxiosResponse<T>>{
    return axios.get(this.getApiUrl(),{params:options})
      .then(
        response => onResult(response.data),
        error    => onError(error)
      )
  }
  public static post(options:any, onResult:(data)=>any=(r)=>r, onError:(error)=>any=(e)=>{throw e}){
    return axios.post(this.getApiUrl(),stringify(options))
      .then(
        response => onResult(response.data),
        error    => onError(error)
      )
  }
}
