import {Selector}                 from './Selector'
import {SelectorStandardName}     from './Selector'
import {SelectorOperator}         from './Selector'
import PossibleSelectors          from './PossibleSelectors'
import {
  Selector as HeadstoneSelector,
}                                 from "./HeadstoneEngagementRule"

const nameMap = {
  PriceAtLeast : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.Price),
    operator  : (s)=>SelectorOperator.AT_LEAST,
    getValue : (s)=>s.price,
  },
  PriceAtMost : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.Price),
    operator  : (s)=>SelectorOperator.AT_MOST,
    getValue : (s)=>s.price,
  },
  NumberOfPhotosAtLeast : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.NumberOfPhotos),
    operator  : (s)=>SelectorOperator.AT_LEAST,
    getValue : (s)=>s.number,
  },
 NumberOfPhotosAtMost : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.NumberOfPhotos),
    operator  : (s)=>SelectorOperator.AT_MOST,
    getValue : (s)=>s.number,
  },
  DaysInInventoryAtLeast : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.NumberOfDays),
    operator  : (s)=>SelectorOperator.AT_LEAST,
    getValue : (s)=>s.days,
  },
  DaysInInventoryAtMost : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.NumberOfDays),
    operator  : (s)=>SelectorOperator.AT_MOST,
    getValue : (s)=>s.days,
  },
  YearIs : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.Year),
    operator  : (s)=>SelectorOperator.IS,
    getValue : (s)=>s.year,
  },
  YearIsNot : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.Year),
    operator  : (s)=>SelectorOperator.IS_NOT,
    getValue : (s)=>s.year,
  },
  YearAtMost : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.Year),
    operator  : (s)=>SelectorOperator.AT_MOST,
    getValue : (s)=>s.year,
  },
  YearAtLeast : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.Year),
    operator  : (s)=>SelectorOperator.AT_LEAST,
    getValue : (s)=>s.year,
  },
  MakeIs : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.Make),
    operator  : (s)=>SelectorOperator.IS,
    getValue : (s)=>s.make,
  },
  MakeIsNot : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.Make),
    operator  : (s)=>SelectorOperator.IS_NOT,
    getValue : (s)=>s.make,
  },
  ModelIs : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.Model),
    operator  : (s)=>s.isRegex?SelectorOperator.IS_REGEX:SelectorOperator.IS,
    getValue : (s)=>s.model
  },
  ModelIsNot : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.Model),
    operator  : (s)=>s.isRegex?SelectorOperator.IS_NOT_REGEX:SelectorOperator.IS_NOT,
    getValue : (s)=>s.model
  },
  StockNumberIs : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.StockNumber),
    operator : (s)=>s.isRegex?SelectorOperator.IS_REGEX:SelectorOperator.IS,
    getValue : (s)=>s.stockNumber
  },
  StockNumberIsNot : {
    abstract : PossibleSelectors.find(x=>x.name===SelectorStandardName.StockNumber),
    operator : (s)=>s.isRegex?SelectorOperator.IS_NOT_REGEX:SelectorOperator.IS_NOT,
    getValue : (s)=>s.stockNumber
  }
}

export default (rawHeadstoneSelector:HeadstoneSelector) : Selector => {
  const addedOn = new Date()
  const recognized = nameMap[rawHeadstoneSelector.type]
  if(recognized===undefined){alert(rawHeadstoneSelector.type)}
  return {
    ...recognized.abstract,
    id       : rawHeadstoneSelector.id,
    operator : recognized.operator(rawHeadstoneSelector),
    value    : recognized.getValue(rawHeadstoneSelector),
    addedOn  : addedOn ? addedOn : new Date(),
    addedBy  : 0,
  }
}
