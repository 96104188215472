import Actions             from "../../../model/constant/actions"
import ReducerUtils        from "../../../utils/ReducerUtils"
import ArrayUtils          from "../../../utils/ArrayUtils"
import {GoogleBudgetStats} from "../../../model/Store/Statistics/BudgetStats"

type ChosenKeys = "byDay" | "byMonth" | "byOngoingMonth"
const setStatsByChosenKey = (state,action,chosenKey:ChosenKeys) =>{
  if(action.stats === undefined){return state}
  const accountId = action.accountId
  if(state[accountId] === undefined){
    return {
      ...state,
      [accountId] : {
        [chosenKey] : action.stats
      }
    }
  }
  if(state[accountId][chosenKey] === undefined){
    return {
      ...state,
      [accountId] : {
        ...state[accountId],
        [chosenKey] : action.stats
      }
    }
  }
  const mergedStats = ArrayUtils.mergeBySerialization(
    state[accountId][chosenKey],
    action.stats,
    (stat)=>stat.Day+stat.BudgetID
  )
  return {
    ...state,
    [accountId] : {
      ...state[accountId],
      [chosenKey] : mergedStats
    }
  }
}
const setStatsByDay = (state,action) => setStatsByChosenKey(state,action,"byDay")
const setStatsByMonth = (state,action) => setStatsByChosenKey(state,action,"byMonth")
const setStatsByOngoingMonth = (state,action) => setStatsByChosenKey(state,action,"byOngoingMonth")
interface BudgetsByAccount{
  [accountId:number] : {
    byDay ?: GoogleBudgetStats[]
    byMonth ?: GoogleBudgetStats[]
    byOngoingMonth ?: GoogleBudgetStats[]
  }
}
export const BudgetsStats = ReducerUtils.createReducer<BudgetsByAccount>({},{
  [Actions.RETRIEVE_BUDGETS_STATS_BY_DAY] : setStatsByDay,
  [Actions.RETRIEVE_BUDGETS_STATS_BY_MONTH] : setStatsByMonth,
  [Actions.RETRIEVE_BUDGETS_STATS_BY_ONGOING_MONTH] : setStatsByOngoingMonth,
})
