import * as React       from "react"
import {withLocalize}   from "react-localize-redux"
import {Modal}          from "carbon-components-react"
import BootstrapTable   from 'react-bootstrap-table-next'
import AccountSelection from "./AccountSelection"

const AccountsModal = (props) => {
  return (
    <Modal
      onRequestClose={props.onRequestClose}
      modalHeading={props.translate("menu.accountsModal.header")}
      open={props.open}
      passiveModal
    >
      {props.open && <AccountSelection onAccountSelection={props.onRowClick} accounts={props.accounts}/>}
    </Modal>
  )
}

export default withLocalize(AccountsModal)
