import * as React                           from "react"
import {connect}                            from "react-redux"
import retrieveBiddingStrategies            from "../../actions/retrieveBiddingStrategies"
import ExplainedLoading                     from "../loading"
import ErrorBoundary                        from "../Error/ErrorBoundary"

const mapStateToProps = (state,ownProps) => {
  const accountId = state.Accounts.selected
  return {
    accountId         : accountId,
    fetching          : state.BiddingStrategies.fetching,
    biddingStrategies : state.BiddingStrategies.list[accountId],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadBiddingStrategies : (accountId:number)=>dispatch(retrieveBiddingStrategies(accountId)),
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    loadBiddingStrategies : () => DP.loadBiddingStrategies(SP.accountId),
  }
}
const BiddingStrategiesLoader = props => {
  if(props.biddingStrategies === undefined){
    if(!props.fetching){props.loadBiddingStrategies()}
    return <ExplainedLoading translateId="loadings.biddingStrategies"/>
  }
  return <ErrorBoundary>{props.render(props.biddingStrategies)}</ErrorBoundary>
}
const BiddingStrategiesLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(BiddingStrategiesLoader)

const requiresBiddingStrategies = Component => props => (
  <BiddingStrategiesLoaderConnected
    render={ biddingStrategies => <Component biddingStrategies={biddingStrategies} {...props} /> }
  />
)

export default requiresBiddingStrategies
