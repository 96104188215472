import './index.css'
import * as React                   from "react"
import {connect}                    from 'react-redux'
import {
  withLocalize,
  Translate,
  TranslateFunction
}                                   from "react-localize-redux"
import BootstrapTable               from 'react-bootstrap-table-next'
import filterFactory, {
  textFilter,
  selectFilter
}                                   from 'react-bootstrap-table2-filter'
import {setStrategy}                from "../../actions/strategies/setStrategy"
import BiddingStrategy              from "../../model/Store/BiddingStrategy"
import Strategy                     from "../../model/Store/Strategy/Strategy"
import Permissions                  from "../../model/constant/Permissions"
import requiresBiddingStrategies    from "../../components/loaders/biddingStrategiesLoader"
import requiresStrategies           from "../../components/loaders/strategiesLoader"
import AccountPermissionBoundary    from "../../components/permissions/AccountPermissionBoundary"
import {Panel}                      from "../../components/Panel/Panel"
import EditStrategyModal            from "./component/EditStrategyModal"
import GeoTargetingTypes            from '../../model/constant/GeoTargetingTypes'

interface StrategiesProps{
  accountId         : number
  strategies        : Strategy[]
  biddingStrategies : BiddingStrategy[]
  saveStrategy      : (strategy:Strategy)=>void
  translate        ?: (translateId:string, data?:any, options?:any)=>string
}
const mapStateToProps = (state)=> {
  const accountId = state.Accounts.selected
  return {
    accountId         : accountId,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    saveStrategy : (accountId:number, strategy:Strategy)=>dispatch(setStrategy(accountId, strategy))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    saveStrategy : (strategy:Strategy)=>DP.saveStrategy(SP.accountId, strategy)
  }
}
interface StrategiesState{
  selected ?: string
}
const findStrategyUniqueKey = (strategy:Strategy):string => {
  return [
    strategy.vehicleType,
    strategy.vehicleState,
    strategy.group,
    strategy.lang
  ].join("-")
}
const generateFilters = (data:string[], translate:(id:string, data?:any, options?:any)=>string) => {
  return data.reduce((filters, obj)=>{
    if(obj === undefined){return filters}
    filters[obj] = translate("common."+obj.toLowerCase(), null, {onMissingTranslation:()=>obj})
    return filters
  },{})
}
const headerTranslator = (col, _, components)=>(
  <div>
    <Translate id={"strategies."+col.text}/>
    {components.sortElement}
    {components.filterElement}
  </div>
)
export const getGeoTargetingString = (string: string, translateFunction: TranslateFunction) => {
  if (string === GeoTargetingTypes.DONT_CARE ||
      string === GeoTargetingTypes.AREA_OF_INTEREST ||
      string === GeoTargetingTypes.LOCATION_OF_PRESENCE
    ) {
      return translateFunction("strategies." + string)
    }
  return string
}
class StrategiesComponent extends React.Component<StrategiesProps,StrategiesState>{
  constructor(props){
    super(props)
    this.state = {
    }
  }
  render(){
    if(this.props.strategies.length === 0){return <div>No strategies for selected account</div>}
    const data = this.props.strategies.map((x,i)=>({
      ...x,
      key             : findStrategyUniqueKey(x),
      biddingStrategy : this.props.biddingStrategies.find(bidStrat=>bidStrat.id+""==x.biddingStrategyId)
    }))
    const initialColumns = [
      {
        dataField : "key",
        isKey     : true,
        hidden    : true,
        text      : "",
      },{
        dataField       : "budgetId",
        text            : "budgetId",
        headerFormatter : headerTranslator,
        align           : "center",
        headerAlign     : "center",
      },{
        dataField       : "biddingStrategy",
        text            : "biddingStrategy",
        headerFormatter : headerTranslator,
        formatter       : (x, row)=>{
          if(x===undefined){return row.biddingStrategyId}
          return x.name
        },
        filter          : textFilter(),
        filterValue     : (cell, row)=>cell?cell.name:row.biddingStrategyId,
        align           : "center",
        headerAlign     : "center",
      },{
        dataField       : "geoTargetingType",
        text            : "geoTargetingType",
        headerFormatter : headerTranslator,
        formatter       : (cell) => getGeoTargetingString(cell, this.props.translate),
        align           : "center",
        headerAlign     : "center",
      },{
        dataField       : "lang",
        text            : "language",
        headerFormatter : headerTranslator,
        sort            : true,
        filter          : selectFilter({options:{
          EN : "EN",
          FR : "FR"
        }}),
        align           : "center",
        headerAlign     : "center",
      },{
        dataField       : "finalUrl",
        text            : "finalUrl",
        headerFormatter : headerTranslator,
        sort            : true,
        formatter       : (cell,row)=>cell===undefined ? "-" : cell,
        filter          : textFilter(),
        align           : "center",
        headerAlign     : "center",
      }
    ]
    const columns = [
      {
        dataField       : "active",
        text            : "status",
        headerFormatter : headerTranslator,
        formatter       : (x)=>(x?<Translate id="common.active"/>:<Translate id="common.paused"/>),
        align           : "center",
        headerAlign     : "center",
        filter          : selectFilter({options:{
          true  : this.props.translate("common.active"),
          false : this.props.translate("common.paused")
        }}),
      },
      ...initialColumns.slice(0,6),
      { //Gotta add these here to generate the options based on data
        dataField   : "group",
        text        : "group",
        headerFormatter : headerTranslator,
        sort        : true,
        formatter   : (cell,row)=>row.group=="DO_NOT_CARE" ? "-" : row.group,
        filter      : selectFilter({options:generateFilters(data.map(f=>f.group), this.props.translate)}),
        align       : "center",
        headerAlign : "center",
      },{
        dataField   : "vehicleState",
        text        : "vehicleState",
        headerFormatter : headerTranslator,
        sort        : true,
        formatter   : (cell,row)=>cell===undefined ? "-" : cell,
        filter      : selectFilter({options:generateFilters(data.map(f=>f.vehicleState), this.props.translate)}),
        align       : "center",
        headerAlign : "center",
      },{
        dataField   : "vehicleType",
        text        : "vehicleType",
        headerFormatter : headerTranslator,
        sort        : true,
        formatter   : (cell,row)=>cell===undefined ? "-" : cell,
        filter      : selectFilter({options:generateFilters(data.map(f=>f.vehicleType), this.props.translate)}),
        align       : "center",
        headerAlign : "center",
      },
      ...initialColumns.slice(6)
    ]
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({selected: row.key})
      }
    }

    return (
      <Panel title={this.props.translate("strategies.title")} subtitle={this.props.translate("strategies.subtitle")}>
        <BootstrapTable
          hover
          data={data}
          columns={columns}
          filter={filterFactory()}
          keyField='key'
          defaultSorted={[{dataField: 'lang',order: 'desc'}]}
          classes={"normalSizedDatatable DataTableStrategies"}
          rowEvents={rowEvents}
          rowStyle={{cursor:"pointer"}}
        />
        <AccountPermissionBoundary
          permissions={[Permissions.Keystone_ChangeStrategy]}
          onFailure={
            this.state.selected &&
              <EditStrategyModal
                open
                onClose={()=>this.setState({selected:undefined})}
                strategy={data.find(x=>x.key===this.state.selected)}
                onConfirm={(strategy)=>console.error("You were not suppose to trigger that >:(")}
                biddingStrategies={this.props.biddingStrategies}
                readOnly
              />
          }
        >
          {this.state.selected &&
            <EditStrategyModal
              open
              onClose={()=>this.setState({selected:undefined})}
              strategy={data.find(x=>x.key===this.state.selected)}
              onConfirm={(strategy)=>{this.props.saveStrategy(strategy);this.setState({selected:undefined})}}
              biddingStrategies={this.props.biddingStrategies}
            />
          }
        </AccountPermissionBoundary>
      </Panel>
    )
  }
}
export const Strategies = (
  withLocalize(
    requiresStrategies()(
      requiresBiddingStrategies(
        connect(mapStateToProps,mapDispatchToProps,mergeProps)(
          StrategiesComponent
        ) as any
      )
    )
  )
)
