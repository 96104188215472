import * as React                   from "react"
import {HashRouter,Route,Redirect}  from "react-router-dom"
import {Link,RouteProps,Switch}     from "react-router-dom"
import RulesModule                  from "../page/rules"
import VehicleStateChooser          from "../page/rules/VehicleStateChooser"
import {StandardVehicleState}       from "../model/constant/VehicleState"
import {KNOWN_VEHICLE_STATE}        from "../model/constant/VehicleState"
import {StandardVehicleType}        from "../model/constant/VehicleType"
import {KNOWN_VEHICLE_TYPE}         from "../model/constant/VehicleType"

interface RouterAdCopyMapping{
  path : string
  content : (props)=>any // Component instance
}
const mapping : RouterAdCopyMapping[] = [
  { path:"*/Rules/Used/", content:(props)=> <RulesModule vehicleState={KNOWN_VEHICLE_STATE[StandardVehicleState.USED]} vehicleType={KNOWN_VEHICLE_TYPE[StandardVehicleType.CAR]} /> },
  { path:"*/Rules/New/",  content:(props)=> <RulesModule vehicleState={KNOWN_VEHICLE_STATE[StandardVehicleState.NEW]}  vehicleType={KNOWN_VEHICLE_TYPE[StandardVehicleType.CAR]} /> },
  { path:"*/Rules/",      content:(props)=> <VehicleStateChooser history={props.history}/>                                  },
]
export default (props) => {
  return (
    <HashRouter>
      <Switch>
        {mapping.map((route:RouterAdCopyMapping)=>
          <Route exact key={route.path} path={route.path} render={(props)=>route.content(props)}/>
        )}
      </Switch>
    </HashRouter>
  )
}
