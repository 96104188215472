import {createSelector}        from 'reselect'
import * as Generic            from "./Generic"

export default class VehicleAccessors{
  public static makeInventorySelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getInventory,
      (accountId, inventory) => inventory[accountId]
    )
  }
}
