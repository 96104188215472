import Actions           from "../../model/constant/actions"
import Friend            from "../../model/Store/Campaign/Friend"
import ReducerUtils      from "../../utils/ReducerUtils"
import InventoryFriend   from "../../model/Store/Campaign/InventoryFriend"
import ArrayUtils        from "../../utils/ArrayUtils"

const setFriends = (state, action) => {
  const accountId = action.accountId
  if(!state[accountId]){
    return {
      ...state,
      [accountId] : {
        friends        : action.friends,
        allLangFriends : action.allLangFriends
      }
    }
  }

  return {
    ...state,
    [accountId] : {
      friends        : [...state[accountId].friends,...action.friends],
      allLangFriends : [...state[accountId].allLangFriends, ...action.allLangFriends],
    }
  }
}
const addInventory = (state, action) => {
  if(!action.friendsAndVehicles.length && !action.allLangFriendsAndVehicles.length){return state}
  let toAdd = action.friendsAndVehicles.map(x=>{
    x.friendIds = x.friend.getIds()
    return x
  })
  let allLangToAdd = action.allLangFriendsAndVehicles.map(x=>{
    x.friendIds = x.friend.getIds()
    return x
  })

  const accountId = toAdd[0].friend.getAccountId()
  const newFriends = state[accountId].friends.map(friend =>{
    const friendIds = friend.getIds()
    for(let i=0; i<toAdd.length; i++){
      if(ArrayUtils.isLooselySameArray(friendIds, toAdd[i].friendIds)){
        const clone = (friend.clone()).withInventory(toAdd[i].vehicles)
        toAdd.splice(i,1)
        return clone
      }
    }
    return friend
  })
  const newAllLangFriends = state[accountId].allLangFriends.map(allLangFriend =>{
    const friendIds = allLangFriend.getIds()
    for(let i=0; i<allLangToAdd.length; i++){
      if(ArrayUtils.isLooselySameArray(friendIds, allLangToAdd[i].friendIds)){
        const clone = (allLangFriend.clone()).withInventory(allLangToAdd[i].vehicles)
        allLangToAdd.splice(i,1)
        return clone
      }
    }
    return allLangFriend
  })
  return {
    ...state,
    [accountId] : {
      ...state[accountId],
      friends : newFriends,
      allLangFriends: newAllLangFriends,
    }
  }
}

const clearInventory = (state, action) => {
  const accountId = action.accountId

  if (!state[accountId]) {
    return state
  }

  const newFriends = state[accountId].friends.map(friend =>{
    if (friend instanceof InventoryFriend) {
      return friend.clone().withInventory([])
    }
    return friend
  })

  return {
    ...state,
    [accountId] : {
      ...state[accountId],
      friends : newFriends
    }
  }
}
const saveChildrenOfCampaign = (state, action) => {
  const accountId = action.friend.getAccountId()
  const friends = state[accountId].friends.map(friend =>{
    if(!ArrayUtils.isLooselySameArray(friend.getIds(),action.friend.getIds())){return friend}
    return (friend.clone()).withCampaigns(friend.getCampaigns().map(campaign=>{
      if(campaign.id !== action.campaign.id){return campaign}
      return {
        ...campaign,
        geoBids               : action.campaign.geoBids,
        adGroups              : action.campaign.adGroups,
        childrenLoaded        : true,
        childrenLoadedFailure : false,
        childrenLoading       : false
      }
    }))
  })
  return {
    ...state,
    [accountId]: {
      ...state[accountId],
      friends : friends,
    }
  }
}
const setCampaignLoading = (state,action) => {
  const accountId = action.friend.getAccountId()
  const friends = state[accountId].friends.map(friend =>{
    if(!ArrayUtils.isLooselySameArray(friend.getIds(),action.friend.getIds())){return friend}
    return (friend.clone()).withCampaigns(friend.getCampaigns().map(campaign=>{
      if(campaign.id !== action.campaignId){return campaign}
      return {...campaign, childrenLoading : true}
    }))
  })
  return {
    ...state,
    [accountId]: {
      ...state[accountId],
      friends : friends,
    }
  }
}
const setCampaignLoadingFailure = (state,action) => {
  const accountId = action.friend.getAccountId()
  const friends = state[accountId].friends.map(friend =>{
    if(!ArrayUtils.isLooselySameArray(friend.getIds(),action.friend.getIds())){return friend}
    return (friend.clone()).withCampaigns(friend.getCampaigns().map(campaign=>{
      if(campaign.id !== action.campaignId){return campaign}
      return {
        ...campaign,
        childrenLoading       : false,
        childrenLoaded        : true,
        childrenLoadedFailure : true,
      }
    }))
  })
  return {
    ...state,
    [accountId]: {
      ...state[accountId],
      friends : friends,
    }
  }
}
interface FriendsByAccountId{
  [accountId:number] : {
    friends : Friend[]
  }
}
export const CampaignsList = ReducerUtils.createReducer<FriendsByAccountId>({},{
  [Actions.RETRIEVE_CAMPAIGNS_BY_PAGE_FOR_ACCOUNTID]   : setFriends,
  [Actions.ADD_INVENTORY_TO_FRIENDS]                   : addInventory,
  [Actions.CLEAR_INVENTORY_FROM_FRIENDS]               : clearInventory,
  [Actions.UPDATE_CAMPAIGN_CHILDREN_LOADED]            : saveChildrenOfCampaign,
  [Actions.DECLARE_CAMPAIGN_CHILDREN_LOADING]          : setCampaignLoading,
  [Actions.FALLBACK_CAMPAIGN_CHILDREN_LOADING_FAILURE] : setCampaignLoadingFailure,
})
