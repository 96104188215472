import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"

const setStatus = (state:SaveStatus, action):SaveStatus => {
  return {
    status : action.status,
    error  : action.error,
  }
}

interface SaveStatus{
  status  : boolean
  error  ?: any
}
const PacingRatio = ReducerUtils.createReducer<SaveStatus>({status: false},{
  [Actions.SAVING_PACY_RATIO] : setStatus,
  [Actions.CLEAR_PACY_RATIO_ERROR] : setStatus
})

export default PacingRatio
