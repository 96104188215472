import * as React          from "react"
import {PermissionContext} from "./"
import PermissionError     from "../../Error/PermissionError"

const requiresPermission = (permissionName:string, silent:boolean=false) => (Component) => (props) => {
  return (
    <PermissionContext.Consumer>
      {(context) => {
        if(context.validatePermission(permissionName)){
          return <Component permissions={context} {...props}/>
        }
        if(!silent){return <PermissionError permission={permissionName}/>}
        return null
      }}
    </PermissionContext.Consumer>
  )
}

export default requiresPermission
