import './index.css'
import * as React            from "react"
import {withRouter}          from "react-router"
import {withLocalize}        from "react-localize-redux"
import RoutingUtils          from "../../utils/RoutingUtils"
import {Panel}               from "../../components/Panel/Panel"
import Button                from "../../components/Button/MinimalistButton"

const Campaigns = (props)=>{
  const buttonCampaigns = [
    {
      text         : props.translate("campaigns.search"),
      subtitle     : props.translate("campaigns.searchSubtitle"),
      onClick      : ()=>RoutingUtils.appendToUrl(props.history, "Search"),
    },
    {
      text         : props.translate("campaigns.display"),
      subtitle     : props.translate("campaigns.displayRmktSubtitle"),
      onClick      : ()=>RoutingUtils.appendToUrl(props.history, "Display"),
    },
  ]
  return(
    <Panel title={props.translate("campaigns.list.title")}>
      <div className="MinimalistButtonsList">
        {buttonCampaigns.map(button=>
          <Button key={button.text} {...button}/>
        )}
      </div>
    </Panel>
  )
}

export default withLocalize(withRouter(Campaigns) as any)
