import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const setTrue = (state,action) => {return true}
const setFalse = (state,action) => {return false}

export const NeedToFetchNavigations = ReducerUtils.createReducer<boolean>(true,{
  [Actions.RETRIEVE_USER_NAVIGATION_OF_ACCOUNT] : setFalse,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST]    : setTrue,
  [Actions.CHANGE_SELECTED_PERIOD]              : setTrue,
})
