import {combineReducers} from "redux"
import Retrieved         from "./retrieved/Retrieved"
import Retrieving        from "./retrieving/Retrieving"
import Failed            from "./failed/Failed"

export default combineReducers({
  Retrieved,
  Retrieving,
  Failed,
})
