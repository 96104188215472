import Auth2                       from "../../utils/Auth2"
import { getToken }                from "../../storeAccessor/Generic"
import NumberFormatter             from "../../utils/NumberFormatter"
import Actions                     from "../../model/constant/actions"


const onFailure = (status: boolean) => {
  return ({
    type   : Actions.FAILED_SET_USER_LOCALE,
    status : status
  })
}

const settingLocale = (status: boolean) => {
  return ({
    type   : Actions.SETTING_USER_LOCALE,
    status : status
  })
}

const setLocale = (userId, locale:string) => (dispatch, getState) => {
  const state = getState()
  const token = getToken(state)
  const currentUserId = state.Login.userLogged.profile.id

  dispatch(settingLocale(true))
  Auth2.setUserLocale(token, userId, locale)
  .then(() => {
    if (userId === currentUserId) {
      NumberFormatter.setLocale(locale)
      dispatch({
        type    : Actions.SET_USER_LOCALE,
        payload : locale
      })
    }
  })
  .catch((error) => {
    dispatch(onFailure(true))
  })
  .then(() => {
    dispatch(settingLocale(false))
  })
}
export default setLocale
