import {combineReducers}  from "redux"
import Bing               from "./bing/Bing"
import Google             from "./google/Google"
import Facebook           from "./facebook/Facebook"
import Retrieving         from "./retrieving/Retrieving"
import RetrievedRange     from "./retrievedRange/RetrievedRange"

export const Statistics = combineReducers({
  Bing,
  Google,
  Facebook,
  Retrieving,
  RetrievedRange,
})
