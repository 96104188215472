import Actions          from "../../model/constant/actions"
import ReducerUtils     from "../../utils/ReducerUtils"

const addCompanies = (state, action) => {
  return {
    list : action.companies,
    isFetching : false
  }
}
const setFetching = (state, action) => {
  return {
    ...state,
    isFetching : true
  }
}
interface Companies{
  list ?: any[]
  isFetching : boolean
}
const defaultState = {
  isFetching : false,
}
const Companies = ReducerUtils.createReducer<Companies>(defaultState,{
  [Actions.RETRIEVE_WAZE_COMPANIES] : addCompanies,
  [Actions.RETRIEVING_WAZE_COMPANIES] : setFetching,
})
export default Companies
