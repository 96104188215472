import "./index.css"
import * as React              from 'react'
import {connect}               from 'react-redux'
import {
  Fade,
  Button,
  Backdrop,
  LinearProgress,
  CircularProgress,
  Card,
}                              from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                              from "react-localize-redux"
import {
  makeStyles,
  createStyles,
  Theme,
}                              from '@material-ui/core/styles'
import RequiresFeeds           from "../../components/loaders/feedsLoader"
import RequiresAvailableFeeds  from "../../components/loaders/availableFeedsLoader"
import RequiresSignedUrls      from "../../components/loaders/feedsSignedUrlsLoader"
import {
  deleteFeedsSignedUrl,
  createFeedsSignedUrl,
}                              from "../../actions/feeds/SignedUrls"
import {Feed as IFeed}         from "../../model/feed/Feed"
import {
  refreshFeed,
  deleteFeed,
}                              from "../../actions/feeds/Feeds"
import Feed                    from "./feed"
import CreationModal           from "./creationModal"

const useStylesForBackdrop = makeStyles((theme: Theme) => createStyles({
  backdrop : {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const LOADING_FADE_ENTER_DURATION = 200
const LOADING_FADE_EXIT_DURATION  = 200

interface OwnProps {
  readonly feeds                  : IFeed[]
  readonly feedsSignedUrls        : any
  readonly availableFeeds         : IFeed[]
  readonly loadingFeedsSignedUrls : boolean
  readonly loadingFeeds           : boolean
  readonly loadingAvailableFeeds  : boolean
}

const mapStateToProps = (state, ownProps)=>{
  return {
    accountId : state.Accounts.selected as number,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    deleteFeed          : (accountId, feedId) => dispatch(deleteFeed(accountId, feedId)),
    deleteSignedUrl     : (accountId, url)    => dispatch(deleteFeedsSignedUrl(accountId, url)),
    createSignedUrl     : (accountId, feedId) => dispatch(createFeedsSignedUrl(accountId, feedId)),
    refreshFeed         : (accountId, feedId) => dispatch(refreshFeed(accountId, feedId, true)),
  }
}
const mergeProps = (SP:ReturnType<typeof mapStateToProps>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:OwnProps)=>{
  return {
    ...SP,
    ...DP,
    deleteFeed      : (feedId) => DP.deleteFeed(SP.accountId, feedId),
    deleteSignedUrl : (url)    => DP.deleteSignedUrl(SP.accountId, url),
    createSignedUrl : (feedId) => DP.createSignedUrl(SP.accountId, feedId),
    refreshFeed     : (feedId) => DP.refreshFeed(SP.accountId, feedId),
    ...ownProps
  }
}

const FeedsPage = (props:ReturnType<typeof mergeProps> & LocalizeContextProps) => {
  const [requestModalOpen, setRequestModalOpen ] = React.useState<boolean>(false)
  const [expansionMemory,  setExpansionMemory  ] = React.useState<{[feedId:string]:true}>({})
  const currentlyLoading : boolean = props.loadingFeeds||props.loadingFeedsSignedUrls
  const loadingAvailable : boolean = props.loadingAvailableFeeds

  React.useEffect(
    ()=>{if(currentlyLoading===false){setRequestModalOpen(false)}},
    [currentlyLoading]
  )
  const hasData : boolean = Boolean(props.feeds&&props.feedsSignedUrls)
  const classes = useStylesForBackdrop(props)

  return (
    <div className='FeedsPage'>
      <CreationModal
        open={requestModalOpen}
        onClose={()=>{setRequestModalOpen(false)}}
        loading={currentlyLoading || loadingAvailable}
        options={props.availableFeeds}
      />
      <div className='headers'>
        <h3>{props.translate("feeds.feeds")}</h3>
       {hasData && props.feeds.length > 0 && <Button size="large" variant="contained" color="primary" onClick={()=>{setRequestModalOpen(true)}}>
          {props.translate("feeds.create-feed.cta")}
        </Button>}
      </div>
      <Fade
        unmountOnExit
        in={currentlyLoading}
        timeout={{
          enter : LOADING_FADE_ENTER_DURATION,
          exit  : LOADING_FADE_EXIT_DURATION,
        }}
      >
        {
          hasData
          ? <Backdrop className={classes.backdrop} open={currentlyLoading}>
              <CircularProgress size={120} color="primary"/>
            </Backdrop>
          : <LinearProgress color="primary"/>
        }
      </Fade>
      {
        hasData===false ?  null : <>
          {
            props.feeds.length === 0
            ?
            <div className="NoFeeds">
              <Card className="NoFeedsCard">
                <h5>{props.translate('feeds.empty-cta')}</h5>
                <Button size="large" variant="contained" color="primary" onClick={()=>{setRequestModalOpen(true)}}>
                  {props.translate("feeds.create-feed.cta")}
                </Button>
              </Card>
            </div>
            :
            props.feeds
            .slice()
            .sort((a,b)=>{return a.stub < b.stub ? -1 : a.stub > b.stub ? 1 : 0})
            .map(feed=>(
              <Feed
                id={feed.id}
                key={feed.id}
                stub={feed.stub}
                config={feed.config}
                urls={props.feedsSignedUrls[feed.id]||[]}
                deleteFeed={()=>props.deleteFeed(feed.id)}
                deleteSignedUrl={props.deleteSignedUrl}
                createSignedUrl={()=>props.createSignedUrl(feed.id)}
                refreshFeed={()=>props.refreshFeed(feed.id)}
                loading={currentlyLoading}
                expanded={expansionMemory[feed.id]}
                setExpanded={(expanded)=>{
                  if(expanded){
                    setExpansionMemory({...expansionMemory, [feed.id]:true})
                  }
                  else{
                    const {[feed.id]:x, ...rest} = expansionMemory
                    setExpansionMemory(rest)
                  }
                }}
              />
            ))
          }
        </>
      }
    </div>
  )
}

export default (
  RequiresSignedUrls(true)( // passthrough loading is necessary for expansionMemory
    RequiresAvailableFeeds(true)( // passthrough loading is necessary for expansionMemory
      RequiresFeeds(true)( // passthrough loading is necessary for expansionMemory
        connect(mapStateToProps,mapDispatchToProps,mergeProps)(
          withLocalize(
            FeedsPage
          )
        )
      )
    )
  )
)
