import Actions                from "../../../model/constant/actions"
import {BingBudgetStats}      from "../../../model/Store/Statistics/BudgetStats"
import ReducerUtils           from "../../../utils/ReducerUtils"
import ArrayUtils             from "../../../utils/ArrayUtils"

const setStatsByDay = (state,action) =>{
  const accountId = action.accountId
  if(!state[accountId]){
    return {
      ...state,
      [accountId] :  action.stats
    }
  }
  const mergedStats = ArrayUtils.mergeBySerialization(
    state[accountId],
    action.stats,
    (stat)=>stat.Day+stat.BudgetID
  )
  return {
    ...state,
    [accountId] : mergedStats
  }
}
interface BudgetsByAccount{
  [accountId:number] : BingBudgetStats[]
}
const BingBudgetsStats = ReducerUtils.createReducer<BudgetsByAccount>({},{
  [Actions.RETRIEVE_BING_BUDGETS_STATS] : setStatsByDay,
})

export default BingBudgetsStats
