import "./AudienceAdsList.css"
import React                     from 'react'
import { FixedSizeList as List } from 'react-window'
import {
  ListItem,
  TextField,
  Card
}                                from '@material-ui/core'
import { Translate }             from "react-localize-redux"
import AWSearch                  from "../../../../model/Store/Audiences/AWSearch"


interface AudienceAdsListOwnProps {
  availableAudiences : (AWSearch | {id: number,name: string})[]
  onSelect           : (id:number) => void
}

const AudienceAdsList = (props:AudienceAdsListOwnProps) => {
  const [searchValue,setSearchValue] = React.useState('')
  const handleOnChange = (event) => {
    const temp = event.target.value
    setSearchValue(temp)
  }
  const audiences = props.availableAudiences.filter(audience=>{
    return audience.name.toLowerCase().includes(searchValue.toLowerCase())
  })
  return (
    <Card className='AudienceAdsListContainer'>
      <TextField
        autoFocus
        onChange={handleOnChange}
        label={<Translate id="adBuilder.audienceSearch.searchAudiences" />}
        className="SearchInput"
        fullWidth
        value={searchValue}
      />
      <div className="AudiencesAdsList">
        <List
          height={400}
          width={600}
          itemSize={40}
          itemCount={audiences.length}
          itemData={{
            audiences : audiences,
            onClick   : props.onSelect
          }}
        >
          {Row}
        </List>
      </div>
    </Card>
  )
}
interface RowOwnProps {
  style : object
  index : number
  data  : {
    audiences : (AWSearch | {id: number,name: string})[]
    onClick   : (index:number) => void
  }
}
const Row =(props:RowOwnProps) => (
  <ListItem
    style={props.style}
    onClick={()=>props.data.onClick(props.data.audiences[props.index].id)}
    button={true}
    key={props.index}
  >
    {props.data.audiences[props.index].name}
  </ListItem>
)

export default AudienceAdsList
