import Actions      from "../../model/constant/actions"
import ReducerUtils from "../../utils/ReducerUtils"

const setMessage = (state, action) => {
  return action.message
}
const Message = ReducerUtils.createReducer<string>("",{
  [Actions.SET_PERMISSION_MESSAGE]  : setMessage,
})
export default Message
