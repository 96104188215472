import * as React           from 'react'
import {
  PieChart,
  Pie,
  Cell,
}                           from 'recharts'
import { ProxyGroup }       from '../../../../../model/pacy/ProxyGroup'
import { Proxy }            from '../../../../../model/pacy'


interface ProxyGroupSharingPieOwnProps {
  proxy                    : Proxy
  proxyGroup               : ProxyGroup
  adjustedWeightProxy      : Proxy
  startingAngle           ?: number
  unadjustedStartingAngle ?: number
}

interface ProxyGroupSharingPie extends ProxyGroupSharingPieOwnProps {}

const ProxyGroupSharingPie = (props: ProxyGroupSharingPie) => {
  const proxies = props.proxyGroup.proxies

  const totalActiveWeight = proxies.reduce((acc,proxy)=>{
    if(proxy.active || proxy.id === props.proxy.id) {
      return acc + proxy.weight
    }
    return acc
  },0)

  const otherWeight = totalActiveWeight - props.proxy.weight

  const data = [
    {
      id: "others",
      name: "others",
      weight: otherWeight
    },
    props.proxy
  ]

  let pieColors = {
    "others": '#ddd',
    [props.adjustedWeightProxy.id]: props.proxy.active ? '#00b2e3' : "#666666"
  }

  return (
    <div>
      <PieChart width={40} height={40}>
        <Pie
          dataKey="weight"
          isAnimationActive={false}
          data={data}
          cx={"50%"}
          cy={"50%"}
          fill={"#00B2E3"}
          blendStroke
          outerRadius={15}
          startAngle={props.proxy.active ? (props.startingAngle || 0) : (props.unadjustedStartingAngle || 0)}
          endAngle={props.proxy.active ? (props.startingAngle + 360 || 360) : props.unadjustedStartingAngle + 360 || 360 }
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={pieColors[entry.id]} />)
          }
        </Pie>
      </PieChart>
    </div>
  )
}

export default ProxyGroupSharingPie
