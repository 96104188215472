import styles                             from "./AdCopyManagerStyles"
import * as React                         from "react"
import {State}                            from "../../../reducers/generic/reducerAssembly"
import {connect}                          from "react-redux"
import {
  withLocalize,
  LocalizeContextProps
}                                         from "react-localize-redux"
import {createSelector}                   from "reselect"
import ArrayUtils                         from "../../../utils/ArrayUtils"
import GROUPS                             from "../../../model/constant/Groups"
import TAGS                               from "../../../model/constant/AdCopiesTags"
import {getActiveStrategies,}             from "../../../actions/AdCopyPatterns"
import {Panel}                            from "../../../components/Panel/Panel"
import ExplainedLoading                   from "../../../components/loading/index"
import Notice                             from "../../../components/alert/Notice"
import AdCopyEditorStateManager           from "./AdCopyEditorStateManager"
import SortedAdCopyList                   from "./SortedAdCopyList"
import TranslatedGoBackLarge              from "../../../components/notifications/TranslatedGoBackLarge"
import {Snackbar}                         from "@material-ui/core"
import requiresAWSearchAudiences          from "../../../components/loaders/AWSearchAudiencesLoader"
import AWSearch                           from "../../../model/Store/Audiences/AWSearch"

interface AdCopyManagerOwnProps extends LocalizeContextProps{
  pathname      : string
  AWAudiences   : AWSearch[]
  readOnly     ?: boolean
}

const makeMapStateToProps = () => {
  const backUrlSelector = createSelector(
    (_,props:AdCopyManagerOwnProps)=>props.pathname,
    (currentUrl:string)=>{
      const sections = currentUrl.split("/")
      return [...sections.slice(0,4)].join("/")
    }
  )
  return (state:State, ownProps:AdCopyManagerOwnProps) => {
    return {
      backUrl              : backUrlSelector(state, ownProps),
      accountId            : state.Accounts.selected,
      activeStrategies     : state.Params.AdCopies.Strategies.ActiveStrategies[state.Accounts.selected],
      retrievingStrategies : state.Params.AdCopies.Strategies.Retrieving,
      failedStrategies     : state.Params.AdCopies.Strategies.Failed,
      savingAdcopies       : state.Params.AdCopies.RSAAdcopies.Saving,
      errorSavingAdcopies  : state.Params.AdCopies.RSAAdcopies.FailedSaving.status,
      errorMessage         : state.Params.AdCopies.RSAAdcopies.FailedSaving.message,
    }
  }
}

const mapDispatchToProps = dispatch => {
  return{
    getActiveStrategies  : (...args:Parameters<typeof getActiveStrategies>) => dispatch(getActiveStrategies(...args)),
  }
}

const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType<typeof mapDispatchToProps>, ownProps:AdCopyManagerOwnProps) => {
  return {
    ...SP,...DP,...ownProps,
    getActiveStrategies : (accountId:number) => DP.getActiveStrategies(accountId)
  }
}

type AdCopyManagerProps = ReturnType<typeof mergeProps>

interface AdCopyData {
  campaignType : string
  campaign     : string
  adgroup      : string
  lang         : string
}

const AdCopyManager = (props:AdCopyManagerProps) => {
  if(props.activeStrategies === undefined && !props.retrievingStrategies && !props.failedStrategies){
    props.getActiveStrategies(props.accountId)
  }
  if (props.activeStrategies === undefined){return <ExplainedLoading  translateId="loadings.adCopies"/>}

  const [editing,setEditing]     = React.useState<boolean>(false)
  const [saveSnackOpen, setSaveSnackOpen] = React.useState<boolean>(props.savingAdcopies)
  const [errorSnackOpen, setErrorSnackOpen] = React.useState<boolean>(props.errorSavingAdcopies)
  const [adCopyData,setAdCopyData] = React.useState<AdCopyData>()

  // Sync the saving state with the store status
  React.useEffect(()=>{
    if (props.savingAdcopies){
      setSaveSnackOpen(props.savingAdcopies)
    }
  }, [props.savingAdcopies])

  // Sync the failed saving state with the store status
  React.useEffect(()=>{
    if (props.errorSavingAdcopies){
      setSaveSnackOpen(false)
      setErrorSnackOpen(props.errorSavingAdcopies)
    }
  }, [props.errorSavingAdcopies])

  // Handle changing accounts
  React.useEffect(()=>{
    props.getActiveStrategies(props.accountId)
    if (editing){
      cancelEditing()
    }
  }, [props.accountId])

  const startEditing = (campaignType:string, campaign:string, adgroup:string, lang:string) => {
    setAdCopyData({
      campaignType,
      campaign,
      adgroup,
      lang
    })
    setEditing(true)
  }

  const cancelEditing = () => {
    setEditing(false)
  }
  
  if(Object.keys(props.activeStrategies).length === 0) {
    return <TranslatedGoBackLarge type="link" to={props.backUrl} translationId='adCopies.noAdCopies'></TranslatedGoBackLarge>
  }

  const classes = styles()
  return (
    <Panel 
      title={editing ? props.translate("adCopies.editorTitle") as string : props.translate("adCopies.adManager") as string} 
      subtitle={editing ? adCopyData.campaignType : ""}
    >
      <Snackbar
        open={saveSnackOpen}
        autoHideDuration={6000}
        onClose={()=>setSaveSnackOpen(false)}
        anchorOrigin={{vertical:"top", horizontal:"center"}}
      >
        <div className={classes.alert}>
          {props.translate("adCopies.saveSnack")}
        </div>
      </Snackbar>
      <Snackbar
        open={errorSnackOpen}
        autoHideDuration={6000}
        onClose={()=>setErrorSnackOpen(false)}
        anchorOrigin={{vertical:"top", horizontal:"center"}}
      >
        <div className={classes.errorAlert}>
          {`${props.translate("adCopies.saveErrorSnack")} : ${props.errorMessage}`}
        </div>
      </Snackbar>
      <Notice
        text="You are not allowed to edit adcopies"
        isActive={props.readOnly||false}
      />
      <div style={{marginTop:30}}>
        { editing 
          ? 
            <AdCopyEditorStateManager
              campaignGroup={adCopyData.campaignType}
              lang={adCopyData.lang}
              strategy={adCopyData.campaign}
              adgroup={adCopyData.adgroup}
              availableOptions={props.activeStrategies[adCopyData.campaignType]}
              readOnly={props.readOnly||false}
              forceDisableInteractions={props.savingAdcopies}
              handleCancel={()=>setEditing(false)}
              AWAudiences={props.AWAudiences}
            />
          : 
            <SortedAdCopyList
              activeStrategies={props.activeStrategies}
              startEditing={startEditing}
              AWAudiences={props.AWAudiences}
            />
        }
      </div>
    </Panel>
  )
}

export default withLocalize(
  requiresAWSearchAudiences()(connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(AdCopyManager)
))
