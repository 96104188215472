import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    eventOverview : {
      backgroundColor : "#FAFAFA",
      height : 375,
      width : 365,
      padding : 20,
      display: "grid",
      gridTemplateRows : "40px 55px 130px 20px",
      gridRowGap : 10
    },
    title : {
      fontFamily : "Roboto",
      fontWeight : "bold",
      fontSize : "22px",
      marginBottom : 15,
      display : "flex",
      justifyContent : "space-between",
      paddingRight : 20,
    },
    statusAndDates : {
      display : "flex",
      gap : 15,
      alignItems : "center",
      marginBottom : 10
    },
    boundaries : {
      margin : "12px 0px 12px 0px",
      maxWidth : 345,
      paddingRight : 20
    },
    boundary : {},
    button : {
      fontFamily : "Roboto",
      backgroundColor : "#707070",
      color : "#FFFFFF",
      width : 90,
      height : 30,
      justifySelf : "center",
      margin: "10px 80px",
      '&:hover':{
        backgroundColor: "#c6c6c6",
      }
    },
    buttonContainer : {
      display : "flex",
      alignItems : "flex-end",
      justifyContent : "center",
      marginTop : 20,
    },
    icon : {
      cursor : "pointer",
      color : "#D8D6D6",
      '&:hover' : {
        color : "#7F7F7F"
      }
    },
    isCopy : {
      color : "#ED6464",
      paddingBottom : 10
    }
  })
)

export default useStyles
