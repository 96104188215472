import './index.css'
import * as React           from "react"
import onClickOutside       from "react-onclickoutside"

interface OverflowMenuProps{
  options        : Option[]
  onSelect       : (selected:Option["value"])=>void
  icon          ?: string
  closeOnSelect ?: boolean
}
export interface Option{
  text       : string
  value      : any
  className ?: string
}
interface OverflowMenuState{
  opened : boolean
}

class OverflowMenu extends React.Component<OverflowMenuProps,OverflowMenuState>{
  static defaultProps = {
    icon          : "more_vert",
    closeOnSelect : false
  }
  constructor(props:OverflowMenuProps){
    super(props)
    this.state = {
      opened : false
    }
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }
  shouldComponentUpdate(nextProps:OverflowMenuProps, nextState:OverflowMenuState){
    if(!this.state.opened && !nextState.opened && this.props.icon == nextProps.icon){
      return false
    }
    return true
  }
  handleClickOutside(e){
    if(this.state.opened){
      this.closeMenu()
    }
  }
  openMenu(){
    this.setState({opened: true})
  }
  closeMenu(){
    this.setState({opened: false})
  }
  onSelect(value:Option["value"]){
    this.props.onSelect(value)
    if(this.props.closeOnSelect){this.closeMenu()}
  }
  render(){
    if(!this.state.opened){
      return (
        <div className="OverflowMenu closed">
          <i className="material-icons" onClick={this.openMenu}>
            {this.props.icon}
          </i>
        </div>
      )
    }
    return (
      <div className="OverflowMenu opened">
        <i className="material-icons" onClick={this.closeMenu}>
          {this.props.icon}
        </i>
        <div className="OptionsModal">
          {this.props.options.map(x=>
            <div
              key={x.value}
              className={"Option "+(x.className||"")}
              onClick={()=>this.onSelect(x.value)}
            >
              {x.text}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default onClickOutside(OverflowMenu)
