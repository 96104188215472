import Actions                   from "../model/constant/actions"
import UserActions               from "../model/constant/UserAction"
import AdCopy                    from "../model/Store/AdCopyPatterns/AdCopy"
import LogBuilder                from "../controller/log/LogBuilder"
import {getToken}                from "../storeAccessor/Generic"
import Headstone                 from "../utils/Headstone"
import ActionUtils               from "../utils/ActionUtils"
import {AudienceAdsContainer}    from "../storeAccessor/AdCopies"
import {AdCopyRSAPatterns}       from "../model/adcopy"

export interface CreateAdCopyPatternBody {
  bidModifier : number
  matcher     : {
    group       : string
    instanceof  : string
    lang        : string
    state       : string
    vehicleType : string
  }
  templates   : AdCopy[]
}

interface getErrorStringError {
  response: {status: number}
}

const getAdCopyPatternsError = (response) => {
  return {
    type            : Actions.RETRIEVE_ADCOPIES_PATTERNS_FOR_ACCOUNTID,
    adCopyPatterns  : undefined,
  }
}
export const loadAdCopyPatterns = (accountId:number) => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_ADCOPY_PATTERNS,
    status : true
  })
  ActionUtils.retryOnFailure(
    ()=>Headstone.getAdCopyPatterns(getToken(getState()), accountId)
  )
  .then((response) => {
    const containers = response.data.map(x=>({
      id          : x.id,
      audience    : x.audienceId,
      patterns    : x.templates,
      matcher     : x.matcher,
      bidModifier : x.bidModifier
    }))
    dispatch({
      type            : Actions.RETRIEVE_ADCOPIES_PATTERNS_FOR_ACCOUNTID,
      adCopyPatterns  : containers,
      accountId       : getState().Accounts.selected
    })
  })
  .catch((error) => dispatch(getAdCopyPatternsError(error)))
  .then(
    dispatch({
      type   : Actions.RETRIEVING_ADCOPY_PATTERNS,
      status : false
    })
  )
}

const getErrorString = (error: getErrorStringError): string => {
  const errorCode = error.response.status

  if (errorCode) {
    return errorCode.toString()
  }

  return error.toString()
}

export const saveAdCopies = (accountId:number, audienceAdsContainers:AudienceAdsContainer[], newPatterns:AdCopy[], adCopy:AdCopy, group:string) => (dispatch, getState) => {
  const state = getState()
  const token = getToken(state)
  LogBuilder.log(UserActions.EDIT_ADCOPY,token,{accountId, containerIds:audienceAdsContainers.map(x=>x.id), ...adCopy, group})
  doSetPatterns(accountId, audienceAdsContainers, newPatterns)(dispatch, getState)
}
export const saveNewAdCopy = (accountId:number, audienceAdsContainers:AudienceAdsContainer[], adCopies:AdCopy[], pattern:AdCopy, group:string) => (dispatch, getState) => {
  const state = getState()
  const token = getToken(state)
  LogBuilder.log(UserActions.CREATE_ADCOPY, token, {accountId, containerIds:audienceAdsContainers.map(x=>x.id), ...pattern, group})
  doSetPatterns(accountId, audienceAdsContainers, adCopies)(dispatch, getState)
}
export const removeAdCopyPattern = (accountId:number, audienceAdsContainers:AudienceAdsContainer[], adCopies:AdCopy[], patternId:number) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.REMOVE_ADCOPY, token, {accountId:accountId, containerIds:audienceAdsContainers.map(x=>x.id), patternId:patternId})
  doSetPatterns(accountId, audienceAdsContainers, adCopies)(dispatch, getState)
}

const doSetPatterns = (accountId:number, audienceAdsContainers:AudienceAdsContainer[], adCopies:AdCopy[]) => (dispatch, getState) => {
  const state = getState()
  const token = getToken(state)

  Promise.all(audienceAdsContainers.map(container=>
    ActionUtils.retryOnFailure(
      () => Headstone.createAdCopyPattern(token, accountId, {
        audienceId  : container.audience,
        bidModifier : container.bidModifier,
        matcher     : container.matcher,
        templates   : adCopies
      })
    )
  ))
  .then((response) => {
    //We want to always dispatch this, even when there is no errors,
    //to remember the last time we tried to save
    dispatch({
      type        : Actions.STORE_SAVE_ADCOPY_PATTERN_ERROR,
      errorString : ""
    })
    dispatch(loadAdCopyPatterns(accountId))
  })
  .catch((error) => {
    dispatch({
      type        : Actions.STORE_SAVE_ADCOPY_PATTERN_ERROR,
      errorString : getErrorString(error)
    })
  })
}

export const getActiveStrategies = (accountId:number) => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_ADCOPY_ACTIVE_STRATEGIES,
    status : true
  })
  ActionUtils.retryOnFailure(()=>Headstone.getActiveStrategies(getToken(getState()), accountId))
  .then((response) => {
    dispatch({
      type       : Actions.RETRIEVE_ADCOPY_ACTIVE_STRATEGIES,
      strategies : response.data,
      accountId  : accountId
    })
    dispatch({
      type   : Actions.RETRIEVING_ADCOPY_ACTIVE_STRATEGIES,
      status : false
    })
  })
  .catch((_error) => {
    console.log(_error)
    dispatch({
      type : Actions.FAILED_RETRIEVING_ADCOPY_ACTIVE_STRATEGIES,
      status : true
    })
    dispatch({
      type   : Actions.RETRIEVING_ADCOPY_ACTIVE_STRATEGIES,
      status : false
    })
  })
}

export const getRSAAdCopies = (accountId:number, campaignGroup:string) => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_RSA_ADCOPIES,
    status : true,
    campaignGroup
  })
  ActionUtils.retryOnFailure(()=>Headstone.getRSAAdCopies(getToken(getState()), accountId, campaignGroup))
  .then((response) => {
    dispatch({
      type     : Actions.RETRIEVE_RSA_ADCOPIES,
      adcopies : response.data,
      accountId,
      campaignGroup
    })
    dispatch({
      type   : Actions.RETRIEVING_RSA_ADCOPIES,
      status : false,
      campaignGroup
    })
  })
  .catch((_error) => {
    dispatch({
      type : Actions.FAILED_RETRIEVING_RSA_ADCOPIES,
      status : true,
      campaignGroup
    })
    dispatch({
      type   : Actions.RETRIEVING_RSA_ADCOPIES,
      status : false,
      campaignGroup
    })
  })
}

export const saveRSAAdCopies = (accountId:number, campaignGroup:string, adCopies:AdCopyRSAPatterns[]) => (dispatch, getState) => {
  // We assign temporary ids when retrieving ad copies or creating new ones for frontend interactions. 
  // We need to clean them up before sending a save request
  adCopies.forEach(adCopy=>{
    adCopy.templates.forEach(template=>{
      template.headlines.forEach(headline=>{
        delete headline.id
      })
      template.descriptions.forEach(description=>{
        delete description.id
      })
    })
  })
  const state = getState()
  const token = getToken(state)

  dispatch({
    type   : Actions.SAVING_RSA_ADCOPIES,
    status : true,
    campaignGroup
  })

  ActionUtils.retryOnFailure(()=>Headstone.saveRSAAdCopies(token, accountId, campaignGroup, adCopies))
  .then(_response => {
    dispatch(getRSAAdCopies(accountId, campaignGroup))
    dispatch({
      type   : Actions.SAVING_RSA_ADCOPIES,
      status : false,
      campaignGroup
    })
  })
  .catch(error=>{
    dispatch({
      type : Actions.FAILED_SAVING_RSA_ADCOPIES,
      status:true,
      errorString : getErrorString(error)
    })
    dispatch({
      type   : Actions.SAVING_RSA_ADCOPIES,
      status : false,
      campaignGroup
    })
  })
}
