import ReducerUtils       from "../../../utils/ReducerUtils"
import Actions            from "../../../model/constant/actions"

const addUser = (state:number[],action) => {
  return [...state , action.userId]
}

const removeUser = (state:number[],action) => {
  return state.filter(userId => userId !== action.userId)
}

const clearUsers = (state:number[],action) => {
  return []
}

const Users = ReducerUtils.createReducer<number[]>([],{
  [Actions.ADD_USER]                         : addUser,
  [Actions.REMOVE_USER]                      : removeUser,
  [Actions.CLEAR_USERS]                      : clearUsers,
  [Actions.MODIFY_SELECTED_ACCOUNTS_BY_LIST] : clearUsers
})

export default Users
