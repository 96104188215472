import "./BoundariesSetup.css"
import * as React                                   from "react"
import {
  withLocalize,
  Translate,
  LocalizeContextProps
}                                                   from "react-localize-redux"
import ArrayUtils                                   from "../../../utils/ArrayUtils"
import {
  ProtoMarketingEvent,
  MarketingEventBoundariesTypes as MEBTypes
}                                                   from "../../../model/adbuilder/"
import {MakesRow, MakeModelsRow, ReferenceRow}      from "../../../model/Store/Cleaner/"
import CampaignSelect                               from "./SingleCampaignSelection"
import Vehicle                                      from "../../../model/Store/Vehicle/Vehicle"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Icon,
  Button
}                                                   from "@material-ui/core"
import { memoize }                                  from "lodash"
import Notice                                       from "../../../components/alert/Notice"

interface BoundariesSetupProps extends LocalizeContextProps{
  event              : ProtoMarketingEvent
  setYear            : (year:number|undefined, index:number)=>void
  setMake            : (make:string|undefined, index:number)=>void
  setModel           : (model:string|undefined, index:number)=>void
  setCampaignName    : (index:number, campaignName?:string, campaignGroup?:string)=>void
  makes              : MakesRow[]
  makeModels         : MakeModelsRow[]
  cleanerReference   : ReferenceRow[]
  matchingInventory  : Vehicle[]
  addBoundary        : (type?:MEBTypes) => void
  removeBoundary     : (index:number) => void
}

const BoundariesSetup = (props:BoundariesSetupProps) => {
  const startingYear = 1950
  const maxYear = (new Date()).getFullYear()+1
  const [campaignSpecific, setCampaignSpecific] = React.useState<boolean>(false)
  const [MMYSpecific, setMMYSpecific] = React.useState<boolean>(false)
  const [nonInventory, setNonInventory] = React.useState<boolean>(false)

  React.useEffect(()=>{
    setCampaignSpecific(
      props.event.boundaries[0].campaignName !== undefined
      || props.event.boundaries[0].campaignGroup !== undefined
    )
    setMMYSpecific(
      props.event.boundaries[0].year !== undefined
        || props.event.boundaries[0].make !== undefined
        || props.event.boundaries[0].model !== undefined
    )
    setNonInventory(props.event.boundaries[0].campaignType !== undefined)
  },[props.event])

  const onYearChange = (e, i:number) => {
    let value = e.target.value
    if(e.target.value === "NONE"){value = undefined}
    props.setYear(value !== undefined ? parseInt(value, 10) : undefined, i)
  }

  const onMakeChange = (e, i:number) => {
    let value = e.target.value

    if(e.target.value === "NONE"){
      props.setMake(undefined, i)
    }
    else{
      //The value of make is the displayMake. we have to find the ID
      props.setMake(props.makes.find(x=>x.DisplayMake===value).ID, i)
    }
  }

  const onModelChange = (e, i:number) => {
    let value = e.target.value
    if(e.target.value === "NONE"){value = undefined}
    props.setModel(value, i)
  }

  const onCampaignNameChange = (i:number, name:string, group:string) => {
    let n = name
    let g = group
    if(n === "NONE"){
      n = undefined
      g = undefined
    }
    props.setCampaignName(i,n,g)
  }

  const getSelectedDisplayMake = (index:number):string => {
    if(!props.event.boundaries[index].make){return "NONE"}
    return props.cleanerReference.find(x=>x.ID  == props.event.boundaries[index].make as any).DisplayMake
  }

  const getFilteredModels = memoize((index: number) => {
    if(!props.event.boundaries[index].make){return props.makeModels}
    const selected = props.cleanerReference.find(x=>x.ID  == props.event.boundaries[index].make as any)
    return [
      selected,
      ...props.makeModels.filter(x=>x.DisplayMake === selected.DisplayMake && x.DisplayModel !== selected.DisplayModel)
    ]
  })

  if(campaignSpecific && MMYSpecific){
    throw Error("Event boundaries setup in unexpected way. (Both Campaign and mmy specified)")
  }

  const orderedMakes = props.makes.sort((a,b)=>ArrayUtils.comp(a.DisplayMake, b.DisplayMake))

  return (
    <div className="BoundariesSetup">
      <h5 className="BoundariesSectionTitle">
        <Translate id="adBuilder.createEvent.boundariesTitle"/>
      </h5>
      <Notice 
        text={props.translate("adBuilder.createEvent.noBoundaries") as string} 
        isActive={nonInventory}
      />
      <div className="Boundaries">
        <div>
          {props.event.boundaries.map((boundary, index)=>{
            const orderedModels = getFilteredModels(index).sort((a,b)=>ArrayUtils.comp(a.DisplayMakeModel, b.DisplayMakeModel))
            if (campaignSpecific && index > 0) return
            return <div key={"YMM selects for boundary "+index} className="Selects">
            { index > 0 &&
              <Button className="Button" onClick={()=>props.removeBoundary(index)}>
                X
              </Button>
            }
            <FormControl>
              <InputLabel>
                <Translate id="common.year"/>
              </InputLabel>
              <Select
                id="BoundariesYearSelect"
                className="Input"
                value={boundary.year||"NONE"}
                onChange={(e)=>onYearChange(e, index)}
                disabled={campaignSpecific || nonInventory}
              >
                <MenuItem value="NONE">
                  {props.translate("adBuilder.createEvent.anyYear")}
                </MenuItem>
                {Array(maxYear-startingYear).fill(index).map((_x,i)=>
                  <MenuItem key={i} value={maxYear-i}>
                    {maxYear-i}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>
                <Translate id="common.make"/>
              </InputLabel>
              <Select
                id="BoundariesMakeSelect"
                className="Input"
                value={getSelectedDisplayMake(index)}
                onChange={(e)=>onMakeChange(e, index)}
                disabled={campaignSpecific || nonInventory}
              >
                <MenuItem value="NONE">
                  {props.translate("adBuilder.createEvent.anyMake")}
                </MenuItem>
                {orderedMakes.map(x =>
                  <MenuItem key={x.ID} value={x.DisplayMake}>
                    {x.DisplayMake}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>
                <Translate id="common.model"/>
              </InputLabel>
              <Select
                id="BoundariesModelSelect"
                className="Input"
                value={boundary.model || "NONE"}
                onChange={(e)=>onModelChange(e,index)}
                disabled={campaignSpecific || getSelectedDisplayMake(index) === "NONE" || nonInventory}
              >
                <MenuItem value="NONE">
                  {getSelectedDisplayMake(index) === "NONE"
                    ? props.translate("adBuilder.createEvent.anyModel")
                    : props.translate("adBuilder.createEvent.makeFirst")
                  }
                </MenuItem>
                {orderedModels.map(x=>
                  <MenuItem key={x.ID} value={x.ID}>
                    {x.DisplayMakeModel}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            </div>
          })}
          { !campaignSpecific && !nonInventory &&
            <div onClick={()=>props.addBoundary()} className="Add">
              <p className="Icon"><Icon>add_circle</Icon></p>
              <p className="AddLabel"><Translate id="adBuilder.createEvent.addCondition"/></p>
            </div>
          }
        </div>
        <div className="Or">
          <Translate id="adBuilder.createEvent.Or"/>
        </div>
        <div>
          {props.event.boundaries.map((boundary,index)=>{
            if (MMYSpecific && index > 0) return
            return <div key={"Campaign select for boundary "+index} className="Selects">
              { index > 0 &&
                <Button className="Button" onClick={()=>props.removeBoundary(index)}>
                  X
                </Button>
              }
              <CampaignSelect
                campaignType={boundary.vehicleState ? boundary.vehicleState : boundary.campaignGroup}
                lang={boundary.lang}
                onChange={(name,group)=>onCampaignNameChange(index, name, group)}
                campaignName={props.event.boundaries[index].campaignName}
                disabled={MMYSpecific || nonInventory}
                originalEventId={props.event.id}
                otherCampaignNames={props.event.boundaries.map(x=>x.campaignName).filter(x=>x!==props.event.boundaries[index].campaignName)}
              />
            </div>
          })}
          { !MMYSpecific && !nonInventory &&
            <div onClick={()=>props.addBoundary(MEBTypes.SPECIFIC_CAMPAIGN)} className="Add">
              <p className="Icon"><Icon>add_circle</Icon></p>
              <p className="AddLabel"><Translate id="adBuilder.createEvent.addCampaign"/></p>
            </div>
          }
        </div>
      </div>
        {
          (props.event.boundaries[0].vehicleState && props.matchingInventory.length === 0) &&
            <div className="NoMatches WarningYellow">
              <Typography
                color="inherit"
                className="NoMatches"
              >
                {props.translate('adBuilder.createEvent.errors.noRows')}
              </Typography>
            </div>
        }
    </div>
  )
}

export default withLocalize(BoundariesSetup)
