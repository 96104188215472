import {combineReducers}         from 'redux'
import {GeoTargetings}           from "./GeoTargetings"
import {AccountsFetched}         from "./AccountsFetched"
import {AdWordsPlaces}           from "./AdWordsPlaces"
import {GeocodingCache}          from "./GeocodingCache"
import {GeocodingMetaCache}      from "./GeocodingMetaCache"
import {GeocodingQueue}          from "./GeocodingQueue"
import Retrieving                from "./Retrieving"
import Shape                     from "./shape/Shape"

export const GeoBids = combineReducers({
  GeoTargetings,
  AccountsFetched,
  AdWordsPlaces,
  GeocodingCache,
  GeocodingMetaCache,
  GeocodingQueue,
  Retrieving,
  Shape
})

