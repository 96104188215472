import {combineReducers} from "redux"
import {GeoBids}         from "./geoBid/GeoBids"
import {Keywords}        from "./keyword/Keywords"
import {BudgetsPlans}    from "./budgetsPlan/BudgetsPlans"
import {EngagementRules} from "./engagementRules/EngagementRules"
import {Strategies}      from "./strategy/Strategies"
import {AdCopies}        from "./adCopy/AdCopies"
import {Branding}          from "./branding/Branding"

export const Params = combineReducers({
  AdCopies,
  GeoBids,
  Keywords,
  Strategies,
  BudgetsPlans,
  EngagementRules,
  Branding
})
