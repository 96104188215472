import './NameAndLanguages.css'
import * as React                from 'react'
import {
  Card,
  Typography,
  CardHeader,
  Icon,
  Divider,
  CardContent,
  TextField,
  FormControl,
  Checkbox
}                               from '@material-ui/core'
import {
  Translate,
  TranslateFunction,
  withLocalize
}                               from 'react-localize-redux'
import { AdGroupConfigsByLang } from '../../../model/branding/AdGroupConfigs'
import { newAdGroupsByLang }    from '../../../model/branding/newAdGroup'

interface NameAndLanguagesOwnProps {
  allAdGroupsByLang    : AdGroupConfigsByLang
  newAdGroup           : newAdGroupsByLang
  adGroupName          : string
  selectedLanguages    : string[]
  error                : boolean
  updateName           : (adGroupName:string) => void
  setSelectedLanguages : (event:React.ChangeEvent<HTMLInputElement>) => void
  setAdGroupName       : (name:string) => void
}
interface NameAndLanguagesProps extends NameAndLanguagesOwnProps {
  translate : TranslateFunction
}

const langTranslation = {
  EN : "common.english",
  FR : "common.french",
}

const NameAndLanguages = (props:NameAndLanguagesProps) => {

  function handleAdGroupNameChange(e) {
    props.setAdGroupName(e.target.value)
  }
  function handleLanguageCheckBox(e) {
    props.setSelectedLanguages(e)
  }
  return (
    <Card className="NameAndLanguages">
      <CardHeader
        title={
          <Typography className="TextWithIcon" variant="h6">
            <Icon className="Icon">edit</Icon>
            {props.translate("adGroupCreator.title")}
            </Typography>
          }
        subheader={
          <Typography className="Info" variant="caption">
            {props.translate("adGroupCreator.description")}
          </Typography>
        }
      />
      <Divider />
      <CardContent className="Content">
        <FormControl>
          <Typography className="Instructions TextWithIcon" variant="subtitle2">{props.translate("adGroupCreator.chooseAdGroup")}</Typography>
          <TextField
            error={props.error}
            placeholder="Name"
            style={{minWidth:300}}
            value={props.adGroupName}
            onChange={handleAdGroupNameChange}
          />
          <Typography className="Instructions TextWithIcon" variant="subtitle2">{props.translate("adGroupCreator.chooseLanguage")}</Typography>
          <div style={{display:'flex',alignItems:'center'}}>
            {Object.keys(props.allAdGroupsByLang).map((lang,i)=>{
              return (
                <div key={i}>
                  {props.translate(langTranslation[lang])}
                  <Checkbox
                    value={lang}
                    checked={props.selectedLanguages.find(x=>x===lang)===lang}
                    color="primary"
                    onChange={handleLanguageCheckBox}
                  />
                </div>)
            })}
          </div>
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default withLocalize(NameAndLanguages as any) as React.ComponentType<NameAndLanguagesOwnProps>
