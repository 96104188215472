import TrackingAPI from "../../utils/TrackingAPI"
import Period      from "../../model/Period"
import Actions     from "../../model/constant/actions"
import DateUtils   from "../../utils/DateUtils"
import FVDUtils    from "../../utils/FieldsValuesDefinitionUtils"
import {getToken}  from "../../storeAccessor/Generic"

const getWebLeadSuccess = (response, accountId) => {
  return {
    type      : Actions.RETRIEVE_WEB_LEADS_OF_ACCOUNT,
    accountId : accountId,
    leads     : FVDUtils.decompress(response.data.leads)
  }
}
const getWebLeadError = (response, accountId) => {
  return {
    type      : Actions.RETRIEVE_WEB_LEADS_OF_ACCOUNT,
    accountId : accountId,
    leads     : [],
  }
}

export const loadWebLeads = (accountId:number, period:Period) => (dispatch, getState) => {
  dispatch({
    type : Actions.RETRIEVING_WEB_LEADS_OF_ACCOUNT,
  })
  TrackingAPI.get({
      action    : "GetWebLeads",
      accountId : accountId,
      token     : getToken(getState()),
      dateFrom  : DateUtils.format(period.dateFrom),
      dateTo    : DateUtils.format(period.dateTo),
    },
    (response) => dispatch(getWebLeadSuccess(response, accountId)),
    (error) => dispatch(getWebLeadError(error, accountId))
  )
}
