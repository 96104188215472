import {loadCampaign}        from "../../page/campaign/action/Campaign"
import Actions               from "../../model/constant/actions"
import Friend                from "../../model/Store/Campaign/Friend"
import UserActions           from "../../model/constant/UserAction"
import LogBuilder            from "../../controller/log/LogBuilder"
import {getToken}            from "../../storeAccessor/Generic"
import Headstone             from "../../utils/Headstone"

const removeDesiredGeoBidException = (friend:Friend) => (dispatch, getState) => {
  const token = getToken(getState())
  const accountId = friend.getAccountId()
  const campaignNames = friend.getCampaignNames()

  LogBuilder.log(UserActions.REMOVE_GEO_EXCEPTION,token,{
    accountId     : accountId,
    campaignNames : campaignNames
  })

  Headstone.deleteGeoBidException(token, accountId, campaignNames)
    .then((response) => {
      dispatch({
        type   : Actions.REMOVE_DESIRED_GEOBID_EXCEPTION,
        friend : friend
      })
      for(let id of friend.getIds()){
        dispatch(loadCampaign(id, friend))
      }
    })
    .catch((error) => console.error(error))
}

export default removeDesiredGeoBidException
