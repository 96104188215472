import './CreateProxyParams.css'
import * as React               from 'react'
import {
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Button,
  CardContent,
  Icon,
  Dialog,
}                               from '@material-ui/core'
import MaxSharingSlider         from './MaxSharingSlider'
import { ProxyGroup }           from '../../../../../model/pacy/ProxyGroup'
import BoostConfig              from './BoostConfig'
import {
  BeginningOfMonthBoostConfig
}                               from '../../../../../model/pacy'
import {
  Translate,
  withLocalize,
  TranslateFunction
}                               from 'react-localize-redux'

interface CreateProxyParamsOwnProps {
  maxSharing              : number
  handleSetMaxSharing     : (newValue) => void
  boost                   : BeginningOfMonthBoostConfig
  selectedProxyGroupId    : string
  name                    : string
  handleNameChange        : (event) => void
  proxyGroups             : ProxyGroup[]
  handleChangeProxyGroup  : (event) => void
  active                  : boolean
  handleSetActive         : (e) => void
  handleBoostConfirm      : (boost:BeginningOfMonthBoostConfig) => void
}
interface CreateProxyParamsProps extends CreateProxyParamsOwnProps {
  translate : TranslateFunction
}

const CreateProxyParams = (props:CreateProxyParamsProps) => {
  const [openBoostModal,setOpenBoostModal] = React.useState(false)
  const handleOpenBoostModal = () => setOpenBoostModal(true)
  const handleCloseBoostModal = () => setOpenBoostModal(false)
  const [boost,setBoost] = React.useState<BeginningOfMonthBoostConfig>({...props.boost})
  const handleBoostChange = (event) => {
    const temp = event.target.value/100+1
    setBoost(prevState=>({...prevState,boost:temp}))
  }
  const handleBoostEnable = () => setBoost(prevState=>({...prevState,active:!prevState.active}))
  const handleDurationChange = (event) => {
    const temp=Number(event.target.value)
    setBoost(prevState=>({...prevState,length:temp}))
  }
  const handleShapeChange = (e) => setBoost(prevState=>({...prevState,shape:e.target.value}))
  const updateBoostState = () => {{
    props.handleBoostConfirm(boost)
  }}
  const handleActivateBoost = () => {
    setBoost(prevState=>({...prevState,active:true}))
  }
  const handleDurationChangeBlur = () => {
    if(boost.length<1) {
      setBoost(prev=>({...prev,length:1}))
    }
    if(boost.length>30) {
      setBoost(prev=>({...prev,length:30}))
    }
  }
  const handleBoostChangeBlur = () => {
    if(boost.boost<0) {
      setBoost(prev=>({...prev,boost:0}))
    }
    if(boost.boost>2) {
      setBoost(prev=>({...prev,boost:2}))
    }
  }
  return (
    <div className="CreateProxyParams">
      <CardContent className="CreateProxyContent">
        <div className="ProxyGroupSelection">
          <div className="SelectProxyGroup">
            <Select variant="outlined" fullWidth onChange={props.handleChangeProxyGroup} value={props.selectedProxyGroupId}>
              {props.proxyGroups.map(proxyGroup=>{
                return <MenuItem key={proxyGroup.id} value={proxyGroup.id}>{proxyGroup.name}</MenuItem>
              })}
            </Select>
            <FormHelperText style={{color:props.selectedProxyGroupId ? 'gray' : 'red'}}>
              <Translate id="pacyConfigs.selectProxyGroup" />
            </FormHelperText>
          </div>
        </div>
        <div className="Name">
          <TextField fullWidth label={<Translate id="pacyConfigs.name" />} value={props.name} onChange={props.handleNameChange} variant="outlined" />
        </div>
        <div className="MaxSharing">
          <MaxSharingSlider
            maxSharing={props.maxSharing}
            handleSharingConfirm={(event,newValue)=>props.handleSetMaxSharing(newValue)}
            handleSharingInputConfirm={props.handleSetMaxSharing}
          />
        </div>
        <div className="BoostConfig">
          <div>
            <Icon style={{color:'#FFA500'}}>flash_on</Icon>
              Boost
            <Button style={boost.active ? {color:'green'} : {color:'red'}}disabled color="primary">{boost.active ? props.translate('pacyConfigs.active') : props.translate('common.paused')}</Button>
            <Button variant="outlined" onClick={handleOpenBoostModal} >
              <Translate id="pacyConfigs.configure" />
              <Icon style={{marginLeft:5}}>build</Icon>
            </Button>
          </div>
        </div>
      </CardContent>
      <Dialog open={openBoostModal} onClose={handleCloseBoostModal}>
        <BoostConfig
          boost={boost}
          handleBoostChange={handleBoostChange}
          handleBoostConfirm={updateBoostState}
          handleBoostEnable={handleBoostEnable}
          handleCloseModal={handleCloseBoostModal}
          handleDurationChange={handleDurationChange}
          handleShapeChange={handleShapeChange}
          handleActivateBoost={handleActivateBoost}
          handleDurationBlur={handleDurationChangeBlur}
          handleBoostBlur={handleBoostChangeBlur}
        />
      </Dialog>
    </div>
  )
}

export default withLocalize(CreateProxyParams as any) as React.ComponentType<CreateProxyParamsOwnProps>
