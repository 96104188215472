import Actions                                from "../../model/constant/actions"
import ReportTableTemplate                    from "../../model/Store/Report/ReportTableTemplate"
import ReportTableTemplateUtils               from "../../utils/ReportTableTemplateUtils"
import DragColumn                             from "../../model/report/DragColumn"

export const editTemplate = (template:ReportTableTemplate) => ({
  type     : Actions.EDIT_TEMPLATE,
  template : template
})
export const editColumns = (templateId:string, columns:DragColumn[]) => ({
  type       : Actions.EDIT_TEMPLATE_COLUMNS,
  templateId : templateId,
  columns    : columns
})
export const editName = (templateId:string, name:string) => ({
  type       : Actions.EDIT_TEMPLATE_NAME,
  templateId : templateId,
  name       : name
})
export const editSizePerPage = (templateId:string, size:number) => ({
  type       : Actions.EDIT_TEMPLATE_SIZE_PER_PAGE,
  templateId : templateId,
  size       : size
})
export const editDefaultSortedColumn = (templateId:string, dataField:string) => ({
  type       : Actions.EDIT_TEMPLATE_DEFAULT_SORTED_COLUMN,
  templateId : templateId,
  dataField  : dataField
})
export const editDefaultSortedOrder = (templateId:string, order:string) => ({
  type       : Actions.EDIT_TEMPLATE_DEFAULT_SORTED_ORDER,
  templateId : templateId,
  order      : order
})
export const editDefaultFilter = (templateId:string, dataField:string, filterValue:string) => ({
  type        : Actions.EDIT_TEMPLATE_DEFAULT_FILTER,
  templateId  : templateId,
  dataField   : dataField,
  filterValue : filterValue
})
