import "./MoneyInput.css"
import * as React              from "react"
import NumberFormat            from "react-number-format"
import InputBase,
  {InputBaseProps}             from "@material-ui/core/InputBase"
import NumFormatter            from "../../../utils/NumberFormatter"


interface MoneyInputProps extends InputBaseProps{

}

const MoneyInput = (props:MoneyInputProps) => {
  return (
    <div className="MoneyInput">
      <InputBase
        {...props}
        inputComponent={NumberFormatCustom}
      />
    </div>
  )
}
const NumberFormatCustom = (props) => {
  const {inputRef, onChange, ...other} = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values=>{
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
      thousandSeparator
      prefix="$"
      // format={(x) => NumFormatter.formatNumber(parseFloat(x))} TODO: Messes with input. Question posed on GH
    />
  )
}

export default MoneyInput
