import * as React                      from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Select,
  MenuItem,
  TextField
}                                      from '@material-ui/core'
import {
  BeginningOfMonthBoostConfig,
  BeginningOfMonthShape
}                                      from '../../../../../model/pacy'
import {
  Translate,
  LocalizeContextProps,
  withLocalize
}                                      from 'react-localize-redux'

interface BoostConfigInputsOwnProps extends LocalizeContextProps {
  boost                : BeginningOfMonthBoostConfig
  handleDurationChange : (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleBoostChange    : (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleShapeChange    : (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleBoostEnable    : (e: React.ChangeEvent<HTMLInputElement>) => void
  handleDurationBlur  ?: () => void
  handleBoostBlur     ?: () => void
}
interface BoostConfigInputsProps extends BoostConfigInputsOwnProps {}

const BoostConfigInputs = (props:BoostConfigInputsProps) => {
  return (
    <Table>
      <TableHead>
        <TableRow style={{backgroundColor:'#00B2E3'}}>
          <TableCell className="HeaderCell"><Translate id="pacyConfigs.status" /></TableCell>
          <TableCell className="HeaderCell"><Translate id="pacyConfigs.boostDuration" /></TableCell>
          <TableCell className="HeaderCell"><Translate id="pacyConfigs.boostPercentage"/></TableCell>
          <TableCell className="HeaderCell"><Translate id="pacyConfigs.boostShape"/></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell><Switch checked={props.boost.active} onChange={props.handleBoostEnable}/></TableCell>
          <TableCell>
            <TextField
              disabled={!props.boost.active}
              onBlur={props.handleDurationBlur}
              onChange={props.handleDurationChange}
              type="number"
              value={props.boost.length.toString()} // .toString() eliminates leading zeroes
              inputProps={{
                min:1,
                max:15
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              disabled={!props.boost.active}
              onBlur={props.handleBoostBlur}
              onChange={props.handleBoostChange}
              type="number"
              value={Math.round((props.boost.boost-1)*100).toString()} // .toString() eliminates leading zeroes
              inputProps={{
                min:0,
                max:100
              }}
            />
          </TableCell>
          <TableCell>
            <Select value={props.boost.shape} onChange={props.handleShapeChange} disabled={!props.boost.active}>
              <MenuItem value={BeginningOfMonthShape.FLAT}>{props.translate('pacyConfigs.flat')}</MenuItem>
              <MenuItem value={BeginningOfMonthShape.LINEAR}>{props.translate('pacyConfigs.linear')}</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default withLocalize(BoostConfigInputs)
