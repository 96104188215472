import './AdGroupDisplay.css'
import * as React                 from 'react'
import {
  MenuItem,
  Card,
  FormControl,
  Select,
  FormHelperText,
  Button,
  Icon,
  Modal,
  Slide,
}                                from '@material-ui/core'
import {
  withLocalize,
  TranslateFunction,
  Translate
}                                 from 'react-localize-redux'
import RoutingUtils               from '../../../utils/RoutingUtils'
import { AdGroupConfigsByLang }   from '../../../model/branding/AdGroupConfigs'
import requiresAWSearchAudiences  from '../../../components/loaders/AWSearchAudiencesLoader'
import { withRouter }             from 'react-router'
import AWSearch                   from '../../../model/Store/Audiences/AWSearch'
import { connect }                from 'react-redux'
import { getAccountId }           from '../../../storeAccessor/Generic'
import {
  deleteAdGroup,
  savePositiveKeywords
}                                 from '../../../actions/branding/AdGroupConfigs'
import { AudienceAd }             from '../../../model/branding/AudienceAd'
import {editAudienceAds}          from '../../../actions/branding/AdGroupConfigs'
import AdGroupView                from './AdGroupView/AdGroupView'
import KeywordsInputContainer     from './AdGroupView/KeywordsInputContainer'
import Actions                    from '../../../model/constant/actions'


interface AdGroupDisplayOwnProps {
  adGroupConfigsByLang : AdGroupConfigsByLang
  lang                 : string
  setAdGroup           : (adGroup:string) => void
  setAudienceAd        : (audienceAd :string) => void
  toggleCreationMode   : (state:boolean) => void
  handleChangeLang
}
interface AdGroupDisplayProps extends AdGroupDisplayOwnProps {
  resetFailedPos          : () => void
  failedSavingPosKeywords : boolean
  savingPosKeywords       : boolean
  accountId               : number
  savePositiveKeywords    : (accountId:number,lang:string,keywords:string[],adGroup:string) => void
  editAudienceAds         : (accountId:number,audienceAds:AudienceAd[]) => void
  deleteAdGroup           : (accountId:number,adGroupName:string,lang:string) => void
  translate               : TranslateFunction
  AWAudiences             : AWSearch[]
  history
}

const mapStateToProps = (state,ownProps) => {
  const accountId = getAccountId(state)
  return {
    accountId               : accountId,
    savingPosKeywords       : state.Params.Branding.savingPositiveKeywords,
    failedSavingPosKeywords : state.Params.Branding.failedSavingPositiveKeywords
  }
}
const mapDispatchToProps = dispatch => {
  return {
    deleteAdGroup : (accountId:number,adGroupName:string,lang:string) => {
      dispatch(deleteAdGroup(accountId,adGroupName,lang))
    },
    editAudienceAds :  (accountId:number,audienceAds:AudienceAd[]) => {
      dispatch(editAudienceAds(accountId,audienceAds))
    },
    savePositiveKeywords : (accountId:number,lang:string,keywords:string[],adGroup:string) => {
      dispatch(savePositiveKeywords(accountId,lang,keywords,adGroup))
    },
    resetFailedPos: () => {
      dispatch({ type: Actions.FAILED_SAVING_POSITIVE_KEYWORDS, status: false })
    }
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
  }
}
const AdGroupDisplay = (props:AdGroupDisplayProps) => {
  const [adGroup,setAdGroup] = React.useState(Object.keys(props.adGroupConfigsByLang[props.lang])[0])
  const [openKeywordsInput,setOpenKeywordsInput] = React.useState(false)

  const availableLanguages = Object.keys(props.adGroupConfigsByLang).map(lan => {
    return (
      <MenuItem
        value={lan}
        key={lan}
      >
        {lan === 'EN' ? props.translate('common.english') : props.translate('common.french')}
      </MenuItem>
    )
  })
  let selectedAdGroup = props.adGroupConfigsByLang[props.lang][adGroup]
  if(!selectedAdGroup){
    const first = Object.keys(props.adGroupConfigsByLang[props.lang])[0]
    selectedAdGroup = props.adGroupConfigsByLang[props.lang][first]
  }
  function handleClickEditKeywords(e) {
    e.stopPropagation()
    handleOpenKeywordsInput()
  }
  function handleClickDeleteAdGroup(adGroupName:string) {
    props.deleteAdGroup(props.accountId,adGroupName,props.lang)
  }
  function handleClickEditAudienceAd(adGroupName:string,audienceAdName:string) {
    props.setAdGroup(adGroupName)
    props.setAudienceAd(audienceAdName)
    RoutingUtils.replaceLastElementOfUrl(props.history,'Ads')
  }
  function handleClickDeleteAudienceAd(adGroupName:string,audienceAdId:string){
    const newAudienceAds = props.adGroupConfigsByLang[props.lang][adGroupName].audienceAds.filter(audienceAd=>{
      return audienceAd.audienceId !== audienceAdId
    })
    props.editAudienceAds(props.accountId,newAudienceAds)
  }
  //Modal Handlers
  function handleOpenKeywordsInput() {
    setOpenKeywordsInput(true)
  }
  function handleCloseKeywordsInput() {
    setOpenKeywordsInput(false)
  }
  const savePositiveKeywords = (keywords:string[]) => {
    props.savePositiveKeywords(props.accountId,props.lang,keywords,adGroup)
  }
  return (
    <div className="AdGroups">
      <FormControl className="Languages">
        <Select
          value={props.lang}
          onChange={props.handleChangeLang}
        >
          {availableLanguages}
        </Select>
        <FormHelperText><Translate id="branding.chooseLanguage" /></FormHelperText>
      </FormControl>
      <Card className="AdGroupsCard">
        <div className="Create">
          <Button onClick={()=>{props.toggleCreationMode(true)}} style={{color:'white'}} variant="contained" color="primary" disabled>
            <Icon className="Icon">add</Icon>
            <Translate id="branding.createAdGroup"/>
          </Button>
        </div>
        {Object.keys(props.adGroupConfigsByLang[props.lang]).map((adGroupName,i)=>{
          return (
            <AdGroupView
              AWAudiences={props.AWAudiences}
              adGroupConfigsByLang={props.adGroupConfigsByLang}
              adGroupName={adGroupName}
              handleClickDeleteAdGroup={handleClickDeleteAdGroup}
              handleClickDeleteAudienceAd={handleClickDeleteAudienceAd}
              handleClickEditAudienceAd={handleClickEditAudienceAd}
              handleClickEditKeywords={handleClickEditKeywords}
              index={i}
              key={i}
              lang={props.lang}
              setAdGroup={setAdGroup}
            />
          )
        })}
      </Card>
      <Modal className="KeywordsInputModal" open={openKeywordsInput} onClose={handleCloseKeywordsInput}>
        <Slide direction="down" in={openKeywordsInput} exit>
          <Card className="KeywordsInputContainer">
            <KeywordsInputContainer
              keywords={selectedAdGroup.keywordPatterns}
              saveFunction = {savePositiveKeywords}
              savingPosKeywords={props.savingPosKeywords}
              failedSavingPosKeywords={props.failedSavingPosKeywords}
              resetFailedPos={props.resetFailedPos}
            />
          </Card>
        </Slide>
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps,mapDispatchToProps,mergeProps)(withLocalize(
  requiresAWSearchAudiences(false)(
    withRouter(AdGroupDisplay as any) as any)) as React.ComponentType<AdGroupDisplayOwnProps>)
