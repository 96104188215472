import * as React                            from "react"
import {connect}                             from "react-redux"
import assignProfile                         from "../../../actions/permission/assignPermissionProfile"
import removeProfile                         from "../../../actions/permission/removePermissionProfile"
import Account                               from "../../../model/Store/Account/Account"
import ArrayUtils                            from "../../../utils/ArrayUtils"
import StatusUpdate                          from "../../alert/StatusUpdate"
import PermissionsDatatable                  from "../PermissionsDatatable"
import requiresPermissionProfiles            from "../../loaders/permissionProfilesLoader"
import requiresUserPermissions               from "../../loaders/userPermissionsLoader"
import ProfilesManager                       from "./ProfilesManager"

interface PermissionsManagerOwnProps{
  targetedUser      : any
  targetedAccount   : Account
  profiles          : any[]
}
interface PermissionsManagerProps extends PermissionsManagerOwnProps{
  message         : string
  assignProfile   : (profileCode:string)=>void
  removeProfile   : (profileCode:string)=>void
}
const mapStateToProps = (state,ownProps)=>{
  return {
    message            : state.Permissions.Message,
    permissionProfiles : state.Permissions.Profiles.List.all
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    assignProfile : (accountId:number, userId:number, profileCode:string)=>{
      dispatch(assignProfile(accountId, userId, profileCode))
    },
    removeProfile : (accountId:number, userId:number, profileCode:string)=>{
      dispatch(removeProfile(accountId, userId, profileCode))
    },
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    assignProfile : (profileCode:string)=>{
      DP.assignProfile(
        ownProps.targetedAccount.id,
        ownProps.targetedUser.id,
        profileCode
      )
    },
    removeProfile : (profileCode:string)=>{
      DP.removeProfile(
        ownProps.targetedAccount.id,
        ownProps.targetedUser.id,
        profileCode
      )
    },
  }
}
interface PermissionsManagerState{
  showStatus : boolean
}
class PermissionsManager extends React.Component<PermissionsManagerProps,PermissionsManagerState>{
  constructor(props){
    super(props)
    this.state = {
      showStatus : false
    }
  }
  render(){
    let accountId = null
    if(this.props.targetedAccount !== null){accountId = this.props.targetedAccount.id}
    const userProfiles = this.props.targetedUser.permissionProfiles.reduce((profiles, userProfile)=>{
      if(userProfile.accountId === accountId || userProfile.accountId === null){
        for(let profile of this.props.profiles){
          if(profile.code === userProfile.code){
            profiles.push({
              ...profile,
              accountId : userProfile.accountId
            })
            break
          }
        }
      }
      return profiles
    },[])

    const userExceptions = this.props.targetedUser.permissionExceptions
    .filter((exception)=>exception.accountId === accountId || exception.accountId === null)
    .map(exception=>{
      let eCopy = {
        ...exception,
        sourceProfiles : []
      }
      for(let profile of userProfiles){
        if(ArrayUtils.contain(profile.permissions, eCopy.name)){
          eCopy.sourceProfiles.push(profile.name)
        }
      }
      return eCopy
    })

    const permissions = this.props.targetedUser.permissions
    .filter(p=>
      p.accountId === accountId || p.accountId === null
    )
    .reduce((permissions, permissionGroup)=>{
      return permissions.concat(permissionGroup.permissions.map(p=>({
        ...permissionGroup,
        name : p
      })))
    },[])
    .map(p=>{
      let pCopy = {
        ...p,
        sourceProfiles : []
      }
      for(let profile of userProfiles){
        if(ArrayUtils.contain(profile.permissions, pCopy.name)){
          pCopy.sourceProfiles.push(profile.name)
        }
      }
      return pCopy
    })
    return(
      <div className="PermissionsManager">
        <ProfilesManager
          currentProfiles={userProfiles}
          profiles={this.props.profiles}
          assignProfile={this.props.assignProfile}
          removeProfile={this.props.removeProfile}
        />
        <PermissionsDatatable permissions={permissions} exceptions={userExceptions}/>
        <StatusUpdate text={this.props.message} />
      </div>
    )
  }
}

export default requiresPermissionProfiles(connect(mapStateToProps,mapDispatchToProps,mergeProps)(PermissionsManager))