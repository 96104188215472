import * as React                 from "react"
import {connect}                  from "react-redux"
import {retrieveFeeds}            from "../../actions/feeds/Feeds"
import ExplainedLoading           from "../loading"
import ErrorBoundary              from "../Error/ErrorBoundary"

const mapStateToProps = (state,ownProps) => {
  const accountId = state.Accounts.selected
  return {
    accountId,
    retrieving : state.Feeds.Retrieving,
    feeds      : state.Feeds.List[accountId],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadFeeds : (accountId:number)=>dispatch(retrieveFeeds(accountId)),
  }
}
const mergeProps = (SP,DP,ownProps) => {
  return {
    ...SP,...DP,...ownProps,
    loadFeeds : () => DP.loadFeeds(SP.accountId),
  }
}
const FeedsLoader = props => {
  let loading = false
  if(props.feeds === undefined || props.retrieving){
    loading = true
    if(!props.retrieving){props.loadFeeds()}
    if(!props.letThrough){
      return <ExplainedLoading translateId="loadings.feeds"/>
    }
  }
  return <ErrorBoundary>{props.render(props.feeds, loading)}</ErrorBoundary>
}
const FeedsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(FeedsLoader)

const requiresFeeds = (letThrough?:boolean) => Component => props => (
  <FeedsLoaderConnected
    letThrough={letThrough || false}
    render={ (feeds, loading) => (
      <Component
        feeds={feeds}
        loadingFeeds={loading}
        {...props}
      />
    )}
  />
)

export default requiresFeeds
