import Auth                             from "../../utils/Authentification"
import Actions                          from "../../model/constant/actions"
import {getToken}                       from "../../storeAccessor/Generic"

const getUsersSuccess = (response) => {
  return {
    type  : Actions.RETRIEVE_USERS_LIST,
    users : response.data.users.map(user=>{
      user.permissionProfiles = []
      user.permissionExceptions = []
      user.permissions = []
      return user
    })
  }
}
const getUserSuccess = (response) => {
  let user:any = {...response.data.user}
  return {
    type  : Actions.RETRIEVE_USER_PERMISSIONS,
    user : {
      ...user,
      permissionProfiles : user.permissionProfiles = [],
      permissionExceptions : user.permissionExceptions = [],
      permissions : user.permissions = (
        response.data.permissions
        .filter(permission=>permission.UserID == user.id)
        .map(permission=>({
          accountId     : permission.AccountID === null ? null : parseInt(permission.AccountID),
          id            : parseInt(permission.ID),
          permissions   : permission.Permissions,
          userId        : parseInt(permission.UserID),
        }))
      )
    }
  }
}
const getUserProfilesSuccess = (response, userId) => {
  return {
    type  : Actions.RETRIEVE_USER_PERMISSIONS_PROFILES,
    userId : userId,
    profiles : response.data.profiles
  }
}
export const retrieveUser = (userId:number) => (dispatch,getState) => {
  dispatch({
    type   : Actions.RETRIEVING_USER_PERMISSIONS,
    status : true
  })
  Auth.get({
      token  : getToken(getState()),
      action : "GetUser",
      userID : userId,
      showPermissions : true
    },
    data  => dispatch(getUserSuccess(data)),
    error => console.log(error)
  )
  .then(()=>{
    Auth.get({
      token  : getToken(getState()),
      action : "GetUserProfiles",
      userID : userId,
      showPermissions : true
    },
    data  => dispatch(getUserProfilesSuccess(data, userId)),
    error => console.log(error)
  )})
  .then(()=>{
    dispatch({
      type   : Actions.RETRIEVING_USER_PERMISSIONS,
      status : false
    })
  })
}
const retrieveUsers = () => (dispatch, getState) => {
  dispatch({
    type   : Actions.RETRIEVING_USERS_LIST,
    status : true
  })
  Auth.get({
      token  : getToken(getState()),
      action : "GetUsers",
      showPermissions : false
    },
    data  => dispatch(getUsersSuccess(data)),
    error => console.log(error)
  ).then(()=>{
    dispatch({
      type   : Actions.RETRIEVING_USERS_LIST,
      status : false
    })
  })
}
export default retrieveUsers

