import * as React          from "react"
import {connect}           from "react-redux"
import assignUserLevel     from "../../../actions/permission/assignUserLevel"
import ObjectUtils         from "../../../utils/ObjectUtils"
import { Button }          from "carbon-components-react"
import { NativeSelect }    from "@material-ui/core"
import { Translate }       from "react-localize-redux"

interface UserLevelManagerProps{
  user            : any
  loggedUser      : any
  assignUserLevel : (userLevel:number)=>void
}

const mapStateToProps = (state,ownProps)=>{
  return {
    loggedUser : state.Login.userLogged.profile
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    assignUserLevel : (userId, userLevel:number)=>dispatch(assignUserLevel(userId, userLevel))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    assignUserLevel : (userLevel:number)=>DP.assignUserLevel(ownProps.user.id, userLevel)
  }
}
interface UserLevelManagerState{
  selectedValue ?: string
}
const STAFF_USER_LEVELS = {
  1 : "Root",
  2 : "Admin",
  3 : "Staff",
}
const BASE_USER_LEVELS = {
  4 : "Power User",
  5 : "Editor",
  6 : "Read Only",
}
const generateOptions = (level, name)=>(
  <option
    key={level}
    value={level}
  >
    {name}
  </option>
)

class UserLevelManager extends React.Component<UserLevelManagerProps,UserLevelManagerState>{
  constructor(props){
    super(props)
    this.state = {}
  }
  render(){
    let userLevels:any = BASE_USER_LEVELS
    if(this.props.loggedUser.userLevel === 1){
      //Only root users can assign staff user levels
      userLevels = {
        ...userLevels,
        ...STAFF_USER_LEVELS
      }
    }
    return (
      <div className="ManagerContainer">
        <div className="ManagerControls">
          <NativeSelect
            variant="filled"
            defaultValue={this.props.user.userLevel}
            onChange={(e)=>this.setState({selectedValue:e.target.value})}
          >
            {ObjectUtils.getObjectValues(ObjectUtils.mapOnObject(userLevels, generateOptions))}
          </NativeSelect>
          <Button
            onClick={(e)=>this.props.assignUserLevel(parseInt(this.state.selectedValue))}
            disabled={this.state.selectedValue === undefined}
          >
            <Translate id="common.confirm"></Translate>
          </Button>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps,mergeProps)(UserLevelManager)
