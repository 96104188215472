import './ColumnsExchangeContext.css'
import * as React                    from "react"
import {DropTarget}                  from "react-dnd"
import ItemTypes                     from "../../../../model/constant/ItemTypes"
import DragColumn                    from "../../../../model/report/DragColumn"
import ColumnsContainer              from "./ColumnsContainer"
import ColumnsBank                   from "./ColumnsBank"

interface ColumnsExchangeContextProps{
  bank                   : DragColumn[]
  columns                : DragColumn[]
  addColumn              : (dataField, hoverIndex)=>void
  removeColumn           : (dragIndex)=>void
  originalDefaultFilters : {[dataField:string]:any}
  editedDefaultFilters   : {[dataField:string]:any}
  setDefaultFilter       : (dataField:string, value:string)=>void
  connectDropTarget      : any
  isOver                 : boolean
}
const contextTarget = {}
function collectDrop(connect, monitor){
  return {
    connectDropTarget : connect.dropTarget(),
    isOver : monitor.isOver()
  }
}

class ColumnsExchangeContext extends React.Component<ColumnsExchangeContextProps,any>{
  render(){
    return this.props.connectDropTarget(
      <div className="ColumnsExchangeContext">
        <div className="ColumnsBank">
          <ColumnsBank columns={this.props.bank}/>
        </div>
        <div className="CurrentColumns">
          <ColumnsContainer
            columns={this.props.columns}
            isDragHappening={this.props.isOver}
            addColumn={this.props.addColumn}
            removeColumn={this.props.removeColumn}
            originalDefaultFilters={this.props.originalDefaultFilters}
            editedDefaultFilters={this.props.editedDefaultFilters}
            setDefaultFilter={this.props.setDefaultFilter}
          />
        </div>
      </div>
    )
  }
}

export default DropTarget(ItemTypes.COLUMN, contextTarget, collectDrop)(ColumnsExchangeContext) as any
