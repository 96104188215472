import './ButtonGoBackLarge.css'
import * as React     from "react"
import {Translate}    from "react-localize-redux"

interface ButtonGoBackProps{
  onClick ?: ()=>void
}
const ButtonGoBackLarge = (props:ButtonGoBackProps) => {
  return (
    <button className='GoBackLarge ButtonGoBackLarge' onClick={props.onClick}>
      <span className="GoBackTextLarge"><Translate id="common.goBack"/></span>
    </button>
  )
}

export default ButtonGoBackLarge
