import * as React         from 'react'
import {
  Icon,
  Button,
  Snackbar,
  Tooltip,
  withStyles
}                         from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                         from "react-localize-redux"
import {CopyToClipboard}  from "react-copy-to-clipboard"
import URLUsageModal      from "./urlUsageModal"
import DeleteUrlModal     from "./deleteUrlModal"
import { UrlObject }      from '../../model/feed/UrlObject'
import { FeedParamTypes } from '../../model/feed/Feed'

const CHECKMARK_VISIBLE_DURATION : number = 1100
const CHECKMARK_ENTER_DURATION   : number = 200
const CHECKMARK_EXIT_DURATION    : number = 700

interface SignedURLProps extends LocalizeContextProps {
  url             : UrlObject
  deleteSignedUrl : ()=>void
  loading         : boolean
  type            : FeedParamTypes
}

const LargerTooltip = withStyles({
  tooltip: {
    fontSize: 14
  }
})(Tooltip);

const SignedURL = (props:SignedURLProps) => {
  const [urlModalOpen,       setUrlModalOpen      ] = React.useState<boolean>(false)
  //const [usageModalOpen,     setUsageModalOpen    ] = React.useState<boolean>(false) // Backend not ready
  const [deleteUrlModalOpen, setDeleteUrlModalOpen] = React.useState<boolean>(false)
  const [copied, setCopied] = React.useState<boolean>(false)
  React.useEffect(
    () => {copied && setTimeout(()=>setCopied(false), CHECKMARK_VISIBLE_DURATION)},
    [copied]
  )
  return (
    <>
      {/*
        <URLUsageModal
          open={usageModalOpen}
          onClose={()=>{setUsageModalOpen(false)}}
        />
      */}
      <DeleteUrlModal
        open={deleteUrlModalOpen}
        onClose={()=>{setDeleteUrlModalOpen(false)}}
        loading={props.loading}
        confirm={props.deleteSignedUrl}
      />
      <Snackbar
        anchorOrigin={{vertical:"bottom",horizontal:"right"}}
        open={copied}
        onClose={()=>{setCopied(false)}}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{props.translate("feeds.url-copied-confirm")}</span>}
      />
      <div className='URL'>
        <pre className="ShortURLDescription">
          {props.url.url.slice(props.url.url.lastIndexOf("/")+1)}
        </pre>
        {
          props.url.isLocked ?
            <LargerTooltip title={props.translate('feeds.revokeLocked')}>
              <div>
                <Button disabled color="secondary" variant="outlined" onClick={()=>{setDeleteUrlModalOpen(true)}}>
                  <div className="AlignCenter"><Icon>lock</Icon>&nbsp;{props.translate("feeds.revoke")}</div>
                </Button>
              </div>
            </LargerTooltip>
          :
            <Button color="secondary" variant="outlined" onClick={()=>{setDeleteUrlModalOpen(true)}}>
              <div className="AlignCenter"><Icon>delete_forever</Icon>&nbsp;{props.translate("feeds.revoke")}</div>
            </Button>
        }
        <a href={props.url.url+"?sid="+Math.random()} target="_blank" style={{display:"block"}}>
          <Button color="primary" variant="outlined">
            <div className="AlignCenter"><Icon>cloud_download</Icon>&nbsp;{props.translate("feeds.download")}</div>
          </Button>
        </a>
        {/*
          <Button color="primary" variant="outlined" onClick={()=>{setUsageModalOpen(true)}}>
            <div className="AlignCenter"><Icon>data_usage</Icon>&nbsp;{props.translate("feeds.stats")}</div>
          </Button>
        */}
        <CopyToClipboard text={props.url.url} onCopy={()=>setCopied(true)}>
          <Button color="primary" variant="contained">
            <div className="AlignCenter"><Icon>file_copy</Icon>&nbsp;{props.translate("feeds.copy")}</div>
          </Button>
        </CopyToClipboard>
      </div>
    </>
  )
}

export default withLocalize(SignedURL)
