export default [
  {"stylers": [{"saturation": -35}]},
  {
    "featureType": "administrative.neighborhood",
    "stylers": [{"visibility": "off"}]
  },{
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [{"visibility": "off"}]
  },{
    "featureType": "poi.business",
    "stylers": [{"visibility": "off"}]
  },{
    "featureType": "road",
    "elementType": "labels",
    "stylers": [{"visibility": "off"}]
  },{
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [{"visibility": "off"}]
  },{
    "featureType": "road.arterial",
    "stylers": [{"visibility": "off"}]
  },{
    "featureType": "road.highway",
    "elementType": "labels",
    "stylers": [{"visibility": "off"}]
  },{
    "featureType": "road.local",
    "stylers": [{"visibility": "off"}]
  },{
    "featureType": "transit",
    "stylers": [{"visibility": "off"}]
  },{
    "featureType": "water",
    "elementType": "labels.text",
    "stylers": [{"visibility": "off"}]
  }
]
