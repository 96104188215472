import * as React                     from "react"
import {connect}                      from "react-redux"
import {retrieveNegativeKeywords}     from "../../actions/branding/NegativeKeywords"
import ExplainedLoading               from "../loading"
import ErrorBoundary                  from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  const accountId = state.Accounts.selected
  return {
    accountId,
    negativeKeywords                 : state.Params.Branding.NegativeKeywords[accountId],
    retrievingNegativeKeywords       : state.Params.Branding.retrievingNegativeKeywords,
    savingNegativeKeywords           : state.Params.Branding.savingNegativeKeywords
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    retrieveNegativeKeywords : (accountId:number) => {
      dispatch(retrieveNegativeKeywords(accountId))
    }
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,
    ...DP,
    ...ownProps,
    retrieveNegativeKeywords: ()=>DP.retrieveNegativeKeywords(SP.accountId)
  }
}
const KeywordsLoader = props => {
  if(props.negativeKeywords===undefined && props.retrievingNegativeKeywords === false){
    props.retrieveNegativeKeywords()
    if(props.config.letThrough===false){
      return <ExplainedLoading translateId="loadings.brandingKeywords"/>
    }
  }
  if(props.retrievingNegativeKeywords && props.config.letThrough===false){
    return <ExplainedLoading translateId="loadings.brandingKeywords"/>
  }
  if(props.savingNegativeKeywords && props.config.letThrough===false) {
    return <ExplainedLoading translateId="loadings.saving"/>
  }

  return (
    <ErrorBoundary>{
      props.render(props.negativeKeywords, props.retrievingNegativeKeywords, props.savingNegativeKeywords)
    }</ErrorBoundary>
  )
}
const NegativeKeywordsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(KeywordsLoader)

interface AdGroupConfigLoaderConfig {
  letThrough : boolean
}
interface AdGroupConfigGivenConfig {
  letThrough ?: boolean
}
const baseConfig : AdGroupConfigLoaderConfig = {
  letThrough : false
}

const requiresKeywords = (givenConfig:AdGroupConfigGivenConfig={}) => Component => props => {
  const config : AdGroupConfigLoaderConfig = {...baseConfig, ...givenConfig}
  return <NegativeKeywordsLoaderConnected
    config = {config}
    render = { (negativeKeywords, loading, saving) => <Component negativeKeywords={negativeKeywords} negativeKeywordsLoading={loading} negativeKeywordsSaving={saving} {...props} /> }
  />
}
export default requiresKeywords
