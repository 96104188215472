import * as React       from "react"
import {connect}        from 'react-redux'
import PermissionUtils  from '../../../utils/PermissionsUtils'

export const PermissionContext = (React as any).createContext()

const mapStateToProps = (state) => {
  return {
    account : state.Accounts.selected,
    user    : state.Login.userLogged,
    profile : state.Login.userLogged.profile,
  }
}
const mapDispatchToProps = (dispatch) => { return {} }
const PermissionsContextProvider  = (props) => {
  const permissions = props.user ? props.user.permissions : []
  const account     = props.account ? props.account : null
  return (
    <PermissionContext.Provider value={new PermissionUtils(props.account,permissions,props.profile)}>
      {props.children}
    </PermissionContext.Provider>
  )
}

export default connect(mapStateToProps,mapDispatchToProps)(PermissionsContextProvider)
