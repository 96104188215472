import {AxiosError, AxiosResponse}                       from "axios"
import Pacy                                              from "../../utils/Pacy"
import ActionUtils, {
  extractMessageFromError,
  extractStatusCodeFromError
}                                                        from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import {getToken}                                        from "../../storeAccessor/Generic"
import { AccountModifier }                               from "../../model/pacy/AccountModifiers"
import { PacyAccount }                                   from "../../model/pacy/"
import { State }                                         from "../../reducers/generic/reducerAssembly"


export const dryPutPacyConfigs = (accountId:number,newActions:AccountModifier[]) => (dispatch, getState) => {
  const state:State = getState()
  dispatch({type: Actions.TRYING_NEW_PACY_CONFIGS_ACTION, status: true})
  const token = getToken(state)
  const newState:AccountModifier[] = [...state.EditCopies.PacyConfigs.AccountModifiers,...newActions]
  const accountStateId = state.Pacy.Accounts[accountId].stateId
  ActionUtils.retryOnFailure(
    ()=>Pacy.dryUpdate(token,accountId,accountStateId,newState)
  )
  .then(response=>{
    dispatch({
      type    : Actions.UPDATE_PACY_ACCOUNT,
      account : response.data
    }),
    dispatch({
      type   : Actions.PUSH_NEW_PACY_CONFIGS_ACTION,
      data   : newState
    })
  })
  .catch((error:AxiosError<{error:string}>)=>{
    if(error.isAxiosError){
      if(error.response.status === 409){
        dispatch({type: Actions.FAILED_UPDATING_PACY_ACCOUNT,status:true,message:'CONFLICT_ON_STATE_ID',code:409})
      }
      else {
        dispatch({type: Actions.FAILED_UPDATING_PACY_ACCOUNT,status:true,message:error.response.data.error,code:500})
      }
    }
    else{
      dispatch({type: Actions.FAILED_UPDATING_PACY_ACCOUNT,status:true,message:error.message,code:0})
    }
    console.warn(error)
  })
  .then(()=>{
    dispatch({type: Actions.TRYING_NEW_PACY_CONFIGS_ACTION, status: false})
  })
}
export const savePacyConfigs = (accountId:number, pacyEnable?:boolean) => (dispatch, getState) => {
  const state:State = getState()
  dispatch({type : Actions.SAVING_NEW_PACY_CONFIGS,status:true})
  const token = getToken(state)
  const accountModifiers:AccountModifier[] = state.EditCopies.PacyConfigs.AccountModifiers
  const accountStateId = state.Pacy.Accounts[accountId].stateId
  let request : () => Promise<AxiosResponse<PacyAccount>>
  if(typeof pacyEnable !== 'undefined'){
    request = ()=>Pacy.enableAccount(token, accountId, pacyEnable)
  } else {
    request = ()=>Pacy.savePacyConfigs(token,accountId,accountStateId,accountModifiers)
  }
  ActionUtils.retryOnFailure(
    request
  )
  .then(response=>{
    dispatch({
      type    : Actions.RETRIEVE_PACY_ACCOUNT,
      account : response.data
    })
  })
  .catch((error)=>{
    console.warn(error)
    dispatch({
      type: Actions.FAILED_RETRIEVING_PACY_ACCOUNT,
      status : true,
      statusCode : extractStatusCodeFromError(error),
      message : extractMessageFromError(error)
    })
  })
  .then(()=>{
    dispatch({type: Actions.SAVING_NEW_PACY_CONFIGS,status:false})
    dispatch({type:Actions.RETRIEVE_PACY_DECISIONS_AFTER_SAVING,status:true})
    dispatch({type:Actions.RETRIEVE_PACY_V0USAGE_AFTER_SAVING,status:true})
  })
}

