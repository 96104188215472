import './HintCard.css'
import * as React            from "react"
import {withLocalize}        from "react-localize-redux"
import ArrayUtils            from "../../../../../../../utils/ArrayUtils"
import NumFormatter          from '../../../../../../../utils/NumberFormatter'
import Friend                from "../../../../../../../model/Store/Campaign/Friend"
import InventoryFriend       from "../../../../../../../model/Store/Campaign/InventoryFriend"
import {Loading}             from "carbon-components-react"
import Card                  from "../../../../../../../components/card/Card"

interface HintCardsProps{
  friend     : Friend
  translate ?: (translateId:string)=>string
}

const extractLowestPrice = (vehicles:any[]):number => {
  return Math.min(...vehicles.map(v=>v.getPrice()).filter(p=>p>0))
}
const HintCards = (props:HintCardsProps) => {
  let cards
  if(!(props.friend instanceof InventoryFriend)){return null}
  const campaign = props.friend.getCampaigns()[0]
  const vehicles = props.friend.getInventory()
  if(!vehicles.length){return <Loading withOverlay={false}/>}
  if(ArrayUtils.contain(["SRP_MM","SRP_MMY"], campaign.type)){
    cards = [
      {
        textTranslationId : "campaigns.hint.startingPrice",
        icon    : "monetization_on",
        number  : NumFormatter.formatCurrency(extractLowestPrice(vehicles)),
      },{
        text    : "",
        icon    : "pie_chart_outlined"  ,
        number  : NumFormatter.formatNumber(vehicles.length, 2) + " " + props.translate("campaigns.hint.inInventory"),
      },
    ]
  }
  else if(ArrayUtils.contain(["VDP","CONQUEST"], campaign.type)){
    cards = [
      {
        textTranslationId : "campaigns.hint.price",
        icon    : "monetization_on",
        number  : NumFormatter.formatCurrency(vehicles[0].getPrice()),
      },{
        text    : "",
        icon    : "pie_chart_outlined"  ,
        number  : NumFormatter.formatNumber(vehicles[0].getNumPictures(), 2) + " " + props.translate("campaigns.hint.pictures"),
      },
    ]
  }
  if(!cards){throw Error("InventoryFriend of unknown type.")}
  return(
    <div className="HintCards">
      {cards.map((card,i)=>
        <Card key={i} {...card}/>
      )}
    </div>
  )
}
export default withLocalize(HintCards as any) as any
