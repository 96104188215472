import './PacyConfigs.css'
import * as React                                 from 'react'
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Icon,
  Button,
  Modal,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  IconButton,
  Switch,
  Typography
}                                                 from '@material-ui/core'
import {withRouter, RouteComponentProps}          from "react-router"
import RoutingUtils                               from "../../../../utils/RoutingUtils"
import PacyLoader                                 from "../../../../components/loaders/pacyLoader"
import Loading                                    from "../../../../components/loading/"
import { connect }                                from 'react-redux'
import {
  AccountModifier,
}                                                 from '../../../../model/pacy/AccountModifiers'
import { State }                                  from '../../../../reducers/generic/reducerAssembly'
import { getAccountId }                           from '../../../../storeAccessor/Generic'
import { dryPutPacyConfigs, savePacyConfigs }     from '../../../../actions/pacy/PacyConfigs'
import Footer                                     from './Components/Footer'
import {moveProxySimulation}                      from '../../../../actions/pacy/MoveProxySimulation'
import CreateProxyGroup                           from './Components/CreateProxyGroup'
import PacySelectors                              from "../../../../storeAccessor/Pacy"
import Period                                     from '../../../../model/Period'
import ProxyGroupExpansion                        from './ProxyGroupExpansion'
import { withLocalize, Translate, TranslateFunction }           from 'react-localize-redux'
import Actions                                    from '../../../../model/constant/actions'
import { PacyAccount }                            from '../../../../model/pacy'
import PERMISSIONS                                from '../../../../model/constant/Permissions'
import AccountPermissionBoundary                  from '../../../../components/permissions/AccountPermissionBoundary'
import { AlertType }                              from "../../../../model/constant/AlertType"
import {
  PacyV0UsageAlert,
  getActiveProvidersTreeForV0Alert
}                                                 from "../../../../model/alerting/PacyV0UsageAlert"
import {Modal as ReactModal}                      from "carbon-components-react"


interface PacyConfigsOwnProps {}
interface PacyConfigInternalProps extends PacyConfigsOwnProps {
  v0Alert : PacyV0UsageAlert,
  translate : TranslateFunction
}
interface PacyConfigsLoaderProps {
  requestReportingDataLoad : (period:Period)=>boolean
}
type PacyConfigsProps  = ReturnType<typeof mergeProps> & PacyConfigInternalProps & PacyConfigsLoaderProps & RouteComponentProps

const makeMapStateToProps = () => {
  const separatedStatsSelector = PacySelectors.makeStatsByMonthByProxyGroupSelector()
  return (state: State, ownProps) => {
    const accountId = getAccountId(state)
    return {
      accountId,
      account                     : state.EditCopies.PacyConfigs.Account,
      statsByMonthByProxyGroup    : separatedStatsSelector(state, ownProps.account),
      actualAccount               : state.Pacy.Accounts[accountId],
      possibleAgents              : state.Pacy.PossibleAgents[accountId],
      makingChanges               : state.EditCopies.PacyConfigs.TryingPush,
      dryPutError                 : state.EditCopies.PacyConfigs.FailedPush,
      saving                      : state.EditCopies.PacyConfigs.Saving,
      user                        : state.Login.userLogged,
      v0Alert                     : state.Alerts.Retrieved[AlertType.PACY_V0USAGE] || {},
      pacyEnabled                 : state.Pacy.Accounts[accountId]?.enabled
    }
  }
}
const mapDispatchToProps = dispatch => {
  return {
    savePacyConfigs     : (accountId:number,pacyEnable?:boolean) => dispatch(savePacyConfigs(accountId,pacyEnable)),
    addAccountModifiers : (accountId:number,newActions:AccountModifier[]) => dispatch(dryPutPacyConfigs(accountId,newActions)),
    moveProxySimulation : (accountId:number,newActions:AccountModifier[],moveInvestment:boolean) => dispatch(moveProxySimulation(accountId,newActions,moveInvestment)),
    changeAccount       : (pacyAccount:PacyAccount) => dispatch ({type:Actions.PACY_CONFIGS_ACCOUNT_CHANGE,account:pacyAccount}),
    resetError          : () => dispatch({type:Actions.FAILED_UPDATING_PACY_ACCOUNT,data:{status:false,message:''}})
  }
}
const mergeProps = (SP:ReturnType<ReturnType<typeof makeMapStateToProps>>, DP:ReturnType< typeof mapDispatchToProps>, ownProps:PacyConfigsOwnProps) => {
  return {
    ...SP, ...DP, ...ownProps,
    account             : SP.account,
    addAccountModifiers : (newActions:AccountModifier[])=>{
      DP.addAccountModifiers(SP.accountId, newActions)
    },
    moveProxySimulation : (newActions:AccountModifier[],moveInvestment:boolean) => {
      DP.moveProxySimulation(SP.accountId,newActions,moveInvestment)
    }
  }
}

const errorTranslations = {
  409 : 'pacyConfigs.accountStateIdConflict',
  500 : 'pacyConfigs.generalError',
  0   : "pacyConfigs.generalError"
}

const PacyConfigs = (props: PacyConfigsProps) => {
  const [openCreateProxyGroupModal,setOpenCreateProxyGroupModal] = React.useState(false)
  const [pacyEnabled,setPacyEnabled] = React.useState(props.pacyEnabled)
  const [openDisablePacyModalConfirmation,setOpenDisablePacyModalConfirmation] = React.useState(false)

  React.useEffect(()=>{
    props.changeAccount(props.actualAccount)
  },[props.accountId])

  if(props.saving){
    return <Loading translateId="loadings.saving"/>
  }
  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if(reason !== 'clickaway'){props.resetError()}
  }

  const handleTogglePacy = (event) => {
    if(event.target.checked){
      setPacyEnabled(true)
      props.savePacyConfigs(props.accountId, true)
    } else {
      setPacyEnabled(false)
      setOpenDisablePacyModalConfirmation(true)
    }
  }
  const handleCancelDisablePacy = () => {
    setPacyEnabled(props.pacyEnabled)
    setOpenDisablePacyModalConfirmation(false)
  }
  const handleConfirmDisablePacy = () => {
    setOpenDisablePacyModalConfirmation(false)
    props.savePacyConfigs(props.accountId, pacyEnabled)
  }

  const handleOpenCreateProxyGroup = () => setOpenCreateProxyGroupModal(true)
  const handleCloseCreateProxyGroup = () => setOpenCreateProxyGroupModal(false)
  const errorMsg = errorTranslations[props.dryPutError.code]
  const activeProvidersAlertTree = getActiveProvidersTreeForV0Alert(props.v0Alert, props.account.proxyGroups)

  return (
    <AccountPermissionBoundary
      permissions={[PERMISSIONS.Pacy_Edit_Config]}
    >
      <div className="PacyConfigsContainer">
      {props.account.enabled ?
        <Card className="PacyConfigs">
          <CardHeader
            title={
              <div className="PacyConfigsHeader">
                <h6><Translate id="pacyConfigs.advancedConfig" /></h6>
                <div className="HeaderButtons">
                  <Button onClick={handleOpenCreateProxyGroup} size="large" color="primary" variant="outlined" className="Button">
                    <Icon style={{marginRight:5}}>add</Icon>
                    <Translate id="pacyConfigs.budgetGroup" />
                  </Button>
                  <Button
                    onClick={()=>{
                      RoutingUtils.replaceLastElementOfUrl(props.history,"Planner")
                    }}
                    size="large"
                    color="primary"
                    variant="text"
                    ><Translate id="common.goBack"/>
                  </Button>
                </div>
              </div>
              }
          />
          <Divider />
          <CardContent>
            <div className="AddProxyGroupButton">
            </div>
            {props.account.proxyGroups.map(proxyGroup =>
              <ProxyGroupExpansion
                key={proxyGroup.id}
                proxyGroup={proxyGroup}
                proxyGroups={props.account.proxyGroups}
                moveProxySimulation={props.moveProxySimulation}
                addAccountModifiers={props.addAccountModifiers}
                requestReportingDataLoad={props.requestReportingDataLoad}
                statsByMonthByProxyGroup={props.statsByMonthByProxyGroup}
                possibleAgents={props.possibleAgents}
                activeProvidersAlertTree={activeProvidersAlertTree}
              />
            )}
          </CardContent>
          <Footer
            reset={()=>props.changeAccount(props.actualAccount)}
            proxyGroups={props.account.proxyGroups}
            possibleAgents={props.possibleAgents}
            addAccountModifiers={props.addAccountModifiers}
            accountId={props.accountId}
            savePacyConfigs={()=>props.savePacyConfigs(props.accountId)}
          />
        </Card>
      :
        <div className="PacyMessage">
          <p style={{fontSize: 'inherit'}}><Translate id="pacyPlanner.deactivated.generalMessage" /></p>
          <br />
        </div>
      }
        <div className="ToggleContainer">
          <Typography className="ToggleSwitch" variant="button">
            <Switch color="primary" size="medium" checked={pacyEnabled} onChange={handleTogglePacy}/>
            <Translate id="pacyConfigs.pacyEnable.toggleButton" />
          </Typography>
        </div>
        <Modal className="CreateProxyGroupModal" open={openCreateProxyGroupModal} onClose={handleCloseCreateProxyGroup}>
          <div>
            <CreateProxyGroup
              accountId={props.accountId}
              closeModal={handleCloseCreateProxyGroup}
              addAccountModifiers={props.addAccountModifiers}
            />
          </div>
        </Modal>
        <ReactModal
          open={openDisablePacyModalConfirmation}
          modalHeading={props.translate("pacyConfigs.pacyEnable.modal.title")}
          primaryButtonText={props.translate("common.confirm")}
          secondaryButtonText={props.translate("common.cancel")}
          onRequestSubmit={handleConfirmDisablePacy}
          onRequestClose={handleCancelDisablePacy}
          onSecondarySubmit={handleCancelDisablePacy}
        >
          <div>
            <br />
            <p>{props.translate("pacyConfigs.pacyEnable.modal.message")}</p>
            <br />
            <p>{props.translate("pacyConfigs.pacyEnable.modal.instructions")}</p>
          </div>
        </ReactModal>
        {props.makingChanges &&
          <div onClick={(event)=>event.stopPropagation()} className="PacyConfigLoader">
            <CircularProgress thickness={5} size="8rem"/>
          </div>
        }
        <Snackbar
          autoHideDuration={5000}
          color="secondary"
          open={props.dryPutError.status}
          onClose={handleCloseSnackBar}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        >
          <SnackbarContent
            className="ErrorSnackBar"
            message={
              <span className="Error" >
                <Icon className="Icon">error</Icon>
                <span className="Message">
                  {errorMsg && <Translate id={errorMsg} />}
                  <br/>
                  {props.dryPutError.code !== 409 && props.dryPutError.message}
                </span>
              </span>
            }
            action={
              <IconButton onClick={handleCloseSnackBar}>
                <Icon style={{color:'white'}}>close</Icon>
              </IconButton>
            }
          />
        </Snackbar>
      </div>
    </AccountPermissionBoundary>
  )
}

export default (
  withLocalize(
    PacyLoader({needStats:true, needPossibleAgents:true})(
      connect(makeMapStateToProps, mapDispatchToProps, mergeProps)(
        withRouter(
          PacyConfigs
        )
      )
    )
  )
) as React.ComponentType<PacyConfigsOwnProps>

