import * as React         from 'react'
import {
  Chip,
  Tooltip,
  Icon,
}                         from "@material-ui/core"
import {
  withLocalize,
  LocalizeContextProps
}                         from "react-localize-redux"
import {
  FacebookV1FeedParams,
}                         from "../../../model/feed/Feed"
import FeedSummaryProps   from "./props"
import LangChip           from "./LangChip"
import InactiveChip       from "./InactiveChip"

interface FeedSumaryGeofencingPvV1Props extends LocalizeContextProps, FeedSummaryProps<FacebookV1FeedParams>{}

const FeedSummaryFacebookV1 = (props:FeedSumaryGeofencingPvV1Props) => {
  const deletionLocked = props.urls.some( url => url.isLocked )

  return (
    <div className='FeedSummary'>
      <Tooltip placement="top" title={<p>{props.translate("feeds.tooltip.GEOFENCING_PV")}</p>}>
        <img className="GEOFENCINGPVV1" src="./assets/img/geo_car.svg"/>
      </Tooltip>
      <Tooltip placement="top" title={<p>{props.translate("feeds.tooltip.vehicleType")}</p>}>
        <Chip color="primary" variant="outlined" label={props.translate("feeds.vehicleType."+props.config.params.vehicleType)}/>
      </Tooltip>
      <LangChip lang={props.config.params.lang} inventoryLang={props.config.params.inventoryLang}/>
      <InactiveChip active={props.urls.length!==0}/>
      {
        deletionLocked &&
          <Tooltip placement="top" title={<p>{props.translate('feeds.deleteLocked')}</p>}>
            <div className="AlignCenter">
              <Icon style={{color: "gray"}}>lock</Icon>
            </div>
          </Tooltip>
      }
    </div>
  )
}
export default withLocalize(FeedSummaryFacebookV1)
