import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    tag : {
      fontWeight: "bold",
      fontStyle: "italic",
      background: "#F2F2F2 0% 0% no-repeat padding-box",
      border: "3px solid #D8D6D6",
      borderRadius : 4,
      opacity: 1,
      margin : 5,
      padding : "0px 5px 0px 5px",
      cursor : 'move',
    },
    dragging : {
      color: "white",
      borderRadius: 10,
    }
  })
)

export default useStyles
