import * as React                    from "react"
import {connect}                     from "react-redux"
import {retrieveUser}                from "../../actions/user/users"
import ExplainedLoading              from "../loading"
import ErrorBoundary                 from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  return {
    users                 : state.Users.List.all,
    retrievingUsers       : state.Users.Retrieving.RetrievingUsersList,
    targetedUser          : state.Users.TargetUser,
    retrievingPermissions : state.Users.Retrieving.RetrievingUserPermissions
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadUser : (userId:number)=>dispatch(retrieveUser(userId))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
const UserPermissionsLoader = props => {
  if ((!props.retrievingUsers && props.targetedUser && props.targetedUser !== "" && props.users.find(x=>x.id===props.targetedUser).permissions.length===0) || props.retrievingPermissions){
    if (!props.retrievingPermissions){
      props.loadUser(props.targetedUser)
    }
    return <ExplainedLoading translateId="loadings.userPermissions"/>
  }
  return <ErrorBoundary>{props.render(props.users)}</ErrorBoundary>
}
const UserPermissionsLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(UserPermissionsLoader)
const requiresUserPermissions = Component => props => (
  <UserPermissionsLoaderConnected
    render={ users => <Component users={users} {...props} /> }
  />
)
export default requiresUserPermissions