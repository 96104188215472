import PerformanceData     from './PerformanceData'
import Retrieving          from './Retrieving'
import IsFetched           from './IsFetched'
import AccountsWithoutWaze from './AccountsWithoutWaze'
import Companies           from './Companies'
import {combineReducers}   from "redux"


export const Waze = combineReducers({
  PerformanceData,
  Retrieving,
  IsFetched,
  AccountsWithoutWaze,
  Companies,
})
