import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    title : {
      fontFamily : "Roboto",
      fontSize : 26,
      fontWeight : "bold",
      padding : 30,
    },
    label : {
      fontFamily : "Roboto",
      fontSize : 16,
      padding: "0px 0px 10px 35px",
    },
    selects : {
      fontFamily : "Roboto",
      width : 306,
      padding : 5,
      marginBottom : 15,
    },
    container : {
      backgroundColor : "#FAFAFA",
      display : "grid",
      width : 365,
      height : 350,
    },
    button : {
      fontFamily : "Roboto",
      backgroundColor : "#707070",
      color : "#FFFFFF",
      margin : 10,
      width : 90,
      height : 30,
      justifySelf : "center",
      '&:hover':{
        backgroundColor: "#c6c6c6",
      }
    },
    formControl : {
      justifySelf : "center",
    }
  })
)

export default useStyles
