import "./ProviderIcon.css"
import React                     from 'react'
import {AgentCompatibleProvider} from "../../../../../../model/pacy/"

interface ProviderIconOwnProps {
  provider : AgentCompatibleProvider
}

const providerToLogoPath:{[k in AgentCompatibleProvider]:string} = {
  [AgentCompatibleProvider.AW]       : "./assets/img/google_logo.svg",
  [AgentCompatibleProvider.BING]     : "./assets/img/bing.svg",
  [AgentCompatibleProvider.FACEBOOK] : "./assets/img/facebook_logo.svg",
}

const ProviderIcon = (props:ProviderIconOwnProps) => {
  return (
    <img style={{width: "30px"}} src={providerToLogoPath[props.provider]}/>
  )
}

export default ProviderIcon
