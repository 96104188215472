import Actions             from "../model/constant/actions"
import ReducerUtils        from "../utils/ReducerUtils"
import DateUtils           from "../utils/DateUtils"
import QueryStringUtils    from "../utils/QueryStringUtils"
import Period              from "../model/Period"

const changePeriod = (state, action) => {
  const period = {
    dateFrom : new Date(action.dateFrom),
    dateTo   : new Date(action.dateTo)
  }
  QueryStringUtils.setPeriod(period)
  return period
}
const PeriodReducer = ReducerUtils.createReducer<Period>(DateUtils.getLast30Day(),{
  [Actions.CHANGE_SELECTED_PERIOD] : changePeriod
})

export default PeriodReducer
