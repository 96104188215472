import './ProxiesAmountsDisplay.css'
import * as React                         from 'react'
import {
  Card,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
}                                         from '@material-ui/core'
import {
  getGuidelineForDay,
  isVagueGuideline,
  isPreciseGuideline
}                                         from '../../../../../model/pacy/Guideline'
import moment, {Moment}                   from "moment"
import { MoveProxySimulationAccount }     from '../../../../../model/pacy/AccountModifiers'
import requiresProxyMoveAccountSimulation from '../../../../../components/loaders/moveProxySimulationLoader'
import MathUtils                          from '../../../../../utils/MathUtils'

interface ProxiesAmountsDisplayOwnProps {
  type          : 'movingInvestment' | 'notMovingInvestment'
  proxyGroupId  : string
  proxyToMoveId : string
}
interface ProxiesAmountsDisplayProps extends ProxiesAmountsDisplayOwnProps {
  moveProxySimulationAccount : MoveProxySimulationAccount
}

const ProxiesAmountsDisplay = (props:ProxiesAmountsDisplayProps) => {
  const proxyGroup = props.moveProxySimulationAccount[props.type].proxyGroups.find(proxyGroup=>proxyGroup.id === props.proxyGroupId)
  const date = moment().format('YYYY-MM-DD')
  const lastGuideline = getGuidelineForDay(proxyGroup.guidelines,date)
  const totalWeight = proxyGroup.proxies.reduce((acc,curr)=>{
    return acc + curr.weight
  },0)
  const proxiesAmount =
    isPreciseGuideline(lastGuideline) ? lastGuideline.amounts
    : isVagueGuideline(lastGuideline) ? proxyGroup.proxies.reduce((acc,curr)=>{
      return {...acc,[curr.id] : lastGuideline.amount * (curr.weight/totalWeight)}
    },{})
    : null
  const total = Object.values(proxiesAmount).reduce((acc,amount)=>acc+amount,0)
  return (
    <Card className="ProxiesAmountsDisplay">
      <Table stickyHeader className="Table">
        <TableHead className="TableHeader">
          <TableRow>
            <TableCell className="HeaderCell">
              {proxyGroup.name}
            </TableCell>
            <TableCell className="HeaderCell">
              {MathUtils.round(total,2)} $
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {proxyGroup.proxies.length !==0
            ? proxyGroup.proxies.map(proxy=>{
              return (
                <TableRow key={proxy.id}>
                  <TableCell style={proxy.id === props.proxyToMoveId ? {color:'green',fontWeight:'bold'} : null }>{proxy.name}</TableCell>
                  <TableCell style={proxy.id === props.proxyToMoveId ? {color:'green',fontWeight:'bold'} : null }>{MathUtils.round(proxiesAmount[proxy.id],2) || 0} $</TableCell>
                </TableRow>
              )
          })
            :<TableRow>
              <TableCell>Empty</TableCell>
              <TableCell></TableCell>
            </TableRow>

          }
        </TableBody>
      </Table>
    </Card>
  )
}

export default requiresProxyMoveAccountSimulation(ProxiesAmountsDisplay) as React.ComponentType<ProxiesAmountsDisplayOwnProps>
