import {createSelector}                 from "reselect"
import * as Generic                     from "./Generic"
import Account from "../model/Store/Account/Account"

export default class TemplatesAccessors{
  public static makeSelectedAccountSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getAccounts,
      (accountId:number, accounts:Account[])=>{
        if(!accountId){return undefined}
        return accounts.find(x=>x.id === accountId)
      }
    )
  }
  public static makeAllAccountIdsSelector(){
    return createSelector(
      Generic.getAccounts,
      accounts=>accounts.map(account=>account.id)
    )
  }
  public static makeSelectedAccountAWIdSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getAccounts,
      (accountId, accountsList)=>{
        if(!accountId){return undefined}
        return accountsList.find(x=>x.id === accountId).AWCustomerId
      }
    )
  }
}
