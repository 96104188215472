import Headstone                                         from "../../utils/Headstone"
import DateUtils                                         from "../../utils/DateUtils"
import Actions                                           from "../../model/constant/actions"
import {
  MarketingEvent,
  MarketingEventBoundariesTypes,
  SavedMarketingEvent,
  NonInventoryMarketingApproach
}                                                        from "../../model/adbuilder"
import UserActions                                       from "../../model/constant/UserAction"
import {getToken}                                        from "../../storeAccessor/Generic"
import LogBuilder                                        from "../../controller/log/LogBuilder"

export const retrieveMarketingEvents = (accountId:number) => (dispatch, getState) => {
  const token = getToken(getState())
  dispatch({
    type : Actions.RETRIEVING_MARKETING_EVENTS,
    status : true
  })

  Headstone.getMarketingEvents(token,accountId)
  .then((response)=>{
    const events = response.data.map(x=>{
      const languages = [...new Set(x.rsaApproaches.map(approach=> approach.lang))]
      return {
        ...x,
        startDate:new Date(x.startDate.date),
        endDate:new Date(x.endDate.date),
        lastModifiedOn: new Date(x.lastModifiedOn.date),
        rsaApproaches : x.rsaApproaches.map(approach=>{
          if(approach.audienceAWId!=='NO_AUDIENCE'){
            return {...approach,audienceAWId: approach.audienceAWId}
          }
          return approach
        }),
        lang: languages.length === 1 ? languages[0] : "ALL"
      }
    })
    dispatch({
      type : Actions.RETRIEVE_MARKETING_EVENTS,
      accountId,
      events
    })
  })
  .catch((error)=>{
    console.log(error)
  })
  .then(()=>{
    dispatch({
      type : Actions.RETRIEVING_MARKETING_EVENTS,
      status : false
    })
  })
}

const extractEventInfoForCreate = (event:MarketingEvent, isDuplicate:boolean = false): SavedMarketingEvent => {
  const approaches = event.rsaApproaches.map(approach=>{
    // Ran into issue where backend returns null values, but doesn't accept null on save.
    const campaignName = (approach as NonInventoryMarketingApproach).campaignName
    const adGroupName = (approach as NonInventoryMarketingApproach).adGroupName

    const copy = {
      ...approach,
      campaignName : campaignName == null ? "" : campaignName,
      adGroupName : adGroupName == null ? "" : adGroupName
    }
    
    // This code was implemented to make sure that we don't have headlines or descriptions
    // that are empty strings. The initial model was changed along the way to add one more of
    // each and Headstone doesn't support empty strings. With this algorithm it should be easy
    // to scale in the future.
    // -Christopher Sarao
    for (let i=0;i<copy.patterns.length;i++){
      if ("id" in copy.patterns[i]) {
        delete copy.patterns[i].id
      }
      // Deleting temporary frontend ids in patterns
      copy.patterns[i].headlines.forEach(headline=>{
        delete headline.id
      })
      copy.patterns[i].descriptions.forEach(description=>{
        delete description.id
      })
      for (let f=copy.patterns[i].headlines.length-1;f>=0;f--){
        if (copy.patterns[i].headlines[f].text === "") {
          copy.patterns[i].headlines = copy.patterns[i].headlines.slice(0,f)
        } else {
          break
        }
      }
      for (let f=copy.patterns[i].descriptions.length-1;f>=0;f--){
        if (copy.patterns[i].descriptions[f].text === "") {
          copy.patterns[i].descriptions = copy.patterns[i].descriptions.slice(0,f)
        } else {
          break
        }
      }
    }
    return copy
  })
  // For some obscure reason, when the post request was sent to Headstone,
  // the cleaner ids were converted to strings, which aren't supported.
  // -CS
  event.boundaries.forEach(boundary=>{
    if (boundary.type === MarketingEventBoundariesTypes.SPECIFIC_M
      || boundary.type === MarketingEventBoundariesTypes.SPECIFIC_MM
      || boundary.type === MarketingEventBoundariesTypes.SPECIFIC_MMY
      || boundary.type === MarketingEventBoundariesTypes.SPECIFIC_MY
    ) {
      boundary.cleanerId = parseInt(boundary.cleanerId+"",10)
    }
  })
  // We ran into an issue when migrating from Keystone to Headstone where
  // the id of the event to copy was being sent in the payload. We avoid
  // the problem by removing it if it exist
  const eventCopy = {...event}
  if ("id" in eventCopy) {
    delete eventCopy.id
  }
  if(isDuplicate) {
    eventCopy.isDuplicate = true
  } else if ("isDuplicate" in eventCopy) {
    delete eventCopy.isDuplicate
  }
  // With Keystone, the provider key wasn't required, since everything came from
  // AdWords. Now that we are looking at bing and facebook support, it made sense
  // to require it. Since we are still in transition at the moment, it is hardcoded
  // to be "AW" (AdWords) for now
  // -CS
  return {
    ...eventCopy,
    rsaApproaches : approaches,
    boundaries : eventCopy.boundaries.map(boundary=>{
      return {
        ...boundary,
        provider : "AW"
      }
    })
  }
}

export const createMarketingEvent = (accountId:number, event:MarketingEvent, urlOnSuccess?:string) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.CREATE_MARKETING_EVENT, token, {accountId, event})
  dispatch({
    type: Actions.SAVING_MARKETING_EVENT_ELEMENT,
    saving : true,
    urlOnSuccess,
  })

  Headstone.createMarketingEvent(
      token,
      accountId,
      extractEventInfoForCreate(event)
  )
  .then((response)=>{
    dispatch(retrieveMarketingEvents(accountId))
    dispatch({type: Actions.CLEAR_MARKETING_EVENT_CREATE_COPY}) //On save success, remove the creation copy
  })
  .catch((error)=>{
    console.error(error)
    dispatch({
      type    : Actions.FAILED_SAVING_MARKETING_EVENT_ELEMENT,
      message : error
    })
  })
  .then(()=>{
    dispatch({
      type   : Actions.SAVING_MARKETING_EVENT_ELEMENT,
      saving : false
    })
  })
}

/* Editing a marketing event is actually creating a new event
 * First we save the edited copy as a new event
 * If the save is successful, we delete the previous event
 * We then retrieve all the marketing events to update the store
 */
export const editMarketingEvent = (accountId:number, event:MarketingEvent,  urlOnSuccess?:string) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.EDIT_MARKETING_EVENT, token, {accountId, event})
  dispatch({
    type: Actions.SAVING_MARKETING_EVENT_ELEMENT,
    saving : true,
    urlOnSuccess,
  })
  Headstone.createMarketingEvent(
      token,
      accountId,
      extractEventInfoForCreate(event)
  )
  .then((response)=>{
    Headstone.deleteMarketingEvent(token,accountId,event.id)
    .then((response)=>{
      dispatch({
        type : Actions.DELETE_SPECIFIC_MARKETING_EVENT,
        accountId,
        id : event.id
      })
    })
    .catch((error)=>{
      //on error, let the first promise handle it
      throw error
    })
    .then(()=>{
      dispatch({type: Actions.CLEAR_MARKETING_EVENT_CREATE_COPY}) //On save success, remove the creation copy
      dispatch(retrieveMarketingEvents(accountId))
      dispatch({
        type   : Actions.SAVING_MARKETING_EVENT_ELEMENT,
        saving : false
      })
    })
  })
  .catch((error)=>{
    console.error(error)
    dispatch({
      type    : Actions.FAILED_SAVING_MARKETING_EVENT_ELEMENT,
      message : error
    })
  })
  .then(()=>{
    dispatch({
      type: Actions.SAVING_MARKETING_EVENT_ELEMENT,
      saving : false
    })
  })
}

export const deleteMarketingEvent = (accountId:number, eventId:number) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.DELETE_MARKETING_EVENT, token, {accountId, eventId})
  dispatch({
    type     : Actions.DELETING_MARKETING_EVENT_ELEMENT,
    deleting : true
  })
  Headstone.deleteMarketingEvent(token,accountId,eventId)
    .then((response)=>{
      console.warn(response)
      dispatch({
        type : Actions.DELETE_SPECIFIC_MARKETING_EVENT,
        accountId,
        id : eventId
      })
      dispatch(retrieveMarketingEvents(accountId))
    })
    .catch((error)=>console.error(error))
    .then(()=>{
      dispatch({
        type     : Actions.DELETING_MARKETING_EVENT_ELEMENT,
        deleting : false
      })
    })
}

export const updateMarketingEventStatus = (accountId:number, event:MarketingEvent) => (dispatch, getState) => {
  const token = getToken(getState())
  LogBuilder.log(UserActions.EDIT_MARKETING_EVENT, token, {accountId, event})

  dispatch({
    type: Actions.SAVING_MARKETING_EVENT_ELEMENT,
    saving : true,
  })

  Headstone.updateMarketingEventStatus(
    token,
    accountId,
    event.id,
    !event.active
  )
  .then((response)=>{
    dispatch(retrieveMarketingEvents(accountId))
  })
  .catch((error)=>{
    console.error(error)
    dispatch({
      type    : Actions.FAILED_SAVING_MARKETING_EVENT_ELEMENT,
      message : error
    })
  })
  .then(()=>{
    dispatch({
      type   : Actions.SAVING_MARKETING_EVENT_ELEMENT,
      saving : false
    })
  })
}

export const copyMarketingEvent = (accountId:number, event:MarketingEvent) => (dispatch, getState) => {
  /*
    Removing all ids from the copied event
  */
  const eventCopy = {
    ...event,
    title : event.title + " (1)"
  }
  delete eventCopy.id
  eventCopy.rsaApproaches.forEach(approach=>{
    delete approach.id
    approach.patterns.forEach(pattern=>{
      delete pattern.id
    })
  })

  const token = getToken(getState())
  LogBuilder.log(UserActions.CREATE_MARKETING_EVENT, token, {accountId, eventCopy})
  dispatch({
    type: Actions.SAVING_MARKETING_EVENT_ELEMENT,
    saving : true
  })

  Headstone.createMarketingEvent(
      token,
      accountId,
      extractEventInfoForCreate(eventCopy, true)
  )
  .then((response)=>{
    dispatch(retrieveMarketingEvents(accountId))
    dispatch({type: Actions.CLEAR_MARKETING_EVENT_CREATE_COPY}) //On save success, remove the creation copy
    dispatch({
      type: Actions.SET_MARKETING_EVENT_LATEST_COPY_ID,
      id : response.data
    })
  })
  .catch((error)=>{
    console.error(error)
    dispatch({
      type    : Actions.FAILED_SAVING_MARKETING_EVENT_ELEMENT,
      message : error
    })
  })
  .then(()=>{
    dispatch({
      type   : Actions.SAVING_MARKETING_EVENT_ELEMENT,
      saving : false
    })
  })
}
