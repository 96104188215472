import "./LengthLimitedTextInput.css"
import * as React                              from "react"
import {Translate, TranslatePlaceholderData}   from "react-localize-redux"
import NumFormatter                            from "../../utils/NumberFormatter"
import {
   TextField,
   InputAdornment,
   FormControl
}                                              from "@material-ui/core"

export interface LengthLimitedTextInputProps{
  label             : string
  lengthLimit       : number
  defaultValue     ?: string
  softLimit        ?: boolean
  customError      ?: (text:string)=>ErrorData[]
  onChange         ?: (text:string)=>void
  onBlur           ?: (text:string)=>void
  onError          ?: (errors:string[])=>void
  lengthCalculator ?: (text:string)=>number
  width            ?: string
  rows             ?: number
  errorColor       ?: string
  showErrorText    ?: boolean
  minimumLength    ?: number
}
export interface ErrorData{
  text  : string
  id    : string
  data ?: TranslatePlaceholderData
}
interface LengthLimitedTextInputState{
  text : string
}
export const defaultErrors = {
  OVERLIMIT : 'OVERLIMIT',
  UNDERLIMIT : 'UNDERLIMIT'
}

class LengthLimitedTextInput extends React.Component<LengthLimitedTextInputProps,LengthLimitedTextInputState>{
  static defaultProps = {
    onChange         : ()=>{},
    lengthCalculator : (text:string)=>text.length
  }
  constructor(props:LengthLimitedTextInputProps){
    super(props)
    this.state = {
      text : this.props.defaultValue || ""
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.renderInput = this.renderInput.bind(this)
  }
  componentDidUpdate(prevProps:LengthLimitedTextInputProps, prevState:LengthLimitedTextInputState){
    if(prevProps.defaultValue !== this.props.defaultValue && this.state.text !== this.props.defaultValue){
      this.setState({text: this.props.defaultValue || ""}) //Removing this will break adbuilder event creation (Checkboxes)
    }
  }

  handleChange(e){
    const value = e.target.value
    if(this.props.onChange){this.props.onChange(value)}
    if(this.props.softLimit || this.props.lengthCalculator(value) <= this.props.lengthLimit){
      this.setState({
        text : value
      })
    }
  }
  handleBlur(e){
    if(this.props.onBlur){this.props.onBlur(this.state.text)}
  }
  render(){
    const errorColor = this.props.errorColor || "#EF0202"
    const textLength = this.props.lengthCalculator(this.state.text)

    const customErrors = this.props.customError ? this.props.customError(this.state.text) : []

    const exceededLimit = textLength > this.props.lengthLimit
    const underLimit = this.props.minimumLength !== undefined ? textLength < this.props.minimumLength : false
    const hasError = exceededLimit || underLimit || customErrors.length > 0
    const errors = []
    if(exceededLimit) {
      errors.push(defaultErrors.OVERLIMIT)
    }
    if(underLimit) {
      errors.push(defaultErrors.UNDERLIMIT)
    }
    if(customErrors.length>0) {
      customErrors.forEach(error=>{
        errors.push(error.id)
      })
    }
    if(this.props.onError){this.props.onError(errors)}

    const textIndicator = `${NumFormatter.formatNumber(textLength)}/${NumFormatter.formatNumber(this.props.lengthLimit)}`
    const textError = this.props.showErrorText &&
      <div className="ErrorText" style={{color: errorColor}}>
        {exceededLimit && <Translate id="adCopies.editorLimitError"/>}
        {underLimit && <Translate id="adCopies.editorMinLengthError" data={{value: this.props.minimumLength}}/>}
        {customErrors &&
          customErrors.map(x=>
            <div key={x.text}><Translate id={x.text} data={x.data}/></div>
          )
        }
      </div>

    return (
      <div className="LengthLimitedTextInput" >
        <div className="InputArea">
          {this.renderInput(hasError,textIndicator,this.props.label,textError)}
        </div>
      </div>
    )
  }
  renderInput(hasError,textIndicator,label,textError){
    const inputProps = {
      onChange : this.handleChange,
      onBlur   : this.handleBlur,
      value    : this.state.text,
    }
    if(this.props.rows){
      return (
        <FormControl>
          <TextField
            multiline
            label={label}
            rows={this.props.rows}
            InputProps={{
              endAdornment: <InputAdornment position="end">{textIndicator}</InputAdornment>,
            }}
            error={hasError}
            {...inputProps}
          />
          {hasError ? textError : null}
        </FormControl>
      )
    }
    return <FormControl>
      <TextField
        label={label}
        InputProps={{
          endAdornment: <InputAdornment style={{marginLeft:10}} position="end">{textIndicator}</InputAdornment>,
        }}
        error={hasError}
        {...inputProps}
      />
      {hasError ? textError : null}
    </FormControl>
  }
}

export default LengthLimitedTextInput
