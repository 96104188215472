import Actions        from "../../model/constant/actions"
import ReducerUtils   from "../../utils/ReducerUtils"
import ObjectUtils    from "../../utils/ObjectUtils"
import {MakesReducer} from "../../model/Store/Cleaner"

const save = (state,action) => ({
  ...state,
  [action.accountId] : ObjectUtils.getObjectValues(
    action.cleaner.reduce((byMake, row)=>{
      byMake[row.DisplayMake] = {
        ID          : row.ID,
        DisplayMake : row.DisplayMake,
      }
      return byMake
    },{})
  )
})

export default ReducerUtils.createReducer<MakesReducer>({},{
  [Actions.RETRIEVE_CLEANER_REFERENCE] : save
})
