import * as React               from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Icon
}                               from '@material-ui/core'
import { Translate }            from 'react-localize-redux'
import { AdGroupConfigsByLang } from '../../../../model/branding/AdGroupConfigs'
import AWSearch                 from '../../../../model/Store/Audiences/AWSearch'
import AudienceAdRow            from './AudienceAdRow'

interface AudiencesTableOwnProps {
  adGroupConfigsByLang        : AdGroupConfigsByLang
  lang                        : string
  adGroupName                 : string
  handleClickEditAudienceAd   : (adGroupName:string,audienceAdName:string) => void
  handleClickDeleteAudienceAd : (adGroupName:string,audienceAdId:string) => void
  AWAudiences                 : AWSearch[]
}
interface AudiencesTableProps extends AudiencesTableOwnProps {}

const AudiencesTable = (props:AudiencesTableProps) => {
  return (
    <Table className="Table">
    <TableHead>
      <TableRow>
        <TableCell>Audiences</TableCell>
        <TableCell className="CenterText" style={{width:170}}><Translate id="branding.editAds" /></TableCell>
        <TableCell className="CenterText" style={{width:130}}><Translate id="common.delete" /></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {props.adGroupConfigsByLang[props.lang][props.adGroupName].audienceAds.map((audience,i)=>{
        if(audience.audienceId === "0") {
          return (
            <AudienceAdRow
              adGroupConfigsByLang={props.adGroupConfigsByLang}
              adGroupName={props.adGroupName}
              handleClickDeleteAudienceAd={props.handleClickDeleteAudienceAd}
              handleClickEditAudienceAd={props.handleClickEditAudienceAd}
              audience={{name:'New Visitors',id:0,description:'',listType:'',size:0,userListType:''}}
              lang={props.lang}
              key={i}
            />
          )
        }
        else {
          const temp = props.AWAudiences.find((awAudience=>{
            return awAudience.id.toString() === audience.audienceId
          }))
          return (
            <AudienceAdRow
              adGroupConfigsByLang={props.adGroupConfigsByLang}
              adGroupName={props.adGroupName}
              handleClickDeleteAudienceAd={props.handleClickDeleteAudienceAd}
              handleClickEditAudienceAd={props.handleClickEditAudienceAd}
              audience={temp}
              lang={props.lang}
              key={i}
            />
          )
        }
      })}
    </TableBody>
  </Table>
  )
}

export default AudiencesTable
