import Pacy                                              from "../../utils/Pacy"
import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import {getToken}                                        from "../../storeAccessor/Generic"
import { AccountModifier }                               from "../../model/pacy/AccountModifiers"
import { State }                                         from "../../reducers/generic/reducerAssembly"

export const moveProxySimulation = (accountId:number,newActions:AccountModifier[],moveInvestment:boolean) => (dispatch, getState) => {
  const state:State = getState()
  dispatch({type: Actions.TRYING_PROXY_MOVE_SIMULATION, status: true})
  const token = getToken(state)
  const newState:AccountModifier[] = [...state.EditCopies.PacyConfigs.AccountModifiers,...newActions]
  const accountStateId = state.Pacy.Accounts[accountId].stateId
  ActionUtils.retryOnFailure(
    ()=>Pacy.dryUpdate(token,accountId,accountStateId,newState)
  )
  .then(response=>{
    dispatch({
      type           : Actions.MOVE_PROXY_SIMULATION,
      account        : response.data,
      moveInvestment : moveInvestment
    })
  })
  .catch(error=>{
    console.warn(error)
    dispatch({type: Actions.FAILED_SIMULATION_MOVING_PROXY,status:true})
  })
  .then(()=>{
    dispatch({type: Actions.TRYING_PROXY_MOVE_SIMULATION, status: false})
  })
}
