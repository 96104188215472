export default class RoutingUtils {

  public static getUrlElements(history:any):string[]{
    return history.location.pathname.split("/").filter(x=>x)
  }
  public static getLastElementOfUrl(history:any){
    const urlElements = RoutingUtils.getUrlElements(history)
    return urlElements[urlElements.length-1]
  }
  public static appendToUrl(history:any, toAppend:string){
    RoutingUtils.setUrl(history, history.location.pathname+"/"+toAppend)
  }
  public static appendAndReturnUrl(history:any, toAppend:string):string{
    return RoutingUtils.getFormattedUrl(history.location.pathname+"/"+toAppend)
  }
  public static replaceLastElementOfUrl(history:any, replacement:string){
    const urlElements = RoutingUtils.getUrlElements(history)
    urlElements[urlElements.length-1] = replacement
    RoutingUtils.setUrl(history, urlElements.join("/"))
  }
  public static removeLastElementOfUrl(history:any){
    const urlElements = RoutingUtils.getUrlElements(history)
    RoutingUtils.setUrl(history, urlElements.slice(0,-1).join("/"))
  }
  public static removeXElementOfUrl(history:any, numberOfElement:number){
    if(numberOfElement < 1){throw Error("Number of elements must be > 0")}
    const urlElements = RoutingUtils.getUrlElements(history)
    RoutingUtils.setUrl(history, urlElements.slice(0, -numberOfElement).join("/"))
  }
  public static removeAndReturnLastElementOfUrl(history:any):string{
    const urlElements = RoutingUtils.getUrlElements(history)
    return RoutingUtils.getFormattedUrl(urlElements.slice(0,-1).join("/"))
  }
  public static setUrl(history:any, newUrl:string){
    history.push(RoutingUtils.getFormattedUrl(newUrl))
  }
  public static getFormattedUrl(url:string){
    if(url[0] !== "/"){url = "/"+url}
    return url.replace(/\/+/g,"/")
  }
}
