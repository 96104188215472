import * as React        from "react"
import {connect}         from "react-redux"
import retrieveUsers     from "../../actions/user/users"
import ExplainedLoading  from "../loading"
import ErrorBoundary     from "../Error/ErrorBoundary"

const mapStateToProps = (state)=>{
  return {
    users           : state.Users.List.all,
    retrievingUsers : state.Users.Retrieving.RetrievingUsersList,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    loadUsers : ()=>dispatch(retrieveUsers())
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}
const UserLoader = props => {
  if(props.users===undefined){
    if(props.retrievingUsers===false){props.loadUsers()}
    return <ExplainedLoading translateId="loadings.userData"/>
  }
  return <ErrorBoundary>{props.render(props.users)}</ErrorBoundary>
}
const UserLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(UserLoader)
const requiresUsers = Component => props => (
  <UserLoaderConnected
    render={ users => <Component users={users} {...props} /> }
  />
)
export default requiresUsers
