import './AgentWeightPie.css'
import * as React   from 'react'
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer
}                   from 'recharts'
import { Agent }    from '../../../../../model/pacy'

interface AgentWeightGraphOwnProps {
  radius      : number
  width       : number
  height      : number
  agents      : Agent[]
  agentId     : string
  agentWeight : number
  active      : boolean
  startAngle ?: number
}
interface AgentWeightGraphProps extends AgentWeightGraphOwnProps {}

const AgentsWeightGraph = (props:AgentWeightGraphProps) => {
  const totalWeightWithoutConcernedAgent = props.agents.reduce((acc,agent)=>{
    if(agent.id !== props.agentId && agent.active) {
      return acc + agent.weight
    }
    return acc
  },0)
  const data = [{name:'total',value:totalWeightWithoutConcernedAgent},{name:'concerned',value:props.agentWeight}]
  const COLORS = props.active ? ['#ddd', '#00B2E3'] : ['#ddd','#666']
  return (
    <ResponsiveContainer width={props.width} height={props.height} className="AgentsWeightGraph">
       <PieChart onClick={()=>console.log(props.startAngle)} >
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          cx={(props.width-10)/2}
          cy={(props.height-10)/2}
          outerRadius={props.radius}
          fill="#00B2E3"
          labelLine={false}
          blendStroke
          startAngle={props.startAngle || 0}
          endAngle={props.startAngle + 360 || 360 }
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default AgentsWeightGraph
