import styles                       from "./StatusDisplayButtonStyles"
import * as React                   from "react"
import {MarketingEvent}             from "../../../model/adbuilder/index"
import {
  Menu,
  MenuItem,
  Button
}                                   from "@material-ui/core"
import {Translate}                  from "react-localize-redux"

interface StatusDisplayButtonProps{
  event        : MarketingEvent
  changeStatus : () => void
}

interface StatusState {
  class: string
  translation: string
}

const StatusDisplayButton = (props:StatusDisplayButtonProps) => {
  const classes = styles()
  const today = new Date()
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null)
  const provideStatus = ():StatusState => {
    if (props.event.endDate < today){
      return {
        translation : "adBuilder.status.ended",
        class : classes.ended
      }
    }
    return props.event.active 
      ? {
          translation : "adBuilder.status.active",
          class : classes.active
        }
      : {
          translation : "adBuilder.status.paused",
          class : classes.paused
        }
  }
  const [status, setStatus] = React.useState<StatusState>(provideStatus())

  React.useEffect(()=>{
    setStatus(provideStatus())
  },[props.event])

  return (
    <div>
      <Button onClick={(e)=>setAnchor(status.translation.includes("ended") ? null : e.currentTarget)} className={status.class}>
        <Translate id={status.translation} />
      </Button>
      <Menu
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={()=>setAnchor(null)}
      >
        <MenuItem
          onClick={props.changeStatus}
        >
          <Translate id={props.event.active ? "adBuilder.status.paused" : "adBuilder.status.active"} />
        </MenuItem>
      </Menu>
    </div>
  )
}

export default StatusDisplayButton
