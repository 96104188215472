type DigitRange = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20

const defaultLocale = "en-CA"
const defaultCurrency = "CAD"

class NumberFormatter{
  public locale:string = defaultLocale
  public currency:string = defaultCurrency

  public setLocale(locale:string){
    this.locale = locale || defaultLocale
  }
  public setCurrency(currency:string){
    this.currency = currency || defaultCurrency
  }

  public formatCurrency(amount:number):string{
    return new Intl.NumberFormat(this.locale, { style: 'currency', currency: this.currency || "CAD" }).format(amount)
  }
  public formatNumber(amount:number, digits?:DigitRange):string{
    /*
      This function allows us to specify our degree of precision with fraction digits.
      If we do not provide that parameter, the expected behavior is to keep the original number of fraction digits on the amount,
      up to 20. (Intl.NumberFormat highest possible value)
      If we do provide a number of significant fraction digits, this number will be used instead of the amount's number of digits.
        - Christopher Sarao
    */
    var decimalsInNumber = this.countDecimals(amount)
    if (digits){
      if (digits<0 || digits>20){
        throw Error("Fraction digit is out of range 0-20.")
      }
    }
    if (digits === 0){
      return new Intl.NumberFormat(this.locale).format(Math.round(amount))
    }
    return new Intl.NumberFormat(this.locale, {maximumFractionDigits:digits||decimalsInNumber}).format(amount)
  }
  public countDecimals (value:number):number {
    if(Math.floor(value) === value){return 0}
    if (!value.toString().split(".")[1]) {
      console.log("problem value: "+ value)

      // return 0
    }
    return value.toString().split(".")[1].length || 0
  }
}
const Singleton = new NumberFormatter()
export default Singleton
