import Actions          from "../../../model/constant/actions"
import Place            from "../../../model/Store/GeoBid/Place"
import ReducerUtils     from "../../../utils/ReducerUtils"
import ArrayUtils     from "../../../utils/ArrayUtils"

const addSingleToQueue = (state,action) => {
  return ArrayUtils.unique([...state, action.code])
}
const addMultipleToQueue = (state,action) => {
  return ArrayUtils.unique([...state, ...action.codes])
}
const removeFromQueue = (state,action) => {
  return state.filter(code=>code!==action.code)
}
const moveFirstToEnd= (state, action) => {
  if(state.length <= 1){return state}
  return [
    ...state.slice(1),
    state[0]
  ]
}
export const GeocodingQueue = ReducerUtils.createReducer<number[]>([],{
  [Actions.ADD_CODE_TO_GEOCODING_QUEUE] : addSingleToQueue,
  [Actions.ADD_CODES_TO_GEOCODING_QUEUE] : addMultipleToQueue,
  [Actions.REMOVE_CODE_FROM_GEOCODING_QUEUE] : removeFromQueue,
  [Actions.MOVE_FIRST_CODE_TO_END_OF_GEOCODING_QUEUE] : moveFirstToEnd,
})
