import styles                 from './AdCopiesStyle'
import * as React             from 'react'
import SimpleAdCopy           from '../../../components/adCopy/SimpleAdCopy'
import { AdCopyRSATemplates } from '../../../model/adcopy'
import {Button}               from '@material-ui/core'
import DeleteOutlined         from '@material-ui/icons/DeleteOutlined'

interface AdCopiesProps {
  templates           : AdCopyRSATemplates[],
  setSelectedTemplate : (e) => void,
  selectedTemplateId  : number
  setToDelete        ?: (id:number) => void
  idsToDelete        ?: number[]
}

const AdCopies = (props:AdCopiesProps) => {
  const classes = styles()
  return (
    <>
      {props.templates.map(template => {
        return (
          <div
            className={classes.adCopies}
            onClick={props.setSelectedTemplate}
            key={template.id}
            id={template.id.toString()}
            style={props.idsToDelete && props.idsToDelete.includes(template.id) 
              ? {backgroundColor:'#FFE0E0'}
              : template.id === props.selectedTemplateId 
                ? {backgroundColor:'#E4FAE8'} 
                : null
            }
          >
            { props.idsToDelete && (props.idsToDelete.length < props.templates.length - 1 || props.idsToDelete.includes(template.id)) &&
              <Button
                className={ props.idsToDelete.includes(template.id)
                  ? classes.redButton
                  : classes.button
                }
                onClick={()=>props.setToDelete(template.id)}
              >
                <DeleteOutlined />
              </Button>
            }
            <div className={classes.adcopy}>
              <SimpleAdCopy headlines={template.headlines.map(x=>x.text)} descriptions={template.descriptions.map(x=>x.text)} url={'www.google.com'}/>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default AdCopies
