import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"
import { AudienceAd } from "../../../model/branding/AudienceAd"

const changeAudienceAdToEdit = (state,action:{audienceAds:AudienceAd[]}) => {
  return action.audienceAds
}
const addAudienceAd = (state,action:{audienceAd:AudienceAd}) => {
  return [...state.filter(audience=>audience.audienceId!=action.audienceAd.audienceId),action.audienceAd]
}
const deleteAudienceAd = (state,action:{audienceAdId:number}) => {
  return [...state.filter(audience=>audience.audienceId!=action.audienceAdId)]
}

const AudienceAdToEdit = ReducerUtils.createReducer([],{
  [Actions.CHANGE_AUDIENCEAD_TOEDIT] : changeAudienceAdToEdit,
  [Actions.ADD_AUDIENCEAD_TO_AUDIENCEADS_TOEDIT] : addAudienceAd,
  [Actions.DELETE_AUDIENCEAD_FROM_AUDIENCEADS_TOEDIT] : deleteAudienceAd
})

export default  AudienceAdToEdit
