import './wazeLoader.css'
import * as React                     from "react"
import {connect}                      from "react-redux"
import Period                         from "../../model/Period"
import ArrayUtils                     from "../../utils/ArrayUtils"
import StatisticsUtils                from "../../utils/StatisticsUtils"
import {loadWazePerfoData}            from "../../actions/Waze"
import ExplainedLoading               from "../loading"
import ErrorBoundary                  from "../Error/ErrorBoundary"
import { Translate }                  from "react-localize-redux"

const mapStateToProps = (state, ownProps)=>{
  const accountId = state.Accounts.selected
  return {
    accountId           : accountId,
    period              : state.Period,
    wazePerformanceData : state.Waze.PerformanceData[accountId],
    fetchingWaze        : state.Waze.Retrieving,
    isFetched           : state.Waze.IsFetched[accountId],
    accountsWithoutWaze : state.Waze.AccountsWithoutWaze,
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    fetchWazePerfoData : (accountId:number, period:Period) => {
      dispatch(loadWazePerfoData(accountId,period))
    },
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,
    ...DP,
    ...ownProps,
    fetchWazePerfoData : ()=>DP.fetchWazePerfoData(SP.accountId, SP.period),
    getData : ()=>{
      if(!ownProps.filterToPeriod){return SP.wazePerformanceData}
      return StatisticsUtils.filterToPeriod(SP.period, "day", SP.wazePerformanceData)
    }
  }
}
const WazeLoader = props => {
  if(!props.isFetched){
    if(props.fetchingWaze === false && props.accountId){props.fetchWazePerfoData()}
    return <ExplainedLoading translateId="loadings.wazeStats"/>
  }
  if(!props.ignoreNoWazeAccount && ArrayUtils.contain(props.accountsWithoutWaze, props.accountId)){
    return <p className="NoWazeAccount"><Translate id="loadings.noWazeAccount"></Translate></p>
  }

  return <ErrorBoundary>{props.render(props.getData())}</ErrorBoundary>
}
const WazeLoaderConnected = connect(mapStateToProps,mapDispatchToProps,mergeProps)(WazeLoader)

export const requiresFilteredWazePerformanceData = Component => props => (
  <WazeLoaderConnected
    filterToPeriod
    render={ wazePerformanceData => <Component wazePerformanceData={wazePerformanceData} {...props} /> }
  />
)
export const requiresFilteredWazePerformanceDataIgnoreNoAccount = Component => props => (
  <WazeLoaderConnected
    ignoreNoWazeAccount
    filterToPeriod
    render={ wazePerformanceData => <Component wazePerformanceData={wazePerformanceData} {...props} /> }
  />
)
const requiresWazePerformanceData = Component => props => (
  <WazeLoaderConnected
    filterToPeriod={false}
    render={ wazePerformanceData => <Component wazePerformanceData={wazePerformanceData} {...props} /> }
  />
)
export default requiresWazePerformanceData
