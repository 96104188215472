import * as React                              from "react"
import {combineReducers}                       from 'redux'
import StrategiesList                          from "./StrategiesList"
import BiddingStrategiesSettingsList           from "./BiddingStrategiesSettingsList"
import Fetching                                from "./Fetching"
import FetchingBiddingStrategySettings         from "./FetchingBiddingStrategySettings"
import FailedRetrievingBiddingStrategySettings from "./FailedRetrievingBiddingStrategySettings";

export const Strategies = combineReducers({
  list                                    : StrategiesList,
  fetching                                : Fetching,
  strategiesSettingsList                  : BiddingStrategiesSettingsList,
  fetchingBiddingStrategySettings         : FetchingBiddingStrategySettings,
  failedRetrievingBiddingStrategySettings : FailedRetrievingBiddingStrategySettings
})

