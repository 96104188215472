import "./index.css"
import * as React                        from "react"
import {connect}                         from "react-redux"
import moment                            from "moment"
import {PacyAccount}                     from "../../../model/pacy/"
import {
  Decision,
  DecisionType,
  BaseDecision,
}                                        from "../../../model/pacy/Decision"
import BootstrapTable                    from "react-bootstrap-table-next"
import filterFactory                     from "react-bootstrap-table2-filter"
import {textFilter}                      from "react-bootstrap-table2-filter"
import {selectFilter}                    from "react-bootstrap-table2-filter"
import paginationFactory                 from "react-bootstrap-table2-paginator"

interface DecisionsOwnProps{
  account : PacyAccount
}
interface DecisionsProps extends DecisionsOwnProps{
  decisions : Decision[]
}
const mapStateToProps = (state,ownProps:DecisionsOwnProps)=>{
  return {
    decisions : state.Pacy.Decisions[ownProps.account.accountId]
  }
}


const columns = [
  {
    text:"id",
    dataField:"id",
    sort:true,
    hidden:true
  },{
    text:"Created on",
    dataField:"createdOn",
    sort:true,
    formatter:(cell)=>moment(cell).format("YYYY-MM-DD HH:mm:ss")
  },{
    text:"Type",
    dataField:"type",
    sort:true,
    filter:selectFilter({
      options: Object.keys(DecisionType).reduce((o,x)=>({...o, [x]:x}),{})
    }),
  },{
    text:"Details",
    dataField:"details",
    sort:true,
    formatter:(cell)=>JSON.stringify(cell),
    filter:textFilter(),
  },
]
const Decisions = (props:DecisionsProps) => {
  const proxyGroupNames = []
  const proxyNames = []
  const agentNames = []
  const completionByProxyGroup = {}
  const completionByProxy = {}
  const completionByAgent = {}
  for(const proxyGroup of props.account.proxyGroups){
    proxyGroupNames.push(proxyGroup.name)
    completionByProxyGroup[proxyGroup.id] = {
      proxyGroupName : proxyGroup.name
    }
    for(const proxy of proxyGroup.proxies){
      proxyNames.push(proxy.name)
      completionByProxy[proxy.id] = {
        proxyGroupName : proxyGroup.name,
        proxyName      : proxy.name
      }
      for(const agent of proxy.agents){
        agentNames.push(agent.provider)
        completionByAgent[agent.id] = {
          proxyGroupName : proxyGroup.name,
          proxyName      : proxy.name,
          agentName      : agent.provider
        }
      }
    }
  }
  const completeDecisions = props.decisions?.map((d:BaseDecision)=>{
    if(d.details.agentId && completionByAgent[d.details.agentId]){
      return {
        ...d,
        ...completionByAgent[d.details.agentId]
      }
    }
    else if(d.details.proxyId && completionByProxy[d.details.proxyId]){
      return {
        ...d,
        ...completionByProxy[d.details.proxyId]
      }
    }
    else if(d.details.proxyGroupId && completionByProxyGroup[d.details.proxyGroupId]){
      return {
        ...d,
        ...completionByProxyGroup[d.details.proxyGroupId]
      }
    }
    return {
      ...d,
      proxyGroupName : "-",
      proxyName      : "-",
      agentName      : "-"
    }
  })

  const allColumns = [...columns]
  allColumns.splice(3, 0,
    {
      text:"ProxyGroup",
      dataField:"proxyGroupName",
      sort:true,
      filter:selectFilter({
        options : proxyGroupNames.reduce((o,x)=>({...o, [x]:x}),{})
      })
    },{
      text:"Proxy",
      dataField:"proxyName",
      sort:true,
      filter:selectFilter({
        options : proxyNames.reduce((o,x)=>({...o, [x]:x}),{})
      })
    },{
      text:"Agent",
      dataField:"agentName",
      sort:true,
      filter:selectFilter({
        options : agentNames.reduce((o,x)=>({...o, [x]:x}),{})
      })
    }
  )
  return (
    <div className="Decisions">

      <BootstrapTable
        data={completeDecisions}
        keyField="id"
        columns={allColumns}
        defaultSorted={[{dataField:"createdOn",order:"desc"}]}
        filter={filterFactory()}
        pagination={paginationFactory({
          sizePerPage : 50,
          hideSizePerPage : true
        })}
        classes={"normalSizedDatatable FriendsListDataTable"}
      />
    </div>
  )
}

export default connect(mapStateToProps)(Decisions)
