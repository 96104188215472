import NumFormatter            from '../../../../utils/NumberFormatter'
import MathUtils               from "../../../../utils/MathUtils"
import * as Compiler           from "./Compilations"


export const COLORS = {
  "Branding"             : "#3366CC", "Marque"             : "#3366CC",
  "Credit"               : "#DC3912", "Crédit"             : "#DC3912",
  "Conquest"             : "#6633CC", "Conquête"           : "#6633CC",
  "Conquest new"         : "#FF9900", "Conquête neuf"      : "#FF9900",
  "Remarketing credit"   : "#109618", "Remarketing crédit" : "#109618",
  "Display"              : "#990099", "Bannières"          : "#990099",
  "Service"              : "#3B3EAC",
  "Remarketing used"     : "#0099C6", "Remarketing usagé"  : "#0099C6",
  "Conquest used"        : "#DD4477", "Conquête usagé"     : "#DD4477",
  "Used"                 : "#66AA00", "Usagé"              : "#66AA00",
  "New"                  : "#B82E2E", "Neuf"               : "#B82E2E",
  "Remarketing new"      : "#316395", "Remarketing neuf"   : "#316395",
  "Remarketing branding" : "#994499", "Remarketing marque" : "#994499",
  "Remarketing service"  : "#22AA99",
  "Waze"                 : "#AAAA11",
}

export const toolsByDetails = {
  cost : {
    compiler       : props=>Compiler.compileDispatcher("cost", props.accountId, props.budgetsStats, props.campaignsStats),
    formatter      : x=>NumFormatter.formatCurrency(x),
    tableFormatter : x=>NumFormatter.formatCurrency(x),
    removeZeros    : true,
    domain         : [0,"auto"],
    chartType      : "pie",
  },
  impressions : {
    compiler       : props=>Compiler.compileDispatcher("impressions", props.accountId, props.budgetsStats, props.campaignsStats),
    formatter      : x=>NumFormatter.formatNumber(x, 2)+" Impressions",
    tableFormatter : x=>NumFormatter.formatNumber(x, 2),
    removeZeros    : true,
    domain         : [0,"auto"],
    chartType      : "pie",
  },
  clicks : {
    compiler       : props=>Compiler.compileDispatcher("clicks", props.accountId, props.budgetsStats, props.campaignsStats),
    formatter      : x=>NumFormatter.formatNumber(x, 2)+" Clicks",
    tableFormatter : x=>NumFormatter.formatNumber(x, 2),
    removeZeros    : true,
    domain         : [0,"auto"],
    chartType      : "pie",
  },
  cpc : {
    compiler       : props=>Compiler.compileDispatcher("cpc", props.accountId, props.budgetsStats, props.campaignsStats),
    formatter      : x=>NumFormatter.formatCurrency(x),
    tableFormatter : x=>NumFormatter.formatCurrency(x),
    removeZeros    : true,
    domain         : [0,"auto"],
    chartType      : "bar",
  },
  impShare : {
    compiler       : props=>Compiler.compileDispatcher("impShare", props.accountId, props.budgetsStats, props.campaignsStats),
    formatter      : x=>NumFormatter.formatNumber(x, 2)+"%",
    tableFormatter : x=>NumFormatter.formatNumber(x, 2)+"%",
    removeZeros    : true,
    domain         : [0,100],
    chartType      : "bar",
  },
  clickShare : {
    compiler       : props=>Compiler.compileDispatcher("clickShare", props.accountId, props.budgetsStats, props.campaignsStats),
    formatter      : x=>NumFormatter.formatNumber(x, 2)+"%",
    tableFormatter : x=>NumFormatter.formatNumber(x, 2)+"%",
    removeZeros    : true,
    domain         : [0,100],
    chartType      : "bar",
  },
  conversions : {
    compiler       : props=>Compiler.compileDispatcher("conversions", props.accountId, props.budgetsStats, props.campaignsStats),
    formatter      : x=>NumFormatter.formatNumber(x, 2)+" Conversions",
    tableFormatter : x=>NumFormatter.formatNumber(x, 2)+" Conversions",
    removeZeros    : true,
    domain         : [0,"auto"],
    chartType      : "pie",
  },
  ctr : {
    compiler       : props=>Compiler.compileDispatcher("ctr", props.accountId, props.budgetsStats, props.campaignsStats),
    formatter      : x=>NumFormatter.formatNumber(x, 2)+"%",
    tableFormatter : x=>NumFormatter.formatNumber(x, 2)+"%",
    removeZeros    : true,
    domain         : [0,"auto"],
    chartType      : "bar",
  },
  conversionRate : {
    compiler       : props=>Compiler.compileDispatcher("conversionrate", props.accountId, props.budgetsStats, props.campaignsStats),
    formatter      : x=>NumFormatter.formatNumber(x, 2)+"%",
    tableFormatter : x=>NumFormatter.formatNumber(x, 2)+"%",
    removeZeros    : true,
    domain         : [0, "auto"],
    chartType      : "bar",
  },
  cpcon : {
    compiler       : props=>Compiler.compileDispatcher("cpcon", props.accountId, props.budgetsStats, props.campaignsStats),
    formatter      : x=>NumFormatter.formatCurrency(x),
    tableFormatter : x=>NumFormatter.formatCurrency(x),
    removeZeros    : true,
    domain         : [0, "auto"],
    chartType      : "bar",
  },
}

