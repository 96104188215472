import * as React                  from "react"
import styles                      from './SortedAdCopyListStyles'
import AdCopyEditingSelector       from "./AdCopyEditingSelector"
import {HeadstoneActiveStrategies} from "../../../model/Store/Strategy/Strategy"
import AWSearch                    from "../../../model/Store/Audiences/AWSearch"

interface SortedAdCopyListProps{
  activeStrategies : HeadstoneActiveStrategies
  startEditing     : (campaignType:string, campaign:string, adgroup:string, lang:string) => void
  AWAudiences      : AWSearch[]
}

const SortedAdCopyList = (props:SortedAdCopyListProps) => {
  const classes = styles()
  return(
    <div className={classes.container}>
      {Object.keys(props.activeStrategies).map(group=>
        <AdCopyEditingSelector
          key={group}
          title={group}
          adCopyParents={props.activeStrategies[group]}
          edit={props.startEditing}
          AWAudiences={props.AWAudiences}
        />
      )}
    </div>
  )
}

export default SortedAdCopyList
