import {combineReducers}  from "redux"
import Budgets            from "./Budgets"
import GeoMapping         from "./GeoMapping"
import {GeoBidStats}      from "./GeoBids"
import {CampaignsStats}   from "./Campaigns"
import {KeywordsStats}    from "./Keywords"
import {SearchTermsStats} from "./SearchTerms"

const Bing = combineReducers({
  Budgets,
  GeoMapping,
  GeoBids     : GeoBidStats,
  Campaigns   : CampaignsStats,
  Keywords    : KeywordsStats,
  SearchTerms : SearchTermsStats,
})

export default Bing
