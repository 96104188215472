import Actions                         from "../../model/constant/actions"
import {getToken}                      from "../../storeAccessor/Generic"
import ActionUtils                     from "../../utils/ActionUtils"
import Headstone, { StatusExceptions } from "../../utils/Headstone"

export default (accountId:number) => (dispatch, getState) => {
  dispatch({
    type : Actions.RETRIEVING_DESIRED_STATUS_EXCEPTIONS,
    accountId : accountId
  })
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Headstone.getStatusAllStatusExceptions(token,accountId)
  )
  .then((response)=>{
    dispatch(loadDesiredStatusExceptionsSuccess(response.data,accountId))
  })
  .catch((error)=>{
    dispatch(loadDesiredStatusExceptionsError(error))
  })
}
const loadDesiredStatusExceptionsSuccess = (response:StatusExceptions[],accountId:number) => {
  const statusExceptions = response.reduce((acc,campaign)=>{
    if(campaign.status === 'PAUSED') {acc.Paused.push(campaign.campaignName)}
    if(campaign.status === 'ENABLED') {acc.Enabled.push(campaign.campaignName)}
    return acc
  },{Paused:[],Enabled:[]})
  return {
    type             : Actions.RETRIEVE_DESIRED_STATUS_EXCEPTIONS,
    accountId,
    statusExceptions
  }
}
const loadDesiredStatusExceptionsError = (error) => {
  return {
    type             : Actions.RETRIEVE_DESIRED_STATUS_EXCEPTIONS,
    statusExceptions : undefined
  }
}
