import './WeightsGraph.css'
import * as React       from 'react'
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
}                       from 'recharts'
import { Proxy }        from '../../../../../model/pacy'
import NumFormatter     from "../../../../../utils/NumberFormatter"

interface WeightsGraphOwnProps {
  proxies : Proxy[]
  colors  : {[proxyId:string]: string}
}
interface WeightsGraphProps extends WeightsGraphOwnProps {}

const WeightsGraph = (props:WeightsGraphProps) => {
  const totalWeight = props.proxies.reduce((acc,proxy)=>{
    if(proxy.active) {
      return acc + proxy.weight
    }
    return acc
  },0)
  const data = props.proxies.filter(proxy=>proxy.active).map(proxy=>{
    return {
      proxyId:proxy.id,
      name:proxy.name,
      value:Math.round(proxy.weight/totalWeight*100)
    }
  })
  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {NumFormatter.formatNumber((percent * 100))}%
      </text>
    )
  }

  return (
    <div>
      <PieChart width={400} height={400}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          cx={200}
          cy={200}
          outerRadius={130}
          fill="#00B2E3"
          label={renderCustomizedLabel as any}
          labelLine={false}
          blendStroke
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={props.colors[entry.proxyId]} />)
          }
        </Pie>
        <Tooltip
          formatter={x => (typeof x === "number" || "string") ? NumFormatter.formatNumber(parseFloat(x.toString())) : x }
        />
      </PieChart>
    </div>
  )
}

export default WeightsGraph
