import AxiosWrapper from "./AxiosWrapper"
import {stringify} from "querystring"

interface action{
  type:string
}
export default class TrackingAPI extends AxiosWrapper {
  protected static getApiUrl(){
    return process.env.REACT_APP_TRACKING_URL
  }
}
