import * as React                        from "react"
import {Translate}                       from "react-localize-redux"
import Button                            from "@material-ui/core/Button"
import Dialog                            from "@material-ui/core/Dialog"
import DialogTitle                       from "@material-ui/core/DialogTitle"
import DialogContent                     from "@material-ui/core/DialogContent"
import DialogContentText                 from "@material-ui/core/DialogContentText"
import DialogActions                     from "@material-ui/core/DialogActions"

interface AlertProps{
  open        ?: boolean
  title       ?: string
  contentText ?: string
  buttons     ?: string[]
  onClose     ?: ()=>void
  translate   ?: boolean
}
interface AlertState{
  open : boolean
}

class Alert extends React.Component<AlertProps,AlertState>{
  static defaultProps = {
    open : false
  }
  constructor(props:AlertProps){
    super(props)
    this.state = {
      open : props.open
    }
    this.handleClose = this.handleClose.bind(this)
  }
  componentDidUpdate(prevProps:AlertProps, prevState:AlertState){
    if(prevProps.open !== this.props.open && this.props.open !== this.state.open){
      this.setState({open: this.props.open})
    }
  }
  handleClose(){
    this.setState({open: false})
  }
  render(){
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {this.props.title &&
            <DialogTitle id="alert-dialog-title">
              {this.renderText(this.props.title)}
            </DialogTitle>
          }
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.renderText(this.props.contentText)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.props.buttons.map((x,i)=>
              <Button key={i} onClick={this.handleClose} color="primary">
                {this.renderText(x)}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
  renderText(text:string){
    if(this.props.translate){return <Translate id={text}/>}
    return text
  }
}

export default Alert
