import * as React                              from "react"
import {DropTarget}                            from "react-dnd"
import ItemTypes                               from "../../../../model/constant/ItemTypes"
import Column                                  from "./Column"
import DragColumn                              from "../../../../model/report/DragColumn"

interface ColumnsBankProps{
  columns           : DragColumn[]
  connectDropTarget : any
  isOver            : any
}
const bankTarget = {
  drop(props, monitor, component){
    monitor.getItem().removeMe()
  }
}
function collectDrop(connect, monitor){
  return {
    connectDropTarget : connect.dropTarget(),
    isOver : monitor.isOver()
  }
}
class ColumnsBank extends React.Component<ColumnsBankProps,any>{
  render(){
    return this.props.connectDropTarget(
      <div>
        {this.props.columns.map((column,i)=>
          <Column
            key={i}
            index={i}
            {...column}
            addColumn={()=>{}}
            removeColumn={()=>{}}
          />
        )}
      </div>
    )
  }
}

export default DropTarget(ItemTypes.COLUMN, bankTarget, collectDrop)(ColumnsBank)
