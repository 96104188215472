import './ArrayWithEditAndDelete.css'
import * as React  from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
}                  from '@material-ui/core'
import ArrayUtils  from '../../../utils/ArrayUtils'
import MUITableRow from './MUITableRow'

interface ArrayWithEditAndDeleteOwnProps {
  audienceAds    : any[]
  idToName       : any[]
  editFunction   : (id:number)=>void
  deleteFunction : (id:number)=>void
  enableSave    ?: (state:boolean)=>void
}
interface ArrayWithEditAndDeleteProps extends ArrayWithEditAndDeleteOwnProps {}

const ArrayWithEditAndDelete = (props:ArrayWithEditAndDeleteProps) => {
  const [errors,setErrors] = React.useState([])
  React.useEffect(()=>{
    props.audienceAds.forEach(audienceAd=>{
      if(audienceAd.templates[0].headlines[0] === "") {
        setErrors(prevState=>{
          if(!prevState.includes(audienceAd.audienceId)){
            return [...prevState,audienceAd.audienceId]
          }
          return prevState
        })
      }
    })
    if(props.enableSave){
      props.enableSave(errors.length===0 && props.audienceAds.length !== 0)
    }
  })
  return (
    <div className="ArrayWithEditAndDelete">
      <Table className="Table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ArrayUtils.uniqueFunc(props.audienceAds,x=>x.audienceId).map((audience,index)=>{
            const found = props.idToName.find(x=>x.id == audience.audienceId)
              if(found) {
                return (
                  <MUITableRow
                    key={index}
                    deleteFunction={props.deleteFunction}
                    editFunction={props.editFunction}
                    name={found.name}
                    id={found.id}
                    errors={errors}
                  />
                )
              }
            })
          }
        </TableBody>
      </Table>
    </div>
  )
}

export default ArrayWithEditAndDelete
