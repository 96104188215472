import './Overview.css'
import * as React                from "react"
import {connect}                 from "react-redux"
import {createSelector}          from "reselect"
import PlacementsTable           from "./component/PlacementsTable"
import KPIs                      from "../../../shared/KPIs"
import DualGraph                 from "../../../shared/DualGraph"
import SimpleMap                 from "../../../shared/SimpleMap"
import DisplayCampaign           from "../../../../../../model/Store/Campaign/DisplayCampaign"
import ObjectUtils               from "../../../../../../utils/ObjectUtils"
import DateUtils                 from '../../../../../../utils/DateUtils'
import StatisticsUtils           from '../../../../../../utils/StatisticsUtils'
import Statistics                from "../../../../../../storeAccessor/Statistics"
import PieChartOverview          from "../../../shared/PieChartOverview"

interface OverviewProps{
  accountId       : number
  campaign        : DisplayCampaign
  currentData     : any
  symmetricalData : any
  geoExceptions   : any
  period          : any
  changeView      : (view:string)=>void
}

const makeMapStateToProps = () => {
  const campaignsStatsSelector = Statistics.makeCampaignsStatsInCurrentAndSymmetricalPeriodSelector()
  const compiledCampaignStatsSelector = createSelector(
    campaignsStatsSelector,
    (_,campaign) => campaign,
    (state:any) => state.Period,
    (campaignsStats, campaign:DisplayCampaign, period)=>{
      if(campaignsStats === undefined){return undefined}
      if(campaignsStats.length === 0){
        return {
          currentData     : [],
          symmetricalData : [],
        }
      }
      const compiledByDay = ObjectUtils.getObjectValues(
        ObjectUtils.mapOnObject(
          campaignsStats.reduce((byDay, stat)=>{
            if(campaign.externalCampaignId+"" !== stat.CampaignAWId+""){return byDay}
              const cleanedStat = {
                Day                   : stat.Day,
                Clicks                : stat.Clicks,
                Conversions           : stat.Conversions,
                Cost                  : stat.Cost,
                Impressions           : stat.Impressions,
              }
              if(!byDay[cleanedStat.Day]){
                byDay[cleanedStat.Day] = {
                  Day                   : cleanedStat.Day,
                  Clicks                : 0,
                  Conversions           : 0,
                  Cost                  : 0,
                  Impressions           : 0,
                }
              }
              byDay[cleanedStat.Day].Clicks += cleanedStat.Clicks
              byDay[cleanedStat.Day].Conversions += cleanedStat.Conversions
              byDay[cleanedStat.Day].Cost += cleanedStat.Cost
              byDay[cleanedStat.Day].Impressions += cleanedStat.Impressions
              return byDay
          },{}),
          (day, stats)=>({ //Compile CPC after grouping by day
            ...stats,
            CPC : StatisticsUtils.calculateCPC(stats.Cost, stats.Clicks)
          })
        )
      ).sort((a,b)=> -(+new Date(b.Day) - +new Date(a.Day))) //for graph
      const symmetricalPeriod = DateUtils.getSymmetricalPeriod(period)
      return {
        currentData     : StatisticsUtils.filterToPeriod(period, "Day", compiledByDay),
        symmetricalData : StatisticsUtils.filterToPeriod(symmetricalPeriod, "Day", compiledByDay)
      }
    }
  )
  return (state, ownProps) => {
    const accountId = state.Accounts.selected
    const campaign:DisplayCampaign = ownProps.object
    const geoExceptions = (
      state.Campaigns.DesiredGeoExceptions[accountId]
      ? state.Campaigns.DesiredGeoExceptions[accountId][campaign.name]
      : undefined
    )
    return {
      accountId,
      campaign,
      geoExceptions   : geoExceptions,
      period : state.Period,
      ...compiledCampaignStatsSelector(state, campaign),
    }
  }
}
const mapDispatchToProps = (dispatch) => ({})
const Overview = (props:OverviewProps) => {
  return(
    <div className="Overview">
      <div className="OverviewElement Graph">
        <DualGraph
          data={props.currentData}
          primaryColor="#00b1e4"
          secondaryColor="#3cba54"
          period={props.period}
          wantedMetrics={["Impressions","Clicks","Cost","CPC","Conversions"]}
        />
      </div>
      <KPIs data={props.currentData} symmetricalData={props.symmetricalData} wantedKPIs={["clicks","cpc","cost","impressions"]}/>
      <div className="MapAndRapport">
        <div className="OverviewElement">
          <SimpleMap
            accountId={props.accountId}
            radiuses={props.campaign.geoBids.radiuses}
            places={props.campaign.geoBids.places}
            ids={[props.campaign.externalCampaignId]}
            onClick={()=>alert("Not yet possible to edit display targeting.")}
            geoExceptions={props.geoExceptions}
          />
        </div>
        <div className="OverviewRapport">
          <PieChartOverview campaignAWIds={[props.campaign.externalCampaignId]}/>
        </div>
      </div>
      <div className="OverviewElement">
        <PlacementsTable campaign={props.campaign}/>
      </div>
    </div>
  )
}

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Overview)
