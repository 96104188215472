import * as React            from "react"
import {withRouter}          from "react-router"
import RoutingUtils          from "../../../../../../utils/RoutingUtils"
import Friend                from "../../../../../../model/Store/Campaign/Friend"
import {buildFromFriend}     from "../../../../../../model/Store/Campaign/SimplifiedFriend"
import GoBack                from "../../../../../../components/Button/ButtonGoBack"
import StatusModalWrapper    from "../../../shared/StatusModal/"

interface HeaderProps{
  object : Friend
  goBack : any
  history //from higher order component
}

const Header = (props:HeaderProps)=> {
  const simplifiedFriend = buildFromFriend(props.object)
  return (
    <>
      <GoBack onClick={()=>RoutingUtils.removeLastElementOfUrl(props.history)}/>
      <StatusModalWrapper
        accountId={props.object.getAccountId()}
        campaignName={props.object.getName()}
        campaigns={props.object.getCampaigns()}
      />
      <h4>{props.object.getName()}</h4>
    </>
  )
}

export default withRouter(Header as any) as any
