import "./Guideline.css"
import * as React                from "react"
import moment                    from "moment"
import {
  withLocalize,
  Translate,
  TranslateFunction
}                                from "react-localize-redux"
import {
  Guideline as IGuideline,
  getGuidelineAmount,
}                                from "../../../../model/pacy/Guideline"
import {
  ProxyGroup
}                                from "../../../../model/pacy/ProxyGroup"
import BudgetStats               from "../../../../model/Store/Statistics/BudgetStats"
import Icon                      from "@material-ui/core/Icon"
import ConfirmationModal         from "../../../../components/Modals/Confirmation"
import Tooltip                   from "../../../../components/tooltip/DesignedTooltip"
import MoneyInput                from "../MoneyInput"
import NumFormatter              from "../../../../utils/NumberFormatter"


interface GuidelineOwnProps{
  month               : string
  proxyGroup          : ProxyGroup
  guideline           : IGuideline
  budgetStatsForMonth : BudgetStats[]
  setAmount          ?: (amount:number)=>void
  highlight          ?: boolean
  color              ?: string
  isCarry            ?: boolean
  readOnly           ?: boolean
  showConfirmModal   ?: boolean
}
interface GuidelineProps extends GuidelineOwnProps{
  translate : TranslateFunction
}


interface GuidelineState{
  lastSavedValue  : number
  value           : number

  modalOpened     : boolean
  onModalConfirm ?: ()=>void
}

class Guideline extends React.Component<GuidelineProps,GuidelineState>{
  static defaultProps = {
    showConfirmModal : true
  }
  constructor(props){
    super(props)
    const amount = getGuidelineAmount(this.props.guideline)
    this.state = {
      lastSavedValue : amount,
      value          : amount,

      modalOpened    : false,
    }
    this.onInputBlur     = this.onInputBlur.bind(this)
    this.onCancelOfValue = this.onCancelOfValue.bind(this)
  }
  componentDidUpdate(prevProps:GuidelineProps, prevState:GuidelineState){
    const currentGuidelineAmount = getGuidelineAmount(this.props.guideline)
    if(currentGuidelineAmount !== getGuidelineAmount(prevProps.guideline)){
      this.setState({
        lastSavedValue : currentGuidelineAmount,
        value          : currentGuidelineAmount,
      })
    }
  }
  onInputBlur(){
    if(this.state.value !== this.state.lastSavedValue){
      if(this.props.showConfirmModal){
        this.setState({
          modalOpened : true,
          onModalConfirm : ()=>{
            this.setState({lastSavedValue: this.state.value})
            this.props.setAmount(this.state.value)
          }
        })
      }
      else{
        this.props.setAmount(this.state.value)
      }
    }
  }
  onCancelOfValue(){
    this.setState(prevState=>({
      modalOpened : false,
      value       : prevState.lastSavedValue
    }))
  }
  render(){
    const spent = this.props.budgetStatsForMonth ? this.props.budgetStatsForMonth.reduce((total, x)=>total+x.Spent,0) : 0
    const monthNameStyle = this.props.highlight ? {color: this.props.color, fontSize: "30px"} : {}
    const month = moment(this.props.month)
    return (
      <div className="Guideline">
        {this.props.isCarry &&
          <Tooltip text={this.props.translate("pacyPlanner.guidelineCarryTooltip") as string} arrowColor={this.props.color}>
            <Icon className="IsCarryArrow" fontSize="small">
              redo
            </Icon>
          </Tooltip>
        }
        <div className="MonthName" style={monthNameStyle}>
          <Translate id={"common.months."+moment(this.props.month).format("MM")}/>
        </div>
        <div>
          <MoneyInput
            className="Amount"
            margin={"dense"}
            disabled={this.props.setAmount===undefined}
            value={this.state.value}
            onChange={(e)=>this.setState({value: parseFloat(e.target.value)})}
            inputProps={{
              onBlur    : this.onInputBlur,
              onKeyDown : (e)=>{
                if(e.key === "Enter"){e.currentTarget.blur()}
                else if(e.key === "Escape"){
                  this.onCancelOfValue()
                }
              }
            }}
          />
        </div>
        {this.props.month <= moment().format("YYYY-MM") && //don't show spent if future month
          <div className="Spent">
            <div className="ActualSpent">
              {NumFormatter.formatCurrency(spent)}
            </div>
            {this.props.highlight
              ? this.props.translate("pacyPlanner.spentSoFar")
              : this.props.translate("common.spent")
            }
          </div>
        }

        <ConfirmationModal
          open={this.state.modalOpened}
          onConfirm={this.state.onModalConfirm}
          title={this.props.proxyGroup.name}
          content={
            <div>
              <Translate
                id="pacyPlanner.vagueModalContent"
                data={{
                  amount: NumFormatter.formatCurrency(this.state.value),
                  period: this.props.translate("common.months."+month.format("MM"))+" "+month.format("YYYY")
                }}
              />
            </div>
          }
          onClose={this.onCancelOfValue}
          onCancel={this.onCancelOfValue}
          readOnly={this.props.readOnly}
        />
      </div>
    )
  }
}

export default withLocalize(Guideline) as React.ComponentType<GuidelineOwnProps>
