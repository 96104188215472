import './AgentWeightsConfiguration.css'
import * as React              from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Icon,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  CardActions,
  Button,
}                              from '@material-ui/core'
import { Agent }               from '../../../../model/pacy'
import AgentsWeightsGraph      from './Components/AgentsWeightsGraph'
import chroma                  from 'chroma-js'
import { Translate }           from 'react-localize-redux'
import NumFormatter            from "../../../../utils/NumberFormatter"


interface AgentWeightsConfigurationOwnProps {
  agents              : Agent[]
  handleWeightsChange : (agentsChanged:{[id:string]:number},agentsActivated:string[]) => void
  handleCloseModal   ?: ( ) => void
}
interface AgentWeightsConfigurationProps extends AgentWeightsConfigurationOwnProps {}

const generateColorsFromPalette = (colors:string[], numberOfItems:number):string[] => {
  if(numberOfItems <= colors.length){return colors}

  //Each gap between colors in palette will have a number of colors to generate
  const numberOfColorsInEachGap = []
  for(let i = 0; i < colors.length-1; ++i){
    numberOfColorsInEachGap[i] = 0
  }
  const numberOfExtraItems = numberOfItems - colors.length

  for(let i = 0; i < numberOfExtraItems; ++i){
    numberOfColorsInEachGap[i%numberOfColorsInEachGap.length]++
  }

  const newPalette = [...colors]
  for(let i = 0; i < numberOfColorsInEachGap.length; ++i){
    const insertIndex = newPalette.indexOf(colors[i])+1
    const tempColors = chroma.scale([colors[i], colors[i+1]]).mode("lch").colors(2+numberOfColorsInEachGap[i])
    const toInsert = tempColors.slice(1, tempColors.length-1)
    newPalette.splice(insertIndex, 0, ...toInsert)
  }
  return newPalette
}

const AgentWeightsConfiguration = (props:AgentWeightsConfigurationProps) => {
  const [activated, setActivated] = React.useState<string[]>([])
  const [weights, setWeights] = React.useState<{[proxyId:string]: number}>({})
  const generatedColorsArray = generateColorsFromPalette(['#FF6633', '#FFB399', '#00B3E6','#E6B333', '#3366E6', '#999966',
  '#99FF99', '#B34D4D','#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A','#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A',
  '#33FFCC','#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC','#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680','#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3','#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],props.agents.length)
  const colorsByAgent = props.agents.reduce((acc,agent,i)=>{
    acc[agent.id] = generatedColorsArray[i]
    return acc
  },{})
  const handleInputChange = (event,agentId:string) => {
    const temp = Math.abs(Math.round(parseInt(event.target.value,10)))
    setWeights(prevState=>({
      ...prevState,
      [agentId] : temp
    }))
  }
  const handleActivateAgent = (agentId:string) => {
    setActivated(prev=>[...prev, agentId])
  }
  const agents = props.agents.map(agent=>({
    ...agent,
    active : activated.includes(agent.id) ? true : agent.active,
    weight : weights[agent.id] === undefined ? agent.weight : weights[agent.id]
  }))
  const finalWeights = Object.keys(weights).reduce((acc,agentId)=>{
    if (isNaN(weights[agentId])) {
      return {...acc,[agentId]:0}
    }
    return {...acc,[agentId]:weights[agentId]}
  },{})
  return (
    <Card className="AgentWeightsConfiguration">
      <CardHeader
        title={<Typography variant="h6"><Translate id="pacyConfigs.agentsWeights" /></Typography>}
        avatar={<Icon>build</Icon>}
      />
      <Divider />
      <CardContent className="Content">
        <div className="WeightInputs">
          <Table>
            <TableBody>
              {agents.map((agent,i)=>{
                return (
                  <TableRow key={agent.id}>
                    <TableCell style={agent.active ? {backgroundColor:generatedColorsArray[i]} : {backgroundColor:'gray'}}></TableCell>
                    <TableCell>
                      {agent.name || agent.provider + " - " + agent.providerId}
                      {!agent.active && <Button onClick={()=>handleActivateAgent(agent.id)} color="primary">Activate</Button>}
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled ={!agent.active}
                        type="number"
                        onChange={(event)=>handleInputChange(event,agent.id)}
                        style={{width:80}}
                        variant="outlined"
                        name={agent.name || agent.provider + " - " + agent.providerId}
                        value={Math.round(agent.weight)}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <Divider orientation="vertical" />
        <div className="Graph">
          <AgentsWeightsGraph
            agents={agents}
            colors={colorsByAgent}
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions className="AgentWeightsConfigurationFooter">
        <Button onClick={props.handleCloseModal} variant="outlined" color="secondary"><Translate id="common.cancel" /></Button>
        <Button onClick={()=>{props.handleCloseModal();props.handleWeightsChange(finalWeights,activated)}} style={{color:'#FFF'}} variant="contained" color="primary">
          <Translate id="pacyConfigs.done" />
        </Button>
      </CardActions>
    </Card>
  )
}

export default AgentWeightsConfiguration
