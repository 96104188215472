import axios, {Method}      from "axios"
import DateUtils            from "./DateUtils"
import Period               from "../model/Period"
import {
  PacyAccount,
  SpendAttempt,
  PossibleAgent,
  V0Usage,
}                           from "../model/pacy/"
import {PacingRatioConfig}  from "../model/pacy/PacingRatioConfig"
import {
  Decision,
}                           from "../model/pacy/Decision"
import {
  AccountState
}                           from "../model/pacy/AccountState"
import {
  Guideline
}                           from "../model/pacy/Guideline"
import {StringMap}          from "../model/generics"
import { AccountModifier }  from "../model/pacy/AccountModifiers"
import chroma               from 'chroma-js'
import { Proxy }            from '../model/pacy'


interface GetAccountStateReturn {
  version : number
  state   : AccountState
}

export const getColorsByProxy = (proxies: Proxy[]) => {
  const generateColorsFromPalette = (colors:string[], numberOfItems:number):string[] => {
    if(numberOfItems <= colors.length){return colors}

    //Each gap between colors in palette will have a number of colors to generate
    const numberOfColorsInEachGap = []
    for(let i = 0; i < colors.length-1; ++i){
      numberOfColorsInEachGap[i] = 0
    }
    const numberOfExtraItems = numberOfItems - colors.length

    for(let i = 0; i < numberOfExtraItems; ++i){
      numberOfColorsInEachGap[i%numberOfColorsInEachGap.length]++
    }

    const newPalette = [...colors]
    for(let i = 0; i < numberOfColorsInEachGap.length; ++i){
      const insertIndex = newPalette.indexOf(colors[i])+1
      const tempColors = chroma.scale([colors[i], colors[i+1]]).mode("lch").colors(2+numberOfColorsInEachGap[i])
      const toInsert = tempColors.slice(1, tempColors.length-1)
      newPalette.splice(insertIndex, 0, ...toInsert)
    }
    return newPalette
  }
  const generatedColorsArray = generateColorsFromPalette(['#FF6633', '#FFB399', '#00B3E6',
  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'], proxies.length)
  const colorsByProxy = proxies.reduce((acc,proxy,i)=>{
    acc[proxy.id] = generatedColorsArray[i]
    return acc
  },{})

  return colorsByProxy
}

export default class Pacy{
  protected static getApiUrl(){
    return process.env.REACT_APP_PACY_URL
  }
  public static doRequest<T>(method:Method, timeout:number, endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    const url = ("/"+endpoint).replace("//","/")
    return axios.request<T>({
      method,
      baseURL : Pacy.getApiUrl(),
      url     : url,
      params  : queryParams,
      data    : body,
      headers : {
        ...headers,
        Authorization : `Bearer ${token}`
      },
      timeout : timeout,
    })
  }
  public static get<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Pacy.doRequest<T>("get", 10*1000, endpoint, token, queryParams, body, headers)
  }
  public static put<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Pacy.doRequest<T>("put", 60*1000, endpoint, token, queryParams, body, headers)
  }
  public static post<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Pacy.doRequest<T>("post", 60*1000, endpoint, token, queryParams, body, headers)
  }
  public static delete<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Pacy.doRequest<T>("delete", 60*1000, endpoint, token, queryParams, body, headers)
  }
  public static patch<T>(endpoint:string, token?:string, queryParams?:any, body?:any, headers?:any){
    return Pacy.doRequest<T>("patch", 200*1000, endpoint, token, queryParams, body, headers)
  }
  public static getMockMarketStats(token:string, period:Period, min:number=0, max:number=250){
    const endpoint = "/Mock/Market"
    const params = {
      //token
      from : DateUtils.format(period.dateFrom),
      to   : DateUtils.format(period.dateTo),
      min,
      max,
    }
    return Pacy.get(endpoint, token, params)
  }
  public static getMockSimpleAccount(token:string){
    const endpoint = "/Mock/Account/Simple"
    const params = {/*token*/}
    return Pacy.get(endpoint, token, params)
  }
  public static getMockComplexAccount(token:string){
    const endpoint = "/Mock/Account/Complex"
    const params = {/*token*/}
    return Pacy.get(endpoint, token, params)
  }
  public static getHGregAccount(token:string){
    const endpoint = "/Mock/Account/10525March"
    const params = {/*token*/}
    return Pacy.get(endpoint, token, params)
  }
  public static runSimulation(token:string, config){
    const endpoint = "/Simulation"
    return Pacy.post(endpoint, undefined, config)
  }

  public static getAccount(token:string, accountId:number) {
    return Pacy.get<PacyAccount>("/Account/"+accountId, token)
  }
  public static getSpendAttempts(token:string, accountId:number, dateFrom:string, dateTo:string) {
    return Pacy.get<StringMap<SpendAttempt[]>>("/Account/"+accountId+"/SpendAttempt", token, {dateFrom, dateTo})
  }
  public static getDecisions(token:string, accountId:number, dateFrom:string, dateTo:string) {
    return Pacy.get<StringMap<Decision[]>>("/Account/"+accountId+"/Decision", token, {dateFrom, dateTo})
  }
  public static addGuideline(token:string, accountId:number, proxyGroupId, guideline:Guideline) {
    return Pacy.post<StringMap<Decision[]>>(
      "/Account/"+accountId+"/ProxyGroup/"+proxyGroupId+"/Guideline",
      token,
      undefined, {
        year    : guideline.year,
        month   : guideline.month,
        amount  : "amount"  in guideline ? guideline.amount  : undefined,
        amounts : "amounts" in guideline ? guideline.amounts : undefined
      })
  }
  public static updatePacingRatio(token:string, accountId:number, pacingRationConfig:PacingRatioConfig) {
    return Pacy.put<PacingRatioConfig>(
      "/Account/"+accountId+"/PacingRatio",
      token,
      undefined, pacingRationConfig)
  }

  public static migrateAccount(token:string, accountId:number) {
    return Pacy.post<PacyAccount>("/Account/"+accountId, token, undefined, {save: true})
  }
  public static deleteAccount(token:string, accountId:number) {
    return Pacy.delete<any>("/Account/"+accountId, token, undefined, {"I accept reponsibility": true})
  }
  public static enableAccount(token:string, accountId:number, enabled:boolean=true) {
    return Pacy.patch<PacyAccount>("/Account/"+accountId+"/Enabled", token, undefined, {enabled: enabled})
  }

  public static getAccountState(token:string, accountId:number, accountStateId:string) {
    return Pacy.get<GetAccountStateReturn>("/Account/"+accountId+"/state/"+accountStateId, token)
  }
  public static getUsers(token:string,accountId:number) {
    return Pacy.get<{[userId:string]:string}>("/Account/"+accountId+"/Users", token)
  }
  public static dryUpdate(token:string,accountId:number,accountStateId:string,configEdits:AccountModifier[]) {
    return Pacy.patch<PacyAccount>("/DryAccount/"+accountId,token,undefined,{
      "accountStateId" : accountStateId,
      "accountModifiers" : configEdits
    })
  }
  public static savePacyConfigs(token:string,accountId:number,accountStateId:string,configEdits:AccountModifier[]) {
    return Pacy.patch<PacyAccount>("/Account/"+accountId,token,undefined,{
      "accountStateId" :  accountStateId,
      "accountModifiers" : configEdits
    })
  }

  public static getPossibleAgents(token:string, accountId:number) {
    return Pacy.get<PossibleAgent[]>("/Account/"+accountId+"/PossibleAgents", token)
  }
  public static getV0Usage(token:string, accountId:number) {
    return Pacy.post<V0Usage[]>(
      "/v0usage",
      token,
      undefined, {
        accountIds : [accountId],
      }
    )
  }
}
