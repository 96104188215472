import * as React from "react"
import Permissions from "../../model/constant/Permissions"
import AccountPermissionBoundary from "./AccountPermissionBoundary"

export default (requiredPermissions:Permissions[]) => (Component) => (props) => {
  return (
    <AccountPermissionBoundary
      permissions={requiredPermissions}
      onFailure={<Component {...props} readOnly />}
    >
      <Component {...props}/>
    </AccountPermissionBoundary>
  )
}
