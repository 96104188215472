import './index.css'
import * as React                  from "react"
import keydown                     from 'react-keydown'
import {connect}                   from "react-redux"
import {withLocalize, Translate}   from "react-localize-redux"
import {
  Form,
  TextInput,
  Button,
  Checkbox,
  Loading
}                                  from "carbon-components-react"
import {Link}                      from "react-router-dom"
import {getUser}                   from "./action/Login"
import {
  rememberUser,
  clearRememberMeCookies
}                                  from "./action/RememberMe"
import CookieUtils                 from "../../utils/CookieUtils"
import MaintenanceUtils            from "../../utils/MaintenanceUtils"

interface LoginProps{
  errorLogin     : string
  userLogged     : any
  isLoggingIn    : boolean
  authentifyUser : (email:string,password:string,remember:boolean)=>void
  rememberUser   : (email:string,rememberId:string)=>void
  translate     ?: (translateId:string)=>string
}
const mapStateToProps = (state) => {
  return {
    errorLogin  : state.Login.loginFailedMessage,
    userLogged  : state.Login.userLogged,
    isLoggingIn : state.Login.isLoggingIn,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    authentifyUser : (email:string ,password:string,remember:boolean)=>dispatch(getUser(email,password,remember)),
    rememberUser   : (email:string ,rememberId:string)=>dispatch(rememberUser(email,rememberId))
  }
}
const ERROR_TO_TRANSLATE_ID = {
  "invalid password" : "invalidPassword",
  "invalid email" : "invalidEmail"
}
const renderError = (error:string) => {
  if(!error){return <div></div>}
  let errorText
  if(Array.isArray(error)){errorText = error[0]}
  else{errorText = error}
  const translateId = ERROR_TO_TRANSLATE_ID[errorText.toLowerCase()]
  if(translateId){
    return <Translate id={"login.errors."+translateId}/>
  }
  return <div>{errorText}</div>
}

const isInMaintenance = MaintenanceUtils.isInMaintenance()
const isMaintenancePlanned = MaintenanceUtils.isMaintenancePlanned()
const renderMaintenance = ():JSX.Element|null => {
  if(isMaintenancePlanned){
    return (
      <div className="alert alert-info">
        <Translate id="login.maintenance.notice"/> :
        <br />
        <br />
        <Translate id="login.maintenance.start"/> : {MaintenanceUtils.getStartAt().toLocaleString()}
        <br />
        <Translate id="login.maintenance.end"/> : {MaintenanceUtils.getStopAt().toLocaleString()}
      </div>
    )
  }
  if(isInMaintenance){
    MaintenanceUtils.reloadAfterTimeElapsed(MaintenanceUtils.getTimeBeforeEnd())
    return (
      <div className="alert alert-warning">
        <Translate id="login.maintenance.unavailable"/>
        <br />
        <br />
        {MaintenanceUtils.getStopAt().toLocaleString()}
      </div>
    )
  }
}
class Login extends React.Component<LoginProps,any> {
  constructor(props){
    super(props)
    this.state = {
      email    : "",
      password : "",
      remember :  false
    }

    this.handleInputChange = this.handleInputChange.bind(this)
  }
  submitForm = (event) => {
    if(!this.props.isLoggingIn){
      this.props.authentifyUser(this.state.email, this.state.password,this.state.remember)
    }
    event.preventDefault()
  }
  @keydown('Enter')
  enterKeyPress(event){
    if(this.state.email != "" && this.state.password != "" ){this.submitForm(event)}
  }
  handleInputChange(e){
    this.setState({[e.target.name] : e.target.value})
  }
  render(){
    if(!this.props.isLoggingIn && document.cookie){
      if(isMaintenancePlanned || isInMaintenance){
        clearRememberMeCookies()
      }else{
        const cookie = CookieUtils.cookieToObj(document.cookie) as any
        if(cookie.email && cookie.UID){
          this.props.rememberUser(cookie.email,cookie.UID)
        }
      }
    }
    let error=""
    let alert=""
    if (this.props.errorLogin != undefined){
      alert = "alert alert-danger"
      error = this.props.errorLogin
    }
    return (
      <div className="pageLogin">
        <div className = "bodyLogin">
          {this.props.isLoggingIn && <Loading withOverlay/>}
          <img src={process.env.PUBLIC_URL+"/assets/img/dealerbreacher.svg"} className="headerLogin"/>
          <div className="bodyFormLogin">
            <Form className = "loginForm" onSubmit={this.submitForm}>
              <div>
                {renderMaintenance()}
              </div>
              <div className={alert}>
                {renderError(error)}
              </div>
              <div className="bodyform">
                <TextInput
                  className="loginInput"
                  name="email"
                  labelText=""
                  required
                  type="email"
                  id="email"
                  placeholder={this.props.translate("login.email")}
                  onChange={this.handleInputChange}
                  disabled={isInMaintenance}
                />
                <TextInput
                  className="loginInput"
                  name="password"
                  labelText=""
                  required
                  type="password"
                  id="password"
                  placeholder={this.props.translate("login.password")}
                  onChange={this.handleInputChange}
                  disabled={isInMaintenance}
                />
                <Checkbox
                  id="remember-me"
                  className="some-class"
                  onChange={(checked)=>this.setState({remember:checked})}
                  labelText={this.props.translate("login.rememberMe")}
                  disabled={isInMaintenance}
                />
                <Button
                  type="submit"
                  id="loginFormButton"
                  className="LoginButton"
                  kind="primary"
                  disabled={isInMaintenance}
                >
                  {this.props.translate("login.login")}
                </Button>
                <div className={`${isInMaintenance ? 'disabled' : 'clickable'} forgotPassword`}>
                  <Link
                    className={`${isInMaintenance ? 'disabled' : ''}`}
                    to={`${isInMaintenance ? '#' : '/ForgotPassword'}`}
                  >
                    <Translate id="login.forgotPassword"/>
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}
export default (
  withLocalize(
    connect(mapStateToProps,mapDispatchToProps)(
      Login
    ) as any
  )
)
