export default class MaintenanceUtils{
  public static getStartAt():Date{
    return new Date(process.env.REACT_APP_MAINTENANCE_START_AT)
  }
  public static getStopAt():Date{
    return new Date(process.env.REACT_APP_MAINTENANCE_STOP_AT)
  }
  public static getNow():Date{
    return new Date()
  }
  public static needDisplay():boolean{
    return process.env.REACT_APP_MAINTENANCE_PLANNED &&
      (process.env.REACT_APP_MAINTENANCE_PLANNED).toLowerCase() === "true"
  }
  public static isInMaintenance():boolean{
    if(MaintenanceUtils.needDisplay()){
      try{
        const start = MaintenanceUtils.getStartAt()
        const stop = MaintenanceUtils.getStopAt()
        const now = MaintenanceUtils.getNow()
        return now > start && now < stop
      }catch(error){
        console.log("A problem occured while parsing maintenance dates. Error: " + error)
        return false
      }
    }
    return false
  }
  public static isMaintenancePlanned():boolean{
    if(MaintenanceUtils.needDisplay()){
      try{
        const start = MaintenanceUtils.getStartAt()
        const now = MaintenanceUtils.getNow()
        return now < start
      }catch(error){
        console.log("A problem occured while parsing maintenance dates. Error: " + error)
        return false
      }
    }
    return false
  }
  public static getTimeBeforeEnd():number{
    const currentTime = Date.parse(MaintenanceUtils.getNow().toISOString())
    const targetTime = Date.parse(MaintenanceUtils.getStopAt().toISOString())
    const timeDifference = targetTime - currentTime
    return timeDifference
  }
  public static reloadAfterTimeElapsed(time:number):void{
    if(time > 0) {
      setTimeout(() => {
        document.location.reload()
      }, time)
    }
  }
}
