import Keystone                   from "../../utils/Keystone"
import Actions                    from "../../model/constant/actions"
import {retrieveStrategies}       from "../strategies/retrieveStrategies"
import {getToken}                 from "../../storeAccessor/Generic"
import UserActions                from "../../model/constant/UserAction"
import LogBuilder                 from "../../controller/log/LogBuilder"
import Strategy                   from "../../model/Store/Strategy/Strategy"

export const setStrategy = (accountId:number, strategy:Strategy) => (dispatch, getState) => {
	dispatch({type:Actions.SAVING_STRATEGIES,status:true})
	const token = getToken(getState())
  LogBuilder.log(UserActions.BRANDING_EDIT_STRATEGY, token, {accountId, strategy})
  let payload = {
    token     : token,
    action    : "UpdateAWStrategy",
    accountId : accountId,
    state     : strategy.vehicleState,
    ...strategy
  }
  if(payload.finalUrl    ===undefined){delete payload.finalUrl}
  if(payload.vehicleType ===undefined){delete payload.vehicleType}
  if(payload.state       ===undefined){delete payload.state}
  if(payload.vehicleState===undefined){delete payload.vehicleState}
  Keystone.post(
    payload,
    (data) => {
			dispatch({type:Actions.SAVING_STRATEGIES,status:false})
			dispatch(retrieveStrategies(accountId))
		},
    (data) => console.log(data.error)
	)
}
