import Actions                 from "../../../model/constant/actions"
import PermissionProfile       from "../../../model/Store/Permissions/PermissionProfile"
import ReducerUtils            from "../../../utils/ReducerUtils"
import ObjectUtils             from "../../../utils/ObjectUtils"

const storeProfiles = (state,action) => {
  return {all:ObjectUtils.getObjectValues(action.profiles)}
}
interface state{
  all ?: PermissionProfile[]
}
const List = ReducerUtils.createReducer<state>({},{
  [Actions.PERMISSION_PROFILE_RETRIEVED] : storeProfiles,
})
export default List
