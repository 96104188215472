import Actions          from "../../../model/constant/actions"
import ReducerUtils     from "../../../utils/ReducerUtils"

const saveBool = (state,action) => {
  return action.status
}
const RetrievingBudgetPlans = ReducerUtils.createReducer<boolean>(false,{
  [Actions.RETRIEVING_BUDGET_PLANS_FOR_ACCOUNTID] : saveBool,
})
export default RetrievingBudgetPlans
