import "./ProxyGroupSummaryChart.css"
import * as React                                     from "react"
import {connect}                                      from "react-redux"
import {createSelector}                               from "reselect"
import moment                                         from "moment"
import {withLocalize, TranslateFunction}              from "react-localize-redux"
import DateUtils                                      from "../../../../utils/DateUtils"
import NumFormatter                                   from "../../../../utils/NumberFormatter"
import MathUtils                                      from "../../../../utils/MathUtils"
import {ProxyGroup}                                   from "../../../../model/pacy/ProxyGroup"

import {
  getGuidelineAmount,
  isGuidelineForDay,
  sortGuidelines
}                                                     from "../../../../model/pacy/Guideline"
import BudgetStats                                    from "../../../../model/Store/Statistics/BudgetStats"
import Period                                         from "../../../../model/Period"
import {StringMap}                                    from "../../../../model/generics"
import {
  ResponsiveContainer,LineChart,XAxis,ReferenceLine,
  YAxis,Tooltip,Line, TooltipFormatter
}                                                     from "recharts"

interface ProxyGroupSummaryChartOwnProps{
  proxyGroup               : ProxyGroup
  shownMonths              : string[]
  requestReportingDataLoad : (period:Period)=>boolean
  budgetStatsByMonth       : StringMap<BudgetStats[]>
  guidelineColor          ?: string
  syncId                  ?: string
}
interface ProxyGroupSummaryChartProps extends ProxyGroupSummaryChartOwnProps{
  guidelineAmountByDay : Map<string, number>
  translate : TranslateFunction
}

const makeMapStateToProps = () => {
  const guidelineAmountByDayMapSelector = createSelector(
    (_,props)=>props.proxyGroup,
    (proxyGroup:ProxyGroup)=>{
      proxyGroup.guidelines.sort(sortGuidelines)
      const map = new Map()
      let day = moment().format("YYYY-MM-DD")
      for(var i = proxyGroup.guidelines.length - 1; i >= 0; i--){
        const amount = getGuidelineAmount(proxyGroup.guidelines[i])
        while(isGuidelineForDay(proxyGroup.guidelines[i], day)){
          map.set(day, amount)
          day = moment(day).subtract(1, "day").format("YYYY-MM-DD")
        }
      }
      return map
    }
  )
  return (state,ownProps:ProxyGroupSummaryChartOwnProps)=>{
    return {
      guidelineAmountByDay  : guidelineAmountByDayMapSelector(state, ownProps)
    }
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {}
}
const mergeProps = (SP,DP,ownProps)=>{
  return {...SP,...DP,...ownProps}
}

const getPeriodForShownMonths = (months:string[]):Period|undefined => {
  let dates
  if(DateUtils.isPastOrCurrentYM(months[0])){
    dates = {}
    dates.dateFrom = moment(months[0]).toDate()
    if(DateUtils.isCurrentOrFutureYM(months[months.length-1])){
      dates.dateTo = moment().toDate()
    }
    else{
      dates.dateTo = moment(months[months.length-1]).endOf("month").toDate()
    }
  }
  return dates
}

class ProxyGroupSummaryChart extends React.Component<ProxyGroupSummaryChartProps,any>{
  static defaultProps = {
    guidelineColor : "blue"
  }
  constructor(props){
    super(props)
    this.generateData = this.generateData.bind(this)
  }
  generateData(dates){
    return DateUtils.enumerateDaysBetweenMomentDates(moment(dates.dateFrom), moment(dates.dateTo)).map(day=>{
      const month = day.slice(0,7)
      const stats = this.props.budgetStatsByMonth[month] || []
      let daySpent = 0
      let upToMonthSpent = 0
      for(const stat of stats){
        if(stat.Day === day){
          daySpent += stat.Spent
          upToMonthSpent += stat.Spent
        }
        else if(stat.Day < day){
          upToMonthSpent += stat.Spent
        }
      }
      const guidelineAmountForDay = this.props.guidelineAmountByDay.get(day)/moment(month).daysInMonth() || 0
      return {
        xValue: day,
        daySpent,
        guidelineAmount : guidelineAmountForDay,
        pacing : (upToMonthSpent/(guidelineAmountForDay*moment(day).date()))*guidelineAmountForDay,
      }
    })
  }
  getTooltipFormatter():TooltipFormatter{
    const pacingFormatter = (v,payload)=>payload.guidelineAmount===0 ?
      "N/A" : NumFormatter.formatNumber(MathUtils.round(v*100/payload.guidelineAmount,2))+"%"

    const dataKeysFormatting = {
      daySpent        : {readable: this.props.translate("common.spent"),           formatter: (x) => NumFormatter.formatCurrency(x)},
      guidelineAmount : {readable: this.props.translate("pacyPlanner.investment"), formatter: (x) => NumFormatter.formatCurrency(x)},
      pacing          : {readable: "Pacing",                                       formatter: pacingFormatter }
    }
    return (value, name, props)=>{
      if(!dataKeysFormatting[name]){return [value, name]}
      return [dataKeysFormatting[name].formatter(value, props.payload), dataKeysFormatting[name].readable]
    }
  }
  render(){
    const dates = getPeriodForShownMonths(this.props.shownMonths)
    if(!dates){return <div>No dates</div>}
    if(this.props.requestReportingDataLoad(dates)){
      return <div>LOADING STATS</div>
    }
    const data = this.generateData(dates)
    const ticks = this.props.shownMonths.reduce((ticks, YM, i)=>{
      const date = moment(YM)
      ticks.push(date.format("YYYY-MM-DD"))
      /* Add current day or end of month as last tick
      if(YM === moment().format("YYYY-MM")){
        ticks.push(moment().format("YYYY-MM-DD"))
      }
      else if(i === this.props.shownMonths.length-1){
        ticks.push(date.endOf("month").format("YYYY-MM-DD"))
      }
      */
      return ticks
    },[])
    return (
      <div className="ProxyGroupSummaryChart">
        <ResponsiveContainer height={"99%"}>
          <LineChart data={data} syncId={this.props.syncId}>
            <XAxis hide tick={false} dataKey="xValue" ticks={ticks}/>
            <YAxis hide domain={[0, (d)=>d*1.2]}/>*/
            <Tooltip
              isAnimationActive={false}
              formatter={this.getTooltipFormatter()}
            />
            <Line
              type={"linear"}
              dataKey={"daySpent"}
              isAnimationActive={false}
              stroke={"#80808060"}
              fill={"#80808060"}
              dot={false}
              strokeWidth={2}
              //activeDot={{r: 6}}
              //strokeWidth={x.strokeWidth||1}
              //strokeDasharray={"4 1"}
            />
            <Line
              type={"step"}
              dataKey={"guidelineAmount"}
              isAnimationActive={false}
              stroke={this.props.guidelineColor}
              fill={this.props.guidelineColor}
              dot={false}
              strokeWidth={3}
              //activeDot={{r: 6}}
              //strokeWidth={x.strokeWidth||1}
              //strokeDasharray={"4 1"}
            />
            <Line
              type={"linear"}
              dataKey={"pacing"}
              isAnimationActive={false}
              stroke={this.props.guidelineColor}
              fill={this.props.guidelineColor}
              dot={false}
              strokeWidth={2}
              //activeDot={{r: 6}}
              //strokeWidth={x.strokeWidth||1}
              strokeDasharray={"4 2"}
            />
            {ticks.map(x=>
              <ReferenceLine
                key={x}
                x={x}
                stroke={"#cccccc"}
                strokeWidth={1}
                isFront={false}
                strokeDasharray={"3 1"}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

export default connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(
  withLocalize(
    ProxyGroupSummaryChart
  )
) as React.ComponentType<ProxyGroupSummaryChartOwnProps>
