import {combineReducers,Reducer } from 'redux'
import {Menu}			                from "../menu/Menu"
import {Login}                    from "../login/Login"
import {Accounts}                 from "../accounts/Accounts"
import {Campaigns}                from "../campaigns/Campaigns"
import {Params}                   from "../Params/Params"
import {BudgetForStrategies}      from "../BudgetForStrategies/BudgetForStrategies"
import {ScreenSize}               from "../ScreenSize"
import Period                     from "../Period"
import Feeds                      from "../feeds/Feeds"
import {Users}                    from "../users/Users"
import Permissions                from "../permissions/Permissions"
import {Inventory}                from "../inventory/Inventory"
import {Statistics}               from "../statistics/Statistics"
import {EditCopies}               from "../editCopies/EditCopies"
import {Reports}                  from "../reports/Reports"
import {Tracking}                 from "../tracking/Tracking"
import {Waze}                     from "../waze/Waze"
import {BiddingStrategies}        from "../biddingStrategies/BiddingStrategies"
import {GrammarBooster}           from "../grammarBooster/GrammarBooster"
import CreateUser                 from "../CreateUser"
import WhiteLabels                from "../whiteLabels/WhiteLabels"
import AdBuilder                  from "../adBuilder/AdBuilder"
import Audiences                  from "../audiences/Audiences"
import Cleaner                    from "../cleaner/Cleaner"
import Pacy                       from "../pacy/Pacy"
import Navigation                 from "../navigation/Navigation"
import Alerts                     from "../alerts/Alerts"

export const allReducers = combineReducers({
  Menu,
  Accounts,
  Campaigns,
  Params,
  Login,
  CreateUser,
  Users,
  Feeds,
  Permissions,
  Period,
  Statistics,
  Inventory,
  BudgetForStrategies,
  EditCopies,
  ScreenSize,
  Reports,
  Tracking,
  Waze,
  BiddingStrategies,
  GrammarBooster,
  Cleaner,
  WhiteLabels,
  AdBuilder,
  Audiences,
  Pacy,
  Navigation,
  Alerts,
})

export type State = ReturnType<typeof allReducers>
