import './CampaignsList.css'
import * as React                        from "react"
import {connect}                         from 'react-redux'
import {
  withLocalize,
  TranslateFunction
}                                        from "react-localize-redux"
import StatisticsAccessors               from "../../../../storeAccessor/Statistics"
import DisplayCampaign                   from "../../../../model/Store/Campaign/DisplayCampaign"
import {retrieveDisplayCampaigns}        from "../../../../actions/campaign/displayCampaigns"
import retrieveDesiredStatus             from "../../../../actions/desiredStatusException/retrieveDesiredStatusExceptions"
import GoBack                            from "../../../../components/Button/LinkGoBack"
import {Panel}                           from "../../../../components/Panel/Panel"
import ExplainedLoading                  from "../../../../components/loading/"
import CampaignsTable                    from "../shared/CampaignsTable"
import TranslatedGoBackLarge             from '../../../../components/notifications/TranslatedGoBackLarge'

interface CampaignsListProps{
  accountId                  : number
  statistics                 : any[]
  campaigns                  : DisplayCampaign[]
  retrievingCampaigns        : boolean
  statusExceptions           : any
  getDesiredStatusExceptions : ()=>void
  retrieveDisplayCampaigns   : ()=>void
  translate                  : TranslateFunction
}

const makeMapStateToProps = ()=>{
  const statisticsSelector = StatisticsAccessors.makeCampaignsStatsInCurrentPeriodSelector()
  return state => {
    const accountId = state.Accounts.selected
    return {
      accountId           : accountId,
      statistics          : statisticsSelector(state),
      retrievingCampaigns : state.Campaigns.Display.Retrieving,
      statusExceptions    : state.Campaigns.DesiredStatusExceptions[accountId],
      campaigns           : state.Campaigns.Display.Campaigns[accountId],
    }
  }
}
const mapDispatchToProps = (dispatch)=>{
  return {
    getDesiredStatusExceptions : (accountId:number) => dispatch(retrieveDesiredStatus(accountId)),
    retrieveDisplayCampaigns   : (accountId:number) => dispatch(retrieveDisplayCampaigns(accountId))
  }
}
const mergeProps = (SP,DP,ownProps)=>{
  return {
    ...SP,...DP,...ownProps,
    getDesiredStatusExceptions : ()=>DP.getDesiredStatusExceptions(SP.accountId),
    retrieveDisplayCampaigns   : ()=>DP.retrieveDisplayCampaigns(SP.accountId)
  }
}

class CampaignsList extends React.Component<CampaignsListProps,any>{
  constructor(props){
    super(props)
    this.getCampaignStatus = this.getCampaignStatus.bind(this)
  }
  componentDidMount(){
    if(this.props.campaigns === undefined && !this.props.retrievingCampaigns){
      this.props.retrieveDisplayCampaigns()
    }
    if(this.props.statusExceptions === undefined){
      this.props.getDesiredStatusExceptions()
    }
  }
  componentDidUpdate(prevProps){
    if(this.props.campaigns === undefined && !this.props.retrievingCampaigns){
      this.props.retrieveDisplayCampaigns()
    }
    if(this.props.statusExceptions === undefined){
      this.props.getDesiredStatusExceptions()
    }
  }
  getCampaignStatus(campaign:DisplayCampaign){
    /*
    Status exceptions not yet supported for display campaigns
    if(this.props.statusExceptions != undefined && this.props.statusExceptions.Enabled){
      if(this.props.statusExceptions.Enabled.find(name=>name===campaign.name)){return "Active"}
      if(this.props.statusExceptions.Paused.find(name=>name===campaign.name)){return "Pause"}
    }
    */
    return campaign.status==="ENABLED"?"Active":"Paused"
  }
  render(){
    if(this.props.campaigns === undefined || this.props.retrievingCampaigns){return <ExplainedLoading translateId="loadings.campaigns"/>}
    if(this.props.statistics === undefined){return <ExplainedLoading translateId="loadings.stats"/>}
    if(this.props.campaigns.length === 0) {
      return (
        <TranslatedGoBackLarge type="link" translationId="campaigns.list.noCampaigns"/>
      )
    }
    const data = this.props.campaigns.map(campaign=>{
      const campaignStats = this.props.statistics.filter(stat=>stat.CampaignAWId+"" === campaign.externalCampaignId+"")
      const clicks      = campaignStats.reduce((total,currentValue) => total + currentValue.Clicks,0)
      const cost        = campaignStats.reduce((total,currentValue) => total + currentValue.Cost,0)
      return {
        accountId     : this.props.accountId,
        campaigns     : [campaign],
        id            : campaign.externalCampaignId,
        status        : this.getCampaignStatus(campaign),
        name          : campaign.name,
        clicks        : clicks,
        CPC           : clicks ? cost/clicks : 0,
        cost          : cost,
      }
    })
    return(
      <Panel title={this.props.translate("campaigns.list.title") as string} subtitle={this.props.translate("campaigns.displayRmktSubtitle") as string}>
        <GoBack/>
        <CampaignsTable
          data={data}
          columns={["id","simpleStatus","name","clicks","CPC","cost"]}
        />
      </Panel>
    )
  }
}

export default withLocalize(connect(makeMapStateToProps,mapDispatchToProps,mergeProps)(CampaignsList))
