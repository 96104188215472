import './SubMenu.css'
import * as React                from "react"
import {MenuButtonDescription}   from "./menuButtonSignature"
import MenuButton                from "./MenuButton"
import {filterDisplayedButtons}  from "./SideMenu"
import {PermissionContext}       from "../../../../components/context/permissionContext"

interface  SubMenuOwnProps {
  buttons        : MenuButtonDescription[]
  path           : string
  parentActive   : boolean
  activeButton   : string
  activateButton : (button:MenuButtonDescription)=>void
}
interface SubMenuProps extends SubMenuOwnProps {
  parentButton    : MenuButtonDescription
}

const SubMenu = (props:SubMenuProps) => {
  if(!props.parentActive || !props.buttons || props.buttons.length === 0){return null}
  return (
    <ul className="nav">
      <PermissionContext.Consumer>{context=>
        filterDisplayedButtons(props.buttons, context).map(button=>
          <MenuButton
            parentButton={props.parentButton}
            key={button.name}
            button={button}
            accountPath={props.path}
            activeButton={props.activeButton}
            activateButton={props.activateButton}
          />
        )
      }
      </PermissionContext.Consumer>
    </ul>
  )
}

export default SubMenu

