export enum SelectorOperator {
  AT_LEAST      = "AT_LEAST",
  AT_MOST       = "AT_MOST",
  IS_NOT        = "IS_NOT",
  IS            = "IS",
  IS_NOT_REGEX  = "IS_NOT_REGEX",
  IS_REGEX      = "IS_REGEX",
}
export const SelectorOperatorText = {
  [SelectorOperator.AT_LEAST]     : ()=>"At least",
  [SelectorOperator.AT_MOST]      : ()=>"At most",
  [SelectorOperator.IS_NOT]       : ()=>"Is not",
  [SelectorOperator.IS]           : ()=>"Is",
  [SelectorOperator.IS_NOT_REGEX] : ()=>"Does not match",
  [SelectorOperator.IS_REGEX]     : ()=>"Matches",
}
export const SelectorOperatorTextId = {
  [SelectorOperator.AT_LEAST]     : ()=>"rules.atLeast",
  [SelectorOperator.AT_MOST]      : ()=>"rules.atMost",
  [SelectorOperator.IS_NOT]       : ()=>"rules.isNot",
  [SelectorOperator.IS_NOT_REGEX] : ()=>"rules.isNotRegex",
  [SelectorOperator.IS]           : ()=>"rules.is",
  [SelectorOperator.IS_REGEX]     : ()=>"rules.isRegex",
}
export enum SelectorValueType {
  NOT_EMPTY_STRING,
  POSITIVE_NUMBER,
}
export const ValueTypeMapping = {
  [SelectorValueType.POSITIVE_NUMBER]  : {type:"text",  validate:(x)=>!isNaN(x) && x>0},
  [SelectorValueType.NOT_EMPTY_STRING] : {type:"text",  validate:(x)=>x.length>0},
}
export enum SelectorStandardName {
  NumberOfPhotos = "NumberOfPhotos",
  NumberOfDays   = "NumberOfDays",
  Make           = "Make",
  Model          = "Model",
  Price          = "Price",
  Year           = "Year",
  StockNumber    = "StockNumber",
}
export interface SelectorAbstract {
  name            : SelectorStandardName
  niceName        : string
  niceNameId      : string
  operators       : SelectorOperator[]
  valueType       : SelectorValueType
  defaultValue    : ()=>string|number
  defaultOperator : SelectorOperator
  icon            : string
}
export interface Selector extends SelectorAbstract {
  id       : number
  value    : string|number
  operator : SelectorOperator
  addedOn ?: Date
  addedBy ?: number
}
