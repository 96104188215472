import "./IdentitySetup.css"
import * as React                            from "react"
import {
  withLocalize,
  Translate,
  LocalizeContextProps
}                                            from "react-localize-redux"
import {
  ProtoMarketingEvent,
  AdBuilderCompatibleCampaignType,
  AdBuilderCompatibleLang,
  AdBuilderCompatibleCampaignGroup
}                                            from "../../../model/adbuilder/"
import requiresStrategies                    from "../../../components/loaders/strategiesLoader"
import Strategy, {HeadstoneActiveStrategies} from "../../../model/Store/Strategy/Strategy"
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
}                                            from "@material-ui/core"
import {KeyboardDatePicker}                  from "@material-ui/pickers"
import ExplainedLoading                      from "../../../components/loading/index"

interface IdentitySetupProps extends LocalizeContextProps{
  event             : ProtoMarketingEvent
  setTitle          : (title:string) => void
  setGroup          : (state:AdBuilderCompatibleCampaignType, index:number) => void
  setLang           : (lang:string) => void
  setStart          : (start:Date) => void
  setEnd            : (end:Date) => void
  strategies        : Strategy[]
  activeStrategies  : HeadstoneActiveStrategies
}

const IdentitySetup =(props:IdentitySetupProps) => {
  const [title, setTitle] = React.useState<string>(props.event.title)
  const [type, setType] = React.useState<string>(props.event.boundaries[0].vehicleState||props.event.boundaries[0]?.campaignType||"")
  const lang = props.event.lang || "ALL"

  React.useEffect(()=>{
    setTitle(props.event.title)
  },[props.event.title])

  React.useEffect(()=>{
    setType(props.event.boundaries[0].vehicleState||props.event.boundaries[0]?.campaignType||"")
  },[props.event.boundaries])

  const onTitleBlur = (e) => {
    props.setTitle(e.target.value)
  }

  const onGroupBlur = (e) => {
    if(e.target.value !== "NONE"){
      props.setGroup(e.target.value, 0)
    }
  }

  const onLangBlur = (e) => {
    if(e.target.value !== "NONE"){
      props.setLang(e.target.value)
    }
  }

  const onStartBlur = (date:Date) => {
    if(props.event.endDate && props.event.endDate.getTime() < date.getTime()){
      props.setEnd(new Date(date))
    }
    props.setStart(date)
  }

  const onEndBlur = (date:Date) => {
    props.setEnd(date)
  }

  if (props.activeStrategies === undefined){return <ExplainedLoading  translateId="loadings.strategies"/>}
  const availableGroups:string[] = [] 
  // Inventory strategies
  availableGroups.push(
    ...Object.keys(AdBuilderCompatibleCampaignType).filter(x=>
      props.strategies.some(strategy=>strategy.active && strategy.vehicleState && strategy.vehicleState.toUpperCase() === x)
    )
  )
  // Active strategies for non inventory campaign types
  availableGroups.push(
    ...Object.keys(AdBuilderCompatibleCampaignType).filter(x=>
      Object.keys(props.activeStrategies).some(strategy=>strategy.toUpperCase() === x)
    )
  )

  const campaignGroups = Object.keys(AdBuilderCompatibleCampaignGroup)
  let availableLanguages = ["ALL"]
  availableLanguages.push(...Object.keys(AdBuilderCompatibleLang).filter(lang =>
    props.strategies.some(strategy => campaignGroups.includes(strategy.group) && strategy.lang === lang)
  ))

  if(availableGroups.length === 1 && !props.event.boundaries[0].vehicleState){
    //Automatically select state if only one possible
    props.setGroup(availableGroups[0] as any, 0)
  }
  return (
    <div className="IdentitySetup">
      <h5 className="IdentitySectionTitle">
        <Translate id="adBuilder.createEvent.identityTitle"/>
      </h5>
      <div className="Identity">
        <div className="TitleAndCampaignGroup">
          <TextField
            className="Input"
            label={props.translate("adBuilder.createEvent.marketingApproachTitle")}
            value={title||""}
            onChange={(e)=>setTitle(e.target.value)}
            onBlur={onTitleBlur}
          />
          <FormControl>
            <InputLabel>
              <Translate id="adBuilder.createEvent.campaignGroupSelection"/>
            </InputLabel>
            <Select
              className="Input"
              value={type}
              onChange={onGroupBlur}
              disabled={Boolean(props.event.id)}
            >
              {availableGroups.map(state=>
                <MenuItem key={state} value={state}>{props.translate(`adBuilder.createEvent.${state}`)}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>
              <Translate id="adBuilder.createEvent.languageSelection"/>
            </InputLabel>
            <Select
              className="Input"
              value={lang}
              onChange={onLangBlur}
            >
              {availableLanguages.map(lang=>
                <MenuItem key={lang} value={lang}>{lang}</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        <div className="DatePickers">
          <KeyboardDatePicker
            className="Input"
            label={props.translate("adBuilder.createEvent.startDate")}
            value={props.event.startDate||null}
            onChange={date => onStartBlur(date)}
            animateYearScrolling
            minDate={new Date()}
            minDateMessage=""
            clearable
            format="yyyy/MM/dd"
            placeholder="yyyy/MM/dd"
          />
          <KeyboardDatePicker
            className="Input"
            label={props.translate("adBuilder.createEvent.endDate")}
            value={props.event.endDate||null}
            onChange={date => onEndBlur(date)}
            animateYearScrolling
            minDate={props.event.startDate}
            clearable
            format="yyyy/MM/dd"
            placeholder="yyyy/MM/dd"
          />
        </div>
      </div>
    </div>
  )
}

export default requiresStrategies()(withLocalize(IdentitySetup))
