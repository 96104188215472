import styles                                     from "./MarketingEventSetupStyles"
import * as React                                 from "react"
import {connect}                                  from "react-redux"
import {State}                                    from '../../../reducers/generic/reducerAssembly'
import RoutingUtils                               from "../../../utils/RoutingUtils"
import {
  startEditing,
  updateTitle,
  updateDate,
  updateBoundaries,
  setApproaches,
  addBoundary,
  removeBoundary,
  updateLang
}                                                 from "../../../actions/adBuilder/editing"
import {createMarketingEvent, editMarketingEvent} from "../../../actions/adBuilder/events"
import Actions                                    from "../../../model/constant/actions"
import {
  ProtoMarketingApproach,
  MarketingEvent,
  AdBuilderCompatibleCampaignGroup,
  MEBCampaignGroupsMapping,
  MarketingEventBoundariesTypes as MEBTypes,
  AdBuilderCompatibleLang,
  AdBuilderCompatibleVehicleState,
  AdBuilderCompatibleVehicleType
}                                                 from "../../../model/adbuilder"
import Audience                                   from "../../../model/Store/Audiences/AWSearch"
import {ReferenceRow}                             from "../../../model/Store/Cleaner"
import isBoundedIn                                from "../utils/CleanerAndBoundaries"
import {findShortestAndLongestStrings}            from "../utils/FindAdCopyLength"
import requiresInventory                          from "../../../components/loaders/inventoryLoader"
import requiresFriends                            from "../../../components/loaders/friendsLoader"
import requiresMarketingEvents                    from "../../../components/loaders/marketingEventsLoader"
import requiresCleaner                            from "../../../components/loaders/cleanerLoader"
import Vehicle                                    from "../../../model/Store/Vehicle/Vehicle"
import Friend                                     from "../../../model/Store/Campaign/Friend"
import InventoryFriend                            from "../../../model/Store/Campaign/InventoryFriend"
import MarketingEventIdentity                     from "./MarketingEventIdentity"
import EditorStateManager                         from "./EditorStateManager"
import {getActiveStrategies}                      from "../../../actions/AdCopyPatterns"
import ExplainedLoading                           from "../../../components/loading/index"
import {
  MarketingEventFactory,
  getEmptyProtoMarketingApproach
}                                                 from "../../../model/adbuilder/factories"
import {
  withLocalize,
  LocalizeContextProps
}                                                 from "react-localize-redux"
import {getRSAAdCopies}                           from '../../../actions/AdCopyPatterns'
import {AdCopyRSAPatterns}                        from "../../../model/adcopy"

interface MarketingEventSetupOwnProps extends LocalizeContextProps{
  allLangFriends : Friend[]
  inventory      : Vehicle[]
  history       ?: any
  eventId       ?: number
  readOnly      ?: boolean
}

const mapStateToProps = (state:State, _ownProps:MarketingEventSetupOwnProps)=>{
  const accountId = state.Accounts.selected
  return {
    accountId,
    event                : state.EditCopies.AdBuilder.Event.current,
    events               : state.AdBuilder.Events[accountId],
    audiences            : state.Audiences.AWSearch[accountId],
    saving               : state.AdBuilder.Saving.status,
    failedSaving         : state.AdBuilder.FailedSaving.failed,
    failedMessage        : state.AdBuilder.FailedSaving.message,
    activeStrategies     : state.Params.AdCopies.Strategies.ActiveStrategies[accountId],
    retrievingStrategies : state.Params.AdCopies.Strategies.Retrieving,
    failedStrategies     : state.Params.AdCopies.Strategies.Failed,
    adcopies             : state.Params.AdCopies.RSAAdcopies.AdCopies[accountId],
    retrievingAdcopies   : state.Params.AdCopies.RSAAdcopies.Retrieving,
  }
}

const mapDispatchToProps = (dispatch)=>{
  return {
    start               : (event?:MarketingEvent)=>dispatch(startEditing(event)),
    reset               : ()=>dispatch({type: Actions.CLEAR_MARKETING_EVENT_CREATE_COPY}),
    updateLang          : (lang:string)=>dispatch(updateLang(lang)),
    updateTitle         : (title:string)=>dispatch(updateTitle(title)),
    updateStartDate     : (date:Date)=>dispatch(updateDate(date)),
    updateEndDate       : (date:Date)=>dispatch(updateDate(undefined, date)),
    updateState         : (state:string|undefined,index:number)=>dispatch(updateBoundaries(
      {
        vehicleState:state,
        vehicleType: AdBuilderCompatibleVehicleType.CAR
      },
    index)),
    updateGroup         : (group:string|undefined,index:number)=>dispatch(updateBoundaries({campaignType:group},
    index)),
    updateYear          : (year:number|undefined,index:number)=>dispatch(updateBoundaries({year},index)),
    updateMake          : (make:string|undefined,index:number)=>dispatch(updateBoundaries({make},index)),
    updateModel         : (model:string|undefined,index:number)=>dispatch(updateBoundaries({model},index)),
    updateCampaignName  : (index:number, campaignName?:string, campaignGroup?:string)=>(
      dispatch(updateBoundaries({campaignName, campaignGroup}, index))
    ),
    setApproaches       : (approaches:ProtoMarketingApproach[])=>dispatch(setApproaches(approaches)),
    createEvent         : (...args:Parameters<typeof createMarketingEvent>)=>(dispatch(createMarketingEvent(...args))),
    editEvent           : (...args:Parameters<typeof editMarketingEvent>)=>(dispatch(editMarketingEvent(...args))),
    addBoundary         : (type?:MEBTypes) => (dispatch(addBoundary(type))),
    removeBoundary      : (index:number) => (dispatch(removeBoundary(index))),
    getActiveStrategies : (...args:Parameters<typeof getActiveStrategies>) => dispatch(getActiveStrategies(...args)),
    getRSAAdCopies      : (...args:Parameters<typeof getRSAAdCopies>) => dispatch(getRSAAdCopies(...args)),
  }
}

const mergeProps = (SP,DP,ownProps)=>{
  let ready = true
  if(!ownProps.eventId && !SP.event){
    ready = false
    DP.start()
  }
  if(ownProps.eventId !== undefined && (!SP.event || (SP.event && SP.event.id !== ownProps.eventId))){
    ready = false
    const event = SP.events.find(x=>x.id == ownProps.eventId)
    if(!event){
      console.warn("Tried to edit an event that doesn't exist")
      RoutingUtils.removeLastElementOfUrl(ownProps.history)
    }
    DP.start(event)
  }
  return {
    ...SP,...DP,...ownProps,
    ready,
    createEvent : (event:MarketingEvent, urlOnSuccess?:string)=>DP.createEvent(SP.accountId, event, urlOnSuccess),
    editEvent   : (event:MarketingEvent, urlOnSuccess?:string)=>DP.editEvent(SP.accountId, event, urlOnSuccess),
  }
}

type MarketingEventSetupProps = ReturnType<typeof mergeProps>

const MarketingEventSetup = (props:MarketingEventSetupProps) => {
  if(props.activeStrategies === undefined && !props.retrievingStrategies && !props.failedStrategies){
    props.getActiveStrategies(props.accountId)
    return <ExplainedLoading  translateId="loadings.strategies"/>
  }

  if(!props.ready){return null}
  const classes = styles()
  const [editing, setEditing] = React.useState<boolean>(false)
  const {SRPPoolIds, isVehicleBoundedIn} = isBoundedIn(
    props.event.boundaries[0],
    props.cleanerReference,
    props.allLangFriends.filter(x=>x instanceof InventoryFriend) as InventoryFriend[]
  )

  const matchingInventory = (
    (
      !props.event 
      || !props.event.boundaries[0] 
      || !props.event.boundaries[0].type 
      || props.event.boundaries[0].type === MEBTypes.NON_INVENTORY
    )
    ? props.inventory
    : props.inventory.filter(isVehicleBoundedIn)
  )

  const computeApproachesGroups = ():AdBuilderCompatibleCampaignGroup[] => {
    let groups = []
    props.event.boundaries.forEach(boundary=>{
      if(boundary.type === MEBTypes.SPECIFIC_CAMPAIGN){
        //When it's a specific campaign, we have to look at the campaign's group
        groups.push(boundary.campaignGroup)
      }
      if (boundary.vehicleState && boundary.vehicleType && boundary.type){
        groups.push(...MEBCampaignGroupsMapping[boundary.type])
      }
    })
    return [...new Set(groups.filter(x=>x!==undefined))]
  }

  const generateDefaultAudiences = ():string[] => {
    const approachRLSA = props.event.rsaApproaches.find(x=>x.audienceAWId!==undefined)
    if(approachRLSA){return approachRLSA.audienceAWId}
    if(!props.audiences || props.audiences.length === 0){return []}
    let biggest:Audience
    for(const audience of props.audiences){
      if(!biggest || audience.size > biggest.size){
        biggest = audience
      }
    }
    return [biggest.id+'']
  }

  const findLanguagesForGroup = (group:string):string[] => {
    let langs = []
    let campaignType:string
    // inventory
    if (props.event.boundaries[0].vehicleState){
      campaignType = props.event.boundaries[0].vehicleState === "NEW"
        ? "Inventory - New"
        : "Inventory - Used"
      Object.keys(props.activeStrategies[campaignType][group]).forEach(key=>{
        langs.push(...props.activeStrategies[campaignType][group][key])
      })  
    // non inventory
    } else {
      const strategy = Object.keys(props.activeStrategies).find(x=>x.toUpperCase()===group.toUpperCase())
      // key represents adgroup or campaign name depending on campaign type
      // deeperKey is audience
      Object.keys(props.activeStrategies[strategy]).forEach(key=>{
        Object.keys(props.activeStrategies[strategy][key]).forEach(deeperKey=>{
          langs.push(...props.activeStrategies[strategy][key][deeperKey])
        })
      })
    }
    return [...new Set(langs)]
  }

  const inventoryBasedSRPPoolIds = new Set<number>(matchingInventory.map(v=>v.getSRPPoolId()))
  const availableRows : Array<ReferenceRow&{inStock:boolean}> = (
    props.cleanerReference
    .filter(row=>SRPPoolIds.has(parseInt(row.SRPable+"",10)))
    .map(row=>({...row, inStock : inventoryBasedSRPPoolIds.has(parseInt(row.SRPable+"",10))}))
  )
  const stringLengths = findShortestAndLongestStrings(availableRows)

  const saveEvent = () => {
    const realEvent = MarketingEventFactory(props.event)
    props.eventId
      ? props.editEvent(realEvent)
      : props.createEvent(realEvent)
  }

  const findApproach = (group: string, lang: string, rlsa: boolean): boolean => {
    const getApproach = (approach) => approach.group === group
      && (rlsa ? approach.audienceAWId !== undefined : approach.audienceAWId === undefined)
      && approach.lang === lang
    return !!(props.event.rsaApproaches.find(getApproach))
  }

  const findNonInventoryApproach = (campaignType:string, option:string, secondOption:string, lang: string, rlsa: boolean): boolean => {
    campaignType = campaignType.toUpperCase()
    const getApproach = (approach) => approach.group === option
      && approach.adGroupName === secondOption
      && approach.lang === lang
    return campaignType === "BRANDING" 
      ? findApproach(option, lang, rlsa)
      : !!(props.event.rsaApproaches.find(getApproach))
  }
  
  const handleGoToEditor = () => {
    // avoid the ad generation logic if it's not a new event
    if (props.event.rsaApproaches.length>0){
      setEditing(true)
      return
    }
    const groups = computeApproachesGroups()
    groups.forEach(group=>{
      const audiences = generateDefaultAudiences()
      let approaches = props.event.rsaApproaches
      const vehicleState = props.event.boundaries[0].vehicleState
      const campaignType = vehicleState === "NEW" ? "Inventory - New" : "Inventory - Used"
      findLanguagesForGroup(group).forEach((lang) => {
        const templates = props.adcopies[campaignType].filter((adcopy:AdCopyRSAPatterns)=>
          adcopy.matcher.group === group
          && adcopy.matcher.lang === lang
        )
        if (!findApproach(group, lang, false)){
          approaches.push(
            getEmptyProtoMarketingApproach(
              group,
              lang as AdBuilderCompatibleLang,
              false,
              undefined,
              vehicleState,
              undefined,
              undefined,
              templates.find(template=>template.audienceId==="NO_AUDIENCE").templates
            )
          )
        }
        // We have audiences, we setup RLSA approaches too
        if (audiences.length > 0){
          if (!findApproach(group, lang, true)){
            approaches.push(
              getEmptyProtoMarketingApproach(
                group,
                lang as AdBuilderCompatibleLang,
                true,
                audiences,
                vehicleState,
                undefined,
                undefined,
                templates.find(template=>template.audienceId!=="NO_AUDIENCE")?.templates
                || templates.find(template=>template.audienceId==="NO_AUDIENCE").templates
              )
            )
          }
        }
      })
      if(props.event.lang !== "ALL") {
        approaches = approaches.filter(approach => approach.lang === props.event.lang)
      }
      props.setApproaches(approaches)
    })
    setEditing(true)
  }

  const handleGoToNonInventoryEditor = () => {
    // avoid the ad generation logic if it's not a new event
    if (props.event.rsaApproaches.length>0){
      setEditing(true)
      return
    }
    // Build the proper select structure for non inventory
    // BRANDING           = Ad group - audience - lang
    // CREDIT & SERVICE   = Campaign name - adgroup - lang
    const audiences = generateDefaultAudiences()
    let approaches = props.event.rsaApproaches
    const currentCampaignType = Object.keys(props.activeStrategies).find(type =>
      type.toUpperCase() === props.event.boundaries[0].campaignType.toUpperCase()
    )
    const currentCampaignTypeOptions = props.activeStrategies[currentCampaignType]
    const firstSelectOptions = Object.keys(currentCampaignTypeOptions)
    firstSelectOptions.forEach(option=>{
      Object.keys(currentCampaignTypeOptions[option]).forEach(secondOption=>{
        currentCampaignTypeOptions[option][secondOption].forEach(lang => {
          if (!findNonInventoryApproach(currentCampaignType, option, secondOption, lang, false)){
            const templates = props.adcopies[currentCampaignType].find((adcopy:AdCopyRSAPatterns) =>
              adcopy.matcher.group.toUpperCase() === currentCampaignType.toUpperCase()
              && adcopy.campaign.toUpperCase() === option.toUpperCase()
              && adcopy.matcher.lang === lang
              && adcopy.audienceId === "NO_AUDIENCE"
            ).templates
            approaches.push(
              getEmptyProtoMarketingApproach(
                option,
                lang as AdBuilderCompatibleLang,
                false,
                undefined,
                props.event.boundaries[0].vehicleState,
                secondOption,
                currentCampaignType,
                templates
              )
            )
          }
          if (currentCampaignType.toUpperCase() === "BRANDING" && audiences.length > 0){
            const templates = props.adcopies[currentCampaignType].find((adcopy:AdCopyRSAPatterns) =>
              adcopy.matcher.group.toUpperCase() === currentCampaignType.toUpperCase()
              && adcopy.campaign.toUpperCase() === option.toUpperCase()
              && adcopy.matcher.lang === lang
              && props.audiences.find(audience=>audience.id+""===adcopy.audienceId)
            ).templates
            if (!findNonInventoryApproach(currentCampaignType, option, secondOption, lang, true)){
              approaches.push(
                getEmptyProtoMarketingApproach(
                  option,
                  lang as AdBuilderCompatibleLang,
                  true,
                  audiences,
                  props.event.boundaries[0].vehicleState,
                  secondOption,
                  currentCampaignType,
                  templates
                )
              )
            }
          }
        })

      })
      if(props.event.lang !== "ALL") {
        approaches = approaches.filter(approach => approach.lang === props.event.lang)
      }
      props.setApproaches(approaches)
    })
    setEditing(true)
  }

  const handleCancel = () => {
    RoutingUtils.removeLastElementOfUrl(props.history)
    props.reset()
  }

  const prepareUpdateGroup = (group:string, index:number) => {
    let campaignType:string
    if (Object.keys(AdBuilderCompatibleVehicleState).includes(group)){
      campaignType = group === "NEW" ? "Inventory - New" : "Inventory - Used"
      props.updateState(group, index)
    } else {
      campaignType = Object.keys(props.activeStrategies).find(type => type.toUpperCase() === group.toUpperCase())
      props.updateGroup(group, index)
    }
    if(props.adcopies?.[campaignType] === undefined && !props.retrievingAdcopies){
      props.getRSAAdCopies(props.accountId, campaignType)
    }
  }
  
  return (
    <div className={classes.container}>
      <div className={classes.title}>{props.translate("adBuilder.newMarketingApproach")}</div>
      {editing
        ? <EditorStateManager
            event={props.event}
            readOnly={props.readOnly||false}
            handleCancel={()=>setEditing(false)}
            handleBackToHomepage={handleCancel}
            saveEvent={saveEvent}
            stringLengths={stringLengths}
            activeStrategies={props.activeStrategies}
          />
        : <MarketingEventIdentity
            event={props.event}
            updateLang={props.updateLang}
            updateTitle={props.updateTitle}
            updateGroup={prepareUpdateGroup}
            updateStartDate={props.updateStartDate}
            updateEndDate={props.updateEndDate}
            updateYear={props.updateYear}
            updateMake={props.updateMake}
            updateModel={props.updateModel}
            updateCampaignName={props.updateCampaignName}
            cleanerReference={props.cleanerReference}
            cleanerMakes={props.cleanerMakes}
            cleanerMakeModels={props.cleanerMakeModels}
            matchingInventory={matchingInventory}
            goToEditor={(isInventory) => isInventory 
              ? handleGoToEditor()
              : handleGoToNonInventoryEditor()
            }
            cancel={handleCancel}
            addBoundary={props.addBoundary}
            removeBoundary={props.removeBoundary}
            activeStrategies={props.activeStrategies}
          />
      }
    </div>
  )
}

export default withLocalize(
  requiresCleaner(false)(
    requiresFriends(false)(
      requiresMarketingEvents(false)(
        requiresInventory(
          connect(mapStateToProps,mapDispatchToProps,mergeProps)(
            MarketingEventSetup
          )
        )
      )
    )
  )
)
