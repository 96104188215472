import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,

}                 from '@material-ui/core'


interface DialogOwnProps {
  open        : boolean
  handleSave  : any
  handleClose : any
  title       : string
  content     : string
  confirmText : string
  cancelText  : string
}
interface DialogProps extends DialogOwnProps {}

const DialogMui = (props:DialogProps) => {
  return (
    <div>
      <Dialog
          open={props.open}
          onClose={props.handleClose}
        >
          <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleSave} color="primary">
              {props.confirmText}
            </Button>
            <Button onClick={props.handleClose} color="secondary" autoFocus>
              {props.cancelText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  )
}

export default DialogMui
