import './BiddingStrategy.css'
import * as React                from 'react'
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  CardContent,
  CardHeader,
}                                from '@material-ui/core'
import { Translate }             from 'react-localize-redux'
import requiresBiddingStrategies from '../../../../components/loaders/biddingStrategiesLoader'
import Strategy                  from '../../../../model/Store/Strategy/Strategy'

interface BiddingStrategyOwnProps {
  setStrategies     : any
  strategy          : Strategy
  setStrategy       : any
  biddingStrategies : any
  biddingStrategyId : string
  accountId         : number
  lang              : string
}
interface BiddingStrategyProps extends BiddingStrategyOwnProps {
}
const BiddingStrategy = (props:BiddingStrategyProps) => {

  const [bsId,setBsId] = React.useState(props.biddingStrategyId)
  function handleChange(event:React.ChangeEvent<{value:unknown}>) {
    setBsId(event.target.value as string)
    props.setStrategy({...props.strategy,budgetId:bsId})
    const bsIdValue = event.target.value
    props.setStrategies(prevState=>
			prevState.map(x=>{
				if(x.lang === props.lang){
					return {
						...x,
						budgetId : bsIdValue
					}
				}
				return x
			})
		)

  }
  React.useEffect(()=>{
    setBsId(props.biddingStrategyId)
  },[props.lang,props.accountId,props.biddingStrategyId])

  //Generate Bidding Strategies
  const biddingStrategiesList = props.biddingStrategies.map(bs => {
    return <MenuItem key={bs.id} value={bs.id.toString()}>{bs.name}</MenuItem>
  })
  return (
    <div className="BiddingStrategy">
      <CardHeader
        title={<Typography variant="h6"><Translate id="strategies.biddingStrategy" /></Typography>}
        subheader={<Typography variant="caption"><Translate id="branding.descriptions.bidStrategy" /></Typography>}
      />
      <CardContent className="Select">
        <FormControl>
          <Select
            style={{width:200,marginTop:20}}
            value={bsId}
            onChange={handleChange}
          >
            {biddingStrategiesList}
          </Select>
          <FormHelperText><Translate id="branding.chooseBiddingStrategy" /></FormHelperText>
        </FormControl>
      </CardContent>
    </div>
  )
}

export default requiresBiddingStrategies(BiddingStrategy)
