import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"

const setStatus = (state:SaveStatus, action):SaveStatus => {
  return {
    status       : action.status,
    error        : action.error,
    proxyGroupId : action.proxyGroupId
  }
}

interface SaveStatus{
  status        : boolean
  error        ?: boolean
  proxyGroupId ?: string
}
const Guideline = ReducerUtils.createReducer<SaveStatus>({status: false},{
  [Actions.SAVING_PACY_GUIDELINE] : setStatus
})

export default Guideline
