export interface RSAText {
  text     : string
  position : number
  // ID for frontend purposes only
  id      ?: number
}

export interface AdCopyRSATemplates {
  id           : number
  headlines    : RSAText[]
  descriptions : RSAText[]
}

export interface AdCopyRSAPatterns {
  campaign   : string
  adgroup    : string
  audienceId : string
  matcher  : {
    group      : string
    instanceof : string
    lang       : string
  }
  templates : AdCopyRSATemplates[]
}

export interface AdCopyParents {
  [campaign:string] : {
      [adgroup:string] : string[] //langs
    }
}

export interface AdgroupsToCopy {
  [campaign:string] : {
    [adgroup:string] : boolean
  }
}

export const INVENTORY_GROUPS = ["Inventory - New", "Inventory - Used", "VDP", "SRP_MM", "SPR_MMY"]