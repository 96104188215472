import Actions      from "../../../../model/constant/actions"
import ReducerUtils from "../../../../utils/ReducerUtils"

const setData = (state, action) => {
  return {
    ...state,
    ...action.data.reduce((shapes, shape)=>{
      shapes[shape.code] = shape
      return shapes
    },{})
  }
}
interface PlaceShapes{
  [code:number] : {
    id       : string
    polygons : number[][][]
  }
}
const PlacesShapes = ReducerUtils.createReducer<PlaceShapes>({},{
  [Actions.RETRIEVE_PLACES_SHAPES] : setData,
})
export default PlacesShapes
