import ReducerUtils            from "../../../utils/ReducerUtils"
import Actions                 from "../../../model/constant/actions"
import {AccountModifier }      from "../../../model/pacy/AccountModifiers"

const pushNewAction = (state,action:{data:AccountModifier[]}) => {
  return (action.data)
}
const resetActions = (state,action) => {
  return []
}
const AccountModifiers = ReducerUtils.createReducer([],{
  [Actions.PUSH_NEW_PACY_CONFIGS_ACTION]   : pushNewAction,
  [Actions.PACY_CONFIGS_ACCOUNT_CHANGE]    : resetActions,
  [Actions.RETRIEVE_PACY_ACCOUNT]          : resetActions
})

export default AccountModifiers
