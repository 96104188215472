import './SideBar.css'
import * as React               from "react"
import AccountSelector          from "./accountSelector/AccountModalWrapper"
import SideMenu                 from "./SideMenu"
import {MenuToggle}             from "./MenuToggle"
import {MenuButtonDescription}  from "./menuButtonSignature"

interface SideBarProps{
  toggle         : boolean
  doToggle       : ()=>void
  activeButton   : string
  accountPath    : string
  activateButton : (button:MenuButtonDescription)=>void
}
export class SideBar extends React.PureComponent<SideBarProps,any>{
  render(){
    return (
      <div className="sidebar" data-image="../assets/img/sidebar-1.jpg">
        <div className={"logo"+(this.props.toggle?"":" small")}>
          <a href={"#"+this.props.accountPath+"/Dashboard"}>
            <div
              className="LogoImageSpace"
              style={{
                backgroundImage : process.env.PUBLIC_URL+"/assets/img/"+(this.props.toggle ? "dealerbreacher.svg" : "logoMini.png")
              }}
            />
          </a>
        </div>
        <div>
          <div className="ExtraLogo"/>
          <AccountSelector isToggled={this.props.toggle}/>
        </div>
        <SideMenu
          activeButton={this.props.activeButton}
          accountPath={this.props.accountPath}
          activateButton={this.props.activateButton}
        />
        <MenuToggle icon="menu" onClick={this.props.doToggle}/>
      </div>
    )
  }
}
