import Reporting     from "../../utils/Reporting"
import CSVUtils      from "../../utils/CSVUtils"
import ActionUtils   from "../../utils/ActionUtils"
import Actions       from "../../model/constant/actions"
import {getToken}    from "../../storeAccessor/Generic"
import {Provider}    from "../../model/Provider"

const googleSearchTermsStatsFieldRenaming = {
  CampaignId : "CampaignAWId",
  Date       : "Day",
  Criteria   : "Keywords",
  Query      : "SearchTerms",
}
const bingSearchTermsStatsFieldRenaming = {
  CampaignId  : "CampaignBingId",
  TimePeriod  : "Day",
  Keyword     : "Keywords",
  KeywordId   : "CriterionId",
  SearchQuery : "SearchTerms",
}
const renameAWHeaders = header => googleSearchTermsStatsFieldRenaming[header]||header
const renameBingHeaders = header => bingSearchTermsStatsFieldRenaming[header]||header

export default (accountId:number, accountAWId:string, accountBingId:string, period) => async (dispatch, getState) => {
  const token = getToken(getState())
  dispatch({
    type   : Actions.RETRIEVING_SEARCHTERMS_STATISTICS,
    status : true
  })
  try{
    const actions = await Promise.all([
      getAWSearchTermStatsAction(token,accountId,accountAWId,period),
      getBingSearchTermStatsAction(token,accountId,accountBingId,period)
    ])
    for(const action of actions){
      if(action){dispatch(action)}
    }
  }
  catch(e){
    console.log("General error while retrieving searchTerms stats", e)
  }
  dispatch({
    type   : Actions.RETRIEVING_SEARCHTERMS_STATISTICS,
    status : false
  })
}
const getAWSearchTermStatsAction = (token:string, accountId:number, accountAWId:string, period) => (dispatch, getState) => {
  return ActionUtils.retryOnFailure(()=>
    Reporting.getAWSearchTermStats(token, accountAWId, period)
  )
  .then(
    (response) => dispatch(loadStatSuccess(response.data, accountId)),
    (error)    => console.log(error)
  )
}
const loadStatSuccess = (response, accountId) => {
  const stats = CSVUtils.parseCSVWithHeaders(response, renameAWHeaders)
  .map(x=>{
    x.Provider=Provider.AW
    return x
  })
  return {
    type : Actions.RETRIEVE_AW_SEARCHTERMS_STATS_OF_ACCOUNT,
    accountId,
    stats,
  }
}
const getBingSearchTermStatsAction = (token:string, accountId:number, accountBingId:string, period) => {
  const baseAction = {
    type : Actions.RETRIEVE_BING_SEARCHTERMS_STATS_OF_ACCOUNT,
    accountId,
    stats : [],
    period
  }
  if(accountBingId === "0"){return baseAction}
  return ActionUtils.retryOnFailure(()=>
    Reporting.getBingSearchTermStats(token,accountBingId,period)
  )
  .then((response)=>{
    baseAction.stats = CSVUtils.parseCSVWithHeaders(response.data, renameBingHeaders)
    .map(x=>{
      x.Provider=Provider.BING
      return x
    })
    return baseAction
  })
  .catch((error)=>{
    console.log(error)
  })
}
