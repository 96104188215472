import './Index.css'
import * as React                              from "react"
import {Button}                                from "carbon-components-react"
import {Panel}                                 from "../../components/Panel/Panel"
import UserPermissionBoundary                  from "../../components/permissions/UserPermissionBoundary"
import Step1                                   from "./component/Step1"
import Step2                                   from "./component/Step2"
import Step3                                   from "./component/Step3"
import ShapeEditor                             from "./component/shapeEditor"
import ShapesWithSameCodeChooser               from "./component/ShapesWithSameCodeChooser"
import ShapeAutoSimplifier                     from "./component/shapeSimplifier/AutoSimplifier"

const PermissionWrapper = props => (
  <UserPermissionBoundary
    userLevel={3}
    permissions={[]}
    onFailure={()=><div>Access DENIED</div>}
  >
    <ShapesValidation {...props}/>
  </UserPermissionBoundary>
)
class ShapesValidation extends React.Component<any,any>{
  constructor(props){
    super(props)
    this.state = {
      step : 0
    }
    this.renderStepChoice = this.renderStepChoice.bind(this)
    this.selectStep = this.selectStep.bind(this)
    this.renderStep = this.renderStep.bind(this)
    this.exitStep = this.exitStep.bind(this)
  }
  selectStep(choice){
    this.setState({step:choice})
  }
  exitStep(){
    this.setState({step:0})
  }
  renderStepChoice(){
    return (
      <Panel title="TinderBreacher" subtitle="Choose a step">
        <div className="ShapeValidationStepChoice">
          <Button onClick={()=>this.selectStep(1)}>Step 1</Button>
          <Button onClick={()=>this.selectStep(2)}>Step 2</Button>
          <Button onClick={()=>this.selectStep(3)}>Step 3</Button>
          <Button onClick={()=>this.selectStep("chooseSameCode")}>Shapes same code</Button>
          <Button onClick={()=>this.selectStep("edit")}>Editor</Button>
          <Button onClick={()=>this.selectStep("autoSimplify")}>Auto simplify</Button>
        </div>
      </Panel>
    )
  }
  renderStep(){
    switch(this.state.step){
      case 1:
        return <Step1 exit={this.exitStep}/>
      case 2:
        return <Step2 exit={this.exitStep}/>
      case 3:
        return <Step3 exit={this.exitStep}/>
      case "chooseSameCode":
        return <ShapesWithSameCodeChooser exit={this.exitStep}/>
      case "edit":
        return <ShapeEditor exit={this.exitStep}/>
      case "autoSimplify":
        return <ShapeAutoSimplifier exit={this.exitStep}/>
      default:
        return this.renderStepChoice()
    }
  }
  render(){
    return this.renderStep()
  }
}

export default PermissionWrapper
