import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    container : {
      padding : "30px 50px"
    },
    alert : {
      textAlign : "center",
      paddingTop : 12,
      verticalAlign : "center",
      backgroundColor : "#E4FAE8",
      width : 500,
      height: 50,
      borderRadius: 4,
    },
    errorAlert : {
      textAlign : "center",
      paddingTop : 12,
      verticalAlign : "center",
      backgroundColor : "#ED646480",
      width : 500,
      height: 50,
      borderRadius: 4,
    },
    title : {
      marginBottom : 50,
      font : "normal normal normal 32px/43px Roboto",
    }
  })
)

export default useStyles
