import * as React                           from "react"
import {withLocalize}                       from "react-localize-redux"
import {ScatterChart, Scatter, XAxis,
        YAxis, CartesianGrid, Legend,
        Tooltip, ResponsiveContainer,
        ZAxis}                              from "recharts"
import {RadioButton}                        from "carbon-components-react"
import ObjectUtils                          from "../../utils/ObjectUtils"
import ArrayUtils                           from "../../utils/ArrayUtils"
import NumFormatter                         from '../../utils/NumberFormatter'

interface InventoryScatterChartProps{
  data      : any[]
  vehicles  : any[]
  translate : (translateId:string)=>string
}
interface InventoryScatterChartState{
  selectedRadio : string
}
const calculateVisitors = navigations => ObjectUtils.getObjectValues(
  navigations.reduce((a,b)=>{
    a[b.browserId] = 1
    return a
  },{})
).length
const calculateVisitorsRank = obj=>{
  const fromDB = calculateVisitors(obj.navigationsFromDB)
  return Math.round(100*fromDB/(calculateVisitors(obj.navigationsNotFromDB)+fromDB))
}
const separateData = (data) => {
  return data.reduce((separated, simplified)=>{
    if(simplified.state === "used"){separated.usedCars.push(simplified)}
    else{separated.newCars.push(simplified)}
    return separated
  },{usedCars:[], newCars:[]})
}
const simplify = x => ({
  YMM : [
    x.getYear(),
    x.getMake(),
    x.getModel(),
    "("+x.getMostRepresentativeId()+")",
  ].join(" "),
  state : x.state.toLowerCase(),
  daysInInventory : x.daysInInventory,
  visitorsFromDB : 0,
  price : x.price
})
const CustomTooltip = props => {
  if(!props.active){return null}
  const data = props.payload[0].payload
  return (
    <div style={{backgroundColor: "#fff", padding:"5px", border:"1px solid black"}}>
      <p>Vehicle : {data.YMM}</p>
      <p>Visitors from DB : {data.visitorsFromDB}%</p>
      <p>Days in inventory : {data.daysInInventory}</p>
      <p>Price : {data.price?NumFormatter.formatCurrency(data.price):"Unknown"}</p>
      <p>Total visitors : {data.totalVisitors?data.totalVisitors:"Unknown"}</p>
    </div>
  )
}
class InventoryScatterChart extends React.Component<InventoryScatterChartProps,InventoryScatterChartState>{
  constructor(props){
    super(props)
    this.state = {
      selectedRadio : "all"
    }
    this.handleRadioChange = this.handleRadioChange.bind(this)
    this.renderScatterNew = this.renderScatterNew.bind(this)
    this.renderScatterUsed = this.renderScatterUsed.bind(this)
  }
  handleRadioChange(value){
    this.setState({
      selectedRadio : value
    })
  }
  renderScatterNew(compiled){
    if(this.state.selectedRadio === "new" || this.state.selectedRadio === "all"){
      return (
        <Scatter
          xAxisId="xAxis"
          yAxisId="yAxis"
          zAxisId="zAxis"
          name={this.props.translate("inventoryTurn.graphs.newInventory")}
          data={compiled.newCars}
          fill="#8884d888"
        />
      )
    }
    return null
  }
  renderScatterUsed(compiled){
    if(this.state.selectedRadio === "used" || this.state.selectedRadio === "all"){
      return (
        <Scatter
          xAxisId="xAxis"
          yAxisId="yAxis"
          zAxisId="zAxis"
          name={this.props.translate("inventoryTurn.graphs.usedInventory")}
          data={compiled.usedCars}
          fill="#29867888"
        />
      )
    }
    return null
  }
  render(){
    const fromDBIds = this.props.data.map(x=>x.vehicle.id)
    const allVehicles = [
      ...this.props.vehicles.filter(x=>!ArrayUtils.contain(fromDBIds, x.id)).map(x=>simplify(x)),
      ...this.props.data.map(x=>({
        ...simplify(x.vehicle),
        visitorsFromDB : calculateVisitorsRank(x),
        totalVisitors : x.navigationsFromDB.length + x.navigationsNotFromDB.length
      })),
    ]
    const prices = allVehicles.map(x=>x.price)
    const priceRange = {
      min : Math.min(...prices),
      max : Math.max(...prices)
    }
    const priceDelta = priceRange.max - priceRange.min
    const allVehiclesPriceAdjusted = allVehicles.map(x=>({
      ...x,
      scaledPrice : (x.price-priceRange.min)*(500/priceDelta) +20
    }))
    const compiled = separateData(allVehiclesPriceAdjusted)
    return(
      <div className="InventoryScatterChart">
        <div className="Options">
          <RadioButton
            name="all"
            value="all"
            labelText={this.props.translate("inventoryTurn.graphs.all")}
            checked={this.state.selectedRadio === "all"}
            onChange={this.handleRadioChange}
          />
          <RadioButton
            name="new"
            value="new"
            labelText={this.props.translate("common.new")}
            checked={this.state.selectedRadio === "new"}
            onChange={this.handleRadioChange}
          />
          <RadioButton
            name="used"
            value="used"
            labelText={this.props.translate("common.used")}
            checked={this.state.selectedRadio === "used"}
            onChange={this.handleRadioChange}
          />
        </div>
        <div className="Chart">
          <ResponsiveContainer width="100%" height={350}>
            <ScatterChart margin={{top: 20, right: 20, bottom: 20, left: 20}}>
              <CartesianGrid  strokeDasharray="3 3"/>
              <XAxis
                xAxisId="xAxis"
                dataKey={"visitorsFromDB"}
                type="number"
                label={{value: this.props.translate("inventoryTurn.graphs.visitorsDB"), position: "insideBottomLeft", offset:-15}}
                name="% Visitors from DB"
                unit="%"
              />
              <YAxis
                yAxisId="yAxis"
                dataKey={"daysInInventory"}
                type="number"
                label={{value: this.props.translate("inventoryTurn.graphs.daysInInventory"), angle: -90, position: "left", offset:-10}}
                name="Days in inventory"
              />
              <ZAxis
                zAxisId="zAxis"
                dataKey={"scaledPrice"}
                type="number"
                name="Price"
                range={[20,500]}
              />
              {this.renderScatterNew(compiled)}
              {this.renderScatterUsed(compiled)}
              <Tooltip
                content={<CustomTooltip/>}
                cursor={{ strokeDasharray: '3 3' }}
              />
              <Legend />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

export default withLocalize(InventoryScatterChart)
