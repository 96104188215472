import * as React     from "react"
import {withLocalize} from "react-localize-redux"
import {TextInput }   from "carbon-components-react"

interface SizePerPageManagerProps{
  originalSize : number
  editedSize   : number
  setSize      : (editedSize:number)=>void
  translate   ?: (translateId:string)=>string
}
interface SizePerPageManagerState{
  error : string
}
class SizePerPageManager extends React.Component<SizePerPageManagerProps,SizePerPageManagerState>{
  constructor(props){
    super(props)
    this.state = {
      error : ""
    }
  }
  validateSize (value:string){
    let size = parseInt(value)
    if(isNaN(size) || size < 1){
      this.setState({error : this.props.translate("reports.ads.editor.sizeError")})
      return
    }
    else if(this.state.error != ""){
      this.setState({error : ""})
    }
    this.props.setSize(size)
  }
  render(){
    return(
      <div className="SizePerPageManager">
        <TextInput
          className={"TemplateSizePerPageInput"+(this.props.editedSize!=this.props.originalSize?" Edited":"")}
          labelText=""
          required
          id="TemplateSizePerPageInput"
          placeholder={"Template size per page"}
          value={this.props.editedSize}
          onChange={(e)=>{this.validateSize(e.target.value)}}
        />
        {this.state.error != ""?
          <span>{this.state.error}</span>
        :
          <></>
        }
      </div>
    )
  }
}

export default withLocalize(SizePerPageManager)
