export default class UserAction{
  public static TOGGLE_MENU                       = "TOGGLE_MENU"
  public static MODIFY_STATUS_CAMPAIGN            = "MODIFY_STATUS_CAMPAIGN"
  public static AUTHENTIFY_LOGIN_SUCCESS          = "LOGIN_SUCCESS"
  public static SAVE_GEO                          = "SAVE_GEO"
  public static CREATE_ADCOPY                     = "CREATE_ADCOPY"
  public static REMOVE_ADCOPY                     = "REMOVE_ADCOPY"
  public static EDIT_ADCOPY                       = "EDIT_ADCOPY"
  public static SAVE_GEO_EXCEPTION                = "SAVE_GEO_EXCEPTION"
  public static REMOVE_GEO_EXCEPTION              = "REMOVE_GEO_EXCEPTION"
  public static SAVE_STATUS_EXCEPTION             = "SAVE_STATUS_EXCEPTION"
  public static REMOVE_STATUS_EXCEPTION           = "REMOVE_STATUS_EXCEPTION"
  public static ASSIGN_WAZE_COMPANY               = "ASSIGN_WAZE_COMPANY"
  public static SAVE_TRACKING_INFO                = "SAVE_TRACKING_INFO"
  public static SAVE_WHITE_LABEL                  = "SAVE_WHITE_LABEL"
  public static CHANGE_PERIOD                     = "CHANGE_PERIOD"
  public static REPLACE_GEOS                      = "REPLACE_GEOS"
  public static SAVED_ENG_RULES                   = "SAVED_ENG_RULES"
  public static CREATE_MARKETING_EVENT            = "CREATE_MARKETING_EVENT"
  public static DELETE_MARKETING_EVENT            = "DELETE_MARKETING_EVENT"
  public static EDIT_MARKETING_EVENT              = "EDIT_MARKETING_EVENT"
  public static SAVE_KEYWORD                      = "SAVE_KEYWORD"
  public static EDIT_STRATEGY                     = "EDIT_STRATEGY"
  public static BRANDING_EDIT_STRATEGY            = "BRANDING_EDIT_STRATEGY"
  public static BRANDING_CREATE_ADGROUP           = "BRANDING_CREATE_ADGROUP"
  public static BRANDING_DELETE_ADGROUP           = "BRANDING_DELETE_ADGROUP"
  public static BRANDING_UPDATE_AUDIENCE_ADS      = "BRANDING_UPDATE_AUDIENCE_ADS"
  public static BRANDING_CREATE_AUDIENCE_ADS      = "BRANDING_CREATE_AUDIENCE_ADS"
  public static BRANDING_SAVE_AD_COPIES           = "BRANDING_SAVE_AD_COPIES"
  public static BRANDING_SAVE_IDENTITY_VARIANTS   = "BRANDING_SAVE_IDENTITY_VARIANTS"
  public static BRANDING_SAVE_POSITIVE_KEYWORDS   = "BRANDING_SAVE_POSITIVE_KEYWORDS"
  public static BRANDING_SAVE_NEGATIVE_KEYWORDS   = "BRANDING_SAVE_NEGATIVE_KEYWORDS"
  public static DELETE_FEED                       = "DELETE_FEED"
  public static DELETE_FEED_SIGNED_URL            = "DELETE_FEED_SIGNED_URL"
  public static CREATE_FEED_SIGNED_URL            = "CREATE_FEED_SIGNED_URL"
  public static REFRESH_FEED                      = "REFRESH_FEED"
}
