import WebLead                 from "../../../model/Store/Tracking/WebLead"
import ByBrowserId             from "./ByBrowserId"
import ByPage                  from "./ByPage"
import ByMoment                from "./ByMoment"
import ExtractQuickInsight     from "./ExtractQuickInsight"



const compile = (leads:WebLead[]) : any[] => {
  const temp1 = ByBrowserId(leads)
  const temp2 = ByPage(temp1)
  const temp3 = ByMoment(temp2)
  const temp4 = ExtractQuickInsight(temp3)
  return temp4
}

export default compile
