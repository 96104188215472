import * as React                        from "react"
import {HashRouter, Route}               from "react-router-dom"
import {Switch,RouteProps}               from "react-router-dom"
import {Redirect}                        from "react-router-dom"
import {Link}                            from "react-router-dom"
import {PermissionContext}               from "../components/context/permissionContext"
import requiresAccounts                  from "../components/loaders/accountsLoader"
import BudgetPlanner                     from "../page/budget/"
import ChangeHistory                     from "../page/budget/ChangeHistory"
import PacyPlanner                       from "../page/budget/pacy/planner/"
import PacyConfigs                       from "../page/budget/pacy/pacyConfigs"
import PacingRatio                       from "../page/budget/pacy/pacingRatio"

const routing = (props) => {
  return (
    <HashRouter>
      <Switch>
        <Route
          key="Planner"
          path={"*/Planner"}
          component={BudgetPlanner}
        />
        <Route
          key="ChangesHistory"
          path={"*/ChangesHistory"}
          component={ChangeHistory}
        />
        <Route
          key="Configs"
          path={"*/Configs"}
          component={PacyConfigs}
        />
        <Route
          key="PacingRatio"
          path={"*/PacingRatio"}
          component={PacingRatio}
        />
        <Redirect from="/" to="Budgets/Planner"/>
      </Switch>
    </HashRouter>
  )
}

export default requiresAccounts(routing)
