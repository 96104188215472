import './IdentityVariantsTester.css'
import * as React                     from 'react'
import requiresIdentityVariants       from '../loaders/identityVariantsLoader'
import {
  SimplifiedIdentityVariants
}                                     from '../../model/branding/SimplifiedIdentityVariants'
import {
  IdentityVariantsByLang,
}                                     from "../../model/branding/IdentityVariants"
import AdCopy,
 { AdCopyWithErrors }                 from '../../model/Store/AdCopyPatterns/AdCopy'
import SimpleAdCopy                   from '../adCopy/SimpleAdCopy'
import {
  Card,
  Tooltip,
  withStyles,
  Theme,
  Typography,
  Icon,
  Divider
}                                    from '@material-ui/core'
import StringUtils                   from '../../utils/StringUtils'
import {
  withLocalize,
  TranslateFunction,
  Translate
}                                    from 'react-localize-redux'

interface MaxChar{
  headlines    : number
  descriptions : number
}
interface IdentityVariantsTesterOwnProps {
  lang           : string
  adCopy         : AdCopy
  maxChar        : MaxChar
  specification ?: 'passing' | 'failing'
  setPassing    ?: any
  setFailing    ?: any
}
interface IdentityVariantsTesterProps extends IdentityVariantsTesterOwnProps {
  identityVariantsByLang : IdentityVariantsByLang
  translate              : TranslateFunction
}

const IdentityVariantsTester = (props:IdentityVariantsTesterProps) => {
  const identityVariants:SimplifiedIdentityVariants = props.identityVariantsByLang[props.lang]
  const adCopy:AdCopy = props.adCopy

  function duplicateAdCopies(adCopies:AdCopy[],tag:string,variants:string[]):AdCopy[] {
    const regex = new RegExp(tag,'g')
    return adCopies.reduce((duplicated, adCopy) =>{
      for(const variant of variants){
        duplicated.push({
          ...adCopy,
          headlines : adCopy.headlines.map(x=>x.replace(regex,variant)),
          descriptions : adCopy.descriptions.map(x=>x.replace(regex,variant))
        })
      }
      return duplicated
    },[])
  }
  function generateAdCopies(adCopy:AdCopy,identityVariants:SimplifiedIdentityVariants):AdCopy[] {
    const fulltext = [...adCopy.headlines,...adCopy.descriptions].join("")
    let adCopies = [adCopy]
    if(fulltext.includes("<make>")) {
      adCopies = duplicateAdCopies(adCopies,"<make>", identityVariants.makes)
    }
    if(fulltext.includes("<city>")) {
      adCopies = duplicateAdCopies(adCopies, "<city>", identityVariants.cities)
    }
    if(fulltext.includes('<name>')) {
      adCopies = duplicateAdCopies(adCopies, "<name>", identityVariants.dealerNames)
    }
    return adCopies
  }
  const sortedAdCopies = generateAdCopies(adCopy,identityVariants).map<AdCopyWithErrors>(adCopy=>{
    const returned:AdCopyWithErrors = {
      ...adCopy,
      errors : {
        descriptions : [],
        headlines    : [],
        current      : false
      }
    }
    adCopy.headlines.forEach((head,i)=>{
      let length = head.length
      length += StringUtils.occurrences(head, "<month>")*2
      length += StringUtils.occurrences(head, "<_month>")*1
      if(length>props.maxChar.headlines) {
        returned.errors.headlines.push(i)
        returned.errors.current = true
      }
    })
    adCopy.descriptions.forEach((des, i)=>{
      let length = des.length
      length += StringUtils.occurrences(des, "<month>")*2
      length += StringUtils.occurrences(des, "<_month>")*1
      if(length>props.maxChar.descriptions) {
        returned.errors.descriptions.push(i)
        returned.errors.current = true
      }
    })
    return returned
  })
  const passing = sortedAdCopies.reduce((acc,adCopy)=>{
    if (!adCopy.errors.current) {acc++}
    return acc
  },0)
  const failing = sortedAdCopies.length-passing
  props.setPassing && props.setPassing(passing)
  props.setFailing && props.setFailing(failing)
  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      padding: 10,
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip)
  const invalidAdCopies =
    <div className="Failed">
      {sortedAdCopies.map((adCopy,i)=>{
        if(adCopy.errors.current) {
          return (
            <HtmlTooltip
              key={-i}
              placement="right"
              title={
                <div className="IdentityVariantsTesterToolTip">
                  {adCopy.errors.headlines.map(i=>
                    <Typography key={i}  variant="caption" color="secondary">
                      <Icon className="Icon">error</Icon> <span className="Error">{props.translate("branding.identityVariantsTester.headline")} {i+1} {props.translate("branding.identityVariantsTester.isTooLong")}</span>
                    </Typography>)
                  }
                  {adCopy.errors.descriptions.map(i=>
                    <Typography key={i} variant="caption" color="secondary">
                      <Icon className="Icon">error</Icon> <span className="Error">Description {i+1} {props.translate("branding.identityVariantsTester.isTooLongDes")}</span>
                    </Typography>)
                  }
                  <Divider style={{marginTop:10,marginBottom:10}} />
                  <Typography key={i} variant="caption">
                    <Icon style={{color:'#ffc966'}}>emoji_objects</Icon>
                    <span className="Tip"><Translate id="branding.identityVariantsTester.tipOne" options={{renderInnerHtml: true} as any} /></span>
                  </Typography>
                  <Typography key={i} variant="caption">
                    <Icon style={{color:'#ffc966'}}>emoji_objects</Icon>
                    <span className="Tip">{props.translate("branding.identityVariantsTester.tipTwo")}</span>
                  </Typography>
                </div>
              }>
              <Card className="FailedAdCopy AdCopy">
                <SimpleAdCopy headlines={adCopy.headlines} descriptions={adCopy.descriptions} url="www.google.com" />
              </Card>
            </HtmlTooltip>
          )
        }
      })}
    </div>
  const validAdCopies =
    <div className="Passed">
      {sortedAdCopies.map((adCopy,i)=>{
        if(!adCopy.errors.current) {
          return (
            <Card key={i} className="PassedAdCopy AdCopy">
              <SimpleAdCopy headlines={adCopy.headlines} descriptions={adCopy.descriptions} url="www.google.com" />
            </Card>
          )
        }
      })}
    </div>

  return (
    <div className="IdentityVariantsTester">
      {!props.specification && <>{invalidAdCopies} {validAdCopies}</>}
      {props.specification === 'passing' && validAdCopies}
      {props.specification === 'failing' && invalidAdCopies}
    </div>
  )
}

export default withLocalize(requiresIdentityVariants()(IdentityVariantsTester)) as React.ComponentType<IdentityVariantsTesterOwnProps>
