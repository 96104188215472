import Actions    from "../../model/constant/actions"
import Keystone   from "../../utils/Keystone"
import DateUtils  from "../../utils/DateUtils"
import FVDUtils   from "../../utils/FieldsValuesDefinitionUtils"
import {getToken} from "../../storeAccessor/Generic"

export default (accountId:number, period) => (dispatch, getState) => {
  const token = getToken(getState())
  dispatch({
    type   : Actions.RETRIEVING_ADS_STATISTICS,
    status : true
  })
  Promise.all(
    DateUtils.dividePeriod(period,5).map(bloc=>
      Keystone.get({
          action      : "IDB2GetStatsOfAds",
          token       : token,
          accountId   : accountId,
          periodStart : DateUtils.format(bloc.dateFrom),
          periodEnd   : DateUtils.format(bloc.dateTo),
        },
        (response) => dispatch(loadStatSuccess(response.data,accountId)),
        (error) => dispatch(loadStatError(error,accountId))
      )
    )
  ).then(()=>dispatch({
    type   : Actions.RETRIEVING_ADS_STATISTICS,
    status : false
  }))
}
const loadStatSuccess = (response, accountId) => {
  const campaignsInfos = FVDUtils.decompress(response.campaignsInfo)
  const stats = response.stats.values.map(value=>{
    let returned = {}
    for(let i=0; i<response.stats.fields.length; i++){
      returned[response.stats.fields[i]] = value[i]
    }
    const campaign = campaignsInfos.find(row=>row["AWId"] === returned["CampaignAWId"])
    if(campaign){
      for(const key of Object.keys(campaign)){
        returned[key] = campaign[key]
      }
    }
    return returned
  })
  return {
    type      : Actions.RETRIEVE_ADS_STATS_OF_ACCOUNT,
    accountId : accountId,
    stats     : stats,
  }
}
const loadStatError = (response, accountId) => {
  return {
    type      : Actions.RETRIEVE_ADS_STATS_OF_ACCOUNT,
    accountId : accountId,
    stats     : undefined
  }
}
