import './ColumnsManager.css'
import * as React                     from "react"
import {DragDropContext}              from "react-dnd"
import HTML5Backend                   from 'react-dnd-html5-backend'
import {Translate}                    from "react-localize-redux"
import DragColumn                     from "../../../../model/report/DragColumn"
import ColumnsExchangeContext         from "./ColumnsExchangeContext"

interface ColumnsManagerProps{
  columns                : DragColumn[]
  columnsBank            : DragColumn[]
  setColumns             : (columns:DragColumn[])=>void
  originalDefaultFilters : {[dataField:string]:any}
  editedDefaultFilters   : {[dataField:string]:any}
  setDefaultFilter       : (dataField:string, value:string)=>void
}
interface ColumnsManagerState{
  columns : DragColumn[]
}
class ColumnsManager extends React.Component<ColumnsManagerProps,ColumnsManagerState>{
  constructor(props){
    super(props)
    this.state = {
      columns : props.columns
    }
    this.moveColumn = this.moveColumn.bind(this)
    this.addColumn = this.addColumn.bind(this)
    this.removeColumn = this.removeColumn.bind(this)
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.columns != nextProps.columns){
      this.setState({columns:nextProps.columns})
    }
  }
  moveColumn(dragIndex, hoverIndex){
    const dragColumn = {...this.state.columns[dragIndex]}
    let cols = [...this.state.columns]
    cols.splice(dragIndex, 1)
    cols.splice(hoverIndex, 0, dragColumn)
    this.props.setColumns(cols)
  }
  addColumn(dataField, hoverIndex){
    const index = this.state.columns.map(c=>c.dataField).indexOf(dataField)
    if(index > -1){
      this.moveColumn(index, hoverIndex)
    }
    else{
      const column = this.props.columnsBank.find(col=>col.dataField==dataField)
      let cols = [...this.state.columns]
      cols.splice(hoverIndex, 0, column)
      this.props.setColumns(cols)
    }
  }
  removeColumn(dragIndex){
    let cols = [...this.state.columns]
    cols.splice(dragIndex, 1)
    this.props.setColumns(cols)
  }
  render(){
    return(
      <div className="ColumnManager">
        <h5 className="ContainerTitle">
          <Translate id="reports.ads.editor.bank"/>
        </h5>
        <h5 className="ContainerTitle">
          <Translate id="reports.ads.editor.yourColumns"/>
        </h5>
        <ColumnsExchangeContext
          bank={this.props.columnsBank}
          columns={this.state.columns}
          addColumn={this.addColumn}
          removeColumn={this.removeColumn}
          originalDefaultFilters={this.props.originalDefaultFilters}
          editedDefaultFilters={this.props.editedDefaultFilters}
          setDefaultFilter={this.props.setDefaultFilter}
        />
      </div>
    )
  }
}

export default DragDropContext(HTML5Backend)(ColumnsManager)
