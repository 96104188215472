import './AccountTrackingView.css'
import * as React                    from "react"
import Account                       from "../../../model/Store/Account/Account"
import EditableKeyValueTable         from "../../../components/table/EditableKeyValueTable"
import ArrayUtils                    from "../../../utils/ArrayUtils"

interface AccountTrackingViewProps {
  account : Account
  addSaveAction : (informationName:string, action:()=>void)=>void
  assignCallRailId : (id:number)=>void
  assignGTMInfo : (name:string)=>void
  assignGAId : (id:string)=>void
  editedInformation ?: string[]
}
const dataIds = {
  GTMName  : "GTMName",
  CallRail : "CallRail",
  GA       : "GA"
}

const AccountTrackingView:React.SFC<AccountTrackingViewProps> = (props:AccountTrackingViewProps) => {
  const struct = {
    "GTM container name"    : {
      value : props.account.GTMContainerName?props.account.GTMContainerName:"",
      placeholder : "GTM-XXXXXXX",
      onChange : (text:string)=>{},
      onBlur : (text:string)=>{
        const trimmedText = text.trim()
        const initialValue = (props.account.GTMContainerName?props.account.GTMContainerName:"")
        if(trimmedText != initialValue){props.addSaveAction(dataIds.GTMName, ()=>props.assignGTMInfo(trimmedText))}
      },
      edited : ArrayUtils.contain(props.editedInformation, dataIds.GTMName)
    },
    "CallRail ID"   : {
      value : props.account.callRailId?props.account.callRailId:"",
      onChange : (text:string)=>{},
      onBlur : (text:string)=>{
        const trimmedText = text.trim()
        const initialValue = (props.account.callRailId?props.account.callRailId:"")
        if(trimmedText != initialValue){props.addSaveAction(dataIds.CallRail, ()=>props.assignCallRailId(parseInt(trimmedText)))}
      },
      edited : ArrayUtils.contain(props.editedInformation, dataIds.CallRail)
    },
    "Google Analytics ID" : {
      value : props.account.GAId?props.account.GAId:"",
      placeholder : "AA-88888888-8",
      onChange : (text:string)=>{},
      onBlur : (text:string)=>{
        const trimmedText = text.trim()
        const initialValue = (props.account.GAId?props.account.GAId:"")
        if(trimmedText != initialValue){props.addSaveAction(dataIds.GA, ()=>props.assignGAId(trimmedText))}
      },
      edited : ArrayUtils.contain(props.editedInformation, dataIds.GA)
    },
  }
  return (
    <div className="AccountTrackingView">
      <EditableKeyValueTable
        title={"Tracking"}
        keyValue={struct}
      />
    </div>
  )
}
AccountTrackingView.defaultProps = {
  editedInformation : []
}
export default AccountTrackingView
