import Pacy                                              from "../../utils/Pacy"
import DateUtils                                         from "../../utils/DateUtils"
import ActionUtils, {
  extractMessageFromError,
  extractStatusCodeFromError
}                                                        from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import Period                                            from "../../model/Period"
import {getToken}                                        from "../../storeAccessor/Generic"


export const retrievePacyAccount = (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_PACY_ACCOUNT, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Pacy.getAccount(token, accountId)
  )
  .then(response=>{
    dispatch({
      type    : Actions.RETRIEVE_PACY_ACCOUNT,
      account : response.data
    })
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_RETRIEVING_PACY_ACCOUNT,
      status : true,
      statusCode : extractStatusCodeFromError(error),
      message : extractMessageFromError(error)
    })
  })
  .then(()=>{
    dispatch({type: Actions.RETRIEVING_PACY_ACCOUNT, status: false})
  })

}

export const retrieveSpendAttempts = (accountId:number, period:Period) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_PACY_SPEND_ATTEMPTS, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Pacy.getSpendAttempts(token, accountId, DateUtils.format(period.dateFrom), DateUtils.format(period.dateTo))
  )
  .then(response=>{
    dispatch({
      type    : Actions.RETRIEVE_PACY_SPEND_ATTEMPTS,
      spendAttempts : response.data,
      accountId,
      period
    })
  })
  .catch(error=>{
    console.warn(error)
    dispatch({type: Actions.FAILED_RETRIEVING_PACY_SPEND_ATTEMPTS})
  })
  .then(()=>{
    dispatch({type: Actions.RETRIEVING_PACY_SPEND_ATTEMPTS, status: false})
  })

}

export const retrieveDecisions = (accountId:number, period:Period) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_PACY_DECISIONS, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Pacy.getDecisions(token, accountId, DateUtils.format(period.dateFrom), DateUtils.format(period.dateTo))
  )
  .then(response=>{
    dispatch({
      type    : Actions.RETRIEVE_PACY_DECISIONS,
      decisions : response.data,
      accountId,
      period
    })
  })
  .catch(error=>{
    console.warn(error)
    dispatch({type: Actions.FAILED_RETRIEVING_PACY_DECISIONS})
  })
  .then(()=>{
    dispatch({type: Actions.RETRIEVE_PACY_DECISIONS_AFTER_SAVING, status:false})
    dispatch({type: Actions.RETRIEVING_PACY_DECISIONS, status: false})
  })

}
