import "./ComparisonLineGraph.css"
import * as React                      from "react"
import ObjectUtils                     from "../../utils/ObjectUtils"
import ArrayUtils                      from "../../utils/ArrayUtils"
import LineGraph, {DataPoint, Line}    from "./LineGraph2"

interface ComparisonLineGraphProps{
  data                     : DataPoint[]
  comparisonData           : DataPoint[]
  lines                    : Line[]
  height                   : string|number
  width                    : string|number
  compare                 ?: boolean
  doubleYAxis             ?: boolean
  tooltipXValueFormatter  ?: (dataKey, xValue)=>string
  tooltipYValueFormatter  ?: (dataKey, yValue)=>string
  tooltipDataKeyFormatter ?: (dataKey)=>string
}
interface ComparisonDataPoint extends DataPoint{
  originalXValue : string|number
}
interface ComparisonLineGraphState{
}
class ComparisonLineGraph extends React.Component<ComparisonLineGraphProps,ComparisonLineGraphState>{
  constructor(props:ComparisonLineGraphProps){
    super(props)
    this.state = {
    }
    this.mergeDataWithComparisonData = this.mergeDataWithComparisonData.bind(this)
    this.duplicateLinesForComparison = this.duplicateLinesForComparison.bind(this)
  }
  mergeDataWithComparisonData(){
    const dataByXValue = this.props.data.reduce((byXValue, data)=>{
      if(byXValue[data.xValue]){throw Error("Multiple datapoints for the same xValue in data")}
      byXValue[data.xValue] = data
      return byXValue
    },{})
    for(const data of this.props.comparisonData){
      if(!dataByXValue[data.xValue]){
        dataByXValue[data.xValue] = {xValue: data.xValue}
      }
      for(const key of Object.keys(data)){
        if(key==="xValue"){continue}
        dataByXValue[data.xValue]["COMPARED_"+key] = data[key]
      }
    }
    return ObjectUtils.getObjectValues(dataByXValue).sort((a,b)=>ArrayUtils.comp(a.xValue, b.xValue))
  }
  duplicateLinesForComparison(){
    const lines = []
    for(const line of this.props.lines){
      lines.push(line)
      lines.push({
        ...line,
        dataKey : "COMPARED_"+line.dataKey,
        strokeDasharray: "3 3",
        showDot: false,
        color: line.color+"99",
      })
    }
    return lines
  }
  getTooltipRenderer(){
    const xValueFormatter = this.props.tooltipXValueFormatter
    const yValueFormatter = this.props.tooltipYValueFormatter
    const dataKeyFormatter = this.props.tooltipDataKeyFormatter || (x=>x)
    return ({payload}) => {
      const keys = ArrayUtils.unique(payload.map(x=>x.dataKey.replace(/^COMPARED_/,"")))
      return (
        <div className="ComparisonTooltip">
          {keys.map(key=>
            <div key={key}>
              <strong>{dataKeyFormatter(key)}</strong>
              <div className="KeyData">
                {payload.reduce((rendered, data)=>{
                  let xValue
                  let yValue
                  if(data.dataKey===key){
                    xValue = data.payload.xValue
                    yValue = data.payload[key]
                  }
                  else if(data.dataKey==="COMPARED_"+key){
                    xValue = data.payload.COMPARED_originalXValue
                    yValue = data.payload["COMPARED_"+key]
                  }
                  if(xValue!==undefined && yValue!==undefined){
                    if(xValueFormatter){xValue = xValueFormatter(key, xValue)}
                    if(yValueFormatter){yValue = yValueFormatter(key, yValue)}
                    rendered.push(
                      <div key={data.dataKey} className="LineData">
                        <div className="XValue">{xValue}</div>
                        <div className="YValue">{yValue}</div>
                      </div>
                    )
                  }
                  return rendered
                },[])}
              </div>
            </div>
          )}
        </div>
      )
    }
  }
  render(){
    const data = this.props.compare ? this.mergeDataWithComparisonData() : this.props.data
    const lines = this.props.compare ? this.duplicateLinesForComparison() : this.props.lines
    return (
      <div>
        <LineGraph
          data={data}
          lines={lines}
          doubleYAxis={this.props.doubleYAxis||false}
          height={this.props.height}
          width={this.props.width}
          renderTooltip={this.getTooltipRenderer()}
          hideXAxisTicks={this.props.compare}
        />
      </div>
    )
  }
}

export default ComparisonLineGraph
