import './SideMenu.css'
import * as React                                    from "react"
import {MENU_BUTTONS, MenuButtonDescription}         from "./menuButtonSignature"
import MenuButton                                    from "./MenuButton"
import {PermissionContext}                           from "../../../../components/context/permissionContext"

interface SideMenuProps{
  accountPath    : string
  activeButton   : string
  activateButton : (button:MenuButtonDescription)=>void
}

export const filterDisplayedButtons = (buttons:MenuButtonDescription[], permissionContext):MenuButtonDescription[] => {
  return buttons.filter(button=>{
    if(button.staffOnly && !permissionContext.validateStaffLevel(2)){return false}
    if(button.requiredPermissions && button.requiredPermissions.length > 0){
      for(let permission of button.requiredPermissions){
        if(!permissionContext.validatePermission(permission)){
          return false
        }
      }
    }
    return true
  })
}

const SideMenu = (props:SideMenuProps) => {
  return(
    <div className="sidebar-wrapper">
      <PermissionContext.Consumer>{context=>
        <ul className="nav">{
          filterDisplayedButtons(MENU_BUTTONS, context)
          .map(button =>
            <MenuButton
              key={button.name}
              button={button}
              accountPath={props.accountPath}
              activeButton={props.activeButton}
              activateButton={props.activateButton}
            />
          )}
        </ul>
      }
      </PermissionContext.Consumer>
    </div>
  )
}

export default SideMenu
