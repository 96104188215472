import Actions      from "../../../../model/constant/actions"
import ReducerUtils from "../../../../utils/ReducerUtils"

const addShapes = (state, action) => {
  return [
    ...state,
    ...action.data
  ]
}
const removeShape = (state, action) => {
  return state.filter(x=>x.id != action.shapeId)
}
interface Shape{
  id       : string
  name     : string
  code     : number
  polygons : number[][][]
}
const ShapesPendingValidation = ReducerUtils.createReducer<Shape[]>([],{
  [Actions.RETRIEVE_VALIDATION_SHAPES] : addShapes,
  [Actions.REMOVE_VALIDATION_SHAPE]    : removeShape
})
export default ShapesPendingValidation
