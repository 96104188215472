import './DualGraph.css'
import * as React                     from "react"
import * as d3                        from "d3"
import {withLocalize, Translate}      from "react-localize-redux"
import Period                         from "../../../../model/Period"
import DateUtils                      from "../../../../utils/DateUtils"
import LineSelector                   from "../../../../components/graph/LineSelector"
import DualAxisGraph                  from "../../../../components/graph/DualAxisGraph"
import ExplainedLoading               from "../../../../components/loading/"
import NumFormatter                   from "../../../../utils/NumberFormatter"


interface DualGraphProps{
  data            : any[]
  period          : Period
  wantedMetrics   : AVAILABLE_METRIC[]
  primaryColor   ?: string
  secondaryColor ?: string
  translate      ?: (translateId:string)=>string
}
type AVAILABLE_METRIC = "Impressions" | "Clicks" | "Cost" | "Conversions" | "AveragePosition" | "SearchImpressionShare" | "CPC" | "SearchClickShare"
const ITEMS_TRANSLATION_KEY = {
  Impressions           : "impressions",
  Clicks                : "clicks",
  Cost                  : "cost",
  Conversions           : "conversions",
  AveragePosition       : "averagePosition",
  SearchImpressionShare : "searchImpressionShare",
  CPC                   : "CPC",
}
class DualGraph extends React.Component<DualGraphProps,any>{
  static defaultProps = {
    primaryColor : "#4D5A75",
    secondaryColor : "#72B9DD"
  }
  constructor(props){
    super(props)
    this.state = {
      primary : {
        id    : "Impressions",
        text  : props.translate("common.impressions"),
        color : props.primaryColor
      },
      secondary : {
        id    : "Clicks",
        text  : props.translate("common.clicks"),
        color : props.secondaryColor
      },
    }
    this.setPrimary = this.setPrimary.bind(this)
    this.setSecondary = this.setSecondary.bind(this)
    this.getFormattedData = this.getFormattedData.bind(this)
  }
  setPrimary(item){
    this.setState(prevState=>({
      primary : {
        ...this.state.primary,
        id : item.id
      }
    }))
  }
  setSecondary(item){
    this.setState(prevState=>({
      secondary : {
        ...this.state.secondary,
        id : item.id
      }
    }))
  }
  getFormattedData(){
    const parseTime = d3.timeParse("%Y-%m-%d")
    const dataByDay = this.props.data.reduce((byDay, dataPoint)=>{
      byDay[dataPoint.Day] = dataPoint
      return byDay
    },{})
    const isDateToToday = DateUtils.format(this.props.period.dateTo) === DateUtils.format(new Date())
    const dates = DateUtils.getDatesBetween(this.props.period, true, !isDateToToday)
    const byMetric = {}
    for(const metric of this.props.wantedMetrics){
      byMetric[metric] = dates.map(date=>{
        let yValue = "0"
        const day = DateUtils.format(date)
        if(dataByDay[day]){
          yValue = dataByDay[day][metric]
        }
        return {
          line : metric,
          xValue : parseTime(day),
          yValue
        }
      })
    }

    return byMetric
  }
  render(){
    if(this.props.data === undefined){return <ExplainedLoading translateId="loadings.stats"/>}
    if(this.props.data.length === 0){ return <div className="GraphNoData"><h4><Translate id="common.noData"/></h4></div>}
    const data = this.getFormattedData()
    const items = Object.keys(data).map(index=>({
      id   : index,
      text : ITEMS_TRANSLATION_KEY[index] ? this.props.translate("dashboard.graph."+ITEMS_TRANSLATION_KEY[index]) : index
    }))
    return (
      <div className={"statPanel DualGraph"}>
        <div className={"statPanelHeader"} >
          <LineSelector
            items={items}
            itemToString={(item) => item.text}
            updatePrimary={this.setPrimary}
            updateSecondary={this.setSecondary}
            label={{
              primary   : this.state.primary.text,
              secondary : this.state.secondary.text
            }}
            fill={{
              primary   : this.state.primary.color,
              secondary : this.state.secondary.color
            }}
          />
        </div>
        <div className={"statPanelGraph"}>
          <DualAxisGraph
            primary={{
              color : this.state.primary.color,
              data  : data[this.state.primary.id] || []
            }}
            secondary={{
              color : this.state.secondary.color,
              data  : data[this.state.secondary.id] || []
            }}
          />
        </div>
      </div>
    )
  }
}

export default withLocalize(DualGraph) as React.ComponentType<DualGraphProps>
