import {createSelector}                  from "reselect"
import ArrayUtils                        from "../utils/ArrayUtils"
import DateUtils                         from "../utils/DateUtils"
import StatisticsUtils                   from "../utils/StatisticsUtils"
import ObjectUtils                       from "../utils/ObjectUtils"
import DisplayCampaign                   from "../model/Store/Campaign/DisplayCampaign"
import Campaign                          from "../model/Store/Campaign/Campaign"
import {GeoStat, GeoStatsCompiledForMap} from "../model/Store/Statistics/GeoStats"
import * as Generic                      from "./Generic"
import {Provider}                        from "../model/Provider"
import { DeviceStatistics }              from "../model/constant/DeviceStatistics"

export default class StatisticsAccessors{
  public static makeCampaignsStatsInCurrentPeriodSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPeriod,
      Generic.getCampaignsStats,
      Generic.getBingCampaignsStats,
      Generic.getRetrievingCampaignsStats,
      (accountId, period, awCampaignStats, bingCampaignStats, retrievingStats) => {
        if(retrievingStats ||
           awCampaignStats[accountId]===undefined ||
           bingCampaignStats[accountId]===undefined
        ){return undefined}
        return StatisticsUtils.filterToPeriod(period,"Day",
          [...awCampaignStats[accountId],...bingCampaignStats[accountId]]
         )
      }
    )
  }
  public static makeAWCampaignsStatsInCurrentPeriodSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPeriod,
      Generic.getCampaignsStats,
      Generic.getRetrievingCampaignsStats,
      (accountId, period, awCampaignStats, retrievingStats) => {
        if(retrievingStats || awCampaignStats[accountId]===undefined){return undefined}
        return StatisticsUtils.filterToPeriod(period,"Day",awCampaignStats[accountId])
      }
    )
  }
  public static makeBingCampaignsStatsInCurrentPeriodSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPeriod,
      Generic.getBingCampaignsStats,
      Generic.getRetrievingCampaignsStats,
      (accountId, period, bingCampaignStats, retrievingStats) => {
        if(retrievingStats || bingCampaignStats[accountId]===undefined){return undefined}
        return StatisticsUtils.filterToPeriod(period,"Day",bingCampaignStats[accountId])
      }
    )
  }
  public static makeAWKeywordsStatsInCurrentPeriodSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPeriod,
      Generic.getKeywordsStats,
      Generic.getRetrievingKeywordsStats,
      (accountId, period, keywordsStats, retrievingStats) => {
        if(retrievingStats || keywordsStats[accountId]===undefined){return undefined}
        return StatisticsUtils.filterToPeriod(period,"Day",keywordsStats[accountId])
      }
    )
  }
  public static makeBingKeywordsStatsInCurrentPeriodSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPeriod,
      Generic.getBingKeywordsStats,
      Generic.getRetrievingKeywordsStats,
      (accountId, period, keywordsStats, retrievingStats) => {
        if(retrievingStats || keywordsStats[accountId]===undefined){return undefined}
        return StatisticsUtils.filterToPeriod(period,"Day",keywordsStats[accountId])
      }
    )
  }
  public static makeBudgetsStatsInCurrentPeriodSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPeriod,
      Generic.getBudgetsStats,
      Generic.getBingBudgetsStats,
      Generic.getRetrievingBudgetsStats,
      (accountId, period, awBudgetsStats, bingBudgetStats, retrievingStats) => {
        if(retrievingStats ||
           awBudgetsStats[accountId]===undefined ||
           awBudgetsStats[accountId].byDay===undefined ||
           bingBudgetStats[accountId]===undefined
        ){return undefined}
        return StatisticsUtils.filterToPeriod(period,"Day",
          [...awBudgetsStats[accountId].byDay,...bingBudgetStats[accountId]]
        )
      }
    )
  }
  public static makeGeoBidsStatsInCurrentPeriodSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPeriod,
      Generic.getGeoBidsStats,
      Generic.getBingGeoBidsStats,
      Generic.getRetrievingGeoBidsStats,
      (accountId, period, awGeoBidsStats, bingGeoBidsStats, retrievingStats) => {
        if(retrievingStats ||
           awGeoBidsStats[accountId] === undefined ||
           bingGeoBidsStats[accountId] === undefined
        ){return undefined}
        return StatisticsUtils.filterToPeriod(period, "Day",
          [...awGeoBidsStats[accountId],...bingGeoBidsStats[accountId]]
        )

      }
    )
  }
  public static makeStatsByDeviceStatsInCurrentPeriodForCampaignAWIdsSelector(){
    return createSelector(
      Generic.getImpressionsByDeviceStats,
      Generic.getRetrievingImpressionsByDeviceStats,
      Generic.getCampaignsBaseInfoById,
      Generic.getPeriod,
      Generic.getAccountId,
      (_,props)=>props.campaignAWIds,
      (allStats, retrievingStats, baseInfosById, period, accountId, campaignAWIds)=>{
        if(retrievingStats || allStats[accountId]===undefined){return undefined}
        const campaignNames = campaignAWIds.reduce((acc,AWId)=>{
          const baseInfo = baseInfosById[accountId][AWId]
          if(baseInfo){acc.push(baseInfo.Name)}
          return acc
        },[])
        const stats = allStats[accountId].filter(x=>(
          campaignNames.indexOf(x.Name)!==-1) ||
          campaignAWIds.indexOf(x.CampaignAWId+"")!==-1
        )
        return (
          ObjectUtils.getObjectValues(
            ObjectUtils.mapOnObject(
              StatisticsUtils.filterToPeriod(period,"Day",stats)
              .reduce(
                (a,stat)=>{
                  if(!a[stat.Device]){
                    a[stat.Device] = {
                      [DeviceStatistics.impressions] : 0,
                      [DeviceStatistics.clicks]      : 0,
                      [DeviceStatistics.conversions] : 0,
                      [DeviceStatistics.cost]        : 0,
                    }
                  }
                  a[stat.Device][DeviceStatistics.impressions] += stat.Impressions
                  a[stat.Device][DeviceStatistics.clicks]      += stat.Clicks
                  a[stat.Device][DeviceStatistics.conversions] += stat.Conversions
                  a[stat.Device][DeviceStatistics.cost]        += stat.Cost
                  return a
                },
                {}
              ),
              (key,value)=>({name:key, value:value})
            )
          )
        )
      }
    )
  }
  public static makeImpressionsByDeviceStatsInCurrentPeriodForDisplayCampaignSelector(){
    return createSelector(
      Generic.getImpressionsByDeviceStats,
      Generic.getRetrievingImpressionsByDeviceStats,
      Generic.getPeriod,
      (_,campaign)=>campaign,
      (stats, retrievingStats, period, campaign:DisplayCampaign)=>{
        const AWId = campaign.externalCampaignId
        if(!stats[AWId] || retrievingStats){return undefined}
        return (
          ObjectUtils.getObjectValues(
            ObjectUtils.mapOnObject(
              StatisticsUtils.filterToPeriod(period,"Day",stats[AWId])
              .filter(x=>AWId === x.CampaignAWId)
              .reduce((a,stat)=>{
                if(a[stat.Device]===undefined){a[stat.Device]=0}
                a[stat.Device] += stat.Impressions
                return a
              },{}),
              (key,value)=>({name:key, value:value})
            )
          )
        )
      }
    )
  }
  public static makeSearchTermsStatsInCurrentPeriodForCampaignExternalIdsSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getSearchTermsStats,
      Generic.getBingSearchTermsStats,
      Generic.getRetrievingSearchTermsStats,
      Generic.getPeriod,
      (_,ownProps)=>ownProps.campaignNames,
      (accountId, googleStats, bingStats, retrievingStats, period, campaignNames)=>{
        if(retrievingStats || (
          googleStats[accountId] === undefined &&
          bingStats[accountId]   === undefined
        )){return undefined}
        const stats = [...(googleStats[accountId]||[]),...(bingStats[accountId]||[])].filter(
          x=>campaignNames.indexOf(x.Name)!==-1
        )
        return (
          ObjectUtils.getObjectValues(
            ObjectUtils.mapOnObject(
              StatisticsUtils.filterToPeriod(period, "Day", stats)
              .reduce((statsByKeyword, x)=>{
                const {CampaignAWId,Day,Group,Name,VehicleState,VehicleType, ...cleanedStat} = x
                const calculableKeys = Object.keys(cleanedStat).filter(test=>!(test == "Keywords" || test == "SearchTerms"))
                const uniqueKey = cleanedStat.SearchTerms + cleanedStat.Keywords
                if(!statsByKeyword[uniqueKey]){
                  statsByKeyword[uniqueKey] = {
                    ...cleanedStat,
                  }
                }
                else{
                  for(let key of calculableKeys){
                    statsByKeyword[uniqueKey][key] += cleanedStat[key]
                  }
                }
                return statsByKeyword
              },{}),
              (key,value)=>({
                id          : key,
                searchTerms : value.SearchTerms,
                keywords    : value.Keywords,
                clicks      : value.Clicks,
                cost        : value.Cost,
                cpc         : value.Clicks != 0 ? (value.Cost/value.Clicks) : 0
              })
            )
          )
        )
      }
    )
  }
  public static makePlacementStatsInCurrentPeriodForCampaignSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPlacementsStats,
      Generic.getRetrievingPlacementsStats,
      Generic.getPeriod,
      (_,campaign)=>campaign,
      (accountId, stats, retrievingStats, period, campaign:Campaign)=>{
        if(retrievingStats || stats[accountId] === undefined){return undefined}
        return (
          stats[accountId]
          .filter(x=>campaign.externalCampaignId === x.CampaignAWId)
          .map(x=>({
            ...x,
            CPC : x.Cost/x.Clicks
          }))
        )
      }
    )
  }
  public static getCampaignsStatsInCurrentPeriod(state):any[]|undefined{
    if(state.Statistics.Retrieving.Campaigns){return undefined}
    const accountId = state.Accounts.selected
    if(state.Statistics.Google.Campaigns[accountId] === undefined){return undefined}
    return StatisticsUtils.filterToPeriod(state.Period,"Day",state.Statistics.Google.Campaigns[accountId])
  }
  public static getKeywordsStatsInCurrentPeriod(state):any[]|undefined{
    if(state.Statistics.Retrieving.Keywords){return undefined}
    const accountId = state.Accounts.selected
    if(state.Statistics.Google.Keywords[accountId] === undefined){return undefined}
    return StatisticsUtils.filterToPeriod(state.Period,"Day",state.Statistics.Google.Keywords[accountId])
  }
  public static getBudgetsStatsByDayInCurrentPeriod(state):any[]|undefined{
    if(state.Statistics.Retrieving.Budgets){return undefined}
    const accountId = state.Accounts.selected
    if(state.Statistics.Google.Budgets[accountId] === undefined){return undefined}
    if(state.Statistics.Google.Budgets[accountId].byDay === undefined){return undefined}
    return StatisticsUtils.filterToPeriod(state.Period,"Day",state.Statistics.Google.Budgets[accountId].byDay)
  }
  public static makeGeoBidsStatsInCurrentPeriodForCampaignAWIds(){
    return createSelector(
      Generic.getAccountId,
      Generic.getGeoBidsStats,
      Generic.getRetrievingGeoBidsStats,
      Generic.getPeriod,
      Generic.getCampaignsBaseInfoById,
      (_,ownProps)=>ownProps.campaignAWIds,
      (accountId, allStats, retrievingStats, period, baseInfosById, campaignAWIds) => {
        if(retrievingStats || allStats[accountId] === undefined){return undefined}
        const campaignNames = campaignAWIds.reduce((acc,AWId)=>{
          const baseInfo = baseInfosById[accountId][AWId]
          if(baseInfo){acc.push(baseInfo.Name)}
          return acc
        },[])
        const stats = allStats[accountId].filter(x=>(
          campaignNames.indexOf(x.Name)!==-1) ||
          campaignAWIds.indexOf(x.CampaignAWId+"")!==-1
        )
        return StatisticsUtils.filterToPeriod(period, "Day", stats)
      }
    )
  }
  public static makeReportCompiledCampaignsStatsSelector(provider:Provider){
    const statSource = provider===Provider.BING
      ? StatisticsAccessors.makeBingCampaignsStatsInCurrentPeriodSelector()
      : Provider.AW
        ? StatisticsAccessors.makeAWCampaignsStatsInCurrentPeriodSelector()
        : undefined
    if(statSource===undefined){ throw Error("No stat source for provider ${provider}") }
    return createSelector(
      statSource,
      (_,template)=>template,
      (stats,template)=>{
        if(!stats){return undefined}
        const result = template.compileStats(stats)
        if(!result || (result.length===1 && Object.keys(result[0]).length===0)){return []}
        return result
      }
    )
  }
  public static makeReportCompiledKeywordsStatsSelector(provider:Provider){
    const statSource = provider===Provider.BING
      ? StatisticsAccessors.makeBingKeywordsStatsInCurrentPeriodSelector()
      : Provider.AW
        ? StatisticsAccessors.makeAWKeywordsStatsInCurrentPeriodSelector()
        : undefined
    return createSelector(
      statSource,
      (_,template)=>template,
      (stats,template)=>{
        const result = template.compileStats(stats)
        if(!result || (result.length===1 && Object.keys(result[0]).length===0)){return undefined}
        return result
      }
    )
  }
  public static makeCampaignsStatsInSymmetricalPeriodSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPeriod,
      Generic.getCampaignsStats,
      Generic.getRetrievingCampaignsStats,
      (accountId, period, stats, retrievingStats)=>{
        if(retrievingStats || stats[accountId] === undefined){return undefined}
        const symmetricalPeriod = DateUtils.getSymmetricalPeriod(period)
        return StatisticsUtils.filterToPeriod(symmetricalPeriod,"Day",stats[accountId])
      }
    )
  }
  public static makeCampaignsStatsInCurrentAndSymmetricalPeriodSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPeriod,
      Generic.getCampaignsStats,
      Generic.getBingCampaignsStats,
      Generic.getRetrievingCampaignsStats,
      (accountId, period, awCampaignsStats, bingCampaignStats, retrievingStats)=>{
        if(retrievingStats ||
           awCampaignsStats[accountId] === undefined ||
           bingCampaignStats[accountId] === undefined
        ){return undefined}
        const extendedPeriod = {
          dateFrom : DateUtils.getSymmetricalPeriod(period).dateFrom,
          dateTo   : period.dateTo
        }
        return StatisticsUtils.filterToPeriod(extendedPeriod,"Day",
          [...awCampaignsStats[accountId],...bingCampaignStats[accountId]]
        )
      }
    )
  }
  public static makeCampaignsStatsInCurrentAndSymmetricalSeparatedPeriodSelector(){
    return createSelector(
      Generic.getAccountId,
      Generic.getPeriod,
      Generic.getCampaignsStats,
      Generic.getRetrievingCampaignsStats,
      (accountId, period, campaignsStats, retrievingStats)=>{
        if(retrievingStats || campaignsStats[accountId] === undefined){
          return {current: undefined, symmetrical: undefined}
        }
        const symmetricalPeriod = DateUtils.getSymmetricalPeriod(period)
        return {
          current : StatisticsUtils.filterToPeriod(period,"Day",campaignsStats[accountId]),
          symmetrical : StatisticsUtils.filterToPeriod(symmetricalPeriod,"Day",campaignsStats[accountId])
        }
      }
    )
  }
  public static makeCompiledForMapGeoBidStatsSelector(
    buildFilter:(state,ownProps) => void | ((row:GeoStat,index:number)=>boolean)
  ) : (state,ownProps) => GeoStatsCompiledForMap {
    return createSelector(
      StatisticsAccessors.makeGeoBidsStatsInCurrentPeriodSelector(),
      Generic.getGeocodingCache,
      Generic.getGeocodingMetaCache,
      Generic.getBingGeoMapping,
      Generic.getRetrievingGeoMapping,
      buildFilter,
      (allStats, cache, metaCache, bingGeoMapping, retrievingGeoMapping, filterMore) => {
        if(retrievingGeoMapping || allStats === undefined){
          return {
            codesWithoutGPS : [],
            clicksOnAdWords : []
          }
        }
        const filteredStats = filterMore ? allStats.filter(filterMore) : allStats
        const codesWithoutGPS = ArrayUtils.unique(
          filteredStats
          .reduce((acc,stat)=>{
            if(stat.Provider == Provider.AW){
              if(cache[stat.SpecificLocationId]||metaCache[stat.SpecificLocationId]){
                acc.push(stat.SpecificLocationId)
              }
            }
            if(stat.Provider == Provider.BING){
              if(stat.SpecificLocationId.toString() in bingGeoMapping){
                if(cache[bingGeoMapping[stat.SpecificLocationId]]||metaCache[bingGeoMapping[stat.SpecificLocationId]]){
                  acc.push(bingGeoMapping[stat.SpecificLocationId])
                }
              }
            }
            return acc
          },[])
        )
        const clicksOnAdWords = filteredStats.map(stat=>{
          let awCriterionId
          if(stat.Provider == Provider.AW){
            awCriterionId = stat.SpecificLocationId
          }
          if(stat.Provider == Provider.BING){
            awCriterionId = bingGeoMapping[stat.SpecificLocationId]
          }
          return {
            ...stat,
            code         : awCriterionId,
            GPSAvailable : (
              cache[awCriterionId]!==undefined ||
              metaCache[awCriterionId]!==undefined
            ),
          }
        }).filter(x=>x.code)
        return {
          codesWithoutGPS,
          clicksOnAdWords
        }
      }
    )
  }
  public static makeBudgetStatsByMonthSelector(){
    return createSelector(
      (state:any) => state.Accounts.selected,
      (state:any) => state.Statistics.Google.Budgets,
      (accountId, budgetsStats)=>{
        if(!budgetsStats[accountId]){return undefined}
        const stats = budgetsStats[accountId]
        let byMonth = stats.reduce((byMonth, stat)=>{
          const id = stat.BudgetId
          const date = new Date(stat.Day)
          const year = date.getUTCFullYear()
          const month = (date).getUTCMonth()
          const fullMonth = year+"-"+month
          if(!byMonth[fullMonth]){byMonth[fullMonth] = {}}
          if(!byMonth[fullMonth][id]){
            const Day = DateUtils.getFormattedDate(date, true)
            byMonth[fullMonth][id] = {...stat, Day, Spent : 0}
          }
          byMonth[fullMonth][id].Spent += stat.Spent
          return byMonth
        },{})
        const test = {}
        return ArrayUtils.flatten(
          ObjectUtils.getObjectValues(byMonth).map(x=>{
            const data = ObjectUtils.getObjectValues(x)
            return data
          })
        )
      }
    )
  }
}
