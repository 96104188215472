import './MenuToggle.css'
import * as React from "react"

interface ToggleProps{
  icon    : string
  onClick : (any)=>any // function
  className ?: string
}
export const MenuToggle:React.SFC<ToggleProps> = (props) =>{
  return (
    <div className={"MenuToggleBase "+props.className} onClick={props.onClick}>
      <span><i className="material-icons">{props.icon}</i></span>
    </div>
  )
}
MenuToggle.defaultProps = {
  icon      : "menu",
  onClick   : (e)=>e.preventDefault(), // function
  className : "MenuToggle"
}
