import ReducerUtils      from "../../utils/ReducerUtils"
import Actions           from "../../model/constant/actions"

const changeActiveButton = (state, action) => {
  return action.index
}

export const ActiveButton = ReducerUtils.createReducer<string>("",{
  [Actions.CHANGE_ACTIVE_BUTTON_IN_MENU] : changeActiveButton
})
