import ReducerUtils   from "../../../utils/ReducerUtils"
import Actions        from "../../../model/constant/actions"
import Period         from "../../../model/Period"
import moment         from "moment"

const setRangeByDay = (state:RetrievedRangeByAccount, action):RetrievedRangeByAccount => {
  if(!state[action.accountId]){
    return {
      ...state,
      [action.accountId] : action.period
    }
  }
  const currentPeriod = state[action.accountId]
  const updatedPeriod:Period = {
    dateFrom : moment(currentPeriod.dateFrom).startOf("day") < moment(action.period.dateFrom).startOf("day") ? currentPeriod.dateFrom : action.period.dateFrom,
    dateTo   : moment(currentPeriod.dateTo).startOf("day")   > moment(action.period.dateTo).startOf("day")   ? currentPeriod.dateTo   : action.period.dateTo
  }
  return {
    ...state,
    [action.accountId] : updatedPeriod
  }
}

const clear = (state:RetrievedRangeByAccount, action):RetrievedRangeByAccount => {
  if(!state[action.accountId]){return state}
  return {
    ...state,
    [action.accountId] : undefined
  }
}

interface RetrievedRangeByAccount{
  [accountId:number] : Period
}
const defaultValue = {byDay: {}}
const SpendAttempts = ReducerUtils.createReducer<RetrievedRangeByAccount>(defaultValue,{
  [Actions.RETRIEVE_PACY_SPEND_ATTEMPTS] : setRangeByDay,
  [Actions.PACY_CLEAR] : clear
})

export default SpendAttempts
