import Actions                     from "../../model/constant/actions"
import ReducerUtils                from "../../utils/ReducerUtils"
import ReportTableTemplate         from "../../model/Store/Report/ReportTableTemplate"

const editTemplate = (state, action) => {
  const newTemplate = action.template.clone()
  let replaced = false
  let newState = state.map(template=>{
    if(template.getId() === newTemplate.getId()){
      replaced = true
      return newTemplate
    }
    return template
  })
  if(!replaced){newState = [...newState, newTemplate]}
  return newState
}
const editColumns = (state, action) => {
  const templateId = action.templateId
  return state.map(template=>{
    if(template.getId() === templateId){
      let updatedTemplate = template.clone()
      const updatedColumns = updatedTemplate.getColumns().map(c=>{
        let column = c.clone()
        let found = false
        for(let i=0;i<action.columns.length;i++){
          if(column.getDataField() === action.columns[i].dataField){
            found = true
            column.setHidden(false)
            column.setOrderIndex(i)
          }
        }
        if(!found){column.setHidden(true)}
        return column
      })
      updatedTemplate.setColumns(updatedColumns)
      return updatedTemplate
    }
    return template
  })
}
const editName = (state, action) => {
  const templateId = action.templateId
  return state.map(template=>{
    if(template.getId() === templateId){
      let updatedTemplate = template.clone()
      updatedTemplate.setName(action.name)
      return updatedTemplate
    }
    return template
  })
}
const editSizePerPage = (state, action) => {
  const templateId = action.templateId
  return state.map(template=>{
    if(template.getId() === templateId){
      let updatedTemplate = template.clone()
      updatedTemplate.setSizePerPage(action.size)
      return updatedTemplate
    }
    return template
  })
}
const editDefaultSortedColumn = (state, action) => {
  const templateId = action.templateId
  return state.map(template=>{
    if(template.getId() === templateId){
      let updatedTemplate = template.clone()
      updatedTemplate.setDefaultSortedColumn(action.dataField)
      return updatedTemplate
    }
    return template
  })
}
const editDefaultSortedOrder = (state, action) => {
  const templateId = action.templateId
  return state.map(template=>{
    if(template.getId() === templateId){
      let updatedTemplate = template.clone()
      updatedTemplate.setDefaultSortedOrder(action.order)
      return updatedTemplate
    }
    return template
  })
}
const editDefaultFilter = (state, action) => {
  const templateId = action.templateId
  return state.map(template=>{
    if(template.getId() === templateId){
      let updatedTemplate = template.clone()
      updatedTemplate.setColumnDefaultFilterValue(action.dataField, action.filterValue)
      return updatedTemplate
    }
    return template
  })
}

export const Templates = ReducerUtils.createReducer<ReportTableTemplate[]>([],{
  [Actions.EDIT_TEMPLATE]                       : editTemplate,
  [Actions.EDIT_TEMPLATE_COLUMNS]               : editColumns,
  [Actions.EDIT_TEMPLATE_NAME]                  : editName,
  [Actions.EDIT_TEMPLATE_SIZE_PER_PAGE]         : editSizePerPage,
  [Actions.EDIT_TEMPLATE_DEFAULT_SORTED_COLUMN] : editDefaultSortedColumn,
  [Actions.EDIT_TEMPLATE_DEFAULT_SORTED_ORDER]  : editDefaultSortedOrder,
  [Actions.EDIT_TEMPLATE_DEFAULT_FILTER]        : editDefaultFilter
})
