import * as React        from "react"
import {combineReducers} from 'redux'
import Retrieving        from "./Retrieving"
import Failed            from "./Failed"
import AdCopies          from "./AdCopies"
import Saving            from "./Saving"
import FailedSaving      from "./FailedSaving"

const RSAAdcopies = combineReducers({
  Retrieving,
  Failed,
  AdCopies,
  FailedSaving,
  Saving
})

export default RSAAdcopies
