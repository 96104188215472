import MathUtils from "./MathUtils"
import * as Papa from "papaparse"

export default class CSVUtils {
  public static generateCSV = (columns:string[], data:any[][], delimiter:string=","):string => {
    if(delimiter.length != 1){throw Error("CSV delimiter must be 1 character long")}
    return (
      columns.map(c=>CSVUtils.escapeField(c, delimiter)).join(delimiter)+"\r\n"+(
        data.map(row=>row.map(field=>CSVUtils.escapeField(field, delimiter)).join(delimiter)).join("\r\n")
      )
    )
  }
  public static downloadCSV = (fileName:string, csv:string) => {
    let download = document.createElement("a")
    download.setAttribute('href', "data:text/csv;charset=utf-8,"+encodeURIComponent(csv))
    download.setAttribute('download', fileName)
    document.body.appendChild(download)
    download.click()
    document.body.removeChild(download) //Not sure if useless/doesn't break anything
  }
  private static escapeField = (field:any, delimiter:string):string => {
    if(field === undefined || field === null){return ""}
    if(Number(field) === field && field % 1 !== 0){ //is float?
      field = MathUtils.round(field,2)
    }
    if(field.toString().includes(delimiter)){
      return '"'+field+'"'
    }
    return field.toString()
  }
  public static parseCSVWithHeaders = (csv:string, transformHeader?:(header:string)=>string):any[] => {
    const beforeFirstChunk = (chunk:string)=>{
      if(!transformHeader){return chunk}
      const index = chunk.match(/\r\n|\r|\n/).index
      const headers = chunk.substr(0, index).split(',')
      for(let i = 0; i < headers.length; i++){headers[i] = transformHeader(headers[i])}
      return headers.join() + chunk.substr(index)
    }
    const parsed = Papa.parse(csv,{
      header:true,
      skipEmptyLines:true,
      dynamicTyping:true,
      beforeFirstChunk
    })
    return parsed.data
  }
}
