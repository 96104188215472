import Reporting                from "../../utils/Reporting"
import CSVUtils                 from "../../utils/CSVUtils"
import ActionUtils              from "../../utils/ActionUtils"
import Actions                  from "../../model/constant/actions"
import {getToken}               from "../../storeAccessor/Generic"
import {Provider}               from "../../model/Provider"
import {retrieveBingGeoMapping} from "./bingGeoMapping"

const googleGeoStatsFieldRenaming = {
  CampaignId             : "CampaignAWId",
  Date                   : "Day",
  MostSpecificCriteriaId : "SpecificLocationId"
}
const bingGeoStatsFieldRenaming = {
  CampaignId             : "CampaignBingId",
  TimePeriod             : "Day",
  MostSpecificCriteriaId : "SpecificLocationId"
}
const renameAWHeaders   = (header)=>googleGeoStatsFieldRenaming[header]||header
const renameBingHeaders = (header)=>bingGeoStatsFieldRenaming[header]||header

const loadGeoBidsStats = (accountId:number, accountAWId:string, accountBingId:string, period) => async (dispatch, getState) => {
  const token = getToken(getState())
  dispatch({
    type   : Actions.RETRIEVING_GEOBIDS_STATISTICS,
    status : true
  })
  try{
    const actions = await Promise.all([
      getAWGeoBidStatsAction(token,accountId,accountAWId,period),
      getBingGeoBidStatsAction(token,accountId,accountBingId,period)
    ])
    for(const action of actions){
      if(action){
        dispatch(action)
        if(action.type === Actions.RETRIEVE_BING_GEOBID_STATS_OF_ACCOUNT && action.stats.length>0){
          dispatch(retrieveBingGeoMapping())
        }
      }
    }
  }
  catch(e){
    console.log("General error while retrieving geoBid stats", e)
  }
  dispatch({
    type   : Actions.RETRIEVING_GEOBIDS_STATISTICS,
    status : false
  })
}
export default loadGeoBidsStats


const getAWGeoBidStatsAction = (token:string, accountId:number, accountAWId:string, period) => {
  return ActionUtils.retryOnFailure(()=>
    Reporting.getAWGeoStats(token, accountAWId, period)
  )
  .then(
    response => {
      const stats = CSVUtils.parseCSVWithHeaders(response.data, renameAWHeaders)
      .map(x=>{
        x.Provider=Provider.AW
        return x
      })
      return {
        type : Actions.RETRIEVE_AW_GEOBIDS_STATS_OF_ACCOUNT,
        accountId,
        stats,
      }
    },
    error    => {
      return {
        type      : Actions.RETRIEVE_AW_GEOBIDS_STATS_OF_ACCOUNT,
        accountId : accountId,
        stats     : []
      }
    }
  )
}

const getBingGeoBidStatsAction = (token:string, accountId:number, accountBingId:string, period) => {
  const baseAction = {
    type : Actions.RETRIEVE_BING_GEOBID_STATS_OF_ACCOUNT,
    accountId,
    stats : [],
    period
  }
  if(accountBingId === "0"){return baseAction}
  return ActionUtils.retryOnFailure(()=>
    Reporting.getBingGeoStats(token,accountBingId,period)
  )
  .then((response)=>{
    baseAction.stats = CSVUtils.parseCSVWithHeaders(response.data, renameBingHeaders)
    .map(x=>{
      x.Provider=Provider.BING
      return x
    })
    return baseAction
  })
  .catch((error)=>{
    console.log(error)
  })
}
