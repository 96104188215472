import {
  makeStyles,
  Theme,
  createStyles
}                      from "@material-ui/core"

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    container : {
      padding: "20px 20px 20px 0px",
    },
    header : {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "left"
    },
    languages : {
      display: "flex",
      flexDirection: "row"
    },
    selectAdGroup : {
      marginRight: 60,
      marginLeft: 60
    },
    editing :  {
      padding: "20px 20px 20px 0px",
    },
    formControl : {

    },
    label:{},
    selects : {
      width : 250,
      marginRight : 50,
    },
  })
)

export default useStyles
