import Actions                      from "../model/constant/actions"
import {getToken}                   from "../storeAccessor/Generic"
import ActionUtils                  from "../utils/ActionUtils"
import Headstone                    from "../utils/Headstone"
import { AxiosResponse }            from "axios"
import { isRadius, isPlace }        from '../utils/GeoBidUtils'
import {
  HeadstoneGeoBidRadius,
  HeadstoneGeoBidPlace,
  HeadstoneGeoBidResponse
}                                   from "../model/headstone/geoBids"

function createRadius(radius: HeadstoneGeoBidRadius){
  return {
    lat         : radius.lat,
    lng         : radius.long,
    radius      : radius.radius,
    bidModifier : radius.bidModifier
  }
}
function createPlace(place: HeadstoneGeoBidPlace){
  return {
    code        : place.code,
    include     : place.include,
    bidModifier : place.bidModifier
  }
}

const getGeoBidSuccess = (response: AxiosResponse<HeadstoneGeoBidResponse[]>, accountId: number) => {
  const geoTargetings = response.data.map(geoBidsAndMatcher=>{
    let radiuses = []
    let places = []

    for(let geoBid of geoBidsAndMatcher.geoBids){
      if(isRadius(geoBid)){radiuses.push(createRadius(geoBid))}
      if(isPlace(geoBid)){places.push(createPlace(geoBid))}
    }
    return {
      accountId : accountId,
      radiuses  : radiuses,
      places    : places,
      matcher   : geoBidsAndMatcher.matcher
    }
  })
  return {
    type          : Actions.RETRIEVE_GEOBID,
    geoTargetings : geoTargetings,
    accountId     : accountId,
  }
}
export const getGeoBid = (accountId:number) => (dispatch, getState) => {
  dispatch({
    type : Actions.RETRIEVING_GEOBIDS,
    status : true
  })

  ActionUtils.retryOnFailure(
    () => Headstone.getGeoBidSettings(getToken(getState()), accountId, "AW") // Hard coded by intention for the time being
  )
  .then((response) => {
    dispatch(getGeoBidSuccess(response, accountId))
  })
  .catch((error) => {
    console.log(error)
  })
  .then(() => {
    dispatch({
      type   : Actions.RETRIEVING_GEOBIDS,
      status : false
    })
  })
}
