import ReducerUtils from "../../../utils/ReducerUtils"
import Actions from "../../../model/constant/actions"

const setStatus = (state, action) => {
  return action.status
}

const Placements = ReducerUtils.createReducer<boolean>(false,{
  [Actions.RETRIEVING_PLACEMENTS_STATISTICS] : setStatus
})

export default Placements
