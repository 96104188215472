import * as React              from 'react'
import ArrayUtils              from '../../utils/ArrayUtils'
import ObjectUtils             from '../../utils/ObjectUtils'
import Replacement             from '../../model/grammarBooster/Replacement'
import {spacesToUnderscore}    from '../../model/grammarBooster/Replacement'
import {underscoreToSpaces}    from '../../model/grammarBooster/Replacement'
import {Button}                from "carbon-components-react"


interface GrammarBoosterEditorProps {
  replacement     : Replacement
  saveReplacement : (replacement:Replacement)=>void
  standout        : boolean
}
interface GrammarBoosterEditorState {
  clone : Replacement
}

const clone = (replacement:Replacement) : Replacement => ({
  ...replacement,
  modifiedOn : {
    ...replacement.modifiedOn
  }
})
class GrammarBoosterEditor extends React.Component<GrammarBoosterEditorProps,GrammarBoosterEditorState>{

  constructor(props:GrammarBoosterEditorProps){
    super(props)
    this.state = {
      clone : clone(props.replacement)
    }
    this.change = this.change.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }
  componentDidUpdate(prevProps:GrammarBoosterEditorProps, prevState:GrammarBoosterEditorState){
    if(this.props.replacement.id !== prevProps.replacement.id){
      this.setState(prevState=>({clone : clone(this.props.replacement)}))
    }
  }
  change(key:string, desired:string|boolean) : void {
    this.setState((prevState:GrammarBoosterEditorState) => ({
      clone : {
        ...prevState.clone,
        [key] : desired
      }
    }))
  }
  handleSave(){
    this.props.saveReplacement({
      ...this.state.clone,
      wrong : underscoreToSpaces(this.state.clone.wrong),
      correct : underscoreToSpaces(this.state.clone.correct)
    })
  }
  render(){
    return (
      <div className={'GrammarBoosterEditor '+(this.props.standout?"standout":"")}>
        <div>
          <h4>
            {this.props.replacement.id
              ? "Modifying replacement #"+this.props.replacement.id
              : "Creating a new replacement"
            }
          </h4>
        </div>
        <div className='EditorForm'>
          <div>
            <select
              key={this.props.replacement.id}
              className="ReplacementKeyStrings lang"
              value={this.state.clone.lang}
              onChange={e=>this.change("lang",e.target.value)}
            >
              <option value="EN">EN</option>
              <option value="FR">FR</option>
              <option value="ES">ES</option>
            </select>
          </div>
          <div>
            <input
              key={this.props.replacement.id}
              className="ReplacementKeyStrings wrong"
              value={spacesToUnderscore(this.state.clone.wrong)}
              placeholder="The thing that's wrong"
              onChange={e=>this.change("wrong",e.target.value)}
            ></input>
          </div>
          <div className="Arrow">
            <span style={{fontSize:"20px", margin:"0px 16px"}}>▼</span>
          </div>
          <div>
            <input
              key={this.props.replacement.id}
              className="ReplacementKeyStrings correct"
              value={spacesToUnderscore(this.state.clone.correct)}
              placeholder="The good way to write it"
              onChange={e=>this.change("correct",e.target.value)}
            ></input>
          </div>
          <div>
            <select
              key={this.props.replacement.id}
              value={this.state.clone.isARegex?'1':'0'}
              onChange={e=>this.change("isARegex",e.target.value==='1')}
            >
              <option value='1'>Is a Regex</option>
              <option value='0'>Is not a Regex</option>
            </select>
          </div>
          <div>
            <select
              key={this.props.replacement.id}
              value={this.state.clone.active?'1':'0'}
              onChange={e=>this.change("active",e.target.value==='1')}
            >
              <option value='1'>Active</option>
              <option value='0'>Inactive</option>
            </select>
          </div>
          <div className="SaveButton">
            <Button onClick={this.handleSave}>Save</Button>
          </div>
        </div>
      </div>
    )
  }
}
export default GrammarBoosterEditor
