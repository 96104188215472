import Actions          from "../../../model/constant/actions"
import ReducerUtils     from "../../../utils/ReducerUtils"

const saveBool = (state,action) => {
  return action.status
}
const RetrievingBudgets = ReducerUtils.createReducer<boolean>(false,{
  [Actions.RETRIEVING_BUDGETS_FOR_ACCOUNTID] : saveBool,
})
export default RetrievingBudgets
