import ActionUtils                                       from "../../utils/ActionUtils"
import Actions                                           from "../../model/constant/actions"
import {getToken}                                        from "../../storeAccessor/Generic"
import Headstone                                         from "../../utils/Headstone"
import LogBuilder                                        from "../../controller/log/LogBuilder"
import UserActions                                       from "../../model/constant/UserAction"
import { SimplifiedIdentityVariants }                    from "../../model/branding/SimplifiedIdentityVariants"

const extractMessageFromError = (error:any) => {
  if(error.response && error.response.data){
    return error.response.data.error
  }
}

export const retrieveIdentityVariants= (accountId:number) => (dispatch, getState) => {
  dispatch({type: Actions.RETRIEVING_IDENTITY_VARIANTS, status: true})
  const token = getToken(getState())
  ActionUtils.retryOnFailure(
    ()=>Headstone.getIdentityVariants(token, accountId)
  )
  .then((response)=>{
    dispatch({
      type         : Actions.RETRIEVE_IDENTITY_VARIANTS,
      identityVariants : response.data,
      accountId
    })
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_RETRIEVING_IDENTITY_VARIANTS,
      status : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type: Actions.RETRIEVING_IDENTITY_VARIANTS, status: false})
  })
}
export const saveIdentityVariants = (accountId:number, lang:string, identityVariants:SimplifiedIdentityVariants) => (dispatch, getState) => {
  dispatch({type: Actions.SAVING_IDENTITY_VARIANTS, status: true})
  const token = getToken(getState())
  LogBuilder.log(UserActions.BRANDING_SAVE_IDENTITY_VARIANTS,token,{accountId,lang,identityVariants})
  ActionUtils.retryOnFailure(
    ()=>Headstone.saveIdentityVariants(token, accountId, lang, identityVariants)
  )
  .then((response)=>{
    dispatch(retrieveIdentityVariants(accountId))
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_RETRIEVING_IDENTITY_VARIANTS,
      status : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type: Actions.SAVING_IDENTITY_VARIANTS, status: false})
  })
}

export const createIdentityVariants = (accountId:number, lang:string, identityVariants:SimplifiedIdentityVariants) => (dispatch, getState) => {
  dispatch({type: Actions.SAVING_IDENTITY_VARIANTS, status: true})
  const token = getToken(getState())
  LogBuilder.log(UserActions.BRANDING_SAVE_IDENTITY_VARIANTS,token,{accountId,lang,identityVariants})
  ActionUtils.retryOnFailure(
    ()=>Headstone.createIdentityVariants(token, accountId, lang, identityVariants)
  )
  .then((response)=>{
    dispatch(retrieveIdentityVariants(accountId))
  })
  .catch((error)=>{
    dispatch({
      type: Actions.FAILED_CREATING_IDENTITY_VARIANTS,
      status : true,
      message : extractMessageFromError(error),
      accountId
    })
  })
  .then(()=>{
    dispatch({type: Actions.SAVING_IDENTITY_VARIANTS, status: false})
  })
}
