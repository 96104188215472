import './Column.css'
import * as React                   from "react"
import { findDOMNode }              from 'react-dom'
import ItemTypes                    from "../../../../model/constant/ItemTypes"
import { DragSource, DropTarget }   from 'react-dnd'
import ColumnDefaultFilter          from "./ColumnDefaultFilter"

interface ColumnProps{
  index           : number
  dataField       : string
  text            : string
  hidden          : boolean
  orderIndex      : number
  addColumn       : (dataField, hoverIndex)=>void
  removeColumn    : (dragIndex)=>void
  originalFilter ?: any
  editedFilter   ?: any
  setFilter       : (dataField:string, value:string)=>void
}
const columnTarget = {
  hover(props, monitor, component){
    const dragIndex = monitor.getItem().index
    const dragDataField = monitor.getItem().dataField
    const hoverIndex = props.index
    const hoverDataField = props.dataField

    // Don't replace items with themselves
    if (dragDataField === hoverDataField) {
      return
    }
    // Determine rectangle on screen
    const hoverBoundingRect = (findDOMNode(component) as Element).getBoundingClientRect()
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    // Determine mouse position
    const clientOffset = monitor.getClientOffset()
    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }
    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    props.addColumn(dragDataField, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
}
const columnSource = {
  beginDrag(props){
    return {
      index : props.index,
      dataField : props.dataField,
      removeMe : ()=>props.removeColumn(props.index)
    }
  }
}
function collectDrop(connect, monitor){
  return {
    connectDropTarget : connect.dropTarget(),
    isOver : monitor.isOver()
  }
}
function collectSource(connect, monitor){
  return {
    connectDragSource : connect.dragSource(),
    isDragging : monitor.isDragging()
  }
}
class Column extends React.Component<any> {
  render(){
    return this.props.connectDragSource(
      this.props.connectDropTarget(
        <div className="DragColumn">
          <p>{this.props.text}</p>
          <ColumnDefaultFilter
            originalFilter={this.props.originalFilter}
            editedFilter={this.props.editedFilter}
            setFilter={(value)=>this.props.setFilter(this.props.dataField, value)}
          />
        </div>
    ))
  }
}

export default DropTarget(ItemTypes.COLUMN, columnTarget, collectDrop)(DragSource(ItemTypes.COLUMN, columnSource, collectSource)(Column))
