import {combineReducers}            from "redux"
import List                         from "./List"
import Retrieving                   from "./Retrieving"
import NeedPermissionExtraData      from "./NeedPermissionExtraData"
import Descriptions                 from "./Descriptions"
import RetrievingPreferences        from './RetrievingPreferences'
import PreferencesList              from './PreferencesList'
import TargetUser                   from './TargetUser'
import SettingLocale                from './SettingLocale'
import SettingLanguage              from './SettingLanguage'

export const Users = combineReducers({
  List,
  Retrieving,
  PreferencesList,
  RetrievingPreferences,
  NeedPermissionExtraData,
  Descriptions,
  TargetUser,
  SettingLocale,
  SettingLanguage,
})
