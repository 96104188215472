import ReducerUtils         from "../../../utils/ReducerUtils"
import Actions              from "../../../model/constant/actions"

const handleError = (state,action) => {
  return {...state ,
    [action.accountId] : {
      ...(state[action.accountId]||{}),
      status:action.status,
      message:action.message
    }
  }
}
const failedRetrievingIdentityVariants = ReducerUtils.createReducer({},{
  [Actions.FAILED_RETRIEVING_IDENTITY_VARIANTS] : handleError
})

export default failedRetrievingIdentityVariants
