import * as React    from "react"
import * as ReactDOM from "react-dom"
import * as d3       from "d3"
import * as d3Tip    from "d3-tip"
import {GraphSize}   from "../../model/graph/Graph"

interface AxisOptions{
  scale(x:any):any, // This needs to be a Scaling function of d3
  color?:string,
}
interface AxisProps{
  size:GraphSize,
  axisBottom?:AxisOptions,
  axisTop?:AxisOptions,
  axisLeft?:AxisOptions,
  axisRight?:AxisOptions,
}

export default class Axis extends React.Component<AxisProps,any>{

  private node

  constructor(props){
    super(props)
    this.drawChart = this.drawChart.bind(this)
    this.updateChart = this.updateChart.bind(this)
    this.drawAxis = this.drawAxis.bind(this)
    this.updateAxis = this.updateAxis.bind(this)
  }
  componentDidMount(){this.drawChart()}
  componentDidUpdate(){this.updateChart()}
  drawChart(){
    let props = this.props
    let graph = d3.select(this.node)
    if(props.axisBottom){this.drawAxis("bottom",`translate(0,${props.size.height})`)}
    if(props.axisTop){this.drawAxis("top")}
    if(props.axisLeft){this.drawAxis("left")}
    if(props.axisRight){this.drawAxis("right",`translate(${props.size.width},0)`)}
  }
  updateChart(){
    let props = this.props
    let graph = d3.select(this.node)
    if(props.axisBottom){this.updateAxis("bottom",props.axisBottom,d3.axisBottom,`translate(0,${props.size.height})`)}
    if(props.axisTop){this.updateAxis("top",props.axisTop,d3.axisTop)}
    if(props.axisLeft){this.updateAxis("left",props.axisLeft,d3.axisLeft)}
    if(props.axisRight){this.updateAxis("right",props.axisRight,d3.axisRight,`translate(${props.size.width},0)`)}
  }
  drawAxis(axis, transformation=""){
    let graph = d3.select(this.node)
    graph.append("g")
      .attr("class", `axis axis-${axis}`)
      .attr("transform", transformation)
  }
  updateAxis(axis,options,callback,transformation=""){
    let graph = d3.select(this.node)
    graph.select(`.axis-${axis}`).transition().duration(400)
      .attr("transform", transformation)
      .style("fill", options.color ? options.color : "none")
      .call(callback(options.scale))
  }
  render() {
    return <g width={this.props.size.width} id={"axis"} ref={node => this.node = node}></g>
  }
}
