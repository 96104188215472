import {retrieveUser}       from "../user/users"
import Actions              from "../../model/constant/actions"
import Authentification     from '../../utils/Authentification'
import {getToken}           from "../../storeAccessor/Generic"

const onSuccess = data=>({
  type    : Actions.SET_PERMISSION_MESSAGE,
  message : JSON.stringify(data,null,4)
})
const onError = error => {
  console.log(error)
  return {
    type    : Actions.SET_PERMISSION_MESSAGE,
    message : JSON.stringify(error,null,4)
  }
}

const assign = (accountId:number, userId:number, profile:string) => (dispatch, getState) => {
  dispatch({
    type    : Actions.SET_PERMISSION_MESSAGE,
    message : "Assigning profile..."
  })
  Authentification.post({
      action    : "AssignPermissionProfile",
      token     : getToken(getState()),
      accountId : accountId,
      userId    : userId,
      profile   : profile,
    },
    data => {
      dispatch(onSuccess(data))
      dispatch(retrieveUser(userId))
    },
    error => dispatch(onError(error)),
  )
}

export default assign
