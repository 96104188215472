import * as React                from "react"
import Path                      from "./Path"
import Circle                    from "./Circle"
import {GraphData,GraphSize}     from "../../model/graph/Graph"

interface LineProps{
  data     : GraphData[]
  scalingX : (x:any)=>any
  scalingY : (x:any)=>any
  color    : string
  tip      : string
}
const Line = (props:LineProps) => {
  return (
    <g>
      <Path
        data={props.data}
        scalingX={props.scalingX}
        scalingY={props.scalingY}
        color={props.color}
      />
      <Circle
        data={props.data}
        scalingX={props.scalingX}
        scalingY={props.scalingY}
        color={props.color}
        tip={props.tip}
      />
    </g>
  )
}

export default Line
