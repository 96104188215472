interface Replacement{
  instanceof : "GrammarBoosterReplacement"
  id         : number
  lang       : string
  wrong      : string
  correct    : string
  isARegex   : boolean
  active     : boolean
  modifiedBy : number
  modifiedOn : {
    date           : string
    timezone_type  : number
    timezone       : string
  }
}
export const createEmptyReplacement = () : Replacement =>({
  instanceof: "GrammarBoosterReplacement",
  id: 0,
  lang: "EN",
  wrong: "the thing that's wrong",
  correct: "the good way to write it",
  isARegex: false,
  active: true,
  modifiedBy: 0,
  modifiedOn: {
    date: "2001-01-01 00:00:00.000000",
    timezone_type: 3,
    timezone: "America/Toronto"
  }
})
export const spacesToUnderscore = (x:string) : string => x.replace(/\s/g,"_")
export const underscoreToSpaces = (x:string) : string => x.replace(/_/g," ")
export default Replacement

export const formatToUpdate = (x:Replacement):object => ({
  Lang    : x.lang,
  Wrong   : x.wrong,
  Correct : x.correct,
  IsRegex : x.isARegex,
  Active  : x.active,
})
export const formatToCreate = (x:Replacement):object => ({
  Lang    : x.lang,
  Wrong   : x.wrong,
  Correct : x.correct,
  IsRegex : x.isARegex,
})
