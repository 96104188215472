import * as React   from "react"
import {
  withLocalize,
  Translate
}                   from "react-localize-redux"
import styles       from './CallsStyles'
import {Link}       from "@material-ui/core"
import {Button}     from "carbon-components-react"


const Calls = () => {

  const classes = styles()
  return (
    <div>
      <div className={classes.containerMessage}>
        <div className={classes.message}>
          <Translate id="leads.calls.message"/>
        </div>
      </div>
      <div className={classes.containerButtons}>
        <div className={classes.button}>
          <Link href="mailto:dbsm@AutoSync.ca">
            <Button>
              <Translate id="leads.calls.contact" />
            </Button>
          </Link>
        </div>
        <div className={classes.button}>
          <Link href="https://app.callrail.com/users/sign_in" target="_blank" rel="noopener">
            <Button>
              <Translate id="leads.calls.login" />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default withLocalize(Calls)
